<form [formGroup]="searchForm" (ngSubmit)="headerSearchSubmit()" style="min-width: 65vw;">
    <div class="bloc_container padding_15 bg_light">
        <div class="row">
            <div class="col-12 ">
                <mat-form-field>
                    <mat-select [placeholder]="'ksleveltype' | translate" disableOptionCentering panelClass="dropdown-panel" color="primary" formControlName="niveau_type">
                        <mat-option *ngFor="let item of niveauTypes" [value]="item.value">{{ item.label | translate }}</mat-option>
                    </mat-select>                                
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-12 text-center">
                <button class="bt_valid" mat-button type="submit">
                    <mat-icon>search</mat-icon>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kpsearch" | translate }}
                    </span>                    
                </button>
            </div>
        </div>
    </div>
</form>