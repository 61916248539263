import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslatePaginatorIntl extends MatPaginatorIntl {

  constructor(private translateService: TranslateService) {
    super();

    this.translateService.onLangChange.subscribe(() => {
      this.translateLabels();
    })

    this.translateLabels();
  }

  translateLabels() {
    this.translateService.get([
      'kpperpage',
      'kppfirst',
      'kpprev',
      'kpnext',
      'kplpage',
    ])
      .subscribe((translation: any) => {
        this.itemsPerPageLabel = translation['kpperpage'];
        this.firstPageLabel = translation['kppfirst'];
        this.previousPageLabel = translation['kpprev'];
        this.nextPageLabel = translation['kpnext'];
        this.lastPageLabel = translation['kplpage'];
  
        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
          return this._getRangeLabels(page, pageSize, length);
        };

        this.changes.next();
      });
  }

  _getRangeLabels(page: number, pageSize: number, length: number) {
    if (length == 0 || pageSize == 0) { return this.translateService.instant('kpleng', { length: length }); }
  
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      
    return this.translateService.instant('kpinlen', { startIndex: startIndex, endIndex: endIndex , length: length });
  }

}
