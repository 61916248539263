import { Component, OnInit } from '@angular/core';
import { Constants } from 'providers/constants';
import { Subject, Subscription } from 'rxjs';
import { UserService } from 'providers/api/userService';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { MotifService } from 'providers/api/motifService';
import { MotifsModel } from 'models/motifs-model';
import { AddMotifComponent } from '../add-motif/add-motif.component';
import { EditMotifComponent } from '../edit-motif/edit-motif.component';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MotifsFilterComponent } from './motifs-filter/motifs-filter.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-motifs',
  templateUrl: './motifs.component.html',
  styleUrls: ['./motifs.component.scss']
})
export class MotifsComponent implements OnInit {

  searchForm: any;


  motifActions = [
    {
      label: "kmaddkit1",
      value: Constants.MOTIF_ANNULATION_DEVIS,
    },
    {
      label: "kmaddkit2",
      value: Constants.MOTIF_RELANCE_DEVIS,
    },
    {
      label: "kmaddkit3",
      value: Constants.MOTIF_SUPPRESSION_COMMANDE,
    },
    {
      label: "kmaddkit4",
      value: Constants.MOTIF_FORCAGE_COMMANDE,
    },
    {
      label: "kmaddkit5",
      value: Constants.MOTIF_FORCAGE_VENTE,
    },
    {
      label: "kgblockdeblock",
      value: Constants.MOTIF_DEBLOCAGE_STOCK,
    },
  ];

  // bouton ENTER valide formulaire
  private pressedButton: any;

  // champs de recherche
  searchData: any = null;

  // Service
  listMotifs: MotifsModel[] = [];

  user: any;
  USERCMINT: any;

  // DATATABLE  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  searched = false;

  chosenAction: number;

  isMobile: boolean = false;
  searchSubscription: Subscription;
  addListenerSubscription: Subscription;
  selectBoxSubscription: Subscription;

  filterValues: any;
  selectVal: any;

  constructor(
    private userService: UserService,
    public motifService: MotifService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private loadingService: NgxSpinnerService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService) {

  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.userService.headerSearch.next(false);
    this.userService.headerSearchInput.next(false);
    this.userService.headerSearchButton.next(false);
    this.userService.headerAdd.next(true);


    this.searchSubscription = this.userService.headerSearch.subscribe(
      (status: boolean) => {
        if (status === true) {
          this.openUpFilterScreen();
        }
      }
    )

    this.addListenerSubscription = this.userService.headerAddListener.subscribe(
      (status: boolean) => {
        if (status === true) {
          this.addMotif();
        }
      }
    )

    this.selectBoxSubscription = this.userService.headerSelectBox.subscribe((dropdownObject) => {
      if(dropdownObject.pageName === 'Motifs'){        
        this.searchForm.patchValue({
          motif_action: dropdownObject.value,
        });
        this.selectVal = dropdownObject.value;
        this.typeOnChange();        
      }            
    });


    this.searchForm = this.formBuilder.group({
      motif_action: ['', [Validators.required]],
    });
    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
          first: this.translateService.instant('kfirst'),
          previous: this.translateService.instant('kprevious'),
          next: this.translateService.instant('knext'),
          last: this.translateService.instant('klast'),
        }
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.selectVal = this.motifActions[0].value;
    this.chosenAction = this.selectVal;
    this.getListMotifs(this.chosenAction);
  }


  ngOnDestroy(): void {
    this.userService.headerAdd.next(false);
    this.userService.headerSearch.next(false);
    this.searchSubscription.unsubscribe();
    this.addListenerSubscription.unsubscribe();
    this.selectBoxSubscription.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  typeOnChange() {
    this.showLoader = true;
    this.currentPage = 0;
    this.chosenAction = this.selectVal;

    this.filterValues = {
      motif_action: this.chosenAction
    };

    this.getListMotifs(this.chosenAction, this.currentPage + 1);
  }

  getListMotifs(action: number, page?: number) {
    const httpData = {
      action: action,
      IDMAG: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      CIINT: this.user.CIINT,
    };

    if(page && !isNaN(page) && page > 0) {
      httpData['page'] = page;
    }

    this.motifService.getListofMotifs(httpData).subscribe(
      (response) => {
        const data = response.data;
        this.rowsPerPage = response.rowsPerPage;
        this.totalPages = response.count;

        this.listMotifs = [...data];

        this.showLoader = false;
        this.dtTrigger.next();
      },
      error => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
      }
    )
  }

  paginationChange(event: any) {
    this.showLoader = true;
    const changedPageIndex = event.pageIndex;

    this.currentPage = changedPageIndex;

    this.getListMotifs(this.chosenAction, changedPageIndex + 1);
  }

  addMotif(): void {
    const dialogRef = this.dialog.open(AddMotifComponent, {
      width: '100vw',
      data: {
        listMotifActions: this.motifActions,
        chosen_action: this.chosenAction
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.currentPage = 0;
      this.getListMotifs(this.chosenAction, this.currentPage + 1);
    });
  }

  editMotif(code_deta: MotifsModel): void {
    const dialogRef = this.dialog.open(EditMotifComponent, {
      width: '100vw',
      autoFocus: !this.isMobile,
      data: { ...code_deta, listMotifActions: this.motifActions, chosen_action: this.chosenAction }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== '' && result !== undefined) {
        this.getListMotifs(this.chosenAction, this.currentPage + 1);
      }
    });
  }

  deleteConfirmation(row_id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: this.translateService.instant('kmcondelete'),
        description: ""
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === "OK") {
        this.deleteMotif(row_id);
      }
    });
  }

  deleteMotif(row_id: number): void {
    this.loadingService.show();
    this.motifService.deleteMotif(row_id).subscribe(
      (resp) => {
        this.loadingService.hide();

        if (resp.statusCode == 200) {

          this.listMotifs = this.listMotifs.filter((el) => el.IDMOT != row_id);

          if (this.listMotifs.length == 0) {
            this.showLoader = true;

            if (this.currentPage != 0) {
              this.currentPage--;
            }

            this.getListMotifs(this.chosenAction, this.currentPage + 1);
          }

          this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmdelete'));
        } else {
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kmerrdelete'));
        }
      },
      (err) => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
      }
    )
  }

  openUpFilterScreen() {
    this.userService.headerSearch.next(false);

    const dialogRef = this.dialog.open(MotifsFilterComponent, {
      width: '60vw',
      data: {
        form_data: this.searchForm.value,
        motifActions: this.motifActions,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === "success") {
        const form_data = result.form_data;

        this.searchForm.patchValue({
          motif_action: form_data.motif_action,
        });

        this.filterValues = form_data;

        if (form_data.motif_action !== '' && form_data.motif_action !== null) {
          this.showLoader = true;
          this.currentPage = 0;
          this.chosenAction = this.searchForm.get('motif_action').value;
          this.userService.headerAdd.next(true);
          this.getListMotifs(this.chosenAction, this.currentPage + 1);
        }
      }
    });
  }

  get filterValuesFormat(): string {
    let filterArr = [];

    if (this.filterValues.motif_action !== undefined && this.filterValues.motif_action !== null && this.filterValues.motif_action !== '') {
      const levelLabel = this.motifActions.find((row) => row.value == this.filterValues.motif_action);
      filterArr.push(this.translateService.instant(levelLabel.label));
    }

    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
