import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Constants} from '../constants';

import {User} from '../../models/users';
import {Global} from './global';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ParamDetail } from 'models/paramDetail';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { NiveauModel } from 'models/niveau-model';
import { Router } from '@angular/router';
import { DialogsService } from './dialogService';
import { StorageService } from './storageService';

@Injectable()
export class UserService {
    @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();    
    @Output() ruleParams: EventEmitter<ParamDetail[]> = new EventEmitter();

    public headerSearch: Subject<boolean> = new Subject();
    public headerSearchButton: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public headerThreeDots: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public headerSearchInput: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public headerSearchInputThreeDots: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public headerScanInput: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public headerSearchInputValue: Subject<string> = new Subject();
    public headerSearchInputOnChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public headerSearchInputType: Subject<'number' | 'text'> = new Subject();
    public headerSelectBox: Subject<any> = new Subject();  
    public popupClose: Subject<any> = new Subject();
    public userNiveauTypesList: Subject<NiveauModel[]> = new Subject();
    
    public headerAdd: Subject<boolean> = new Subject();
    public headerAddListener: Subject<boolean> = new Subject();
    public filterValues:any;
    public barcodeScanner: Subject<any> = new Subject();

    public actionReserveRefreshBon: Subject<any> = new Subject();

    public headerRefreshReportListener: Subject<boolean> = new Subject();
    public headerDeleteReportListener: Subject<string> = new Subject();
    

    constructor(private http: HttpClient, private global: Global, private dateAdapter: DateAdapter<any>,
        private translate: TranslateService,
        private dialogsService: DialogsService,
        private router: Router,
        private storageService: StorageService) {
    }    
    
    setAdvanceSearchFormData(formdata){
        this.filterValues = formdata;
    }
    getAdvanceSearchFormData(){
        return this.filterValues;
    }
    isUserLoggedIn() {
        const result: any = this.storageService.getItem('CurrentUser');
        if(result !== null && typeof result !== 'undefined') {
            return true;
        }
        return false;
    }
    getLoggedUser() {
        const result: any = this.storageService.getItem('CurrentUser');
        if(result !== null && typeof result !== 'undefined') {
            const userData = new User(result);
            return userData;
        }
        return null;
    }

    getUserCountryByIP(ip_address: string): Observable<any> {
        return this.http.get('https://api.ipdata.co/'+ip_address);
    }

    getCurrentUser(): User {        
        const result: any = this.storageService.getItem('CurrentUser');

        if(result !== null && typeof result !== 'undefined') {
            const userData = new User(result);
            this.getLoggedInName.emit(userData.CIUSER);            
            return userData;
        }        
        this.getLoggedInName.emit('NOT_LOGGED_IN');
        return null;        
    }

    getRememberedUser(): User {
        const result: any = this.storageService.getItem('REMEMBER_USER');
        if(result !== null && typeof result !== 'undefined') {
            const userData = new User(result);
            return userData;
        }
        return null;
    }

    setCurrentUser(currentUser: any) {
        this.global.currentUser = currentUser;
        this.global.reloadData();
        this.storageService.setItem('CurrentUser', currentUser);
        
        // Resets the Remembered user data
        this.storageService.setItem('REMEMBER_USER', currentUser);

        this.getLoggedInName.emit(currentUser.CIUSER);        
    }

    emptyCurrentUser() {
        this.global.currentUser = null;
        this.global.reloadData();
        this.storageService.removeItem('CurrentUser');              
        this.getLoggedInName.emit('LOGGED_OUT');
    }

    __successRedirect() {
        const restrictionData = this.storageService.getItem('CurrentUserPermission');
    
        if(restrictionData['4_0']) { // Bon de vente
            return this.router.navigate(['bon-de-vente']);    
        } else if(restrictionData['110_0']) { // Bon de vente: Autre magasin
            return this.router.navigate(['bon-de-vente/autre-magasin']);
        } else if(restrictionData['32_0']) { // Bon de commande
            return this.router.navigate(['bon-de-commande']);
        } else if(restrictionData['75_0']) { // Historique des commande
            return this.router.navigate(['historique-des-commandes']);
        } else if(restrictionData['56_0']) { // Commandes Supprimees
            return this.router.navigate(['commandes-supprimees']);
        } else if(restrictionData['24_0']) { // Devis
            return this.router.navigate(['devis']);
        } else if(restrictionData['23_0']) { // Articles 
            return this.router.navigate(['article']);
        } else if(restrictionData['69_0']) { // General: Niveau de securite
            return this.router.navigate(['niveau-de-securite']);
        } else if(restrictionData['74_0']) { // General: Gestion des droits
            return this.router.navigate(['gestion-des-droits']);
        } else if(restrictionData['73_0']) { // General: Utilisateurs
            return this.router.navigate(['utilisateurs']);
        } else if(restrictionData['101_0']) { // General: Motifs
            return this.router.navigate(['motifs']);
        } else if(restrictionData['52_0']) { // General: Paramètrage Blocage/Déblocage Stock
            return this.router.navigate(['motif-blocage']);
        } else if(restrictionData['71_0']) { // General: Codes postaux
            return this.router.navigate(['postal-codes']);
        } else if(restrictionData['60_0']) { // General: Complément information magasin
            return this.router.navigate(['magasin-complement-info']);
        } else if(restrictionData['2_0']) { // Clients
            return this.router.navigate(['client']);
        } else if(restrictionData['54_0']) { // Reporting
            return this.router.navigate(['reporting', 'liste_des_actions_speciales']);
        } else {
            return new Promise<any>((res, rej) => {
                this.emptyCurrentUser();
                this.storageService.clear();
                this.dialogsService.prompt(this.translate.instant('kserror'), this.translate.instant('kdroitsindefini'), this.translate.instant('kuok'), '100px'); // If no rights checked.
                res('ACCESS_DENIED');
            })
        }
    }

    addHistoryRow(data: any): Observable<any> {
        return this.http.post(Constants.API_GESTION_DES_DROITS_URL + 'addHistory', data);
    }

    getListofDroits(IDMAG: string, CIINT: string, niveauId?: string): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {
            IDMAG: IDMAG,
            IDSOC: Constants.IDSOC,
            CIINT: CIINT
        };

        if(niveauId !== undefined && niveauId !== null) {
            params['NIVINT'] = niveauId;
        }

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_GESTION_DES_DROITS_URL + 'getDroitsList', options);        
    }

    getCashAttributes(data: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_ARTICLE_URL + 'getCashattributes', options);        
    }

    getTimeZone(data?: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data || {};

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_USER_URL + 'getTimeZoneWS', options);        
    }

    getRulesParams(CMINT?: string, PARENT_CMINT?: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        
        if(CMINT !== undefined && CMINT != null) {
            params['IDMAG'] = CMINT;        
        }

        if(PARENT_CMINT !== undefined && PARENT_CMINT != null) {
            params['PARENT_IDMAG'] = PARENT_CMINT;
        }

        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_PARAM_URL + 'getParamsDetail', options);        
    }

    getRulesParamDetail(CMINT: string, ROW_ID: number, ROW_DET_ID: number): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {
            IDMAG: CMINT,
            IDSOC: Constants.IDSOC,
            TROWID: ROW_ID.toString(),
            TROWDETID: ROW_DET_ID.toString()
        };

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_PARAM_URL + 'getParamsDetailInfo', options);
    }

    getWeFoxSlotByMag(CMINT: string): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const params = {
            IDMAG: CMINT
        };

        const options = {
            headers: headers,
            params
        };

        return this.http.get(Constants.API_PARAM_URL + 'getWefoxAvailableSlotsByMag', options);
    }

    getRuleParamsList() {
        const parametres = this.storageService.getItem('RULE_PARAMS');        
        return this.processRulesParams(parametres);
    }

    async getRuleParamsData(ROW_ID: number, ROW_DET_ID: number, column?: string, society?: boolean, overrideCMINT?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = undefined;
            const parametres = undefined;

            if(parametres === undefined || parametres === null) {
                let IDMAG = 0;
                const userData = this.getLoggedUser();
                if(userData === undefined || userData == null) {
                    IDMAG = 0;
                } else {
                    IDMAG = (userData.CMINT !== undefined && userData.CMINT != null)?+userData.CMINT:0;
                }

                if(society === true) {
                    IDMAG = 0;
                }

                if (overrideCMINT !== undefined && overrideCMINT !== null) {
                    IDMAG = overrideCMINT;
                }
    
                return this.getRulesParamDetail(IDMAG.toString(), ROW_ID, ROW_DET_ID).toPromise().then(
                    (resp: any) => {
                        if(resp && resp.success == 'ok' && resp.data.length > 0) {
                            data = resp.data[0];
                            if(column !== undefined && column !== '') {
                                resolve(resp.data[0][column]);
                                return;
                            }
                            resolve(resp.data[0]);
                            return;
                        } else {
                            reject('error_response');
                            return;
                        }
                    },
                    error => {
                        reject(error);
                        return;
                    }
                );
                
            } else {
                data = this.processRulesParams(JSON.parse(parametres));
            }
            
    
            if(data === undefined || data === null) {
                reject('error_empty_data');
                return;
            }
    
            if(column !== undefined && column !== '') {
                resolve(data[ROW_ID+'_'+ROW_DET_ID][column]);
                return;
            }
    
            resolve(data[ROW_ID+'_'+ROW_DET_ID]);
            return;
        });
    }

    processRulesParams(ruleParams: ParamDetail[]) {
        if(ruleParams === undefined || ruleParams === null) {
            return;
        }

        const changedFormatParams: any[] = [];

        ruleParams.forEach((row: ParamDetail) => {
            const currentAccessKey = row.TROWID+'_'+row.TROWDETID;
            changedFormatParams[currentAccessKey] = row;
        });        

        return changedFormatParams;
    }

    languageSelection = (shortCode: string) => {
        const list = {
            fr: 'Français',
            en: 'English',
            es: 'España',
            nl: 'Deutsch'
        };
        return list[shortCode];
    }

    async changeLanguage() {
        const paramLang = await this.getRuleParamsData(10, 1, 'VCHXMUL1');        
        let changable_lang = paramLang;
        let userData = this.getLoggedUser();
        if(userData !== undefined && userData != null && userData.CILANG !== undefined && userData.CILANG != null && userData.CILANG !== '') {
            let trimmedLangShort = userData.CILANG.substr(0, 2);
            changable_lang = trimmedLangShort;
        }

        if(changable_lang !== undefined && changable_lang !== null && changable_lang !== '') {
            this.dateAdapter.setLocale(changable_lang);
            this.translate.setDefaultLang(changable_lang);
        } else {
            this.dateAdapter.setLocale(Constants.APP_DEFAULT_LANG);
            this.translate.setDefaultLang(Constants.APP_DEFAULT_LANG);
        }        
        
    }
}

