<div class="modal-container" >    
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-12">
                        <h1 class="text-center">
                            {{'kartsearch' | translate}}
                        </h1>
                        <h1 class="text-center m-0" *ngIf="currentArticleStockMag" [innerHTML]="'kstock_mag' | translate: { MAGASIN_NOM: currentArticleStockMagNom }"></h1>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row" *ngIf="isMobile">
                <div class="col-10">
                    <form (ngSubmit)="submit_search_input()">
                        <input name="header_search" #filterFirstEl inputmode="none" (click)="inputFocus($event)" [(ngModel)]="searchValue" class="form-control header-search-input border-success" style="color: #000 !important;" />
                    </form>
                </div>
                <div class="col-2 align-items-end d-flex flex-row justify-content-end">
                    <div class="align-items-center d-flex d-md-none d-lg-none justify-content-around">
                        <mat-icon (click)="submit_search_input()" class="bt_logout bt_cursor text-dark" [title]="'kbonrecherchere' | translate">search</mat-icon>
                        <mat-icon (click)="search_callback()" class="bt_logout bt_cursor text-dark" [title]="'kbonrecherchere' | translate">more_vert</mat-icon>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" *ngIf="isMobile && filterValues">
                    <h2 class="text-left mt-4 font-italic font-weight-light text-capitalize">{{filterValuesFormat }}</h2>
                </div>
            </div>
            <div class="table-scroller articlesearch">
                <app-article displayType='dialog' [flowType]="flowType" [flowCMINT]="flowCMINT" [searchNomPhrase]="searchNomPhrase" [formSearchType]='formSearchType' [enableMultiSelection]="multiSelectionEnabled" (onMultiArticleSelectionChange)="onChosenArticleChange($event)" [(preFilterConf)]="preFilterConf" [(searchedArticle)]="searchedArticle" (onArticleChnage)="submitSelectedArticle($event)" ></app-article>
            </div>

            <div class="row bg_white">
                <div class="col-12 mt-2 mt-md-0 text-center height_bloc" >                      
                    <div>
                        <button class="bt_valid" mat-button *ngIf="multiArticles !== undefined && multiArticles !== null && multiArticles.length > 0" (click)="onMultiArticleValidation()" type="button">
                            <i class="material-icons">check</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{ "kuvalid" | translate }}
                            </span>                        
                        </button>
                        <button class="bt_valid ml-2" mat-button mat-dialog-close type="button">
                            <i class="material-icons">close</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{ "kucancel" | translate }}
                            </span>                        
                        </button>
                    </div>    
                </div>
            </div>
        </mat-card-content>
    </mat-card>    
</div>
