<form [formGroup]="searchForm" (ngSubmit)="headerSearchSubmit()" style="min-width: 65vw;">
    <div class="bloc_container padding_15 bg_light">
        <div class="row">
            <div class="col-12 ">
                <div class="row">
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <mat-select [placeholder]="'kmaction' | translate" color="primary" formControlName="motif_action">
                                <mat-option *ngFor="let item of motifActions" [value]="item.value">{{ item.label | translate }}</mat-option>
                            </mat-select>                                
                        </mat-form-field>                            
                    </div>                        
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-12 text-center">
                <button class="bt_valid" mat-button type="submit">
                    <mat-icon>search</mat-icon>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kpsearch" | translate }}
                    </span>                    
                </button>
            </div>
        </div>
    </div>
</form>