import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Magasin } from 'models/magasins';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogsService } from 'providers/api/dialogService';
import { LoginService } from 'providers/api/loginService';
import { UserService } from 'providers/api/userService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-autre-magasin',
  templateUrl: './autre-magasin.component.html',
  styleUrls: ['./autre-magasin.component.scss']
})
export class AutreMagasinComponent implements OnInit {

  showLoader: boolean;
  listMagasins: Magasin[] = [];
  filteredMagasins: Magasin[] = [];

  searchMag: string;

  isMobile: boolean = false;
  searchMagSubscription: Subscription;

  constructor(private loginService: LoginService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private router: Router) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.getMagasins();
    
    this.isMobile = this.deviceService.isMobile();

    this.userService.headerSearchInput.next(true);
    this.userService.headerSearchInputType.next('text');
    this.userService.headerSearch.next(false);    
    this.userService.headerSearchInputThreeDots.next(false);
    this.userService.headerSearchInputOnChange.next(true);

    this.searchMagSubscription = this.userService.headerSearchInputValue.subscribe(
      (searchKey) => {
        if(searchKey !== undefined) {
          this.searchMag = searchKey;
          this.filterMagasinList();
        }              
      }
    )
  }
  
  ngOnDestroy() {
    this.userService.headerSearchInput.next(false);    
    this.userService.headerSearchInputOnChange.next(false);
    this.searchMagSubscription.unsubscribe();
  }

  public getMagasins() {    
    this.loginService.getMagasins().subscribe(
      (data) => {
        this.showLoader = false;
        this.listMagasins = data;
        this.filteredMagasins = this.listMagasins;
      }, 
      (error) => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  filterMagasinList(e?: any) {
    if(this.searchMag !== undefined && this.searchMag !== null && this.searchMag !== '') {      
      this.filteredMagasins = this.listMagasins.filter((row) => (row.CMRAISOC.toLowerCase()).indexOf(this.searchMag.toLowerCase()) !== -1);
    } else {
      this.filteredMagasins = this.listMagasins;
    }
  }

  createBonDeForAutreMag(magasin: Magasin) {
    this.router.navigate(['create-bon-de-vente', magasin.CMINT]);
  }

}
