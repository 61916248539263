<div class="page-container">
    <div class="title row">
        <div class="col-1 col-sm-2 col-md-2 col-lg-3" [class.no-padding]="isMobile">
            <mat-icon class="pointer" (click)="returnBack()" [title]="'kuback' | translate">
                keyboard_arrow_left
            </mat-icon>
        </div>
        <div class="col-10 col-sm-8 col-md-8 col-lg-6 text-center">
            <h1 class="text-center" *ngIf="!isMobile">
                <span class=" d-inline-block mr-1">{{ 'kboncommanddet' | translate }}{{ previousPanier?' - '+('kboncomcommande' | translate)+' '+previousPanier.typecde:'' }}</span>
                <span *ngIf="magasinNom" class="d-inline-block">&nbsp;- {{ magasinNom }}</span><br>
                <span *ngIf="currentArticleStockMag" class="d-inline-block">{{ 'kstock_mag' | translate: { MAGASIN_NOM: currentArticleStockMagNom } }}</span><br *ngIf="currentArticleStockMag">
                <span class="badge greenbut d-inline-block mr-1">{{ _etatDefine() | translate }}</span>
                <span class="badge badge-secondary  d-inline-block">{{ titre }}</span>
                <span class="badge badge-danger  d-inline-block ml-1" *ngIf="previousPanier && previousPanier.preparation == true">{{ 'kprepare' | translate }}</span>
            </h1>
            <h1 class="text-center" *ngIf="isMobile">
                <span>{{(bonNumBon && bonNumBon != 'null')?bonNumBon:'*************'}} - {{(bonDuDate)?bonDuDate:''}}</span>
                <span *ngIf="magasinNom" class="d-inline-block">&nbsp;{{ magasinNom }}</span><br>
                <span *ngIf="currentArticleStockMag" class="d-inline-block">{{ 'kstock_mag' | translate: { MAGASIN_NOM: currentArticleStockMagNom } }}</span><br *ngIf="currentArticleStockMag">
                <span>{{currentUserName}}</span><br>
                <span class="badge greenbut d-inline-block mr-1">{{ _etatDefine() | translate }}</span>
                <span class="badge badge-secondary  d-inline-block">{{ titre }}</span>
                <span class="badge badge-danger  d-inline-block ml-1" *ngIf="previousPanier && previousPanier.preparation == true">{{ 'kprepare' | translate }}</span>
            </h1>
        </div>  
        <div class="col-1 col-sm-2 col-md-2 col-lg-3 no-padding" *ngIf="isMobile">
            <mat-icon *ngIf="pageType === 'commande'">assignment_turned_in</mat-icon>
            <mat-icon *ngIf="pageType === 'historique'">receipt_long</mat-icon>
            <mat-icon *ngIf="pageType === 'supprimees'">restore_from_trash</mat-icon>
        </div>      
    </div>
    <div *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light" >
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="boxed-section">
                        <div class="row">
                            <div class="col-12 col-md-4 col-lg-3 tiers">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kbonbonl' | translate }} </mat-label>
                                    <input matInput [value]="(bonNumBon && bonNumBon != 'null')?bonNumBon:'*************'" placeholder="*************" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 tiers">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kbondatdebon' | translate }} </mat-label>
                                    <input matInput [value]="(bonDuDate)?bonDuDate:''" placeholder="" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 tiers">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{'kbonuser1' | translate}} </mat-label>
                                    <input matInput [value]="currentUserName" disabled>
                                </mat-form-field>
                            </div>                            
                            <div class="col-12 col-md-4 col-lg-3 tiers">
                                <div class="note-badge" *ngIf="totalNotes && totalNotes > 0">
                                    <span class="note-badge-text">{{ totalNotes }} {{(totalNotes > 1?'kknotes':'kknote') | translate}}</span>
                                </div>
                                <button type="button" class="btn-block text-left mt-0" (click)="viewBonCommandeHistory()" mat-button>
                                    <i class="material-icons">history</i>
                                    {{'kbonhistoire' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <form [formGroup]="newArticleForm" #newItemForm=ngForm [cdkTrapFocusAutoCapture]="true" [cdkTrapFocus]="true" *ngIf="!readOnly && insertionAllowed && (this.previousPanier && !this.previousPanier.preparation)" (keydown.enter)="$event.preventDefault()" (ngSubmit)="newArticleFormSubmitter()">
                        <div class="boxed-section">                        
                            <div class="row">                        
                                <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-2">
                                    <mat-form-field floatLabel="always" appearance="outline">
                                        <mat-label>{{'kartref' | translate}}</mat-label>
                                        <input matInput type="text" formControlName="CDART" (keyup.enter)="searchArticleRapid($event)" class="text-uppercase" cdkFocusInitial #newArticleCdart />
                                        <button matSuffix type="button" (click)="openUpSearchArticle($event)" tabindex="-1" mat-icon-button>
                                            <mat-icon>search</mat-icon>
                                        </button>
                                        <button matSuffix class="ml-2" type="button" *ngIf="isMobile" (click)="openScanArticle($event)" tabindex="-1" mat-icon-button>
                                            <mat-icon>qr_code_scanner</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-3 col-lg-2 pl-1 pr-2">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>{{'kbondesgn' | translate}}</mat-label>
                                        <input matInput formControlName="CALIB1" class="text-uppercase" />
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-9 col-lg-5 pl-1 pr-2">
                                    <div class="row">
                                        <div class="col-12 col-lg-3 quantity_holder pl-1 pr-2">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kartqqty' | translate}}</mat-label>
                                                <input [imask]="quantityMask" [unmask]="true" type="text" matInput #newArticleQty formControlName="QTE" (click)="onQuantityFocused()" (change)="onQTYChanged($event)" (keyup)="onQTYChanged($event)" (keydown)="incDecByArrows($event)" (keydown.tab)="_focusPUvente();false;" (keydown.enter)="_focusPUvente()" class="text-uppercase" />
        
                                                <button type="button" mat-icon-button matSuffix (click)="quantityChange('increment')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>add</mat-icon>
                                                </button> 
                                                <button type="button" mat-icon-button class="ml-2" matSuffix (click)="quantityChange('decrement')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-lg-3 pl-1 pr-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpucais' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="CAPRIX" class="text-uppercase"/>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-lg-3 pl-1 pr-2">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kbonpuven' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" type="text" matInput #prixVente formControlName="NEW_PRIX" (change)="onPriceChanged($event)" (keydown)="onPriceChanged($event)" (keyup)="onPriceChanged($event)" (keydown.tab)="_triggerSubmitNewItem();false;" (keydown.enter)="_triggerSubmitNewItem()" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-lg-3 pl-1 pr-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpvtot' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="ROW_TOTAL" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-4 col-md-2 col-lg-1 pl-1 pr-2">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>{{'kbonremise' | translate}}</mat-label>                                        
                                        <input [imask]="maskOptions" [unmask]="true" matInput formControlName="MOTANT" />

                                        <button type="button" mat-icon-button matSuffix [disabled]="!currentArticle" (click)="openUpRemiseList(1, currentArticleCAPrix.value, true)">
                                            %
                                        </button>
                                    </mat-form-field>
                                </div>
                                <div class="clearfix"></div>
                                
                                <div class="col-12 col-md-3 col-lg-2 pl-1">
                                    <mat-form-field class="display-large-label" floatLabel="always">
                                        <mat-label>
                                            {{'kboninstock' | translate}}
                                            <span *ngIf="currentArticleStockMag"> - {{ currentArticleStockMagNom }}</span>
                                        </mat-label>
                                        <input matInput formControlName="STOCKDISPO" placeholder="">
    
                                        <button type="button" mat-icon-button matSuffix (click)="openUpDetailStock()" [class.d-none]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')" [disabled]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')">
                                            <mat-icon>edit</mat-icon>
                                        </button>                                
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-7 col-lg-5 d-flex align-items-center">
                                    <h4 class="text-primary animated flash" *ngIf="commander">{{ ((currentArticle && currentArticle.etatart == 'G')?((currentArticle && +currentArticle.STOCKDISPO <= 0)?('ksanssuite' | translate):('ksanssuite' | translate)): ('kboncommande' | translate))}}</h4>
                                </div>
                                <div class="col-12 col-md-5 col-lg-7 text-right">
                                    <button type="submit" class="lock-open" mat-button><mat-icon>check</mat-icon> {{ 'ksaddbtn' | translate }}</button>
                                </div>
                            </div>                        
                        </div>  
                    </form>                    
                    
                    <div class="row no-gutters">
                        <div class="col-12 col-md-8 col-lg-8">
                            <div class="d-flex flex-row justify-content-start align-items-center">
                                <div class="ml-2">
                                    <button type="button" class="btn-block multi-line-btn no-padding" (click)="openUpAlerteClient()" [disabled]="!buttonsListAvail" mat-button>
                                        <i class="material-icons">pending_actions</i>
                                        {{'kboncomalertclient' | translate}}
                                    </button>
                                </div>

                                <div class="ml-2">
                                    <button type="button" class="btn-block multi-line-btn no-padding" (click)="openUpTickets()" mat-button>
                                        <i class="material-icons">receipt</i>
                                        {{'kboncomtickets' | translate}}
                                    </button>
                                </div>

                                <div class="ml-2">
                                    <button type="button" class="btn-block multi-line-btn no-padding" (click)="openUpRelance()" [disabled]="(previousPanier && [3,4,5,6].indexOf(previousPanier.statut) < 0) || readOnly" mat-button>
                                        <i class="material-icons">notifications</i>
                                        {{'kbonrelance' | translate}}
                                    </button>
                                </div>
                                <h4 class="ml-2">
                                    {{'kboncomdatedernierrelance' | translate}}     
                                    <span [class.px-5]="!bonRelanceDate">{{ bonRelanceDate?bonRelanceDate:'&nbsp;' }}</span>
                                </h4>
                            </div>                            
                        </div>                        
                        <div class="col-12 col-md-4 col-lg-4"  *ngIf="listBonCommandeItems?.length != 0 && autreStockTypeModif">
                            <div class="d-flex align-items-center justify-content-end"  *ngIf="!readOnly && (previousPanier !== undefined && previousPanier.statut != 7 && previousPanier.statut != 17 && previousPanier.statut != 27 && previousPanier.preparation != true) && !isMobile">
                                <button type="button" class="btn-block multi-line-btn no-padding w-75" (click)="modifierTypeConfirmation()" mat-button>
                                    <i class="material-icons">cached</i>    
                                    {{'kboncommodifytypecmd' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div style="border: 1px solid #d8d8d8;overflow-y: scroll;" class="bg-white">
                        <table #tableItems id="items_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border no-border table-bordered hover dataTable no-footer">
                            <thead>
                                <tr>               
                                    <th *ngFor="let col of bonCommandeModifyColumns" [class]="col.class" [width]="col.width">
                                        {{ col.name | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="listBonCommandeItems?.length != 0 && !showLoader">
                                <tr *ngFor="let item of listBonCommandeItems;let i=index;"
                                    [class.active]="activeLineItem === i"
                                    [class.parent-row]="item.opt < 0"
                                    [class.child-row]="item.opt > 0"
                                    [class.status_17]="item.statut == 17"
                                >
                                    <td *ngFor="let col of bonCommandeModifyColumns" [class]="col.class" (click)="col.value !== 'remise' && onClickRow(i, item)">
                                        <ng-container *ngIf="col.value === 'statut'">
                                            <div class="d-flex align-items-center justify-content-between solde_state">
                                                <mat-icon *ngIf="item.statut != 7 && item.statut != 8">block</mat-icon>
                                                <mat-icon *ngIf="item.statut == 7 || item.statut == 8">check_circle</mat-icon>
                                                <span class="colorcode" [class.solde_yellow]="item.statut == 2 || item.statut == 9" 
                                                [class.solde_green]="item.statut >= 3 && item.statut <= 6"
                                                [class.solde_aqua]="item.statut == 7 || item.statut == 8"
                                                [class.solde_black]="item.statut == 17"
                                                [class.solde_partiel]="item.statut == 27"></span>
                                            </div>  
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'cdart'">
                                            <div class="d-flex justify-content-start align-items-center">                                                
                                                <span class="ml-3">{{ item.cdart }}</span>
                                                <span class="red-dot ml-3" *ngIf="(item.commlig1 !== null && item.commlig1 !== '') || (item.commlig2 !== null && item.commlig2 !== '')"></span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'lib'">
                                            <div class="d-flex justify-content-between">
                                                <span>{{ item.lib }}</span>
                                                <div class="align-self-start d-flex flex-column align-items-end">                                                   
                                                    <span class="badge badge-danger mb-1" *ngIf="item.coinsolde">{{'kboncoin' | translate}}</span>
                                                    <span class="badge badge-danger mt-1" *ngIf="item.expo">{{'kbonexpo' | translate}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'quantite'">
                                            {{ item.quantite | number: '0.2' }}
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'prix'">
                                            <div class="d-flex justify-content-end align-items-center">
                                                <span *ngIf="item.prixforce" class="badge badge-danger mr-3">P</span>
                                                <span>{{ item.prix | number: '0.2' }}</span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'prixupv'">
                                            <div class="d-flex justify-content-end align-items-center">
                                                <span *ngIf="(item.datarem !== '' && item.datarem !== null)" class="badge badge-danger mr-3">%</span>
                                                <span>{{ item.prixupv | number: '0.2' }}</span>                                                
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'montant'">
                                            {{ item.montant | number: '0.2' }}
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'typecde'">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <span>{{ item.typecde }}</span>
                                                <mat-checkbox [(ngModel)]="checkedLineItems[item.idlig]" *ngIf="!readOnly && (getTotalMainArticleItems > 1 && previousPanier.typecde != 'EI') && (previousPanier !== undefined && previousPanier.statut != 7 && previousPanier.statut != 17 && previousPanier.statut != 27 && previousPanier.preparation != true) && item.opt <= 0 && !currentArticleStockMag" (ngModelChange)="itemChosenChange($event, item)" class="mt-2"></mat-checkbox>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="col.value === 'remise'">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <span>{{ item.remise | number: '0.2' }} %</span>
                                            
                                                <button type="button" mat-icon-button class="button-small theme-color" (click)="openUpAddedRemiseList(1, 0, item)">
                                                    <mat-icon>info</mat-icon>
                                                </button>
                                            </div>   
                                        </ng-container>
                                    </td>
                                </tr>                        
                            </tbody>
                            <tbody *ngIf="listBonCommandeItems?.length == 0 || showLoader">
                                <tr>
                                    <td colspan="9" *ngIf="listBonCommandeItems?.length == 0 && !showLoader" class="no-data-available text-center empty_items_table"></td>
                                    <td colspan="9" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>                            
                                    <td style="display: none;"></td>
                                    <td style="display: none;"></td>
                                    <td style="display: none;"></td>
                                    <td style="display: none;"></td>
                                    <td style="display: none;"></td>
                                    <td style="display: none;"></td>
                                    <td style="display: none;"></td>
                                    <td style="display: none;"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row no-gutters">
                        <div class="col-md-7">
                            <div class="mt-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-form-field>
                                            <mat-label>{{'kboncomment' | translate}}</mat-label>
                                            <input matInput [(ngModel)]="bonVenteComments" (keyup)="onFocusCommentaire()" [disabled]="readOnly" class="text-uppercase"/>
                                            <div matSuffix><app-inline-spinner [loading]="updateCommentInProgress"></app-inline-spinner></div>
                                        </mat-form-field>
                                    </div>
                                </div>                                
                            </div>
                            <div class="mt-3">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="d-flex flex-column flex-lg-row flex-wrap justify-content-start">
                                            <div *ngIf="!readOnly && remiseEnE2" class="mr-2">
                                                <button type="button" class="btn-block" (click)="validateRemiseEnE2()" [disabled]="remiseEnE2Disabled" mat-button>
                                                    <i class="material-icons">account_balance_wallet</i>
                                                    {{'kboncomremiseene2' | translate}}
                                                </button>
                                            </div>
                                            <div class="validatebonde mr-1" *ngIf="viewDevisDetail">
                                                <button type="button" class="btn-block" (click)="viewSourceDevis()" mat-button>
                                                    <i class="material-icons">request_quote</i>
                                                    {{'kkvoirdevis' | translate}}
                                                </button>
                                            </div>
                                            <div class="voir_retrait" *ngIf="viewRetraitMarchandiseDetail">
                                                <button type="button" class="btn-block" (click)="redirectModificationRetraitMarchandise()" mat-button>
                                                    <i class="material-icons">shopping_cart_checkout</i>
                                                    {{'kvoir_bon_de_retrait' | translate}}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column flex-lg-row justify-content-start">
                                            <div class="">
                                                <button type="button" class="btn-block m-0"
                                                        *ngIf="!readOnly && changeStoreStatus && (!(ticketsArtZeroTotal == null) && (ticketsArtOthersTotal == null || ticketsArtOthersTotal == 0)) && (previousPanier !== undefined && previousPanier.statut != 7 && previousPanier.statut != 17 && previousPanier.statut != 27 && previousPanier.preparation != true) && !currentArticleStockMag"
                                                        (click)="changeCommandeStore()" mat-button>
                                                    <i class="material-icons">store</i>
                                                    <span>{{'kchangestore' | translate}}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field floatLabel="always">
                                            <mat-label>{{'kbonremtot' | translate}} </mat-label>
                                            <input matInput [value]="((totalDiscountPercentage?totalDiscountPercentage:0) | number: '0.2')+'%'" disabled>

                                            <button type="button" mat-icon-button matSuffix (click)="openUpAddedRemiseList()">
                                                i
                                            </button>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                <h4>{{'kbontotHT' | translate}}</h4>
                                <h4>{{ (totalHT?totalHT:0) | cap_currency:null:false }}</h4>
                            </div>

                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                <h4>{{'kbontotTVA' | translate}}</h4>
                                <h4>{{ (totalTVA?totalTVA:0) | cap_currency:null:false }}</h4>
                            </div>

                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                <h4>{{'kbontotTTC' | translate}}</h4>
                                <h4>{{ (totalTTC?totalTTC:0) | cap_currency:null:false }}</h4>
                            </div>                                                    
                        </div>
                        <div class="col-md-2">
                            <button type="submit" class="btn-block text-left" (click)="submitBonDeCommande()" *ngIf="!readOnly" mat-button>
                                <i class="material-icons">check</i>
                                {{ 'kuok' | translate }}
                            </button>
                            <button type="button" class="btn-block padd5"  [title]="'kboncomreactive' | translate" (click)="reactivateConfirmation()"  *ngIf="(pageType === 'historique' || pageType === 'supprimees') && readOnly && (userPermission !== undefined && userPermission !== null && userPermission['32_16']) && userAllowedToModifyOrRemove"  mat-button>
                                <mat-icon>model_training</mat-icon>
                                {{'kboncomreactive' | translate}}
                            </button>

                            <button type="button" class="btn-block text-left" (click)="imprimerConfirmCheckboxPopup()" *ngIf="!readOnly" mat-button>
                                <i class="material-icons">print</i>
                                {{ 'kartprint' | translate }}
                            </button>

                            <button type="button" class="btn-block text-left" (click)="onCloseBonDeCommande()" mat-button>
                                <i class="material-icons">{{ (!readOnly && previousPanier !== undefined && previousPanier !== null && previousPanier.preparation !== true)?'delete':'close'}}</i>
                                {{ ((!readOnly && previousPanier !== undefined && previousPanier !== null && previousPanier.preparation !== true)?'kbonsupprimer':'ktcol') | translate}}
                            </button>
                        </div>

                        <div class="clearfix"></div>
                        <div class="col-md-5"></div>
                        <div class="col-md-5">
                            <div class="mt-3 d-flex align-items-center justify-content-end">
                                <h1 class="font-weight-bold">{{'kbontotTTC' | translate}}</h1>
                                <h1 class="animated total_ttc text-right w-50 ml-3" [class.blue]="totalTTCNC === undefined || totalTTCNC == 0">{{ (totalTTCNC?totalTTCNC:0) | cap_currency:null:false}}</h1>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </div>
        </div>

         <div class="bloc_container">
            <mat-card class="no-padding">
                <mat-card-content class="mat-card-content text-center">
                    <div class="row">
                        <div class="col-12 no-padding">    
                            <mat-tab-group [(selectedIndex)]="bonVenteSelectedTab" style='min-height:400px'>
                                <mat-tab [label]="'kbonclient' | translate">
                                    <app-client-bonde [readOnly]="readOnly || restrictClientModifications" [emailMandatory]="emailMandatory" [flowType]="flowType" [modifiableStat]="clientLivraisonModifiable" [panier]="previousPanier" [(focusInput)]="focusClientSearch" [listMagasins]="listMagasins" (panierChange)="panierUpdate($event)" [(client)]="chosenClient" [(chosenFidelite)]="chosenFidelite"></app-client-bonde>
                                </mat-tab>

                                <mat-tab [label]="'kbonloyalty' | translate">
                                    <app-fidelite-bonde [readOnly]="readOnly || restrictClientModifications" [flowType]="flowType" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(bonNumBon)]="bonNumBon" [(client)]="chosenClient" [(fidelite)]="chosenFidelite"></app-fidelite-bonde>
                                </mat-tab>

                                <mat-tab [label]="'kboninfoliv' | translate">
                                    <app-info-livraison-bonde [readOnly]="readOnly || restrictClientModifications" [chosenArticle]="article" [flowType]="flowType" [modifiableStat]="clientLivraisonModifiable" [panier]="previousPanier" [listMagasins]="listMagasins" (livraisonTrackUpdated)="onLivraisonTrackingChanged($event)" (panierChange)="panierUpdate($event)" [magComplementData]="magComplementData" [(transporteurLabel)]="transporteurLabel" [(livreDetail)]="livraisonData" [prixLivraison]="prixLivraison" [(sameAsClient)]="sameAsClient" [(livreClient)]="chosenLivraisonClient" [(client)]="chosenClient"></app-info-livraison-bonde>
                                </mat-tab>

                                <mat-tab [label]="'kboninfofac' | translate">
                                    <app-info-facturation-bonde [readOnly]="readOnly || restrictClientModifications" [flowType]="flowType" [panier]="previousPanier" [listMagasins]="listMagasins" (panierChange)="panierUpdate($event)" [(facturationDetail)]="facturationData" [(sameAsClient)]="sameAsFacturationClient" [(factuClient)]="chosenFacturationClient" (clientChange)="onClientChangeFromFacturation($event)" [(client)]="chosenClient"></app-info-facturation-bonde>
                                </mat-tab>

                                <mat-tab [label]="'kboninfocom' | translate">
                                    <app-info-complementaries [readOnly]="readOnly" [flowType]="flowType" [(article)]="article" [bonNumBon]="bonNumBon" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(activeItemNumber)]="activeLineItem" [wefoxAvail]="activeItemWefoxStat" [userName]="currentUserName"></app-info-complementaries>
                                </mat-tab>

                                <mat-tab [label]="'kartarttit' | translate">
                                    <ng-template matTabContent>
                                        <app-article-detail [flowCMINT]="currentArticleStockMag" [flowType]="flowType" displayType="tab" [(vente_item_article)]="article"></app-article-detail>
                                    </ng-template>
                                </mat-tab>

                                <mat-tab *ngIf="pageType === 'commande'" [label]="'kcommandeliees' | translate">
                                    <app-commande-liees [readOnly]="readOnly" [flowType]="flowType" [bonNumBon]="bonNumBon" [panier]="previousPanier" [userName]="currentUserName"></app-commande-liees>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>  

    <div class="bloc_container mobile" *ngIf="isMobile">
        <div class="row no-gutters">
            <div class="col-12">
                <mat-accordion>
                    <mat-expansion-panel *ngIf="!readOnly && insertionAllowed && (this.previousPanier && !this.previousPanier.preparation)" id="scr_ajouter" [expanded]="step === 0" (afterExpand)="__scrollToTab('scr_ajouter')" (opened)="setStep(0)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>                                
                                {{ 'kbonajouterart' | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <form [formGroup]="newArticleForm" [cdkTrapFocusAutoCapture]="true" [cdkTrapFocus]="true" #newItemForm=ngForm (keydown.enter)="$event.preventDefault()" (keydown.tab)="_focusPUvente();false;" (keydown.enter)="_focusPUvente()" (ngSubmit)="newArticleFormSubmitter()">
                                <div class="boxed-section">                        
                                    <div class="row">                        
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kartref' | translate}}</mat-label>
                                                <input matInput inputmode="none" formControlName="CDART" (click)="newArticleCdart.select()" (keyup.enter)="searchArticleRapid($event)" class="text-uppercase col-8" #newArticleCdart />
                                                <button matSuffix type="button" (click)="searchArticleRapid($event)" mat-icon-button class="inlineblock" tabindex="-1" *ngIf="isMobile">
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <button matSuffix class="ml-2" type="button" *ngIf="isMobile" (click)="openScanArticle($event)" tabindex="-1" mat-icon-button>
                                                    <i class="fa fa-barcode" aria-hidden="true"></i>
                                                </button>
                                                <button matSuffix type="button" (click)="openUpSearchArticle($event)" mat-icon-button class="inlineblock" tabindex="-1" *ngIf="!isMobile">
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <button matSuffix type="button" class="ml-2 inlineblock" mat-icon-button (click)="openAdvancedSearch()" tabindex="-1" *ngIf="isMobile">
                                                    <mat-icon class="bt_logout bt_cursor text-white" [title]="'kclsearc' | translate">more_vert</mat-icon>
                                                </button>
                                            </mat-form-field>   
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbondesgn' | translate}}</mat-label>
                                                <input matInput formControlName="CALIB1" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <mat-form-field class="display-large-label" floatLabel="always">
                                                <mat-label>
                                                    {{'kboninstock' | translate}}
                                                    <span *ngIf="currentArticleStockMag"> - {{ currentArticleStockMagNom }}</span>
                                                </mat-label>
                                                <input matInput formControlName="STOCKDISPO" placeholder="">
            
                                                <h4 matSuffix class="text-primary text-right animated flash mr-3" *ngIf="commander">{{ ((currentArticle && currentArticle.etatart == 'G')?((currentArticle && +currentArticle.STOCKDISPO <= 0)?('ksanssuite' | translate):('ksanssuite' | translate)): ('kboncommande' | translate))}}</h4>
                                                <button type="button" mat-icon-button matSuffix (click)="openUpDetailStock()" tabindex="-1" [class.d-none]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')" [disabled]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')">
                                                    <mat-icon>edit</mat-icon>
                                                </button>                                
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pr-1">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kartqqty' | translate}}</mat-label>
                                                <input [imask]="quantityMask" [unmask]="true" inputmode="none" matInput #newArticleQty formControlName="QTE" (click)="onQuantityFocused()" (change)="onQTYChanged($event)" (keyup)="onQTYChanged($event)" (keydown)="incDecByArrows($event)" (click)="newArticleQty.select()" (keydown.tab)="_focusPUvente();false;" (keydown.enter)="_focusPUvente()" class="text-uppercase" />

                                                <button type="button" mat-icon-button matSuffix class="ml-2" (click)="quantityChange('decrement')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                                <button type="button" mat-icon-button matSuffix (click)="quantityChange('increment')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pl-1">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kbonpuven' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" type="text" inputmode="decimal" matInput #prixVente formControlName="NEW_PRIX" (change)="onPriceChanged($event)" (keydown)="onPriceChanged($event)" (keyup)="onPriceChanged($event)" (focus)="prixVente.select()" (click)="prixVente.select()" (keydown.tab)="_triggerSubmitNewItem();false;" (keydown.enter)="_triggerSubmitNewItem()" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pr-1">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpucais' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="CAPRIX" class="text-uppercase"/>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pl-1">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpvtot' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="ROW_TOTAL" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-9 col-lg-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonremise' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="MOTANT" />
        
                                                <button type="button" mat-icon-button matSuffix [disabled]="!currentArticle" (click)="openUpRemiseList(1, currentArticleCAPrix.value, true)">
                                                    %
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div class="clearfix"></div>
        
                                        <div class="col-md-7"></div>
                                        <div class="col-md-5 text-right">
                                            <button class="ml-2 addbut" type="submit" mat-button><mat-icon>check</mat-icon></button>
                                        </div>
                                    </div>                        
                                </div>  
                            </form>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true" id="scr_detail" [expanded]="step === 1" (afterExpand)="__scrollToTab('scr_detail')" (opened)="setStep(1)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kboncommanddet' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <div class="row no-gutters">
                                <div class="col-12">
                                    <div class="d-flex justify-content-center" >
                                        <button type="button" class="btn-block w-auto"
                                                *ngIf="!readOnly && changeStoreStatus && (!(ticketsArtZeroTotal == null) && (ticketsArtOthersTotal == null || ticketsArtOthersTotal == 0)) && (previousPanier !== undefined && previousPanier.statut != 7 && previousPanier.statut != 17 && previousPanier.statut != 27 && previousPanier.preparation != true) && !currentArticleStockMag"
                                                (click)="changeCommandeStore()" mat-button>
                                            <i class="material-icons">store</i>
                                            <span>{{'kchangestore' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn-block w-auto" (click)="validateRemiseEnE2()" mat-button  *ngIf="!readOnly && remiseEnE2">
                                            <i class="material-icons">account_balance_wallet</i>
                                            {{'kboncomremiseene2' | translate}}
                                        </button>                   
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex justify-content-center">
                                        <button type="button" class="btn-block w-auto" (click)="modifierTypeConfirmation()" mat-button   *ngIf="!readOnly && (previousPanier !== undefined && previousPanier.statut != 7 && previousPanier.statut != 17 && previousPanier.statut != 27 && previousPanier.preparation != true)">
                                            <i class="material-icons">cached</i>    
                                            {{'kboncommodifytypecmd' | translate}}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <div style="border: 1px solid #d8d8d8;overflow-y: scroll;" class="bg-white">
                                        <mat-list *ngIf="(listBonCommandeItems?.length == 0 || showLoader)">
                                            <mat-list-item *ngIf="listBonCommandeItems?.length == 0 && !showLoader">                        
                                                <p matLine class="text-center">
                                                    {{ "kpnoresult" | translate  }}
                                                </p>
                                            </mat-list-item>
                        
                                            <mat-list-item *ngIf="showLoader">                        
                                                <p matLine class="text-center">
                                                    <mat-icon class="synching">sync</mat-icon>
                                                </p>
                                            </mat-list-item>
                                        </mat-list>
                        
                                        <mat-list *ngIf="(listBonCommandeItems?.length != 0 && !showLoader)">
                                            <mat-list-item  *ngFor="let item of listBonCommandeItems;let i=index;"
                                                            [id]="'swipeItem-' + i"
                                                            style="user-select: none;"
                                                            (swipe)="onSwipe($event, i, item)"
                                                            [class.active]="activeLineItem === i"
                                                            [class.status_17]="item.statut == 17"
                                            >
                                                <div class="swipedContent">
                                                    <button type="button" mat-mini-fab color="primary" class="mr-1" (click)="moveToInfoTab(i)">
                                                        <span>i</span>
                                                    </button>
                                                    <button type="button" *ngIf="item.opt <= 0 && !readOnly" mat-mini-fab color="warn" class="" (click)="deleteCommandeConfirmation(item)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                                
                                                <div class="w-100 d-flex list-item-main-content" (click)="setActiveItem(i);loadEditArticleItem(item,$event)">
                                                    <img matListAvatar [src]="getImageURL(item.cdart)" default="assets/images/no_img.png" [alt]="item.cdart">                                                

                                                    <div class="mat-list-text">
                                                        <div matLine class="align-items-start d-flex flex-row justify-content-between solde_state">
                                                            <h2 class="m-0" (click)="loadArticleDetail(item,$event)">                                                        
                                                                <span class="linktxt">{{ item.cdart }} </span>
                                                                <mat-icon *ngIf="item.statut != 7 && item.statut != 8">block</mat-icon>
                                                                <mat-icon *ngIf="item.statut == 7 || item.statut == 8">check_circle</mat-icon>
                                                                <span class="colorcode" [class.solde_yellow]="item.statut == 2 || item.statut == 9" 
                                                                [class.solde_green]="item.statut >= 3 && item.statut <= 6"
                                                                [class.solde_aqua]="item.statut == 7 || item.statut == 8"
                                                                [class.solde_black]="item.statut == 17"
                                                                [class.solde_partiel]="item.statut == 27"></span>                                                        
                                                            </h2>
                                                            <div class="d-flex flex-row align-items-center justify-content-around">
                                                                <span class="badge badge-danger mr-1" *ngIf="item.coinsolde">{{'kboncoin' | translate}}</span>
                                                                <span class="badge badge-danger" *ngIf="item.expo">{{'kbonexpo' | translate}}</span>
                                                            </div>
                                                        </div>
            
                                                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                            <p class="mobile-list-title mb-0">
                                                                <span class="linktxt" (click)="loadArticleDetail(item,$event)"> {{ item.lib }}</span>
        
                                                                <br *ngIf="item.opt < 0 || item.opt > 0">
                                                                <span class="badge badge-info" *ngIf="item.opt < 0">{{ 'kkopts' | translate }}</span>
                                                                <span class="badge badge-success" *ngIf="item.opt > 0">{{ 'kkopt' | translate }}</span>
                                                            </p>
                                                            <span>{{ item.typecde }}</span>
                                                            <mat-checkbox [(ngModel)]="checkedLineItems[item.idlig]" [class]="(!readOnly && (getTotalMainArticleItems > 1 && previousPanier.typecde != 'EI') && (previousPanier !== undefined && previousPanier.statut != 7 && previousPanier.statut != 17 && previousPanier.statut != 27 && previousPanier.preparation != true) && item.opt <= 0 && !currentArticleStockMag)?'visible':'invisible'" (ngModelChange)="itemChosenChange($event, item)" class="ml-2"></mat-checkbox>
                                                        </div>
            
                                                        <p matLine class="mt-1 align-items-center d-flex flex-row justify-content-end">
                                                            <span *ngIf="item.prixforce" class="badge badge-danger mr-3">P</span>
            
                                                            <span class="badge badge-info ml-1">
                                                                {{ item.quantite | number: '0.2' }}
                                                            </span>
                                                            <span class="badge badge-secondary ml-1">
                                                                {{ item.montant | cap_currency:null:false }}
                                                            </span>
                                                            <span class="badge badge-danger ml-1" *ngIf="item.remise != 0">
                                                                {{ item.remise | number: '0.2' }} %
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                        </mat-list>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters">
                                        <div class="col-12">
                                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                                <h4>{{'kbontotHT' | translate}}</h4>
                                                <h4>{{ (totalHT?totalHT:0) | cap_currency:null:false}}</h4>
                                            </div>
                    
                                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                                <h4>{{'kbontotTVA' | translate}}</h4>
                                                <h4>{{ (totalTVA?totalTVA:0) | cap_currency:null:false}}</h4>
                                            </div>
                    
                                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                                <h4>{{'kbontotTTC' | translate}}</h4>
                                                <h4>{{ (totalTTC?totalTTC:0) | cap_currency:null:false}}</h4>
                                            </div>
                                        </div>                                        
                                        <div class="row">
                                            <div class="col-md-3 mt-3">
                                                <mat-form-field floatLabel="always">
                                                    <mat-label>{{'kbonremtot' | translate}} </mat-label>
                                                    <input matInput [value]="((totalDiscountPercentage?totalDiscountPercentage:0) | number: '0.2')+'%'" disabled>

                                                    <button type="button" mat-icon-button matSuffix (click)="openUpAddedRemiseList()">
                                                        i
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </div>     
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-form-field>
                                                    <mat-label>{{'kboncomment' | translate}}</mat-label>                                                    
                                                    <input matInput [(ngModel)]="bonVenteComments" (keyup)="onFocusCommentaire()" [disabled]="readOnly" class="text-uppercase"/>
                                                    <div matSuffix><app-inline-spinner [loading]="updateCommentInProgress"></app-inline-spinner></div>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="mt-3 d-flex align-items-center justify-content-between">
                                                    <h1 class="font-weight-bold">{{'kbontotTTC' | translate}}</h1>
                                                    <h1 class="animated total_ttc text-right w-50 ml-3" [class.blue]="totalTTCNC === undefined || totalTTCNC == 0" >{{ (totalTTCNC?totalTTCNC:0) | cap_currency:null:false }}</h1>
                                                </div>
                                            </div>
                                        </div>                       
                                       
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mobile" id="scr_client" [expanded]="step === 2" (afterExpand)="__scrollToTab('scr_client')" (opened)="setStep(2)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kbonclient' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-client-bonde [readOnly]="readOnly || restrictClientModifications" [emailMandatory]="emailMandatory" [modifiableStat]="clientLivraisonModifiable" flowType="bonde_commande" [panier]="previousPanier" [listMagasins]="listMagasins" (panierChange)="panierUpdate($event)" [(client)]="chosenClient" [(chosenFidelite)]="chosenFidelite"></app-client-bonde>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mobile" id="scr_fidelite" (afterExpand)="__scrollToTab('scr_fidelite')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kbonloyalty' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-fidelite-bonde [readOnly]="readOnly || restrictClientModifications" flowType="bonde_commande" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(bonNumBon)]="bonNumBon" [(client)]="chosenClient" [(fidelite)]="chosenFidelite"></app-fidelite-bonde>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mobile" id="scr_livraison" (afterExpand)="__scrollToTab('scr_livraison')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kboninfoliv' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-info-livraison-bonde [readOnly]="readOnly || restrictClientModifications" [chosenArticle]="article" [modifiableStat]="clientLivraisonModifiable" flowType="bonde_commande" [panier]="previousPanier" [listMagasins]="listMagasins" (livraisonTrackUpdated)="onLivraisonTrackingChanged($event)" (panierChange)="panierUpdate($event)" [magComplementData]="magComplementData" [(transporteurLabel)]="transporteurLabel" [(livreDetail)]="livraisonData" [prixLivraison]="prixLivraison" [(sameAsClient)]="sameAsClient" [(livreClient)]="chosenLivraisonClient" [(client)]="chosenClient"></app-info-livraison-bonde>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mobile" id="scr_facturation" (afterExpand)="__scrollToTab('scr_facturation')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kboninfofac' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-info-facturation-bonde [readOnly]="readOnly || restrictClientModifications" flowType="bonde_commande" [panier]="previousPanier" [listMagasins]="listMagasins" (panierChange)="panierUpdate($event)" [(facturationDetail)]="facturationData" [(sameAsClient)]="sameAsFacturationClient" [(factuClient)]="chosenFacturationClient" (clientChange)="onClientChangeFromFacturation($event)" [(client)]="chosenClient"></app-info-facturation-bonde>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="false" id="scr_alerteclient" (afterExpand)="__scrollToTab('scr_alerteclient')" (opened)="openUpAlerteClient()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kboncomalertclient' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <ng-container >
                                <app-alerte-client [readOnly]="readOnly" [alertClientData]="alertClientData"></app-alerte-client>
                            </ng-container>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="false" id="scr_relance" (afterExpand)="__scrollToTab('scr_relance')">
                        <mat-expansion-panel-header>
                            <mat-panel-title> 
                                {{'kbonrelance' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                                <div class="card">
                                    <div class="card-header d-flex align-items-center justify-content-between">
                                        <span>{{'kboncomdatedernierrelance' | translate}}</span>
                        
                                        <button type="button" class="m-0" (click)="openUpRelance()" *ngIf="!readOnly && [3,4,5,6].indexOf(previousPanier.statut) > -1" mat-icon-button>
                                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i> 
                                        </button>
                                    </div>
                                    
                                    <div class="card-body">
                                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                                            <span class="mr-3">{{'kbondate' | translate}} </span> 
                                            <span class="detail-holder text-left" style="flex: 1">{{ bonRelanceDate?bonRelanceDate:'&nbsp;' }}</span>
                                        </h4>                
                                    </div>                    
                                </div>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel [expanded]="false" id="scr_tickets" (afterExpand)="__scrollToTab('scr_tickets')" (opened)="openUpTickets()">
                        <mat-expansion-panel-header>
                            <mat-panel-title> 
                                {{'kboncomtickets' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <ng-container >
                                <app-tickets [ticketData]="ticketData"></app-tickets>
                            </ng-container>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mobile" id="scr_complement" [expanded]="step === 6" (afterExpand)="__scrollToTab('scr_complement')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kboninfocom' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-info-complementaries [readOnly]="readOnly" flowType="bonde_commande" [(article)]="article" [bonNumBon]="bonNumBon" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(activeItemNumber)]="activeLineItem" [wefoxAvail]="activeItemWefoxStat" [userName]="currentUserName"></app-info-complementaries>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mobile" id="scr_commande_liees" *ngIf="pageType === 'commande'" (afterExpand)="__scrollToTab('scr_commande_liees')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kcommandeliees' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-commande-liees [readOnly]="readOnly" [flowType]="flowType" [bonNumBon]="bonNumBon" [panier]="previousPanier" [userName]="currentUserName"></app-commande-liees>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="false" id="scr_history" (afterExpand)="__scrollToTab('scr_history')" (opened)="viewBonCommandeHistory()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kbonhistoire' | translate}}
                            </mat-panel-title>      
                            <div class="note-badge" *ngIf="totalNotes && totalNotes > 0">
                                <span class="note-badge-text">{{ totalNotes }} {{(totalNotes > 1?'kknotes':'kknote') | translate}}</span>
                            </div>          
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <ng-container *ngIf="historiqueData">
                                <app-historique-bonde [historiqueData]="historiqueData"></app-historique-bonde>
                            </ng-container>
                        </ng-template>
                    </mat-expansion-panel>                    
                </mat-accordion>
            </div>
        </div>

        <div class="row bg_white brtop">
            <div class="row mt-3">       
                <div class="col-5 d-flex flex-row justify-content-start">
                    <div class="validatebonde mr-2" *ngIf="viewDevisDetail">
                        <button type="button" class="btn-block" (click)="viewSourceDevis()" mat-button>
                            <i class="material-icons">request_quote</i>
                        </button>
                    </div>
                    <div class="voir_retrait" *ngIf="viewRetraitMarchandiseDetail">
                        <button type="button" class="btn-block" (click)="redirectModificationRetraitMarchandise()" mat-button>
                            <i class="material-icons">shopping_cart_checkout</i>
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-end col-7">
                    <button type="button" class="bt_valid" (click)="reactivateConfirmation()" *ngIf="(pageType === 'historique' || pageType === 'supprimees') && readOnly && (userPermission !== undefined && userPermission !== null && userPermission['32_16']) && userAllowedToModifyOrRemove" mat-button>
                        <mat-icon>model_training</mat-icon>
                    </button>
                    <button type="button" class="btn-valid text-left ml-2" (click)="imprimerConfirmCheckboxPopup()" *ngIf="!readOnly" mat-button>
                        <i class="material-icons">print</i>                        
                    </button>
                    <button type="submit" class="bt_valid ml-2" *ngIf="!readOnly" (click)="submitBonDeCommande()" mat-button>                                            
                        <i class="material-icons">done</i>
                    </button>
                    <button type="button" class="bt_valid ml-2" (click)="onCloseBonDeCommande()" mat-button>
                        <mat-icon>{{ (!readOnly && previousPanier !== undefined && previousPanier !== null && previousPanier.preparation !== true)?'delete':'close'}}</mat-icon>
                    </button>                    
                </div>                
            </div>
        </div> 
    </div>
</div>
