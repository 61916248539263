export const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD.MM.YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const CUSTOM_DATETIME_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY HH:mm:ss',
    },
    display: {
        dateInput: 'DD.MM.YYYY HH:mm:ss',
        monthYearLabel: 'MM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};
