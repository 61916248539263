<form [formGroup]="editItemForm" (ngSubmit)="editItemFormSubmitter()">
    <div class="row">
        <div class="col-6 col-md-6 col-lg-6 text-left pr-1">
            <mat-form-field floatLabel="always" class="pr-1">
                <mat-label>{{'kbondermodi' | translate}} </mat-label>                                    
                <input matInput [value]="articleModifiedUserName" disabled>
            </mat-form-field>
        </div>
        <div class="col-6 col-md-6 col-lg-6 text-left pl-1">
            <mat-form-field floatLabel="always">
                <mat-label>{{'kbonle' | translate}} </mat-label>                                    
                <input matInput [value]="articleModifiedDate" disabled>
            </mat-form-field>
        </div>
        
        <div class="col-12 col-md-12 col-lg-12">
            <mat-form-field floatLabel="always">
                <mat-label>{{'kartref' | translate}}</mat-label>
                <input matInput formControlName="CDART"                       
                       class="text-uppercase">
                       
                <h4 matSuffix class="text-primary text-right animated flash mr-3" *ngIf="flowType === 'bonde_commande' && commander && (articleData && articleData.etatart == 'G')">{{ ((articleData && +articleData.STOCKDISPO <= 0)?('ksanssuite' | translate):('ksanssuite' | translate)) }}</h4>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-12 col-lg-12">
            <mat-form-field floatLabel="always">
                <mat-label>{{'kbondesgn' | translate}}</mat-label>
                <input matInput
                       formControlName="CALIB"                       
                       class="text-uppercase">                
            </mat-form-field>
        </div>
        <div class="col-12 col-md-12 col-lg-12" *ngIf="flowType !== 'bonde_commande'">
            <mat-form-field class="display-large-label">
                <mat-label>
                    {{ 'kartdisp' | translate }}
                    <span *ngIf="currentArticleStockMag"> - {{ currentArticleStockMagNom }}</span>
                </mat-label>
                <input matInput formControlName="STOCK_DISPO">

                <h4 matSuffix class="text-primary text-right animated flash mr-3" *ngIf="commander">{{ ((articleData && articleData.etatart == 'G')?((articleData && +articleData.STOCKDISPO <= 0)?('ksanssuite' | translate):('ksanssuite' | translate)): ('kboncommande' | translate))}}</h4>
                <button matSuffix type="button" (click)="openUpDetailStock()" [class.d-none]="!articleData || articleData === null || (articleData && articleData.CAGESTOCK == '0') || (articleData && articleData.CAOKLIV == '-1')" [disabled]="!articleData || articleData === null || (articleData && articleData.CAGESTOCK == '0') || (articleData && articleData.CAOKLIV == '-1')" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
            </mat-form-field>
        </div>  
        
        <div class="col-6 col-md-6 col-lg-6 pr-1">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{ 'kartqqty' | translate }}</mat-label>
                <input inputmode="none" [imask]="quantityMask" #articleQty [unmask]="true" matInput formControlName="QTY"
                       (click)="onQuantityFocused()"
                       (change)="onQTYChanged($event)" (keyup)="onQTYChanged($event)"
                       class="text-uppercase">   

                <button type="button" mat-icon-button class="ml-2" matSuffix (click)="quantityChange('decrement')" *ngIf="!quantityDisabled && !readOnly" [disabled]="!(currentItem?.opt <= 0)" tabindex="-1">
                    <mat-icon>remove</mat-icon>
                </button>
                <button type="button" mat-icon-button matSuffix (click)="quantityChange('increment')" *ngIf="!quantityDisabled && !readOnly" [disabled]="!(currentItem?.opt <= 0)" tabindex="-1">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-6 col-md-6 col-lg-6 pl-1">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'kbonpuven' | translate}}</mat-label>
                <input type="text" inputmode="decimal" [imask]="maskOptions" [unmask]="true" matInput #prixVente min="1" formControlName="PRIX_VENTE"                       
                       (change)="onPriceChanged($event)" (keydown)="onPriceChanged($event)" (keyup)="onPriceChanged($event)"
                       (focus)="prixVente.select()"
                       class="text-uppercase">                
            </mat-form-field>
        </div>

        <div class="col-6 col-md-6 col-lg-6 pr-1" [class]="flowType === 'bonde_commande'?'col-md-6 col-lg-6':'col-md-6 col-lg-6'">
            <mat-form-field floatLabel="always">
                <mat-label>{{'kbonpucais' | translate}}</mat-label>
                <input [imask]="maskOptions" [unmask]="'typed'" matInput formControlName="PRIX"
                       class="text-uppercase">                
            </mat-form-field>
        </div>
        <div class="col-6 col-md-6 col-lg-6 pl-1">
            <mat-form-field floatLabel="always">
                <mat-label>{{'kbonpvtot' | translate}}</mat-label>
                <input [imask]="maskOptions" [unmask]="'typed'" matInput formControlName="PRIX_TOTAL"
                       class="text-uppercase">                
            </mat-form-field>
        </div>

        <div class="col-12 col-md-12 col-lg-12" *ngIf="currentItem?.opt <= 0 && (flowType !== 'bonde_commande')">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'kbontypedevente' | translate}}</mat-label>
                <mat-select formControlName="TYPECDE" disableOptionCentering panelClass="dropdown-panel">                                            
                    <mat-optgroup *ngIf="typeVenteRules" [label]="typeVenteRules.title | translate">
                        <mat-option *ngFor="let item of typeVenteRules.data;let i=index;" [value]="item.value" [selected]="(i == 0)?true:false" [disabled]="item.disabled != undefined && item.disabled">{{item.label | translate}}</mat-option>                                                
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-12 col-lg-12">
            <mat-form-field floatLabel="always">
                <mat-label>{{'kbonremise' | translate}}</mat-label>
                <input matInput [imask]="maskOptions" [unmask]="true" formControlName="REMTOT" class="text-uppercase">
                <button type="button" mat-icon-button matSuffix (click)="openUpRemiseList(1, itemPrix.value)" [disabled]="flowType === 'bonde_commande'">%</button>
                <button type="button" mat-icon-button class="ml-2" matSuffix (click)="openUpAddedRemiseList(1, 0, currentItem)">i</button>
            </mat-form-field>
        </div>

        <div class="col-6 col-md-6 col-lg-6 text-left" *ngIf="(flowType !== 'bonde_commande' && flowType !== 'devis') && !articleLivraison">
            <mat-checkbox formControlName="EXPO"> {{'kbonexposure' | translate}}</mat-checkbox>
        </div>

        <div class="col-6 col-md-6 col-lg-6 text-left" *ngIf="(flowType !== 'bonde_commande' && flowType !== 'devis') && !articleLivraison">
            <mat-checkbox formControlName="COIN_SOLDE"> {{'kboncoinsolde' | translate}}</mat-checkbox>
        </div>

        <div class="col-12 col-md-12 col-lg-12 text-left" *ngIf="(flowType !== 'bonde_commande' && flowType !== 'devis')">
            <mat-checkbox formControlName="EXCLUSION_COM"> {{'kexclusioncommercial' | translate}}</mat-checkbox>
        </div>

        <div class="col-12 col-md-12 col-lg-12">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'kbondesc1' | translate}}</mat-label>
                <input matInput formControlName="INFO1">
            </mat-form-field>

            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'kbondesc2' | translate}}</mat-label>
                <input matInput formControlName="INFO2">
            </mat-form-field>
        </div>
    </div>

    <div class="bloc_container m-0 w-100">
        <div class="row bg_white">
            <div class="col-6 col-md-7 text-left">
                <button type="button" class="bt_valid" mat-button *ngIf="!readOnly && (currentItem?.opt <= 0 || linkedStatus !== 'bundle')" (click)="supprimerLineItem()" [title]="'kbonsupprimer' | translate">
                    <i class="material-icons">delete</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kbonsupprimer' | translate}}
                    </span>                     
                </button>
                <button type="button" class="bt_valid ml-2" [class.disabled]="currentItem?.opt > 0" mat-button *ngIf="(!readOnly && currentItem?.opt >= 0) && !isWefoxArticle && isServiceArticle && linkedStatus !== 'bundle'" [disabled]="currentItem?.opt > 0" (click)="openUpNonServiceArticleSelection()" [title]="'klier_un_article' | translate">
                    <i class="material-icons">link</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'klier_un_article' | translate}}
                    </span>
                </button>
            </div>

            <div class="col-6 col-md-5 text-right">
                <button type="submit" class="bt_valid" mat-button *ngIf="!readOnly" [title]="'kuok' | translate">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'kuok' | translate }}
                    </span>
                </button>
                <button class="bt_valid ml-2" type="button" mat-button mat-dialog-close [title]="'kucancel' | translate">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'kucancel' | translate }}
                    </span>                    
                </button>
            </div>                
        </div>
    </div>
</form>
