<div class="modal-container" id="prix_force_article">
    <form [formGroup]="prixForceForm" (ngSubmit)="prixForceFormSubmitter()">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">{{'kbonmotifprix' | translate}}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row" style="max-height: 300px;overflow-x: auto;overflow-y: scroll;">
                <div class="col-md-12">
                    <mat-radio-group
                        formControlName="force_motif"
                        aria-labelledby="forcage-label"
                        class="forcage-radio-group"
                        (change)="onMotifChange($event)"
                    >
                        <mat-radio-button class="forcage-radio-button" *ngFor="let rule of force_prix_rules" [value]="rule.value">
                            {{rule.label}}
                        </mat-radio-button>                        
                    </mat-radio-group>
                </div>
            </div>            
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button type="submit" class="bt_send">
                <i class="material-icons">done</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kuok' | translate}}
                </span>
            </button>
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
    </form>
</div>
