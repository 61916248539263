import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Constants } from 'providers/constants';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { BonVenteService } from 'providers/api/bonventeService';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Article } from 'models/articles';
import { Client } from 'models/clients';
import * as moment from 'moment';
import { ArticleStockComponent } from 'pages/article-detail/article-stock/article-stock.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import BonPanier, { PanierArticle, PanierClient } from 'models/bonPanier';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { MagComplementService } from 'providers/api/magComplementService';
import MagComplement from 'models/magComplement';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { LoginService } from 'providers/api/loginService';
import { Magasin } from 'models/magasins';
import { ArticleDetailComponent } from 'pages/article-detail/article-detail.component';
import { ArticleFilterComponent } from 'pages/article/article-filter/article-filter.component';
import { ParamDetail } from 'models/paramDetail';
import { RemiseListComponent } from 'pages/create-bon-de-vente/remise-list/remise-list.component';
import { RemiseDetailComponent } from 'pages/create-bon-de-vente/remise-detail/remise-detail.component';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import { ModifyArticleDetailComponent } from 'pages/create-bon-de-vente/info-complementaries/modify-article-detail/modify-article-detail.component';
import { MotifForcePrixComponent } from 'pages/create-bon-de-vente/motif-force-prix/motif-force-prix.component';
import { SearchArticleComponent } from 'pages/create-bon-de-vente/search-article/search-article.component';
import { VenteArticleGroupsComponent } from 'pages/create-bon-de-vente/vente-article-groups/vente-article-groups.component';
import { DevisService } from 'providers/api/devisService';
import { UtilService } from 'providers/api/utilService';
import { User } from 'models/users';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { CalculateQuantityComponent } from 'pages/create-bon-de-vente/calculate-quantity/calculate-quantity.component';
import { CapDecimalPipe } from 'providers/customNumber-pipe';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { PdfViewerComponent } from 'pages/pdf-viewer/pdf-viewer.component';
import { ImprimerConfirmationComponent } from 'pages/imprimer-confirmation/imprimer-confirmation.component';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import {
  AUTHORIZE_CHANGEMENT_DE_PRIX,
  AUTHORIZE_REMISE_EN_LIGNE,
  AUTHORIZE_REMISE_EN_PIED,
  AUTHORIZE_VALIDATION_BON_DE_COMMANDE,
  HistoryService
} from '../../providers/api/historyService';
import { NonServiceArticlesListComponent } from 'pages/create-bon-de-vente/non-service-articles-list/non-service-articles-list.component';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-create-devis',
  templateUrl: './create-devis.component.html',
  styleUrls: ['./create-devis.component.scss'],
  providers: [BonVenteService, DevisService, UtilService, {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
      {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
      {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}]
})
export class CreateDevisComponent implements OnInit {

  @ViewChild('newArticleCdart', { static: false }) cdartInput: ElementRef;
  @ViewChild('prixVente', {static: false}) newPrixVente: ElementRef;
  @ViewChild('newArticleQty', {static: false}) newArticleQty: ElementRef;
  @ViewChild('typeCde', {static: false}) newTypeCde: MatSelect;
  @ViewChild('newItemForm', {static: false}) newArticleFormEl: NgForm;

  maskOptions = {
    mask: Number,
    scale: 2,    
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  qtyMaskOptions = {
    mask: Number,
    scale: 2,
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  user: UserModel;
  USERCMINT: any;

  addBonDeVenteForm: UntypedFormGroup;
  newArticleForm: UntypedFormGroup;
  currentArticle: Article;
  articleGroupForm: any[];

  listBonVenteItems: PanierArticle[] = [];
  listBonVenteArticles: Article[] = [];

  showLoader = false;
  bonvente: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  article: Article;

  chosenClient: Client;

  chosenFidelite: Client;

  focusClientSearch: boolean = false;

  bonVenteLineItems: Article[];
  activeLineItem: number = 0;
  itemDoubleClicked: any;
  itemClickedCount = 0;

  bonNumBon: string = '*************';
  bonDuDate: string;
  previousPanier: BonPanier;
  previousPanierClient: Client;

  previousFIDClient: Client;

  currentUserName: string;

  totalHT: number;
  totalTVA: number;
  totalTTC: number;
  totalDiscount: number;
  totalDiscountPercentage: string;
  totalPrixCaisse: number;

  chosenRemise: any[];
  chosenLineRemise: any[];

  formType: 'saise' | 'modification' = 'saise';
  commander: boolean = false;
  typeVenteRules: {title: string, data: { label: string, value: string, disabled?: boolean }[]};

  devisComments: string = '';
  magComplementData: MagComplement;

  isMobile: boolean;

  listMagasins: Magasin[];

  devisSelectedTab: number;
  advancedDialogRef: any;

  autreMag: boolean = false;
  flowCMINT: any;
  flowType: string = 'devis';
  showOnly: string = 'devisValidate';
  // mat-expansion-panel   
  step = 0;

  // Article d'options
  listArticleGroups: any[];
  articleGroupsRawData: any[];
  currentArticleChosenGroups: any;

  paramsList: ParamDetail[];

  userPermission: any;

  PU_vente_changable: boolean = false;
  authorizedLinePrixUser: User;
  authorizedValidationVenteUser: User;
  authorizedTotalRemiseUser: User;
  authorizedLineRemiseUser: User;
  
  qtyLength: number = 0;
  qtyWidth: number = 0;
  qtyHeight: number = 0;

  codeClient: any;

  bonApiSubscription: Subscription;
  quantity5Decimal: boolean = false;
  quantityDisabled = false;

  validationButtonAccess: boolean = false;

  emailParamData: string;
  emailMandatory = false;

  linkedStatus: 'service' | 'bundle' | 'no_link' = 'no_link';
  isWefoxArticle: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public snackBar: MatSnackBar,
              private formBuilder: UntypedFormBuilder,                            
              private userService: UserService,
              private bonVenteService: BonVenteService,
              private devisService: DevisService,
              private loginService: LoginService,
              private magComplementService: MagComplementService,
              private loadingService: NgxSpinnerService,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private utilService: UtilService,
              private dialog: MatDialog,
              private currencyPipe: CapCurrencyPipe,
              private decimalPipe: CapDecimalPipe,
              private deviceDetector: DeviceDetectorService,
              private historyService: HistoryService,
              private storageService: StorageService) {
 }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
    this.flowCMINT = this.USERCMINT;
    this.userService.headerSearchButton.next(false);

    this.currentUserName = this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':'';

    this.addBonDeVenteForm = this.formBuilder.group({
      CMINT: ['', Validators.required],        
      IDSOC: [Constants.IDSOC, Validators.required],
    });

    this._getCodeClient();

    this.newArticleForm = this.formBuilder.group({
      CDART: ['', [Validators.required]],
      CALIB1: [{ value: '', disabled: true }, Validators.required],
      QTE: [{ value: '1', disabled: true }, [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      CAPRIX: [{ value: '', disabled: true }, Validators.required],
      NEW_PRIX: [{ value: '0', disabled: true }, [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      ROW_TOTAL: [{ value: '', disabled: true }, Validators.required],
      TYPECDE: [{ value: 'EI', disabled: true }, Validators.required],
      MOTANT: [{ value: '0', disabled: true }, Validators.required],
      STOCKDISPO: [{ value: '', disabled: true }],

      PRIX_FORCE: [false],
      MOTIF_PRIX: [''],
      MOTIF_PRIX_LBL: [''],

      GROUP_AVAIL: [false]
    });
    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.addBonDeVenteForm.patchValue({ CMINT: this.USERCMINT });

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }       
      },      
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };      

    this.loadDependencies();
  }

  ngAfterViewInit() {
  }

  async _getCodeClient() {
    try {
      this.codeClient = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  ngOnDestroy() {
    this.__clearPageVars();

    if(this.bonApiSubscription) {
      this.bonApiSubscription.unsubscribe();
    }
  }

  async loadDependencies() {
    this.loadingService.show();

    this.formType = 'saise';    
    
    await this.getListParams();
    await this.getQuantityDecimalStatus();

    this.emailParamData = await this.userService.getRuleParamsData(20, 54, 'VSTR1');
    
    const routeParams = this.route.snapshot.queryParams;
    const numBon = routeParams['numbon'];

    if(numBon !== undefined && numBon != null && numBon !== '') {
      this.bonNumBon = numBon;
      this.getDevisDataByNumBon(this.bonNumBon);
    }

    this.getMagasins();
    this.getMagComplementData();

    this.loadingService.hide();

    this.PU_vente_changable = true;
    if(!this.userPermission['0_17']) {
      this.PU_vente_changable = false;
    }

    this.validationButtonAccess = true;
    if(!this.userPermission['24_1']) {
      this.validationButtonAccess = false;
    }

    this.newArticleForm.get('CDART').valueChanges.subscribe(
      (value) => {
        if(value === '' || value === null) {
          this.currentArticle = undefined;
          this.chosenLineRemise = [];
          this.newArticleForm.patchValue({
            CALIB1: '',            
            CAPRIX: null,
            QTE: this.utilService.formatQteMaskCompat(1),
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: null,
            TYPECDE: 'EI',
            STOCKDISPO: '',
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.PU_vente_changable = true;
          if(!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
          this.commander = false;
        }
      }
    );
  }

  get quantityMask() {
    return this.qtyMaskOptions;
  }

  get viewBonVenteDetail() {
    return this.userPermission['4_0'];
  }

  get validateBtnStat() {
    return this.previousPanier && ((this.previousPanier.statut != 2 && this.validationButtonAccess) || (this.previousPanier.statut == 2 && this.viewBonVenteDetail));
  }

  _checkEmailMandatory() {
    this.emailMandatory = false;
    if (this.emailParamData) {
      const splitUp = this.emailParamData.split(';');
      this.emailMandatory = this.previousPanier.articles.some(r => splitUp.indexOf(r.typecde) > -1);
    }
  }

  async getQuantityDecimalStatus() {
    try {
      const paramData = await this.userService.getRuleParamsData(10,12, 'VBOOL1');
      this.quantity5Decimal = false;
      this.qtyMaskOptions = {
        mask: Number,
        scale: 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ["."]
      };
      if(paramData !== undefined && paramData != null && paramData == '-1') {
          this.quantity5Decimal = true;
          this.qtyMaskOptions = {
            mask: Number,
            scale: 5,
            padFractionalZeros: true,
            radix: this.utilService.getCurrentDecimalSymbol,
            mapToRadix: ["."]
          };
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  setStep(index: number) {
    this.step = index;

    if(index === 0) {
      setTimeout(() => {
        if(this.cdartInput) {
          this.cdartInput.nativeElement.setAttribute('inputmode', 'none'); 
          this.cdartInput.nativeElement.focus();
          this.cdartInput.nativeElement.select();
          this.cdartInput.nativeElement.setAttribute('inputmode', 'text'); 
        }
      }, 100)
    }
  }

  __scrollToTab(el: string) {
    setTimeout(() => {
      const element = document.getElementById(el);
      element.scrollIntoView({ behavior: 'smooth' })
    }, 80)
  }
  
  panierUpdate = (panier: BonPanier) => {
    if(panier !== undefined && panier !== null) {     
      this.previousPanier = panier;
      this.listBonVenteItems = this.previousPanier.articles;    
    }    
  };

  setActiveItem(i: number) {
    this.activeLineItem = i;
  }

  refreshByPanier(panier: BonPanier) {
    if(panier && panier !== null) {
      this.chosenLineRemise = undefined;
      this.chosenRemise = undefined;

      this.listBonVenteItems = panier.articles;

      this._checkEmailMandatory();

      if(panier.client !== undefined && panier.client !== null) {
        this.chosenClient = this.previousPanierClient;
      }

      if(panier.numfid !== undefined && panier.numfid !== null && panier.numfid !== '') {             
        this.chosenFidelite = this.previousFIDClient;
      }
    
      this.totalHT = panier.montantHT;
      this.totalTTC = panier.montant;
      this.totalTVA = panier.montant - panier.montantHT;
      this.totalDiscount = panier.remtot;

      this.totalPrixCaisse = panier.montant + panier.remtot;

      if(panier.datarem !== '' && panier.datarem !== null) {
        this.totalDiscountPercentage = panier.remise.toString();
      } else {
        if(panier.remise && panier.remise > 0) {
          this.totalDiscountPercentage = panier.remise.toString();
        } else {
          this.totalDiscountPercentage = '0';
        }
      }

      this.devisComments = panier.comm;
      
      this.activeLineItem = 0;
      const currentItem = panier.articles[this.activeLineItem];
      this.article = this.listBonVenteArticles.find(row => row.CDART == currentItem.cdart);      

      this.bonDuDate = moment(panier.dtsaisie).utcOffset(panier.dtsaisie).format('DD.MM.YYYY HH:mm');
    }
  }

  loadArticleItem(item: PanierArticle) {
    this.itemClickedCount++;

    setTimeout(() => {
      if(this.itemClickedCount === 1) {
        this.article = this.listBonVenteArticles.find(row => row.CDART == item.cdart);  
        this.dialog.closeAll();
        if(this.isMobile) {
          this.openUpArticleDetail(this.article);
        }
      } else if(this.itemClickedCount === 2) {
        this.dialog.closeAll();
        this.editBonVenteItem(item);
      }
      this.itemClickedCount = 0;
    }, 250);
  }

  loadArticleDetail(item: PanierArticle, event) {
    event.stopPropagation();
    this.article = this.listBonVenteArticles.find(row => row.CDART == item.cdart);  
    this.dialog.closeAll();    
    this.openUpArticleDetail(this.article);
  } 

  loadEditArticleItem(item: PanierArticle, event) {
      event.stopPropagation();
      this.dialog.closeAll();      
      this.editBonVenteItem(item);      
  }

  async editBonVenteItem(item: PanierArticle) {
    let authorizedModifyItemUser: User | undefined;

    if(this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21']) {
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '21', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      authorizedModifyItemUser = authorizationResult.data;
    }

    if(this.previousPanier == undefined || this.previousPanier == null || this.listBonVenteArticles == undefined || this.listBonVenteArticles == null || item == undefined || item == null) {
      return;
    }

    const article_data = this.listBonVenteArticles.find(
      (row) => row.CDART == item.cdart
    );

    const listNonServiceArticles = this.listBonVenteArticles.filter((row) => row.CAGESTOCK == '-1');

    const cdartArticlesHashTable = [...listNonServiceArticles].reduce((acc, it) => {
      if (!acc.hasOwnProperty(it.CDART)) {
        acc[it.CDART] = {};
      }

      acc[it.CDART] = it;
      return acc;
    }, {});

    const listNonServiceItems = this.listBonVenteItems.filter(rw => {
      return rw.opt <= 0 && (cdartArticlesHashTable[rw.cdart] && cdartArticlesHashTable[rw.cdart].CAGESTOCK == '-1');
    });

    const dialogRef = this.dialog.open(ModifyArticleDetailComponent, {
      width: '50vw',
      maxWidth: '55vw',
      height: this.isMobile?'80vh':undefined,
      autoFocus: false,
      data: {
        panier: this.previousPanier,
        bonNumBon: this.bonNumBon,
        panier_article: item,
        article_data: article_data,
        listBonVenteArticles: this.listBonVenteArticles,
        CMINT: this.USERCMINT,
        flowType: 'devis',
        authorizedModifyItemUser,
        listNonServiceArticles,
        listNonServiceItems,
        cdartArticlesHashTable,
        isDevis: true,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.bonNumBon = result.numBon;
        this.previousPanier = result.panier;
                
        if(result.customArticles !== undefined && result.customArticles != null) {
          this.listBonVenteArticles = result.customArticles;
        }
        
        this.refreshByPanier(this.previousPanier);
      }
    });    
  }

  getCdartWithPlacehold(CDART: string): string {
    return (Constants.CONFORAMA_CDART_PLACEHOLDER).replace("{{CDART_PLACEHOLDER}}", CDART);
  }

  getImageURL(CDART: string): string {
    if(this.codeClient && this.codeClient.toLowerCase() === 'conforama') {
      // To generate Conforama Image URL Algorithm
      let concatenated = "";
      const length = Constants.CONFORMA_IMAGE_SEGMENT_LENGTH;
      for(let i=0;i < length;i++) {
        if(i < (length - 1)) {
          if(concatenated !== '') {
            concatenated += "/"  
          }
          if(CDART[i] !== undefined) {
            concatenated += (CDART[i].padEnd(length - i, '0'));
          } else {
            concatenated += ('0'.padEnd(length - i, '0')); // Prefixing Zero if the CDART length is small as mentioned in CAP-1056
          }
        }
      }

      const URL = Constants.CONFORAMA_IMAGE_URL + concatenated + '/' + this.getCdartWithPlacehold(CDART) + Constants.DEFAULT_IMAGE_EXTENSION;
      return URL;
    }

    return Constants.BASE_IMAGE_URL+CDART+Constants.DEFAULT_IMAGE_EXTENSION;
  }

  checkArticleWefoxStatus(CDART: string, processedRules?: any): { wefoxStat: boolean, wefoxTypeRestriction: boolean, wefoxType: string } {
    if (processedRules === undefined || processedRules === null) {
      processedRules = this.userService.processRulesParams(this.paramsList);
    }

    const result = {
      wefoxStat: false,
      wefoxTypeRestriction: true,
      wefoxType: ''
    };

    if (processedRules['20_61']['VBOOL1'] == '-1') {
      if (CDART.toLowerCase() === processedRules['20_61']['VSTR1'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR2'] !== '' && processedRules['20_61']['VSTR2'] != null) ? processedRules['20_61']['VSTR2']:'';

        result.wefoxTypeRestriction = true;
      }

      if (CDART.toLowerCase() === processedRules['20_61']['VSTR3'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR4'] !== '' && processedRules['20_61']['VSTR4'] != null) ? processedRules['20_61']['VSTR4']:'';

        result.wefoxTypeRestriction = true;
      }
    }

    return result;
  }

  newArticleFormSubmitter() {
    if(this.currentArticle === undefined || this.currentArticle === null) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const form_data = this.newArticleForm.getRawValue();

    if(form_data.CDART === '' || form_data.CDART === null || form_data.TYPECDE === '' || form_data.TYPECDE === null || form_data.QTE === '' || form_data.QTE === null || +form_data.QTE === 0) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const checkWefox = this.checkArticleWefoxStatus(form_data.CDART.toLowerCase());
    this.isWefoxArticle = checkWefox.wefoxStat;

    if((form_data.NEW_PRIX === '' || form_data.NEW_PRIX === null || +form_data.NEW_PRIX === 0) &&
        !(checkWefox.wefoxStat) &&
        !this.userPermission['0_22']) {
      this.newPrixVente.nativeElement.select();
      this.newPrixVente.nativeElement.focus();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kboninvalid'));
      return;
    }

    const processedRules = this.userService.processRulesParams(this.paramsList);
    const maxAllowedQte = (processedRules['10_12']['VFLOAT1'] !== undefined && processedRules['10_12']['VFLOAT1'] !== null && processedRules['10_12']['VFLOAT1'] !== '') ? +processedRules['10_12']['VFLOAT1'] : 0;
    if (maxAllowedQte > 0 && +form_data.QTE > maxAllowedQte) {
      this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
      this.newArticleQty.nativeElement.select();
      this.newArticleQty.nativeElement.focus();
      setTimeout(() => {
        this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
      }, 50);

      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntytrop'), this.translateService.instant('kuok'));
      return;
    }

    if(this.newArticleForm.get('NEW_PRIX').touched && !this.newArticleForm.get('NEW_PRIX').pristine && this.newArticleForm.get('NEW_PRIX').dirty && (+form_data.CAPRIX !== +this.currentArticle.calculated_price)) {
      this.openUpPrixForceMotif();
      return;
    }
    
    this.addNewLineItem();
  }

  async addNewLineItem() {
    const form_data = this.newArticleForm.getRawValue();

    if(form_data.GROUP_AVAIL || +this.currentArticle.GROUP_COUNT > 0) {
      this.searchArticleGroupsCAINT(this.currentArticle.CAINT, form_data.TYPECDE);
      return;
    }
    
    this.loadingService.show();
    this.__newLineItemAddLogic();
  }

  async __newLineItemAddLogic(type: 'normal' | 'options' = 'normal') {
    const form_data = this.newArticleForm.getRawValue();

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
    const row_tax = (+form_data.ROW_TOTAL) * 0.021;
    
    let grand_total_amount = 0;

    let client_data: PanierClient;    

    if(this.chosenClient && this.chosenClient !== null) {
      client_data = {
        mag: +this.chosenClient.CMINT,
        civil: +this.chosenClient.IDCIV,
        nom: this.chosenClient.TRAISOC,
        prenom: this.chosenClient.TPRENOM,
        adr1: this.chosenClient.TADR1,
        adr2: this.chosenClient.TADR2,
        adr3: this.chosenClient.TADR3,
        adr4: this.chosenClient.TADR4,
        codpos: this.chosenClient.CODPOS,
        ville: this.chosenClient.TVILLE,
        pays: this.chosenClient.CODPAYS,
        tel: this.chosenClient.TTEL,
        fax: this.chosenClient.TFAX,
        gsm: this.chosenClient.TPORT,
        email: this.chosenClient.TEMAIL,
        idinstcli: +Constants.CIINST,
        idcli: +this.chosenClient.IDTIERS
      }
    }

    if(this.previousPanier !== undefined && this.previousPanier !== null && this.previousPanier.client !== undefined && this.previousPanier.client !== null) {
      client_data = this.previousPanier.client;
    }
  
    let PRIX_UPV = form_data.NEW_PRIX;
    let PRIX_PVT = form_data.NEW_PRIX * form_data.QTE;

    let lineDataRem = "";
    let lineRemTotal = 0.0;
    let lineRemPercentage = 0.0;
    let totalLineRemTotal = 0;
    let lineRemTot = 0;

    if(this.chosenLineRemise !== undefined && this.chosenLineRemise !== null) {
      if(this.chosenLineRemise.length === 0) {
        // Remise 0
        lineRemPercentage = 0.0;
        lineRemTotal = 0.0;
        lineDataRem = '';
        totalLineRemTotal = 0;
        lineRemTot = 0;
      } else {
        let remFormat: string[] = [];
        for(let j=0;j < this.chosenLineRemise.length;j++) {
          let currentLineRemPercentage = +this.chosenLineRemise[j].REMMTAUX;
          lineRemPercentage += currentLineRemPercentage;
          lineRemTotal += form_data.CAPRIX * (currentLineRemPercentage / 100);
          remFormat.push(this.chosenLineRemise[j].REMMCODE+'#'+(+currentLineRemPercentage)+'#'+this.utilService.formatMaskCompat(lineRemTotal));
        }
        lineDataRem = remFormat.join(';');

        PRIX_UPV = form_data.CAPRIX - lineRemTotal;
        PRIX_PVT = PRIX_UPV * +form_data.QTE;

        totalLineRemTotal = lineRemTotal * +form_data.QTE;
      }
    }

    let currentCdart = this.currentArticle.CDART;
    let oldPrix = parseFloat(form_data.CAPRIX.toString());
    let newPrix = form_data.NEW_PRIX.toString();

    let article_data: PanierArticle[] = [];
    let currentOpt: number = 0;
    let currentIdLigne: number = 1;

    let description = '';
    if(this.currentArticle.CAUNVTE === 'M3') {
      description = `L ${this.qtyLength} x l ${this.qtyWidth} x h ${this.qtyHeight}`;
    }

    if(this.previousPanier !== undefined && this.previousPanier !== null) {
      article_data = this.previousPanier.articles;

      if(type === 'options' || form_data.GROUP_AVAIL || +this.currentArticle.GROUP_COUNT > 0) {
        this.previousPanier.articles.map((_item_art: PanierArticle) => {
          if(_item_art.opt < 0) {
            currentOpt = _item_art.opt;
          }
        });

        currentOpt = currentOpt - 1;
      }

      currentIdLigne = article_data.length + 1;
      article_data.push({
        idlig: currentIdLigne,
        cdart: this.currentArticle.CDART,
        quantite: parseFloat(form_data.QTE.toString()),
        prix: parseFloat(form_data.CAPRIX.toString()), // Article prix
        prixforce: form_data.PRIX_FORCE,
        motifprixforce: (form_data.MOTIF_PRIX !== null)?form_data.MOTIF_PRIX:"",
        commlig1: description,
        commlig2: "",
        prixachatHT: 0.0,
        prixachatTTC: 0.0,
        tauxTVA: "", // Optional
        typecde: form_data.TYPECDE,
        remlig: parseFloat(totalLineRemTotal.toString()), // Total remise amount of the row
        remtot: parseFloat(lineRemTot.toString()),
        datafid: "",
        datafidaco: "",
        dataopeco: "" ,
        datarem: lineDataRem,
        okdiff: false,
        bundle: 0,
        opt: currentOpt,
        lib: this.currentArticle.CALIB3,
        idinstuser: +this.user.IDINSTINT,
        iduser: +this.user.CIINT,
        libuser: this.currentUserName,
        dtpromesse: current_date, // 2018-03-27 10:04:41
        coinsolde: false,
        expo: false,
        motifcde: '',
        numcde: '',
        prixupv: parseFloat(form_data.NEW_PRIX.toString()), // New Price (Typeable) - PU Ven.
        montant: parseFloat((form_data.NEW_PRIX * form_data.QTE).toString()), // Row Subtotal - PV Total
        remise: lineRemPercentage,
        emplacement: "",
        statut: this.currentArticle.CAECODE === 'A'?-1:0,
        dtmaj: current_date
      });
    } else {
      if(type === 'options' || form_data.GROUP_AVAIL || +this.currentArticle.GROUP_COUNT > 0) {
        currentOpt = -1;
      }

      article_data.push({
        idlig: currentIdLigne,
        cdart: this.currentArticle.CDART,
        quantite: parseFloat(form_data.QTE.toString()),
        prix: parseFloat(form_data.CAPRIX.toString()), // Article prix
        prixforce: form_data.PRIX_FORCE,
        motifprixforce: (form_data.MOTIF_PRIX !== null)?form_data.MOTIF_PRIX:"",
        commlig1: description,
        commlig2: "",
        prixachatHT: 0.0,
        prixachatTTC: 0.0,
        tauxTVA: "", // Optional
        typecde: form_data.TYPECDE,
        remlig: parseFloat(totalLineRemTotal.toString()), // Total remise amount of the row
        remtot: parseFloat(lineRemTot.toString()),
        datafid: "",
        datafidaco: "",
        dataopeco: "" ,
        datarem: lineDataRem,
        okdiff: false,
        bundle: 0,
        opt: currentOpt,
        lib: this.currentArticle.CALIB3,
        idinstuser: +this.user.IDINSTINT,
        iduser: +this.user.CIINT,
        libuser: this.currentUserName,
        dtpromesse: current_date, // 2018-03-27 10:04:41
        coinsolde: false,
        expo: false,
        motifcde: '',
        numcde: '',
        prixupv: parseFloat(form_data.NEW_PRIX.toString()), // New Price (Typeable) - PU Ven.
        montant: parseFloat((form_data.NEW_PRIX * form_data.QTE).toString()), // Row Subtotal - PV Total
        remise: lineRemPercentage,
        emplacement: "",
        statut: this.currentArticle.CAECODE === 'A'?-1:0,
        dtmaj: current_date
      });
    }

    if(type === 'options') {
      currentOpt = Math.abs(currentOpt);
      
      this.currentArticleChosenGroups.GROUPS.map((_row_group: any) => {
        _row_group.GROUP_CHOSEN_OPTIONS.map((_row_option: any) => {
          if(_row_option.SELECTED) {
            currentIdLigne = currentIdLigne + 1;

            article_data.push({
              idlig: currentIdLigne,
              cdart: _row_option.CDART,
              quantite: parseFloat(form_data.QTE.toString()),
              prix: parseFloat(_row_option.PRIX.toString()), // Article prix
              prixforce: false,
              motifprixforce: "",
              commlig1: "",
              commlig2: "",
              prixachatHT: 0.0,
              prixachatTTC: 0.0,
              tauxTVA: "", // Optional
              typecde: form_data.TYPECDE,
              remlig: 0, // Total remise amount of the row
              remtot: 0,
              datafid: "",
              datafidaco: "",
              dataopeco: "" ,
              datarem: "",
              okdiff: false,
              bundle: 0,
              opt: currentOpt,
              lib: _row_option.CALIB,
              idinstuser: +this.user.IDINSTINT,
              iduser: +this.user.CIINT,
              libuser: this.currentUserName,
              dtpromesse: current_date, // 2018-03-27 10:04:41
              coinsolde: false,
              expo: false,
              motifcde: '',
              numcde: '',
              prixupv: parseFloat(_row_option.PRIX.toString()), // New Price (Typeable) => PU Ven.
              montant: parseFloat((_row_option.PRIX * _row_option.QTY).toString()), // Row Subtotal - PV Total
              remise: 0,
              emplacement: "",
              statut: 0,
              dtmaj: current_date
            });
          }
        })
      })
    }

    article_data.map((row) => {
      grand_total_amount += row.montant;
    });

    let dataRem = (this.previousPanier !== undefined && this.previousPanier !== null)?this.previousPanier.datarem:"";
    let remTotal = (this.previousPanier !== undefined && this.previousPanier !== null)?this.previousPanier.remtot:0;
    let remPercentage = (this.previousPanier !== undefined && this.previousPanier !== null)?this.previousPanier.remise:0;

    // Default Date limite for Emportéé différe from Params
    const ED_buffer_days = await this.userService.getRuleParamsData(20, 50, 'VINT1');
    const ED_default_date = moment().add(ED_buffer_days, 'days').format('YYYY-MM-DD HH:mm:ss');

    let devisPanier = {
      articles: article_data,
      mag: this.USERCMINT,
      clientlivre : "",
      clientfacture : "",      
      numfid: '',
      comm : this.devisComments !== null?this.devisComments:"",
      idinstuser : +this.user.IDINSTINT,
      iduser : +this.user.CIINT,
      libuser: this.currentUserName,

      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.currentUserName,
      
      userexterne : "",
      numdevis: "",

      dtliv : '1899-12-28T00:00:00.000+01:00', // Livraison Date
      dtlivsouhait : '1899-12-28T00:00:00.000+01:00', //
      livjour : "", // A (Afternoon) / M (Morning)
      livconfirm : false, // Confirmation de livraison
      livinfo1 : "", // Livraison Info 1
      livinfo2 : "", // Livraison Info 2
      livdeport : false, // Livraison Transport
      livdirect : false, // Livraison Directe

      transporteur : "",
      numexterne : "",
      remtot : remTotal,
      datafid : "",
      datafidaco : "",
      dataopeco : "",
      datarem : dataRem,
      vteemp : 0,
      pseudo : "",
      place : "",
      infocde : "",
      numcourt : "",
      statut : 1,
      dtsaisie : current_date,
      dtmaj : current_date,
      dtech : '1899-12-28T00:00:00.000+01:00',
      dtrel : '1899-12-28T00:00:00.000+01:00',
      dtpromesse : '1899-12-28T00:00:00.000+01:00',
      ecartpromesse : "",
      remise: remPercentage,
      canal : 0,
      montant : grand_total_amount,
      montantrestant : 0,
      preparation: false,
      retrait1h: false,
      montantHT: grand_total_amount,
      typecde: '',
      paiements: [],
      infosfac: {
        totEI: 0,
        totED: 0,
        totBL: 0,
        totE2: 0,
        mntED: 0,
        mntBL: 0,
        mntE2: 0,
        pourED: 100,
        pourBL: 100,
        pourE2: 20
      }
    };
    
    if(this.previousPanier !== undefined && this.previousPanier !== null) {
      devisPanier = {
        ...this.previousPanier,
        articles: article_data,
        clientlivre : this.previousPanier.clientlivre !== null?this.previousPanier.clientlivre:"",
        clientfacture : this.previousPanier.clientfacture !== null?this.previousPanier.clientfacture:"",
        comm: this.devisComments !== null?this.devisComments:this.previousPanier.comm,
        
        idinstusermaj: +this.user.IDINSTINT,
        idusermaj: +this.user.CIINT,
        libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.currentUserName,

        remtot : remTotal,
        datarem : dataRem,
        dtmaj : current_date,
        remise: remPercentage,
        montant : grand_total_amount,
        montantHT: grand_total_amount,
        infosfac: this.previousPanier.infosfac        
      };
    }

    if(this.chosenFidelite) {      
      devisPanier['numfid'] = this.chosenFidelite.NUMFID;
    }

    if(client_data !== undefined && client_data !== null) {
      devisPanier['client'] = client_data;
    } else {
      devisPanier['client'] = "";
    }

    if(this.previousPanier !== undefined && this.previousPanier !== null) {  
      let devisData = {
        pMag: this.USERCMINT,
        pNumBon: this.bonNumBon,
        pPanier: devisPanier,
        IDSOC: Constants.IDSOC,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST,
      };

      const chosenLineRemiseBcup = this.chosenLineRemise;
      const chosenArticleBcup = this.currentArticle;
      const forcePrixMotif = (form_data.MOTIF_PRIX !== null) ? form_data.MOTIF_PRIX_LBL : '';

      this.editDevis(devisData).then(
        async (resp: any) => {
          if(chosenLineRemiseBcup !== undefined && chosenLineRemiseBcup !== null && chosenLineRemiseBcup.length > 0) {
            try {
              await this._addLigneRemiseHistory(currentCdart, this.user.CIINT, oldPrix, newPrix, false);

              if (this.authorizedLineRemiseUser !== undefined && this.authorizedLineRemiseUser !== null) {
                await this._addLigneRemiseHistory(currentCdart, this.authorizedLineRemiseUser.CIINT, oldPrix, newPrix);
                this.authorizedLineRemiseUser = undefined;
              }
            } catch(err) {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
          }

          if (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) {
            await this._addChangementPrixHistory(currentCdart, forcePrixMotif, +chosenArticleBcup.calculated_price, form_data.CAPRIX);
            this.authorizedLinePrixUser = undefined;
          }

          this.commander = false;
          this.openCommandeDetail();

          // Check if the added item is a service article and open the popup where it can be linked to any other non service article
          if (!this.isWefoxArticle) {
            let currentItem: PanierArticle;
            if (article_data?.length > 0) {
              currentItem = article_data[article_data.length - 1]
            }

            const currentArticleData = resp?.current_article;

            if (currentItem.opt >= 0) {
              const parentItem = [...article_data].find(rw => rw.opt == -currentItem.opt);
              const parentArticle = this.listBonVenteArticles.find(rw => rw.CDART === parentItem.cdart);

              await this.checkArticleParentChildStatus([[parentArticle.CAINT, currentArticleData.CAINT]]);
            }
        
            if(currentItem.opt < 0 && +currentArticleData.GROUP_COUNT > 0) {
              this.linkedStatus = 'bundle';
            }
        
            this.checkIfAddedArticleIsService(currentItem, currentArticleData)
          }
        }
      );
    } else {
      let defaultDiffereDays = 7;
      try {
        defaultDiffereDays = await this.userService.getRuleParamsData(20, 50, 'VINT2');
      } catch(err) {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }

      const devisLimiteDate = moment().add(defaultDiffereDays, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');    
      devisPanier.dtech = devisLimiteDate;

      devisPanier['Id_User'] = this.user.CIINT;
      devisPanier['Id_InstUser'] = Constants.CIINST;

      const chosenLineRemiseBcup = this.chosenLineRemise;
      const chosenArticleBcup = this.currentArticle;
      const forcePrixMotif = (form_data.MOTIF_PRIX !== null) ? form_data.MOTIF_PRIX_LBL : '';

      this.createNewDevis(devisPanier).then(
        async (resp) => {
          if(chosenLineRemiseBcup !== undefined && chosenLineRemiseBcup !== null && chosenLineRemiseBcup.length > 0) {
            try {
              await this._addLigneRemiseHistory(currentCdart, this.user.CIINT, oldPrix, newPrix, false);

              if (this.authorizedLineRemiseUser !== undefined && this.authorizedLineRemiseUser !== null) {
                await this._addLigneRemiseHistory(currentCdart, this.authorizedLineRemiseUser.CIINT, oldPrix, newPrix);
                this.authorizedLineRemiseUser = undefined;
              }
            } catch(err) {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
          }

          if (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) {
            await this._addChangementPrixHistory(currentCdart, forcePrixMotif, +chosenArticleBcup.calculated_price, form_data.CAPRIX);
            this.authorizedLinePrixUser = undefined;
          }

          this.router.navigate([], {
            queryParams: {
              numbon: this.bonNumBon
            }
          }).then(() => {
            this.storageService.setItem('previousBonDevis', this.previousPanier);
            this.storageService.setItem('previousNumBon', this.bonNumBon);
          });

          // Check if the added item is a service article and open the popup where it can be linked to any other non service article
          if (!this.isWefoxArticle) {
            let currentItem: PanierArticle;
            if (article_data?.length > 0) {
              currentItem = article_data[article_data.length - 1]
            }

            let currentArticleData;
            const listArticles = resp?.data?.customArticles;
            if (listArticles && listArticles?.length > 0) {
              currentArticleData = listArticles[listArticles.length - 1]
            }

            if (currentItem.opt >= 0) {
              const parentItem = [...article_data].find(rw => rw.opt == -currentItem.opt);
              const parentArticle = this.listBonVenteArticles.find(rw => rw.CDART === parentItem.cdart);

              await this.checkArticleParentChildStatus([[parentArticle.CAINT, currentArticleData.CAINT]]);
            }
        
            if(currentItem.opt < 0 && +currentArticleData.GROUP_COUNT > 0) {
              this.linkedStatus = 'bundle';
            }
        
            this.checkIfAddedArticleIsService(currentItem, currentArticleData)
          }
        }
      );
      this.openCommandeDetail();

    }
  }

  checkIfAddedArticleIsService(currentItem: PanierArticle, currentArticleData) {
    if (currentArticleData.CAGESTOCK == '0' && currentItem?.opt >= 0 && this.linkedStatus !== 'bundle') {
      const listNonServiceArticles = this.listBonVenteArticles.filter((row) => row.CAGESTOCK == '-1');

      const cdartArticlesHashTable = [...listNonServiceArticles].reduce((acc, it) => {
        if (!acc.hasOwnProperty(it.CDART)) {
          acc[it.CDART] = {};
        }

        acc[it.CDART] = it;
        return acc;
      }, {});

      const listNonServiceItems = this.listBonVenteItems.filter(rw => {
        return rw.opt <= 0 && (cdartArticlesHashTable[rw.cdart] && cdartArticlesHashTable[rw.cdart].CAGESTOCK == '-1');
      });
      
      const dialogRef = this.dialog.open(NonServiceArticlesListComponent, {
        width: '40vw',
        maxWidth: '40vw',
        maxHeight: '80vh',
        data: {
          currentItem: currentItem,
          nonServiceArticles: listNonServiceArticles,
          nonServiceItems: listNonServiceItems,
          nonServiceArticleHashTable: cdartArticlesHashTable,
          panier: this.previousPanier,
          isDevis: true,
        }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.status === 'success') {
          
          if (result.data.CHOSEN_IDLIG !== undefined && result.data.CHOSEN_IDLIG !== null) {
            const chosenLineId = result.data.CHOSEN_IDLIG;
            
            const serviceItem = currentItem; // Service
            const nonServiceItem = listNonServiceItems.find(rw => rw.idlig == chosenLineId); // Non service
            
            this._linkNonServiceToService(serviceItem, nonServiceItem, currentItem);
          } else {
            // Add History record for adding non service article from another bon-de-commande
            if (result?.data?.NUMBON && result?.data?.AUTRE_CHOSEN_CDART) {
              this._addAutreArticleLinkHistory(result.data.AUTRE_CHOSEN_CDART, result.data.NUMBON, currentItem.cdart)  
            }
          }
        }
      });
    }
  }

  checkArticleParentChildStatus(payload: string[][]): Promise<any> {
    this.loadingService.show(); 
    return new Promise((resolve, reject) => {
      this.bonVenteService.checkArticleParentChildStatus(payload).subscribe(
          (res: any) => {
            if(res.success !== undefined) {
              if(res.data.length > 0) {
                this.linkedStatus = 'bundle';
              } else {
                this.linkedStatus = 'service';
              }
              this.loadingService.hide();
              resolve(res);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
              resolve(res);
            }
          },
          (err) => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    })
  }

  async _linkNonServiceToService(serviceItem: PanierArticle, nonServiceItem: PanierArticle, currentItem) {
    if (nonServiceItem === undefined || nonServiceItem === null) {
      return;
    }

    this.loadingService.show();

    let article_data: PanierArticle[] = [...this.previousPanier.articles];

    let currentIdLigne = 1;
    let nextOpt = 0;
    article_data.forEach((_r) => {
      if(_r.opt < 0) {
        nextOpt = _r.opt;
      }
    });
    nextOpt = nextOpt - 1;

    currentIdLigne = nonServiceItem.idlig;
    if (nonServiceItem.opt < 0) {
      // If it's already a parent.
      nextOpt = nonServiceItem.opt;

      const filteredChildren = [...article_data]
          .filter(rw => rw.opt === Math.abs(nonServiceItem.opt))
          .map(rw => rw.idlig);

      if (filteredChildren.length > 0) {
        currentIdLigne = Math.max(...filteredChildren);
      }
    }

    const QTE = nonServiceItem.quantite;
    let PRIX_UPV = serviceItem.prixupv;
    let PRIX_PVT = PRIX_UPV * QTE;

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const whole_remise_percent = (this.previousPanier.remise !== undefined && this.previousPanier.remise !== null) ? +this.previousPanier.remise : 0.0;
    let line_remise_percent = (currentItem.remise !== undefined && currentItem.remise !== null) ? +currentItem.remise : 0.0;
    let line_remise_prix = 0,
        line_remise_value = 0,
        total_line_remise = 0,
        line_remtot = 0,
        line_subtotal = 0;

    const itemRemPercentage = currentItem.remise;

    line_remise_percent = itemRemPercentage;

    if(line_remise_percent > 0) {
      line_remise_value = serviceItem.prix * (line_remise_percent / 100);
      line_remise_prix = serviceItem.prix - line_remise_value;

      total_line_remise = line_remise_value * +QTE;
      line_subtotal = line_remise_prix * +QTE;

      PRIX_UPV = line_remise_prix;
      PRIX_PVT = line_subtotal;
    }

    if(whole_remise_percent > 0) {
      line_remtot = total_line_remise * (whole_remise_percent / 100);
    }

    const serviceChildItem = {
      ...serviceItem,
      opt: Math.abs(nextOpt),
      quantite: QTE,
      typecde: nonServiceItem.typecde,
      prixupv: PRIX_UPV,
      montant: PRIX_PVT,

      remise: line_remise_percent,
      remlig: parseFloat(total_line_remise.toString()),
      remtot: parseFloat(line_remtot.toString()), // Total remise amount of the row

      idusermaj: +this.user.CIINT,
      idinstusermaj: +this.user.IDINSTINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : serviceItem.libuser,
      dtmaj: current_date
    };

    let grand_total_amount = 0;

    // Removing the service item from the article_data array
    article_data.splice(article_data.findIndex(rw => rw.idlig === serviceItem.idlig), 1);

    // Inserting the service item into the article_data array in a specific position
    article_data.splice(article_data.findIndex(rw => rw.idlig === currentIdLigne) + 1, 0, serviceChildItem);

    article_data = article_data.map((row) => {
      if (row.idlig === nonServiceItem.idlig) {
        // Update the `opt` only to the chosen Non Service article
        row.opt = nextOpt;

        row.dtmaj = current_date;
        row.idusermaj = +this.user.CIINT;
        row.idinstusermaj = +this.user.IDINSTINT;
        row.libusermaj = this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : row.libusermaj;
      }

      grand_total_amount += row.montant;

      return row;
    });

    let dataRem = "";
    let remTotal = 0;
    const remPercentage = whole_remise_percent;

    if(whole_remise_percent !== undefined && whole_remise_percent !== null && whole_remise_percent > 0) {
      remTotal = grand_total_amount * (remPercentage / 100);

      dataRem = this.previousPanier.datarem;
      remTotal = this.previousPanier.remtot;
    }

    const bondeVenteData = {
      ...this.previousPanier,
      articles: article_data,

      idinstusermaj : this.user.IDINSTINT,
      idusermaj : +this.user.CIINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.previousPanier.libuser,

      remtot : remTotal,
      datarem : dataRem,
      montant : grand_total_amount,
      montantHT: grand_total_amount,
      dtmaj : current_date,
    };

    bondeVenteData['editinfo'] = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon
    };

    const devisData = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon,
      pPanier: bondeVenteData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST
    };

    await this.editDevis(devisData);

    this.loadingService.hide();
  }

  async _addAutreArticleLinkHistory(autreCDART: string, autreBON: string, currentCDART, unlink: boolean = false, historyData?: any) {
    let ARTEAN = '';
    let MOTIF = '';

    if (unlink) {
      ARTEAN = 'idem';
      MOTIF = 'idem';

      if(historyData?.ARTEAN && historyData?.MOTIF) {
        ARTEAN = historyData?.ARTEAN;
        MOTIF = historyData?.MOTIF;
      }
    } else {
      ARTEAN = currentCDART;
      MOTIF = `“Bon lié N° : ${autreBON} ; Article : ${autreCDART}`;
    }

    return await this.historyService.addHistory({
      NumBon: this.bonNumBon,
      mag: this.USERCMINT,
      iduser: this.user.CIINT,
      ARTEAN,
      MOTIF,
      OPERAT : unlink ? this.translateService.instant('kunlink_autre_article') : this.translateService.instant('klink_autre_article'),
      VALUER1: null,
      VALUER2: null,
    });
  }

  async imprimerConfirmCheckboxPopup() {
    if(!this.previousPanier.client || (this.previousPanier.client && (this.previousPanier.client.email == null || this.previousPanier.client.email === ''))) {
      this.imprimerDevis();
      return;
    }

    const dialogRef = this.dialog.open(ImprimerConfirmationComponent, {
      width: '60vw',
      data: {
        panier: this.previousPanier,
        CMINT: this.USERCMINT,
        flowType: this.flowType
      }     
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result !== undefined && result !== null && result.status === "success") {
        console.log(result);
        const editBon = result.data.EDIT_BON;
        const sendBon = result.data.SEND_BON;

        if(sendBon) {
          await this.insertBatchReport();
          await this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmsgenvoye')).toPromise();
        }

        if(editBon) {
          this.imprimerDevis();
        }
      }
    });    
  }

  insertBatchReport(): Promise<any> {
    this.loadingService.show();
    return new Promise((resolve, reject) => {
      const data = {
        BRACTION: Constants.BRACTION_DEVIS,
        DTREPORT: moment().format('YYYY-MM-DD HH:mm:ss'),
        CIINT: this.user.CIINT,
        BRINT1: Constants.IDSOC, // IDSOC
        BRINT2: this.USERCMINT, // CMINT
        BRSTR1: this.bonNumBon,
        TRAITE: 0
      };

      this.bonVenteService.createBatchReport(data).subscribe(
        (resp) => {
          this.loadingService.hide();
          resolve(resp);
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    })
  }

  imprimerDevis() {
    this.loadingService.show();
    this.__printDevis({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pImprimante: 'download' });
  }

  __printDevis(devisData: { pMag: any, pNumBon: string, pImprimante: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.devisService.imprimerDevis(devisData).subscribe(
        (resp) => {
          this.loadingService.hide();        
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              let fileUrl = resp.basePath+resp.data.Fichier; // 'Bon_2721162009080_122446557.pdf'

              this.bonVenteService.downloadFile(fileUrl).subscribe(
                (_down_res) => {
                  this.openUpPdfViewer({ url: fileUrl });
                },
                error => {
                  console.error("Error while downloading the file", error);
                  this.dialogService.prompt(this.translateService.instant('kfailure'), error.message);
                }
              );
              resolve();
            } else {
              reject();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {         
            reject(); 
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject();
        }
      );
    })
  }

  openUpPdfViewer(data: any) {
    const dialogRef = this.dialog.open(PdfViewerComponent,
      {
        height: this.isMobile?'100%':undefined,
        data: {
          ...data
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        
      }      
    });
  }

  createNewDevis(devisData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonApiSubscription = this.devisService.createDevis(devisData).subscribe(
        (resp) => {
          this.loadingService.hide();        
          const currentArticleBcup = this.currentArticle;
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.chosenLineRemise = undefined;
              this.chosenRemise = undefined;
    
              this.bonNumBon = resp.data.NumBon;
              this.previousPanier = resp.data.Panier;          
              this.listBonVenteItems = this.previousPanier.articles;
    
              this.listBonVenteArticles = resp.data.customArticles;
              this.previousPanierClient = resp.data.customClient;
              this.chosenClient = this.previousPanierClient;  
    
              this.storageService.setItem('previousBonDevis', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);
              
              this.currentArticle = undefined;
              this.article = this.currentArticle;
              this.commander = false;
              this.newArticleForm.patchValue({
                CDART: '',
                CALIB1: '',
                QTE: this.utilService.formatQteMaskCompat(1),
                CAPRIX: '',
                NEW_PRIX: this.utilService.formatMaskCompat(0),
                ROW_TOTAL: '',
                TYPECDE: 'EI',
                MOTANT: this.utilService.formatMaskCompat(0),
                STOCKDISPO: '',
                PRIX_FORCE: false,
                MOTIF_FORCE: '',
              });

              this.qtyLength = 0;
              this.qtyWidth = 0;
              this.qtyHeight = 0;
    
              this.newArticleForm.get('QTE').disable({ onlySelf: true });
              this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
              this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
    
              this.refreshByPanier(this.previousPanier);
              resolve(resp);
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              resolve(resp);
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            resolve(resp);
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    }); 
  }

  editDevis(devisData: any, closeLoader: boolean = true) {
    return new Promise((resolve, reject) => {
      this.bonApiSubscription = this.devisService.modifyDevis(devisData).subscribe(
        (resp) => {
          if(closeLoader) {
            this.loadingService.hide();
          }
          const currentArticleBcup = this.currentArticle;

          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.chosenLineRemise = undefined;
              this.chosenRemise = undefined;
  
              this.bonNumBon = resp.data.NumBon;
              this.previousPanier = resp.data.Panier;     
              this.listBonVenteItems = this.previousPanier.articles;
    
              this.listBonVenteArticles = resp.data.customArticles;
              this.previousPanierClient = resp.data.customClient;
              this.chosenClient = this.previousPanierClient;
    
              this.storageService.setItem('previousBonDevis', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);
    
              this.currentArticle = undefined;
              this.article = this.currentArticle;
    
              this.newArticleForm.patchValue({
                CDART: '',
                CALIB1: '',
                QTE: this.utilService.formatQteMaskCompat(1),
                CAPRIX: '',
                NEW_PRIX: this.utilService.formatMaskCompat(0),
                ROW_TOTAL: '',
                TYPECDE: 'EI',
                MOTANT: this.utilService.formatMaskCompat(0),
                STOCKDISPO: '',
                PRIX_FORCE: false,
                MOTIF_FORCE: '',
              });

              this.qtyLength = 0;
              this.qtyWidth = 0;
              this.qtyHeight = 0;
    
              this.newArticleForm.get('QTE').disable({ onlySelf: true });
              this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
              this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
    
              this.refreshByPanier(this.previousPanier);
              resolve({ response: resp, current_article: currentArticleBcup });
            } else {
              this.loadingService.hide();
              reject({ response: resp, current_article: currentArticleBcup });
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {        
            this.loadingService.hide();
            resolve({ response: resp, current_article: currentArticleBcup });
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });     
  }

  async searchArticleGroupsCAINT(CAINT: any, TYPECDE: string) {
    this.loadingService.show();    

    this.bonVenteService.getArticleGroupsByCAINT(CAINT, this.USERCMINT, this.user.CIINT).subscribe(
      async (res: any) => {
        if(res.success !== undefined) {
          if(res.data.length > 0) {
            let groupsData = res.data;
            this.listArticleGroups = [];

            if(groupsData !== undefined && groupsData != null && groupsData.length > 0) {
              groupsData.map((row: any) => {
                let groupAvail = this.listArticleGroups.find((_row) => _row.group_id == row.id);
  
                if(groupAvail === undefined || groupAvail === null) {
                  this.listArticleGroups.push({
                    group_id: row.id,
                    group_label: row.label,
                    group_min: row.selectable_qtymin,
                    group_max: row.selectable_qty,
                    group_custom: row.custom,
                    group_type: row.TYPECDE,
                    group_deposit: row.DEPOSIT,
                    group_active: row.ACTIVE,
                    group_position: row.grouppos,
                    group_articles: [row]
                  });
                } else {
                  this.listArticleGroups.map((_row) => {
                    if(_row.group_id == row.id) {
                      _row.group_articles.push(row);
                    }
                  });                
                }              
              });

              if(groupsData.some((_r: any) => _r.TYPECDE == null || TYPECDE.indexOf(_r.TYPECDE) > -1)) {
                const groupsPopupResult = await this.openUpArticleGroups(this.listArticleGroups, undefined, TYPECDE);
                if(groupsPopupResult !== undefined && groupsPopupResult !== null && groupsPopupResult.status !== undefined && groupsPopupResult.status === 'success') {
                  this.currentArticleChosenGroups = groupsPopupResult.data;
                  this.__newLineItemAddLogic('options');
                }
              } else {
                this.__newLineItemAddLogic();
              }
            } else {
              this.__newLineItemAddLogic();
            }
          } else {
            this.currentArticle = undefined;

            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
          }          
        } else {
          this.currentArticle = undefined;          

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.currentArticle = undefined;

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  _searchMultiArticleGroupsCAINT(CAINT: any, closeLoader: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.getArticleGroupsByCAINT(CAINT, this.USERCMINT, this.user.CIINT).subscribe(
          (res: any) => {
            if(res.success !== undefined) {
              if(res.data.length > 0) {
                if(closeLoader) {
                  this.loadingService.hide();
                }

                let groupsData = res.data;
                this.articleGroupsRawData = groupsData;
                this.listArticleGroups = [];

                if(groupsData !== undefined && groupsData != null && groupsData.length > 0) {
                  groupsData.map((row: any) => {
                    let groupAvail = this.listArticleGroups.find((_row) => _row.group_id == row.id);

                    if(groupAvail === undefined || groupAvail === null) {
                      this.listArticleGroups.push({
                        group_id: row.id,
                        group_label: row.label,
                        group_min: row.selectable_qtymin,
                        group_max: row.selectable_qty,
                        group_custom: row.custom,
                        group_type: row.TYPECDE,
                        group_deposit: row.DEPOSIT,
                        group_active: row.ACTIVE,
                        group_position: row.grouppos,
                        group_articles: [row]
                      });
                    } else {
                      this.listArticleGroups.map((_row) => {
                        if(_row.group_id == row.id) {
                          _row.group_articles.push(row);
                        }
                      });
                    }
                  });
                }

                resolve(res);
              } else {
                this.currentArticle = undefined;
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
                if(closeLoader) {
                  this.loadingService.hide();
                }
                resolve(res);
              }
            } else {
              this.currentArticle = undefined;

              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
              if(closeLoader) {
                this.loadingService.hide();
              }
              resolve(res);
            }
          },
          (err) => {
            this.currentArticle = undefined;

            if(closeLoader) {
              this.loadingService.hide();
            }
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    });
  }

  _focusPUvente() {
    if(this.newPrixVente){
      this.newPrixVente.nativeElement.select();            
      this.newPrixVente.nativeElement.focus();
      this.newPrixVente.nativeElement.click();
    }
  }

  _focusTypecde() {
    if(this.newTypeCde){
      this.newTypeCde.focus();
      this.newTypeCde.open();
    }
  }

  _triggerSubmitNewItem() {
    this.newArticleFormEl?.ngSubmit.emit();
  }

  searchArticleRapid(event: any =''){
    if(event){
      event.preventDefault();
    }

    if(this.newArticleForm.get('CDART').errors) {
      return;
    }
    this.chosenLineRemise = undefined;
    const search_code = this.newArticleForm.get('CDART').value;

    if(this.cdartInput && this.isMobile) {
      this.cdartInput.nativeElement.readOnly = true;
      this.cdartInput.nativeElement.setAttribute('inputmode', 'none');

      setTimeout(() => {
        this.cdartInput.nativeElement.setAttribute('inputmode', 'text');
        this.cdartInput.nativeElement.readOnly = false;
      }, 50)
    }

    this._barCodeLogicCheck(search_code, false).catch((val) => {
      this.searchArticleCDART();
    })
  }

  searchArticleCDART() {
    
    if(this.newArticleForm.get('CDART').errors) {
      return;
    }

    this.loadingService.show();
    const CDART = this.newArticleForm.get('CDART').value;

    this.bonVenteService.getArticleByCDART(CDART, this.USERCMINT).subscribe(
      (res: any) => {
        this.chosenLineRemise = [];
        if(res.success !== undefined) {
          this.PU_vente_changable = true;
          if(!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if(res.data.length > 0) {
            let groupAvail = res.groupExist?res.groupExist:false;
            this.currentArticle = res.data[0];            

            this.newArticleForm.patchValue({
              CALIB1: this.currentArticle.CALIB3,
              CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
              TYPECDE: 'EI',
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: groupAvail,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if(this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }
            
            if(this.newArticleQty && !this.isMobile){
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }

            this.definingTypeDeRules();
            
            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CALIB1: '',            
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: 'EI',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: false,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            
            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;

            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            if(!isNaN(Number(CDART))) {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
            } else {
              this.searchArticleNOM();
            }
          }          
        } else {
          this.currentArticle = undefined;
          this.newArticleForm.patchValue({
            CALIB1: '',            
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: 'EI',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            GROUP_AVAIL: false,
            QTE: this.utilService.formatQteMaskCompat(1),
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.currentArticle = undefined;
        this.chosenLineRemise = [];
        this.newArticleForm.patchValue({
          CALIB1: '',            
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: 'EI',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          GROUP_AVAIL: false,
          QTE: this.utilService.formatQteMaskCompat(1),
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;
        
        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  searchArticleNOM() {
    const NOM = this.newArticleForm.get('CDART').value;
    
    this.bonVenteService.getArticleByNOM(NOM, this.USERCMINT).subscribe(
      (res: any) => {
        if(res.success !== undefined) {
          const data = res.data;
          const totalResults: number = res.data.length;
          
          if(totalResults === 1) {
            let groupAvail = res.groupExist?res.groupExist:false;
            this.currentArticle = res.data[0];
            this.newArticleForm.patchValue({
              CDART: this.currentArticle.CDART,
              CALIB1: this.currentArticle.CALIB1,
              CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
              TYPECDE: '',
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: groupAvail,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if(this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }

            if(this.newArticleQty && !this.isMobile){
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }
            this.definingTypeDeRules();
            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CDART: '',
              CALIB1: '',
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: false,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;

            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            if(data.length > 0) {
              this.loadingService.hide();
              const data = {
                formType: 'advancedSearch',
                filterData: { NOM }
              };
              this.openUpSearchArticle(undefined, data);
            } else {
              this.loadingService.hide();
              const data = {
                formType: 'advancedSearch',
                filterData: { NOM }
              };
              this.openUpSearchArticle(undefined, data);
            }
          }
        } else {
          this.currentArticle = undefined;
          this.newArticleForm.patchValue({
            CDART: '',
            CALIB1: '',            
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: '',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            GROUP_AVAIL: false,
            QTE: this.utilService.formatQteMaskCompat(1),
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.currentArticle = undefined;
        this.newArticleForm.patchValue({
          CDART: '',
          CALIB1: '',            
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: '',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          GROUP_AVAIL: false,
          QTE: this.utilService.formatQteMaskCompat(1),
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;
        
        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  searchArticleByCMEAN(event: any) {
    if(event === undefined || event == 'null') {
      return;
    }

    this.loadingService.show();
    const CMEAN = event;

    this.bonVenteService.getArticleByCMEAN(CMEAN, this.USERCMINT).subscribe(
      (res: any) => {
        this.chosenLineRemise = [];
        if(res.success !== undefined) {
          this.PU_vente_changable = true;
          if(!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if(res.data.length > 0) {
            this.currentArticle = res.data[0];            

            this.newArticleForm.patchValue({
              CDART: this.currentArticle.CDART,
              CALIB1: this.currentArticle.CALIB3,
              CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
              TYPECDE: 'EI',
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if(this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }
            
            if(this.newArticleQty && !this.isMobile){
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }

            this.definingTypeDeRules();
            
            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CDART: '',
              CALIB1: '',            
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: 'EI',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;
            
            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
          }          
        } else {
          this.currentArticle = undefined;
          this.newArticleForm.patchValue({
            CDART: '',
            CALIB1: '',            
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: 'EI',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            QTE: this.utilService.formatQteMaskCompat(1),
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.currentArticle = undefined;
        this.chosenLineRemise = [];
        this.newArticleForm.patchValue({
          CDART: '',
          CALIB1: '',            
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: 'EI',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          QTE: this.utilService.formatQteMaskCompat(1),
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;
        
        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  getArticleByCdart(CDART: string) {
    if(CDART === undefined || CDART === null) {
      return;
    }

    this.loadingService.show();
    this.bonVenteService.getArticleByCDART(CDART, this.USERCMINT).subscribe(
      (res: any) => {
        this.chosenLineRemise = [];
        if(res.success !== undefined) {
          this.PU_vente_changable = true;
          if(!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if(res.data.length > 0) {            
            this.article = res.data[0];
            
            this.loadingService.hide();
          } else {
            this.article = undefined;

            this.loadingService.hide();
          }          
        } else {
          this.article = undefined;

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.chosenLineRemise = [];
        this.article = undefined;

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  onQTYChanged(event: any) {
    this.rowTotalCalculation();
  }

  incDecByArrows(event: any) {
    const $input = this.newArticleForm.get('QTE');

    if (event.which == 38) {
      let total = parseInt($input.value) + 1;
      $input.setValue(this.utilService.formatQteMaskCompat(total));
    } else if (event.which == 40) {
      let total = parseInt($input.value) - 1;
      $input.setValue(this.utilService.formatQteMaskCompat(total));
    }
  }

  onQuantityFocused() {
    if(this.currentArticle === undefined || this.currentArticle === null) return;

    if(this.currentArticle.CAUNVTE === 'M3') {
      this.openUpCalculateQty().then((result) => {
        if(result && result.status === "success") {
          const data = result.data;

          const length = data.length?data.length:0;
          const width = data.width?data.width:0;
          const height = data.height?data.height:0;

          this.qtyLength = length;
          this.qtyWidth = width;
          this.qtyHeight = height;

          const totalQty = length * width * height;

          this.newArticleForm.patchValue({
            QTE: this.utilService.formatQteMaskCompat(totalQty)
          });

          this.rowTotalCalculation();
        }
      })
    }
  }

  openUpCalculateQty(): Promise<any> {
    const dialogRef = this.dialog.open(CalculateQuantityComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier,
          currentItemFormData: this.newArticleForm.getRawValue(),
          currentArticle: this.currentArticle,
          dimensions: {
            length: this.qtyLength,
            width: this.qtyWidth,
            height: this.qtyHeight,
          }
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  quantityChange(action){
    this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
    let qty = Number(this.newArticleForm.get('QTE').value);
    if(action === 'increment'){
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }
    this.newArticleForm.get('QTE').setValue(qty.toString());
    setTimeout(() => {
      this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
    }, 50)
    this.rowTotalCalculation();
  }

  get currentArticleCAPrix(): AbstractControl {
    return this.newArticleForm.get('CAPRIX');
  }

  async onPriceChanged(event?: any) {    
    if(!this.PU_vente_changable && (+this.currentArticle.calculated_price > 0)) {
      this.authorizedLinePrixUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '17', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        event.preventDefault();
        return;
      }
      
      this.authorizedLinePrixUser = authorizationResult.data;
      this.PU_vente_changable = true;
    }

    this.rowTotalCalculation();

    // While forcing prix, make a copy of it into CAPRIX form control
    let entered_prix = this.newArticleForm.get('NEW_PRIX').value;
    this.currentArticleCAPrix.setValue(entered_prix);
  }

  defineRowColor() {
    let colorClass: string = 'devis_en_cours';

    if(this.previousPanier === undefined || this.previousPanier == null) {
      return '';
    }

    if(this.previousPanier.statut == 2) {
      colorClass = 'devis_valide';
    } 
    
    if (this.previousPanier.statut == 1) {
      colorClass = 'devis_en_cours';
    }

    return colorClass;
  }

  rowTotalCalculation() {
    const price = this.newArticleForm.get('NEW_PRIX').value;
    const qty = this.newArticleForm.get('QTE').value;

    if(isNaN(price) || isNaN(qty)) {
      this.newArticleForm.patchValue({
        ROW_TOTAL: this.utilService.formatMaskCompat(0)
      });
      return;
    }

    const total_price = price * qty;

    this.newArticleForm.patchValue({
      ROW_TOTAL: this.utilService.formatMaskCompat(total_price)
    });
  }

  submitBondeVente(type: 'devis_submit' | 'vente_validate' = 'devis_submit') {
    if(this.previousPanier === undefined || this.previousPanier === null || (this.previousPanier && this.previousPanier.articles.length === 0)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if(this.previousPanier.client === undefined || this.previousPanier.client === null) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonclientobligat')).subscribe(
        (res) => {
          this.step = 2;
          this.devisSelectedTab = 0;
          setTimeout(() => {
            this.focusClientSearch = true;
          }, 400);
        }
      );
      return;
    }

    if (
        (this.previousPanier.client.gsm === undefined || this.previousPanier.client.gsm === null || this.previousPanier.client.gsm === '')
        && (this.previousPanier.client.tel === undefined || this.previousPanier.client.tel === null || this.previousPanier.client.tel === '')
    ) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclienttelnatelmand'));
      return;
    }

    if (this.emailMandatory
        && (this.previousPanier.client.email === undefined || this.previousPanier.client.email === null || this.previousPanier.client.email === '')
    ) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclientemailmand'));
      return;
    }

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
    
    let devisPanier: BonPanier = {
      ...this.previousPanier,
      comm: this.devisComments !== null?this.devisComments:'',
      dtmaj: current_date
    };

    let devisData = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon,
      pPanier: devisPanier,
      IDSOC: Constants.IDSOC,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };

    if(type === 'vente_validate') {  
      this.loadingService.show();
      this.editDevis(devisData, false).then(
        (data: any) => {
          this.validationDevis();
        }
      );    
    } else {
      this.loadingService.show();
      this.editDevis(devisData).then(
        (data: any) => {
          this.router.navigate(['/devis']);
        }
      );
    }
  }

  async validationEnBonDeVente() {
    if(this.previousPanier === undefined || this.previousPanier === null || (this.previousPanier && this.previousPanier.articles.length === 0)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if(this.previousPanier.statut == 2) {
      if(this.userPermission['4_0'] && !this.userPermission['4_1']) { // Checking whether the user is having access to Bon de vente or not.
        this.router.navigate(['/detail-bon-de-vente', this.bonNumBon, this.previousPanier.mag]);
        return;
      }

      this.router.navigate(['/modify-bon-de-vente', this.bonNumBon, this.previousPanier.mag]);
      return;
    }

    if(this.previousPanier.client === undefined || this.previousPanier.client === null) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonclientobligat')).subscribe(
        (res) => {
          this.step = 2;
          this.devisSelectedTab = 0;
          setTimeout(() => {
            this.focusClientSearch = true;
          }, 400);
        }
      );
      return;
    }

    const confirmation: any = await this.validationConfirmation();
    if(!confirmation || (confirmation && confirmation.status && confirmation.status !== 'OK')) {
      this.loadingService.hide();
      return;
    }
    
    if(!this.userPermission['0_3']) {
      this.authorizedValidationVenteUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '3', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedValidationVenteUser = authorizationResult.data;
    }

    this.submitBondeVente('vente_validate');
  }

  async validationDevis() {
    const data = {
      pMag: this.USERCMINT,
      pNumBon: this.bonNumBon,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST
    };

    data.Id_User = (this.authorizedValidationVenteUser !== undefined && this.authorizedValidationVenteUser !== null)?this.authorizedValidationVenteUser.CIINT:this.user.CIINT;

    this.devisService.validateDevis(data).subscribe(
      async (resp: any) => {
        if(resp.statusCode == 200) {
          if(resp.data.ErrorCode == 0) {
            if (this.authorizedValidationVenteUser !== undefined && this.authorizedValidationVenteUser !== null) {
              await this._addValidationVenteHistory(this.bonNumBon);
            }

            this.storageService.removeItem('devisListData');

            let redirectTo = '/modify-bon-de-vente';
            if(this.userPermission['24_0'] && !this.userPermission['24_1']) {
              redirectTo = '/detail-bon-de-vente';
            }

            const panier = this.previousPanier;
            const panierArticles = this.listBonVenteArticles;

            this.router.navigate([redirectTo, resp.data.NumBon, this.previousPanier.mag]).then(
              () => {
                let EtatGAvail = false;
                panier.articles.map((_r) => {
                  const articleData = panierArticles.find((_ar) => _ar.CDART == _r.cdart);
                  if(articleData.etatart === 'G') {
                    EtatGAvail = true;
                  }
                  return _r;
                });
                if(EtatGAvail) {
                  this.etatArtListIdsPopup(panier, panierArticles);
                }

                this.loadingService.hide();
              }
            );
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )
  }

  etatArtListIdsPopup(panier: BonPanier, articlesList: Article[]): void {
    let etatArticles = [];
    panier.articles.map((_r) => {
      const articleData = articlesList.find((_ar) => _ar.CDART == _r.cdart);
      if(articleData.etatart === 'G') {
        etatArticles.push(_r.cdart);
      }

      return _r;
    });

    const message = `
    <h1 class="text-left mb-3">${this.translateService.instant('kartsanssuiteids', { ARTICLE_IDS: etatArticles.join(', ') })}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        okButtonOnly: true
      }     
    });
  }

  validationConfirmation(): Promise<any> {
    const message = `
    <h1 class="text-left mb-3">${this.translateService.instant('kkvaliddevisconfirm')}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'), this.translateService.instant('ksno')]
      }     
    });

    return dialogRef.afterClosed().toPromise();    
  }

  openUpArticleGroups(listGroups: any, currentArticle?: Article, TYPECDE?: string): Promise<any> {
    const dialogRef = this.dialog.open(VenteArticleGroupsComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier,
          listArticleGroups: listGroups,
          currentItemFormData: undefined,
          TYPECDE: TYPECDE,
          currentArticle: currentArticle || undefined,
          typeDevis: false
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  openUpArticleDetail(articleData: Article) {
    const dialogRef = this.dialog.open(ArticleDetailComponent,
      {
        height: this.isMobile?'100%':undefined,
        data: {
          displayType: 'dialog',
          CDART: articleData.CDART,
          articleData: articleData
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        
      }      
    });
  }

  getMagasins():Promise<any> {
    return new Promise((resolve, reject) => {
      this.listMagasins = [];
      this.loginService.getMagasins().subscribe(
      (data: any) => {
          if (data.length > 0) {
            this.listMagasins = [...data];
          }
          resolve(data);
      }, 
      (error: any) => {        
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
      });
    });
  }

  getDevisDataByNumBon(numBon: string) {
    if(numBon == 'undefined' || numBon == 'null') {
      return;
    }

    this.devisService.getDevisByNumBon(numBon, this.USERCMINT).subscribe(
      (resp) => {
        if(resp.statusCode == 200) {
          if(resp.data.ErrorCode == 0) {
            this.bonNumBon = resp.data.NumBon;
            this.previousPanier = resp.data.Panier;
            this.listBonVenteItems = this.previousPanier.articles;

            this.listBonVenteArticles = resp.data.customArticles;
            this.previousPanierClient = resp.data.customClient;
            this.chosenClient = this.previousPanierClient;

            if(this.previousPanier.numfid !== '' && this.previousPanier.numfid !== null) {
              this.previousFIDClient = resp.data.fidClient;
            }

            this.storageService.setItem('previousBonDevis', this.previousPanier);
            this.storageService.setItem('previousNumBon', this.bonNumBon);

            this.refreshByPanier(this.previousPanier);
            this.loadingService.hide();
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
            
            this.__clearPageVars();
            this.router.navigate(['devis']);
          }
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }        
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )
  }

  canExit() {
    if((this.bonNumBon !== undefined && this.previousPanier !== undefined) && (this.listBonVenteItems.length == 0 || this.devisComments != this.previousPanier.comm || this.previousPanier.client == null)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kdevgoodsalesnotval'));
      return false;
    }

    return true;
  }

  returnBack() {
    this.router.navigate(['/devis']);
  }

  openUpDetailStock() {
    const dialogRef = this.dialog.open(ArticleStockComponent,
      {
        minWidth: "50vw",
        autoFocus: false,
        data: {
          NUMBON: this.bonNumBon,
          PANIER: this.previousPanier,
          articleData: this.currentArticle,
          CAINT: this.currentArticle.CAINT,
          stockBlocageEditable: true,
          bonVenteFlow: true,
          flowType: this.flowType
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(result.STOCK_DISPO !== undefined && result.STOCK_DISPO !== null) {
            this.currentArticle.STOCKDISPO = result.STOCK_DISPO;
            this.newArticleForm.get('STOCKDISPO').setValue(this.decimalPipe.transform(result.STOCK_DISPO));
          }
      }
    });
  }

  async openUpRemiseList(type?: number, totalAmount?: number, newLine?: boolean) {
    if(!this.previousPanier && (type === 1 && !this.currentArticle)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if(newLine === true && !this.userPermission['0_1']) {
      this.authorizedLineRemiseUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '1', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedLineRemiseUser = authorizationResult.data;
    }

    if(newLine !== true && !this.userPermission['0_5']) {
      this.authorizedTotalRemiseUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '5', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedTotalRemiseUser = authorizationResult.data;
    }

    const dialogRef = this.dialog.open(RemiseListComponent,
      {
        data: {
          remiseType: type !== undefined?type:2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined?totalAmount:0, // Total amount to calculate discount
          previousChosenRemise: type !== undefined?(type === 1?this.chosenLineRemise:this.chosenRemise):this.chosenRemise,
          panier: this.previousPanier,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(type === 1 && (totalAmount === undefined || totalAmount == 0)) {
        this.newPrixVente.nativeElement.select();
        this.newPrixVente.nativeElement.focus();
      }

      if(result && result.status === "success") {        
        if(type === 1) {
          this.chosenLineRemise = [...result.data];
        } else {
          this.chosenRemise = [...result.data];
        }

        if(newLine === true) {
          if(result.data !== undefined && result.data !== null) {
            const qty = this.newArticleForm.get('QTE').value;
            if(result.data.length !== 0) {
              let row_total = 0,
                  rowTotalDiscount = 0, 
                  rowTotalPercentage = 0,
                  discountedPrix = 0;
  
              for(let j=0;j < this.chosenLineRemise.length;j++) {
                const remPercentage = +this.chosenLineRemise[j].REMMTAUX;
                
                let remTotal = this.currentArticleCAPrix.value * (remPercentage / 100);
                if(discountedPrix > 0) {
                  remTotal = discountedPrix * (remPercentage / 100);
                  discountedPrix = discountedPrix - remTotal;
                } else {
                  remTotal = this.currentArticleCAPrix.value * (remPercentage / 100);
                  discountedPrix = this.currentArticleCAPrix.value - remTotal;
                }

                rowTotalDiscount += remTotal;
                rowTotalPercentage += remPercentage;
              }

              const totalAfterDiscount = this.currentArticleCAPrix.value - rowTotalDiscount;
              row_total = totalAfterDiscount * qty;

              //Discount percentage calculation
              let newRemisePercent = ((this.currentArticleCAPrix.value - totalAfterDiscount) / this.currentArticleCAPrix.value) * 100;
              
              this.newArticleForm.get('ROW_TOTAL').setValue(this.utilService.formatMaskCompat(row_total));
              this.newArticleForm.get('NEW_PRIX').setValue(this.utilService.formatMaskCompat(+totalAfterDiscount.toFixed(2)));
              this.newArticleForm.get('MOTANT').setValue(this.utilService.formatMaskCompat(newRemisePercent));
            } else {
              const row_total = this.currentArticleCAPrix.value * qty;
              this.newArticleForm.get('ROW_TOTAL').setValue(this.utilService.formatMaskCompat(row_total));
              this.newArticleForm.get('NEW_PRIX').setValue(this.utilService.formatMaskCompat(this.currentArticleCAPrix.value));
              this.newArticleForm.get('MOTANT').setValue(this.utilService.formatMaskCompat(0));
            }
          }
        }

        if(newLine !== true && this.previousPanier !== undefined && this.previousPanier !== null) {
          let grand_total_amount = 0;
          const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

          this.previousPanier.articles.map((row) => {
            grand_total_amount += row.montant;
          });

          this.loadingService.show();
          
          if(type === 2 || type === undefined) {
            // Total remise update
            let dataRem = "";
            let remTotal = 0.0;
            let remPercentage = 0.0;            

            if(this.chosenRemise !== undefined && this.chosenRemise !== null) {
              if(this.chosenRemise.length === 0) {
                // Remise 0
                remPercentage = 0.0;
                remTotal = 0.0;
                dataRem = '';
              } else {
                let remFormat: string[] = [];
                for(let j=0;j < this.chosenRemise.length;j++) {
                  let currentRemPercentage = +this.chosenRemise[j].REMMTAUX;
                  remPercentage += currentRemPercentage;
                  remTotal += grand_total_amount * (currentRemPercentage / 100);
                  remFormat.push(this.chosenRemise[j].REMMCODE+'#'+(+currentRemPercentage)+'#'+this.utilService.formatMaskCompat(remTotal));
                }
                dataRem = remFormat.join(';');
              }
            } else {
              // Previous Panier Remise data
              remPercentage = this.previousPanier.remise;
              remTotal = this.previousPanier.remtot;
              dataRem = this.previousPanier.datarem;
            }

            let devisPanier: BonPanier = {
              ...this.previousPanier,            
              comm: this.devisComments !== null?this.devisComments:this.previousPanier.comm,
              dtmaj : current_date,
  
              remtot : remTotal,            
              datarem : dataRem,                                    
              remise: remPercentage,
            };
        
            let devisData = {
              pMag: this.USERCMINT,
              pNumBon: this.bonNumBon,
              pPanier: devisPanier,
              IDSOC: Constants.IDSOC,
              Id_User: this.user.CIINT,
              Id_InstUser: Constants.CIINST,
            };

            const chosenRemiseBcup = this.chosenRemise;
      
            this.editDevis(devisData).then(() => {
              if(chosenRemiseBcup !== undefined && chosenRemiseBcup !== null && chosenRemiseBcup.length > 0) {
                try {
                  this._addRemiseHistory(remTotal, this.user.CIINT, remPercentage, false);

                  if (this.authorizedTotalRemiseUser !== undefined && this.authorizedTotalRemiseUser !== null) {
                    this._addRemiseHistory(remTotal, this.authorizedTotalRemiseUser.CIINT, remPercentage);
                  }
                } catch(err) {
                  this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                }
              }
            });
          } else {
            // Line Remise update
            
          }
        }
      }      
    });
  }

  _addRemiseHistory(discountAmount: number, CIINT: string, discountPercentage: number, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REMISE_EN_PIED,
      NUMBON: this.bonNumBon,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: discountAmount,
      VALUER2: discountPercentage
    }, HISTORY_AUTHORIZATION);
  }

  _addLigneRemiseHistory(CDART: string, CIINT: string, oldPrix: number, newPrix: number, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REMISE_EN_LIGNE,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART,
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: oldPrix,
      VALUER2: newPrix,
    }, HISTORY_AUTHORIZATION);
  }

  _addChangementPrixHistory(CDART: string, MOTIF: string, oldPrix: number, newPrix: number): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_CHANGEMENT_DE_PRIX,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART ? CDART : this.currentArticle.CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) ? this.authorizedLinePrixUser.CIINT : this.user.CIINT,
      MOTIF: MOTIF,
      VALUER1: oldPrix,
      VALUER2: newPrix,
    });
  }

  _addValidationVenteHistory(NUMBON: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_VALIDATION_BON_DE_COMMANDE,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedValidationVenteUser !== undefined && this.authorizedValidationVenteUser !== null) ? this.authorizedValidationVenteUser.CIINT : this.user.CIINT,
      MOTIF: 'Devis',
      VALUER1: null,
      VALUER2: null,
      TYPE_OPERAT: 'devis',
    });
  }

  openUpAddedRemiseList(type?: number, totalAmount?: number, item?: PanierArticle) {
    if(!this.previousPanier && (type === 1 && !this.currentArticle)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const currentArticleData = item?this.listBonVenteArticles.find((el) => el.CDART == item.cdart):undefined;

    const dialogRef = this.dialog.open(RemiseDetailComponent,
      {
        data: {
          remiseType: type !== undefined?type:2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined?totalAmount:0, // Total amount to calculate discount
          chosenRemise: type !== undefined?(type === 1?this.chosenLineRemise:this.chosenRemise):this.chosenRemise,
          panier: this.previousPanier,
          panier_article: item,
          custom_article: currentArticleData,
          USERCMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
              
      }      
    });
  }

  openUpPrixForceMotif() {
    const dialogRef = this.dialog.open(MotifForcePrixComponent,
      {
        data: {
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(result.data.force_motif !== '') {
            this.newArticleForm.get('PRIX_FORCE').setValue(true);
            this.newArticleForm.get('MOTIF_PRIX').setValue(result.data.force_motif);
            this.newArticleForm.get('MOTIF_PRIX_LBL').setValue(result.data.MOTIF_LABEL);
            
            this.loadingService.show();
            this.addNewLineItem();
          } else {
            this.newArticleForm.get('PRIX_FORCE').setValue(false);
            this.newArticleForm.get('MOTIF_PRIX').setValue('');
            this.newArticleForm.get('MOTIF_PRIX_LBL').setValue('');

            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonprixoblig'));
          }          
      }
    });
  }

  getMagComplementData() {
    return new Promise((resolve, reject) => {
      this.magComplementService.getMagComplementData(this.USERCMINT).subscribe(
        (resp) => {          
          if(resp.statusCode == 200) {
            this.magComplementData = resp.data[0];
          }
          resolve(resp);
        },
        error => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
        }
      );
    });
  }

  onCloseBondeVente() {
    if(this.previousPanier !== undefined && this.previousPanier !== null) {
      this.deleteConfirmation(this.bonNumBon, this.previousPanier);
      return;
    }
    
    this.router.navigate(['/devis']);
  }

  deleteConfirmation(numBon: string, item: BonPanier): void {
    const message = `
    <h1 class="text-left mb-3">${this.translateService.instant('ksuppridevis')}</h1>
    <h1 class="text-left pl-2">        
      ${this.translateService.instant('kbonNum')} : ${numBon} <br>
      ${this.translateService.instant('kbonpour')} : ${item.client?.nom != null?item.client?.nom:''}${item.client?.prenom != null?' '+item.client?.prenom:''}<br>
      ${this.translateService.instant('kbonmontant')} : ${this.currencyPipe.transform(item.montant, null, false)}
    </h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.loadingService.show();
        this.deleteDevis({ pMag: this.USERCMINT, pNumBon: numBon, Id_User: this.user.CIINT, Id_InstUser: Constants.CIINST });
      }
    });    
  }

  __clearPageVars() {
    this.storageService.removeItem('previousNumBon');
    this.storageService.removeItem('previousBonDevis');

    this.bonNumBon = undefined;
    this.previousPanier = undefined;
    this.listBonVenteItems = undefined;
    this.listBonVenteArticles = undefined;
    this.devisComments = '';

    this.currentArticle = undefined;
    this.newArticleForm.patchValue({
      CALIB1: '',            
      CAPRIX: '',
      NEW_PRIX: this.utilService.formatMaskCompat(0),
      ROW_TOTAL: '',
      TYPECDE: '',
      STOCKDISPO: ''
    });

    this.qtyLength = 0;
    this.qtyWidth = 0;
    this.qtyHeight = 0;
    
    this.newArticleForm.get('QTE').disable({ onlySelf: true });
    this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
    this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
  }

  deleteDevis(data: { pMag: number, pNumBon: string, Id_User: string, Id_InstUser: string }) {    
    this.devisService.removeDevis(data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          if(resp.data.ErrorCode == 0) {
            this.__clearPageVars();
            this.storageService.removeItem('devisListData');
            this.router.navigate(['/devis']);
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }          
        } else {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }        
      },
      error => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  onSwipe(e: any, index: number, item: PanierArticle) {
    if(item.opt > 0 || (this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21'])) {
      return;
    }

    if(e.deltaX > 0) {
      // Right Swipe
      const element = document.getElementById('swipeItem-' + index);
      element.style.transform = 'translateX(0)'
      element.classList.remove('swiped');
    } else {
      // Left Swipe
      this.__resetPreviousSwipes('.mat-list-item');

      const element = document.getElementById('swipeItem-' + index);
      element.classList.add('swiped');
      element.style.transform = 'translateX(-100px)'
    }
  }

  __resetPreviousSwipes(listEl: string) {
    const allSwipeAbles = document.querySelectorAll(listEl);
    allSwipeAbles.forEach((el: any, i) => {
      el.classList.remove('swiped');
      el.style.transform = 'translateX(0)';
    })
  }

  supprimerLineItem(currentItem: PanierArticle) {
    if(this.previousPanier === undefined || this.previousPanier === null) {      
      return;  
    }

    this.loadingService.show();    
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let article_data: PanierArticle[] = [];
    article_data = this.previousPanier.articles.filter(
      (item) => {
        if(currentItem.opt < 0 && item.opt == Math.abs(currentItem.opt)) {
          return false;
        }

        return item.idlig != currentItem.idlig;
      }
    );    

    let bondeVenteData = {
      ...this.previousPanier,
      articles: article_data,    
      dtmaj : current_date
    };

    let devisData = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon,
      pPanier: bondeVenteData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST
    };

    this.editDevis(devisData);
  }

  openUpSearchArticle(event: any = '', filterData?: any) {
    if(event){
      event.preventDefault();
    }

    let passData = {
      CMINT: this.USERCMINT,
      flowType: 'devis',
      CDART: this.newArticleForm.get('CDART').value
    };

    if(filterData !== undefined && filterData != null) {
      passData = {
        CMINT: this.USERCMINT,
        flowType: 'devis',
        ...filterData
      };
    }

    const dialogRef = this.dialog.open(SearchArticleComponent,
      {
        maxHeight: this.isMobile?'95vh':'90vh', 
        data: passData
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(result.data !== undefined) {
            if(result.hasOwnProperty('multi') && result.multi === true) {
              this.__processMultiChosenArticles(result.data); // Processing chosen multi articles
            } else {
              const chosenArticle = result.data;
              this.currentArticle = chosenArticle;

              this.newArticleForm.patchValue({
                CDART: this.currentArticle.CDART,
                CALIB1: this.currentArticle.CALIB3,
                CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
                NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
                ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
                TYPECDE: 'EI',
                STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
                GROUP_AVAIL: +this.currentArticle.GROUP_COUNT > 0
              });

              this.newArticleForm.get('QTE').enable({ onlySelf: true });
              this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
              this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

              if(this.cdartInput && this.isMobile) {
                this.cdartInput.nativeElement.select();
              }

              if(this.newArticleQty && !this.isMobile){
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
                this.newArticleQty.nativeElement.focus();
                this.newArticleQty.nativeElement.select();
                setTimeout(() => {
                  this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
                }, 50)
              }

              this.definingTypeDeRules();
            }
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CALIB1: '',            
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: 'EI',
              STOCKDISPO: '',
              GROUP_AVAIL: false
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;
            
            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });             
          }
      }      
    });
  }

  async __processMultiChosenArticles(data: any[]) {
    this.loadingService.show();
    console.log('Processing Multi Articles: ', data);

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
    let processedRules = this.userService.processRulesParams(this.paramsList);
    let grand_total_amount = 0;
    let devisPanier = {
      articles: [],
      mag: this.USERCMINT,
      clientlivre : "",
      clientfacture : "",
      numfid: '',
      comm : this.devisComments !== null?this.devisComments:"",
      idinstuser : +this.user.IDINSTINT,
      iduser : +this.user.CIINT,
      libuser: this.currentUserName,

      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.currentUserName,

      userexterne : "",
      numdevis: "",

      dtliv : '1899-12-28T00:00:00.000+01:00', // Livraison Date
      dtlivsouhait : '1899-12-28T00:00:00.000+01:00', //
      livjour : "", // A (Afternoon) / M (Morning)
      livconfirm : false, // Confirmation de livraison
      livinfo1 : "", // Livraison Info 1
      livinfo2 : "", // Livraison Info 2
      livdeport : false, // Livraison Transport
      livdirect : false, // Livraison Directe

      transporteur : "",
      numexterne : "",
      remtot : 0,
      datafid : "",
      datafidaco : "",
      dataopeco : "",
      datarem : "",
      vteemp : 0,
      pseudo : "",
      place : "",
      infocde : "",
      numcourt : "",
      statut : 1,
      dtsaisie : current_date,
      dtmaj : current_date,
      dtech : '1899-12-28T00:00:00.000+01:00',
      dtrel : '1899-12-28T00:00:00.000+01:00',
      dtpromesse : '1899-12-28T00:00:00.000+01:00',
      ecartpromesse : "",
      remise: 0,
      canal : 0,
      montant : grand_total_amount,
      montantrestant : 0,
      preparation: false,
      retrait1h: false,
      montantHT: grand_total_amount,
      typecde: '',
      paiements: [],
      infosfac: {
        totEI: 0,
        totED: 0,
        totBL: 0,
        totE2: 0,
        mntED: 0,
        mntBL: 0,
        mntE2: 0,
        pourED: 100,
        pourBL: 100,
        pourE2: 20
      }
    };

    let client_data: PanierClient;

    if(this.chosenClient && this.chosenClient !== null) {
      client_data = {
        mag: +this.chosenClient.CMINT,
        civil: +this.chosenClient.IDCIV,
        nom: this.chosenClient.TRAISOC,
        prenom: this.chosenClient.TPRENOM,
        adr1: this.chosenClient.TADR1,
        adr2: this.chosenClient.TADR2,
        adr3: this.chosenClient.TADR3,
        adr4: this.chosenClient.TADR4,
        codpos: this.chosenClient.CODPOS,
        ville: this.chosenClient.TVILLE,
        pays: this.chosenClient.CODPAYS,
        tel: this.chosenClient.TTEL,
        fax: this.chosenClient.TFAX,
        gsm: this.chosenClient.TPORT,
        email: this.chosenClient.TEMAIL,
        idinstcli: +Constants.CIINST,
        idcli: +this.chosenClient.IDTIERS
      }
    }

    if(this.previousPanier !== undefined && this.previousPanier !== null && this.previousPanier.client !== undefined && this.previousPanier.client !== null) {
      client_data = this.previousPanier.client;
    }

    let dataRem = (this.previousPanier !== undefined && this.previousPanier !== null)?this.previousPanier.datarem:"";
    let remTotal = (this.previousPanier !== undefined && this.previousPanier !== null)?this.previousPanier.remtot:0;
    let remPercentage = (this.previousPanier !== undefined && this.previousPanier !== null)?this.previousPanier.remise:0;

    let article_data: PanierArticle[] = [];

    if(this.previousPanier !== undefined && this.previousPanier !== null && this.previousPanier.articles !== undefined && this.previousPanier.articles !== null && this.previousPanier.articles.length > 0) {
      article_data = [...this.previousPanier.articles];
    }

    for (const rowMultiArticle of data) {
      const row: { cdart: string, status: boolean, data: Article } = rowMultiArticle;
      console.log("ROW: ", row);

      const ROW_PRIX = row.data.calculated_price?+row.data.calculated_price:0;
      const ROW_QTY = 1;
      const ROW_TOTAL = +ROW_PRIX * ROW_QTY;

      let currentCdart = row.cdart;
      let currentOpt: number = 0;
      let currentIdLigne: number = 1;

      let description = '';
      if(row.data.CAUNVTE === 'M3') {
        description = `L ${this.qtyLength} x l ${this.qtyWidth} x h ${this.qtyHeight}`;
      }

      let ROW_TYPECDE = "EI";
      if(row.data.CAOKLIV == "-1") {
        ROW_TYPECDE = "BL";
      }


      if(this.previousPanier !== undefined && this.previousPanier !== null) {
        if(+row.data.GROUP_COUNT > 0) {
          article_data.map((_item_art: PanierArticle) => {
            if(_item_art.opt < 0) {
              currentOpt = _item_art.opt;
            }
          });

          currentOpt = currentOpt - 1;
        }

        currentIdLigne = article_data.length + 1;
      } else {
        if(+row.data.GROUP_COUNT > 0) {
          currentOpt = -1;
        }
      }

      article_data.push({
        idlig: currentIdLigne,
        cdart: currentCdart,
        quantite: parseFloat(ROW_QTY.toString()),
        prix: parseFloat(ROW_PRIX.toString()), // Article prix
        prixforce: false,
        motifprixforce: "",
        commlig1: "",
        commlig2: "",
        prixachatHT: 0.0,
        prixachatTTC: 0.0,
        tauxTVA: "", // Optional
        typecde: ROW_TYPECDE,
        remlig: 0, // Total remise amount of the row
        remtot: 0,
        datafid: "",
        datafidaco: "",
        dataopeco: "" ,
        datarem: "",
        okdiff: false,
        bundle: 0,
        opt: currentOpt,
        lib: row.data.CALIB3,
        idinstuser: +this.user.IDINSTINT,
        iduser: +this.user.CIINT,
        libuser: this.currentUserName,
        dtpromesse: current_date, // 2018-03-27 10:04:41
        coinsolde: false,
        expo: false,
        motifcde: '',
        numcde: '',
        prixupv: parseFloat(ROW_PRIX.toString()), // New Price (Typeable) - PU Ven.
        montant: parseFloat(ROW_TOTAL.toString()), // Row Subtotal - PV Total
        remise: 0,
        emplacement: "",
        statut: row.data.CAECODE === 'A'?-1:0,
        dtmaj: current_date
      });

      if(+row.data.GROUP_COUNT > 0) {
        currentOpt = Math.abs(currentOpt);

        await this._searchMultiArticleGroupsCAINT(row.data.CAINT, false);

        if(this.articleGroupsRawData.some((_r: any) => _r.TYPECDE == null || ROW_TYPECDE.indexOf(_r.TYPECDE) > -1)) {
          const groupsPopupResult = await this.openUpArticleGroups(this.listArticleGroups, row.data, ROW_TYPECDE);

          if(groupsPopupResult !== undefined && groupsPopupResult !== null && groupsPopupResult.status !== undefined && groupsPopupResult.status === 'success') {
            this.currentArticleChosenGroups = groupsPopupResult.data;

            this.currentArticleChosenGroups.GROUPS.map((_row_group: any) => {
              _row_group.GROUP_CHOSEN_OPTIONS.map((_row_option: any) => {
                if(_row_option.SELECTED) {
                  currentIdLigne = currentIdLigne + 1;

                  article_data.push({
                    idlig: currentIdLigne,
                    cdart: _row_option.CDART,
                    quantite: parseFloat(ROW_QTY.toString()),
                    prix: parseFloat(_row_option.PRIX.toString()), // Article prix
                    prixforce: false,
                    motifprixforce: "",
                    commlig1: "",
                    commlig2: "",
                    prixachatHT: 0.0,
                    prixachatTTC: 0.0,
                    tauxTVA: "", // Optional
                    typecde: ROW_TYPECDE,
                    remlig: 0, // Total remise amount of the row
                    remtot: 0,
                    datafid: "",
                    datafidaco: "",
                    dataopeco: "" ,
                    datarem: "",
                    okdiff: false,
                    bundle: 0,
                    opt: currentOpt,
                    lib: _row_option.CALIB,
                    idinstuser: +this.user.IDINSTINT,
                    iduser: +this.user.CIINT,
                    libuser: this.currentUserName,
                    dtpromesse: current_date, // 2018-03-27 10:04:41
                    coinsolde: false,
                    expo: false,
                    motifcde: '',
                    numcde: '',
                    prixupv: parseFloat(_row_option.PRIX.toString()), // New Price (Typeable) => PU Ven.
                    montant: parseFloat((_row_option.PRIX * _row_option.QTY).toString()), // Row Subtotal - PV Total
                    remise: 0,
                    emplacement: "",
                    statut: 0,
                    dtmaj: current_date
                  });
                }
              })
            })
          }
        }
      }
    }

    article_data.map((row) => {
      grand_total_amount += row.montant;
    });

    if(this.previousPanier !== undefined && this.previousPanier !== null) {
      devisPanier = {
        ...this.previousPanier,
        articles: article_data,
        clientlivre : this.previousPanier.clientlivre !== null?this.previousPanier.clientlivre:"",
        clientfacture : this.previousPanier.clientfacture !== null?this.previousPanier.clientfacture:"",
        comm: this.devisComments !== null?this.devisComments:this.previousPanier.comm,

        idinstusermaj: +this.user.IDINSTINT,
        idusermaj: +this.user.CIINT,
        libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.currentUserName,

        remtot : remTotal,
        datarem : dataRem,
        dtmaj : current_date,
        remise: remPercentage,
        montant : grand_total_amount,
        montantHT: grand_total_amount,
        infosfac: this.previousPanier.infosfac
      };
    } else {
      devisPanier.articles = article_data;
      devisPanier.remtot = remTotal;
      devisPanier.datarem = dataRem;
      devisPanier.remise = remPercentage;
      devisPanier.montant = grand_total_amount;
      devisPanier.montantHT = grand_total_amount;
    }

    if(this.chosenFidelite) {
      devisPanier['numfid'] = this.chosenFidelite.NUMFID;
    }

    if(client_data !== undefined && client_data !== null) {
      devisPanier['client'] = client_data;
    } else {
      devisPanier['client'] = "";
    }

    if(this.previousPanier !== undefined && this.previousPanier !== null) {
      let devisData = {
        pMag: this.USERCMINT,
        pNumBon: this.bonNumBon,
        pPanier: devisPanier,
        IDSOC: Constants.IDSOC,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST,
      };

      this.editDevis(devisData).then(
          async (resp: any) => {
            this.commander = false;
            this.openCommandeDetail();
          }
      );
    } else {
      let defaultDiffereDays = 7;
      try {
        defaultDiffereDays = await this.userService.getRuleParamsData(20, 50, 'VINT2');
      } catch(err) {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }

      const devisLimiteDate = moment().add(defaultDiffereDays, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      devisPanier.dtech = devisLimiteDate;

      devisPanier['Id_User'] = this.user.CIINT;
      devisPanier['Id_InstUser'] = Constants.CIINST;

      this.createNewDevis(devisPanier).then(
          async () => {
            this.router.navigate([], {
              queryParams: {
                numbon: this.bonNumBon
              }
            }).then(() => {
              this.storageService.setItem('previousBonDevis', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);
            });
          }
      );
      this.openCommandeDetail();
    }
  }

  viewArticleDetail(){
    if(this.isMobile){
      const dialogRef = this.dialog.open(ArticleDetailComponent,{
          width: '60vw',
          height: '100%',
          data: {
             articleData: this.article,
             displayType: 'dialog'
          }
        }
      );      
    } 
  }

  openAdvancedSearch(){ 
    const dialogRef = this.dialog.open(ArticleFilterComponent,{
        width: '60vw',
        maxHeight: this.isMobile?'95vh':undefined, 
        autoFocus: false,
        data: {
          fetchType: 'new_load'
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {    
        const data = {
          formType: 'advancedSearch',
          filterData: result
        };
        this.openUpSearchArticle(undefined, data);
      }      
    });
  }

  closeAdvancedSearch(){        
    if(this.advancedDialogRef){
      this.advancedDialogRef.close();
    }
  } 
  allowNumeric(event) {
    const pattern = /^[0-9\.]+$/; 
    const inputChar = String.fromCharCode(event.keyCode);
    if (!pattern.test(inputChar)) {    
        // invalid character, prevent input
        event.preventDefault();
    }
  }

  _barCodeLogicCheck = (value: string, scanner = true) => {
    return new Promise((resolve, reject) => {
      if(value !== undefined && value != null) {
        
        if(value.indexOf('~') > -1) {
          const [CDART, PRIX] = value.split('~');
          this.newArticleForm.patchValue({ CDART });

          this.searchArticleCDART();
          resolve(CDART);
          return;  
        }

        if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
          let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
          CDART = CDART.replace(/^0+/, '');

          this.newArticleForm.patchValue({ CDART });

          this.searchArticleCDART();
          resolve(CDART);
          return;
        }

        if(scanner) {
          this.searchArticleByCMEAN(value);
          resolve(value);
        } else {
          reject(value);
        }
      }
    });
  }

  openScanArticle(e: any) {
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: this.isMobile?'95vh':undefined,
        autoFocus: false 
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        this._barCodeLogicCheck(result.data);
      }      
    });
  }

  openCommandeDetail(){
    this.step = 1;
  }

  async filterAndUpdateVenteType() {
    const filteredRules = this.typeVenteRules.data.filter((row) => !row.disabled);

    if (filteredRules.length === 0) {
      this.typeVenteRules.data = [];
      this.newArticleForm.get('TYPECDE').setValue('');
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kprix_commande_impossible_magasin')).toPromise();
      return;
    }

    this.newArticleForm.get('TYPECDE').setValue(filteredRules[0].value);
  }

  definingTypeDeRules() {
    let processedRules = this.userService.processRulesParams(this.paramsList);
    
    this.typeVenteRules = {
      title: "kbontypedevente",
      data: [
        {
          label: processedRules['20_3']['VSTR1'],
          value: "EI",
          disabled: (processedRules['20_3']['VBOOL1'] == '-1')?false:true
        },
        {
          label: processedRules['20_3']['VSTR2'],
          value: "ED",
          disabled: (processedRules['20_3']['VBOOL2'] == '-1')?false:true
        },
        {
          label: processedRules['20_3']['VSTR3'],
          value: "BL",
          disabled: (processedRules['20_3']['VBOOL3'] == '-1')?false:true
        },
      ]
    };

    this.filterAndUpdateVenteType();

    if(this.currentArticle.etatart === 'G') {
      this.commander = true;
    }

    const checkWefox = this.checkArticleWefoxStatus(this.currentArticle.CDART, processedRules);
    if (checkWefox.wefoxStat) {
      const typeCdeArt = checkWefox.wefoxType.split(Constants.WEFOX_TYPE_SEPARATOR);

      this.quantityDisabled = true;
      this.newArticleForm.get('QTE').disable({ onlySelf: true });

      if (checkWefox.wefoxTypeRestriction) {
        this.typeVenteRules.data = this.typeVenteRules.data.filter((row) => {
          return typeCdeArt.some((_r: string) => row.value.includes(_r));
        });

        this.newArticleForm.get('TYPECDE').setValue(typeCdeArt ? typeCdeArt[0] : '');
      }
    } else {
      this.quantityDisabled = false;
      this.newArticleForm.get('QTE').enable({ onlySelf: true });
    }
  }

  async getListParams(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getRulesParams(this.USERCMINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {              
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

}
