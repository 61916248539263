import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Constants } from '../constants';

import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class ArticleService {

    isMobile: boolean = false;

    constructor(private http: HttpClient, private deviceService: DeviceDetectorService) {
        this.isMobile = this.deviceService.isMobile();
    }

    searchArticles(data: any, page?: number): Observable<any> {
        let params = {};
        params = data;

        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        params['device'] = this.isMobile?'mobile':'pc';
        
        return this.http.post(Constants.API_ARTICLE_URL + 'searchArticle', params);
    }

    getCodeExterne(CAINT: any, IDMAG: any) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getCodeext', options);
    }

    getPromesse(data: any) {
        return this.http.post(Constants.API_ARTICLE_URL + 'getPromesse', data);
    }

    getSousFamileList(data: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = data;       
        
        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_ARTICLE_URL + 'getSousFamille', options);        
    }

    searchArticlesWithPrix(data: any, page?: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = {};       
        
        params = data;

        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_ARTICLE_URL + 'searchArticleprix', options);        
    }

    getArticleById(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        
        params['CAINT'] = data;
        params['IDSOC'] = Constants.IDSOC;

        const options: any = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_ARTICLE_URL + 'getArticleById', options);        
    }

    getComEnCours(CAINT: any, IDMAG: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getComEnCours', options);
    }

    getNombreE2enCours(CAINT: any, IDMAG: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getcalculE2', options);
    }

    getFournisseurData(CAINT: any, IDMAG: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getFournisseurData', options);
    }

    getListEntreprot(CAINT: any, IDMAG: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDINSTCAI'] = Constants.CIINST;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getEntrepotList', options);
    }

    getNivUn(data: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CSTNIV1'] = data;

        const options: any = {
            headers: headers,
            search: params
        };        
        
        return this.http.get(Constants.API_ARTICLE_URL + 'getNivUn', options);        
    }

    getNivDeux(CSTNIV1: any, CSTNIV2: any): Observable<any> {
    
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CSTNIV1'] = CSTNIV1;
        params['CSTNIV2'] = CSTNIV2;

        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getNivDeux', options);
    }

    getNivTrois(CSTNIV1: any, CSTNIV2: any, CSTNIV3: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CSTNIV1'] = CSTNIV1;
        params['CSTNIV2'] = CSTNIV2;
        params['CSTNIV3'] = CSTNIV3;

        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getNivTrois', options);
    }

    getNivQuatre(CSTNIV1: any, CSTNIV2: any, CSTNIV3: any, CSTNIV4: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CSTNIV1'] = CSTNIV1;
        params['CSTNIV2'] = CSTNIV2;
        params['CSTNIV3'] = CSTNIV3;
        params['CSTNIV4'] = CSTNIV4;


        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getNivQuatre', options);
    }

    getCodeCaisse(data: any, currentPage: string): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CAINT'] = data;

        params['IDSOC'] = Constants.IDSOC;
        params['currentPage'] = currentPage;
        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getCodeCaisse', options);
    }

    getCouchePrix(data: any, currentPage?: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = { ...data }

        params['IDSOC'] = Constants.IDSOC;
        if(currentPage) {
            params['currentPage'] = currentPage;
        }
        
        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getCouchePrix', options);
    }

    getDetailCodeMagasin(CAINT: string, IDMAG: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CAINT'] = CAINT;
        params['IDMAG'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;        
        
        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getCdeMagasin', options);
    }

    getDetailCodeEntreprot(CAINT: string, CMINT: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CAINT'] = CAINT;
        params['CMINT'] = CMINT;
        params['IDSOC'] = Constants.IDSOC;        
        
        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getCdeEntrepot', options);
    }

    getPrixArticle(data: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params[key] = data[key];
            }
        }
        params['idSoc'] = Constants.IDSOC;
        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getPrixArticle', options);
    }

    getStockDispo(data: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params[key] = data[key];
            }
        }
        params['idSoc'] = Constants.IDSOC;

        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'searchArticle', options);
    }

    searchArticleAdd(data: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CDART'] = data;
        params['idSoc'] = Constants.IDSOC;

        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'searchArticleAdd', options);
    }

    getTva(data: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CTVINT'] = data;

        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getTva', options);        
    }

    getMotifChangementPrix(): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getMotifChangementPrix', options);
    }
    
    getMotifChangementPrixById(data: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CHPCODE'] = data;

        const options: any = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getMotifChangementPrixById', options);
    }

    updateMotifBlocage(data: any): Observable<any> {
        const headers = new HttpHeaders();            

        const options = {
            headers: headers,
            params: data
        };        
        
        return this.http.get(Constants.API_STOCK_URL + 'addStockBlock', options);
    }

    updateBlocageExterne(data: any): Observable<any> {        
        return this.http.post(Constants.API_STOCK_URL + 'blockageExt', data);
    }

    updateDefaultStockLocation(data: any): Observable<any> {
        return this.http.post(Constants.API_ARTICLE_URL + 'stockDefiner', data);
    }

    updateQtyConfirmStockLocation(data: any): Observable<any> {
        return this.http.post(Constants.API_ARTICLE_URL + 'stockQtyConfirm', data);
    }

    transferStockLocation(data: any): Observable<any> {
        return this.http.post(Constants.API_ARTICLE_URL + 'stockempTransfer', data);
    }

    addNewStockLocation(data: any): Observable<any> {
        return this.http.post(Constants.API_ARTICLE_URL + 'stockempInsertion', data);
    }

    deleteStockLocation(data: any): Observable<any> {
        return this.http.post(Constants.API_ARTICLE_URL + 'stockempSupprimer', data);
    }

}