import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EditPostalCodeComponent } from '../edit-postal-code/edit-postal-code.component';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { NiveauSecuriteService } from 'providers/api/niveauSecuriteService';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'providers/constants';

@Component({
  selector: 'app-edit-niveau-de-securite',
  templateUrl: './edit-niveau-de-securite.component.html',
  styleUrls: ['./edit-niveau-de-securite.component.scss'],
  providers: [NiveauSecuriteService]
})
export class EditNiveauDeSecuriteComponent {

  editSecuriteLevelForm: UntypedFormGroup;

  isDisabled: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<EditPostalCodeComponent>,
              private formBuilder: UntypedFormBuilder,              
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private securiteLevelService: NiveauSecuriteService,
              private translateService: TranslateService) {
      this.editSecuriteLevelForm = this.formBuilder.group({
        TYPE: [{value: '', disabled: true}],
        NIVNOM: ['', [Validators.required]],
        NIVINT: ['']
      });

      this.editSecuriteLevelForm.patchValue({
        'TYPE': this.translateService.instant(this.data.niveau_type),
        'NIVNOM': this.data.NIVNOM,
        'NIVINT': this.data.NIVINT
      });
  }

  editFormSubmission(): void {
    this.loadingService.show();

    if(this.editSecuriteLevelForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kserror'), this.translateService.instant('ksmand'));

      return;
    }

    const form_data = {
      "NIVNOM" : this.editSecuriteLevelForm.value.NIVNOM,
      "NIVINT" :this.editSecuriteLevelForm.value.NIVINT,
      "IDSOC": Constants.IDSOC
    };
  
    this.securiteLevelService.updateSecuriteLevel(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('ksedit'));
          this.dialogRef.close(form_data);
        } else {
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kserrupdate'));          
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('ksconerr'), this.translateService.instant('ksconerrtry'));
      }
    )
  }

}
