import * as moment from 'moment';

export interface ReportMenuModel {
    label: string;
    value: string; 
    icon: string;
    param?: string;
    child?: ReportChildMenuModel[], 
    subChilds?: ReportSubChildMenuModel[]
}

export interface ReportChildMenuModel {
    label: string 
    value: string
    param?: string
}

export interface ReportSubChildMenuModel {
    label: string 
    value: string 
    pAttribut?: any
    parent: string 
    param?: string
    separateAccess?: boolean
    dateDisabled?: boolean;
    dateFilter?: 'from' | 'to' | 'both';
    dateMandatory?: boolean;
    dateFreedom?: boolean;
    magasinFilter?: boolean;
    defaultDate?: moment.Moment | undefined;
}

export const reportingMenuTree: ReportMenuModel[] = [
    {
        label: 'kreporting_attente', // Reportings en attente
        value: 'reporting_attente',
        icon: 'list',
        subChilds: []
    },
    {
        label: 'keditionctle',
        value: 'editions_de_controle',
        icon: 'check_circle_outline',
        child: [
            {
                label: 'kactspecial',
                value: 'liste_des_actions_speciales'
            },
            {
                label: 'ksuivibons',
                value: 'suivi_des_bons'
            },
            // {
            //     label: 'ksuividevis',
            //     value: 'suivi_des_devis'
            // }
        ],
        subChilds: [
            // --- liste_des_actions_speciales - Child starts ---
            {
                label: 'khisremise', // Historique des Remises
                value: '10',
                parent: 'liste_des_actions_speciales',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'khisprix', // Historique des Changements de prix
                value: '16',
                parent: 'liste_des_actions_speciales',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'khisforce', // Historique des ventes forcées
                value: '11',
                parent: 'liste_des_actions_speciales',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'khisorderhis', // Historique des commandes (E2) forcées
                value: '12',
                parent: 'liste_des_actions_speciales',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'khistickinfo', // Historique des retraits clients sans tickets informatique
                value: '13',
                parent: 'liste_des_actions_speciales',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'khismetvente', // Historique des déblocages métier vente
                value: '14',
                parent: 'liste_des_actions_speciales',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'khissupp', // Historique des suppressions
                value: '15',
                parent: 'liste_des_actions_speciales',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            // --- liste_des_actions_speciales - Child ends ---

            // --- suivi_des_bons - Child starts ---
            {
                label: 'klistimpaye', // Liste des bons Soldés avec impayé
                value: '20',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                dateMandatory: false,
                defaultDate: undefined
            },
            {
                label: 'klistedblenacompte', // Liste des ED et BL effectés en acompte (pas de typage caisse)
                value: '21',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: undefined
            },
            {
                label: 'klistedblsansacompte', // Liste des ED et BL effectés sans acompte (pas de typage caisse)
                value: '32',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: undefined
            },
            {
                label: 'klistedblencaisse', // Liste des ED et BL effectés et typés en caisse
                value: '22',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: undefined
            },
            {
                label: 'kedblsansticket', // Liste des ED et BL effectés sans tickets de caisse
                value: '23',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: undefined
            },
            {
                label: 'kedblencaisseacompte', // Liste des ED et BL effectés et typés en caisse avec acompte <> 0
                value: '24',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: undefined
            },
            {
                label: 'klistnegatif', // Liste des bons avec un reste à payer négatif (typage différent du bon)
                value: '25',
                parent: 'suivi_des_bons',
                dateFilter: 'from',
                dateDisabled: true,
                defaultDate: moment()
            },
            {
                label: 'klistretrautclient', // Liste des bons typés sans retrait client (non soldés)
                value: '26',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'ke2enattenteaffect', // Liste des E2 en attente d\'affectation
                value: '27',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'klistfournisseur', // Liste des L.D en cours chez le fournisseur (passées par edition defenitive)
                value: '28',
                parent: 'suivi_des_bons',
                param: '20_21_VBOOL3',
                dateFilter: 'from',
                dateDisabled: true,
                defaultDate: moment()
            },
            {
                label: 'klistcage', // Liste des L.D en attente de preparation cage
                value: '29',
                parent: 'suivi_des_bons',
                param: '20_21_VBOOL4',
                dateFilter: 'from',
                dateDisabled: true,
                defaultDate: moment()
            },
            {
                label: 'kliste2four', // Liste des E2 par fournisseur
                value: '30',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'ksuivicbi', // Suivi BCI
                value: '31',
                separateAccess: true,
                parent: 'suivi_des_bons',
                dateFilter: 'from',
                dateDisabled: true,
                param: '10_7_VSTR1_ALINEA',
                defaultDate: moment()
            },
            {
                label: 'ksuivicom', // Suivi Commandes
                value: '33',
                parent: 'suivi_des_bons',
                param: '10_7_VSTR1_ALINEA',
                dateFilter: 'both',
                defaultDate: moment()
            },
            // {
            //     label: 'kcomachatscuisine', // Commandes achats cuisine
            //     value: '',
            //     parent: 'suivi_des_bons'
            // },
            // {
            //     label: 'kcomachatsdress', // Commandes achats dressing
            //     value: '',
            //     parent: 'suivi_des_bons'
            // },
            // {
            //     label: 'kcomachatcuisine', // Commandes achats cuisine - Détailé
            //     value: '',
            //     parent: 'suivi_des_bons'
            // },
            // {
            //     label: 'kcomachatdress', // Commandes achats dressing - Détailé
            //     value: '',
            //     parent: 'suivi_des_bons'
            // },
            {
                label: 'kstatvend', // Statistiques vendeurs
                value: '60',
                parent: 'suivi_des_bons',
                dateFilter: 'from',
                magasinFilter: true,
                dateFreedom: true,
                defaultDate: moment()
            },
            {
                label: 'klistedescommandesavecacompte', // Liste des commandes avec acompte à 0
                value: '34',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                dateMandatory: false,
                defaultDate: undefined
            },
            {
                label: 'klistedescommandessansacompte', // Liste des commandes sans acompte
                value: '35',
                parent: 'suivi_des_bons',
                dateFilter: 'both',
                dateMandatory: false,
                defaultDate: undefined
            },
            // --- suivi_des_bons - Child ends ---

            // --- suivi_des_devis - Child starts ---
            {
                label: 'ksuivideviscuisine', // Suivi devis cuisine
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'ksuividevisdress', // Suivi devis dressing
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kdeviscuisine', // Devis pris cuisine
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kdevisprisdress', // Devis pris dressing
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kdevisvalidcuisine', // Devis validés cuisine
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kdevisdress', // Devis validés dressing
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kstatdeviscuisine', // Statistiques devis cuisine
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kstatdevisdress', // Statistiques devis dressing
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kstatvenddeviscuisine', // Statistiques vendeurs devis cuisine
                value: '',
                parent: 'suivi_des_devis'
            },
            {
                label: 'kstatvenddevisdress', // Statistiques vendeurs devis dressing
                value: '',
                parent: 'suivi_des_devis'
            },
            // --- suivi_des_devis - Child ends ---
        ]
    },
    {
        label: 'keditstocks', // Editions de stocks
        value: 'editions_de_stocks',
        icon: 'production_quantity_limits',
        child: [
            {
                label: 'kvalorisationmotif', // Valorisation des stocks par motif
                value: 'valorisation_des_stocks_par_motif'
            },
            {
                label: 'klistventestockinfer', // Liste des ventes avec un stock inférieur à 2 Uvc
                value: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc'
            },
            {
                label: 'kcontrolcoherence', // Contrôle de cohérence des stocks
                value: 'controle_de_coherence_des_stocks',
                param: '52_1_VBOOL1',
            },
            {
                label: 'kfeuillerange', // Feuille de rangement
                value: 'feuille_de_rangement'
            }
        ],
        subChilds: [
            // --- valorisation_des_stocks_par_motif - Child starts --- Dynamic Motif blocage list
            // {
            //     label: '***************',
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kambiance', // Ambiance
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kattretourcuisine', // Att.Retour Cuisine
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kattcoinsoldes', // Attente Coin Soldes
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kattretourfourn', // Attente Retour Fourn
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kattvalidate', // Attente validation
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kblacompte', // BL avec acompte
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kblockexterne', // Blocage externe
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kattretrait', // Click&Collect attente retrait
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kecoemballage', // ecoemballage
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kecomattinv', // ECOM Att.Inv.tournan
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kecombrokerpass', // ECOM Att.pass.casse
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kedacompte', // ED avec Acompte
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kemptester', // Emporter/Tester
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'klitige', // Litige a reception
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'krelpresse', // Relations Presse
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'krescomclient', // Réservé Commandes Clients
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kresdeport', // Reserve deportee
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kksav', // SAV
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'ksingrenoble', // Sinistre Grenoble
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kstockmelun', // Stock Melun
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kstockmetz', // Stock Metz
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kstockrennes', // Stock Rennes
            //     value: '',
            //     parent: 'valorisation_des_stocks_par_motif',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // --- valorisation_des_stocks_par_motif - Child ends --- Dynamic Motif blocage list

            // --- liste_des_ventes_avec_un_stock_inferieur_a_2_uvc - Child starts --- Dynamic Strucuture Marchandise list
            // {
            //     label: 'kfraisbouche', // FRAIS METIERS DE BOUCHE
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'kfraisls', // FRAIS LS
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'kepicerie', // EPICERIE
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'kliquide', // LIQUIDE
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'kcca', // CCA
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'kbebe', // BEBE
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'kmaison', // MAISON
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'kautresservices', // AUTRES PRODUITS ET SERVICES
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // {
            //     label: 'knoncommercialise', // NON COMMERCIALISE
            //     value: '',
            //     parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc',
            //     dateFilter: 'both',
            //     defaultDate: moment().subtract(1, 'day')
            // },
            // --- liste_des_ventes_avec_un_stock_inferieur_a_2_uvc - Child ends --- Dynamic Strucuture Marchandise list

            // --- controle_de_coherence_des_stocks - Child starts --- Dynamic Strucuture Marchandise / Cash attributes list
            // {
            //     label: 'kfraisbouche', // FRAIS METIERS DE BOUCHE
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kfraisls', // FRAIS LS
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kepicerie', // EPICERIE
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kliquide', // LIQUIDE
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kcca', // CCA
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kbebe', // BEBE
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kmaison', // MAISON
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'kautresservices', // AUTRES PRODUITS ET SERVICES
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // {
            //     label: 'knoncommercialise', // NON COMMERCIALISE
            //     value: '',
            //     parent: 'controle_de_coherence_des_stocks',
            //     dateFilter: 'both',
            //     dateDisabled: true,
            //     defaultDate: moment()
            // },
            // --- controle_de_coherence_des_stocks - Child ends --- Dynamic Strucuture Marchandise / Cash attributes list

            // --- feuille_de_rangement - Child starts ---
            {
                label: 'kfeuillerange', // Feuille de rangement
                value: '1000',
                parent: 'feuille_de_rangement',
                dateFilter: 'from',
                dateDisabled: true,
                defaultDate: moment()
            },
            // --- feuille_de_rangement - Child ends ---
        ]
    },
    {
        label: 'kedittresor', // Editions de trésorerie
        value: 'editions_de_tresorerie',
        icon: 'calculate',
        subChilds: [
            // --- editions_de_tresorerie - Child starts ---
            {
                label: 'ksuiviacomptejournal', // Suivi des acomptes journaliers
                value: '80',
                parent: 'editions_de_tresorerie',
                dateFilter: 'from',
                defaultDate: moment()
            },
            {
                label: 'kcontrolperiode', // Contrôle des acomptes pour une période
                value: '81',
                parent: 'editions_de_tresorerie',
                dateFilter: 'both',
                defaultDate: moment()
            },
            {
                label: 'kcontrolperiodenonfilter', // Contrôle des acomptes pour une période (non filtré)
                value: '87',
                parent: 'editions_de_tresorerie',
                dateFilter: 'both',
                defaultDate: moment()
            },
            {
                label: 'ksuivijournalier', // Suivi des reprises d\'acompte journalier
                value: '82',
                parent: 'editions_de_tresorerie',
                dateFilter: 'both',
                defaultDate: moment()
            },
            {
                label: 'kcontrolreprises', // Contrôle des reprises d\'acompte pour une période
                value: '83',
                parent: 'editions_de_tresorerie',
                dateFilter: 'both',
                defaultDate: moment()
            },
            {
                label: 'ksynjournaliers', // Synthèse des encaissements journaliers
                value: '84',
                parent: 'editions_de_tresorerie',
                dateFilter: 'both',
                defaultDate: moment()
            },
            {
                label: 'kcontrolencaise', // Contrôle des encaissements pour une période
                value: '85',
                parent: 'editions_de_tresorerie',
                dateFilter: 'both',
                defaultDate: moment()
            },
            // --- editions_de_tresorerie - Child ends ---
        ]
    },
    {
        label: 'keditdrive', // Editions Drive
        value: 'editions_drive',
        icon: 'drive_eta',
        param: '53_1_VBOOL1',
        subChilds: [
            // --- editions_drive - Child ends ---
            {
                label: 'ksuivitempatt', // Suivi des temps d’attente
                value: '100',
                parent: 'editions_drive',
                dateFilter: 'both',
                defaultDate: moment()
            },
            {
                label: 'ksuivicaissesansdrive', // Suivi des passages caisse sans Drive
                value: '101',
                parent: 'editions_drive',
                dateFilter: 'both',
                defaultDate: moment()
            },
            // --- editions_drive - Child ends ---
        ]
    },
    {
        label: 'keditdiverses', // Editions diverses
        value: 'editions_diverses',
        icon: 'data_exploration',
        subChilds: [
            // --- editions_diverses - Child ends ---
            {
                label: 'ksuiviachatclient', // Suivi des achats clients
                value: '90',
                parent: 'editions_diverses',
                dateFilter: 'from',
                magasinFilter: true,
                dateFreedom: true,
                defaultDate: moment().subtract(1, 'day')
            },
            {
                label: 'ksuividelaiclientei', // Suivi des délais remises clients EI
                value: '91',
                parent: 'editions_diverses',
                dateFilter: 'both',
                magasinFilter: true,
                defaultDate: moment().subtract(1, 'day')
            },
            // --- editions_diverses - Child ends ---
        ]
    },
];