import { Component, OnInit } from '@angular/core';
import { Constants } from 'providers/constants';
import { Subject, Subscription } from 'rxjs';
import { UserService } from 'providers/api/userService';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { NiveauModel } from 'models/niveau-model';
import { NiveauSecuriteService } from 'providers/api/niveauSecuriteService';
import { AddNiveauDeSecuriteComponent } from '../add-niveau-de-securite/add-niveau-de-securite.component';
import { EditNiveauDeSecuriteComponent } from '../edit-niveau-de-securite/edit-niveau-de-securite.component';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { NiveauFilterComponent } from './niveau-filter/niveau-filter.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-niveau-de-securite',
  templateUrl: './niveau-de-securite.component.html',
  styleUrls: ['./niveau-de-securite.component.scss']  
})
export class NiveauDeSecuriteComponent implements OnInit {

  searchForm: any;
  orderByList = [
    {
      label: "kpcode",
      value: Constants.POSTAL_CODE_ORDER_CODE
    },
    {
      label: "kpname",
      value: Constants.POSTAL_CODE_ORDER_NOM
    },
  ];

  niveauTypes = [
    {
      label: "ksadmin",
      value: Constants.TYPE_NIVEAU_ADMIN,
    },
    {
      label: "kssecretary",
      value: Constants.TYPE_NIVEAU_SECRATARY,
    },
    {
      label: "ksseller",
      value: Constants.TYPE_NIVEAU_VENDOR,
    },
    {
      label: "kspointinfo",
      value: Constants.TYPE_NIVEAU_POINT_INFO,
    },
    {
      label: "ksreserve",
      value: Constants.TYPE_NIVEAU_RESERVE,
    }    
  ];

  // bouton ENTER valide formulaire
  private pressedButton: any;

  // champs de recherche
  searchData: any = null;

  // Service
  listSecuriteLevels: NiveauModel[] = [];

  user: any;
  USERCMINT: any;

  // DATATABLE  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  searched = false;

  niveau_type: string;

  isMobile: boolean = false;
  searchSubscription: Subscription;
  addListenerSubscription: Subscription;
  selectBoxSubscription: Subscription;

  filterValues: any;
  selectVal: any;

  userPermission: any;

  constructor(private userService: UserService, 
    public securiteLevelService: NiveauSecuriteService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private loadingService: NgxSpinnerService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    private storageService: StorageService) {
     
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();    
    this.userService.headerSearch.next(false);
    this.userService.headerSearchInput.next(false);
    this.userService.headerSearchButton.next(false);
    this.userService.headerAdd.next(true);

    this.searchSubscription = this.userService.headerSearch.subscribe(
      (status: boolean) => {
        if(status === true) {
          this.openUpFilterScreen();
        }
      }
    )

    this.addListenerSubscription = this.userService.headerAddListener.subscribe(
      (status: boolean) => {
        if(status === true) {
          this.addSecuriteLevel();
        }
      }
    )

    this.selectBoxSubscription = this.userService.headerSelectBox.subscribe((dropdownObject) => {
      if(dropdownObject.pageName === 'Security'){        
        this.searchForm.patchValue({
          niveau_type: dropdownObject.value,
        });
        this.selectVal = dropdownObject.value;
        this.typeOnChange();        
      }            
    });

    this.searchForm = this.formBuilder.group({
      niveau_type: ['', [Validators.required]],      
    });

    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
  
    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }          
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['69_1']) {
      this.niveauTypes = this.niveauTypes.filter((_f) => _f.value != Constants.TYPE_NIVEAU_ADMIN);
    }
    
    this.selectVal = this.niveauTypes[0].value;
    this.niveau_type = this.selectVal;
    this.getListSecuriteLevels(this.niveau_type);
  }

  ngOnDestroy(): void {
    this.userService.headerAdd.next(false);
    this.userService.headerSearch.next(false);
    this.searchSubscription.unsubscribe();
    this.addListenerSubscription.unsubscribe();
    this.selectBoxSubscription.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {      
      this.dtTrigger.next();
  }

  typeOnChange() {
    this.showLoader = true;
    this.currentPage = 0;
    this.niveau_type = this.selectVal;

    if(this.niveau_type !== undefined) {
      this.filterValues = {
        niveau_type: this.niveau_type
      }
    }
    
    this.getListSecuriteLevels(this.niveau_type, this.currentPage + 1);
  }

  getListSecuriteLevels(type: string, page?: number) {
    this.securiteLevelService.getListofSecuriteLevels(type, this.USERCMINT, page).subscribe(
      (response) => {        
        const data = response.data;

        this.rowsPerPage = response.rowsPerPage;
        this.totalPages = response.count;        

        this.listSecuriteLevels = [...data];
        
        this.showLoader = false;
        this.dtTrigger.next();
      },
      error => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('ksconerr'), this.translateService.instant('ksconerrtry'));
      }
    )  
  }

  paginationChange(event: any) {
    this.showLoader = true;
    const changedPageIndex = event.pageIndex;    

    this.currentPage = changedPageIndex;

    this.getListSecuriteLevels(this.niveau_type, changedPageIndex + 1);    
  }

  addSecuriteLevel(): void {    
    const dialogRef = this.dialog.open(AddNiveauDeSecuriteComponent, {      
      width: '100vw',
      data: {
        list_niveau_type: this.niveauTypes,
        niveau_type: this.niveau_type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.currentPage = 0;      
        this.getListSecuriteLevels(this.niveau_type, this.currentPage + 1);      
    });    
  }

  editSecuriteLevel(code_deta: NiveauModel): void {
    const dialogRef = this.dialog.open(EditNiveauDeSecuriteComponent, {      
      width: '100vw',
      autoFocus: !this.isMobile,
      data: {...code_deta, niveau_type: (this.niveauTypes.find((el) => el.value == this.niveau_type )).label }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== '' && result !== undefined) {
        this.getListSecuriteLevels(this.niveau_type, this.currentPage + 1);
      }      
    });    
  }

  deleteConfirmation(row_id: number, userCount: number): void {    

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {      
      width: '60vw',
      data: {
        title: this.translateService.instant('kscdelete'),
        description: ""
      }     
    });    

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {

        if(userCount && userCount > 0) {
          const userCountDialogRef = this.dialog.open(ConfirmDialogComponent, {      
            width: '60vw',
            disableClose: true,
            data: {
              title: userCount+" "+this.translateService.instant('kscdeletemsg'),
              description: "",
              buttons: [
                this.translateService.instant('ksyes'),
                this.translateService.instant('kartno')
              ]
            }     
          });
  
          userCountDialogRef.afterClosed().subscribe(result => {
            if(result === "OK") {              
              this.deleteSecuriteLevel(row_id);
            }
          });                
        } else {
          this.deleteSecuriteLevel(row_id);
        }
      }    
    });    
  }

  deleteSecuriteLevel(row_id: number): void {    
      this.loadingService.show();

      this.securiteLevelService.deleteSecuriteLevel(row_id).subscribe(
        (resp) => {
          this.loadingService.hide();

          if(resp.statusCode == 200) {

            this.listSecuriteLevels = this.listSecuriteLevels.filter((el) => el.NIVINT != row_id);

            if(this.listSecuriteLevels.length == 0) {
              this.showLoader = true;

              if(this.currentPage != 0) {
                this.currentPage--;
              }
              
              this.getListSecuriteLevels(this.niveau_type, this.currentPage + 1);            
            }          

            this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('ksdelete'));          
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kserrremove'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('ksconerr'), this.translateService.instant('ksconerrtry'));
        }
      )    
  }

  openUpFilterScreen() {
    this.userService.headerSearch.next(false);

    const dialogRef = this.dialog.open(NiveauFilterComponent, {
      width: '60vw',
      data: {
        form_data: this.searchForm.value,
        niveauTypes: this.niveauTypes,        
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        const form_data = result.form_data;

        this.searchForm.patchValue({
          niveau_type: form_data.niveau_type,          
        });

        this.filterValues = form_data;

        if(form_data.niveau_type !== '' && form_data.niveau_type !== null) {
          this.showLoader = true;
          this.currentPage = 0;
          this.niveau_type = this.searchForm.get('niveau_type').value;
  
          this.userService.headerAdd.next(true);
  
          this.getListSecuriteLevels(this.niveau_type, this.currentPage + 1);
        }   
      }      
    }); 
  }

  get filterValuesFormat(): string {
    let filterArr = [];
  
    if(this.filterValues.niveau_type !== undefined && this.filterValues.niveau_type !== null && this.filterValues.niveau_type !== '') {
      const levelLabel = this.niveauTypes.find((row) => row.value == this.filterValues.niveau_type);
      filterArr.push(this.translateService.instant(levelLabel.label));
    }

    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
