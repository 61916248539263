import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import {UserService} from '../../../providers/api/userService';
import {ParamDetail} from '../../../models/paramDetail';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogsService} from '../../../providers/api/dialogService';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from '../../../models/user-model';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-modifier-le-type-de-la-commande',
  templateUrl: './modifier-le-type-de-la-commande.component.html',
  styleUrls: ['./modifier-le-type-de-la-commande.component.scss']
})
export class ModifierLeTypeDeLaCommandeComponent implements OnInit {

  updateType: 'total' | 'line';
  user: UserModel;
  USERCMINT: any;
  numBon: string;
  panier: BonPanier;
  chosenItem: PanierArticle;
  typeForm: UntypedFormGroup;
  etatGAvail: boolean;
  splitterPopup: boolean;
  isSplitterAvailable: 0 | 1 | 2;

  typeCdeList: {value: string, label: string, paramKey: string, disabled: boolean}[] = [];

  pageTitle: string = "";
  userPermission: any;

  currentArticleStockMag: number | undefined;

  paramsList: ParamDetail[];

  constructor(private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private userService: UserService,
              private loadingService: NgxSpinnerService,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<ModifierLeTypeDeLaCommandeComponent>,
              private storageService: StorageService
              ) { }

  ngOnInit(): void {
    this.updateType = this.data.type;
    this.numBon = this.data.numBon;
    this.panier = this.data.panier;
    this.chosenItem = this.data.chosenItem;
    this.etatGAvail = this.data.etatGAvail;
    this.splitterPopup = this.data.splitterPopup;
    this.isSplitterAvailable = this.data.isSplitterAvailable;

    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.currentArticleStockMag = this.data.currentArticleStockMag;

    this.userPermission = this.storageService.getItem('CurrentUserPermission');

    this.typeForm = this.formBuilder.group({
      TYPECDE: ['', Validators.required]
    });

    if(this.splitterPopup) {
      this.pageTitle = "ksplittype";
    } else {
      this.pageTitle = "kboncommodifytypecmd";
    }

    this.loadDependencies()
  }

  async loadDependencies() {
    this.loadingService.show();

    await this.getListParams(this.currentArticleStockMag);
    this._defineRules();

    this.loadingService.hide();
  }

  async getListParams(CMINT: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getRulesParams(CMINT, this.USERCMINT).subscribe(
          (resp: any) => {
            if (resp.success !== undefined && resp.success === 'ok') {
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
          },
          (error: any) => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject('error_callback');
          });
    });
  }

  async _defineRules() {
    const processedRules = this.userService.processRulesParams(this.paramsList);

    if(this.updateType === 'line') {
      if(this.chosenItem.typecde === 'BL') {
        this.typeCdeList = [
          {
            paramKey: '20_4;VSTR4;VBOOL4',
            label: processedRules['20_4']['VSTR4'],
            value: 'E2/BL',
            disabled: (processedRules['20_4']['VBOOL4'] == '-1') ? false : true
          },
        ];
      } else if(this.chosenItem.typecde === 'ED') {
        this.typeCdeList = [
          {
            paramKey: '20_4;VSTR3;VBOOL3',
            label: processedRules['20_4']['VSTR3'],
            value: 'E2/ED',
            disabled: (processedRules['20_4']['VBOOL3'] == '-1') ? false : true
          },
        ];
      } else if(this.chosenItem.typecde === 'EI') {
        this.typeCdeList = [
          {
            paramKey: '20_3;VSTR2;VBOOL2',
            label: processedRules['20_3']['VSTR2'],
            value: 'ED',
            disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
          }
        ];
      } else if(this.chosenItem.typecde === 'E2/BL' || this.chosenItem.typecde === 'E2/ED') {
        this.typeCdeList = [];
      }
    } else {
      if(this.panier.typecde === 'EI') {
        this.typeCdeList = [
          {
            paramKey: '20_3;VSTR2;VBOOL2',
            label: processedRules['20_3']['VSTR2'],
            value: 'ED',
            disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
          },
          {
            paramKey: '20_3;VSTR3;VBOOL3',
            label: processedRules['20_3']['VSTR3'],
            value: 'BL',
            disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
          }
        ];

        this.typeCdeList = this.typeCdeList.filter((row) => this.panier.typecde.indexOf(row.value) == -1 && !row.disabled);
      } else if(this.panier.typecde === 'BL' || this.panier.typecde === 'ED') {
        this.typeCdeList = [
          {
            paramKey: '20_3;VSTR2;VBOOL2',
            label: processedRules['20_3']['VSTR2'],
            value: 'ED',
            disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
          },
          {
            paramKey: '20_3;VSTR3;VBOOL3',
            label: processedRules['20_3']['VSTR3'],
            value: 'BL',
            disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
          },
          {
            paramKey: '20_4;VSTR3;VBOOL3',
            label: processedRules['20_4']['VSTR3'],
            value: 'E2/ED',
            disabled: (processedRules['20_4']['VBOOL3'] == '-1') ? false : true
          },
          {
            paramKey: '20_4;VSTR4;VBOOL4',
            label: processedRules['20_4']['VSTR4'],
            value: 'E2/BL',
            disabled: (processedRules['20_4']['VBOOL4'] == '-1') ? false : true
          },
        ];

        this.typeCdeList = this.typeCdeList.filter((row) => row.value != this.panier.typecde && !row.disabled);

        if(this.etatGAvail) {
          this.typeCdeList = this.typeCdeList.filter((_rw) => _rw.value.indexOf('E2') == -1);
        }

      } else {
        this.typeCdeList = [
          {
            paramKey: '20_3;VSTR2;VBOOL2',
            label: processedRules['20_3']['VSTR2'],
            value: 'ED',
            disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
          },
          {
            paramKey: '20_3;VSTR3;VBOOL3',
            label: processedRules['20_3']['VSTR3'],
            value: 'BL',
            disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
          }
        ];

        this.typeCdeList = this.typeCdeList.filter((row) => this.panier.typecde.indexOf(row.value) == -1 && !row.disabled);
      }
    }
    
    if(this.splitterPopup) {
      this.typeCdeList = [
        {
          paramKey: '20_3;VSTR2;VBOOL2',
          label: processedRules['20_3']['VSTR2'],
          value: 'ED',
          disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
        },
        {
          paramKey: '20_3;VSTR3;VBOOL3',
          label: processedRules['20_3']['VSTR3'],
          value: 'BL',
          disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
        },
        {
          paramKey: '20_4;VSTR3;VBOOL3',
          label: processedRules['20_4']['VSTR3'],
          value: 'E2/ED',
          disabled: (processedRules['20_4']['VBOOL3'] == '-1') ? false : true
        },
        {
          paramKey: '20_4;VSTR4;VBOOL4',
          label: processedRules['20_4']['VSTR4'],
          value: 'E2/BL',
          disabled: (processedRules['20_4']['VBOOL4'] == '-1') ? false : true
        },
      ];

      this.typeCdeList = this.typeCdeList.filter((row) => {
        switch(this.panier.typecde) {
          case 'ED':
            return row.value === 'BL' || row.value === 'ED';
          case 'BL':
            return row.value === 'ED' || row.value === 'BL';
          case 'E2/ED':
            return row.value === 'E2/BL' || row.value === 'E2/ED';
          case 'E2/BL':
            return row.value === 'E2/ED' || row.value === 'E2/BL';
        }
        return row;
      });
      this.typeCdeList = this.typeCdeList.filter((row) => !row.disabled);
    } else {
      if(this.updateType === 'line' && this.userPermission['32_14'] && this.isSplitterAvailable) {
        this.typeCdeList.push({
          paramKey: '',
          label: "ksplittercmd",
          value: "SPLIT",
          disabled: false
        });
      }
    }

    if (this.currentArticleStockMag) {
      if (processedRules['20_5'] && processedRules['20_6']) {
        // const replacementParamKey = { '20_3': '20_5', '20_4': '20_6' };

        // this.typeCdeList = this.typeCdeList.map(rw => {
        //   if (rw.paramKey) {
        //     const [PARAM_ROW_ID, PARAM_STR, PARAM_BOOL] = rw.paramKey.split(';');

        //     rw.label = processedRules[replacementParamKey[PARAM_ROW_ID]][PARAM_STR];
        //     rw.disabled = (processedRules[replacementParamKey[PARAM_ROW_ID]][PARAM_BOOL] == '-1') ? false : true;
        //   }

        //   return rw;
        // });

        this.typeCdeList = [
          {
            paramKey: '20_5;VSTR1;VBOOL1',
            label: processedRules['20_5']['VSTR1'],
            value: 'EI',
            disabled: (processedRules['20_5']['VBOOL1'] == '-1') ? false : true
          },
          {
            paramKey: '20_5;VSTR2;VBOOL2',
            label: processedRules['20_5']['VSTR2'],
            value: 'ED',
            disabled: (processedRules['20_5']['VBOOL2'] == '-1') ? false : true
          },
          {
            paramKey: '20_5;VSTR3;VBOOL3',
            label: processedRules['20_5']['VSTR3'],
            value: 'BL',
            disabled: (processedRules['20_5']['VBOOL3'] == '-1') ? false : true
          },
          {
            paramKey: '20_6;VSTR3;VBOOL3',
            label: processedRules['20_6']['VSTR3'],
            value: 'E2/ED',
            disabled: (processedRules['20_6']['VBOOL3'] == '-1') ? false : true
          },
          {
            paramKey: '20_6;VSTR4;VBOOL4',
            label: processedRules['20_6']['VSTR4'],
            value: 'E2/BL',
            disabled: (processedRules['20_6']['VBOOL4'] == '-1') ? false : true
          },
        ];

        this.typeCdeList = this.typeCdeList.filter((row) => !row.disabled && this.panier.typecde.indexOf(row.value) == -1);

        if (this.typeCdeList.length === 0) {
          this.typeCdeList = [];
          this.typeForm.get('TYPECDE').setValue('');
          await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kmodification_impossible_surce_magasin')).toPromise();
          this.dialogRef.close();
          return;
        }

        const selectedTypeVal = this.typeForm.get('TYPECDE').value;
        const selectedTypeIsAvail = this.typeCdeList.find(rw => rw.value == selectedTypeVal);
        if (!selectedTypeIsAvail) {
          this.typeForm.get('TYPECDE').setValue(this.typeCdeList[0].value);
        }


        return;
      }

      this.typeCdeList = this.typeCdeList.filter((row) => row.value !== 'EI' && row.value.indexOf('E2') === -1 && !row.disabled);

      if(this.typeCdeList.length === 0) {
        this.typeCdeList = [];
        this.typeForm.get('TYPECDE').setValue('');
        await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kmodification_impossible_surce_magasin')).toPromise();
        this.dialogRef.close();
      }
    }
  }

  typeFormSubmitter() {
    const form_data = this.typeForm.getRawValue();

    if (form_data.TYPECDE == "SPLIT" && this.isSplitterAvailable === 2) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kmodify_non_conformes'));
      return;
    }
    
    this.typeForm.get('TYPECDE').setValue(''); // Resetting the type value

    this.dialogRef.close({ status: 'success', data: form_data, splitter: this.splitterPopup });
  }
}
