import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { PanierArticle } from 'models/bonPanier';
import { User } from 'models/users';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { MotifService } from 'providers/api/motifService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';

@Component({
  selector: 'app-motif-force-prix',
  templateUrl: './motif-force-prix.component.html',
  styleUrls: ['./motif-force-prix.component.scss'],
  providers: [BonVenteService, TranslateService]
})
export class MotifForcePrixComponent implements OnInit {

  title = "";
  force_prix_rules: { label: string, value: string }[];

  prixForceForm: UntypedFormGroup;

  originPrix: number = 0;
  caissePrix: number = 0;

  user: User;
  USERCMINT: number;

  currentItem: PanierArticle;
  CDART: string;

  applyPercentage: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<MotifForcePrixComponent>,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private loadingService: NgxSpinnerService,
              private userService: UserService,
              public motifService: MotifService,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();

    this.prixForceForm = this.formBuilder.group({
      force_motif: ['', Validators.required],
      MOTIF_LABEL: ['', Validators.required]
    });

    this.USERCMINT = this.data.CMINT; 
    this.currentItem = this.data.item;

    this.getListForceMotifs();
  }

  getListForceMotifs() {
    this.loadingService.show();

    const data = {
      IDMAG: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      CIINT: this.user.CIINT
    };

    this.motifService.getListPrixForceMotifs(data).subscribe((response) => {
      let data = response.data;
      data = data.map((_r:any) => {
        return { label: _r.CHPLUBL, value: _r.CHPCODE };
      });

      this.force_prix_rules = [...data];

      if(this.currentItem !== undefined && this.currentItem !== null && this.currentItem.prixforce) {
        this.prixForceForm.get('force_motif').setValue(this.currentItem.motifprixforce);

        const currentMotif = this.force_prix_rules.find(rw => rw.value == this.currentItem.motifprixforce);
        this.prixForceForm.get('MOTIF_LABEL').setValue(currentMotif.label);
      }

      this.loadingService.hide();
    },
    error => {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
    })
  }

  onMotifChange(event: any) {
    const selectedMotifId = event.value;
    const currentMotif = this.force_prix_rules.find(rw => rw.value == selectedMotifId);
    this.prixForceForm.get('MOTIF_LABEL').setValue(currentMotif.label);
  }

  prixForceFormSubmitter() {
    if(this.prixForceForm.invalid) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonprixoblig'));
      return;
    }

    const form_data = this.prixForceForm.getRawValue();
    this.dialogRef.close({ status: 'success', data: form_data });
  }

}
