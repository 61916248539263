import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import BonPanier from 'models/bonPanier';
import MagComplement from 'models/magComplement';
import Transporteur from 'models/transporteur';
import * as moment from "moment";
import { BonVenteService } from 'providers/api/bonventeService';
import { ListTransporteurComponent } from '../list-transporteur/list-transporteur.component';
// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { TrackDeliveryComponent } from '../track-delivery/track-delivery.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {CUSTOM_DATE_FORMATS} from '../../../../providers/custom-date-formats';


@Component({
  selector: 'app-livraison-details',
  templateUrl: './livraison-details.component.html',
  styleUrls: ['./livraison-details.component.scss'],
  providers: [ {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},BonVenteService]
})
export class LivraisonDetailsComponent implements OnInit {

  today = new Date();
  livreForm: UntypedFormGroup; 
  oldLivreData: any;  

  dtliv_status: boolean = false;

  magComplementData: MagComplement;
  
  transporteurLabel: string = '';
  panier: BonPanier;

  previousTranporteur: Transporteur;

  flowType: string;

  suiviLivraison: boolean = false;
  suiviLivraisonClosed = new EventEmitter();
  livraisonDetailsChanged = new EventEmitter();

  lienLogAvail: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: any,
            public dialogRef: MatDialogRef<LivraisonDetailsComponent>,
            private dialogService: DialogsService,
            private translateService: TranslateService,
            private loadingService: NgxSpinnerService,
            private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.oldLivreData = this.data.livreDetail;
    this.magComplementData = this.data.magComplementData;
    this.panier = this.data.panier;
    this.flowType = (this.data.flowType !== undefined && this.data.flowType != 'null')?this.data.flowType:'bonde_vente';
    
    this.livreForm = this.formBuilder.group({
      dtliv: [{value: '', disabled: true}],
      dtlivsouhait: [{value: '', disabled: true}],
      livjour: [''],
      livconfirm: [false],
      livinfo1: [''],
      livinfo2: [''],
      livdeport: [false],
      livdirect: [false],

      transporteurLabel: [{value: '', disabled: true}],
      transporteurContent: [''],
    });

    if(this.oldLivreData !== undefined && this.oldLivreData !== null) {
      let livreDateYear = moment(this.oldLivreData.dtliv).startOf('day').year();
      this.livreForm.patchValue({
        dtliv: (livreDateYear !== undefined && livreDateYear !== 1899)?new Date(this.oldLivreData.dtliv):'',
        dtlivsouhait: new Date(this.oldLivreData.dtlivsouhait),
        livjour: this.oldLivreData.livjour,
        livconfirm: this.oldLivreData.livconfirm,
        livinfo1: this.oldLivreData.livinfo1,
        livinfo2: this.oldLivreData.livinfo2,
        livdeport: this.oldLivreData.livdeport,
        livdirect: this.oldLivreData.livdirect,   
        
        transporteurContent: this.oldLivreData.transporteurContent,
        transporteurLabel: this.oldLivreData.transporteurLabel
      })
    }

    if(this.panier && this.panier.lienlog !== undefined && this.panier.lienlog !== null && this.panier.lienlog !== '') {
      this.suiviLivraison = true;
      this.lienLogAvail = true;

      this.dtliv_status = true;
    }

    this.livraisonDetailsChanged.subscribe((res: any) => {
      this.loadingService.hide();
      if(res !== undefined && res != null) {
        this.oldLivreData = res;

        let livreDateYear = moment(this.oldLivreData.dtliv).startOf('day').year();
        this.livreForm.patchValue({
          dtliv: (livreDateYear !== undefined && livreDateYear !== 1899)?new Date(this.oldLivreData.dtliv):'',
          dtlivsouhait: new Date(this.oldLivreData.dtlivsouhait),
          livjour: this.oldLivreData.livjour,
          livconfirm: this.oldLivreData.livconfirm,
          livinfo1: this.oldLivreData.livinfo1,
          livinfo2: this.oldLivreData.livinfo2,
          livdeport: this.oldLivreData.livdeport,
          livdirect: this.oldLivreData.livdirect,
          
          transporteurContent: this.oldLivreData.transporteurContent,
          transporteurLabel: this.oldLivreData.transporteurLabel
        })
      }
    });
  }

  checkboxChanged(event: any, elementName) {
    const direct = this.livreForm.get('livdirect');
    const deport = this.livreForm.get('livdeport');
    deport.setValue(false);
    direct.setValue(false);
    if(elementName === 'direct'){
      direct.setValue(event.checked);
    } else {
      deport.setValue(event.checked);
    }    
    if(direct.value === false && deport.value === false) {
      this.dtliv_status = false;      
    } else {
      this.dtliv_status = true;      
    }
  }

  livreFormSubmitter() {
    const form_data = this.livreForm.getRawValue();

    const today = moment().startOf('day');    
    const selected_date = moment(form_data.dtliv).startOf('day'); 

    if(today.diff(selected_date, 'days') > 0) { // Error alert, If selected date < today date
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kbondateinf'));
      return;
    }
    
    form_data.dtliv = (form_data.dtliv !== '')?moment(form_data.dtliv).format('YYYY-MM-DD HH:mm:ss'):moment('1899-12-28').format('YYYY-MM-DD HH:mm:ss');
    form_data.dtlivsouhait = moment(form_data.dtlivsouhait).format('YYYY-MM-DD HH:mm:ss');

    this.dialogRef.close({ status: 'success', data: form_data });
  }

  openUpTransporteurList() {
    const dialogRef = this.dialog.open(ListTransporteurComponent, {
      width: '40vw',
      maxWidth: '40vw',    
      data: {
        panier: this.panier,    
        previousTranporteur: this.previousTranporteur    
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
          const transporteurData: Transporteur = result.data;
          this.previousTranporteur = transporteurData;
          if(transporteurData !== undefined && transporteurData !== null) {
            const transporteurContent = transporteurData.CNUF+'/'+transporteurData.DEPT;

            this.livreForm.get('transporteurLabel').setValue(transporteurData.TRALIBL);
            this.livreForm.get('transporteurContent').setValue(transporteurContent);
          } else {
            this.livreForm.get('transporteurLabel').setValue('');
            this.livreForm.get('transporteurContent').setValue('');
          }
      }
    });
  }

  openUpDeliveryTracking() {
    const dialogRef = this.dialog.open(TrackDeliveryComponent, {
      panelClass: 'full-screen-modal',
      data: {
        panier: this.panier
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.suiviLivraisonClosed.emit({ status: true });
      if(result && result.status === 'success') {
          console.log(result);
      }
    });
  }

  removeSelectedTranporteur() {
    this.previousTranporteur = undefined;
    this.livreForm.get('transporteurLabel').setValue('');
    this.livreForm.get('transporteurContent').setValue('');
  }  

}
