import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ColorsListComponent} from '../bon-de-commande/colors-list/colors-list.component';
import {UserService} from '../../providers/api/userService';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, NgForm} from '@angular/forms';
import {DataTableDirective} from 'angular-datatables';
import {Observable, Subject, Subscription} from 'rxjs';
import {MagasinStructModel} from '../../models/magasinStructModel';
import {User} from '../../models/users';
import {ActivatedRoute, Router} from '@angular/router';
import {BonCommandeService} from '../../providers/api/bonCommandeService';
import {UtilisatuersService} from '../../providers/api/utilisatuersService';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogsService} from '../../providers/api/dialogService';
import {UtilService} from '../../providers/api/utilService';
import {DeviceDetectorService} from 'ngx-device-detector';
import {CapCurrencyPipe} from '../../providers/customCurrency-pipe';
import {DOCUMENT} from '@angular/common';
import {Constants} from '../../providers/constants';
import * as moment from 'moment';
import {ConfirmDialogComponent} from '../../components/confirm-dialog/confirm-dialog.component';
import BonPanier from '../../models/bonPanier';
import {BlocageCustomMotifComponent} from '../article-detail/article-stock/update-motif-blocage/blocage-custom-motif/blocage-custom-motif.component';
import {BonVente} from '../../models/bonventes';
import {TicketsComponent} from '../bon-de-commande/tickets/tickets.component';
import {HistoriqueBondeComponent} from '../detail-bon-de-vente/historique-bonde/historique-bonde.component';
import {BonDeCommadeFilterComponent} from '../bon-de-commande/bon-de-commade-filter/bon-de-commade-filter.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Article} from '../../models/articles';
import {ArticleDetailComponent} from '../article-detail/article-detail.component';
import {ModifierGestionDesE2Component} from '../modifier-gestion-des-e2/modifier-gestion-des-e2.component';
import {DesaffecterE2Component} from './desaffecter-e2/desaffecter-e2.component';
import {GestionPreAffectationComponent} from '../gestion-pre-affectation/gestion-pre-affectation.component';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import {AUTHORIZE_REACTIVATION_COMMANDE, AUTHORIZE_SUPPRESSION_COMMANDE, HistoryService} from 'providers/api/historyService';
import {GestionDesE2FilterComponent} from '../bon-de-commande/gestion-des-e2-filter/gestion-des-e2-filter.component';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-search-gestion-des-e2',
  templateUrl: './search-gestion-des-e2.component.html',
  styleUrls: ['./search-gestion-des-e2.component.scss'],
  providers: [BonCommandeService, UtilisatuersService, UtilService, {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}, ]
})
export class SearchGestionDesE2Component implements OnInit {

  @ViewChild('f', { static: false }) searchFormRef: NgForm;
  // bouton ENTER valide formulaire
  private pressedButton: any;

  // champs de recherche
  searchData: any = null;

  // Service
  listBonDeCommande: any[] = [];

  bonsE2List: { id: string, label: string }[] = [
    {
      label: 'ktouteslese2encours',
      id: ''
    },
    {
      label: 'klese2avecstock',
      id: 'les-e2-avec-stock'
    },
    {
      label: 'klese2avecstocktotal',
      id: 'les-e2-avec-stock-total'
    },
    {
      label: 'klese2avecstockpartiel',
      id: 'les-e2-avec-stock-partiel'
    },
    {
      label: 'klese2sansstock',
      id: 'les-e2-sans-stock'
    },
  ];

  fournisseurNomList: { id: string, label: string }[] = [
    {
      label: 'kuall',
      id: ''
    },
    {
      label: 'klibandros',
      id: 'andros'
    },
    {
      label: 'klibentrepota',
      id: 'entrepot_a'
    },
    {
      label: 'klibentrepotb',
      id: 'entrepot_b'
    },
    {
      label: 'klibfermierlocal',
      id: 'fermier_local'
    },
    {
      label: 'klibfourndirecttest',
      id: 'fourn_direct_test'
    },
    {
      label: 'klibfournisseurlambda',
      id: 'fournisseur_lambda'
    },
    {
      label: 'kliblivreurentrepot',
      id: 'livreur_entrepot'
    },
  ];

  user: any;
  USERCMINT: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = false;
  magasinStructure: MagasinStructModel[];

  currentPage = 0;
  totalPages: number;
  rowsPerPage = 10;

  searchForm: UntypedFormGroup;
  searched = false;

  isMobile = false;
  searchSubscription: Subscription;
  searchCodeSubscription: Subscription;
  addListenerSubscription: Subscription;

  filterValues: any;

  pageType = 'search-gestion-des-e2';
  pageTitle = 'kgestiondese2';

  defaultDiffereDays: number;
  positionnement: 'A l\'affectation de l\'E2' | 'Client prévenu';

  userPermission: any;
  detailBonCommandeVisibility = false;
  authorizedSuppressionCommandeUser: User;
  authorizedReactivationCommandeUser: User;

  chosenLineItems: any[] = [];
  checkedLineItems: any[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              public bonCommandeService: BonCommandeService,
              private utilisatuersService: UtilisatuersService,
              private translateService: TranslateService,
              private loadingService: NgxSpinnerService,
              private formBuilder: UntypedFormBuilder,
              private dialogService: DialogsService,
              private utilService: UtilService,
              private deviceService: DeviceDetectorService,
              private currencyPipe: CapCurrencyPipe,
              private dialog: MatDialog,
              @Inject(DOCUMENT) document,
              private historyService: HistoryService,
              private storageService: StorageService
  ) {
  }

  ngOnInit(): void {

    this.isMobile = this.deviceService.isMobile();
    this.rowsPerPage = 10;

    if (this.isMobile) {
      this.rowsPerPage = 5;
    }

    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.loadDependencies();

    this.userService.headerAdd.next(false);
    this.userService.headerAddListener.next(false);
    this.userService.headerSearchInputThreeDots.next(true);
    this.userService.headerSearchInput.next(true);
    this.userService.headerSearchInputType.next('text');
    this.userService.headerSearch.next(false);

    this.searchSubscription = this.userService.headerSearch.subscribe(
        (status: boolean) => {
          if (status === true) {
            this.openUpBondeFilterScreen();
          }
        }
    )

    this.searchCodeSubscription = this.userService.headerSearchInputValue.subscribe(
        (search_code) => {
          if (search_code !== undefined) {
            const rapid_search_code: any = Number(search_code);
            if (isNaN(rapid_search_code)) {
              this.searchForm.patchValue({ NOM: search_code === null ? '' : search_code, NUMBON : '' });
            } else {
              this.searchForm.patchValue({ NUMBON: search_code === null ? '' : search_code, NOM: '' });
            }
            this.searchFormSubmitter();
          }
        }
    )

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
          first: this.translateService.instant('kfirst'),
          previous: this.translateService.instant('kprevious'),
          next: this.translateService.instant('knext'),
          last: this.translateService.instant('klast'),
        }
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.searchForm = this.formBuilder.group({
      IDSOC: [Constants.IDSOC],
      CMINT: this.USERCMINT,
      NUMBON: [''],
      NOM: [''],
      PRENOM: [''],
      CODPOS: [''],
      VILLE: [''],
      TEL_PORT: [''],

      TOTAL_ENTRE_FROM: [''],
      TOTAL_ENTRE_TO: [''],
      NUM_EXT: [''],
      BONS_E2: [''],
      ARTICLE: [''],
      SECTEUR: [''],

      CODE: [''],
      LIBEL: [''],
      ORDER_BY: [''],
    });

    this.userPermission = this.storageService.getItem('CurrentUserPermission');

    if (this.userPermission['32_0'] && !this.userPermission['32_10']) {
      this.detailBonCommandeVisibility = true;
    }

    const routeData: any = this.route.snapshot.data;
    if (routeData !== undefined && routeData !== null) {

      if (routeData.autre_choix !== undefined && routeData.autre_choix !== null) {
        // this.searchForm.get('AUTRE_CHOIX').setValue(routeData.autre_choix);
      }
    }

    const oldFormData: any = this.storageService.getItem('bonCommandeFormData');
    const oldListResponse: any = this.storageService.getItem('bonCommandeListData');

    if (oldFormData !== undefined && oldFormData !== null) {
      this.searchForm.setValue({
        IDSOC: oldFormData.IDSOC !== null ? oldFormData.IDSOC : Constants.IDSOC,
        CMINT: oldFormData.CMINT !== null ? oldFormData.CMINT : this.USERCMINT,
        NUMBON: oldFormData.NUMBON !== null ? oldFormData.NUMBON : '',
        NOM: oldFormData.NOM !== null ? oldFormData.NOM : '',
        PRENOM: oldFormData.PRENOM !== null ? oldFormData.PRENOM : '',
        CODPOS: oldFormData.CODPOS !== null ? oldFormData.CODPOS : '',
        VILLE: oldFormData.VILLE !== null ? oldFormData.VILLE : '',
        TEL_PORT: oldFormData.TEL_PORT !== null ? oldFormData.TEL_PORT : '',

        TOTAL_ENTRE_FROM: oldFormData.TOTAL_ENTRE_FROM !== null ? oldFormData.TOTAL_ENTRE_FROM : '',
        TOTAL_ENTRE_TO: oldFormData.TOTAL_ENTRE_TO !== null ? oldFormData.TOTAL_ENTRE_TO : '',
        NUM_EXT: oldFormData.NUM_EXT !== null ? oldFormData.NUM_EXT : '',
        BONS_E2: oldFormData.BONS_E2 !== null ? oldFormData.BONS_E2 : '',
        ARTICLE: oldFormData.ARTICLE !== null ? oldFormData.ARTICLE : '',
        SECTEUR: oldFormData.SECTEUR !== null ? oldFormData.SECTEUR : '',

        CODE: oldFormData.CODE !== null ? oldFormData.CODE : '',
        LIBEL: oldFormData.LIBEL !== null ? oldFormData.LIBEL : '',
        ORDER_BY: oldFormData.ORDER_BY !== null ? oldFormData.ORDER_BY : '',
      });

      this.filterValues = oldFormData;
    }

    if (oldListResponse !== undefined && oldListResponse != null) {
      const data = oldListResponse.data.Bons;
      this.currentPage = Math.floor(oldListResponse.data.FinBon / this.rowsPerPage) - 1;

      this.totalPages = oldListResponse.data.NbBons;

      this.listBonDeCommande = [...data];
      this.showLoader = false;

      setTimeout(() => {
        this.dtTrigger.next();
      }, 400);
    } else {
    }
  }

  async loadDependencies() {
    this.loadingService.show();

    await this.getListMagasinStructure();
    try {
      this.defaultDiffereDays = await this.userService.getRuleParamsData(20, 50, 'VINT1');
      this.positionnement = await this.userService.getRuleParamsData(30, 4, 'VCHXMUL1');
    } catch (err) {

    }


    this.loadingService.hide();
  }

  _barCodeLogicCheck = (value: string) => {
    if (value !== undefined && value != null) {

      if (value.indexOf('~') > -1) {
        const [CDART, PRIX] = value.split('~');
        this.searchForm.patchValue({ ARTICLE: CDART });

        if (this.searchFormRef) {
          this.searchFormRef.ngSubmit.emit();
        } else {
          this.searchFormSubmitter()
        }

        return;
      }

      if (value.length === Constants.BARCODE_HIGHEST_LENGTH) {
        let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
        CDART = CDART.replace(/^0+/, '');

        this.searchForm.patchValue({ ARTICLE: CDART });

        if (this.searchFormRef) {
          this.searchFormRef.ngSubmit.emit();
        } else {
          this.searchFormSubmitter()
        }

        return;
      }

      if (this.searchFormRef) {
        this.searchFormRef.ngSubmit.emit();
      } else {
        this.searchFormSubmitter()
      }
    }
  }

  itemChosenChange(event: any, item: any) {
    const occupied = this.chosenLineItems.find((row) => row.numbon == item.numbon);

    if(occupied) {
      this.chosenLineItems.map((row) => {
        if(row.numbon == item.numbon) {
          row.status = event;
        }
        return row;
      });
      this.chosenLineItems = this.chosenLineItems.filter((row) => row.status);
    } else {
      this.chosenLineItems.push({
        numbon: item.numbon,
        status: event,
        data: item
      });
    }
  }

  onCodeEntered(e: any) {
    const cdart = this.searchForm.get('ARTICLE');
    this._barCodeLogicCheck(cdart.value);
  }

  searchFormSubmitter(): void {
    this.showLoader = true;

    if (this.searchForm.invalid) {
      this.showLoader = false;
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));

      return;
    }

    const form_data = this.searchForm.getRawValue();

    this.storageService.setItem('bonCommandeFormData', form_data);
    this.currentPage = 0;

    let creeMinDate = '1899-12-30T00:00:00', creeMaxDate = '1899-12-30T00:00:00',
        echMinDate = '1899-12-30T00:00:00', echMaxDate = '1899-12-30T00:00:00';

    this.filterValues = form_data;

    const otherFilters = {
      NumBon: form_data.NUMBON != null ? form_data.NUMBON : '',
      Autre: form_data.AUTRE_CHOIX != null ? form_data.AUTRE_CHOIX : '-1',
      Tel: form_data.TEL_PORT != null ? form_data.TEL_PORT : '',
      Ville: form_data.VILLE !== null ? form_data.VILLE : '',
    };

    const search_data = {
      pMag: form_data.CMINT,
      pNumfid: '',
      pNom: form_data.NOM != null ? form_data.NOM : '',
      pPrenom: form_data.PRENOM != null ? form_data.PRENOM : '',
      pCodpos: form_data.CODPOS != null ? form_data.CODPOS : '',
      pMin: form_data.TOTAL_ENTRE_FROM != null ? form_data.TOTAL_ENTRE_FROM : '',
      pMax: form_data.TOTAL_ENTRE_TO != null ? form_data.TOTAL_ENTRE_TO : '',
      pDtCreMin: creeMinDate,
      pDtCreMax: creeMaxDate,
      pDtEchMin: echMinDate,
      pDtEchMax: echMaxDate,
      pArt: form_data.ARTICLE != null ? form_data.ARTICLE : '',
      pCanal: form_data.CANAL != null ? form_data.CANAL : '',
      pNumExterne: form_data.NUM_EXT != null ? form_data.NUM_EXT : '',
      pStatut: form_data.ETAT != null ? form_data.ETAT : 0,
      pNiv1: form_data.SECTEUR != null ? form_data.SECTEUR : 0,
      pRetrait1H: form_data.RETRAIT_1H != null ? form_data.RETRAIT_1H : false,
      pTypecde: '',
      pNbElements: this.rowsPerPage,
      pNumPage: this.currentPage + 1,
      pFilter: otherFilters,
    };

    this.searchGestionDesCommande(search_data);
  }

  searchGestionDesCommande(search_data: any) {
    this.bonCommandeService.searchBonDeCommande(search_data).subscribe(
        (response: any) => {
          if (response.success !== undefined) {
            if (response.data.ErrorCode == 0) {

              const data = response.data.Bons;
              this.totalPages = response.data.NbBons;

              this.listBonDeCommande = [...data];

              this.showLoader = false;
              this.dtTrigger.next();

              this.storageService.setItem('bonCommandeListData', response);
            } else {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kuconerr'), response.data.Error);
            }
          } else {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        err => {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  searchHistoriqueCommande(search_data: any) {
    this.bonCommandeService.searchHistoriqueCommande(search_data).subscribe(
        (response: any) => {
          if (response.success !== undefined) {
            if (response.data.ErrorCode == 0) {

              const data = response.data.Bons;
              this.totalPages = response.data.NbBons;

              this.listBonDeCommande = [...data];

              this.showLoader = false;
              this.dtTrigger.next();

              this.storageService.setItem('bonCommandeListData', response);
            } else {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kuconerr'), response.data.Error);
            }
          } else {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        err => {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  searchSupprimeeCommande(search_data: any) {
    this.bonCommandeService.searchCommandeSupprimes(search_data).subscribe(
        (response: any) => {
          if (response.success !== undefined) {
            if (response.data.ErrorCode == 0) {

              const data = response.data.Bons;
              this.totalPages = response.data.NbBons;

              this.listBonDeCommande = [...data];

              this.showLoader = false;
              this.dtTrigger.next();

              this.storageService.setItem('bonCommandeListData', response);
            } else {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kuconerr'), response.data.Error);
            }
          } else {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        err => {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  _alerteDateCalculation = (dateString: string): string => {
    if (dateString === undefined || dateString == null) { return ''; }
    if (moment(dateString).year() == 1899) { return ''; }

    const subtractedDate = moment(dateString).startOf('day').subtract(this.defaultDiffereDays, 'days').format('YYYY-MM-DD HH:mm:ss');
    return this.listDateFormatter(subtractedDate, false, false);
  }

  defineRowColor(item: any) {
    let colorClass = 'cde_en_cours';
    const today = moment();
    const dtech = moment(item.dtech).utcOffset(item.dtech);

    const dtechDiff = today.diff(dtech, 'days');

    const dtrel = moment(item.dtrel).utcOffset(item.dtrel);
    const ED_subtracted_date = moment().subtract(this.defaultDiffereDays, 'days');
    const dtrelDiff = ED_subtracted_date.diff(dtrel);

    if ((item.statut < 7) && ((item.dtech != 0) && dtechDiff > 0) && ((item.dtrel == 0) || dtrelDiff > 0)) {
      colorClass = 'cde_a_relancer';
    }

    if (item.livdirect == true) {
      colorClass = 'liv_directe';
    }

    if (item.livdeport == true) {
      colorClass = 'liv_deportee';
    }

    if (item.statut == 2 || item.statut == 9) {
      colorClass = 'e2_en_cours';
    }

    if (item.statut == 7 || item.statut == 8) {
      colorClass = 'cde_soldee';
    }

    if (item.statut == 17) {
      colorClass = 'cde_cloture_contient';
    }

    if (item.statut == 27) {
      colorClass = 'solde_partiel';
    }

    if (item.statut == 11) {
      colorClass = 'e2_partielle';
    }

    if (item.statut == 50) {
      colorClass = '';
    }

    if (item.statut >= 89 && item.statut <= 98) {
      colorClass = 'som_tick_art';
    }

    return colorClass;
  }

  redirectToModify(item: any) {
    if (this.pageType === 'retrait-marchandise') {
      if (this.detailBonCommandeVisibility) {
        this.router.navigate(['/retrait-marchandise-detail/', item.numbon, item.mag], { queryParams: { pageName: this.pageType } });
        return;
      }

      this.router.navigate(['/modify-retrait-marchandise', item.numbon, item.mag]);
      return;
    }

    if (this.pageType === 'gestion-des-e2') {
      if (this.detailBonCommandeVisibility) {
        this.router.navigate(['/gestion-des-e2-detail/', item.numbon], { queryParams: { pageName: this.pageType } });
        return;
      }

      this.router.navigate(['/modify-gestion-des-e2', item.numbon]);
      return;
    }


    if (this.detailBonCommandeVisibility || (this.pageType === 'historique' || this.pageType === 'supprimees')) {
      this.router.navigate(['/bon-de-commande-detail/', item.numbon, item.mag], { queryParams: { pageName: this.pageType } });
      return;
    }

    this.router.navigate(['/modify-bon-de-commande', item.numbon, item.mag]);
  }

  async onPrepaClick(item: any) {
    if (item.statut == 2) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('Pas de retrait possible sur les E2 en cours !'));
      return;
    }

    const confirmation = await this._prepaConfirmation().toPromise();
    if (confirmation !== 'OK') { return; }

    this.loadingService.show();

    const panierData: any = {
      mag: item.mag,
      articles: [],
      idinstuser: Constants.CIINST,
      iduser: this.user.CIINT,
      libuser: item.libuser,
      libusermaj: item.libusermaj,
      preparation: !item.preparation
    };

    // Prepa process
    const bonDeCommandeData = {
      pMag: item.mag,
      pNumBon: item.numbon,
      pPanier: panierData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };

    this.editBonDeCommande(bonDeCommandeData);
  }

  _prepaConfirmation(): Observable<any> {
    const message = `<h1 class="mb-3">${this.translateService.instant('Le bon est-il préparé ?')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'), this.translateService.instant('ksno')]
      }
    });

    return dialogRef.afterClosed();
  }

  editBonDeCommande(bonCommandeData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyBonCommande(bonCommandeData).subscribe(
          (resp) => {
            this.loadingService.hide();

            if (resp.statusCode == 200) {
              if (resp.data.ErrorCode == 0) {
                const bonNumBon = bonCommandeData.pNumBon;
                const updatedPanier = bonCommandeData.pPanier;

                this.__updateBonDeCommandeList(bonNumBon, updatedPanier);

                resolve({ response: resp });
              } else {
                this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                reject({ response: resp });
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
              resolve({ response: resp });
            }
          },
          err => {
            reject(err);
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
      );
    });
  }

  __updateBonDeCommandeList(bonNumBon: any, panier: BonPanier) {
    // List update after a success response
    const oldListData = this.storageService.getItem('bonCommandeListData');
    if (oldListData !== undefined && oldListData !== null && oldListData.data != null) {
      oldListData.data.Bons = oldListData.data.Bons.filter((_r: any) => _r !== null && _r != 'null');
      oldListData.data.Bons.map(
          (row: any) => {
            if (row.numbon == bonNumBon) {
              row.preparation = panier.preparation;
            }
            return row;
          }
      );

      const updatedCommande = oldListData.data.Bons.find(
          (row: any) => row.numbon == bonNumBon
      );

      const updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != bonNumBon);
      updated_row_removed.unshift(updatedCommande);

      oldListData.data.Bons = updated_row_removed;

      this.storageService.setItem('bonCommandeListData', oldListData);
    }

    this.listBonDeCommande.map((row) => {
      if (row.numbon == bonNumBon) {
        row.preparation = panier.preparation;
      }

      return row;
    })
  }

  searchPaginate(page?: number) {

    if (this.searchForm.invalid) {
      this.showLoader = false;
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));

      return;
    }

    const form_data = this.searchForm.getRawValue();

    this.storageService.setItem('bonCommandeFormData', form_data);

    let creeMinDate = '1899-12-30T00:00:00', creeMaxDate = '1899-12-30T00:00:00',
        echMinDate = '1899-12-30T00:00:00', echMaxDate = '1899-12-30T00:00:00';

    this.filterValues = form_data;
    let search_data: any = {};
    let otherFilters: any = {};

    switch (this.pageType) {
      case 'commande':
      case 'relance':

        otherFilters = {
          NumBon: form_data.NUMBON != null ? form_data.NUMBON : '',
          Autre: form_data.AUTRE_CHOIX != null ? form_data.AUTRE_CHOIX : '-1',
          Tel: form_data.TEL_PORT != null ? form_data.TEL_PORT : '',
          Ville: form_data.VILLE !== null ? form_data.VILLE : '',
        };

        search_data = {
          pMag: form_data.CMINT,
          pNumfid: '',
          pNom: form_data.NOM != null ? form_data.NOM : '',
          pPrenom: form_data.PRENOM != null ? form_data.PRENOM : '',
          pCodpos: form_data.CODPOS != null ? form_data.CODPOS : '',
          pMin: form_data.TOTAL_ENTRE_FROM != null ? form_data.TOTAL_ENTRE_FROM : '',
          pMax: form_data.TOTAL_ENTRE_TO != null ? form_data.TOTAL_ENTRE_TO : '',
          pDtCreMin: creeMinDate,
          pDtCreMax: creeMaxDate,
          pDtEchMin: echMinDate,
          pDtEchMax: echMaxDate,
          pArt: form_data.ARTICLE != null ? form_data.ARTICLE : '',
          pCanal: form_data.CANAL != null ? form_data.CANAL : '',
          pNumExterne: form_data.NUM_EXT != null ? form_data.NUM_EXT : '',
          pStatut: form_data.ETAT != null ? form_data.ETAT : 0,
          pNiv1: form_data.SECTEUR != null ? form_data.SECTEUR : 0,
          pRetrait1H: form_data.RETRAIT_1H != null ? form_data.RETRAIT_1H : false,
          pTypecde: '',
          pNbElements: this.rowsPerPage,
          pNumPage: page,
          pFilter: otherFilters,
        };

        this.searchGestionDesCommande(search_data);
        break;

      case 'historique':
        otherFilters = {
          NumBon: form_data.NUMBON != null ? form_data.NUMBON : '',
          Tel: form_data.TEL_PORT != null ? form_data.TEL_PORT : '',
          Ville: form_data.VILLE !== null ? form_data.VILLE : '',
        };

        search_data = {
          pMag: form_data.CMINT,
          pNumfid: '',
          pNom: form_data.NOM != null ? form_data.NOM : '',
          pPrenom: form_data.PRENOM != null ? form_data.PRENOM : '',
          pCodpos: form_data.CODPOS != null ? form_data.CODPOS : '',
          pMin: form_data.TOTAL_ENTRE_FROM != null ? form_data.TOTAL_ENTRE_FROM : '',
          pMax: form_data.TOTAL_ENTRE_TO != null ? form_data.TOTAL_ENTRE_TO : '',
          pDtCreMin: creeMinDate,
          pDtCreMax: creeMaxDate,
          pDtEchMin: echMinDate,
          pDtEchMax: echMaxDate,
          pArt: form_data.ARTICLE != null ? form_data.ARTICLE : '',
          pCanal: form_data.CANAL != null ? form_data.CANAL : '',
          pNumExterne: form_data.NUM_EXT != null ? form_data.NUM_EXT : '',
          pStatut: '',
          pNiv1: form_data.SECTEUR != null ? form_data.SECTEUR : 0,
          pRetrait1H: form_data.RETRAIT_1H != null ? form_data.RETRAIT_1H : false,
          pTypecde: form_data.TYPECDE != null ? form_data.TYPECDE : '',
          pNbElements: this.rowsPerPage,
          pNumPage: page,
          pFilter: otherFilters,
        };

        this.searchHistoriqueCommande(search_data);
        break;

      case 'supprimees':
        otherFilters = {
          NumBon: form_data.NUMBON != null ? form_data.NUMBON : '',
          Autre: form_data.AUTRE_CHOIX != null ? form_data.AUTRE_CHOIX : '-1',
          Tel: form_data.TEL_PORT != null ? form_data.TEL_PORT : '',
          Ville: form_data.VILLE !== null ? form_data.VILLE : '',
        };

        search_data = {
          pMag: form_data.CMINT,
          pNumfid: '',
          pNom: form_data.NOM != null ? form_data.NOM : '',
          pPrenom: form_data.PRENOM != null ? form_data.PRENOM : '',
          pCodpos: form_data.CODPOS != null ? form_data.CODPOS : '',
          pMin: form_data.TOTAL_ENTRE_FROM != null ? form_data.TOTAL_ENTRE_FROM : '',
          pMax: form_data.TOTAL_ENTRE_TO != null ? form_data.TOTAL_ENTRE_TO : '',
          pDtCreMin: creeMinDate,
          pDtCreMax: creeMaxDate,
          pDtEchMin: echMinDate,
          pDtEchMax: echMaxDate,
          pArt: form_data.ARTICLE != null ? form_data.ARTICLE : '',
          pCanal: form_data.CANAL != null ? form_data.CANAL : '',
          pNumExterne: form_data.NUM_EXT != null ? form_data.NUM_EXT : '',
          pStatut: '',
          pNiv1: form_data.SECTEUR != null ? form_data.SECTEUR : 0,
          pRetrait1H: form_data.RETRAIT_1H != null ? form_data.RETRAIT_1H : false,
          pTypecde: '',
          pNbElements: this.rowsPerPage,
          pNumPage: page,
          pFilter: otherFilters,
        };

        this.searchSupprimeeCommande(search_data);
        break;

      case 'retrait-marchandise':
        otherFilters = {
          NumBon: form_data.NUMBON != null ? form_data.NUMBON : '',
          Autre: form_data.AUTRE_CHOIX != null ? form_data.AUTRE_CHOIX : '-1',
          Tel: form_data.TEL_PORT != null ? form_data.TEL_PORT : '',
          Ville: form_data.VILLE !== null ? form_data.VILLE : '',
        };

        search_data = {
          pMag: form_data.CMINT,
          pNumfid: '',
          pNom: form_data.NOM != null ? form_data.NOM : '',
          pPrenom: form_data.PRENOM != null ? form_data.PRENOM : '',
          pCodpos: form_data.CODPOS != null ? form_data.CODPOS : '',
          pMin: form_data.TOTAL_ENTRE_FROM != null ? form_data.TOTAL_ENTRE_FROM : '',
          pMax: form_data.TOTAL_ENTRE_TO != null ? form_data.TOTAL_ENTRE_TO : '',
          pDtCreMin: creeMinDate,
          pDtCreMax: creeMaxDate,
          pDtEchMin: echMinDate,
          pDtEchMax: echMaxDate,
          pArt: form_data.ARTICLE != null ? form_data.ARTICLE : '',
          pCanal: form_data.CANAL != null ? form_data.CANAL : '',
          pNumExterne: form_data.NUM_EXT != null ? form_data.NUM_EXT : '',
          pStatut: form_data.ETAT != null ? form_data.ETAT : 0,
          pNiv1: form_data.SECTEUR != null ? form_data.SECTEUR : 0,
          pRetrait1H: form_data.RETRAIT_1H != null ? form_data.RETRAIT_1H : false,
          pTypecde: '',
          pNbElements: this.rowsPerPage,
          pNumPage: this.currentPage + 1,
          pFilter: otherFilters,
        };

        this.searchGestionDesCommande(search_data);
        break;

      case 'gestion-des-e2':
        otherFilters = {
          NumBon: form_data.NUMBON != null ? form_data.NUMBON : '',
          Autre: form_data.AUTRE_CHOIX != null ? form_data.AUTRE_CHOIX : '-1',
          Tel: form_data.TEL_PORT != null ? form_data.TEL_PORT : '',
          Ville: form_data.VILLE !== null ? form_data.VILLE : '',
        };

        search_data = {
          pMag: form_data.CMINT,
          pNumfid: '',
          pNom: form_data.NOM != null ? form_data.NOM : '',
          pPrenom: form_data.PRENOM != null ? form_data.PRENOM : '',
          pCodpos: form_data.CODPOS != null ? form_data.CODPOS : '',
          pMin: form_data.TOTAL_ENTRE_FROM != null ? form_data.TOTAL_ENTRE_FROM : '',
          pMax: form_data.TOTAL_ENTRE_TO != null ? form_data.TOTAL_ENTRE_TO : '',
          pDtCreMin: creeMinDate,
          pDtCreMax: creeMaxDate,
          pDtEchMin: echMinDate,
          pDtEchMax: echMaxDate,
          pArt: form_data.ARTICLE != null ? form_data.ARTICLE : '',
          pCanal: form_data.CANAL != null ? form_data.CANAL : '',
          pNumExterne: form_data.NUM_EXT != null ? form_data.NUM_EXT : '',
          pStatut: form_data.ETAT != null ? form_data.ETAT : 0,
          pNiv1: form_data.SECTEUR != null ? form_data.SECTEUR : 0,
          pRetrait1H: form_data.RETRAIT_1H != null ? form_data.RETRAIT_1H : false,
          pTypecde: '',
          pNbElements: this.rowsPerPage,
          pNumPage: this.currentPage + 1,
          pFilter: otherFilters,
        };

        this.searchGestionDesCommande(search_data);
        break;
    }
  }

  paginationChange(event: any) {
    this.showLoader = true;
    const changedPageIndex = event.pageIndex;

    this.currentPage = changedPageIndex;

    this.searchPaginate(changedPageIndex + 1);
  }

  ngOnDestroy(): void {
    if (this.router.routerState.snapshot.url.indexOf('search-gestion-des-e2') > -1) {

    } else {
      this.storageService.removeItem('bonCommandeFormData');
      this.storageService.removeItem('bonCommandeListData');
    }

    this.userService.headerSearch.next(false);
    this.userService.headerSearchInput.next(false);
    // this.searchSubscription.unsubscribe();
    // this.searchCodeSubscription.unsubscribe();

    // this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  async getListMagasinStructure(): Promise<void> {
    return new Promise((resolve, reject) => {
      const httpData = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        CIINT: this.user.CIINT,
        action: 'parent'
      };

      this.utilisatuersService.getMagasinStructure(httpData).subscribe(
          (res: any) => {
            if (res.success !== undefined) {
              this.magasinStructure = res.data;

              resolve();
            }
          },
          err => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject()
          }
      );
    });
  }

  _addSuppressionCommandeHistory(NUMBON: string, MOTIF: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_SUPPRESSION_COMMANDE,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedSuppressionCommandeUser !== undefined && this.authorizedSuppressionCommandeUser !== null) ? this.authorizedSuppressionCommandeUser.CIINT : this.user.CIINT,
      MOTIF: MOTIF,
      VALUER1: null,
      VALUER2: null,
    });
  }

  _addReactivationCommandeHistory(NUMBON: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REACTIVATION_COMMANDE,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedReactivationCommandeUser !== undefined && this.authorizedReactivationCommandeUser !== null) ? this.authorizedReactivationCommandeUser.CIINT : this.user.CIINT,
      VALUER1: null,
      VALUER2: null,
    });
  }

  async deleteConfirmation(item: any) {

    if (!this.userPermission['0_14']) {
      this.authorizedSuppressionCommandeUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '14', this.user.CIINT, item.numbon);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedSuppressionCommandeUser = authorizationResult.data;
    }

    const message = `
      <h1 class="text-left mb-3">${this.translateService.instant('kboncomsupprimercmd')}</h1>
      <h1 class="text-left pl-2">
        ${this.translateService.instant('kbonNum')} : ${item.numbon} <br>
        ${this.translateService.instant('kbonpour')}: ${item.prenom + ' ' + item.nom} <br>
        ${this.translateService.instant('kbonmontant')}: ${this.currencyPipe.transform(item.montant, null, false)}
      </h1>
      `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
        this.loadingService.show();
        this.openUpBondeCustomMotif(item);
      }
    });
  }

  openUpBondeCustomMotif(item: any): void {
    const dialogRef = this.dialog.open(BlocageCustomMotifComponent, {
      maxWidth: '40vw',
      data: {
        action: Constants.MOTIF_SUPPRESSION_COMMANDE
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        const custom_motif_data = result.data;

        const motifText = custom_motif_data.MOTLIB;
        this.deleteBonDeCommande({ pMag: this.USERCMINT, pNumBon: item.numbon, pMotif: motifText });
      }
    });
  }

  deleteBonDeCommande(data: { pMag: number, pNumBon: string, pMotif: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.removeBonCommande(data).subscribe(
        async (resp) => {
          this.loadingService.hide();
          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {

              if (this.authorizedSuppressionCommandeUser !== undefined && this.authorizedSuppressionCommandeUser != null) {
                await this._addSuppressionCommandeHistory(data.pNumBon, data.pMotif);
                this.authorizedSuppressionCommandeUser = undefined;
              }

              this.listBonDeCommande = this.listBonDeCommande.filter((el) => el.numbon != data.pNumBon);

              if (this.listBonDeCommande.length == 0) {
                this.showLoader = true;

                if (this.currentPage != 0) {
                  this.currentPage--;
                }

                this.searchPaginate(this.currentPage + 1);
              } else {
                const oldListData = this.storageService.getItem('bonCommandeListData');

                if (oldListData !== undefined && oldListData != 'null') {
                  oldListData.data.Bons = this.listBonDeCommande;
                }

                this.storageService.setItem('bonCommandeListData', oldListData);
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
          }
        },
        error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }
    );
  }

  async reactivateConfirmation(item: any) {
    if (!this.userPermission['0_15']) {
      this.authorizedReactivationCommandeUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '15', this.user.CIINT, item.numbon);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedReactivationCommandeUser = authorizationResult.data;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: this.translateService.instant('kboncomreactiverbon'),
        description: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
        this.loadingService.show();

        if (this.pageType === 'historique') {
          this.reactivateBonHistory({ pMag: this.USERCMINT, pNumBon: item.numbon });
        }

        if (this.pageType === 'supprimees') {
          this.reactivateBonSupprimees({ pMag: this.USERCMINT, pNumBon: item.numbon });
        }
      }
    });
  }

  reactivateBonSupprimees(data: { pMag: number, pNumBon: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.reactiverBonCommande(data).subscribe(
        async (resp) => {
          this.loadingService.hide();
          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {

              if (this.authorizedReactivationCommandeUser !== undefined && this.authorizedReactivationCommandeUser != null) {
                await this._addReactivationCommandeHistory(data.pNumBon);
                this.authorizedReactivationCommandeUser = undefined;
              }

              this.listBonDeCommande = this.listBonDeCommande.filter((el) => el.numbon != data.pNumBon);

              if (this.listBonDeCommande.length == 0) {
                this.showLoader = true;

                if (this.currentPage != 0) {
                  this.currentPage--;
                }

                this.searchPaginate(this.currentPage + 1);
              } else {
                const oldListData = this.storageService.getItem('bonCommandeListData');

                if (oldListData !== undefined && oldListData != 'null') {
                  oldListData.data.Bons = this.listBonDeCommande;
                }

                this.storageService.setItem('bonCommandeListData', oldListData);
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kconnfail'));
          }
        },
        error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kconnfail'));
        }
    );
  }

  reactivateBonHistory(data: { pMag: number, pNumBon: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.reactiverBonCommandeHistory(data).subscribe(
        async (resp) => {
          this.loadingService.hide();
          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {

              if (this.authorizedReactivationCommandeUser !== undefined && this.authorizedReactivationCommandeUser != null) {
                await this._addReactivationCommandeHistory(data.pNumBon);
                this.authorizedReactivationCommandeUser = undefined;
              }

              this.listBonDeCommande = this.listBonDeCommande.filter((el) => el.numbon != data.pNumBon);

              if (this.listBonDeCommande.length == 0) {
                this.showLoader = true;

                if (this.currentPage != 0) {
                  this.currentPage--;
                }

                this.searchPaginate(this.currentPage + 1);
              } else {
                const oldListData = this.storageService.getItem('bonCommandeListData');

                if (oldListData !== undefined && oldListData != 'null') {
                  oldListData.data.Bons = this.listBonDeCommande;
                }

                this.storageService.setItem('bonCommandeListData', oldListData);
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kconnfail'));
          }
        },
        error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kconnfail'));
        }
    );
  }

  viewBonVenteDetail(item: BonVente) {
    this.router.navigate(['/detail-bon-de-vente', item.NUMCOM, item.CMINT]);
  }

  resetForm() {
    this.searchForm.reset();
    this.storageService.removeItem('bonCommandeFormData');

    this.searchForm.patchValue({
      IDSOC: Constants.IDSOC,
      CMINT: this.USERCMINT,
      SECTEUR: '',
      BONS_E2: '',
      LIBEL: '',
    });

    // To empty the search input value and trigger the searchFormSubmitter
    this.userService.headerSearchInputValue.next('');
  }

  listDateFormatter(date: string, offset = true, today_time = true) {
    if (date === undefined || date  === null) {
      return '';
    }

    const given_date = moment(date).format('DD.MM.YYYY');
    const today_date = moment().format('DD.MM.YYYY');

    if (given_date === undefined || moment(date).year() === 1899) {
      return '';
    }

    if (given_date === today_date && today_time) {
      return moment(date).utcOffset(date).format('HH:mm');
    }

    if (offset) {
      return moment(date).utcOffset(date).format('DD.MM.YY');
    }
    return moment(date).format('DD.MM.YY');
  }

  async affectationGlobaleConfirmation() {
    const message = `<h1 class="text-left mb-3">${this.translateService.instant('kaffectationglobaleconf')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'), this.translateService.instant('ksno')]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
      }
    });
  }

  openUpModifierPopup() {
    const dialogRef = this.dialog.open(ModifierGestionDesE2Component,
        {
          height: this.isMobile ? '100%' : undefined,
          minWidth: '60vw',
          data: {
            displayType: 'dialog',
          }
        }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  openUpPreAffectationPopup() {
    const dialogRef = this.dialog.open(GestionPreAffectationComponent,
        {
          height: this.isMobile ? '100%' : undefined,
          minWidth: '60vw',
          data: {
            displayType: 'dialog',
          }
        }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  openUpDesaffecterPopup() {
    const dialogRef = this.dialog.open(DesaffecterE2Component,
        {
          height: this.isMobile ? '100%' : undefined,
          minWidth: '60vw',
          data: {
            displayType: 'dialog',
          }
        }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  openUpTickets(item: any) {
    const dialogRef = this.dialog.open(TicketsComponent, {
      minWidth: '82vw',
      height: '40vw',
      data: {
        numBon: item.numbon,
        commandeData: item,
        CMINT: item.mag,
        pageType: this.pageType
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.status === 'success') {
      }
    });
  }

  viewBonCommandeHistory(item: any) {
    if (!item) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    let noteReadOnly = this.detailBonCommandeVisibility;
    if (!this.userPermission['32_10']) {
      noteReadOnly = true;
    }

    if (this.pageType === 'historique' || this.pageType === 'supprimees') {
      noteReadOnly = true;
    }

    const dialogRef = this.dialog.open(HistoriqueBondeComponent,
        {
          data: {
            panier: undefined,
            numBon: item.numbon,
            cdeRow: item,
            CMINT: item.mag,
            historyType: this.pageType === 'historique' ? 'historique' : 'commande',
            readOnly: noteReadOnly
          }
        }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  openColorCode() {
    this.userService.headerSearch.next(false);
    const dialogRef = this.dialog.open(ColorsListComponent, {
      width: '30vw',
      data: {
        type: this.pageType
      }
    });
  }

  viewBonCommande(item: any) {
    this.router.navigate(['/bon-de-commande-detail', item.numbon, item.mag], {queryParams: {pageName: this.pageType}});
  }

  openUpBondeFilterScreen() {
    this.userService.headerSearch.next(undefined);
    const dialogRef = this.dialog.open(GestionDesE2FilterComponent, {
      width: '60vw',
      maxHeight: this.isMobile ? '85vh' : undefined,
      data: {
        form_data: this.searchForm.value,
        user: this.user,
        USERCMINT: this.USERCMINT,
        pageType: this.pageType,
        pageTitle: this.pageTitle,

        magasinStructure: this.magasinStructure,
        bonsE2List: this.bonsE2List,
        fournisseurNomList: this.fournisseurNomList,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        const form_data = result.form_data;

        this.searchForm.patchValue({
          IDSOC: form_data.IDSOC !== null ? form_data.IDSOC : Constants.IDSOC,
          CMINT: form_data.CMINT !== null ? form_data.CMINT : this.USERCMINT,
          NUMBON: form_data.NUMBON !== null ? form_data.NUMBON : '',
          NOM: form_data.NOM !== null ? form_data.NOM : '',
          PRENOM: form_data.PRENOM !== null ? form_data.PRENOM : '',
          CODPOS: form_data.CODPOS !== null ? form_data.CODPOS : '',
          VILLE: form_data.VILLE !== null ? form_data.VILLE : '',
          TEL_PORT: form_data.TEL_PORT !== null ? form_data.TEL_PORT : '',

          TOTAL_ENTRE_FROM: form_data.TOTAL_ENTRE_FROM !== null ? form_data.TOTAL_ENTRE_FROM : '',
          TOTAL_ENTRE_TO: form_data.TOTAL_ENTRE_TO !== null ? form_data.TOTAL_ENTRE_TO : '',
          NUM_EXT: form_data.NUM_EXT !== null ? form_data.NUM_EXT : '',
          BONS_E2: form_data.BONS_E2 !== null ? form_data.BONS_E2 : '',
          ARTICLE: form_data.ARTICLE !== null ? form_data.ARTICLE : '',
          SECTEUR: form_data.SECTEUR !== null ? form_data.SECTEUR : '',

          CODE: form_data.CODE !== null ? form_data.CODE : '',
          LIBEL: form_data.LIBEL !== null ? form_data.LIBEL : '',
          ORDER_BY: form_data.ORDER_BY !== null ? form_data.ORDER_BY : '',
        });

        this.filterValues = form_data;
        this.searchFormSubmitter();
      }
    });

  }

  onSwipe(e: any, index: number) {
    if (this.pageType !== 'historique' && this.pageType !== 'supprimees' && this.detailBonCommandeVisibility) {
      return;
    }

    if (e.deltaX > 0) {
      // Right Swipe
      const element = document.getElementById('swipeItem-' + index);
      element.style.transform = 'translateX(0)'
      element.classList.remove('swiped');
    } else {
      // Left Swipe
      this.__resetPreviousSwipes('.mat-list-item');

      const element = document.getElementById('swipeItem-' + index);
      element.classList.add('swiped');
      element.style.transform = 'translateX(-100px)'
    }
  }

  __resetPreviousSwipes(listEl: string) {
    const allSwipeAbles = document.querySelectorAll(listEl);
    allSwipeAbles.forEach((el: any, i) => {
      el.classList.remove('swiped');
      el.style.transform = 'translateX(0)';
    })
  }

  get filterValuesFormat(): string {
    const filterArr = [];

    if (this.filterValues.NUMBON !== undefined && this.filterValues.NUMBON !== null && this.filterValues.NUMBON !== '') {
      filterArr.push(this.filterValues.NUMBON);
    }
    if (this.filterValues.NOM !== undefined && this.filterValues.NOM !== null && this.filterValues.NOM !== '') {
      filterArr.push(this.filterValues.NOM);
    }
    if (this.filterValues.PRENOM !== undefined && this.filterValues.PRENOM !== null && this.filterValues.PRENOM !== '') {
      filterArr.push(this.filterValues.PRENOM);
    }
    if (this.filterValues.CODPOS !== undefined && this.filterValues.CODPOS !== null && this.filterValues.CODPOS !== '') {
      filterArr.push(this.filterValues.CODPOS);
    }
    if (this.filterValues.VILLE !== undefined && this.filterValues.VILLE !== null && this.filterValues.VILLE !== '') {
      filterArr.push(this.filterValues.VILLE);
    }
    if (this.filterValues.TEL_PORT !== undefined && this.filterValues.TEL_PORT !== null && this.filterValues.TEL_PORT !== '') {
      filterArr.push(this.filterValues.TEL_PORT);
    }
    if (this.filterValues.TOTAL_ENTRE_FROM !== undefined && this.filterValues.TOTAL_ENTRE_FROM !== null && this.filterValues.TOTAL_ENTRE_FROM !== '') {
      filterArr.push(this.filterValues.TOTAL_ENTRE_FROM);
    }
    if (this.filterValues.TOTAL_ENTRE_TO !== undefined && this.filterValues.TOTAL_ENTRE_TO !== null && this.filterValues.TOTAL_ENTRE_TO !== '') {
      filterArr.push(this.filterValues.TOTAL_ENTRE_TO);
    }
    if (this.filterValues.NUM_EXT !== undefined && this.filterValues.NUM_EXT !== null && this.filterValues.NUM_EXT !== '') {
      filterArr.push(this.filterValues.NUM_EXT);
    }

    if (this.filterValues.BONS_E2 !== undefined && this.filterValues.BONS_E2 !== null && this.filterValues.BONS_E2 !== '') {
      if (this.bonsE2List) {
        const bonsE2Exist = this.bonsE2List.find(rw => rw.id === this.filterValues.BONS_E2);
        filterArr.push(this.translateService.instant(bonsE2Exist.label));
      }
    } else {
      filterArr.push(this.translateService.instant('ktouteslese2encours'));
    }

    if (this.filterValues.ARTICLE !== undefined && this.filterValues.ARTICLE !== null && this.filterValues.ARTICLE !== '') {
      filterArr.push(this.filterValues.ARTICLE);
    }

    if (this.filterValues.SECTEUR !== undefined && this.filterValues.SECTEUR !== null && this.filterValues.SECTEUR !== '') {
      if (this.magasinStructure) {
        const magasinLabel = this.magasinStructure.find((row: any) => row.CSTNIV1 == this.filterValues.SECTEUR);
        filterArr.push(this.translateService.instant(magasinLabel.CSTLIB1));
      }
    } else {
      filterArr.push(this.translateService.instant('kutoussecteur'));
    }

    if (this.filterValues.CODE !== undefined && this.filterValues.CODE !== null && this.filterValues.CODE !== '') {
      filterArr.push(this.filterValues.CODE);
    }

    if (this.filterValues.LIBEL !== undefined && this.filterValues.LIBEL !== null && this.filterValues.LIBEL !== '') {
      if (this.filterValues.LIBEL === 'tous') {
        filterArr.push(this.translateService.instant('kuall'));
      } else {
        if (this.fournisseurNomList) {
          const fournisseurNomItem = this.fournisseurNomList.find((row: any) => row.id == this.filterValues.LIBEL);
          filterArr.push(this.translateService.instant(fournisseurNomItem.label));
        }
      }
    }

    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
