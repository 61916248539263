import {Component, OnInit, Input, ViewChild, Inject, Optional} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';


import { TranslateService } from '@ngx-translate/core';
import { BonVenteService } from 'providers/api/bonventeService';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import { DialogsService } from 'providers/api/dialogService';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-remise-list',
  templateUrl: './remise-list.component.html',
  styleUrls: ['./remise-list.component.scss'],
  providers: [BonVenteService, TranslateService]
})
export class RemiseListComponent implements OnInit {
  
  user: UserModel;
  USERCMINT: any;  

  listRemises: any;
  filteredRemises: any;
  showLoader = true;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  @Input() displayType: 'dialog' | 'component' = 'dialog';

  remiseForm: UntypedFormGroup;
  remiseType: number = 2;
  totalPrice: number;

  chosenRemise: any[];  

  panier: BonPanier;
  lineItem: PanierArticle;

  userPermission: any;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<RemiseListComponent>,                            
              private bonVenteService: BonVenteService, 
              private translateService: TranslateService,
              private dialogService: DialogsService,
              private userService: UserService,
              private formBuilder: UntypedFormBuilder,
              private storageService: StorageService) {

  }

  ngOnInit() {
      this.user = this.userService.getCurrentUser();

      this.userPermission = this.storageService.getItem('CurrentUserPermission');
      
      this.USERCMINT = this.data.CMINT;
      this.remiseType = this.data.remiseType;
      this.totalPrice = this.data.totalPrice;
      this.chosenRemise = this.data.previousChosenRemise;
      this.panier = this.data.panier;
      this.lineItem = this.data.line_item;

      this.remiseForm = this.formBuilder.group({
        remise: new UntypedFormControl(['none', Validators.required])
      });

      this.dtOptions = {
          pageLength: 5,
          lengthMenu: [[50, -1], [50, this.translateService.instant('karttous')]],
          language: {
              processing: this.translateService.instant('kprocessing'),
              search: this.translateService.instant('ksearch'),
              loadingRecords: this.translateService.instant('kloading'),
              info: this.translateService.instant('kgenshowentry'),
              infoEmpty: this.translateService.instant('kgenshowingentry'),
              zeroRecords: this.translateService.instant('knodata'),
              emptyTable: this.translateService.instant('knodatadash'),
              paginate: {
                  first: this.translateService.instant('kfirst'),
                  previous: this.translateService.instant('kprevious'),
                  next: this.translateService.instant('knext'),
                  last: this.translateService.instant('klast'),
              }         
          },   
          ordering: false,
          order: [
              [ 0, 'asc' ]
          ],
          destroy: true
      };      

      this.getRemiseList(this.remiseType);             
      
      if(this.panier !== undefined && this.panier !== null && this.panier.datarem !== null && this.panier.datarem !== '') {
        const multiSplitter = this.panier.datarem.split(';');

        let discountsSelected = [];
        for(let i=0;i < multiSplitter.length;i++) {
          const [REMMCODE, REMMTAUX, REMMMONTANT] = multiSplitter[i].split('#');

          discountsSelected.push(REMMCODE);
        }
        
        this.remiseForm.get('remise').setValue(discountsSelected);
      }

      if(this.remiseType === 1) {
        if(this.lineItem !== undefined && this.lineItem !== null && this.lineItem.datarem !== null && this.lineItem.datarem !== '') {
          const multiSplitter = this.lineItem.datarem.split(';');

          let discountsSelected = [];
          for(let i=0;i < multiSplitter.length;i++) {
            const [REMMCODE, REMMTAUX, REMMMONTANT] = multiSplitter[i].split('#');
            discountsSelected.push(REMMCODE);
          }

          this.remiseForm.get('remise').setValue(discountsSelected);
        }
      }

      if(this.chosenRemise !== undefined && this.chosenRemise !== null && this.chosenRemise.length > 0) {
        let discountsSelected = [];
        this.chosenRemise.map(
          (row: any) => {
            discountsSelected.push(row.REMMCODE);
          }
        );

        this.remiseForm.get('remise').setValue(discountsSelected);
      }
  }

  getRemiseList(type: number) {
      this.bonVenteService.getRemiseList(type, this.USERCMINT, this.user.CIINT).subscribe(
          (res) => {
              this.showLoader = false;
              if(res.success !== undefined) {
                  this.listRemises = res.data;
                  this.filteredRemises = this.listRemises.filter((row) => {
                    return row.REMMACTIF == '1';
                  })

                  if(this.chosenRemise !== undefined && this.chosenRemise !== null && this.chosenRemise.length > 0) {
                    let discountsSelected = [];
                    this.chosenRemise.map(
                      (row: any) => {
                        discountsSelected.push(row.REMMCODE);
                      }
                    );
            
                    this.remiseForm.get('remise').setValue(discountsSelected);
                  }
              }
          },
          error => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            this.showLoader = false;
          }
      );
  }

  remiseFormSubmitter() {
    if(this.remiseForm.invalid) {

      return;
    }    

    if((this.totalPrice === undefined || this.totalPrice === null || +this.totalPrice === 0) && !this.userPermission['0_22']) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kboninvalid')).toPromise().then(
        () => {
          this.dialogRef.close();
        }
      );
      return;
    }

    const form_data = this.remiseForm.getRawValue();

    if(form_data.remise !== '' && form_data.remise !== null && form_data.remise.length > 0) {
      this.chosenRemise = this.listRemises.filter(
        (row: any) => {
          if(form_data.remise.includes(row.REMMCODE)) {
            return row;
          }
        }
      );
    }
    
    this.dialogRef.close({ status: 'success', data: this.chosenRemise });
  }

  changeRemise(item: string[]) {
    if(item !== undefined && item !== null && item.length > 0) {
      this.chosenRemise = this.listRemises.filter(
        (row: any) => {
          if(item.includes(row.REMMCODE)) {
            return row;
          }
        }
      );
    } else {
      this.chosenRemise = [];
    }
  }

}
