import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserModel } from 'models/user-model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MagComplementService } from 'providers/api/magComplementService';
import { UserService } from 'providers/api/userService';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'providers/constants';
import { ParamDetail } from 'models/paramDetail';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-magasin-complement-info',
  templateUrl: './magasin-complement-info.component.html',
  styleUrls: ['./magasin-complement-info.component.scss']
})
export class MagasinComplementInfoComponent implements OnInit {
  public magData;
  @ViewChild('magModalTemplate') magModalTemplate;
  @ViewChild('kcimsmtpmdp') smtpPass: ElementRef;
  @ViewChild('kcimemailstore') smtpEmail: ElementRef;
  @ViewChild('kcimemaillivdeport') smtpLivraison: ElementRef;

  user: UserModel;
  USERCMINT: any;

  dialogRef: any;
  isMobile: boolean;
  
  paramData: ParamDetail;
  formData = {
    "CMINT": "",
    "OKLIVDEPORT": false,
    "OKLIVDIRECT": false,
    "IDSOC": "",
    "MAILMAG": "",
    "MAILSMTP": "",
    "MAILPASS": "",
    "MAILDEPORT": ""
  }

  constructor(
    private userService: UserService,
    private magComplementService: MagComplementService,
    private dialog: MatDialog,
    private deviceDetector: DeviceDetectorService,
    private loadingService: NgxSpinnerService,
    private dialogService: DialogsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;    
    this.userService.headerSearchButton.next(false);
    
    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();

    await Promise.all([
      this.fetchMagComplementData(),
      this._getUtilisationLivraision()
    ]);

    this.loadingService.hide();
  }

  async _getUtilisationLivraision() {
    try {
      this.paramData = await this.userService.getRuleParamsData(20, 21);
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  get livraisonDeporteeStat() {
    return this.paramData !== undefined && this.paramData['VBOOL3'] == '-1';
  }

  get livraisonDirecteStat() {
    return this.paramData !== undefined && this.paramData['VBOOL4'] == '-1';
  }

  async fetchMagComplementData() {
    return new Promise((resolve, reject) => {
      this.magComplementService.getMagComplementData(this.USERCMINT).subscribe(result => {
        this.magData = result.data[0];
        resolve(result);
      },
      err => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      })
    })
  }
  
  moveFocus(event: ElementRef) {
    event.nativeElement.focus();
    event.nativeElement.select();
  }

  magSupFunction() { 
    this.formData.CMINT = this.USERCMINT;
    this.formData.IDSOC = Constants.IDSOC;
    if(this.magData){
    this.formData = {
      "CMINT": this.USERCMINT,
      "OKLIVDEPORT": this.magData.OKLIVDEPORT == '-1' ? true : false,
      "OKLIVDIRECT": this.magData.OKLIVDIRECT == '-1' ? true : false,
      "IDSOC": this.magData.IDSOC,
      "MAILMAG": this.magData.MAILMAG,
      "MAILSMTP": this.magData.MAILSMTP,
      "MAILPASS": this.magData.MAILPASS,
      "MAILDEPORT": this.magData.MAILDEPORT    
    }    
  }
    this.dialogRef = this.dialog.open(this.magModalTemplate);
  }

  updateMagData() {
    this.loadingService.show();
    const postData = {
      "CMINT": this.formData.CMINT,
      "OKLIVDEPORT": this.formData.OKLIVDEPORT == true ? '-1' : '0',
      "OKLIVDIRECT": this.formData.OKLIVDIRECT == true ? '-1' : '0',
      "IDSOC": this.formData.IDSOC,
      "MAILMAG": this.formData.MAILMAG,
      "MAILSMTP": this.formData.MAILSMTP,
      "MAILPASS": this.formData.MAILPASS,
      "MAILDEPORT": this.formData.MAILDEPORT
    }    
    this.magComplementService.updateMagComplementData(postData).subscribe(result => {
      this.loadingService.hide();
      if (result['success'] == 'ok') {
        this.closeMagSupFunction();
        this.fetchMagComplementData();
      }
    },
    err => {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    });
  }
  closeMagSupFunction() {
    this.dialogRef.close();
  }

}
