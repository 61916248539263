<div class="modal-container w-100" id="couche_prix_article">    
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>{{'kclient_his' | translate}}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-12 no-padding table-scroller">
                    <div class="py-2" *ngIf="!isMobile">
                        <mat-paginator (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                    [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                    >
                        </mat-paginator>
                    </div>
                    
                    <table id="article_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border table-bordered hover dataTable no-footer">
                        <thead>
                            <tr>
                                <th width="12%">{{'kbondate' | translate}}</th>
                                <th>{{'kbonutilistateur' | translate}}</th>
                                <th>{{'kbonoperation' | translate}}</th>
                                <th>{{'kclient_his_old_value' | translate}}</th>
                                <th>{{'kclient_his_new_value' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listHistory?.length != 0 && !showLoader">
                            <tr *ngFor="let history of listHistory">
                                <td >{{ history.DTTRACE | date: 'dd.MM.yyyy HH:mm' }}</td>
                                <td>
                                    <span>{{ history.USERPRENOM }}</span>
                                    <span *ngIf="history.USERNOM"> {{ history.USERNOM.slice(0,1) }}.</span>
                                </td>
                                <td>{{ history.TYPETRACE | translate }}</td>
                                <td>{{ history.OLDVALUE }}</td>
                                <td>{{ history.NEWVALUE }}</td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="listHistory?.length == 0 || showLoader">
                            <tr>                                
                                <td colspan="7" *ngIf="listHistory?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                <td colspan="7" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                <td class="d-none">&nbsp;</td>
                                <td class="d-none">&nbsp;</td>
                                <td class="d-none">&nbsp;</td>
                                <td class="d-none">&nbsp;</td>
                            </tr>                            
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">done</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kuok' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>    
</div>
