import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService'


@Component({
  selector: 'app-postal-code-filter',
  templateUrl: './postal-code-filter.component.html',
  styleUrls: ['./postal-code-filter.component.scss']
})
export class PostalCodeFilterComponent implements OnInit, AfterViewInit {

  @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;
  searchForm: any;
  orderByList: any;
  oldFormData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PostalCodeFilterComponent>,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService) { }

  ngOnInit(): void {
    this.orderByList = this.data.orderByList;
    this.oldFormData = this.data.form_data;

    this.searchForm = this.formBuilder.group({
      postal_code: ['', [Validators.pattern('[0-9]*'), Validators.maxLength(5)]],
      city: [''],
      order: [Constants.POSTAL_CODE_ORDER_CODE],
      sort: 'asc'
    });

    this.searchForm.patchValue({
      postal_code: this.oldFormData.postal_code,
      city: this.oldFormData.city,
      order: this.oldFormData.order,
      sort: 'asc'
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.filterFirst.nativeElement.select();
      this.filterFirst.nativeElement.focus();
    }, 300);
  }

  headerSearchFormSubmitter() {
    this.userService.headerSearchInputValue.next(undefined);
    this.dialogRef.close({status: 'success', form_data: this.searchForm.value });
  }

  resetForm(){            
    this.searchForm.patchValue({
      postal_code: '',
      city: '',
      order: Constants.POSTAL_CODE_ORDER_CODE
    });
  }

}
