import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MotifBlocage } from 'models/motifBlocage';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogsService } from 'providers/api/dialogService';
import { MotifBlocageService } from 'providers/api/motifBlocageService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-blocage-deblocage-stock',
  templateUrl: './blocage-deblocage-stock.component.html',
  styleUrls: ['./blocage-deblocage-stock.component.scss'],
  providers: [MotifBlocageService]
})
export class BlocageDeblocageStockComponent implements OnInit {  

  // Service
  listMotifs: MotifBlocage[] = [];

  user: any;
  USERCMINT: any;

  // DATATABLE  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  isMobile: boolean = false;  

  constructor(    
    private userService: UserService, 
    public motifService: MotifBlocageService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService) { 
     
  }

  ngOnInit(): void {

    this.isMobile = this.deviceService.isMobile();

    this.userService.headerSearchButton.next(false);
    this.userService.headerSearch.next(false);

    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
  
    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }          
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };          
    
    this.getListMotifs();
  }

  ngOnDestroy(): void {
    this.userService.headerSearch.next(false);  
    this.userService.headerSearchButton.next(false);  
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {      
      this.dtTrigger.next();
  }

  getListMotifs(page?: number) {
    const httpData = {
      action: Constants.MOTIF_DEBLOCAGE_STOCK,
      CMINT: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      CIINT: this.user.CIINT,
    };

    if(page && !isNaN(page) && page > 0) {
      httpData['page'] = page;
    }
    
    this.motifService.getListofMotifs(httpData).subscribe(
      (response) => {        
        const data = response.data;

        this.rowsPerPage = response.rowsPerPage;
        this.totalPages = response.count;        

        this.listMotifs = [...data];
        
        this.showLoader = false;
        this.dtTrigger.next();
      },
      error => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
      }
    )  
  }

  paginationChange(event: any) {
    this.showLoader = true;
    const changedPageIndex = event.pageIndex;    

    this.currentPage = changedPageIndex;

    this.getListMotifs(changedPageIndex + 1);    
  }

  updateBlocageStock(event: any, type: 'modify' | 'visible', motif: MotifBlocage): void {

    const blocage_update_data: any = {
      CMINT: motif.CMINT === null?this.USERCMINT:motif.CMINT,
      IDSOC: motif.IDSOC,
      IDMOT: motif.IDMOT,      
    };

    if(type === 'visible') {
      blocage_update_data.OKVISIBLE = event.checked?'-1':'0';

      if(!event.checked) {
        this.listMotifs.map(
          (item) => {
            if(item.IDMOT == motif.IDMOT) {
              item.OKMODIF = '0';
              return item;
            }
            return item;
          }
        );
        blocage_update_data.OKMODIF = '0';
      } else {
        blocage_update_data.OKMODIF = motif.OKMODIF !== null?motif.OKMODIF.toString():'0';
      }

      blocage_update_data.OKMODIF = motif.OKMODIF !== null?motif.OKMODIF.toString():'0';
    } else {
      blocage_update_data.OKVISIBLE = motif.OKVISIBLE.toString();
      blocage_update_data.OKMODIF = event.checked?'-1':'0';
    }

    if(type === 'visible') {
      this.listMotifs.map(
        (row) => {
          if(row.IDMOT == blocage_update_data.IDMOT) {
            row.OKVISIBLE = blocage_update_data.OKVISIBLE;
            return row;
          }
          return row;
        }
      );
    } else {
      this.listMotifs.map(
        (row) => {
          if(row.IDMOT == blocage_update_data.IDMOT) {
            row.OKMODIF = blocage_update_data.OKMODIF;
            return row;
          }
          return row;
        }
      );
    }

    this.motifService.updateMotifBlocage(blocage_update_data).subscribe(
      (resp) => {
        if(resp.statusCode == 200) {
          if(type === 'visible') {
            this.listMotifs.map(
              (row) => {
                if(row.IDMOT == blocage_update_data.IDMOT) {
                  row.OKVISIBLE = blocage_update_data.OKVISIBLE;
                  return row;
                }
                return row;
              }
            );
          } else {
            this.listMotifs.map(
              (row) => {
                if(row.IDMOT == blocage_update_data.IDMOT) {
                  row.OKMODIF = blocage_update_data.OKMODIF;
                  return row;
                }
                return row;
              }
            );
          }
        } else {

          if(type === 'visible') {
            this.listMotifs.map(
              (row) => {
                if(row.IDMOT == blocage_update_data.IDMOT) {
                  row.OKVISIBLE = blocage_update_data.OKVISIBLE == '0'?'-1':'0';
                  return row;
                }
                return row;
              }
            );
          } else {
            this.listMotifs.map(
              (row) => {
                if(row.IDMOT == blocage_update_data.IDMOT) {
                  row.OKMODIF = blocage_update_data.OKMODIF == '0'?'-1':'0';
                  return row;
                }
                return row;
              }
            );
          }

          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kpupdateerr'));
        }
      },
      err => {
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));        
      }
    )
  }
}
