<h1 mat-dialog-title class="text-center">{{'kartventi' | translate}}</h1>
<div mat-dialog-content [class.p-4]="isMobile">
    <form [formGroup]="editMotifBlocageForm" (ngSubmit)="updateBlocageExterne()">
        <div class="bloc_container bg_light pt-3" [class.padding_15]="!isMobile">
            <div class="row">
                <div class="col-6 col-md-6">
                    <mat-form-field floatLabel="always">
                        <input matInput inputmode="decimal" [placeholder]="'kartcrntqnty' | translate" formControlName="ACTUAL_QTY" />
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-6">
                    <mat-form-field floatLabel="always">
                        <input matInput inputmode="decimal" [placeholder]="'kartqntyaffected' | translate" formControlName="AFFECT_QTY" />
                    </mat-form-field>                    
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field floatLabel="always">
                        <input matInput inputmode="decimal" [placeholder]="'kartqntyremain' | translate" formControlName="REMAIN_QTY" />
                    </mat-form-field>
                </div>
                <div class="clearfix"></div>

                <div class="col-12 col-md-12" *ngIf="motifBlocageData">
                    <h6 class="strong-heading"><strong>{{'kartmotif' | translate}}</strong></h6>
                    <p class="light-heading">{{ motifBlocageData.LIBEL }}</p>
                </div>
            </div>
        </div>    
        <div class="bloc_container bg_light table-scroller" [class.padding_15]="!isMobile">
            <table *ngIf="listMotifBlocage" id="stock_motif_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover dataTable no-footer">
                <thead>
                    <tr>
                        <th class="text-left">{{'kartmotif' | translate}}</th>
                        <th class="text-right">{{'kartqntybloq' | translate}}</th>
                        <th class="text-right">{{'kartqtyaff' | translate}}</th>
                        <th class="text-right">{{'kartqtytot' | translate}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="listMotifBlocage && listMotifBlocage?.length != 0 && !showLoader">
                    <tr *ngFor="let motif of listMotifBlocage" [class.non-editable]="motif && motif.OKMODIF != '-1'" [matTooltip]="(motif && motif.OKMODIF != '-1')?'':('karteditbloqtock' | translate)" (click)="checkEditable(motif)">
                        <td class="text-left">{{ motif.LIBEL }}</td>
                        <td  class="text-right">{{ (motif.VBLOC?motif.VBLOC:0) | number: '0.2' }}</td>
                        <td  class="text-right" [class]="(motif.affected && motif.affected > 0)?'text-danger font-weight-bold':''">{{ (motif.affected?motif.affected:0) | number: '0.2' }}</td>
                        <td  class="text-right">{{ (motif.affected?(+motif.affected)+(+motif.VBLOC):(+motif.VBLOC)) | number: '0.2' }}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="(!listMotifBlocage || listMotifBlocage?.length == 0) || showLoader">
                    <tr>
                        <td  colspan="4" *ngIf="(!listMotifBlocage || listMotifBlocage?.length == 0) && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                        <td colspan="4" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button [disabled]="editMotifBlocageForm.invalid" type="submit">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kpvalid" | translate }}
                        </span>                        
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kpback" | translate }}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>