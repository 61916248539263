<div [class.modal-container]="displayType === 'dialog'" class="pt-3" id="stock_autre_article">
    <mat-card [class.no-padding]="isMobile">
        <mat-card-header *ngIf="displayType === 'dialog'">
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>
                            kartothmag
                        </h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row" [class.no-gutters]="isMobile">
                <div class="col-12 no-padding table-scroller">

                    <table id="stock_autre_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover dataTable no-footer">
                        <thead>
                            <tr>
                                <th translate class="text-center lib_court">kartmagtit</th>
                                <th class="text-center" *ngIf="magasinChangeAllowed" translate >kajouter_au_bon</th>
                                <th class="text-center" translate >kartdisp</th>
                                <th class="text-center" translate >kartblock</th>
                                <th translate class="text-center lib_court">kartnoe2</th>
                            </tr>                           
                            <tr *ngIf="lisStock" class="total">
                                <td translate  class="text-center">
                                    karttot
                                </td>
                                <td class="text-center" *ngIf="magasinChangeAllowed"></td>
                                <td class="text-center">
                                    {{ SumStockDispo | cap_decimal }}
                                </td>
                                <td class="text-center">
                                    {{ SumStockBloque | cap_decimal }}
                                </td>
                                 <td class="ean" class="text-center">
                                    {{ SumStockE2 | cap_decimal }}
                                </td>
                            </tr>
                        </thead>
                        <tbody *ngIf="lisStock?.length != 0 && !showLoader">
                            <tr *ngFor="let stock of lisStock">
                                <td class="text-center">
                                    {{ stock.NOM_MAG }}
                                </td>
                                <td class="text-center" *ngIf="magasinChangeAllowed">
                                    <button type="button" mat-button class="choose-mag-btn"
                                            *ngIf="((stock.STOCK_DISPO && stock.STOCK_DISPO > 0) || (stock.E2_BL_LIV_stat == '-1' || stock.E2_BL_stat == '-1' || stock.E2_ED_stat == '-1')) && (stock.CMINT != 501 && stock.CMINT != 500)" (click)="onChooseMagasin(stock)">
                                        <mat-icon>check</mat-icon>
                                    </button>
                                </td>
                                <td class="text-center">
                                    {{ stock.STOCK_DISPO | cap_decimal }}
                                </td>
                                <td class="text-center">
                                    {{ stock.STOCK_BLOQUE | cap_decimal }}
                                </td>
                               <td class="ean" class="text-center">
                                    {{ stock.E2_val | cap_decimal }}
                                </td>
                            </tr>                             
                        </tbody>

                        <tbody *ngIf="lisStock?.length == 0 || showLoader">
                            <tr>
                                <td  [attr.colspan]="magasinChangeAllowed ? 5 : 4" *ngIf="lisStock?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                <td [attr.colspan]="magasinChangeAllowed ? 5 : 4" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                <td style="display: none;" *ngIf="magasinChangeAllowed"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                            </tr>                            
                        </tbody>
                    </table>

                </div>
            </div>

        </mat-card-content>
        <mat-divider *ngIf="displayType === 'dialog'"></mat-divider>

        <mat-card-actions class="text-center" *ngIf="displayType === 'dialog'">
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
</div>
