import { Component, Inject, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import { UserService } from 'providers/api/userService';
import * as moment from 'moment';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {CUSTOM_DATE_FORMATS, CUSTOM_DATETIME_FORMATS} from '../../../providers/custom-date-formats';
import WefoxSlot from '../../../models/wefoxSlot';
import {UserModel} from '../../../models/user-model';
import {DateTimePickerComponent} from '../../../components/date-time-picker/date-time-picker.component';
import {BonVenteService} from '../../../providers/api/bonventeService';
import {BonCommandeService} from '../../../providers/api/bonCommandeService';
import { Client } from 'models/clients';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ]
})
export class DateTimeComponent implements OnInit {

  minAvailDate = moment();
  wefoxForm: UntypedFormGroup  = this.formBuilder.group({
    dtlib3: [{value: moment()}, [Validators.required]],
    strlib3: [''],
    strlib2: [''],
    dtlib4: [{value: '', disabled: true}, Validators.required],
  });
  prevWefoxDate: moment.Moment | undefined;

  user: UserModel;
  USERCMINT: any;
  flowCMINT: any;

  panier: BonPanier;
  currentArticle: PanierArticle;
  client: Client;

  defaultDiffereDays: number;
  paramData: any;

  popupType: 'new' | 'edit' = 'new';

  companiesList: { label: string, value: string }[] = [
    {
      label: 'Agrisano',
      value: 'Agrisano'
    },
    {
      label: 'AMB',
      value: 'AMB'
    },
    {
      label: 'Aquilana',
      value: 'Aquilana'
    },
    {
      label: 'Arcosana',
      value: 'Arcosana'
    },
    {
      label: 'Assura',
      value: 'Assura'
    },
    {
      label: 'Atupri',
      value: 'Atupri'
    },
    {
      label: 'Avenir',
      value: 'Avenir'
    },
    {
      label: 'Cassa da malsauns Lumneziana',
      value: 'Cassa da malsauns Lumneziana'
    },
    {
      label: 'CM Vallée d\'Entremont',
      value: 'CM Vallée d\'Entremont'
    },
    {
      label: 'Compact',
      value: 'Compact'
    },
    {
      label: 'Concordia',
      value: 'Concordia'
    },
    {
      label: 'CSS',
      value: 'CSS'
    },
    {
      label: 'Easy Sana',
      value: 'Easy Sana'
    },
    {
      label: 'EGK-Gesundheitskasse',
      value: 'EGK-Gesundheitskasse'
    },
    {
      label: 'Galenos',
      value: 'Galenos'
    },
    {
      label: 'Glarner Krankenversicherung',
      value: 'Glarner Krankenversicherung'
    },
    {
      label: 'Groupe Mutuel',
      value: 'Groupe Mutuel'
    },
    {
      label: 'Helsana',
      value: 'Helsana'
    },
    {
      label: 'Klug',
      value: 'Klug'
    },
    {
      label: 'KPT',
      value: 'KPT'
    },
    {
      label: 'Krankenkasse Einsiedeln',
      value: 'Krankenkasse Einsiedeln'
    },
    {
      label: 'Krankenkasse Luzerner Hinterland',
      value: 'Krankenkasse Luzerner Hinterland'
    },
    {
      label: 'Krankenkasse Steffisburg',
      value: 'Krankenkasse Steffisburg'
    },
    {
      label: 'Krankenkasse Stoffel',
      value: 'Krankenkasse Stoffel'
    },
    {
      label: 'Krankenkasse Visperterminen',
      value: 'Krankenkasse Visperterminen'
    },
    {
      label: 'Krankenkasse Wädenswil',
      value: 'Krankenkasse Wädenswil'
    },
    {
      label: 'Moove Sympany',
      value: 'Moove Sympany'
    },
    {
      label: 'Mutuel Assurance',
      value: 'Mutuel Assurance'
    },
    {
      label: 'ÖKK',
      value: 'ÖKK'
    },
    {
      label: 'Philos',
      value: 'Philos'
    },
    {
      label: 'Provita',
      value: 'Provita'
    },
    {
      label: 'Rhenusana',
      value: 'Rhenusana'
    },
    {
      label: 'Sana24',
      value: 'Sana24'
    },
    {
      label: 'Sanavals',
      value: 'Sanavals'
    },
    {
      label: 'Sanitas',
      value: 'Sanitas'
    },
    {
      label: 'Slkk',
      value: 'Slkk'
    },
    {
      label: 'Sodalis',
      value: 'Sodalis'
    },
    {
      label: 'Sumiswalder Krankenkasse',
      value: 'Sumiswalder Krankenkasse'
    },
    {
      label: 'Supra',
      value: 'Supra'
    },
    {
      label: 'Swica',
      value: 'Swica'
    },
    {
      label: 'Sympany',
      value: 'Sympany'
    },
    {
      label: 'Visana',
      value: 'Visana'
    },
    {
      label: 'Vita Surselva',
      value: 'Vita Surselva'
    },
    {
      label: 'Vivacare',
      value: 'Vivacare'
    },
    {
      label: this.translateService.instant('kautre'),
      value: 'autre'
    },
  ];

  restrictedDates: string[] = [];
  availableSlots: WefoxSlot[] | undefined;
  availableSlotDays: number[] | undefined;
  currentSlot: WefoxSlot | undefined;
  wefoxMinDelay: string = '';
  wefoxMaxDelay: string = '';

  defaultTime: number[] = [0,0,0];

  flowType: 'bonde_vente' | 'bonde_commande' = 'bonde_vente';

  today = new Date();
  loyalCustomer: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private userService: UserService,
            public dialogRef: MatDialogRef<DateTimeComponent>,
            private dialog: MatDialog,
            private dialogService: DialogsService,
            private loadingService: NgxSpinnerService,
            private translateService: TranslateService,
            private bonVenteService: BonVenteService,
            private bonCommandeService: BonCommandeService,) { }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();

    this.panier = this.data.panier;
    this.currentArticle = this.data.currentArticle;
    this.client = this.data.chosenClient;

    this.USERCMINT = this.user.CMINT;
    this.flowCMINT = (this.data.CMINT !== undefined && this.data.CMINT !== null && this.data.CMINT !== '') ? this.data.CMINT : this.USERCMINT;

    this.flowType = this.data.flowType;

    this.loadDependencies();

    if (this.data.popupType !== undefined && this.data.popupType != null) {
      this.popupType = this.data.popupType;
    }

    if (this.popupType !== undefined && this.popupType !== null) {
      if (this.popupType === 'new') {
        this.wefoxForm.patchValue({
          dtlib3: '',
          strlib3: '',
          strlib2: '',
          dtlib4: (this.client && this.client.TDTNAIS != null && this.client.TDTNAIS !== '0000-00-00') ? new Date(this.client.TDTNAIS) : '',
        })
      } else {
        this.prevWefoxDate = moment(this.currentArticle.dtlib3).utcOffset(this.currentArticle.dtlib3);
        const weFoxYear = this.prevWefoxDate.year();

        let dtlib4 = this.currentArticle.dtlib4;
        if (dtlib4 && moment(dtlib4).year() === 1899) {
          dtlib4 = ''
        }

        this.wefoxForm.patchValue({
          dtlib3: (weFoxYear !== undefined && weFoxYear !== 1899) ? this.prevWefoxDate.format(CUSTOM_DATETIME_FORMATS.display.dateInput) : '',
          strlib3: this.currentArticle.strlib3,
          strlib2: this.currentArticle.strlib2,
          dtlib4,
        })
      }
    }
  }

  async loadDependencies() {
    this.loadingService.show();

    const wefoxSlotResp: any = await this.userService.getWeFoxSlotByMag(this.USERCMINT).toPromise();

    if (wefoxSlotResp && wefoxSlotResp.hasOwnProperty('success')) {
      this.availableSlots = wefoxSlotResp.data;
      this.availableSlotDays = [];
      if (this.availableSlots && this.availableSlots.length > 0) {
        for (let i = 0; i < this.availableSlots.length; i++) {
          const currentSlot = this.availableSlots[i];

          this.availableSlotDays.push(+currentSlot.SLOT_DAY);
        }

        this.availableSlotDays = [...new Set(this.availableSlotDays)];
      }

      const wefoxDate = this.wefoxForm.get('dtlib3').value;

      if (wefoxDate !== null && wefoxDate !== '') {
        const weFoxDate = moment(wefoxDate, CUSTOM_DATETIME_FORMATS.display.dateInput);

        if (this.availableSlots) {
          this.currentSlot = this.availableSlots.find((slotRow) => {
            const slotStartTime = moment(`${weFoxDate.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${slotRow.SLOT_DEB}`, CUSTOM_DATETIME_FORMATS.display.dateInput);
            const slotEndTime = moment(`${weFoxDate.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${slotRow.SLOT_END}`, CUSTOM_DATETIME_FORMATS.display.dateInput);

            return (+slotRow.SLOT_DAY === weFoxDate.day() || (weFoxDate.day() === 0 && +slotRow.SLOT_DAY === 7)) &&
                weFoxDate.isBetween(slotStartTime, slotEndTime, null, '[)');
          });
        }
      }
    }

    const columns = await this.userService.getRuleParamsData(20, 61);

    const restrictableDates = columns['VCHXMUL1LIS'];
    if (restrictableDates !== undefined && restrictableDates !== null && restrictableDates !== '') {
      this.restrictedDates = restrictableDates.trim().split(/\s+/);
    }

    const wefoxMinDelay = columns['VINT1'];
    if (wefoxMinDelay !== undefined && wefoxMinDelay !== null && wefoxMinDelay !== '' && wefoxMinDelay !== '0') {
      this.wefoxMinDelay = wefoxMinDelay;
    }

    const wefoxMaxDelay = columns['VINT2'];
    if (wefoxMaxDelay !== undefined && wefoxMaxDelay !== null && wefoxMaxDelay !== '' && wefoxMaxDelay !== '0') {
      this.wefoxMaxDelay = wefoxMaxDelay;
    }

    this.loadingService.hide();
  }

  __paddNum(val: number): string {
    return val.toString().padStart(2, '0');
  }

  dateFilter = (d: moment.Moment) => {
    const day = d.day();

    const dateFormatted = d.format('YYYY-MM-DD');

    if (this.restrictedDates !== undefined && this.restrictedDates !== null && this.restrictedDates.length > 0 && this.restrictedDates.indexOf(dateFormatted) > -1) {
      return false;
    }

    if (this.availableSlotDays && this.availableSlotDays.length > 0) {
      // day = starting from 0 - 6 (Sunday to Saturday)
      // slotDay = starting from 1 - 7 (Monday to Sunday)
      // Monday -> day=1, slotDay=1
      // Tuesday -> day=2, slotDay=2
      // Wednesday -> day=3, slotDay=3
      // Thursday -> day=4, slotDay=4
      // Friday -> day=5, slotDay=5
      // Saturday -> day=6, slotDay=6
      // Sunday -> day=0, slotDay=7

      return this.availableSlotDays.includes(day !== 0 ? day : 7);
    }

    // Prevent Sunday from being selected.
    return day !== 0;
  }

  onPickerDayChange = (date: moment.Moment, currentSlot: WefoxSlot) => {
    this.currentSlot = currentSlot;

    console.log('DAY CHANGE: ', date, this.currentSlot);
  }

  onPickerTimeChange = (time: string, currentSlot: WefoxSlot | undefined) => {
    if (currentSlot) {
      this.currentSlot = currentSlot;
    }

    console.log('TIME CHANGE: ', time);
  }

  openUpDateTimePickerPopup() {
    const currentDateTime = this.wefoxForm.get('dtlib3').value;

    const dialogRef = this.dialog.open(DateTimePickerComponent,
        {
          maxWidth: '320px',
          minWidth: '280px',
          width: '320px',
          data: {
            flowType: this.flowType,
            CMINT: this.flowCMINT,
            defaultDateTime: currentDateTime ? moment(currentDateTime, CUSTOM_DATETIME_FORMATS.display.dateInput) : undefined,
            prevWefoxDate: this.prevWefoxDate,
            minAllowedDate: moment(),
            dateFilter: this.dateFilter,
            availableSlots: this.availableSlots,
            onDayChange: this.onPickerDayChange,
            onTimeChange: this.onPickerTimeChange,
            wefoxMinDelay: this.wefoxMinDelay,
            wefoxMaxDelay: this.wefoxMaxDelay,
            restrictedDates: this.restrictedDates,
          }
        }
    );

    dialogRef.afterClosed().subscribe((resp: any) => {
      if (resp && resp?.status === 'success') {
        const dateTimePickerResp = resp.data;
        this.wefoxForm.get('dtlib3').setValue(dateTimePickerResp.DATE_TIME_FORMATTED);
      }
    });
  }

  checkIfSlotChanged(latestSlot: string) {
    if (this.popupType === 'edit') {
      return this.prevWefoxDate.format(CUSTOM_DATETIME_FORMATS.display.dateInput) == latestSlot;
    }

    return false;
  }

  async wefoxFormSubmitter() {
    this.loadingService.show();
    const form_data = this.wefoxForm.getRawValue();

    const today = moment().startOf('day');
    const selected_date = moment(form_data.dtlib3).startOf('day');

    // Check WEFOX Slot availability for the selected Date
    // if slot limit reached, display an alert and stop the execution
    if (this.currentSlot && !this.checkIfSlotChanged(form_data.dtlib3)) { // Also bypass the slot check if it is unchanged.
      let slotAvailResp: any;
      if (this.flowType === 'bonde_vente') {
        slotAvailResp = await this.bonVenteService.getWeFOXSlotsDetail({ SLOT_ID: this.currentSlot.IDROW.toString(), CHOSEN_DATE: moment(form_data.dtlib3, CUSTOM_DATETIME_FORMATS.display.dateInput).format('YYYY-MM-DD HH:mm:ss') }).toPromise();
      }

      if (this.flowType === 'bonde_commande') {
        slotAvailResp = await this.bonCommandeService.getWeFOXSlotsDetail({ SLOT_ID: this.currentSlot.IDROW.toString(), CHOSEN_DATE: moment(form_data.dtlib3, CUSTOM_DATETIME_FORMATS.display.dateInput).format('YYYY-MM-DD HH:mm:ss') }).toPromise();
      }

      if (slotAvailResp && slotAvailResp.hasOwnProperty('success')) {
        const slotAvailDetail = slotAvailResp.data;
        const OCCUPIED_SLOTS = slotAvailDetail.occupiedSlots;
        const REMAINING_SLOTS = slotAvailDetail.remainingSlots;
        const TOTAL_SLOTS = slotAvailDetail.totalSlots;

        console.log('AVAIL SLOTS DETAILS: ', slotAvailResp.data);
        if (REMAINING_SLOTS < 1) {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kwefoxslotlimitreached'));
          return;
        }
      }
    }

    // Check WEFOX Slot Start and End time for the selected Date Time
    // if selected time is not between the slot timing, display an alert and stop the execution
    if (this.currentSlot) {
      if (this.currentSlot.SLOT_DEB && this.currentSlot.SLOT_DEB !== '' && this.currentSlot.SLOT_END && this.currentSlot.SLOT_END !== '') {
        const inputDtLib3 = moment(form_data.dtlib3, CUSTOM_DATETIME_FORMATS.display.dateInput);
        const slotStartTime = moment(`${inputDtLib3.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${this.currentSlot.SLOT_DEB}`, CUSTOM_DATETIME_FORMATS.display.dateInput);
        let slotEndTime = moment(`${inputDtLib3.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${this.currentSlot.SLOT_END}`, CUSTOM_DATETIME_FORMATS.display.dateInput);

        // Find maximum available slot time for the currently selected day
        const currentDay = inputDtLib3.day() !== 0 ? inputDtLib3.day() : 7;
        if (this.availableSlots) {
          const currentAvailSlots = this.availableSlots.filter(rw => +rw.SLOT_DAY === currentDay);

          if (currentAvailSlots.length > 0) {
            let maxEndSlot: WefoxSlot | undefined;
            for (let i = 0; i < currentAvailSlots.length; i++) {
              if (maxEndSlot !== undefined) {
                const endSlotTime = moment(`${inputDtLib3.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${currentAvailSlots[i].SLOT_END}`, CUSTOM_DATETIME_FORMATS.display.dateInput);
                const prevMaxEndSlotTime = moment(`${inputDtLib3.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${maxEndSlot.SLOT_END}`, CUSTOM_DATETIME_FORMATS.display.dateInput);

                if (endSlotTime.isAfter(prevMaxEndSlotTime)) {
                  maxEndSlot = currentAvailSlots[i];
                }
              } else {
                maxEndSlot = currentAvailSlots[i];
              }
            }

            if (maxEndSlot) {
              slotEndTime = moment(`${inputDtLib3.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${maxEndSlot.SLOT_END}`, CUSTOM_DATETIME_FORMATS.display.dateInput);
            }
          }
        }

        if (!inputDtLib3.isBetween(slotStartTime, slotEndTime, null, '[)')) {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kwefoxdatelimiterror', {
            START_HOUR: this.__paddNum(slotStartTime.hour()),
            START_MINUTE: this.__paddNum(slotStartTime.minute()),
            END_HOUR: this.__paddNum(slotEndTime.hour()),
            END_MINUTE: this.__paddNum(slotEndTime.minute()),
          }));
          return;
        }
      }
    }

    this.wefoxUpdate();
  }

  wefoxUpdate() {
    const form_data = this.wefoxForm.getRawValue();

    form_data.dtlib3 = (form_data.dtlib3 !== '') ? moment(form_data.dtlib3, CUSTOM_DATETIME_FORMATS.display.dateInput).format('YYYY-MM-DD HH:mm:ss') : moment('1899-12-28').format('YYYY-MM-DD HH:mm:ss');
    
    if(form_data.dtlib4 !== undefined && form_data.dtlib4 !== null  && form_data.dtlib4 !== '') {
      form_data.dtlib4 = moment(form_data.dtlib4).format('YYYY-MM-DD');
    }

    this.dialogRef.close({ status: 'success', data: form_data });
  }

}
