<form name="userForm" class="pt-3">
    <div class="row no-gutters">
        <div class="col-12 col-sm-12 col-md-12">
            <div class="row" [class.no-gutters]="isMobile">
                <div class="col-12 col-lg-3 text-left">
                    <mat-form-field class="mb-3">
                        <input matInput name="code_externe" [ngModel]="code_externe | async"
                               [placeholder]="'kartcodeext' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-6 col-lg-3 text-left pr-2 pr-md-0 css_slide_fournisseur">
                    <mat-form-field>
                        <input matInput [placeholder]="'kartorder' | translate" name="nombre_commande" [value]="(commande_cours?commande_cours:0) | cap_decimal"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-6 col-lg-3 text-left pl-2 pl-md-0 css_slide_fournisseur_check">
                    <mat-form-field>
                        <input matInput [placeholder]="'karte2' | translate" name="nombre_E2" [value]="(E2_nombre?E2_nombre:0) | cap_decimal" disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-3 text-left css_slide_fournisseur_check" >
                    <mat-form-field>
                        <input matInput [placeholder]="'kartentrepot' | translate"
                               name="stock_entreprot_nord_sud" [value]="((listEntreprots && listEntreprots !== null && listEntreprots.length > 0)?+listEntreprots[0].CSKDISPOVTE:0) | cap_decimal" disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" [class.no-gutters]="isMobile">                
                <div class="col-12 col-lg-6 text-left" *ngIf="userPermission !== undefined && userPermission !== null && userPermission['23_1']">
                    <mat-form-field>
                        <input matInput [placeholder]="'kartfour' | translate"
                               name="fournisseur" [value]="(fornisseur_data && fornisseur_data.CNUF && fornisseur_data.CNUF !== null)?fornisseur_data.CNUF+' - '+fornisseur_data.LIBFOURN:''" disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-6 text-left">
                    <mat-form-field>
                        <input matInput [placeholder]="'kartfourlog' | translate"
                               name="fournisseur_logistique" [value]="(fornisseur_data && fornisseur_data.ARTCCNU && fornisseur_data.STKENTLIB)? fornisseur_data.ARTCCNU+' - '+fornisseur_data.STKENTLIB:''" disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" [class.no-gutters]="isMobile">                
                <div class="col-12 col-lg-6 text-left" *ngIf="userPermission !== undefined && userPermission !== null && userPermission['23_1']">
                    <button type="button" mat-raised-button class="bt_stock" [disabled]="!article" (click)="refreshPromesseDate()">                        
                        <div class="text-medium" translate>kdisponibilite</div>
                    </button>
                </div>
                <div class="col-12 col-lg-6 text-left">

                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12">
            <div class="row" [class.no-gutters]="isMobile" *ngIf="displayType !== 'component'">
                <div class="col-12 col-md-6 col-lg-5 text-left margin_top_10">
                    <button type="button" mat-raised-button class="bt_stock" [disabled]="!article" (click)="openStockAutreDialog()">                        
                        <div class="text-medium" translate>kartothmag</div>
                    </button>
                </div>
                <div class="col-12 col-md-6 col-lg-5 offset-lg-1 margin_top_10 text-left">
                    <button type="button"  mat-raised-button class="bt_stock" [disabled]="!article" (click)="openCouchePrixDialog()">                        
                        <div class="text-medium" translate>kartcoprix</div>
                    </button>
                </div>
            </div>
            <div class="row" [class.no-gutters]="isMobile" *ngIf="displayType !== 'component'">
                <div class="col-12 col-md-6 col-lg-5 text-left margin_top_40">
                    <button type="button" mat-raised-button class="bt_stock" [disabled]="!article" (click)="openDetailMagasinDialog()">                        
                        <div class="text-medium" translate>kartretstore</div>
                    </button>
                </div>
                <div class="col-12 col-md-6 col-lg-5 offset-lg-1 margin_top_40 text-left">
                    <button type="button" mat-raised-button class="bt_stock" [disabled]="!article" (click)="openDetailEntrepotDialog()">                        
                        <div class="text-medium" translate>kartretentrepot</div>
                    </button>
                </div>
            </div>
            <div class="row" [class.no-gutters]="isMobile">
                <div class="col-12 col-md-6 col-lg-5 offset-lg-1 margin_top_40 text-left" *ngIf="displayType === 'bonde'">
                    <button type="button" mat-raised-button [disabled]="!article" class="bt_stock" (click)="openDetailStockDialog()">                        
                        <div class="text-medium" translate>kartstockdet</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>