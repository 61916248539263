import {Component, OnInit,Input, Output, EventEmitter} from '@angular/core';
import {Article} from '../../../models/articles';
import {ArticleService} from '../../../providers/api/articleService';

import {ArticleDescriptionPhotoComponent} from './article-description-photo/article-description-photo.component';
import {ArticleDescriptionCodeComponent} from './article-description-code/article-description-code.component';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { UtilisatuersService } from 'providers/api/utilisatuersService';
import { MagasinStructModel } from 'models/magasinStructModel';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'providers/constants';
import { DefaultImage } from 'providers/default-image';
import { UserService } from 'providers/api/userService';
import { StockService } from 'providers/api/stockService';
import { DeviceDetectorService } from 'ngx-device-detector';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-article-description',
    templateUrl: './article-description.component.html',
    providers: [ArticleService,
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
        UtilisatuersService,
        DialogsService,
        TranslateService,
        DefaultImage
    ]
})

export class ArticleDescriptionComponent implements OnInit {

    private _article: Article;
    get article(): Article { return this._article }
    @Input() set article(article: Article) {    
      this.user = this.userService.getCurrentUser();          
      this.USERCMINT = this.user.CMINT;  

      this.articleChange.emit(this._article = article)

      this.CAINT = this.article?this.article.CAINT:undefined;
      if(this.CAINT) {
        this.code_externe = this.getCodeExterne();
        this.getListMagasinStructure();
      }
    }
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();

    @Input() displayType: 'desc' | 'comp' | 'delivery';
    @Input() deliveryOptions: any[] = [];

    article_session: Article;
    CAINT: any;
    CDART: any;
    CSTNIV1: any;
    LIB_CSTNIV1: any;
    LIB_CSTNIV2: any;
    LIB_CSTNIV3: any;
    LIB_CSTNIV4: any;

    firstLevelStructure: MagasinStructModel[];
    secondLevelStructure: MagasinStructModel[];
    thirdLevelStructure: MagasinStructModel[];
    fourthLevelStructure: MagasinStructModel[];

    m50_clicked = false;
    m50_status: boolean;

    image_path: string;
    
    user: any;
    USERCMINT: any;
    code_externe: Promise<any>;

    isMobile = false;    

    constructor(public dialog: MatDialog,
                private articleService: ArticleService,
                private dialogService: DialogsService,
                private translateService: TranslateService,
                private userService: UserService,
                private utilisatuersService: UtilisatuersService,
                private stockService: StockService,
                private deviceService: DeviceDetectorService,
                private storageService: StorageService) {
    }

    ngOnInit() {
      this.isMobile = this.deviceService.isMobile();

      this.user = this.userService.getCurrentUser();          
      this.USERCMINT = this.user.CMINT;

      this.article_session = this.storageService.getItem('article'); 
      
      this.loadDependencies();
    }    

    async loadDependencies() {
      try {
        const paramData = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
        if(paramData && paramData !== '' && paramData === 'ALINEA') {
          this.m50_status = true;
        }
      } catch(err) {
        this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
      }
      
    }
    
    getCodeExterne(): Promise<any> {
      return new Promise((resolve, reject) => {
          this.articleService.getCodeExterne(this.CAINT, this.USERCMINT).subscribe(
              (res: any) => {
                  if(res.success !== undefined) {
                      resolve(res.data[0].CDEXTERNE);                        
                  }              
              }, 
              err => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                reject(err);
              }
          );
      });        
    }

    async getListMagasinStructure() {
        return new Promise<void>((resolve, reject) => {
          let httpData = {
            IDMAG: this.USERCMINT,
            IDSOC: Constants.IDSOC,
            CIINT: this.user.CIINT
          };

          this.utilisatuersService.getMagasinStructure(httpData).subscribe(
            (res: any) => {
              if(res.success !== undefined) {
                const magasinStructure = res.data;
                
                magasinStructure.forEach(
                  (res: MagasinStructModel) => {

                    if(this.article) {
                      if(res.CSTNIV1 == this.article.CSTNIV1 && res.CSTNIV2 == '0') {
                        this.LIB_CSTNIV1 = res.CSTLIB1
                      }
  
                      if(res.CSTNIV1 == this.article.CSTNIV1 && res.CSTNIV2 == this.article.CSTNIV2 && res.CSTNIV3 == '0') {
                        this.LIB_CSTNIV2 = res.CSTLIB1
                      }
  
                      if(res.CSTNIV1 == this.article.CSTNIV1 && res.CSTNIV2 == this.article.CSTNIV2 && res.CSTNIV3 == this.article.CSTNIV3 && res.CSTNIV4 == '0') {
                        this.LIB_CSTNIV3 = res.CSTLIB1
                      }
  
                      if(res.CSTNIV1 == this.article.CSTNIV1 && res.CSTNIV2 == this.article.CSTNIV2 && res.CSTNIV3 == this.article.CSTNIV3 && res.CSTNIV4 == this.article.CSTNIV4 && res.CSTNIV5 == '0') {
                        this.LIB_CSTNIV4 = res.CSTLIB1
                      }
                    }

                  }
                );              

                resolve();            
              }
            },
            err => {
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
              reject()
            }
          );
        });    
    }

    public openCodeArticleDialog(): void {        
        const dialogRef = this.dialog.open(ArticleDescriptionCodeComponent,
            {data: {CAINT: this.CAINT, CDART: this.article.CDART}}
        );
    }

    onClickM50() {
      this.m50_clicked = !this.m50_clicked;
    }

    getStockDispo() {
      this.stockService.getStockDispo(this.CAINT, this.USERCMINT).subscribe(
          (data) => {
              if(data) {                 
                 this.article.STOCKDISPO = parseFloat(data.STOCK_DISPO);
              }                 
          },
          (error) => {
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
          }
      );
    }
}
