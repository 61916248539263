import { Component, Input } from '@angular/core';

@Component({
  selector: 'side-nav-group',
  template:
    `<div class="side-nav-group">
        <div *ngIf="title" class="side-nav-group-title">
          <mat-icon *ngIf="icon">{{icon}}</mat-icon>
          <span style="padding: 0 10px;">{{ title }}</span>
        </div>
        <ng-content></ng-content>
    </div>`,
  styles: [`
    .side-nav-group{
      border-width:0px 0px 0px 0px;border-style:solid;border-color:#e0e0e0
    }
    .side-nav-group-title{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: .5em .4em .5em 1em;
      background:rgba(0,0,0,.32);color:hsla(0,0%,100%,.87);width:100%
    }
  `]
})
export class SideNavGroupComponent {
  @Input('title') title: string;
  @Input('icon') icon: string | null;

}
