<mat-card>    
    <mat-card-content class="mat-card-content-form">
        <div class="row"  [class.section-disabled]="!readOnly && sectionDisabled">
            <div class="col-12 col-md-12 no-padding"  >
                <form #fideliteForm="ngForm" [formGroup]="searchForm" (ngSubmit)="searchFideliteFormSubmitter()">                    
                    <div class="row" *ngIf="!readOnly">
                        <div class="col-12 col-md-4 col-lg-4" *ngIf="flowType != 'bonde_commande' && flowType !== 'retrait_marchandise'">
                            <mat-form-field>
                                <input matInput inputmode="none" #fideliteSearchInput formControlName="NUMFID"
                                       [placeholder]="'kbonenterfid' | translate"
                                       class="text-uppercase">
                                <button matSuffix type="submit" mat-icon-button [disabled]="sectionDisabled">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button matSuffix type="button" *ngIf="isMobile" class="ml-2" mat-icon-button (click)="openUpFideliteScan($event)" [disabled]="sectionDisabled">
                                    <i class="fa fa-barcode" aria-hidden="true"></i>
                                </button>
                                <button matSuffix type="button" *ngIf="isMobile" class="ml-2" mat-icon-button (click)="removeFidelite()" [disabled]="sectionDisabled">
                                    <mat-icon>close</mat-icon>
                                </button>    
                            </mat-form-field>
                        </div> 
                        <div class="col-12 col-md-4 col-lg-2 text-left" *ngIf="!isMobile">
                            <button type="button" class="m-0 text-left" (click)="removeFidelite()" *ngIf="flowType != 'bonde_commande' && flowType !== 'retrait_marchandise'" [disabled]="sectionDisabled" mat-button>
                                <i class="material-icons">close</i>
                                {{'kbonsupprimer' | translate}}
                            </button>                            
                        </div>
                        <div class="col-12 col-md-4 col-lg-6 text-left">
                            <div class="align-items-center align-items-md-end d-flex h-100 justify-content-end justify-content-md-end p-md-0 py-2">
                                <span class="badge badge-info fidelite-badge" *ngIf="selectedFidlite && selectedFidlite?.CTCLIB">{{ selectedFidlite?.CTCLIB }}</span>
                                <span class="badge badge-success fidelite-badge ml-2" *ngIf="selectedFidlite && selectedFidlite?.TYPEFIDLIB">{{ selectedFidlite?.TYPEFIDLIB }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 col-lg-6">
                            <h4 class="text-left">
                                {{'kbonnumfid' | translate}}
                                <span class="detail-holder d-inline-block w-75">{{selectedFidlite?selectedFidlite.NUMFID:''}}</span>
                            </h4>
                        </div>                                              
                    </div>
                </form>

                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-md-6">
                        <h4 class="text-left">
                            {{'kboncompnomprenom' | translate}}
                            <span class="detail-holder">{{selectedFidlite?((selectedFidlite.CIVLIBC != null?selectedFidlite.CIVLIBC+' ':'')+selectedFidlite.TRAISOC+(selectedFidlite.TPRENOM?' '+selectedFidlite.TPRENOM:'')):''}}</span>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kbonaddress' | translate}} <br>
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TADR1?selectedFidlite.TADR1:''):''}}</span>
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TADR2?selectedFidlite.TADR2:''):''}}</span>
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TADR3?selectedFidlite.TADR3:''):''}}</span>
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TADR4?selectedFidlite.TADR4:''):''}}</span>
        
                            <div class="d-flex justify-content-between">
                                <span class="detail-holder w-25">{{selectedFidlite?(selectedFidlite.CODPOS?selectedFidlite.CODPOS:''):''}}</span> 
                                <span class="detail-holder ml-1" style="flex: 1;">{{selectedFidlite?(selectedFidlite.TVILLE?selectedFidlite.TVILLE:''):''}}</span>
                            </div>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kboncountry' | translate}}
                            <span class="detail-holder"> {{selectedFidlite?(selectedFidlite.PAYSNOM?selectedFidlite.PAYSNOM:''):''}}</span>
                        </h4>
                    </div>
                    <div class="col-md-6">
                        <h4 class="text-left">
                            {{'kbonemail' | translate}}
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TEMAIL?selectedFidlite.TEMAIL:''):''}}</span>
                        </h4>

                        <h4 class="text-left">
                            {{'kclport' | translate}}
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TPORT?(selectedFidlite.TPORT | formatTele):''):''}}</span>
                        </h4>

                        <h4 class="text-left">
                            {{'kbontel' | translate}}
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TTEL?(selectedFidlite.TTEL | formatTele):''):''}}</span>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kbonfax' | translate}}
                            <span class="detail-holder">{{selectedFidlite?(selectedFidlite.TFAX?selectedFidlite.TFAX:''):''}}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">                
                
            </div>            
        </div>
    </mat-card-content>
</mat-card>
