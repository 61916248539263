import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Constants } from '../constants';

import { DeviceDetectorService } from 'ngx-device-detector';
import { PanierArticle } from 'models/bonPanier';

@Injectable()
export class BonCommandeService {

    isMobile: boolean = false;

    constructor(private http: HttpClient,
        private deviceService: DeviceDetectorService) {
        this.isMobile = this.deviceService.isMobile();
    }

    getWeFOXSlotsDetail(payload: { SLOT_ID: string, CHOSEN_DATE: string }) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: payload
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getOccupiedBonCdeBySlotId', options);
    }

    getWeFOXSlotsWithAvailability(payload: { IDMAG: any, CHOSEN_DATE?: string }) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: payload
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getBonCdeSlotsWithAvailability', options);
    }

    getBonCommande(): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getwBondeCommande', options);
    }

    getLivraisonLienList(data): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {
            ...data,
            IDSOC: Constants.IDSOC
        };

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getArticleLienListByBon', options);
    }

    sendCdePaymentLink(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'sendPaymentLink', data);
    }

    searchBonDeCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'searchwBondeCommande', data);
    }

    searchHistoriqueCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'searchhistoriesBondeCommande', data);
    }

    searchCommandeSupprimes(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'searchCommandeSupprimes', data);
    }

    modifyBonCommande(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_COMMANDE_URL + 'editwBondeCommande', data);
    }

    modifyLigneBonCommande(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_COMMANDE_URL + 'editLigneBondeCommande', data);
    }

    modifyBonCommandeFull(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_COMMANDE_URL + 'editwBondeCommandeFull', data);
    }

    createClientFromBonDeCommande(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers
        };

        return this.http.post(Constants.API_COMMANDE_URL + 'editwBonCommandeCreateClient', data, options);
    }

    modifyClientFromBonDeCommande(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers
        };

        return this.http.post(Constants.API_COMMANDE_URL + 'editwBonCommandeModifyClient', data, options);
    }

    modifyTypeBondeCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'modifyTypeBondeCommande', data);
    }
    
    newOptionInsertion(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_COMMANDE_URL + 'addLigneBondeCommand', data);
    }

    modifyLivraisonBonCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'updateLivraison', data);
    }

    modifyDateRelance(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: data
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'updateRelanceDate', options);
    }

    removeBonCommande(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'deleteBondeCommand', options);
    }

    removeBonCommandeLineItem(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'deleteLigneBondeCommandByID', options);
    }

    solderBonCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'solderBonDeCommande', data);
    }

    deSolderBonCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'deSolderBonDeCommande', data);
    }

    nonConformeBonCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'nonConformeBonDeCommande', data);
    }

    conformeBonCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'conformeBonDeCommande', data);
    }

    splitterLaBonCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'splitterLaBonDeCommande', data);
    }

    reactiverBonCommande(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'reactivateBondeCommande', options);
    }

    reactiverBonCommandeHistory(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'reactivateBondeCommandehistory', options);
    }

    getArticleByCDART(CDART: any, IDMAG: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['CDART'] = CDART;
        params['IDMAG'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getArticleByCdart', options);
    }

    getRemiseList(type: number, IDMAG: number, CIINT: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['REMMTYPE'] = type;
        params['IDMAG'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;
        params['CIINT'] = CIINT;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'remiseList', options);
    }

    getBonVenteHistorique(NUMBON: string, IDMAG: number, page?: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        if(page !== undefined) {
            params['page'] = page;
        }

        params['NUMCOM'] = NUMBON;
        params['CMINT'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'bondeventeHistoire', options);
    }

    getListTransporteur(TRALIBL: string, TRASTR1: number, page?: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        if(TRALIBL !== undefined && TRALIBL !== null) {
            params['TRALIBL'] = TRALIBL;
        } else {
            params['TRALIBL'] = '';
        }

        if(TRASTR1 !== undefined && TRASTR1 !== null) {
            params['TRASTR1'] = TRASTR1;
        } else {
            params['TRASTR1'] = '';
        }

        params['page'] = page === undefined?1:page;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'searchTransporteur', options);
    }

    getBonDeCommandeByNumBon(NumBon: any, IDMAG: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['pNumBon'] = NumBon;
        params['pMag'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getwBondeCommande', options);
    }

    validateRemiseEnE2(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: data
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getremisebondeCommande', options);
    }

    changeCommandeStore(data: any): Observable<any> {
        // CAP-1249: Change prixforce to true in changeCommandeStore function
        data.PANIER.articles.map((item: PanierArticle) => {
            item.prixforce = true;
            item.excluoffre = true;
            
            if(item.remlig || item.remtot) {
                item.prix = item.prix - ((item.remlig + item.remtot)/item.quantite);
            }
            return item;
        })

        return this.http.post(Constants.API_COMMANDE_URL + 'changeCommandeStore', data);
    }

    getCdeTicketTotalAmount(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: data
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getTotalTicketMontant', options);
    }

    getClientByFidelite(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = {};
        params = data;
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_CLIENT_URL + 'getCagnotte', options);
    }

    getArticles(data: any, currentPage: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['NUMCOM'] = data;

        params['IDSOC'] = Constants.IDSOC;
        params['currentPage'] = currentPage;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getArticles', options);
    }

    getArticleById(data: any, currentPage: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['CAINT'] = data;

        params['IDSOC'] = Constants.IDSOC;
        params['currentPage'] = currentPage;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getArticleById', options);
    }

    getBonComandeTickets(NUMBON: string, IDMAG: number, TYPE: string = '', page?: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        if(page !== undefined) {
            params['page'] = page;
        }

        if(TYPE !== undefined) {
            params['TYPE'] = TYPE;
        }

        params['NUMCOM'] = NUMBON;
        params['CMINT'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'gettickets', options);
    }

    getBonComandeTicketsStatus(NUMBON: string, IDMAG: number, TYPE: string = '', USER_ID: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['NUMCOM'] = NUMBON;
        params['CMINT'] = IDMAG;
        params['CIINT'] = USER_ID;
        params['IDSOC'] = Constants.IDSOC;

        if(TYPE !== undefined) {
            params['TYPE'] = TYPE;
        }

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getTicketStatus', options);
    }

    getAlerteClient(NUMBON: string, IDMAG: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['NUMCOM'] = NUMBON;
        params['CMINT'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'alertClient', options);
    }

    imprimerBonCommande(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'imprimerBonDeCommande', options);
    }

    imprimerRelance(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'imprimerRelance', options);
    }

    downloadFile(downloadUrl: string): Observable<any> {
		return this.http.get(downloadUrl, {responseType: 'blob'});
    }

    sendClientSMS(NUMBON: string, IDMAG: number, TYPE: string, Id_User?: any, Id_InstUser?: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['pNumBon'] = NUMBON;
        params['pMag'] = IDMAG;
        params['pType'] = TYPE;
        params['IDSOC'] = Constants.IDSOC;

        params['Id_User'] = Id_User;
        params['Id_InstUser'] = Id_InstUser;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'sendSMS', options);
    }

    getCanalList(IDMAG: number, CIINT: string): Observable<any> {

        const headers = new HttpHeaders(); 
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['IDMAG'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;
        params['CIINT'] = CIINT;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'canalList', options);
    }

    getBonCommandeDetail(numBon:any, pMag:any, page:any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        let endPoint = 'getwBondeCommande';

        if(page == 'commande'){           
            endPoint = 'getwBondeCommande';
        } else if (page == 'historique'){
            endPoint = 'gethistoriesBondeCommande';
        } else if (page == 'supprimees'){            
            endPoint = 'getCommandeSupprimes';
        } else if (page == 'gestion-des-e2') {
            endPoint = 'getwBondeCommande';
        }
        params['pNumBon'] = numBon; 
        params['pMag'] = pMag;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };
      
        return this.http.get(Constants.API_COMMANDE_URL + endPoint, options);
    }

    checkBondeCommandeStatus(numBon:any, pMag:any): Observable<any> {
        let endPoint = 'checkbondecommandeStatus';

        const data = {
            CMINT: pMag,
            NUMBON: numBon,
            IDSOC: Constants.IDSOC,
        };
      
        return this.http.post(Constants.API_COMMANDE_URL + endPoint, data);
    }

    checkServiceArticleIsDelivery(cdart:any): Observable<any> {
        let endPoint = 'checkServiceArticleIsDelivery';

        const data = {
            CDART: cdart,
            IDSOC: Constants.IDSOC,
        };
      
        return this.http.post(Constants.API_COMMANDE_URL + endPoint, data);
    }

    livrerBonDeCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'livrerBonDeCommande', data);
    }

    annulerLivraisonBonDeCommande(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'annulerLivraisonBonDeCommande', data);
    }
}
