import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {CUSTOM_DATE_FORMATS, CUSTOM_DATETIME_FORMATS} from '../../providers/custom-date-formats';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogsService} from '../../providers/api/dialogService';
import {TranslateService} from '@ngx-translate/core';
import WefoxSlot from '../../models/wefoxSlot';
import {MatLegacySelectChange as MatSelectChange} from '@angular/material/legacy-select';
import {BonVenteService} from '../../providers/api/bonventeService';
import {BonCommandeService} from '../../providers/api/bonCommandeService';
import {NgxSpinnerService} from 'ngx-spinner';
import WefoxSlotAvailability from '../../models/wefoxSlot';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ]
})
export class DateTimePickerComponent implements OnInit {

  selected: moment.Moment | null;
  defaultTime = '00:00';

  dateTimeForm: UntypedFormGroup = this.formBuilder.group({
    PICK_DATE: ['', Validators.required],
    PICK_TIME: ['', Validators.required],
    PICK_TIME_SLOT: [{ value: '', disabled: true }, Validators.required],
    DATE_TIME_FORMATTED: ['', Validators.required],
  });

  // Props
  CMINT: any;
  flowType: 'bonde_vente' | 'bonde_commande' = 'bonde_vente';
  defaultDateTime: moment.Moment | undefined;
  prevWefoxDate: moment.Moment | undefined;
  prevWefoxSlot: WefoxSlot | undefined;
  minAvailDate = moment();
  maxAvailDate;
  availableSlots: WefoxSlot[] | undefined;
  listDaySlots: WefoxSlotAvailability[] = [];
  listAllSlots: WefoxSlotAvailability[] = [];
  dateFilter: ((date: moment.Moment) => boolean) | undefined;
  _onDayChange: ((date: moment.Moment, currentSlot: WefoxSlot) => void) | undefined;
  _onTimeChange: ((time: string, currentSlot: WefoxSlot | undefined) => void) | undefined;
  restrictedDates: string[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<DateTimePickerComponent>,
              private dialogService: DialogsService,
              private bonVenteService: BonVenteService,
              private bonCommandeService: BonCommandeService,
              private translateService: TranslateService,
              private loadingService: NgxSpinnerService,
              ) {
  }

  ngOnInit(): void {
    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();
    this.listAllSlots = [];

    if (this.data !== undefined && this.data !== null) {
      if (this.data.hasOwnProperty('CMINT')) {
        this.CMINT = this.data.CMINT;
      }

      if (this.data.hasOwnProperty('flowType')) {
        this.flowType = this.data.flowType;
      }

      if (this.data.hasOwnProperty('restrictedDates')) {
        this.restrictedDates = this.data.restrictedDates;
      }

      if (this.data.hasOwnProperty('minAllowedDate')) {
        this.minAvailDate = this.data.minAllowedDate;

        if(this.data.hasOwnProperty('wefoxMinDelay') && this.data.wefoxMinDelay !== undefined && this.data.wefoxMinDelay !== null && this.data.wefoxMinDelay !== '' && this.data.wefoxMinDelay !== '0') {
          const startDate = moment();
          this.minAvailDate = this.addBusinessDays(startDate, this.data.wefoxMinDelay);
        }
      }

      if(this.data.hasOwnProperty('wefoxMaxDelay') && this.data.wefoxMaxDelay !== undefined && this.data.wefoxMaxDelay !== null && this.data.wefoxMaxDelay !== '' && this.data.wefoxMaxDelay !== '0') {
        this.maxAvailDate = moment().add(this.data.wefoxMaxDelay, 'days');
      }

      if (this.data.hasOwnProperty('availableSlots')) {
        this.availableSlots = this.data.availableSlots;
      }

      if (this.flowType === 'bonde_vente') {
        this.listAllSlots = await this.getVenteSlotsWithAvailabilityByDay(this.CMINT);
      }

      if (this.flowType === 'bonde_commande') {
        this.listAllSlots = await this.getCdeSlotsWithAvailabilityByDay(this.CMINT);
      }

      if (this.data.hasOwnProperty('prevWefoxDate')) {
        this.prevWefoxDate = this.data.prevWefoxDate;

        if (this.prevWefoxDate !== undefined && this.prevWefoxDate !== null) {
          this.prevWefoxDate = moment(moment(this.prevWefoxDate).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');

          this.prevWefoxSlot = this.availableSlots.find((rw) => {
            const currentDay = this.prevWefoxDate.day() !== 0 ? this.prevWefoxDate.day() : 7;
            const slotStartTime = moment(`${this.prevWefoxDate.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${rw.SLOT_DEB}`, CUSTOM_DATETIME_FORMATS.display.dateInput);
            const slotEndTime = moment(`${this.prevWefoxDate.format(CUSTOM_DATE_FORMATS.display.dateInput)} ${rw.SLOT_END}`, CUSTOM_DATETIME_FORMATS.display.dateInput);

            if (currentDay === +rw.SLOT_DAY && this.prevWefoxDate.isSameOrAfter(slotStartTime) && this.prevWefoxDate.isSameOrBefore(slotEndTime)) {
              return true;
            }

            return false;
          });
        }
      }

      if (this.data.hasOwnProperty('defaultDateTime')) {
        this.defaultDateTime = this.data.defaultDateTime;
        if (this.defaultDateTime) {
          this.fillDateAndTime(this.defaultDateTime, this.defaultDateTime.format('HH:mm'));
        } else {
          // To get the next available selectable date

          // Restrict Sunday by default
          const restrictedDays = [0];

          // Filter out the available days from Sunday to Saturday
          const availDays = [0, 1, 2, 3, 4, 5, 6]
              .filter(rw => restrictedDays.indexOf(rw) === -1)
              .filter((rw) => this.availableSlots.map(_r => _r.SLOT_DAY).indexOf(rw) > -1);

          const todayDate = moment().startOf('day');

          let nextAvailDate;
          for (let i = 0; i < availDays.length; i++) {
            const rowDay = availDays[i];
            const rowDate = moment().startOf('day').day(rowDay);

            // Weekday date (rowDate) should be greater than or equal to Today date
            if (todayDate.isSameOrBefore(rowDate)) {
              nextAvailDate = rowDate;
              break;
            }
          }

          // If no days available in the current week
          if (nextAvailDate === undefined) {
            // Setting today date as fallback date
            nextAvailDate = todayDate;
            if (availDays.length > 0) {
              // Setting the first available date from next week.
              nextAvailDate = moment().startOf('day').day(availDays[0] + 7);
            }
          }

          this.defaultDateTime = nextAvailDate;

          this.fillDateAndTime(this.defaultDateTime, undefined);
        }
      }

      if (this.data.hasOwnProperty('dateFilter')) {
        this.dateFilter = (d: moment.Moment) => {
          const day = d.day();

          if (this.listAllSlots !== undefined && this.listAllSlots !== null && this.listAllSlots.length > 0) {
            const filteredSlots = [...this.listAllSlots].filter(rw => +rw.SLOT_DAY === (day !== 0 ? day : 7));
            const isAllSlotOccupied = filteredSlots.every(rw => (rw.occupiedDate === null || rw.occupiedDate === '' || rw.occupiedDate === d.format('YYYY-MM-DD')) && rw.remainingSlots < 1);

            if (isAllSlotOccupied) {
              return false;
            }
          }

          return this.data.dateFilter(d);
        };
      }

      if (this.data.hasOwnProperty('onDayChange')) {
        this._onDayChange = this.data.onDayChange;
      }

      if (this.data.hasOwnProperty('onTimeChange')) {
        this._onTimeChange = this.data.onTimeChange;
      }
    }

    this.loadingService.hide();
  }

  addBusinessDays(startDate, numDaysToAdd) {
    const restrictedDates = this.restrictedDates !== undefined && this.restrictedDates !== null && this.restrictedDates;
    while (numDaysToAdd > 0) {
      startDate.add(1, 'days');
      if (startDate.day() !== 0 && startDate.day() !== 6 && !restrictedDates.includes(startDate.format('YYYY-MM-DD'))) {
        numDaysToAdd--;
      }
    }
    return startDate;
  }

  formattedTime(timeString: string): string {
    return moment(timeString, 'HH:mm:ss').format('HH:mm');
  }

  async fillDateAndTime(date: moment.Moment, time?: string) {
    let defaultDate: moment.Moment;
    if(this.minAvailDate.isBefore(date)) {
      this.selected = date;
      defaultDate = date;
    } else {
      this.selected = this.minAvailDate;
      defaultDate = this.minAvailDate;
    }

    this.defaultTime = time;

    let currentSlot;

    this.listDaySlots = [...this.availableSlots].filter(rw => {
      const currentDay = defaultDate.day() !== 0 ? defaultDate.day() : 7;
      return currentDay === +rw.SLOT_DAY;
    }).map(rw => {
      const availabilitySlot = this.listAllSlots.find(_rw => +_rw.IDROW === +rw.IDROW && _rw.occupiedDate === defaultDate.format('YYYY-MM-DD'));

      let availabilityDetail = {
            occupiedSlots: 0,
            remainingSlots: +rw.MAX_QTY,
            totalSlots: +rw.MAX_QTY,
            occupiedDate: null
          };
      if (availabilitySlot !== undefined && availabilitySlot !== null) {
        availabilityDetail = {
          occupiedSlots: availabilitySlot.occupiedSlots,
          remainingSlots: availabilitySlot.remainingSlots,
          totalSlots: availabilitySlot.totalSlots,
          occupiedDate: availabilitySlot.occupiedDate
        };
      }

      return {
        ...rw,
        ...availabilityDetail
      };
    });

    if (this.listDaySlots.length > 0 && this.prevWefoxSlot) {
      this.listDaySlots = this.listDaySlots.map(rw => {
        if (+rw.IDROW === +this.prevWefoxSlot.IDROW) {
          rw.remainingSlots = +rw.remainingSlots + 1;
          rw.occupiedSlots = +rw.occupiedSlots - 1;
        }

        return rw;
      })
    }

    if (time === undefined || time === null) {
      // Find minimum available slot time for the next available day
      const currentDay = defaultDate.day() !== 0 ? defaultDate.day() : 7;
      if (this.listDaySlots.length > 0) {
        let minStartSlot: WefoxSlot | undefined;
        for (let i = 0; i < this.listDaySlots.length; i++) {
          if (this.listDaySlots[i].remainingSlots > 0 && (!minStartSlot || moment(this.listDaySlots[i].SLOT_DEB).isBefore(minStartSlot.SLOT_DEB))) {
            minStartSlot = this.listDaySlots[i];
          }
        }

        if (minStartSlot) {
          this.defaultTime = moment(minStartSlot.SLOT_DEB, 'HH:mm:ss').format('HH:mm');
          currentSlot = minStartSlot;
        }

        const isAllSlotOccupied = this.listDaySlots.every(rw => rw.remainingSlots < 1);
        if (isAllSlotOccupied) {
          this.dateTimeForm.get('PICK_TIME_SLOT').setValue('');
          this.dateTimeForm.get('PICK_TIME_SLOT').disable({ onlySelf: true });
        } else {
          this.dateTimeForm.get('PICK_TIME_SLOT').enable({ onlySelf: true });
        }
      }
    } else {
      this.dateTimeForm.get('PICK_TIME_SLOT').enable({ onlySelf: true });
      currentSlot = this.listDaySlots.find(rw => moment(rw.SLOT_DEB, 'HH:mm:ss').format('HH:mm') === time);
    }

    this.loadingService.hide();

    const pickDate = defaultDate ? defaultDate.format('YYYY-MM-DD') : '';

    this.dateTimeForm.patchValue({
      PICK_DATE: pickDate,
      PICK_TIME: this.defaultTime,
      PICK_TIME_SLOT: currentSlot ? currentSlot.IDROW : '',
    });

    if (this.restrictedDates !== undefined && this.restrictedDates !== null && this.restrictedDates.length > 0 && this.restrictedDates.indexOf(pickDate) > -1) {
      this.dateTimeForm.get('PICK_DATE').setValue('');
    }

    this.updateDateTimeCombined();
  }

  async onDateChange(date: moment.Moment) {
    const dateFormatted = date ? date.format('YYYY-MM-DD') : '';
    this.dateTimeForm.get('PICK_DATE').setValue(dateFormatted);

    this.listDaySlots = [...this.availableSlots].filter(rw => {
      const currentDay = date.day() !== 0 ? date.day() : 7;
      return currentDay === +rw.SLOT_DAY;
    }).map(rw => {
      const availabilitySlot = this.listAllSlots.find(_rw => +_rw.IDROW === +rw.IDROW && _rw.occupiedDate === date.format('YYYY-MM-DD'));

      let availabilityDetail = {
        occupiedSlots: 0,
        remainingSlots: +rw.MAX_QTY,
        totalSlots: +rw.MAX_QTY,
        occupiedDate: null
      };
      if (availabilitySlot !== undefined && availabilitySlot !== null) {
        availabilityDetail = {
          occupiedSlots: availabilitySlot.occupiedSlots,
          remainingSlots: availabilitySlot.remainingSlots,
          totalSlots: availabilitySlot.totalSlots,
          occupiedDate: availabilitySlot.occupiedDate
        };
      }

      return {
        ...rw,
        ...availabilityDetail
      };
    });

    if (this.listDaySlots.length > 0 && this.prevWefoxSlot) {
      this.listDaySlots = this.listDaySlots.map(rw => {
        if (+rw.IDROW === +this.prevWefoxSlot.IDROW) {
          rw.remainingSlots = +rw.remainingSlots + 1;
          rw.occupiedSlots = +rw.occupiedSlots - 1;
        }

        return rw;
      })
    }

    let currentSlot;
    if (this.listDaySlots.length > 0) {
      let minStartSlot: WefoxSlot | undefined;
      for (let i = 0; i < this.listDaySlots.length; i++) {
        if (this.listDaySlots[i].remainingSlots > 0 && (!minStartSlot || moment(this.listDaySlots[i].SLOT_DEB).isBefore(minStartSlot.SLOT_DEB))) {
          minStartSlot = this.listDaySlots[i];
        }
      }

      if (minStartSlot) {
        currentSlot = minStartSlot;
        this.dateTimeForm.get('PICK_TIME_SLOT').setValue(currentSlot.IDROW);
        this.defaultTime = moment(currentSlot.SLOT_DEB, 'HH:mm:ss').format('HH:mm');
        this.dateTimeForm.get('PICK_TIME').setValue(this.defaultTime);
      }

      const isAllSlotOccupied = this.listDaySlots.every(rw => rw.remainingSlots < 1);
      if (isAllSlotOccupied) {
        this.dateTimeForm.get('PICK_TIME_SLOT').disable({ onlySelf: true });
      } else {
        this.dateTimeForm.get('PICK_TIME_SLOT').enable({ onlySelf: true });
      }
    }

    if (this._onDayChange !== undefined) {
      this._onDayChange(date, currentSlot);
    }

    this.updateDateTimeCombined();
  }

  async getVenteSlotsWithAvailabilityByDay(CMINT: any, date?: string) {
    return new Promise<any>((resolve, reject) => {
      this.bonVenteService.getWeFOXSlotsWithAvailability({
        CHOSEN_DATE: date || '',
        IDMAG: CMINT
      }).subscribe((resp: any) => {
        if (resp.success === 'ok') {
          resolve(resp.data);
        }
      }, (error) => {
        reject(error);
      })
    });
  }

  async getCdeSlotsWithAvailabilityByDay(CMINT: any, date?: string) {
    return new Promise<any>((resolve, reject) => {
      this.bonCommandeService.getWeFOXSlotsWithAvailability({
        CHOSEN_DATE: date || '',
        IDMAG: CMINT
      }).subscribe((resp: any) => {
        if (resp.success === 'ok') {
          resolve(resp.data);
        }
      }, (error) => {
        reject(error);
      })
    });
  }

  onTimeChange(event: MatSelectChange) {
    const slotId = event.value;

    if (slotId) {
      const currentSlot = this.availableSlots.find(rw => +rw.IDROW === +slotId);
      const time = moment(currentSlot.SLOT_DEB, 'HH:mm:ss').format('HH:mm');

      this.dateTimeForm.get('PICK_TIME').setValue(time);

      if (this._onTimeChange !== undefined) {
        this._onTimeChange(time, currentSlot);
      }

      this.updateDateTimeCombined();
    }
  }

  updateDateTimeCombined() {
    const date = this.dateTimeForm.get('PICK_DATE').value;
    const time = this.dateTimeForm.get('PICK_TIME').value;

    let combinedDateTime = '';
    if (date !== null && date !== '' && time !== null && time !== '') {
      combinedDateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format(CUSTOM_DATETIME_FORMATS.display.dateInput);
    } else if (date !== null && date !== '') {
      combinedDateTime = moment(`${date}`, 'YYYY-MM-DD').startOf('day').format(CUSTOM_DATETIME_FORMATS.display.dateInput);
    } else if (time !== null && time !== '') {
      combinedDateTime = moment(`${time}`, 'HH:mm').format(CUSTOM_DATETIME_FORMATS.display.dateInput);
    }

    this.dateTimeForm.get('DATE_TIME_FORMATTED').setValue(combinedDateTime);
  }

  onDateTimeSubmitter() {
    if (this.dateTimeForm.invalid) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
      return;
    }

    const formData = this.dateTimeForm.getRawValue();
    this.dialogRef.close({ status: 'success', data: formData });
  }

}
