
<div class="modal-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-12 text-center">
                        <h1 class="modal_title">
                           {{data.title}}
                        </h1>

                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row ">
                <div class="col-12 col-sm-12 text-center uc_first">
                    <h1 [innerHtml]="data.message"></h1>
                </div>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button [mat-dialog-close] class="bt_send" cdkFocusInitial>
                <mat-icon>close</mat-icon>
                <span class="d-none d-sm-none d-md-block">
                    {{ buttonName }}
                </span>                
            </button>
        </mat-card-actions>

    </mat-card>
</div>