<mat-card>    
    <mat-card-content class="mat-card-content-form">
        <app-create-client *ngIf="newClient" [flowType]="flowType" displayType="tab" bonClientType="clientfacture" [bonNumBon]="bonNumBon" [(panier)]="panier" (onChange)="onCreateClientChanged($event)" ></app-create-client>
        <app-client-detail *ngIf="editClient" [flowType]="flowType" displayType="tab" bonClientType="clientfacture" [bonNumBon]="bonNumBon" [selectedClient]="selectedClient" [(panier)]="panier" (onChange)="onEditClientChanged($event)" ></app-client-detail>

        <div class="row" [class.no-gutters]="isMobile" *ngIf="!newClient && !editClient">
            <div class="col-md-8" [class.no-padding]="isMobile">        
                <form [formGroup]="searchForm" *ngIf="!readOnly && !newClient && !editClient && flowType !== 'retrait_marchandise'" (keydown.enter)="$event.preventDefault();" [class.section-disabled]="sameAsClient || sectionDisabled" (ngSubmit)="searchClientFormSubmitter()">
                    <div class="row">
                        <div class="col-9 col-md-6 text-left d-flex align-items-center">
                            <mat-slide-toggle formControlName="same_client" (change)="changeSameClient()" [disabled]="readOnly && sectionDisabled">{{'kbonsamecust' | translate}}</mat-slide-toggle>
                        </div>

                        <div class="d-flex justify-content-end">
                            <div class="col-12 col-md-6 text-right">
                                <button type="button" mat-button (click)="openClientHistory()" *ngIf="selectedClient !== undefined && selectedClient !== null">
                                    <i class="material-icons">history</i>
                                    <span class="d-none d-sm-none d-md-block">
                                        {{'kbonhistoire' | translate}}
                                    </span>                        
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isMobile">
                        <div class="col-9 col-md-4">
                            <h4 class="text-left">
                                {{'kbonNum' | translate}}
                                <span class="detail-holder d-inline-block w-75">{{selectedClient?selectedClient.IDTIERS:''}}</span>
                            </h4>
                        </div>
                        <div class="col-3 no-padding">                    
                            <button matSuffix type="button" class="ml-2" mat-button (click)="openEditClientForm()" [disabled]="readOnly && sectionDisabled" *ngIf="!sameAsClient && (selectedClient !== undefined && selectedClient !== null) && flowType !== 'retrait_marchandise'" style="margin-top:2px !important">
                                <mat-icon>edit</mat-icon>
                            </button>                         
                        </div>  
                    </div>
                    <div class="row" *ngIf="!isMobile">
                        <div class="col-12 col-md-6">
                            <h4 class="text-left  pt-3">
                                {{'kbonNum' | translate}}
                                <span class="detail-holder d-inline-block w-75">{{selectedClient?selectedClient.IDTIERS:''}}</span>
                            </h4>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <button type="button" *ngIf="!sameAsClient && flowType !== 'retrait_marchandise'" mat-button [disabled]="readOnly && sectionDisabled" (click)="openCreateClientForm()">
                                <i class="material-icons">add</i>
                                <span class="d-none d-sm-none d-md-block">
                                    {{'kbonnouveau' | translate}}
                                </span>                        
                            </button>
                                               
                            <button type="button" class="ml-2" mat-button (click)="openEditClientForm()" [disabled]="readOnly && sectionDisabled" *ngIf="!sameAsClient && (selectedClient !== undefined && selectedClient !== null) && flowType !== 'retrait_marchandise'">
                                <i class="material-icons">edit</i>
                                <span class="d-none d-sm-none d-md-block">
                                    {{'kbonmodifier' | translate}}
                                </span>                        
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field>
                                <input matInput formControlName="client_nom"
                                       (keyup.enter)="searchClients()"
                                       [placeholder]="'kbonrecherchenom' | translate"
                                       class="text-uppercase">
                                <button matSuffix type="submit" [disabled]="searchButtonDisabled || (readOnly && sectionDisabled)" mat-icon-button *ngIf="!isMobile">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button matSuffix type="button" (click)="searchClients()" mat-icon-button class="inlineblock"  [disabled]="readOnly && sectionDisabled" *ngIf="isMobile">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button matSuffix type="button"  class="ml-2" mat-icon-button (click)="openAdvancedSearch()" [disabled]="readOnly && sectionDisabled" *ngIf="!sameAsClient && isMobile">
                                    <mat-icon class="bt_logout bt_cursor text-white" [title]="'kclsearc' | translate">more_vert</mat-icon>
                                </button> 
                                <button matSuffix type="button" *ngIf="!sameAsClient && isMobile && flowType !== 'retrait_marchandise'" [disabled]="readOnly && sectionDisabled" mat-icon-button (click)="openCreateClientForm()" class="ml-2">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-md-6 no-padding" >                        
                        <div class="col-12" [class.section-disabled]="!readOnly && (sameAsClient || sectionDisabled)">
                            <h4 class="text-left">
                                {{'kboncompnomprenom' | translate}}
                                <span class="detail-holder">{{selectedClient?((selectedClient.CIVLIBC != null?selectedClient.CIVLIBC+' ':'')+selectedClient.TRAISOC+(selectedClient.TPRENOM?' '+selectedClient.TPRENOM:'')):''}}</span>
                            </h4>
            
                            <h4 class="text-left">
                                {{'kbonaddress' | translate}} <br>
                                <span class="detail-holder">{{selectedClient?(selectedClient.TADR1?selectedClient.TADR1:''):''}}</span>
                                <span class="detail-holder">{{selectedClient?(selectedClient.TADR2?selectedClient.TADR2:''):''}}</span>
                                <span class="detail-holder">{{selectedClient?(selectedClient.TADR3?selectedClient.TADR3:''):''}}</span>
                                <span class="detail-holder">{{selectedClient?(selectedClient.TADR4?selectedClient.TADR4:''):''}}</span>
            
                                <div class="d-flex justify-content-between">
                                    <span class="detail-holder w-25">{{selectedClient?(selectedClient.CODPOS?selectedClient.CODPOS:''):''}}</span> 
                                    <span class="detail-holder ml-1" style="flex: 1;">{{selectedClient?(selectedClient.TVILLE?selectedClient.TVILLE:''):''}}</span>
                                </div>                    
                            </h4>
            
                            <h4 class="text-left">
                                {{'kboncountry' | translate}}
                                <span class="detail-holder"> {{selectedClient?(selectedClient.PAYSNOM?selectedClient.PAYSNOM:''):''}}</span>
                            </h4>
                        </div>
                    </div>
                    <div class="col-md-6 no-padding" >
                        <div class="col-12" [class.section-disabled]="!readOnly && (sameAsClient || sectionDisabled)">
                            <h4 class="text-left">
                                {{'kclshop' | translate}}
                                <span class="detail-holder">{{ (magasinNom !== undefined && magasinNom !== null)?magasinNom:('kclnondefi' | translate) }}</span>
                            </h4>
                            
                            <h4 class="text-left">
                                {{'kbonemail' | translate}}
                                <span class="detail-holder">{{selectedClient?(selectedClient.TEMAIL?selectedClient.TEMAIL:''):''}}</span>
                            </h4>

                            <h4 class="text-left">
                                {{'kclport' | translate}}
                                <span class="detail-holder">{{selectedClient?(selectedClient.TPORT?(selectedClient.TPORT | formatTele):''):''}}</span>
                            </h4>
                            
                            <h4 class="text-left">
                                {{'kbontel' | translate}}
                                <span class="detail-holder">{{selectedClient?(selectedClient.TTEL?(selectedClient.TTEL | formatTele):''):''}}</span>
                            </h4>

                            <h4 class="text-left">
                                {{'kbonfax' | translate}}
                                <span class="detail-holder">{{selectedClient?(selectedClient.TFAX?selectedClient.TFAX:''):''}}</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">                
                <div class="card" *ngIf="facturationDetail && facturationDetail !== null">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kbonpayaujour' | translate}}</span>
                    </div>
                    
                    <div class="card-body">
                        <table class="table no-border">
                            <tr>
                                <td></td>
                                <th class="text-right">{{'kbonmonatant' | translate}}</th>
                                <th class="text-right">{{'kbonporacct' | translate}}</th>
                                <th class="text-right">{{'kbontotal' | translate}}</th>
                            </tr>
                            <tr>
                                <th>{{'kbonei' | translate}}</th>
                                <td class="text-right"></td>
                                <td class="text-right"></td>
                                <th class="text-right">{{facturationDetail.totEI | number: '0.2'}}</th>
                            </tr>
                            <tr>
                                <th>{{'kartlabeled' | translate}}</th>
                                <td class="text-right">{{facturationDetail.mntED | number: '0.2'}}</td>
                                <td class="text-right">{{facturationDetail.pourED | number: '0.2'}} %</td>
                                <th class="text-right">{{facturationDetail.totED | number: '0.2'}}</th>
                            </tr>
                            <tr>
                                <th>{{'kbonbl' | translate}}</th>
                                <td class="text-right">{{facturationDetail.mntBL | number: '0.2'}}</td>
                                <td class="text-right">{{facturationDetail.pourBL | number: '0.2'}} %</td>
                                <th class="text-right">{{facturationDetail.totBL | number: '0.2'}}</th>
                            </tr>
                            <tr>
                                <th>{{'kbone2' | translate}}</th>
                                <td class="text-right">{{facturationDetail.mntE2 | number: '0.2'}}</td>
                                <td class="text-right">{{facturationDetail.pourE2 | number: '0.2'}} %</td>
                                <th class="text-right">{{facturationDetail.totE2 | number: '0.2'}}</th>
                            </tr>

                            <tr>
                                <th colspan="3" class="text-right">{{'kbontotal' | translate}}</th>                                
                                <th class="text-right">{{(facturationDetail.totEI + facturationDetail.totED + facturationDetail.totBL + facturationDetail.totE2) | number: '0.2'}}</th>
                            </tr>
                        </table>                        
                    </div>                    
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
