import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/fromEvent';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  windowScrolled: boolean;
  private scrollEvent$;
  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngAfterViewInit() {
    this.scrollEvent$ = Observable.fromEvent(document.querySelector('mat-sidenav-content'), 
    'scroll').subscribe((e: any) => {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100 || document.querySelector('mat-sidenav-content').scrollTop > 100) {
        this.windowScrolled = true;
      } 
      else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10 || document.querySelector('mat-sidenav-content').scrollTop < 10) {
          this.windowScrolled = false;
      }
  });
  }
  
  scrollToTop() {
      (function smoothscroll() {
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop || document.querySelector('mat-sidenav-content').scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              document.querySelector('mat-sidenav-content').scrollTo(0, currentScroll - (currentScroll / 8));
          }
      })();
  }
  ngOnInit() {}

  ngOnDestroy() {
    this.scrollEvent$.unsubscribe();
  }
}
