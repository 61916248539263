import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import BonPanier, { PanierInfosfac } from 'models/bonPanier';

@Component({
  selector: 'app-facturation-acompte',
  templateUrl: './facturation-acompte.component.html',
  styleUrls: ['./facturation-acompte.component.scss']
})
export class FacturationAcompteComponent implements OnInit {

  panier: BonPanier;
  facturationDetail: PanierInfosfac;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<FacturationAcompteComponent>) { }

  ngOnInit(): void {
    this.panier = this.data.panier;
    this.facturationDetail = this.data.facturationDetail;
  }

  onSubmitter() {
    this.dialogRef.close({ status: 'success' });
  }

}
