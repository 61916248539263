<div class="page-container" *ngIf="!keycloakLogin">
    <div class="form-container">
        <form name="loginForm" #loginFormRef="ngForm" [formGroup]="loginForm" (ngSubmit)="loginFormSubmitter()" (keydown.enter)="$event.preventDefault()" class="form-horizontal" autocomplete="off" aria-autocomplete="none" >
            <mat-card>
                <mat-card-header>
                    <mat-card-title class="text-uppercase text-center">
                        <h2>
                            {{'klwelcome' | translate}}
                        </h2>
                    </mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon>
                                <i class="fa fa-user" aria-hidden="true"></i>
                            </mat-icon>
                        </span>
                        <input matInput inputmode="none" [placeholder]="'kusername' | translate" 
                            formControlName="USERNAME"
                            (keydown)="onUsernameEnterPressed($event)" 
                            (keyup)="checkRememberUser()" 
                            (change)="checkRememberUser()"
                            name="userName" 
                            cdkFocusInitial 
                            #userNameInput >
                        <button matSuffix type="button" *ngIf="isMobile" (click)="openScanArticle($event)" tabindex="-1" mat-icon-button>
                            <i class="fa fa-barcode" aria-hidden="true"></i>
                        </button>
                    </mat-form-field>
                    <br> <br>
                    <mat-form-field>
                        <span matPrefix>
                            <mat-icon>
                                  <i class="fa fa-key" aria-hidden="true"></i>
                            </mat-icon>
                        </span>
                        <input matInput #password [placeholder]="'kpassword' | translate"
                            formControlName="PASSWORD"
                            (keydown)="onPasswordEnterPressed($event)" 
                            (keyup)="checkRememberUser()" 
                            (change)="checkRememberUser()" 
                            name="password" 
                            [type]="passwordHide ? 'password' : 'text'" minlength="3" 
                        >
                        <span matSuffix>
                            <mat-icon (click)="togglePasswordDisplay()">
                                  <i [class]=" passwordHide ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i>
                            </mat-icon>
                        </span>
                    </mat-form-field> 
                    <br><br>
                    <mat-form-field>
                        <span matPrefix><mat-icon>store</mat-icon></span>
                        <mat-select #mySelect 
                            (focus)="selectOptions()" 
                            (keydown.enter)="loginFormRef.ngSubmit.next()" 
                            formControlName="MAGASIN"
                            [placeholder]="'klselstore' | translate" 
                            disableOptionCentering 
                            panelClass="dropdown-panel" 
                            (selectionChange)="onChangeMagasin($event)">
                            <mat-option>
                                <ngx-mat-select-search
                                [placeholderLabel]="'klselstore' | translate"
                                [noEntriesFoundLabel]="'klnostore' | translate"
                                [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="onSearchChange($event)" [(ngModel)]="filterMagasin"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let magasin of filteredMagasins" [value]="magasin.CMINT" required>
                                {{ magasin.CMRAISOC }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>
                    <div *ngIf="control_magasin" class="controlmag">{{ control_magasin | translate }}</div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button type="submit" class="bt_connect" >{{'klconnection' | translate}}</button>                    
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
</div>