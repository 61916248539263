import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import BonPanier, { PanierArticle, PanierClient } from 'models/bonPanier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArticleStockComponent } from 'pages/article-detail/article-stock/article-stock.component';
import { RemiseDetailComponent } from 'pages/create-bon-de-vente/remise-detail/remise-detail.component';
import { RemiseListComponent } from 'pages/create-bon-de-vente/remise-list/remise-list.component';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import * as moment from 'moment';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { BonVente } from 'models/bonventes';
import { MotifForcePrixComponent } from 'pages/create-bon-de-vente/motif-force-prix/motif-force-prix.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { BlocageCustomMotifComponent } from 'pages/article-detail/article-stock/update-motif-blocage/blocage-custom-motif/blocage-custom-motif.component';
import { Constants } from 'providers/constants';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { ParamDetail } from 'models/paramDetail';
import { VenteArticleGroupsComponent } from 'pages/create-bon-de-vente/vente-article-groups/vente-article-groups.component';
import { DevisService } from 'providers/api/devisService';
import { UtilService } from 'providers/api/utilService';
import { User } from 'models/users';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { CalculateQuantityComponent } from 'pages/create-bon-de-vente/calculate-quantity/calculate-quantity.component';
import { CapDecimalPipe } from 'providers/customNumber-pipe';
import {Magasin} from '../../../../models/magasins';
import {LoginService} from '../../../../providers/api/loginService';
import {
  AUTHORIZE_CHANGEMENT_DE_PRIX,
  AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG, AUTHORIZE_MODIFY_ARTICLES_BON_AUTRE_VENDEUR, AUTHORIZE_REMISE_EN_LIGNE,
  AUTHORIZE_REMISE_EN_PIED,
  HistoryService
} from '../../../../providers/api/historyService';
import {NonServiceArticlesListComponent} from '../../non-service-articles-list/non-service-articles-list.component';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-modify-article-detail',
  templateUrl: './modify-article-detail.component.html',
  styleUrls: ['./modify-article-detail.component.scss'],
  providers: [BonVenteService, UserService, DevisService, UtilService]
})
export class ModifyArticleDetailComponent implements OnInit, AfterViewInit {

  @ViewChild('prixVente', {static: true}) newPrixVente: ElementRef;
  @ViewChild('articleQty', {static: true}) articleQty: ElementRef;

  maskOptions = {
    mask: Number,
    scale: 2,    
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  qtyMaskOptions: any = {
    mask: Number,
    scale: 2,
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  isMobile: boolean;
  user: UserModel;
  USERCMINT: any;

  articleData: Article;
  currentItem: PanierArticle;
  listBonVenteArticles: Article[];
  currentArticleStockMag: number | undefined;
  currentArticleStockMagNom: string = "";

  listNonServiceArticles: Article[] = [];
  listNonServiceItems: PanierArticle[] = [];
  cdartArticlesHashTable: { [key: string]: Article } = {};

  bonNumBon: string;
  panier: BonPanier;

  editItemForm: UntypedFormGroup;

  chosenRemise: any[];
  chosenLineRemise: any[];
  articleModifiedUserName: string;
  articleModifiedDate: string;

  forcage_text = "***";
  forcageType: 'vente' | 'commande';
  commander: boolean = false;

  articleLivraison: boolean = false;

  typeVenteRules: {title: string, data: { label: string, value: string, paramKey: string, disabled?: boolean }[]};
  flowType: string;

  // Stock Autre or User Params List
  paramsList: ParamDetail[];
  // Only User Params List (Backup)
  userParamsList: ParamDetail[];

  listMagasins: Magasin[] = [];

  // Article d'options
  articleGroupsRawData: any[];
  listArticleGroups: any[];
  currentArticleChosenGroups: any;
  linkedStatus: 'service' | 'bundle' | 'no_link' = 'no_link';

  userPermission: any;
  PU_vente_changable: boolean = false;
  
  authorizedLineRemiseUser: User;
  authorizedLinePrixUser: User;
  authorizedArticleStockMagUser: User;
  authorizedModifyItemUser: User;

  qtyLength: number = 0;
  qtyWidth: number = 0;
  qtyHeight: number = 0;

  quantity5Decimal: boolean = false;
  quantityDisabled = false;
  maxQteAllowed = 0;

  readOnly: boolean = true;
  isLinkedToAutreArticleHistory: any;
  isDevis: boolean;
  isBonDeVente: boolean;
  isBonDeCommande: boolean;
  isWefoxArticle: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModifyArticleDetailComponent>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private dialogService: DialogsService,
    private userService: UserService,
    private bonVenteService: BonVenteService,
    private bonCommandeService: BonCommandeService,
    private deivsService: DevisService,
    private utilService: UtilService,
    private loadingService: NgxSpinnerService,
    private currencyPipe: CapCurrencyPipe,
    private deviceDetector: DeviceDetectorService,
    private decimalPipe: CapDecimalPipe,
    private loginService: LoginService,
    private historyService: HistoryService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();

    this.bonNumBon = this.data.bonNumBon;
    this.panier = this.data.panier;
    this.currentItem = this.data.panier_article;    
    this.articleData = this.data.article_data;
    this.listBonVenteArticles = this.data.listBonVenteArticles;

    this.listNonServiceArticles = this.data?.listNonServiceArticles;
    this.listNonServiceItems = this.data?.listNonServiceItems;
    this.cdartArticlesHashTable = this.data?.cdartArticlesHashTable;
    this.isDevis = this.data?.isDevis;
    this.isBonDeVente = this.data?.isBonDeVente;
    this.isBonDeCommande = this.data?.isBonDeCommande;

    this.articleModifiedDate = (this.currentItem !== undefined && this.currentItem.dtmaj !== undefined && this.currentItem.dtmaj !== null)?moment(this.currentItem.dtmaj).utcOffset(this.currentItem.dtmaj).format('DD.MM.YYYY HH:mm'):'';      
    this.USERCMINT = (this.data.CMINT !== undefined && this.data.CMINT !== null)?this.data.CMINT:this.user.CMINT;
    this.flowType = (this.data.flowType !== undefined && this.data.flowType != 'null')?this.data.flowType:'bonde_vente';

    this.articleModifiedUserName = (this.currentItem !== undefined)?this.currentItem.libuser:'';

    if (this.data && this.data.hasOwnProperty('authorizedModifyItemUser')) {
      this.authorizedModifyItemUser = this.data.authorizedModifyItemUser || undefined;
    }
    
    this.editItemForm = this.formBuilder.group({
      CDART: [{value: '', disabled: true}, Validators.required],
      CALIB: [{value: '', disabled: true}, Validators.required],
      QTY: ['', [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      PRIX: [{value: '', disabled: true}],
      STOCK_DISPO: [{value: '', disabled: true}],
      PRIX_VENTE: ['', [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      PRIX_TOTAL: [{value: '', disabled: true}],
      TYPECDE: ['', Validators.required],
      REMTOT: [{value: '', disabled: true}],
      EXPO: [false],
      COIN_SOLDE: [false],
      INFO1: [''],
      INFO2: [''],

      PRIX_FORCE: [false],
      MOTIF_PRIX: [''],
      MOTIF_PRIX_LBL: [''],

      EXCLUSION_COM: [false]
    });

    this.userPermission = this.storageService.getItem('CurrentUserPermission');

    if(!(this.currentItem.opt <= 0)) {
      this.editItemForm.get('QTY').disable({ onlySelf: true });
    } else {
      this.editItemForm.get('QTY').enable({ onlySelf: true });
    }

    if(this.flowType === 'bonde_commande') {
      if(this.currentItem.statut != 7 && this.currentItem.statut != 17 && this.currentItem.statut != 27 && this.panier.preparation == false) {
        this.readOnly = false;
      }

      if(!this.userPermission['32_13'] || this.currentItem.statut == 7 || this.currentItem.statut == 17 || this.currentItem.statut == 27 || this.panier.preparation) {
        this.editItemForm.get('PRIX_VENTE').disable({ onlySelf: true });
        this.editItemForm.get('PRIX_VENTE').setErrors(null);
        this.editItemForm.get('PRIX_VENTE').clearValidators();
        this.editItemForm.get('PRIX_VENTE').updateValueAndValidity();
      } else {
        this.editItemForm.get('PRIX_VENTE').enable({ onlySelf: true });
        this.editItemForm.get('PRIX_VENTE').setValidators([Validators.required, Validators.pattern('^[0-9\.\,]+$')]);
        this.editItemForm.get('PRIX_VENTE').updateValueAndValidity();
      }
    } else {
      this.readOnly = false;
      this.editItemForm.get('PRIX_VENTE').enable({ onlySelf: true });
      this.editItemForm.get('PRIX_VENTE').setValidators([Validators.required, Validators.pattern('^[0-9\.\,]+$')]);
      this.editItemForm.get('PRIX_VENTE').updateValueAndValidity();
    }

    this.editItemForm.patchValue({
      CDART: this.currentItem.cdart,
      CALIB: this.currentItem.lib,
      STOCK_DISPO: (this.articleData.STOCKDISPO !== null)?this.decimalPipe.transform(this.articleData.STOCKDISPO):this.decimalPipe.transform(0),
      TYPECDE: this.currentItem.typecde,
      REMTOT: (this.currentItem.remise).toString(),
      EXPO: this.currentItem.expo?true:false,
      COIN_SOLDE: this.currentItem.coinsolde?true:false,
      INFO1: this.currentItem.commlig1,
      INFO2: this.currentItem.commlig2,

      PRIX_FORCE: this.currentItem.prixforce,
      MOTIF_PRIX: this.currentItem.motifprixforce,
      EXCLUSION_COM: this.currentItem.excluoffre ? true : false
    });

    // In order to fix the Input Mask issue for prices,
    // we're putting them into setTimeOut
    setTimeout(() => {
      this.editItemForm.patchValue({
        PRIX: this.utilService.formatMaskCompat(this.currentItem.prix),
        PRIX_VENTE: this.utilService.formatMaskCompat(this.currentItem.prixupv),
        PRIX_TOTAL: this.utilService.formatMaskCompat(this.currentItem.montant),
      })
    }, 500);

    if(this.readOnly) {
      this.editItemForm.get('QTY').disable({ onlySelf: true });
      this.editItemForm.get('INFO1').disable({ onlySelf: true });
      this.editItemForm.get('INFO2').disable({ onlySelf: true });
      this.editItemForm.get('EXPO').disable({ onlySelf: true });
      this.editItemForm.get('COIN_SOLDE').disable({ onlySelf: true });
      this.editItemForm.get('EXCLUSION_COM').disable({ onlySelf: true });

      this.newPrixVente.nativeElement.blur();
    }

    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();

    await this.getQuantityMaxLimit();

    if(this.flowType === 'devis' || this.articleData.CAUNVTE === Constants.QTE_ALLOW_DECIMAL_CAUNVTE) {
      await this.getQuantityDecimalStatus();
    }

    await this.getListParams();
    if (this.panier.magstock) {
      await this.getListParams(this.panier.magstock);
    }

    await this.getMagasins();

    // Check Stock Magasin
    if (this.panier.magstock) {
      this.currentArticleStockMag = this.panier.magstock;

      // Getting the Magsin Nom
      const currentBonStockMag = this.listMagasins.find((row) => +row.CMINT == this.currentArticleStockMag)
      if(currentBonStockMag) {
        this.currentArticleStockMagNom = currentBonStockMag.CMRAISOC;
      }
    }

    if(this.flowType !== 'devis') {
      await this._restrictQtyDecimal(this.articleData);
    }

    this.definingTypeDeRules();

    if(this.quantity5Decimal) {
      this.editItemForm.patchValue({
        QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite, this.quantityMask.scale === 0 ? '0.0' : '0.5')
      });
    } else {
      this.editItemForm.patchValue({
        QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite, this.quantityMask.scale === 0 ? '0.0' : '0.2')
      });
    }

    if (this.currentItem.opt > 0) {
      const parentItem = [...this.panier.articles].find(rw => rw.opt == -this.currentItem.opt);
      const parentArticle = this.listBonVenteArticles.find(rw => rw.CDART === parentItem.cdart);

      await this.checkArticleParentChildStatus([[parentArticle.CAINT, this.articleData.CAINT]]);
    }

    if(this.currentItem.opt < 0 && +this.articleData.GROUP_COUNT > 0) {
      this.linkedStatus = 'bundle';
      await this.searchArticleGroupsCAINT(this.articleData.CAINT);
    }

    this.PU_vente_changable = true;
    if(!this.userPermission['0_17']) {
      this.PU_vente_changable = false;
    }

    this.loadingService.hide();
  }

  get isServiceArticle() {
      return this.articleData && this.articleData.CAGESTOCK == '0';
  }

  get quantityMask() {
    return this.qtyMaskOptions;
  }

  _restrictQtyDecimal(currentArticle: Article): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if(currentArticle === undefined || currentArticle === null) {
        reject('INVALID_CURRENT_ARTICLE');
        return;
      }

      const processedRules = this.userService.processRulesParams(this.paramsList);

      let maxLimit;
      if (this.currentArticleStockMag && (currentArticle.STOCKDISPO !== undefined && currentArticle.STOCKDISPO !== null)) {
        if (!processedRules['20_6'] || (processedRules['20_6']['VBOOL3'] != '-1' && processedRules['20_6']['VBOOL4'] != '-1')) {
          maxLimit = +currentArticle.STOCKDISPO + this.currentItem.quantite;
        }
      }

      if(currentArticle.CAUNVTE !== Constants.QTE_ALLOW_DECIMAL_CAUNVTE) {
        this.qtyMaskOptions = {
          mask: Number,
          scale: 0,
          padFractionalZeros: false,
          normalizeZeros: false,
          radix: this.utilService.getCurrentDecimalSymbol,
          mapToRadix: ["."],
          max: maxLimit
        };
      } else {
        this.qtyMaskOptions = {
          mask: Number,
          scale: this.quantity5Decimal ? 5 : 2,
          padFractionalZeros: true,
          radix: this.utilService.getCurrentDecimalSymbol,
          mapToRadix: ["."],
          max: maxLimit
        };
      }

      // To solve issue with Qty while changing the MaskOptions.scale to zero
      setTimeout(() => {
        this.editItemForm.get('QTY').setValue('1');
        resolve();
      }, 100);
    });
  }

  async getQuantityDecimalStatus() {
    try {
      const paramData = await this.userService.getRuleParamsData(10,12, 'VBOOL1');
      this.quantity5Decimal = false;
  
      this.qtyMaskOptions = {
        mask: Number,
        scale: 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ["."]
      };
  
      if(paramData !== undefined && paramData != null && paramData == '-1') {
          this.quantity5Decimal = true;
  
          this.qtyMaskOptions = {
            mask: Number,
            scale: 5,
            padFractionalZeros: true,
            radix: this.utilService.getCurrentDecimalSymbol,
            mapToRadix: ["."]
          };
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  async getQuantityMaxLimit() {
    try {
      const paramData = await this.userService.getRuleParamsData(10, 12, 'VFLOAT1');
      this.maxQteAllowed = 0;

      if (paramData !== undefined && paramData != null && paramData !== '') {
        this.maxQteAllowed = +paramData;
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.articleQty.nativeElement.setAttribute('inputmode','none');
      this.articleQty.nativeElement.select();
      this.articleQty.nativeElement.focus();
      this.articleQty.nativeElement.setAttribute('inputmode','decimal');
    }, 100);
  }

  async definingTypeDeRules() {
    this.articleLivraison = false;
    const processedRules = this.userService.processRulesParams(this.paramsList);
    let processedUserRules = this.userService.processRulesParams(this.userParamsList);

    if (this.userParamsList === undefined || this.userParamsList === null) {
      processedUserRules = processedRules;
    }

    if(this.articleData.CAGESTOCK != '0' && this.articleData.CAOKLIV != '-1') {
      if(this.currentItem.typecde.search('E2') > -1) {
        this.typeVenteRules = {
          title: "kbondevenircmd",
          data: [
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: "E2/ED",
              disabled: (processedRules['20_4']['VBOOL3'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: "E2/BL",
              disabled: (processedRules['20_4']['VBOOL4'] == '-1')?false:true
            },
          ]
        };
      } else {
        this.typeVenteRules = {
          title: "kbontypedevente",
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: "EI",
              disabled: (processedRules['20_3']['VBOOL1'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: "ED",
              disabled: (processedRules['20_3']['VBOOL2'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: "BL",
              disabled: (processedRules['20_3']['VBOOL3'] == '-1')?false:true
            },
          ]
        };
      }

      if(this.articleData.STOCKDISPO > 0) {
        this.forcageType = 'commande';
        this.forcage_text = "kbonforcecommand";

        if(this.currentItem.motifcde !== null && this.currentItem.motifcde !== '') {
          this.commander = true;
        } else {
          this.commander = false;
        }
      } else {
        this.forcageType = 'vente';        
        this.forcage_text = "kbonforecsale";

        if(this.currentItem.motifcde !== null && this.currentItem.motifcde !== '') {
          this.commander = false;
        } else {
          this.commander = true;
        }        
      }
    } else {
      if(this.articleData.CAOKLIV == '-1') {
        this.typeVenteRules = {
          title: "kbontypedeli",
          data: [
            {
              paramKey: '20_4;VSTR1;VBOOL1',
              label: processedUserRules['20_4']['VSTR1'],
              value: "BL",
              disabled: (processedRules['20_4']['VBOOL1'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR2;VBOOL2',
              label: processedUserRules['20_4']['VSTR2'],
              value: "E2/BL",
              disabled: (processedRules['20_4']['VBOOL2'] == '-1')?false:true
            },
          ]
        };
      } else {
        this.typeVenteRules = {
          title: "kbontypedecomvente",
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: "EI",
              disabled: (processedRules['20_3']['VBOOL1'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: "ED",
              disabled: (processedRules['20_3']['VBOOL2'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: "BL",
              disabled: (processedRules['20_3']['VBOOL3'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: "E2/ED",
              disabled: (processedRules['20_4']['VBOOL3'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: "E2/BL",
              disabled: (processedRules['20_4']['VBOOL4'] == '-1')?false:true
            },
          ]
        };
      }

      this.commander = false;
      this.forcageType = undefined;
      this.forcage_text = "***";

      if(this.articleData.CAGESTOCK == '0') {
        this.editItemForm.patchValue({
          STOCK_DISPO: this.translateService.instant('kartmang')
        });
      } else if(this.articleData.CAOKLIV == '-1') {
        this.editItemForm.patchValue({
          STOCK_DISPO: this.translateService.instant('kbonartliv')
        });

        this.articleLivraison = true;
      }
    }

    if(this.flowType === 'bonde_commande') {
      if(this.currentItem.typecde === 'E2/ED' || this.currentItem.typecde === 'E2/BL') {
        this.commander = true;
      } else {
        this.commander = false;
      }
    }

    if(this.flowType === 'devis') {
      this.typeVenteRules = {
        title: "kbontypedevente",
        data: [
          {
            paramKey: '20_3;VSTR1;VBOOL1',
            label: processedUserRules['20_3']['VSTR1'],
            value: "EI",
            disabled: (processedRules['20_3']['VBOOL1'] == '-1')?false:true
          },
          {
            paramKey: '20_3;VSTR2;VBOOL2',
            label: processedUserRules['20_3']['VSTR2'],
            value: "ED",
            disabled: (processedRules['20_3']['VBOOL2'] == '-1')?false:true
          },
          {
            paramKey: '20_3;VSTR3;VBOOL3',
            label: processedUserRules['20_3']['VSTR3'],
            value: "BL",
            disabled: (processedRules['20_3']['VBOOL3'] == '-1')?false:true
          },
        ]
      };
    }

    if(this.articleData.etatart === 'G') {
      this.commander = true;
    }

    this.quantityDisabled = false;
    const checkWefox = this.checkArticleWefoxStatus(this.articleData.CDART.toLowerCase());
    this.isWefoxArticle = checkWefox.wefoxStat;
    
    if (checkWefox.wefoxStat) {
      this.quantityDisabled = true;
      this.editItemForm.get('QTY').disable({ onlySelf: true });

      if (checkWefox.wefoxTypeRestriction) {
        const typeCdeArt = checkWefox.wefoxType.split(Constants.WEFOX_TYPE_SEPARATOR);

        this.typeVenteRules = {
          title: 'kbontypedecomvente',
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: "EI",
              disabled: (processedRules['20_3']['VBOOL1'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: "ED",
              disabled: (processedRules['20_3']['VBOOL2'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: "BL",
              disabled: (processedRules['20_3']['VBOOL3'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: "E2/ED",
              disabled: (processedRules['20_4']['VBOOL3'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: "E2/BL",
              disabled: (processedRules['20_4']['VBOOL4'] == '-1')?false:true
            },
          ]
        };

        this.typeVenteRules.data = this.typeVenteRules.data.filter((row) => {
          return typeCdeArt.some((_r: string) => row.value.includes(_r));
        });
      }
    }

    if (this.flowType === 'bonde_vente' && this.currentArticleStockMag) {
      if (processedRules['20_5'] && processedRules['20_6']) {
        const replacementParamKey = { '20_3': '20_5', '20_4': '20_6' };

        this.typeVenteRules.data = this.typeVenteRules.data.map(rw => {
          const [PARAM_ROW_ID, PARAM_STR, PARAM_BOOL] = rw.paramKey.split(';');

          rw.label = processedRules[replacementParamKey[PARAM_ROW_ID]][PARAM_STR];
          rw.disabled = (processedRules[replacementParamKey[PARAM_ROW_ID]][PARAM_BOOL] == '-1') ? false : true;

          return rw;
        });

        const filteredRules = this.typeVenteRules.data.filter((row) => !row.disabled);

        if (filteredRules.length === 0) {
          this.typeVenteRules.data = [];
          this.editItemForm.get('TYPECDE').setValue('');
          await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kprix_commande_impossible_magasin')).toPromise();
          return;
        }

        const selectedTypeVal = this.editItemForm.get('TYPECDE').value;
        const selectedTypeIsAvail = filteredRules.find(rw => rw.value == selectedTypeVal);
        if (!selectedTypeIsAvail) {
          this.editItemForm.get('TYPECDE').setValue(filteredRules[0].value);
        }

        return;
      }

      this.typeVenteRules.data = this.typeVenteRules.data.filter((row) => row.value != 'EI' && row.value.indexOf('E2') == -1 && !row.disabled);

      if (this.typeVenteRules.data.length === 0) {
        this.typeVenteRules.data = [];
        this.editItemForm.get('TYPECDE').setValue('');
        await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kprix_commande_impossible_magasin')).toPromise();
        return;
      }

      const row_ED = this.typeVenteRules.data.find(r => r.value === 'ED');
      const row_BL = this.typeVenteRules.data.find(r => r.value === 'BL');

      const TYPECDE = this.editItemForm.get('TYPECDE').value;
      if (TYPECDE === "" || TYPECDE === null) {
        if (row_ED && row_BL) {
          this.editItemForm.get('TYPECDE').setValue('ED');
        } else {
          this.editItemForm.get('TYPECDE').setValue(this.typeVenteRules.data[0].value);
        }
      }
    }
  }

  getListParams(overrideCMINT?: any): Promise<void> {
    return new Promise((resolve, reject) => {

      let CMINT = this.USERCMINT;
      if (overrideCMINT !== undefined && overrideCMINT !== null) {
        CMINT = overrideCMINT;
      }

      if (CMINT != this.USERCMINT && (this.userParamsList === undefined || this.userParamsList === null)) {
        this.userParamsList = this.paramsList;
      }

      this.userService.getRulesParams(CMINT, this.USERCMINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {              
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  getMagasins(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.listMagasins = [];
      this.loginService.getMagasins().subscribe(
          (data: any) => {
            if (data.length > 0) {
              this.listMagasins = [...data];
            }
            resolve(data);
          },
          (error: any) => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(error);
          });
    });
  }

  checkArticleWefoxStatus(CDART: string, processedRules?: any): { wefoxStat: boolean, wefoxTypeRestriction: boolean, wefoxType: string } {
    if (processedRules === undefined || processedRules === null) {
      processedRules = this.userService.processRulesParams(this.paramsList);
    }

    const result = {
      wefoxStat: false,
      wefoxTypeRestriction: true,
      wefoxType: ''
    };

    if (processedRules['20_61']['VBOOL1'] == '-1') {
      if (CDART.toLowerCase() === processedRules['20_61']['VSTR1'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR2'] !== '' && processedRules['20_61']['VSTR2'] != null)?processedRules['20_61']['VSTR2']:'';

        result.wefoxTypeRestriction = true;
      }

      if (CDART.toLowerCase() === processedRules['20_61']['VSTR3'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR4'] !== '' && processedRules['20_61']['VSTR4'] != null)?processedRules['20_61']['VSTR4']:'';

        result.wefoxTypeRestriction = true;
      }
    }

    return result;
  }

  editItemFormSubmitter() {
    if(this.currentItem === undefined || this.currentItem === null) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const form_data = this.editItemForm.getRawValue();

    if(form_data.CDART === '' || form_data.CDART === null || form_data.TYPECDE === '' || form_data.TYPECDE === null || form_data.QTY === '' || form_data.QTY === null || +form_data.QTY === 0) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if ((this.flowType === 'bonde_commande' || this.flowType === 'bonde_vente' || this.flowType === 'devis') && this.maxQteAllowed > 0 && +form_data.QTY > this.maxQteAllowed) {
      this.articleQty.nativeElement.setAttribute('inputmode', 'none');
      this.articleQty.nativeElement.select();
      this.articleQty.nativeElement.focus();
      setTimeout(() => {
        this.articleQty.nativeElement.setAttribute('inputmode', 'decimal');
      }, 50);

      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntytrop'), this.translateService.instant('kuok'));
      return;
    }

    if((this.flowType === 'bonde_commande' || this.flowType === 'bonde_vente') &&
        (form_data.TYPECDE === 'EI' || form_data.TYPECDE === 'ED' || form_data.TYPECDE === 'BL') &&
        !isNaN(form_data.STOCK_DISPO) &&
      (+form_data.QTY > this.currentItem.quantite && 
        +form_data.STOCK_DISPO < ((+form_data.QTY) - this.currentItem.quantite)
      )
      ) {
        this.articleQty.nativeElement.setAttribute('inputmode', 'none');
        this.articleQty.nativeElement.focus();
        this.articleQty.nativeElement.select();
        setTimeout(() => {
          this.articleQty.nativeElement.setAttribute('inputmode', 'decimal');
        }, 50);
        this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('katystockact'));
      return;
    }

    const checkWefox = this.checkArticleWefoxStatus(form_data.CDART.toLowerCase());

    if((form_data.PRIX_VENTE === '' || form_data.PRIX_VENTE === null || +form_data.PRIX_VENTE === 0) &&
        !(checkWefox.wefoxStat) &&
        !this.userPermission['0_22']) {
      this.newPrixVente.nativeElement.select();
      this.newPrixVente.nativeElement.focus();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kboninvalid'));
      return;
    }

    if((this.editItemForm.get('PRIX_VENTE').touched && !this.editItemForm.get('PRIX_VENTE').pristine && this.editItemForm.get('PRIX_VENTE').dirty) && (+form_data.PRIX !== +this.articleData.calculated_price)) {
      this.openUpPrixForceMotif();
      return;
    }

    if(this.flowType !== 'bonde_commande' && ((this.currentItem.opt < 0 && +this.articleData.GROUP_COUNT > 0) && (this.articleGroupsRawData.some((_r: any) => _r.TYPECDE == null || form_data.TYPECDE.indexOf(_r.TYPECDE) > -1)))) {
      this.openUpArticleGroups(this.listArticleGroups, async (res: any) => {
        if(res.status === 'success') {
          this.currentArticleChosenGroups = res.data;
          const processedRules = this.userService.processRulesParams(this.paramsList);

          // Check All Bons (Normal bons/Bons with autre mag) whether
          // All the Child options are having stock more than 0 or not
          // if not more than 0, display an alert and stop the execution instead of continuing.
          const notAvailChildCdartList = [];
          const someChildNotHaveStockAvail = this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
            return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
              return _row_option.SELECTED &&
                  _row_option.CAGE_STOCK != '0' &&
                  (!_row_option.STOCK_DISPO ||
                      (_row_option.PANIER_POSITION != null &&
                          (+_row_option.STOCK_DISPO + this.currentItem.quantite) < +form_data.QTY) ||
                      (_row_option.PANIER_POSITION == null && +_row_option.STOCK_DISPO < +form_data.QTY));
            });
          });

          this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
            return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
              if(_row_option.SELECTED &&
                  _row_option.CAGE_STOCK != '0' &&
                  (!_row_option.STOCK_DISPO ||
                      (_row_option.PANIER_POSITION != null &&
                          (+_row_option.STOCK_DISPO + this.currentItem.quantite) < +form_data.QTY) ||
                      (_row_option.PANIER_POSITION == null && +_row_option.STOCK_DISPO < +form_data.QTY))) {
                notAvailChildCdartList.push(_row_option.CDART);
              }
            });
          });

          if (someChildNotHaveStockAvail && (this.currentArticleStockMag || form_data.TYPECDE.indexOf('E2') < 0) && (!processedRules['20_6'] || (processedRules['20_6']['VBOOL3'] != '-1' && processedRules['20_6']['VBOOL4'] != '-1'))) {
            const message = `${this.translateService.instant('katystockact')} ${notAvailChildCdartList ? notAvailChildCdartList.join(', ') : ''}`;
            this.dialogService.prompt(this.translateService.instant('kuerror'), message);

            this.loadingService.hide();
            return;
          }

          if (this.currentArticleStockMag) {
            this.authorizedArticleStockMagUser = undefined;

            const someChildHaveStockMoreThanZero = this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
              return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
                return _row_option.SELECTED && _row_option.CAGE_STOCK != '0' && (_row_option.STOCK_DISPO && +_row_option.STOCK_DISPO > 0);
              });
            });

            if (someChildHaveStockMoreThanZero && !this.userPermission['0_23']) {
              this.loadingService.hide();
              await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kstock_disponible_votre_magasin')).toPromise();
              const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '23', this.user.CIINT, '');

              if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
                return;
              }

              this.loadingService.show();
              this.authorizedArticleStockMagUser = authorizationResult.data;
            }
          }

          this.editLineItem();
        }
      });
      return;
    }

    this.loadingService.show();
    this.editLineItem();
  }

  async editLineItem() {
    const form_data = this.editItemForm.getRawValue();

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');    
    
    let grand_total_amount = 0;

    let client_data: PanierClient;

    if(this.panier !== undefined && this.panier !== null && this.panier.client !== undefined && this.panier.client !== null) {
      client_data = this.panier.client;
    }
  
    let PRIX_UPV = form_data.PRIX_VENTE;
    let PRIX_PVT = form_data.PRIX_VENTE * form_data.QTY;

    const whole_remise_percent = (this.panier.remise !== undefined && this.panier.remise !== null)?+this.panier.remise:0.0;
    let line_remise_percent = (this.currentItem.remise !== undefined && this.currentItem.remise !== null)?+this.currentItem.remise:0.0;
    let line_remise_prix = 0,
        line_remise_value = 0,        
        total_line_remise = 0,
        line_data_rem = "",
        line_remtot = 0,
        line_subtotal = 0;

    let oldPrix = this.currentItem.montant;
    let newPrix = this.currentItem.montant;

    let itemDataRem = this.currentItem.datarem;
    let itemRemTotal = this.currentItem.remtot;
    let itemRemPercentage = this.currentItem.remise;

    if(this.chosenLineRemise !== undefined && this.chosenLineRemise !== null) {
      if(this.chosenLineRemise.length === 0) {
        // Remise 0
        itemRemPercentage = 0.0;
        itemRemTotal = 0.0;
        itemDataRem = '';

        line_remise_percent = itemRemPercentage;
        line_data_rem = itemDataRem;
      } else {
        let remFormat: string[] = [];
        for(let j=0;j < this.chosenLineRemise.length;j++) {
          let currentRemPercentage = +this.chosenLineRemise[j].REMMTAUX;
          itemRemPercentage += currentRemPercentage;
          itemRemTotal += parseFloat((this.currentItem.prixupv * form_data.QTY).toString()) * (currentRemPercentage / 100);
          remFormat.push(this.chosenLineRemise[j].REMMCODE+'#'+(+currentRemPercentage)+'#'+this.utilService.formatMaskCompat(itemRemTotal));
        }
        itemDataRem = remFormat.join(';');
        
        line_remise_percent = itemRemPercentage;
        line_data_rem = itemDataRem;
      }
    } else {
      itemRemPercentage = this.currentItem.remise;
      itemRemTotal = this.currentItem.remtot;
      itemDataRem = this.currentItem.datarem;

      line_remise_percent = itemRemPercentage;
      line_data_rem = itemDataRem;
    }

    if(line_remise_percent > 0) {
      line_remise_value = form_data.PRIX * (line_remise_percent / 100);  
      line_remise_prix = form_data.PRIX - line_remise_value;

      total_line_remise = line_remise_value * +form_data.QTY;
      line_subtotal = line_remise_prix * +form_data.QTY;

      PRIX_UPV = line_remise_prix;
      PRIX_PVT = line_subtotal;
    }

    if(whole_remise_percent > 0) {
      line_remtot = total_line_remise * (whole_remise_percent / 100);
    }

    let article_data: PanierArticle[] = [];
    if(this.panier !== undefined && this.panier !== null) {    
      article_data = this.panier.articles;
      
      let nextOpt: number = 0;
      article_data.map((_r) => {
        if(_r.opt < 0) {
          nextOpt = _r.opt;
        }
      })
      nextOpt = nextOpt - 1;

      article_data.map((row) => {
        
        if(row.idlig == this.currentItem.idlig && this.currentItem.opt == 0 && (+this.articleData.GROUP_COUNT > 0 && (this.articleGroupsRawData.some((_r: any) => _r.TYPECDE == null || form_data.TYPECDE.indexOf(_r.TYPECDE) > -1)))) {
          this.currentItem.opt = nextOpt;
          row.opt = nextOpt;
        }

        if(this.currentItem.opt < 0 && row.opt == Math.abs(this.currentItem.opt)) { // Same values for Child as Parent if an item has child.
          row.quantite = parseFloat(form_data.QTY.toString());
          row.typecde = form_data.TYPECDE;
          row.montant = parseFloat((row.prixupv * form_data.QTY).toString()); // Row Subtotal - PV Total
        }

        if(row.idlig == this.currentItem.idlig) {

          row.iduser = +this.user.CIINT;
          row.idinstuser = +this.user.IDINSTINT;
          row.libuser = this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':row.libuser;

          row.quantite = parseFloat(form_data.QTY.toString());
          row.prix = parseFloat(form_data.PRIX.toString()); // Article prix
          row.prixforce = form_data.PRIX_FORCE;
          row.motifprixforce = (form_data.MOTIF_PRIX !== null)?form_data.MOTIF_PRIX:"";
          row.commlig1 = (form_data.INFO1 !== null)?form_data.INFO1:"";
          row.commlig2 = (form_data.INFO2 !== null)?form_data.INFO2:"";
          row.typecde = form_data.TYPECDE;
          row.remlig = parseFloat(total_line_remise.toString());
          row.remtot = parseFloat(line_remtot.toString()); // Total remise amount of the row
          row.datafid = this.currentItem.datafid !== null?this.currentItem.datafid:"";
          row.datafidaco = this.currentItem.datafidaco !== null?this.currentItem.datafidaco:"";
          row.dataopeco = this.currentItem.dataopeco !== null?this.currentItem.dataopeco:"";
          row.datarem = line_data_rem;
          row.coinsolde = form_data.COIN_SOLDE;
          row.expo = form_data.EXPO;
          row.excluoffre = form_data.EXCLUSION_COM;
          row.motifcde = this.currentItem.motifcde !== null?this.currentItem.motifcde:'';
          row.numcde = this.currentItem.numcde !== null?this.currentItem.numcde:'';
          row.prixupv = parseFloat(form_data.PRIX_VENTE.toString()); // New Price (Typeable) - PU Ven.
          row.montant = parseFloat((form_data.PRIX_VENTE * form_data.QTY).toString()); // Row Subtotal - PV Total
          row.remise = line_remise_percent;
          row.emplacement = this.currentItem.emplacement !== null?this.currentItem.emplacement:'';
          row.dtmaj = current_date;

          newPrix = row.prixupv;
          
          grand_total_amount += row.montant;
          return row;
        }

        grand_total_amount += row.montant;
        return row;
      });

      if(this.flowType !== 'bonde_commande') {

        const currentOpt = Math.abs(this.currentItem.opt);

        let isBundle = false;
        const backupServiceItems = article_data.filter(rw => {
          const isBundleChild = this.articleGroupsRawData && this.articleGroupsRawData.find(grw => grw.CDART === rw.cdart);
          if (isBundleChild) {
            isBundle = true;
          }
          return rw.opt > 0 && rw.opt == currentOpt && !isBundleChild;
        });

        if(this.currentItem.opt < 0 && (this.articleGroupsRawData && this.articleGroupsRawData.length > 0)) {
          article_data = article_data.filter((row) => {
            return row.opt != currentOpt;
          })
        }

        let currentIdLigne = this.currentItem.idlig;
        let currentItemIndex = this.panier.articles.findIndex((_r) => _r.idlig == this.currentItem.idlig) + 1;

        if(this.currentArticleChosenGroups) {
          this.currentArticleChosenGroups.GROUPS.map((_row_group: any) => {
            let groupPosition = _row_group.PANIER_POSITION;
            if(groupPosition && groupPosition != null) {
              currentIdLigne = groupPosition;
            }
  
            _row_group.GROUP_CHOSEN_OPTIONS.map((_row_option: any) => {
              if(_row_option.SELECTED) {
                
                const oldArticleData = this.panier.articles.find((old_art) => old_art.cdart == _row_option.CDART && old_art.opt == Math.abs(this.currentItem.opt));
  
                let increasableId = null;
                article_data.map((_r) => {
                  if(_r.idlig == currentIdLigne || (increasableId != null && _r.idlig > increasableId)) {
                    increasableId = _r.idlig;
                    _r.idlig = _r.idlig+1;
                  }
                  return _r;
                })
  
                article_data.splice(currentItemIndex, 0, {
                  idlig: currentIdLigne,
                  cdart: _row_option.CDART,
                  quantite: parseFloat(form_data.QTY.toString()),
                  prix: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.prix:parseFloat(_row_option.PRIX.toString()), // Article prix
                  prixforce: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.prixforce:false,
                  motifprixforce: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.motifprixforce:"",
                  commlig1: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.commlig1:"",
                  commlig2: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.commlig2:"",
                  prixachatHT: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.prixachatHT:0.0,
                  prixachatTTC: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.prixachatTTC:0.0,
                  tauxTVA: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.tauxTVA:"", // Optional
                  typecde: this.currentItem.typecde,
                  remlig: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.remlig:0, // Total remise amount of the row
                  remtot: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.remtot:0,
                  datafid: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.datafid:"",
                  datafidaco: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.datafidaco:"",
                  dataopeco: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.dataopeco:"" ,
                  datarem: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.datarem:"",
                  okdiff: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.okdiff:false,
                  bundle: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.bundle:0,
                  opt: currentOpt,
                  lib: _row_option.CALIB,
                  idinstuser: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.idinstuser:+this.user.IDINSTINT,
                  iduser: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.iduser:+this.user.CIINT,
                  libuser: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.libuser:(this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libuser),
                  dtpromesse: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.dtpromesse:current_date, // 2018-03-27 10:04:41
                  coinsolde: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.coinsolde:false,
                  expo: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.expo:false,
                  excluoffre: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.excluoffre:false,
                  motifcde: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.motifcde:'',
                  numcde: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.numcde:'',
                  prixupv: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.prixupv:parseFloat(_row_option.PRIX.toString()), // New Price (Typeable) => PU Ven.
                  montant: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.montant:parseFloat((_row_option.PRIX * _row_option.QTY).toString()), // Row Subtotal - PV Total
                  remise: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.remise:0,
                  emplacement: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.emplacement:"",
                  statut: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.statut:0,
                  dtmaj: (oldArticleData !== undefined && oldArticleData !== null)?oldArticleData.dtmaj:current_date
                });
  
                currentIdLigne++;
                currentItemIndex++;
              }
            })
          })
        }

        if (backupServiceItems.length > 0 && isBundle) {
          backupServiceItems.forEach(serviceItem => {
            article_data.splice(currentItemIndex, 0, {
              ...serviceItem,
              idlig: currentIdLigne
            });

            currentIdLigne++;
            currentItemIndex++;
          })
        }
      }
    }

    let dataRem = "";
    let remTotal = 0;
    let remPercentage = whole_remise_percent;

    if(whole_remise_percent !== undefined && whole_remise_percent !== null && whole_remise_percent > 0) {      
      remTotal = grand_total_amount * (remPercentage / 100);

      dataRem = this.panier.datarem;
    }

    let bondeVenteData = {
      ...this.panier,
      articles: article_data,

      clientlivre : this.panier.clientlivre !== null?this.panier.clientlivre:"",
      clientfacture : this.panier.clientfacture !== null?this.panier.clientfacture:"",
      numfid : this.panier.numfid !== null?this.panier.numfid:"",
      comm : this.panier.comm !== null?this.panier.comm:"",

      idinstusermaj : this.user.IDINSTINT,
      idusermaj : +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libuser,

      userexterne : this.panier.userexterne !== null?this.panier.userexterne:'',

      remtot : remTotal,
      datarem : dataRem,
      dtmaj : current_date,
      montant : grand_total_amount,
      montantHT: grand_total_amount
    };

    let bondeCommandeData: any = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pPanier: bondeVenteData,
      pMotif: '',
      Id_User: +this.user.CIINT,
      Id_InstUser: +this.user.IDINSTINT
    };

    if(client_data !== undefined && client_data !== null) {
      bondeVenteData['client'] = client_data;
    } else {
      bondeVenteData['client'] = "";
    }    

    bondeCommandeData.pPanier = bondeVenteData;

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    const forcePrixMotif = (form_data.MOTIF_PRIX !== null) ? form_data.MOTIF_PRIX_LBL : '';

    if(this.flowType === 'bonde_commande') {
      this.editBonDeCommande(bondeCommandeData).then(
        async() => {
          if (this.currentArticleStockMag && this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
            await this._addMagStockHistory(this.user.CIINT, undefined, false);

            if (this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
              await this._addMagStockHistory(this.authorizedArticleStockMagUser.CIINT);
            }
          }

          if (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) {
            await this._addChangementPrixHistory(this.currentItem.cdart, forcePrixMotif, +this.articleData.calculated_price, form_data.PRIX);
            this.authorizedLinePrixUser = undefined;
          }

          if (this.authorizedModifyItemUser !== undefined && this.authorizedModifyItemUser !== null) {
            await this._addModificationItemHistory(this.currentItem.cdart, +form_data.QTY, +form_data.PRIX);
            this.authorizedModifyItemUser = undefined;
          }

          if((+this.articleData.GROUP_COUNT > 0 && this.articleGroupsRawData.some((_r: any) => _r.TYPECDE == null || this.currentItem.typecde.indexOf(_r.TYPECDE) > -1))) {
            const groupsPopupResult = await this.openUpCommmandeArticleGroups(this.listArticleGroups, this.currentItem, this.articleData, this.panier.typecde);

            if(groupsPopupResult !== undefined && groupsPopupResult !== null && groupsPopupResult.status !== undefined && groupsPopupResult.status === 'success') {
              this.loadingService.show();
              this.currentArticleChosenGroups = groupsPopupResult.data;

              let itemsToBeRemoved = [];

              // To check any old options exists which are unlinked from the currentLineItem group.
              this.panier.articles.map(
                (row) => {
                  if(row.opt != 0 && row.opt == Math.abs(this.currentItem.opt) && this.articleGroupsRawData.every((gr) => gr.CDART != row.cdart)) {
                    itemsToBeRemoved.push(row.idlig);
                  }
                }
              );

              for (const _row_group of this.currentArticleChosenGroups.GROUPS) {
                for (const _row_option of _row_group.GROUP_CHOSEN_OPTIONS) {
                  if(!_row_option.SELECTED) {
                    let oldTypeOptionExists = this.panier.articles.find((_r_pan_art) => (_r_pan_art.opt == Math.abs(this.currentItem.opt) && _r_pan_art.cdart == _row_option.CDART));
                    if(oldTypeOptionExists !== undefined && oldTypeOptionExists !== null) {
                      itemsToBeRemoved.push(oldTypeOptionExists.idlig);
                    }
                  }
                }
              }

              if(itemsToBeRemoved !== undefined && itemsToBeRemoved !== null && itemsToBeRemoved.length > 0) {
                let removeLinebondeCommandeData = {
                  pMag: this.panier.mag,
                  pNumBon: this.bonNumBon,
                  pLignes: itemsToBeRemoved.join(';'),
                  pMotif: '',
                  Id_User: +this.user.CIINT,
                  Id_InstUser: +this.user.IDINSTINT
                };

                await this.deleteBonDeCommandeLigne(removeLinebondeCommandeData, false);
              }

              let position = this.currentItem.idlig + 1;
              let prevGroup = undefined;

              for (const _row_group of this.currentArticleChosenGroups.GROUPS) {
                let groupPosition = _row_group.PANIER_POSITION;
                let groupChosenCount = _row_group.CHOSEN_COUNT;
                let groupMax = _row_group.CHOOSE_MAX;

                let nextOpt: number = this.panier.articles.find(__r => __r.idlig == this.currentItem.idlig)?.opt;
                if(this.currentItem.opt == 0) {
                  this.panier.articles.map((_r) => {
                    if(_r.opt < 0) {
                      nextOpt = _r.opt;
                    }

                    return _r;
                  })
                  nextOpt = nextOpt - 1;
                }

                for (const _r of this.panier.articles) {
                  if(_r.opt == Math.abs(nextOpt)) {
                    position = _r.idlig + 1;
                  }
                }

                if(prevGroup !== undefined && (groupPosition === undefined || groupPosition === null)) {
                  groupPosition = prevGroup.PANIER_POSITION + 1;
                }
  
                if(groupPosition !== undefined && groupPosition !== null) {
                  position = groupPosition;
                }

                for (const _row_option of _row_group.GROUP_CHOSEN_OPTIONS) {
                  if(_row_option.SELECTED) {
                    let checkIfExists = this.panier.articles.find((_r) => _r.cdart == _row_option.CDART && _r.opt == Math.abs(nextOpt));
                    if(checkIfExists === undefined || checkIfExists === null) {
                      await this._newCommandeLineOptionsInsertion(position, _row_option, this.currentItem, nextOpt);
                      this.currentArticleChosenGroups.GROUPS.map(
                        (_r_gr: any) => {
                          if(_r_gr.PANIER_POSITION !== undefined && _r_gr.PANIER_POSITION !== null) {
                            _r_gr.PANIER_POSITION = +(_r_gr.PANIER_POSITION) + 1;
                          }
  
                          return _r_gr;
                      });
                      position = position + 1 // next to the last idligne from corresponding group options
                    } else {
                      position = checkIfExists.idlig + 1 // next to the last idligne from corresponding panier article
                    }
                  }
                }

                prevGroup = _row_group;
              }
              
              this.__updateBonDeCommandeList();
              this.loadingService.hide();
              this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});                
            } else {
              this.loadingService.hide();
              this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});  
            }
          } else {
            this.loadingService.hide();
            this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});
          }
        }
      );
      return;
    }

    if(this.flowType === 'devis') {
      let devisData = {
        pMag: this.panier.mag,
        pNumBon: this.bonNumBon,
        pPanier: bondeVenteData,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST
      };

      this.editDevis(devisData).then(
        async () => {
          if(this.chosenLineRemise !== undefined && this.chosenLineRemise !== null) {
            try {
              this._addRemiseHistory(oldPrix, this.currentItem.prixupv);
            } catch(err) {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
          }

          if (this.authorizedModifyItemUser !== undefined && this.authorizedModifyItemUser !== null) {
            await this._addModificationItemHistory(this.currentItem.cdart, +form_data.QTY, +form_data.PRIX);
            this.authorizedModifyItemUser = undefined;
          }

          if (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) {
            await this._addChangementPrixHistory(this.currentItem.cdart, forcePrixMotif, +this.articleData.calculated_price, form_data.PRIX);
            this.authorizedLinePrixUser = undefined;
          }
        }
      );
      return;
    }

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData).then(
      async () => {
        if (this.currentArticleStockMag && this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
          await this._addMagStockHistory(this.user.CIINT, undefined, false);

          if (this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
            await this._addMagStockHistory(this.authorizedArticleStockMagUser.CIINT);
          }
        }

        if (this.authorizedModifyItemUser !== undefined && this.authorizedModifyItemUser !== null) {
          await this._addModificationItemHistory(this.currentItem.cdart, +form_data.QTY, +form_data.PRIX);
          this.authorizedModifyItemUser = undefined;
        }

        if(this.chosenLineRemise !== undefined && this.chosenLineRemise !== null) {
          try {
            this._addRemiseHistory(oldPrix, this.currentItem.prixupv);
          } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        }

        if (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) {
          await this._addChangementPrixHistory(this.currentItem.cdart, forcePrixMotif, +this.articleData.calculated_price, form_data.PRIX);
          this.authorizedLinePrixUser = undefined;
        }
      }
    );
  }

  _addRemiseHistory(oldPrix: number, newPrix: number): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REMISE_EN_LIGNE,
      NUMBON: this.bonNumBon,
      ARTEAN : this.currentItem.cdart,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedLineRemiseUser !== undefined && this.authorizedLineRemiseUser !== null)?this.authorizedLineRemiseUser.CIINT:this.user.CIINT,
      VALUER1: oldPrix,
      VALUER2: newPrix
    });
  }

  _addMagStockHistory(CIINT: string, CDART?: any, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART ? CDART : this.currentItem.cdart,
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: null,
      VALUER2: null,
    }, HISTORY_AUTHORIZATION);
  }

  _addChangementPrixHistory(CDART: string, MOTIF: string, oldPrix: number, newPrix: number): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_CHANGEMENT_DE_PRIX,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) ? this.authorizedLinePrixUser.CIINT : this.user.CIINT,
      MOTIF: MOTIF,
      VALUER1: oldPrix,
      VALUER2: newPrix,
    });
  }

  _addModificationItemHistory(CDART: string, QTY: number, PRIX: number): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_MODIFY_ARTICLES_BON_AUTRE_VENDEUR,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedModifyItemUser !== undefined && this.authorizedModifyItemUser !== null) ? this.authorizedModifyItemUser.CIINT : this.user.CIINT,
      VALUER1: QTY,
      VALUER2: PRIX,
    });
  }

  async _addAutreArticleLinkHistory(autreCDART: string, autreBON: string, currentCDART, unlink: boolean = false, historyData?: any) {
    let ARTEAN = '';
    let MOTIF = '';

    if (unlink) {
      ARTEAN = 'idem';
      MOTIF = 'idem';

      if(historyData?.ARTEAN && historyData?.MOTIF) {
        ARTEAN = historyData?.ARTEAN;
        MOTIF = historyData?.MOTIF;
      }
    } else {
      ARTEAN = currentCDART;
      MOTIF = `“Bon lié N° : ${autreBON} ; Article : ${autreCDART}`;
    }

    return await this.historyService.addHistory({
      NumBon: this.bonNumBon,
      mag: this.USERCMINT,
      iduser: this.user.CIINT,
      ARTEAN,
      MOTIF,
      OPERAT : unlink ? this.translateService.instant('kunlink_autre_article') : this.translateService.instant('klink_autre_article'),
      VALUER1: null,
      VALUER2: null,
    });
  }

  onQTYChanged(event: any) {
    this.rowTotalCalculation();
  }

  onQuantityFocused() {
    if(this.articleData === undefined || this.articleData === null) return;

    if(this.articleData.CAUNVTE === 'M3') {
      this.openUpCalculateQty().then((result) => {
        if(result && result.status === "success") {
          const data = result.data;

          const length = data.length?data.length:0;
          const width = data.width?data.width:0;
          const height = data.height?data.height:0;

          this.qtyLength = length;
          this.qtyWidth = width;
          this.qtyHeight = height;

          const oldDescription = this.editItemForm.get('INFO1').value;
          let description = `L ${length} x l ${width} x h ${height}`;

          if(oldDescription !== '' && oldDescription !== null) {
            description = `${oldDescription} | ${description}`;
          }

          const totalQty = length * width * height;

          if(this.quantity5Decimal) {
            this.editItemForm.patchValue({
              QTY: this.utilService.formatQteMaskCompat(totalQty, this.quantityMask.scale === 0 ? '0.0' : '0.5'),
              INFO1: description
            });
          } else {
            this.editItemForm.patchValue({
              QTY: this.utilService.formatQteMaskCompat(totalQty, this.quantityMask.scale === 0 ? '0.0' : '0.2'),
              INFO1: description
            });
          }

          this.rowTotalCalculation();
        }
      })
    }
  }

  openUpCalculateQty(): Promise<any> {
    const dialogRef = this.dialog.open(CalculateQuantityComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.panier,
          currentItemFormData: this.editItemForm.getRawValue(),
          currentArticle: this.articleData,
          dimensions: {
            length: this.qtyLength,
            width: this.qtyWidth,
            height: this.qtyHeight,
          }
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  quantityChange(action){    
    this.articleQty.nativeElement.setAttribute('inputmode', 'none');
    let qty = Number(this.editItemForm.get('QTY').value);
    if(action === 'increment'){
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }    
    this.editItemForm.get('QTY').setValue(qty.toString());
    setTimeout(() => {
      this.articleQty.nativeElement.setAttribute('inputmode', 'decimal');
    }, 50);
    this.rowTotalCalculation(); 
  }

  get itemPrix(): AbstractControl {
    return this.editItemForm.get('PRIX');
  }

  async onPriceChanged(event: any) {
    if(!this.PU_vente_changable) {
      this.authorizedLinePrixUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '17', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        event.preventDefault();
        return;
      }
      
      this.authorizedLinePrixUser = authorizationResult.data;
      this.PU_vente_changable = true;
    }

    this.rowTotalCalculation();

    // While forcing prix, make a copy of it into PRIX form control
    let entered_prix = this.editItemForm.get('PRIX_VENTE').value;
    this.itemPrix.setValue(entered_prix);
  }

  rowTotalCalculation() {
    const price = this.editItemForm.get('PRIX_VENTE').value;
    const qty = this.editItemForm.get('QTY').value;

    if(isNaN(price) || isNaN(qty)) {
      this.editItemForm.patchValue({
        PRIX_TOTAL: '0'
      });
      return;
    }

    const total_price = price * qty;

    this.editItemForm.patchValue({
      PRIX_TOTAL: total_price.toString(),
    });
  }

  async openUpRemiseList(type?: number, totalAmount?: number) {
    if(!this.panier) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }


    if(!this.userPermission['0_1']) {
      this.authorizedLineRemiseUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '1', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedLineRemiseUser = authorizationResult.data;
    }

    const dialogRef = this.dialog.open(RemiseListComponent,
      {
        data: {
          remiseType: type !== undefined?type:2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined?totalAmount:0, // Total amount to calculate discount
          previousChosenRemise: type !== undefined?(type === 1?this.chosenLineRemise:this.chosenRemise):this.chosenRemise,
          panier: this.panier,
          line_item: this.currentItem,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {        
        this.chosenLineRemise = [...result.data];

        let remPercentage = this.currentItem.remise;
        let prixVente = this.currentItem.prixupv;
        const prixCaisse = this.editItemForm.get('PRIX').value;
        if(this.chosenLineRemise !== undefined && this.chosenLineRemise !== null) {
          if(this.chosenLineRemise.length === 0) {
            remPercentage = 0;
            prixVente = prixCaisse;
          } else {
            remPercentage = 0;
            for(let j=0;j < this.chosenLineRemise.length;j++) {
              let currentRemPercentage = +this.chosenLineRemise[j].REMMTAUX;
              remPercentage += currentRemPercentage;
            }

            let remiseAmount = (prixCaisse * remPercentage) / 100;
            prixVente = prixCaisse - remiseAmount;
          }
        }
        
        this.editItemForm.get('PRIX_VENTE').setValue(this.utilService.formatMaskCompat(prixVente));
        this.editItemForm.get("REMTOT").setValue(this.utilService.formatMaskCompat(remPercentage));

        this.rowTotalCalculation();
      }      
    });
  }

  openUpAddedRemiseList(type?: number, totalAmount?: number, item?: PanierArticle) {
    if(!this.panier) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const dialogRef = this.dialog.open(RemiseDetailComponent,
      {
        data: {
          remiseType: type !== undefined?type:2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined?totalAmount:0, // Total amount to calculate discount
          chosenRemise: type !== undefined?(type === 1?this.chosenLineRemise:this.chosenRemise):this.chosenRemise,
          panier: this.panier,
          panier_article: item,
          USERCMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
              
      }      
    });
  }

  searchArticleGroupsCAINT(CAINT: any): Promise<any> {
    return new Promise((resolve, reject) => {

      let CMINT = this.USERCMINT;
      if (this.panier.magstock) {
        CMINT = this.panier.magstock;
      }

      this.bonVenteService.getArticleGroupsByCAINT(CAINT, CMINT, this.user.CIINT, this.USERCMINT).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            if(res.data.length > 0) {

              let groupsData = res.data;
              this.articleGroupsRawData = groupsData;
              this.listArticleGroups = [];
  
              if(groupsData !== undefined && groupsData != null && groupsData.length > 0) {
                groupsData.map((row: any) => {
                  let groupAvail = this.listArticleGroups.find((_row) => _row.group_id == row.id);
    
                  if(groupAvail === undefined || groupAvail === null) {
                    this.listArticleGroups.push({
                      group_id: row.id,
                      group_label: row.label,
                      group_min: row.selectable_qtymin,
                      group_max: row.selectable_qty,
                      group_custom: row.custom,
                      group_type: row.TYPECDE,
                      group_deposit: row.DEPOSIT,
                      group_active: row.ACTIVE,
                      group_position: row.grouppos,
                      group_articles: [row]
                    });
                  } else {
                    this.listArticleGroups.map((_row) => {
                      if(_row.group_id == row.id) {
                        _row.group_articles.push(row);
                      }
                    });                
                  }              
                });
              }                      
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
            }   
            resolve(res);
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            resolve(res);
          }      
        },
        (err) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    })
  }

  checkArticleParentChildStatus(payload: string[][]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.checkArticleParentChildStatus(payload).subscribe(
          (res: any) => {
            if(res.success !== undefined) {
              if(res.data.length > 0) {
                this.linkedStatus = 'bundle';
              } else {
                this.linkedStatus = 'service';
              }
              resolve(res);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
              resolve(res);
            }
          },
          (err) => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    })
  }

  openUpArticleGroups(listGroups: any, callback: Function) {
    const dialogRef = this.dialog.open(VenteArticleGroupsComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.panier,
          listArticleGroups: listGroups,
          currentModifyItemFormData: this.editItemForm.getRawValue(),
          currentItem: this.currentItem,
          currentArticle: this.articleData,
          flowType: 'edit',
          typeDevis: false
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        callback(result);
      }      
    });
  }

  openUpCommmandeArticleGroups(listGroups: any, currentPanierArticle: PanierArticle, currentArticle: Article, TYPECDE: string): Promise<any> {
    const dialogRef = this.dialog.open(VenteArticleGroupsComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.panier,
          listArticleGroups: listGroups,
          currentItemFormData: undefined,
          TYPECDE: TYPECDE,
          flowType: 'edit',
          pageType: 'commande', // commande, others
          currentItem: currentPanierArticle,
          currentArticle: currentArticle
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  openUpPrixForceMotif() {
    const dialogRef = this.dialog.open(MotifForcePrixComponent,
      {
        data: {
          item: this.currentItem,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(result.data.force_motif !== '') {
            this.editItemForm.get('PRIX_FORCE').setValue(true);
            this.editItemForm.get('MOTIF_PRIX').setValue(result.data.force_motif);
            this.editItemForm.get('MOTIF_PRIX_LBL').setValue(result.data.MOTIF_LABEL);

            const TYPECDE = this.editItemForm.get('TYPECDE').value;

            if(this.flowType !== 'bonde_commande' && (+this.articleData.GROUP_COUNT > 0 && (this.articleGroupsRawData.some((_r: any) => _r.TYPECDE == null || TYPECDE.indexOf(_r.TYPECDE) > -1)))) {
              this.openUpArticleGroups(this.listArticleGroups, (res: any) => {
                if(res.status === 'success') {
                  this.currentArticleChosenGroups = res.data;
                  this.editLineItem();
                }
              });
              return;
            }

            this.loadingService.show();
            this.editLineItem();
          } else {
            this.editItemForm.get('PRIX_FORCE').setValue(false);
            this.editItemForm.get('MOTIF_PRIX').setValue('');
            this.editItemForm.get('MOTIF_PRIX_LBL').setValue('');

            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonprixoblig'));
          }          
      }
    });
  }

  openUpDetailStock() {
    const dialogRef = this.dialog.open(ArticleStockComponent,
      {
        disableClose: true,
        minWidth: "50vw",
        autoFocus: false,
        data: {
          NUMBON: this.bonNumBon,
          PANIER: this.panier,
          articleData: this.articleData,
          CAINT: this.articleData.CAINT,
          stockBlocageEditable: true,
          bonVenteFlow: true,
          currentStockMag: this.currentArticleStockMag,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if (!this.currentArticleStockMag && result.STOCK_DISPO !== undefined && result.STOCK_DISPO !== null) {
            this.articleData.STOCKDISPO = result.STOCK_DISPO;
            this.editItemForm.get('STOCK_DISPO').setValue(this.decimalPipe.transform(result.STOCK_DISPO));
          }
      }
    });
  }  

  editBonDeVente(bondeVenteData: any, type?: 'update' | 'delete', closable: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.modifyBonvente(bondeVenteData).subscribe(
        (resp) => {
          this.loadingService.hide();
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              // List update after a success response
              const oldListData = this.storageService.getItem('bonVenteListData');
              const oldNumBon = this.bonNumBon;
              // ------------------------------
              
              this.bonNumBon = resp.data.NumBon;
              this.panier = resp.data.Panier;
  
              if(type === undefined || type !== 'delete') {
                this.currentItem = this.panier.articles.find((row) => row.idlig == this.currentItem.idlig);
                this.articleData = resp.data.customArticles.find((row: Article) => row.CDART == this.currentItem.cdart);
              } else {
                this.currentItem = undefined;
                this.articleData = undefined;
              }
  
              this.storageService.setItem('previousBonPanier', this.panier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);
  
              // List update after a success response
              if(oldListData !== undefined && oldListData !== null) {
                oldListData.data.map(
                  (row: BonVente) => {
                    if(row.NUMCOM == oldNumBon) {
                      row.TRAISOC = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                      row.TPRENOM = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                      row.TADR3 = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TADR3:null;
                      row.CODPOS = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                      row.TVILLE = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                      row.TTEL = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                      row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                      row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                      row.ENDMOD = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('YYYY-MM-DD HH:mm:ss');
                      
                      row.MTHT = this.panier.montantHT;                    
                      row.MTTTC = this.panier.montant;
                      row.MTTVA = (this.panier.montant - this.panier.montantHT);
                      
                      row.CLINUM = (this.panier.client !== undefined && this.panier.client !== null)?this.panier.client.idcli.toString():"0";
                      row.CLILIVNUM = (this.panier.clientlivre !== undefined && this.panier.clientlivre !== null)?this.panier.clientlivre.idcli.toString():"0";
                      row.CLIFACNUM = (this.panier.clientfacture !== undefined && this.panier.clientfacture !== null)?this.panier.clientfacture.idcli.toString():"0";                    
                      
                      let cumulativeTypeCDE = '';
                      let _typeCdeArr = this.panier.articles.map((row_art) => row_art.typecde);
                      
                      _typeCdeArr = _typeCdeArr.filter(function(elem, index, self) {
                        return index === self.indexOf(elem);
                      })
  
                      cumulativeTypeCDE = _typeCdeArr.join(',');
  
                      row.TYPECDE = cumulativeTypeCDE;
  
                      row.NUMCOM = this.bonNumBon;
  
                      return row;
                    }
  
                    return row;
                  }
                );
  
                const updated_bonvente = oldListData.data.find(
                  (row: BonVente) => row.NUMCOM == this.bonNumBon
                );
  
                let updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
                updated_row_removed.unshift(updated_bonvente);
  
                oldListData.data = updated_row_removed;
  
                this.storageService.setItem('bonVenteListData', oldListData);
              }
              // ------------------------------
  
              if(closable) {
                this.editItemForm.patchValue({
                  CDART: '',
                  CALIB: '',
                  QTY: '1',
                  PRIX: '',
                  STOCK_DISPO: '',
                  PRIX_VENTE: '',
                  PRIX_TOTAL: '1',
                  TYPECDE: '',
                  REMTOT: this.utilService.formatMaskCompat(1),
                  EXPO: false,
                  COIN_SOLDE: false,
                  INFO1: '',
                  INFO2: '',
    
                  PRIX_FORCE: false,
                  MOTIF_PRIX: ''
                });
  
                this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier, customArticles: resp.data.customArticles, customClient: resp?.data?.customClient});
                resolve(resp);
              } else {
                if(type === undefined || type !== 'delete') {

                  if(this.quantity5Decimal) {
                    this.editItemForm.patchValue({
                      QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite, this.quantityMask.scale === 0 ? '0.0' : '0.5')
                    });
                  } else {
                    this.editItemForm.patchValue({
                      QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite, this.quantityMask.scale === 0 ? '0.0' : '0.2')
                    });
                  }

                  this.editItemForm.patchValue({
                    CDART: this.currentItem.cdart,
                    CALIB: this.currentItem.lib,
                    PRIX: (+this.currentItem.prix).toString(),
                    STOCK_DISPO: (this.articleData.STOCKDISPO !== null)?this.decimalPipe.transform(this.articleData.STOCKDISPO):this.decimalPipe.transform(0),
                    PRIX_VENTE: this.utilService.formatMaskCompat(this.currentItem.prixupv),
                    PRIX_TOTAL: (this.currentItem.montant).toString(),
                    TYPECDE: this.currentItem.typecde,
                    REMTOT: this.utilService.formatMaskCompat(this.currentItem.remise),
                    EXPO: this.currentItem.expo?true:false,
                    COIN_SOLDE: this.currentItem.coinsolde?true:false,
                    INFO1: this.currentItem.commlig1,
                    INFO2: this.currentItem.commlig2,
              
                    PRIX_FORCE: this.currentItem.prixforce,
                    MOTIF_PRIX: this.currentItem.motifprixforce,
                  });
                }
                resolve(resp);
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              resolve(resp);
            }      
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            resolve(resp);
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });
  }

  editDevis(devisData: any, type?: 'update' | 'delete', closable: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deivsService.modifyDevis(devisData).subscribe(
        (resp) => {
          this.loadingService.hide();        
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              // List update after a success response
              const oldListData = this.storageService.getItem('devisListData');
              const oldNumBon = this.bonNumBon;
              // ------------------------------
              
              this.bonNumBon = resp.data.NumBon;
              this.panier = resp.data.Panier;
  
              if(type === undefined || type !== 'delete') {
                this.currentItem = this.panier.articles.find((row) => row.idlig == this.currentItem.idlig);
                this.articleData = resp.data.customArticles.find((row: Article) => row.CDART == this.currentItem.cdart);
              } else {
                this.currentItem = undefined;
                this.articleData = undefined;
              }
  
              this.storageService.setItem('previousBonDevis', this.panier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);
  
              // List update after a success response
              if(oldListData !== undefined && oldListData !== null) {
                oldListData.data.Bons.map(
                  (row: any) => {
                    if(row.numbon == oldNumBon) {
                      row.civlib = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CIVLIB:null;
                      row.nom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                      row.prenom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                      row.cp = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                      row.ville = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                      row.tel = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                      row.gsm = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
  
                      row.statut = this.panier.statut;
                      row.typecde = this.panier.typecde;
                      row.livdeport = this.panier.livdeport;
                      row.livdirect = this.panier.livdirect;
                      row.numexterne = this.panier.numexterne;
                      
                      row.montant = this.panier.montant;
                      row.dtsaisie = this.panier.dtsaisie;
                      row.dtmaj = this.panier.dtmaj;
  
                      return row;
                    }
  
                    return row;
                  }
                );
  
                const updated_devis = oldListData.data.Bons.find(
                  (row: any) => row.numbon == this.bonNumBon
                );
  
                let updated_row_removed: BonVente[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
                updated_row_removed.unshift(updated_devis);
  
                oldListData.data.Bons = updated_row_removed;
  
                this.storageService.setItem('devisListData', oldListData);
              }
              // ------------------------------
  
              if(closable) {
                this.editItemForm.patchValue({
                  CDART: '',
                  CALIB: '',
                  QTY: '1',
                  PRIX: '',
                  STOCK_DISPO: '',
                  PRIX_VENTE: '',
                  PRIX_TOTAL: '1',
                  TYPECDE: '',
                  REMTOT: this.utilService.formatMaskCompat(1),
                  EXPO: false,
                  COIN_SOLDE: false,
                  INFO1: '',
                  INFO2: '',
    
                  PRIX_FORCE: false,
                  MOTIF_PRIX: ''
                });
  
                this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier, customArticles: resp.data.customArticles});
                resolve(resp);
              } else {
                if(type === undefined || type !== 'delete') {

                  if(this.quantity5Decimal) {
                    this.editItemForm.patchValue({
                      QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite, this.quantityMask.scale === 0 ? '0.0' : '0.5')
                    });
                  } else {
                    this.editItemForm.patchValue({
                      QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite, this.quantityMask.scale === 0 ? '0.0' : '0.2')
                    });
                  }

                  this.editItemForm.patchValue({
                    CDART: this.currentItem.cdart,
                    CALIB: this.currentItem.lib,
                    PRIX: (+this.currentItem.prix).toString(),
                    STOCK_DISPO: (this.articleData.STOCKDISPO !== null)?this.decimalPipe.transform(this.articleData.STOCKDISPO):this.decimalPipe.transform(0),
                    PRIX_VENTE: this.utilService.formatMaskCompat(this.currentItem.prixupv),
                    PRIX_TOTAL: (this.currentItem.montant).toString(),
                    TYPECDE: this.currentItem.typecde,
                    REMTOT: this.utilService.formatMaskCompat(this.currentItem.remise),
                    EXPO: this.currentItem.expo?true:false,
                    COIN_SOLDE: this.currentItem.coinsolde?true:false,
                    INFO1: this.currentItem.commlig1,
                    INFO2: this.currentItem.commlig2,

                    PRIX_FORCE: this.currentItem.prixforce,
                    MOTIF_PRIX: this.currentItem.motifprixforce,
                  });
                }
                resolve(resp);
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              resolve(resp);
            }      
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            resolve(resp);
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    })
  }

  editBonDeCommande(bonCommandeData: any) {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyLigneBonCommande(bonCommandeData).subscribe(
        (resp) => {
          this.loadingService.hide();
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.panier = resp.data.Panier;

              this.__updateBonDeCommandeList();

              resolve({ response: resp });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              reject({ response: resp });
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            resolve({ response: resp });
          }
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    });     
  }

  __updateBonDeCommandeList() {
    // List update after a success response
    const oldListData = this.storageService.getItem('bonCommandeListData');
    if(oldListData !== undefined && oldListData !== null && oldListData.data != null) {
      oldListData.data.Bons = oldListData.data.Bons.filter((_r: any) => _r !== null && _r != 'null');
      oldListData.data.Bons.map(
        (row: any) => {
          if(row.numbon == this.bonNumBon) {
            row.montant = this.panier.montant;
            row.dtrel = this.panier.dtrel;
            row.typecde = this.panier.typecde;
            row.dtech = this.panier.dtech;
            row.statut = this.panier.statut;
            row.dtmaj = this.panier.dtmaj;
          }
          return row;
        }
      );

      const updatedCommande = oldListData.data.Bons.find(
        (row: any) => row.numbon == this.bonNumBon
      );

      let updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
      updated_row_removed.unshift(updatedCommande);

      oldListData.data.Bons = updated_row_removed;

      this.storageService.setItem('bonCommandeListData', oldListData);
    }
  }

  supprimerLineItem() {
    if(this.panier === undefined || this.panier === null) {      
      return;  
    }

    if(this.flowType === 'bonde_commande') {
      this.deleteCommandeConfirmation();
      return;
    }

    this.loadingService.show();    
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let article_data: PanierArticle[] = [...this.panier.articles];

    if (this.linkedStatus === 'service' && this.currentItem.opt > 0) {
      const otherServiceChildren = article_data.filter((rw) => rw.idlig !== this.currentItem.idlig && rw.opt === this.currentItem.opt);

      if (otherServiceChildren.length === 0) {
        article_data.map(rw => {
          if (rw.opt === (-this.currentItem.opt)) {
            rw.opt = 0;
          }

          return rw;
        })
      }
    }

    this.checkArticleIsLinkedToAutreBon().then(res => {
      if(res) {
        // Add history trace for removing non-service article.
        this._addAutreArticleLinkHistory('', '', '', true, this.isLinkedToAutreArticleHistory)
      }
    })

    article_data = this.panier.articles.filter(
      (item) => {
        if(this.currentItem.opt < 0 && item.opt == Math.abs(this.currentItem.opt)) {
          return false;
        }

        return item.idlig != this.currentItem.idlig;
      }
    );    

    let bondeVenteData = {
      ...this.panier,
      articles: article_data,    
      dtmaj : current_date
    };

    if (article_data.length === 0 && this.flowType === 'bonde_vente') {
      // To reset the magstock (Autre Stock magasin)
      // when all the articles from the bon has been removed.
      bondeVenteData.magstock = 0;
    }

    if(this.flowType === 'devis') {
      let devisData = {
        pMag: this.panier.mag,
        pNumBon: this.bonNumBon,
        pPanier: bondeVenteData,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST
      };

      this.editDevis(devisData, 'delete');
      return;
    }

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData, 'delete');
  }

  async checkArticleIsLinkedToAutreBon() {
    await this.getHistoryLists();

    return (this.isLinkedToAutreArticleHistory && this.isLinkedToAutreArticleHistory?.ARTEAN) && this.isLinkedToAutreArticleHistory.ARTEAN === this.currentItem.cdart;
  }

  async getHistoryLists() {
    return new Promise((resolve, reject) => {

      this.bonVenteService.getBonVenteHistorique(this.bonNumBon, this.panier.mag).subscribe(
        (response) => {
            if(response.success !== undefined && response?.data?.length > 0) {
              const autreLinkedTraces = response.data.filter(item => item.OPERAT.includes(this.translateService.instant('klink_autre_article')));
              this.isLinkedToAutreArticleHistory = autreLinkedTraces.find(item => item.ARTEAN === this.currentItem.cdart)
            }
            resolve(response);
        }, error => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(error);
        }
      );
    });
  }

  openUpNonServiceArticleSelection() {
    const dialogRef = this.dialog.open(NonServiceArticlesListComponent, {
      width: '40vw',
      maxWidth: '40vw',
      maxHeight: '80vh',
      data: {
        currentItem: this.currentItem,
        nonServiceArticles: this.listNonServiceArticles,
        nonServiceItems: this.listNonServiceItems,
        nonServiceArticleHashTable: this.cdartArticlesHashTable,
        panier: this.panier,
        isDevis: this.isDevis,
        isBonDeVente: this.isBonDeVente,
        isBonDeCommande: this.isBonDeCommande,
        bonNumBon: this.bonNumBon,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        
        if (result.data.CHOSEN_IDLIG !== undefined && result.data.CHOSEN_IDLIG !== null) {
          const chosenLineId = result.data.CHOSEN_IDLIG;
          
          const serviceItem = this.currentItem; // Service
          const nonServiceItem = this.listNonServiceItems.find(rw => rw.idlig == chosenLineId); // Non service
          
          this._linkNonServiceToService(serviceItem, nonServiceItem);
        } else {
          // Add History record for adding non service article from another bon-de-commande
          if (result?.data?.NUMBON && result?.data?.AUTRE_CHOSEN_CDART) {
            this._addAutreArticleLinkHistory(result.data.AUTRE_CHOSEN_CDART, result.data.NUMBON, this.currentItem.cdart)

            this.editItemFormSubmitter()
          }
        }
      }
    });
  }

  async _linkNonServiceToService(serviceItem: PanierArticle, nonServiceItem: PanierArticle) {
    if (nonServiceItem === undefined || nonServiceItem === null) {
      return;
    }

    this.loadingService.show();

    let article_data: PanierArticle[] = [...this.panier.articles];

    let currentIdLigne = 1;
    let nextOpt = 0;
    article_data.forEach((_r) => {
      if(_r.opt < 0) {
        nextOpt = _r.opt;
      }
    });
    nextOpt = nextOpt - 1;

    currentIdLigne = nonServiceItem.idlig;
    if (nonServiceItem.opt < 0) {
      // If it's already a parent.
      nextOpt = nonServiceItem.opt;

      const filteredChildren = [...article_data]
          .filter(rw => rw.opt === Math.abs(nonServiceItem.opt))
          .map(rw => rw.idlig);

      if (filteredChildren.length > 0) {
        currentIdLigne = Math.max(...filteredChildren);
      }
    }

    const QTE = nonServiceItem.quantite;
    let PRIX_UPV = serviceItem.prixupv;
    let PRIX_PVT = PRIX_UPV * QTE;

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const whole_remise_percent = (this.panier.remise !== undefined && this.panier.remise !== null) ? +this.panier.remise : 0.0;
    let line_remise_percent = (this.currentItem.remise !== undefined && this.currentItem.remise !== null)?+this.currentItem.remise:0.0;
    let line_remise_prix = 0,
        line_remise_value = 0,
        total_line_remise = 0,
        line_remtot = 0,
        line_subtotal = 0;

    const itemRemPercentage = this.currentItem.remise;

    line_remise_percent = itemRemPercentage;

    if(line_remise_percent > 0) {
      line_remise_value = serviceItem.prix * (line_remise_percent / 100);
      line_remise_prix = serviceItem.prix - line_remise_value;

      total_line_remise = line_remise_value * +QTE;
      line_subtotal = line_remise_prix * +QTE;

      PRIX_UPV = line_remise_prix;
      PRIX_PVT = line_subtotal;
    }

    if(whole_remise_percent > 0) {
      line_remtot = total_line_remise * (whole_remise_percent / 100);
    }

    const serviceChildItem = {
      ...serviceItem,
      opt: Math.abs(nextOpt),
      quantite: QTE,
      typecde: nonServiceItem.typecde,
      prixupv: PRIX_UPV,
      montant: PRIX_PVT,

      remise: line_remise_percent,
      remlig: parseFloat(total_line_remise.toString()),
      remtot: parseFloat(line_remtot.toString()), // Total remise amount of the row

      idusermaj: +this.user.CIINT,
      idinstusermaj: +this.user.IDINSTINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : serviceItem.libuser,
      dtmaj: current_date
    };

    let grand_total_amount = 0;

    // Removing the service item from the article_data array
    article_data.splice(article_data.findIndex(rw => rw.idlig === serviceItem.idlig), 1);

    // Inserting the service item into the article_data array in a specific position
    article_data.splice(article_data.findIndex(rw => rw.idlig === currentIdLigne) + 1, 0, serviceChildItem);

    article_data = article_data.map((row) => {
      if (row.idlig === nonServiceItem.idlig) {
        // Update the `opt` only to the chosen Non Service article
        row.opt = nextOpt;

        row.dtmaj = current_date;
        row.idusermaj = +this.user.CIINT;
        row.idinstusermaj = +this.user.IDINSTINT;
        row.libusermaj = this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : row.libusermaj;
      }

      grand_total_amount += row.montant;

      return row;
    });

    let dataRem = "";
    let remTotal = 0;
    const remPercentage = whole_remise_percent;

    if(whole_remise_percent !== undefined && whole_remise_percent !== null && whole_remise_percent > 0) {
      remTotal = grand_total_amount * (remPercentage / 100);

      dataRem = this.panier.datarem;
      remTotal = this.panier.remtot;
    }

    const bondeVenteData = {
      ...this.panier,
      articles: article_data,

      idinstusermaj : this.user.IDINSTINT,
      idusermaj : +this.user.CIINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.panier.libuser,

      remtot : remTotal,
      datarem : dataRem,
      montant : grand_total_amount,
      montantHT: grand_total_amount,
      dtmaj : current_date,
    };

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    if (this.flowType === 'devis') {
      const devisData = {
        pMag: this.panier.mag,
        pNumBon: this.bonNumBon,
        pPanier: bondeVenteData,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST
      };

      await this.editDevis(devisData);
    }

    if (this.flowType === 'bonde_vente') {
        bondeVenteData['Id_User'] = this.user.CIINT;
        bondeVenteData['Id_InstUser'] = Constants.CIINST;

        await this.editBonDeVente(bondeVenteData);
    }

    if (this.flowType === 'bonde_commande') {
      let client_data: PanierClient;

      if(this.panier !== undefined && this.panier !== null && this.panier.client !== undefined && this.panier.client !== null) {
        client_data = this.panier.client;
      }

      if(client_data !== undefined && client_data !== null) {
        bondeVenteData['client'] = client_data;
      } else {
        bondeVenteData['client'] = "";
      }    

      const bondeCommandeData = {
        pMag: this.panier.mag,
        pNumBon: this.bonNumBon,
        pPanier: bondeVenteData,
        pMotif: '',
        Id_User: +this.user.CIINT,
        Id_InstUser: +this.user.IDINSTINT
      }

      await this.editBonDeCommande(bondeCommandeData).then(
        async() => {
          this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});
        }
      );
    }

    this.loadingService.hide();
  }

  supprimerCommandeLineItem(motif: string) {
    if(this.panier === undefined || this.panier === null) {
      return;  
    }

    this.loadingService.show();    
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let article_data: PanierArticle[] = [...this.panier.articles];
    let pLignes: number[] = [this.currentItem.idlig];

    if (this.linkedStatus === 'service' && this.currentItem.opt > 0) {
      const otherServiceChildren = article_data.filter((rw) => rw.idlig !== this.currentItem.idlig && rw.opt === this.currentItem.opt);

      if (otherServiceChildren.length === 0) {
        article_data.map(rw => {
          if (rw.opt === (-this.currentItem.opt)) {
            rw.opt = 0;
          }

          return rw;
        })
      }
    }

    article_data = this.panier.articles.filter(
      (item) => {
        if(this.currentItem.opt < 0 && item.opt == Math.abs(this.currentItem.opt)) {
          pLignes.push(item.idlig);
          return false;
        }

        return item.idlig != this.currentItem.idlig;
      }
    );
    this.panier.articles = article_data;

    let bondeCommandeData = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pLignes: pLignes.join(';'),
      pMotif: (motif !== undefined && motif !== null)?motif:'',
      Id_User: +this.user.CIINT,
      Id_InstUser: +this.user.IDINSTINT
    };

    this.deleteBonDeCommandeLigne(bondeCommandeData);
  }

  deleteCommandeConfirmation(): void { 
    if(this.linkedStatus === 'service' && this.currentItem.opt > 0) {
      this.supprimerCommandeLineItem('');
      
    } else {
      let title = '';
      let message = `
      <h1 class="text-left mb-3">${this.translateService.instant('kboncomsupprimercmd')}</h1>
      <h1 class="text-left pl-2">        
        ${this.translateService.instant('kbonNum')} : ${this.bonNumBon} <br>
        ${this.translateService.instant('kbonpour')}: ${this.panier.client?.prenom+' '+this.panier.client?.nom} <br>
        ${this.translateService.instant('kbonmontant')}: ${this.currencyPipe.transform(this.panier.montant, null, false)}
      </h1>
      `;
  
      if(this.panier.articles.length > 1) {
        title = this.translateService.instant('kboncomsupprimerligne');
        message = '';
      }
  
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '60vw',
        data: {
          title: title,
          description: message
        }     
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result === "OK") {
          this.loadingService.show();

          this.checkArticleIsLinkedToAutreBon().then(res => {
            if(res) {
              // Add history trace for removing non-service article.
              this._addAutreArticleLinkHistory('', '', '', true, this.isLinkedToAutreArticleHistory)

              if(this.panier.articles.filter((_r) => _r.opt <= 0).length > 1) {
                this.supprimerCommandeLineItem('');
              } else {
                this.deleteBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pMotif: '' });
              }
            } else {
              this.openUpBondeCustomMotif();
            }
          })
        }
      });
    }   
  }

  openUpBondeCustomMotif(): void {
    const dialogRef = this.dialog.open(BlocageCustomMotifComponent, {
      maxWidth: '40vw',
      data: {
        action: Constants.MOTIF_SUPPRESSION_COMMANDE
      }
    });

    dialogRef.afterClosed().subscribe(result => {          
      if(result && result.status === "success") {
        const custom_motif_data = result.data;
        
        let motifText = custom_motif_data.MOTLIB;
        if(this.panier.articles.filter((_r) => _r.opt <= 0).length > 1) {
          this.supprimerCommandeLineItem(motifText);
        } else {
          this.deleteBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pMotif: motifText });
        }
      }      
    });    
  }

  async _newCommandeLineOptionsInsertion(position: number,optionArticle: any, parentPanierArticle: PanierArticle, newOpt?: number): Promise<void> {
    return new Promise(
      async (resolve, reject) => {
      const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
      const article_data = {
        idlig: position,
        cdart: optionArticle.CDART,
        quantite: parseFloat(optionArticle.QTY.toString()),
        prix: parseFloat(optionArticle.PRIX.toString()), // Article prix
        prixforce: false,
        motifprixforce: "",
        commlig1: "",
        commlig2: "",
        prixachatHT: 0.0,
        prixachatTTC: 0.0,
        tauxTVA: "", // Optional
        typecde: parentPanierArticle.typecde,
        remlig: 0, // Total remise amount of the row
        remtot: '0',
        datafid: "",
        datafidaco: "",
        dataopeco: "" ,
        datarem: "",
        okdiff: false,
        bundle: 0,
        opt: Math.abs(newOpt?newOpt:parentPanierArticle.opt),
        lib: optionArticle.CALIB,
        idinstuser: +this.user.IDINSTINT,
        iduser: +this.user.CIINT,
        libuser: this.user?(this.user.CIPRENOM+' '+this.user.CINOM[0]+'.'):'',
        dtpromesse: current_date, // 2018-03-27 10:04:41
        coinsolde: false,
        expo: false,
        motifcde: '',
        numcde: '',
        prixupv: parseFloat(optionArticle.PRIX.toString()), // New Price (Typeable) => PU Ven.
        montant: parseFloat((optionArticle.PRIX * optionArticle.QTY).toString()), // Row Subtotal - PV Total
        remise: 0,
        emplacement: "",
        statut: 0,
        dtmaj: current_date
      };
  
      const insertionData: any = {
        pMag: this.USERCMINT, 
        pNumBon: this.bonNumBon,
        pArticle: article_data,
        pPosition: position 
      };

      insertionData['Id_User'] = this.user.CIINT;
      insertionData['Id_InstUser'] = Constants.CIINST;
  
      this.insertNewOptionCallAPI(insertionData, false).then(
        async () => {
          if (this.currentArticleStockMag && this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
            await this._addMagStockHistory(this.user.CIINT, article_data.cdart, false);

            if (this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
              await this._addMagStockHistory(this.authorizedArticleStockMagUser.CIINT, article_data.cdart);
            }
          }

          resolve()
        }
      );
    })
  }

  insertNewOptionCallAPI(data: { pMag: any, pNumBon: string, pArticle: PanierArticle, pPosition: number }, closeLoader: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if(data == undefined || data == null) {
        resolve(null);
      }

      this.bonCommandeService.newOptionInsertion(data).subscribe(
        (resp) => {
          if(resp.statusCode == 200) {
            this.bonNumBon = resp.data.NumBon;
            
            this.panier = resp.data.Panier;

            this.__updateBonDeCommandeList();

            if(closeLoader) {
              this.loadingService.hide();
            }
            
            resolve(resp);
          } else {
            
            if(closeLoader) {
              this.loadingService.hide();
            }

            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve(resp);
          }        
        },
        err => {
          
          if(closeLoader) {
            this.loadingService.hide();
          }

          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      )
    });
  }
  
  deleteBonDeCommandeLigne(data: { pMag: number, pNumBon: string, pLignes: string, pMotif: string, Id_User: number, Id_InstUser: number }, closable: boolean = true): Promise<any> {
    return new Promise(
      async (resolve, reject) => {
        this.bonCommandeService.removeBonCommandeLineItem(data).subscribe(
          (resp) => {
            if(closable) {
              this.loadingService.hide();
            }
            
            if(resp.statusCode == 200) {
                if(resp.data.ErrorCode == 0) {
                  
                  if(data.pLignes.indexOf(';') > -1) {
                    const removedRows: any = data.pLignes.split(';');
                    this.panier.articles = this.panier.articles.filter((_rart) => {
                      return removedRows.indexOf(_rart.idlig) == -1
                    });
                  } else {
                    const removedRow = +data.pLignes;
                    this.panier.articles = this.panier.articles.filter((_rart) => _rart.idlig != removedRow);
                  }
                  
                  if(closable) {
                    this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});
                  }
                  resolve(resp);
                } else {
                  this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                  resolve(resp);
                }          
              } else {
                this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
                resolve(resp);
              }         
          },
          error => {        
            if(closable) {
              this.loadingService.hide();
            }

            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(error);
          }
        );
      }
    );
  }

  deleteBonDeCommande(data: { pMag: number, pNumBon: string, pMotif: string }) {
    this.bonCommandeService.removeBonCommande(data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              const oldListData = this.storageService.getItem('bonCommandeListData');

              if(oldListData !== undefined && oldListData != 'null' && oldListData !== null) {
                oldListData.data.Bons = oldListData?.data?.Bons.filter((row) => row.numbon != data.pNumBon);
              }

              this.storageService.setItem('bonCommandeListData', oldListData);

              this.dialogRef.close({status: 'removed', numBon: this.bonNumBon, panier: this.panier});
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }          
          } else {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }         
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

}
