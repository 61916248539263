import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import BonPanier from 'models/bonPanier';
import Transporteur from 'models/transporteur';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Subject } from 'rxjs';
import { DetailTransporteurComponent } from '../detail-transporteur/detail-transporteur.component';

@Component({
  selector: 'app-list-transporteur',
  templateUrl: './list-transporteur.component.html',
  styleUrls: ['./list-transporteur.component.scss'],
  providers: [BonVenteService, DialogsService, UserService]
})
export class ListTransporteurComponent implements OnInit {

  @ViewChild('firstFocusElement') firstFocusElement: ElementRef;
  
  searchForm: any;  
  listTransporteur: Transporteur[] = [];

  user: any;
  USERCMINT: any;

  // DATATABLE  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = false;

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;
  
  isMobile: boolean = false;

  chosenTransporteur: Transporteur;

  previousTranporteur: Transporteur;
  panier: BonPanier;

  constructor(private userService: UserService, 
    public bonVenteService: BonVenteService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ListTransporteurComponent>,) { 
     
  }

  ngOnInit(): void {
    this.panier = this.data.panier;
    this.previousTranporteur = this.data.previousTranporteur;

    this.isMobile = this.deviceService.isMobile();

    this.userService.headerAdd.next(true);
    this.userService.headerAddListener.next(false);

    this.userService.headerSearch.next(false);    

    this.searchForm = this.formBuilder.group({
      TRALIBL: [''],
      TRASTR1: ['', [Validators.pattern('[0-9]*'), Validators.maxLength(10)]],
    });

    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
  
    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }          
      },      
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };     

    this.searchFormSubmitter();
  }

  ngOnDestroy(): void {        
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
      this.dtTrigger.next();

      setTimeout(() => {
        this.firstFocusElement.nativeElement.focus();
        this.firstFocusElement.nativeElement.setAttribute('inputmode', 'text');
      }, 100)
  }

  searchFormSubmitter(): void {
    this.showLoader = true;

    if(this.searchForm.invalid) {
      this.showLoader = false;
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }

    const form_data = this.searchForm.value;    
    this.bonVenteService.getListTransporteur(form_data.TRALIBL, form_data.TRASTR1).subscribe(
      (response: any) => {
        if(response.success !== undefined) {
          this.currentPage = 0;

          const data = response.data;
  
          this.rowsPerPage = response.rowsPerPage;
          this.totalPages = response.count;
  
          this.listTransporteur = [...data];

          if(this.panier !== undefined && this.panier !== null && this.panier.transporteur !== '') {
            const [TRANS_CNUF, TRANS_DEPT] = this.panier.transporteur.split('/');
            this.chosenTransporteur = this.listTransporteur.find((item: Transporteur) =>  item.CNUF == TRANS_CNUF);
          }

          if(this.previousTranporteur !== undefined && this.previousTranporteur !== null) {
            this.chosenTransporteur = this.previousTranporteur;
          }
  
          this.showLoader = false;
          this.dtTrigger.next();
        }        
      }, 
      err => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )    
  }

  searchPaginate(page?: number) {
    const form_data = this.searchForm.value;
    
    if(this.searchForm.invalid) {
      this.showLoader = false;
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }

    this.bonVenteService.getListTransporteur(form_data.TRALIBL, form_data.TRASTR1, page).subscribe(
      (response: any) => {
        if(response.success !== undefined) {
          const data = response.data;

          this.rowsPerPage = response.rowsPerPage;
          this.totalPages = response.count;
  
          this.listTransporteur = [...data];
  
          this.showLoader = false;
          this.dtTrigger.next();
        }                
      }, 
      err => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )
  }

  paginationChange(event: any) {
    this.showLoader = true;
    const changedPageIndex = event.pageIndex;    

    this.currentPage = changedPageIndex;

    this.searchPaginate(changedPageIndex + 1);
  }

  changeTransporteur(event: any, transporteur: Transporteur) {
    if(event.checked) {
      this.chosenTransporteur = transporteur;
    } else {
      this.chosenTransporteur = undefined;
    }    
  }

  submitTransporteur() {
    this.dialogRef.close({ status: 'success', data: this.chosenTransporteur });
  }

  viewTransporteurDetail(transporteur: Transporteur) {
    const dialogRef = this.dialog.open(DetailTransporteurComponent, {
      width: '40vw',
      maxWidth: '40vw',    
      data: {
        transporteur: transporteur
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
          
      }
    });
  }

}
