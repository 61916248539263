import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import BonPanier from 'models/bonPanier';
import MagComplement from 'models/magComplement';
import Transporteur from 'models/transporteur';
import * as moment from "moment";
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';

@Component({
  selector: 'app-emportee-differe',
  templateUrl: './emportee-differe.component.html',
  styleUrls: ['./emportee-differe.component.scss'],
  providers: [ {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},BonVenteService]
})
export class EmporteeDiffereComponent implements OnInit {

  today = new Date();
  emporteeForm: UntypedFormGroup; 
  oldLivreData: any;  

  dtliv_status: boolean = false;

  magComplementData: MagComplement;
  
  transporteurLabel: string = '';
  panier: BonPanier;

  previousTranporteur: Transporteur;

  defaultDiffereDays: number;
  paramData: any;

  constructor(private formBuilder: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private userService: UserService,
            public dialogRef: MatDialogRef<EmporteeDiffereComponent>,
            private dialog: MatDialog,
            private dialogService: DialogsService,
            private loadingService: NgxSpinnerService,
            private translateService: TranslateService) { }

  ngOnInit(): void {
    this.oldLivreData = this.data.livreDetail;
    this.magComplementData = this.data.magComplementData;
    this.panier = this.data.panier;    
    
    this.emporteeForm = this.formBuilder.group({
      dtemporte: [{value: '', disabled: true}],
    });

    this.setDefaultDtEmporte();

    if(this.panier !== undefined && this.panier !== null) {
      let emporteDateYear = moment(this.panier.dtech).year();
      this.emporteeForm.patchValue({
        dtemporte: (emporteDateYear !== undefined && emporteDateYear !== 1899)?new Date(this.panier.dtech):''
      })
    }
  }

  async setDefaultDtEmporte() {
    this.loadingService.show();
    let oldTechYear = moment(this.panier.dtech).year();
    
    try {
      const ED_CMINT = this.panier.magstock ? this.panier.magstock : undefined;
      this.paramData = await this.userService.getRuleParamsData(20, 50, undefined, undefined, ED_CMINT);
      let defaultDiffereDays: number;
      if(this.paramData) {
        this.defaultDiffereDays = this.paramData['VINT1'];
  
        if(this.paramData['VINT3'] !== '' && this.paramData['VINT3'] !== null) {
          defaultDiffereDays = this.paramData['VINT3'];
        } else {
          defaultDiffereDays = this.paramData['VINT1'];
        }
      }
  
      if(oldTechYear === 1899) {
        // Default Date limite for Emportéé différe from Params
        if(this.paramData) {
          const ED_default_date = moment().add(defaultDiffereDays, 'days').format('YYYY-MM-DD HH:mm:ss');
          this.emporteeForm.get('dtemporte').setValue(new Date(ED_default_date));
        }
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }

    this.loadingService.hide();
  }

  emporteeFormSubmitter() {
    const form_data = this.emporteeForm.getRawValue();

    const today = moment().startOf('day');
    const selected_date = moment(form_data.dtemporte).startOf('day');

    if(today.diff(selected_date, 'days') > 0) { // Error alert, If selected date < today date
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kbondateinf'));
      return;
    }

    if(this.defaultDiffereDays !== undefined && this.defaultDiffereDays !== null) {
      const ED_default_date = moment().add(this.defaultDiffereDays, 'days').startOf('day');
      const diff = ED_default_date.diff(selected_date, 'days');
  
      if(diff < 0) { // Confirmation, If (selected date > default delay date || selected date < default delay date) (20/50 VINT1 - 7 days from today)
        this.emporteConfirmation();
        return;
      }
    }
    
    this.emporteDiffereUpdate();
  }

  emporteDiffereUpdate() {
    const form_data = this.emporteeForm.getRawValue();

    form_data.dtemporte = (form_data.dtemporte !== '')?moment(form_data.dtemporte).format('YYYY-MM-DD HH:mm:ss'):moment('1899-12-28').format('YYYY-MM-DD HH:mm:ss');
    this.dialogRef.close({ status: 'success', data: form_data });
  }

  emporteConfirmation(): void {
    const message = `
      <h1 class="mb-3">${this.translateService.instant('kbondatenotstd')}</h1>
      <h1 class="">${this.translateService.instant('kboncontinue')}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [
          this.translateService.instant('ksyes'),
          this.translateService.instant('kartno')
        ]
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.emporteDiffereUpdate();
      }
    });    
  }

}
