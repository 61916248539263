import { Component, Inject} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html'
})

export class ConfirmComponent{
    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<ConfirmComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {

    }
}
