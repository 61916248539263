<mat-card [class.no-padding]="isMobile">    
    <mat-card-content class="mat-card-content-form">
        <app-create-client *ngIf="newClient" [flowType]="flowType" displayType="tab" bonClientType="clientlivre" [bonNumBon]="bonNumBon" [(panier)]="panier" (onChange)="onCreateClientChanged($event)" ></app-create-client>
        <app-client-detail *ngIf="editClient" [flowType]="flowType" displayType="tab" bonClientType="clientlivre" [bonNumBon]="bonNumBon" [selectedClient]="selectedClient" [(panier)]="panier" (onChange)="onEditClientChanged($event)" ></app-client-detail>

        <div class="row" *ngIf="!newClient && !editClient">
            <div class="mt-2 w-100" *ngIf="isMobile && panier && panier.preparation && panier.typecde === 'BL' && (panier.statut == 5 || panier.statut == 6)">
                <button type="button" class="btn-block text-left" mat-button (click)="handleDeliveryStatusChange()">
                    <i class="material-icons mr-1">local_shipping</i>
                    {{ (panier.statut == 5 ? 'kliv_en_cours' : 'kliv_en_attente') | translate}}
                </button>
            </div>
            <div class="col-md-8"  [class.no-padding]="isMobile">
                <form [formGroup]="searchForm" [class.section-disabled]="sameAsClient || sectionDisabled" (keydown.enter)="$event.preventDefault();" (ngSubmit)="searchClientFormSubmitter()" *ngIf="!readOnly && (flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise'">
                    <div class="row" *ngIf="!readOnly && (flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise'">
                        <div class="col-9 col-md-6 text-left d-flex align-items-center">
                            <mat-slide-toggle formControlName="same_client" (change)="changeSameClient()" [disabled]="readOnly && sectionDisabled">{{'kbonsamecust' | translate}}</mat-slide-toggle>
                        </div>

                        <div class="d-flex justify-content-end">
                            <div class="col-12 col-md-6 text-right">
                                <button type="button" mat-button (click)="openClientHistory()" *ngIf="selectedClient !== undefined && selectedClient !== null">
                                    <i class="material-icons">history</i>
                                    <span class="d-none d-sm-none d-md-block">
                                        {{'kbonhistoire' | translate}}
                                    </span>                        
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isMobile">
                        <div class="col-9 col-md-12">
                            <h4 class="text-left">
                                {{'kbonNum' | translate}}
                                <span class="detail-holder d-inline-block w-75">{{selectedClient?selectedClient.IDTIERS:''}}</span>
                            </h4>
                        </div>
                        <div class="col-3 no-padding"> 
                            <button matSuffix type="button" class="ml-2" mat-button (click)="openEditClientForm()" [disabled]="readOnly && sectionDisabled" *ngIf="!sameAsClient && (selectedClient !== undefined && selectedClient !== null) && (flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise'" style="margin-top:2px !important">
                                <mat-icon>edit</mat-icon>
                            </button>                             
                        </div>
                    </div> 
                    <div class="row" *ngIf="!isMobile">
                        <div class="col-9 col-md-6">
                            <h4 class="text-left pt-3">
                                {{'kbonNum' | translate}}
                                <span class="detail-holder d-inline-block w-75">{{selectedClient?selectedClient.IDTIERS:''}}</span>
                            </h4>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <button type="button" *ngIf="!sameAsClient && (flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise'" [disabled]="readOnly && sectionDisabled" mat-button (click)="openCreateClientForm()">
                                <i class="material-icons">add</i>
                                <span class="d-none d-sm-none d-md-block">
                                    {{'kbonnouveau' | translate}}
                                </span>                        
                            </button>
        
                            <button type="button" class="ml-2" mat-button (click)="openEditClientForm()" [disabled]="readOnly && sectionDisabled" *ngIf="!sameAsClient && (selectedClient !== undefined && selectedClient !== null) && (flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise'">
                                <i class="material-icons">edit</i>
                                <span class="d-none d-sm-none d-md-block">
                                    {{'kbonmodifier' | translate}}
                                </span>                        
                            </button>
                        </div>
                    </div>

                    <div class="row" *ngIf="!readOnly && (flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise'">
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field>
                                <input matInput formControlName="client_nom"
                                       (keyup.enter)="searchClients()"
                                       [placeholder]="'kbonrecherchenom' | translate"
                                       class="text-uppercase">
                                <button matSuffix type="submit" [disabled]="searchButtonDisabled || (readOnly && sectionDisabled)" mat-icon-button  *ngIf="!isMobile">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button matSuffix type="button" (click)="searchClients()" mat-icon-button class="inlineblock"  [disabled]="readOnly && sectionDisabled" *ngIf="isMobile">
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button matSuffix type="button"  class="ml-2" mat-icon-button (click)="openAdvancedSearch()" [disabled]="readOnly && sectionDisabled" *ngIf="!sameAsClient && isMobile">
                                    <mat-icon class="bt_logout bt_cursor text-white" [title]="'kclsearc' | translate">more_vert</mat-icon>
                                </button> 
                                <button matSuffix type="button" *ngIf="isMobile && !sameAsClient && (flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise'" mat-icon-button (click)="openCreateClientForm()" [disabled]="readOnly && sectionDisabled" class="ml-2">
                                    <mat-icon>add</mat-icon>
                                </button>                          
                            </mat-form-field>
                        </div>
                    </div>
                </form>

                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-md-6" [class.section-disabled]="!readOnly && (sameAsClient || sectionDisabled)">
                        <h4 class="text-left">
                            {{'kboncompnomprenom' | translate}}
                            <span class="detail-holder">{{selectedClient?((selectedClient.CIVLIBC != null?selectedClient.CIVLIBC+' ':'')+selectedClient.TRAISOC+(selectedClient.TPRENOM?' '+selectedClient.TPRENOM:'')):''}}</span>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kbonaddress' | translate}} <br>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR1?selectedClient.TADR1:''):''}}</span>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR2?selectedClient.TADR2:''):''}}</span>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR3?selectedClient.TADR3:''):''}}</span>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR4?selectedClient.TADR4:''):''}}</span>
        
                            <div class="d-flex justify-content-between">
                                <span class="detail-holder w-25">{{selectedClient?(selectedClient.CODPOS?selectedClient.CODPOS:''):''}}</span> 
                                <span class="detail-holder ml-1" style="flex: 1;">{{selectedClient?(selectedClient.TVILLE?selectedClient.TVILLE:''):''}}</span>
                            </div>                    
                        </h4>
        
                        <h4 class="text-left">
                            {{'kboncountry' | translate}}
                            <span class="detail-holder"> {{selectedClient?(selectedClient.PAYSNOM?selectedClient.PAYSNOM:''):''}}</span>
                        </h4>
                    </div>
                    <div class="col-md-6" [class.section-disabled]="!readOnly && (sameAsClient || sectionDisabled)">
                        <h4 class="text-left">
                            {{'kclshop' | translate}}
                            <span class="detail-holder">{{ (magasinNom !== undefined && magasinNom !== null)?magasinNom:('kclnondefi' | translate) }}</span>
                        </h4>
                        
                        <h4 class="text-left">
                            {{'kbonemail' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TEMAIL?selectedClient.TEMAIL:''):''}}</span>
                        </h4>

                        <h4 class="text-left">
                            {{'kclport' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TPORT?(selectedClient.TPORT | formatTele):''):''}}</span>
                        </h4>

                        <h4 class="text-left">
                            {{'kbontel' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TTEL?(selectedClient.TTEL | formatTele):''):''}}</span>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kbonfax' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TFAX?selectedClient.TFAX:''):''}}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card" *ngIf="panier && livreArtAvail">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kbonidl' | translate}}</span>

                        <button type="button" class="m-0" (click)="openUpLivraionInfoDetails()" *ngIf="!readOnly" [disabled]="!panier || (panier && prestataire === 'URBANTZ' && !lienLogAvail) || (flowType === 'retrait_marchandise')" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i> 
                        </button>
                    </div>
                    
                    <div class="card-body">
                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                            <span class="mr-1">{{'kbonlivde' | translate}}</span>  
                            <span class="detail-holder text-left" style="flex: 1" *ngIf="livreDetail">
                                {{ (livreDateYear !== undefined && livreDateYear !== 1899)?livreDate:'' }}<br/>
                                {{ (livreDetail.livjour !== undefined && livreDetail.livjour !== null && livreDetail.livjour !== '')?((livreDetail.livjour === 'M'?'kbonmatin':'kbonapresmidi') | translate):'' }}
                            </span>                           
                        </h4>
    
                        
                        <h4 class="text-left" *ngIf="!lienLogAvail && prestataire !== 'URBANTZ'">
                            {{'kbonconfirmdateliv' | translate}}
                            <span class="detail-holder" *ngIf="livreDetail">{{ livreDetail.livconfirm?('ksyes' | translate):('kartno' | translate) }}</span>
                        </h4>
                        
                        <h4 class="text-left" *ngIf="!lienLogAvail && prestataire !== 'URBANTZ'">
                            {{'kbonadddelivery' | translate}}
                            <span class="detail-holder" *ngIf="livreDetail">{{ livreDetail.livinfo1 }}</span>
                            <span class="detail-holder" *ngIf="livreDetail">{{ livreDetail.livinfo2 }}</span>
                        </h4>

                                                
                        <div>
                            <h4 class="align-items-center d-flex flex-row justify-content-between text-left" *ngIf="!lienLogAvail && prestataire !== 'URBANTZ'">
                                <span class="mr-1">{{'kbontrans' | translate}}</span>
                                <span class="detail-holder text-left" style="flex: 1"> {{ transporteurLabel }}</span>
                            </h4>

                            <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                                <span class="mr-1">{{'kbonliveprix' | translate}}</span>                                
                                <span class="detail-holder text-right" style="flex: 1;"> {{ prixLivraison | number: '0.2' }}</span>
                            </h4>
                        </div>

                        <div class="mt-4 text-right" *ngIf="!lienLogAvail && prestataire !== 'URBANTZ'">
                            <span class="badge badge-primary" *ngIf="livreDetail && livreDetail.livdeport === true">{{'kbonlivedeport' | translate}}</span>
                            <span class="badge badge-info" *ngIf="livreDetail && livreDetail.livdirect === true">{{'kbonlivdirect' | translate}}</span>
                        </div>
                    </div>                    
                </div>

                <div class="card mt-2" *ngIf="panier && emporteeArtAvail">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kbonemportdiff' | translate}}</span>

                        <button type="button" class="m-0" (click)="openUpEmporteDiffere()" *ngIf="!readOnly" [disabled]="!panier || (flowType === 'retrait_marchandise')" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i> 
                        </button>
                    </div>
                    
                    <div class="card-body">
                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                            <span class="mr-1">{{'kbondeadline' | translate}}</span>  
                            <span class="detail-holder text-right" style="flex: 1" *ngIf="livreDetail">
                                {{ dateFormatter(emporteeDiffereDate, false) }}
                            </span>                           
                        </h4>                                                     
                    </div>                    
                </div>

                <div class="card mt-2" *ngIf="panier && promesseArtAvail">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kdatedepromesse' | translate}}</span>

                        <button type="button" class="m-0" (click)="openUpDatePromesse()" *ngIf="!readOnly" [disabled]="!panier || (flowType === 'retrait_marchandise')" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i> 
                        </button>
                    </div>
                    
                    <div class="card-body">
                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                            <span class="mr-1">{{'kbondeadline' | translate}}</span>  
                            <span class="detail-holder text-right" style="flex: 1" *ngIf="livreDetail">
                                {{ dateFormatter(promesseDate, false) }}
                            </span>                           
                        </h4>                                                     
                    </div>                    
                </div>

                <div class="card mt-2" *ngIf="panier && livreArtAvail">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kbontransporteur' | translate}}</span>

                        <button type="button" class="m-0" (click)="openUpCarrier()" *ngIf="!readOnly" [disabled]="!panier || (flowType === 'retrait_marchandise')" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="card-body">
                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                            <span class="detail-holder text-left" style="flex: 1" >{{ transporteurLabel?transporteurLabel:'' }}</span>
                        </h4>
                    </div>
                </div>

                <div class="card mt-2" *ngIf="panier && livreArtAvail && flowType === 'bonde_commande'">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kliendetail' | translate}}</span>

                        <!-- Including button with visibility: hidden for the Padding -->
                        <button type="button" class="m-0 invisible" mat-icon-button><i class="fa fa-pencil-square-o f20" aria-hidden="true"></i></button>
                    </div>

                    <div class="card-body">
                        <div *ngIf="lienListLoading" class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </div>
                        <div *ngIf="!lienListLoading">
                            <p *ngFor="let lienRow of lienListData | async" class="text-left">
                                <a [attr.href]="__lienLink(lienRow.LIEN)" target="_blank" class="text-left text-primary">
                                    <span class="lien-link">{{ lienRow.LIEN }}</span>
                                </a>
                                <span class="lien-qte ml-2" *ngIf="lienRow.QTE">({{ 'klienqte' | translate }}: {{ lienRow.QTE | number }})</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card mt-2" *ngIf="!isMobile && panier && panier.preparation && panier.typecde === 'BL' && (panier.statut == 5 || panier.statut == 6)">
                    <button type="button" class="btn-block text-left" mat-button (click)="handleDeliveryStatusChange()">
                        <i class="material-icons mr-1">local_shipping</i>
                        {{ (panier.statut == 5 ? 'kliv_en_cours' : 'kliv_en_attente') | translate}}
                    </button>
                </div>
            </div>
        </div>    
    </mat-card-content>
</mat-card>
