<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{"kuusers" | translate}}
            </h1>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile">
            <div class="row">
                <button type="button" class="bt_valid w-100 pad10 mb-2" (click)="addUser()" mat-button >
                    <mat-icon class="addicon">add_circle</mat-icon>
                    {{ 'kmaddbtn' | translate }}
                </button>
            </div>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile && filterValues">            
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>
    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium">{{'kclclearform' | translate}}</div>
                </button>
            </div>
        </div>
    </div>

    <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-12 ">

                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="search_code" [placeholder]="'kucode' | translate" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="search_nom" [placeholder]="'kuname' | translate" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <mat-form-field>
                                <mat-select [placeholder]="'kuniveaulev' | translate" color="primary" disableOptionCentering panelClass="dropdown-panel" formControlName="search_niveau">
                                    <mat-option>
                                        <ngx-mat-select-search
                                        [placeholderLabel]="'kuallsec' | translate"
                                        [noEntriesFoundLabel]="'kksecuritetrouve' | translate"                                
                                        (ngModelChange)="onSecurityChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterNiveau"></ngx-mat-select-search>
                                    </mat-option>  
                                    <mat-option value="tous">{{'kuallsec' | translate}}</mat-option>
                                    <mat-option value="nondefini">{{'kuundefine' | translate}}</mat-option>                                    
                                    <mat-option *ngFor="let item of filteredNiveauTypes" [value]="item.NIVINT">{{ item.NIVNOM }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <div class="d-flex h-100 align-items-center">
                                <mat-checkbox formControlName="search_actif">{{'kuuserdeact' | translate}}</mat-checkbox>
                            </div>
                        </div>  
                        <div class="clearfix"></div>                                                                 
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button type="submit">
                        <mat-icon>search</mat-icon>
                        {{ "kpsearch" | translate }}
                    </button>

                    <button class="bt_valid ml-3" (click)="addUser()" mat-button type="button">
                        <mat-icon>add_circle</mat-icon>
                        {{ 'kmaddbtn' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">

        <div class="row">
            <div class="col-12 no-padding">
                <div class="">
                    <mat-paginator *ngIf="!showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

                <div class="table-responsive" *ngIf="!isMobile">
                    <table datatable class="row-border hover" id="utilisateursList" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                        <thead>
                            <tr>
                                <th class="actif">
                                    {{'kuuseract' | translate}}
                                </th>
                                <th class="code">
                                    <div class="d-flex justify-content-start align-items-center sorter" (click)="sortingOrder('CIUSER')">
                                        <span>{{'kucode' | translate}}</span>
                                        <span class="ml-3" *ngIf="orderedByColumn === 'CIUSER'">
                                            <mat-icon *ngIf="sortByColumn === 'asc'">keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="sortByColumn === 'desc'">keyboard_arrow_up</mat-icon>
                                        </span>
                                    </div>
                                </th>        
                                <th class="code">
                                    <div class="d-flex justify-content-start align-items-center sorter" (click)="sortingOrder('CINOM')">
                                        <span>{{'kuname' | translate}}</span>
                                        <span class="ml-3" *ngIf="orderedByColumn === 'CINOM'">
                                            <mat-icon *ngIf="sortByColumn === 'asc'">keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="sortByColumn === 'desc'">keyboard_arrow_up</mat-icon>
                                        </span>
                                    </div>
                                </th>
                                <th class="prenom">{{'kuprenom' | translate}}</th>
                                <th class="niveau">{{'kslevel' | translate}}</th>
                                <th width="15%" class="actions">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listUsers?.length != 0 && !showLoader">
                            <tr *ngFor="let user of listUsers">
                                <td class="numero" (click)="editUser(user)">
                                    <mat-icon [color]="user.CIACTIF == '-1'?'primary':'warn'">{{ user.CIACTIF == '-1'?'check_circle':'cancel' }}</mat-icon>                                
                                </td>
                                <td class="numero" (click)="editUser(user)">{{ user.CIUSER }}</td>
                                <td class="numero" (click)="editUser(user)">{{ user.CINOM }}</td>
                                <td class="numero" (click)="editUser(user)">{{ user.CIPRENOM }}</td>        
                                <td class="numero" (click)="editUser(user)">{{ user.NIVNOM?user.NIVNOM:'< '+ ('kuundefine' | translate)+' >' }}</td>                            
                                <td class="actions">
                                    <button type="button" mat-mini-fab color="primary" (click)="editUser(user)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </tr>                        
                        </tbody>
                        <tbody *ngIf="listUsers?.length == 0 || showLoader">
                            <tr>
                                <td colspan="6" *ngIf="listUsers?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                                <td colspan="6" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <mat-list *ngIf="isMobile && (listUsers?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="listUsers?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">                        
                        <p matLine class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                    </mat-list-item>
                </mat-list>

                <mat-list *ngIf="isMobile && (listUsers?.length != 0 && !showLoader)">
                    <mat-list-item  *ngFor="let user of listUsers" (click)="editUser(user)">     
                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                            <h2>
                                {{ user.CIUSER }}
                            </h2>
                            <p class="m-0">
                                <span class="badge" [class]="user.CIACTIF == '-1'?'badge-success':'badge-danger'" translate>{{ user.CIACTIF == '-1'?'kartactive':'kartinactive' }}</span>                                
                            </p>
                        </div>
                        <div matLine>
                            <h2 class="m-0"><strong>{{ user.NIVNOM?user.NIVNOM:'< '+ ('kuundefine' | translate)+' >' }}</strong></h2>
                        </div>
                        <p matLine class="mobile-list-title">
                            {{ user.CINOM }} {{ user.CIPRENOM }}
                        </p>                        
                    </mat-list-item>
                </mat-list>

            </div>
        </div>
    </div>
</div>
