<div class="modal-container" id="carrier_vente">
    <form [formGroup]="carrierForm" (ngSubmit)="carrierFormSubmitter()">
        <mat-card>
            <mat-card-header >
                <mat-card-title class="text-uppercase text-center ">
                    <div class="row">
                        <div class="col-11 text-left">
                            <h4 class="modal_title" translate>{{'kboncarrier' | translate}}</h4>
                        </div>
                        <div class="col-1 text-right no-padding">
                            <button mat-button [mat-dialog-close] class="bt_close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-12">
                        <div *ngIf="transporteurList?.length == 0 || showLoader" class="w-100 text-center">
                            <p *ngIf="transporteurList?.length == 0"></p>
                            <mat-icon *ngIf="showLoader" class="synching">sync</mat-icon>
                        </div>

                        <mat-form-field floatLabel="always" appearance="outline" *ngIf="transporteurList?.length != 0 && !showLoader">
                            <mat-label>{{'kbontransporteur' | translate}}</mat-label>
                            <mat-select formControlName="TRANSPORTEUR" disableOptionCentering panelClass="dropdown-panel">
                                <mat-option value="">{{ 'kuundefine' | translate }}</mat-option>
                                <mat-option *ngFor="let item of transporteurList;let i=index;" [value]="item.CNUF">{{ item.TRALIBL | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>

            <mat-card-actions class="text-center">
                <button mat-button type="submit" class="bt_send">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">
                    {{'kuok' | translate}}
                </span>
                </button>
                <button mat-button [mat-dialog-close] class="bt_send">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>