import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import { UserModel } from 'models/user-model';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { UtilService } from 'providers/api/utilService';

@Component({
  selector: 'app-remise-detail',
  templateUrl: './remise-detail.component.html',
  styleUrls: ['./remise-detail.component.scss'],
  providers: [BonVenteService, UtilService]
})
export class RemiseDetailComponent implements OnInit {

  remiseType: number;
  totalPrice: number;
  chosenRemise: any[];
  remiseManuelles: {'label':string, amount: string}[] = [];

  maskOptions = {
    mask: Number,
    signed: true,
    scale: 2,    
    padFractionalZeros: true,
    radix: ",",
    mapToRadix: ["."]
  };
  user: UserModel;
  USERCMINT: any;
  
  listRemises: any;  

  panier: BonPanier;
  panierArticle: PanierArticle;
  articleData: Article;

  totalDiscount: number = 0;

  changementDePrix: any;
  prixForcePercentage: any;
  prixForceAmount: any;

  remiseCommercialeStat: boolean = false;
  remiseCommercialeDiscounts: {'label':string, amount: string}[] = [];
  
  animationCommercialeStat: boolean = false;
  animationCommercialeDiscounts: {'label':string, amount: string}[] = [];

  avantageCommercialeStat: boolean = false;
  avantageCommercialeDiscounts: {'label':string, amount: string}[] = [];

  constructor(private userService: UserService,@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  private bonVenteService: BonVenteService,
  private translateService: TranslateService,
  private dialogService: DialogsService,
  private utilService: UtilService) { }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
    this.remiseType = this.data.remiseType;
    this.totalPrice = this.data.totalPrice;
    this.chosenRemise = this.data.chosenRemise;
    this.USERCMINT = this.data.USERCMINT;    
    this.panier = this.data.panier;
    this.panierArticle = this.data.panier_article;
    this.articleData = this.data.custom_article;

    if(this.panierArticle !== undefined && this.panierArticle !== null && this.panierArticle.prixforce == true) {
      this.prixForceAmount = (this.panierArticle.prix - +this.articleData?.calculated_price).toString();
      this.prixForcePercentage = (((this.panierArticle.prix - +this.articleData?.calculated_price) / +this.articleData?.calculated_price) * 100).toString();
    }

    this.calculatingRemise();
  }

  async calculatingRemise() {
    if(this.panier !== undefined && this.panier !== null) {
      if(this.remiseType === 2) {

        if(this.panier.dataopeco !== null && this.panier.dataopeco !== '') {
          this.remiseCommercialeStat = true;

          const multiSplitter = this.panier.dataopeco.split(';');

          for(let i=0;i < multiSplitter.length;i++) {
            
            let [,,,commercialeAmount,commercialLabel] = multiSplitter[i].split('#');

            this.remiseCommercialeDiscounts.push({
              label: commercialLabel,
              amount: this.utilService.frToEnNumberFormat(commercialeAmount)
            });
          }
        }
  
        if(this.panier.datafidaco !== null && this.panier.datafidaco !== '') {
          this.animationCommercialeStat = true;
  
          const multiSplitter = this.panier.datafidaco.split(';');

          for(let i=0;i < multiSplitter.length;i++) {
            
            let [,,,commercialeAmount,commercialLabel] = multiSplitter[i].split('#');

            this.animationCommercialeDiscounts.push({
              label: commercialLabel,
              amount: this.utilService.frToEnNumberFormat(commercialeAmount)
            });
          }
        }
  
        if(this.panier.datafid !== null && this.panier.datafid !== '') {
          this.avantageCommercialeStat = true;

          const multiSplitter = this.panier.datafid.split(';');

          for(let i=0;i < multiSplitter.length;i++) {
            
            let [,,,commercialeAmount,commercialLabel] = multiSplitter[i].split('#');

            this.avantageCommercialeDiscounts.push({
              label: commercialLabel,
              amount: this.utilService.frToEnNumberFormat(commercialeAmount)
            });
          }
        }

        if(this.panier.datarem !== null && this.panier.datarem !== '') {
          const multiSplitter = this.panier.datarem.split(';');

          for(let i=0;i < multiSplitter.length;i++) {
            
            let [REMMCODE, REMMTAUX, REMMMONTANT] = multiSplitter[i].split('#');

            this.remiseManuelles.push({
              label: this.translateService.instant('kbonremise')+' ('+REMMTAUX+'%)',
              amount: this.utilService.frToEnNumberFormat(REMMMONTANT)
            });
          }
          
          return;
        }

      } else {
        if(this.panierArticle !== undefined && this.panierArticle !== null) {          

          this.changementDePrix = this.panierArticle.prixforce === true?'':undefined;
  
          if(this.panierArticle.dataopeco !== null && this.panierArticle.dataopeco !== '') {
            this.remiseCommercialeStat = true;

            const multiSplitter = this.panierArticle.dataopeco.split(';');

            for(let i=0;i < multiSplitter.length;i++) {
              
              let [,,,commercialeAmount,commercialLabel] = multiSplitter[i].split('#');

              this.remiseCommercialeDiscounts.push({
                label: commercialLabel,
                amount: this.utilService.frToEnNumberFormat(commercialeAmount)
              });
            }
          }
    
          if(this.panierArticle.datafidaco !== null && this.panierArticle.datafidaco !== '') {
            this.animationCommercialeStat = true;

            const multiSplitter = this.panierArticle.datafidaco.split(';');

            for(let i=0;i < multiSplitter.length;i++) {
              
              let [,,,commercialeAmount,commercialLabel] = multiSplitter[i].split('#');

              this.animationCommercialeDiscounts.push({
                label: commercialLabel,
                amount: this.utilService.frToEnNumberFormat(commercialeAmount)
              });
            }
          }
    
          if(this.panierArticle.datafid !== null && this.panierArticle.datafid !== '') {
            this.avantageCommercialeStat = true;

            const multiSplitter = this.panierArticle.datafid.split(';');

            for(let i=0;i < multiSplitter.length;i++) {
              
              let [,,,commercialeAmount,commercialLabel] = multiSplitter[i].split('#');

              this.avantageCommercialeDiscounts.push({
                label: commercialLabel,
                amount: this.utilService.frToEnNumberFormat(commercialeAmount)
              });
            }
          }

          if(this.panierArticle.datarem !== null && this.panierArticle.datarem !== '') {
            const multiSplitter = this.panierArticle.datarem.split(';');

            for(let i=0;i < multiSplitter.length;i++) {
              
              let [REMMCODE, REMMTAUX, REMMMONTANT] = multiSplitter[i].split('#');

              this.remiseManuelles.push({
                label: this.translateService.instant('kbonremise')+' ('+REMMTAUX+'%)',
                amount: this.utilService.frToEnNumberFormat(REMMMONTANT)
              });
            }

            return;
          }
        }        
      }
    }

    if(this.chosenRemise !== undefined && this.chosenRemise !== null && this.chosenRemise.length > 0) {
      for(let i=0;i < this.chosenRemise.length;i++) {
        const totalDiscount = this.totalPrice * (this.chosenRemise[i].REMMTAUX / 100);
        
        this.remiseManuelles.push({
          label: this.translateService.instant('kbonremise')+' ('+this.chosenRemise[i].REMMTAUX+'%)',
          amount: totalDiscount.toString()
        });
      }
    }
  }

  async getRemiseList(type: number) {
    return new Promise((resolve, reject) => {
      this.bonVenteService.getRemiseList(type, this.USERCMINT,this.user.CIINT).subscribe(
        (res) => {            
            if(res.success !== undefined) {
              this.listRemises = res.data;                
              resolve(res.data);                             
            }
        },
        error => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
        }
    );
    });    
  }

}
