<div class="modal-container" id="couche_prix_article">
    <div class="row no-gutters">
        <div class="col-12">
            <div>
                <h2 class="text-center mb-5">{{ 'kchoisir_larticle_lier' | translate }}</h2>
            </div>
            <form [formGroup]="articleSelectionForm">
                <mat-vertical-stepper [linear]="true" #stepper *ngIf="!isDevis" (animationDone)="setFocus()" (selectionChange)="onStepChange($event)">
                    <mat-step>
                        <ng-template matStepLabel>{{ (isBonDeVente ? 'ksur_ce_bon_vente' : 'ksur_ce_bon') | translate }}</ng-template>

                        <mat-list>
                            <ng-container *ngIf="listNonServiceItems && listNonServiceItems.length > 0">
                                <mat-list-item *ngFor="let rowItem of listNonServiceItems;let j=index" class="test">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-2 col-md-2">
                                            <div class="">
                                                <img [src]="getImageURL(rowItem.cdart)" class="img-fluid" [alt]="rowItem.cdart" default="assets/images/no_img.png" />
                                            </div>
                                        </div>
                                        <div class="col-10 col-md-10 d-flex">
                                            <div class="d-flex w-100 justify-content-between align-items-center pl-4">
                                                <mat-checkbox [(ngModel)]="selectedItem[rowItem.idlig]" (ngModelChange)="onSelectedItemChange($event, rowItem)" [ngModelOptions]="{ standalone: true }" matLine>{{ rowItem.lib }}</mat-checkbox>
                                                <span class="badge" *ngIf="nonServiceArticleHashTable[rowItem.cdart] && nonServiceArticleHashTable[rowItem.cdart].calculated_price != null && nonServiceArticleHashTable[rowItem.cdart].calculated_price !== '' && +nonServiceArticleHashTable[rowItem.cdart].calculated_price > 0">{{ +nonServiceArticleHashTable[rowItem.cdart].calculated_price | cap_currency }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </ng-container>

                            <ng-container *ngIf="!listNonServiceItems || listNonServiceItems.length === 0">
                                <mat-list-item class="test">
                                    <div class="row no-gutters mb-2 py-4">
                                        <div class="col">
                                            <h4 class="text-center">{{ 'kaucun_article_lier' | translate }}</h4>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>{{ 'ksur_autre_bon' | translate }}</ng-template>

                        <div class="row">    
                            <div class="col-12 col-md-6">
                                <mat-form-field floatLabel="always" appearance="outline">
                                    <mat-label>{{'kbonbonl' | translate}}</mat-label>
                                    <input matInput type="text" formControlName="NUMBON" (keyup.enter)="searchBondecommandeArticles($event)" class="text-uppercase" id="numbonInput" />
                                    <button matSuffix type="button"  tabindex="-1" mat-icon-button (click)="searchBondecommandeArticles($event)">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                    <button matSuffix class="ml-2" type="button" *ngIf="isMobile" (click)="openScanArticle($event)" tabindex="-1" mat-icon-button>
                                        <i class="fa fa-barcode" aria-hidden="true"></i>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>

                        <mat-list>
                            <ng-container *ngIf="listAutreNonServiceItems && listAutreNonServiceItems.length > 0">
                                <mat-list-item *ngFor="let rowItem of listAutreNonServiceItems;let j=index" class="test">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-2 col-md-2">
                                            <div class="">
                                                <img [src]="getImageURL(rowItem.cdart)" class="img-fluid" [alt]="rowItem.cdart" default="assets/images/no_img.png" />
                                            </div>
                                        </div>
                                        <div class="col-10 col-md-10 d-flex">
                                            <div class="d-flex w-100 justify-content-between align-items-center pl-4">
                                                <mat-checkbox [(ngModel)]="autreSelectedItem[rowItem.cdart]" (ngModelChange)="onAutreSelectedItemChange($event, rowItem)" [ngModelOptions]="{ standalone: true }" matLine>{{ rowItem.lib }}</mat-checkbox>
                                                <span class="badge" *ngIf="autreNonServiceArticleHashTable[rowItem.cdart] && autreNonServiceArticleHashTable[rowItem.cdart].calculated_price != null && autreNonServiceArticleHashTable[rowItem.cdart].calculated_price !== '' && +autreNonServiceArticleHashTable[rowItem.cdart].calculated_price > 0">{{ +autreNonServiceArticleHashTable[rowItem.cdart].calculated_price | cap_currency }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </ng-container>

                            <ng-container *ngIf="!listAutreNonServiceItems || listAutreNonServiceItems.length === 0">
                                <mat-list-item class="test">
                                    <div class="row no-gutters mb-2 py-4">
                                        <div class="col">
                                            <h4 class="text-center">{{ 'kaucun_article_lier' | translate }}</h4>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </mat-step>
                </mat-vertical-stepper>

                <mat-list *ngIf="isDevis">
                    <ng-container *ngIf="listNonServiceItems && listNonServiceItems.length > 0">
                        <mat-list-item *ngFor="let rowItem of listNonServiceItems;let j=index" class="test">
                            <div class="row no-gutters mb-2">
                                <div class="col-2 col-md-2">
                                    <div class="">
                                        <img [src]="getImageURL(rowItem.cdart)" class="img-fluid" [alt]="rowItem.cdart" default="assets/images/no_img.png" />
                                    </div>
                                </div>
                                <div class="col-10 col-md-10 d-flex">
                                    <div class="d-flex w-100 justify-content-between align-items-center pl-4">
                                        <mat-checkbox [(ngModel)]="selectedItem[rowItem.idlig]" (ngModelChange)="onSelectedItemChange($event, rowItem)" [ngModelOptions]="{ standalone: true }" matLine>{{ rowItem.lib }}</mat-checkbox>
                                        <span class="badge" *ngIf="nonServiceArticleHashTable[rowItem.cdart] && nonServiceArticleHashTable[rowItem.cdart].calculated_price != null && nonServiceArticleHashTable[rowItem.cdart].calculated_price !== '' && +nonServiceArticleHashTable[rowItem.cdart].calculated_price > 0">{{ +nonServiceArticleHashTable[rowItem.cdart].calculated_price | cap_currency }}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                    </ng-container>

                    <ng-container *ngIf="!listNonServiceItems || listNonServiceItems.length === 0">
                        <mat-list-item class="test">
                            <div class="row no-gutters mb-2 py-4">
                                <div class="col">
                                    <h4 class="text-center">{{ 'kaucun_article_lier' | translate }}</h4>
                                </div>
                            </div>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </form>
                
            <h4 class="text-danger mt-4" *ngIf="chosenIdLigValue && autreChosenIdLigValue">{{ 'kun_choix_possible' | translate }}</h4>
            
            <div class="text-right">
                <button type="submit" mat-button [title]="'ksvalid' | translate" *ngIf="!(chosenIdLigValue && autreChosenIdLigValue) && (chosenIdLigValue || autreChosenIdLigValue)" (click)="articleSelectionFormSubmitter()">
                    <mat-icon>done</mat-icon>
                    <span class="d-none d-sm-none d-md-block">
                        {{'ksvalid' | translate}}
                    </span>
                </button>
                <button mat-button class="ml-2" [title]="'kucancel' | translate" [mat-dialog-close]>
                    <mat-icon>close</mat-icon>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kucancel' | translate}}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
