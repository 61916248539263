import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Article} from '../../../models/articles';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Constants} from '../../../providers/constants';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogsService} from '../../../providers/api/dialogService';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../providers/api/userService';
import {PanierArticle} from '../../../models/bonPanier';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserModel } from 'models/user-model';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { BarcodeScannerComponent } from '../barcode-scanner/barcode-scanner.component';
import { BonVenteService } from 'providers/api/bonventeService';

@Component({
  selector: 'app-non-service-articles-list',
  templateUrl: './non-service-articles-list.component.html',
  styleUrls: ['./non-service-articles-list.component.scss']
})
export class NonServiceArticlesListComponent implements OnInit {

  currentItem: PanierArticle;
  listNonServiceArticles: Article[] = [];
  listNonServiceItems: PanierArticle[] | any = [];
  nonServiceArticleHashTable: { [key: string]: Article } = {};

  listAutreNonServiceArticles: Article[] = [];
  listAutreNonServiceItems: PanierArticle[] | any = [];
  autreNonServiceArticleHashTable: { [key: string]: Article } = {};

  articleSelectionForm: UntypedFormGroup;
  selectedItem: { [key: number]: boolean } = {};
  autreSelectedItem: { [key: number]: boolean } = {};

  codeClient: any;
  isMobile: boolean;
  isDevis: boolean = false;
  isBonDeVente: boolean = false;
  isBonDeCommande: boolean = false;
  bonNumBon: string;
  
  user: UserModel;
  USERCMINT: any;

  inputToFocus: string;

  isServiceArticleIsADelivery: string = '';

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<NonServiceArticlesListComponent>,
      private formBuilder: UntypedFormBuilder,
      private userService: UserService,
      private dialogService: DialogsService,
      private translateService: TranslateService,
      private loadingService: NgxSpinnerService,
      private deviceDetector: DeviceDetectorService,
      private bonCommandeService: BonCommandeService,
      private dialog: MatDialog,
      private bonVenteService: BonVenteService) {
    this.articleSelectionForm = this.formBuilder.group({
      CHOSEN_IDLIG: [null],
      NUMBON: [null],
      AUTRE_CHOSEN_CDART: [null],
    });
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    if (this.data !== undefined && this.data !== null) {
      if (this.data.nonServiceArticles !== undefined && this.data.nonServiceArticles !== null) {
        this.listNonServiceArticles = this.data.nonServiceArticles;
      }

      if (this.data.nonServiceItems !== undefined && this.data.nonServiceItems !== null) {
        this.listNonServiceItems = this.data.nonServiceItems;
      }

      if (this.data.nonServiceItems !== undefined && this.data.nonServiceItems !== null) {
        this.nonServiceArticleHashTable = this.data.nonServiceArticleHashTable;
      }

      if (this.data.currentItem !== undefined && this.data.currentItem !== null) {
        this.currentItem = this.data.currentItem;
      }

      this.isDevis = this.data?.isDevis;
      this.isBonDeVente = this.data?.isBonDeVente;
      this.isBonDeCommande = this.data?.isBonDeCommande;

      this.bonNumBon = this.data?.bonNumBon;
    }

    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();

    // Not used... Can be used in the future
    // await this._getCodeClient();
    if (this.isBonDeVente) {
      await this.checkVenteArticleNonConformStatus();

    } else if (this.isBonDeCommande) {
      await this.checkCommandeArticleNonConformStatus();
    }

    await this.checkServiceArticleIsADelivery(this.currentItem.cdart);

    this.loadingService.hide();
  }

  async checkVenteArticleNonConformStatus() {
    return new Promise((resolve, reject) => {
      this.bonVenteService.checkBondeVenteStatus(this.bonNumBon, this.USERCMINT).subscribe(
        async (resp) => {
          if (resp.statusCode == 200 && resp.data.length > 0) {
            const combinedArray = this.listNonServiceItems.map((itemA) => {
              const matchingItemB = resp.data.find((itemB) => itemB.ARTEAN === itemA.cdart);
            
              if (matchingItemB) {
                return { ...itemA, ETAT: matchingItemB.ETAT };
              }
            
              return itemA;
            });
            
            this.listNonServiceItems = combinedArray.filter((item) => item.ETAT !== "17");

            resolve(resp);
          }
        },
        err => {
          reject(err);
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      )
    });
  }

  async checkCommandeArticleNonConformStatus() {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.checkBondeCommandeStatus(this.bonNumBon, this.USERCMINT).subscribe(
        async (resp) => {
          if (resp.statusCode == 200 && resp.data.length > 0) {
            const combinedArray = this.listNonServiceItems.map((itemA) => {
              const matchingItemB = resp.data.find((itemB) => itemB.ARTEAN === itemA.cdart);
            
              if (matchingItemB) {
                return { ...itemA, ETAT: matchingItemB.ETAT };
              }
            
              return itemA;
            });
            
            this.listNonServiceItems = combinedArray.filter((item) => item.ETAT !== "17");

          }
          resolve(resp);
        },
        err => {
          reject(err);
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      )
    });
  }

  async _getCodeClient() {
    try {
      this.codeClient = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  getCdartWithPlacehold(CDART: string): string {
    return (Constants.CONFORAMA_CDART_PLACEHOLDER).replace('{{CDART_PLACEHOLDER}}', CDART);
  }

  get chosenIdLig() {
    return this.articleSelectionForm.get('CHOSEN_IDLIG');
  }

  get chosenIdLigValue() {
    return this.articleSelectionForm.get('CHOSEN_IDLIG').value;
  }

  get autreChosenIdLig() {
    return this.articleSelectionForm.get('AUTRE_CHOSEN_CDART');
  }

  get autreChosenIdLigValue() {
    return this.articleSelectionForm.get('AUTRE_CHOSEN_CDART').value;
  }

  getImageURL(CDART: string): string {
    if (this.codeClient && this.codeClient.toLowerCase() === 'conforama') {
      // To generate Conforama Image URL Algorithm
      let concatenated = '';
      const length = Constants.CONFORMA_IMAGE_SEGMENT_LENGTH;
      for(let i = 0; i < length; i++) {
        if(i < (length - 1)) {
          if(concatenated !== '') {
            concatenated += "/"
          }

          if(CDART[i] !== undefined) {
            concatenated += (CDART[i].padEnd(length - i, '0'));
          } else {
            concatenated += ('0'.padEnd(length - i, '0')); // Prefixing Zero if the CDART length is small as mentioned in CAP-1056
          }

        }
      }

      const URL = Constants.CONFORAMA_IMAGE_URL + concatenated + '/' + this.getCdartWithPlacehold(CDART) + Constants.DEFAULT_IMAGE_EXTENSION;
      return URL;
    }

    return Constants.BASE_IMAGE_URL + CDART + Constants.DEFAULT_IMAGE_EXTENSION;
  }

  onStepChange(event: any) {
    if(event.selectedIndex === 1) {
      this.inputToFocus = 'numbonInput'
      return;
    }
    this.inputToFocus = null
  }

  setFocus() {
    if(this.inputToFocus === 'numbonInput') {
      const targetElement = document.getElementById(this.inputToFocus)
      targetElement.focus();
    }
  }

  onSelectedItemChange(status: boolean, currentItem: PanierArticle) {
    this.chosenIdLig.setValue(undefined);
    this.selectedItem = {};
    if (status) {
      this.chosenIdLig.setValue(currentItem.idlig.toString());
      this.selectedItem[currentItem.idlig] = true;
    }
  }

  onAutreSelectedItemChange(status: boolean, currentItem: PanierArticle) {
    this.autreChosenIdLig.setValue(undefined);
    this.autreSelectedItem = {};
    if (status) {
      this.autreChosenIdLig.setValue(currentItem.cdart.toString());
      this.autreSelectedItem[currentItem.cdart] = true;
    }
  }

  // Searching other Bon-de-commande with BON ID and populating non service articles
  async searchBondecommandeArticles(event: any) {
    if (event) {
      event.preventDefault();
    }

    const pNumBon = this.articleSelectionForm.get('NUMBON')?.value
    const pMag = this.USERCMINT;

    if(!pNumBon) return; 

    this.loadingService.show();

    await this.getAutreNonSeriveArticles(pNumBon, pMag)

  }

  async getAutreNonSeriveArticles(pNumBon: any, pMag: any) {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.getBonCommandeDetail(pNumBon, pMag, '').subscribe(
        async (resp) => {
          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {
              this.listAutreNonServiceArticles = resp?.data?.customArticles.filter((row) => row.CAGESTOCK == '-1');
              
              this.autreNonServiceArticleHashTable = [...this.listAutreNonServiceArticles].reduce((acc, it) => {
                if (!acc.hasOwnProperty(it.CDART)) {
                  acc[it.CDART] = {};
                }
          
                acc[it.CDART] = it;
                return acc;
              }, {});
              
              const listAutreNonServiceItems = resp?.data?.Panier?.articles.filter(rw => {
                return rw.opt <= 0 && (this.autreNonServiceArticleHashTable[rw.cdart] && this.autreNonServiceArticleHashTable[rw.cdart].CAGESTOCK == '-1');
              });

              await this.checkAutreCommandeArticleNonConformStatus(pNumBon, listAutreNonServiceItems);
            
              this.loadingService.hide();
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
            }
            resolve(resp);
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve(resp);
          }
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      )
    });
  }

  async checkAutreCommandeArticleNonConformStatus(pNumBon: any, listAutreNonServiceItems: any) {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.checkBondeCommandeStatus(pNumBon, this.USERCMINT).subscribe(
        async (resp) => {
          if (resp.statusCode == 200 && resp.data.length > 0) {
            const combinedArray = listAutreNonServiceItems.map((itemA) => {
              const matchingItemB = resp.data.find((itemB) => itemB.ARTEAN === itemA.cdart);
            
              if (matchingItemB) {
                return { ...itemA, ETAT: matchingItemB.ETAT };
              }
            
              return itemA;
            });
            
            this.listAutreNonServiceItems = combinedArray.filter((item) => item.ETAT !== "17");

            this.loadingService.hide();
          }
          resolve(resp);
          this.loadingService.hide();
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      )
    });
  }

  async articleSelectionFormSubmitter() {
    const formData = this.articleSelectionForm.getRawValue();

    const serviceItem = this.currentItem; // Service
    let nonServiceItem: PanierArticle; // Non service

    if (formData.CHOSEN_IDLIG !== undefined && formData.CHOSEN_IDLIG !== null) {
      nonServiceItem = this.listNonServiceItems.find((rw: PanierArticle) => rw.idlig == formData.CHOSEN_IDLIG);
    } else {
      nonServiceItem = this.listAutreNonServiceItems.find((rw: PanierArticle) => rw.cdart == formData.AUTRE_CHOSEN_CDART);
    }

    // For devis/bon-de-vente - api to check the service article is delivery (CAOKLIV from capcash.cashart)
    if (this.isServiceArticleIsADelivery === "-1" && !(nonServiceItem?.typecde === "BL" || nonServiceItem?.typecde === "E2/BL")) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('Karticle_delivery_not_possible'));
      return;
    }

    this.dialogRef.close({ status: 'success', data: formData });
  }

  async checkServiceArticleIsADelivery(cdart: any) {
    this.loadingService.show();
    
    return new Promise((resolve, reject) => {
      this.bonCommandeService.checkServiceArticleIsDelivery(cdart).subscribe(
        async (resp) => {
          if (resp.statusCode == 200 && resp?.data?.length > 0) {
            this.isServiceArticleIsADelivery = resp?.data[0]?.CAOKLIV
          }
          resolve(resp);
        },
        err => {
          reject(err);
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      )
    });
  }

  // Barcode scanner methods...
  openScanArticle(event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: this.isMobile ? '95vh' : undefined,
        autoFocus: false
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result && result.status === 'success') {
        this._barCodeLogicCheck(result.data);
      }
    });
  }

  async _barCodeLogicCheck(value: string) {
    if (value !== undefined && value != null) {

      if (value.indexOf('~') > -1) {
        const [CDART, PRIX] = value.split('~');
        return;
      }

      if (value.length === Constants.BARCODE_HIGHEST_LENGTH) {
        let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
        CDART = CDART.replace(/^0+/, '');
        return;
      }

      this.loadingService.show();
      this.articleSelectionForm.patchValue({
        NUMBON: value
      })
      await this.getAutreNonSeriveArticles(value, this.USERCMINT)
    }
  }
}
