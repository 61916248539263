<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{'kgdeviss' | translate}}
            </h1>
        </div>        
        <div class="col-12" *ngIf="isMobile">
            <div class="d-flex align-items-center justify-content-center">
                <button type="button" class="bt_valid w-100 pad10" *ngIf="!detailDevisVisibility" [routerLink]="['/create-devis']" mat-button >
                    <mat-icon class="addicon">add_circle</mat-icon>
                    {{ 'kmaddbtn' | translate }}
                </button>
            </div>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile && filterValues">
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>

    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium">{{'kclclearform' | translate}}</div>
                </button>
            </div>
        </div>
    </div>

    <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field>
                        <input matInput formControlName="NUMBON" [placeholder]="'kdevbonl' | translate" class="text-uppercase">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field>
                        <input matInput formControlName="NOM"  [placeholder]="'kpname' | translate" class="text-uppercase">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field>
                        <input matInput formControlName="PRENOM" [placeholder]="'kuprenom' | translate" class="text-uppercase">
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field>
                        <input matInput formControlName="TEL_PORT" [placeholder]="'kcltel' | translate" class="text-uppercase">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput formControlName="CODPOS"
                                    [placeholder]="'kclcp' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-md-8 pl-3">
                            <mat-form-field>
                                <input matInput formControlName="VILLE"
                                    [placeholder]="'kclcity' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row no-gutters">
                        <div [class]="dateAct === 'between'?'col-6':'col-12'">
                            <mat-form-field floatLabel="always" class="date">
                                <input matInput formControlName="DATE" (dateChange)="onDateChanged()" [matDatepicker]="cdeDate"
                                    [placeholder]="'kbondate' | translate"
                                    class="text-uppercase">
                                <mat-datepicker-toggle matSuffix [for]="cdeDate">
                                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #cdeDate [disabled]="false"></mat-datepicker>
                                <button mat-icon-button type="button" *ngIf="searchStartDate.value" (click)="_clearDate('DATE')" matSuffix ><mat-icon>close</mat-icon></button>
                                <button matSuffix type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                                    <mat-icon class="d-flex justify-content-center align-items-center">
                                        <span style="line-height: initial;" *ngIf="dateAct === 'equal'">=</span>
                                        <span style="line-height: initial;" *ngIf="dateAct === 'greater_equal'">>=</span>
                                        <span style="line-height: initial;" *ngIf="dateAct === 'less_equal'"><=</span>
                                        <span style="line-height: initial;" *ngIf="dateAct === 'between'"><></span>
                                    </mat-icon>
                                </button>                                
                            </mat-form-field>
                            <mat-menu #menu="matMenu" [hasBackdrop]="false">
                                <button mat-menu-item (click)="changeDateAct('equal')">{{'kbondategale' | translate}}</button>
                                <button mat-menu-item (click)="changeDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                                <button mat-menu-item (click)="changeDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                                <button mat-menu-item (click)="changeDateAct('between')">{{'kbondatentre' | translate}}</button>
                            </mat-menu>
                        </div>
                        <div class="col-6 pl-3" *ngIf="dateAct === 'between'">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="DATE_END" [min]="minDateAct" [matDatepicker]="cdeEndDate"
                                    [placeholder]="'kbonand' | translate"
                                    class="text-uppercase">
                                    
                                <mat-datepicker-toggle matSuffix [for]="cdeEndDate">
                                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #cdeEndDate [disabled]="false"></mat-datepicker>
                                <button mat-icon-button type="button" *ngIf="searchEndDate.value" (click)="_clearDate('DATE_END')" matSuffix ><mat-icon>close</mat-icon></button>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4 col-md-4">
                    <div class="row no-gutters">
                        <div class="col-6">
                            <mat-form-field>
                                <input matInput formControlName="TOTAL_ENTRE_FROM"
                                    [placeholder]="'kbontotbetn' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-6 pl-4">
                            <mat-form-field>
                                <input matInput formControlName="TOTAL_ENTRE_TO"
                                    [placeholder]="'kbonand' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-4">
                    <mat-checkbox formControlName="USERCON"> {{'kbonuser' | translate}} </mat-checkbox>
                </div>
                <div class="col-12 col-md-4 mt-3">
                    <div class="radio-container">
                        <mat-label>{{ "kartstate" | translate }}</mat-label>
                        <mat-radio-group formControlName="ETAT" aria-label="Select an option">
                            <mat-radio-button *ngFor="let item of statusList" [value]="item.id">
                                <span [translate]="item.label" class="d-block" [class.devis_valide]="item.id == '2'" [class.devis_en_cours]="item.id == '1'"></span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button type="submit" class="bt_valid" mat-button>
                        <mat-icon>search</mat-icon>
                        {{ 'kpsearch' | translate }}
                    </button>
                    <button type="button" class="bt_valid ml-3" *ngIf="!detailDevisVisibility" [routerLink]="['/create-devis']" mat-button>
                        <mat-icon>add_circle</mat-icon>
                        {{ 'kmaddbtn' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">
        <div class="row">
            <div class="col-12 no-padding table-scroller">
                <div class="">
                    <mat-paginator *ngIf="!showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

                <table datatable class="row-border hover devis" *ngIf="!isMobile" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead>
                        <tr>
                            <th width="15%" class="nom">{{ 'kdevbonl' | translate }}</th>
                            <th class="nom">{{ 'ksseller' | translate }}</th>
                            <th class="nom">{{ 'kbonnompre' | translate }}</th>
                            <th class="cp" width="10%">{{ 'kboncpville' | translate }}</th>
                            <th class="tel" width="14%">{{ 'kcltel' | translate }}</th>
                            <th class="date" width="8%" >{{ 'kbondate' | translate }}</th>
                            <th class="port">{{ 'kkvalidate' | translate }}</th>
                            <th class="port" width="5%">{{ 'kbontotal' | translate }}</th>
                            <th width="9%">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listDevis?.length != 0 && !showLoader">
                        <tr *ngFor="let devi of listDevis" >
                            <td class="numero" nowrap (click)="editDevis(devi)">
                               <span class="colorcode" [class]="defineRowColor(devi)"></span> {{ devi.numbon | slice:0:2 }} {{ devi.numbon | slice:2:4 }} {{ devi.numbon | slice:4:7 }} {{ devi.numbon | slice:7:12 }} {{ devi.numbon | slice:12:15 }}
                            </td>
                            <td class="prenom" (click)="editDevis(devi)">{{ devi.libuser }}</td>
                            <td class="prenom" (click)="editDevis(devi)" nowrap>{{devi.civlib?' '+devi.civlib:'' }}{{ devi.nom?' '+devi.nom:'' }}<br> {{ devi.prenom | uppercase  }}</td>
                            <td class="cp" (click)="editDevis(devi)">{{ devi.cp }}<br> {{ devi.ville | uppercase }}</td>
                            <td class="tel" (click)="editDevis(devi)" [innerHtml]="(devi.gsm | formatTele)+(devi.tel?(devi.gsm !== '' && devi.gsm != null?'<br />/ ':'')+(devi.tel | formatTele):'')"></td>
                            <td class="date" (click)="editDevis(devi)">{{ listDateFormatter(devi.dtmaj) }}</td>
                            <td class="date" (click)="editDevis(devi)">{{ listValideDateFormatter(devi.dtech) }}</td>
                            <td class="port" (click)="editDevis(devi)" nowrap>
                                <div style="width: 60px;white-space: break-spaces;">{{ devi.montant | cap_currency:null:false }}</div>
                            </td>
                            <td nowrap>
                                <button type="button" mat-mini-fab color="primary" (click)="editDevis(devi)" class="ml-1 mr-1">
                                    <mat-icon>{{ detailDevisVisibility?'visibility':'edit' }}</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="warn" (click)="deleteConfirmation(devi)" [disabled]="devi.statut == 2" *ngIf="!detailDevisVisibility">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="listDevis?.length == 0 || showLoader">
                        <tr>
                            <td colspan="9" *ngIf="listDevis?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                            <td colspan="9" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>

                <mat-list *ngIf="isMobile && (listDevis?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="listDevis?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">                        
                        <p matLine class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                    </mat-list-item>
                </mat-list>
                
                <mat-list *ngIf="isMobile && (listDevis?.length != 0 && !showLoader)">
                    <mat-list-item  *ngFor="let devi of listDevis;let i=index;" [id]="'swipeItem-' + i" style="user-select: none;" (swipe)="onSwipe($event, i)">
                        <div class="swipedContent">
                            <button type="button" mat-mini-fab color="warn" (click)="deleteConfirmation(devi)" [disabled]="devi.statut == 2" *ngIf="!detailDevisVisibility">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div class="w-100 list-item-main-content" (click)="editDevis(devi)">
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <p class="m-0">
                                    <span class="colorcode" [class]="defineRowColor(devi)"></span> {{ devi.numbon | slice:0:2 }} {{ devi.numbon | slice:2:4 }} {{ devi.numbon | slice:4:7 }} {{ devi.numbon | slice:7:12 }} {{ devi.numbon | slice:12:15 }}
                                </p>
                                <p class="m-0">{{ listDateFormatter(devi.dtmaj) }}</p>
                            </div>
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <div matLine class="mobile-list-title">{{devi.civlib?' '+devi.civlib:'' }} {{ devi.nom | uppercase }} {{ devi.prenom | uppercase  }}</div>
                                <p class="m-0">{{ devi.libuser | uppercase }}</p>
                            </div>
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <p matLine class="mobile-list-title">
                                    {{ devi.cp }} {{ devi.ville?devi.ville+(devi.gsm?' , ':''):'' | uppercase }} <br>
                                    {{ devi.gsm | formatTele }} {{ devi.tel?(devi.gsm !== '' && devi.gsm != null?' / ':'')+(devi.tel | formatTele):'' }}
                                </p>
                                <p matLine class="mobile-list-title text-right no-margin"> 
                                    <span class="badge badge-secondary">{{ devi.montant | cap_currency:null:false }}</span>
                                    <!-- <span>{{ devi.typecde }}</span> -->
                                </p>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list> 
            </div>
        </div>
    </div>    
</div>
