<div class="modal-container w-100" id="couche_prix_article">
    <form >
        <mat-card>
            <mat-card-header  *ngIf="!isMobile">
                <mat-card-title class="text-uppercase text-center ">
                    <div class="row">
                        <div class="col-11 text-left">
                            <h4 class="modal_title">{{ "kdesaffecter" | translate }}</h4>
                        </div>
                        <div class="col-1 text-right no-padding">
                            <button mat-button [mat-dialog-close] class="bt_close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content [class.bnone]="isMobile">
                <div class="mt-3">
                    <div class="row">
                        <div class="col-3">
                            <mat-form-field floatLabel="always">
                                <mat-label>{{'kdate' | translate}} </mat-label>
                                <input matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-3">
                            <mat-form-field floatLabel="always">
                                <mat-label>{{'kbonref' | translate}} </mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>

            <mat-card-actions class="text-right" *ngIf="!isMobile">
                <div class="row no-gutters">
                    <div class="col-md-12 pr-2">
                        <button type="submit" mat-button class="bt_send">
                            <i class="material-icons">check</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'kuok' | translate}}
                            </span>
                        </button>
                        <button mat-button [mat-dialog-close] class="bt_send m-0 ml-2">
                            <i class="material-icons">close</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'ktcol' | translate}}
                            </span>
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
