import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Constants } from '../constants';

@Injectable()
export class MagComplementService {

    constructor(private http: HttpClient) {
    }   

    getMagComplementData(IDMAG: number): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['IDSOC'] = Constants.IDSOC;
        params['CMINT'] = IDMAG;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_MAG_COMPLEMENT_URL + 'getMagasinComplement', options);
    }

    updateMagComplementData(postData){
        return this.http.post(Constants.API_MAG_COMPLEMENT_URL + 'updateMagasinComplement', postData);
    }

}
