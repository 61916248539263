import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import {DataTableDirective} from 'angular-datatables';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';

import ReportsPending from 'models/ReportPending';
import { DialogsService } from 'providers/api/dialogService';
import { ReportingService } from 'providers/api/reportingService';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-reporting-attende',
  templateUrl: './reporting-attende.component.html',
  styleUrls: ['./reporting-attende.component.scss'],
  providers: [ReportingService]
})
export class ReportingAttendeComponent implements OnInit {
  pageTitle = 'kreporting_attente';
  isMobile: boolean = false;
  showLoader = false;    

  searchForm: any;
  
  listPendingReports: ReportsPending[] = [];
  columns = ['kreport_name', 'kreport_status', 'kreport_date'];

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number = 10;

  user: any
  baseURL: string;

  reportStatusTypes = [
    {
      label: 'kuall',
      value: ''
    },
    {
      label: 'kreport_traiter',
      value: Constants.REPORT_TODO
    },
    {
      label: 'kreport_en_cours',
      value: Constants.REPORT_INPROGRESS
    },
    {
      label: 'kreport_disponsible',
      value: Constants.REPORT_AVAILABLE
    },
    {
      label: 'kreport_erreur',
      value: Constants.REPORT_ERROR
    },
    {
      label: 'kreport_consulte',
      value: Constants.REPORT_CONSULTED
    },
  ]

  enableStatusFilter: boolean = false;
  selectAll: boolean = false;

  refreshReportsSubscription: Subscription;
  deleteReportSubscription: Subscription;

  constructor(private deviceService: DeviceDetectorService,
    private loadingService: NgxSpinnerService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private reportingService: ReportingService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.user = this.userService.getCurrentUser();

    this.searchForm = this.formBuilder.group({
      reportStatus: [''],
    });

    this.refreshReportsSubscription = this.userService.headerRefreshReportListener.subscribe(
      (status: boolean) => {
        if(status === true) {
          this.searchFormSubmitter();
        }
      }
    )

    this.deleteReportSubscription = this.userService.headerDeleteReportListener.subscribe(
      (status: string) => {
        if(status === 'clicked') {
          this.removeReport();
        }
      }
    )

    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
          first: this.translateService.instant('kfirst'),
          previous: this.translateService.instant('kprevious'),
          next: this.translateService.instant('knext'),
          last: this.translateService.instant('klast'),
        }
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.loadingService.hide();
    this.showLoader = true;
    await this.getReportStatus();

    setTimeout(() => {
      this.dtTrigger.next();
   }, 400);
  }

  async getReportStatus(page?: number, status?: number): Promise<void> {
    this.reportingService.getReportStatus(page, status).subscribe((res: any) => {
        if(res.success !== undefined) {
          this.listPendingReports = res.data;
          this.listPendingReports.forEach(item => item.selected = false); // For checkboxes
          this.rowsPerPage = res.rowsPerPage;
          this.totalPages = res.count;
          this.baseURL = res.basePath;

          this.storageService.setItem('cap-listPendingReports', this.listPendingReports);
          this.showLoader = false;
          this.dtTrigger.next();
        } else {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      },
      err => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  paginationChange(event: any) {
    this.showLoader = true;
    const changedPageIndex = event.pageIndex;    
    this.currentPage = changedPageIndex;

    this.getReportStatus(changedPageIndex + 1);
  }

  async searchFormSubmitter() {
    this.showLoader = true;
    // const formData = this.searchForm.getRawValue();
    // await this.getReportStatus(1, formData.reportStatus);

    await this.getReportStatus();
  }

  reportStatus(code: string) {
    switch(code) {
      case Constants.REPORT_TODO:
        return 'kreport_traiter';
      case Constants.REPORT_INPROGRESS: 
        return 'kreport_en_cours';
      case Constants.REPORT_AVAILABLE: 
        return 'kreport_disponsible';
      case Constants.REPORT_ERROR: 
        return 'kreport_erreur';
      case Constants.REPORT_CONSULTED: 
        return 'kreport_consulte';
      default:
        return 'kreport_traiter';
    }
  }

  reportStatusBadge(code: string) {
    switch(code) {
      case Constants.REPORT_TODO: 
        return 'badge badge-info';
      case Constants.REPORT_INPROGRESS: 
        return 'badge badge-info';
      case Constants.REPORT_AVAILABLE: 
        return 'badge badge-success';
      case Constants.REPORT_ERROR: 
        return 'badge badge-danger';
      case Constants.REPORT_CONSULTED: 
        return 'badge badge-success';
      default:
        return 'badge badge-info';
    }
  }

  isReportAvailable(code: string) {
    return code === Constants.REPORT_AVAILABLE || code === Constants.REPORT_CONSULTED;
  }

  getSelectedIds(id?: number): number[] {
    if (id) {
      return [id];
    }

    return this.listPendingReports.filter(item => item.selected).map(item => item.TROWID);
  }
  
  removeReport(id?: number): Promise<void> {
    this.loadingService.show();
    const data = {
      TROWID: this.getSelectedIds(id),
    }

    return new Promise((resolve, reject) => {
      this.reportingService.deleteReportStatus(data).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            const listPendingReports = this.storageService.getItem('cap-listPendingReports');
            this.listPendingReports = listPendingReports.filter((item: ReportsPending) => {
              return !data.TROWID.includes(item.TROWID);
            });

            this.storageService.setItem('cap-listPendingReports', this.listPendingReports);
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kreport_deleted'));
            resolve();            
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });   
  }

  updateViewedReport(TROWID: number): Promise<void> {
    this.loadingService.show();
    const data = {
      TROWID
    }

    return new Promise((resolve, reject) => {
      this.reportingService.updateViewedReportStatus(data).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            const listPendingReports = this.storageService.getItem('cap-listPendingReports');
            this.listPendingReports = listPendingReports.map((item: ReportsPending) => {
              if (item.TROWID == data.TROWID) {
                return { ...item, TRAITE: Constants.REPORT_CONSULTED};
              }
              return item;
            });

            this.storageService.setItem('cap-listPendingReports', this.listPendingReports);
            this.loadingService.hide();
            resolve();            
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });   
  }

  async downloadPDF(reportName: string, id: number) {
    try {
      if (!reportName || reportName == "") {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kreport_not_available'));
        return;
      }

      await this.updateViewedReport(id);

      const fileUrl = this.baseURL+reportName;
      window.open(fileUrl);
    } catch (err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  formatDate(date: Date) {
    if(date === undefined || date  === null) {
      return '';
    }
    
    return moment(date).format('DD-MM-YYYY');
  }

  // Checkbox functions
  toggleSelectAll(selected: boolean) {
    this.selectAll = selected;
    if (this.listPendingReports == null) {
      return;
    }
    this.listPendingReports.forEach(list => (list.selected = selected));

    this.handleMobileRefreshAndDeleteIcons(selected);
  }

  someSelected() {
    if (this.listPendingReports == null) {
      return false;
    }
    return this.listPendingReports.filter(list => list.selected).length > 0 && !this.selectAll;
  }

  toggleItemSelection(selected: boolean) {
    this.selectAll = this.listPendingReports != null && this.listPendingReports.every(list => list.selected);

    this.handleMobileRefreshAndDeleteIcons(this.getSelectedIds().length > 0);
  }

  handleMobileRefreshAndDeleteIcons(check: boolean) {
    if (this.isMobile) {
      if (check) {
        this.userService.headerDeleteReportListener.next('selected');
      } else {
        this.userService.headerDeleteReportListener.next('unselected');
      }
    }
  }

  onSwipe(e: any, index: number) {
    const currentRowItem = this.listPendingReports[index];

    if(e.deltaX > 0) {
      // Right Swipe
      const element = document.getElementById('swipeItem-' + index);
      element.style.transform = 'translateX(0)'
      element.classList.remove('swiped');
    } else {
      // Left Swipe
      this.__resetPreviousSwipes('.mat-list-item');

      const element = document.getElementById('swipeItem-' + index);
      element.classList.add('swiped');
      element.style.transform = 'translateX(-100px)'
    }
  }

  __resetPreviousSwipes(listEl: string) {
    const allSwipeAbles = document.querySelectorAll(listEl);
    allSwipeAbles.forEach((el: any, i) => {
      el.classList.remove('swiped');
      el.style.transform = 'translateX(0)';
    })
  }
}
