<div [class.modal-container]="displayType === 'dialog'" class="pt-3" id="couche_prix_article">
    <mat-card [class.no-padding]="isMobile">
        <mat-card-header *ngIf="displayType === 'dialog'">
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>kartcoprix</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row no-gutters">
                <div class="col-12 no-padding table-scroller">

                    <table id="couche_prix_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable no-footer hover">
                        <thead>
                            <tr>
                                <th translate class="lib_court">kartstart</th>
                                <th translate >kartend</th>
                                <th translate >kartprice</th>
                                <th translate >kartcampagne</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="ListCouche?.length != 0 && !showLoader">
                            <tr *ngFor="let couche of ListCouche">
                                <td class="text-center">
                                    {{ couche.PRIXDDEB | date: 'dd/MM/yyyy' }}
                                </td>
                                <td class="text-center">
                                    {{ couche.PRIXDFIN | date: 'dd/MM/yyyy'  }}
                                </td>
                                <td class="text-center">
                                    {{ (couche.CAPVTTC?couche.CAPVTTC:0) | number: '0.2' }}
                                </td>
                                <td class="text-center">
                                    {{ couche.DOSNOM }}
                                </td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="ListCouche?.length == 0 || showLoader">
                            <tr>
                                <td  colspan="4" *ngIf="ListCouche?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                <td colspan="4" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>                                
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                            </tr>                            
                        </tbody>
                    </table>

                </div>
            </div>
        </mat-card-content>
        <mat-divider *ngIf="displayType === 'dialog'"></mat-divider>

        <mat-card-actions class="text-center" *ngIf="displayType === 'dialog'">
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
</div>