<div class="modal-container" id="detail_entrepot_article">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">
                            {{'kartstockentrepot' | translate}}
                        </h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-12 no-padding table-scroller">
                    <table datatable [dtOptions]="dtOptions" id="articleStockEntreprots" [dtTrigger]="dtTrigger" class="row-border hover">
                        <thead>
                            <tr>                                
                                <th translate>kartware</th>
                                <th translate>kartstock</th>                                
                            </tr>
                        </thead>
                        <tbody *ngIf="listEntreprots?.length != 0 && !showLoader">
                            <tr *ngFor="let entrepot of listEntreprots">                                
                                <td class="text-center">{{ entrepot.LIBEL }}</td>
                                <td class="text-center">{{ entrepot.CSKDISPOVTE | number: '0.2'  }}</td>                                
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="listEntreprots?.length == 0 || showLoader">
                            <tr>
                                <td  colspan="2" *ngIf="listEntreprots?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                <td colspan="2" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>                                
                                <td style="display: none;"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button [mat-dialog-close] class="bt_send">                
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
</div>