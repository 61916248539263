import {Component, OnInit, ViewChild} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ReservationComponent} from './reservation/reservation.component';
import {ConfirmDialogComponent} from '../../components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modifier-gestion-des-e2',
  templateUrl: './modifier-gestion-des-e2.component.html',
  styleUrls: ['./modifier-gestion-des-e2.component.scss']
})
export class ModifierGestionDesE2Component implements OnInit {

  isMobile: boolean;
  showLoader = false;

  numBon = '';
  bonClientNom = '';
  bonDuDate = '';
  bonCpVille = '';

  listArticles: any[] = [];

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private deviceDetector: DeviceDetectorService,
              private dialog: MatDialog,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
  }

  openUpModifierPopup() {
    const dialogRef = this.dialog.open(ReservationComponent,
        {
          height: this.isMobile ? '100%' : undefined,
          minWidth: '45vw',
          data: {
            displayType: 'dialog',
          }
        }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  async affectationGlobaleConfirmation() {
    const message = `<h1 class="text-left mb-3">${this.translateService.instant('kaffectationglobaleconf')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'), this.translateService.instant('ksno')]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
      }
    });
  }

  listDateFormatter(date: string, offset = true, today_time = true) {
    if (date === undefined || date  === null) {
      return '';
    }

    const given_date = moment(date).format('DD.MM.YYYY');
    const today_date = moment().format('DD.MM.YYYY');

    if (given_date === undefined || given_date === '30.12.1899' || given_date === '29.12.1899') {
      return '';
    }

    if (given_date === today_date && today_time) {
      return moment(date).utcOffset(date).format('HH:mm');
    }

    if (offset) {
      return moment(date).utcOffset(date).format('DD.MM.YY');
    }
    return moment(date).format('DD.MM.YY');
  }

}
