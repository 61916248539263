import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: 'img[default]',
    host: {      
      '[src]':'src'
     }
})

export class DefaultImage {
    @Input() src:string;
    @Input() default:string;
    @Input() getLost: boolean = false;

    constructor(private el: ElementRef) {
    }
  
    @HostListener('error') onError() {
        this.updateUrl();
    }

    updateUrl() {
      if(this.getLost) {
        this.el.nativeElement.style.display = 'none'; 
      } else {
        this.src = this.default;
      }
    }
  }