import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { isNil, reverse } from 'lodash';

import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from "ngx-spinner";
import {
  TreeviewItem, TreeviewConfig, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem, TreeviewI18n
} from 'ngx-treeview2';
import { TreeviewTranslation } from 'providers/treeview-translation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-merchandise-de-vente',
  templateUrl: './merchandise-de-vente.component.html',
  styleUrls: ['./merchandise-de-vente.component.scss'],
  providers: [DialogsService, NgxSpinnerService, { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }, { provide: TreeviewI18n, useClass: TreeviewTranslation }, TreeviewComponent  ]
})
export class MerchandiseDeVenteComponent {  

  @Output() newTreeValues: EventEmitter<{ treeValues: string[], treeItems: TreeviewItem[] }> = new EventEmitter<{ treeValues: string[], treeItems: TreeviewItem[] }>();
  @Output() updatedRows: EventEmitter<number> = new EventEmitter<number>();

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500,    
  });
  treeValues: string[];
  treeItems: TreeviewItem[];
  treeCollapsedItems: { collapsed: boolean, children: any[] | null | undefined };
  
  totalSelected: number;

  @Input() displayType: 'component' | 'dialog' = 'dialog';

  _userID: number;
  get userID(): number { return this._userID; }
  @Input() set userID(userID: number) {
    this._userID = userID;
  }

  _prevTreeItems: TreeviewItem[];
  get prevTreeItems(): TreeviewItem[] { return this._prevTreeItems; }
  @Input() set prevTreeItems(prevTreeItems: TreeviewItem[]) {
    this._prevTreeItems = prevTreeItems;
    this.treeItems = prevTreeItems;
  }

  constructor(private dialogService: DialogsService,
              private translateService: TranslateService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {      
     
  }  
  ngOnInit() {
    if(this.displayType === 'dialog') {
      this.userID = this.data.userID;
      this.treeItems = this.data.treeItems;
    }
  }
  
  saveDetails(): void {
    this.getCollapesedItems();

    if(this.totalSelected === 0) {
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kunostruct'), this.translateService.instant('kuok'));
      return;
    }

    this.newTreeValues.emit({ treeValues: this.treeValues, treeItems: this.treeItems });
    this.updatedRows.emit(this.totalSelected);
  }

  getCollapesedItems() {

    const topLevelData: any[] = [];      

    this.treeItems[0].children.forEach(
      (level_1: TreeviewItem) => {
        const level_1_data: any[] = [];
        
        if(level_1.children) {
          level_1.children.forEach((level_2) => {
            const level_2_data: any[] = [];            

            if(level_2.children) {
              level_2.children.forEach((level_3) => {
                const level_3_data: any[] = [];

                if(level_3.children) {
                  level_3.children.forEach(
                    (level_4) => {
                      const level_4_data: any[] = [];

                      if(level_4.children) {
                        level_4.children.forEach(
                          (level_5) => {
                            level_4_data.push({
                              value: level_5.value,
                              collapsed: level_5.collapsed
                            });
                          }
                        )
                      }

                      level_3_data.push({
                        value: level_4.value,
                        collapsed: level_4.collapsed, 
                        children: level_4_data
                      });
                    }
                  )
                }                

                level_2_data.push({
                  value: level_3.value,
                  collapsed: level_3.collapsed,
                  children: level_3_data
                });
              });
            }

            level_1_data.push({
              value: level_2.value,
              collapsed: level_2.collapsed,
              children: level_2_data
            });
          });
        }

        topLevelData.push({
          value: level_1.value,
          collapsed: level_1.collapsed,
          children: level_1_data
        });
      }
    );

    this.treeCollapsedItems = {
      collapsed: this.treeItems[0].collapsed,
      children: topLevelData
    };        

    if(this.userID) {      
      sessionStorage.setItem('EDIT_MARCHANDISE_'+this.userID, JSON.stringify(this.treeCollapsedItems));            
    }    
  }

  onTreeValueChanged(downlineItems: DownlineTreeviewItem[]) {
    this.treeValues = [];
    
    this.totalSelected = downlineItems.length;

    downlineItems.forEach((downlineItem: DownlineTreeviewItem) => {      
      const item = downlineItem.item;          

      const value = item.value;      
      const parent_values = [value];
      let parent = downlineItem.parent;
      
      while (!isNil(parent)) {        
        parent_values.push(parent.item.value);
        parent = parent.parent;                   
      }
      
      const reverseValues = reverse(parent_values);      

      const row = `${reverseValues.join(";")}`;
      this.treeValues.push(row.replace(/^;/, ''));
    });
    
    this.newTreeValues.emit({ treeValues: this.treeValues, treeItems: this.treeItems });
    this.updatedRows.emit(this.totalSelected);
  }

}
