import { Component, EventEmitter, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import BonPanier, { BonHistory } from 'models/bonPanier';
import { UserModel } from 'models/user-model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-historique-bonde',
  templateUrl: './historique-bonde.component.html',
  styleUrls: ['./historique-bonde.component.scss'],
  providers: [BonVenteService]
})
export class HistoriqueBondeComponent implements OnInit {

  @Input() historiqueData;
  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  showLoader = true;
  historySubscription: Subscription;

  numBon: string;
  panier: BonPanier;
  cdeRow: any;
  CMINT: any;
  historyType: 'commande' | 'historique';

  isMobile: boolean;

  listHistory: BonHistory[] = [];

  notesForm: UntypedFormGroup;

  user: UserModel;
  USERCMINT: any;

  readOnly: boolean = false;

  onNoteAdded = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,          
              private userService: UserService,    
              private bonVenteService: BonVenteService,
              private translateService: TranslateService,
              private deviceDetector: DeviceDetectorService,
              private loadingService: NgxSpinnerService,
              private dialogService: DialogsService,
              private formBuilder: UntypedFormBuilder
              ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    if(this.isMobile){
      this.data = this.historiqueData;
    }

    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.numBon = this.data.numBon;
    this.panier = this.data.panier;
    this.CMINT = this.data.CMINT;
    this.cdeRow = this.data.cdeRow;
    this.historyType = this.data.historyType;

    if(this.data.readOnly !== undefined && this.data.readOnly != null) {
      this.readOnly = this.data.readOnly;
    }
   
    this.notesForm = this.formBuilder.group({
      NOTE: ['', Validators.required]
    });

    this.dtOptions = {
      language: {
          processing: this.translateService.instant('kprocessing'),
          search: this.translateService.instant('ksearch'),
          loadingRecords: this.translateService.instant('kloading'),
          info: this.translateService.instant('kgenshowentry'),
          infoEmpty: this.translateService.instant('kgenshowingentry'),
          zeroRecords: this.translateService.instant('knodata'),
          emptyTable: this.translateService.instant('knodatadash'),
          paginate: {
              first: this.translateService.instant('kfirst'),
              previous: this.translateService.instant('kprevious'),
              next: this.translateService.instant('knext'),
              last: this.translateService.instant('klast'),
          }        
      },      
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.getBonVenteHistory();
  }

  getBonVenteHistory(page?: number) {
    this.historySubscription = this.bonVenteService.getBonVenteHistorique(this.numBon, this.CMINT, page, this.historyType).subscribe(
        (response) => {
            this.showLoader = false;
            this.dtTrigger.next();

            if(response.success !== undefined) {                
                if(page === undefined) {
                  this.currentPage = 0;
                }
        
                this.rowsPerPage = response.rowsPerPage;
                this.totalPages = response.count;                      

                this.listHistory = response.data;                
            }            
        }, error => {
            this.dtTrigger.next();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            this.showLoader = false;            
        }
    );
  }

  searchPaginate(page?: number) {
    this.getBonVenteHistory(page);  
  }

  paginationChange(event: any) {
    if(!this.isMobile){
      this.showLoader = true;
      const changedPageIndex = event.pageIndex;    

      this.currentPage = changedPageIndex;

      this.searchPaginate(changedPageIndex + 1);        
    }
  }

  ngOnDestroy() {
    this.historySubscription.unsubscribe(); 
  }

  notesFormSubmitter() {
    if(this.notesForm.errors) {
      return;
    }

    const form_data = this.notesForm.getRawValue();

    const data = {
      NumBon: this.numBon,
      IDSOC: Constants.IDSOC,
      mag: this.panier.mag,
      iduser: this.user.CIINT,
      note: form_data.NOTE
    };

    this.loadingService.show();
    this.addNewNote(data);
  }

  addNewNote(data: any) {
    this.bonVenteService.createNewNote(data).subscribe(
        (response) => {
          this.loadingService.hide();
          if(response.success !== undefined) {
              this.notesForm.get('NOTE').reset();
              this.notesForm.get('NOTE').markAsUntouched();
              this.notesForm.get('NOTE').markAsPristine();
              this.notesForm.get('NOTE').setErrors(null);
              this.notesForm.get('NOTE').updateValueAndValidity();

              this.currentPage = 0;

              this.onNoteAdded.emit({ status: true });

              this.showLoader = true;
              this.getBonVenteHistory();
          }
        }, error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  _nl2br = (label: string) => {
      if (label !== undefined && label !== null) {
          label = label.trim();
          return label.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }

    return '';
  }

}
