<div class="modal-container w-100" id="couche_prix_article">
    <form >
    <mat-card>
        <mat-card-header *ngIf="!isMobile">
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">{{ "kboncomchoix" | translate }}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>            
            <div class="row" *ngFor="let item of buttonsList;let i=index">
                <div class="col-md-6" [class.mt-4]="i === 0 && !isMobile">
                    <button mat-button class="bt_send" (click)="sendSMS(item.actionType)" [disabled]="item.disabled">                        
                        {{ item.label }}
                    </button>
                </div>
            </div>            

            <div class="row mt-3 mb-4" *ngIf="alerteRelanceData">
                <div class="col-md-12">
                    <h3>{{ alerteRelanceData.OPERAT }} {{'kbonle' | translate}} {{ alerteRelanceData.DDAY | date: 'dd.MM.y HH:mm' }}</h3>
                </div>
            </div>
            <div class="row mt-3 mb-4" *ngIf="alerteMarchandiseData">
                <div class="col-md-12">
                    <h3>{{ alerteMarchandiseData.OPERAT }} {{'kbonle' | translate}} {{ alerteMarchandiseData.DDAY | date: 'dd.MM.y HH:mm' }}</h3>
                </div>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center"  *ngIf="!isMobile">
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'ktcol' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
    </form>
</div>