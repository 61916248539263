import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Constants} from '../constants';

import {Observable} from 'rxjs';

@Injectable()
export class StockService {

    constructor(private http: HttpClient) {
    }    

    getStockDispo(CAINT: any, IDMAG: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_STOCK_URL + 'getStockDispo', options);
    }

    getStockBlock(CAINT: any, IDMAG: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };
        
        return this.http.get(Constants.API_STOCK_URL + 'getStockBlock', options);
    }

    getStockEmplacementParams(CAINT: any, IDMAG: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };
        
        return this.http.get(Constants.API_ARTICLE_URL + 'getparaStockEmplacement', options);
    }

    

    getStockFromOtherMag(CAINT: any, CMINT: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;    
        params['CAINT'] = CAINT;
        params['CMINT'] = CMINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_STOCK_URL + 'getStockFromOtherMag', options)
    }

    getSumStockFromOtherMag(CAINT: any, IDMAG: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        const options = {
            headers: headers,
            params: params
        };
        
        return this.http.get(Constants.API_STOCK_URL + 'getSumStockFromOtherMag', options);
    }

    getMotifBlocageStock(CAINT: any, IDMAG: any, CIINT: any, PARENT_IDMAG?: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        params['CIINT'] = CIINT;

        if (PARENT_IDMAG !== undefined && PARENT_IDMAG !== null) {
            params['PARENT_IDMAG'] = PARENT_IDMAG;
        }

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_STOCK_URL + 'getMotifBlocageStock', options);
    }

    getMotifBlocageStockWithoutCAINT(IDMAG: any, CIINT: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;
        params['CIINT'] = CIINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_STOCK_URL + 'getMotifBlocageStockwtcaint', options);
    }

    getListStockEmplacement(CAINT: any, IDMAG: any, lang: string, CIINT: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        params['IDSOC'] = Constants.IDSOC;
        params['IDINSTCAI'] = Constants.CIINST;
        params['IDMAG'] = IDMAG;
        params['CAINT'] = CAINT;
        params['lang'] = lang;
        params['CIINT'] = CIINT;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getStockEmplacement', options);
    }

}
