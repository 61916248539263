export class Magasin {
    CMINT: string = '';
    CMRAISOC: string = '';

    constructor(values: Object = {}) {
        for (const prop in values) {
            if (values[prop] === null) {
                values[prop] = '';
            }
        }
        Object.assign(this, values);
    }
}
