import {Component, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

import {UserService} from 'providers/api/userService';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Constants } from 'providers/constants';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { UserModel } from 'models/user-model';
import { UtilisatuersService } from 'providers/api/utilisatuersService';
import { NiveauModel } from 'models/niveau-model';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UsersFilterComponent } from './users-filter/users-filter.component';
import { StorageService } from 'providers/api/storageService';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UtilisatuersService]
})
export class UsersComponent implements OnInit {

  searchForm: UntypedFormGroup;
  orderByList = [
    {
      label: "kucode",
      value: Constants.USER_ORDER_CODE
    },
    {
      label: "kuname",
      value: Constants.USER_ORDER_NOM
    },
  ];

  // bouton ENTER valide formulaire
  private pressedButton: any;

  // champs de recherche
  searchData: any = null;

  // Service
  listUsers: UserModel[] = [];

  user: UserModel;
  USERCMINT: any;

  // DATATABLE  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  searched = false;

  niveauTypes: NiveauModel[];  
  filteredNiveauTypes: NiveauModel[];
  filterNiveau: string = "";    

  isMobile: boolean = false;
  searchSubscription: Subscription;
  searchCodeSubscription: Subscription;
  addListenerSubscription: Subscription;

  filterValues: any;

  userPermission: any;

  listUserAPISubscription: Subscription;

  constructor(private userService: UserService, 
    public utilisatuersService: UtilisatuersService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    private storageService: StorageService) { 
      this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {

    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.getListNiveau();

      this.userService.headerAdd.next(false);
    this.userService.headerAddListener.next(false);
    this.userService.headerSearchInputThreeDots.next(true);
    this.userService.headerSearchInput.next(true);
    this.userService.headerSearchInputType.next('text');
    this.userService.headerSearch.next(false);
    
    this.searchSubscription = this.userService.headerSearch.subscribe(
      (status: boolean) => {
        if(status === true) {
          this.openUpFilterScreen();
        }
      }
    )

    this.searchCodeSubscription = this.userService.headerSearchInputValue.subscribe(
      (search_code) => {
        if(search_code !== undefined) {
          this.searchForm.patchValue({  rapidsearch: search_code === null?'':search_code, search_code:''  });
          this.searchFormSubmitter();
        }
      }
    )

    const oldFormData: any = this.storageService.getItem('utilisateurFormData');
    const oldListResponse: any = this.storageService.getItem('utilisateurListData');
    const oldSearchedStat: boolean = this.storageService.getItem('utilisateurSearched');

    const oldEditFormData: any = this.storageService.getItem('utilisateurEditFormData');

    this.searchForm = this.formBuilder.group({
      search_code: [''],
      search_nom: [''],
      search_niveau: ['tous'],
      search_order: [Constants.USER_ORDER_NOM],
      sort: ['asc'],
      search_actif: [false],
      rapidsearch: ['']
    });

    if(oldFormData !== undefined && oldFormData !== null) {
      this.searchForm.patchValue({
        search_code: oldFormData.search_code,
        search_nom: oldFormData.search_nom,
        search_niveau: (oldFormData.search_niveau !== undefined && oldFormData.search_niveau !== null)?oldFormData.search_niveau:'tous',
        search_order: (oldFormData.search_order !== undefined && oldFormData.search_order !== null)?oldFormData.search_order:Constants.USER_ORDER_NOM,
        sort: (oldFormData.sort !== undefined && oldFormData.sort !== null)?oldFormData.sort:'asc',
        search_actif: (oldFormData.search_actif !== undefined && oldFormData.search_actif !== null)?oldFormData.search_actif:false,        
        rapidsearch:''
      });

      this.filterValues = oldFormData;      
    }  else {      
      this.filterValues = this.searchForm.value;
    }      
  
    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }          
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    if(oldSearchedStat !== undefined && oldSearchedStat !== null) {
      this.searched = oldSearchedStat;
    }

    if(oldListResponse !== undefined && oldListResponse !== null) {
      const data = oldListResponse.data;

      this.rowsPerPage = oldListResponse.rowsPerPage;
      this.totalPages = oldListResponse.count;        

      this.listUsers = [...data];           

      if(oldEditFormData !== undefined && oldEditFormData !== null) {
        this.listUsers.map(
          (item: UserModel) => {
            if(item.CIINT == oldEditFormData.CIINT) {
              item.CIACTIF = oldEditFormData.CIACTIF?'-1':'0';
              item.CIUSER = oldEditFormData.CIUSER;
              item.CINOM = oldEditFormData.CINOM;
              item.CIPRENOM = oldEditFormData.CIPRENOM;
              item.CTUINTGC = oldEditFormData.CTUINTGC;
              item.NIVNOM = oldEditFormData.NIVNOM;              
            }                         
          }
        )
      }

      this.showLoader = false;
      this.dtTrigger.next();
    } else {
      this.getListUsers();
    }
    
  }  



  ngOnDestroy(): void {
    if(this.router.routerState.snapshot.url.indexOf('modifier-utilisateur') > -1) {

    } else {
      this.storageService.removeItem('utilisateurFormData');
      this.storageService.removeItem('utilisateurListData');
      this.storageService.removeItem('utilisateurSearched');
    }    

    this.userService.headerAdd.next(false);
    this.userService.headerSearch.next(false);
    this.userService.headerSearchInput.next(false);
    this.searchSubscription.unsubscribe();
    this.searchCodeSubscription.unsubscribe();
    this.dtTrigger.unsubscribe();

    if(this.listUserAPISubscription) {
      this.listUserAPISubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {      
      this.dtTrigger.next();
  }

  get orderedByColumn() {
    return this.searchForm.get('search_order').value;
  }

  get sortByColumn() {
    return this.searchForm.get('sort').value;
  }

  sortingOrder(orderBy, sort?: 'asc' | 'desc') {
    let prevOrder = this.orderedByColumn;
    this.searchForm.get('search_order').setValue(orderBy);

    if(sort === undefined || sort == null) {
      let prevSort = this.sortByColumn;
      if(prevOrder === orderBy) {
        this.searchForm.get('sort').setValue(prevSort === 'asc'?'desc':'asc');
      } else {
        this.searchForm.get('sort').setValue('asc');
      }      
    }    
    this.searchFormSubmitter();
  }
  onSecurityChange(search: string) {
    this.filteredNiveauTypes = this.niveauTypes.filter(row => row.NIVNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  async getListNiveau() {
    this.utilisatuersService.getListofNiveau(this.USERCMINT, this.user.CIINT).subscribe(
      (res: any) => {
        if(res.success !== undefined) {
          this.niveauTypes = res.data;
          this.filteredNiveauTypes = this.niveauTypes;
        }
      },
      err => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  getListUsers(page?: number) {
    if(this.listUserAPISubscription) {
      this.listUserAPISubscription.unsubscribe();
    }
    
    this.listUserAPISubscription = this.utilisatuersService.getListofUsers(this.user, page).subscribe(
      (response) => {        
        const data = response.data;

        this.rowsPerPage = response.rowsPerPage;
        this.totalPages = response.count;        

        this.listUsers = [...data];
        
        this.showLoader = false;
        this.dtTrigger.next();

        this.storageService.setItem('utilisateurListData', response);
        this.storageService.setItem('utilisateurSearched', this.searched);
      },
      error => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )  
  }

  resetForm(){    
    this.searchForm.patchValue({
      rapidsearch: '',
      search_code: '',
      search_nom: '',
      search_niveau: 'tous',
      search_actif: '',
      search_order: Constants.USER_ORDER_NOM    
    });         
    this.searchFormSubmitter();
  }

  searchFormSubmitter(): void {    
    this.showLoader = true;

    if(this.searchForm.invalid) {    
      this.showLoader = false;  
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));

      return;
    }

    const form_data = this.searchForm.value; 
    this.storageService.setItem('utilisateurFormData', form_data);
    this.filterValues = form_data;

    const search_data = {
      nom: form_data.search_nom,
      code: form_data.search_code,
      orderby: form_data.search_order,
      sort: form_data.sort,
      CMINT: this.USERCMINT,
      CIACTIF: (form_data.search_actif?'0':''),
      CTUINTGC: form_data.search_niveau,
      rapidsearch: form_data.rapidsearch
    };

    if(this.listUserAPISubscription) {
      this.listUserAPISubscription.unsubscribe();
    }
    
    this.listUserAPISubscription = this.utilisatuersService.searchUsers(search_data).subscribe(
      (response: any) => {
        if(response.success !== undefined) {
          this.currentPage = 0;

          const data = response.data;
  
          this.rowsPerPage = response.rowsPerPage;
          this.totalPages = response.count;
  
          this.listUsers = [...data];
          
          this.searched = true;        
  
          this.showLoader = false;
          this.dtTrigger.next();

          this.storageService.setItem('utilisateurListData', response);
          this.storageService.setItem('utilisateurSearched', this.searched);
        } else {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }        
      }, 
      err => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )    
  }

  searchPaginate(page?: number) {    
    
    if(this.searchForm.invalid) {
      this.showLoader = false;      
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));

      return;
    }

    const form_data = this.searchForm.value;
    this.storageService.setItem('utilisateurFormData', form_data);
    this.filterValues = form_data;
    
    const search_data = {
      nom: form_data.search_nom,
      code: form_data.search_code,
      orderby: form_data.search_order,
      sort: form_data.sort,
      CMINT: this.USERCMINT,
      CIACTIF: (form_data.search_actif?'0':''),
      CTUINTGC: form_data.search_niveau
    };

    if(this.listUserAPISubscription) {
      this.listUserAPISubscription.unsubscribe();
    }

    this.listUserAPISubscription = this.utilisatuersService.searchUsers(search_data, page).subscribe(
      (response: any) => {
        if(response.success !== undefined) {
          const data = response.data;

          this.rowsPerPage = response.rowsPerPage;
          this.totalPages = response.count;

          this.listUsers = [...data];
          
          this.showLoader = false;
          this.dtTrigger.next();        

          this.storageService.setItem('utilisateurListData', response);
          this.storageService.setItem('utilisateurSearched', this.searched);
        } else {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      }, 
      err => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )
  }

  paginationChange(event: any) {
    this.showLoader = true;

    const changedPageIndex = event.pageIndex;    

    this.currentPage = changedPageIndex;

    if(this.searched) {
      this.searchPaginate(changedPageIndex + 1);
    } else {
      this.getListUsers(changedPageIndex + 1);
    }
  }

  addUser(): void {      
      this.router.navigate(['ajouter-utilisateur']);
  }

  editUser(code_deta: UserModel): void {
    this.router.navigate(['modifier-utilisateur', code_deta.CIINT]);  
  }

  openUpFilterScreen() {
    this.userService.headerSearch.next(false);
    const dialogRef = this.dialog.open(UsersFilterComponent, {      
      width: '60vw',
      data: {
        form_data: this.searchForm.value,
        orderByList: this.orderByList,
        niveauTypes: this.niveauTypes,        
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        const form_data = result.form_data;
        this.filterValues = form_data;

        this.searchForm.patchValue({
          search_code: form_data.search_code,
          search_nom: form_data.search_nom,
          search_niveau: form_data.search_niveau,
          search_order: form_data.search_order,
          sort: form_data.sort,
          search_actif: form_data.search_actif,
          rapidsearch: ''
        });

        this.searchFormSubmitter();
      }      
    }); 
  } 

  get filterValuesFormat(): string {
    
    let filterArr = [];

    if(this.filterValues.search_code !== undefined && this.filterValues.search_code !== null && this.filterValues.search_code !== '') {
      filterArr.push(this.filterValues.search_code);
    }
    if(this.filterValues.rapidsearch !== undefined && this.filterValues.rapidsearch !== null && this.filterValues.rapidsearch !== '') {
      filterArr.push(this.filterValues.rapidsearch);
    }
    if(this.filterValues.search_nom !== undefined && this.filterValues.search_nom !== null && this.filterValues.search_nom !== '') {
      filterArr.push(this.filterValues.search_nom);
    }
    if(this.filterValues.search_niveau !== undefined && this.filterValues.search_niveau !== null && this.filterValues.search_niveau !== '') {
      if(this.filterValues.search_niveau === 'tous') {
        filterArr.push(this.translateService.instant('kuallsec'));
      } else if(this.filterValues.search_niveau === 'nondefini') {
        filterArr.push(this.translateService.instant('kuundefine'));
      } else {
        if(this.niveauTypes) {
          const levelLabel = this.niveauTypes.find((row) => row.NIVINT == this.filterValues.search_niveau);
          filterArr.push(levelLabel.NIVNOM);
        }
      }
    }

    if(this.filterValues.search_actif !== undefined && this.filterValues.search_actif !== null && this.filterValues.search_actif !== '') {
      if(this.filterValues.search_actif) {
        filterArr.push(this.translateService.instant('kuuserdeact'));
      }
    }

    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
