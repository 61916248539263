<div class="modal-container" id="photo_article_article">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">
                            PHOTO ARTICLE
                        </h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-12">
                    <img [src]="imageUrl" alt="">
                </div>
            </div>

            <br> <br>

        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button [mat-dialog-close] class="bt_send" cdkFocusInitial>{{'kartclose' | translate}}</button>            
        </mat-card-actions>
    </mat-card>
</div>