import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Constants } from 'providers/constants';


@Injectable()
export class NiveauSecuriteService {
    constructor(private http: HttpClient) {
    }
    
    getListofSecuriteLevels(type: string, IDMAG: number, page?: number): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};       
        
        params['type'] = type;
                
        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        params['IDSOC'] = Constants.IDSOC;
        params['IDMAG'] = IDMAG;

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_NIVEAU_SECURITE_URL + 'getSecuriteAtLoad', options);
    }

    addSecuriteLevel(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_NIVEAU_SECURITE_URL + 'createSecurite', data, options);
    }

    updateSecuriteLevel(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_NIVEAU_SECURITE_URL + 'updateSecurite', data, options);
    }

    deleteSecuriteLevel(row_id: number): Observable<any> {

        const headers = new HttpHeaders();            
        headers.append('Content-Type', 'application/json');
        
        const params = {};
        params['NIVINT'] = row_id;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_NIVEAU_SECURITE_URL + 'deleteSecurite', options);
    }

}