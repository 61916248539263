<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{'kgtitle' | translate}}
            </h1>
        </div>
    </div>    

    <form [formGroup]="searchForm" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-12 ">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                            <mat-form-field>
                                <mat-select [placeholder]="'kgniveau' | translate" color="primary" disableOptionCentering panelClass="dropdown-panel" (selectionChange)="onNiveauSecuriteChange()" formControlName="search_niveau">
                                    <mat-option>
                                        <ngx-mat-select-search
                                        [placeholderLabel]="'kgchooseniv' | translate"
                                        [noEntriesFoundLabel]="'kgnoniv' | translate"
                                        (ngModelChange)="onSearchChange($event)" [(ngModel)]="filterNiveau" [ngModelOptions]="{standalone: true}"></ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option *ngFor="let item of filteredNiveauTypes" [value]="item.NIVINT">{{ item.NIVNOM }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="clearfix"></div>
                    </div>                    
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">
        <div class="row">
            <div class="col-12" style="max-height: 450px;overflow: scroll;">
                <div class="form-group">
                    <div class="treeview-item" *ngFor="let item of treeItems">
                        <div class="form-inline row-item">
                            <i class="d-flex align-items-center mr-1" aria-hidden="false" (click)="_treeCollapseToggle(item)" [ngSwitch]="item.collapsed">
                                <svg *ngSwitchCase="true" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                                <svg *ngSwitchCase="false" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>

                                <mat-icon *ngIf="item.children && (item.value === 'general' || item.value === 'ventes' || item.value === 'reporting' || item.value === 'clients' || item.value === 'articles')" class="icon-small text-primary ml-2">security</mat-icon>
                                <mat-icon *ngIf="item.children && (item.value === 'confirmation_action')" class="icon-small text-warning ml-2">grade</mat-icon>
                            </i>
    
                            <div class="form-check">
                                <mat-checkbox class="example-margin" [(ngModel)]="item.checked" (ngModelChange)="_treeCheckboxChange(item)" [indeterminate]="item.indeterminate" [checked]="item.checked" [disabled]="item.disabled">{{ item.text | translate }}</mat-checkbox>
                            </div>
                        </div>                                    
    
                        <div *ngIf="item.children && !item.collapsed">
                            <div class="treeview-item" *ngFor="let child_item of item.children">
                                <div class="form-inline row-item align-items-baseline">
                                    <i *ngIf="child_item.children && child_item.children.length > 0" class="d-flex align-items-center mr-1 mll-1" (click)="_treeCollapseToggle(child_item)" aria-hidden="false" [ngSwitch]="child_item.collapsed">
                                        <svg *ngSwitchCase="true" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                            d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                        <svg *ngSwitchCase="false" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </i>
                                    <div class="form-check">
                                        <mat-checkbox class="example-margin" [(ngModel)]="child_item.checked" (ngModelChange)="_treeCheckboxChange(child_item, item)" [indeterminate]="child_item.indeterminate" [checked]="child_item.checked" [disabled]="child_item.disabled">{{child_item.text}}</mat-checkbox>
                                    </div>
                                </div>

                                <div *ngIf="child_item.children && !child_item.collapsed">
                                    <div class="treeview-item" *ngFor="let sub_child_item of child_item.children">
                                        <div class="form-inline row-item align-items-baseline">
                                            <i *ngIf="sub_child_item.children" class="d-flex align-items-center mr-1 mll-1" (click)="_treeCollapseToggle(sub_child_item)" aria-hidden="true" [ngSwitch]="sub_child_item.collapsed">
                                                <svg *ngSwitchCase="true" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                                <svg *ngSwitchCase="false" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </i>

                                            <div class="form-check">
                                                <mat-checkbox class="example-margin" [(ngModel)]="sub_child_item.checked" (ngModelChange)="_treeCheckboxChange(sub_child_item, child_item, item)" [indeterminate]="sub_child_item.indeterminate" [checked]="sub_child_item.checked" [disabled]="sub_child_item.disabled">{{sub_child_item.text}}</mat-checkbox>
                                            </div>
                                        </div>

                                        <div *ngIf="sub_child_item.children && !sub_child_item.collapsed">
                                            <div class="treeview-item" *ngFor="let sub_sub_child_item of sub_child_item.children">
                                                <div class="form-inline row-item align-items-baseline">
                                                    <i *ngIf="sub_sub_child_item.children" class="d-flex align-items-center mr-1" (click)="_treeCollapseToggle(sub_sub_child_item)" aria-hidden="true" [ngSwitch]="sub_sub_child_item.collapsed">
                                                        <svg *ngSwitchCase="true" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                            d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                        </svg>
                                                        <svg *ngSwitchCase="false" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                        </svg>
                                                    </i>
        
                                                    <div class="form-check">
                                                        <mat-checkbox class="example-margin" [(ngModel)]="sub_sub_child_item.checked" (ngModelChange)="_treeCheckboxChange(sub_sub_child_item, sub_child_item, child_item, item)" [indeterminate]="sub_sub_child_item.indeterminate" [checked]="sub_sub_child_item.checked" [disabled]="sub_sub_child_item.disabled">{{sub_sub_child_item.text}}</mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
