
<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium">{{'kclclearform' | translate}}</div>
            </button>
        </div>
    </div>
</div>

<form #f="ngForm" [formGroup]="searchForm" (ngSubmit)="headerSearchFormSubmitter()" >
    <div class="bloc_container bg_light py-4">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="NUMBON"
                                   [placeholder]="'kbonnbon' | translate"
                                   class="text-uppercase" #filterFirst>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="NOM"
                                   [placeholder]="'kpname' | translate"
                                   class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="PRENOM"
                                   [placeholder]="'kuprenom' | translate"
                                   class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="CODPOS"
                                   [placeholder]="'kboncodeopostal' | translate"
                                   class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="VILLE"
                                   [placeholder]="'kclcity' | translate"
                                   class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="TEL_PORT"
                                   [placeholder]="'kcltel' | translate"
                                   class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <div class="row no-gutters">
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput inputmode="decimal" formControlName="TOTAL_ENTRE_FROM"
                                           [placeholder]="'kbontotbetn' | translate"
                                           class="text-uppercase">
                                </mat-form-field>
                            </div>
                            <div class="col-6 pl-4">
                                <mat-form-field>
                                    <input matInput inputmode="decimal" formControlName="TOTAL_ENTRE_TO"
                                           [placeholder]="'kbonand' | translate"
                                           class="text-uppercase">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="NUM_EXT"
                                   [placeholder]="'kbonnext' | translate"
                                   class="text-uppercase">
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field>
                            <mat-select [placeholder]="'kbonsecteur' | translate" formControlName="SECTEUR" >
                                <mat-option value="">{{'kutoussecteur' | translate}}</mat-option>
                                <mat-option *ngFor="let magStruct of magasinStructure" [value]="magStruct.CSTNIV1">
                                    {{ magStruct.CSTNIV1+ ' ' + magStruct.CSTLIB1 }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="ARTICLE" (keydown.enter)="onCodeEntered($event)" [placeholder]="'kartarttit' | translate" class="text-uppercase">
                            <button matSuffix class="ml-2" type="button" (click)="openScanArticle()" mat-icon-button>
                                <i class="fa fa-barcode" aria-hidden="true"></i>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <mat-form-field>
                    <mat-select [placeholder]="'Bons E2' | translate" formControlName="BONS_E2" >
                        <mat-option *ngFor="let bonE2Item of bonsE2List" [value]="bonE2Item.id">{{ bonE2Item.label | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field>
                    <input matInput
                           name="CODE"
                           formControlName="CODE"
                           [placeholder]="'kfournisseurcode' | translate"
                           class="text-uppercase">
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field>
                    <mat-select [placeholder]="'kfournisseurlibel' | translate" formControlName="LIBEL" >
                        <mat-option *ngFor="let fournisseurNomItem of fournisseurNomList" [value]="fournisseurNomItem.id">{{ fournisseurNomItem.label | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <div class="radio-container mt-3">
                    <mat-label>{{'kordre' | translate}}</mat-label>
                    <mat-radio-group name="ORDER_BY" formControlName="ORDER_BY" class="mt-3" >
                        <mat-radio-button value="date"><span [translate]="'kdate'"></span></mat-radio-button>
                        <mat-radio-button class="ml-3" value="nom_client"><span [translate]="'knomclient'"></span></mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-12 mt-2 mt-md-0 text-center">
                <button type="submit" class="bt_valid" mat-button>
                    <i class="material-icons">search</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'kpsearch' | translate }}
                    </span>
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kucancel" | translate }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</form>
