<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{ 'kmotif' | translate }}
            </h1>
        </div>
    </div>    

    <form [formGroup]="searchForm" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-12 ">
                    <div class="row">
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <mat-select [(ngModel)]="selectVal" (selectionChange)="typeOnChange()" color="primary" formControlName="motif_action">
                                    <mat-option *ngFor="let item of motifActions" [value]="item.value">{{ item.label | translate }}</mat-option>
                                </mat-select>                                
                            </mat-form-field>                            
                        </div>                        
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container" *ngIf="!isMobile">
        <div class="row bg_white">
            <div class="col-12 text-center">
                <button class="bt_valid" (click)="addMotif()" [disabled]="searchForm.invalid" mat-button type="button">
                    <mat-icon>add_circle</mat-icon> {{ 'kmaddbtn' | translate }}
                </button>
            </div>
        </div>
    </div>    

    <div class="bloc_container" >

        <div class="row">
            <div class="col-12 no-padding">
                <div class="">
                    <mat-paginator *ngIf="!showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

                <table datatable class="row-border hover" id="motifsList" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead  [class.d-none]="isMobile">
                        <tr>
                            <th width="70%" class="code">{{ 'kmaddpattern' | translate }}</th>
                            <th width="15%" class="code">{{ 'kmaddlibre' | translate }}</th>                            
                            <th width="15%" class="actions">{{'kpaction' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listMotifs?.length != 0 && !showLoader">
                        <tr *ngFor="let code_row of listMotifs">
                            <td class="numero" (click)="editMotif(code_row)">{{ code_row.MOTLIB }}</td>
                            <td class="numero" (click)="editMotif(code_row)">
                                <span class="badge" [class]="code_row.MOTFREE == '-1'?'badge-success':'badge-danger'" translate *ngIf="code_row.MOTFREE != '0'" >{{ (code_row.MOTFREE == '-1'?'kmaddlibre':"ksno") | translate }}</span>                                
                            </td>
                            <td class="actions">
                                <button type="button" mat-mini-fab color="primary" (click)="editMotif(code_row)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="warn" class="ml-1 my-1" (click)="deleteConfirmation(code_row.IDMOT)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>                        
                        </tr>                        
                    </tbody>
                    <tbody *ngIf="listMotifs?.length == 0 || showLoader">
                        <tr>
                            <td colspan="3" *ngIf="listMotifs?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                            <td colspan="3" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>
