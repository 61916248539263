<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">            
                {{ 'kcimtitle' | translate }}
            </h1>
        </div>
    </div>
    <div class="bloc_container padding_15 bg_light">
        <div class="row">
            <div class="col-12 col-md-6 mb-3" [class.offset-md-3]="!livraisonDirecteStat && !livraisonDeporteeStat" [class.no-padding]="isMobile">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimnom' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMRAISOC}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimaddress' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMADR1}}{{magData?.CMADR2}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimcpcity' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMCP}}</span>
                        <span class="detail-holder">{{magData?.CMVILLE}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimtelserviceclient' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMTELCLIENT}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimfaxserviceclient' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMFAXCLIENT}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimtelsav' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMTELSAV}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimfaxsav' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMFAXSAV}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <label>{{ 'kcimsiren' | translate }}</label>
                        <span class="detail-holder">{{magData?.CMSIRET}}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-3" *ngIf="livraisonDirecteStat || livraisonDeporteeStat" [class.no-padding]="isMobile">
                <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{ 'kcimsuppmag' | translate }} </span>
                        <button type="button" class="m-0"  (click)="magSupFunction()" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="row mt-4" *ngIf="livraisonDeporteeStat">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="align-items-center flex-row justify-content-between text-left">
                                    <span class="mr-1">{{ 'kcimlivdeport' | translate }}</span>
                                    <span class="detail-holder" style="flex:1">{{((magData?.OKLIVDEPORT === '-1')?'ksyes':'ksno') | translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="livraisonDeporteeStat">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="align-items-center flex-row justify-content-between text-left">
                                    <span class="mr-1">{{ 'kcimsmtpserver' | translate }}</span>
                                    <span class="detail-holder" style="flex:1" [class.disable]="(magData?.OKLIVDEPORT === '0')">{{(magData?.OKLIVDEPORT === '0')?'': magData?.MAILSMTP}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="livraisonDeporteeStat">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="align-items-center flex-row justify-content-between text-left">
                                    <span class="mr-1">{{ 'kcimemailstore' | translate }}</span>
                                    <span class="detail-holder" style="flex:1" [class.disable]="(magData?.OKLIVDEPORT === '0')">{{(magData?.OKLIVDEPORT === '0')?'': magData?.MAILMAG}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="livraisonDeporteeStat">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="align-items-center flex-row justify-content-between text-left">
                                    <span class="mr-1">{{ 'kcimemaillivdeport' | translate }}</span>
                                    <span class="detail-holder" style="flex:1" [class.disable]="(magData?.OKLIVDEPORT === '0')">{{(magData?.OKLIVDEPORT === '0')?'': magData?.MAILDEPORT}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2 mb-4" *ngIf="livraisonDirecteStat">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div class="align-items-center flex-row justify-content-between text-left">
                                    <span class="mr-1">{{ 'kcimlivdirectact' | translate }}</span>
                                    <span class="detail-holder" style="flex:1">{{((magData?.OKLIVDIRECT === '-1')?'ksyes':'ksno') | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #magModalTemplate>
    <div class="modal-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title class="text-uppercase text-center">
                    <div class="row">
                        <div class="col-12">
                            <h2>{{ 'kcimsuppmag' | translate }} </h2>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]="formData.OKLIVDEPORT" >{{ 'kcimlivdeport' | translate }}</mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput (keydown.enter)="moveFocus(smtpPass)" placeholder="{{ 'kcimsmtpserver' | translate }}" [(ngModel)]="formData.MAILSMTP" *ngIf="formData.OKLIVDEPORT">
                                <input matInput (keydown.enter)="moveFocus(smtpPass)" placeholder="{{ 'kcimsmtpserver' | translate }}" disabled *ngIf="!formData.OKLIVDEPORT" >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput (keydown.enter)="moveFocus(smtpEmail)" #kcimsmtpmdp placeholder="{{ 'kcimsmtpmdp' | translate }}" [(ngModel)]="formData.MAILPASS" *ngIf="formData.OKLIVDEPORT">
                                <input matInput (keydown.enter)="moveFocus(smtpEmail)" #kcimsmtpmdp placeholder="{{ 'kcimsmtpmdp' | translate }}" disabled *ngIf="!formData.OKLIVDEPORT" >
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput (keydown.enter)="moveFocus(smtpLivraison)" #kcimemailstore placeholder="{{ 'kcimemailstore' | translate }}" [(ngModel)]="formData.MAILMAG" *ngIf="formData.OKLIVDEPORT">
                                <input matInput (keydown.enter)="moveFocus(smtpLivraison)" #kcimemailstore placeholder="{{ 'kcimemailstore' | translate }}" disabled *ngIf="!formData.OKLIVDEPORT">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">                       
                            <mat-form-field>
                                <input matInput #kcimemaillivdeport placeholder="{{ 'kcimemaillivdeport' | translate }}" [(ngModel)]="formData.MAILDEPORT" *ngIf="formData.OKLIVDEPORT">
                                <input matInput #kcimemaillivdeport placeholder="{{ 'kcimemaillivdeport' | translate }}" disabled *ngIf="!formData.OKLIVDEPORT">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]="formData.OKLIVDIRECT">{{ 'kcimlivdirectact' | translate }}</mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button type="button" class="text-left" (click)="updateMagData()" mat-button>
                                <i class="material-icons">check</i>
                                <span class="d-none d-sm-none d-md-block">{{ 'kcimokay' | translate }}</span>
                            </button>
                            <button class="text-left ml-2" (click)="closeMagSupFunction()" mat-button>
                                <i class="material-icons">close</i>
                                <span class="d-none d-sm-none d-md-block">{{ 'kcimannuler' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>
