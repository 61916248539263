import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from 'providers/api/loginService';
import { User } from 'models/users';
import { UserComplementModel } from 'models/user-model';
import * as moment from 'moment';
import { Constants } from 'providers/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [LoginService, DialogsService]
})
export class ResetPasswordComponent {

  resetPasswordType: 'reinit' | 'expiry' = 'reinit';
  resetPasswordForm: UntypedFormGroup;

  userData: User;
  userComplementData: UserComplementModel;

  oldPasswordHide: boolean = true;
  newPasswordHide: boolean = true;
  newConfirmPasswordHide: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ResetPasswordComponent>,
              private formBuilder: UntypedFormBuilder,
              private loginService: LoginService,
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private translateService: TranslateService) {

      this.resetPasswordType = this.data.reset_type;
      this.userData = this.data.userData;
      this.userComplementData = this.data.userComplementData;
      
      this.resetPasswordForm = this.formBuilder.group({
        CIOLDPSW: ['', [Validators.required, Validators.minLength(3)]],
        CIPSW: ['', [Validators.required, Validators.minLength(3)]],
        CICPSW: ['', [Validators.required, this.matchValues('CIPSW')]],
        CMINT: [this.userData.CMINT],
        IDSOC: [Constants.IDSOC],
        CIINST: [Constants.CIINST]
      });      
  }

  onFormSubmission(): void {
    this.loadingService.show();    

    if(this.resetPasswordForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }

    const form_data = this.resetPasswordForm.value;
    form_data.CIINT = this.userData.CIINT;
    form_data.DATPASS = moment().format('YYYY-MM-DD HH:mm:ss');
    form_data.OKREINIT = 0;
    form_data.CIPSW = form_data.CIPSW.toUpperCase();
    form_data.CICPSW = form_data.CICPSW.toUpperCase();

    if(this.resetPasswordType === 'expiry') {
      form_data.OKEXPIRE = 0;
    }
    
    this.loginService.resetPassword(form_data, this.resetPasswordType).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kkmodefft'));
          this.dialogRef.close('success');
        } else {
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kkreint'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('ksneterr'));
      }
    )
  }

  matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  toggleOldPasswordDisplay() {
    this.oldPasswordHide = !this.oldPasswordHide
  }

  toggleNewPasswordDisplay() {
    this.newPasswordHide = !this.newPasswordHide
  }

  toggleNewConfirmPasswordDisplay() {
    this.newConfirmPasswordHide = !this.newConfirmPasswordHide
  }

}
