<form name="userForm" class="pt-3">
    <div class="row" *ngIf="!displayType">
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 col-md-4" >
                    <mat-form-field>
                        <input matInput name="CDART" [ngModel]="article?.CDART"
                               [placeholder]="'kartref' | translate" disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field>
                        <input matInput name="CALIB3" [ngModel]="article?.CALIB3"
                               [placeholder]="'kartlongdesc' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field>
                        <input matInput name="BALNOM" [ngModel]="article?.BALNOM"
                               [placeholder]="'kartnomart' | translate"  disabled>
                    </mat-form-field>
                </div>
                
                <div class="col-12 col-md-6 col-lg-12">
                    <mat-form-field>
                        <input matInput name="DESIGNATION" [ngModel]="article?.BALDESIGN1"
                               [placeholder]="'kartdesig' | translate"  disabled>

                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-12">
                    <mat-form-field>
                        <input matInput name="Descriptif" [ngModel]="article?.BALDESC"
                               [placeholder]="'kartdesc' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="dimension" [ngModel]="article?.BALDIMCOUR"
                               [placeholder]="'kartdimen' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="poids" [ngModel]="(article?.BALDIMPDS && article?.BALDIMPDS !== null)?(+article.BALDIMPDS)+(article?.BALDIMKG === 'O'?' Kg':''):''"
                               [placeholder]="'kartweight' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="marque" [ngModel]="article?.BALMARQ"
                               [placeholder]="'kartmark' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="modele" [ngModel]="article?.BALMODELFOU"
                               [placeholder]="'kartmodel' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="conseils" [ngModel]="article?.BALCONSEIL"
                               [placeholder]="'kartmain' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="garantie" [ngModel]="article?.BALGARAN"
                               [placeholder]="'kartgaur' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="composition" [ngModel]="article?.BALCOMPO"
                               [placeholder]="'kartcomp' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6 structure">
                    <table class="my-3 my-md-0">
                        <tr>
                            <td colspan="2">
                                {{'kartmar' | translate}}
                            </td>
                        </tr>
                        <tr *ngIf="article && (+article.CSTNIV1 > 0 || +article.CSTNIV2 > 0 || +article.CSTNIV3 > 0 || +article.CSTNIV4 > 0)">
                            <td width="30%">
                                {{ article.CSTNIV1 }}<br>
                                {{ article.CSTNIV2 }}<br>
                                {{ article.CSTNIV3 }}<br>
                                {{ article.CSTNIV4 }}
                            </td>
                            <td>
                                {{ LIB_CSTNIV1 | uppercase }}<br>
                                {{ LIB_CSTNIV2 | uppercase }}<br>
                                {{ LIB_CSTNIV3 | uppercase }}<br>
                                {{ LIB_CSTNIV4 | uppercase }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-6">

            <div class="row">
                <div class="col-12 col-md-6">
                    <img [src]="image_path" class="img-fluid mb-3" default="assets/images/no_img.png" />
                </div>
                <div class="col-12 col-md-6">
                    <div class="row no-gutters">                        
                        <div class="col-12 col-md-12">
                            <h5 class="mb-3"><strong>{{'kartstate' | translate}} :</strong> {{ (article?.CAECODE && article?.CAECODE === 'A'?'kartactive':'kartinactive') | translate }}</h5>
                        </div>
                        <div class="col-12 col-md-6" *ngIf="!isMobile">
                            <mat-form-field class="mb-3">
                                <input matInput name="code_externe" [ngModel]="code_externe | async"
                                       [placeholder]="'kartcodeext' | translate"  disabled>
                            </mat-form-field>                            
                        </div>
                        <div class="col-12 col-md-6 css_height" *ngIf="!isMobile">
                            <button type="button" mat-raised-button class="bt_code" [disabled]="!article" (click)="openCodeArticleDialog()">
                                <mat-icon>qr_code</mat-icon>
                                <div class="text-medium" translate>kartcais</div>
                            </button>
                        </div>

                        <div class="col-12">
                            <mat-form-field class="prix mb-3">
                                <input matInput name="Prix" [ngModel]="(article?.calculated_price?article?.calculated_price:0) | number:'.2'"
                                       [placeholder]="'kartprice' | translate"  disabled/>
                                <mat-icon matSuffix>
                                    <i class="material-icons">euro_symbol</i>
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field class="mb-3">
                                <input matInput name="stock" [ngModel]="(article?.STOCKDISPO?article?.STOCKDISPO:0) | number: '0.2':'fr-FR'"
                                       [placeholder]="'kartstock' | translate"  disabled>
                            </mat-form-field>
                        </div>
                    </div>                    
                </div>
            </div>            
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="plus" [ngModel]="article?.BALPLUS"
                               [placeholder]="'kartprod' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput  name="mais_aussi" [ngModel]="article?.BALAUSSI"
                               [placeholder]="'kartmais' | translate"  disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="conditionnement" [ngModel]="article?.BALCONDMAG"
                               [placeholder]="'kartpack' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="couleur" [ngModel]="article?.BALCOUL"
                               [placeholder]="'kartcolor' | translate"  disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="disponible" [ngModel]="article?.BALEGAL"
                               [placeholder]="'kartavai' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="argumentaire" [ngModel]="article?.BALARGUWEB"
                               [placeholder]="'kartarg' | translate"  disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div [class]="m50_status?'col-md-6':'col-md-12'" >
                    <p>
                        <strong>{{'kartsoufami' | translate}}</strong><br>
                        {{ article?article.CSFLIB1:'' }}
                    </p>
                </div>
                <div class="col-md-2" *ngIf="m50_status">
                    <button type="button" mat-raised-button class="bt_code" [disabled]="!article" (click)="onClickM50()">                        
                        <div class="text-medium" translate>kartm50</div>
                    </button>
                </div>
                <div class="col-md-3" *ngIf="m50_status && m50_clicked">
                    <p class="text-left"><strong>{{ (article?.CALIBFLO1?article?.CALIBFLO1:0) | number: '0.2' }}</strong></p>
                </div>
            </div>
        </div>
    </div>

    <div class="row no-gutters" *ngIf="displayType === 'desc'">
        <!-- In Mobile screens - Descriptif produit tab -->
        <div class="col-12 col-lg-12">
            <div class="row no-gutters">
                
                <div class="col-12 col-md-12" *ngIf="!isMobile">
                    <div class="row" [class.no-gutters]="isMobile">
                        <div class="col-12 col-md-2" >
                            <mat-form-field>
                                <input matInput name="CDART" [ngModel]="article?.CDART"
                                       [placeholder]="'kartref' | translate" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3">
                            <mat-form-field>
                                <input matInput name="CALIB3" [ngModel]="article?.CALIB3"
                                       [placeholder]="'kartlongdesc' | translate"  disabled>
                            </mat-form-field>                            
                        </div>
                        <div [class]="m50_status?'col-12 col-md-3 structure':'col-12 col-md-4 structure'">
                            <table class="my-3 my-md-0 w-100">
                                <tr>
                                    <td colspan="2">
                                        <label class="mat-label">{{'kartmar' | translate}}</label>
                                    </td>
                                </tr>
                                <tr *ngIf="article && (+article.CSTNIV1 > 0 || +article.CSTNIV2 > 0 || +article.CSTNIV3 > 0 || +article.CSTNIV4 > 0)">
                                    <td width="30%" class="text-left">
                                        {{ article.CSTNIV1 }}<br>
                                        {{ article.CSTNIV2 }}<br>
                                        {{ article.CSTNIV3 }}<br>
                                        {{ article.CSTNIV4 }}
                                    </td>
                                    <td  class="text-left">
                                        {{ LIB_CSTNIV1 | uppercase }}<br>
                                        {{ LIB_CSTNIV2 | uppercase }}<br>
                                        {{ LIB_CSTNIV3 | uppercase }}<br>
                                        {{ LIB_CSTNIV4 | uppercase }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="text-left">
                                <label class="mat-label">{{'kartsoufami' | translate}}</label><br>
                                {{ article?article.CSFLIB1:'' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-1" *ngIf="m50_status">
                            <button type="button" mat-raised-button class="bt_code" [disabled]="!article" (click)="onClickM50()">                        
                                <div class="text-medium" translate>kartm50</div>
                            </button>
                        </div>
                    </div>
                    <div class="row" *ngIf="m50_status">
                        <div class="col-12 col-md-3" *ngIf="m50_clicked">
                            <p class="text-left"><strong>{{ (article?.CALIBFLO1?article?.CALIBFLO1:0) | number: '0.2' }}</strong></p>
                        </div>
                    </div>
                </div>  
                <div class="col-12 col-md-12" *ngIf="isMobile">
                    <div class="row" [class.no-gutters]="isMobile">
                        <div class="col-12 col-md-3" >
                            <mat-form-field>
                                <input matInput name="CDART" [ngModel]="article?.CDART"
                                       [placeholder]="'kartref' | translate" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3">
                            <mat-form-field>
                                <input matInput name="CALIB3" [ngModel]="article?.CALIB3"
                                       [placeholder]="'kartlongdesc' | translate"  disabled>
                            </mat-form-field>                            
                        </div>
                        <div class="col-12 col-md-3 structure">
                            <table class="my-3 my-md-0 w-100">
                                <tr>
                                    <td colspan="2">
                                        <label class="mat-label">{{'kartmar' | translate}}</label>
                                    </td>
                                </tr>
                                <tr *ngIf="article && (+article.CSTNIV1 > 0 || +article.CSTNIV2 > 0 || +article.CSTNIV3 > 0 || +article.CSTNIV4 > 0)">
                                    <td width="30%" class="text-left">
                                        {{ article.CSTNIV1 }}<br>
                                        {{ article.CSTNIV2 }}<br>
                                        {{ article.CSTNIV3 }}<br>
                                        {{ article.CSTNIV4 }}
                                    </td>
                                    <td  class="text-left">
                                        {{ LIB_CSTNIV1 | uppercase }}<br>
                                        {{ LIB_CSTNIV2 | uppercase }}<br>
                                        {{ LIB_CSTNIV3 | uppercase }}<br>
                                        {{ LIB_CSTNIV4 | uppercase }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="text-left">
                                <label class="mat-label">{{'kartsoufami' | translate}}</label><br>
                                {{ article?article.CSFLIB1:'' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-2" *ngIf="m50_status">
                            <div class="w-25" *ngIf="isMobile">
                                <button type="button" mat-raised-button class="bt_code" [disabled]="!article" (click)="onClickM50()">                        
                                    <div class="text-medium" translate>kartm50</div>
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-md-3" *ngIf="m50_status && m50_clicked">
                            <p class="text-left"><strong>{{ (article?.CALIBFLO1?article?.CALIBFLO1:0) | number: '0.2' }}</strong></p>
                        </div>
                    </div>
                </div>                
            </div>
        </div>        
    </div>

    <div class="row no-gutters" *ngIf="displayType === 'comp'">
        <div class="col-12 col-lg-6">
            <div class="row" [class.no-gutters]="isMobile">                                
                <div class="col-12 col-md-12">
                    <mat-form-field>
                        <input matInput name="BALNOM" [ngModel]="article?.BALNOM"
                               [placeholder]="'kartnomart' | translate"  disabled>
                    </mat-form-field>
                </div>                
                <div class="col-12 col-md-12">
                    <mat-form-field>
                        <input matInput name="DESIGNATION" [ngModel]="article?.BALDESIGN1"
                               [placeholder]="'kartdesig' | translate"  disabled>

                    </mat-form-field>
                </div>
                <div class="col-12 col-md-12">
                    <mat-form-field>
                        <input matInput name="Descriptif" [ngModel]="article?.BALDESC"
                               [placeholder]="'kartdesc' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-12">
                    <mat-form-field>
                        <input matInput name="couleur" [ngModel]="article?.BALCOUL"
                               [placeholder]="'kartcolor' | translate"  disabled>
                    </mat-form-field>
                </div>            
                <div class="col-6 col-md-6 pr-2 pr-md-0">
                    <mat-form-field>
                        <input matInput name="dimension" [ngModel]="article?.BALDIMCOUR"
                               [placeholder]="'kartdimen' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-6 pl-2 pl-md-0">
                    <mat-form-field>
                        <input matInput name="poids" [ngModel]="(article?.BALDIMPDS && article?.BALDIMPDS !== null)?(+article.BALDIMPDS)+(article?.BALDIMKG === 'O'?' Kg':''):''"
                               [placeholder]="'kartweight' | translate"  disabled>
                    </mat-form-field>
                </div>
               
            </div>
        </div>

        <div class="col-12 col-lg-6">            
            <div class="row" [class.no-gutters]="isMobile">
                <div class="col-12 col-md-6">
                    <mat-form-field>
                        <input matInput name="composition" [ngModel]="article?.BALCOMPO"
                               [placeholder]="'kartcomp' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field>
                        <input matInput name="conseils" [ngModel]="article?.BALCONSEIL"
                               [placeholder]="'kartmain' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="plus" [ngModel]="article?.BALPLUS"
                               [placeholder]="'kartprod' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput  name="mais_aussi" [ngModel]="article?.BALAUSSI"
                               [placeholder]="'kartmais' | translate"  disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" [class.no-gutters]="isMobile">
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="conditionnement" [ngModel]="article?.BALCONDMAG"
                               [placeholder]="'kartpack' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="disponible" [ngModel]="article?.BALEGAL"
                               [placeholder]="'kartavai' | translate"  disabled>
                    </mat-form-field>
                </div>                
            </div>
            <div class="row" [class.no-gutters]="isMobile">                
                <div class="col-12 col-md-6 col-lg-6">
                    <mat-form-field>
                        <input matInput name="argumentaire" [ngModel]="article?.BALARGUWEB"
                               [placeholder]="'kartarg' | translate"  disabled>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field>
                        <input matInput name="marque" [ngModel]="article?.BALMARQ"
                               [placeholder]="'kartmark' | translate"  disabled>
                    </mat-form-field>
                </div>                
            </div> 
            <div class="row" [class.no-gutters]="isMobile">
                <div class="col-12 col-md-6">
                    <mat-form-field>
                        <input matInput name="modele" [ngModel]="article?.BALMODELFOU"
                               [placeholder]="'kartmodel' | translate"  disabled>
                    </mat-form-field>
                </div>                
                <div class="col-12 col-md-6">
                    <mat-form-field>
                        <input matInput name="garantie" [ngModel]="article?.BALGARAN"
                               [placeholder]="'kartgaur' | translate"  disabled>
                    </mat-form-field>
                </div> 
            </div>            
        </div>
    </div>

    <div class="row no-gutters" *ngIf="displayType === 'delivery'">
        <div class="col-12 col-lg-12">
            <div class="row no-gutters">
                
                <div class="col-12 col-md-12">
                    <div class="row" *ngFor="let item of deliveryOptions">
                        <div class="col-12 col-md-2" >
                            <mat-form-field>
                                <input matInput name="CDART" [value]="item.CDART"
                                       [placeholder]="'kartref' | translate" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-md-4" >
                            <mat-form-field>
                                <input matInput name="LIB" [value]="item.CALIB1"
                                       [placeholder]="'kartlib' | translate" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-md-2" >
                            <mat-form-field>
                                <input matInput name="PRIX" [value]="item.calculated_price | cap_currency:null:false"
                                       [placeholder]="'kartprice' | translate" disabled>
                            </mat-form-field>
                        </div>
                    </div>
                </div>           
            </div>
        </div>        
    </div>
</form>