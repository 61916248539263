import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements AfterViewInit {

  @ViewChild('annulerButton') annulerButton: MatButton;

  confirmation_title: string = "";
  confirmation_message: string = "";

  okButtonText = this.translateService.instant('kuok');
  okButtonDisabled: boolean = false;
  cancelButtonText = this.translateService.instant('kucancel');

  OkButtonOnly: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
            public dialogRef: MatDialogRef<ConfirmDialogComponent>,
            private translateService: TranslateService) { 
    this.confirmation_message = this.data.description;
    this.confirmation_title = this.data.title;    
    this.OkButtonOnly = this.data.okButtonOnly;

    if(this.data.okButtonDisabled !== undefined) {
      this.okButtonDisabled = this.data.okButtonDisabled;
    }

    if(this.data.buttons !== undefined) {
      const [okButtonText, cancelButtonText] = this.data.buttons;
      
      this.okButtonText = okButtonText;
      this.cancelButtonText = cancelButtonText;
    }
  }

  ngAfterViewInit() {
    if(!this.OkButtonOnly) {
      this.annulerButton.focus();
    }    
  }

  closeWithConfirm() {
    this.dialogRef.close('OK');
  }

}
