<div class="modal-container">
    <mat-card *ngIf="facturationDetail && facturationDetail !== null">
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">{{'kbonpayaujour' | translate}}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table class="table no-border">
                <tr>
                    <td></td>
                    <th class="text-right">{{'kbonmonatant' | translate}}</th>
                    <th class="text-right">{{'kbonporacct' | translate}}</th>
                    <th class="text-right">{{'kbontotal' | translate}}</th>
                </tr>
                <tr>
                    <th>{{'kbonei' | translate}}</th>
                    <td class="text-right"></td>
                    <td class="text-right"></td>
                    <th class="text-right">{{facturationDetail.totEI | number: '0.2'}}</th>
                </tr>
                <tr>
                    <th>{{'kartlabeled' | translate}}</th>
                    <td class="text-right">{{facturationDetail.mntED | number: '0.2'}}</td>
                    <td class="text-right">{{facturationDetail.pourED | number: '0.2'}} %</td>
                    <th class="text-right">{{facturationDetail.totED | number: '0.2'}}</th>
                </tr>
                <tr>
                    <th>{{'kbonbl' | translate}}</th>
                    <td class="text-right">{{facturationDetail.mntBL | number: '0.2'}}</td>
                    <td class="text-right">{{facturationDetail.pourBL | number: '0.2'}} %</td>
                    <th class="text-right">{{facturationDetail.totBL | number: '0.2'}}</th>
                </tr>
                <tr>
                    <th>{{'kbone2' | translate}}</th>
                    <td class="text-right">{{facturationDetail.mntE2 | number: '0.2'}}</td>
                    <td class="text-right">{{facturationDetail.pourE2 | number: '0.2'}} %</td>
                    <th class="text-right">{{facturationDetail.totE2 | number: '0.2'}}</th>
                </tr>

                <tr>
                    <th colspan="3" class="text-right">{{'kbontotal' | translate}}</th>                                
                    <th class="text-right">{{(facturationDetail.totEI + facturationDetail.totED + facturationDetail.totBL + facturationDetail.totE2) | number: '0.2'}}</th>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card-actions class="text-center">
        <button mat-button class="bt_send" (click)="onSubmitter()">
            <i class="material-icons">done</i>
            <span class="d-none d-sm-none d-md-block">
                {{'kuok' | translate}}
            </span>
        </button>
        <button mat-button [mat-dialog-close] class="bt_send">
            <i class="material-icons">close</i>
            <span class="d-none d-sm-none d-md-block">
                {{'kartclose' | translate}}
            </span>
        </button>
    </mat-card-actions>
</div>