import {Component, AfterViewInit, OnInit, OnDestroy, ViewChild, NgZone, Input, Output, EventEmitter, Optional, Inject} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { isNil, reverse } from 'lodash';
import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

import {Article, SousFamile} from '../../models/articles';
import {ArticleService} from '../../providers/api/articleService';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'providers/constants';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { UserService } from 'providers/api/userService';
import { DialogsService } from 'providers/api/dialogService';
import { UtilisatuersService } from 'providers/api/utilisatuersService';
import { MagasinStructModel } from 'models/magasinStructModel';
import {
    TreeviewItem, TreeviewConfig, TreeviewComponent,
    TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem, TreeviewI18n
  } from 'ngx-treeview2';
  import { TreeviewTranslation } from 'providers/treeview-translation';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { ArticleFilterComponent } from './article-filter/article-filter.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ImageDefaultPipe } from 'providers/image-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArticleStockComponent } from 'pages/article-detail/article-stock/article-stock.component';
import { JsonService } from 'providers/api/json.service';
import { StorageService } from 'providers/api/storageService';

interface CollapsedItems {
  collapsed: boolean;
  children?: CollapsedItems[] | undefined | null;
  value?: string;
}

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    providers: [
      { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }, 
      { provide: TreeviewI18n, useClass: TreeviewTranslation }, 
      TreeviewComponent,
      ArticleService, UtilisatuersService, DialogsService, 
      ImageDefaultPipe
    ]
})

export class ArticleComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('magasinTree', { static: false }) magTreeComponent: TreeviewComponent;  
    @ViewChild('sousTree', { static: false }) sousTreeComponent: TreeviewComponent;
    @ViewChild('f', { static: false }) searchFormRef: NgForm;

    private _searchedArticle: Article
    get searchedArticle() {return this._searchedArticle}
    @Input() set searchedArticle(searchedArticle: Article) {
        this.searchedArticleChange.emit(this._searchedArticle = searchedArticle);
    }
    @Output() private searchedArticleChange: EventEmitter<Article> = new EventEmitter<Article>();

    @Output() private onArticleChnage: EventEmitter<Article> = new EventEmitter<Article>();

    @Input() private enableMultiSelection: boolean = true;
    @Output() private onMultiArticleSelectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    private _flowCMINT: any;
    get flowCMINT() {
        this.user = this.userService.getCurrentUser();
        this.USERCMINT = this.user.CMINT;

        return this._flowCMINT || this.USERCMINT
    }
    @Input() set flowCMINT(flowCMINT: any) {
      this._flowCMINT = flowCMINT;
      this.initFillNomAndIDMag(this._preFilterConf?.NOM, flowCMINT);
    }

    private _preFilterConf: any;
    @Input() set preFilterConf(preFilterConf: any) {
      this._preFilterConf = preFilterConf;
      if(preFilterConf !== undefined && preFilterConf != null) {
        if(preFilterConf.form_data !== undefined && preFilterConf.form_data !== null) {
          const form_data = preFilterConf.form_data;
          this.filterValues = form_data;

          this.user = this.userService.getCurrentUser();          
          this.USERCMINT = this.user.CMINT;

          this.searchForm.patchValue({
            NOM: form_data.NOM !== null?form_data.NOM:'',
            LIV: form_data.LIV !== null?form_data.LIV:false,
            ACTIF: form_data.ACTIF !== null?form_data.ACTIF:true,
            CDART: form_data.CDART !== null?form_data.CDART:'',
            CODEEXT: form_data.CODEEXT !== null?form_data.CODEEXT:'',

            STRUCTU_LABEL: form_data.STRUCTU_LABEL !== null?form_data.STRUCTU_LABEL:'',
            MTTTCMIN: form_data.MTTTCMIN !== null?form_data.MTTTCMIN:'',
            MTTTCMAX: form_data.MTTTCMAX !== null?form_data.MTTTCMAX:'',
            STOCK_SUPERIOR: form_data.STOCK_SUPERIOR !== null?form_data.STOCK_SUPERIOR:false,

            STRUCTU: form_data.STRUCTU !== null?form_data.STRUCTU:'[]',
            CSFSFAM: form_data.CSFSFAM !== null?form_data.CSFSFAM:'[]',
            IDMAG: form_data.IDMAG !== null?form_data.IDMAG:this.USERCMINT,
            IDSOC: form_data.IDSOC !== null?form_data.IDSOC:Constants.IDSOC,
            ORDERBY: form_data.ORDERBY !== null?form_data.ORDERBY:Constants.ARTICLE_SEARCH_ORDER_CODE,
            sort: form_data.sort !== null?form_data.sort:'asc',
            
            STRUCT_ORDER: (form_data.STRUCT_ORDER !== undefined && form_data.STRUCT_ORDER !== null)?form_data.STRUCT_ORDER:'CODE',
            SOUS_ORDER: (form_data.SOUS_ORDER !== undefined && form_data.SOUS_ORDER !== null)?form_data.SOUS_ORDER:'SOUS_CODE',
          });

          this.treeItems = preFilterConf.treeItems;
          this.filteredTreeItems = this.treeItems;
          this.treeValues = preFilterConf.treeValues;
          this.totalSelected = preFilterConf.totalSelected;
          this.listSousFamile = preFilterConf.listSousFamile;
          this.sousfamileTreeValues = preFilterConf.sousfamileTreeValues;
          this.sousfamileTreeItems = preFilterConf.sousfamileTreeItems;
          this.sousfamileTotalSelected = preFilterConf.sousfamileTotalSelected;
          this.searchFormSubmitter();
        } else {
          if(preFilterConf.NOM !== undefined && preFilterConf.NOM !== null) {
            this.initFillNomAndIDMag(preFilterConf.NOM, this._flowCMINT);
          }
        }
      }
    }
    get preFilterConf(): any {
      return this._preFilterConf;
  }
    @Output() preFilterConfChange = new EventEmitter<any>();

    @Input() formSearchType;

    @Input('flowType') flowType: 'bonde_vente'|'bonde_commande'|'devis'|'retrait_marchandise';

    // bouton ENTER valide formulaire
    private pressedButton: any;

    // champs de recherche
    searchDataArticle: any = null;

    // Service
    listArticle: Article[] = [];

    // DATATABLE
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    user: any;
    USERCMINT: any;

    searchForm: UntypedFormGroup = this.formBuilder.group({
      NOM: [''],
      LIV: [false],
      ACTIF: [true],                        
      CDART: [''],
      CODEEXT: [''],
      
      STRUCTU_LABEL: [''],
      MTTTCMIN: [''],
      MTTTCMAX: [''],
      STOCK_SUPERIOR: [false],


      STRUCTU: ['[]'],
      CSFSFAM: ['[]'],
      IDMAG: [],            
      IDSOC: [Constants.IDSOC],
      ORDERBY: [Constants.ARTICLE_SEARCH_ORDER_CODE],
      sort: ['asc'],

      STRUCT_ORDER: ['CODE'],
      SOUS_ORDER: ['SOUS_CODE'],
    });

    orderBySearchList = [
        {
        label: "kartcode",
        value: Constants.ARTICLE_SEARCH_ORDER_CODE
        },
        {
        label: "kartlib",
        value: Constants.ARTICLE_SEARCH_ORDER_LABEL
        },
    ];

    showLoader = false;

    currentPage: number = 0;
    totalPages: number;
    rowsPerPage: number;

    magasinStructure: MagasinStructModel[];
    treeItems: TreeviewItem[];
    filteredTreeItems: TreeviewItem[];
    treeValues: string[];
    totalSelected: number;

    listSousFamile: SousFamile[];
    sousfamileTreeItems: TreeviewItem[];
    sousfamileTreeValues: string[];
    sousfamileTotalSelected: number;

    config = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 130           
    });

    sousTreeConfig = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 180           
    });

    isMobile: boolean = false;
    searchSubscription: Subscription;
    searchCodeSubscription: Subscription;

    @Input() displayType: 'component' | 'dialog' = 'component';
    
    filterValues: any;
    previousTreeValue: string = "";

    searchStructureMarchandiseLabel: string = '';
    rapidSearch: boolean = false;

    codeClient: any;

    chosenLineItems: any[] = [];
    checkedLineItems: any[] = [];
    articleListColumns: any;
    articleColumnDefaultSort: any;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private formBuilder: UntypedFormBuilder,       
                private dialog: MatDialog,         
                private loadingService: NgxSpinnerService,
                private articleService: ArticleService,
                private userService: UserService,
                private dialogService: DialogsService,
                private utilisatuersService: UtilisatuersService,
                private translateService: TranslateService,
                private deviceService: DeviceDetectorService,
                public jsonService: JsonService,
                private storageService: StorageService,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any,) {          
    }


    ngOnInit() {
        this.isMobile = this.deviceService.isMobile();
        this.user = this.userService.getCurrentUser();

        this.USERCMINT = this.user.CMINT;

        let IDMAG_field = this.searchForm.get('IDMAG');
        if (IDMAG_field.value === undefined || IDMAG_field.value == null || IDMAG_field.value === '') {
            IDMAG_field.setValue(this.USERCMINT);
        }

        this.jsonService.getAppConfig().subscribe((res: any) => {
          this.articleListColumns = res.articles.list.columns.sort((a, b) => a.order - b.order);
          this.articleListColumns = this.articleListColumns.filter(col => col.display);
          this.articleColumnDefaultSort = res.articles.list.default_sort;

          // Defining default sorts
          if ((this.articleColumnDefaultSort?.column === 'CDART' || this.articleColumnDefaultSort?.column === 'CALIB3') && (this.articleColumnDefaultSort?.order === 'asc' || this.articleColumnDefaultSort?.order === 'desc')) {
            this.sortingOrder(this.articleColumnDefaultSort.column, this.articleColumnDefaultSort.order);
          }
        }, 
        (error) => {
          console.log('Error', error)
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
        );

        this._getCodeClient();

        this.dtOptions = {
          language: {
            processing: this.translateService.instant('kprocessing'),
            search: this.translateService.instant('ksearch'),
            loadingRecords: this.translateService.instant('kloading'),
            info: this.translateService.instant('kgenshowentry'),
            infoEmpty: this.translateService.instant('kgenshowingentry'),
            zeroRecords: this.translateService.instant('knodata'),
            emptyTable: this.translateService.instant('knodatadash'),
            paginate: {
                first: this.translateService.instant('kfirst'),
                previous: this.translateService.instant('kprevious'),
                next: this.translateService.instant('knext'),
                last: this.translateService.instant('klast'),
            }       
          },      
          ordering: false,
          paging: false,
          searching: false,
          retrieve: true,
          info: false
        };

        if(this.displayType === 'component' || (this.displayType !== 'dialog' && !this.isMobile) || (this.displayType === 'dialog' && !this.isMobile)) {
          this.loadDependencies();
        }

        this.previousTreeValue = this.storageService.getItem('USER_STRUCT');

        if(this.isMobile) {
          this.searchSubscription = this.userService.headerSearch.subscribe(
            (status: boolean) => {
              if(status === true) {
                this.openUpFilterScreen('prefetch');
              }
            }
          )
  
          this.searchCodeSubscription = this.userService.headerSearchInputValue.subscribe(
            (search_code) => {            
              if(search_code !== undefined) {
                this.userService.setAdvanceSearchFormData(null);
                this.searchForm.patchValue({ 
                  NOM: '',
                  LIV: false,
                  ACTIF: true,
                  CDART: '',
                  CODEEXT: '',

                  STRUCTU_LABEL: '',
                  MTTTCMIN: '',
                  MTTTCMAX: '',
                  STOCK_SUPERIOR: false,
                  
                  STRUCTU: '[]',
                  CSFSFAM: '[]',
                  IDSOC: Constants.IDSOC,
                  ORDERBY: Constants.ARTICLE_SEARCH_ORDER_CODE,
                  sort: 'asc',                  
                  STRUCT_ORDER: 'CODE',
                  SOUS_ORDER: 'SOUS_CODE',
                });

                this.searchForm.patchValue({ CDART: search_code === null?'':search_code, NOM: '' });
                this.rapidSearch = true;
                this._barCodeLogicCheck(search_code, true)
              }
            }
          )
        }

        if(this.displayType === 'component') {
          this.userService.headerSearchInput.next(true);
          this.userService.headerSearchInputType.next('text');
          this.userService.headerSearchInputThreeDots.next(true);
          this.userService.headerSearch.next(false);          
  
          const oldFormData: any = this.storageService.getItem('articleFormData');
          const oldListResponse: any = this.storageService.getItem('articleListData');

          if(oldFormData !== undefined && oldFormData !== null) {
            this.filterValues = oldFormData;
            this.searchForm.setValue({
              NOM: oldFormData.NOM !== null?oldFormData.NOM:'',
              LIV: oldFormData.LIV !== null?oldFormData.LIV:false,
              ACTIF: oldFormData.ACTIF !== null?oldFormData.ACTIF:true,
              CDART: oldFormData.CDART !== null?oldFormData.CDART:'',
              CODEEXT: oldFormData.CODEEXT !== null?oldFormData.CODEEXT:'',

              STRUCTU_LABEL: oldFormData.STRUCTU_LABEL !== null?oldFormData.STRUCTU_LABEL:'',
              MTTTCMIN: oldFormData.MTTTCMIN !== null?oldFormData.MTTTCMIN:'',
              MTTTCMAX: oldFormData.MTTTCMAX !== null?oldFormData.MTTTCMAX:'',
              STOCK_SUPERIOR: oldFormData.STOCK_SUPERIOR !== null?oldFormData.STOCK_SUPERIOR:false,

              STRUCTU: oldFormData.STRUCTU !== null?oldFormData.STRUCTU:'[]',
              CSFSFAM: oldFormData.CSFSFAM !== null?oldFormData.CSFSFAM:'[]',
              IDMAG: oldFormData.IDMAG !== null?oldFormData.IDMAG:this.USERCMINT,
              IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
              ORDERBY: oldFormData.ORDERBY !== null?oldFormData.ORDERBY:Constants.ARTICLE_SEARCH_ORDER_CODE,
              sort: oldFormData.sort !== null?oldFormData.sort:'asc',
              
              STRUCT_ORDER: (oldFormData.STRUCT_ORDER !== undefined && oldFormData.STRUCT_ORDER !== null)?oldFormData.STRUCT_ORDER:'CODE',
              SOUS_ORDER: (oldFormData.SOUS_ORDER !== undefined && oldFormData.SOUS_ORDER !== null)?oldFormData.SOUS_ORDER:'SOUS_CODE',
            });
            
            if(oldFormData.STRUCTU !== undefined && oldFormData.STRUCTU !== null) {
              let formatted_struct = oldFormData.STRUCTU.replace('[', '').replace(']', '');
              this.previousTreeValue = (formatted_struct !== undefined && formatted_struct !== null)?formatted_struct.split(',').join('#'):undefined;
            }

            if(oldFormData.CSFSFAM !== undefined && oldFormData.CSFSFAM !== null) {
              // TODO - Previous value set for Sous famile
              let formatted_struct = oldFormData.CSFSFAM.replace('[', '').replace(']', '');              
            }
          }

          if(oldListResponse !== undefined && oldListResponse !== null) {
            const data = oldListResponse.data;
      
            this.rowsPerPage = oldListResponse.rowsPerPage;
            this.totalPages = oldListResponse.count;        
      
            this.listArticle = [...data];

            this.showLoader = false;
            this.dtTrigger.next();
          }
          
          const updatedArticleDetail: Article = this.storageService.getItem('articleDetailUpdate');
          
          if(updatedArticleDetail !== undefined && updatedArticleDetail !== null) {
            this.listArticle.map(
              (row) => {
                if(updatedArticleDetail.CAINT == row.CAINT) {
                  row.STOCKDISPO = updatedArticleDetail.STOCKDISPO;
                  return row;
                }

                return row;
              }
            );

            const updatable = {
              rowsPerPage: this.rowsPerPage,
              count: this.totalPages,
              data: this.listArticle
            };               

            this.storageService.setItem('articleListData', updatable);
            this.storageService.removeItem('articleDetailUpdate');
          }
        }

        if(this.data !== undefined && this.data != null && this.data !== '' && Object.keys(this.data).length !== 0)  {
          //this.resetForm();
                  
          if(this.data.CDART !== undefined && this.data.CDART != null && this.data.CDART !== '') {
            this.searchForm.patchValue({
              CDART: this.data.CDART
            });
            this.searchFormSubmitter();
          }
        }
    }

    async _getCodeClient() {
      try {
        this.codeClient = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
      } catch(err) {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    }

    ngOnDestroy(): void {
        if(this.router.routerState.snapshot.url.indexOf('article-detail') > -1) {

        } else {
          this.storageService.removeItem('articleFormData');
          this.storageService.removeItem('articleListData');
        }

        if(this.isMobile) {
          this.userService.headerSearch.next(false);          
          this.userService.headerSearchInput.next(false);
          this.searchSubscription.unsubscribe();
          this.searchCodeSubscription.unsubscribe();
        }
        
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {      
        this.dtTrigger.next();
    }

    initFillNomAndIDMag(NOM: any, flowCMINT: any) {
        this.user = this.userService.getCurrentUser();
        this.USERCMINT = this.user.CMINT;

        this.searchForm.patchValue({
            NOM: NOM || '',
            IDMAG: flowCMINT || '',
        });

        if ((NOM !== null && NOM !== undefined) && (flowCMINT !== undefined && flowCMINT !== null)) {
            this.searchFormSubmitter();
        }
    }

    async loadDependencies() {
      this.loadingService.show();

      await this.getListMagasinStructure();
      await this.getListSousFamile();

      let sousFamileChildrens: any[] = [];

      if(this.listSousFamile) {
        for(let i=0;i<this.listSousFamile.length;i++) {
          sousFamileChildrens.push({
            text: this.listSousFamile[i].CSFSFAM+' - '+this.listSousFamile[i].CSFLIB1,
            value: this.listSousFamile[i].CSFSFAM
          });
        }
      }

      this.sousfamileTreeItems = [
        new TreeviewItem({
          text: this.translateService.instant('kartsoufami'),
          value: "",
          checked: true,
          children: sousFamileChildrens           
        })
      ];

      this.changeSousFamileTreeOrder({ value: this.searchForm.get('SOUS_ORDER').value });

      if(this.displayType === 'dialog' && this.isMobile) {
        this.sousfamileTreeItems[0].collapsed = true;
      }

      this.loadingService.hide();
    }

    get multiSelectionEnabled() {
        return this.displayType === 'dialog' && (this.flowType === 'bonde_vente' || this.flowType === 'devis') && this.enableMultiSelection;
    }

    get orderedByColumn() {
      return this.searchForm.get('ORDERBY').value;
    }
  
    get sortByColumn() {
      return this.searchForm.get('sort').value;
    }

    _barCodeLogicCheck = (value: string, rapid = false, nomField = false) => {
      if(value !== undefined && value != null) {
        
        if(value.indexOf('~') > -1) {
          const [CDART, PRIX] = value.split('~');
          this.searchForm.patchValue({ CDART });

          if(nomField) {
            this.searchForm.patchValue({ NOM: '' });
          }
  
          if(rapid) {
            this.userService.headerSearchInputValue.next(CDART);
          }

          if(this.searchFormRef) {
            this.searchFormRef.ngSubmit.emit();
          } else {
            this.searchFormSubmitter()
          }

          return;  
        }
  
        if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
          let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
          CDART = CDART.replace(/^0+/, '');

          this.searchForm.patchValue({ CDART });

          if(nomField) {
            this.searchForm.patchValue({ NOM: '' });
          }

          if(rapid) {
            this.userService.headerSearchInputValue.next(CDART);
          }
  
          if(this.searchFormRef) {
            this.searchFormRef.ngSubmit.emit();
          } else {
            this.searchFormSubmitter()
          }

          return;
        }

        if(this.searchFormRef) {
          this.searchFormRef.ngSubmit.emit();
        } else {
          this.searchFormSubmitter()
        }
      }
    }

    onCodeEntered(e: any) {
      const cdart = this.searchForm.get('CDART');
      this._barCodeLogicCheck(cdart.value);
    }
  
    sortingOrder(orderBy, sort?: 'asc' | 'desc') {
      let prevOrder = this.orderedByColumn;
      this.searchForm.get('ORDERBY').setValue(orderBy);
  
      if(sort === undefined || sort == null) {
        let prevSort = this.sortByColumn;
        if(prevOrder === orderBy) {
          this.searchForm.get('sort').setValue(prevSort === 'asc'?'desc':'asc');
        } else {
          this.searchForm.get('sort').setValue('asc');
        }
      }

      if(sort === 'asc' || sort === 'desc') {
        this.searchForm.get('sort').setValue(sort);
      }

      this.searchFormSubmitter();
    }

    getCdartWithPlacehold(CDART: string): string {
      return (Constants.CONFORAMA_CDART_PLACEHOLDER).replace("{{CDART_PLACEHOLDER}}", CDART);
    }

    getImageURL(CDART: string): string {
      if(this.codeClient && this.codeClient.toLowerCase() === 'conforama') {
        // To generate Conforama Image URL Algorithm
        let concatenated = "";
        const length = Constants.CONFORMA_IMAGE_SEGMENT_LENGTH;
        for(let i=0;i < length;i++) {
          if(i < (length - 1)) {
            if(concatenated !== '') {
              concatenated += "/"  
            }
            if(CDART[i] !== undefined) {
              concatenated += (CDART[i].padEnd(length - i, '0'));
            } else {
              concatenated += ('0'.padEnd(length - i, '0')); // Prefixing Zero if the CDART length is small as mentioned in CAP-1056
            }
          }
        }

        const URL = Constants.CONFORAMA_IMAGE_URL + concatenated + '/' + this.getCdartWithPlacehold(CDART) + Constants.DEFAULT_IMAGE_EXTENSION;
        return URL;
      }

      return Constants.BASE_IMAGE_URL+CDART+Constants.DEFAULT_IMAGE_EXTENSION;
    }

    itemChosenChange(event: any, item: Article) {    
      let occupied = this.chosenLineItems.find((row) => row.cdart == item.CDART);
  
      if(occupied) {
        this.chosenLineItems.map((row) => {
          if(row.cdart == item.CDART) {
            row.status = event;
          }
          return row;
        });
        this.chosenLineItems = this.chosenLineItems.filter((row) => row.status);
      } else {
        this.chosenLineItems.push({
          cdart: item.CDART,
          status: event,
          data: item
        });
      }

      this.onMultiArticleSelectionChange.next(this.chosenLineItems);
    }

    public resetForm = () => {
        if(this.isMobile) {
          this.userService.headerSearchInputValue.next('');
        }        

        this.searchForm.patchValue({ 
          NOM: '',
          LIV: false,
          ACTIF: true,
          CDART: '',
          CODEEXT: '',

          STRUCTU_LABEL: '',
          MTTTCMIN: '',
          MTTTCMAX: '',
          STOCK_SUPERIOR: false,

          STRUCTU: '[]',
          CSFSFAM: '[]',
          IDMAG: this.USERCMINT,
          IDSOC: Constants.IDSOC,
          ORDERBY: Constants.ARTICLE_SEARCH_ORDER_CODE,
          sort: 'asc',
          
          STRUCT_ORDER: 'CODE',
          SOUS_ORDER: 'SOUS_CODE',
        });

        this.filterValues = undefined;
        this.storageService.removeItem('STRUCTU_KEYWORDS');
        this.changeMagasinStructTreeOrder({ value: 'CODE' });
        this.changeSousFamileTreeOrder({ value: 'SOUS_CODE' });

        this.filteredTreeItems[0].checked = true;
        this.filteredTreeItems[0].collapsed = false;

        this.storageService.removeItem('articleFormData');
        this.storageService.removeItem('articleListData');

        // Reset the multi article selection checkboxes after resetting the form
        this.chosenLineItems = [];
        this.checkedLineItems = [];
        this.onMultiArticleSelectionChange.next(this.chosenLineItems);
        
        let sousChilds = this.sousfamileTreeItems[0].children;
        let childs = this.filteredTreeItems[0].children;
        if(childs) {            
          childs.forEach(
            (row) => {
              let sub_childs = row.children;
              row.checked = true;
              row.collapsed = true;

              if(sub_childs) {
                sub_childs.forEach(
                  (sub_row) => {
                    sub_row.checked = true;
                    sub_row.collapsed = true;

                    if(sub_row.children) {
                      sub_row.children.forEach((lastSubRow => {
                        lastSubRow.checked = true;
                        lastSubRow.collapsed = true;
                      }))
                    }
                  }
                )
              }

            }
          );
        }

        if(sousChilds) {
          this.sousfamileTreeItems[0].checked = true;
          sousChilds.forEach(
            (row) => {
              row.checked = true;
              row.collapsed = true;
            }
          )
        }

        this.searchFormSubmitter();
    }

    public editArticle(info: any, stockColumn = false): void {
        if(this.displayType !== 'component') {
          if(stockColumn) return;

          this.searchedArticle = info;
          this.onArticleChnage.next(info);
          return;
        }

        this.storageService.setItem('article', info);

        this.storageService.setItem('searchDataArticle', this.searchDataArticle);

        this.router.navigate(['../article-detail/'+info.CDART], { relativeTo: this.route });
    }

    searchFormSubmitter(): void {
      if(this.displayType === 'dialog') {
        this.showLoader = true;  
        this.loadingService.show();
      } else {
        this.showLoader = true;  
      }
    
      if(this.searchForm.invalid) {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));
  
        return;
      }
          
      const form_data = this.searchForm.value;
      this.filterValues = form_data;

      this.storageService.setItem('articleFormData', form_data);      
      
      this.searchArticles(form_data);
    }

    searchArticles(form_data: any) {
      form_data.stockdispo = form_data.STOCK_SUPERIOR;
      
      if(this.rapidSearch) {
        form_data.rapid = this.rapidSearch;
      }

      form_data['PARENT_IDMAG'] = this.USERCMINT;

      this.articleService.searchArticles(form_data).subscribe(
        (response: any) => {
          this.currentPage = 0;
  
          const data = response.data;
  
          this.rowsPerPage = response.rowsPerPage;
          this.totalPages = response.count;
  
          this.listArticle = [...data];                      
  
          this.showLoader = false;
          this.dtTrigger.next();

          if(this.rapidSearch) {
            if(response.count_cdart > 0) {
              this.searchForm.patchValue({ CDART: form_data.CDART, NOM: '' });
            }

            if(response.count_nom > 0) {
              this.searchForm.patchValue({ CDART: '', NOM: form_data.CDART });
            }
          }

          // Reset the multi article selection checkboxes after searching the form
          this.chosenLineItems = [];
          this.checkedLineItems = [];
          this.onMultiArticleSelectionChange.next(this.chosenLineItems);
          
          this.storageService.setItem('articleListData', response);
          this.rapidSearch = false;
          this.loadingService.hide();
        }, 
        err => {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
        }
      )
    }
    
    searchPaginate(page?: number) {
        const form_data = this.searchForm.value;
        
        if(this.searchForm.invalid) {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

            return;
        }

        form_data['PARENT_IDMAG'] = this.USERCMINT;

        this.filterValues = form_data;
        this.storageService.setItem('articleFormData', form_data);
        
        this.articleService.searchArticles(form_data, page).subscribe(
            (response: any) => {
                const data = response.data;

                this.rowsPerPage = response.rowsPerPage;
                this.totalPages = response.count;

                this.listArticle = [...data];

                this.showLoader = false;
                this.dtTrigger.next();
                
                this.storageService.setItem('articleListData', response);
            }, 
            err => {
                this.showLoader = false;
                this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            }
        )
    }
    
    paginationChange(event: any) {
        this.showLoader = true;

        const changedPageIndex = event.pageIndex;    

        this.currentPage = changedPageIndex;

        this.searchPaginate(changedPageIndex + 1);        
    }

    splitUpStructString(previous_string: string):Array<{ level_1: string, level_2?: string, level_3?: string, level_4?: string, level_5?: string }> | "" {    
      const splittedData: any[] = [];

      if(previous_string) {
        const tree_values_split_up = previous_string.split('#');      
        tree_values_split_up.forEach(
          (row) => {
            const [level_1, level_2, level_3, level_4, level_5] = row.split(';');
            
            splittedData.push({
              level_1,
              level_2,
              level_3,
              level_4,
              level_5
            });                
          }
        );
      }
      
      return splittedData || "";
    }

    async getListMagasinStructure() {
        return new Promise<void>((resolve, reject) => {
          let httpData = {
            IDMAG: this.USERCMINT,
            IDSOC: Constants.IDSOC,
            CIINT: this.user.CIINT
          };

          this.utilisatuersService.getMagasinStructure(httpData).subscribe(
            (res: any) => {
              if(res.success !== undefined) {
                this.magasinStructure = res.data;                 

                const previousTreeValue = this.splitUpStructString(this.previousTreeValue);

                this.treeItems = [
                  new TreeviewItem({
                    text: this.translateService.instant('kartmar'),
                    value: '',
                    checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                    children: this.__structurizeMarchandiseData(this.magasinStructure, previousTreeValue)
                  })
                ];

                this.filteredTreeItems = this.treeItems;
                
                this.changeMagasinStructTreeOrder({ value: this.searchForm.get('STRUCT_ORDER').value });

                if(this.displayType === 'dialog' && this.isMobile) {
                  this.treeItems[0].collapsed = true;
                  this.filteredTreeItems[0].collapsed = true;
                }

                resolve();            
              }
            },
            err => {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject()
            }
          );
        });    
    }

    __isolateStructureData = (magasinStructure: MagasinStructModel[], level: 1 | 2 | 3 | 4 | 5 = 1) => {
      const filteredStruct = magasinStructure.filter(
        (res: MagasinStructModel) => {
          if(level === 1) {
            return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 == '0');
          }
  
          if(level === 2) {
            return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 == '0');
          }
  
          if(level === 3) {
            return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 == '0');
          }
  
          if(level === 4) {
            return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 != '0' && res.CSTNIV5 == '0');
          }
  
          if(level === 5) {
            return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 != '0' && res.CSTNIV5 != '0');
          }
        }
      );
  
      return filteredStruct;
    }
  
    __structurizeMarchandiseData = (magasinStructure: MagasinStructModel[], previousTreeValue: Array<{ level_1: string, level_2?: string, level_3?: string, level_4?: string, level_5?: string }> | "") => {
  
      const level_1 = this.__isolateStructureData(magasinStructure, 1);
      const level_2 = this.__isolateStructureData(magasinStructure, 2);
      const level_3 = this.__isolateStructureData(magasinStructure, 3);
      const level_4 = this.__isolateStructureData(magasinStructure, 4);
      const level_5 = this.__isolateStructureData(magasinStructure, 5);
  
      const parentTreeItems: TreeviewItem[] = [];
  
      level_1.forEach(
        (row_1: MagasinStructModel) => {
  
          const level_1_prev_filter = (previousTreeValue !== "" && previousTreeValue.length > 0)?previousTreeValue.filter(tree_row => tree_row.level_1 === row_1.CSTNIV1):null;
  
          const level_2_filtered = level_2.filter((_r) => _r.CSTNIV1 == row_1.CSTNIV1);
          const level_1_childrens: any[] = [];
  
          if(level_2_filtered) {
            level_2_filtered.forEach(
              (row_2: MagasinStructModel) => {
  
                const level_2_prev_filter = level_1_prev_filter !== null?level_1_prev_filter.filter(tree_row => tree_row.level_2 === row_2.CSTNIV2):null;

                const level_3_filtered = level_3.filter((_r) => _r.CSTNIV1 == row_2.CSTNIV1 && _r.CSTNIV2 == row_2.CSTNIV2);
                const level_2_childrens: any[] = [];
  
                if(level_3_filtered) {
                  level_3_filtered.forEach(
                    (row_3: MagasinStructModel) => {
  
                      const level_3_prev_filter = (level_2_prev_filter && level_2_prev_filter !== null)?level_2_prev_filter.filter(tree_row => tree_row.level_3 === row_3.CSTNIV3):null;
  
                      const level_4_filtered = level_4.filter((_r) => _r.CSTNIV1 == row_3.CSTNIV1 && _r.CSTNIV2 == row_3.CSTNIV2 && _r.CSTNIV3 == row_3.CSTNIV3);
                      const level_3_childrens: any[] = [];
  
                      if(level_4_filtered) {
                        level_4_filtered.forEach(
                          (row_4: MagasinStructModel) => {
  
                            const level_4_prev_filter = (level_3_prev_filter && level_3_prev_filter !== null)?level_3_prev_filter.filter(tree_row => tree_row.level_4 === row_4.CSTNIV4):null;
  
                            const level_5_filtered = level_5.filter((_r) => _r.CSTNIV1 == row_4.CSTNIV1 && _r.CSTNIV2 == row_4.CSTNIV2 && _r.CSTNIV3 == row_4.CSTNIV3 && _r.CSTNIV4 == row_4.CSTNIV4);
                            const level_4_childrens: any[] = [];
  
                            if(level_5_filtered) {
                              level_5_filtered.forEach(
                                (row_5: MagasinStructModel) => {
  
                                  const level_5_prev_filter = (level_4_prev_filter && level_4_prev_filter !== null)?level_4_prev_filter.find(tree_row => tree_row.level_5 === row_5.CSTNIV5):null;                            
  
                                  level_4_childrens.push({
                                    text: row_5.CSTNIV5+' - '+row_5.CSTLIB1,
                                    value: row_5.CSTNIV5,
                                    checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:((level_5_prev_filter && level_5_prev_filter !== null)?(level_5_prev_filter.level_5?true:false):false)
                                  });
                                }
                              )
                            }
  
                            level_3_childrens.push({
                              text: row_4.CSTNIV4+' - '+row_4.CSTLIB1,
                              value: row_4.CSTNIV4,
                              checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                              collapsed: true,
                              children: level_4_childrens
                            });
                          }
                        )
                      }
  
                      level_2_childrens.push({
                        text: row_3.CSTNIV3+' - '+row_3.CSTLIB1,
                        value: row_3.CSTNIV3,
                        checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                        collapsed: true,
                        children: level_3_childrens
                      });
                    }
                  )
                }                      
  
                level_1_childrens.push({
                  text: row_2.CSTNIV2+' - '+row_2.CSTLIB1,
                  value: row_2.CSTNIV2,
                  checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                  collapsed: true,
                  children: level_2_childrens
                });
              }
            )
          }                
  
          parentTreeItems.push(new TreeviewItem({
            text: row_1.CSTNIV1+' - '+row_1.CSTLIB1,
            value: row_1.CSTNIV1,
            checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
            collapsed: true,
            children: level_1_childrens
          }));
          
        }
      )
  
      return parentTreeItems;
    }

    async getListSousFamile(): Promise<void> {
      return new Promise((resolve, reject) => {
        let httpData = {
          IDMAG: this.USERCMINT,
          IDSOC: Constants.IDSOC,
          CIINT: this.user.CIINT
        };

        this.articleService.getSousFamileList(httpData).subscribe(
          (res: any) => {
            if(res.success !== undefined) {
              this.listSousFamile = res.data;
              resolve();
            }
          },
          err => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject()
          }
        );
      });
    }

    searchStructureMarchandise(search: string): void {
      if(search !== "") {
        let cumulatedKeys = [];
        const oldKeys = this.storageService.getItem('STRUCTU_KEYWORDS');
        if(oldKeys !== undefined && oldKeys != null && oldKeys != 'null') {
          const oldKeysSeparated = oldKeys.split(', ');
          const alreadyExist = oldKeysSeparated.find((key) => key.toLowerCase() == search.toLowerCase());

          if(alreadyExist !== undefined && alreadyExist !== null) {
            // Keyword already exists in the array
            cumulatedKeys = oldKeysSeparated;
          } else {
            // New keyword
            cumulatedKeys = [...oldKeysSeparated, search.toLowerCase()];
          }
        } else {
          // First time search
          cumulatedKeys = [search.toLowerCase()];
        }

        this.storageService.setItem('STRUCTU_KEYWORDS', cumulatedKeys.join(', '));
      }

      this.filterStructu(search, true);
    }

    _clearStructuLabel() {
      this.searchStructureMarchandiseLabel = '';
      this.filterStructu(this.searchStructureMarchandiseLabel);
    }

    _resetStructuTree() {
      this.searchStructureMarchandiseLabel = '';
      this.filterStructu(this.searchStructureMarchandiseLabel);
      this.storageService.removeItem('STRUCTU_KEYWORDS');

      this.searchForm.patchValue({
        STRUCTU_LABEL: '',
        STRUCTU: '[]',
        
        STRUCT_ORDER: 'CODE',
      });

      this.changeMagasinStructTreeOrder({ value: 'CODE' });

      this.filteredTreeItems[0].checked = true;
      this.filteredTreeItems[0].collapsed = false;

      let childs = this.filteredTreeItems[0].children;
      if(childs) {            
        childs.forEach(
          (row) => {
            row.checked = true;
            row.collapsed = true;

            row.setCheckedRecursive(true);
            row.setCollapsedRecursive(true);
          }
        );
      }
    }

    filterStructu = (search: string, checkable = false) => {
      const allChecked = this.__checkAllStructuChecked();
      const anythingChecked = this.__checkAnyStructuChecked();

      if(allChecked === true) {
        this.filteredTreeItems[0].setCheckedRecursive(false); // to Uncheck all the tree items if all boxes have already checked
        // Structure Marchandise - All checked
        this.__filterBySearchedString(search, checkable);
      } else {
        if(anythingChecked === true) {
          // Structure Marchandise - Not all checked and something checked
          this.__filterBySearchedString(search, checkable);
        } else {
          // Structure Marchandise - Nothing checked
          this.__filterBySearchedString(search, checkable);
        }
      }

      if(this.displayType === 'dialog') {
        this.filteredTreeItems[0].collapsed = false;
      }
    }

    __filterBySearchedString(search: string = '', checkable = false) {
      this.filteredTreeItems[0].children.map(
        (_pr) => {
          let childAvail = false;
          if(_pr.children !== undefined && _pr.children !== null && _pr.children.length > 0) {
            _pr.children.map(
              (_cr) => {
                let subChildAvail = false;
                if(_cr.children !== undefined && _cr.children !== null && _cr.children.length > 0) {
                  _cr.children.map(
                    (_scr) => {
                      let level_4_child_avail = false;
                      if(_scr.children !== undefined && _scr.children !== null && _scr.children.length > 0) {
                        _scr.children.map(
                          (_sscr) => {
                            let level_5_child_avail = false;
                            if(_sscr.children !== undefined && _sscr.children !== null && _sscr.children.length > 0) {
                              _sscr.children.map(
                                (_ssscr) => {

                                  if(search !== '' && search.length > 2 && _ssscr.text.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                                    level_5_child_avail = true;
                                    _ssscr.collapsed = false;
                                    if(checkable) {
                                      _ssscr.checked = true; // !_ssscr.checked
                                    }

                                    return _ssscr;
                                  }

                                  if(search !== '' && search.length > 2 && _ssscr.checked) {
                                    _ssscr.collapsed = false;
                                    return;  
                                  }
                      
                                  _ssscr.collapsed = true;

                                  return _ssscr;
                                }
                              );
                            }

                            if(level_5_child_avail) {
                              _sscr.correctChecked();
                            }

                            if(level_5_child_avail || (search !== '' && search.length > 2 && _sscr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                              level_4_child_avail = true;
                              _sscr.collapsed = false;
                              _sscr.setCollapsedRecursive(false);

                              if((search !== '' && search.length > 2 && _sscr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                                _sscr.setCheckedRecursive(true);
                              }
                
                              return _sscr;
                            }

                            if(search !== '' && search.length > 2 && (_sscr.checked || _sscr.indeterminate)) {
                              _sscr.setCollapsedRecursive(false);
                              return;  
                            }

                            _sscr.collapsed = true;
                            _sscr.setCollapsedRecursive(true);

                            return _sscr;
                          }
                        );
                      }

                      if(level_4_child_avail) {
                        _scr.correctChecked();
                      }

                      if(level_4_child_avail || (search !== '' && search.length > 2 && _scr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                        subChildAvail = true;
                        _scr.collapsed = false;
                        _scr.setCollapsedRecursive(false);

                        if((search !== '' && search.length > 2 && _scr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                          _scr.setCheckedRecursive(true);
                        }
          
                        return _scr;
                      }

                      if(search !== '' && search.length > 2 && (_scr.checked || _scr.indeterminate)) {
                        _scr.setCollapsedRecursive(false);
                        return;  
                      }
          
                      _scr.collapsed = true;
                      _scr.setCollapsedRecursive(true);
                      return _scr;
                    }
                  );
                }

                if(subChildAvail) {
                  _cr.correctChecked();
                }

                if(subChildAvail || (search !== '' && search.length > 2 && _cr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                  childAvail = true;
                  _cr.collapsed = false;
                  _cr.setCollapsedRecursive(false);

                  if((search !== '' && search.length > 2 && _cr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                    _cr.setCheckedRecursive(true);
                  }
    
                  return _cr;
                }

                if(search !== '' && search.length > 2 && (_cr.checked || _cr.indeterminate)) {
                  _cr.setCollapsedRecursive(false);
                  return;  
                }
    
                _cr.collapsed = true;
                _cr.setCollapsedRecursive(true);
                return _cr;
              }
            )
          }

          if(childAvail) {
            _pr.correctChecked();
          }

          if(childAvail || (search !== '' && search.length > 2 && _pr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
            _pr.collapsed = false;

            if((search !== '' && search.length > 2 && _pr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
              _pr.setCheckedRecursive(true);
            }

            return _pr;
          }

          if(search !== '' && search.length > 2 && (_pr.checked || _pr.indeterminate)) {
            _pr.setCollapsedRecursive(false);
            return;  
          }

          _pr.collapsed = true;
          return _pr;
        }
      );

      if(checkable) {
        this.filteredTreeItems[0].correctChecked();
        this.magTreeComponent.raiseSelectedChange();
      }
    }

    __checkAllStructuChecked = () => {
      return this.filteredTreeItems[0].checked;
    }

    __checkAnyStructuChecked = () => {
      return this.filteredTreeItems[0].indeterminate;
    }

    sortByString = (a: string, b: string) => { 
      if ( a < b ){
        return -1;
      }
      if ( a > b ){
        return 1;
      }
      return 0;
    }

    sortByNumber = (a: number, b: number) => {
      return a - b; 
    }

    structureSorting(event: any, a: TreeviewItem, b: TreeviewItem) {
      if(a.children) {
        a.children.sort(
          (a: TreeviewItem, b: TreeviewItem) => {            
            return this.structureSorting(event, a, b);
          }
        );
      }

      if(b.children) {
        b.children.sort(
          (a: TreeviewItem, b: TreeviewItem) => {
            return this.structureSorting(event, a, b);               
          }
        );
      }      

      const a_text = a.text.split(' - ')[1];
      const a_value = a.value;

      const b_text = b.text.split(' - ')[1];
      const b_value = b.value;

      if(event.value === 'CODE' || event.value === 'SOUS_CODE') { // Sorting by CODE
        return this.sortByNumber(a_value, b_value);
      } else if (event.value === 'LABEL' || event.value === 'SOUS_LABEL') { // Sorting by Label
        return this.sortByString(a_text, b_text);
      } 
    }

    changeMagasinStructTreeOrder(event: any) {
      if(this.filteredTreeItems[0] === undefined || this.filteredTreeItems[0] == null || this.filteredTreeItems[0].children == null || this.filteredTreeItems[0].children == undefined || this.filteredTreeItems[0].children.length == 0) return;

      this.filteredTreeItems[0].children.sort(
        (a: TreeviewItem, b: TreeviewItem) => {        
          return this.structureSorting(event, a, b);
        }
      );
    }

    changeSousFamileTreeOrder(event: any) {
      if(this.sousfamileTreeItems === undefined || this.sousfamileTreeItems == null || this.sousfamileTreeItems[0].children === undefined || this.sousfamileTreeItems[0].children == null) return;
        this.sousfamileTreeItems[0].children.sort(
          (a: TreeviewItem, b: TreeviewItem) => { 
            return this.structureSorting(event, a, b);                                              
          }
        );     
    }

    onTreeValueChanged(downlineItems: DownlineTreeviewItem[]) {
        let tempTreeValues: string[] = [];
        
        this.treeValues = [];
        this.totalSelected = downlineItems.length;

        downlineItems.forEach((downlineItem: DownlineTreeviewItem) => {      
          const item = downlineItem.item;          

          const value = item.value;      
          const parent_values = [value];
          let parent = downlineItem.parent;
          
          while (!isNil(parent)) {        
              parent_values.push(parent.item.value);
              parent = parent.parent;                   
          }
          
          const reverseValues = reverse(parent_values);      

          const row = `${reverseValues.join(";")}`;        
          tempTreeValues.push(row.replace(/^;/, ''));
        }); 

        this.treeValues = tempTreeValues;

        if(this.filteredTreeItems[0].checked === true && !this.filteredTreeItems[0].indeterminate) {
          this.searchForm.patchValue({ STRUCTU: '[]' });
        } else {
          this.searchForm.patchValue({ STRUCTU: JSON.stringify(this.treeValues) });
        }
    }

    onSousTreeValueChanged(downlineItems: DownlineTreeviewItem[]) {
      this.sousfamileTreeValues = [];    
      
      this.sousfamileTotalSelected = downlineItems.length;

      downlineItems.forEach((downlineItem: DownlineTreeviewItem) => {      
        const item = downlineItem.item;          

        const value = item.value;      
        const parent_values = [value];
        let parent = downlineItem.parent;
        
        while (!isNil(parent)) {        
            parent_values.push(parent.item.value);
            parent = parent.parent;                   
        }
        
        const reverseValues = reverse(parent_values);      

        const row = `${reverseValues.join(";")}`;        
        this.sousfamileTreeValues.push(row.replace(/^;/, ''));        
      });

      if(this.sousfamileTreeItems[0].checked === true && !this.sousfamileTreeItems[0].indeterminate) {
        this.searchForm.patchValue({
          CSFSFAM: '[]'
        });
      } else {        
        this.searchForm.patchValue({
          CSFSFAM: JSON.stringify(this.sousfamileTreeValues)
        });
      }
    }

    openUpDetailStock(article: Article) {
      const dialogRef = this.dialog.open(ArticleStockComponent,
        {
          minWidth: "50vw",
          autoFocus: false,
          data: {
            articleData: article,
            CAINT: article.CAINT,
            stockBlocageEditable: true,
            bonVenteFlow: true,
            currentStockMag: (this.data && this.data.stockAutreMag) ? this.data.stockAutreMag : undefined,
          }
        }
      );
  
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.status === "success") {
            if(result.STOCK_DISPO !== undefined && result.STOCK_DISPO !== null) {
              this.listArticle.map((_r) => {

                if(_r.CAINT == article.CAINT) {
                  _r.STOCKDISPO = result.STOCK_DISPO;
                }

                return _r;
              });
            }
        }
      });
    }

    openUpFilterScreen(fetchType: 'prefetch' | 'new_load' = 'new_load') {
      this.filterValues = this.userService.getAdvanceSearchFormData(); 
      this.userService.headerSearch.next(false);

      const dialogRef = this.dialog.open(ArticleFilterComponent, {      
        width: '60vw',
        maxHeight: this.isMobile?'95vh':undefined,
        autoFocus: false,
        data: {
          fetchType: fetchType,
          form_data: (!this.filterValues)?this.searchForm.getRawValue():this.filterValues,
          orderBySearchList: this.orderBySearchList,
          
          user: this.user,
          USERCMINT: this.USERCMINT,

          config: this.config,
          sousTreeConfig: this.sousTreeConfig,

          magasinStructure: this.magasinStructure,
          treeItems: this.treeItems,

          treeValues: this.treeValues,
          totalSelected: this.totalSelected,
          listSousFamile: this.listSousFamile,
          sousfamileTreeItems: this.sousfamileTreeItems,
          sousfamileTreeValues: this.sousfamileTreeValues,
          sousfamileTotalSelected: this.sousfamileTotalSelected,
          changeMagasinStructTreeOrder: this.changeMagasinStructTreeOrder,
          changeSousFamileTreeOrder: this.changeSousFamileTreeOrder,
          onTreeValueChanged: this.onTreeValueChanged,
          onSousTreeValueChanged: this.onSousTreeValueChanged,
          resetForm: this.resetForm
        }     
      });  
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.status === "success") {
          const form_data = result.form_data;
         
          this.searchForm.patchValue({            
            NOM: form_data.NOM !== null?form_data.NOM:'',
            LIV: form_data.LIV !== null?form_data.LIV:false,
            ACTIF: form_data.ACTIF !== null?form_data.ACTIF:true,
            CDART: form_data.CDART !== null?form_data.CDART:'',
            CODEEXT: form_data.CODEEXT !== null?form_data.CODEEXT:'',

            STRUCTU_LABEL: form_data.STRUCTU_LABEL !== null?form_data.STRUCTU_LABEL:'',
            MTTTCMIN: form_data.MTTTCMIN !== null?form_data.MTTTCMIN:'',
            MTTTCMAX: form_data.MTTTCMAX !== null?form_data.MTTTCMAX:'',
            STOCK_SUPERIOR: form_data.STOCK_SUPERIOR !== null?form_data.STOCK_SUPERIOR:false,

            STRUCTU: form_data.STRUCTU !== null?form_data.STRUCTU:'[]',
            CSFSFAM: form_data.CSFSFAM !== null?form_data.CSFSFAM:'[]',
            IDMAG: form_data.IDMAG !== null?form_data.IDMAG:this.USERCMINT,
            IDSOC: form_data.IDSOC !== null?form_data.IDSOC:Constants.IDSOC,
            ORDERBY: form_data.ORDERBY !== null?form_data.ORDERBY:Constants.ARTICLE_SEARCH_ORDER_CODE,
            sort: form_data.sort !== null?form_data.sort:'asc',
            
            STRUCT_ORDER: (form_data.STRUCT_ORDER !== undefined && form_data.STRUCT_ORDER !== null)?form_data.STRUCT_ORDER:'CODE',
            SOUS_ORDER: (form_data.SOUS_ORDER !== undefined && form_data.SOUS_ORDER !== null)?form_data.SOUS_ORDER:'SOUS_CODE',
          });

          this.treeItems = result.treeItems;
          this.filteredTreeItems = this.treeItems;
          this.treeValues = result.treeValues;
          this.totalSelected = result.totalSelected;
          this.listSousFamile = result.listSousFamile;
          this.sousfamileTreeValues = result.sousfamileTreeValues;
          this.sousfamileTreeItems = result.sousfamileTreeItems;
          this.sousfamileTotalSelected = result.sousfamileTotalSelected;

          this.filterValues = form_data;
          
          if(this.isMobile){
            this.userService.setAdvanceSearchFormData(this.filterValues);
            this.userService.popupClose.next({'action':'clientAdsearch'});
          }
          this.searchFormSubmitter();          
        }  else {          
          //advanced search close 
          if(this.isMobile){            
            this.userService.popupClose.next(false);
          }
        }      
      }); 
    }

    get filterValuesFormat(): string {
      let filterArr = [];
      if(this.filterValues.CDART !== undefined && this.filterValues.CDART !== null && this.filterValues.CDART !== '') {
        filterArr.push(this.filterValues.CDART);
      }
      if(this.filterValues.NOM !== undefined && this.filterValues.NOM !== null && this.filterValues.NOM !== '') {
        filterArr.push(this.filterValues.NOM);
      }
      if(this.filterValues.CODEEXT !== undefined && this.filterValues.CODEEXT !== null && this.filterValues.CODEEXT !== '') {
        filterArr.push(this.filterValues.CODEEXT);
      } 
      if(this.filterValues.MTTTCMIN !== undefined && this.filterValues.MTTTCMIN !== null && this.filterValues.MTTTCMIN !== '') {
        filterArr.push(this.filterValues.MTTTCMIN);
      }
      if(this.filterValues.MTTTCMAX !== undefined && this.filterValues.MTTTCMAX !== null && this.filterValues.MTTTCMAX !== '') {
        filterArr.push(this.filterValues.MTTTCMAX);
      }         
      if(this.filterValues.LIV !== undefined && this.filterValues.LIV !== null && this.filterValues.LIV !== '') {
        if(this.filterValues.LIV) {
          filterArr.push(this.translateService.instant('kartdelitem'));
        }
      }
      if(this.filterValues.ACTIF !== undefined && this.filterValues.ACTIF !== null && this.filterValues.ACTIF !== '') {
        if(this.filterValues.ACTIF) {
          filterArr.push(this.translateService.instant('kartactitm'));
        }
      }
      if(this.filterValues.STOCK_SUPERIOR !== undefined && this.filterValues.STOCK_SUPERIOR !== null && this.filterValues.STOCK_SUPERIOR !== '') {
        if(this.filterValues.STOCK_SUPERIOR) {
          filterArr.push(this.translateService.instant('kdisponibleimmedi'));
        }
      }
      
      const structuKeyWords = this.storageService.getItem('STRUCTU_KEYWORDS');
      if(structuKeyWords !== undefined && structuKeyWords != 'null' && structuKeyWords != null && structuKeyWords !== '') {
        filterArr.push(`"${structuKeyWords}"`);
      }

      return filterArr.length > 0 ? filterArr.join(', ') : '';
    }
}
