import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import BonPanier from 'models/bonPanier';
import { ParamDetail } from 'models/paramDetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';

@Component({
  selector: 'app-relance-confirmation',
  templateUrl: './relance-confirmation.component.html',
  styleUrls: ['./relance-confirmation.component.scss']
})
export class RelanceConfirmationComponent implements OnInit {

  confirmationForm: UntypedFormGroup;
  panier: BonPanier;
  paramsList: ParamDetail[];

  CMINT: any;
  flowType: 'bonde_commande' | 'devis';

  constructor(private formBuilder: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: any,
            public dialogRef: MatDialogRef<RelanceConfirmationComponent>,
            private userService: UserService,
            private translateService: TranslateService,
              private dialogService: DialogsService,
            private loadingService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.confirmationForm = this.formBuilder.group({
      SMS: [false],
      EMAIL: [false]
    });

    this.flowType = this.data.flowType;
    this.panier = this.data.panier;
    this.CMINT = this.data.CMINT;
    
    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();
    
    await this.getListParams();
    this.checkBoxRestriction();

    this.loadingService.hide();
  }

  checkBoxRestriction() {
    let processedRules = this.userService.processRulesParams(this.paramsList);
    let paramKey = this.flowType === 'bonde_commande' ? '51_2' : '51_3';

    if(
      (this.panier.client === null) ||
      ((processedRules[paramKey]['VBOOL1'] != '-1') && // VBOOL1 If yes
        (processedRules[paramKey]['VBOOL4'] != '-1')// and also VBOOL4 If yes
      ) ||
      (
        (processedRules[paramKey]['VBOOL1'] == '-1') && // VBOOL1 If yes
        (processedRules[paramKey]['VBOOL4'] == '-1') && // and also VBOOL4 If yes
        (this.panier.client.email == null || this.panier.client.email === '') &&
        (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
        (this.panier.client.tel == null || this.panier.client.tel === '')
      ) ||
      (
        (processedRules[paramKey]['VBOOL4'] == '-1') && // VBOOL4 If yes
        (processedRules[paramKey]['VBOOL1'] != '-1') && // VBOOL1 and if not yes
        (this.panier.client.email == null || this.panier.client.email === '')
      ) ||
      (
        (processedRules[paramKey]['VBOOL1'] == '-1') && // VBOOL1 If yes
        (processedRules[paramKey]['VBOOL4'] != '-1') && // VBOOL4 and if not yes
        (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
        (this.panier.client.tel == null || this.panier.client.tel === '')
      )
    ) {
      this.confirmationForm.get('SMS').disable({ onlySelf: true });
    }
  }

  getListParams(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getRulesParams(this.CMINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {              
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  confirmationFormSubmitter() {
    const form_data = this.confirmationForm.getRawValue();
    this.dialogRef.close({ status: 'success', data: form_data });
  }

}
