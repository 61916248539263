<h1  *ngIf="displayType === 'dialog'" mat-dialog-title class="text-center">{{'kumerch1' | translate}}</h1>
<div mat-dialog-content>
    <div class="">
        <div class="row">
            <div class="col-md-12">
                <h5 *ngIf="treeItems === undefined || treeItems === null || treeItems.length === 0" class="font-weight-bold">{{'kartmar' | translate}}</h5>
                <div class="form-group" *ngIf="treeItems !== undefined && treeItems !== null && treeItems.length > 0">
                    <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
                    let-onCheckedChange="onCheckedChange">
                        <div class="form-inline row-item">
                            <i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
                                <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                                <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </i>
                            <div class="form-check">
                                <mat-checkbox class="example-margin" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()" [disabled]="item.disabled" [indeterminate]="item.indeterminate">{{item.text}}</mat-checkbox>
                            </div>
                        </div>
                    </ng-template>

                    <ngx-treeview [config]="config" [items]="treeItems" [itemTemplate]="itemTemplate" (selectedChange)="onTreeValueChanged($event)">
                    </ngx-treeview>
                </div> 
            </div>                
        </div>
    </div>
    <div class="bloc_container" *ngIf="displayType === 'dialog'">
        <div class="row bg_white">
            <div class="col-12 text-center">
                <button class="bt_valid" mat-button (click)="saveDetails()">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kuok' | translate}}
                    </span>                    
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kucancel' | translate}}
                    </span>                    
                </button>
            </div>
        </div>
    </div>    
</div>