<div class="modal-container" id="numero_court_vente">
    <form [formGroup]="numeroForm" (ngSubmit)="numeroFormSubmitter()">
    <mat-card>
        <mat-card-header >
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>{{'kbonumerocourt' | translate}}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-12">
                    <mat-form-field floatLabel="always">
                        <mat-label>{{'kbonumerocourtno' | translate}}</mat-label>
                        <input matInput formControlName="NUMERO_COURT" maxlength="4" minlength="4" class="text-uppercase" />
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button type="submit" class="bt_send">
                <i class="material-icons">done</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kuok' | translate}}
                </span>
            </button>
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
    </form>
</div>