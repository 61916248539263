<mat-card>    
    <mat-card-content class="mat-card-content-form">        
        <div class="row" [class.no-gutters]="isMobile">
            <div class="col-6 col-md-7">
                <mat-form-field floatLabel="always">
                    <mat-label>{{ (flowType == 'devis'?'kdevcreepar':'kboncreepar') | translate}} </mat-label>
                    <input matInput [value]="userName?userName:''" disabled>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-5">
                <mat-form-field floatLabel="always">
                    <mat-label>{{'kbonle' | translate}} </mat-label>
                    <input matInput [value]="createdDate?createdDate:''" disabled>
                </mat-form-field>
            </div>
        </div>
       <div class="row" [class.no-gutters]="isMobile">
            <div class="col-6 col-md-7">
                <mat-form-field floatLabel="always">
                    <mat-label>{{ (flowType == 'devis'?'kdevlastmodi':'kbonlastmodi') | translate}} </mat-label>
                    <input matInput [value]="modifiedUserName?modifiedUserName:''" disabled>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-5">
                <mat-form-field floatLabel="always">
                    <mat-label>{{'kbonle' | translate}} </mat-label>
                    <input matInput [value]="modifiedDate?modifiedDate:''" disabled>
                </mat-form-field>
            </div>
        </div>
        <div class="row" [class.no-gutters]="isMobile" *ngIf="flowType === 'bonde_vente' || flowType === 'bonde_commande' || flowType === 'retrait_marchandise'">
            <div class="col-12 col-md-12">
                <mat-form-field floatLabel="always">
                    <mat-label>{{'kdatedepromesse' | translate}} </mat-label>
                    <input matInput [value]="itemPromesseDate" disabled>
                </mat-form-field>
            </div>
        </div>
        <div class="row" [class.no-gutters]="isMobile" *ngIf="isMobile">
            <div class="col-12">
                <div class="card" *ngIf="panier && wefoxAvail">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kbon_wefox' | translate}}</span>

                        <button type="button" class="m-0" (click)="openUpDateLib3Popup()" *ngIf="!readOnly" [disabled]="!panier || (flowType === 'bonde_commande' || flowType === 'retrait_marchandise')" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="card-body">
                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                            <span class="mr-1">{{'kbondeadline' | translate}}</span>
                            <span class="detail-holder text-right" style="flex: 1">
                                {{ dateFormatter(wefoxDate, true) }}
                            </span>
                        </h4>
                        <h4 class="text-left">
                            {{'kassureur_actuel' | translate}}
                            <span class="detail-holder">{{ wefoxStrLib3 }}</span>
                        </h4>
                        <h4 class="text-left">
                            {{'kcldatedenai' | translate}}
                            <span class="detail-holder">{{ formatClientDOB() }}</span>
                        </h4>
                        <h4 class="text-left">
                            {{'kwefox_commentaire' | translate}}
                            <span class="detail-holder">{{ wefoxStrLib2 }}</span>
                        </h4>
                    </div>
                </div>
            </div>
        </div>

       <div class="row"  *ngIf="!isMobile">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kbonproddetail' | translate}}</span>

                        <button type="button" class="m-0" *ngIf="!readOnly" (click)="openUpArticleDetails()" [disabled]="!panier" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i> 
                        </button>
                    </div>
                    
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <span class="detail-holder text-left w-25">{{ currentArticle?currentArticle.cdart:'' }}</span> 
                            <span class="detail-holder text-left ml-1" style="flex: 1;">{{ currentArticle?currentArticle.lib:'' }}</span>
                        </div>

                        <div class="row no-gutters mt-3" *ngIf="!isMobile">
                            <div class="col-md-7">
                                <mat-form-field floatLabel="always" class="pr-1">
                                    <mat-label>{{'kbonlastmodipar' | translate}} </mat-label>                                    
                                    <input matInput [value]="articleModifiedUserName" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-md-5">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{'kbonle' | translate}} </mat-label>                                    
                                    <input matInput [value]="articleModifiedDate" disabled>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12" *ngIf="flowType === 'bonde_vente' || flowType === 'bonde_commande' || flowType === 'retrait_marchandise'">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{'kdatedepromesse' | translate}} </mat-label>                                    
                                    <input matInput [value]="itemPromesseDate" disabled>
                                </mat-form-field>
                            </div>
                        </div>

                        <mat-divider></mat-divider>

                        <div class="row no-gutters mt-3">
                            <div class="col-md-6">
                                <h4 class="text-left pr-1">
                                    {{'kbonexposure' | translate}}
                                    <span class="detail-holder">{{ currentArticle?(currentArticle.expo?('ksyes' | translate):('kartno' | translate)):'' }}</span>
                                </h4>
                            </div>
                            <div class="col-md-6">
                                <h4 class="text-left">
                                    {{'kboncoinsolde' | translate}}
                                    <span class="detail-holder">{{ currentArticle?(currentArticle.coinsolde?('ksyes' | translate):('kartno' | translate)):'' }}</span>
                                </h4>
                            </div>
                        </div>

                        <h4 class="text-left">
                            {{'kbondesc' | translate}}
                            <span class="detail-holder">
                                {{ currentArticle?currentArticle.commlig1:'' }}
                            </span>
                            <span class="detail-holder">
                                {{ currentArticle?currentArticle.commlig2:'' }}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card" *ngIf="panier && wefoxAvail">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kbon_wefox' | translate}}</span>

                        <button type="button" class="m-0" (click)="openUpDateLib3Popup()" *ngIf="!readOnly" [disabled]="!panier || (flowType === 'bonde_commande' || flowType === 'retrait_marchandise')" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i> 
                        </button>
                    </div>
                    
                    <div class="card-body">
                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                            <span class="mr-1">{{'kbondeadline' | translate}}</span>  
                            <span class="detail-holder text-right" style="flex: 1">
                                {{ dateFormatter(wefoxDate, true) }}
                            </span>                           
                        </h4>
                        <h4 class="text-left">
                            {{'kassureur_actuel' | translate}}
                            <span class="detail-holder">{{ wefoxStrLib3 }}</span>
                        </h4>
                        <h4 class="text-left">
                            {{'kcldatedenai' | translate}}
                            <span class="detail-holder">{{ formatClientDOB() }}</span>
                        </h4>
                        <h4 class="text-left">
                            {{'kwefox_commentaire' | translate}}
                            <span class="detail-holder">{{ wefoxStrLib2 }}</span>
                        </h4>
                    </div>                    
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
