import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Subscription } from 'rxjs';

import { ClientHistory } from 'models/clients';
import { ClientService } from 'providers/api/clientService';
import { DialogsService } from 'providers/api/dialogService';

@Component({
  selector: 'app-client-historique',
  templateUrl: './client-historique.component.html',
  styleUrls: ['./client-historique.component.scss'],
  providers: [ClientService]
})
export class ClientHistoriqueComponent implements OnInit {
  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  showLoader = true;
  historySubscription: Subscription;
  listHistory: ClientHistory[] = [];

  isMobile: boolean;

  IDTIERS: string;
  IDINSTTIE: string;
  IDSOC: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,          
    private clientService: ClientService,
    private translateService: TranslateService,
    private deviceDetector: DeviceDetectorService,
    private dialogService: DialogsService,
    ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();

    if(this.data !== undefined && this.data !== null) {
      this.IDTIERS = this.data.IDTIERS;
      this.IDINSTTIE = this.data.IDINSTTIE;
      this.IDSOC = this.data.IDSOC;
    }

    this.dtOptions = {
      language: {
          processing: this.translateService.instant('kprocessing'),
          search: this.translateService.instant('ksearch'),
          loadingRecords: this.translateService.instant('kloading'),
          info: this.translateService.instant('kgenshowentry'),
          infoEmpty: this.translateService.instant('kgenshowingentry'),
          zeroRecords: this.translateService.instant('knodata'),
          emptyTable: this.translateService.instant('knodatadash'),
          paginate: {
              first: this.translateService.instant('kfirst'),
              previous: this.translateService.instant('kprevious'),
              next: this.translateService.instant('knext'),
              last: this.translateService.instant('klast'),
          }        
      },      
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.getClientHistory();
  }

  getClientHistory(page?: number) {
    this.historySubscription = this.clientService.getClientHistory(this.IDTIERS, this.IDINSTTIE, this.IDSOC, page).subscribe(
        (response) => {
            this.showLoader = false;
            this.dtTrigger.next();

            if(response.success !== undefined) {                
                if(page === undefined) {
                  this.currentPage = 0;
                }
        
                this.rowsPerPage = response.rowsPerPage;
                this.totalPages = response.count;                      

                this.listHistory = response.data;                
            }            
        }, error => {
            this.dtTrigger.next();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            this.showLoader = false;            
        }
    );
  }

  searchPaginate(page?: number) {
    this.getClientHistory(page);  
  }

  paginationChange(event: any) {
    if(!this.isMobile){
      this.showLoader = true;
      const changedPageIndex = event.pageIndex;    

      this.currentPage = changedPageIndex;

      this.searchPaginate(changedPageIndex + 1);        
    }
  }

  ngOnDestroy() {
    this.historySubscription.unsubscribe(); 
  }

}
