import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Constants } from '../constants';
import {Observable} from 'rxjs';

@Injectable()
export class PrinterService  {

  constructor(private http : HttpClient) {
  }

  getListPrinter(idMag) : Observable<any> {
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let params = {};
    params['IDSOC'] = Constants.IDSOC;
    params['IDMAG'] = idMag;
    let options = {
        headers: headers,
        params: params
    };

    return this.http.get(Constants.API_PRINTER_URL + 'getListPrinter', options);

  }

  printBonDeVente(idMag, CIINT, numcom, nomPrinter) : Observable<any> {
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let params = {};
    params['IDSOC'] = Constants.IDSOC;
    params['IDMAG'] = idMag;
    params['NUMCOM'] = numcom;
    params['NOMPRINTER'] = nomPrinter;
    params['INTERVENANTCODE'] = CIINT;
    let options = {
        headers: headers,
        params: params
    };
    
    return this.http.get(Constants.API_PRINTER_URL + 'printBonDeVente', options);

  }
}
