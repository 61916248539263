import { Injectable } from '@angular/core';
import * as _ from "lodash";

import { AuthorizationCheckComponent } from 'components/authorization-check/authorization-check.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { formatNumber } from '@angular/common';
import * as moment from 'moment';
import numeral from 'numeral';
import { StorageService } from './storageService';

@Injectable()
export class UtilService  {

  private static DEFAULT_DECIMAL_DIGITS = 2;
  private static DEFAULT_DECIMAL_MULTIPLIER_OFFSET = 10;

  constructor(private dialog: MatDialog,
    private storageService: StorageService
  ) {
  }

  get getCurrentDecimalSymbol() {
    return this.storageService.getItem('DECIMAL_SYMBOL') || ',';
  }

  formatDateToWS(dateString: string) {
    if(dateString === undefined || dateString == null) return '';
    
    const tzOffset = this.storageService.getItem('TIMEZONE_OFFSET');
    
    if(tzOffset !== undefined && tzOffset != null && tzOffset !== '') {
      return moment(dateString).format('YYYY-MM-DD[T]HH:mm:ss.SSS'+tzOffset);
    }

    return moment(dateString).format('YYYY-MM-DD[T]HH:mm:ss.SSS');
  }

  getLast2DigitYear(): string {
    return new Date().getFullYear().toString().substr(-2);
  }

  formatMaskCompat(number: number): string {
    if (number == null) return '';

    const NUM = numeral(number);

    return NUM.value().toFixed(UtilService.DEFAULT_DECIMAL_DIGITS);
  }

  formatQteMaskCompat(number: number, decimal?: string): string {
    if (number == null) return '';

    const NUM = numeral(number);

    return NUM.value().toFixed(decimal ? (+decimal * UtilService.DEFAULT_DECIMAL_MULTIPLIER_OFFSET) : UtilService.DEFAULT_DECIMAL_DIGITS);
  }

  calculateChecksumDigitWithString(chaine : string) {
      var  iSum = 0;
      var iDigit = 0;

      for (var i = chaine.length; i >= 1; i--)
      {
          iDigit = Number(chaine[i-1]);
          if (i % 2 === 0)
          {
              iSum += iDigit * 3;
          }
          else
          {
              iSum += iDigit * 1;
          }
      }
      var iCheckSum = (10 - (iSum % 10)) % 10; 
      return iCheckSum;
  }

  frToEnNumberFormat(french_string: string) {
    return parseFloat(french_string.replace(',','.').replace(' ','')).toFixed(2);
  } 

  authorizationCheck(CMINT: any, NUMECR: string, NUMCAS: string, CIINT: string, NUMCOM: string, CDART: string = '', MOTIF: string = ''): Promise<any> {
    const dialogRef = this.dialog.open(AuthorizationCheckComponent, {
      width: '80vw',
      data: {
        CMINT: CMINT,
        NUMECR: NUMECR, 
        NUMCAS: NUMCAS,
        CIINT: CIINT,
        NUMCOM: NUMCOM,
        CDART: CDART,
        MOTIF: MOTIF
      }
    });

    return dialogRef.afterClosed().toPromise();
  }

  remove_accents(strAccents: any): string {
    strAccents = strAccents.split('');
    let strAccentsOut: any = [];
    const strAccentsLen = strAccents.length;
    const accents =    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    for (let y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else
            strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');

    return strAccentsOut;
  }

}
