import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import BonPanier, { PanierClient } from 'models/bonPanier';
import { Client } from 'models/clients';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import * as moment from 'moment';
import { BonVente } from 'models/bonventes';
import { UserService } from 'providers/api/userService';
import { Magasin } from 'models/magasins';
import { ClientService } from 'providers/api/clientService';
import { Constants } from 'providers/constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { Subscription } from 'rxjs';
import { SearchClientComponent } from '../search-client/search-client.component';
import { ClientFilterComponent } from 'pages/client/client-filter/client-filter.component';
import { DevisService } from 'providers/api/devisService';
import { ValiditeLimitComponent } from 'pages/create-devis/validite-limit/validite-limit.component';
import { User } from 'models/users';
import { ClientHistoriqueComponent } from 'components/client-historique/client-historique.component';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-client-bonde',
  templateUrl: './client-bonde.component.html',
  styleUrls: ['./client-bonde.component.scss'],
  providers: [DevisService]
})
export class ClientBondeComponent implements OnInit, OnChanges {

  private _client: Client;
  
  get client(): Client { return this._client }
  @Input() set client(client: Client) {
    this.clientChange.emit(this._client = client);
    this.selectedClient = client;
    this.refreshClientMagasinNom();
  }
  @Output() private clientChange: EventEmitter<Client> = new EventEmitter<Client>();

  private _chosenFidelite: Client;
  get chosenFidelite(): Client { return this._chosenFidelite }
  @Input() set chosenFidelite(chosenFidelite: Client) {
    this.chosenFideliteChange.emit(this._chosenFidelite = chosenFidelite);
  }
  @Output() private chosenFideliteChange: EventEmitter<Client> = new EventEmitter<Client>();  

  private _panier: BonPanier;
  get panier(): BonPanier { return this._panier }
  @Input() set panier(panier: BonPanier) {
    this.panierChange.emit(this._panier = panier);

    if(panier !== undefined && panier !== null) {
      const numBon = this.storageService.getItem('previousNumBon');    
      if(numBon !== undefined && numBon != null) {
        this.bonNumBon = numBon;
      }
      this.limiteDate = panier.dtech;
    } else {
      this.limiteDate = undefined;
    }    
  }
  @Output() private panierChange: EventEmitter<BonPanier> = new EventEmitter<BonPanier>();

  @Input() listMagasins: Magasin[];
  @Input() flowType: any;
  @Input() showOnly: string;
  @Input() readOnly: boolean = false;

  private _modifiableStat: boolean;
  get modifiableStat(): boolean { return this._modifiableStat }
  @Input() set modifiableStat(modifiableStat: boolean) {
    if(modifiableStat !== undefined && modifiableStat != null) {
      this._modifiableStat = modifiableStat;

      if(this.flowType === 'bonde_commande' && modifiableStat == true) {
        this.sectionDisabled = false;
        this.searchForm.get('client_nom').enable({ onlySelf: true });
      }
    }
  }

  @Input() set flowCMINT(flowCMINT: any) {
    if(flowCMINT !== undefined && flowCMINT != null) {
      this.USERCMINT = flowCMINT;
    } else {
      this.user = this.userService.getCurrentUser();          
      this.USERCMINT = this.user.CMINT;
    }
  }

  private _focusInput: boolean;
  get focusInput(): boolean { return this._focusInput }
  @Input() set focusInput(status: boolean) {
    this.focusInputChange.emit(this._focusInput = status);    
    if(status === true) {
      this.searchInput.focus();
      this.focusInput = false;
    }
  }
  @Output() private focusInputChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('searchInput') searchInput: MatInput;

  @Input() emailMandatory = false;

  
  advancedDialogRef: any;
  isMobile: boolean;
  user: User;
  USERCMINT: any;

  bonNumBon: string;
  searchForm: UntypedFormGroup = this.formBuilder.group({
    client_nom: [''],
  });

  selectedClient: Client;

  newClient: boolean = false;
  editClient: boolean = false;
  popupCloseSusscription: Subscription;

  magasinNom: string;

  userPermission: any;
  sectionDisabled: boolean = false;

  limiteDate: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private bonVenteService: BonVenteService,
    private devisService: DevisService,
    private clientService: ClientService,
    private loadingService: NgxSpinnerService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private userService: UserService,
    private deviceDetector: DeviceDetectorService,
    private storageService: StorageService
    ) {
       this.popupCloseSusscription = this.userService.popupClose.subscribe(closeAction => {
         if(closeAction == false){
            this.closeAdvancedSearch();
         }
       });
     }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();

    this.USERCMINT = this.user.CMINT;

    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.defineSectionStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }
  ngOnDestroy(): void {
    this.popupCloseSusscription.unsubscribe();
  }

  defineSectionStatus() {
    if(this.flowType == 'bonde_commande' || this.flowType == 'retrait_marchandise') {
      this.sectionDisabled = true;
      this.searchForm.get('client_nom').disable({ onlySelf: true });
    } else {
      if(this.readOnly) {
        this.sectionDisabled = true;
        this.searchForm.get('client_nom').disable({ onlySelf: true });
      } else {
        this.sectionDisabled = false;
        this.searchForm.get('client_nom').enable({ onlySelf: true });
      }
    }
  }
  
  searchClientFormSubmitter(data?: any) {
    if(this.flowType === 'bonde_commande') {
      return;
    }
    
    let passData = {};
    if(data !== undefined && data != null) {
      passData = {
        ...data,
        CMINT: this.USERCMINT,
      };
    } else {
      passData = {
        CMINT: this.USERCMINT,
        client_nom: this.searchForm.get('client_nom').value
      };
    }

    const dialogRef = this.dialog.open(SearchClientComponent, {
      width: '100vw',
      maxHeight: this.isMobile?'95vh':'90vh', 
      data: passData
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.loadingService.show();
        this.selectedClient = result.client_data;
        this.client = this.selectedClient;
        this.refreshClientMagasinNom();
        this.callUpdateAPI();

        this.searchForm.get('client_nom').setValue('');
      }
    });
  }

  openAdvancedSearch(){
    this.userService.setAdvanceSearchFormData(null);  
    const dialogRef = this.dialog.open(ClientFilterComponent, {
      width: '100vw',
      maxHeight: '80vh',
      autoFocus: !this.isMobile,
      data: {
        fetchType: 'new_load',
        dateAct: 'equal'
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {      
      if(result && result.status === "success") {        
        this.userService.setAdvanceSearchFormData(result.form_data);    
        const data = {
          formType: 'advancedSearch',
          filterData: result
        };
        this.searchClientFormSubmitter(data);
      }      
    });
  }
  closeAdvancedSearch(){
    
  }

  async callUpdateAPI(type: 'normal' | 'devis' = 'normal') {
    let fideliteRecovery;
    try {
      fideliteRecovery = await this.userService.getRuleParamsData(20, 54, 'VBOOL1');
      if(fideliteRecovery === '-1') {
        if(this.selectedClient !== undefined && this.selectedClient !== null && this.selectedClient.NUMFID !== undefined && this.selectedClient.NUMFID !== null) {
          this.chosenFidelite = this.selectedClient;
        } else {
          this.chosenFidelite = undefined;
        }
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
    
    if(this.panier === undefined || this.panier === null) {
      this.loadingService.hide();
      return;
    }    
    
    this.loadingService.show();    

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');    

    let client_data: PanierClient = this.panier.client;

    if(this.selectedClient !== undefined && this.selectedClient !== null) {
      client_data = {
        mag: +this.selectedClient.CMINT,
        civil: +this.selectedClient.IDCIV,
        nom: this.selectedClient.TRAISOC,
        prenom: this.selectedClient.TPRENOM,
        adr1: this.selectedClient.TADR1,
        adr2: this.selectedClient.TADR2,
        adr3: this.selectedClient.TADR3,
        adr4: this.selectedClient.TADR4,
        codpos: this.selectedClient.CODPOS,
        ville: this.selectedClient.TVILLE,
        pays: this.selectedClient.CODPAYS,
        tel: this.selectedClient.TTEL,
        fax: this.selectedClient.TFAX,
        gsm: this.selectedClient.TPORT,
        email: this.selectedClient.TEMAIL,
        idinstcli: +this.selectedClient.IDINSTTIE,
        idcli: +this.selectedClient.IDTIERS
      };      
    }

    let bondeVenteData: BonPanier = {
      ...this.panier,
      client: client_data,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    if(type === 'devis') {
      bondeVenteData['dtech'] = moment(this.limiteDate).format('YYYY-MM-DD HH:mm:ss');
    }


    if(fideliteRecovery === '-1') {
      if(this.selectedClient !== undefined && this.selectedClient !== null && this.selectedClient.NUMFID !== undefined && this.selectedClient.NUMFID !== null) {
        bondeVenteData['numfid'] = this.selectedClient.NUMFID;
      } else {
        bondeVenteData['numfid'] = '';
      }
    }

    if(this.flowType === 'devis') {
      let devisData = {
        pMag: this.panier.mag,
        pNumBon: this.bonNumBon,
        pPanier: bondeVenteData,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST
      };

      this.editDevis(devisData);
      return;
    }

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    }

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData);    
  }

  editBonDeVente(bondeVenteData: any) {
    this.bonVenteService.modifyBonvente(bondeVenteData).subscribe(
      (resp) => {
        this.loadingService.hide();        

        if(resp.statusCode == 200) {    
          if(resp.data.ErrorCode == 0) {
            // List update after a success response
            const oldListData = this.storageService.getItem('bonVenteListData');
            const oldNumBon = this.bonNumBon;
            // ------------------------------

            this.bonNumBon = resp.data.NumBon;
            this.panier = resp.data.Panier;
          
            this.selectedClient = resp.data.customClient;
            this.client = this.selectedClient;

            this.refreshClientMagasinNom();

            this.storageService.setItem('previousBonPanier', this.panier);
            this.storageService.setItem('previousNumBon', resp.data.NumBon);

            if(this.panier.numfid !== undefined && this.panier.numfid !== null && this.panier.numfid !== '') {
              this.chosenFidelite = resp.data.fidClient;
            } else {
              this.chosenFidelite = undefined;
            }

            this.searchForm.get('client_nom').setValue('');

            // List update after a success response
            if(oldListData !== undefined && oldListData !== null) {
              oldListData.data.map(
                (row: BonVente) => {
                  if(row.NUMCOM == oldNumBon) {
                    row.TRAISOC = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                    row.TPRENOM = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                    row.TADR3 = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TADR3:null;
                    row.CODPOS = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                    row.TVILLE = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                    row.TTEL = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.ENDMOD = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('YYYY-MM-DD HH:mm:ss');
                    
                    row.MTHT = this.panier.montantHT;                    
                    row.MTTTC = this.panier.montant;
                    row.MTTVA = (this.panier.montant - this.panier.montantHT);
                    
                    row.CLINUM = (this.panier.client !== undefined && this.panier.client !== null)?this.panier.client.idcli.toString():"0";
                    row.CLILIVNUM = (this.panier.clientlivre !== undefined && this.panier.clientlivre !== null)?this.panier.clientlivre.idcli.toString():"0";
                    row.CLIFACNUM = (this.panier.clientfacture !== undefined && this.panier.clientfacture !== null)?this.panier.clientfacture.idcli.toString():"0";                    

                    row.NUMCOM = this.bonNumBon;

                    return row;
                  }

                  return row;
                }
              );

              const updated_bonvente = oldListData.data.find(
                (row: BonVente) => row.NUMCOM == this.bonNumBon
              );

              let updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
              updated_row_removed.unshift(updated_bonvente);

              oldListData.data = updated_row_removed;

              this.storageService.setItem('bonVenteListData', oldListData);
            }
            // ------------------------------

          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    ); 
  }

  editDevis(devisData: any, closeLoader: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.devisService.modifyDevis(devisData).subscribe(
        (resp) => {
          if(closeLoader) this.loadingService.hide();          

          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              
              // List update after a success response
            const oldListData = this.storageService.getItem('devisListData');
            const oldNumBon = this.bonNumBon;
            // ------------------------------

            this.bonNumBon = resp.data.NumBon;
            this.panier = resp.data.Panier;
          
            this.selectedClient = resp.data.customClient;
            this.client = this.selectedClient;

            this.refreshClientMagasinNom();

            this.storageService.setItem('previousBonDevis', this.panier);
            this.storageService.setItem('previousNumBon', this.bonNumBon);

            if(this.panier.numfid !== undefined && this.panier.numfid !== null && this.panier.numfid !== '') {
              this.chosenFidelite = resp.data.fidClient;
            } else {
              this.chosenFidelite = undefined;
            }

            this.searchForm.get('client_nom').setValue('');

            // List update after a success response
            if(oldListData !== undefined && oldListData !== null) {
              oldListData.data.Bons.map(
                (row: any) => {
                  if(row.numbon == oldNumBon) {
                    row.civlib = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CIVLIB:null;
                    row.nom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                    row.prenom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                    row.cp = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                    row.ville = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                    row.tel = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                    row.gsm = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;

                    row.montant = this.panier.montant;
                    row.dtmaj = this.panier.dtmaj;                    

                    return row;
                  }

                  return row;
                }
              );

              const updated_devis = oldListData.data.Bons.find(
                (row: any) => row.numbon == this.bonNumBon
              );

              let updated_row_removed: BonVente[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
              updated_row_removed.unshift(updated_devis);

              oldListData.data.Bons = updated_row_removed;

              this.storageService.setItem('devisListData', oldListData);
            }
            // ------------------------------
    
              resolve({ response: resp });
            } else {
              reject({ response: resp });
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {        
            resolve({ response: resp });
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
          }
        },
        err => {
          if(closeLoader) this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });     
  }

  openCreateClientForm() {
    this.newClient = true;
    this.editClient = false;
  }

  onCreateClientChanged(event: any) {
    if(event.status === 'return') {
      this.newClient = false;
    }

    if(event.status === 'success_bon_create') {
      this.newClient = false;
      const response = event.response;
      this.selectedClient = response.data.customClient;
      this.client = this.selectedClient;

      this.refreshClientMagasinNom();
    }

    if(event.status === 'success_create') {
      this.newClient = false;
      const response = event.response;
      this.selectedClient = response.data;
      this.client = this.selectedClient;

      this.refreshClientMagasinNom();
    }
  }

  openEditClientForm() {
    this.newClient = false;
    this.editClient = true;
    this.selectedClient.MAG_NOM = this.magasinNom;
  }

  onEditClientChanged(event: any) {
    if(event.status === 'return') {
      this.editClient = false;
    }

    if(event.status === 'success_edit_bon_client') {
      this.editClient = false;
      const response = event.response;
      this.selectedClient = response.data.customClient;
      this.client = this.selectedClient;
      this.refreshClientMagasinNom();
    }

    if(event.status === 'success_edit_client') {
      this.editClient = false;
      const response = event.data;
      this.selectedClient = response;
      this.client = this.selectedClient;
      this.refreshClientMagasinNom();
    }
  }

  refreshClientMagasinNom() {
    if(this.selectedClient !== undefined && this.selectedClient !== null) {
      if(this.selectedClient.CMINT !== undefined && this.selectedClient.CMINT !== null && +this.selectedClient.CMINT > 0) {
        const magData = this.listMagasins?.find((row) =>  row.CMINT == this.selectedClient.CMINT);
        if(magData !== undefined && magData != null) {
          this.magasinNom = magData.CMRAISOC;
        } else {
          this.magasinNom = undefined;
        }
      } else {
        this.magasinNom = undefined;
      }
    }
  }

  searchClients() {
    let searchKey = this.searchForm.get('client_nom').value;

    if(searchKey === undefined || searchKey == null || searchKey === '') {
      return;
    }    
    this.loadingService.show();
    let form_data: any = {};    
    //Rapid search
    let rapid_search_code:any = Number(searchKey);

    if(isNaN(rapid_search_code)){
      form_data.NOM = searchKey; 
    } else {
      form_data.TEL = searchKey;
    }

    const emailPattern = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
    if (emailPattern.test(searchKey)) {
      form_data.TEMAIL = searchKey; 
      form_data.NOM = ''; 
      form_data.TEL = ''; 
    }

    form_data.IDSOC = Constants.IDSOC;
    form_data.CIINT = this.user.CIINT;
    form_data.paracmint = this.USERCMINT;

    this.clientService.searchClients(form_data).subscribe(
      (response: any) => {
          if(response.success !== undefined) {
            const data = response.data;
            const totalResults: number = response.count;

            if(totalResults === 1) {
              this.selectedClient = data[0];
              this.client = this.selectedClient;
              this.refreshClientMagasinNom();
  
              if(this.panier !== undefined && this.panier !== null) {
                this.callUpdateAPI();
              } else {
                this.loadingService.hide();
              }

              this.searchForm.get('client_nom').setValue('');
            } else {
              this.loadingService.hide();
              this.searchClientFormSubmitter();
            }
          }
      }, 
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )
  }

  openUpValiditeLimite() {
    const dialogRef = this.dialog.open(ValiditeLimitComponent, {
      width: '50vw',
      maxWidth: '50vw',
      data: {
        panier: this.panier,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.limiteDate = result.data.dtlimit;

        this.callUpdateAPI('devis');
      }
    });
  }

  valideDateFormatter(date: string, offset = true, today_time = true) {
    if(date === undefined || date  === null) {
      return '';
    }
    
    const given_date = moment(date).format('DD.MM.YYYY');
    const today_date = moment().format('DD.MM.YYYY');

    if(given_date === undefined || moment(date).year() === 1899) {
      return '';
    }

    if(offset) {
      return moment(date).utcOffset(date).format('DD.MM.YY');
    }
    return moment(date).format('DD.MM.YY');
  }

  openClientHistory() {
    const dialogRef = this.dialog.open(ClientHistoriqueComponent,
      {
        data: {
          IDTIERS: this.client.IDTIERS,
          IDINSTTIE: this.client.IDINSTTIE,
          IDSOC: this.client.IDSOC,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }
}
