<mat-card>    
    <mat-card-content class="mat-card-content-form">
        <app-create-client *ngIf="newClient" [flowType]="flowType" displayType="tab" [emailMandatory]="emailMandatory" bonClientType="client" [bonNumBon]="bonNumBon" [(panier)]="panier" (onChange)="onCreateClientChanged($event)" ></app-create-client>
        <app-client-detail *ngIf="editClient" [flowType]="flowType" displayType="tab" [emailMandatory]="emailMandatory" bonClientType="client" [bonNumBon]="bonNumBon" [selectedClient]="selectedClient" [(panier)]="panier" (onChange)="onEditClientChanged($event)" ></app-client-detail>

        <form [formGroup]="searchForm" *ngIf="!readOnly && !newClient && !editClient && ((flowType != 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise') && showOnly != 'devisValidate'" (keydown.enter)="$event.preventDefault();" (ngSubmit)="searchClientFormSubmitter()">
            <div class="row"  [class.section-disabled]="sectionDisabled">
                <div class="col-12 col-md-4 col-lg-3">
                    <mat-form-field *ngIf="!readOnly && !newClient && !editClient && (flowType != 'bonde_commande' && flowType !== 'retrait_marchandise') && showOnly != 'devisValidate'">
                        <input matInput #searchInput="matInput" type="text" formControlName="client_nom"
                               (keyup.enter)="searchClients()"
                               [placeholder]="'kbonrecherchenom' | translate"
                               class="text-uppercase" cdkFocusInitial>
                        <button matSuffix type="submit" mat-icon-button [disabled]="sectionDisabled" *ngIf="!isMobile">
                            <mat-icon>search</mat-icon>
                        </button>
                        <button matSuffix type="button" (click)="searchClients()" mat-icon-button class="inlineblock"  [disabled]="sectionDisabled" *ngIf="isMobile">
                            <mat-icon>search</mat-icon>
                        </button>
                        <button matSuffix type="button" class="ml-2" mat-icon-button (click)="openAdvancedSearch()" [disabled]="sectionDisabled" *ngIf="isMobile">
                            <mat-icon class="bt_logout bt_cursor text-white" [title]="'kclsearc' | translate">more_vert</mat-icon>
                        </button>                        
                        <button matSuffix class="ml-2" type="button"  mat-icon-button (click)="openCreateClientForm()" [disabled]="sectionDisabled" *ngIf="isMobile">
                            <mat-icon>add</mat-icon>                       
                        </button>                        
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <div class="row">
                        <div class="col-6 col-md-12 no-padding">
                            <h4 class="text-left">
                                {{'kbonNum' | translate}}
                                <span class="detail-holder d-inline-block w-75">{{selectedClient?selectedClient.IDTIERS:''}}</span>
                            </h4>
                        </div>
                        <div class="col-3 no-padding" *ngIf="isMobile">
                            <button matSuffix type="button" class="ml-2" mat-button (click)="openClientHistory()" *ngIf="selectedClient !== undefined && selectedClient !== null" style="margin-top:0 !important">
                                <mat-icon>history</mat-icon>
                            </button>
                        </div>

                        <div class="col-3 no-padding" *ngIf="isMobile && ((flowType !== 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise')">
                            <button matSuffix type="button" class="ml-2" mat-button (click)="openEditClientForm()" *ngIf="selectedClient !== undefined && selectedClient !== null" style="margin-top:0 !important">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </div>                  
                </div>

                <div class="col-12 col-md-4 col-lg-6 text-right" *ngIf="!isMobile && ((flowType !== 'bonde_commande' || (flowType == 'bonde_commande' && modifiableStat)) && flowType !== 'retrait_marchandise')">
                    <button type="button" mat-button (click)="openClientHistory()" *ngIf="selectedClient !== undefined && selectedClient !== null">
                        <i class="material-icons">history</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kbonhistoire' | translate}}
                        </span>                        
                    </button>
                    <button type="button" class="ml-2" mat-button *ngIf="!isMobile && (flowType !== 'bonde_commande' && flowType !== 'retrait_marchandise')" (click)="openCreateClientForm()" [disabled]="sectionDisabled">
                        <i class="material-icons">add</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kbonnouveau' | translate}}
                        </span>                        
                    </button>

                    <button type="button" class="ml-2" mat-button (click)="openEditClientForm()" [disabled]="sectionDisabled" *ngIf="selectedClient !== undefined && selectedClient !== null">
                        <i class="material-icons">edit</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kbonmodifier' | translate}}
                        </span>                        
                    </button>
                </div>
            </div>
        </form>

        <div class="row d-flex justify-content-end">
            <div class="col-12 col-md-4 col-lg-6 text-right" *ngIf="flowType === 'retrait_marchandise'">
                <button type="button" mat-button (click)="openClientHistory()" *ngIf="selectedClient !== undefined && selectedClient !== null">
                    <i class="material-icons">history</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kbonhistoire' | translate}}
                    </span>                        
                </button>
            </div>
        </div>

        <div class="row no-gutters" *ngIf="!newClient && !editClient"  [class.section-disabled]="!readOnly && sectionDisabled">
            <div [class]="flowType === 'devis'?'col-md-9 col-12':'col-md-12 col-12'" *ngIf="showOnly != 'devisValidate'">
                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-md-6">
                        <h4 class="text-left">
                            {{'kboncompnomprenom' | translate}}
                            <span class="detail-holder">{{selectedClient?((selectedClient.CIVLIBC != null?selectedClient.CIVLIBC+' ':'')+selectedClient.TRAISOC+(selectedClient.TPRENOM?' '+selectedClient.TPRENOM:'')):''}}</span>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kbonaddress' | translate}} <br>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR1?selectedClient.TADR1:''):''}}</span>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR2?selectedClient.TADR2:''):''}}</span>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR3?selectedClient.TADR3:''):''}}</span>
                            <span class="detail-holder">{{selectedClient?(selectedClient.TADR4?selectedClient.TADR4:''):''}}</span>
        
                            <div class="d-flex justify-content-between">
                                <span class="detail-holder w-25">{{selectedClient?(selectedClient.CODPOS?selectedClient.CODPOS:''):''}}</span> 
                                <span class="detail-holder ml-1" style="flex: 1;">{{selectedClient?(selectedClient.TVILLE?selectedClient.TVILLE:''):''}}</span>
                            </div>                    
                        </h4>
        
                        <h4 class="text-left">
                            {{'kboncountry' | translate}}
                            <span class="detail-holder"> {{selectedClient?(selectedClient.PAYSNOM?selectedClient.PAYSNOM:''):''}}</span>
                        </h4>
                    </div>
                    <div class="col-md-6">
                        <h4 class="text-left">
                            {{'kclshop' | translate}}
                            <span class="detail-holder">{{ (magasinNom !== undefined && magasinNom !== null)?magasinNom:('kclnondefi' | translate) }}</span>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kbonemail' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TEMAIL?selectedClient.TEMAIL:''):''}}</span>
                        </h4>

                        <h4 class="text-left">
                            {{'kclport' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TPORT?(selectedClient.TPORT | formatTele):''):''}}</span>
                        </h4>

                        <h4 class="text-left">
                            {{'kbontel' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TTEL?(selectedClient.TTEL | formatTele):''):''}}</span>
                        </h4>
        
                        <h4 class="text-left">
                            {{'kbonfax' | translate}}
                            <span class="detail-holder">{{selectedClient?(selectedClient.TFAX?selectedClient.TFAX:''):''}}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="flowType === 'devis' && ((!showOnly && !isMobile) || (isMobile && showOnly === 'devisValidate' ))">
                <div class="card mt-2">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span>{{'kdevisvalidate' | translate}}</span>

                        <button type="button" class="m-0" (click)="openUpValiditeLimite()" *ngIf="!readOnly" [disabled]="!panier" mat-icon-button>
                            <i class="fa fa-pencil-square-o f20" aria-hidden="true"></i> 
                        </button>
                    </div>
                    
                    <div class="card-body">
                        <h4 class="align-items-center d-flex flex-row justify-content-between text-left">
                            <span class="mr-1">{{'kkdatelimite' | translate}}</span>  
                            <span class="detail-holder text-right" style="flex: 1">
                                {{ valideDateFormatter(limiteDate) }}
                            </span>                           
                        </h4>                                                     
                    </div>                    
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
