import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-calculate-quantity',
  templateUrl: './calculate-quantity.component.html',
  styleUrls: ['./calculate-quantity.component.scss']
})
export class CalculateQuantityComponent implements OnInit {

  calculateQtyForm: UntypedFormGroup;
  maskOptions = {
    mask: Number,
    scale: 2,    
    padFractionalZeros: true,
    radix: ",",
    mapToRadix: ["."]
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<CalculateQuantityComponent>,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.calculateQtyForm = this.formBuilder.group({
      length: ['0'],
      width: ['0'],
      height: ['0']
    });

    if(this.data) {
      this.calculateQtyForm.patchValue({
        length: this.data.dimensions['length'],
        width: this.data.dimensions['width'],
        height: this.data.dimensions['height'],
      });
    }
  }

  calculateQtyFormSubmitter() {
    const formData = this.calculateQtyForm.getRawValue();

    this.dialogRef.close({ status: 'success', data: formData });
  }

}
