import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {VerificationDetail} from '../../../models/addressVerification';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-address-confirmation',
  templateUrl: './address-confirmation.component.html',
  styleUrls: ['./address-confirmation.component.scss']
})
export class AddressConfirmationComponent implements OnInit {

  returnedAddressDetail: VerificationDetail;
  enteredAddressDetail: any;

  returnedStatus: number;

  confirmationForm: UntypedFormGroup = this.formBuilder.group({
    ENTERED_ADDRESS: [{ value: '', disabled: true }],
    RETURNED_ADDRESS: [{ value: '', disabled: true }],
  });

  enteredAddressFormatted: string | '' = '';
  returnedAddressFormatted: string | '' = '';

  isMobile = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { entered: any, returned: VerificationDetail },
              private dialogRef: MatDialogRef<AddressConfirmationComponent>,
              private formBuilder: UntypedFormBuilder,
              private deviceDetector: DeviceDetectorService,
            ) {
    this.isMobile = this.deviceDetector.isMobile();

    if (this.data && this.data.hasOwnProperty('entered')) {
      this.enteredAddressDetail = this.data.entered;
    }

    if (this.data && this.data.hasOwnProperty('returned')) {
      this.returnedAddressDetail = this.data.returned;
      this.returnedStatus = +this.returnedAddressDetail.PSTAT;

      if (this.returnedStatus >= 3 && this.returnedStatus <= 5) {
        this.enteredAddressFormatted = `${this.enteredAddressDetail.TADR3}, ${this.enteredAddressDetail.CODPOS} ${this.enteredAddressDetail.TVILLE}`;
        this.returnedAddressFormatted = `${this.returnedAddressDetail.StreetName} ${this.returnedAddressDetail.HouseNo}, ${this.returnedAddressDetail.ZipCode} ${this.returnedAddressDetail.TownName}`;

        this.confirmationForm.patchValue({
          RETURNED_ADDRESS: this.returnedAddressFormatted,
          ENTERED_ADDRESS: this.enteredAddressFormatted,
        });
      } else if (this.returnedStatus >= 6 && this.returnedStatus <= 10) {
        this.enteredAddressFormatted = `${this.enteredAddressDetail.TADR3}, ${this.enteredAddressDetail.CODPOS} ${this.enteredAddressDetail.TVILLE}`;
        this.returnedAddressFormatted = `${this.returnedAddressDetail.StreetName} ${this.returnedAddressDetail.HouseNo}, ${this.returnedAddressDetail.ZipCode} ${this.returnedAddressDetail.TownName}`;

        this.confirmationForm.patchValue({
          RETURNED_ADDRESS: this.returnedAddressFormatted,
          ENTERED_ADDRESS: this.enteredAddressFormatted,
        });
      }
    }
  }

  ngOnInit(): void {
  }

}
