import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'models/users';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { LoginService } from 'providers/api/loginService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-authorization-check',
  templateUrl: './authorization-check.component.html',
  styleUrls: ['./authorization-check.component.scss']
})
export class AuthorizationCheckComponent implements OnInit {

  authCheckForm: any;

  user: any;
  CMINT: any;

  NUMECR: string;
  NUMCAS: string;
  CIINT: string;
  NUMCOM: string;
  CDART: string = '';
  MOTIF: string = '';

  isMobile: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
            private dialogRef: MatDialogRef<AuthorizationCheckComponent>,
            private dialog: MatDialog,
            private formBuilder: UntypedFormBuilder,
            private userService: UserService,
            private loginService: LoginService,
            private translateService: TranslateService,
            private loadingService: NgxSpinnerService,
            private dialogsService: DialogsService,
            private deviceDetector: DeviceDetectorService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.CMINT = this.user.CMINT;

    this.NUMECR = this.data.NUMECR;
    this.NUMCAS = this.data.NUMCAS;
    this.CIINT = this.data.CIINT;
    this.NUMCOM = this.data.NUMCOM;
    this.CDART = this.data.CDART;
    this.MOTIF = this.data.MOTIF;

    this.authCheckForm = this.formBuilder.group({
      USERNAME: ['', Validators.required],
      PASSWORD: ['', [Validators.required, Validators.minLength(3)]],
      MAGASIN: [this.CMINT]
    });
  }

  authorizationFormSubmitter() {
    this.userLogin();    
  }

  openScanArticle(e: any) {
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: this.isMobile?'95vh':undefined,
        autoFocus: false
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {          
        const scanValue = result.data;
        if(scanValue){
            let scanData = scanValue.split('~~');

            if(scanData !== undefined && scanData != null) {
                this.authCheckForm.patchValue({
                  USERNAME: scanData[0],
                  PASSWORD: scanData[1]
                });
            }
        }  
      }      
    });
}

onUsernameEnterPressed(e: any) {
  let key = e.keyCode || e.which;
  if(key == 13) {
      this.emulationScan();
  }
}

emulationScan(){
  let enterValue = this.authCheckForm.get('USERNAME').value;
  if(enterValue){
      if(enterValue.indexOf('~~') > -1) {
          let emulationScanData = enterValue.split('~~');

          if(emulationScanData !== undefined && emulationScanData != null) {
            this.authCheckForm.patchValue({
              USERNAME: emulationScanData[0],
              PASSWORD: emulationScanData[1]
            });
          }
      }
  }

  // this.passwordInput.nativeElement.focus();
}

  userLogin() {
    this.loadingService.show();

    if(this.authCheckForm.invalid) {
      this.loadingService.hide();
      this.dialogsService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }

    const form_data = this.authCheckForm.value;
    const username = form_data.USERNAME;
    const password = form_data.PASSWORD;

    const data = {
      USERNAME: username,
      PASSWORD: password,
      MAGASIN: this.CMINT,
      NUMECR: this.NUMECR,
      NUMCAS: this.NUMCAS,
      CIINT: this.CIINT,
      IDSOC: Constants.IDSOC,
      NUMCOM: this.NUMCOM,
      CDART: this.CDART,
      MOTIF: this.MOTIF
    };

    this.loginService.checkUserForRights(data).subscribe(
      (resp: any) => {
          this.loadingService.hide();
          if (resp.success !== undefined) {
              const userData: User = resp.data[0];
              this.dialogRef.close({ status: 'success', data: userData });
          } else {
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('knavezdroits'));
              this.dialogRef.close({ status: 'failure' });
          }              
      },
      (error) => {
        this.loadingService.hide();
        const loginData = error.error;
        const status = loginData.status;

        switch(status) {
          case 'ACCESS_FORBIDDEN':
            this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('knavezdroits'));
            break;
          case 'WRONG_CREDENTIALS':
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klbadpassword'));
              break;
          case 'MAGASIN_EMPTY':
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klstorecon'));
              break;
          case 'WRONG_MAGASIN':
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klstorenotuser'));
              break;
          case 'MAGASIN_EMPTY':
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klstorecon'));
              break;
          case 'USERNAME_EMPTY':
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('kluserempty'));
              break;
          case 'PASSWORD_EMPTY':
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klpassempty'));
              break;    
          default:
              this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klbadpassword'));
              break;                    
        }
      }
    );
  }

}

