<div class="modal-container" class="pt-3" id="code_caisse_article">
    <mat-card>
        <mat-card-header *ngIf="displayType === 'dialog'">
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>kartcais</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row no-gutters">
                <div class="col-12 no-padding">
                    <table id="article_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover dataTable no-footer">
                        <thead class="d-none">
                            <tr>
                                <th class="lib_court" translate>kartcode</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listCode?.length != 0 && !showLoader">
                            <tr *ngFor="let code of listCode">
                                <td class="ean">
                                    {{ code.CMEAN }}
                                </td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="listCode?.length == 0 || showLoader">
                            <tr>                                
                                <td *ngIf="listCode?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                <td *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>                                
                            </tr>                            
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-card-content>
        <mat-divider *ngIf="displayType === 'dialog'"></mat-divider>

        <mat-card-actions class="text-center" *ngIf="displayType === 'dialog'">
            <button mat-button [mat-dialog-close] class="bt_send">                
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
</div>