<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{'kchangestore' | translate}}
            </h1>
        </div>
    </div>

    <form [formGroup]="changeStoreForm" (ngSubmit)="changeStoreFormSubmitter()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-12 col-sm-12">
                    <mat-form-field>
                        <mat-select [placeholder]="'kclshop' | translate" formControlName="NEW_CMINT" >
                            <mat-option>
                                <ngx-mat-select-search
                                        [placeholderLabel]="'klselstore' | translate"
                                        [noEntriesFoundLabel]="'klnostore' | translate"
                                        (ngModelChange)="onSearchChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterMagasin"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option value="">{{ 'klselstore' | translate }}</mat-option>
                            <mat-option *ngFor="let magasin of filteredMagasins" [value]="magasin.CMINT" [disabled]="panier.mag == +magasin.CMINT">
                                {{ magasin?.CMRAISOC }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center height_bloc" >
                    <div>
                        <button mat-button type="submit" class="bt_send">
                            <i class="material-icons">done</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'kpvalid' | translate}}
                            </span>
                        </button>
                        <button mat-button [mat-dialog-close] class="bt_send ml-2">
                            <i class="material-icons">close</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'kpback' | translate}}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>