import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Constants } from '../constants';

import { BonVente } from '../../models/bonventes';
import { UserService } from './userService';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class BonVenteService {

    isMobile: boolean = false;

    constructor(private http: HttpClient, 
        private userService: UserService,
        private deviceService: DeviceDetectorService) {
        this.isMobile = this.deviceService.isMobile();
    }

    getWeFOXSlotsDetail(payload: { SLOT_ID: string, CHOSEN_DATE: string }) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: payload
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getOccupiedBonVenteBySlotId', options);
    }

    getWeFOXSlotsWithAvailability(payload: { IDMAG: any, CHOSEN_DATE?: string }) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: payload
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getBonVenteSlotsWithAvailability', options);
    }

    getLastBonVente(currentPage: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['IDSOC'] = Constants.IDSOC;
        params['currentPage'] = currentPage;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getLastBonsVente', options);
    }

    getBonVente(bonvente: BonVente): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getBonVente', options);
    }

    searchBonvente(data: any, page?: number): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;
        
        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        if(data['USERCON'] === '-1') {
            const user = this.userService.getCurrentUser();
            params['IDUSER'] = user.CIINT;
        }
        params['device'] = this.isMobile?'mobile':'pc';

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'searchBonVente', options);
    }

    getNoteCount(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_GESTION_DES_DROITS_URL + 'getCountNotes', options);
    }

    createNewNote(data: any): Observable<any> {
        return this.http.post(Constants.API_COMMANDE_URL + 'addNotes', data);
    }

    createBonvente(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_BONVENTE_URL + 'createwbondevente', data);
    }

    modifyEtCheckBonvente(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_BONVENTE_URL + 'modifierEtCheckBonDeVente', data);
    }

    modifyBonvente(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_BONVENTE_URL + 'editwbondevente', data);
    }

    createBatchReport(data: any): Observable<any> {
        return this.http.post(Constants.API_BONVENTE_URL + 'createBatchReport', data);
    }

    imprimerBonVente(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'imprimerBondeVente', options);
    }

    downloadFile(downloadUrl: string): Observable<any> {
		return this.http.get(downloadUrl, {responseType: 'blob'});
    }

    removeBonvente(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'deleteBondevente', options);
    }

    getArticleByCDART(CDART: any, IDMAG: any, PARENT_IDMAG?: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CDART'] = CDART;
        params['IDMAG'] = IDMAG;

        if (PARENT_IDMAG !== undefined && PARENT_IDMAG !== '') {
            params['PARENT_IDMAG'] = PARENT_IDMAG;
        }

        params['IDSOC'] = Constants.IDSOC;
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getArticleByCdart', options);
    }

    getArticleGroupsByCAINT(CAINT: any, IDMAG: any, CIINT: string, PARENT_IDMAG?: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['caint'] = CAINT;
        params['cmint'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;
        params['CIINT'] = CIINT;

        if (PARENT_IDMAG !== undefined && PARENT_IDMAG !== '') {
            params['PARENT_IDMAG'] = PARENT_IDMAG;
        }

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'getGroupByArt', options);
    }

    checkArticleParentChildStatus(payload: string[][]): Observable<any> {
        const params = {
            GROUP_CAINT: payload
        };

        return this.http.post(Constants.API_COMMANDE_URL + 'articleParentChildStatus', params);
    }

    getArticleByCMEAN(CMEAN: any, IDMAG: any, PARENT_IDMAG?: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['CMEAN'] = CMEAN;
        params['IDMAG'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;

        if (PARENT_IDMAG !== undefined && PARENT_IDMAG !== '') {
            params['PARENT_IDMAG'] = PARENT_IDMAG;
        }

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getArticleByCMEAN', options);
    }

    getArticleByNOM(NOM: any, IDMAG: any, PARENT_IDMAG?: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['NOM'] = NOM;
        params['IDMAG'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;

        if (PARENT_IDMAG !== undefined && PARENT_IDMAG !== '') {
            params['PARENT_IDMAG'] = PARENT_IDMAG;
        }

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_ARTICLE_URL + 'getArticleByNom', options);
    }

    getRemiseList(type: number, IDMAG: number, CIINT: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['REMMTYPE'] = type;
        params['IDMAG'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;
        params['CIINT'] = CIINT;
        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'remiseList', options);
    }

    getBonVenteHistorique(NUMBON: string, IDMAG: number, page?: number, type?: 'commande' | 'historique'): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        if(page !== undefined) {
            params['page'] = page;
        }

        if(type !== undefined) {
            params['type'] = type;
        }

        params['NUMCOM'] = NUMBON;
        params['CMINT'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'bondeventeHistoire', options);
    }

    getListTransporteur(TRALIBL: string, TRASTR1: number, page?: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        if(TRALIBL !== undefined && TRALIBL !== null) {
            params['TRALIBL'] = TRALIBL;
        } else {
            params['TRALIBL'] = '';
        }

        if(TRASTR1 !== undefined && TRASTR1 !== null) {
            params['TRASTR1'] = TRASTR1;
        } else {
            params['TRASTR1'] = '';
        }

        params['page'] = page === undefined?1:page;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'searchTransporteur', options);
    }

    getAllTransporteur(IDSOC?: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {
            IDSOC: IDSOC || Constants.IDSOC
        };

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getTransporterList', options);
    }

    getBonDeVenteByNumBon(NumBon: any, IDMAG: any, ID_USER?: any, ID_INSTUSER?: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['pNumBon'] = NumBon;
        params['pMag'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;

        if(ID_USER != undefined && ID_USER != null) {
            params['Id_User'] = ID_USER;
        }

        if(ID_INSTUSER != undefined && ID_INSTUSER != null) {
            params['Id_InstUser'] = ID_INSTUSER;
        }
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getwBonVente', options);
    }

    validateEnBonDeCommande(NumBon: any, IDMAG: any, ID_USER?: any, ID_INSTUSER?: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['pNumBon'] = NumBon;
        params['pMag'] = IDMAG;

        if(ID_USER != undefined && ID_USER != null) {
            params['Id_User'] = ID_USER;
        }

        if(ID_INSTUSER != undefined && ID_INSTUSER != null) {
            params['Id_InstUser'] = ID_INSTUSER;
        }
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'validateBondevente', options);
    }

    acompteBonDeCommande(payload: { autreMag: number, pNumBon: string, Id_User: number, Id_InstUser: number, cmint: number, IDSOC: number, defaultLang: string }): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options: any = {
            headers: headers,
            params: payload
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'acompteBondeCommande', options);
    }

    getClientByFidelite(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = {};
        params = data;
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_CLIENT_URL + 'getCagnotte', options);
    }

    getArticles(data: any, currentPage: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['NUMCOM'] = data;

        params['IDSOC'] = Constants.IDSOC;
        params['currentPage'] = currentPage;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getArticles', options);
    }

    getArticleById(data: any, currentPage: string): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CAINT'] = data;

        params['IDSOC'] = Constants.IDSOC;
        params['currentPage'] = currentPage;
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_BONVENTE_URL + 'getArticleById', options);
    }

    createClientFromBonDeVente(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        
        const options = {
            headers: headers
        };
        
        return this.http.post(Constants.API_BONVENTE_URL + 'bondeventeCreateClient', data, options);
    }

    updateClientFromBonDeVente(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        
        const options = {
            headers: headers
        };
        
        return this.http.post(Constants.API_BONVENTE_URL + 'bondeventeEditClient', data, options);
    }


    checkBondeVenteStatus(numBon:any, pMag:any): Observable<any> {
        let endPoint = 'checkbondeventeStatus';

        const data = {
            CMINT: pMag,
            NUMBON: numBon,
            IDSOC: Constants.IDSOC,
        };
      
        return this.http.post(Constants.API_BONVENTE_URL + endPoint, data);
    }

}
