import { Component, Inject} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-prompt',
    templateUrl: './prompt.component.html'
})

export class PromptComponent{
    public title: string;
    public message: string;
    buttonName = this.translateService.instant('kartclose');

    constructor(public dialogRef: MatDialogRef<PromptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private translateService: TranslateService) {
        this.buttonName = this.data.buttonName;
    }
}
