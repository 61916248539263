import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, NgForm} from '@angular/forms';
import {MagasinStructModel} from '../../../models/magasinStructModel';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UserService} from '../../../providers/api/userService';
import {Constants} from '../../../providers/constants';
import {BarcodeScannerComponent} from '../../create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-gestion-des-e2-filter',
  templateUrl: './gestion-des-e2-filter.component.html',
  styleUrls: ['./gestion-des-e2-filter.component.scss'],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}]
})
export class GestionDesE2FilterComponent implements OnInit {

  @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;
  @ViewChild('f', { static: false }) searchFormRef: NgForm;

  // champs de recherche
  searchData: any = null;
  user: any;
  USERCMINT: any;
  showLoader = true;
  searchForm: UntypedFormGroup;
  searched: boolean = false;

  pageType: 'commande' | 'relance' | 'historique' | 'supprimees' = 'commande';
  pageTitle = "kkkbonsdecommande";

  magasinStructure: MagasinStructModel[];

  bonsE2List: { id: string, label: string }[] = [];

  fournisseurNomList: { id: string, label: string }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<GestionDesE2FilterComponent>,
              private userService: UserService,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.user = this.data.user;
    this.USERCMINT = this.data.USERCMINT;

    this.pageType = this.data.pageType;
    this.pageTitle = this.data.pageTitle;

    this.magasinStructure = this.data.magasinStructure;
    this.bonsE2List = this.data.bonsE2List;
    this.fournisseurNomList = this.data.fournisseurNomList;

    this.searchForm = this.formBuilder.group({
      IDSOC: [Constants.IDSOC],
      CMINT: this.USERCMINT,
      NUMBON: [''],
      NOM: [''],
      PRENOM: [''],
      CODPOS: [''],
      VILLE: [''],
      TEL_PORT: [''],

      TOTAL_ENTRE_FROM: [''],
      TOTAL_ENTRE_TO: [''],
      NUM_EXT: [''],
      BONS_E2: [''],
      ARTICLE: [''],
      SECTEUR: [''],

      CODE: [''],
      LIBEL: [''],
      ORDER_BY: [''],
    });

    const oldFormData: any = this.storageService.getItem('bonCommandeFormData');

    if(oldFormData !== undefined && oldFormData !== null) {
      this.searchForm.patchValue({
        IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
        CMINT: oldFormData.CMINT !== null?oldFormData.CMINT:this.USERCMINT,
        NUMBON: oldFormData.NUMBON !== null?oldFormData.NUMBON:'',
        NOM: oldFormData.NOM !== null?oldFormData.NOM:'',
        PRENOM: oldFormData.PRENOM !== null?oldFormData.PRENOM:'',
        CODPOS: oldFormData.CODPOS !== null?oldFormData.CODPOS:'',
        VILLE: oldFormData.VILLE !== null?oldFormData.VILLE:'',
        TEL_PORT: oldFormData.TEL_PORT !== null?oldFormData.TEL_PORT:'',

        TOTAL_ENTRE_FROM: oldFormData.TOTAL_ENTRE_FROM !== null?oldFormData.TOTAL_ENTRE_FROM:'',
        TOTAL_ENTRE_TO: oldFormData.TOTAL_ENTRE_TO !== null?oldFormData.TOTAL_ENTRE_TO:'',
        NUM_EXT: oldFormData.NUM_EXT !== null?oldFormData.NUM_EXT:'',
        BONS_E2: oldFormData.BONS_E2 !== null ? oldFormData.BONS_E2 : '',
        ARTICLE: oldFormData.ARTICLE !== null?oldFormData.ARTICLE:'',
        SECTEUR: oldFormData.SECTEUR !== null?oldFormData.SECTEUR:'',

        CODE: oldFormData.CODE !== null ? oldFormData.CODE : '',
        LIBEL: oldFormData.LIBEL !== null ? oldFormData.LIBEL : '',
        ORDER_BY: oldFormData.ORDER_BY !== null ? oldFormData.ORDER_BY : '',
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.filterFirst.nativeElement.select();
      this.filterFirst.nativeElement.focus();
      this.filterFirst.nativeElement.setAttribute('inputmode', 'numeric');
    }, 300);
  }

  headerSearchFormSubmitter () {
    this.userService.headerSearchInputValue.next(undefined);
    this.dialogRef.close({
      status: 'success',
      form_data: this.searchForm.getRawValue()
    });
  }

  _barCodeLogicCheck = (value: string, emulation = false) => {
    if(value !== undefined && value != null) {

      if(value.indexOf('~') > -1) {
        const [CDART, PRIX] = value.split('~');
        this.searchForm.patchValue({ ARTICLE: CDART });

        if(emulation) {
          if(this.searchFormRef) {
            this.searchFormRef.ngSubmit.emit();
          } else {
            this.headerSearchFormSubmitter()
          }
        }

        return;
      }

      if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
        let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
        CDART = CDART.replace(/^0+/, '');

        this.searchForm.patchValue({ ARTICLE: CDART });

        if(emulation) {
          if(this.searchFormRef) {
            this.searchFormRef.ngSubmit.emit();
          } else {
            this.headerSearchFormSubmitter()
          }
        }

        return;
      }

      this.searchForm.patchValue({ ARTICLE: value });
      if(emulation) {
        if(this.searchFormRef) {
          this.searchFormRef.ngSubmit.emit();
        } else {
          this.headerSearchFormSubmitter()
        }
      }
    }
  }

  onCodeEntered(e: any) {
    const cdart = this.searchForm.get('ARTICLE');
    this._barCodeLogicCheck(cdart.value, true);
  }

  openScanArticle() {
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
        {
          width: '60vw',
          maxHeight: '95vh',
          autoFocus: false
        }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        this._barCodeLogicCheck(result.data);
      }
    });
  }

  resetForm() {
    this.userService.headerSearchInputValue.next(undefined);

    this.searchForm.reset();
    this.storageService.removeItem('bonCommandeFormData');

    this.searchForm.patchValue({
      IDSOC: Constants.IDSOC,
      CMINT: this.USERCMINT,
      SECTEUR: '',
      BONS_E2: '',
      LIBEL: '',
    });
  }

}
