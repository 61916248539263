<div class="modal-container">
    <div class="row no-gutters">
        <div class="col">
            <form [formGroup]="dateTimeForm" (ngSubmit)="onDateTimeSubmitter()">
                <mat-calendar
                        [(selected)]="selected"
                        [startAt]="selected"
                        (selectedChange)="onDateChange($event)"
                        [minDate]="minAvailDate"
                        [maxDate]="maxAvailDate"
                        [dateFilter]="dateFilter"
                >
                </mat-calendar>

                <div class="mt-2">
                    <mat-form-field floatLabel="always">
                        <mat-label></mat-label>
                        <mat-select formControlName="PICK_TIME_SLOT" (selectionChange)="onTimeChange($event)" panelClass="dropdown-panel">
                            <mat-option value="">{{ 'kchoose' | translate }}</mat-option>
                            <mat-option *ngFor="let item of listDaySlots;let i=index;" [value]="item.IDROW" [disabled]="item.remainingSlots < 1">{{ formattedTime(item.SLOT_DEB) + ' / ' + formattedTime(item.SLOT_END) }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="">
                    <div class="row">
                        <div class="col-12 text-center">
                            <button class="bt_valid" mat-button type="submit">
                                <i class="material-icons">done</i>
                                <span class="d-none d-sm-none d-md-block">
                                    {{ "kuok" | translate }}
                                </span>
                            </button>
                            <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                                <i class="material-icons">close</i>
                                <span class="d-none d-sm-none d-md-block">
                                    {{ "kucancel" | translate }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
