import { Component, OnDestroy, OnInit } from '@angular/core';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { reportingMenuTree, ReportMenuModel } from 'providers/reportingMenus';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FilterReportComponent } from './filter-report/filter-report.component';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from 'providers/api/userService';
import { Subscription } from 'rxjs';
import { StockService } from 'providers/api/stockService';
import { User } from 'models/users';
import { reject } from 'lodash';
import { Constants } from 'providers/constants';
import { UtilisatuersService } from 'providers/api/utilisatuersService';
import { UtilService } from 'providers/api/utilService';
import { ReportingService } from 'providers/api/reportingService';
import { ParamDetail } from 'models/paramDetail';
import * as moment from 'moment';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  providers: [
    UtilisatuersService,
    ReportingService,
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
  ]
})
export class ReportingComponent implements OnInit, OnDestroy {

  showLoader: boolean;

  user: User;
  USERCMINT: any;

  parentSlug: string;
  pageTitle: string = 'kreporting';

  allReportingMenus = reportingMenuTree;
  listReportingMenus: any[] = [];
  filteredReportingMenus: any[] = [];

  searchMenu: string;

  isMobile: boolean = false;
  searchMenuSubscription: Subscription;

  paramsList: ParamDetail[];

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private deviceService: DeviceDetectorService,
              private stockService: StockService,
              private utilisatuersService: UtilisatuersService,
              private utilService: UtilService,
              private reportingService: ReportingService,
              private userService: UserService,
              private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();

    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.loadDependencies();

    this.userService.headerSearchInput.next(true);
    this.userService.headerSearchInputType.next('text');
    this.userService.headerSearch.next(false);    
    this.userService.headerSearchInputThreeDots.next(false);
    this.userService.headerSearchInputOnChange.next(true);

    this.searchMenuSubscription = this.userService.headerSearchInputValue.subscribe(
      (searchKey) => {
        if(searchKey !== undefined) {
          this.searchMenu = searchKey;
          this.filterMenuList();
        }              
      }
    )
  }

  ngOnDestroy() {
    this.userService.headerSearchInput.next(false);    
    this.userService.headerSearchInputOnChange.next(false);
    this.searchMenuSubscription.unsubscribe();
    this.searchMenu = undefined;
  }

  async loadDependencies() {
    const userPermission = this.storageService.getItem('CurrentUserPermission');

    this.route.paramMap.subscribe(
      async (params) => {
      this.showLoader = true;
      this.searchMenu = undefined;

      this.userService.headerSearchInputValue.next(this.searchMenu);
      let pageSlug = params.get('page_slug');
      this.parentSlug = pageSlug;

      let currentMenu;

      this.allReportingMenus.find((_r) => {
        if(!_r?.child && _r.value.toLowerCase().indexOf(this.parentSlug) > -1) {
          this.pageTitle = _r.label;
        }
      });

      this.allReportingMenus.find((_r) => {
        if(_r?.child) {
          _r.child.find(_cr => {
            if(_cr.value.toLowerCase().indexOf(this.parentSlug) > -1) {
              this.pageTitle = _cr.label;
            }
          })
        }
      });

      await this.getListParams();
      let processedRules = this.userService.processRulesParams(this.paramsList);

      this.allReportingMenus.forEach(
        (_r) => {
          const filtered = _r.subChilds.filter((_cr) => {
            if(this.parentSlug === 'suivi_des_bons' && !userPermission['54_0'] && userPermission['0_18']) {
              return _cr.parent.toLowerCase().indexOf(this.parentSlug) > -1 && _cr?.separateAccess === true;
            }

            if(_cr.param !== undefined && _cr.param != null) {
              const [ROW_ID, ROW_DET_ID, COLUMN, EQUAL_TO] = _cr.param.split('_');
              let paramEqualTo: any = -1;
              if(EQUAL_TO !== undefined && EQUAL_TO !== null) {
                paramEqualTo = EQUAL_TO.toLowerCase();
              }

              return _cr.parent.toLowerCase().indexOf(this.parentSlug) > -1 && (processedRules[`${ROW_ID}_${ROW_DET_ID}`][COLUMN]).toLowerCase() == paramEqualTo;
            }

            return _cr.parent.toLowerCase().indexOf(this.parentSlug) > -1;
          });
          if(filtered !== undefined && filtered !== null && filtered.length > 0) {
            this.listReportingMenus = filtered;
            this.filteredReportingMenus = this.listReportingMenus;
            return;
          }
        }
      );

      if(this.parentSlug === 'valorisation_des_stocks_par_motif') {
        this.listReportingMenus = [];
        this.filteredReportingMenus = this.listReportingMenus;
        await this.getMotifBlocageList();
      }

      if(this.parentSlug === 'controle_de_coherence_des_stocks' || this.parentSlug === 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc') {
        this.listReportingMenus = [];
        this.filteredReportingMenus = this.listReportingMenus;

        if(this.parentSlug === 'controle_de_coherence_des_stocks') {
          const attrFiltre = processedRules['50_20']['VSTR1'];
          if(attrFiltre == '' || attrFiltre == null) {
            await this.getListMagasinStructure(this.parentSlug);
          } else {
            await this.getListCashAttr(attrFiltre);
          }
        }

        if(this.parentSlug === 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc') {
          await this.getListMagasinStructure(this.parentSlug);
        }
      }

      this.showLoader = false;
    });
  }

  sortByString = (a: string, b: string) => { 
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
  }

  getMotifBlocageList(): Promise<any> {
    return new Promise((resolve) => {
      this.stockService.getMotifBlocageStockWithoutCAINT(this.USERCMINT, this.user.CIINT).subscribe(
        (resp) => {
          if(resp?.success == 'ok') {
            this.listReportingMenus = resp.data;
            this.listReportingMenus = this.listReportingMenus.filter((_r: any) => _r.OKVISIBLE == '-1');
            this.listReportingMenus = this.listReportingMenus.map((_r: any) => {
              return {
                label: _r.LIBEL,
                value: 400 + (+_r.IDMOT),
                parent: 'valorisation_des_stocks_par_motif',
                dateFilter: 'from',
                dateDisabled: true,
                defaultDate: moment()
              };
            });
            
            this.listReportingMenus.sort((a: any, b: any) => {
              return this.sortByString(a.label, b.label);
            });

            this.filteredReportingMenus = this.listReportingMenus;

            resolve(resp);
          }
        },
        err => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    })
  }

  getListParams(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getRulesParams(this.USERCMINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {              
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  getListMagasinStructure(parent: 'liste_des_ventes_avec_un_stock_inferieur_a_2_uvc' | 'controle_de_coherence_des_stocks'): Promise<void> {
    return new Promise((resolve, reject) => {
      let httpData = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        CIINT: this.user.CIINT,
        action: 'parent'
      };

      this.utilisatuersService.getMagasinStructure(httpData).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            this.listReportingMenus = res.data;
            this.listReportingMenus = this.listReportingMenus.map((_r: any) => {
              if(parent === 'controle_de_coherence_des_stocks') {
                return {
                  label: _r.CSTLIB1,
                  value: 800,
                  pAttribut: _r.CSTNIV1, 
                  parent,
                  dateFilter: 'from',
                  dateDisabled: true,
                  defaultDate: moment()
                };
              }

              return {
                label: _r.CSTLIB1,
                value: 600,
                pAttribut: _r.CSTNIV1,
                parent,
                dateFilter: 'both',
                defaultDate: moment().subtract(1, 'day')
              };
            });

            this.filteredReportingMenus = this.listReportingMenus;
            console.log(this.filteredReportingMenus);
            resolve();            
          }
        },
        err => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });    
  }

  getListCashAttr(value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let httpData = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        CIINT: this.user.CIINT,
        NOM: value
      };

      this.userService.getCashAttributes(httpData).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            this.listReportingMenus = res.data;
            this.listReportingMenus = this.listReportingMenus.map((_r: any) => {
              return {
                label: _r.VALUE,
                value: 800,
                pAttribut: _r.VALUE,
                parent,
                dateFilter: 'from',
                dateDisabled: true,
                defaultDate: moment()
              };
            });

            this.filteredReportingMenus = this.listReportingMenus;

            resolve();            
          }
        },
        err => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });    
  }

  filterMenuList(e?: any) {
    if(this.searchMenu !== undefined && this.searchMenu !== null && this.searchMenu !== '') {
      this.filteredReportingMenus = this.listReportingMenus.filter((row) => {
        return (this.utilService.remove_accents(this.translateService.instant(row.label)).toLowerCase()).search(this.utilService.remove_accents(this.searchMenu).toLowerCase()) > -1;
      });
    } else {
      this.filteredReportingMenus = this.listReportingMenus;
    }
  }

  openUpExportReporting(menu: any) {
    const userPermission = this.storageService.getItem('CurrentUserPermission');

    if(menu.label.toLowerCase() === 'ksuivicbi' && userPermission !== undefined && userPermission != 'null') {
        if(!userPermission['0_18']) {
            this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kgnoaccess'));
            return false;
        }
    }

    const dialogRef = this.dialog.open(FilterReportComponent,
      {
        data: {
          pageSlug: this.parentSlug,
          currentMenu: menu,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        console.log(result);
      }      
    });
  }

}
