import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

import { Constants } from '../constants';

@Injectable()
export class ClientService {

    constructor(private http: HttpClient) {
    }

    searchClients(data: any, page?: number): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = {};       
        
        params = data;

        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_CLIENT_URL + 'searchClient', options);
    }

    imprimerReleveCompte(data: { pIdTiers: number, pIdInsttie: number, pImprimante: string, Id_User: number, Id_InstUser: number, CMINT: any, IDSOC: string }): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: data as any
        };

        return this.http.get(Constants.API_CLIENT_URL + 'imprimerReleveCompte', options);
    }

    downloadFile(downloadUrl: string): Observable<any> {
        return this.http.get(downloadUrl, {responseType: 'blob'});
    }

    checkAddressVerification(data: { swissUrl: string, swissUsername: string, swissPassword: string, streetName: string, houseNo: number | '', zipCode: number | '', townName: string }): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Basic ' + btoa(`${data.swissUsername}:${data.swissPassword}`));

        const params: any = {
            streetname: data.streetName,
            houseno: data.houseNo,
            zipcode: data.zipCode,
            townName: data.townName,
        };

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(data.swissUrl, options);
    }

    getListPays(IDMAG: string, CIINT: string): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let params = {
            IDMAG: IDMAG,
            IDSOC: Constants.IDSOC,
            CIINT: CIINT
        };

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_CLIENT_URL + 'getpays', options);
    }

    getClientById(IDTIERS: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['IDTIERS'] = IDTIERS;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };
       
        return this.http.get(Constants.API_CLIENT_URL + 'getClientById', options);
    }

    getClients(): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['IDSOC'] = Constants.IDSOC;
       
        const options = {
            headers: headers,
            params: params
        };
        
        return this.http.get(Constants.API_CLIENT_URL + 'searchClient', options);
    }

    createClient(form_data: any): Observable<any> {
         const headers = new HttpHeaders();
         headers.append('Content-Type', 'application/json');
         
         const options = {
             headers: headers
         };
         
         return this.http.post(Constants.API_CLIENT_URL + 'createClient', form_data, options);
    }    

    updateClient(form_data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        
        const options = {
            headers: headers
        };
        
        return this.http.post(Constants.API_CLIENT_URL + 'updateClient', form_data, options);
   }    

    searchPostalCode(code: string, pays?: any, type?: 'code' | 'ville'): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        if(type === undefined || type === 'code') {
            params['code'] = code;
        }

        if(type !== undefined && type === 'ville') {
            params['VILLE'] = code;
        }

        if(pays !== undefined && pays !== null && pays !== 'tous' && pays !== 'non_defini' && pays !== '') {
            params['PAYS'] = pays;
        }
       
        const options = {
            headers: headers,
            params: params
        };
        
        return this.http.get(Constants.API_CLIENT_URL + 'searchPostalCode', options);
    }

    getClientHistory(IDTIERS: string, IDINSTTIE: string, IDSOC: string, page?: number): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        if(page !== undefined) {
            params['page'] = page;
        }

        params['IDTIERS'] = IDTIERS;
        params['IDINSTTIE'] = IDINSTTIE;
        params['IDSOC'] = IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_CLIENT_URL + 'clientHistoire', options);
    }

    anonymizeClient(IDTIERS: string, IDINSTTIE: string, Id_User: number): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        const payload = {
            IDTIERS: +IDTIERS,
            IDINSTTIE: +IDINSTTIE,
            Id_User: Id_User,
            Id_InstUser: Constants.CIINST
        }

        const options = {
            headers: headers,
        };

        return this.http.post(Constants.API_CLIENT_URL + 'clientAnonymize', payload, options);
    }
}
