import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'providers/api/dialogService';

@Component({
  selector: 'app-update-affect-quantity',
  templateUrl: './update-affect-quantity.component.html',
  styleUrls: ['./update-affect-quantity.component.scss'],
  providers: [DialogsService, TranslateService]
})
export class UpdateAffectQuantityComponent implements OnInit, AfterViewInit {

  @ViewChild('firstFocusElement') firstFocusElement: ElementRef;

  motifData: any;
  maxQuantity: number;

  updateForm: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<UpdateAffectQuantityComponent>,
  private formBuilder: UntypedFormBuilder,
  private dialogService: DialogsService,
  private translateService: TranslateService) {    
  }

  ngOnInit(): void {
    this.motifData = this.data.motif_data;
    this.maxQuantity = this.data.maxQuantity;    

    this.updateForm = this.formBuilder.group({
      ALLOCATE_QTY: [this.maxQuantity, [Validators.required, Validators.pattern('^[0-9\.]+$'), Validators.max(this.maxQuantity)]]
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.firstFocusElement.nativeElement.focus();
      this.firstFocusElement.nativeElement.setAttribute('inputmode', 'decimal');
    }, 100)
  }

  updateAffectQty() {
    const allocatable_quantity = this.updateForm.get('ALLOCATE_QTY').value;

    if(isNaN(allocatable_quantity) || allocatable_quantity < 0) {
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntyinval'), this.translateService.instant('kuok'));
      return;
    }

    if(this.maxQuantity < 0 || allocatable_quantity > this.maxQuantity) {
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntytrop'), this.translateService.instant('kuok'));
      return;
    }
    
    this.dialogRef.close({ status: 'success', affected_quantity: allocatable_quantity });
  }

}
