import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Magasin } from 'models/magasins';
import { User } from 'models/users';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientService } from 'providers/api/clientService';
import { DialogsService } from 'providers/api/dialogService';
import { LoginService } from 'providers/api/loginService';
import { StorageService } from 'providers/api/storageService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';

@Component({
  selector: 'app-client-filter',
  templateUrl: './client-filter.component.html',
  styleUrls: ['./client-filter.component.scss']
})
export class ClientFilterComponent implements OnInit, AfterViewInit {

  @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;
  searchForm: UntypedFormGroup;
  alpha_categories = [ //09,ab,cd,eh,il,mo,pr,su,vz
    {
        label: '0...9',
        value: '09'
    },
    {
        label: 'A...B',
        value: 'ab'
    },
    {
        label: 'C...D',
        value: 'cd'
    },
    {
        label: 'E...H',
        value: 'eh'
    },
    {
        label: 'I...L',
        value: 'il'
    },
    {
        label: 'M...O',
        value: 'mo'
    },
    {
        label: 'P...R',
        value: 'pr'
    },
    {
        label: 'S...U',
        value: 'su'
    },
    {
        label: 'V...Z',
        value: 'vz'
    }
  ];
  listMagasins: Magasin[] = [];
  filteredMagasins: Magasin[] = [];
  filterMagasin: string = "";
  listPays: any[] = [];  
  filteredPays: any;
  filterPays: string = "";
  oldFormData: any;

  user: User;
  USERCMINT: any;

  today = new Date();

  defaultPays: string = '';
  dateAct: string = 'equal';
  minDateAct: any = null;

  loadType: 'prefetch' | 'new_load' = 'prefetch';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ClientFilterComponent>,
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogsService,
    private userService: UserService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private clientService: ClientService,
    private loadingService: NgxSpinnerService,
    private storageService: StorageService,) { }

  ngOnInit(): void {
    this.dateAct = this.data.dateAct;
    this.searchForm = this.formBuilder.group({
      CMINT: [''],
      NOM: [''],
      TPRENOM: [''],            
      CP: [''],
      VILLE: [''],
      PAYS: [this.defaultPays],
      BIRTHDATEMIN: [{value: '', disabled: true}],
      BIRTHDATEMAX: [{value: '', disabled: true}],
      TEL: [''],
      IDSOC: [Constants.IDSOC],
      lettre: [''],     
      TEMAIL: [''],
    });
    this.loadType = this.data.fetchType;
    this.loadDependencies(this.loadType);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.loadType === 'prefetch') {
        this.filterFirst.nativeElement.select();
        this.filterFirst.nativeElement.focus();
      }
    }, 300);
  }

  async loadDependencies(type: 'prefetch' | 'new_load' = 'prefetch') {
    if(type === undefined || type == null || type === 'prefetch') {
      this.alpha_categories = this.data.alpha_categories;
      this.listMagasins = this.data.listMagasins;
      this.filteredMagasins = this.listMagasins;
      this.listPays = this.data.listPays;
      this.filteredPays = this.listPays;
      
      this.setDefaultCountry();
      this.oldFormData = this.data.form_data;

      this.searchForm.patchValue({
        CMINT: this.oldFormData.CMINT !== null?this.oldFormData.CMINT:'',
        NOM: this.oldFormData.NOM !== null?this.oldFormData.NOM:'',
        TPRENOM: this.oldFormData.TPRENOM !== null?this.oldFormData.TPRENOM:'',            
        CP: this.oldFormData.CP !== null?this.oldFormData.CP:'',
        VILLE: this.oldFormData.VILLE !== null?this.oldFormData.VILLE:'',
        PAYS: this.oldFormData.PAYS !== null?this.oldFormData.PAYS:this.defaultPays,
        BIRTHDATEMIN: (this.oldFormData.BIRTHDATEMIN !== null && this.oldFormData.BIRTHDATEMIN !== '' && this.oldFormData.BIRTHDATEMIN !== 'Invalid Date')?this.oldFormData.BIRTHDATEMIN:'',
        BIRTHDATEMAX: (this.oldFormData.BIRTHDATEMAX !== null && this.oldFormData.BIRTHDATEMAX !== '' && this.oldFormData.BIRTHDATEMAX !== 'Invalid Date')?this.oldFormData.BIRTHDATEMAX:'',
        TEL: this.oldFormData.TEL !== null?this.oldFormData.TEL:'',
        IDSOC: this.oldFormData.IDSOC !== null?this.oldFormData.IDSOC:Constants.IDSOC,
        lettre: this.oldFormData.lettre !== null?this.oldFormData.lettre:'',
        TEMAIL: this.oldFormData.TEMAIL !== null?this.oldFormData.TEMAIL:'',   
      });
    } else {
      this.loadingService.show();
      this.user = this.userService.getCurrentUser();
      this.USERCMINT = this.user.CMINT;
  
      await this.getAllMagasins();
      await this.getAllPays();

      this.loadingService.hide();
    }
  }

  async setDefaultCountry() {
    try {
      const paramData = await this.userService.getRuleParamsData(10,1);
      this.defaultPays = paramData.VBOOL1 == '-1'?paramData.VSTR1:'tous';
      this.searchForm.get('PAYS').setValue(this.defaultPays);
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'))
    }
  }

  onDateChanged() {
    this.minDateAct = this.searchStartDate.value?this.searchStartDate.value:null;
    if(this.minDateAct !== null) {
      this.searchEndDate.setValue(this.minDateAct);
    }
  }

  onSearchChange(search: string) {
    this.filteredMagasins = this.listMagasins.filter(row => row.CMRAISOC.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  onCountryChange(search: string) {
    this.filteredPays = this.listPays.filter(row => row.PAYSNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  dialogSearchFormSubmitter() {
    if(this.searchForm.invalid) {
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }
    this.userService.headerSearchInputValue.next(undefined);
    this.dialogRef.close({status: 'success', form_data: this.searchForm.getRawValue(), dateAct: this.dateAct });
  }

  get searchStartDate() {
    return this.searchForm.get('BIRTHDATEMIN');
  }

  get searchEndDate() {
    return this.searchForm.get('BIRTHDATEMAX');
  }

  changeDateAct(type: string = 'equal') {
    this.dateAct = type;
  }

  _clearDate = (form_field: string) => {
    this.searchForm.get(form_field).setValue('');
    if(form_field == 'BIRTHDATEMIN') {
      this.minDateAct = null;
    }
  }

  resetForm() {
    this.searchForm.reset();
    this.dateAct = 'equal';
    this.searchForm.patchValue({      
      PAYS: this.defaultPays
    });

    this.storageService.removeItem('clientFormData');
    this.storageService.removeItem('clientListData');
    
    this.userService.headerSearchInputValue.next(undefined);
  }

  getAllMagasins() {
    return new Promise((resolve, reject) => {
      this.loginService.getMagasins().subscribe(
        (data) => {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    this.listMagasins.push(new Magasin(data[i]));          
                }
                this.filteredMagasins = this.listMagasins;
                resolve(data);
            }
        }, 
      (error) => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        reject(error);
      });
    })
  }

  async getAllPays(): Promise<void> {
    const paramData = await this.userService.getRuleParamsData(10,1);        
    this.defaultPays = paramData.VBOOL1 == '-1'?paramData.VSTR1:'tous';
    this.searchForm.get('PAYS').setValue(this.defaultPays);

    return new Promise((resolve, reject) => {
        this.clientService.getListPays(this.USERCMINT, this.user.CIINT).subscribe(
          (res: any) => {
            if(res.success !== undefined) {
              this.listPays = res.data;  
              this.filteredPays = this.listPays;            
              resolve();            
            }
          },
          err => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject()
          }
        );
    });
}

}
