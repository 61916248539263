<div class="row">
    <div class="col-12 col-md-12 col-lg-12">
        <h2>{{'kbondetail' | translate}} {{ transporteur.TRALIBL }}</h2>
    </div>

    <div class="col-12 col-md-12 col-lg-12">            
        <div class="card card-body mb-3" style="max-height: 220px; overflow-y: scroll;" *ngIf="transporteurDept">
            <h2 class="mb-2" *ngFor="let item of transporteurDept">{{ item }}</h2>
        </div>
    </div>
</div>

<div class="bloc_container">
    <div class="row bg_white">
        <div class="col-md-12 text-center">
            <button class="bt_valid" type="button" mat-button mat-dialog-close [title]="'kartclose' | translate">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">{{'kartclose' | translate}}</span>
            </button>
        </div>                
    </div>
</div>