<div class="page-container">
    <form [formGroup]="searchForm" (keydown.enter)="$event.preventDefault()" #f="ngForm" (ngSubmit)="searchFormSubmitter()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-12 ">

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <mat-form-field>
                                <input matInput #firstFocusElement (keydown.enter)="TRASTR1.focus()" inputmode="none" formControlName="TRALIBL" [placeholder]="'kartlib' | translate" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <mat-form-field>
                                <input matInput inputmode="numeric" #TRASTR1 (keydown.enter)="f.ngSubmit.emit()" formControlName="TRASTR1" maxlength="10" [placeholder]="'kbondepartment' | translate" />
                            </mat-form-field>
                        </div>                        
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button type="submit">
                        <mat-icon>search</mat-icon>
                        <span class="d-none d-sm-none d-md-block">{{'kclsearc' | translate}}</span>
                    </button>                    
                </div>                
            </div>
        </div>
    </form>

    <div class="bloc_container">

        <div class="row">
            <div class="col-12 no-padding">
                <table datatable class="row-border hover" id="transporteursList" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead class="d-none">
                        <tr>
                            <th class="code">{{'kartlib' | translate}}</th>  
                            <th></th>                          
                        </tr>
                    </thead>
                    <tbody *ngIf="listTransporteur?.length != 0 && !showLoader">
                        <tr *ngFor="let transporteur of listTransporteur">
                            <td class="numero">
                                <div class="d-flex flex-row justify-content-start align-items-center">                                    
                                    <span>{{ transporteur.TRALIBL }}</span>
                                </div>                                
                            </td>
                            <td>
                                <div class="d-flex justify-content-around align-items-center">
                                    <mat-slide-toggle (change)="changeTransporteur($event, transporteur)" [checked]="(chosenTransporteur !== undefined && chosenTransporteur.CNUF == transporteur.CNUF)?true:false"></mat-slide-toggle>
                                
                                    <button type="button" mat-icon-button class="ml-1 my-1 theme-color" (click)="viewTransporteurDetail(transporteur)">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </div>                                
                            </td>
                        </tr>                        
                    </tbody>
                    <tbody *ngIf="listTransporteur?.length == 0 || showLoader">
                        <tr>
                            <td colspan="2" *ngIf="listTransporteur?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate  }}</td>
                            <td colspan="2" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                            <td class="text-center d-none"></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-6 text-right">
                <button type="button" (click)="submitTransporteur()" class="bt_valid" mat-button [title]="'kuok' | translate">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">{{ 'kuok' | translate }}</span>
                </button>
            </div>
            <div class="col-6 text-left">
                <button class="bt_valid" type="button" mat-button mat-dialog-close [title]="'kucancel' | translate">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">{{ 'kucancel' | translate }}</span>
                </button>
            </div>                
        </div>
    </div>
</div>