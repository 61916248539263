import { Constants } from '../providers/constants';

export class Article {
    CAINT: string = '';
    CDART: string = '';
    CALIB1: string = '';
    CALIB2: string = '';
    CALIB3: string = '';
    CALIBC: string = '';
    CSTNIV1: string = '';
    CSTNIV2: string = '';
    CSTNIV3: string = '';
    CSTNIV4: string = '';
    CSTNIV5: string = '';
    CSFLIB1: string = '';
    CSFSFAM: string = '';
    CAPVTTC: any = '';
    CAPVFORCE: string = '';
    CMCODE: string = '';
    CTVINT: string = '';
    CDINT: string = '';
    CAECODE: string = '';
    CAETIQ: string = '';
    CADESCAIS: string = '';
    CAREMISABLE: string = '';
    CAIMPRIM: string = '';
    CAGESTOCK: string = '';
    CADCREA: string = '';
    CADMODIF: string = '';
    CACNUF: string = '';
    CADEPT: string = '';
    CAOKCPR: string = '';
    CAKDO: string = '';
    IDSOC: string = '';
    IDINSTCAI: string = '';
    CAOKLIV: string = '';
    CAISTC: string = '';
    CAISCOMPOSE: string = '';
    CAUNVTE: string = '';
    CAPMP: string = '';
    CANUMSERIE: string = '';
    CASENSIBLE: string = '';
    CAPMINI: string = '';
    CALIBINT1: string = '';
    CALIBINT2: string = '';
    CALIBINT3: string = '';
    CALIBSTR1: string = '';
    CALIBSTR2: string = '';
    CALIBSTR3: string = '';
    BALCINT: string = '';
    BALNOM: string = '';
    BALDESC: string = '';
    BALDESIGN1: string = '';
    OKMODIF: string = '';
    BALMARQ: string = '';
    BALMODELFOU: string = '';
    BALCOUL: string = '';
    BALCONSEIL: string = '';
    BALPLUS: string = '';
    BALAUSSI: string = '';
    BALEGAL: string = '';
    BALCONSO: string = '';
    BALALIM: string = '';
    BALGARAN: string = '';
    BALARGUWEB: string = '';
    BALDISPMAG: string = '';
    BALDISPWEB: string = '';
    BALMONTER: string = '';
    BALLEGAL: string = '';
    BALDIMPDS: string = ''
    BALDIMKG: string = '';
    BALDIMCOUR: string = '';
    BALDIMLONG: string = '';
    BALPLAN: string = '';
    BALCONDMAG: string = '';
    BALCONDWEB: string = '';
    BALNBCOLMAG: string = '';
    BALNBCOLWEB: string = '';
    BALCONDIDWEB: string = '';
    BALCOMPO: string = '';
    BALTAR1: string = '';
    BALTAR2: string = '';
    BALTAR3: string = '';
    BALTAR4: string = '';
    BALEAN: string = '';
    BALPIC1: string = '';
    BALPIC2: string = '';
    BALPIC3: string = '';
    BALPIC4: string = '';
    BALPIC5: string = '';
    ATTINTER: string = '';
    IDINSTBAL: string = '';
    USERDMOD: string = '';
    QTE: number = 1;
    PRIX: string = '0';
    TYPE: string = 'EL';
    STOCKDISPO: number = 0;
    FORCER: string = '';
    PRIXUPV: number = 0;
    PRIXCAIS: number = 0;
    PRIXORI: number = 0;
    MTTVA: number= 0;
    VENQTE: number = 1;
    MNTTVA: number = 0;
    MOTIFCHANGEPRIX: string = '';
    NEWPRIX: string = '0';
    DATAREM_CODE_ARTICLE: number = 0;
    DATAREM_MONTANT: any;
    DATAREM: any;
    REMMTT: any;
    DATAREM_CODE:any;
    REMISE: any;
    NUMLIG: any;
    MOTIFREM: any;
    CHPCODE: any;
    GROUP_COUNT: string;
    calculated_price?: string;
    etatart?: string;
    OKSTOCK?: string;
    CALIBFLO1?: string;

    constructor(values: Object = {}) {
        for (const prop in values) {
            if (values[prop] === null) {
                values[prop] = '';
            }
        }
        Object.assign(this, values);
    }

}

export interface SousFamile {
    CSFSFAM: string;
    CSFLIB1: string;
    CSFLIB2: string;
    CSFLIB3?: any;
    CSFCTVA?: any;
    CSTNIV1: string;
    CSTNIV2: string;
    CSTNIV3: string;
    CSTNIV4: string;
    CSTNIV5: string;
}

export interface Fournisseur {
    CNUF: number;
    DEPT: string;
    LIBFOURN: string;
    ARTCCNU?: number;
    STKENTLIB?: string;    
}

export interface Entreprots {
    CMINT: string;
    CAINT: string;
    CSKDISPOVTE: string;
    CSKRESERVE?: any;
    IDSOC: string;
    CSKFLOAT1?: any;
    CSKRUPTURE: string;
    CSKCOMINGSOON: string;
    CSKLASTMVT?: any;
    IDENTPOT: string;
    LIBEL: string;   
}
