
<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium">{{'kclclearform' | translate}}</div>
            </button>
        </div>
    </div>
</div>

<form #f="ngForm" [formGroup]="searchForm" (ngSubmit)="headerSearchFormSubmitter()" >    
    <div class="bloc_container bg_light py-4">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="NUMBON"
                                [placeholder]="'kbonnbon' | translate"
                                class="text-uppercase" #filterFirst>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="NOM"
                                [placeholder]="'kpname' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="PRENOM"
                                [placeholder]="'kuprenom' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">                        
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="CODPOS"
                                [placeholder]="'kboncodeopostal' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="VILLE"
                                [placeholder]="'kclcity' | translate"
                                class="text-uppercase">
                        </mat-form-field>                        
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="TEL_PORT"
                            [placeholder]="'kcltel' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <div class="row no-gutters">
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput inputmode="decimal" formControlName="TOTAL_ENTRE_FROM"
                                        [placeholder]="'kbontotbetn' | translate"
                                        class="text-uppercase">
                                </mat-form-field>
                            </div>
                            <div class="col-6 pl-4">
                                <mat-form-field>
                                    <input matInput inputmode="decimal" formControlName="TOTAL_ENTRE_TO"
                                        [placeholder]="'kbonand' | translate"
                                        class="text-uppercase">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">                        
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="NUM_EXT"
                                [placeholder]="'kbonnext' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <div class="row no-gutters">
                            <div [class]="dateAct === 'between'?'col-6':'col-12'">
                                <mat-form-field floatLabel="always" class="date">
                                    <input matInput formControlName="DATE" (dateChange)="onDateChanged('create')" [matDatepicker]="cdeDate"
                                        [placeholder]="'kbondate' | translate"
                                        class="text-uppercase">
                                    <mat-datepicker-toggle matSuffix [for]="cdeDate">
                                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #cdeDate [disabled]="false"></mat-datepicker>
                                    <button mat-icon-button type="button" *ngIf="searchStartDate.value" (click)="_clearDate('DATE')" matSuffix ><mat-icon>close</mat-icon></button>
                                    <button matSuffix type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                                        <mat-icon class="d-flex justify-content-center align-items-center">
                                            <span style="line-height: initial;" *ngIf="dateAct === 'equal'">=</span>
                                            <span style="line-height: initial;" *ngIf="dateAct === 'greater_equal'">>=</span>
                                            <span style="line-height: initial;" *ngIf="dateAct === 'less_equal'"><=</span>
                                            <span style="line-height: initial;" *ngIf="dateAct === 'between'"><></span>
                                        </mat-icon>
                                    </button>                                
                                </mat-form-field>
                                <mat-menu #menu="matMenu" [hasBackdrop]="false">
                                    <button mat-menu-item (click)="changeDateAct('equal')">{{'kbondategale' | translate}}</button>
                                    <button mat-menu-item (click)="changeDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                                    <button mat-menu-item (click)="changeDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                                    <button mat-menu-item (click)="changeDateAct('between')">{{'kbondatentre' | translate}}</button>
                                </mat-menu>
                            </div>
                            <div class="col-6 pl-3" *ngIf="dateAct === 'between'">
                                <mat-form-field floatLabel="always">
                                    <input matInput formControlName="DATE_END" [min]="minDateAct" [matDatepicker]="cdeEndDate"
                                        [placeholder]="'kbonand' | translate"
                                        class="text-uppercase">
                                        
                                    <mat-datepicker-toggle matSuffix [for]="cdeEndDate">
                                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #cdeEndDate [disabled]="false"></mat-datepicker>
                                    <button mat-icon-button type="button" *ngIf="searchEndDate.value" (click)="_clearDate('DATE_END')" matSuffix ><mat-icon>close</mat-icon></button>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row no-gutters">
                            <div [class]="echDateAct === 'between'?'col-6':'col-12'">
                                <mat-form-field floatLabel="always" class="date">
                                    <input matInput formControlName="DEADLINE" (dateChange)="onDateChanged('deadline')" [matDatepicker]="echDate"
                                        [placeholder]="'kbondeadline' | translate"
                                        class="text-uppercase">
                                    <mat-datepicker-toggle matSuffix [for]="echDate">
                                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #echDate [disabled]="false"></mat-datepicker>
                                    <button mat-icon-button type="button" *ngIf="searchStartDeadlineDate.value" (click)="_clearDate('DEADLINE')" matSuffix ><mat-icon>close</mat-icon></button>
                                    <button matSuffix type="button" [matMenuTriggerFor]="echMenu" mat-icon-button>
                                        <mat-icon class="d-flex justify-content-center align-items-center">
                                            <span style="line-height: initial;" *ngIf="echDateAct === 'equal'">=</span>
                                            <span style="line-height: initial;" *ngIf="echDateAct === 'greater_equal'">>=</span>
                                            <span style="line-height: initial;" *ngIf="echDateAct === 'less_equal'"><=</span>
                                            <span style="line-height: initial;" *ngIf="echDateAct === 'between'"><></span>
                                        </mat-icon>
                                    </button>                                
                                </mat-form-field>
                                <mat-menu #echMenu="matMenu" [hasBackdrop]="false">
                                    <button mat-menu-item (click)="changeEchDateAct('equal')">{{'kbondategale' | translate}}</button>
                                    <button mat-menu-item (click)="changeEchDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                                    <button mat-menu-item (click)="changeEchDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                                    <button mat-menu-item (click)="changeEchDateAct('between')">{{'kbondatentre' | translate}}</button>
                                </mat-menu>
                            </div>
                            <div class="col-6 pl-3" *ngIf="echDateAct === 'between'">
                                <mat-form-field floatLabel="always">
                                    <input matInput formControlName="DEADLINE_END" [min]="minEchDateAct" [matDatepicker]="echEndDate"
                                        [placeholder]="'kbonand' | translate"
                                        class="text-uppercase">
                                        
                                    <mat-datepicker-toggle matSuffix [for]="echEndDate">
                                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #echEndDate [disabled]="false"></mat-datepicker>
                                    <button mat-icon-button type="button" *ngIf="searchEndDeadlineDate.value" (click)="_clearDate('DEADLINE_END')" matSuffix ><mat-icon>close</mat-icon></button>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput formControlName="ARTICLE" (keydown.enter)="onCodeEntered($event)" [placeholder]="'kartarttit' | translate" class="text-uppercase">
                            <button matSuffix class="ml-2" type="button" (click)="openScanArticle()" mat-icon-button>
                                <i class="fa fa-barcode" aria-hidden="true"></i>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="pageType !== 'historique'">
                        <mat-form-field>
                            <mat-select [placeholder]="'kartstate' | translate" formControlName="ETAT" >
                                <mat-option value="">{{'kutousetats' | translate}}</mat-option>
                                <mat-option *ngFor="let etat of etatList" [value]="etat.id">
                                    {{ etat.label | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field>
                            <mat-select [placeholder]="'kbonsecteur' | translate" formControlName="SECTEUR" >
                                <mat-option value="">{{'kutoussecteur' | translate}}</mat-option>
                                <mat-option *ngFor="let magStruct of magasinStructure" [value]="magStruct.CSTNIV1">
                                    {{ magStruct.CSTNIV1+ ' ' + magStruct.CSTLIB1 }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>                                    
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12" *ngIf="pageType !== 'historique'">
                        <mat-form-field>
                            <mat-select [placeholder]="'kbonautrechoice' | translate" formControlName="AUTRE_CHOIX" >
                               
                                <mat-option *ngFor="let autreChoix of autreChoixList" [value]="autreChoix.id">
                                    {{ autreChoix.label | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field>
                            <mat-select [placeholder]="'kboncanal' | translate" formControlName="CANAL" >
                                <mat-option value="">{{'kutouscanal' | translate}}</mat-option>
                                <mat-option *ngFor="let canal of canalList" [value]="canal.IDCANAL">
                                    {{ canal.CANALLIB | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 mb-3" *ngIf="pageType === 'historique'">
                        <div class="radio-container">
                            <mat-label>{{ "kbontype" | translate }}</mat-label>
                            <mat-radio-group formControlName="TYPECDE" aria-label="Select an option">
                                <mat-radio-button *ngFor="let item of typeCdeList" [value]="item.id"><span [translate]="item.label" class="d-block"></span></mat-radio-button>
                            </mat-radio-group>
                        </div>                            
                    </div>                  
                    <div class="col-12">                        
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-12 mt-2 mt-md-0 text-center">
                <button type="submit" class="bt_valid" mat-button>
                    <i class="material-icons">search</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'kpsearch' | translate }}
                    </span>
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kucancel" | translate }}
                    </span>                        
                </button>
            </div>
        </div>
    </div>
</form>