<div class="row no-gutters" style="height: 90%;">
    <div class="col-12">
        <iframe width="100%" height="100%" [src]="iframeUrl | safe"></iframe>
    </div>
</div>

<div class="bloc_container" style="width: 100%;min-width: 100%;">
    <div class="row bg_white">
        <div class="col-12 text-center">
            <button class="bt_valid" type="button" mat-button mat-dialog-close [title]="'kartclose' | translate">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">{{ 'kartclose' | translate }}</span>
            </button>
        </div>                
    </div>
</div>