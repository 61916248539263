import {Component, OnInit, ViewChild, Inject, Optional, Input, EventEmitter, Output} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

import {Article} from '../../../../models/articles';
import {ArticleService} from '../../../../providers/api/articleService';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'providers/api/dialogService';

@Component({
    selector: 'app-article-description-code',
    templateUrl: './article-description-code.component.html',
    providers: [ArticleService, HttpClientModule, TranslateService]
})

export class ArticleDescriptionCodeComponent implements OnInit {
    
    private _article: Article
    get article() {return this._article}
    @Input() set article(article: Article) {this.articleChange.emit(this._article = article)};
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();

    CAINT: string;
    CDART: string;
    listCode: any[] = [];

    // DATATABLE
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    caisseSubscription: Subscription;
    showLoader = true;

    @Input() displayType: 'dialog' | 'component' = 'dialog';

    constructor(private articleService: ArticleService,
                private translateService: TranslateService,
                private dialogService: DialogsService,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    ngOnInit() {        
        this.dtOptions = {
            language: {
                processing: this.translateService.instant('kprocessing'),
                search: this.translateService.instant('ksearch'),
                loadingRecords: this.translateService.instant('kloading'),
                info: this.translateService.instant('kgenshowentry'),
                infoEmpty: this.translateService.instant('kgenshowingentry'),
                zeroRecords: this.translateService.instant('knodata'),
                emptyTable: this.translateService.instant('knodatadash'),
                paginate: {
                    first: this.translateService.instant('kfirst'),
                    previous: this.translateService.instant('kprevious'),
                    next: this.translateService.instant('knext'),
                    last: this.translateService.instant('klast'),
                }        
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };

        if(this.article !== undefined && this.article !== null) {
            this.CAINT = this.article?this.article.CAINT:this.data.CAINT;
            this.CDART = this.article?this.article.CDART:this.data.CDART;
    
            this.getCaisseList();
        } else {
            if(this.displayType === 'component') {
                this.showLoader = false;
            }            
        }        
    }

    getCaisseList() {
        this.caisseSubscription = this.articleService.getCodeCaisse(this.CAINT, '1').subscribe(
            (response) => {
                this.showLoader = false;

                if(response.length > 0) {
                    const result = [{ CMEAN: this.CDART }, ...response];
                    this.listCode = result;
                    this.dtTrigger.next();
                }            
            }, error => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                this.showLoader = false;
            }
        );
    }

    ngOnDestroy() {
        this.caisseSubscription.unsubscribe();
    }
}
