<h1 mat-dialog-title class="text-center" translate>karteditbloqtock</h1>
<div mat-dialog-content>
    <form [formGroup]="editMotifBlocageForm" (ngSubmit)="openUpBondeCustomMotif()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-md-12">
                    <mat-form-field floatLabel="always">
                        <input matInput inputmode="decimal" [placeholder]="'kartcrntqnty' | translate" formControlName="ACTUAL_QTY" />
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field floatLabel="always">
                        <input matInput #firstFocusElement inputmode="none" [placeholder]="'kartnewqnty' | translate" formControlName="NOVEL_QTY" (keyup)="quantityChanged($event)" />
                    </mat-form-field>                    
                </div>
                <div class="col-md-12">
                    <mat-form-field floatLabel="always">
                        <input matInput inputmode="decimal" [placeholder]="'kartdiff' | translate" formControlName="DIFFERENCE" />
                    </mat-form-field>
                </div>
                <div class="col-md-12" *ngIf="motifBlocageData">
                    <h6 class="strong-heading"><strong translate>kartmotif</strong></h6>
                    <p class="light-heading">{{ motifBlocageData.LIBEL }}</p>

                    <h6 class="font-italic strong-heading"><strong translate [translateParams]="{max_quantity: (maxQuantity?maxQuantity:0).toFixed(2) }">kartmaxqnty</strong></h6>
                </div>
            </div>
        </div>
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button [disabled]="editMotifBlocageForm.invalid" type="submit">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kpvalid" | translate }}
                        </span>                        
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kpback" | translate }}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>