<h1 mat-dialog-title class="text-center mb-3">{{'kartstockmod' | translate}}</h1>
<div mat-dialog-content>
    <form [formGroup]="transferStockForm" (ngSubmit)="transferStockLocation()">
        <div class="bloc_container bg_light" [class.padding_15]="!isMobile">
            <div class="row no-gutters mt-4">
                <div class="col-md-12">
                    <div class="row">                        
                        <div class="col-md-8" [class.no-padding]="isMobile">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <h5 class="strong-heading">{{'kartcrntloca' | translate}}</h5>
                                </div>
                                <div class="clearfix"></div>

                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">                        
                                        <input matInput [placeholder]="'kartalle' | translate" formControlName="ACTUAL_ALLEE" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">                        
                                        <input matInput [placeholder]="'kartColumn' | translate" formControlName="ACTUAL_COLONNE" />
                                    </mat-form-field>                    
                                </div>
                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">                        
                                        <input matInput [placeholder]="'kartlevel' | translate" formControlName="ACTUAL_NIVEAU" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">                        
                                        <input matInput [placeholder]="'kartqqty' | translate" formControlName="ACTUAL_QTE" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mb-3 mt-3">
                                    <h5 class="strong-heading">{{'kartdest' | translate}}</h5>
                                </div>
                                <div class="clearfix"></div>

                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">                        
                                        <input matInput inputmode="none" #firstFocusElement [placeholder]="'kartalle' | translate" formControlName="DEST_ALLEE" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">                        
                                        <input type="number" matInput [placeholder]="'kartColumn' | translate" formControlName="DEST_COLONNE" />
                                    </mat-form-field>                    
                                </div>
                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">                        
                                        <input type="number" matInput [placeholder]="'kartlevel' | translate" formControlName="DEST_NIVEAU" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-3">
                                    <mat-form-field floatLabel="always">
                                        <input type="number" matInput [placeholder]="'kartqqty' | translate" formControlName="DEST_QTE" />
                                    </mat-form-field>
                                </div>
                            </div>                            
                            <div class="row">
                                <div class="col-md-12">
                                    <h3 class="mt-3 text-right text-primary strong-heading" *ngIf="lastCheckedDate && lastCheckedDate !== null"><strong>{{'kartlastdate' | translate}}</strong> {{ lastCheckedDate }}</h3>
                                    <h3 class="mt-3 text-right text-primary strong-heading" *ngIf="!lastCheckedDate || lastCheckedDate === null"><strong>{{'kartnocheck' | translate}}</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="d-flex justify-content-center align-items-start flex-column">
                                <button type="button" mat-raised-button class="bt_code mb-4" (click)="updateDefinerDetails()" [disabled]="definerButtonDisabled">
                                    <mat-icon class="text-warning">create</mat-icon>
                                    <div class="text-medium"> {{'kartprimary' | translate}}</div>
                                </button>
                                <button type="submit" mat-raised-button class="bt_code mb-4">
                                    <mat-icon class="text-success">import_export</mat-icon>
                                    <div class="text-medium"> {{'karttransfer' | translate}}</div>
                                </button>
                                <button type="button" mat-raised-button class="bt_code mb-4" (click)="updateQuantityConfirm()">
                                    <mat-icon class="text-primary">how_to_reg</mat-icon>
                                    <div class="text-medium"> {{'kartqtconfirm' | translate}}</div>
                                </button>
                                <button type="button" mat-raised-button class="bt_code mb-4" (click)="deleteStockLocation()" disabled>
                                    <mat-icon class="text-danger">remove_circle</mat-icon>
                                    <div class="text-medium"> {{'kartqtsupprimer' | translate}}</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="clearfix"></div>

            </div>
        </div>            
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">                    
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kartclose" | translate }}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>