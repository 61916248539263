<div class="modal-container" id="emporte_differe_limite">
    <form [formGroup]="wefoxForm" (ngSubmit)="wefoxFormSubmitter()">
        <mat-card>
            <mat-card-header>
                <mat-card-title class="text-uppercase text-center ">
                    <div class="row">
                        <div class="col-11 text-left">
                            <h4 class="modal_title">{{'kwefoxdatetitle' | translate}}</h4>
                        </div>
                        <div class="col-1 text-right no-padding">
                            <button mat-button [mat-dialog-close] class="bt_close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row">        
                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{'kbondate' | translate}} </mat-label>
                            <input matInput [placeholder]="'kchoosedate' | translate" formControlName="dtlib3" readonly>

                            <button type="button" matSuffix mat-icon-button (click)="openUpDateTimePickerPopup()">
                                <i class="material-icons">calendar_month</i>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{'kassureur_actuel' | translate}} </mat-label>
                            <mat-select formControlName="strlib3">
                                <mat-option value="">{{ 'kchoose' | translate }}</mat-option>
                                <mat-option *ngFor="let companyRow of companiesList" [value]="companyRow.value">{{ companyRow.label | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-form-field floatLabel="always" class="date">
                            <input matInput [max]="today" [matDatepicker]="birthdayDate" formControlName="dtlib4" [placeholder]="'kcldatedenai' | translate" >
                            <mat-datepicker-toggle matSuffix [for]="birthdayDate">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #birthdayDate [disabled]="loyalCustomer"></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{'kwefox_commentaire' | translate}} </mat-label>
                            <input matInput formControlName="strlib2">
                        </mat-form-field>
                    </div>
                </div> 
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions class="text-center">
                <button mat-button type="submit" class="bt_send">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kuok' | translate}}
                    </span>
                </button>
                <button mat-button [mat-dialog-close] class="bt_send">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kartclose' | translate}}
                    </span>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
