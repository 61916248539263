import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import BonPanier from 'models/bonPanier';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ParamDetail } from 'models/paramDetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Subject } from 'rxjs';
import { UserModel } from 'models/user-model';

@Component({
  selector: 'app-alerte-client',
  templateUrl: './alerte-client.component.html',
  styleUrls: ['./alerte-client.component.scss']
})
export class AlerteClientComponent implements OnInit {
  @Input() alertClientData;
  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  panier: BonPanier;
  numBon: string;

  user: UserModel;
  USERCMINT: any;

  CMINT: any;

  alerteRelanceData: any;
  alerteMarchandiseData: any;
  isMobile: boolean;

  paramsList: ParamDetail[];
  buttonsList: { label: string, actionType: string, disabled: boolean }[] = [];
  buttonsDisabled: boolean = true;

  @Input() readOnly: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  
              private bonCommandeService: BonCommandeService,
              private userService: UserService,
              private loadingService: NgxSpinnerService,
              private translateService: TranslateService,
              private dialogService: DialogsService,
              private deviceDetector: DeviceDetectorService
              ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    if(this.isMobile){
      this.data = this.alertClientData;
    }

    this.numBon = this.data.numBon;
    this.panier = this.data.panier;
    this.CMINT = this.data.CMINT;
    if(this.data !== undefined && this.data != null) {
      this.readOnly = this.data.readOnly;
    }

    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    if(this.panier.client.tel) {
      this.buttonsDisabled = false;
    } else {
      this.buttonsDisabled = true;
    }

    this._loadDependencies();
  }

  async _loadDependencies() {
    this.loadingService.show();
    
    await this.getListParams();
    this._processingButtonsList();
    await this.getAlerteClient();

    this.loadingService.hide();
  }

  getListParams(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getRulesParams(this.CMINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {              
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  getAlerteClient(): Promise<void> {
    if(this.numBon === undefined || this.numBon == 'null' || this.CMINT === undefined || this.CMINT == 'null') return;

    return new Promise((resolve, reject) => {
      this.bonCommandeService.getAlerteClient(this.numBon, this.CMINT).subscribe(
        (data: any) => {
            if(data.success !== undefined && data.success === 'ok') {
              this.alerteRelanceData = data.relanceData.length > 0 ? data.relanceData[0]: undefined;
              this.alerteMarchandiseData = data.marchandiseData.length > 0 ? data.marchandiseData[0]: undefined;

              resolve(data);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  get firstButtonDisableStatus(): boolean { // SMS Marchandise dispo button
    let processedRules = this.userService.processRulesParams(this.paramsList);
    return this.readOnly || 
            (
              (
                (
                  (processedRules['51_1']['VBOOL1'] == '-1') &&
                  (processedRules['51_1']['VBOOL4'] == '-1') &&
                  (this.panier.client.email == null || this.panier.client.email === '') &&
                  (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
                  (this.panier.client.tel == null || this.panier.client.tel === '')
                ) ||
                (
                  (processedRules['51_1']['VBOOL4'] == '-1') &&
                  (processedRules['51_1']['VBOOL1'] != '-1') &&
                  (this.panier.client.email == null || this.panier.client.email === '')
                ) ||
                (
                  (processedRules['51_1']['VBOOL1'] == '-1') &&
                  (processedRules['51_1']['VBOOL4'] != '-1') &&
                  (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
                  (this.panier.client.tel == null || this.panier.client.tel === '')
                ) || 
                this.panier.typecde === 'BL' || 
                this.panier.typecde === 'E2/BL'
              )?true:false
            )
  }

  otherButtonDisableStatus = (key: string): boolean => { // all the other buttons
    let processedRules = this.userService.processRulesParams(this.paramsList);
    return this.readOnly || 
            (
              (
                (processedRules[key]['VBOOL1'] == '-1') && // VBOOL1 If yes
                (processedRules[key]['VBOOL4'] == '-1') && // and also VBOOL4 If yes
                (this.panier.client.email == null || this.panier.client.email === '') &&
                (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
                (this.panier.client.tel == null || this.panier.client.tel === '')
              ) ||
              (
                (processedRules[key]['VBOOL4'] == '-1') && // VBOOL4 If yes
                (processedRules[key]['VBOOL1'] != '-1') && // VBOOL1 and if not yes
                (this.panier.client.email == null || this.panier.client.email === '')
              ) ||
              (
                (processedRules[key]['VBOOL1'] == '-1') && // VBOOL1 If yes
                (processedRules[key]['VBOOL4'] != '-1') && // VBOOL4 and if not yes
                (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
                (this.panier.client.tel == null || this.panier.client.tel === '')
              )?true:false
            )
  }

  _processingButtonsList() {
    this.buttonsList = [];

    let processedRules = this.userService.processRulesParams(this.paramsList);
    
    if(processedRules['51_1']['VBOOL1'] == '-1' || processedRules['51_1']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_1']['VSTR2'] != null && processedRules['51_1']['VSTR2'] != '')?processedRules['51_1']['VSTR2']:'SMS Marchandise dispo',
        actionType: 'dispo',
        disabled: this.firstButtonDisableStatus
      });
    }

    if(processedRules['51_5']['VBOOL1'] == '-1' || processedRules['51_5']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_5']['VSTR2'] != null && processedRules['51_5']['VSTR2'] != '')?processedRules['51_5']['VSTR2']:'SMS Marchandise dispo 1',
        actionType: 'dispo1',
        disabled: this.otherButtonDisableStatus('51_5')
      });
    }

    if(processedRules['51_6']['VBOOL1'] == '-1' || processedRules['51_6']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_6']['VSTR2'] != null && processedRules['51_6']['VSTR2'] != '')?processedRules['51_6']['VSTR2']:'SMS Marchandise dispo 2',
        actionType: 'dispo2',
        disabled: this.otherButtonDisableStatus('51_6')
      });
    }

    if(processedRules['51_7']['VBOOL1'] == '-1' || processedRules['51_7']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_7']['VSTR2'] != null && processedRules['51_7']['VSTR2'] != '')?processedRules['51_7']['VSTR2']:'SMS Marchandise dispo 3',
        actionType: 'dispo3',
        disabled: this.otherButtonDisableStatus('51_7')
      });
    }

    if(processedRules['51_8']['VBOOL1'] == '-1' || processedRules['51_8']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_8']['VSTR2'] != null && processedRules['51_8']['VSTR2'] != '')?processedRules['51_8']['VSTR2']:'SMS Marchandise dispo 4',
        actionType: 'dispo4',
        disabled: this.otherButtonDisableStatus('51_8')
      });
    }

    if(processedRules['51_9']['VBOOL1'] == '-1' || processedRules['51_9']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_9']['VSTR2'] != null && processedRules['51_9']['VSTR2'] != '')?processedRules['51_9']['VSTR2']:'SMS Marchandise dispo 5',
        actionType: 'dispo5',
        disabled: this.otherButtonDisableStatus('51_9')
      });
    }

    if(processedRules['51_10']['VBOOL1'] == '-1' || processedRules['51_10']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_10']['VSTR2'] != null && processedRules['51_10']['VSTR2'] != '')?processedRules['51_10']['VSTR2']:'SMS Marchandise dispo 6',
        actionType: 'dispo6',
        disabled: this.otherButtonDisableStatus('51_10')
      });
    }

    if(processedRules['51_11']['VBOOL1'] == '-1' || processedRules['51_11']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_11']['VSTR2'] != null && processedRules['51_11']['VSTR2'] != '')?processedRules['51_11']['VSTR2']:'SMS Marchandise dispo 7',
        actionType: 'dispo7',
        disabled: this.otherButtonDisableStatus('51_11')
      });
    }

    if(processedRules['51_12']['VBOOL1'] == '-1' || processedRules['51_12']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_12']['VSTR2'] != null && processedRules['51_12']['VSTR2'] != '')?processedRules['51_12']['VSTR2']:'SMS Marchandise dispo 8',
        actionType: 'dispo8',
        disabled: this.otherButtonDisableStatus('51_12')
      });
    }

    if(processedRules['51_13']['VBOOL1'] == '-1' || processedRules['51_13']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_13']['VSTR2'] != null && processedRules['51_13']['VSTR2'] != '')?processedRules['51_13']['VSTR2']:'SMS Marchandise dispo 9',
        actionType: 'dispo9',
        disabled: this.otherButtonDisableStatus('51_13')
      });
    }

    if(processedRules['51_14']['VBOOL1'] == '-1' || processedRules['51_14']['VBOOL4'] == '-1') {
      this.buttonsList.push({
        label: (processedRules['51_14']['VSTR2'] != null && processedRules['51_14']['VSTR2'] != '')?processedRules['51_14']['VSTR2']:'SMS Marchandise dispo 10',
        actionType: 'dispo10',
        disabled: this.otherButtonDisableStatus('51_14')
      });
    }

  }

  sendSMS(type: string): Promise<void> {
    this.loadingService.show();
    if(this.numBon === undefined || this.numBon == 'null' || this.CMINT === undefined || this.CMINT == 'null') return;    

    return new Promise((resolve, reject) => {
      this.bonCommandeService.sendClientSMS(this.numBon, this.CMINT, type, this.user.CIINT, this.user.IDINSTINT).subscribe(
        (resp: any) => {
          
            if(resp.success !== undefined && resp.success === 'ok') {
              if(resp.data.ErrorCode == 0) {
                  this.alerteRelanceData = resp.relanceData.length > 0 ? resp.relanceData[0]: undefined;

                  if(type === 'dispo') {
                    this.alerteMarchandiseData = resp.marchandiseData.length > 0 ? resp.marchandiseData[0]: undefined;
                  }

                  this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmsgenvoye'));
              } else {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
              }              
              this.loadingService.hide();
              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

}
