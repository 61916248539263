import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageService } from 'providers/api/storageService';
import { UserService } from 'providers/api/userService';


@Injectable()
export class LoginGuard  {

    constructor(private userService: UserService,
                private router: Router,
                private storageService: StorageService
                ) {}

    canActivate(route: ActivatedRouteSnapshot) {                     
        
        // Check to see if a user is not logged in
        if (!this.userService.isUserLoggedIn()) {
            return true;
        }else{
          this.__successRedirect(); // If logged in already, redirect to corresponding home page
          return false;
        }
    }

    __successRedirect() {
        const restrictionData = this.storageService.getItem('CurrentUserPermission');
    
        if(restrictionData['4_0']) { // Bon de vente
            this.router.navigate(['bon-de-vente']);    
        } else if(restrictionData['110_0']) { // Bon de vente: Autre magasin
            this.router.navigate(['bon-de-vente/autre-magasin']);
        } else if(restrictionData['32_0']) { // Bon de commande
            this.router.navigate(['bon-de-commande']);
        } else if(restrictionData['75_0']) { // Historique des commande
            this.router.navigate(['historique-des-commandes']);
        } else if(restrictionData['56_0']) { // Commandes Supprimees
            this.router.navigate(['commandes-supprimees']);
        } else if(restrictionData['24_0']) { // Devis
            this.router.navigate(['devis']);
        } else if(restrictionData['23_0']) { // Articles 
            this.router.navigate(['article']);
        } else if(restrictionData['69_0']) { // General: Niveau de securite
            this.router.navigate(['niveau-de-securite']);
        } else if(restrictionData['74_0']) { // General: Gestion des droits
            this.router.navigate(['gestion-des-droits']);
        } else if(restrictionData['73_0']) { // General: Utilisateurs
            this.router.navigate(['utilisateurs']);
        } else if(restrictionData['101_0']) { // General: Motifs
            this.router.navigate(['motifs']);
        } else if(restrictionData['52_0']) { // General: Paramètrage Blocage/Déblocage Stock
            this.router.navigate(['motif-blocage']);
        } else if(restrictionData['71_0']) { // General: Codes postaux
            this.router.navigate(['postal-codes']);
        } else if(restrictionData['60_0']) { // General: Complément information magasin
            this.router.navigate(['magasin-complement-info']);
        } else if(restrictionData['2_0']) { // Clients
            this.router.navigate(['client']);
        } else {
            // If the logged in user have no rights
        }
    }
}