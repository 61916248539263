import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import BonPanier from 'models/bonPanier';
import { Client } from 'models/clients';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { Constants } from 'providers/constants';
import * as moment from 'moment';
import { BonVente } from 'models/bonventes';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { DevisService } from 'providers/api/devisService';
import { UserService } from 'providers/api/userService';
import { User } from 'models/users';
import { BarcodeScannerComponent } from '../barcode-scanner/barcode-scanner.component';
import { FocusMonitor } from '@angular/cdk/a11y';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-fidelite-bonde',
  templateUrl: './fidelite-bonde.component.html',
  styleUrls: ['./fidelite-bonde.component.scss'],
  providers: [DevisService]
})
export class FideliteBondeComponent implements OnInit, AfterViewInit {

  @ViewChild('fideliteForm') fideliteForm: NgForm;
  @ViewChild('fideliteSearchInput') fideliteSearchInput: ElementRef;

  private _client: Client;
  get client(): Client { return this._client }
  @Input() set client(client: Client) {this.clientChange.emit(this._client = client)}
  @Output() private clientChange: EventEmitter<Client> = new EventEmitter<Client>();

  private _fidelite: Client;
  get fidelite(): Client { return this._fidelite }
  @Input() set fidelite(fidelite: Client) {
    this.fideliteChange.emit(this._fidelite = fidelite);
    this.selectedFidlite = fidelite;
  }
  @Output() private fideliteChange: EventEmitter<Client> = new EventEmitter<Client>();

  private _bonNumBon: string;
  get bonNumBon(): string { return this._bonNumBon }
  @Input() set bonNumBon(bonNumBon: string) {this.bonNumBonChange.emit(this._bonNumBon = bonNumBon)}
  @Output() private bonNumBonChange: EventEmitter<string> = new EventEmitter<string>();

  private _panier: BonPanier;
  get panier(): BonPanier { return this._panier }
  @Input() set panier(panier: BonPanier) {
    this.panierChange.emit(this._panier = panier);

    if(panier !== undefined && panier !== null) {
      
    } else {
      
    }    
  }
  @Output() private panierChange: EventEmitter<BonPanier> = new EventEmitter<BonPanier>();

  @Input() flowType: string;
  @Input() readOnly: boolean = false;

  user: User;
  USERCMINT: any;

  searchForm: UntypedFormGroup = this.formBuilder.group({
    IDSOC: [Constants.IDSOC],
    IDINSTTIE: [Constants.CIINST],
    NUMFID: ['', Validators.required],    
  });
  isMobile: boolean;

  selectedFidlite: Client;

  userPermission: any;
  sectionDisabled: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private bonVenteService: BonVenteService,
    private devisService: DevisService,
    private userService: UserService,
    private loadingService: NgxSpinnerService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private deviceDetector: DeviceDetectorService,
    private focusMonitor: FocusMonitor,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
    this.isMobile = this.deviceDetector.isMobile();

    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.defineSectionStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['client'] !== undefined) {
    }  
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.isMobile) {
        this.focusMonitor.focusVia(this.fideliteSearchInput.nativeElement, null)
        this.fideliteSearchInput.nativeElement.setAttribute('inputmode', 'numeric');
      }
    }, 200)
  }

  openUpFideliteScan(event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: this.isMobile?'95vh':undefined,
        autoFocus: false,
        data: {
          title: 'kfidcodebar'
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result.status === "success") {
        this.searchForm.get('NUMFID').setValue(result.data);
        this.fideliteForm.ngSubmit.emit();
      }      
    });
  }

  defineSectionStatus() {
    if(this.flowType == 'bonde_commande' || this.flowType == 'retrait_marchandise') {
      this.sectionDisabled = true;
      this.searchForm.get('NUMFID').disable({ onlySelf: true });
    } else {
      if(this.userPermission != undefined && this.userPermission != null) {
        if(!this.userPermission['4_2']) {
          this.sectionDisabled = true;
          this.searchForm.get('NUMFID').disable({ onlySelf: true });
        } else {
          this.sectionDisabled = false;
          this.searchForm.get('NUMFID').enable({ onlySelf: true });
        }
      } else {
        this.sectionDisabled = false;
        this.searchForm.get('NUMFID').enable({ onlySelf: true });
      }
    }
  }

  removeFidelite() {
    if(this.userPermission['0_6']) {
      this.removeConfirmation();
      return;
    }

    this.selectedFidlite = undefined;
    this.fidelite = this.selectedFidlite;

    this.callUpdateAPI();
  }

  removeConfirmation(): void {
    const message = `
    <h1 class="text-left mb-3">${this.translateService.instant('kksuppfidelite')}</h1>    
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.selectedFidlite = undefined;
        this.fidelite = this.selectedFidlite;

        this.callUpdateAPI();
      }
    });    
  }

  searchFideliteFormSubmitter() {
    if(this.searchForm.invalid) {
      return;
    }
    
    this.loadingService.show();
    const form_data = this.searchForm.value;
    this.bonVenteService.getClientByFidelite(form_data).subscribe(
      (res) => {
        this.loadingService.hide();
        if(res.success !== undefined) {
          this.selectedFidlite = res.data[0];
          this.fidelite = this.selectedFidlite;

          if((this.client === undefined || this.client == null) && (this.selectedFidlite !== undefined && this.selectedFidlite != null)) {
            this.client = this.fidelite
          }

          this.callUpdateAPI();

          this.searchForm.get('NUMFID').reset();
          this.searchForm.patchValue({
            NUMFID: '',
            IDSOC: Constants.IDSOC,
            IDINSTTIE: Constants.CIINST
          });

        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )
  }

  callUpdateAPI() {
    if(this.panier === undefined || this.panier === null) {
      return;
    }    
    
    this.loadingService.show();    

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let client_data = this.panier.client;
    if(this.client !== undefined && this.client !== null) {
      client_data = {
        mag: +this.client.CMINT,
        civil: +this.client.IDCIV,
        nom: this.client.TRAISOC,
        prenom: this.client.TPRENOM,
        adr1: this.client.TADR1,
        adr2: this.client.TADR2,
        adr3: this.client.TADR3,
        adr4: this.client.TADR4,
        codpos: this.client.CODPOS,
        ville: this.client.TVILLE,
        pays: this.client.CODPAYS,
        tel: this.client.TTEL,
        fax: this.client.TFAX,
        gsm: this.client.TPORT,
        email: this.client.TEMAIL,
        idinstcli: +this.client.IDINSTTIE,
        idcli: +this.client.IDTIERS
      };
    }

    let bondeVenteData: BonPanier = {
      ...this.panier,
      client: client_data,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      numfid: (this.selectedFidlite !== undefined && this.selectedFidlite !== null)?this.selectedFidlite.NUMFID:'',      
      dtmaj: current_date
    };    

    if(this.flowType === 'devis') {
      let devisData = {
        pMag: this.panier.mag,
        pNumBon: this.bonNumBon,
        pPanier: bondeVenteData,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST
      };

      this.editDevis(devisData);
      return;
    }

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData);    
  }

  editBonDeVente(bondeVenteData: any) {
    this.bonVenteService.modifyBonvente(bondeVenteData).subscribe(
      (resp) => {
        this.loadingService.hide();        

        if(resp.statusCode == 200) {    
          if(resp.data.ErrorCode == 0) {
            // List update after a success response
            const oldListData = this.storageService.getItem('bonVenteListData');
            const oldNumBon = this.bonNumBon;
            // ------------------------------

            this.panier = resp.data.Panier;
            this.bonNumBon = resp.data.NumBon;

            if(this.panier.numfid !== null && this.panier.numfid !== "") {
              this.selectedFidlite = resp.data.fidClient;
            }

            this.storageService.setItem('previousBonPanier', this.panier);
            this.storageService.setItem('previousNumBon', this.bonNumBon);

            // List update after a success response
            if(oldListData !== undefined && oldListData !== null) {
              oldListData.data.map(
                (row: BonVente) => {
                  if(row.NUMCOM == oldNumBon) {
                    row.TRAISOC = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                    row.TPRENOM = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                    row.TADR3 = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TADR3:null;
                    row.CODPOS = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                    row.TVILLE = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                    row.TTEL = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.ENDMOD = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('YYYY-MM-DD HH:mm:ss');
                    
                    row.MTHT = this.panier.montantHT;                    
                    row.MTTTC = this.panier.montant;
                    row.MTTVA = (this.panier.montant - this.panier.montantHT);
                    
                    row.CLINUM = (this.panier.client !== undefined && this.panier.client !== null)?this.panier.client.idcli.toString():"0";
                    row.CLILIVNUM = (this.panier.clientlivre !== undefined && this.panier.clientlivre !== null)?this.panier.clientlivre.idcli.toString():"0";
                    row.CLIFACNUM = (this.panier.clientfacture !== undefined && this.panier.clientfacture !== null)?this.panier.clientfacture.idcli.toString():"0";                    

                    row.NUMCOM = this.bonNumBon;

                    return row;
                  }

                  return row;
                }
              );

              const updated_bonvente = oldListData.data.find(
                (row: BonVente) => row.NUMCOM == this.bonNumBon
              );

              let updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
              updated_row_removed.unshift(updated_bonvente);

              oldListData.data = updated_row_removed;

              this.storageService.setItem('bonVenteListData', oldListData);
            }
            // ------------------------------
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    ); 
  }

  editDevis(devisData: any, closeLoader: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.devisService.modifyDevis(devisData).subscribe(
        (resp) => {
          if(closeLoader) this.loadingService.hide();          

          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              
              // List update after a success response
            const oldListData = this.storageService.getItem('devisListData');
            const oldNumBon = this.bonNumBon;
            // ------------------------------

            this.panier = resp.data.Panier;
            this.bonNumBon = resp.data.NumBon;

            if(this.panier.numfid !== null && this.panier.numfid !== "") {
              this.selectedFidlite = resp.data.fidClient;
            }
            
            this.storageService.setItem('previousBonDevis', this.panier);
            this.storageService.setItem('previousNumBon', this.bonNumBon);

            // List update after a success response
            if(oldListData !== undefined && oldListData !== null) {
              oldListData.data.Bons.map(
                (row: any) => {
                  if(row.numbon == oldNumBon) {
                    row.civlib = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CIVLIB:null;
                    row.nom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                    row.prenom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                    row.cp = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                    row.ville = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                    row.tel = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                    row.gsm = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;

                    row.montant = this.panier.montant;
                    row.dtmaj = this.panier.dtmaj;                    

                    return row;
                  }

                  return row;
                }
              );

              const updated_devis = oldListData.data.Bons.find(
                (row: any) => row.numbon == this.bonNumBon
              );

              let updated_row_removed: BonVente[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
              updated_row_removed.unshift(updated_devis);

              oldListData.data.Bons = updated_row_removed;

              this.storageService.setItem('devisListData', oldListData);
            }
            // ------------------------------
    
              resolve({ response: resp });
            } else {
              reject({ response: resp });
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {        
            resolve({ response: resp });
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
          }
        },
        err => {
          if(closeLoader) this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });     
  }

}
