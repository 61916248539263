import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Article } from 'models/articles';
import { User } from 'models/users';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArticleService } from 'providers/api/articleService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';
import { Subject } from 'rxjs';
import { UpdateAffectQuantityComponent } from '../update-affect-quantity/update-affect-quantity.component';
import {AUTHORIZE_BLOCAGE_OU_DEBLOCAGE_STOCK, HistoryService} from '../../../../providers/api/historyService';
import BonPanier from 'models/bonPanier';

@Component({
  selector: 'app-update-blocage-externe',
  templateUrl: './update-blocage-externe.component.html',
  styleUrls: ['./update-blocage-externe.component.scss'],
  providers: [ArticleService, DialogsService, TranslateService, NgxSpinnerService]
})
export class UpdateBlocageExterneComponent implements OnInit {
  
  motifBlocageData: any;
  articleData: Article;

  editMotifBlocageForm: any;
  
  originQuantity: number;
  maxQuantity: number;
  affectedQuantity = 0;

  user: any;
  USERCMINT: any;

  listMotifBlocage: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};  
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  isMobile: boolean;
  authorizedBlocageUser: User;

  NUMBON: string;
  PANIER: BonPanier | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UpdateBlocageExterneComponent>,
    private formBuilder: UntypedFormBuilder,
    private articleService: ArticleService,
    private userService: UserService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private loadingService: NgxSpinnerService,
    private dialog: MatDialog,
    private deviceDetector: DeviceDetectorService,
    private historyService: HistoryService) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();

    this.motifBlocageData = this.data.motif_data;
    this.articleData = this.data.article_data;
    this.listMotifBlocage = this.data.listMotifs;
    this.authorizedBlocageUser = this.data.authorizedBlocageUser;

    if (this.data.NUMBON !== undefined && this.data.NUMBON !== null) {
      this.NUMBON = this.data.NUMBON;
    }

    if (this.data.PANIER !== undefined && this.data.PANIER !== null) {
      this.PANIER = this.data.PANIER;
    }
    
    if(this.listMotifBlocage) {
      this.listMotifBlocage.map((row: any) => {
        row.affected = null;
        return row;
      });
    }    

    this.showLoader = false;

    this.dtOptions = {
        pageLength: 5,
        ordering: false,
        paging: false,
        searching: false,            
        info: false,           
        retrieve: true,
        language: {
            processing: this.translateService.instant('kprocessing'),
            search: this.translateService.instant('ksearch'),
            loadingRecords: this.translateService.instant('kloading'),
            lengthMenu: this.translateService.instant('kgenshowingmenu'),
            info: this.translateService.instant('kgenshowentry'),
            infoEmpty: this.translateService.instant('kgenshowingentry'),
            zeroRecords: this.translateService.instant('knodata'),
            emptyTable: this.translateService.instant('knodatadash'),
            paginate: {
                first: this.translateService.instant('kfirst'),
                previous: this.translateService.instant('kprevious'),
                next: this.translateService.instant('knext'),
                last: this.translateService.instant('klast'),
            }          
        },
    };
    
    this.user = this.userService.getCurrentUser();          
    this.USERCMINT = this.user.CMINT;

    this.editMotifBlocageForm = this.formBuilder.group({
      ACTUAL_QTY: [{ value: parseFloat('0').toFixed(2), disabled: true }],
      AFFECT_QTY: [{ value: parseFloat('0').toFixed(2), disabled: true }],
      REMAIN_QTY: [{ value: parseFloat('0').toFixed(2), disabled: true }],
      UPDATE_DATA: [null]
    });

    this.editMotifBlocageForm.patchValue({
      ACTUAL_QTY: (this.motifBlocageData.VBLOC && this.motifBlocageData.VBLOC !== null)?parseFloat(this.motifBlocageData.VBLOC).toFixed(2):0,
      AFFECT_QTY: this.affectedQuantity.toFixed(2),
      REMAIN_QTY: (this.motifBlocageData.VBLOC && this.motifBlocageData.VBLOC !== null)?parseFloat(this.motifBlocageData.VBLOC).toFixed(2):0,
    });

    this.maxQuantity = (this.motifBlocageData.VBLOC && this.motifBlocageData.VBLOC !== null)?parseFloat(this.motifBlocageData.VBLOC):0;
    this.originQuantity = (this.motifBlocageData.VBLOC && this.motifBlocageData.VBLOC !== null)?parseFloat(this.motifBlocageData.VBLOC):0;
  }

  ngOnDestroy(): void {
    $.fn['dataTable'].ext.search.pop()
  }

  ngAfterViewInit(): void {
      // si on l'enlève, on ne peut pas recharger le tableau en faisant une nouvelle recherche, mais du coupe cela casse le datatable oupfffff....      
      this.dtTrigger.next();
  }

  quantityChanged(event: any) {    
    const currentQty = this.editMotifBlocageForm.get('NOVEL_QTY').value;
    const actualQty = this.editMotifBlocageForm.get('ACTUAL_QTY').value;

    let differenceQty = 0;
    if(currentQty !== '' && !isNaN(currentQty) && actualQty !== '' && !isNaN(actualQty)) {
      differenceQty = parseFloat(currentQty) - parseFloat(actualQty);
    }
    
    this.editMotifBlocageForm.patchValue({
      DIFFERENCE: differenceQty.toFixed(2)
    });
  }

  updateBlocageExterne() {
    const updateableData = JSON.parse(this.editMotifBlocageForm.get('UPDATE_DATA').value);

    if(updateableData === undefined || updateableData === null || updateableData === '' || updateableData.length === 0) {
      this.dialogRef.close();
      return;
    }

    this.loadingService.show();

    this.articleService.updateBlocageExterne(updateableData).subscribe(
      async (res) => {
        if(res.success !== undefined) {
          if (this.NUMBON && this.authorizedBlocageUser !== undefined && this.authorizedBlocageUser !== null) {
              await this._addBlocageDeBlocageHistory(this.articleData.CDART);
          }

          this.loadingService.hide();
          this.dialogRef.close({status: 'success', data: updateableData});          
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));  
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )    
  }

  checkEditable(motif: any) {
    const editable = (motif && motif.OKMODIF == '-1')?true:false;

    if(editable) {
        this.updateAffectedQuantity(motif);
    } else {
        this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartuauth'), this.translateService.instant('kuok'));            
    }
  }

  _addBlocageDeBlocageHistory(CDART: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
        AUTHORIZED_RIGHT_ID: AUTHORIZE_BLOCAGE_OU_DEBLOCAGE_STOCK,
        NUMBON: this.NUMBON,
        ARTEAN: CDART,
        CMINT: this.USERCMINT,
        CIINT: (this.authorizedBlocageUser !== undefined && this.authorizedBlocageUser !== null) ? this.authorizedBlocageUser.CIINT : this.user.CIINT,
        VALUER1: 'Reason',
        VALUER2: null,
        TYPE_OPERAT: 'externe'
    });
  }
  updateAffectedQuantity(motif: any): void {
    const dialogRef = this.dialog.open(UpdateAffectQuantityComponent, {
      maxWidth: '40vw',
      data: {
        motif_data: motif,
        maxQuantity: this.maxQuantity
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        const affected_quantity = result.affected_quantity;
        this.maxQuantity = this.originQuantity - (+affected_quantity);
        
        let totalAffectedQty = 0;

        this.listMotifBlocage.map(
          (row: any) => {            

            if(row.IDMOT == motif.IDMOT) {              
              row.affected = affected_quantity;
              totalAffectedQty += (+row.affected);
              return row;
            }

            if(row.affected && row.affected > 0) {
              totalAffectedQty += (+row.affected);
            }
            return row;
          }
        );        
        
        this.editMotifBlocageForm.patchValue({
          AFFECT_QTY: (+totalAffectedQty).toFixed(2),
          REMAIN_QTY: (this.originQuantity - (+totalAffectedQty)).toFixed(2)
        });

        const affectedRows = this.listMotifBlocage.filter((row: any) => row.affected !== undefined && row.affected !== null && +row.affected > 0);
        let updateableData: any[] = [];
        affectedRows.map(
          (row: any) => {
            updateableData.push({
              CAINT: this.articleData.CAINT,
              CDART: this.articleData.CDART,
              IDMAG: this.USERCMINT,
              IDSOC: Constants.IDSOC,
              IDINST: Constants.CIINST,
              IDMOT: row.IDMOT,
              QTY: row.affected
            });
          }
        );

        if(updateableData !== null && updateableData.length > 0) {
          this.editMotifBlocageForm.patchValue({
            UPDATE_DATA: JSON.stringify(updateableData)
          });          
        }
      }      
    });    
  }

}
