import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {Component, HostListener, Inject, OnInit} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';

@Component({
  selector: 'app-vente-article-groups',
  templateUrl: './vente-article-groups.component.html',
  styleUrls: ['./vente-article-groups.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class VenteArticleGroupsComponent implements OnInit {

  articleGroupForm: UntypedFormGroup;
  chosenGroups: UntypedFormArray;
  chosenOptionArticles: UntypedFormArray;

  flowType: 'new' | 'edit' = 'new';
  pageType: 'commande' | 'others' = 'others';
  numBon: any;
  panier: BonPanier;
  currentItemFormData: any;
  articleTypeCde: string;
  typeDevis: boolean = false;
  currentItem: PanierArticle;
  currentModifyItemFormData: any;

  listArticleGroups: any[];

  currentStepIndex = 0;

  codeClient: any;

  constructor(private formBuilder: UntypedFormBuilder,
              private loadingService: NgxSpinnerService,
              private userService: UserService,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<VenteArticleGroupsComponent>,) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.numBon = this.data.bonNumBon;
    this.panier = this.data.panier;
    this.listArticleGroups = this.data.listArticleGroups;
    this.currentItemFormData = this.data.currentItemFormData;    

    this._getCodeClient();
    
    this.currentItem = this.data.currentItem;
    this.currentModifyItemFormData = this.data.currentModifyItemFormData;    

    if(this.data.flowType !== undefined && this.data.flowType !== null) {
      this.flowType = this.data.flowType;
    }
    
    if(this.data.typeDevis !== undefined && this.data.typeDevis !== null) {
      this.typeDevis = this.data.typeDevis;
    }

    if(this.data.pageType !== undefined && this.data.pageType !== null) {
      this.pageType = this.data.pageType;
    }

    this.articleGroupForm = this.formBuilder.group({
      PARENT_CAINT: ['', Validators.required],
      PARENT_CDART: ['', Validators.required],
      PARENT_LABEL: [''],
      GROUPS: new UntypedFormArray([])
    });
    
    if(this.flowType === 'new') {
      if(this.currentItemFormData === undefined || this.currentItemFormData == null) {
        this.articleTypeCde = this.data.TYPECDE;
      } else {      
        this.articleTypeCde = this.currentItemFormData.TYPECDE;
      }

      this.listArticleGroups.map((row) => {
        if(row.group_type == '' || row.group_type == null || (this.typeDevis || !this.typeDevis && this.articleTypeCde.indexOf(row.group_type) > -1)) {
          let rowGroup = this.__newGroupArticle(row);
        
          row.group_articles.map((row_article) => {
            let rowArticle = this.__newOptionArticle(rowGroup, row_article);
            let rowGroupOptions = rowGroup.get('GROUP_CHOSEN_OPTIONS') as UntypedFormArray;
    
            rowGroupOptions.push(rowArticle);
          })
    
          this.articleFormGroups.push(rowGroup)
        }
      });      
    } else {
      if(this.currentModifyItemFormData === undefined || this.currentModifyItemFormData == null) {
        this.articleTypeCde = this.data.TYPECDE;
      } else {      
        this.articleTypeCde = this.currentModifyItemFormData.TYPECDE;
      }
      
      this.listArticleGroups.map((row) => {
        if(row.group_type == '' || row.group_type == null || (this.typeDevis || !this.typeDevis && this.articleTypeCde.indexOf(row.group_type) > -1)) {
          let rowGroup = this.__newGroupArticle(row);
        
          row.group_articles.map((row_article) => {
            let rowArticle = this.__newOptionArticle(rowGroup, row_article);
            let rowGroupOptions = rowGroup.get('GROUP_CHOSEN_OPTIONS') as UntypedFormArray;
    
            rowGroupOptions.push(rowArticle);

            if(this.flowType === 'edit') {
              let totalSelected = 0;
              
              rowGroupOptions.controls.map(
                (rowGroup: UntypedFormGroup) => {
                  rowGroup.get('SELECTED').setValue(rowGroup.value.SELECTED);
                  totalSelected = totalSelected + (rowGroup.value.SELECTED?1:0);
                }
              );

              rowGroup.get('CHOSEN_COUNT').setValue(totalSelected);
            }
          })
    
          this.articleFormGroups.push(rowGroup)
        }
      }); 
    }


    this.loadingService.hide();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(e: BeforeUnloadEvent) {
    e.preventDefault();

    const confirmationMessage = '\o/';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
  }

  async _getCodeClient() {
    try {
      this.codeClient = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  get articleFormGroups() {
    return this.articleGroupForm.get('GROUPS') as any;
  }

  currentFormGroup(i: number): any {
    return this.articleFormGroups.at(i) as any;
  }

  singleArticleFormGroup = (i: number, control?: string) => {
    let formGroup = (this.articleGroupForm.get('GROUPS') as UntypedFormArray).at(i) as UntypedFormGroup;
    return control?formGroup.get(control):formGroup;
  }

  __newGroupArticle(item?: any): UntypedFormGroup {
    let countValidators = [Validators.required];

    if(item.group_max != 0) {
      countValidators.push(Validators.min(item.group_min));
      countValidators.push(Validators.max(item.group_max));
    }

    let groupItem = this.formBuilder.group({
      GROUP_ID: ['', Validators.required],
      GROUP_LABEL: [''],

      GROUP_CHOSEN_OPTIONS: new UntypedFormArray([]),

      CHOOSE_MIN: [0],
      CHOOSE_MAX: [0],
      CHOSEN_COUNT: [0, countValidators],
      GROUP_TYPECDE: [''],
      PANIER_POSITION: null
    });

    groupItem.patchValue({
      GROUP_ID: item?item.group_id:'',
      GROUP_LABEL: item?item.group_label:'',

      CHOOSE_MIN: item?item.group_min:0,
      CHOOSE_MAX: item?item.group_max:0,
      CHOSEN_COUNT: 0,
      GROUP_TYPECDE: item?item.group_type:'',
      PANIER_POSITION: null
    });

    return groupItem;
  }

  __newOptionArticle(group: UntypedFormGroup, item?: any): UntypedFormGroup {    
    let optionItem = this.formBuilder.group({
      CDART: ['', Validators.required],
      CAINT: ['', Validators.required],
      CALIB: [''],
      PRIX: [''],
      STOCK_DISPO: [''],
      CAGE_STOCK: [''],
      QTY: [1],
      SELECTED: [],
      PANIER_POSITION: null
    });

    if(item) {
      let quantity = 1, selected = false, position = null;
      if(this.flowType === 'edit') {
        const currentArticle = this.panier.articles.find((_art) => _art.cdart == item.CDART && _art.opt == Math.abs(this.currentItem.opt));

        if(this.currentItem !== undefined && this.currentItem !== null) {
          quantity = this.currentItem.quantite;
        }

        if(this.currentModifyItemFormData !== undefined && this.currentModifyItemFormData !== null) {
          quantity = this.currentModifyItemFormData.QTY;
        }

        if(currentArticle !== undefined && currentArticle !== null) {
          selected = true;
          position = currentArticle.idlig;
        }

        if(position !== undefined && position !== null && position !== '') {
          group.get('PANIER_POSITION').setValue(position);
        }
      }

      optionItem.patchValue({
        CDART: item.CDART,
        CAINT: item.CAINT,
        CALIB: item.CALIB3,
        PRIX: item.calculated_price,
        STOCK_DISPO: item.STOCKDISPO,
        CAGE_STOCK: item.CAGESTOCK,
        QTY: quantity,
        SELECTED: selected,
        PANIER_POSITION: position
      });
    }

    return optionItem;
  }

  articleGroupFormSubmitter() {
    this.loadingService.show();
    this.dialogRef.close({ status: 'success', data: this.articleGroupForm.getRawValue() });
  }

  getCdartWithPlacehold(CDART: string): string {
    return (Constants.CONFORAMA_CDART_PLACEHOLDER).replace("{{CDART_PLACEHOLDER}}", CDART);
  }

  getImageURL(CDART: string): string {
    if(this.codeClient && this.codeClient.toLowerCase() === 'conforama') {
      // To generate Conforama Image URL Algorithm
      let concatenated = "";
      const length = Constants.CONFORMA_IMAGE_SEGMENT_LENGTH;
      for(let i=0;i < length;i++) {
        if(i < (length - 1)) {
          if(concatenated !== '') {
            concatenated += "/"  
          }

          if(CDART[i] !== undefined) {
            concatenated += (CDART[i].padEnd(length - i, '0'));
          } else {
            concatenated += ('0'.padEnd(length - i, '0')); // Prefixing Zero if the CDART length is small as mentioned in CAP-1056
          }

        }
      }

      const URL = Constants.CONFORAMA_IMAGE_URL + concatenated + '/' + this.getCdartWithPlacehold(CDART) + Constants.DEFAULT_IMAGE_EXTENSION;
      return URL;
    }

    return Constants.BASE_IMAGE_URL+CDART+Constants.DEFAULT_IMAGE_EXTENSION;
  }

  optionChanged(i: number, CAINT: number) {
    const currentForm = this.articleFormGroups.at(i) as UntypedFormGroup;
    const currentFormOptions = currentForm.get('GROUP_CHOSEN_OPTIONS') as UntypedFormArray;

    const minSelect = currentForm.get('CHOOSE_MIN').value;
    const maxSelect = currentForm.get('CHOOSE_MAX').value;

    let totalSelected = 0;
    currentFormOptions.controls.map(
      (rowGroup: UntypedFormGroup) => {

        rowGroup.get('SELECTED').setValue(rowGroup.value.SELECTED);
        totalSelected = totalSelected + (rowGroup.value.SELECTED?1:0);        

        if(maxSelect == 1 && rowGroup.get('CAINT').value !== CAINT && rowGroup.get('SELECTED').value) {
          rowGroup.value.SELECTED = false;
          rowGroup.get('SELECTED').setValue(false);
          totalSelected = totalSelected - 1;
        }
      }
    );
    
    currentForm.get('CHOSEN_COUNT').setValue(totalSelected);
  }

  previousStep(i: number) {
    this.currentStepIndex = i - 1;
  }

  stepFormSubmitter(index: number) {
    const currentForm = this.articleFormGroups.at(index);

    if((this.articleFormGroups.controls.length - 1) == index && currentForm.valid) {
      this.articleGroupFormSubmitter();
    } else {
      if(this.flowType === 'new') {
        this.currentStepIndex = index + 1;
      }
    }
  }
}
