<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{'kbonventeautremag' | translate}}
            </h1>
        </div>
    </div>    

    <div class="bloc_container">
        <div class="row">
            <div class="col-12 col-md-12" *ngIf="!isMobile">
                <mat-form-field class="mt-3" floatLabel="always">
                    <mat-label></mat-label>
                    <input matInput [(ngModel)]="searchMag" (ngModelChange)="filterMagasinList($event)"
                            [placeholder]="'kbondesiredstore' | translate" cdkFocusInitial />
                </mat-form-field>
            </div>

            <div class="col-12 no-padding">
                <mat-list *ngIf="(filteredMagasins?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="filteredMagasins?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">                        
                        <p matLine class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                    </mat-list-item>
                </mat-list>

                <mat-nav-list *ngIf="(filteredMagasins?.length != 0 && !showLoader)">
                    <a mat-list-item href="javascript:void(0);" *ngFor="let magasin of filteredMagasins">
                        <div matLine class="align-items-center d-flex flex-row justify-content-between">
                            <h2 class="mobile-list-title m-0">
                                {{ magasin.CMRAISOC }}
                            </h2>
                            
                            <button type="button" mat-mini-fab color="primary" (click)="createBonDeForAutreMag(magasin)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </a>
                </mat-nav-list>

            </div>
        </div>
    </div>
</div>
