import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DefaultTreeviewI18n, TreeviewSelection } from 'ngx-treeview2';

@Injectable()
export class TreeviewTranslation extends DefaultTreeviewI18n {
  constructor(private translateService: TranslateService) {
    super();
  }

  getText(selection: TreeviewSelection): string {
    if (selection.uncheckedItems.length === 0) {
      if (selection.checkedItems.length > 0) {
        return this.translateService.instant('ktall');
      } else {
        return '';
      }
    }

    switch (selection.checkedItems.length) {
      case 0:        
        return this.translateService.instant('ktseloption');
      case 1:
        return selection.checkedItems[0].text;
      default:
        return this.translateService.instant('ktoptsel', { items_length: selection.checkedItems.length });    
    }
  }

  getAllCheckboxText(): string {    
    return this.translateService.instant('ktall');
  }

  getFilterPlaceholder(): string {    
    return this.translateService.instant('ktfilter');
  }

  getFilterNoItemsFoundText(): string {    
    return this.translateService.instant('ktnoitems'); // No item (s) found
  }

  getTooltipCollapseExpandText(isCollapse: boolean): string {    
    return isCollapse
      ? this.translateService.instant('ktexpand')
      : this.translateService.instant('ktcol');    
  }
}