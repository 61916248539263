import { Component, OnInit, Inject, ViewChild, ElementRef  } from '@angular/core';
import { BonVenteService } from '../../../providers/api/bonventeService';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import * as moment from 'moment';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-devis-filter',
  templateUrl: './devis-filter.component.html',
  styleUrls: ['./devis-filter.component.scss']
})
export class DevisFilterComponent implements OnInit {

  @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;

  // champs de recherche
  searchData: any = null;
  user: any;
  USERCMINT: any;
  showLoader = true;
  searchForm: UntypedFormGroup;
  searched: boolean = false;

  statusList: any;

  dateAct: string = 'equal';
  minDateAct: any = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DevisFilterComponent>,
    public bonVenteService: BonVenteService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService
    ) { }

  ngOnInit(): void {
    this.USERCMINT = this.data.USERCMINT;
    this.dateAct = this.data.dateAct;
    this.statusList = this.data.statusList;

      this.searchForm = this.formBuilder.group({
        IDSOC: [Constants.IDSOC],
        CMINT: this.USERCMINT,
        NUMBON: [''],
        NOM: [''],
        PRENOM: [''],
        CODPOS: [''],
        VILLE: [''],
        TEL_PORT: [''],
        TOTAL_ENTRE_FROM: [''],
        TOTAL_ENTRE_TO: [''],
        DATE: [{ value: '', disabled: true }],
        DATE_END: [{ value: '', disabled: true }],
        USERCON: [false],
        ETAT: ['0']
    });

    const oldFormData: any = this.storageService.getItem('devisFormData');

    if(oldFormData !== undefined && oldFormData !== null) {
      this.searchForm.patchValue({
        IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
        CMINT: oldFormData.CMINT !== null?oldFormData.CMINT:this.USERCMINT,
        NUMBON: oldFormData.NUMBON !== null?oldFormData.NUMBON:'',
        NOM: oldFormData.NOM !== null?oldFormData.NOM:'',
        PRENOM: oldFormData.PRENOM !== null?oldFormData.PRENOM:'',
        CODPOS: oldFormData.CODPOS !== null?oldFormData.CODPOS:'',
        VILLE: oldFormData.VILLE !== null?oldFormData.VILLE:'',
        TEL_PORT: oldFormData.TEL_PORT !== null?oldFormData.TEL_PORT:'',
        TOTAL_ENTRE_FROM: oldFormData.TOTAL_ENTRE_FROM !== null?oldFormData.TOTAL_ENTRE_FROM:'',
        TOTAL_ENTRE_TO: oldFormData.TOTAL_ENTRE_TO !== null?oldFormData.TOTAL_ENTRE_TO:'',
        DATE: oldFormData.DATE !== null?oldFormData.DATE:'',
        DATE_END: oldFormData.DATE_END !== null?oldFormData.DATE_END:'',
        USERCON: oldFormData.USERCON !== null?oldFormData.USERCON:false,
        ETAT: oldFormData.ETAT !== null?oldFormData.ETAT:'0',
      });
    }
}

ngAfterViewInit() {
  setTimeout(() => {
    this.filterFirst.nativeElement.select();
    this.filterFirst.nativeElement.focus();
    this.filterFirst.nativeElement.setAttribute('inputmode', 'numeric');
  }, 300);
}

headerSearchFormSubmitter () {
  this.userService.headerSearchInputValue.next(undefined);
  this.dialogRef.close({
    status: 'success',
    form_data: this.searchForm.getRawValue(),
    dateAct: this.dateAct
  });
}

resetForm() {
  this.searchForm.reset();
  this.storageService.removeItem('devisFormData');

  this.searchForm.patchValue({
    IDSOC: Constants.IDSOC,
    CMINT: this.USERCMINT,
    USERCON: false,
  });

  this.userService.headerSearchInputValue.next(undefined);
}

onDateChanged() {
  this.minDateAct = this.searchStartDate.value?this.searchStartDate.value:null;
  if(this.minDateAct !== null) {
    this.searchEndDate.setValue(this.minDateAct);
  }
}

changeDateAct(type: string = 'equal') {
  this.dateAct = type;
}

_clearDate = (form_field: string) => {
  this.searchForm.get(form_field).setValue('');
  if(form_field == 'DATE') {
    this.minDateAct = null;
  }
}

get searchStartDate() {
  return this.searchForm.get('DATE');
}

get searchEndDate() {
  return this.searchForm.get('DATE_END');
}

}
