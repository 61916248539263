
<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium">{{'kclclearform' | translate}}</div>
            </button>
        </div>
    </div>
</div>

<form [formGroup]="searchForm" (ngSubmit)="headerSearchFormSubmitter()" >    
    <div class="bloc_container padding_15 bg_light">
        <div class="row no-gutters">
            <div class="col-12 ">

                <div class="row no-gutters">
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="NUMBON" [placeholder]="'kdevbonl' | translate" class="text-uppercase" #filterFirst>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="NOM"  [placeholder]="'kpname' | translate" class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="PRENOM" [placeholder]="'kuprenom' | translate" class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="TEL_PORT" [placeholder]="'kcltel' | translate" class="text-uppercase">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters">                        
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput type="number" formControlName="CODPOS"
                                [placeholder]="'kclcp' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="VILLE"
                                [placeholder]="'kclcity' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div [class]="dateAct === 'between'?'col-6':'col-12'">
                        <mat-form-field floatLabel="always" class="date">
                            <input matInput formControlName="DATE" (dateChange)="onDateChanged()" [matDatepicker]="cdeDate"
                                [placeholder]="'kbondate' | translate"
                                class="text-uppercase">
                            <mat-datepicker-toggle matSuffix [for]="cdeDate">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #cdeDate [disabled]="false"></mat-datepicker>
                            <button mat-icon-button type="button" *ngIf="searchStartDate.value" (click)="_clearDate('DATE')" matSuffix ><mat-icon>close</mat-icon></button>
                            <button matSuffix type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                                <mat-icon class="d-flex justify-content-center align-items-center">
                                    <span style="line-height: initial;" *ngIf="dateAct === 'equal'">=</span>
                                    <span style="line-height: initial;" *ngIf="dateAct === 'greater_equal'">>=</span>
                                    <span style="line-height: initial;" *ngIf="dateAct === 'less_equal'"><=</span>
                                    <span style="line-height: initial;" *ngIf="dateAct === 'between'"><></span>
                                </mat-icon>
                            </button>                                
                        </mat-form-field>
                        <mat-menu #menu="matMenu" [hasBackdrop]="false">
                            <button mat-menu-item (click)="changeDateAct('equal')">{{'kbondategale' | translate}}</button>
                            <button mat-menu-item (click)="changeDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                            <button mat-menu-item (click)="changeDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                            <button mat-menu-item (click)="changeDateAct('between')">{{'kbondatentre' | translate}}</button>
                        </mat-menu>
                    </div>
                    <div class="col-6 pl-3" *ngIf="dateAct === 'between'">
                        <mat-form-field floatLabel="always">
                            <input matInput formControlName="DATE_END" [min]="minDateAct" [matDatepicker]="cdeEndDate"
                                [placeholder]="'kbonand' | translate"
                                class="text-uppercase">
                                
                            <mat-datepicker-toggle matSuffix [for]="cdeEndDate">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #cdeEndDate [disabled]="false"></mat-datepicker>
                            <button mat-icon-button type="button" *ngIf="searchEndDate.value" (click)="_clearDate('DATE_END')" matSuffix ><mat-icon>close</mat-icon></button>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-6">
                        <mat-form-field>
                            <input matInput inputmode="decimal" formControlName="TOTAL_ENTRE_FROM"
                                [placeholder]="'kbontotbetn' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-6 pl-4">
                        <mat-form-field>
                            <input matInput inputmode="decimal" formControlName="TOTAL_ENTRE_TO"
                                [placeholder]="'kbonand' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-checkbox formControlName="USERCON"> {{'kbonuser' | translate}} </mat-checkbox>
                    </div>
                </div>

                <div class="row no-gutters mt-3">
                    <div class="col-12">
                        <div class="radio-container">
                            <mat-label>{{ "kartstate" | translate }}</mat-label>
                            <mat-radio-group formControlName="ETAT" aria-label="Select an option">
                                <mat-radio-button *ngFor="let item of statusList" [value]="item.id">
                                    <span [translate]="item.label" class="d-block" [class.devis_valide]="item.id == 2" [class.devis_en_cours]="item.id == 1"></span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white mt-2 mt-md-0">
            <div class="col-12 text-center">
                <button type="submit" class="bt_valid" mat-button>
                    <i class="material-icons">search</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'kpsearch' | translate }}
                    </span>
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kucancel" | translate }}
                    </span>                        
                </button>
            </div>
        </div>
    </div>
</form>