<div class="modal-container" id="couche_prix_article">
    <form [formGroup]="remiseForm" (ngSubmit)="remiseFormSubmitter()">
    <mat-card>
        <mat-card-header *ngIf="displayType === 'dialog'">
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>{{ remiseType === 2?'kbondislen':'kbondisonline' | translate }}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content [class.mt-4]="displayType !== 'dialog'">
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <p class="py-2 text-right mb-0">{{'kboncaisseprix' | translate}}: {{ totalPrice | number: '0.2' }}</p>
                    </div>
                </div>

                <div class="col-12 no-padding table-scroller">
                    <div *ngIf="(!filteredRemises || filteredRemises?.length == 0) || showLoader" class="w-100 text-center">
                        <p *ngIf="(!filteredRemises || filteredRemises?.length == 0)"></p>  
                        <mat-icon *ngIf="showLoader" class="synching">sync</mat-icon>
                    </div>

                    <mat-selection-list formControlName="remise" *ngIf="filteredRemises && filteredRemises?.length != 0 && !showLoader" class="w-100" [multiple]="true">
                        <mat-list-option *ngFor="let remise of filteredRemises" (click)="changeRemise(remise)" [value]="remise.REMMCODE">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{remise.REMMLIBL}} - {{ remise.REMMTAUX | number: '0.2' }}% </span>
                                <mat-icon>check</mat-icon>
                            </div>                          
                        </mat-list-option>
                    </mat-selection-list>                    
                </div>
            </div>
        </mat-card-content>
        <mat-divider *ngIf="displayType === 'dialog'"></mat-divider>

        <mat-card-actions class="text-center" *ngIf="displayType === 'dialog'">
            <button mat-button type="submit" class="bt_send">
                <i class="material-icons">done</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kuok' | translate}}
                </span>
            </button>
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
    </form>
</div>