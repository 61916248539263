<div class="modal-container w-100" id="couche_prix_article">
    <form >
        <mat-card>
            <mat-card-header  *ngIf="!isMobile">
                <mat-card-title class="text-uppercase text-center ">
                    <div class="row">
                        <div class="col-11 text-left">
                            <h4 class="modal_title">{{ "kdetaile2atraiter" | translate }}</h4>
                        </div>
                        <div class="col-1 text-right no-padding">
                            <button mat-button [mat-dialog-close] class="bt_close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content [class.bnone]="isMobile">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-scroller">
                            <div [class.mt-2]="!isMobile">
                                <table *ngIf="!isMobile" datatable class="row-border table hover table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                                    <thead>
                                    <tr>
                                        <th width="14%" class="nom">{{ 'kbonref' | translate }}</th>
                                        <th class="cp">{{ 'klibelle' | translate }}</th>
                                        <th class="nom">{{ 'kqtecde' | translate }}</th>
                                        <th class="nom">{{ 'kqtereserve' | translate }}</th>
                                        <th class="nom">{{ 'kstock' | translate }}</th>
                                        <th class="tel">{{ 'ktype' | translate }}</th>
                                        <th class="tel">{{ 'kfournisseur' | translate }}</th>
                                    </tr>
                                    </thead>
                                    <tbody *ngIf="listArticles?.length != 0 && !showLoader">
                                    <tr *ngFor="let bonArticle of listArticles">
                                        <td class="numero" nowrap>{{ bonArticle.NUMBON }}</td>
                                        <td class="numero" nowrap>{{ bonArticle.LIBEL }}</td>
                                        <td class="numero" nowrap>{{ bonArticle.QTECDE }}</td>
                                        <td class="numero" nowrap>{{ bonArticle.QTERESERVE }}</td>
                                        <td class="numero" nowrap>{{ bonArticle.STOCKBLOC }}</td>
                                        <td class="numero" nowrap>{{ bonArticle.TYPECDE }}</td>
                                        <td class="numero" nowrap>{{ bonArticle.FOURN }}</td>
                                    </tr>
                                    </tbody>
                                    <tbody *ngIf="listArticles?.length == 0 || showLoader">
                                    <tr>
                                        <td colspan="7" *ngIf="listArticles?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                                        <td colspan="7" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <mat-list *ngIf="isMobile && (listArticles?.length == 0 || showLoader)">
                                    <mat-list-item *ngIf="listArticles?.length == 0 && !showLoader">
                                        <p matLine class="text-center">
                                            {{ "kpnoresult" | translate  }}
                                        </p>
                                    </mat-list-item>

                                    <mat-list-item *ngIf="showLoader">
                                        <p matLine class="text-center">
                                            <mat-icon class="synching">sync</mat-icon>
                                        </p>
                                    </mat-list-item>
                                </mat-list>
                                <mat-list *ngIf="isMobile && (listArticles?.length != 0 && !showLoader)">
                                    <mat-list-item *ngFor="let bonArticle of listArticles" class="pt-2 pb-2">
                                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                            <h2 class="m-0">{{ bonArticle.NUMBON }}</h2>
                                            <h2 class="m-0">{{ bonArticle.LIBEL }}</h2>
                                        </div>
                                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                            <h2 matLine class="mobile-list-title m-0">{{ bonArticle.TYPECDE }}</h2>
                                            <h2 matLine class="mobile-list-title text-right m-0">{{ bonArticle.FOURN }}</h2>
                                        </div>
                                        <div matLine class="align-items-center d-flex flex-row justify-content-end">
                                            <p class="text-right m-0">
                                                <span class="badge badge-secondary">{{ bonArticle.QTECDE }}</span>
                                                <span class="badge badge-secondary">{{ bonArticle.QTERESERVE }}</span>
                                                <span class="badge badge-secondary">{{ bonArticle.STOCKBLOC }}</span>
                                            </p>
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>

            <mat-card-actions class="text-right" *ngIf="!isMobile">
                <div class="row no-gutters">
                    <div class="col-md-4 text-left">
                        <button mat-button class="bt_send">
                            <i class="material-icons">topic</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'Calculer les pre-affectations' | translate}}
                            </span>
                        </button>
                    </div>
                    <div class="col-md-8 pr-2 text-right">
                        <button mat-button class="bt_send m-0">
                            <i class="material-icons">check</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'kuok' | translate}}
                            </span>
                        </button>
                        <button mat-button [mat-dialog-close] class="bt_send m-0 ml-2">
                            <i class="material-icons">close</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'ktcol' | translate}}
                            </span>
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
