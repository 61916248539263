<div [class.modal-container]="displayType === 'dialog'" class="pt-3" id="detail_magasin_article">
    <mat-card [class.no-padding]="isMobile">
        <mat-card-header *ngIf="displayType === 'dialog'">
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>
                            kartretstore
                        </h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row" [class.no-gutters]="isMobile">
                <div class="col-12 no-padding table-scroller">
                    <table datatable [dtOptions]="dtOptions" id="articleLogistiqueMagasin" [dtTrigger]="dtTrigger" class="row-border hover">
                        <thead>
                            <tr>
                                <th class="text-center" translate >kartliv</th>
                                <th class="text-center" translate >kartlivconf</th>
                                <th class="text-center" translate >kartgross</th>
                                <th class="text-center" translate >kartnet</th>
                                <th class="text-center" translate >kartncmd</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listCodeMagasin?.length != 0 && !showLoader">
                            <tr *ngFor="let magasin of listCodeMagasin">
                                <td class="text-center">{{ magasin.DLIV | date: 'dd.MM.yyyy' }}</td>
                                <td class="text-center">{{ magasin.DCONFIRME | date: 'dd.MM.yyyy' }}</td>
                                <td class="text-center">{{ magasin.QTE | number  }}</td>
                                <td class="text-center">{{ magasin.QTENET | number  }}</td>
                                <td class="text-center">{{ magasin.CDECOM }}</td>
                            </tr>                            
                        </tbody>
                        <tbody *ngIf="listCodeMagasin?.length == 0 || showLoader">
                            <tr>
                                <td colspan="5" *ngIf="listCodeMagasin?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                <td colspan="5" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </mat-card-content>
        <mat-divider *ngIf="displayType === 'dialog'"></mat-divider>

        <mat-card-actions class="text-center" *ngIf="displayType === 'dialog'">
            <button mat-button [mat-dialog-close] class="bt_send">                
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
</div>
