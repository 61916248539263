import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, Injectable, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
declare var $: any;




//  material design
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyRadioModule as MatRadioModule, MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/legacy-radio';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {DateAdapter, MatNativeDateModule} from '@angular/material/core';
import {MatTreeModule} from '@angular/material/tree';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {MatStepperModule} from '@angular/material/stepper';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SideNavMenuModule } from '../side-nav-menu/module';

//  modules externes
import { DataTablesModule } from 'angular-datatables';
import { LoadingBarModule } from '@ngx-loading-bar/core';

//  pages du sites ( composants )
// LOGIN
import { LoginComponent } from '../pages/login/login.component';

// CASHORDER
// BON DE VENTE
import { BonDeVenteComponent } from '../pages/bon-de-vente/bon-de-vente.component';
import { BonDeVenteFilterComponent } from '../pages/bon-de-vente/bon-de-vente-filter/bon-de-vente-filter.component'

// DEVIS
import { DevisComponent } from '../pages/devis/devis.component';

// CLIENT
import { ClientComponent } from '../pages/client/client.component';
import { ClientDetailComponent } from '../pages/client-detail/client-detail.component';

// ARTICLE
import { ArticleComponent } from '../pages/article/article.component';
import { ArticleDetailComponent } from '../pages/article-detail/article-detail.component';
import { ArticleLogistiqueComponent } from '../pages/article-detail/article-logistique/article-logistique.component';
import { ArticleStockComponent } from '../pages/article-detail/article-stock/article-stock.component';
import { ArticleDescriptionComponent } from '../pages/article-detail/article-description/article-description.component';
import { ArticleDescriptionPhotoComponent } from '../pages/article-detail/article-description/article-description-photo/article-description-photo.component';
import { ArticleDescriptionCodeComponent } from '../pages/article-detail/article-description/article-description-code/article-description-code.component';
import { ArticleLogistiqueStockAutreComponent } from '../pages/article-detail/article-logistique/article-logistique-stock-autre/article-logistique-stock-autre.component';
import { ArticleLogistiqueCouchePrixComponent } from '../pages/article-detail/article-logistique/article-logistique-couche-prix/article-logistique-couche-prix.component';
import { ArticleLogistiqueMagasinComponent } from '../pages/article-detail/article-logistique/article-logistique-magasin/article-logistique-magasin.component';
import { ArticleLogistiqueEntrepotComponent } from '../pages/article-detail/article-logistique/article-logistique-entrepot/article-logistique-entrepot.component';

import { MagasinComplementInfoComponent } from '../pages/magasin-complement-info/magasin-complement-info.component';

// COMPONENTS CASHORDER
import { HeaderComponent } from '../components/header/header.component';
import { PromptComponent } from '../components/prompt/prompt.component';
import { ConfirmComponent } from '../components/confirm/confirm.component';

// SERVICES CASHORDER
import { Global } from './../providers/api/global';

import { AuthGuard } from '../providers/router/auth.guard';
import { UserService } from '../providers/api/userService';
import { LoginService } from '../providers/api/loginService';
import { DialogsService } from '../providers/api/dialogService';
import { ParamService } from '../providers/api/paramsService';
import { PrinterService } from '../providers/api/printerService';
import { UtilService } from '../providers/api/utilService';
import { RemiseService } from '../providers/api/remiseService';
import { MotifService } from '../providers/api/motifService';
import { ClientService } from '../providers/api/clientService';
import { CiviliteService } from '../providers/api/civiliteService';
import { StockService } from '../providers/api/stockService';
import { DelayFocusDirective } from '../providers/delay-focus';

import { PostalCodesComponent } from 'pages/table-base/postal-codes/postal-codes.component';
import { TableBaseComponent } from 'pages/table-base/table-base.component';
import { EditPostalCodeComponent } from 'pages/table-base/edit-postal-code/edit-postal-code.component';
import { AddPostalCodeComponent } from 'pages/table-base/add-postal-code/add-postal-code.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NiveauDeSecuriteComponent } from 'pages/table-base/niveau-de-securite/niveau-de-securite.component';
import { EditNiveauDeSecuriteComponent } from 'pages/table-base/edit-niveau-de-securite/edit-niveau-de-securite.component';
import { AddNiveauDeSecuriteComponent } from 'pages/table-base/add-niveau-de-securite/add-niveau-de-securite.component';
import { NiveauSecuriteService } from 'providers/api/niveauSecuriteService';
import { MotifsComponent } from 'pages/table-base/motifs/motifs.component';
import { AddMotifComponent } from 'pages/table-base/add-motif/add-motif.component';
import { EditMotifComponent } from 'pages/table-base/edit-motif/edit-motif.component';
import { UsersComponent } from 'pages/users/users.component';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { AddUserComponent } from 'pages/users/add-user/add-user.component';
import { EditUserComponent } from 'pages/users/edit-user/edit-user.component';

import { TreeviewModule } from 'ngx-treeview2';
import { MerchandiseDeVenteComponent } from 'pages/users/merchandise-de-vente/merchandise-de-vente.component';
import { TranslatePaginatorIntl } from 'providers/french-paginator-intl';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'ngx-moment';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { GestionDesDroitsComponent } from 'pages/gestion-des-droits/gestion-des-droits.component';
import { ResetPasswordComponent } from 'pages/reset-password/reset-password.component';
import { Constants } from 'providers/constants';
import { Observable } from 'rxjs';
import { UpdateMotifBlocageComponent } from 'pages/article-detail/article-stock/update-motif-blocage/update-motif-blocage.component';
import { StockEntreprotsComponent } from 'pages/article-detail/article-logistique/stock-entreprots/stock-entreprots.component';
import { UpdateBlocageExterneComponent } from 'pages/article-detail/article-stock/update-blocage-externe/update-blocage-externe.component';
import { UpdateAffectQuantityComponent } from 'pages/article-detail/article-stock/update-affect-quantity/update-affect-quantity.component';
import { UpdateStockEmplacementComponent } from 'pages/article-detail/article-stock/update-stock-emplacement/update-stock-emplacement.component';
import { UsersFilterComponent } from 'pages/users/users-filter/users-filter.component';

import { PostalCodeFilterComponent } from 'pages/table-base/postal-codes/postal-code-filter/postal-code-filter.component';
import { NiveauFilterComponent } from 'pages/table-base/niveau-de-securite/niveau-filter/niveau-filter.component';
import { MotifsFilterComponent } from 'pages/table-base/motifs/motifs-filter/motifs-filter.component';
import { GestionFilterComponent } from 'pages/gestion-des-droits/gestion-filter/gestion-filter.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DefaultImage } from 'providers/default-image';
import { ImageDefaultPipe } from 'providers/image-pipe';
import { SearchPostalCodeComponent } from 'pages/client-detail/search-postal-code/search-postal-code.component';
import { ClientFilterComponent } from 'pages/client/client-filter/client-filter.component';
import { BlocageDeblocageStockComponent } from 'pages/blocage-deblocage-stock/blocage-deblocage-stock.component';
import { ArticleFilterComponent } from 'pages/article/article-filter/article-filter.component';
import { AuthorizationCheckComponent } from 'components/authorization-check/authorization-check.component';
import { CreateBonDeVenteComponent } from 'pages/create-bon-de-vente/create-bon-de-vente.component';
import { ClientBondeComponent } from 'pages/create-bon-de-vente/client-bonde/client-bonde.component';
import { FideliteBondeComponent } from 'pages/create-bon-de-vente/fidelite-bonde/fidelite-bonde.component';
import { InfoLivraisonBondeComponent } from 'pages/create-bon-de-vente/info-livraison-bonde/info-livraison-bonde.component';
import { LivraisonDetailsComponent } from 'pages/create-bon-de-vente/info-livraison-bonde/livraison-details/livraison-details.component';
import { InfoFacturationBondeComponent } from 'pages/create-bon-de-vente/info-facturation-bonde/info-facturation-bonde.component';
import { InfoComplementariesComponent } from 'pages/create-bon-de-vente/info-complementaries/info-complementaries.component';
import { ModifyArticleDetailComponent } from 'pages/create-bon-de-vente/info-complementaries/modify-article-detail/modify-article-detail.component';
import { RemiseListComponent } from 'pages/create-bon-de-vente/remise-list/remise-list.component';
import { ForcageBondeComponent } from 'pages/create-bon-de-vente/forcage-bonde/forcage-bonde.component';
import { RemiseDetailComponent } from 'pages/create-bon-de-vente/remise-detail/remise-detail.component';
import { ModifyBonDeVenteComponent } from 'pages/modify-bon-de-vente/modify-bon-de-vente.component';
import { NumeroCourtComponent } from 'pages/create-bon-de-vente/numero-court/numero-court.component';
import { MagComplementService } from 'providers/api/magComplementService';
import { ListTransporteurComponent } from 'pages/create-bon-de-vente/info-livraison-bonde/list-transporteur/list-transporteur.component';
import { DetailTransporteurComponent } from 'pages/create-bon-de-vente/info-livraison-bonde/detail-transporteur/detail-transporteur.component';
import { SearchArticleComponent } from 'pages/create-bon-de-vente/search-article/search-article.component';
import { HistoriqueBondeComponent } from 'pages/detail-bon-de-vente/historique-bonde/historique-bonde.component';
import { EmporteeDiffereComponent } from 'pages/create-bon-de-vente/emportee-differe/emportee-differe.component';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { MotifForcePrixComponent } from 'pages/create-bon-de-vente/motif-force-prix/motif-force-prix.component';
import { BlocageCustomMotifComponent } from 'pages/article-detail/article-stock/update-motif-blocage/blocage-custom-motif/blocage-custom-motif.component';
import { IMaskModule } from 'angular-imask';
import { CreateClientComponent } from 'pages/client/create-client/create-client.component';
import { map } from 'rxjs/operators';
import { SearchClientComponent } from 'pages/create-bon-de-vente/search-client/search-client.component';
import { AutreMagasinComponent } from 'pages/create-bon-de-vente/autre-magasin/autre-magasin.component';
import { BonDeCommandeComponent } from 'pages/bon-de-commande/bon-de-commande.component';
import { AlerteClientComponent } from 'pages/bon-de-commande/alerte-client/alerte-client.component';
import { TicketsComponent } from 'pages/bon-de-commande/tickets/tickets.component';
import { DateDeRelanceComponent } from 'pages/bon-de-commande/date-de-relance/date-de-relance.component';
import { ModifyBonDeCommandeComponent } from 'pages/modify-bon-de-commande/modify-bon-de-commande.component';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { BonVenteService } from 'providers/api/bonventeService';
import { ModifierLeTypeDeLaCommandeComponent } from 'pages/bon-de-commande/modifier-le-type-de-la-commande/modifier-le-type-de-la-commande.component';
import { ColorsListComponent } from 'pages/bon-de-commande/colors-list/colors-list.component';
import { BonDeCommadeFilterComponent } from 'pages/bon-de-commande/bon-de-commade-filter/bon-de-commade-filter.component';
import { ModifyLineItemChoixComponent } from 'pages/bon-de-commande/modify-line-item-choix/modify-line-item-choix.component';
registerLocaleData(localeFr);
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import { FacturationAcompteComponent } from 'pages/create-bon-de-vente/facturation-acompte/facturation-acompte.component';
import { VenteArticleGroupsComponent } from 'pages/create-bon-de-vente/vente-article-groups/vente-article-groups.component';
import { CreateDevisComponent } from 'pages/create-devis/create-devis.component';
import { ValiditeLimitComponent } from 'pages/create-devis/validite-limit/validite-limit.component';
import { ModifyDevisComponent } from 'pages/modify-devis/modify-devis.component';
import { DeactivatorGuard } from 'providers/router/deactivator.guard';
import { DevisFilterComponent } from 'pages/devis/devis-filter/devis-filter.component';
import { LoginGuard } from 'providers/router/login.guard';
import { ReportingComponent } from 'pages/reporting/reporting.component';
import { CalculateQuantityComponent } from 'pages/create-bon-de-vente/calculate-quantity/calculate-quantity.component';
import { FilterReportComponent } from 'pages/reporting/filter-report/filter-report.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgSelectModule } from '@ng-select/ng-select';
import { User } from 'models/users';
import { TrackDeliveryComponent } from 'pages/create-bon-de-vente/info-livraison-bonde/track-delivery/track-delivery.component';
import { SafePipe } from 'providers/safe-pipe';
import { PhoneNumberPipe } from 'providers/phoneNumber-pipe';
import { CustomDateAdapter } from 'providers/customDateAdapter';
import { CapDecimalPipe } from 'providers/customNumber-pipe';
import {A11yModule} from '@angular/cdk/a11y';
import { ScrollToTopComponent } from 'components/scroll-to-top/scroll-to-top.component';
import * as Hammer from 'hammerjs';
import {
HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} 
from '@angular/platform-browser';
import { DateDePromesseComponent } from 'pages/create-bon-de-vente/date-de-promesse/date-de-promesse.component';
import { PdfViewerComponent } from 'pages/pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RelanceConfirmationComponent } from 'pages/bon-de-commande/relance-confirmation/relance-confirmation.component';
import { ModifyRetraitMarchandiseComponent } from 'pages/modify-retrait-marchandise/modify-retrait-marchandise.component';
import { ActionReserveComponent } from 'pages/bon-de-commande/action-reserve/action-reserve.component';
import { ImprimerConfirmationComponent } from 'pages/imprimer-confirmation/imprimer-confirmation.component';
import {SearchGestionDesE2Component} from '../pages/search-gestion-des-e2/search-gestion-des-e2.component';

import { DateTimeComponent } from 'pages/create-bon-de-vente/date-time/date-time.component';
import {ModifierGestionDesE2Component} from '../pages/modifier-gestion-des-e2/modifier-gestion-des-e2.component';
import {ReservationComponent} from '../pages/modifier-gestion-des-e2/reservation/reservation.component';
import {DesaffecterE2Component} from '../pages/search-gestion-des-e2/desaffecter-e2/desaffecter-e2.component';
import {GestionPreAffectationComponent} from '../pages/gestion-pre-affectation/gestion-pre-affectation.component';
import {CommandeLieesComponent} from '../pages/modify-bon-de-commande/commande-liees/commande-liees.component';
import {InfoCarrierComponent} from '../pages/create-bon-de-vente/info-livraison-bonde/info-carrier/info-carrier.component';
import {ChangeStoreComponent} from '../pages/modify-bon-de-commande/change-store/change-store.component';
import { AddressConfirmationComponent } from '../pages/client-detail/address-confirmation/address-confirmation.component';
import {InlineSpinnerComponent} from '../components/inline-spinner/inline-spinner.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {BottomSheetComponent} from '../components/bottom-sheet/bottom-sheet.component';
import {HistoryService} from '../providers/api/historyService';
import {DateTimePickerComponent} from '../components/date-time-picker/date-time-picker.component';
import {GestionDesE2FilterComponent} from '../pages/bon-de-commande/gestion-des-e2-filter/gestion-des-e2-filter.component';
import {NonServiceArticlesListComponent} from '../pages/create-bon-de-vente/non-service-articles-list/non-service-articles-list.component';
import { JsonService } from '../providers/api/json.service';
import { ReportingAttendeComponent } from 'pages/reporting-attende/reporting-attende.component';
import { ClientHistoriqueComponent } from 'components/client-historique/client-historique.component';
import { StorageService } from 'providers/api/storageService';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from 'init/keyclock-init.factory';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pinch': { enable: false },
    'rotate': { enable: false },
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL }
  };
}

export class CustomLoader implements TranslateLoader {

    constructor(private http: HttpClient, private storageService: StorageService) {}
  
    public getTranslation(lang: string): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};

        params['lang'] = lang;
        params['IDSOC'] = Constants.IDSOC;

        let userData: User;
        const result: any = this.storageService.getItem('CURRENT_LANG_USER');
        if(result !== null && typeof result !== 'undefined') {
            userData = result;
        }

        if(userData !== undefined && userData != null) {
            params['IDMAG'] = userData.CMINT;
            params['CIINT'] = userData.CIINT;
        }

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_MULTI_LANG_URL+'getLangText', options);
    }
}


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, Constants.API_MULTI_LANG_URL+'getLangText?lang=', '');
}

//  MaterialModule
@NgModule({
    exports: [
        MatButtonModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatRadioModule,
        MatIconModule,
        MatSidenavModule,
        MatToolbarModule,
        MatMenuModule,
        MatListModule,
        MatSortModule,
        MatFormFieldModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatCardModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatTreeModule,
        CdkTreeModule,
        BrowserAnimationsModule,
        NgxMatSelectSearchModule,
        MatStepperModule,
        MatBottomSheetModule,
        A11yModule,
    ],
    providers: [{
        provide: MAT_RADIO_DEFAULT_OPTIONS,
        useValue: { color: 'primary' }, // To make the default color scheme as primary for Mat Radio buttons
    }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserAnimationsModule],
})
export class MaterialModule {}


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        BonDeVenteComponent,
        BonDeVenteFilterComponent,
        DevisComponent,
        ClientComponent,
        ClientDetailComponent,
        ArticleComponent,
        ArticleDetailComponent,
        ArticleLogistiqueComponent,
        ArticleDescriptionComponent,
        ArticleStockComponent,
        ArticleDescriptionPhotoComponent,
        ArticleDescriptionCodeComponent,
        ArticleLogistiqueStockAutreComponent,
        ArticleLogistiqueCouchePrixComponent,
        ArticleLogistiqueMagasinComponent,
        ArticleLogistiqueEntrepotComponent,
        HeaderComponent,
        PromptComponent,
        ConfirmComponent,
        DelayFocusDirective,
        TableBaseComponent,
        PostalCodesComponent,
        AddPostalCodeComponent,
        EditPostalCodeComponent,
        NiveauDeSecuriteComponent,
        AddNiveauDeSecuriteComponent,
        EditNiveauDeSecuriteComponent,
        MotifsComponent,
        AddMotifComponent,
        EditMotifComponent,
        UsersComponent,
        ConfirmDialogComponent,
        AddUserComponent,
        EditUserComponent,
        MerchandiseDeVenteComponent,
        GestionDesDroitsComponent,
        ResetPasswordComponent,
        UpdateMotifBlocageComponent,
        UpdateBlocageExterneComponent,
        StockEntreprotsComponent,
        UpdateAffectQuantityComponent,
        UpdateStockEmplacementComponent,
        UsersFilterComponent,
        ArticleFilterComponent,
        PostalCodeFilterComponent,
        NiveauFilterComponent,
        MotifsFilterComponent,
        GestionFilterComponent,
        DefaultImage,
        ImageDefaultPipe,
        SearchPostalCodeComponent,
        ClientFilterComponent,
        BlocageDeblocageStockComponent,
        AuthorizationCheckComponent,
        CreateBonDeVenteComponent,
        ClientBondeComponent,
        FideliteBondeComponent,
        InfoLivraisonBondeComponent,
        LivraisonDetailsComponent,
        InfoFacturationBondeComponent,
        InfoComplementariesComponent,
        ModifyArticleDetailComponent,
        RemiseListComponent,
        ForcageBondeComponent,
        RemiseDetailComponent,
        ModifyBonDeVenteComponent,
        NumeroCourtComponent,
        ListTransporteurComponent,
        DetailTransporteurComponent,
        SearchArticleComponent,
        HistoriqueBondeComponent,
        EmporteeDiffereComponent,
        CapCurrencyPipe,
        CapDecimalPipe,
        MotifForcePrixComponent,
        BlocageCustomMotifComponent,
        CreateClientComponent,
        SearchClientComponent,
        AutreMagasinComponent,
        BonDeCommandeComponent,
        MagasinComplementInfoComponent,
        AlerteClientComponent,
        TicketsComponent,
        DateDeRelanceComponent,
        ModifyBonDeCommandeComponent,
        ModifierLeTypeDeLaCommandeComponent,
        ColorsListComponent,
        BonDeCommadeFilterComponent,
        GestionDesE2FilterComponent,
        BarcodeScannerComponent,
        ModifyLineItemChoixComponent,
        FacturationAcompteComponent,
        VenteArticleGroupsComponent,
        CreateDevisComponent,
        ValiditeLimitComponent,
        ModifyDevisComponent,
        DevisFilterComponent,
        ReportingComponent,
        CalculateQuantityComponent,
        FilterReportComponent,
        TrackDeliveryComponent,
        SafePipe,
        PhoneNumberPipe,
        ScrollToTopComponent,
        DateDePromesseComponent,
        PdfViewerComponent,
        RelanceConfirmationComponent,
        ModifyRetraitMarchandiseComponent,
        ActionReserveComponent,
        ImprimerConfirmationComponent,
        DateTimeComponent,
        SearchGestionDesE2Component,
        ModifierGestionDesE2Component,
        ReservationComponent,
        DesaffecterE2Component,
        GestionPreAffectationComponent,
        CommandeLieesComponent,
        InfoCarrierComponent,
        ChangeStoreComponent,
        AddressConfirmationComponent,
        InlineSpinnerComponent,
        BottomSheetComponent,
        DateTimePickerComponent,
        NonServiceArticlesListComponent,
        ReportingAttendeComponent,
        ClientHistoriqueComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SideNavMenuModule,
        AppRoutingModule,
        DataTablesModule,
        LoadingBarModule,
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        MatAutocompleteModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [HttpClient,StorageService]
            }
        }),
        TreeviewModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        IMaskModule,
        NgxIntlTelInputModule,
        NgSelectModule,
        HammerModule,
        NgxExtendedPdfViewerModule,
        ZXingScannerModule,
    ],
    exports: [],
    providers: [
        Global,
        AuthGuard,
        LoginGuard,
        DeactivatorGuard,
        UserService,
        LoginService,
        RemiseService,
        PrinterService,
        DialogsService,
        MotifService,
        UtilService,
        ParamService,
        CiviliteService,
        ClientService,
        StockService,
        NiveauSecuriteService,
        MagComplementService,
        StorageService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatPaginatorIntl, useClass: TranslatePaginatorIntl },
        {
            provide: LOCALE_ID,
            deps: [UtilService],
            useFactory: (utilService) => utilService.getCurrentDecimalSymbol === ',' ? 'fr-FR' : 'en-US' //returns locale string
        },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        BonVenteService,
        JsonService,
        BonCommandeService,
        HistoryService,
        CapCurrencyPipe,
        CapDecimalPipe,
        SafePipe,
        PhoneNumberPipe,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        KeycloakService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}
