import {Injectable} from '@angular/core';

import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Constants} from '../constants';

@Injectable()
export class DevisService {

    constructor(private http: HttpClient) {
    }

    searchDevis(data: any): Observable<any> {
        return this.http.post(Constants.API_DEVIS_URL + 'searchBonDevis', data);
    }

    createDevis(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_DEVIS_URL + 'createwDevis', data);
    }

    imprimerDevis(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;        

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_DEVIS_URL + 'imprimerDevis', options);
    }

    getGeneratedCommandeNumBons(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['NUMBON'] = data.NUMBON;
        params['CMINT'] = data.CMINT;
        params['IDSOC'] = Constants.IDSOC;
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_COMMANDE_URL + 'checkbondecommande', options);
    }

    getDevisByNumBon(NumBon: any, IDMAG: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
   
        params['pNumBon'] = NumBon;
        params['pMag'] = IDMAG;
        params['IDSOC'] = Constants.IDSOC;
        
        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_DEVIS_URL + 'getwBonDevis', options);
    }

    modifyDevis(data: any): Observable<any> {
        data['IDSOC'] = Constants.IDSOC;
        return this.http.post(Constants.API_DEVIS_URL + 'modifywDevis', data);
    }

    modifyRelanceDevis(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_DEVIS_URL + 'modifyRelanceDateDevis', options);
    }

    validateDevis(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_DEVIS_URL + 'validateDevis', options);
    }

    removeDevis(data: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;
        params['IDSOC'] = Constants.IDSOC;

        const options = {
            headers: headers,
            params: params
        };

        return this.http.get(Constants.API_DEVIS_URL + 'deleteDevis', options);
    }

}
