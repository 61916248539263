import { Component, OnInit } from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';

@Component({
  selector: 'app-desaffecter-e2',
  templateUrl: './desaffecter-e2.component.html',
  styleUrls: ['./desaffecter-e2.component.scss'],
  providers: [ {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}]
})
export class DesaffecterE2Component implements OnInit {

  isMobile: boolean;
  showLoader = false;

  constructor(private deviceDetector: DeviceDetectorService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
  }

}
