import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'providers/constants';

@Injectable()
export class MotifService {

    constructor(private http: HttpClient) {
    }

    getListPrixForceMotifs(data: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const options = {
            headers: headers,
            params: data
        };        
        
        return this.http.get(Constants.API_MOTIF_URL + 'getMotifPrixChange', options);
    }
    
    getListofMotifs(data: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_MOTIF_URL + 'getMotifAtLoad', options);
    }

    addMotif(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_MOTIF_URL + 'createMotif', data, options);
    }

    updateMotif(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_MOTIF_URL + 'updateMotif', data, options);
    }

    deleteMotif(row_id: number): Observable<any> {

        const headers = new HttpHeaders();            
        headers.append('Content-Type', 'application/json');
        
        const params = {};
        params['IDMOT'] = row_id;            

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_MOTIF_URL + 'deleteMotif', options);
    }

    getListofMotifsWithoutPagination(data: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_MOTIF_URL + 'getMotifList', options);
    }
}   
