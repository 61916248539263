import {Component, AfterViewInit, OnInit, OnDestroy, ViewChild, Optional, Inject, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

// horaires date picker en français
import {MAT_DATE_FORMATS} from '@angular/material/core';

import {Client} from '../../models/clients';
import {ClientService} from '../../providers/api/clientService';

import {UserService} from '../../providers/api/userService';
import { Magasin } from 'models/magasins';
import { LoginService } from 'providers/api/loginService';
import { Constants } from '../../providers/constants';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogsService } from 'providers/api/dialogService';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ClientFilterComponent } from './client-filter/client-filter.component';
import * as moment from "moment";
import { NgxSpinnerService } from 'ngx-spinner';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    providers: [ClientService,
                {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
    ]
})

export class ClientComponent implements AfterViewInit, OnInit, OnDestroy {

    private _searchedClient: Client
    get searchedClient() {return this._searchedClient}
    @Input() set searchedClient(searchedClient: Client) {
        this.searchedClientChange.emit(this._searchedClient = searchedClient);
    }
    @Output() private searchedClientChange: EventEmitter<Client> = new EventEmitter<Client>();

    @Output() private onClientChnage: EventEmitter<Client> = new EventEmitter<Client>();

    @Output() private listMagasinsData: EventEmitter<Magasin[]> = new EventEmitter<Magasin[]>();

    @Output() private listPaysData: EventEmitter<any> = new EventEmitter<any>();

    @Input() set flowCMINT(flowCMINT: any) {
      if(flowCMINT !== undefined && flowCMINT != null) {
        this.USERCMINT = flowCMINT;
        this.searchForm.patchValue({
          CMINT: this.USERCMINT,
        });

      } else {
        this.user = this.userService.getCurrentUser();          
        this.USERCMINT = this.user.CMINT;

        this.searchForm.patchValue({
          CMINT: this.USERCMINT,
        });
      }
    }
    
    @Input() set preFilterConf(preFilterConf: any) {
      if(preFilterConf !== undefined && preFilterConf != null) {
          this.user = this.userService.getCurrentUser();          
          this.USERCMINT = (this.flowCMINT !== undefined && this.flowCMINT != null)?this.flowCMINT:this.user.CMINT;

          const form_data = preFilterConf.form_data;
          
          this.searchForm.patchValue({
              CMINT: form_data.CMINT !== null?form_data.CMINT:'',
              NOM: form_data.NOM !== null?form_data.NOM:'',
              TPRENOM: form_data.TPRENOM !== null?form_data.TPRENOM:'',            
              CP: form_data.CP !== null?form_data.CP:'',
              VILLE: form_data.VILLE !== null?form_data.VILLE:'',
              PAYS: form_data.PAYS !== null?form_data.PAYS:'',
              BIRTHDATEMIN: (form_data.BIRTHDATEMIN !== null && form_data.BIRTHDATEMIN !== '')?new Date(form_data.BIRTHDATEMIN):'',
              BIRTHDATEMAX: (form_data.BIRTHDATEMIN !== null && form_data.BIRTHDATEMIN !== '')?new Date(form_data.BIRTHDATEMIN):'',
              TEL: form_data.TEL !== null?form_data.TEL:'',
              IDSOC: form_data.IDSOC !== null?form_data.IDSOC:Constants.IDSOC,
              lettre: form_data.lettre !== null?form_data.lettre:'',
              CIINT: this.user.CIINT,
              TEMAIL: form_data.TEMAIL !== null?form_data.TEMAIL:'', 
          });

          this.filterValues = form_data;

          this.searchFormSubmitter();
      }
    }

    @Input() formSearchType;

    // bouton ENTER valide formulaire
    private pressedButton: any;

    // champs de recherche
    searchDataClient: any = null;
    today = new Date();

    // Service
    listClient: Client[] = [];

    //Magasin
    listMagasins: Magasin[] = [];
    filteredMagasins: Magasin[] = [];
    filterMagasin: string = "";
    listPays: any;
    filteredPays: any;
    filterPays: string = "";
    
    user: any;
    USERCMINT: any;

    showLoader = false;

    currentPage: number = 0;
    totalPages: number;
    rowsPerPage: number;

    searchForm: UntypedFormGroup = this.formBuilder.group({
      CMINT: [''],
      NOM: [''],
      TPRENOM: [''],            
      CP: [''],
      VILLE: [''],
      PAYS: [''],
      BIRTHDATEMIN: [{value: '', disabled: true}],
      BIRTHDATEMAX: [{value: '', disabled: true}],
      TEL: [''],
      IDSOC: [Constants.IDSOC],
      lettre: [''],
      CIINT: [''],
      TEMAIL: [''],
    });

    // DATATABLE
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();    

    alpha_categories = [ //09,ab,cd,eh,il,mo,pr,su,vz
        {
            label: '0...9',
            value: '09'
        },
        {
            label: 'A...B',
            value: 'ab'
        },
        {
            label: 'C...D',
            value: 'cd'
        },
        {
            label: 'E...H',
            value: 'eh'
        },
        {
            label: 'I...L',
            value: 'il'
        },
        {
            label: 'M...O',
            value: 'mo'
        },
        {
            label: 'P...R',
            value: 'pr'
        },
        {
            label: 'S...U',
            value: 'su'
        },
        {
            label: 'V...Z',
            value: 'vz'
        }
    ];

    isMobile: boolean = false;
    searchSubscription: Subscription;
    searchCodeSubscription: Subscription;

    pageType: 'page' | 'popup' = 'page';
    defaultPays: string = '';
    defaultMagasin: string = '';

    filterValues: any;
    dateAct: string = 'equal';
    minDateAct: any = null;

    emailPattern = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;

    constructor(private router: Router,                
                private formBuilder: UntypedFormBuilder,
                private loginService: LoginService,
                private userService: UserService, 
                private clientService: ClientService,
                private translateService: TranslateService,
                private dialogService: DialogsService,
                private loadingService: NgxSpinnerService,
                private dialog: MatDialog,
                private deviceService: DeviceDetectorService,
                private storageService: StorageService,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    }

   
    ngOnInit(): void {
        this.userService.changeLanguage();
        this.isMobile = this.deviceService.isMobile();

        // on cherche le user connecté et son magasin de rattachement
        this.user = this.userService.getCurrentUser();

        if(this.flowCMINT === undefined || this.flowCMINT == null) {
          this.USERCMINT = this.user.CMINT;
          
          let IDMAG_field = this.searchForm.get('CMINT');
          if(IDMAG_field.value === undefined || IDMAG_field.value == null || IDMAG_field.value === '') {
            IDMAG_field.setValue(this.USERCMINT);
          }
        }

        const oldFormData: any = this.storageService.getItem('clientFormData');
        const oldListResponse: any = this.storageService.getItem('clientListData');
        const oldEditFormData: any = this.storageService.getItem('clientEditFormData');

        this.dtOptions = {
            language: {
              processing: this.translateService.instant('kprocessing'),
              search: this.translateService.instant('ksearch'),
              loadingRecords: this.translateService.instant('kloading'),
              info: this.translateService.instant('kgenshowentry'),
              infoEmpty: this.translateService.instant('kgenshowingentry'),
              zeroRecords: this.translateService.instant('knodata'),
              emptyTable: this.translateService.instant('knodatadash'),
              paginate: {
                  first: this.translateService.instant('kfirst'),
                  previous: this.translateService.instant('kprevious'),
                  next: this.translateService.instant('knext'),
                  last: this.translateService.instant('klast'),
              }       
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };        

        this.loadDependencies();
        
        if(oldFormData !== undefined && oldFormData !== null) {
            this.searchForm.patchValue({
                CMINT: oldFormData.CMINT !== null?oldFormData.CMINT:'',
                NOM: oldFormData.NOM !== null?oldFormData.NOM:'',
                TPRENOM: oldFormData.TPRENOM !== null?oldFormData.TPRENOM:'',            
                CP: oldFormData.CP !== null?oldFormData.CP:'',
                VILLE: oldFormData.VILLE !== null?oldFormData.VILLE:'',
                PAYS: oldFormData.PAYS !== null?oldFormData.PAYS:this.defaultPays,
                BIRTHDATEMIN: (oldFormData.BIRTHDATEMIN !== null && oldFormData.BIRTHDATEMIN !== '')?new Date(oldFormData.BIRTHDATEMIN):'',
                BIRTHDATEMAX: (oldFormData.BIRTHDATEMAX !== null && oldFormData.BIRTHDATEMAX !== '')?new Date(oldFormData.BIRTHDATEMAX):'',
                TEL: oldFormData.TEL !== null?oldFormData.TEL:'',
                IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
                lettre: oldFormData.lettre !== null?oldFormData.lettre:'',
                TEMAIL: oldFormData.TEMAIL !== null?oldFormData.TEMAIL:'',  
            });

            this.filterValues = oldFormData;
          }


        if(oldListResponse !== undefined && oldListResponse !== null) {
            const data = oldListResponse.data;
      
            this.rowsPerPage = oldListResponse.rowsPerPage;
            this.totalPages = oldListResponse.count;        
      
            this.listClient = [...data];            
      
            if(oldEditFormData !== undefined && oldEditFormData !== null) {
              this.listClient.map(
                (item: Client) => {
                  if(item.IDTIERS == oldEditFormData.IDTIERS) {                    
                    
                    item.CODPAYS = oldEditFormData.CODPAYS;
                    item.IDCIV = oldEditFormData.IDCIV;
                    item.TPRENOM = oldEditFormData.TPRENOM;
                    item.TRAISOC = oldEditFormData.TRAISOC;
                    item.TADR1 = oldEditFormData.TADR1;
                    item.TADR2 = oldEditFormData.TADR2;
                    item.TADR3 = oldEditFormData.TADR3;
                    item.TADR4 = oldEditFormData.TADR4;
                    item.TDTNAIS = oldEditFormData.TDTNAIS;
                    item.TFAX = oldEditFormData.TFAX;
                    item.CODPOS = oldEditFormData.CODPOS;
                    item.TVILLE = oldEditFormData.TVILLE;
                    item.TTEL = oldEditFormData.TTEL;
                    item.TPORT = oldEditFormData.TPORT;
                    item.TEMAIL = oldEditFormData.TEMAIL;
                  }                         
                }
              )
            }
      
            this.showLoader = false;
            this.dtTrigger.next();
          }
    }

    async loadDependencies() {
      this.loadingService.show();

      if(this.data !== undefined && this.data != null && this.data !== '' && Object.keys(this.data).length !== 0)  {
        if(this.data.client_nom !== undefined && this.data.client_nom != null && this.data.client_nom !== '') {
          this.showLoader = true;
        }
        this.pageType = 'popup';
      }

      await this.getAllMagasins();
      await this.getAllPays();

      this.searchForm.patchValue({
        CMINT: this.defaultMagasin        
      });

      this.searchForm.patchValue({
        PAYS: this.defaultPays,
        CIINT: this.user.CIINT
      });

      if(this.isMobile) {
        this.searchSubscription = this.userService.headerSearch.subscribe(
          (status: boolean) => {
            if(status === true) {
              this.openUpFilterScreen();
            }
          }
        );

        this.searchCodeSubscription = this.userService.headerSearchInputValue.subscribe(
          (search_nom) => {
            if(search_nom !== undefined) {
                this.userService.setAdvanceSearchFormData(null);
                this.searchForm.patchValue({
                  CMINT: '',
                  NOM: '',
                  TPRENOM: '',            
                  CP: '',
                  VILLE: '',
                  TEL: '',
                  BIRTHDATEMIN: '',
                  BIRTHDATEMAX: '',
                  lettre: '',
                  PAYS: this.defaultPays,
                  TEMAIL: ''
              });
              let rapid_search_code:any = Number(search_nom);
              if(isNaN(rapid_search_code)){
                this.searchForm.patchValue({ NOM: search_nom === null?'':search_nom, TEL : '' });
              } else {
                this.searchForm.patchValue({ TEL: search_nom === null?'':search_nom, NOM: '' });          
              }

              
              if (this.emailPattern.test(search_nom)) {
                this.searchForm.patchValue({ TEMAIL: search_nom === null?'':search_nom, TEL : '', NOM: '' });
              }
              this.searchFormSubmitter();
            }         
          }
        );
      }

      if(this.pageType === 'page') {
        this.userService.headerSearchInput.next(true);
        this.userService.headerSearchInputType.next('text');
        this.userService.headerSearchInputThreeDots.next(true);
        this.userService.headerSearch.next(false);    
      }
      if(this.data !== undefined && this.data != null && this.data !== '' && Object.keys(this.data).length !== 0)  {
                
        if(this.data.client_nom !== undefined && this.data.client_nom != null && this.data.client_nom !== '') {
          //Rapid search
          let rapid_search_code:any = Number(this.data.client_nom);

          if(isNaN(rapid_search_code)){
            this.searchForm.patchValue({
              NOM: this.data.client_nom
            });            
          } else {
            this.searchForm.patchValue({
              TEL: this.data.client_nom
            });           
          }

          if (this.emailPattern.test(this.data.client_nom)) {
            this.searchForm.patchValue({ TEMAIL: this.data.client_nom, NOM: '', TEL: '' });
          }

          this.searchFormSubmitter();
        }
      }

      this.loadingService.hide();
    }

    onDateChanged() {
      this.minDateAct = this.searchStartDate.value?this.searchStartDate.value:null;
      if(this.minDateAct !== null) {
        this.searchEndDate.setValue(this.minDateAct);
      }
    }

    onSearchChange(search: string) {
      this.filteredMagasins = this.listMagasins.filter(row => row.CMRAISOC.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }

    onCountryChange(search: string) {
      this.filteredPays = this.listPays.filter(row => row.PAYSNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }

    getAllMagasins() {            
      this.searchForm.get('CMINT').setValue(this.defaultMagasin);
     
      return new Promise((resolve, reject) => {
        this.loginService.getMagasins().subscribe(
          (data) => {
              if (data.length > 0) {
                  for (let i = 0; i < data.length; i++) {
                      this.listMagasins.push(new Magasin(data[i]));          
                  }
                  this.filteredMagasins = this.listMagasins;
                  this.listMagasinsData.emit(this.listMagasins);
                  resolve(data);
              }
          }, 
        (error) => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
        });
      })
    }

    resetForm(){
        this.userService.headerSearchInputValue.next(undefined);
        this.searchForm.patchValue({
            CMINT: '',
            NOM: '',
            TPRENOM: '',            
            CP: '',
            VILLE: '',
            TEL: '',
            BIRTHDATEMIN: '',
            BIRTHDATEMAX: '',
            lettre: '',
            PAYS: this.defaultPays,
            TEMAIL: '', 
        });
        this.dateAct = 'equal';
        this.filterValues = undefined;
        this.storageService.removeItem('clientFormData');
        this.storageService.removeItem('clientListData');

        this.searchFormSubmitter();
    }

    searchFormSubmitter(): void {
      if(this.pageType === 'page') {
        this.showLoader = true;
      } else {
        this.showLoader = true;
        this.loadingService.show();
      }
    
        if(this.searchForm.invalid) {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));
    
          return;
        }
            
        const form_data = this.searchForm.getRawValue();

        let creeMinDate = '', creeMaxDate = '';

        if(this.dateAct === 'between') {
          creeMinDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
          creeMaxDate = form_data.BIRTHDATEMAX?form_data.BIRTHDATEMAX:"";
        } else if (this.dateAct === 'greater_equal') {
          creeMinDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
          creeMaxDate = "";
        } else if (this.dateAct === 'less_equal') {
          creeMinDate = "";
          creeMaxDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
        } else {
          creeMinDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
          creeMaxDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
        }

        form_data.BIRTHDATEMIN = creeMinDate;
        form_data.BIRTHDATEMAX = creeMaxDate;

        this.filterValues = form_data;

        if(this.pageType === 'page') {
          this.storageService.setItem('clientFormData', form_data);
        }

        this.searchClients(form_data);
    }

    searchClients(form_data: any) {
        form_data.BIRTHDATEMIN = (form_data.BIRTHDATEMIN !== '' && form_data.BIRTHDATEMIN !== null)?moment(form_data.BIRTHDATEMIN).format('YYYY-MM-DD'):'',
        form_data.BIRTHDATEMAX = (form_data.BIRTHDATEMAX !== '' && form_data.BIRTHDATEMAX !== null)?moment(form_data.BIRTHDATEMAX).format('YYYY-MM-DD'):'',
        form_data.paracmint = this.USERCMINT;
        
        this.clientService.searchClients(form_data).subscribe(
          (response: any) => {
              if(response.success !== undefined) {
                this.currentPage = 0;
    
                const data = response.data;
        
                this.rowsPerPage = response.rowsPerPage;
                this.totalPages = response.count;
        
                this.listClient = [...data];                                          
                
                if(this.pageType === 'page') {
                  this.storageService.setItem('clientListData', response);
                }
              }            

              this.showLoader = false;
              this.dtTrigger.next();
              this.loadingService.hide();
          }, 
          err => {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
          }
        )
    }

    searchPaginate(page?: number) {
        const form_data = this.searchForm.getRawValue();
        
        if(this.searchForm.invalid) {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

            return;
        }        
        
        this.filterValues = form_data;
        if(this.pageType === 'page') {
          this.storageService.setItem('clientFormData', form_data);
        }

        let creeMinDate = '', creeMaxDate = '';

        if(this.dateAct === 'between') {
          creeMinDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
          creeMaxDate = form_data.BIRTHDATEMAX?form_data.BIRTHDATEMAX:"";
        } else if (this.dateAct === 'greater_equal') {
          creeMinDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
          creeMaxDate = "";
        } else if (this.dateAct === 'less_equal') {
          creeMinDate = "";
          creeMaxDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
        } else {
          creeMinDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
          creeMaxDate = form_data.BIRTHDATEMIN?form_data.BIRTHDATEMIN:"";
        }

        form_data.BIRTHDATEMIN = (creeMinDate !== '' && creeMinDate !== null)?moment(creeMinDate).format('YYYY-MM-DD'):'',
        form_data.BIRTHDATEMAX = (creeMaxDate !== '' && creeMaxDate !== null)?moment(creeMaxDate).format('YYYY-MM-DD'):'',
        form_data.paracmint = this.USERCMINT;

        this.clientService.searchClients(form_data, page).subscribe(
            (response: any) => {
                if(response.success !== undefined) {
                    const data = response.data;
            
                    this.rowsPerPage = response.rowsPerPage;
                    this.totalPages = response.count;
            
                    this.listClient = [...data];                                          
                    
                    if(this.pageType === 'page') {
                      this.storageService.setItem('clientListData', response);
                    }
                  }            
    
                  this.showLoader = false;
                  this.dtTrigger.next();                                
            }, 
            err => {
                this.showLoader = false;
                this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            }
        )
    }

    paginationChange(event: any) {
        this.showLoader = true;

        const changedPageIndex = event.pageIndex;    

        this.currentPage = changedPageIndex;

        this.searchPaginate(changedPageIndex + 1);        
    }

    editClient(info: Client): void {
        const currentMag = this.listMagasins.find((row) => row.CMINT == info.CMINT);

        if(currentMag !== undefined && currentMag !== null) {
            info.MAG_NOM = currentMag.CMRAISOC;
        }

        if(this.pageType === 'popup') {
          this.searchedClient = info;          
          this.onClientChnage.next(info);
          return;
        }
        
        this.storageService.setItem('client', info);
      
        this.router.navigate(['/client-detail']);
    }

    async getAllPays(): Promise<void> {
        const paramData = await this.userService.getRuleParamsData(10,1);        
        this.defaultPays = paramData.VBOOL1 == '-1'?paramData.VSTR1:'tous';
        this.searchForm.get('PAYS').setValue(this.defaultPays);

        return new Promise((resolve, reject) => {
            this.clientService.getListPays(this.USERCMINT, this.user.CIINT).subscribe(
              (res: any) => {
                if(res.success !== undefined) {
                  this.listPays = res.data;
                  this.filteredPays = this.listPays;
                  this.listPaysData.emit(this.listPays);
                  resolve();            
                }
              },
              err => {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                reject()
              }
            );
        });
    }

    ngOnDestroy(): void {

        if(this.router.routerState.snapshot.url.indexOf('client-detail') > -1) {

        } else {
          this.storageService.removeItem('clientFormData');
          this.storageService.removeItem('clientListData');
        }
        if(this.isMobile) {
          this.userService.headerSearch.next(false);
          this.userService.headerSearchInput.next(false);   
          if(this.searchSubscription){
            this.searchSubscription.unsubscribe();
          }
          if(this.searchCodeSubscription){
            this.searchCodeSubscription.unsubscribe();
          }
        } 

        if(this.data !== undefined && this.data !== null && this.data !== '') {
        } else {
                  
        }

        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    openUpFilterScreen() {      
        this.filterValues = this.userService.getAdvanceSearchFormData();        
        this.userService.headerSearch.next(false);

        const dialogRef = this.dialog.open(ClientFilterComponent, {
          width: '60vw',
          maxHeight: this.isMobile?'85vh':undefined,
          data: {
            form_data: (!this.filterValues)?this.searchForm.getRawValue():this.filterValues,
            alpha_categories: this.alpha_categories,
            listMagasins: this.listMagasins,
            listPays: this.listPays,      
            dateAct: this.dateAct      
          }               
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result && result.status === "success") {            
            const form_data = result.form_data;
            this.dateAct = result.dateAct;
            this.searchForm.patchValue({
                CMINT: form_data.CMINT !== null?form_data.CMINT:'',
                NOM: form_data.NOM !== null?form_data.NOM:'',
                TPRENOM: form_data.TPRENOM !== null?form_data.TPRENOM:'',            
                CP: form_data.CP !== null?form_data.CP:'',
                VILLE: form_data.VILLE !== null?form_data.VILLE:'',
                PAYS: form_data.PAYS !== null?form_data.PAYS:'',
                BIRTHDATEMIN: (form_data.BIRTHDATEMIN !== null && form_data.BIRTHDATEMIN !== '')?new Date(form_data.BIRTHDATEMIN):'',
                BIRTHDATEMAX: (form_data.BIRTHDATEMAX !== null && form_data.BIRTHDATEMAX !== '')?new Date(form_data.BIRTHDATEMAX):'',
                TEL: form_data.TEL !== null?form_data.TEL:'',
                IDSOC: form_data.IDSOC !== null?form_data.IDSOC:Constants.IDSOC,
                lettre: form_data.lettre !== null?form_data.lettre:'',
                TEMAIL: form_data.TEMAIL !== null?form_data.TEMAIL:'',
            });
    
            this.filterValues = form_data;
            
            if(this.isMobile){
              this.userService.setAdvanceSearchFormData(this.filterValues);
              this.userService.popupClose.next({'action':'clientAdsearch'});
            }
            this.searchFormSubmitter();
          }  else {
            //advanced search close 
            if(this.isMobile){
              this.userService.popupClose.next(false);
            }
          }   
        }); 
    }


    get searchStartDate() {
      return this.searchForm.get('BIRTHDATEMIN');
    }

    get searchEndDate() {
      return this.searchForm.get('BIRTHDATEMAX');
    }

    changeDateAct(type: string = 'equal') {
      this.dateAct = type;
    }

    _clearDate = (form_field: string) => {
      this.searchForm.get(form_field).setValue('');
      if(form_field == 'BIRTHDATEMIN') {
        this.minDateAct = null;
      }
    }

    get filterValuesFormat(): string {
      let filterArr = [];
  
      if(this.filterValues.CMINT !== undefined && this.filterValues.CMINT !== null) {
        if(this.filterValues.CMINT === '') {
          filterArr.push(this.translateService.instant('kcltouslesmag'));
        } else if(this.filterValues.CMINT === 'non_defini') {
          filterArr.push(this.translateService.instant('kclnondefi'));
        } else {
          if(this.filteredMagasins) {
            let magLabel = this.filteredMagasins.find((row) => row.CMINT == this.filterValues.CMINT);
            filterArr.push(magLabel?.CMRAISOC);
          }
        }
      }  
      if(this.filterValues.NOM !== undefined && this.filterValues.NOM !== null && this.filterValues.NOM !== '') {
        filterArr.push(this.filterValues.NOM);
      }
      if(this.filterValues.TPRENOM !== undefined && this.filterValues.TPRENOM !== null && this.filterValues.TPRENOM !== '') {
        filterArr.push(this.filterValues.TPRENOM);
      }
      if(this.filterValues.CP !== undefined && this.filterValues.CP !== null && this.filterValues.CP !== '') {
        filterArr.push(this.filterValues.CP);
      }
      if(this.filterValues.VILLE !== undefined && this.filterValues.VILLE !== null && this.filterValues.VILLE !== '') {
        filterArr.push(this.filterValues.VILLE);
      }
      if(this.filterValues.TEL !== undefined && this.filterValues.TEL !== null && this.filterValues.TEL !== '') {
        filterArr.push(this.filterValues.TEL);
      }
      if(this.filterValues.BIRTHDATEMIN !== undefined && this.filterValues.BIRTHDATEMIN !== null && this.filterValues.BIRTHDATEMIN !== '' && this.filterValues.BIRTHDATEMIN !== 'Invalid Date') {
        filterArr.push(moment(this.filterValues.BIRTHDATEMIN).format('DD.MM.YYYY'));
      }
      if(this.filterValues.lettre !== undefined && this.filterValues.lettre !== null && this.filterValues.lettre !== '') {
        filterArr.push(this.filterValues.lettre);
      }     
      if(this.filterValues.TEMAIL !== undefined && this.filterValues.TEMAIL !== null && this.filterValues.TEMAIL !== '') {
        filterArr.push(this.filterValues.TEMAIL);
      } 
      if(this.filterValues.PAYS !== undefined && this.filterValues.PAYS !== null && this.filterValues.PAYS !== '') {
        if(this.filterValues.PAYS === 'tous') {
          filterArr.push(this.translateService.instant('kcltouslespays'));
        } else if(this.filterValues.PAYS === 'non_defini') {
          filterArr.push(this.translateService.instant('kclnondefi'));
        } else {
          if(this.filteredPays) {
            let paysLabel = this.filteredPays.find((row: any) => row.CODPAYS == this.filterValues.PAYS);
            filterArr.push(paysLabel.PAYSNOM);
          }
        }        
      }
      return filterArr.length > 0 ? filterArr.join(', ') : '';
    }
 
}
