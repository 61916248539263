<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium" translate>kartclearform</div>
            </button>
        </div>
    </div>
</div>

<form #f="ngForm" [formGroup]="searchForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="headerSearchFormSubmitter()">
    <div class="bloc_container bg_light w-100">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
                <div class="row no-gutters">
                    <div class="col-12 col-lg-12 mt-3">
                        <mat-form-field>
                            <input inputmode="none" #filterFirst matInput formControlName="CDART" (keydown.enter)="onCodeEntered($event)" [placeholder]="'kartcode' | translate" class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-lg-12">
                        <mat-form-field>
                            <input matInput formControlName="NOM" (keydown.enter)="f.ngSubmit.emit()"
                                [placeholder]="'kartnom' | translate" class="text-uppercase">
                        </mat-form-field>
                    </div>                   
                    <div class="col-12 col-lg-12">
                        <mat-form-field>
                            <input matInput formControlName="CODEEXT" (keydown.enter)="f.ngSubmit.emit()" [placeholder]="'kartcodeext' | translate" class="text-uppercase">                          
                        </mat-form-field>                       
                    </div>
                    <div class="col-12">
                        <div class="row no-gutters">
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput inputmode="decimal" formControlName="MTTTCMIN" (keydown.enter)="f.ngSubmit.emit()"
                                        [placeholder]="'kprixentre' | translate"
                                        class="text-uppercase">
                                </mat-form-field>
                            </div>
                            <div class="col-6 pl-4">
                                <mat-form-field>
                                    <input matInput inputmode="decimal" formControlName="MTTTCMAX" (keydown.enter)="f.ngSubmit.emit()"
                                        [placeholder]="'kbonand' | translate"
                                        class="text-uppercase">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-checkbox formControlName="LIV" 
                                    class="mat-checkbox" >
                            {{'kartdelitem' | translate}}
                        </mat-checkbox>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-checkbox formControlName="ACTIF" 
                                class="mat-checkbox">
                            {{'kartactitm' | translate}}
                        </mat-checkbox>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-checkbox formControlName="STOCK_SUPERIOR"
                                class="mat-checkbox">
                            {{'kdisponibleimmedi' | translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            
            <div class="col-12 col-md-6 col-lg-5">
                <div class="border_fieldset margin_top_20" style="max-height: 330px;height: auto;overflow: scroll;">
                    <mat-form-field floatLabel="always">
                        <input matInput [(ngModel)]="searchStructureMarchandiseLabel" (keydown.enter)="searchStructureMarchandise(searchStructureMarchandiseLabel)" [ngModelOptions]="{standalone: true}"
                            [placeholder]="'kartmar' | translate" class="text-uppercase">
                            
                        <button mat-icon-button type="button" *ngIf="searchStructureMarchandiseLabel && searchStructureMarchandiseLabel !== ''" (click)="searchStructureMarchandise(searchStructureMarchandiseLabel)" matSuffix ><mat-icon>search</mat-icon></button>
                        <button mat-icon-button type="button" *ngIf="searchStructureMarchandiseLabel && searchStructureMarchandiseLabel !== ''" (click)="_clearStructuLabel()" matSuffix ><mat-icon>add</mat-icon></button>
                        <button mat-icon-button type="button" *ngIf="searchStructureMarchandiseLabel && searchStructureMarchandiseLabel !== ''" (click)="_resetStructuTree()" matSuffix ><mat-icon>close</mat-icon></button>
                    </mat-form-field>
                    <div class="form-group m-0">                                    
                        <ngx-treeview #magasinTree [config]="config" [items]="filteredTreeItems" [itemTemplate]="itemTemplate" (selectedChange)="onTreeValueChanged($event)">
                        </ngx-treeview>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <div class="border_fieldset margin_top_20" style="max-height: 330px;height: auto;overflow: scroll;">
                    <div class="form-group m-0">
                        <ngx-treeview #sousTree [config]="sousTreeConfig" [items]="sousfamileTreeItems" [itemTemplate]="sousItemTemplate" (selectedChange)="onSousTreeValueChanged($event)">
                        </ngx-treeview>
                    </div>
                </div>                    
            </div>

            <div class="col-12">
                <mat-form-field class="mt-4">
                    <mat-label>{{ 'kbontrierpar' | translate }}</mat-label>
                    <mat-select color="primary" disableOptionCentering panelClass="dropdown-panel" formControlName="ORDERBY">
                        <mat-option *ngFor="let item of orderBySearchList" [value]="item.value">{{ item.label | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="bloc_container w-100">
        <div class="row bg_white">                
            <div class="align-items-center mt-2 mt-md-0 col-12 d-flex flex-md-row flex-lg-row justify-content-center text-center">
                <button type="submit" class="bt_valid" mat-button>
                    <mat-icon>search</mat-icon>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kartcher' | translate}}
                    </span>                    
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kucancel" | translate }}
                    </span>                        
                </button>
            </div>
        </div>
    </div>
</form>

<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange">
    <div class="form-inline row-item">
        <i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
            <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
            <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
        </i>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckedChange()"
                [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
            <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
                {{item.text}}
            </label>                                
        </div>
    </div>
</ng-template>

<ng-template #sousItemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange">
    <div class="form-inline row-item">
        <i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
            <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
            <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
        </i>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckedChange()"
                [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
            <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
                {{item.text}}
            </label>                                
        </div>
    </div>
</ng-template>