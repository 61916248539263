<div class="modal-container" id="emporte_differe_limite">
    <form [formGroup]="emporteeForm" (ngSubmit)="emporteeFormSubmitter()">
        <mat-card>
            <mat-card-header>
                <mat-card-title class="text-uppercase text-center ">
                    <div class="row">
                        <div class="col-11 text-left">
                            <h4 class="modal_title">{{'kbonemportdiff' | translate}}</h4>
                        </div>
                        <div class="col-1 text-right no-padding">
                            <button mat-button [mat-dialog-close] class="bt_close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row">        
                    <div class="col-12 col-md-12 col-lg-12">
                        <mat-form-field >
                            <mat-label>{{'kbondeadline' | translate}} </mat-label>
                            <input matInput [min]="today" formControlName="dtemporte" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div> 
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions class="text-center">
                <button mat-button type="submit" class="bt_send">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kuok' | translate}}
                    </span>
                </button>
                <button mat-button [mat-dialog-close] class="bt_send">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kartclose' | translate}}
                    </span>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>