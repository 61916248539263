<div class="modal-container w-100" id="couche_prix_article">
    <form >
        <mat-card>
            <mat-card-header  *ngIf="!isMobile">
                <mat-card-title class="text-uppercase text-center ">
                    <div class="row">
                        <div class="col-11 text-left">
                            <h4 class="modal_title">{{ "kreservation" | translate }}</h4>
                        </div>
                        <div class="col-1 text-right no-padding">
                            <button mat-button [mat-dialog-close] class="bt_close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content [class.bnone]="isMobile">
                <div class="row"  *ngIf="!isMobile">
                    <div class="col-md-12 no-padding">
                        <div class="row">
                            <div class="col-md-3 mt-2">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kbonbonl' | translate }} </mat-label>
                                    <input matInput [value]="numBon" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-2">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kbonclient' | translate }} </mat-label>
                                    <input matInput [value]="bonClientNom" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-2">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kbondatdebon' | translate }} </mat-label>
                                    <input matInput [value]="bonDuDate" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mt-2">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kcimcpcity' | translate }} </mat-label>
                                    <input matInput [value]="bonCpVille" disabled>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{ 'kbonref' | translate }} </mat-label>
                            <input matInput [value]="numBon" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{ 'klibelle' | translate }} </mat-label>
                            <input matInput [value]="numBon" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{ 'kqtecde' | translate }} </mat-label>
                            <input matInput [value]="numBon" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{ 'kqtereserve' | translate }} </mat-label>
                            <input matInput [value]="numBon" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{ 'kstock' | translate }} </mat-label>
                            <input matInput [value]="numBon" disabled>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                        <mat-form-field floatLabel="always">
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>

            <mat-card-actions class="text-right" *ngIf="!isMobile">
                <div class="row no-gutters">
                    <div class="col-md-12 pr-2">
                        <button type="submit" mat-button class="bt_send">
                            <i class="material-icons">check</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'kuok' | translate}}
                            </span>
                        </button>
                        <button mat-button [mat-dialog-close] class="bt_send m-0 ml-2">
                            <i class="material-icons">close</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'ktcol' | translate}}
                            </span>
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
