import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import Transporteur from 'models/transporteur';

@Component({
  selector: 'app-detail-transporteur',
  templateUrl: './detail-transporteur.component.html',
  styleUrls: ['./detail-transporteur.component.scss']
})
export class DetailTransporteurComponent implements OnInit {

  transporteur: Transporteur;

  transporteurDept: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<DetailTransporteurComponent>) { }

  ngOnInit(): void {
    this.transporteur = this.data.transporteur;
    this.transporteurDept = this.transporteur.TRASTR1.split(';');
  }

}
