import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PostalCodeModel } from 'models/postal-code';

@Component({
  selector: 'app-search-postal-code',
  templateUrl: './search-postal-code.component.html',
  styleUrls: ['./search-postal-code.component.scss']
})
export class SearchPostalCodeComponent implements OnInit {

  listCodes: PostalCodeModel[] = [];

  codeSelectionForm: any;  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<SearchPostalCodeComponent>,
  private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.listCodes = this.data.list_codes;

    this.codeSelectionForm = this.formBuilder.group({
      code_pos: ['']
    });
  }

  onCodeSelected() {
    const chosen_code = this.codeSelectionForm.get('code_pos').value;

    if(chosen_code !== '' && chosen_code !== null) {
      const chosen_code_data = this.listCodes.find(row => row.TROWID == chosen_code);
      this.dialogRef.close({ status: 'success', code_data: chosen_code_data });
    } else {
      this.dialogRef.close();
    }    
  }

}
