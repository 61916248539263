import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import { User } from 'models/users';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArticleService } from 'providers/api/articleService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';
import { BlocageCustomMotifComponent } from './blocage-custom-motif/blocage-custom-motif.component';
import {AUTHORIZE_BLOCAGE_OU_DEBLOCAGE_STOCK, AUTHORIZE_REMISE_EN_PIED, HistoryService} from '../../../../providers/api/historyService';
import BonPanier from '../../../../models/bonPanier';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-update-motif-blocage',
  templateUrl: './update-motif-blocage.component.html',
  styleUrls: ['./update-motif-blocage.component.scss'],
  providers: [ArticleService, DialogsService, TranslateService]
})
export class UpdateMotifBlocageComponent implements OnInit, AfterViewInit {

  @ViewChild('firstFocusElement') firstFocusElement: ElementRef;
  
  motifBlocageData: any;
  articleData: Article;

  editMotifBlocageForm: any;
  
  maxQuantity: number;

  user: any;
  USERCMINT: any;

  flowType: 'article' | 'bonde';
  authorizedBlocageUser: User;

  NUMBON: string;
  PANIER: BonPanier | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UpdateMotifBlocageComponent>,
    private formBuilder: UntypedFormBuilder,
    private articleService: ArticleService,
    private userService: UserService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private loadingService: NgxSpinnerService,
    private dialog: MatDialog,
    private historyService: HistoryService,
    private storageService: StorageService,) {
      this.motifBlocageData = this.data.motif_data;
      this.articleData = this.data.article_data;
      this.flowType = this.data.flowType;

      if (this.data.NUMBON !== undefined && this.data.NUMBON !== null) {
        this.NUMBON = this.data.NUMBON;
      }

      if (this.data.PANIER !== undefined && this.data.PANIER !== null) {
        this.PANIER = this.data.PANIER;
      }
  }

  ngOnInit(): void {
    
    this.user = this.userService.getCurrentUser();          
    this.USERCMINT = this.user.CMINT;
    this.authorizedBlocageUser = this.data.authorizedBlocageUser;

    this.editMotifBlocageForm = this.formBuilder.group({
      ACTUAL_QTY: [{ value: parseFloat('0').toFixed(2), disabled: true }],
      NOVEL_QTY: ['', [Validators.required, Validators.pattern('^[0-9\.]+$')]],
      DIFFERENCE: [{ value: parseFloat('0').toFixed(2), disabled: true }]
    });

    this.editMotifBlocageForm.patchValue({
      ACTUAL_QTY: (this.motifBlocageData.VBLOC && this.motifBlocageData.VBLOC !== null)?parseFloat(this.motifBlocageData.VBLOC).toFixed(2):0,
      NOVEL_QTY: (this.motifBlocageData.VBLOC && this.motifBlocageData.VBLOC !== null)?parseFloat(this.motifBlocageData.VBLOC):''
    });

    this.maxQuantity = (this.motifBlocageData.VBLOC && this.motifBlocageData.VBLOC !== null)?parseFloat(this.motifBlocageData.VBLOC):0;

    if(this.data.STOCK_DISPO) {
      this.maxQuantity += parseFloat(this.data.STOCK_DISPO);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.firstFocusElement.nativeElement.focus();
      this.firstFocusElement.nativeElement.setAttribute('inputmode', 'decimal');
    }, 100)
  }

  quantityChanged(event: any) {    
    const currentQty = this.editMotifBlocageForm.get('NOVEL_QTY').value;
    const actualQty = this.editMotifBlocageForm.get('ACTUAL_QTY').value;

    let differenceQty = 0;
    if(currentQty !== '' && !isNaN(currentQty) && actualQty !== '' && !isNaN(actualQty)) {
      differenceQty = parseFloat(currentQty) - parseFloat(actualQty);
    }
    
    this.editMotifBlocageForm.patchValue({
      DIFFERENCE: differenceQty.toFixed(2)
    });
  }

  openUpBondeCustomMotif(): void {       
    if(this.flowType === 'article') {
      this.updateStockBlocage();
      return;
    }

    const dialogRef = this.dialog.open(BlocageCustomMotifComponent, {
      maxWidth: '40vw',
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {          
      if(result && result.status === "success") {
        const custom_motif_data = result.data;
        this.updateStockBlocage(custom_motif_data.MOTLIB);
      }      
    });    
  }

  updateStockBlocage(customMotif?: string) {
    const form_data: any = {};

    const entered_qty = this.editMotifBlocageForm.get('NOVEL_QTY').value;
    
    if(Math.abs(entered_qty) > Math.abs(this.maxQuantity)) {
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntyinval'), this.translateService.instant('kuok'));
      return;
    }

    this.loadingService.show();

    const formData = this.editMotifBlocageForm.getRawValue();

    const NOVEL_QTY = formData['NOVEL_QTY'];
    const ACTUAL_QTY = formData['ACTUAL_QTY'];

    let TYPE_BLOCAGE: 'block' | 'deblock' = 'block';

    if (NOVEL_QTY > ACTUAL_QTY) {
      TYPE_BLOCAGE = 'block';
    } else if (NOVEL_QTY < ACTUAL_QTY) {
      TYPE_BLOCAGE = 'deblock';
    } else {
      TYPE_BLOCAGE = 'block';
    }

    form_data['CAINT'] = this.articleData.CAINT;
    form_data['IDMAG'] = this.USERCMINT;
    form_data['IDSOC'] = Constants.IDSOC;
    form_data['IDINST'] = Constants.CIINST;
    form_data['IDMOT'] = this.motifBlocageData.IDMOT;
    form_data['QTY'] = NOVEL_QTY;
    
    if(customMotif !== undefined) {
      const prevNumBon = this.storageService.getItem('previousNumBon');
      //   Motif process
      form_data['MOTIF'] = customMotif;
      form_data['BLOC_LIBEL'] = this.motifBlocageData.LIBEL;
      form_data['USER_ID'] = this.user.CIINT;
      form_data['NUMCOM'] = (prevNumBon != undefined && prevNumBon != 'null' && prevNumBon !== '')?prevNumBon:'';
      form_data['CDART'] = this.articleData.CDART;
    }    

    this.articleService.updateMotifBlocage(form_data).subscribe(
      async (res) => {
        if(res.success !== undefined) {
          if (this.NUMBON && this.authorizedBlocageUser !== undefined && this.authorizedBlocageUser !== null) {
            await this._addBlocageDeBlocageHistory(this.articleData.CDART, NOVEL_QTY, TYPE_BLOCAGE);
          }

          this.loadingService.hide();
          this.dialogRef.close({status: 'success', changed_qty: form_data.QTY, difference_qty: this.editMotifBlocageForm.get('DIFFERENCE').value });
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));  
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )    
  }

  _addBlocageDeBlocageHistory(CDART: string, QTE: number, TYPE_BLOC: 'block' | 'deblock'): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_BLOCAGE_OU_DEBLOCAGE_STOCK,
      NUMBON: this.NUMBON,
      ARTEAN: CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedBlocageUser !== undefined && this.authorizedBlocageUser !== null) ? this.authorizedBlocageUser.CIINT : this.user.CIINT,
      VALUER1: 'Reason',
      VALUER2: QTE,
      TYPE_OPERAT: TYPE_BLOC as string,
    });
  }

}
