<div class="modal-container w-100" id="couche_prix_article"> 
    <form >
    <mat-card>
        <mat-card-header  *ngIf="!isMobile">
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">{{ "kboncomtickets" | translate }}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content [class.bnone]="isMobile">
            <div class="row"  *ngIf="!isMobile">
                <div class="col-md-12 no-padding">
                    <div class="row">
                        <div class="col-md-4 mt-2">
                            <mat-form-field floatLabel="always">
                                <mat-label>{{ 'kbonbonl' | translate }} </mat-label>
                                <input matInput [value]="numBon" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 mt-2">
                            <mat-form-field floatLabel="always">
                                <mat-label>{{ 'kbondatdebon' | translate }} </mat-label>
                                <input matInput [value]="bonDuDate" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 mt-2">
                            <mat-form-field floatLabel="always">
                                <mat-label>{{ 'kbonclient' | translate }} </mat-label>
                                <input matInput [value]="bonClientNom" disabled>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-accordion multi >
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ "kboncomticktarticle" | translate }}
                                </mat-panel-title>                
                            </mat-expansion-panel-header>
                        
                            <ng-template matExpansionPanelContent >
                                <div class="table-scroller">
                                    <div [class.mt-2]="!isMobile" >
                                        <table *ngIf="!isMobile" datatable class="row-border table hover table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                                            <thead>
                                                <tr>                                                    
                                                    <th width="14%" class="nom">{{ 'kboncomnoticket' | translate }}</th>
                                                    <th class="cp">{{ 'kbondate' | translate }}</th>
                                                    <th class="nom">{{ 'kboncomnocaisse' | translate }}</th>
                                                    <th class="nom">{{ 'kboncomhost' | translate }}</th>
                                                    <th class="nom">{{ 'kboncomhostannule' | translate }}</th>
                                                    <th class="nom">{{ 'kboncommagretour' | translate }}</th>                                                    
                                                    <th class="cp">{{ 'kboncomcodeart' | translate }}</th>
                                                    <th class="cp">{{ 'kmlibel' | translate }}</th>
                                                    <th class="cp">{{ 'kartqqty' | translate }}</th>
                                                    <th class="tel">{{ 'kbonmonatant' | translate }}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="listTicketsArticle?.length != 0 && !showArticleLoader">
                                                <tr *ngFor="let ticketArt of listTicketsArticle" >
                                                    <td class="numero" nowrap><span class="mr-2" [class.ticket_annule_box]="ticketArt.OKANNUL == '-1'"></span>{{ ticketArt.NUMTICK }}</td>
                                                    <td class="date">{{ listDateFormatter(ticketArt.TICDATE, false, false) }}</td>
                                                    <td class="numero text-right">{{ ticketArt.NUMCAISSE }}</td>                                    
                                                    <td class="numero">{{ (ticketArt.HOT_PRENOM != null)?(ticketArt.HOT_PRENOM+(ticketArt.HOT_NOM != null?' '+(ticketArt.HOT_NOM | slice:0:1)+'.':'')):'' }}</td>
                                                    <td class="numero">{{ (ticketArt.HOTANN_PRENOM != null)?(ticketArt.HOTANN_PRENOM+(ticketArt.HOTANN_NOM != null?' '+(ticketArt.HOTANN_NOM | slice:0:1)+'.':'')):'' }}</td>                                                  
                                                    <td class="numero">{{ getReturnMagasin(ticketArt) }}</td>
                                                    <td class="numero">{{ ticketArt.CODEART }}</td>
                                                    <td class="cp">{{ ticketArt.CALIB1 }}</td>
                                                    <td class="cp text-right">{{ ticketArt.VENQTE | number }}</td>
                                                    <td class="port text-right">{{ ticketArt.MNTTTC | cap_currency:null:false }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="listTicketsArticle?.length == 0 || showArticleLoader">
                                                <tr>
                                                    <td colspan="9" *ngIf="listTicketsArticle?.length == 0 && !showArticleLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                                                    <td colspan="9" *ngIf="showArticleLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <mat-list *ngIf="isMobile && (listTicketsArticle?.length == 0 || showLoader)">                  
                                            <mat-list-item *ngIf="listTicketsArticle?.length == 0 && !showLoader">                        
                                                <p matLine class="text-center">
                                                    {{ "kpnoresult" | translate  }}
                                                </p>
                                            </mat-list-item>
                        
                                            <mat-list-item *ngIf="showLoader">                        
                                                <p matLine class="text-center">
                                                    <mat-icon class="synching">sync</mat-icon>
                                                </p>
                                            </mat-list-item>
                                        </mat-list>  
                                        <mat-list *ngIf="isMobile && (listTicketsArticle?.length != 0 && !showLoader)">                   
                                            <mat-list-item  *ngFor="let ticketArt of listTicketsArticle" class="pt-2 pb-2">
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                    <h2 class="m-0">{{ ticketArt.NUMTICK }}</h2>                                                    
                                                    <h2 class="m-0">{{ listDateFormatter(ticketArt.TICDATE, false, false) }}</h2>
                                                </div>
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                    <h2 matLine class="mobile-list-title m-0">{{ ticketArt.NUMCAISSE }} 
                                                        {{ (ticketArt.HOT_PRENOM != null)?(ticketArt.HOT_PRENOM+(ticketArt.HOT_NOM != null?' '+(ticketArt.HOT_NOM | slice:0:1)+'.':'')):'' }} </h2>
                                                    <h2 matLine class="mobile-list-title text-right m-0">
                                                       {{ (ticketArt.HOTANN_PRENOM != null)?(ticketArt.HOTANN_PRENOM+(ticketArt.HOTANN_NOM != null?' '+(ticketArt.HOTANN_NOM | slice:0:1)+'.':'')):'' }} <span class="ml-2" [class.ticket_annule_box]="ticketArt.OKANNUL == '-1'"></span>
                                                    </h2>
                                                </div>
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                    <h2 matLine class="mobile-list-title m-0">{{ ticketArt.CODEART }}<br> {{ ticketArt.CALIB1 }}</h2>
                                                    <p class="m-0 text-right"> 
                                                        <span class="badge badge-secondary mr-2"></span>
                                                        <span class="badge badge-info mr-2">{{ ticketArt.VENQTE | number }}</span>
                                                        <span class="badge badge-secondary">{{ ticketArt.MNTTTC | cap_currency:null:false }}</span>
                                                    </p>                                                    
                                                </div>
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between" *ngIf="getReturnMagasin(ticketArt)">
                                                    <h2 matLine class="mobile-list-title m-0" style="width: 100%;">{{ 'kboncommagretour' | translate }}:  {{ getReturnMagasin(ticketArt) }}</h2>                                                
                                                </div>
                                                <div matLine class="align-items-center d-flex flex-row justify-content-end">
                                                    
                                                </div>
                                            </mat-list-item>
                                        </mat-list> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-9 col-12 d-none d-md-block"></div>
                                    <div class="col-md-3 col-12">
                                        <mat-form-field floatLabel="always">
                                            <mat-label>{{ 'kbontotal' | translate }} </mat-label>
                                            <input matInput [value]="totalTicketArticle | cap_currency:null:false" disabled>
                                        </mat-form-field>
                                    </div>
                                </div>  
                            </ng-template>
                        </mat-expansion-panel>
                        <mat-expansion-panel >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ "kboncomticktcompte" | translate }}
                                </mat-panel-title>                
                            </mat-expansion-panel-header>
                        
                            <ng-template matExpansionPanelContent >
                                <div class="table-scroller">
                                    <div [class.mt-2]="!isMobile">
                                        <table *ngIf="!isMobile" datatable class="row-border table hover table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                                            <thead>
                                                <tr>
                                                    <th width="14%" class="nom">{{ 'kboncomnoticket' | translate }}</th>
                                                    <th class="cp">{{ 'kbondate' | translate }}</th>
                                                    <th class="nom">{{ 'kboncomnocaisse' | translate }}</th>
                                                    <th class="nom">{{ 'kboncomhost' | translate }}</th>
                                                    <th class="nom">{{ 'kboncomhostannule' | translate }}</th>                                                    
                                                    <th class="tel">{{ 'kbonmonatant' | translate }}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="listTicketAcompte?.length != 0 && !showAcompteLoader">
                                                <tr *ngFor="let ticketAcompte of listTicketAcompte">
                                                    <td class="numero" nowrap><span class="mr-2" [class.ticket_annule_box]="ticketAcompte?.OKANNUL == '-1'"></span>{{ ticketAcompte.NUMTICK }}</td>
                                                    <td class="date">{{ listDateFormatter(ticketAcompte.TICDATE, false, false) }}</td>
                                                    <td class="cp text-right">{{ ticketAcompte.NUMCAISSE }}</td>
                                                    <td class="numero">{{ (ticketAcompte.HOT_PRENOM != null)?(ticketAcompte.HOT_PRENOM+(ticketAcompte.HOT_NOM != null?' '+(ticketAcompte.HOT_NOM | slice:0:1)+'.':'')):'' }}</td>
                                                    <td class="numero">{{ (ticketAcompte.HOTANN_PRENOM != null)?(ticketAcompte.HOTANN_PRENOM+(ticketAcompte.HOTANN_NOM != null?' '+(ticketAcompte.HOTANN_NOM | slice:0:1)+'.':'')):'' }}</td>                                                    
                                                    <td class="port text-right">{{ ticketAcompte.MNTTTC | cap_currency:null:false }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="listTicketAcompte?.length == 0 || showAcompteLoader">
                                                <tr>
                                                    <td colspan="6" *ngIf="listTicketAcompte?.length == 0 && !showAcompteLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                                                    <td colspan="6" *ngIf="showAcompteLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <mat-list *ngIf="isMobile && (listTicketAcompte?.length == 0 || showLoader)">                  
                                            <mat-list-item *ngIf="listTicketAcompte?.length == 0 && !showLoader">                        
                                                <p matLine class="text-center">
                                                    {{ "kpnoresult" | translate  }}
                                                </p>
                                            </mat-list-item>
                        
                                            <mat-list-item *ngIf="showLoader">                        
                                                <p matLine class="text-center">
                                                    <mat-icon class="synching">sync</mat-icon>
                                                </p>
                                            </mat-list-item>
                                        </mat-list>  
                                        <mat-list *ngIf="isMobile && (listTicketAcompte?.length != 0 && !showLoader)">                   
                                            <mat-list-item *ngFor="let ticketAcompte of listTicketAcompte" class="pt-2 pb-2">
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                    <h2 class="m-0">{{ ticketAcompte.NUMTICK }}</h2>
                                                    <h2 class="m-0">{{ listDateFormatter(ticketAcompte.TICDATE, false, false) }}</h2>
                                                </div>
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                    <h2 matLine class="mobile-list-title m-0">{{ ticketAcompte.NUMCAISSE }}
                                                        {{ (ticketAcompte.HOT_PRENOM != null)?(ticketAcompte.HOT_PRENOM+(ticketAcompte.HOT_NOM != null?' '+(ticketAcompte.HOT_NOM | slice:0:1)+'.':'')):'' }} 
                                                    </h2>
                                                    <h2 matLine class="mobile-list-title text-right m-0">
                                                        {{ (ticketAcompte.HOTANN_PRENOM != null)?(ticketAcompte.HOTANN_PRENOM+(ticketAcompte.HOTANN_NOM != null?' '+(ticketAcompte.HOTANN_NOM | slice:0:1)+'.':'')):'' }}<span class="ml-2" [class.ticket_annule_box]="ticketAcompte.OKANNUL == '-1'"></span>
                                                    </h2>
                                                </div>
                                                <div matLine class="align-items-center d-flex flex-row justify-content-end">
                                                    <p class="text-right m-0"> 
                                                        <span class="badge badge-secondary">{{ ticketAcompte.MNTTTC | cap_currency:null:false }}</span>
                                                    </p>
                                                </div>
                                            </mat-list-item>
                                        </mat-list> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-7 col-12 d-none d-md-block"></div>
                                    <div class="col-md-5 col-12">
                                        <mat-form-field floatLabel="always">
                                            <mat-label>{{ 'kbontotal' | translate }} </mat-label>
                                            <input matInput [value]="totalTicketAcompte | cap_currency:null:false" disabled>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-expansion-panel>
                        <mat-expansion-panel >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ "kboncommoderegle" | translate }}
                                </mat-panel-title>                
                            </mat-expansion-panel-header>
                        
                            <ng-template matExpansionPanelContent >
                                <div class="table-scroller">
                                    <div [class.mt-2]="!isMobile">
                                        <table *ngIf="!isMobile" datatable class="row-border table hover table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                                            <thead>
                                                <tr>
                                                    <th width="14%" class="nom" nowrap>{{ 'kboncomnoticket' | translate }}</th>
                                                    <th class="cp">{{ 'kbondate' | translate }}</th>                                                    
                                                    <th class="nom">{{ 'kmlibel' | translate }}</th>
                                                    <th class="tel">{{ 'kbonmonatant' | translate }}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="listReglements?.length != 0 && !showReglementLoader">
                                                <tr *ngFor="let ticketReglement of listReglements">                                                    
                                                    <td class="numero" nowrap> <span class="mr-2" [class.ticket_annule_box]="ticketReglement.OKANNUL == '-1'"></span>{{ ticketReglement.NUMTICK }}</td>
                                                    <td class="date">{{ listDateFormatter(ticketReglement.TICDATE, false, false) }}</td>
                                                    <td class="cp">{{ ticketReglement.CPLIB }}</td>
                                                    <td class="port text-right">{{ ticketReglement.MNTTTC | cap_currency:null:false }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="listReglements?.length == 0 || showReglementLoader">
                                                <tr>
                                                    <td colspan="4" *ngIf="listReglements?.length == 0 && !showReglementLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                                                    <td colspan="4" *ngIf="showReglementLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                    <td style="display: none;"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <mat-list *ngIf="isMobile && listReglements?.length == 0 || showReglementLoader">
                                            <mat-list-item *ngIf="listReglements?.length == 0 && !showReglementLoader">                        
                                                <p matLine class="text-center">
                                                    {{ "kpnoresult" | translate  }}
                                                </p>
                                            </mat-list-item>
                        
                                            <mat-list-item *ngIf="showReglementLoader">                        
                                                <p matLine class="text-center">
                                                    <mat-icon class="synching">sync</mat-icon>
                                                </p>
                                            </mat-list-item>
                                        </mat-list>  
                                        <mat-list *ngIf="isMobile && (listReglements?.length != 0 && !showReglementLoader)">                   
                                            <mat-list-item *ngFor="let ticketReglement of listReglements" class="pt-2 pb-2">                                                
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                    <h2 class="m-0">{{ ticketReglement.NUMTICK }}</h2>                                                    
                                                    <h2 class="m-0">{{ listDateFormatter(ticketReglement.TICDATE, false, false) }}</h2>
                                                </div>
                                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                    <h2 matLine class="mobile-list-title m-0">{{ ticketReglement.CPLIB }}</h2>
                                                    <h2 matLine class="mobile-list-title text-right m-0"><span class="mr-2" [class.ticket_annule_box]="ticketReglement.OKANNUL == '-1'"></span></h2>
                                                </div>
                                                <div matLine class="align-items-center d-flex flex-row justify-content-end">
                                                    <p class="text-right m-0"> 
                                                        <span class="badge badge-secondary">{{ ticketReglement.MNTTTC | cap_currency:null:false }}</span>
                                                    </p>
                                                </div>
                                            </mat-list-item>
                                        </mat-list> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-9 col-12 d-none d-md-block"></div>
                                    <div class="col-md-3 col-12">
                                        <mat-form-field floatLabel="always">
                                            <mat-label>{{ 'kbontotal' | translate }} </mat-label>
                                            <input matInput [value]="totalTicketReglement | cap_currency:null:false" disabled>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>             
            </div>
            <div class="border-top mt-2">
                <div class="row mt-4">
                    <div class="col-md-6 d-none d-md-block"></div>
                    <div class="col-md-6 col-12">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{ 'kboncomtotticket' | translate }} </mat-label>
                            <input matInput [value]="totalTickets | cap_currency:null:false" disabled>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 d-none d-md-block"></div>
                    <div class="col-md-6 col-12">
                        <mat-form-field floatLabel="always">
                            <mat-label>{{ 'kboncomtotcmd' | translate }} </mat-label>
                            <input matInput [value]="vTotCom | cap_currency:null:false" disabled>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-6 col-12">
                        <div class="d-block">
                            <div class="d-flex pos-relative flex-row align-items-center w-100 ml-2">
                                <span class="color-box ticket_annule_box"></span>
                                <span>*{{'kboncomtktannule' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12" [class.no-padding]="isMobile">
                        <h1 class="text-left" *ngIf="vTotPrPe > 0">{{'kboncommontantpre' | translate}} <span class="animated reste_pay" [class.blue]="vTotPrPe === undefined || vTotPrPe == 0">{{ vTotPrPe | cap_currency:null:false }}</span></h1>

                        <div class="align-items-center d-flex flex-row" [class.justify-content-center]="isMobile">
                            <h1 class="text-left m-0" [class.text-right]="isMobile" >{{'kboncomrestapayer' | translate}} <span class="animated reste_pay" [class.blue]="totalRestePayer === undefined || totalRestePayer == 0">{{ totalRestePayer | cap_currency:null:false }}</span></h1>

                            <button mat-button class="bt_send m-0 ml-2 reste-payer" *ngIf="paymentButtonStatus && !checkPageType" (click)="onPayButtonClicked()">
                                <i class="material-icons">price_check</i>
                                <span class="d-none d-sm-none d-md-block">
                                    {{'kpayer' | translate}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-right" *ngIf="!isMobile">
            <div class="row no-gutters">
                <div class="col-md-12 pr-2">
                    <button mat-button [mat-dialog-close] class="bt_send">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'ktcol' | translate}}
                        </span>
                    </button>
                </div>
            </div>            
        </mat-card-actions>
    </mat-card>
    </form>
</div>
