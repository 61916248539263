import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import Transporteur from '../../../../models/transporteur';
import {NgxSpinnerService} from 'ngx-spinner';
import {BonVenteService} from '../../../../providers/api/bonventeService';
import {Constants} from '../../../../providers/constants';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogsService} from '../../../../providers/api/dialogService';
import {TranslateService} from '@ngx-translate/core';
import BonPanier from '../../../../models/bonPanier';

@Component({
  selector: 'app-info-carrier',
  templateUrl: './info-carrier.component.html',
  styleUrls: ['./info-carrier.component.scss']
})
export class InfoCarrierComponent implements OnInit {

  showLoader = true;
  carrierForm: UntypedFormGroup;

  panier: BonPanier;

  transporteurList: Transporteur[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<InfoCarrierComponent>,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder,
              public bonVenteService: BonVenteService) { }

  ngOnInit(): void {
    this.panier = this.data.panier;

    this.carrierForm = this.formBuilder.group({
      TRANSPORTEUR: ['']
    });

    if (this.panier.transporteur) {
      const [CARRIER_CNUF] = this.panier.transporteur.split('/');
      this.carrierForm.patchValue({
        TRANSPORTEUR: CARRIER_CNUF
      });
    }

    this.loadDependencies();
  }

  async loadDependencies() {
    await this.getTransporteurList();

    this.showLoader = false;
  }

  __transporteurValue(item: Transporteur): string {
    if (item.DEPT) {
      return item.CNUF + '/' + item.DEPT;
    }

    return item.CNUF;
  }

  carrierFormSubmitter() {
    if (this.carrierForm.invalid) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
      return;
    }

    const formData = this.carrierForm.getRawValue();
    let transporteurRow = null;
    let transporteurValue = '';

    if (formData.TRANSPORTEUR !== '') {
      transporteurRow = this.transporteurList.find(r => r.CNUF === formData.TRANSPORTEUR);
      transporteurValue = this.__transporteurValue(transporteurRow);
    }

    this.dialogRef.close({ status: 'success', data: transporteurValue, transporteurData: transporteurRow });
  }

  getTransporteurList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const IDSOC = Constants.IDSOC;
      this.bonVenteService.getAllTransporteur(IDSOC).subscribe(
          (res) => {
            if (res.success === 'ok') {
              this.transporteurList = res.data;
            }
            resolve(res);
          },
          err => {
            reject(err);
          }
      )
    });
  }
}
