import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  url: URL;
  isMobile: boolean;

  type: 'PDF' | 'XL' = 'PDF';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private deviceDetector: DeviceDetectorService) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    
    if(this.data !== undefined && this.data.type != undefined) {
      this.type = this.data.type;
    }

    if(this.data !== undefined && this.data.url != undefined) {
      if(this.type === 'PDF') {
        this.url = new URL(this.data.url);
      } else {
        this.url = this.data.url;
      }
    }
  }

}
