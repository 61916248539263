<h1 mat-dialog-title class="text-center">{{ 'kmaddpattern' | translate }}</h1>
<div mat-dialog-content>
    <form [formGroup]="editMotifForm" (ngSubmit)="editFormSubmission()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field>
                        <mat-label>{{ 'kmaddpattern' | translate }}</mat-label>
                        <mat-select [placeholder]="'kmaddpattern' | translate" (selectionChange)="onMotifChanged($event)" color="primary" formControlName="PRE_MOTLIB">
                            <mat-option *ngIf="listMotifs !== undefined && (listMotifs === null || listMotifs.length === 0)" value="free_text">< {{'kmaddlibre' | translate}} ></mat-option>

                            <mat-option [value]="item.MOTLIB" *ngFor="let item of listMotifs">{{ item.MOTLIB }}</mat-option>
                        </mat-select>                                
                    </mat-form-field>
                </div>
                <div class="col-md-12" *ngIf="customMotif">
                    <mat-form-field>
                        <input matInput formControlName="MOTLIB" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button [disabled]="editMotifForm.invalid" type="submit">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kuok' | translate}}
                        </span> 
                    </button>
                    <button class="bt_valid ml-3" color="danger" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kucancel' | translate}}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>