import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { isNil, reverse, stubTrue } from 'lodash';
import { UntypedFormBuilder, NgForm } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SousFamile } from 'models/articles';
import { MagasinStructModel } from 'models/magasinStructModel';
import {
  TreeviewItem, TreeviewConfig, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem, TreeviewI18n
} from 'ngx-treeview2';
import { TreeviewTranslation } from 'providers/treeview-translation';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import { UtilisatuersService } from 'providers/api/utilisatuersService';
import { TranslateService } from '@ngx-translate/core';
import { ArticleService } from 'providers/api/articleService';
import { NgxSpinnerService } from 'ngx-spinner';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import { DialogsService } from 'providers/api/dialogService';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-article-filter',
  templateUrl: './article-filter.component.html',
  styleUrls: ['./article-filter.component.scss'],
  providers: [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }, 
      { provide: TreeviewI18n, useClass: TreeviewTranslation }, TreeviewComponent,
      UtilisatuersService, ArticleService
  ]
})
export class ArticleFilterComponent implements OnInit, AfterViewInit {

  @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;
  @ViewChild('f', { static: true }) searchFormRef: NgForm;

  searchForm: any;  
  oldFormData: any;

  user: any;
  USERCMINT: any;

  magasinStructure: MagasinStructModel[];
  treeItems: TreeviewItem[];
  filteredTreeItems: TreeviewItem[];
  treeValues: string[];
  totalSelected: number;

  listSousFamile: SousFamile[];
  sousfamileTreeItems: TreeviewItem[];
  sousfamileTreeValues: string[];
  sousfamileTotalSelected: number;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 280           
  });

  sousTreeConfig = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 345           
  });

  // Load Dependencies itself

  orderBySearchList = [
    {
    label: "kartcode",
    value: Constants.ARTICLE_SEARCH_ORDER_CODE
    },
    {
    label: "kartlib",
    value: Constants.ARTICLE_SEARCH_ORDER_LABEL
    },
  ];

  previousTreeValue: string = "";

  loadType: 'prefetch' | 'new_load' = 'prefetch';

  @ViewChild('magasinTree', { static: true }) magTreeComponent: TreeviewComponent;
  @ViewChild('sousTree', { static: true }) sousTreeComponent: TreeviewComponent;

  searchStructureMarchandiseLabel: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ArticleFilterComponent>,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private utilisatuersService: UtilisatuersService,
    private translateService: TranslateService,
    private articleService: ArticleService,
    private loadingService: NgxSpinnerService,
    private dialogService: DialogsService,
    private dialog: MatDialog,
    private storageService: StorageService,) { }

  ngOnInit(): void {    

    this.searchForm = this.formBuilder.group({
      NOM: [''],
      LIV: [false],
      ACTIF: [true],                        
      CDART: [''],
      CODEEXT: [''],

      STRUCTU_LABEL: [''],
      MTTTCMIN: [''],
      MTTTCMAX: [''],
      STOCK_SUPERIOR: [false],

      STRUCTU: ['[]'],
      CSFSFAM: ['[]'],
      IDMAG: [this.USERCMINT],            
      IDSOC: [Constants.IDSOC],
      ORDERBY: [Constants.ARTICLE_SEARCH_ORDER_LABEL],
      sort: ['asc']
    });

    this.loadType = this.data.fetchType;
    this.loadDependencies(this.loadType);
  }

  ngAfterViewInit() {
    if(this.filterFirst) {
      this.filterFirst.nativeElement.readOnly = true;
      this.filterFirst.nativeElement.setAttribute('inputmode', 'none');
      
      setTimeout(() => {
        this.filterFirst.nativeElement.focus();
        this.filterFirst.nativeElement.select();
        this.filterFirst.nativeElement.setAttribute('inputmode', 'text');
        this.filterFirst.nativeElement.readOnly = false;
      }, 50);
    }
  }

  async loadDependencies(type: 'prefetch' | 'new_load' = 'prefetch') {
    if(type === undefined || type === 'prefetch') {
      this.orderBySearchList = this.data.orderBySearchList;    
      this.user = this.data.user;
      this.USERCMINT = this.data.USERCMINT;
  
      this.config = this.data.config;
      this.sousTreeConfig = this.data.sousTreeConfig;
  
      this.config.maxHeight = undefined;
      this.sousTreeConfig.maxHeight = undefined;
  
      this.magasinStructure = this.data.magasinStructure;
      this.treeItems = this.data.treeItems;
      this.filteredTreeItems = this.treeItems;
      this.treeValues = this.data.treeValues;
      this.totalSelected = this.data.totalSelected;
      this.listSousFamile = this.data.listSousFamile;
      this.sousfamileTreeItems = this.data.sousfamileTreeItems;
      this.sousfamileTreeValues = this.data.sousfamileTreeValues;
      this.sousfamileTotalSelected = this.data.sousfamileTotalSelected;

      if(this.filteredTreeItems){
        this.filteredTreeItems[0].collapsed = true;
      }
      if(this.sousfamileTreeItems){
        this.sousfamileTreeItems[0].collapsed = true;
      }
      this.oldFormData = this.data.form_data;

      this.searchForm.patchValue({
        NOM: this.oldFormData.NOM,
        LIV: this.oldFormData.LIV,
        ACTIF: this.oldFormData.ACTIF,
        CDART: this.oldFormData.CDART,
        CODEEXT: this.oldFormData.CODEEXT,

        STRUCTU_LABEL: this.oldFormData.STRUCTU_LABEL,
        MTTTCMIN: this.oldFormData.MTTTCMIN,
        MTTTCMAX: this.oldFormData.MTTTCMAX,
        STOCK_SUPERIOR: this.oldFormData.STOCK_SUPERIOR,

        STRUCTU: this.oldFormData.STRUCTU,
        CSFSFAM: this.oldFormData.CSFSFAM,
        IDMAG: this.oldFormData.IDMAG,            
        IDSOC: this.oldFormData.IDSOC,
        ORDERBY: this.oldFormData.ORDERBY,
        sort: this.oldFormData.sort
      });
    } else {
      this.user = this.userService.getCurrentUser();          
      this.USERCMINT = this.user.CMINT;
  
      this.previousTreeValue = this.storageService.getItem('USER_STRUCT');
      await this.getListMagasinStructure();
      await this.getListSousFamile();
  
      let sousFamileChildrens: any[] = [];
  
      if(this.listSousFamile) {
        for(let i=0;i<this.listSousFamile.length;i++) {
          sousFamileChildrens.push({
            text: this.listSousFamile[i].CSFSFAM+' - '+this.listSousFamile[i].CSFLIB1,
            value: this.listSousFamile[i].CSFSFAM
          });
        }
      }
  
      this.sousfamileTreeItems = [
        new TreeviewItem({
          text: this.translateService.instant('kartsoufami'),
          value: "",
          checked: true,
          children: sousFamileChildrens           
        })
      ];

      this.changeSousFamileTreeOrder({ value: 'CODE' });
  
      this.treeValues = [];
      this.sousfamileTreeValues = [];
  
      if(this.filteredTreeItems){
        this.filteredTreeItems[0].collapsed = true;
      }
      if(this.sousfamileTreeItems){
        this.sousfamileTreeItems[0].collapsed = true;
      }

      this.loadingService.hide();
    }
    
  }

  headerSearchFormSubmitter () {
    this.userService.headerSearchInputValue.next(undefined);

    this.dialogRef.close({
      status: 'success', 
      form_data: this.searchForm.value,
      magTreeComponent: this.magTreeComponent,
      sousTreeComponent: this.sousTreeComponent,
      treeItems: this.filteredTreeItems,
      treeValues: this.treeValues,
      totalSelected: this.totalSelected,
      listSousFamile: this.listSousFamile,
      sousfamileTreeValues: this.sousfamileTreeValues,
      sousfamileTreeItems: this.sousfamileTreeItems,
      sousfamileTotalSelected: this.sousfamileTotalSelected,    
    });
  }

  async getListMagasinStructure() {
    return new Promise<void>((resolve, reject) => {
      let httpData = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        CIINT: this.user.CIINT
      };

      this.utilisatuersService.getMagasinStructure(httpData).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            this.magasinStructure = res.data;                 
            
            const previousTreeValue = this.splitUpStructString(this.previousTreeValue);

            this.treeItems = [
              new TreeviewItem({
                text: this.translateService.instant('kartmar'),
                value: '',
                checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                children: this.__structurizeMarchandiseData(this.magasinStructure, previousTreeValue)
              })
            ];

            this.filteredTreeItems = this.treeItems;

            this.changeMagasinStructTreeOrder({ value: 'CODE' });

            this.treeItems[0].collapsed = true;
            this.filteredTreeItems[0].collapsed = true;

            resolve();            
          }
        },
        err => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });    
  }

  __isolateStructureData = (magasinStructure: MagasinStructModel[], level: 1 | 2 | 3 | 4 | 5 = 1) => {
    const filteredStruct = magasinStructure.filter(
      (res: MagasinStructModel) => {
        if(level === 1) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 == '0');
        }

        if(level === 2) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 == '0');
        }

        if(level === 3) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 == '0');
        }

        if(level === 4) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 != '0' && res.CSTNIV5 == '0');
        }

        if(level === 5) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 != '0' && res.CSTNIV5 != '0');
        }
      }
    );

    return filteredStruct;
  }

  __structurizeMarchandiseData = (magasinStructure: MagasinStructModel[], previousTreeValue: Array<{ level_1: string, level_2?: string, level_3?: string, level_4?: string, level_5?: string }> | "") => {
  
    const level_1 = this.__isolateStructureData(magasinStructure, 1);
    const level_2 = this.__isolateStructureData(magasinStructure, 2);
    const level_3 = this.__isolateStructureData(magasinStructure, 3);
    const level_4 = this.__isolateStructureData(magasinStructure, 4);
    const level_5 = this.__isolateStructureData(magasinStructure, 5);

    const parentTreeItems: TreeviewItem[] = [];

    level_1.forEach(
      (row_1: MagasinStructModel) => {

        const level_1_prev_filter = (previousTreeValue !== "" && previousTreeValue.length > 0)?previousTreeValue.filter(tree_row => tree_row.level_1 === row_1.CSTNIV1):null;

        const level_2_filtered = level_2.filter((_r) => _r.CSTNIV1 == row_1.CSTNIV1);
        const level_1_childrens: any[] = [];

        if(level_2_filtered) {
          level_2_filtered.forEach(
            (row_2: MagasinStructModel) => {

              const level_2_prev_filter = level_1_prev_filter !== null?level_1_prev_filter.filter(tree_row => tree_row.level_2 === row_2.CSTNIV2):null;

              const level_3_filtered = level_3.filter((_r) => _r.CSTNIV1 == row_2.CSTNIV1 && _r.CSTNIV2 == row_2.CSTNIV2);
              const level_2_childrens: any[] = [];

              if(level_3_filtered) {
                level_3_filtered.forEach(
                  (row_3: MagasinStructModel) => {

                    const level_3_prev_filter = (level_2_prev_filter && level_2_prev_filter !== null)?level_2_prev_filter.filter(tree_row => tree_row.level_3 === row_3.CSTNIV3):null;

                    const level_4_filtered = level_4.filter((_r) => _r.CSTNIV1 == row_3.CSTNIV1 && _r.CSTNIV2 == row_3.CSTNIV2 && _r.CSTNIV3 == row_3.CSTNIV3);
                    const level_3_childrens: any[] = [];

                    if(level_4_filtered) {
                      level_4_filtered.forEach(
                        (row_4: MagasinStructModel) => {

                          const level_4_prev_filter = (level_3_prev_filter && level_3_prev_filter !== null)?level_3_prev_filter.filter(tree_row => tree_row.level_4 === row_4.CSTNIV4):null;

                          const level_5_filtered = level_5.filter((_r) => _r.CSTNIV1 == row_4.CSTNIV1 && _r.CSTNIV2 == row_4.CSTNIV2 && _r.CSTNIV3 == row_4.CSTNIV3 && _r.CSTNIV4 == row_4.CSTNIV4);
                          const level_4_childrens: any[] = [];

                          if(level_5_filtered) {
                            level_5_filtered.forEach(
                              (row_5: MagasinStructModel) => {

                                const level_5_prev_filter = (level_4_prev_filter && level_4_prev_filter !== null)?level_4_prev_filter.find(tree_row => tree_row.level_5 === row_5.CSTNIV5):null;                            

                                level_4_childrens.push({
                                  text: row_5.CSTNIV5+' - '+row_5.CSTLIB1,
                                  value: row_5.CSTNIV5,
                                  checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:((level_5_prev_filter && level_5_prev_filter !== null)?(level_5_prev_filter.level_5?true:false):false)
                                });
                              }
                            )
                          }

                          level_3_childrens.push({
                            text: row_4.CSTNIV4+' - '+row_4.CSTLIB1,
                            value: row_4.CSTNIV4,
                            checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                            collapsed: true,
                            children: level_4_childrens
                          });
                        }
                      )
                    }

                    level_2_childrens.push({
                      text: row_3.CSTNIV3+' - '+row_3.CSTLIB1,
                      value: row_3.CSTNIV3,
                      checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                      collapsed: true,
                      children: level_3_childrens
                    });
                  }
                )
              }                      

              level_1_childrens.push({
                text: row_2.CSTNIV2+' - '+row_2.CSTLIB1,
                value: row_2.CSTNIV2,
                checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                collapsed: true,
                children: level_2_childrens
              });
            }
          )
        }                

        parentTreeItems.push(new TreeviewItem({
          text: row_1.CSTNIV1+' - '+row_1.CSTLIB1,
          value: row_1.CSTNIV1,
          checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
          collapsed: true,
          children: level_1_childrens
        }));
        
      }
    )

    return parentTreeItems;
  }

  async getListSousFamile(): Promise<void> {
    return new Promise((resolve, reject) => {
      let httpData = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        CIINT: this.user.CIINT
      };

      this.articleService.getSousFamileList(httpData).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            this.listSousFamile = res.data;              
            resolve();            
          }
        },
        err => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });
  }

  splitUpStructString(previous_string: string):Array<{ level_1: string, level_2?: string, level_3?: string, level_4?: string, level_5?: string }> | "" {    
    const splittedData: any[] = [];

    if(previous_string) {
      const tree_values_split_up = previous_string.split('#');      
      tree_values_split_up.forEach(
        (row) => {
          const [level_1, level_2, level_3, level_4, level_5] = row.split(';');
          
          splittedData.push({
            level_1,
            level_2,
            level_3,
            level_4,
            level_5
          });                
        }
      );
    }
    
    return splittedData || "";
  }

  searchStructureMarchandise(search: string): void {

    if(search !== "") {
      let cumulatedKeys = [];
      const oldKeys = this.storageService.getItem('STRUCTU_KEYWORDS');
      if(oldKeys !== undefined && oldKeys != null && oldKeys != 'null') {
        const oldKeysSeparated = oldKeys.split(', ');
        const alreadyExist = oldKeysSeparated.find((key) => key.toLowerCase() == search.toLowerCase());
  
        if(alreadyExist !== undefined && alreadyExist !== null) {
          // Keyword already exists in the array
          cumulatedKeys = oldKeysSeparated;
        } else {
          // New keyword
          cumulatedKeys = [...oldKeysSeparated, search.toLowerCase()];
        }
      } else {
        // First time search
        cumulatedKeys = [search.toLowerCase()];
      }

      this.storageService.setItem('STRUCTU_KEYWORDS', cumulatedKeys.join(', '));
      console.log(cumulatedKeys);
    }

    this.filterStructu(search, true);
  }

  _clearStructuLabel() {
    this.searchStructureMarchandiseLabel = '';
    this.filterStructu(this.searchStructureMarchandiseLabel);
  }

  _resetStructuTree() {
    this.searchStructureMarchandiseLabel = '';
    this.filterStructu(this.searchStructureMarchandiseLabel);
    this.storageService.removeItem('STRUCTU_KEYWORDS');

    this.searchForm.patchValue({
      STRUCTU_LABEL: '',
      STRUCTU: '[]',
      
      STRUCT_ORDER: 'CODE',
    });

    this.changeMagasinStructTreeOrder({ value: 'CODE' });

    this.filteredTreeItems[0].checked = true;
    this.filteredTreeItems[0].collapsed = false;

    let childs = this.filteredTreeItems[0].children;
    if(childs) {            
      childs.forEach(
        (row) => {
          row.checked = true;
          row.collapsed = true;

          row.setCheckedRecursive(true);
          row.setCollapsedRecursive(true);
        }
      );
    }
  }

  filterStructu = (search: string, checkable = false) => {
    const allChecked = this.__checkAllStructuChecked();
    const anythingChecked = this.__checkAnyStructuChecked();

    if(allChecked === true) {
      // Structure Marchandise - All checked
      this.filteredTreeItems[0].setCheckedRecursive(false); // to Uncheck all the tree items if all boxes have already checked
      this.__filterBySearchedString(search, checkable);
    } else {
      if(anythingChecked === true) {
        // Structure Marchandise - Not all checked and something checked
        this.__filterBySearchedString(search, checkable);
      } else {
        // Structure Marchandise - Nothing checked
        this.__filterBySearchedString(search, checkable);
      }
    }

    this.filteredTreeItems[0].collapsed = false;
  }

  __filterBySearchedString(search: string = '', checkable = false) {
    this.filteredTreeItems[0].children.map(
      (_pr) => {
        let childAvail = false;
        if(_pr.children !== undefined && _pr.children !== null && _pr.children.length > 0) {
          _pr.children.map(
            (_cr) => {
              let subChildAvail = false;
              if(_cr.children !== undefined && _cr.children !== null && _cr.children.length > 0) {
                _cr.children.map(
                  (_scr) => {
                    let level_4_child_avail = false;
                    if(_scr.children !== undefined && _scr.children !== null && _scr.children.length > 0) {
                      _scr.children.map(
                        (_sscr) => {
                          let level_5_child_avail = false;
                          if(_sscr.children !== undefined && _sscr.children !== null && _sscr.children.length > 0) {
                            _sscr.children.map(
                              (_ssscr) => {

                                if(search !== '' && search.length > 2 && _ssscr.text.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                                  level_5_child_avail = true;
                                  _ssscr.collapsed = false;
                                  if(checkable) {
                                    _ssscr.checked = true; // !_ssscr.checked
                                  }

                                  return _ssscr;
                                }

                                if(search !== '' && search.length > 2 && _ssscr.checked) {
                                  _ssscr.collapsed = false;
                                  return;  
                                }
                    
                                _ssscr.collapsed = true;

                                return _ssscr;
                              }
                            );
                          }

                          if(level_5_child_avail) {
                            _sscr.correctChecked();
                          }

                          if(level_5_child_avail || (search !== '' && search.length > 2 && _sscr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                            level_4_child_avail = true;
                            _sscr.collapsed = false;
                            _sscr.setCollapsedRecursive(false);

                            if((search !== '' && search.length > 2 && _sscr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                              _sscr.setCheckedRecursive(true);
                            }
              
                            return _sscr;
                          }

                          if(search !== '' && search.length > 2 && (_sscr.checked || _sscr.indeterminate)) {
                            _sscr.setCollapsedRecursive(false);
                            return;  
                          }

                          _sscr.collapsed = true;
                          _sscr.setCollapsedRecursive(true);

                          return _sscr;
                        }
                      );
                    }

                    if(level_4_child_avail) {
                      _scr.correctChecked();
                    }

                    if(level_4_child_avail || (search !== '' && search.length > 2 && _scr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                      subChildAvail = true;
                      _scr.collapsed = false;
                      _scr.setCollapsedRecursive(false);

                      if((search !== '' && search.length > 2 && _scr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                        _scr.setCheckedRecursive(true);
                      }
        
                      return _scr;
                    }

                    if(search !== '' && search.length > 2 && (_scr.checked || _scr.indeterminate)) {
                      _scr.setCollapsedRecursive(false);
                      return;  
                    }
        
                    _scr.collapsed = true;
                    _scr.setCollapsedRecursive(true);
                    return _scr;
                  }
                );
              }

              if(subChildAvail) {
                _cr.correctChecked();
              }

              if(subChildAvail || (search !== '' && search.length > 2 && _cr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                childAvail = true;
                _cr.collapsed = false;
                _cr.setCollapsedRecursive(false);

                if((search !== '' && search.length > 2 && _cr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                  _cr.setCheckedRecursive(true);
                }
  
                return _cr;
              }

              if(search !== '' && search.length > 2 && (_cr.checked || _cr.indeterminate)) {
                _cr.setCollapsedRecursive(false);
                return;  
              }
  
              _cr.collapsed = true;
              _cr.setCollapsedRecursive(true);
              return _cr;
            }
          )
        }

        if(childAvail) {
          _pr.correctChecked();
        }

        if(childAvail || (search !== '' && search.length > 2 && _pr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
          _pr.collapsed = false;

          if((search !== '' && search.length > 2 && _pr.text.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
            _pr.setCheckedRecursive(true);
          }

          return _pr;
        }

        if(search !== '' && search.length > 2 && (_pr.checked || _pr.indeterminate)) {
          _pr.setCollapsedRecursive(false);
          return;  
        }

        _pr.collapsed = true;
        return _pr;
      }
    );

    if(checkable) {
      this.filteredTreeItems[0].correctChecked();
      this.magTreeComponent.raiseSelectedChange();
    }
  }

  __checkAllStructuChecked = () => {
    return this.filteredTreeItems[0].checked;
  }

  __checkAnyStructuChecked = () => {
    return this.filteredTreeItems[0].indeterminate;
  }

  sortByString = (a: string, b: string) => { 
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
  }

  sortByNumber = (a: number, b: number) => {
    return a - b; 
  }

  structureSorting(event: any, a: TreeviewItem, b: TreeviewItem) {
    if(a.children) {
      a.children.sort(
        (a: TreeviewItem, b: TreeviewItem) => {            
          return this.structureSorting(event, a, b);
        }
      );
    }

    if(b.children) {
      b.children.sort(
        (a: TreeviewItem, b: TreeviewItem) => {
          return this.structureSorting(event, a, b);               
        }
      );
    }      

    const a_text = a.text.split(' - ')[1];
    const a_value = a.value;

    const b_text = b.text.split(' - ')[1];
    const b_value = b.value;

    if(event.value === 'CODE' || event.value === 'SOUS_CODE') { // Sorting by CODE
      return this.sortByNumber(a_value, b_value);
    } else if (event.value === 'LABEL' || event.value === 'SOUS_LABEL') { // Sorting by Label
      return this.sortByString(a_text, b_text);
    } 
  }

  changeMagasinStructTreeOrder(event: any) {    
    if(this.filteredTreeItems[0] === undefined || this.filteredTreeItems[0] == null || this.filteredTreeItems[0].children == null || this.filteredTreeItems[0].children == undefined || this.filteredTreeItems[0].children.length == 0) return;

    this.filteredTreeItems[0].children.sort(
      (a: TreeviewItem, b: TreeviewItem) => {        
        return this.structureSorting(event, a, b);
      }
    );
  }

  changeSousFamileTreeOrder(event: any) {
    if(this.sousfamileTreeItems[0].children){
    this.sousfamileTreeItems[0].children.sort(
      (a: TreeviewItem, b: TreeviewItem) => { 
        return this.structureSorting(event, a, b);                                              
      }
    );
    }
  }

  onTreeValueChanged = (downlineItems: DownlineTreeviewItem[]) => {
      let tempTreeValues: string[] = [];   

      this.treeValues = [];
      
      this.totalSelected = downlineItems.length;

      downlineItems.forEach((downlineItem: DownlineTreeviewItem) => {      
        const item = downlineItem.item;          

        const value = item.value;      
        const parent_values = [value];
        let parent = downlineItem.parent;
        
        while (!isNil(parent)) {        
            parent_values.push(parent.item.value);
            parent = parent.parent;                   
        }
        
        const reverseValues = reverse(parent_values);      

        const row = `${reverseValues.join(";")}`;        
        tempTreeValues.push(row.replace(/^;/, ''));
      });

      this.treeValues = tempTreeValues;
      if(this.filteredTreeItems[0].checked === true && !this.filteredTreeItems[0].indeterminate) {
        this.searchForm.patchValue({ STRUCTU: '[]' });
      } else {
        this.searchForm.patchValue({ STRUCTU: JSON.stringify(this.treeValues) });
      }
  }

  onSousTreeValueChanged = (downlineItems: DownlineTreeviewItem[]) => {
    this.sousfamileTreeValues = [];    
    
    this.sousfamileTotalSelected = downlineItems.length;

    downlineItems.forEach((downlineItem: DownlineTreeviewItem) => {      
      const item = downlineItem.item;          

      const value = item.value;      
      const parent_values = [value];
      let parent = downlineItem.parent;
      
      while (!isNil(parent)) {        
          parent_values.push(parent.item.value);
          parent = parent.parent;                   
      }
      
      const reverseValues = reverse(parent_values);      

      const row = `${reverseValues.join(";")}`;        
      this.sousfamileTreeValues.push(row.replace(/^;/, ''));        
    });      
    
    if(this.sousfamileTreeItems[0].checked === true && !this.sousfamileTreeItems[0].indeterminate) {
      this.searchForm.patchValue({
        CSFSFAM: '[]'
      });
    } else {        
      this.searchForm.patchValue({
        CSFSFAM: JSON.stringify(this.sousfamileTreeValues)
      });
    }
  }

  onCodeEntered(e: any) {
    const cdart = this.searchForm.get('CDART');
    this._barCodeEmulationLogicCheck(cdart.value);
  }

  _barCodeEmulationLogicCheck = (value: string, rapid = false, nomField = false) => {
    if(value !== undefined && value != null) {
      
      if(value.indexOf('~') > -1) {
        const [CDART, PRIX] = value.split('~');
        this.searchForm.patchValue({ CDART });

        if(nomField) {
          this.searchForm.patchValue({ NOM: '' });
        }

        if(rapid) {
          this.userService.headerSearchInputValue.next(CDART);
        }

        if(this.searchFormRef) {
          this.searchFormRef.ngSubmit.emit();
        } else {
          this.headerSearchFormSubmitter()
        }

        return;  
      }

      if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
        let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
        CDART = CDART.replace(/^0+/, '');

        this.searchForm.patchValue({ CDART });

        if(nomField) {
          this.searchForm.patchValue({ NOM: '' });
        }

        if(rapid) {
          this.userService.headerSearchInputValue.next(CDART);
        }

        if(this.searchFormRef) {
          this.searchFormRef.ngSubmit.emit();
        } else {
          this.headerSearchFormSubmitter()
        }

        return;
      }

      if(this.searchFormRef) {
        this.searchFormRef.ngSubmit.emit();
      } else {
        this.headerSearchFormSubmitter()
      }
    }
  }

  _barCodeLogicCheck = (value: string) => {
    if(value !== undefined && value != null) {
      
      if(value.indexOf('~') > -1) {
        const [CDART, PRIX] = value.split('~');
        this.searchForm.patchValue({ CDART });
        return;
      }

      if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
        let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
        CDART = CDART.replace(/^0+/, '')

        this.searchForm.patchValue({ CDART });
        return;
      }

      this.searchForm.patchValue({ CDART: value });
    }
  }
  
  openScanArticle() {
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: '95vh',
        autoFocus: false
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        this._barCodeLogicCheck(result.data);
      }      
    });
  }

  resetForm = () => {
    this.userService.headerSearchInputValue.next(undefined);
    this.searchForm.reset();
    this.searchForm.patchValue({ 
      ACTIF: true, 
      ORDERBY: Constants.ARTICLE_SEARCH_ORDER_LABEL,
      sort: 'asc',
      STRUCT_ORDER: 'CODE',
      SOUS_ORDER: 'SOUS_CODE',
    });

    this.changeMagasinStructTreeOrder({ value: 'CODE' });
    this.changeSousFamileTreeOrder({ value: 'SOUS_CODE' });

    this.filteredTreeItems[0].checked = true;
    this.filteredTreeItems[0].collapsed = false;
    this.storageService.removeItem('STRUCTU_KEYWORDS');
    let sousChilds = this.sousfamileTreeItems[0].children;
    let childs = this.filteredTreeItems[0].children;
    if(childs) {            
      childs.forEach(
        (row) => {
          let sub_childs = row.children;
          row.checked = true;
          row.collapsed = true;

          if(sub_childs) {
            sub_childs.forEach(
              (sub_row) => {
                sub_row.checked = true;
                sub_row.collapsed = true;

                if(sub_row.children) {
                  sub_row.children.forEach((lastSubRow => {
                    lastSubRow.checked = true;
                    lastSubRow.collapsed = true;
                  }))
                }
              }
            )
          }

        }
      );
    }

    if(sousChilds) {
      this.sousfamileTreeItems[0].checked = true;
      sousChilds.forEach(
        (row) => {
          row.checked = true;
          row.collapsed = true;
        }
      )
    }

    this.treeItems[0].collapsed = true;
  }

}
