<div mat-dialog-content>
    <div class="bloc_container padding_15">
        <form [formGroup]="confirmationForm">
            <div [class.container]="!isMobile">
                <div class="row" [class.no-gutters]="isMobile">
                    <!-- Title section STARTS -->
                    <div class="col-md-12">
                        <h1 class="confirmation-title" *ngIf="returnedStatus && returnedStatus >= 3 && returnedStatus <= 5">{{ 'kfound_similar_address' | translate }}</h1>
                        <h1 class="confirmation-title" *ngIf="returnedStatus && returnedStatus >= 6 && returnedStatus <= 10">{{ 'knot_found_similar_address' | translate }}</h1>
                    </div>
                    <!-- Title section ENDS -->

                    <!-- Address Display section STARTS -->
                    <div class="col-md-12">
                        <div class="address-container entered">
                            <h3 class="m-0 text-center">{{ 'kaddress_you_entered' | translate }}</h3>
                            <h5 class="address-display">{{ enteredAddressFormatted }}</h5>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="returnedStatus && returnedStatus >= 3 && returnedStatus <= 5">
                        <div class="address-container returned">
                            <h3 class="m-0 text-center">{{ 'kaddress_we_suggest' | translate }}</h3>
                            <h5 class="address-display green-border">{{ returnedAddressFormatted }}</h5>
                        </div>
                    </div>
                    <!-- Address Display section ENDS -->

                    <!-- Suggestion section STARTS -->
                    <div class="col-md-12" *ngIf="returnedStatus && returnedStatus >= 6 && returnedStatus <= 10">
                        <div class="suggestion-container">
                            <h3 class="suggestion-heading text-center">{{ 'ktips_to_change_input' | translate }}</h3>
                            <ul class="suggestion-points green-border">
                                <li class="suggestion-point">{{ 'kdelivery_only_on_territory' | translate }}</li>
                                <li class="suggestion-point">{{ 'kspelling_street_city_name' | translate }}</li>
                                <li class="suggestion-point">{{ 'kavoid_place_prefer_postal_code' | translate }}</li>
                                <li class="suggestion-point">{{ 'kcheck_postal_code_with_city' | translate }}</li>
                            </ul>
                        </div>
                    </div>
                    <!-- Suggestion section ENDS -->

                    <!-- Footer section STARTS -->
                    <div class="col-md-12" *ngIf="returnedStatus && returnedStatus >= 3 && returnedStatus <= 5">
                        <div class="confirmation-summary">
                            <h3 class="confirmation-subtitle">{{ 'kchoose_proposed_address_confirm' | translate }}</h3>
                            <h3 class="confirmation-subtitle">{{ 'kwish_to_modify_entry' | translate }}</h3>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="returnedStatus && returnedStatus >= 6 && returnedStatus <= 10">
                        <div class="confirmation-summary">
                            <h3 class="confirmation-subtitle">{{ 'kcheck_address_confirm_it' | translate }}</h3>
                        </div>
                    </div>
                    <!-- Footer section ENDS -->
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-12 text-center">
                <button class="bt_valid bt_height" mat-button type="button" mat-dialog-close="returned" *ngIf="returnedStatus && returnedStatus >= 3 && returnedStatus <= 5">
                    <span class="">
                        {{ "kchoose_proposed_address" | translate }}
                    </span>
                </button>
                <button class="bt_valid bt_height" mat-button type="button" mat-dialog-close="modifier" *ngIf="returnedStatus && returnedStatus >= 6 && returnedStatus <= 10">
                    <span class="">
                        {{ "kmodify_my_address" | translate }}
                    </span>
                </button>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 text-center">
                <span class="bt_valid" mat-dialog-close="entered">
                    <span class="bt_confirm">
                        {{ "kconfirm_my_address" | translate }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>