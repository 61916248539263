<h1 mat-dialog-title *ngIf="displayType === 'popup'" class="text-center">{{'kdeviscodecol' | translate}}</h1>
<div mat-dialog-content>
    <div class="bg_light" [class.bloc_container]="displayType === 'popup'" [class.padding_15]="displayType === 'popup'">
        <div class="colors-container" *ngIf="type === 'commande' || type === 'retrait-marchandise' || type === 'gestion-des-e2' || type === 'relance' || type === 'supprimees' || type === 'historique'">
            <h3><span class="color-row cde_en_cours"></span>{{'kboncomcdecours' | translate}}</h3>
            <h3><span class="color-row cde_a_relancer"></span>{{'kboncomcderelance' | translate}}</h3>
            <h3><span class="color-row e2_en_cours"></span>{{'kboncome2cours' | translate}}</h3>
            <h3><span class="color-row cde_soldee"></span>{{'kboncomecdesolde' | translate}}</h3>
            <h3><span class="color-row cde_cloture_contient"></span>{{'kboncomcloturecontient' | translate}}</h3>
            <h3><span class="color-row solde_partiel"></span>{{'ksolde_partiel_etat' | translate}}</h3>
            <h3><span class="color-row liv_deportee"></span>{{'kboncomelivdeporte' | translate}}</h3>
            <h3><span class="color-row liv_directe"></span>{{'kboncomelivdirect' | translate}}</h3>
            <h3><span class="color-row e2_partielle"></span>{{'kboncome2partial' | translate}}</h3>
            <h3><span class="color-row som_tick_art"></span>{{'kboncomsomticket' | translate}}</h3>
        </div>

        <div class="colors-container" [class.flex-row]="displayType !== 'popup'" *ngIf="type === 'search-gestion-des-e2'">
            <h3 [class]="displayType !== 'popup'?'d-flex align-items-center':''">
                <span class="color-row e2_sans_stock"></span>{{'ke2sansstock' | translate}}
            </h3>
            <h3 [class]="displayType !== 'popup'?'d-flex align-items-center ml-3':''">
                <span class="color-row e2_stock_partiel"></span>{{'ke2avecstockpartiel' | translate}}
            </h3>
            <h3 [class]="displayType !== 'popup'?'d-flex align-items-center ml-3':''">
                <span class="color-row e2_stock_total"></span>{{'ke2avecstocktotal' | translate}}
            </h3>
            <h3 [class]="displayType !== 'popup'?'d-flex align-items-center ml-3':''">
                <span class="color-row e2_affecte_totalement"></span>{{'ke2affecteetotalement' | translate}}
            </h3>
        </div>

        <div class="colors-container" [class.flex-row]="displayType !== 'popup'" *ngIf="type === 'devis' || type === 'cuisine' || type === 'dressing'">
            <h3 [class]="displayType !== 'popup'?'d-flex align-items-center':''"><span class="color-row devis_en_cours"></span>{{'kdevisencours' | translate}}</h3>
            <h3 [class]="displayType !== 'popup'?'d-flex align-items-center ml-3':''"><span class="color-row devis_valide"></span>{{'kdevisvalide' | translate}}</h3>
            <h3 [class]="displayType !== 'popup'?'d-flex align-items-center ml-3':''" *ngIf="type === 'cuisine' || type === 'dressing'"><span class="color-row devis_annule"></span>{{'kdevisannuler' | translate}}</h3>
        </div>
    </div>
    <div class="bloc_container" *ngIf="displayType === 'popup'">
        <div class="row bg_white">
            <div class="col-12 text-center">
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kartclose' | translate}}
                    </span>                     
                </button>
            </div>
        </div>
    </div>
</div>
