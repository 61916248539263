import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Magasin } from 'models/magasins';
import { LoginService } from 'providers/api/loginService';
import * as moment from 'moment';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ReportSubChildMenuModel } from 'providers/reportingMenus';
import { ReportingService } from 'providers/api/reportingService';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PdfViewerComponent } from 'pages/pdf-viewer/pdf-viewer.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'providers/api/utilService';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';

@Component({
  selector: 'app-filter-report',
  templateUrl: './filter-report.component.html',
  styleUrls: ['./filter-report.component.scss'],
  providers: [ReportingService]
})
export class FilterReportComponent implements OnInit {

  //Magasin
  listMagasins: Magasin[] = [];
  filteredMagasins: Magasin[] = [];
  filterMagasin: string = "";

  today = new Date();

  dateAct: string = 'equal';
  minDateAct: any = null;

  reportingForm: UntypedFormGroup;

  currentMenu: ReportSubChildMenuModel;

  dateDisabled: boolean = false;
  defaultDate: moment.Moment;
  fromDateStat: boolean;
  toDateStat: boolean;
  magasinStat: boolean;

  CMINT: any;
  isMobile: boolean;
  user: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<FilterReportComponent>,
              private formBuilder: UntypedFormBuilder,
              private loginService: LoginService,
              private utilService: UtilService,
              private reportingService: ReportingService,
              private dialogService: DialogsService,
              private bonVenteService: BonVenteService,
              private dialog: MatDialog,
              private translateService: TranslateService,
              private loadingService: NgxSpinnerService,
              private deviceDetector: DeviceDetectorService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.currentMenu = this.data.currentMenu;
    this.CMINT = this.data.CMINT;

    this.user = this.userService.getCurrentUser();

    this.reportingForm = this.formBuilder.group({
      REPORT_NUM: [''],
      MAGASIN: [''],
      START_DATE: [{ value: '', disabled: true }],
      END_DATE: [{ value: '', disabled: true }]
    });

    if(this.currentMenu?.value !== undefined && this.currentMenu?.value !== null) {
      this.reportingForm.patchValue({ REPORT_NUM: this.currentMenu?.value });
    }

    if(this.currentMenu?.dateFilter !== undefined && this.currentMenu?.dateFilter != null) {
      if(this.currentMenu?.defaultDate !== undefined && this.currentMenu?.defaultDate != null) {
        this.defaultDate = this.currentMenu?.defaultDate;
      }

      if(this.currentMenu?.dateDisabled !== undefined && this.currentMenu?.dateDisabled != null) {
        this.dateDisabled = this.currentMenu?.dateDisabled;
      }

      switch(this.currentMenu?.dateFilter) {
        case 'from':
          this.fromDateStat = true;
          this.toDateStat = false;

          if(this.defaultDate !== undefined && this.defaultDate !== null) {
            this.reportingForm.patchValue({
              START_DATE: this.defaultDate.format('YYYY-MM-DD')
            });
          }
          break;
        case 'to':
          this.fromDateStat = false;
          this.toDateStat = true;

          if(this.defaultDate !== undefined && this.defaultDate !== null) {
            this.reportingForm.patchValue({
              END_DATE: this.defaultDate.format('YYYY-MM-DD')
            });
          }
          break;
        case 'both':
          this.fromDateStat = true;
          this.toDateStat = true;

          if(this.defaultDate !== undefined && this.defaultDate !== null) {
            this.reportingForm.patchValue({
              START_DATE: this.defaultDate.format('YYYY-MM-DD'),
              END_DATE: this.defaultDate.format('YYYY-MM-DD')
            });
          }
          break;
        default: 
          this.fromDateStat = true;
          this.toDateStat = true;

          if(this.defaultDate !== undefined && this.defaultDate !== null) {
            this.reportingForm.patchValue({
              START_DATE: this.defaultDate.format('YYYY-MM-DD'),
              END_DATE: this.defaultDate.format('YYYY-MM-DD')
            });
          }
          break;
      }
    }

    if(this.currentMenu?.magasinFilter !== undefined && this.currentMenu?.magasinFilter != null) {
      this.magasinStat = this.currentMenu?.magasinFilter;
    }

    this.magasinStat = true; // As Romain asked in CAP-1035 displaying Magasin dropdown to all reportings (Just remove this line to revert it)

    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();
    await this.getAllMagasins();

    if(this.magasinStat) {
      if(this.CMINT !== undefined && this.CMINT != null && this.CMINT != '0') {
        this.reportingForm.patchValue({
          MAGASIN: this.CMINT // Updating the default value for the Magasin dropdown.
        })
      }
    }

    this.loadingService.hide();
  }

  get reportStartDate() {
    return this.reportingForm.get('START_DATE');
  }

  get reportEndDate() {
    return this.reportingForm.get('END_DATE');
  }

  onDateChanged() {
    this.minDateAct = this.reportStartDate.value?this.reportStartDate.value:null;
    if(this.minDateAct !== null && this.currentMenu?.dateFilter === 'both') {
      this.reportEndDate.setValue(this.minDateAct);
    }
  }

  changeDateAct(type: string = 'equal') {
    this.dateAct = type;
  }

  _clearDate = (form_field: string) => {
    this.reportingForm.get(form_field).setValue('');
    if(form_field == 'START_DATE') {
      this.minDateAct = null;
    }
  }

  onSearchChange(search: string) {
    this.filteredMagasins = this.listMagasins.filter(row => row.CMRAISOC.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  getAllMagasins() {            
    this.reportingForm.get('MAGASIN').setValue('');
   
    return new Promise((resolve, reject) => {
      this.loginService.getMagasins().subscribe(
        (data) => {
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                this.listMagasins.push(new Magasin(data[i]));          
            }
            this.filteredMagasins = this.listMagasins;
            resolve(data);
          }
        }, 
      (error) => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        reject(error);
      });
    })
  }

  openUpPdfViewer(data: any) {
    const dialogRef = this.dialog.open(PdfViewerComponent,
      {
        height: this.isMobile?'100%':undefined,
        data: {
          ...data
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        
      }      
    });
  }

  reportingFormSubmitter() {
    this.loadingService.show();
    const form_data = this.reportingForm.getRawValue();

    if(this.dateDisabled === false && this.currentMenu?.dateFilter === 'both' &&
        (this.currentMenu?.dateMandatory !== false) &&
    ((form_data.START_DATE === undefined || form_data.START_DATE === null || form_data.START_DATE === '') ||
      (form_data.END_DATE === undefined || form_data.END_DATE === null || form_data.END_DATE === '')
    )) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('ksaiseruneperiod'));
      return;
    }

    const data: any = {
      IDSOC: Constants.IDSOC,
      DTREPORT: moment().format('YYYY-MM-DD HH:mm:ss'),
      BRACTION: Constants.BRACTION_REPORT,
      BRSTR1: this.translateService.instant(this.currentMenu.label),
      CIINT: this.user.CIINT,
      TRAITE: 0,
      pFilter: {
        Mag: this.CMINT,
        Cas: form_data.REPORT_NUM,
        PDF: false
      }
    };

    if(this.currentMenu?.pAttribut !== undefined && this.currentMenu?.pAttribut !== null && this.currentMenu?.pAttribut !== "") {
      data.pFilter = {
        ...data.pFilter,
        pAttribut: this.currentMenu?.pAttribut
      }
    }

    if(form_data.MAGASIN !== undefined && form_data.MAGASIN !== null && form_data.MAGASIN !== '') {
      data.pFilter = {
        ...data.pFilter,
        Mag: form_data.MAGASIN
      }
    }

    if(this.dateDisabled === false && form_data.START_DATE !== undefined && form_data.START_DATE !== null && form_data.START_DATE !== '') {
      data.pFilter = {
        ...data.pFilter,
        DDeb: this.utilService.formatDateToWS(form_data.START_DATE)
      }
    }

    if(this.dateDisabled === false && (this.currentMenu?.dateFreedom === undefined || this.currentMenu?.dateFreedom !== true || (this.currentMenu?.dateFreedom === true && this.dateAct === 'between')) && form_data.END_DATE !== undefined && form_data.END_DATE !== null && form_data.END_DATE !== '') {
      data.pFilter = {
        ...data.pFilter,
        DFin: this.utilService.formatDateToWS(form_data.END_DATE)
      }
    }

    if(this.dateDisabled === false && this.currentMenu?.dateFreedom === true) {
      if(this.dateAct === 'equal') {
        data.pFilter = {
          ...data.pFilter,
          DateOperateur: '1'
        };
      }

      if(this.dateAct === 'greater_equal') {
        data.pFilter = {
          ...data.pFilter,
          DateOperateur: '2'
        } 
      }

      if(this.dateAct === 'less_equal') {
        data.pFilter = {
          ...data.pFilter,
          DateOperateur: '3'
        } 
      }
    }

    this.reportingService.addReportStatus(data).subscribe((resp) => {
      this.loadingService.hide();
      if(resp.statusCode == 200) {
        if(resp.data.ErrorCode == 0) {
          if(resp.data.Fichier !== '') {
            let fileUrl = resp.basePath+resp.data.Fichier; // Reporting_150055355.xls
            this.bonVenteService.downloadFile(fileUrl).subscribe(
              (_down_res) => {
                this.openUpPdfViewer({ url: fileUrl, type: 'XL' });
              },
              error => {
                console.error("Error while downloading the file", error);
                this.dialogService.prompt(this.translateService.instant('kfailure'), error.message);
              }
            );
          }
        } else {
          this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
        }
      } else {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    }, (err) => {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    })
  }

}
