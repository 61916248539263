<h1 mat-dialog-title class="text-center">{{ 'kmmodify' | translate }}</h1>
<div mat-dialog-content>
    <form [formGroup]="editMotifForm" (ngSubmit)="editFormSubmission()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field>
                        <mat-select [placeholder]="'kmaction' | translate" color="primary" formControlName="MOTINT">
                            <mat-option value="item.value">< {{'kmany' | translate}} ></mat-option>
                            <mat-option *ngFor="let item of motifActions" [value]="item.value">{{ item.label | translate }}</mat-option>
                        </mat-select>                                
                    </mat-form-field> 
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <input matInput formControlName="MOTLIB" [placeholder]="'kmlibel' | translate" />
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-checkbox formControlName="MOTFREE" class="example-margin">{{'kmmolibel' | translate}}</mat-checkbox>
                </div>
            </div>
        </div>
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button [disabled]="editMotifForm.invalid" type="submit">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kmvalid' | translate}}
                        </span> 
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kmback' | translate}}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>