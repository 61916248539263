import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Constants } from 'providers/constants';

@Injectable()
export class MotifBlocageService {

    constructor(private http: HttpClient) {
    }
    
    getListofMotifs(data: any): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = data;

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_STOCK_BLOCK_URL + 'getStockList', options);
    }

    updateMotifBlocage(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_STOCK_BLOCK_URL + 'updateStockCondition', data, options);
    }

}   
