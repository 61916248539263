<div class="modal-container" id="remise_detail">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title" translate>{{'kbonremisedet' | translate}}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="mt-4">
            <div class="row">
                <div class="col-md-12">
                    <div *ngIf="(changementDePrix !== undefined)">
                        <h3>{{'kbonprixchange' | translate}}</h3>
                        <div class="d-flex align-content-center justify-content-between px-2" *ngIf="panierArticle && panierArticle.prixforce">
                            <h4 style="text-indent: 1em;"> - {{ panierArticle.motifprixforce }} ({{ prixForcePercentage | imask: maskOptions }}%)</h4>
                            <h4> {{ prixForceAmount | imask: maskOptions }}</h4>
                        </div>
                    </div>
                    <div class="mt-3" *ngIf="(remiseManuelles !== undefined && remiseManuelles !== null && remiseManuelles.length > 0)">
                        <h3>{{'kbondismanu' | translate}} </h3>
                        <div class="d-flex align-content-center justify-content-between px-2" *ngFor="let item of remiseManuelles">
                            <h4 style="text-indent: 1em;"> - {{ item.label }}</h4>
                            <h4> - {{ item.amount | imask: maskOptions }}</h4>
                        </div>
                    </div>
                    <div class="mt-3" *ngIf="remiseCommercialeStat">
                        <h3>{{'kbondiscommer' | translate}} </h3>
                        <div class="d-flex align-content-center justify-content-between px-2" *ngFor="let item of remiseCommercialeDiscounts">
                            <h4 style="text-indent: 1em;"> - {{ item.label }}</h4>
                            <h4> - {{ item.amount | imask: maskOptions }}</h4>
                        </div>
                    </div>

                    <div class="mt-3" *ngIf="animationCommercialeStat">
                        <h3>{{'kbonanimatcommer' | translate}} </h3>
                        <div class="d-flex align-content-center justify-content-between px-2" *ngFor="let item of animationCommercialeDiscounts">
                            <h4 style="text-indent: 1em;"> - {{ item.label }}</h4>
                            <h4> - {{ item.amount | imask: maskOptions }}</h4>
                        </div>
                    </div>

                    <div class="mt-3" *ngIf="avantageCommercialeStat">
                        <h3>{{'kbonavanperman' | translate}} </h3>
                        <div class="d-flex align-content-center justify-content-between px-2" *ngFor="let item of avantageCommercialeDiscounts">
                            <h4 style="text-indent: 1em;"> - {{ item.label }}</h4>
                            <h4> - {{ item.amount | imask: maskOptions }}</h4>
                        </div>
                    </div>
                    
                </div>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center" >            
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
</div>