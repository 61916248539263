<h1 mat-dialog-title class="text-center">{{ 'kureinit' | translate }}</h1>
<div mat-dialog-content>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onFormSubmission()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field>
                        <input [type]="oldPasswordHide ? 'password' : 'text'" matInput formControlName="CIOLDPSW" [placeholder]="'kuoldpass' | translate" />
                        <span matSuffix>
                            <mat-icon (click)="toggleOldPasswordDisplay()">
                                  <i [class]=" oldPasswordHide ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i>
                            </mat-icon>
                        </span>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <input [type]="newPasswordHide ? 'password' : 'text'" matInput formControlName="CIPSW" [placeholder]="'kunewpass' | translate" class="toUpperCase" />
                        <span matSuffix>
                            <mat-icon (click)="toggleNewPasswordDisplay()">
                                  <i [class]=" newPasswordHide ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i>
                            </mat-icon>
                        </span>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <input [type]="newConfirmPasswordHide ? 'password' : 'text'" matInput formControlName="CICPSW" [placeholder]="'kuconfirm' | translate" class="toUpperCase" />
                        <span matSuffix>
                            <mat-icon (click)="toggleNewConfirmPasswordDisplay()">
                                  <i [class]=" newConfirmPasswordHide ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i>
                            </mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button [disabled]="resetPasswordForm.invalid" type="submit">
                        <mat-icon>done</mat-icon>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "ksvalid" | translate }}
                        </span>
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <mat-icon>close</mat-icon>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "ksback" | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>