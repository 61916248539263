import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import BonPanier from '../../models/bonPanier';
import { ParamDetail } from '../../models/paramDetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from '../../providers/api/dialogService';
import { UserService } from '../../providers/api/userService';

@Component({
  selector: 'app-imprimer-confirmation',
  templateUrl: './imprimer-confirmation.component.html',
  styleUrls: ['./imprimer-confirmation.component.scss']
})
export class ImprimerConfirmationComponent implements OnInit {

  confirmationForm: UntypedFormGroup;
  panier: BonPanier;

  CMINT: any;
  flowType: 'bonde_vente' | 'bonde_commande' | 'devis';

  constructor(private formBuilder: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: any,
            public dialogRef: MatDialogRef<ImprimerConfirmationComponent>,
            private userService: UserService,
            private translateService: TranslateService,
              private dialogService: DialogsService,
            private loadingService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.confirmationForm = this.formBuilder.group({
      EDIT_BON: [true],
      SEND_BON: [false]
    });

    this.flowType = this.data.flowType;
    this.panier = this.data.panier;
    this.CMINT = this.data.CMINT;
    
    this.loadDependencies();
  }

  onCheckBoxChange() {
    const EDIT = this.confirmationForm.get('EDIT_BON').value;
    const SEND = this.confirmationForm.get('SEND_BON').value;

    if(EDIT || SEND) {
      this.confirmationForm.get('EDIT_BON').setErrors(null);
      this.confirmationForm.get('EDIT_BON').clearValidators();
      this.confirmationForm.get('EDIT_BON').updateValueAndValidity();
    } else {
      this.confirmationForm.get('EDIT_BON').setValidators([Validators.requiredTrue]);
      this.confirmationForm.get('EDIT_BON').updateValueAndValidity();
    }
  }

  async loadDependencies() {
    this.loadingService.show();
    
    this.loadingService.hide();
  }

  confirmationFormSubmitter() {
    const form_data = this.confirmationForm.getRawValue();
    this.dialogRef.close({ status: 'success', data: form_data });
  }

}
