<h1 mat-dialog-title class="text-center">{{ 'kvoulezvoussms' | translate }}</h1>
<div>
    <form [formGroup]="confirmationForm" (ngSubmit)="confirmationFormSubmitter()">
        <div class="text-center">
            <div class="row">
                <div class="col-12 py-1 text-left">
                    <mat-slide-toggle 
                        formControlName="EDIT_BON"
                        (change)="onCheckBoxChange()"
                    >
                        {{ 'keditlebon' | translate }}
                    </mat-slide-toggle>
                </div>
    
                <div class="col-12 py-1 text-left">
                    <mat-slide-toggle 
                        formControlName="SEND_BON" 
                        (change)="onCheckBoxChange()"
                    >
                        {{ 'kenvoylebon' | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="justify-content-center">  
            <button type="submit" mat-button [disabled]="confirmationForm.invalid">
                <mat-icon>done</mat-icon>
                <span class="d-none d-sm-none d-md-block">
                {{ 'kuok' | translate }}
                </span>    
            </button>
    
            <button type="button" mat-button mat-dialog-close class="" #annulerButton="matButton">
                <mat-icon>close</mat-icon>
                <span class="d-none d-sm-none d-md-block">
                {{ 'kucancel' | translate }}
                </span>    
            </button>
        </div>
    </form>
</div>