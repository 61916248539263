import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {ArticleLogistiqueStockAutreComponent} from './article-logistique-stock-autre/article-logistique-stock-autre.component';
import {ArticleLogistiqueCouchePrixComponent} from './article-logistique-couche-prix/article-logistique-couche-prix.component';
import {ArticleLogistiqueMagasinComponent} from './article-logistique-magasin/article-logistique-magasin.component';
import {ArticleLogistiqueEntrepotComponent} from './article-logistique-entrepot/article-logistique-entrepot.component';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

import {Article, Entreprots, Fournisseur} from '../../../models/articles';
import {ArticleService} from '../../../providers/api/articleService';

import { UserService } from 'providers/api/userService';
import { StockEntreprotsComponent } from './stock-entreprots/stock-entreprots.component';
import { ArticleStockComponent } from '../article-stock/article-stock.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-article-logistique',
    templateUrl: './article-logistique.component.html',
    providers: [ArticleService]
})

export class ArticleLogistiqueComponent implements OnInit {

    dtOptions: any = {};

    private _article: Article
    get article() {return this._article}
    @Input() set article(article: Article) {
        this._article = article;
        this.articleChange.emit(this._article)
    }
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();
    @Input() displayType: 'dialog' | 'component' | 'bonde' = 'dialog';
    @Input() flowCMINT: any | undefined;
    
    CAINT: any;
    user: any;
    USERCMINT: any;
    STOCK_DISPO: any;
    STOCK_BLOQUE: any;
    article_session: any;

    commande_cours: any;
    E2_nombre: any;
    fornisseur_data: Fournisseur;
    listEntreprots: Entreprots[];

    isMobile: boolean = false;
    code_externe: Promise<any>;

    userPermission: any;

    promesseDate: any;

    constructor(public dialog: MatDialog,
                private articleService: ArticleService,
                private loadingService: NgxSpinnerService,
                private userService: UserService,
                private deviceDetector: DeviceDetectorService,
                private dialogService: DialogsService,
                private translateService: TranslateService,
                private storageService: StorageService) {   }

    ngOnInit() {
        this.isMobile = this.deviceDetector.isMobile();

        this.loadDependencies();
    }

    async loadDependencies() {
        this.userPermission = this.storageService.getItem('CurrentUserPermission');
        this.user = this.userService.getCurrentUser();

        this.USERCMINT = this.user.CMINT;
        if (this.flowCMINT) {
            this.USERCMINT = this.flowCMINT;
        }

        if (this.article) {
            this.CAINT = this.article.CAINT;
            if (this.CAINT) {
                this.code_externe = this.getCodeExterne();
                this.getCommandeEnCours();
                this.getNombreE2enCours();
                this.getFournisseurData();
                this.getListEntreprot();
            }
        }
    }

    dateFormatter(date: string, offset = true, today_time = true) {
        if(date === undefined || date  === null) {
          return '';
        }
        
        const given_date = moment(date).format('DD.MM.YYYY');
    
        if(given_date === undefined || moment(date).year() === 1899) {
          return '';
        }
    
        if(offset) {
          return moment(date).utc().format('DD.MM.YY');
        }
        return moment(date).format('DD.MM.YY');
    }

    refreshPromesseDate() {
        this.loadingService.show();
        const data = {
            pMag: this.USERCMINT,
            pCdart: this.article.CDART
        };

        this.getPromesseDate(data);
    }

    getPromesseDate(data: any) {
        this.articleService.getPromesse(data).subscribe(
            (res: any) => {
                this.loadingService.hide();
                if(res !== undefined && res.success == 'ok') {
                    if(res.data.ErrorCode == 0) {
                        this.promesseDate = res.data.DtPromesse;
                        const [dateTime, offset] = this.promesseDate.split('+');
                        const formattedDate = this.dateFormatter(dateTime, false);
                        if(formattedDate != '') {
                            this.dialogService.prompt(this.translateService.instant('kdatedepromesse'), formattedDate);
                        } else {
                            this.dialogService.prompt(this.translateService.instant('kdatedepromesse'), this.translateService.instant('kpasdisponibilite'));
                        }
                    } else {
                        this.dialogService.prompt(this.translateService.instant('kuerror'), res.data.Error);
                    }
                } else {
                    this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                }
            }, 
            err => {
                this.loadingService.hide();
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        )
    }

    getFournisseurData() {        
        this.articleService.getFournisseurData(this.CAINT, this.USERCMINT).subscribe(
            (res) => {
                if(res.success !== undefined) {
                    this.fornisseur_data = res.data[0];                    
                }              
            }, 
            err => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        );
    }

    getListEntreprot() {        
        this.articleService.getListEntreprot(this.CAINT, this.USERCMINT).subscribe(
            (res) => {
                if(res.success !== undefined) {
                    this.listEntreprots = res.data;                    
                }              
            }, 
            err => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        );
    }

    getNombreE2enCours() {
        this.articleService.getNombreE2enCours(this.CAINT, this.USERCMINT).subscribe(
            (res) => {
                this.E2_nombre = res.E2val;
            }, 
            err => {            
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        )
    }

    getCommandeEnCours() {
        this.articleService.getComEnCours(this.CAINT, this.USERCMINT).subscribe(
            (res) => {
                if(res.success !== undefined) {
                    this.commande_cours = (res.data && res.data.length > 0)?res.data[0].QTE:undefined;
                }
            }, 
            err => {            
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        )
    }

    getCodeExterne(): Promise<any> {      
        this.loadingService.show();   
        return new Promise((resolve, reject) => {
            this.articleService.getCodeExterne(this.CAINT, this.USERCMINT).subscribe(
                (res: any) => {  
                    this.loadingService.hide();                      

                    if(res.success !== undefined && res.data && res.data.length > 0) {
                        resolve(res.data[0].CDEXTERNE);
                    }              
                },  
                err => {
                    this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                    reject(err);
                }
            );
        });        
      }

    public openStockAutreDialog(): void {        
        const dialogRef = this.dialog.open(ArticleLogistiqueStockAutreComponent,
            {
                data: {
                    CAINT: this.CAINT,
                    listEntreprots: this.listEntreprots
                }
            }
        );
    }

    public openCouchePrixDialog(): void {        
        const dialogRef = this.dialog.open(ArticleLogistiqueCouchePrixComponent,
            {data: {CAINT: this.CAINT}}
        );
    }

    public openDetailMagasinDialog(): void {        
        const dialogRef = this.dialog.open(ArticleLogistiqueMagasinComponent,
            {data: {CAINT: this.CAINT}}
        );
    }

    public openDetailEntrepotDialog(): void {        
        const dialogRef = this.dialog.open(ArticleLogistiqueEntrepotComponent,
            {data: {CAINT: this.CAINT}}
        );
    }    

    openEntrepotListDialog() {        
        const dialogRef = this.dialog.open(StockEntreprotsComponent,
            {
                data: {
                    listEntreprots: this.listEntreprots
                }
            }
        );
    }

    openDetailStockDialog() {        
        const dialogRef = this.dialog.open(ArticleStockComponent,
            {
                minWidth: "50vw",
                autoFocus: false,
                data: {
                    articleData: this.article,
                    CAINT: this.CAINT
                }
            }
        );
    }    
}


