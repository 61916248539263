import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'providers/constants';
import { UserModel } from 'models/user-model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class ReportingService {
    isMobile: boolean = false;

    constructor(private http: HttpClient,
        private deviceService: DeviceDetectorService) {
            this.isMobile = this.deviceService.isMobile();
    }

    exportReportingPDF(data: any): Observable<any> {
        return this.http.post(Constants.API_PARAM_URL + 'getReport', data);
    }

    getReportStatus(page?: number, status?: number): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        
        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        params['device'] = this.isMobile?'mobile':'pc';
        params['status'] = status ? status : '';

        const options = {
            headers: headers,
            params: params
        };
        
        return this.http.get(Constants.API_PARAM_URL + 'getReportStatus', options);
    }

    addReportStatus(data: any): Observable<any> {
        return this.http.post(Constants.API_PARAM_URL + 'addReportStatus', data);
    }

    deleteReportStatus(data: {TROWID: number[]}): Observable<any> {
        return this.http.post(Constants.API_PARAM_URL + 'deleteReportStatus', data);
    }

    downloadFile(downloadUrl: string): Observable<any> {
		return this.http.get(downloadUrl, {responseType: 'blob'});
    }

    updateViewedReportStatus(data: {TROWID: number}): Observable<any> {
        return this.http.post(Constants.API_PARAM_URL + 'updateViewedReportStatus', data);
    }
}