<div class="page-container">
    <div class="title row" [class.no-gutters]="isMobile && displayType === 'dialog'">
      <div class="col-3 col-sm-2 col-md-2 col-lg-2" *ngIf="!isMobile">
        <mat-icon class="pointer" (click)="returnBack()" *ngIf="displayType === 'page'" [title]="'kuback' | translate">
            keyboard_arrow_left page
        </mat-icon>
      </div>
      <div class="col-1 col-sm-2 col-md-2 col-lg-3" *ngIf="isMobile">
        <mat-icon *ngIf="displayType === 'dialog' || displayType !== 'tab'" class="pointer d-block d-sm-block d-md-none" (click)="returnBack()" [title]="'kuback' | translate">
           keyboard_arrow_left dialog
        </mat-icon>
      </div>
      <div class="col-10 col-sm-8 col-md-8 col-lg-8 text-center">
        <h1 class="text-center w-100 align-items-center justify-content-center" [class.d-flex]="!isMobile">
          <span class="article-detail-title" [style]="isMobile?'left: 0':null" [class.position-absolute]="isMobile">{{ (article !== undefined && article !== null)?article.BALDESIGN1:('kartarttit' | translate)}}</span>
          <br *ngIf="isMobile">
          <span *ngIf="(article?.CAECODE && article?.CAECODE)"  class="badge ml-3" [class]="article?.CAECODE && article?.CAECODE === 'A'?'badge-success':'badge-danger'" >          
              {{ (article?.CAECODE && article?.CAECODE === 'A'?'kartactive':'kartinactive') | translate }}          
          </span>
          <span class="badge badge-info ml-3">
              {{ (article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')?((article.STOCKDISPO != null?article.STOCKDISPO:0) | cap_decimal):('kartmang' | translate) }}
          </span>
          <span class="badge badge-secondary ml-3">
              {{ articlePrix | cap_currency:null:false }}
          </span>
      </h1>   
      </div>
      <div class="col-1 col-sm-2 col-md-2 col-lg-1 no-padding">
          <span *ngIf="isMobile"><mat-icon>shopping_cart</mat-icon></span>
      </div>  
    </div>

    <form #articleForm="ngForm">                
        <div class="row" [class.no-gutters]="isMobile && displayType === 'dialog'">
          <div class="col-12 d-block d-md-none text-center" *ngIf="article">
            <img [src]="getImageURL(article.CDART)" class="img-fluid mb-3" [alt]="article.CDART" [getLost]="true" default="assets/images/no_img.png" />                    
          </div>
        </div>
        <div [class.container]="!isMobile">
          <div class="col-12 d-none d-md-block text-center">
            <div *ngIf="article">
                <img [src]="getImageURL(article.CDART)" width="40%" class="img-fluid mb-3" [alt]="article.CDART" [getLost]="true" default="assets/images/no_img.png" />
            </div>
          </div>
          <div > 

          <div *ngIf="!isMobile">
            <mat-accordion multi >
              <mat-expansion-panel id="descriptif_produit" [expanded]="expandedAccordion === 7">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartdescprod' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent >
                  <app-article-description [displayType]="'desc'" [(article)]="article"></app-article-description>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="info_complementaire" [expanded]="expandedAccordion === 9">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartinfocomp' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                  <app-article-description [displayType]="'comp'" [(article)]="article"></app-article-description>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="livraison_option" *ngIf="deliveryOptions.length > 0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'koption_livraison' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                  <app-article-description [displayType]="'delivery'" [(article)]="article" [deliveryOptions]="deliveryOptions"></app-article-description>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="info_logisitique" [expanded]="expandedAccordion === 0" (opened)="changedAccordionTo(0)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartartinfolog' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique [flowCMINT]="currentStockMag" [tabChanged]="tabTrigger" [displayType]="'component'" [(article)]="article"></app-article-logistique>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="blocage_stock" [expanded]="expandedAccordion === 1" (opened)="changedAccordionTo(1)" *ngIf="(article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartstockblock' | translate}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-stock [currentStockMag]="currentStockMag" [tabChanged]="tabTrigger" [displayType]="'component'" [stockBlocageEditable]="stockBlocageEditable" [bonVenteFlow]="bonVenteFlow" displayOnly="block_stock" [(article)]="article" ></app-article-stock>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="autre_magasins" [expanded]="expandedAccordion === 2" (opened)="changedAccordionTo(2)"  *ngIf="(article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')">
                <mat-expansion-panel-header>
                  <mat-panel-title>                    
                    {{'kartothmag' | translate}}                    
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique-stock-autre [(article)]="article" [displayType]="'component'" [listEntreprots]="listEntreprots | async"></app-article-logistique-stock-autre>                
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="comm_magasin" [expanded]="expandedAccordion === 4" (opened)="changedAccordionTo(4)">
                <mat-expansion-panel-header>
                  <mat-panel-title>                    
                    {{'kartretstore' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique-magasin [flowCMINT]="currentStockMag" [(article)]="article" [displayType]="'component'"></app-article-logistique-magasin>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="comm_entreprot" [expanded]="expandedAccordion === 5" (opened)="changedAccordionTo(5)">
                  <mat-expansion-panel-header>
                    <mat-panel-title>                    
                      {{'kartretentrepot' | translate}}
                    </mat-panel-title>                
                  </mat-expansion-panel-header>
                  
                  <ng-template matExpansionPanelContent>
                      <app-article-logistique-entrepot [flowCMINT]="currentStockMag" [(article)]="article" [displayType]="'component'" [listEntreprots]="listEntreprots | async"></app-article-logistique-entrepot>
                  </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="emplacement_stock" [expanded]="expandedAccordion === 8" (opened)="changedAccordionTo(8)"  *ngIf="(article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartstockemplace' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-stock [currentStockMag]="currentStockMag" [tabChanged]="tabTrigger" [displayType]="'component'" displayOnly="emplacement_stock" [(article)]="article"></app-article-stock>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="couches_de_prix" [expanded]="expandedAccordion === 3" (opened)="changedAccordionTo(3)" *ngIf="userPermission !== undefined && userPermission !== null && userPermission['23_2']">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartcoprix' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique-couche-prix [(article)]="article" [displayType]="'component'"></app-article-logistique-couche-prix>                
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="code_caisse" [expanded]="expandedAccordion === 6" (opened)="changedAccordionTo(6)">
                <mat-expansion-panel-header>
                  <mat-panel-title>                    
                    {{'kartcais' | translate}}                    
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-description-code [(article)]="article" [displayType]="'component'"></app-article-description-code>                
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div *ngIf="isMobile">
            <mat-accordion >
              <mat-expansion-panel id="descriptif_produit" [expanded]="expandedAccordion === 7">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartdescprod' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent >
                  <app-article-description [displayType]="'desc'" [(article)]="article"></app-article-description>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="info_complementaire" [expanded]="expandedAccordion === 9">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartinfocomp' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                  <app-article-description [displayType]="'comp'" [(article)]="article"></app-article-description>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="livraison_option" *ngIf="deliveryOptions.length > 0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'koption_livraison' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                  <app-article-description [displayType]="'delivery'" [(article)]="article" [deliveryOptions]="deliveryOptions"></app-article-description>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="info_logisitique" [expanded]="expandedAccordion === 0" (opened)="changedAccordionTo(0)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartartinfolog' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique [flowCMINT]="currentStockMag" [tabChanged]="tabTrigger" [displayType]="'component'" [(article)]="article"></app-article-logistique>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="blocage_stock" [expanded]="expandedAccordion === 1" (opened)="changedAccordionTo(1)" *ngIf="(article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartstockblock' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-stock [currentStockMag]="currentStockMag" [tabChanged]="tabTrigger" [displayType]="'component'" [stockBlocageEditable]="stockBlocageEditable" [bonVenteFlow]="bonVenteFlow" displayOnly="block_stock" [(article)]="article"></app-article-stock>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="autre_magasins" [expanded]="expandedAccordion === 2" (opened)="changedAccordionTo(2)" *ngIf="(article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')">
                <mat-expansion-panel-header>
                  <mat-panel-title>                    
                    {{'kartothmag' | translate}}                    
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique-stock-autre [(article)]="article" [displayType]="'component'" [listEntreprots]="listEntreprots | async"></app-article-logistique-stock-autre>                
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="comm_magasin" [expanded]="expandedAccordion === 4" (opened)="changedAccordionTo(4)">
                <mat-expansion-panel-header>
                  <mat-panel-title>                    
                    {{'kartretstore' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique-magasin [flowCMINT]="currentStockMag" [(article)]="article" [displayType]="'component'"></app-article-logistique-magasin>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="comm_entreprot" [expanded]="expandedAccordion === 5" (opened)="changedAccordionTo(5)">
                  <mat-expansion-panel-header>
                    <mat-panel-title>                    
                      {{'kartretentrepot' | translate}}
                    </mat-panel-title>                
                  </mat-expansion-panel-header>
                  
                  <ng-template matExpansionPanelContent>
                      <app-article-logistique-entrepot [flowCMINT]="currentStockMag" [(article)]="article" [displayType]="'component'" [listEntreprots]="listEntreprots | async"></app-article-logistique-entrepot>
                  </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="emplacement_stock" [expanded]="expandedAccordion === 8" (opened)="changedAccordionTo(8)" *ngIf="(article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartstockemplace' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-stock [currentStockMag]="currentStockMag" [tabChanged]="tabTrigger" [displayType]="'component'" displayOnly="emplacement_stock" [(article)]="article"></app-article-stock>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="couches_de_prix" [expanded]="expandedAccordion === 3" (opened)="changedAccordionTo(3)" *ngIf="userPermission !== undefined && userPermission !== null && userPermission['23_2']">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'kartcoprix' | translate}}
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-logistique-couche-prix [(article)]="article" [displayType]="'component'"></app-article-logistique-couche-prix>                
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel id="code_caisse" [expanded]="expandedAccordion === 6" (opened)="changedAccordionTo(6)">
                <mat-expansion-panel-header>
                  <mat-panel-title>                    
                    {{'kartcais' | translate}}                    
                  </mat-panel-title>                
                </mat-expansion-panel-header>
                
                <ng-template matExpansionPanelContent>
                    <app-article-description-code [(article)]="article" [displayType]="'component'"></app-article-description-code>                
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          
            
          </div>
        </div>        
    </form>
</div>


