import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ArticleService } from 'providers/api/articleService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-article-logistique-magasin',
    templateUrl: './article-logistique-magasin.component.html',
    providers: [ArticleService, DialogsService, TranslateService]
})
export class ArticleLogistiqueMagasinComponent implements OnInit {

    private _article: Article
    get article() {return this._article}
    @Input() set article(article: Article) {
        this.articleChange.emit(this._article = article);
    };
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();
    @Input() flowCMINT: any | undefined;

    CAINT: string;

    dtOptions: any = {};
    user: any;
    USERCMINT: any;
    showLoader = true;
    dtTrigger: Subject<any> = new Subject();

    listCodeMagasin: any[] = [];

    @Input() displayType: 'dialog' | 'component' = 'dialog';

    isMobile: boolean;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
                private articleService: ArticleService,
                private userService: UserService,
                private dialogService: DialogsService,
                private translateService: TranslateService,
                private deviceDetector: DeviceDetectorService) {
    }

    ngOnInit() {
        this.isMobile = this.deviceDetector.isMobile();

        this.loadDependencies();

        this.dtTrigger.next();
        this.dtOptions = {
            language: {
                processing: this.translateService.instant('kprocessing'),
                search: this.translateService.instant('ksearch'),
                loadingRecords: this.translateService.instant('kloading'),
                info: this.translateService.instant('kgenshowentry'),
                infoEmpty: this.translateService.instant('kgenshowingentry'),
                zeroRecords: this.translateService.instant('knodata'),
                emptyTable: this.translateService.instant('knodatadash'),
                paginate: {
                    first: this.translateService.instant('kfirst'),
                    previous: this.translateService.instant('kprevious'),
                    next: this.translateService.instant('knext'),
                    last: this.translateService.instant('klast'),
                }        
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };
    }

    async loadDependencies() {
        this.user = this.userService.getCurrentUser();

        this.USERCMINT = this.user.CMINT;
        if (this.flowCMINT) {
            this.USERCMINT = this.flowCMINT;
        }

        if (this.displayType !== 'component') {
            this.CAINT = this.data.CAINT;

            if(this.CAINT !== undefined && this.CAINT !== null) {
                this.showLoader = true;
                this.getCodeDetailMagasin(this.CAINT, this.USERCMINT);
            }
        } else {
            if (this.article !== undefined && this.article !== null) {
                this.CAINT = this.article.CAINT;
                this.showLoader = true;
                this.getCodeDetailMagasin(this.CAINT, this.USERCMINT);
            } else {
                this.showLoader = false;
            }
        }

    }

    ngAfterViewInit(): void {      
        this.dtTrigger.next();
    }

    ngOnDestroy() {
        this.dtTrigger.unsubscribe();
    }

    getCodeDetailMagasin(CAINT: string, CMINT: number) {
        this.articleService.getDetailCodeMagasin(CAINT, CMINT).subscribe(
            (res) => {
                this.showLoader = false;
                if(res.success !== undefined) {
                    this.listCodeMagasin = res.data;
                }                
            }, 
            err => {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            }
        )
    }

}
