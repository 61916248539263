import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Magasin} from '../../../models/magasins';
import {DialogsService} from '../../../providers/api/dialogService';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../../../providers/api/loginService';
import {NgxSpinnerService} from 'ngx-spinner';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import BonPanier from '../../../models/bonPanier';

@Component({
  selector: 'app-change-store',
  templateUrl: './change-store.component.html',
  styleUrls: ['./change-store.component.scss']
})
export class ChangeStoreComponent implements OnInit {

  panier: BonPanier;

  // Magasin
  listMagasins: Magasin[] = [];
  filteredMagasins: Magasin[] = [];
  filterMagasin = '';

  changeStoreForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ChangeStoreComponent>,
              private formBuilder: UntypedFormBuilder,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private loginService: LoginService,
              private loadingService: NgxSpinnerService) { }

  ngOnInit(): void {
    if (this.data !== undefined && this.data !== null) {
      if (this.data.panier !== undefined && this.data.panier !== null) {
        this.panier = this.data.panier;
      }
    }

    this.changeStoreForm = this.formBuilder.group({
      NEW_CMINT: ['', Validators.required]
    });

    this.loadDependencies();
  }

  onSearchChange(search: string) {
    this.filteredMagasins = this.listMagasins.filter(row => row.CMRAISOC.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  async loadDependencies() {
    this.loadingService.show();

    await this.getAllMagasins();

    this.loadingService.hide();
  }

  getAllMagasins(): Promise<any> {
    this.changeStoreForm.get('NEW_CMINT').setValue('');

    return new Promise((resolve, reject) => {
      this.loginService.getMagasins().subscribe(
          (data) => {
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                this.listMagasins.push(new Magasin(data[i]));
              }
              this.filteredMagasins = this.listMagasins;
              resolve(data);
            }
          },
          (error) => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(error);
          });
    })
  }

  changeStoreFormSubmitter() {
    if(this.changeStoreForm.invalid) {
      return;
    }

    const formData = this.changeStoreForm.getRawValue();

    const magDetail = this.listMagasins.find((row) => row.CMINT === formData.NEW_CMINT);

    const data = {
      NEW_CMINT: formData.NEW_CMINT,
      MAG_NOM: magDetail.CMRAISOC
    };

    this.dialogRef.close({ status: 'success', data })
  }

}
