<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{'kblolock' | translate}}
            </h1>
        </div>
    </div>    

    <div class="bloc_container">
        <div class="row">
            <div class="col-12 no-padding">
                <div class="">
                    <mat-paginator *ngIf="!showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

                <table *ngIf="!isMobile" datatable class="row-border hover" id="motifsList" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead>
                        <tr>
                            <th class="code" width="35">{{'kbloNo' | translate}}</th>
                            <th class="code">{{'kblomotbldeb' | translate}}</th>
                            <th class="code" width="100">{{'kblomodi' | translate}}</th>
                            <th class="code" width="65">{{'kblovisible' | translate}}</th>                            
                        </tr>
                    </thead>
                    <tbody *ngIf="listMotifs?.length != 0 && !showLoader">
                        <tr *ngFor="let motif of listMotifs">
                            <td class="numero">{{ motif.IDMOT }}</td>
                            <td class="numero">{{ motif.LIBEL }}</td>
                            <td class="numero">
                                <mat-slide-toggle (change)="updateBlocageStock($event, 'modify', motif)" *ngIf="motif.OKVISIBLE == '-1'" [checked]="motif.OKMODIF == '-1'?true:false"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="motif.OKVISIBLE === null || motif.OKVISIBLE == '0'" disabled [checked]="motif.OKMODIF == '-1'?true:false"></mat-slide-toggle>
                            </td>
                            <td class="numero">
                                <mat-slide-toggle (change)="updateBlocageStock($event, 'visible', motif)" [checked]="(motif.OKVISIBLE == '-1')?true:false"></mat-slide-toggle>
                            </td>                            
                        </tr>                        
                    </tbody>
                    <tbody *ngIf="listMotifs?.length == 0 || showLoader">
                        <tr>
                            <td colspan="4" *ngIf="listMotifs?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                            <td colspan="4" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>                            
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>

                <mat-list *ngIf="isMobile && (listMotifs?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="listMotifs?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">                        
                        <p matLine class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                    </mat-list-item>
                </mat-list>

                <mat-list *ngIf="isMobile && (listMotifs?.length != 0 && !showLoader)">
                    <mat-list-item  *ngFor="let motif of listMotifs">     
                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                            <h2 class="mobile-list-title">
                                {{ motif.LIBEL }}
                            </h2>                            
                        </div>

                        <div matLine class="d-flex flex-row">
                            <p class="align-items-center d-flex justify-content-start">
                                <span>{{'kblomodi' | translate}}</span>
                                <mat-slide-toggle class="ml-4" (change)="updateBlocageStock($event, 'modify', motif)" *ngIf="motif.OKVISIBLE == '-1'" [checked]="motif.OKMODIF == '-1'?true:false"></mat-slide-toggle>
                                <mat-slide-toggle class="ml-4" *ngIf="motif.OKVISIBLE === null || motif.OKVISIBLE == '0'" disabled [checked]="motif.OKMODIF == '-1'?true:false"></mat-slide-toggle>
                            </p>

                            <p class="align-items-center d-flex justify-content-start mr-4">
                                <span>{{'kblovisible' | translate}}</span>
                                <mat-slide-toggle class="ml-4" (change)="updateBlocageStock($event, 'visible', motif)" [checked]="(motif.OKVISIBLE == '-1')?true:false"></mat-slide-toggle>
                            </p>
                        </div>
                    </mat-list-item>
                </mat-list>

            </div>
        </div>
    </div>
</div>
