import {Component, OnInit} from '@angular/core';
import {Global} from '../../providers/api/global';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    constructor(public global: Global) {
    }

    ngOnInit() {
    }

}
