import { Observable } from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

import { Injectable } from '@angular/core';

import { PromptComponent } from '../../components/prompt/prompt.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DialogsService {

    constructor(public dialog: MatDialog,
        private translateService: TranslateService)  { }

    public prompt(title: string, message: string, button?: string, width?: string): Observable<any> {
        const buttonString = button?button:this.translateService.instant('kartclose');
        let dialogRef = this.dialog.open(PromptComponent, {
            width: width?width:'250px',
            data: { title: title, message: message, buttonName: buttonString }
        });
        return dialogRef.afterClosed();

    }
 
}
