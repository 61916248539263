import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { MotifService } from 'providers/api/motifService';
import { Constants } from 'providers/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-motif',
  templateUrl: './edit-motif.component.html',
  styleUrls: ['./edit-motif.component.scss']
})
export class EditMotifComponent {

  motifActions: any;
  chosenAction: number;

  editMotifForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<EditMotifComponent>,
              private formBuilder: UntypedFormBuilder,              
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private motifService: MotifService,
              private translateService: TranslateService) {

      this.motifActions = this.data.listMotifActions;
      this.chosenAction = this.data.chosen_action;

      this.editMotifForm = this.formBuilder.group({
        MOTINT: [{value: "", disabled: true}, Validators.required],
        IDSOC : [''],
        MOTLIB: ['', Validators.required],
        MOTFREE: [false],
        IDMOT: ['']
      });  

      this.editMotifForm.setValue({
        MOTFREE: this.data.MOTFREE == '-1'?true:false,
        MOTLIB: this.data.MOTLIB,
        IDMOT: this.data.IDMOT,
        MOTINT: this.chosenAction,
        IDSOC: Constants.IDSOC
      });
  }

  editFormSubmission(): void {
    this.loadingService.show();

    if(this.editMotifForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('kmmand'));

      return;
    }

    const form_data = this.editMotifForm.getRawValue();
    form_data.MOTFREE = form_data.MOTFREE?'-1':'0';
  
    this.motifService.updateMotif(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {          
          this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmupdateerr'));
          this.dialogRef.close(form_data);
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kmerrupdate'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
      }
    )
  }

}
