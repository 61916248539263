import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ArticleService } from 'providers/api/articleService';
import { DialogsService } from 'providers/api/dialogService';
import { StorageService } from 'providers/api/storageService';
import { UserService } from 'providers/api/userService';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-article-logistique-entrepot',
    templateUrl: './article-logistique-entrepot.component.html',
    providers: [ArticleService, DialogsService, TranslateService]
})
export class ArticleLogistiqueEntrepotComponent implements OnInit {

    private _article: Article
    get article() {return this._article}
    @Input() set article(article: Article) {
        this.articleChange.emit(this._article = article);
    };
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();
    @Input() flowCMINT: any | undefined;
    
    CAINT: string;

    dtOptions: any = {};
    user: any;
    USERCMINT: any;
    showLoader = true;
    dtTrigger: Subject<any> = new Subject();

    listCodeEntreprot: any[] = [];

    @Input() displayType: 'dialog' | 'component' = 'dialog';

    isMobile: boolean;

    userPermission: any;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
                private articleService: ArticleService,
                private dialogService: DialogsService,
                private translateService: TranslateService,
                private userService: UserService,
                private deviceDetector: DeviceDetectorService,
                private storageService: StorageService) {
    }

    ngOnInit() {
        this.isMobile = this.deviceDetector.isMobile();

        this.loadDependencies();

        this.dtOptions = {
            language: {
                processing: this.translateService.instant('kprocessing'),
                search: this.translateService.instant('ksearch'),
                loadingRecords: this.translateService.instant('kloading'),
                info: this.translateService.instant('kgenshowentry'),
                infoEmpty: this.translateService.instant('kgenshowingentry'),
                zeroRecords: this.translateService.instant('knodata'),
                emptyTable: this.translateService.instant('knodatadash'),
                paginate: {
                    first: this.translateService.instant('kfirst'),
                    previous: this.translateService.instant('kprevious'),
                    next: this.translateService.instant('knext'),
                    last: this.translateService.instant('klast'),
                }        
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };
    }

    async loadDependencies() {
        this.userPermission = this.storageService.getItem('CurrentUserPermission');
        this.user = this.userService.getCurrentUser();

        this.USERCMINT = this.user.CMINT;
        if (this.flowCMINT) {
            this.USERCMINT = this.flowCMINT;
        }

        if(this.displayType !== 'component') {
            this.CAINT = this.data.CAINT;

            if (this.CAINT !== undefined && this.CAINT !== null) {
                this.showLoader = true;
                this.getCodeDetailEntreprot(this.CAINT);
            }
        } else {
            if (this.article !== undefined && this.article !== null) {
                this.CAINT = this.article.CAINT;
                this.showLoader = true;
                this.getCodeDetailEntreprot(this.CAINT);
            } else {
                this.showLoader = false;
            }
        }
    }

    ngAfterViewInit(): void {      
        this.dtTrigger.next();
    }

    ngOnDestroy() {
        this.dtTrigger.unsubscribe();
    }

    async getCodeDetailEntreprot(CAINT: string) {
        const paramData = await this.userService.getRuleParamsData(10, 8, 'VBOOL1');

        this.articleService.getDetailCodeEntreprot(CAINT, this.USERCMINT).subscribe(
            (res) => {
                this.showLoader = false;
                if(res.success !== undefined) {
                    this.listCodeEntreprot = res.data;

                    if(this.userPermission != undefined && this.userPermission != null && this.userPermission['0_11'] && paramData == '-1') {
                        
                    } else {
                        this.listCodeEntreprot = this.listCodeEntreprot.filter((_r) => _r.default_mag == '-1');
                    }

                    this.dtTrigger.next();
                }                
            }, 
            err => {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            }
        )
    }

}
