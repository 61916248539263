import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import BonPanier from 'models/bonPanier';
import Transporteur from 'models/transporteur';
import * as moment from "moment";
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { Subject } from 'rxjs';
// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { RelanceConfirmationComponent } from '../relance-confirmation/relance-confirmation.component';
import { ParamDetail } from 'models/paramDetail';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';

@Component({
  selector: 'app-date-de-relance',
  templateUrl: './date-de-relance.component.html',
  styleUrls: ['./date-de-relance.component.scss'],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},BonVenteService]
})
export class DateDeRelanceComponent implements OnInit {
  @Input() relanceData;
  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  user: UserModel;
  USERCMINT: any;

  isMobile: boolean;
  today = new Date();
  relanceForm: UntypedFormGroup;

  dtliv_status: boolean = false;
  
  transporteurLabel: string = '';
  panier: BonPanier;
  numBon: string;

  CMINT: any;
  previousTranporteur: Transporteur;

  defaultDiffereDays: number;
  readOnly: boolean = false;

  flowType: 'bonde_commande' | 'devis';

  paramsList: ParamDetail[];

  constructor(private formBuilder: UntypedFormBuilder,
            @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
            @Optional() public dialogRef: MatDialogRef<DateDeRelanceComponent>,
            private dialog: MatDialog,
            private dialogService: DialogsService,
            private userService: UserService,
            private bonCommandeService: BonCommandeService,
            private loadingService: NgxSpinnerService,
            private deviceDetector: DeviceDetectorService,
            private translateService: TranslateService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();

    this.numBon = this.data.numBon;
    this.panier = this.data.panier;
    this.CMINT = this.data.CMINT; 
    this.readOnly = this.data.readOnly;
    this.flowType = this.data.flowType;

    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
    
    this.relanceForm = this.formBuilder.group({
      dtrel: [{value: '', disabled: true}],
    });

    this.loadDependencies();
    
    if(this.panier !== undefined && this.panier !== null) {
      let oldRelDate = new Date(this.panier.dtrel);
      let oldRelYear = moment(this.panier.dtrel).year();
      this.relanceForm.patchValue({
        dtrel: oldRelYear != 1899?oldRelDate:(!this.readOnly?new Date():'')
      })
    }
  }

  async loadDependencies() {
    this.loadingService.show();
    
    if(this.flowType === 'devis') {
      await this.getListParams();
    }

    this.loadingService.hide();
  }

  relanceFormSubmitter() {
    if(this.flowType === 'devis') {
      if(this.envoyerSMSStatusDisabled) {
        this.relanceUpdate();
        return;
      }

      this.relanceConfirmation();
      return;
    }

    this.relanceCheckboxPopup();
  }

  relanceUpdate(confirmation?: any) {
    const form_data = this.relanceForm.getRawValue();

    form_data.dtrel = moment(form_data.dtrel).format('YYYY-MM-DD HH:mm:ss');

    this.dialogRef.close({ status: 'success', data: form_data, confirmation_data: confirmation });
  }

  relanceConfirmation(): void {
    const message = `
      <h1 class="mb-3">${this.translateService.instant('kvoulezunmsg')}</h1>
    `;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [
          this.translateService.instant('ksyes'),
          this.translateService.instant('kartno')
        ]
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.relanceUpdate({ SMS: true });
        return;
      }

      this.relanceUpdate();
    });    
  }

  get envoyerSMSStatusDisabled() {
    let processedRules = this.userService.processRulesParams(this.paramsList);
    let paramKey = '51_3'; // 51/3 for Devis

    if(
      (this.panier.client === null) ||
      ((processedRules[paramKey]['VBOOL1'] != '-1') && // VBOOL1 If yes
        (processedRules[paramKey]['VBOOL4'] != '-1')// and also VBOOL4 If yes
      ) ||
      (
        (processedRules[paramKey]['VBOOL1'] == '-1') && // VBOOL1 If yes
        (processedRules[paramKey]['VBOOL4'] == '-1') && // and also VBOOL4 If yes
        (this.panier.client.email == null || this.panier.client.email === '') &&
        (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
        (this.panier.client.tel == null || this.panier.client.tel === '')
      ) ||
      (
        (processedRules[paramKey]['VBOOL4'] == '-1') && // VBOOL4 If yes
        (processedRules[paramKey]['VBOOL1'] != '-1') && // VBOOL1 and if not yes
        (this.panier.client.email == null || this.panier.client.email === '')
      ) ||
      (
        (processedRules[paramKey]['VBOOL1'] == '-1') && // VBOOL1 If yes
        (processedRules[paramKey]['VBOOL4'] != '-1') && // VBOOL4 and if not yes
        (this.panier.client.gsm == null || this.panier.client.gsm === '') && 
        (this.panier.client.tel == null || this.panier.client.tel === '')
      )
    ) {
      return true; // disabled
    } else {
      return false; // enabled
    }
  }

  getListParams(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getRulesParams(this.CMINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {              
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  relanceCheckboxPopup(): void {
    const dialogRef = this.dialog.open(RelanceConfirmationComponent, {
      width: '60vw',
      data: {
        panier: this.panier,
        CMINT: this.CMINT,
        flowType: this.flowType
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result !== null && result.status === "success") {
        this.relanceUpdate(result.data);
      }
    });    
  }

  sendSMS(type: string): Promise<void> {
    if(this.numBon === undefined || this.numBon == 'null' || this.CMINT === undefined || this.CMINT == 'null') return;

    return new Promise((resolve, reject) => {
      this.bonCommandeService.sendClientSMS(this.numBon, this.CMINT, type, this.user.CIINT, this.user.IDINSTINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {
              if(resp.data.ErrorCode == 0) {                
                this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmsgenvoye'));
              } else {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
              }              

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

}
