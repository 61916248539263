import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class StorageService {
    private secretKey = '6rt78jbzjs45fgbsja';

    constructor() { }

    encrypt(data: string): string {
        return CryptoJS.AES.encrypt(data, this.secretKey).toString();
    }

    decrypt(data: string): string {
        const bytes = CryptoJS.AES.decrypt(data, this.secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    setItem(key: string, value: any): void {
        const encryptedValue = this.encrypt(JSON.stringify(value));
        localStorage.setItem(key, encryptedValue);
    }

    getItem(key: string): any {
        const encryptedValue = localStorage.getItem(key);
        if (encryptedValue) {
            const decryptedValue = this.decrypt(encryptedValue);
            try {
                return JSON.parse(decryptedValue);
            } catch (e) {
                return decryptedValue;
            }
        }
        return null;
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    clear(): void {
        localStorage.clear();
    }
}