import { Component, OnInit, ChangeDetectorRef, NgZone, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Global } from '../providers/api/global';

import { User } from '../models/users';
import { UserService } from '../providers/api/userService';

import { TranslateService } from '@ngx-translate/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NiveauModel } from 'models/niveau-model';
import { DroitChild, GestionDesDroitsModel } from 'models/gestionDesDroitsModel';
import { Constants } from 'providers/constants';
import { UntypedFormBuilder } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GestionDesDroitService } from 'providers/api/gestionDesDroitService';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import * as moment from 'moment';
import { reportingMenuTree } from 'providers/reportingMenus';
import { ParamDetail } from 'models/paramDetail';
import { DialogsService } from 'providers/api/dialogService';
import { Title } from '@angular/platform-browser';
import { FocusMonitor } from '@angular/cdk/a11y';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'environments/environment';
import { StorageService } from 'providers/api/storageService';
import { KeycloakOperationService } from 'providers/api/keyclockService';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [GestionDesDroitService]
})

export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('rapidInput') rapidInput: ElementRef;

    user: User;
    USERCMINT: any;
    userLoggedIn: boolean = false;
    allMenusVisibility: boolean = false;
    menuVisibilityParam: string;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    title = 'angular-idle-timeout';

    isMobile: boolean;

    currentUserDroit: GestionDesDroitsModel;


    headerThreeDots: boolean = false;

    headerSearchInputStatus: boolean = false;
    headerSearchInputOnChangeStatus: boolean = false;
    headerSearchInputValue: number;
    headerSearchInputType: 'text' | 'number' = 'text';
    headerSearchInputThreeDotsStatus: boolean = true;

    headerAddButtonStatus: boolean = false;
    headerSearchButtonStatus: boolean = true;
    headerScanInput: boolean = true;

    threeDotsSubscription: Subscription;
    searchInputSubscription: Subscription;
    searchInputOnChangeSubscription: Subscription;
    headerAddSubscription: Subscription;
    headerSearchButtonSubscription: Subscription;
    headerSearchInputTypeSubscription: Subscription;
    headerSearchInputThreeDotsSubscription: Subscription;
    searchInputValueSubscription: Subscription;

    searchValue: any;

    dropValue: any;
    dropOption: any;
    currentPageName = '';
    headerSelectOption: boolean = false;
    selectForm: any;
    niveauTypes: NiveauModel[];
    filteredNiveauTypes: NiveauModel[];
    filterNiveau: any;
        
    sideMenu = {        
        backButton: false,
        mainMenu : true,
        generalMenu : false,
        salesMenu: false,
        commandesMenu: false,
        reservesMenu: false,
        reportingMenu: false,
        reportingEditControl: false,
        reportingEditStock: false
    }

    userPermission: any;

    reportingMenusList: any;

    paramsList: ParamDetail[];

    currentRoutedComp: any;

    spinnerButtonStatus: boolean = false;
    spinnerButtonTimeout: any;
    isReportingPage: boolean = false;

    headerDeleteReportStatus: boolean = false;
    headerDeleteReportSubscription: Subscription;

    constructor(public router: Router,
        public activatedRoute: ActivatedRoute,
        public global: Global,
        public userService: UserService,
        private formBuilder: UntypedFormBuilder,
        public translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private idle: Idle,
        private keepalive: Keepalive,
        private dialog: MatDialog,
        private zone: NgZone,
        private dialogService: DialogsService,
        private translateService: TranslateService,
        private deviceService: DeviceDetectorService,
        public gestionDesDroitService: GestionDesDroitService,
        private loadingService: NgxSpinnerService,
        private titleService: Title,
        private focusMonitor : FocusMonitor,
        private storageService: StorageService,
        private keycloakService: KeycloakOperationService) {
        
        this.user = this.userService.getLoggedUser();
        if (this.user != null && this.user !== undefined) {
            this.USERCMINT = this.user.CMINT;
        }

        this.loadingService.getSpinner('primary').subscribe((r) => {
            if(this.spinnerButtonTimeout !== undefined && this.spinnerButtonTimeout !== null && this.spinnerButtonTimeout !== '') {
                clearTimeout(this.spinnerButtonTimeout);
            }

            this.spinnerButtonStatus = false;
            if(r.show) {
                this.spinnerTimeout(20);
            }
        })
        
        this.isMobile = this.deviceService.isMobile();

        if (this.user !== undefined && this.user !== null) {
            this.userLoggedIn = true;
        } else {
            this.userLoggedIn = false;
        }
    }

    spinnerTimeout(seconds: number) {
        const timeout = setTimeout(() => {
            if (this.router.url.includes('/reporting')) {
                this.spinnerButtonStatus = true;
                this.isReportingPage = true;
            } else {
                this.spinnerButtonStatus = true;
            }
            clearTimeout(timeout);
        }, (seconds * 1000));

        this.spinnerButtonTimeout = timeout;
    }

    navigateToPendingReports() {
        this.dialog.closeAll();
        this.loadingService.hide();

        const restrictionData = this.storageService.getItem('CurrentUserPermission');
        if(restrictionData['54_0']) { // Reporting
            return this.router.navigate(['reporting', 'reporting_attente']);
        }
    }

    async idleTimeout() {
        // sets an idle timeout of 10 seconds, for testing purposes.
        this.idle.setIdle(Constants.TIMEOUT);
        // sets a timeout period of {{LOGIN_TIMEOUT}} seconds. after {{LOGIN_TIMEOUT}} seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(Constants.LOGIN_TIMEOUT);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.'
            console.log(this.idleState);
            this.reset();
        });

        this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.Disconnect();
        });

        this.idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!'
            console.log(this.idleState);
        });

        this.idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!'
            console.log(this.idleState)
        });

        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);

        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

        this.getIdleTime();
        
        if(this.userLoggedIn) {
            this.user = this.userService.getLoggedUser();
            if (this.user) {
                await this.getListDroitsData(this.user.CTUINTGC);
            }
        }

        this.menuVisibilityParam = await this.userService.getRuleParamsData(10, 11, 'VBOOL1');
        this.userService.getLoggedInName.subscribe((status: string | null) => {
            this.changeUserLoggedStatus(status);
            if (status !== undefined && status !== null && status !== 'NOT_LOGGED_IN' && status !== 'LOGGED_OUT') {
                if (this.user === null || this.user === undefined) {
                    this.user = this.userService.getLoggedUser();

                    if (this.user != null && this.user !== undefined) {
                        this.USERCMINT = this.user.CMINT;
                    }
                }

                this._getMenuVisibility();
                this.refreshDroitsList(this.user.CTUINTGC);
                this.idle.watch()
                this.timedOut = false;
            } else {
                this.currentUserDroit = undefined;
                this.user = undefined;
                this.USERCMINT = undefined;
                this.menuVisibilityParam = undefined;

                if (this.user === null || this.user === undefined) {
                    this.user = this.userService.getLoggedUser();

                    if (this.user != null && this.user !== undefined) {
                        this.USERCMINT = this.user.CMINT;
                    }
                }

                this.idle.stop();
            }
        })
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    ngOnInit() {
        this.translateService.onDefaultLangChange.subscribe((res) => {
            this.titleService.setTitle(this.translateService.instant('kapptitre'));
        })

        this.getReportingMenus();
        this.__checkClosedTime();
        this.userPermission = this.storageService.getItem('CurrentUserPermission');

        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                setTimeout(() => {
                    if((e.url.indexOf('/bon-de-vente') > -1) ||
                    (e.url.indexOf('/bon-de-commande') > -1) ||
                    (e.url.indexOf('/retrait-marchandise') > -1) ||
                    (e.url.indexOf('/gestion-des-e2') > -1) ||
                    (e.url.indexOf('/historique-des-commandes') > -1) ||
                    (e.url.indexOf('/commandes-supprimees') > -1) ||
                    (e.url.indexOf('/devis') > -1) ||
                    (e.url.indexOf('/article') > -1)
                    ) {
                        if(this.rapidInput) {
                            this.focusMonitor.focusVia(this.rapidInput.nativeElement, null)
                            this.rapidInput.nativeElement.setAttribute('inputmode', 'text');
                        }
                    } else {
                        if(this.rapidInput) {
                            this.rapidInput.nativeElement.setAttribute('inputmode', 'text');
                        }
                    }
                }, 550)

                this.storageService.removeItem('previousNumBon');
                this.storageService.removeItem('previousBonPanier');

                this._activeMenuGroup();
            });

        let changable_lang = undefined;
        let userData = this.userService.getLoggedUser();
        if(userData !== undefined && userData != null && userData.CILANG !== undefined && userData.CILANG != null && userData.CILANG !== '') {
            let trimmedLangShort = userData.CILANG.substr(0, 2);
            changable_lang = trimmedLangShort;
        }
        this.setAppLang(changable_lang);

        this.userService.userNiveauTypesList.subscribe((niveauList: NiveauModel[]) => {
            if(niveauList !== undefined && niveauList !== null) {
                this.niveauTypes = niveauList;
                this.filteredNiveauTypes = this.niveauTypes;
            } else {
                this.niveauTypes = [];
                this.filteredNiveauTypes = this.niveauTypes;
            }
        })

        this.threeDotsSubscription = this.userService.headerThreeDots.subscribe((status) => {
            this.headerThreeDots = status;
        });

        this.headerSearchInputThreeDotsSubscription = this.userService.headerSearchInputThreeDots.subscribe((status) => {
            this.headerSearchInputThreeDotsStatus = status;
        });

        this.headerSearchInputTypeSubscription = this.userService.headerSearchInputType.subscribe((type) => {
            if (type !== undefined) {
                this.headerSearchInputType = type;
            }
        });

        this.searchInputSubscription = this.userService.headerSearchInput.subscribe((status) => {
            this.headerSearchInputStatus = status;
            if (status === false) {
                this.searchValue = '';
            }
        });

        this.searchInputOnChangeSubscription = this.userService.headerSearchInputOnChange.subscribe((status) => {
            this.headerSearchInputOnChangeStatus = status;
        });

        this.searchInputValueSubscription = this.userService.headerSearchInputValue.subscribe(
            (search_code) => {
                this.searchValue = search_code;
            }
        );

        this.headerAddSubscription = this.userService.headerAdd.subscribe((status) => {
            this.headerAddButtonStatus = status;
            if (status === false) {
                this.userService.headerAddListener.next(false);
            }
        });

        this.headerDeleteReportSubscription = this.userService.headerDeleteReportListener.subscribe((status) => {
            this.headerDeleteReportStatus = (status === 'selected');
        });

        this.headerSearchButtonSubscription = this.userService.headerSearchButton.subscribe((status) => {
            this.headerSearchButtonStatus = status;
        });

        this.idleTimeout();

        this.selectForm = this.formBuilder.group({
            search_niveau: ['1'],
        });

        if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['74_1']) {
            this.selectForm.get('search_niveau').setValue('10');
        }
    }

    async getReportingMenus() {
        this.reportingMenusList = reportingMenuTree;
        
        await this.getDecimalSymbol();
        await this.getListParams();
        let processedRules = this.userService.processRulesParams(this.paramsList);

        this.reportingMenusList = this.reportingMenusList.filter((_r: any) => {
            if(_r.child !== undefined && _r.child != null) {
                _r.child = _r.child.filter((_cr: any) => {
                    if(_cr.param !== undefined && _cr.param != null) {
                        const [ROW_ID, ROW_DET_ID, COLUMN] = _cr.param.split('_');
                        return processedRules[`${ROW_ID}_${ROW_DET_ID}`][COLUMN] == -1;
                    }

                    return true;
                });

                return true;
            }

            if(_r.param !== undefined && _r.param != null) {
                const [ROW_ID, ROW_DET_ID, COLUMN] = _r.param.split('_');
                return processedRules[`${ROW_ID}_${ROW_DET_ID}`][COLUMN] == -1;
            }
            
            return true;
        });
    }

    getTimeZoneFromWS(): Promise<void> {
        return new Promise((resolve, reject) => {
            const timezoneOffset = this.storageService.getItem('TIMEZONE_OFFSET');
            if (timezoneOffset !== undefined && timezoneOffset !== null) {
                resolve();
                return;
            }

            this.userService.getTimeZone().subscribe(
                (res) => {
                    if(res.success !== undefined && res.success != null && res.success === 'ok') {
                        this.storageService.setItem('TIMEZONE_OFFSET', res.data);
                    }
                    resolve();
                },
                err => {
                    this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                    reject();
                }
            );
        });
    }

    getListParams(): Promise<void> {
        return new Promise((resolve, reject) => {
          this.userService.getRulesParams(this.USERCMINT).subscribe(
            (resp: any) => {
                if(resp.success !== undefined && resp.success === 'ok') {              
                  this.paramsList = resp.data;
    
                  resolve(resp);
                } else {
                  this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                  reject('api_error');
                }
            }, 
            (error: any) => {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('error_callback');
            });
        });
      }

    __checkClosedTime() {
        const closedTime = this.storageService.getItem('CLOSED_TIME');

        if(closedTime !== undefined && closedTime != null) {
            const difference = moment().diff(moment(closedTime), 'seconds');
            
            if(difference >= Constants.LOGIN_TIMEOUT) {
                this.Disconnect();
            }
            this.storageService.removeItem('CLOSED_TIME');
        }
    }

    setdropDown() {
        if (this.currentPageName === '/motifs') {
            this.dropOption = [
                {
                    label: "kmaddkit1",
                    value: Constants.MOTIF_ANNULATION_DEVIS,
                },
                {
                    label: "kmaddkit2",
                    value: Constants.MOTIF_RELANCE_DEVIS,
                },
                {
                    label: "kmaddkit3",
                    value: Constants.MOTIF_SUPPRESSION_COMMANDE,
                },
                {
                    label: "kmaddkit4",
                    value: Constants.MOTIF_FORCAGE_COMMANDE,
                },
                {
                    label: "kmaddkit5",
                    value: Constants.MOTIF_FORCAGE_VENTE,
                },
                {
                    label: "kgblockdeblock",
                    value: Constants.MOTIF_DEBLOCAGE_STOCK,
                },
            ];
        } else {
            this.dropOption = [
                {
                    label: "ksadmin",
                    value: Constants.TYPE_NIVEAU_ADMIN,
                },
                {
                    label: "kssecretary",
                    value: Constants.TYPE_NIVEAU_SECRATARY,
                },
                {
                    label: "ksseller",
                    value: Constants.TYPE_NIVEAU_VENDOR,
                },
                {
                    label: "kspointinfo",
                    value: Constants.TYPE_NIVEAU_POINT_INFO,
                },
                {
                    label: "ksreserve",
                    value: Constants.TYPE_NIVEAU_RESERVE,
                }
            ];

            if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['69_1']) {
                this.dropOption = this.dropOption.filter((_f: any) => _f.value != Constants.TYPE_NIVEAU_ADMIN);
            }
        }        

        this.dropValue = this.dropOption[0].value;
        setTimeout(() => {
            this.submit_select_box();
        }, 500);
    }

    async _activeMenuGroup() {
        this.currentPageName = this.router.routerState.snapshot.url;
        this.setdropDown();
        this.headerSelectOption = (this.currentPageName === '/motifs' || this.currentPageName === '/niveau-de-securite') ? true : false;                
        if(this.currentPageName == '/bon-de-commande' || this.currentPageName == '/historique-des-commandes' || this.currentPageName == '/commandes-supprimees' || this.currentPageName.indexOf('/modify-bon-de-commande') > -1 || this.currentPageName.indexOf('/bon-de-commande-detail') > -1 ){
            this.openSubMenu('commandes');
        } else if(
            this.currentPageName.indexOf('/retrait-marchandise') > -1 || 
            this.currentPageName.indexOf('/modify-retrait-marchandise') > -1 || 
            this.currentPageName.indexOf('/retrait-marchandise-detail') > -1 ||
            this.currentPageName == '/gestion-des-e2' ||
            this.currentPageName.indexOf('/modify-gestion-des-e2') > -1 ||
            this.currentPageName.indexOf('/gestion-des-e2-detail') > -1
        ) {
            this.openSubMenu('reserves');
        } else if(this.currentPageName == '/postal-codes' || this.currentPageName == '/niveau-de-securite' || this.currentPageName == '/motifs' || this.currentPageName == '/motif-blocage' || this.currentPageName == '/utilisateurs' || this.currentPageName == '/gestion-des-droits' || this.currentPageName == '/magasin-complement-info' || this.currentPageName.indexOf('/modifier-utilisateur') > -1 || this.currentPageName == '/ajouter-utilisateur' ) {
            this.openSubMenu('general');
        } else if(this.currentPageName == '/bon-de-vente' ||
            this.currentPageName == '/bon-de-vente/autre-magasin' ||
            this.currentPageName.indexOf('/bon-de-commande') > -1 ||
            this.currentPageName.indexOf('/modify-bon-de-commande') > -1 ||
            this.currentPageName.indexOf('/bon-de-commande-detail') > -1 ||
            this.currentPageName == '/devis' ||
            this.currentPageName == '/create-devis' ||
            this.currentPageName.indexOf('/modify-devis') > -1 ||
            this.currentPageName.indexOf('/devis-detail') > -1 ||
            this.currentPageName.indexOf('/create-bon-de-vente') > -1 ||
            this.currentPageName.indexOf('/modify-bon-de-vente') > -1 ||
            this.currentPageName.indexOf('/detail-bon-de-vente') > -1
        ) {
            this.openSubMenu('sales'); 
        } else if(this.currentPageName.indexOf('/reporting') > -1) {
            let splitup = this.currentPageName.split('/reporting/');
            splitup = splitup.filter((el) => el !== '');
            const reportSlug = splitup?splitup[0]:undefined;
            if(reportSlug) {
                const foundSome = this.reportingMenusList.find((el: any) => {
                    if(el.child) {
                        return el.child.find((cl: any) => cl.value === reportSlug);
                    }
                    return false;
                });
                
                if(foundSome) {
                    this.openSubMenu(foundSome.value);
                    return;        
                }
            }

            this.openSubMenu('reporting'); 
        } else { 
            this.resetMenu();
        }
    }

    async getIdleTime() {
        try {
            const timeoutParamData = await this.userService.getRuleParamsData(10, 3, 'VINT1');
            if(timeoutParamData !== undefined && timeoutParamData !== null && !isNaN(+timeoutParamData) && +timeoutParamData > 0) {
                this.idle.setTimeout(+timeoutParamData);
            }
        } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    }

    async getDecimalSymbol() {
        try {
            const paramData = await this.userService.getRuleParamsData(10,1, 'VSTR4');
            this.storageService.setItem('DECIMAL_SYMBOL', ',');

            if(paramData !== undefined && paramData != null) {
                this.storageService.setItem('DECIMAL_SYMBOL', paramData);
            }
        } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    }

    async _getMenuVisibility() {
        if(this.menuVisibilityParam !== undefined && this.menuVisibilityParam !== null) {
            if(this.menuVisibilityParam == '-1') {
                this.allMenusVisibility = true;
            } else {
                this.allMenusVisibility = false;
            }
        } else {
            try {
                this.menuVisibilityParam = await this.userService.getRuleParamsData(10, 11, 'VBOOL1');
                this._getMenuVisibility();
            } catch(err) {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
        }
    }

    definingMenuVisibility(route_link: string): boolean {
        this.userPermission = this.storageService.getItem('CurrentUserPermission');
        if(this.userPermission == undefined || this.userPermission == null) return false;
        
        switch(route_link) {
            case 'client':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['2_0']);
            case 'article':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['23_0']);
            case 'niveau-de-securite':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['69_0']);
            case 'gestion-des-droits':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['74_0']);
            case 'utilisateurs':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['73_0']);
            case 'motifs':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['101_0']);
            case 'motif-blocage':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['52_0']);
            case 'postal-codes':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['71_0']);
            case 'magasin-complement-info':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['60_0']);
            case 'bon-de-vente':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['4_0']);
            case 'autre-magasin':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['110_0']);
            case 'devis':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['24_0']);
            case 'bon-de-commande':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['32_0']);
            case 'retrait-marchandise':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['35_1']);
            case 'gestion-des-e2':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['35_2']);
            case 'historique-des-commandes':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['75_0']);
            case 'commandes-supprimees':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['56_0']);
            case 'reporting':
                return this.allMenusVisibility || (!this.allMenusVisibility && this.userPermission['54_0']);
            case 'editions_de_controle':
            case 'suivi_des_bons':
                return this.allMenusVisibility || (!this.allMenusVisibility && (this.userPermission['54_0'] || this.userPermission['0_18']));
        }

        return false;
    }

    checkAccessFromArray(keys: Array<string>): boolean {
        this.userPermission = this.storageService.getItem('CurrentUserPermission');
        if(this.userPermission == undefined || this.userPermission == null) return false;

        return this.allMenusVisibility || (!this.allMenusVisibility && keys.some((key) => {
            return this.userPermission[key];
        }));
    }

    async setAppLang(prevLang?: string) {
        try {
            let lang_data = Constants.APP_DEFAULT_LANG;
            if(prevLang !== undefined && prevLang !== null  && prevLang !== '') {
                lang_data = prevLang;
            } else {
                lang_data = await this.userService.getRuleParamsData(10, 1, 'VCHXMUL1');
            }
    
            if(lang_data !== undefined && lang_data !== null && lang_data !== '') {
                this.translate.setDefaultLang(lang_data);
            } else {
                this.translate.setDefaultLang(Constants.APP_DEFAULT_LANG);
            }
        } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    }

    async refreshDroitsList(niveauID: string | null) {
        if(this.currentUserDroit === undefined || this.currentUserDroit === null) {
            await this.getListDroitsData(niveauID);
        }
    }

    async getListDroitsData(niveauID: string | null): Promise<any> {
        if(this.user === undefined || this.user == null) return;

        return new Promise((resolve, reject) => {
            this.userService.getListofDroits(this.USERCMINT, this.user.CIINT, niveauID).subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.currentUserDroit = res.data;
                        this.processUserDroitData();
                    }
                    resolve(res);
                },
                err => {
                    this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                    reject(err);
                }
            );
        })
    }

    _processDroitsData(data: DroitChild) {
        let processedData: {} = {};
        let childData: {} = {};

        processedData[data.id+'_'+data.value] = data.checked;

        if(data.child !== undefined && data.child != 'null') {
            let childRows: any = data.child;

            childRows.map((child_row: DroitChild) => {
                childData = {...childData, ...this._processDroitsData(child_row)};
            });
        }

        processedData = {...processedData, ...childData};

        return processedData;
    }

    processUserDroitData() {
        if(this.currentUserDroit === undefined || this.currentUserDroit === null) return;

        let processed: {} = {};

        this.currentUserDroit.general?.map(
            row => {                
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.ventes?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.reporting?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.clients?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.articles?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );
        
        this.currentUserDroit.confirmation_action?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );
        
        this.storageService.setItem('CurrentUserPermission', processed)
    }

    changeUserLoggedStatus(status: string | null) {
        if (status !== undefined && status !== null && status !== 'NOT_LOGGED_IN' && status !== 'LOGGED_OUT') {
            this.userLoggedIn = true;
        } else {
            this.userLoggedIn = false;
        }
    }

    public onRouterOutletActivate($event: any) {
        this.getTimeZoneFromWS();
        this.currentRoutedComp = $event;
    }

    public Disconnect = () => {
        if(this.currentRoutedComp?.canExit !== undefined && !this.currentRoutedComp?.canExit()) {
            return;
        }

        const userData = this.userService.getCurrentUser();

        this.user = undefined;
        this.USERCMINT = undefined;
        this.currentUserDroit = undefined;
        this.menuVisibilityParam = undefined;

        this.userService.emptyCurrentUser();
        this.dialog.closeAll();
        
        if (environment.ENABLE_KEYCLOAK) {
            this.keycloakService.logout();
        } else {
            this.storageService.clear();
            this.storageService.setItem('REMEMBER_USER', userData);

            this.zone.run(() => {
                this.router.navigate(['login']);
            });

        }

        this.translate.setDefaultLang(Constants.APP_DEFAULT_LANG);
    }

    search_callback() {
        this.userService.headerSearch.next(true);
    }

    add_callback() {
        this.userService.headerAddListener.next(true);
    }

    refreshReports() {
        this.userService.headerRefreshReportListener.next(true);
    }

    deleteSelectedReports() {
        this.userService.headerDeleteReportListener.next('clicked');
    }

    submit_search_input() {
        this.userService.headerSearchInputValue.next(this.searchValue);
    }

    change_search_input() {
        if(this.headerSearchInputOnChangeStatus) {
            this.userService.headerSearchInputValue.next(this.searchValue);
        }
    }

    submit_select_box() {
        let tempObject = {
            'value': this.dropValue,
            'pageName': (this.currentPageName === '/motifs') ? 'Motifs' : 'Security'
        }
        this.userService.headerSelectBox.next(tempObject);
    }

    _barCodeLogicCheck = (value: string) => {
        if(value !== undefined && value != null) {
          this.searchValue = value;

          if(value.indexOf('~') > -1) {
            const [CDART, PRIX] = value.split('~');
            this.searchValue = CDART;
          }
    
          if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
            let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
            CDART = CDART.replace(/^0+/, '')

            this.searchValue = CDART;
          }
    
          this.submit_search_input();
        }
      }

    scan_callback() {        
        const dialogRef = this.dialog.open(BarcodeScannerComponent,
          {
            width: '60vw',
            maxHeight: this.isMobile?'95vh':undefined,
            autoFocus: false
          }
        );    
        dialogRef.afterClosed().subscribe(result => {
          if(result && result.status === "success") {
            this._barCodeLogicCheck(result.data);
          }      
        });
      }

      onSearchChange(search: string) {
        this.filteredNiveauTypes = this.niveauTypes.filter(row => row.NIVNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
      }

      onNiveauSelectionChange(niveauValue){
        let tempObject = {
            'value': niveauValue,
            'pageName': 'gestiondes'
        }
        this.userService.headerSelectBox.next(tempObject);
      }
      ngOnDestroy() {
         this.searchInputSubscription.unsubscribe();
         this.headerSearchButtonSubscription.unsubscribe();
      }
      menuStatus(category,menuName){         
          if(this.currentUserDroit) {
            const index = this.currentUserDroit[category].findIndex(item => item.label == menuName);                
            return this.currentUserDroit[category][index]['checked'];
          } 
          return false;
      }

      rightsInvalid(){
          alert('testing');
      }
      navigation(url){
        this.router.navigate(['/' + url]);
      }
      openSubMenu(menuName){           
        this.sideMenu.mainMenu = false; 
        this.sideMenu.commandesMenu = false;
        this.sideMenu.reservesMenu = false;
        this.sideMenu.salesMenu = false;
        this.sideMenu.generalMenu = false;
        this.sideMenu.reportingMenu = false;
        this.sideMenu.reportingEditControl = false;
        this.sideMenu.reportingEditStock = false;

        this.sideMenu.backButton = true;
        if(menuName == 'general'){
        this.sideMenu.generalMenu = true;
        } else if (menuName == 'sales'){
        this.sideMenu.salesMenu = true;
        } else if (menuName == 'commandes'){
        this.sideMenu.commandesMenu = true;
        } else if (menuName == 'reserves') {
        this.sideMenu.reservesMenu = true;
        } else if (menuName == 'reporting'){
        this.sideMenu.reportingMenu = true;
        } else if (menuName == 'editions_de_controle'){
        this.sideMenu.reportingEditControl = true;
        } else if (menuName == 'editions_de_stocks'){
        this.sideMenu.reportingEditStock = true;
        }
      }
      goBack(){
        if(this.sideMenu.commandesMenu == true){
            this.sideMenu.mainMenu = false;
            this.sideMenu.salesMenu = true;
            this.sideMenu.backButton = true;
            this.sideMenu.reservesMenu = false;
            this.sideMenu.commandesMenu = false;
            this.sideMenu.reportingMenu = false;
        } else if(this.sideMenu.reservesMenu == true){
            this.sideMenu.mainMenu = false;
            this.sideMenu.salesMenu = true;
            this.sideMenu.backButton = true;
            this.sideMenu.reservesMenu = false;
            this.sideMenu.commandesMenu = false;
            this.sideMenu.reportingMenu = false;
        } else if(this.sideMenu.reportingEditControl == true){
            this.sideMenu.mainMenu = false;
            this.sideMenu.salesMenu = false;
            this.sideMenu.backButton = true;
            this.sideMenu.reservesMenu = false;
            this.sideMenu.commandesMenu = false;
            this.sideMenu.reportingEditControl = false;
            this.sideMenu.reportingMenu = true;
        } else if(this.sideMenu.reportingEditStock == true){
            this.sideMenu.mainMenu = false;
            this.sideMenu.salesMenu = false;
            this.sideMenu.backButton = true;
            this.sideMenu.reservesMenu = false;
            this.sideMenu.commandesMenu = false;
            this.sideMenu.reportingEditStock = false;
            this.sideMenu.reportingMenu = true;
        } else {
            this.sideMenu.mainMenu = true;
            this.sideMenu.generalMenu = false;
            this.sideMenu.backButton = false;
            this.sideMenu.salesMenu = false;     
            this.sideMenu.reportingMenu = false;       
        }
      }
      resetMenu(){
        this.sideMenu = {        
            backButton: false,
            mainMenu : true,
            generalMenu : false,
            salesMenu: false,
            commandesMenu: false,
            reservesMenu: false,
            reportingMenu: false,
            reportingEditControl: false,
            reportingEditStock: false
        }
      }
      inputFocus(event){
        this.rapidInput.nativeElement.setAttribute('inputmode', 'text');
        event.target.select();
      }

      manuallyCancelSubscription() {
          this.loadingService.hide();

          this.userService.__successRedirect().then((result) => {
              // After a redirection
          });
      }

      @HostListener('window:unload', [ '$event' ])
      unloadHandler(event) {
        this.storageService.setItem('CLOSED_TIME', moment().format('YYYY-MM-DD HH:mm:ss'));
      }

      @HostListener("window:focus", ["$event.target.value"])
      onFocused(e) {
        this.user = this.userService.getLoggedUser();
        if (this.user != null && this.user !== undefined) {
            this.USERCMINT = this.user.CMINT;
        }
        
        this.isMobile = this.deviceService.isMobile();

        if (this.user !== undefined && this.user !== null) {
            this.userLoggedIn = true;
        } else {
            this.userLoggedIn = false;
        }

        this.userPermission = this.storageService.getItem('CurrentUserPermission');

        let changable_lang = undefined;
        let userData = this.userService.getLoggedUser();
        if(userData !== undefined && userData != null && userData.CILANG !== undefined && userData.CILANG != null && userData.CILANG !== '') {
            let trimmedLangShort = userData.CILANG.substr(0, 2);
            changable_lang = trimmedLangShort;
        }
        this.setAppLang(changable_lang);

        if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['74_1']) {
            this.selectForm.get('search_niveau').setValue('10');
        }

        if(!this.userService.isUserLoggedIn()) {
            this.router.navigate(['login']);
        }
      }

      @HostListener("window:blur", ["$event.target.value"])
      onBlur(e) {
        this.user = this.userService.getLoggedUser();
        if (this.user != null && this.user !== undefined) {
            this.USERCMINT = this.user.CMINT;
        }
        
        this.isMobile = this.deviceService.isMobile();

        if (this.user !== undefined && this.user !== null) {
            this.userLoggedIn = true;
        } else {
            this.userLoggedIn = false;
        }

        this.userPermission = this.storageService.getItem('CurrentUserPermission');

        let changable_lang = undefined;
        let userData = this.userService.getLoggedUser();
        if(userData !== undefined && userData != null && userData.CILANG !== undefined && userData.CILANG != null && userData.CILANG !== '') {
            let trimmedLangShort = userData.CILANG.substr(0, 2);
            changable_lang = trimmedLangShort;
        }
        this.setAppLang(changable_lang);

        if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['74_1']) {
            this.selectForm.get('search_niveau').setValue('10');
        }

        if(!this.userService.isUserLoggedIn()) {
            this.router.navigate(['login']);
        }
      }
}
