<div class="modal-container w-100" id="couche_prix_article">
    <form >
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">{{ pageTitle | translate }}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <form [formGroup]="typeForm" (ngSubmit)="typeFormSubmitter()">
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12 my-4">
                        <mat-radio-group formControlName="TYPECDE" class="vertical-radio-group" aria-label="{{'kboncommodifytypecmd' | translate}}">
                            <mat-radio-button class="vertical-radio-button" *ngFor="let item of typeCdeList" [value]="item.value">{{ item.label | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>

            <mat-card-actions class="text-center">
                <button mat-button type="submit" class="bt_send">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kuok' | translate}}
                    </span>
                </button>
                <button mat-button [mat-dialog-close] class="bt_send">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{'kartclose' | translate}}
                    </span>
                </button>
            </mat-card-actions>
        </form>
    </mat-card>
    </form>
</div>