<form [formGroup]="editItemForm" (ngSubmit)="editItemFormSubmitter()">
    <div class="row">
        <div class="col-4 col-md-6 text-left pr-1">
            <mat-form-field floatLabel="always" class="pr-1">
                <input matInput formControlName="CDART" [disabled]="isDisabled">
            </mat-form-field>
        </div>
        <div class="col-8 col-md-6 text-left pl-1">
            <mat-form-field floatLabel="always">
                <input matInput formControlName="CALIB" [disabled]="isDisabled">
            </mat-form-field>
        </div>

        <div class="col-12 text-left">
            <h3>Géographie Réserve:</h3>
            <div class="boxed-section">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <mat-radio-group
                        aria-labelledby="Géographie réserve"
                        class="example-radio-group m-0" value="Allée">
                            <mat-radio-button class="example-radio-button" value="Allée">
                                {{ 'Allée' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button" value="Bout d\allée">
                                {{ 'Bout d’allée' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button" value="Mezzanine">
                                {{ 'Mezzanine' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-12 col-md-7">
                        <mat-form-field floatLabel="always" appearance="outline">
                            <mat-label>{{ 'N° allée' | translate }}</mat-label>
                            <input inputmode="none" [imask]="quantityMask" [unmask]="true" matInput
                                   class="text-uppercase">
                        </mat-form-field>

                        <mat-form-field floatLabel="always" appearance="outline">
                            <mat-label>{{ 'Emplacement' | translate }}</mat-label>
                            <input inputmode="none" [imask]="quantityMask" [unmask]="true" matInput
                                   class="text-uppercase">
                        </mat-form-field>

                        <mat-form-field floatLabel="always" appearance="outline">
                            <mat-label>{{ 'Hauteur' | translate }}</mat-label>
                            <input inputmode="none" [imask]="maskOptions" [unmask]="true" matInput
                                   class="text-uppercase">
                        </mat-form-field>

                        <mat-form-field floatLabel="always" appearance="outline">
                            <mat-label>{{ 'Qté à l\'emplacement' | translate }}</mat-label>
                            <input inputmode="none" [imask]="quantityMask" #articleQty [unmask]="true" matInput formControlName="QTY"
                                   class="text-uppercase">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12" [class.mt-2]="isMobile">
            <div class="row no-gutters">
                <div class="col-12">

                </div>
            </div>
        </div>
    </div>

    <div class="bloc_container w-100 m-0">
        <div class="row bg_white">
            <div class="col-4 col-md-4 text-left">
                <button type="button" class="bt_valid" mat-button [title]="'Actions' | translate" (click)="openUpBottomSheet()">
                    <i class="material-icons">miscellaneous_services</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'Actions' | translate }}
                    </span>
                </button>
            </div>
            <div class="col-8 col-md-8 text-right">
                <button type="submit" class="bt_valid" mat-button [title]="'kuok' | translate">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'kuok' | translate }}
                    </span>
                </button>
                <button class="bt_valid ml-2" type="button" mat-button mat-dialog-close [title]="'kucancel' | translate">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ ('ktcol') | translate }}
                    </span>                    
                </button>
            </div>                
        </div>
    </div>
</form>
