import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Pipe({
  name: 'formatTele',  
})
export class PhoneNumberPipe implements PipeTransform {

  constructor() { }

  transform(value: any) {
    if(value !== null && value !== '') {
        if(phoneNumberUtil.isPossibleNumberString(value, '+')) {
            const phoneNumberInst = phoneNumberUtil.parse(value.toString());
            if(phoneNumberInst) {
                return phoneNumberUtil.formatOutOfCountryCallingNumber(phoneNumberInst);
            }
            
            return value;
        }

        return value;
    }

    return '';
  }
}