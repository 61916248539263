<div class="row no-gutters mt-3" *ngIf="displayType !== 'bonde'">
    <div class="col-12 col-sm-12 col-md-12">        
        <div class="row no-gutters">
            <div *ngIf="displayOnly === undefined || displayOnly === 'block_stock'" [class]="displayOnly === 'block_stock'?'col-lg-12':'col-lg-4 col-md-4 col-12'">
                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-6 col-md-6 col-lg-6">
                        <mat-form-field>
                            <input matInput [ngModel]="(STOCK_DISPO?STOCK_DISPO:0) | cap_decimal"
                                [placeholder]="'kartstockdis' | translate" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-6 col-lg-6">
                        <mat-form-field>
                            <input matInput [ngModel]="(STOCK_BLOQUE?STOCK_BLOQUE:0) | cap_decimal"
                                [placeholder]="'kartstockbloq' | translate" disabled>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-12 col-md-12">
                        <div class="table-scroller">
                            <table id="stock_motif_datatable" #motifTable datatable [dtOptions]="dtMotifOptions" [dtTrigger]="dtMotifTrigger" class="row-border hover dataTable no-footer">
                                <thead>
                                    <tr>
                                        <th translate>kartmotifbloq</th>
                                        <th translate>kartnoofbloq</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="listMotifBlocage?.length != 0 && !showMotifLoader">
                                    <tr *ngFor="let motif of listMotifBlocage" [class.non-editable]="(motif && motif.OKMODIF != '-1') || !stockBlocageEditable" [matTooltip]="((motif && motif.OKMODIF != '-1') || !stockBlocageEditable)?'':('karteditbloqtock' | translate)" (click)="checkEditable(motif)">
                                        <td class="text-center">
                                            {{ motif.LIBEL }}
                                        </td>
                                        <td  class="text-center">
                                            {{ (motif.VBLOC?motif.VBLOC:0) | cap_decimal }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="listMotifBlocage?.length == 0 || showMotifLoader">
                                    <tr>
                                        <td  colspan="2" *ngIf="listMotifBlocage?.length == 0 && !showMotifLoader" class="no-data-available text-center" translate>kartnores</td>
                                        <td colspan="2" *ngIf="showMotifLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                        <td style="display: none;"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>            
            <div class="table-scroller" *ngIf="displayOnly === undefined || displayOnly === 'emplacement_stock'" [class]="displayOnly === 'emplacement_stock'?'col-lg-12':'col-lg-8 col-md-8 col-12'">           
                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-6 col-md-3 col-lg-2">
                        <mat-form-field>
                            <input matInput [value]="emplacementValue | cap_decimal"
                                [placeholder]="'kartstockemp' | translate" disabled>
                        </mat-form-field>
                    </div>                    
                    <div class="col-6 col-md-2 col-lg-2">
                        <mat-form-field>
                            <input matInput [value]="e2Value | cap_decimal"
                                [placeholder]="'kartlabele2' | translate" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2 col-lg-2">
                        <mat-form-field>
                            <input matInput [value]="edValue | cap_decimal"
                                [placeholder]="'kartlabeled' | translate" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-2 col-lg-2">
                        <mat-form-field>
                            <input matInput [value]="blValue | cap_decimal"
                                [placeholder]="'kartlabelbl' | translate" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2">
                        <mat-form-field> 
                            <input matInput [value]="livEnCoursValue | cap_decimal"
                                [placeholder]="'kartdelencours' | translate" disabled>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2">
                        <mat-form-field>
                            <input matInput [value]="attenteLivValue | cap_decimal"
                                [placeholder]="'kartattendliv' | translate" disabled>
                        </mat-form-field>
                    </div>

                    <div class="clearfix"></div>
                    <div class="col-3 no-padding text-left d-none">
                        <!-- Hided for time being -->
                        <button mat-raised-button class="bt_add opacity_moins">
                            <mat-icon>print</mat-icon>
                            <div class="text-medium" translate>kartprint</div>
                        </button>
                    </div>
                </div>
                <div class="row" [class.no-gutters]="isMobile">
                    <div class="col-12 col-md-12">
                        <div class="table-scroller">
                            <table id="stock_emplacement_datatable" #emplacementTable datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover dataTable no-footer">
                                <thead>
                                    <tr>
                                        <th translate>kartalle</th>
                                        <th translate>kartcol</th>
                                        <th translate>kartniv</th>
                                        <th translate>kartprin</th>
                                        <th translate>kartstock</th>
                                        <th translate>kartlastcntrl</th>
                                        <th translate>kartlabeled</th>
                                        <th translate>kartlabelbl</th>
                                        <th translate>kartlabelliv</th>
                                        <th translate>kartlabelattliv</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="filteredStockEmplacement?.length != 0 && !showLoader">
                                    <tr *ngFor="let stockEmplacement of filteredStockEmplacement" (click)="openTransferStockLocation(stockEmplacement)">
                                        <td class="text-center">{{ stockEmplacement.EMPATTR1 }}</td>
                                        <td class="text-center">{{ stockEmplacement.EMPATTR2 }}</td>
                                        <td class="text-center">{{ stockEmplacement.EMPATTR3 }}</td>
                                        <td class="text-center">
                                            <mat-icon [color]="(stockEmplacement.ESPRINC == '-1')?'primary':'warn'">{{ (stockEmplacement.ESPRINC == '-1')?'check_circle':'cancel' }}</mat-icon>                                
                                        </td>
                                        <td class="text-center">{{ (stockEmplacement.MVTQTE?+stockEmplacement.MVTQTE:0) | number }}</td>
                                        <td class="text-center">{{ (stockEmplacement.ESLASTCHECK != undefined && stockEmplacement.ESLASTCHECK != null && stockEmplacement.ESLASTCHECK != 0)?stockEmplacement.ESLASTCHECK:"" }}</td>
            
                                        <td class="text-center">{{ stockEmplacement.ED_value?+stockEmplacement.ED_value:0 | number }}</td>
                                        <td class="text-center">{{ stockEmplacement.BL_value?+stockEmplacement.BL_value:0 | number }}</td>
                                        <td class="text-center">{{ stockEmplacement.LIVENCOUR?+stockEmplacement.LIVENCOUR:0 | number }}</td>
                                        <td class="text-center">{{ stockEmplacement.LIVENATT?+stockEmplacement.LIVENATT:0 | number }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="filteredStockEmplacement?.length == 0 || showLoader">
                                    <tr>
                                        <td  colspan="10" *ngIf="filteredStockEmplacement?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                        <td colspan="10" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                        <td style="display: none;"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row no-gutters mt-3 table-scroller" style="max-height: 500px;" *ngIf="displayType === 'bonde'">
    <div class="col-12 col-sm-12 col-md-12">
        <div >
            <div class="table-scroller" class="col-lg-12">           
                <mat-accordion>
                    <mat-expansion-panel [expanded]="defaultTab === 'info_logistique'" id="info_logistique">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kartartinfolog' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <app-article-logistique [flowCMINT]="currentStockMag" [displayType]="'component'" [(article)]="article"></app-article-logistique>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="defaultTab === 'blocage_stock'" id="blocage_stock">
                        <mat-expansion-panel-header>
                            <mat-panel-title>                                
                                {{'kartstockblock' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                                
                            <div class="row mt-4" [class.no-gutters]="isMobile">
                                <div class="col-6 col-md-6 col-lg-6">
                                    <mat-form-field>
                                        <input matInput [ngModel]="(STOCK_DISPO?STOCK_DISPO:0) | cap_decimal"
                                            [placeholder]="'kartstockdis' | translate" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-6 col-lg-6">
                                    <mat-form-field>
                                        <input matInput [ngModel]="(STOCK_BLOQUE?STOCK_BLOQUE:0) | cap_decimal"
                                            [placeholder]="'kartstockbloq' | translate" disabled>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="table-scroller stock_motif_datatable">
                                <table id="stock_motif_datatable" #motifTable datatable [dtOptions]="dtMotifOptions" [dtTrigger]="dtMotifTrigger" class="row-border hover dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th class="text-center" translate>kartmotifbloq</th>
                                            <th class="text-center" translate>kartnoofbloq</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="listMotifBlocage?.length != 0 && !showMotifLoader">
                                        <tr *ngFor="let motif of listMotifBlocage" [class.non-editable]="(motif && motif.OKMODIF != '-1') || !stockBlocageEditable" [matTooltip]="((motif && motif.OKMODIF != '-1') || !stockBlocageEditable)?'':('karteditbloqtock' | translate)" (click)="checkEditable(motif)">
                                            <td class="text-center">
                                                {{ motif.LIBEL }}
                                            </td>
                                            <td  class="text-center">
                                                {{ (motif.VBLOC?motif.VBLOC:0) | cap_decimal }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="listMotifBlocage?.length == 0 || showMotifLoader">
                                        <tr>
                                            <td  colspan="2" *ngIf="listMotifBlocage?.length == 0 && !showMotifLoader" class="no-data-available text-center" translate>kartnores</td>
                                            <td colspan="2" *ngIf="showMotifLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                            <td style="display: none;"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                                
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="defaultTab === 'autre_magasins'" id="autre_magasins" *ngIf="(article?.CAGESTOCK == '-1' && article?.CAOKLIV != '-1')">
                        <mat-expansion-panel-header>
                          <mat-panel-title>                    
                            {{'kartothmag' | translate}}                    
                          </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <app-article-logistique-stock-autre [(article)]="article" [displayType]="'component'" [enableMagSelection]="enableStockMagSelection" (magasinChange)="onChosenMagChange($event)"></app-article-logistique-stock-autre>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="defaultTab === 'comm_magasin'" id="comm_magasin">
                        <mat-expansion-panel-header>
                          <mat-panel-title>                    
                            {{'kartretstore' | translate}}
                          </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <app-article-logistique-magasin [flowCMINT]="currentStockMag" [(article)]="article" [displayType]="'component'"></app-article-logistique-magasin>
                        </ng-template>
                    </mat-expansion-panel>
    
                    <mat-expansion-panel [expanded]="defaultTab === 'comm_entreprot'" id="comm_entreprot">
                        <mat-expansion-panel-header>
                        <mat-panel-title>                    
                            {{'kartretentrepot' | translate}}
                        </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <app-article-logistique-entrepot  [flowCMINT]="currentStockMag" [(article)]="article" [displayType]="'component'"></app-article-logistique-entrepot>
                        </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="defaultTab === 'stock_emplacement'" id="stock_emplacement">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'kartstockemplace' | translate }}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                                
                            <div class="row mt-4" [class.no-gutters]="isMobile">
                                <div class="col-6 col-md-3 col-lg-3">
                                    <mat-form-field>
                                        <input matInput [value]="emplacementValue | cap_decimal"
                                            [placeholder]="'kartstockemp' | translate" disabled>
                                    </mat-form-field>
                                </div>                    
                                <div class="col-6 col-md-2 col-lg-1">
                                    <mat-form-field>
                                        <input matInput [value]="e2Value | cap_decimal"
                                            [placeholder]="'kartlabele2' | translate" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-2 col-lg-1">
                                    <mat-form-field>
                                        <input matInput [value]="edValue | cap_decimal"
                                            [placeholder]="'kartlabeled' | translate" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-2 col-lg-1">
                                    <mat-form-field>
                                        <input matInput [value]="blValue | cap_decimal"
                                            [placeholder]="'kartlabelbl' | translate" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-3 col-lg-2">
                                    <mat-form-field>
                                        <input matInput [value]="livEnCoursValue | cap_decimal"
                                            [placeholder]="'kartdelencours' | translate" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-md-3 col-lg-2">
                                    <mat-form-field>
                                        <input matInput [value]="attenteLivValue | cap_decimal"
                                            [placeholder]="'kartattendliv' | translate" disabled>
                                    </mat-form-field>
                                </div>
        
                                <div class="clearfix"></div>
                                <div class="col-3 no-padding text-left d-none">
                                    <!-- Hided for time being -->
                                    <button mat-raised-button class="bt_add opacity_moins">
                                        <mat-icon>print</mat-icon>
                                        <div class="text-medium" translate>kartprint</div>
                                    </button>
                                </div>
                            </div>     
                            <div class="table-scroller stock_emplacement_datatable">
                                <table id="stock_emplacement_datatable" #emplacementTable datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th class="text-center" translate>kartalle</th>
                                            <th class="text-center" translate>kartcol</th>
                                            <th class="text-center" translate>kartniv</th>
                                            <th class="text-center" translate>kartprin</th>
                                            <th class="text-center" translate>kartstock</th>
                                            <th class="text-center" translate>kartlastcntrl</th>
                                            <th class="text-center" translate>kartlabeled</th>
                                            <th class="text-center" translate>kartlabelbl</th>
                                            <th class="text-center" translate>kartlabelliv</th>
                                            <th class="text-center" translate>kartlabelattliv</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="filteredStockEmplacement?.length != 0 && !showLoader">
                                        <tr *ngFor="let stockEmplacement of filteredStockEmplacement" (click)="openTransferStockLocation(stockEmplacement)">
                                            <td class="text-center">{{ stockEmplacement.EMPATTR1 }}</td>
                                            <td class="text-center">{{ stockEmplacement.EMPATTR2 }}</td>
                                            <td class="text-center">{{ stockEmplacement.EMPATTR3 }}</td>
                                            <td class="text-center">
                                                <mat-icon [color]="(stockEmplacement.ESPRINC == '-1')?'primary':'warn'">{{ (stockEmplacement.ESPRINC == '-1')?'check_circle':'cancel' }}</mat-icon>                                
                                            </td>
                                            <td class="text-center">{{ (stockEmplacement.MVTQTE?+stockEmplacement.MVTQTE:0) | number }}</td>
                                            <td class="text-center">{{ stockEmplacement.ESLASTCHECK?stockEmplacement.ESLASTCHECK:'' }}</td>
                
                                            <td class="text-center">{{ stockEmplacement.ED_value?+stockEmplacement.ED_value:0 | number }}</td>
                                            <td class="text-center">{{ stockEmplacement.BL_value?+stockEmplacement.BL_value:0 | number }}</td>
                                            <td class="text-center">{{ stockEmplacement.LIVENCOUR?+stockEmplacement.LIVENCOUR:0 | number }}</td>
                                            <td class="text-center">{{ stockEmplacement.LIVENATT?+stockEmplacement.LIVENATT:0 | number }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="filteredStockEmplacement?.length == 0 || showLoader">
                                        <tr>
                                            <td  colspan="10" *ngIf="filteredStockEmplacement?.length == 0 && !showLoader" class="no-data-available text-center" translate>kartnores</td>
                                            <td colspan="10" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                            <td style="display: none;"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                            
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <div class="text-center">
            <button mat-button (click)="closeDialog()" class="bt_send mt-3">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </div>
    </div>
</div>

