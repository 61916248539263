
<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium">{{'kclclearform' | translate}}</div>
            </button>
        </div>
    </div>
</div>

<form [formGroup]="searchForm" (ngSubmit)="headerSearchFormSubmitter()" >    
    <div class="bloc_container padding_15 bg_light">
        <div class="row no-gutters">
            <div class="col-12 ">

                <div class="row no-gutters">
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="NUMCOM"
                                [placeholder]="'kbonumdebon' | translate"
                                class="text-uppercase" #filterFirst>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="TRAISOC"
                                [placeholder]="'kclnom' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="TPRENOM"
                                [placeholder]="'kclfirstnom' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="TPORT"
                                [placeholder]="'kcltel' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters">                        
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="CODPOS"
                                type="number"
                                [placeholder]="'kboncodeopostal' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="TVILLE"
                                [placeholder]="'kpcity' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>                   
                    <div class="col-12 col-md-3 col-lg-3">
                        <mat-checkbox formControlName="USERCON"> {{'kbonuser' | translate}}</mat-checkbox>
                    </div>     
                </div>

                <div class="row no-gutters">
                    <div class="col-6 col-md-2 col-lg-2">                            
                        <mat-form-field>
                            <input matInput type="number" formControlName="MTTTCMIN"                                    
                                class="text-uppercase">
                            <span matPrefix>{{'kbontotbetn' | translate}} &nbsp;</span>
                        </mat-form-field>                            
                    </div>                        
                    <div class="col-6 col-md-2 col-lg-2">                                                                                    
                        <mat-form-field>
                            <input matInput type="number" formControlName="MTTTCMAX"                                    
                                class="text-uppercase">
                                <span matPrefix>{{'kbonand' | translate}} &nbsp;</span>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-12 mt-2 mt-md-0 text-center">
                <button type="submit" class="bt_valid" mat-button>
                    <i class="material-icons">search</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ 'kpsearch' | translate }}
                    </span>
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kucancel" | translate }}
                    </span>                        
                </button>
            </div>
        </div>
    </div>
</form>