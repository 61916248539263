import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Client } from 'models/clients';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from 'providers/api/userService';
import { Magasin } from 'models/magasins';
import * as moment from "moment";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-client',
  templateUrl: './search-client.component.html',
  styleUrls: ['./search-client.component.scss']
})
export class SearchClientComponent implements OnInit {

  searchedClient: Client;
  filterValues: any;
  //Magasin
  listMagasins: Magasin[] = [];
  filteredMagasins: Magasin[] = [];
  filterMagasin: string = "";
  filteredPays: any;
  filterPays: string = "";
  listPays: any;  

  searchNomPhrase: string;
  // Mobile - Filter needs
  searchValue: any;
  
  searchInputSubscription: Subscription;
  searchInputValueSubscription: Subscription;
  popupCloseSubscription : Subscription

  isMobile: boolean = false;
  formSearchType = 'basic';

  preFilterConf: any;

  flowCMINT: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SearchClientComponent>,
    private userService: UserService,
    private deviceDetector: DeviceDetectorService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();    
    if(this.data) {
      this.flowCMINT = this.data.CMINT;
      this.searchNomPhrase = this.data.client_nom;      

      if(this.data.formType === 'advancedSearch') {
        this.formSearchType = 'advancedSearch';
        this.preFilterConf = this.data.filterData;
        this.filterValues = this.data.filterData.form_data;

      }
    }

    this.popupCloseSubscription = this.userService.popupClose.subscribe(result =>{
      if(result.action === 'clientAdsearch'){
        const formData = this.userService.getAdvanceSearchFormData();
        if(this.preFilterConf){
          this.preFilterConf['form_data'] = formData;
        }
        this.filterValues = formData;
        console.log(this.filterValues);
        console.log(result);
      }
    })
    
    this.searchInputSubscription = this.userService.headerSearchInput.subscribe((status) => {      
      if(status === false) {
          this.searchValue = undefined;          
      }
    });

    this.searchInputValueSubscription = this.userService.headerSearchInputValue.subscribe(
      (search_code) => {        
        if(search_code !== undefined) {
          this.searchValue = search_code;
        }
      }
    );
  }

  ngOnDestroy() {
    this.searchInputSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe()
  }

  search_callback() {
    this.userService.headerSearch.next(true);
  }

  submit_search_input() {
    this.userService.headerSearchInputValue.next(this.searchValue);    
    this.filterValues = {};
    this.filterValues['rapidSearch'] = this.searchValue;
  }
  inputFocus(event){
    event.target.select();
  }

  submitSelectedClient = (chosenClient: Client) => {
    this.searchedClient = chosenClient;

    this.searchValue = undefined;
    this.dialogRef.close({ status: 'success', client_data: this.searchedClient });
  }
 
  loadMagasins = (listMagasins: Magasin[]) => {
    this.listMagasins = listMagasins; 
    this.filteredMagasins = this.listMagasins    
  }

  loadPays = (listPays:any) => {
    this.listPays = listPays; 
    this.filteredPays = this.listPays    
  }
  get filterValuesFormat(): string {    
    let filterArr = [];
   
    if(this.filterValues.CMINT !== undefined && this.filterValues.CMINT !== null) {
      if(this.filterValues.CMINT === '') {
        filterArr.push(this.translateService.instant('kcltouslesmag'));
      } else if(this.filterValues.CMINT === 'non_defini') {
        filterArr.push(this.translateService.instant('kclnondefi'));
      } else {
        if(this.filteredMagasins) {
          let magLabel = this.filteredMagasins.find((row) => row.CMINT == this.filterValues.CMINT);          
            filterArr.push(magLabel?.CMRAISOC);        
        }
      }
    }  
    if( this.filterValues['rapidSearch']){
      filterArr.push( this.filterValues['rapidSearch']);
    }
    if(this.filterValues.NOM !== undefined && this.filterValues.NOM !== null && this.filterValues.NOM !== '') {
      filterArr.push(this.filterValues.NOM);
    }
    if(this.filterValues.TPRENOM !== undefined && this.filterValues.TPRENOM !== null && this.filterValues.TPRENOM !== '') {
      filterArr.push(this.filterValues.TPRENOM);
    }
    if(this.filterValues.CP !== undefined && this.filterValues.CP !== null && this.filterValues.CP !== '') {
      filterArr.push(this.filterValues.CP);
    }
    if(this.filterValues.VILLE !== undefined && this.filterValues.VILLE !== null && this.filterValues.VILLE !== '') {
      filterArr.push(this.filterValues.VILLE);
    }
    if(this.filterValues.TEL !== undefined && this.filterValues.TEL !== null && this.filterValues.TEL !== '') {
      filterArr.push(this.filterValues.TEL);
    }
    if(this.filterValues.BIRTHDATEMIN !== undefined && this.filterValues.BIRTHDATEMIN !== null && this.filterValues.BIRTHDATEMIN !== '') {
      filterArr.push(moment(this.filterValues.BIRTHDATEMIN).format('DD.MM.YYYY'));
    }
    if(this.filterValues.lettre !== undefined && this.filterValues.lettre !== null && this.filterValues.lettre !== '') {
      filterArr.push(this.filterValues.lettre);
    }   
    if(this.filterValues.TEMAIL !== undefined && this.filterValues.TEMAIL !== null && this.filterValues.TEMAIL !== '') {
      filterArr.push(this.filterValues.TEMAIL);
    }   
    if(this.filterValues.PAYS !== undefined && this.filterValues.PAYS !== null && this.filterValues.PAYS !== '') {
      if(this.filterValues.PAYS === 'tous') {
        filterArr.push(this.translateService.instant('kcltouslespays'));
      } else if(this.filterValues.PAYS === 'non_defini') {
        filterArr.push(this.translateService.instant('kclnondefi'));
      } else {
        if(this.filteredPays) {
          let paysLabel = this.filteredPays.find((row: any) => row.CODPAYS == this.filterValues.PAYS);
          filterArr.push(paysLabel.PAYSNOM);
        }
      }        
    }
    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
