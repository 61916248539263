import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'providers/constants';
import { UserModel } from 'models/user-model';

@Injectable()
export class UtilisatuersService {
    constructor(private http: HttpClient) {
    }
    
    getListofUsers(userData: UserModel, page?: number): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        
        params['CMINT'] = userData.CMINT;
        params['CTUINTGC'] = 'tous';

        if(Constants.USERS_DEFAULT_STATUS === 0) {
            params['CIACTIF'] = Constants.USERS_DEFAULT_STATUS;
        }        

        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_USER_URL + 'getUserAtLoad', options);        
    }

    getListofNiveau(IDMAG: string, CIINT: string): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');               

        const options = {
            headers: headers,
            params: {
                IDMAG: IDMAG,
                IDSOC: Constants.IDSOC,
                CIINT: CIINT
            }
        };        
        
        return this.http.get(Constants.API_USER_URL + 'getUserNiveauList', options);        
    }

    getUserByID(user_id: number): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');               

        const params = {};
        params['CIINT'] = user_id;

        const options = {
            headers: headers,
            params: params         
        };        
        
        return this.http.get(Constants.API_USER_URL + 'getUserByID', options);        
    }

    getMagasinStructure(data: any): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');               

        const options = {
            headers: headers,
            params: data           
        };        
        
        return this.http.get(Constants.API_USER_URL + 'getUserMagasinStruct', options);        
    }

    searchUsers(data: any, page?: number): Observable<any> {        

        const params = {};       
        
        params['orderby'] = data.orderby;
        params['sort'] = data.sort;
        params['CMINT'] = data.CMINT;        

        if(data.CIACTIF && data.CIACTIF !== "") {
            params['CIACTIF'] = data.CIACTIF;
        }

        if(data.code && data.code !== "") {
            params['code'] = data.code;
        }

        if(data.nom && data.nom !== "") {
            params['nom'] = data.nom;
        }        

        if(data.CTUINTGC && data.CTUINTGC !== "") {
            params['CTUINTGC'] = data.CTUINTGC;
        }        

        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }
        if(data.rapidsearch && data.rapidsearch !== "") {
            params['rapidsearch'] = data.rapidsearch;
        }
        
        return this.http.post(Constants.API_USER_URL + 'searchUser', params);        
    }

    addNewUser(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_USER_URL + 'createUser', data, options);
    }

    updateUser(data: any): Observable<any> {

        const headers = new HttpHeaders();

        const options = {
            headers: headers
        };        
        
        return this.http.post(Constants.API_USER_URL + 'updateUser', data, options);
    }

    deleteUser(row_id: number): Observable<any> {

        const headers = new HttpHeaders();            
        headers.append('Content-Type', 'application/json');
        
        const params = {};
        params['TROWID'] = row_id;            

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_USER_URL + 'deleteCode', options);
    }

}