import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';
import { DialogsService } from 'providers/api/dialogService';
import { StorageService } from 'providers/api/storageService';
import { UserService } from 'providers/api/userService';

@Injectable()
export class AuthGuard  {

    base_url: string;

    constructor(private router: Router,
                private userService: UserService,
                private dialogService: DialogsService,
                private translateService: TranslateService,
                private storageService: StorageService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        // Check to see if a user logged in
        if (this.userService.isUserLoggedIn()) {
            const userPermission = this.storageService.getItem('CurrentUserPermission');

            if(userPermission !== undefined && userPermission != 'null') {
                let routeDroitID = route.data.id;
    
                if(routeDroitID != null && routeDroitID === '54_0' && route.params['page_slug'] === 'suivi_des_bons') {
                    let routeChildIds = route.data.childIds;
    
                    if(routeChildIds != null && isArray(routeChildIds)) {
                        if(routeChildIds.every((_el) => !userPermission[_el])) {
                            this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kgnoaccess'));
                            return false;    
                        }
                    }
                } else if(routeDroitID != null && !userPermission[routeDroitID]) {
                    this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kgnoaccess'));
                    return false;
                }
            }

            return true;
        }else{
          // If not, they redirect them to the login page
          this.router.navigate(['login']);
          return false;
        }
    }
}