import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'providers/constants';
import { MotifService } from 'providers/api/motifService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-motif',
  templateUrl: './add-motif.component.html',
  styleUrls: ['./add-motif.component.scss']
})
export class AddMotifComponent {

  motifActions: any;
  chosenAction: string;
  
  addMotifForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AddMotifComponent>,
              private formBuilder: UntypedFormBuilder,
              private motifService: MotifService,
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private translateService: TranslateService) {           
      
      this.motifActions = this.data.listMotifActions;
      this.chosenAction = this.data.chosen_action;
      
      this.addMotifForm = this.formBuilder.group({
        MOTINT: [this.chosenAction, Validators.required],
        IDSOC : [Constants.IDSOC],
        MOTLIB: ['', Validators.required],
        MOTFREE: [false]
      });
  }

  addMotifFormSubmission(): void {
    this.loadingService.show();

    if(this.addMotifForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('kmmand'));

      return;
    }

    const form_data = this.addMotifForm.value;
    form_data.MOTFREE = form_data.MOTFREE?'-1':'0';    

    this.motifService.addMotif(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmaddmsg'));
          this.dialogRef.close(form_data);
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kmerr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
      }
    )
  }

}
