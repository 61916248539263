import {Injectable} from '@angular/core';
import {UserService} from './userService';
import {Constants} from '../constants';

enum AUTHORIZATION_ID {
    '0_1', // AUTHORIZE_REMISE_EN_LIGNE
    '0_17', // AUTHORIZE_CHANGEMENT_DE_PRIX
    '0_2', // AUTHORIZE_FORCAGE_VENTE_OU_COMMANDE
    '0_23', // AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG
    '0_5', // AUTHORIZE_REMISE_EN_PIED
    '0_10', // AUTHORIZE_BLOCAGE_OU_DEBLOCAGE_STOCK
    '0_3', // AUTHORIZE_VALIDATION_BON_DE_COMMANDE
    '0_13', // AUTHORIZE_MODIFY_TYPE_COMMANDE
    '0_12', // AUTHORIZE_REMISE_EN_E2
    '0_14', // AUTHORIZE_SUPPRESSION_COMMANDE
    '0_15', // AUTHORIZE_REACTIVATION_COMMANDE
    '0_21', // AUTHORIZE_MODIFY_ARTICLES_BON_AUTRE_VENDEUR
    '0_16', // AUTHORIZE_FAIRE_UNE_RELANCE
}

export const [AUTHORIZE_REMISE_EN_LIGNE,
    AUTHORIZE_CHANGEMENT_DE_PRIX,
    AUTHORIZE_FORCAGE_VENTE_OU_COMMANDE,
    AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG,
    AUTHORIZE_REMISE_EN_PIED,
    AUTHORIZE_BLOCAGE_OU_DEBLOCAGE_STOCK,
    AUTHORIZE_VALIDATION_BON_DE_COMMANDE,
    AUTHORIZE_MODIFY_TYPE_COMMANDE,
    AUTHORIZE_REMISE_EN_E2,
    AUTHORIZE_SUPPRESSION_COMMANDE,
    AUTHORIZE_REACTIVATION_COMMANDE,
    AUTHORIZE_MODIFY_ARTICLES_BON_AUTRE_VENDEUR,
    AUTHORIZE_FAIRE_UNE_RELANCE
] = Object.keys(AUTHORIZATION_ID).map(key => (AUTHORIZATION_ID[key] ? AUTHORIZATION_ID[key] : null)).filter(val => val !== null);

interface HistoriqueProps {
    AUTHORIZED_RIGHT_ID: keyof typeof AUTHORIZATION_ID;
    NUMBON: any;
    ARTEAN: any;
    IDSOC?: any;
    CMINT: any;
    CIINT: any;
    VALUER1: any;
    VALUER2: any;

    MOTIF?: string;
    OPERAT?: string;
    TYPE_OPERAT?: string; // Forcage type (vente/commande)
}

interface AuthorizeDetail {
    MOTIF: string;
    OPERAT: string | { [key: string]: string };
}

@Injectable()
export class HistoryService {

    authorizationSuffix = ' - Authorization';
    authorizeDetail: { [key: string]: AuthorizeDetail };
    constructor(private userService: UserService) {
        this.authorizeDetail = {
            [AUTHORIZE_REMISE_EN_LIGNE]: {
                MOTIF: '',
                OPERAT: 'Remise en ligne'
            },
            [AUTHORIZE_CHANGEMENT_DE_PRIX]: {
                MOTIF: '',
                OPERAT: 'Changement de prix'
            },
            [AUTHORIZE_FORCAGE_VENTE_OU_COMMANDE]: {
                MOTIF: '',
                OPERAT: {
                    commande: 'Forçage Commande E2',
                    vente: 'Forçage Vente'
                }
            },
            [AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG]: {
                MOTIF: '',
                OPERAT: 'Forçage stock autre magasin'
            },
            [AUTHORIZE_REMISE_EN_PIED]: {
                MOTIF: '',
                OPERAT: 'Remise en pied'
            },
            [AUTHORIZE_BLOCAGE_OU_DEBLOCAGE_STOCK]: {
                MOTIF: '',
                OPERAT: {
                    block: 'Blocage stock',
                    deblock: 'Déblocage stock',
                    externe: 'Blocage externe',
                }
            },
            [AUTHORIZE_VALIDATION_BON_DE_COMMANDE]: {
                MOTIF: '', // Can be "Bon de vente" or "Devis"
                OPERAT: {
                    vente: 'Validation en bon de commande',
                    devis: 'Validation en bon de vente- Autorisation'
                }
            },
            [AUTHORIZE_MODIFY_TYPE_COMMANDE]: {
                MOTIF: '', // Passage de {FROM_TYPECDE} à {TO_TYPECDE}
                OPERAT: 'Chgt Type de commande'
            },
            [AUTHORIZE_REMISE_EN_E2]: {
                MOTIF: '',
                OPERAT: 'Remise en E2'
            },
            [AUTHORIZE_SUPPRESSION_COMMANDE]: {
                MOTIF: '',
                OPERAT: 'Suppression du bon de commande'
            },
            [AUTHORIZE_REACTIVATION_COMMANDE]: {
                MOTIF: '',
                OPERAT: 'Réactivation du bon de commande'
            },
            [AUTHORIZE_MODIFY_ARTICLES_BON_AUTRE_VENDEUR]: {
                MOTIF: '',
                OPERAT: 'Modification articles - bons autre vendeur'
            },
            [AUTHORIZE_FAIRE_UNE_RELANCE]: {
                MOTIF: '',
                OPERAT: {
                    relance: 'Envoi SMS Relance Client',
                    relancedevis: 'Envoi SMS Relance Devis',
                    retrait1h: 'Envoi SMS Retrait 1H',
                    dispo: 'Envoi SMS Marchandise Dispo',
                    dispo1: 'Envoi SMS Marchandise Dispo1',
                    dispo2: 'Envoi SMS Marchandise Dispo2',
                    dispo3: 'Envoi SMS Marchandise Dispo3',
                    dispo4: 'Envoi SMS Marchandise Dispo4',
                    dispo5: 'Envoi SMS Marchandise Dispo5',
                    dispo6: 'Envoi SMS Marchandise Dispo6',
                    dispo7: 'Envoi SMS Marchandise Dispo7',
                    dispo8: 'Envoi SMS Marchandise Dispo8',
                    dispo9: 'Envoi SMS Marchandise Dispo9',
                    dispo10: 'Envoi SMS Marchandise Dispo10',
                    confirmation: 'Envoi SMS Confirmation Commande',
                }
            },
        };
    }

    addAuthorizationHistory(props: HistoriqueProps, AUTHORIZATION: boolean = true) {
        let OPERAT = '', MOTIF = '';

        if (typeof this.authorizeDetail[props.AUTHORIZED_RIGHT_ID]?.OPERAT === 'object') {
            if (props?.TYPE_OPERAT) {
                OPERAT = this.authorizeDetail[props.AUTHORIZED_RIGHT_ID]?.OPERAT[props.TYPE_OPERAT];
            }
        } else {
            OPERAT = this.authorizeDetail[props.AUTHORIZED_RIGHT_ID]?.OPERAT as string;
        }

        if (props?.OPERAT) {
            OPERAT = props?.OPERAT || '';
        }

        if (AUTHORIZATION === true) {
            OPERAT += this.authorizationSuffix;
        }

        if (this.authorizeDetail[props.AUTHORIZED_RIGHT_ID]) {
            MOTIF = this.authorizeDetail[props.AUTHORIZED_RIGHT_ID].MOTIF;
        }

        if (props?.MOTIF) {
            MOTIF = props?.MOTIF;
        }

        const data = {
            NumBon : props.NUMBON,
            IDSOC : props.IDSOC || Constants.IDSOC,
            mag : props.CMINT,
            iduser : props.CIINT,
            ARTEAN : props.ARTEAN || '',
            MOTIF : MOTIF,
            OPERAT : OPERAT,
            VALEUR1 : props.VALUER1 || null,
            VALEUR2 : props.VALUER2 || null
        };

        return this.userService.addHistoryRow(data).toPromise();
    }

    addHistory(props: any) {
        const data = {
            ...props,
            IDSOC : Constants.IDSOC,
            VALEUR1 : props.VALUER1 || null,
            VALEUR2 : props.VALUER2 || null
        }

        return this.userService.addHistoryRow(data).toPromise();
    }
}
