import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

import { StorageService } from "./storageService";

@Injectable({ providedIn: "root" })
export class KeycloakOperationService {
  constructor(
    private readonly keycloak: KeycloakService,
    private storageService: StorageService
  ) {}

  loginWithGoogle() {
    this.keycloak.login({
      idpHint: "google",
    });
  }

  isLoggedIn() {
    return this.keycloak.isLoggedIn();
  }

  logout(): void {
    this.storageService.clear();
    this.keycloak.logout(window.location.origin);
  }

  getUserProfile(): any {
    return this.keycloak.loadUserProfile();
  }

  getUserRoles(): string[] {
    return this.keycloak.getUserRoles();
  }
}
