import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from 'providers/constants';
import { UtilisatuersService } from 'providers/api/utilisatuersService';
import { NiveauModel } from 'models/niveau-model';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  providers: [UtilisatuersService]
})
export class AddUserComponent implements OnInit {  

  user: UserModel;
  USERCMINT: any;

  addUserForm: UntypedFormGroup;
  niveauTypes: NiveauModel[];
  filteredNiveauTypes: NiveauModel[];
  filterNiveau: string = "";

  userPermission: any;
  listLang: any[];
  defaultLang: string;

  constructor(private router: Router,              
              public snackBar: MatSnackBar,
              private location: Location,
              private formBuilder: UntypedFormBuilder,                            
              private userService: UserService,
              private utilisatuersService: UtilisatuersService,
              private loadingService: NgxSpinnerService,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private storageService: StorageService) {
      this.addUserForm = this.formBuilder.group({
        CMINT: ['', Validators.required],
        CIACTIF: [true], // -1 (true) => Active and 0 (false) => In-Active
        IDSOC: [Constants.IDSOC, Validators.required],
        CIUSER: ['', Validators.required],
        CINOM: ['', Validators.required],
        CIPRENOM: [''],
        CIPSW: ['', [Validators.required, Validators.minLength(3)]],
        CTUINTGC: [''],
        CIEXTERNE: [''],
        USERLANG: ['']
      });
 }

  ngOnInit() {      
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.addUserForm.patchValue({ CMINT: this.USERCMINT });    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.getListNiveau();
    this._getListLanguages();
  }
  onSecurityChange(search: string) {
    this.filteredNiveauTypes = this.niveauTypes.filter(row => row.NIVNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  async _getListLanguages() {
    try {
      const paramData = await this.userService.getRuleParamsData(10, 1);
      this.defaultLang = paramData['VCHXMUL1'];
  
      if(this.defaultLang === '' || this.defaultLang == null) {
        const societyParamData = await this.userService.getRuleParamsData(10, 1, undefined, true);
        this.defaultLang = societyParamData['VCHXMUL1'];
      }
  
      this.addUserForm.patchValue({ USERLANG: this.defaultLang });
  
      this.listLang = [];
      if(paramData['VCHXMUL1LIS'] == null || paramData['VCHXMUL1LIS'] == '') {
        this.listLang = [this.defaultLang];
      } else {
        const separated = paramData['VCHXMUL1LIS'].split(' ');
        this.listLang = separated;
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  async getListNiveau() {
    this.utilisatuersService.getListofNiveau(this.USERCMINT, this.user.CIINT).subscribe(
      (res: any) => {
        if(res.success !== undefined) {
          this.niveauTypes = res.data;
          if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['73_1']) {
            this.niveauTypes = this.niveauTypes.filter((_d) => !(_d.NIVINT >= 1 && _d.NIVINT <= 9)); // Restrict Super Administrator level
          }
          this.filteredNiveauTypes = this.niveauTypes;
        }
      },
      err => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  addUserFormSubmitter() {
    this.loadingService.show();

    if(this.addUserForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
      
      return;
    }

    const form_data = this.addUserForm.value;
    form_data.CIPSW = form_data.CIPSW.toUpperCase();

    form_data.CIACTIF = form_data.CIACTIF?'-1':'0';
    
    form_data.CILANG=`${form_data.USERLANG}_${form_data.USERLANG.toUpperCase()}.php`;
    delete form_data.USERLANG;

    this.utilisatuersService.addNewUser(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {          
          this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kuadd'));

          this.addUserForm.reset();
          this.niveauTypes = [];          

          this.router.navigate(['utilisateurs']);    
        } else {          
          if(resp.status && resp.status === 'USEREXISTS') {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuseralreadyexistmag'));
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
          }
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );      
  } 

  returnBack() {
    this.location.back();
  }

}
