import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constants} from '../constants';

@Injectable()
export class LoginService {

    constructor(private http: HttpClient) {
        
    }

    getLogin(username: string, password: string, CMINT: any, keycloakLogin: boolean): Observable<any> {
        const data = {
            USERNAME: username,
            PASSWORD: password,
            MAGASIN: CMINT,
            keycloakLogin,
        };

        return this.http.post(Constants.API_LOGIN_URL + 'getLogin', data);
    }
    
    checkUserForRights(data: any) {
        return this.http.post(Constants.API_GESTION_DES_DROITS_URL + 'checkGestiondesdroits', data);
    }

    getLoginForUpdateArticle(username: string, password: string, ciint: string): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {
            USERNAME: username,
            PASSWORD: password,
            CIINT: ciint
        };

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_LOGIN_URL + 'getLoginForUpdateArticle', options);        
    }

    getMagasins(): Observable<any> {
        const that = this;

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');        

        const params = {
            IDSOC: Constants.IDSOC
        };
       
        const options = {
            headers: headers,
            params: params
        };
        const http = that.http;
        
        return that.http.get(Constants.API_LOGIN_URL + 'getMagasins', options);        
    }    

    resetPassword(data: any, type: 'reinit' | 'expiry'): Observable<any> {

        const headers = new HttpHeaders();
        
        const params = {};

        if(type === 'expiry') {
            params['action'] = 'userinfo';
        }

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.post(Constants.API_USER_URL + 'resetPassword', data, options);
    }

}