import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Pipe({
  name: 'defaultImage'
})
export class ImageDefaultPipe implements PipeTransform {

  constructor(private http: HttpClient) { }

  transform(url: string) {

    return new Observable<string>((observer) => {
      // This is a tiny blank image
      observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

      // The next and error callbacks from the observer
      const {next, error} = observer;

      this.http.get(url, {responseType: 'blob'}).subscribe(response => {
        observer.next(url);
      },
      err => {
          observer.next('assets/images/no_img.png');
          console.error(`Image not available for ${url} URL, so we're setting default image !`);
          return;
      });

      return {unsubscribe() {  }};
    });
  }
}