<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{'kbontitre' | translate}}
            </h1>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile">
            <div class="row">
                <button type="button" class="bt_valid w-100 pad10 mb-2" *ngIf="!detailBonVenteVisibility" [routerLink]="['/create-bon-de-vente']" mat-button >
                    <mat-icon class="addicon">add_circle</mat-icon>
                    {{ 'kmaddbtn' | translate }}
                </button>
            </div>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile && filterValues">
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>

    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium">{{'kclclearform' | translate}}</div>
                </button>
            </div>
        </div>
    </div>

    <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">    
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-12 ">

                    <div class="row">
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="NUMCOM"
                                    [placeholder]="'kbonumdebon' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="TRAISOC"
                                    [placeholder]="'kclnom' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="TPRENOM"
                                    [placeholder]="'kclfirstnom' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="TPORT"
                                    [placeholder]="'kcltel' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">                        
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="CODPOS"
                                    type="number"
                                    [placeholder]="'kboncodeopostal' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="TVILLE"
                                    [placeholder]="'kpcity' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>                   
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-checkbox formControlName="USERCON"> {{'kbonuser' | translate}}</mat-checkbox>
                        </div>     
                    </div>

                    <div class="row">
                        <div class="col-6 col-md-2 col-lg-2">                            
                            <mat-form-field>
                                <input matInput type="number" formControlName="MTTTCMIN"                                    
                                    class="text-uppercase">
                                <span matPrefix>{{'kbontotbetn' | translate}} &nbsp;</span>
                            </mat-form-field>                            
                        </div>                        
                        <div class="col-6 col-md-2 col-lg-2">                                                                                    
                            <mat-form-field>
                                <input matInput type="number" formControlName="MTTTCMAX"                                    
                                    class="text-uppercase">
                                    <span matPrefix>{{'kbonand' | translate}} &nbsp;</span>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button type="submit" class="bt_valid" mat-button>
                        <mat-icon>search</mat-icon>
                        {{ 'kpsearch' | translate }}
                    </button>
                    <button type="button" class="bt_valid ml-3" *ngIf="!detailBonVenteVisibility" [routerLink]="['/create-bon-de-vente']" mat-button>
                        <mat-icon>add_circle</mat-icon>
                        {{ 'kmaddbtn' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">

        <div class="row">
            <div class="col-12 no-padding table-scroller">
                <div class="">
                    <mat-paginator *ngIf="!showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

                <table *ngIf="!isMobile && bonVenteListColumns" datatable class="row-border hover dataTable no-footer table-bg-color" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead>
                        <tr>
                            <th *ngFor="let col of bonVenteListColumns" [width]="col.width">
                                {{ col.name | translate }}
                            </th>
                            <th width="7%">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listBonvente?.length != 0 && !showLoader">
                        <tr *ngFor="let bonvente of listBonvente" >
                            <td *ngFor="let col of bonVenteListColumns" (click)="editBonVente(bonvente)"
                                [attr.nowrap]="col.nowrap ? true : null"
                            >
                                <ng-container *ngIf="col.value === 'NUMCOM'">
                                    {{ bonvente.NUMCOM | slice:0:2 }} {{ bonvente.NUMCOM | slice:2:4 }} {{ bonvente.NUMCOM | slice:4:7 }} {{ bonvente.NUMCOM | slice:7:12 }} {{ bonvente.NUMCOM | slice:12:15 }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'ENDMOD'">
                                    {{ listDateFormatter(bonvente.ENDMOD) }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'CIPRENOM'">
                                    {{ bonvente.CIPRENOM | uppercase  }}{{ bonvente.CINOM?' '+bonvente.CINOM[0]+'.':'' }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'CIVLIBC'">
                                    {{ (bonvente.CIVLIBC != null)?bonvente.CIVLIBC+' ':'' }}{{ bonvente.TRAISOC | uppercase  }} {{ bonvente.TPRENOM | uppercase }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'CODPOS'">
                                    {{ bonvente.CODPOS }} {{ bonvente.TVILLE | uppercase }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'TPORT'">
                                    {{ bonvente.TPORT | formatTele }} <br> {{ bonvente.TTEL ? (bonvente.TPORT != '' && bonvente.TPORT != null) ? '/ ' + (bonvente.TTEL | formatTele) : '' : '' }} 
                                </ng-container>
                                <ng-container *ngIf="col.value === 'MTTTC'">
                                    {{ bonvente.MTTTC | cap_currency:null:false }}
                                </ng-container>

                                <ng-container *ngIf="col.value !== 'NUMCOM' && col.value !== 'ENDMOD' && col.value !== 'CIPRENOM' && col.value !== 'CIVLIBC' && col.value !== 'CODPOS' && col.value !== 'TPORT' && col.value !== 'MTTTC'">
                                    {{ bonvente[col.value] }}
                                </ng-container>
                            </td>
                            <td nowrap>
                                <button type="button" mat-mini-fab color="primary" class="ml-1 mr-1" (click)="editBonVente(bonvente)">
                                    <mat-icon>{{ (detailBonVenteVisibility || !userAllowedToModifyOrRemove(bonvente))?'visibility':'edit' }}</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="warn" (click)="deleteConfirmation(bonvente)" *ngIf="!detailBonVenteVisibility && userAllowedToModifyOrRemove(bonvente)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="listBonvente?.length == 0 || showLoader">
                        <tr>
                            <td colspan="10" *ngIf="listBonvente?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                            <td colspan="10" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>
                <mat-list *ngIf="isMobile && (listBonvente?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="listBonvente?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">                        
                        <p matLine class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                    </mat-list-item>
                </mat-list>
                
                <mat-list *ngIf="isMobile && (listBonvente?.length != 0 && !showLoader)">
                    <mat-list-item  *ngFor="let bonvente of listBonvente;let i=index;" [id]="'swipeItem-' + i" style="user-select: none;" (swipe)="onSwipe($event, i)">
                        <div class="swipedContent">
                            <button type="button" mat-mini-fab color="warn" class="" (click)="deleteConfirmation(bonvente)" *ngIf="!detailBonVenteVisibility && userAllowedToModifyOrRemove(bonvente)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div class="w-100 list-item-main-content" (click)="editBonVente(bonvente)">
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <div class="m-0 d-flex flex-row align-content-center justify-content-start">
                                    <p class="m-0">
                                    {{ bonvente.NUMCOM | slice:0:2 }} {{ bonvente.NUMCOM | slice:2:4 }} {{ bonvente.NUMCOM | slice:4:7 }} {{ bonvente.NUMCOM | slice:7:12 }} {{ bonvente.NUMCOM | slice:12:15 }}
                                    </p>
                                    <span class="different-store ml-2" *ngIf="isMagStockAvail(bonvente)">
                                        <mat-icon>store</mat-icon>
                                    </span>
                                </div>
                                <p class="m-0">{{ listDateFormatter(bonvente.ENDMOD) }}</p>
                            </div>
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <div matLine class="mobile-list-title">{{ bonvente.TRAISOC | uppercase  }} {{ bonvente.TPRENOM | uppercase }}</div>
                                <p class="m-0">{{ bonvente.CIPRENOM | uppercase  }}{{ bonvente.CINOM?' '+bonvente.CINOM[0]+'.':'' }}</p>
                            </div>
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <p matLine class="mobile-list-title">
                                    {{ bonvente.CODPOS }} {{ bonvente.TVILLE?bonvente.TVILLE+(bonvente.TPORT?' , ':''):'' | uppercase }}<br>
                                    {{ bonvente.TPORT | formatTele }} {{ bonvente.TTEL?(bonvente.TPORT !== '' && bonvente.TPORT != null?' / ':'')+(bonvente.TTEL | formatTele):'' }}
                                </p>                            
                                <p matLine class="mobile-list-title text-right"> 
                                    <span class="badge badge-secondary">{{ bonvente.MTTTC | cap_currency:null:false }}</span><br>
                                    <span>{{ bonvente.TYPECDE }}</span>
                                </p>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>         
            </div>
        </div>
    </div>
</div>
