import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
// horaires date picker en français
import {MAT_DATE_FORMATS} from '@angular/material/core';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import {Client} from '../../models/clients';
import {ClientService} from '../../providers/api/clientService';

import {Civilite} from '../../models/civilites';
import {CiviliteService} from '../../providers/api/civiliteService';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PostalCodeModel } from 'models/postal-code';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchPostalCodeComponent } from './search-postal-code/search-postal-code.component';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import BonPanier from 'models/bonPanier';
import { BonVenteService } from 'providers/api/bonventeService';
import { BonVente } from 'models/bonventes';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchCountryField, CountryISO, PhoneNumberFormat, NgxIntlTelInputComponent } from 'ngx-intl-tel-input-gg';
import { PhoneNumberValidator } from 'providers/phoneNumberValidator';
import { Country } from 'ngx-intl-tel-input-gg/lib/model/country.model';
import { PhoneNumberUtil, PhoneNumberFormat as PNF_PhoneNumberFormat } from 'google-libphonenumber';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import {BonCommandeService} from '../../providers/api/bonCommandeService';
import {PdfViewerComponent} from '../pdf-viewer/pdf-viewer.component';
import AddressVerification, {VerificationDetail} from '../../models/addressVerification';
import {MotifForcePrixComponent} from '../create-bon-de-vente/motif-force-prix/motif-force-prix.component';
import {AddressConfirmationComponent} from './address-confirmation/address-confirmation.component';
import {HttpErrorResponse} from '@angular/common/http';
import {ParamDetail} from '../../models/paramDetail';
import { ClientHistoriqueComponent } from 'components/client-historique/client-historique.component';
import { StorageService } from 'providers/api/storageService';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';

const phoneNumberUtil = PhoneNumberUtil.getInstance();
const PNF = PNF_PhoneNumberFormat;


@Component({
    selector: 'app-client-detail',
    templateUrl: './client-detail.component.html',
    styleUrls: ['./client-detail.component.scss'],
    providers: [BonVenteService, {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}]
})
export class ClientDetailComponent implements OnInit {

    @Input() displayType: 'page' | 'tab' = 'page';
    @Input() flowType: 'bonde_vente' | 'devis' | 'bonde_commande' = 'bonde_vente';
    @Input() selectedClient: Client;

    private _emailMandatory = false;
    get emailMandatory(): boolean { return this._emailMandatory }
    @Input() set emailMandatory(emailMandatory: boolean) {
        this._emailMandatory = emailMandatory;

        if (emailMandatory !== undefined && emailMandatory !== null) {
            this.setEmailValidation(emailMandatory);
        }
    }

    private _panier: BonPanier;
    get panier(): BonPanier { return this._panier }
    @Input() set panier(panier: BonPanier) {
      this.panierChange.emit(this._panier = panier);

      if(panier !== undefined && panier !== null) {
      } else {
      }    
    }
    @Output() private panierChange: EventEmitter<BonPanier> = new EventEmitter<BonPanier>();

    @Output() private onChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() private bonNumBon: string;
    @Input() private bonClientType: 'clientlivre' | 'client' | 'clientfacture' = 'client';

    client: Client
    civilite: Civilite[];
    editForm: UntypedFormGroup = this.formBuilder.group({
        IDSOC: [Constants.IDSOC, Validators.required],
        IDINST: ['', Validators.required],
        CMINT: [''],
        TLANG: [''],
        CODPAYS: [''],
        IDCIV: [''],
        TPRENOM: [''],
        TRAISOC: ['', [Validators.required]],
        TADR1: [''],
        TADR2: [''],
        TADR3: ['', Validators.required],
        TADR4: [''],
        TDTNAIS: [{value: '', disabled: true}, Validators.required],
        TFAX: [''],
        CODPOS: ['', [Validators.required]],
        TVILLE: ['', [Validators.required]],
        TTEL: [undefined],
        TPORT: [undefined],
        TEMAIL: ['', [Validators.pattern('^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$')]],
        IDTIERS: [{value: '', disabled: true}, Validators.required],
        TOKSMS: [0],
        TOKAPP1: [0],
        TOKMAIL: [0]
    });
    today = new Date();

    user: any;
    USERCMINT: any;
    isMobile: boolean;

    listPays: any;
    loyalCustomer: boolean = false;

    listPostalCodes: PostalCodeModel[] = [];
    filteredPostalCodes: Observable<PostalCodeModel[] | any>;

    listVilles: PostalCodeModel[] = [];
    filteredVilles: Observable<PostalCodeModel[] | any>;

    filteredPays: any;
    filterPays: string = "";

    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.France];
    selectedTeleCountry: any;
    selectedPortCountry: any;

    teleCountry: string;
    portCountry: string;

    paysParamData: string;

    listLang: any[];
    defaultLang: string;

    swissParamDetail: ParamDetail | undefined;
    clientRegexPattern: string | undefined;

    checkEmailParam: number = 0;
    emailWarningOnly: boolean = false;
    
    userPermission: any;

    constructor(private router: Router,                
                public snackBar: MatSnackBar,
                private location: Location,                
                private clientService: ClientService,
                private bonVenteService: BonVenteService,
                private bonCommandeService: BonCommandeService,
                private civiliteService: CiviliteService,
                private formBuilder: UntypedFormBuilder,
                private userService: UserService,
                private loadingService: NgxSpinnerService,
                private dialogService: DialogsService,
                private translateService: TranslateService,
                private deviceDetector: DeviceDetectorService,
                private dialog: MatDialog,
                private storageService: StorageService) {
   }

    returnBack() {
        this.location.back();
    }

    ngOnInit() {
      this.isMobile = this.deviceDetector.isMobile();

      this.userPermission = this.storageService.getItem('CurrentUserPermission');

        this.userService.changeLanguage();
        this.client = this.storageService.getItem('client');

        if(this.displayType === 'tab') {
          this.client = this.selectedClient;
        }

        this.user = this.userService.getCurrentUser();
        this.USERCMINT = this.user.CMINT;

        this.editForm.patchValue({
            IDSOC: Constants.IDSOC,
            IDINST: (this.client?.IDINSTTIE == undefined?this.client.IDINST:this.client.IDINSTTIE),
            CMINT: this.client.CMINT,
            TLANG: this.client.TLANG || '',
            CODPAYS: this.client.CODPAYS,
            IDCIV: this.client.IDCIV,
            TPRENOM: this.client.TPRENOM,
            TRAISOC: this.client.TRAISOC,
            TADR1: this.client.TADR1,
            TADR2: this.client.TADR2,
            TADR3: this.client.TADR3,
            TADR4: this.client.TADR4,
            TDTNAIS: ((this.client.TDTNAIS !== null && this.client.TDTNAIS !== '0000-00-00') ? new Date(this.client.TDTNAIS) : ''),
            TFAX: this.client.TFAX,
            CODPOS: this.client.CODPOS,
            TVILLE: this.client.TVILLE,
            TTEL: undefined,
            TPORT: undefined,
            TEMAIL: this.client.TEMAIL,
            IDTIERS: this.client.IDTIERS,
            TOKSMS: this.client.TOKSMS == "-1" ? true : false,
            TOKMAIL: this.client.TOKMAIL == "-1" ? true : false,
            TOKAPP1: this.client.TOKAPP1 == "-1" ? true : false,
        });

        this.selectedTeleCountry = this.client.CODPAYS.toLowerCase();
        this.selectedPortCountry = this.client.CODPAYS.toLowerCase();

        if(this.client.CODPAYS !== '' && this.client.CODPAYS !== null && this.client.CODPAYS !== undefined) {
          this.selectedTeleCountry = this.client.CODPAYS.toLowerCase();
          this.selectedPortCountry = this.client.CODPAYS.toLowerCase();
          this.teleCountry = this.client.CODPAYS.toUpperCase();
          this.portCountry = this.client.CODPAYS.toUpperCase();

          if(this.client.TTEL !== undefined && this.client.TTEL !== null && this.client.TTEL !== '') {
            let parsedTele: any = this.client.TTEL;
            if(phoneNumberUtil.isPossibleNumberString(this.client.TTEL, '+')) {
              const teleCountryCode = phoneNumberUtil.getRegionCodeForNumber(phoneNumberUtil.parse(this.client.TTEL));
              parsedTele = phoneNumberUtil.parse(this.client.TTEL).getNationalNumber();

              this.selectedTeleCountry = teleCountryCode.toLowerCase();
              this.teleCountry = teleCountryCode.toUpperCase();
            }

            this.editForm.patchValue({
              TTEL: parsedTele
            });
          }

          if(this.client.TPORT !== undefined && this.client.TPORT !== null && this.client.TPORT !== '') {
            let parsedPort: any = this.client.TPORT;
            if(phoneNumberUtil.isPossibleNumberString(this.client.TPORT, '+')) {
              const portCountryCode = phoneNumberUtil.getRegionCodeForNumber(phoneNumberUtil.parse(this.client.TPORT));
              parsedPort = phoneNumberUtil.parse(this.client.TPORT).getNationalNumber();

              this.selectedPortCountry = portCountryCode.toLowerCase();
              this.portCountry = portCountryCode.toUpperCase();
            }

            this.editForm.patchValue({
              TPORT: parsedPort
            });
          }
        }

        this.loadDependencies();
        this.setTelOrPORTValidation();
        this.setEmailValidation();

        this.filteredPostalCodes = this.editForm.get('CODPOS').valueChanges
        .pipe(          
          distinctUntilChanged(),
          switchMap(val => {
            if(val !== '' && val != null) {
              return this._code_filter(val || '')
            }
            return of([]);
          })       
        );

        this.filteredVilles = this.editForm.get('TVILLE').valueChanges
        .pipe(          
          distinctUntilChanged(),
          switchMap(val => {
            if(val !== '' && val != null) {
              return this._ville_filter(val || '')
            }
            return of([]);
          })       
        );
    }

    changePreferredCountries() {
      this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    }

    onCodePostalChanged() {
      const val = this.editForm.get('CODPOS').value;
      if(val !== '' && val != null) {
        this.filteredPostalCodes = this._code_filter(val || '');
      }
    }

    onVilleChanged() {
      const val = this.editForm.get('TVILLE').value;
      if(val !== '' && val != null) {
        this.filteredVilles = this._ville_filter(val || '');
      }
    }

    onCodePostalFocus() {      
      const val = this.editForm.get('TVILLE').value;
      if(val !== '' && val != null) {
        this.filteredPostalCodes = this._ville_filter(val || '');
      }
    }

    onVilleFocus() {
      const val = this.editForm.get('CODPOS').value;
      if(val !== '' && val != null) {
        this.filteredVilles = this._code_filter(val || '');
      }
    }

    setTelOrPORTValidation() {
      const telControl = this.editForm.get('TTEL');
      const portControl = this.editForm.get('TPORT');
  
      if((telControl.value === undefined || telControl.value == '' || telControl.value == null) && (portControl.value === undefined || portControl.value == '' || portControl.value == null)) {
        telControl.setValidators([Validators.required, PhoneNumberValidator(this.teleCountry, 'tele')]);
        portControl.setValidators([Validators.required, PhoneNumberValidator(this.portCountry, 'port')]);
      } else {
        telControl.setValidators(null);
        portControl.setValidators(null);
        telControl.setValidators([PhoneNumberValidator(this.teleCountry, 'tele')]);
        portControl.setValidators([PhoneNumberValidator(this.portCountry, 'port')]);
      }

      telControl.updateValueAndValidity();
      portControl.updateValueAndValidity();
    }

    setEmailValidation(mandatory?: boolean) {
        const emailControl = this.editForm.get('TEMAIL');

        if (mandatory === undefined || mandatory === null) {
           mandatory = this.emailMandatory;
        }

        if (mandatory === true) {
            emailControl.setValidators([Validators.required, Validators.pattern('^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$')]);
        } else {
            emailControl.setValidators(null);
            emailControl.setValidators([Validators.pattern('^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$')]);
        }

        emailControl.updateValueAndValidity();
    }

    get emailInputField(): UntypedFormControl {
        return this.editForm.get('TEMAIL') as UntypedFormControl;
    }

    get teleInputField(): UntypedFormControl {
      return this.editForm.get('TTEL') as UntypedFormControl;
    }

    get portInputField(): UntypedFormControl {
      return this.editForm.get('TPORT') as UntypedFormControl;
    }

    get codposInputField(): UntypedFormControl {
      return this.editForm.get('CODPOS') as UntypedFormControl;
    }

    get villeInputField(): UntypedFormControl {
      return this.editForm.get('TVILLE') as UntypedFormControl;
    }

    replaceXplaceholder(placeholder: string): string {
      return placeholder.replace(/(\d)/g, Constants.TEL_PLACEHOLDER_TEXT);
    }

    teleIntlCountryChange(event: Country) {
      this.teleCountry = event.iso2.toUpperCase();
    }

    portIntlCountryChange(event: Country) {
      this.portCountry = event.iso2.toUpperCase();
    }

    onCountryChange(search: string) {
      this.filteredPays = this.listPays.filter(row => row.PAYSNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }

    openUpAddressConfirmation(data: { entered: any, returned: VerificationDetail }): Promise<any> {
        const dialogRef = this.dialog.open(AddressConfirmationComponent,
            { data }
        );

        return dialogRef.afterClosed().toPromise();
    }

    async validateClientAddress({ swissUrl, swissUsername, swissPassword }): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const form_data = this.editForm.getRawValue();
            try {
                const [houseNo] = form_data.TADR3.match(/^[0-9]+|[0-9]+$/gi) || [''];
                const [streetName] = form_data.TADR3.match(/[a-zA-Z\s\.]+/gi) || [''];
                const postalCode = form_data.CODPOS;
                const townName = form_data.TVILLE;

                const verification: AddressVerification = await this.clientService.checkAddressVerification({
                    swissUrl,
                    swissUsername,
                    swissPassword,
                    streetName: streetName ? streetName.trim() : '',
                    houseNo: houseNo ? (houseNo as number) : '',
                    zipCode: postalCode || '',
                    townName: townName || '',
                }).toPromise();

                if (verification.hasOwnProperty('QueryBuildingVerification4Result') &&
                    verification.QueryBuildingVerification4Result.Status === 0) {
                    const verificationData = verification.QueryBuildingVerification4Result.BuildingVerificationData;
                    if (+verificationData.PSTAT === 1) {
                        // Nothing is displayed, entered address is saved
                        this.editForm.patchValue({
                            TADR3: `${verificationData.StreetName} ${verificationData.HouseNo}`,
                            CODPOS: verificationData.ZipCode,
                            TVILLE: verificationData.TownName,
                        });

                        resolve('CONTINUE');
                        return;
                    } else if (+verificationData.PSTAT === 2) {
                        // Nothing is displayed, returned address is saved
                        this.editForm.patchValue({
                            TADR3: `${verificationData.StreetName} ${verificationData.HouseNo}`,
                            CODPOS: verificationData.ZipCode,
                            TVILLE: verificationData.TownName,
                        });

                        resolve('CONTINUE');
                        return;
                    } else if (+verificationData.PSTAT >= 3 && +verificationData.PSTAT <= 5) {
                        // Show to popin to suggest the returned address
                        // You can confirm the returned address or force the entered addreee
                        this.loadingService.hide();
                        const confirmationResult = await this.openUpAddressConfirmation({ entered: form_data, returned: verificationData });

                        if (!confirmationResult) {
                            reject('EXIT');
                            return;
                        }

                        if (confirmationResult === 'returned') {
                            this.editForm.patchValue({
                                TADR3: `${verificationData.StreetName} ${verificationData.HouseNo}`,
                                CODPOS: verificationData.ZipCode,
                                TVILLE: verificationData.TownName,
                            });
                        }

                        this.loadingService.show();
                        resolve('CONTINUE');
                        return;
                    } else if (+verificationData.PSTAT >= 6 && +verificationData.PSTAT <= 10) {
                        // Show popin without any suggestion
                        // You can modify the entered address or force it
                        this.loadingService.hide();
                        const confirmationResult = await this.openUpAddressConfirmation({ entered: form_data, returned: verificationData });

                        if (!confirmationResult || confirmationResult === 'modifier') {
                            reject('EXIT');
                            return;
                        }

                        this.loadingService.show();
                        resolve('CONTINUE');
                        return;
                    } else {
                        this.loadingService.hide();
                        reject('VERIFICATION_FAILED');
                        return;
                    }
                } else {
                    // Error response
                    this.loadingService.hide();
                    this.dialogService.prompt(this.translateService.instant('kverification_failed'), this.translateService.instant('kproblem_verify_address'));
                    reject('VERIFICATION_FAILED');
                    return;
                }
            } catch (e: any) {
                this.loadingService.hide();
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kproblem_entered_address'));
                reject('CONNECTION_FAILURE');
                return;
            }
        });
    }

    onEmailChange(event: any) {
      this.emailWarningOnly = false;
    }

    showEmailExistsPopup(message: string = 'kuerror') {
      this.dialogService.prompt(this.translateService.instant(message), this.translateService.instant('kemail_exists'));
      this.editForm.get('TEMAIL').setErrors({ customError: true });
      this.loadingService.hide();
    }
  
    async checkEmailExists(email: string = ''): Promise<void> {
      return new Promise((resolve, reject) => {
        if (email !== undefined && email !== null && email !== '') {
          this.clientService.searchClients({TEMAIL: email}).subscribe((response: any) => {
            if(response.success !== undefined) {
              const data: Client[] = response.data;
              const totalResults: number = response.count;
              if(totalResults > 0 && data[0].TEMAIL !== this.client.TEMAIL && !this.emailWarningOnly) {
                if (this.checkEmailParam == 1) {
                  this.showEmailExistsPopup('kwarning');
                  this.emailWarningOnly = true;
                  reject();
                }
                if (this.checkEmailParam == 2) {
                  this.showEmailExistsPopup();
                  this.emailWarningOnly = false;
                  reject();
                }
              }
              resolve();
            }
          }, 
          err => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            reject(); 
          });
        } else {
          resolve();
        }
      });
    }

    async editFormSubmitter() {
        this.loadingService.show();
        if(this.editForm.invalid) {
          this.loadingService.hide();

          if(this.editForm.get('TEMAIL').errors?.pattern
              || (this.editForm.get('TTEL').errors?.validatePhoneNumber && this.editForm.get('TPORT').errors?.validatePhoneNumber) 
              || (this.editForm.get('TTEL').errors?.validatePhoneNumber && this.editForm.get('TPORT').value == null) 
              || (this.editForm.get('TPORT').errors?.validatePhoneNumber && this.editForm.get('TTEL').value == null)) {
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclformatinvalid'));
            return;
          }

          let otherFieldsError = false;
          Object.keys(this.editForm.controls).forEach(key => {
            // Get errors of every form control
            if((key !== 'TTEL' && key !== 'TPORT' && key !== 'TEMAIL') && this.editForm.get(key).errors) {
              otherFieldsError = true;
            }
          });

          if(this.editForm.get('TTEL').errors?.required || this.editForm.get('TPORT').errors?.required || this.editForm.get('TEMAIL').errors?.required || otherFieldsError) {
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
            return;
          }
        }

        if(this.editForm.get('TTEL').errors?.validatePhoneNumber || this.editForm.get('TPORT').errors?.validatePhoneNumber) {
          this.loadingService.hide();
          await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclformatinvalid')).toPromise();
          this.loadingService.show();
        }
    
        let form_data = this.editForm.getRawValue();

        if (this.checkEmailParam != 0) {
          try {
            await this.checkEmailExists(form_data.TEMAIL);
          } catch (e) {
            return;
          }
        }

        // Check the address valid or invalid
        // Use SwissPost
        if(this.swissParamDetail && (
            (this.swissParamDetail.VSTR1 !== undefined && this.swissParamDetail.VSTR1 !== null && this.swissParamDetail.VSTR1 !== "") &&
            (this.swissParamDetail.VSTR2 !== undefined && this.swissParamDetail.VSTR2 !== null && this.swissParamDetail.VSTR2 !== "") &&
            (this.swissParamDetail.VSTR3 !== undefined && this.swissParamDetail.VSTR3 !== null && this.swissParamDetail.VSTR3 !== "")
        )) {
            const swissUrl = this.swissParamDetail.VSTR1;
            const swissUsername = this.swissParamDetail.VSTR2;
            const swissPassword = this.swissParamDetail.VSTR3;

            try {
                await this.validateClientAddress({ swissUrl, swissUsername, swissPassword });
                form_data = this.editForm.getRawValue();
            } catch (e) {
                return;
            }
        }

        if(form_data.TDTNAIS !== undefined && form_data.TDTNAIS !== null  && form_data.TDTNAIS !== '') {
            form_data.TDTNAIS = moment(form_data.TDTNAIS).format('YYYY-MM-DD');
        }

        if(form_data.TTEL != null) {
            if(!this.editForm.get('TTEL').errors?.validatePhoneNumber) {
                const teleCountryCode = phoneNumberUtil.parse(form_data.TTEL?.number?.toString(), this.teleCountry);
                form_data.TTEL = phoneNumberUtil.format(teleCountryCode, PNF.E164);
            } else {
                form_data.TTEL = form_data.TTEL?.number?.toString();
            }
        }

        if(form_data.TPORT != null) {
            if(!this.editForm.get('TPORT').errors?.validatePhoneNumber) {
                const portCountryCode = phoneNumberUtil.parse(form_data.TPORT?.number?.toString(), this.portCountry);
                form_data.TPORT = phoneNumberUtil.format(portCountryCode, PNF.E164);
            } else {
                form_data.TPORT = form_data.TPORT?.number?.toString();
            }
        }

        // Checkbox --> -1 (checked) 0 (unchecked)
        form_data.TOKSMS = form_data.TOKSMS ? -1 : 0;
        form_data.TOKAPP1 = form_data.TOKAPP1 ? -1 : 0;
        form_data.TOKMAIL = form_data.TOKMAIL ? -1 : 0;

        // CAP-1287: Enter user details who create/modify a client
        form_data.IDINSTINT = this.user.IDINSTINT;
        form_data.CIINT = this.user.CIINT;

        if(this.displayType === 'tab' && (this.panier == undefined || this.panier == null)) {
          this.updateOnlyClient(form_data);
          return;
        }

        if(this.displayType === 'tab') {          
          const updatableClientData = {
            mag: +form_data.CMINT,
            civil: +form_data.IDCIV,
            nom: form_data.TRAISOC,
            prenom: form_data.TPRENOM,
            adr1: form_data.TADR1,
            adr2: form_data.TADR2,
            adr3: form_data.TADR3,
            adr4: form_data.TADR4,
            codpos: form_data.CODPOS,
            ville: form_data.TVILLE,
            pays: form_data.CODPAYS,
            tel: form_data.TTEL,
            fax: form_data.TFAX,
            gsm: form_data.TPORT,
            email: form_data.TEMAIL,
            idinstcli: +form_data.IDINST,
            idcli: +form_data.IDTIERS,
            toksms: form_data.TOKSMS,
            tokapp1: form_data.TOKAPP1,
            tokmail: form_data.TOKMAIL,
          };

          if(this.bonClientType === 'client') {
            this.panier.client = updatableClientData;            
          } else if(this.bonClientType === 'clientfacture') {
            this.panier.clientfacture = updatableClientData;
            if(this.panier.clientfacture != null && this.panier.clientfacture.idcli == this.panier.client.idcli){
              this.panier.client = updatableClientData;
            }
          } else if(this.bonClientType == 'clientlivre') {
              if(this.panier.clientlivre != null) {
                  this.panier.clientlivre = updatableClientData;
                  if(this.panier.clientlivre.idcli == this.panier.client.idcli){
                      this.panier.client = updatableClientData;
                  }
              } else {
                  this.panier.client = updatableClientData;
              }
          } else {
            this.panier.client = updatableClientData;
          }

          const combined_data = {
            panier: this.panier,
            update_client: form_data,
            edit_info: {
              pMag: this.panier.mag,          
              pNumBon: this.bonNumBon
            },
            IDSOC: Constants.IDSOC,
            Id_User: this.user.CIINT,
            Id_InstUser: Constants.CIINST,
            type: this.flowType
          };

          if(this.flowType === 'bonde_commande') {
              const payload = {
                  CMINT: this.panier.mag,
                  NUMBON: this.bonNumBon,
                  CLIENT_DATA: form_data,
                  IDSOC: Constants.IDSOC,
                  pPanier: this.panier,
                  client_type: this.bonClientType,
                  Id_User: this.user.CIINT,
                  Id_InstUser: Constants.CIINST,
              };

              this.updateClientandBonCommande(payload);
              return;
          }

          this.updateClientandBonVente(combined_data);
          return;
        }

        this.updateOnlyClient(form_data);         
    }
    
    updateOnlyClient(form_data: any) {
      this.clientService.updateClient(form_data).subscribe(
        (resp) => {
          this.loadingService.hide();
          if(resp.statusCode == 200) {
            if(this.displayType === 'tab') {
              this.editForm.reset();
              this.onChange.emit({ status: 'success_edit_client', data: form_data });
            } else {
              const list_respose_data = this.storageService.getItem('clientListData');
              let list_data: Client[];
  
              if(list_respose_data !== undefined && list_respose_data !== null) {
                list_data = list_respose_data.data;
              }              
  
              if(list_data !== undefined && list_data !== null) {
                  list_data.map(
                    (item: Client) => {
                      if(item.IDTIERS == form_data.IDTIERS) {
                        item.CODPAYS = form_data.CODPAYS;

                        if(form_data.IDCIV !== undefined && form_data.IDCIV !== null && form_data.IDCIV !== "") {
                            item.IDCIV = form_data.IDCIV;
                            item.CIVLIB = (this.civilite.find((row: Civilite) => row.IDCIV == form_data.IDCIV)).CIVLIB;
                        } else {
                            item.IDCIV = '';
                            item.CIVLIB = '';
                        }

                        item.TPRENOM = form_data.TPRENOM;
                        item.TRAISOC = form_data.TRAISOC;
                        item.TADR1 = form_data.TADR1;
                        item.TADR2 = form_data.TADR2;
                        item.TADR3 = form_data.TADR3;
                        item.TADR4 = form_data.TADR4;
                        item.TLANG = form_data.TLANG;
                        item.TDTNAIS = form_data.TDTNAIS;
                        item.TFAX = form_data.TFAX;
                        item.CODPOS = form_data.CODPOS;
                        item.TVILLE = form_data.TVILLE;
                        item.TTEL = form_data.TTEL;
                        item.TPORT = form_data.TPORT;
                        item.TEMAIL = form_data.TEMAIL;
  
                        return item;
                      }
                      return item;
                    }
                  );
  
                  list_respose_data.data = list_data;
  
                  this.storageService.setItem('clientListData', list_respose_data);
              }
  
              this.storageService.setItem('clientEditFormData', form_data);
              this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kclcltupdate'));

              this.editForm.reset();
              this.router.navigate(['client']);
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuupdateerr'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    }

    updateClientandBonVente(form_data: any) {
      this.bonVenteService.updateClientFromBonDeVente(form_data).subscribe(
        (resp) => {
          this.loadingService.hide();
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.editForm.reset();
              // List update after a success response
              const oldListData = this.storageService.getItem('bonVenteListData');
              const oldNumBon = this.bonNumBon;
              // ------------------------------
  
              this.bonNumBon = resp.data.NumBon;
              this.panier = resp.data.Panier;
  
              this.storageService.setItem('previousBonPanier', this.panier);
              this.storageService.setItem('previousNumBon', resp.data.NumBon);
  
              // List update after a success response
              if(oldListData !== undefined && oldListData != null) {
  
                oldListData.data.map(
                  (row: BonVente) => {
                    if(row === undefined || row == null) return false;
  
                    if(row.NUMCOM == oldNumBon) {
                      row.TRAISOC = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                      row.TPRENOM = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                      row.TADR3 = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TADR3:null;
                      row.CODPOS = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                      row.TVILLE = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                      row.TTEL = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                      row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                      row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                      row.ENDMOD = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('YYYY-MM-DD HH:mm:ss');
                      
                      row.MTHT = this.panier.montantHT;                    
                      row.MTTTC = this.panier.montant;
                      row.MTTVA = (this.panier.montant - this.panier.montantHT);
                      
                      row.CLINUM = (this.panier.client !== undefined && this.panier.client !== null)?this.panier.client.idcli.toString():"0";
                      row.CLILIVNUM = (this.panier.clientlivre !== undefined && this.panier.clientlivre !== null)?this.panier.clientlivre.idcli.toString():"0";
                      row.CLIFACNUM = (this.panier.clientfacture !== undefined && this.panier.clientfacture !== null)?this.panier.clientfacture.idcli.toString():"0";                    
  
                      row.NUMCOM = this.bonNumBon;
  
                      return row;
                    }
  
                    return row;
                  }
                );
  
                const updated_bonvente = oldListData.data.find(
                  (row: BonVente) => {
                    if(row === undefined || row == null) return false;
  
                    if(row.NUMCOM == this.bonNumBon) return row;
                  }
                );
  
                let updated_row_removed: BonVente[] = oldListData.data.filter(
                  (row: BonVente) => {
                    if(row === undefined || row == null) return false;
  
                    row.NUMCOM != this.bonNumBon
                  }
                );
                if(updated_bonvente !== undefined && updated_bonvente !== null) {
                  updated_row_removed.unshift(updated_bonvente);
                }
  
                oldListData.data = updated_row_removed;
  
                this.storageService.setItem('bonVenteListData', oldListData);
              }
              // ------------------------------
  

              const list_respose_data = this.storageService.getItem('clientListData');
              let list_data: Client[];
  
              if(list_respose_data !== undefined && list_respose_data !== null) {
                list_data = list_respose_data.data;
              }              
  
              if(list_data !== undefined && list_data !== null) {
                  list_data.map(
                    (item: Client) => {
                      if(item.IDTIERS == form_data.IDTIERS) {
                        item.CODPAYS = form_data.CODPAYS;
                        item.IDCIV = form_data.IDCIV;
                        item.CIVLIB = (this.civilite.find((row: Civilite) => row.IDCIV == form_data.IDCIV)).CIVLIB;
                        item.TPRENOM = form_data.TPRENOM;
                        item.TRAISOC = form_data.TRAISOC;
                        item.TADR1 = form_data.TADR1;
                        item.TADR2 = form_data.TADR2;
                        item.TADR3 = form_data.TADR3;
                        item.TADR4 = form_data.TADR4;
                        item.TLANG = form_data.TLANG;
                        item.TDTNAIS = form_data.TDTNAIS;
                        item.TFAX = form_data.TFAX;
                        item.CODPOS = form_data.CODPOS;
                        item.TVILLE = form_data.TVILLE;
                        item.TTEL = form_data.TTEL;
                        item.TPORT = form_data.TPORT;
                        item.TEMAIL = form_data.TEMAIL;
  
                        return item;
                      }
                      return item;
                    }
                  );
  
                  list_respose_data.data = list_data;
  
                  this.storageService.setItem('clientListData', list_respose_data);
              }

              this.onChange.emit({ status: 'success_edit_bon_client', response: resp });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuupdateerr'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    }

    updateClientandBonCommande(form_data: any) {
        this.bonCommandeService.modifyClientFromBonDeCommande(form_data).subscribe(
            (resp) => {
                this.loadingService.hide();
                if(resp.statusCode == 200) {
                    if(resp.data.ErrorCode == 0) {
                        this.editForm.reset();
                        // List update after a success response
                        const oldListData = this.storageService.getItem('bonCommandeListData');
                        const oldNumBon = this.bonNumBon;
                        // ------------------------------

                        this.bonNumBon = resp.data.NumBon;
                        this.panier = resp.data.Panier;

                        this.storageService.setItem('previousBonPanier', this.panier);
                        this.storageService.setItem('previousNumBon', resp.data.NumBon);

                        // List update after a success response
                        if(oldListData !== undefined && oldListData != null) {
                            oldListData.data.Bons.map(
                                (row: any) => {
                                    if(row === undefined || row == null) return false;

                                    if(row.numbon == oldNumBon) {
                                        row.nom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                                        row.prenom = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                                        row.TADR3 = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TADR3:null;
                                        row.cp = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                                        row.ville = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                                        row.tel = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                                        row.gsm = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                                        row.dtmaj = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('YYYY-MM-DD HH:mm:ss');

                                        row.montant = this.panier.montant;

                                        row.idcli = (this.panier.client !== undefined && this.panier.client !== null)?this.panier.client.idcli.toString():"0";

                                        row.numbon = this.bonNumBon;

                                        return row;
                                    }

                                    return row;
                                }
                            );

                            const updated_commande = oldListData.data.Bons.find(
                                (row: any) => {
                                    if(row === undefined || row == null) return false;

                                    if(row.numbon == this.bonNumBon) return row;
                                }
                            );

                            let updated_row_removed: any[] = oldListData.data.Bons.filter(
                                (row: any) => {
                                    if(row === undefined || row == null) return false;

                                    return row.numbon != this.bonNumBon;
                                }
                            );
                            if(updated_commande !== undefined && updated_commande !== null) {
                                updated_row_removed.unshift(updated_commande);
                            }

                            oldListData.data.Bons = updated_row_removed;

                            this.storageService.setItem('bonCommandeListData', oldListData);
                        }
                        // ------------------------------


                        const list_respose_data = this.storageService.getItem('clientListData');
                        let list_data: Client[];

                        if(list_respose_data !== undefined && list_respose_data !== null) {
                            list_data = list_respose_data.data;
                        }

                        if(list_data !== undefined && list_data !== null) {
                            list_data.map(
                                (item: Client) => {
                                    if(item.IDTIERS == form_data.IDTIERS) {
                                        item.CODPAYS = form_data.CODPAYS;
                                        item.IDCIV = form_data.IDCIV;
                                        item.CIVLIB = (this.civilite.find((row: Civilite) => row.IDCIV == form_data.IDCIV)).CIVLIB;
                                        item.TPRENOM = form_data.TPRENOM;
                                        item.TRAISOC = form_data.TRAISOC;
                                        item.TADR1 = form_data.TADR1;
                                        item.TADR2 = form_data.TADR2;
                                        item.TADR3 = form_data.TADR3;
                                        item.TADR4 = form_data.TADR4;
                                        item.TLANG = form_data.TLANG;
                                        item.TDTNAIS = form_data.TDTNAIS;
                                        item.TFAX = form_data.TFAX;
                                        item.CODPOS = form_data.CODPOS;
                                        item.TVILLE = form_data.TVILLE;
                                        item.TTEL = form_data.TTEL;
                                        item.TPORT = form_data.TPORT;
                                        item.TEMAIL = form_data.TEMAIL;

                                        return item;
                                    }
                                    return item;
                                }
                            );

                            list_respose_data.data = list_data;

                            this.storageService.setItem('clientListData', list_respose_data);
                        }

                        this.onChange.emit({ status: 'success_edit_bon_client', response: resp });
                    } else {
                        this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                    }
                } else {
                    this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuupdateerr'));
                }
            },
            err => {
                this.loadingService.hide();
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
        );
    }

    async loadDependencies() {
      this.loadingService.show();

      this.swissParamDetail = await this.userService.getRuleParamsData(20, 62);

      // Get the value of VINT1 from 20, 62 params, to check the email
      if (this.swissParamDetail && (this.swissParamDetail.VINT1 !== undefined && this.swissParamDetail.VINT1 !== null && this.swissParamDetail.VINT1 !== '')) {
        this.checkEmailParam = +this.swissParamDetail.VINT1;
      }

      this.checkClientRegexPattern();

      await this.getAllCivilite();
      await this.getAllPays();
      await this.getPaysParamData();
      await this.setDefaultPays();
      await this._getListLanguages();

      this.loadingService.hide();
    }

    checkClientRegexPattern() {
        if (this.swissParamDetail &&
            (this.swissParamDetail.VSTR4 !== undefined && this.swissParamDetail.VSTR4 !== null && this.swissParamDetail.VSTR4 !== '')
        ) {
            this.clientRegexPattern = this.swissParamDetail.VSTR4;

            this.editForm.get('TPRENOM').setValidators([Validators.pattern(this.clientRegexPattern)]);
            this.editForm.get('TRAISOC').setValidators([Validators.required, Validators.pattern(this.clientRegexPattern)]);
            this.editForm.get('TVILLE').setValidators([Validators.required, Validators.pattern(this.clientRegexPattern)]);
        } else {
            this.clientRegexPattern = undefined;

            this.editForm.get('TPRENOM').setValidators([]);
            this.editForm.get('TRAISOC').setValidators([Validators.required]);
            this.editForm.get('TVILLE').setValidators([Validators.required]);
        }

        this.editForm.get('TPRENOM').updateValueAndValidity();
        this.editForm.get('TRAISOC').updateValueAndValidity();
        this.editForm.get('TVILLE').updateValueAndValidity();

        this.editForm.updateValueAndValidity();
    }

    async _getListLanguages() {
        try {
            const paramData = await this.userService.getRuleParamsData(10, 1);
            this.defaultLang = paramData['VCHXMUL1'];
            this.listLang = [];

            if(paramData['VCHXMUL1LIS'] == null || paramData['VCHXMUL1LIS'] == '') {
                let langShortCode = this.defaultLang;
                if(this.client.TLANG !== undefined && this.client.TLANG != null && this.client.TLANG !== '') {
                    langShortCode = this.client.TLANG;
                }

                this.listLang = [langShortCode];
            } else {
                const separated = paramData['VCHXMUL1LIS'].split(' ');
                this.listLang = separated;
            }
        } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    }

    async getAllCivilite(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.civiliteService.getCivilite(this.USERCMINT, this.user.CIINT).subscribe(
              (res: any) => {
                this.civilite = res;        
                this.editForm.get('IDCIV').setValue(this.client.IDCIV);
                resolve();
              },
              err => {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                reject()
              }
            );
        });
    }

    printClientReport() {
        const idTiers = this.editForm.get('IDTIERS').value;

        const payload = {
            pIdTiers: +idTiers,
            pIdInsttie: +Constants.CIINST,
            pImprimante: 'download',
            Id_User: this.user.CIINT,
            Id_InstUser: +Constants.CIINST,
            CMINT: this.USERCMINT,
            IDSOC: Constants.IDSOC
        };

        this.loadingService.show();
        this.imprimerReveleCompte(payload);
    }

    async imprimerReveleCompte(payload: { pIdTiers: number, pIdInsttie: number, pImprimante: string, Id_User: number, Id_InstUser: number, CMINT: any, IDSOC: string }): Promise<void> {
        return new Promise((resolve, reject) => {
            this.clientService.imprimerReleveCompte(payload).subscribe(
                (resp: any) => {
                    this.loadingService.hide();

                    if (resp.statusCode == 200) {
                        if (resp.data.ErrorCode == 0) {
                            const fileUrl = resp.basePath + resp.data.Fichier; // 'Bon_2721162009080_122446557.pdf'

                            this.clientService.downloadFile(fileUrl).subscribe(
                                (_down_res) => {
                                    this.openUpPdfViewer({ url: fileUrl });
                                },
                                error => {
                                    this.dialogService.prompt(this.translateService.instant('kfailure'), error.message);
                                }
                            );
                            resolve();
                        } else {
                            reject();
                            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                        }
                    } else {
                        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                        reject();
                    }
                },
                err => {
                    this.loadingService.hide();
                    this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                    reject()
                }
            );
        });
    }

    openUpPdfViewer(data: any) {
        const dialogRef = this.dialog.open(PdfViewerComponent,
            {
                height: this.isMobile ? '100%' : undefined,
                data: {
                    ...data
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.status === "success") {

            }
        });
    }

    async getAllPays(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.clientService.getListPays(this.USERCMINT, this.user.CIINT).subscribe(
              (res: any) => {
                if(res.success !== undefined) {
                  this.listPays = res.data;
                  this.filteredPays = this.listPays;
                  this.editForm.get('CODPAYS').setValue(this.client.CODPAYS);              
                  resolve();            
                }
              },
              err => {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                reject()
              }
            );
        });
    }    

    async getPaysParamData() {
      try {
        this.paysParamData = await this.userService.getRuleParamsData(10, 1, 'VSTR1');
        if(this.paysParamData === undefined || this.paysParamData == null || this.paysParamData === '') {
          this.paysParamData = await this.userService.getRuleParamsData(10, 1, 'VSTR1', true);
        }
      } catch(err) {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    }

    async setDefaultPays() {
      if(this.selectedTeleCountry === undefined) {
        this.teleCountry = this.paysParamData.toUpperCase();
        this.selectedTeleCountry = this.paysParamData.toLowerCase();
      }

      if(this.selectedPortCountry === undefined) {
        this.portCountry = this.paysParamData.toUpperCase();
        this.selectedPortCountry = this.paysParamData.toLowerCase();
      }

      if(this.client.CODPAYS === undefined || this.client.CODPAYS === null || this.client.CODPAYS === '') {
        this.editForm.patchValue({ CODPAYS: this.paysParamData });
      }
    }

    onAutoOptionSelected(event: any, type?: 'code' | 'ville') {
      const value = event.option.value;
      let data: PostalCodeModel;
      if(type === undefined || type === 'code') {
        if(this.listPostalCodes !== undefined && this.listPostalCodes !== null) {
          data = this.listPostalCodes.find((row) => row.TROWID == value);
        } else {
          data = this.listVilles.find((row) => row.TROWID == value);  
        }        
      }

      if(type !== undefined || type === 'ville') {
        if(this.listVilles !== undefined && this.listVilles !== null) {
          data = this.listVilles.find((row) => row.TROWID == value);
        } else {
          data = this.listPostalCodes.find((row) => row.TROWID == value);
        }        
      }

      let clientPays = data.CODPAYS;

      if(clientPays === '' || clientPays == null) {
        clientPays = this.paysParamData.toUpperCase(); 
      }
      
      this.editForm.patchValue({
        CODPOS: data.CODPOS,
        CODPAYS: clientPays,
        TVILLE: data.VILLE,                    
      });
    }

    resetCodPos() {
      this.editForm.patchValue({ CODPOS: '' });
    }

    resetVille() {
      this.editForm.patchValue({ TVILLE: '' });
    }

    private _code_filter(value: string): Observable<PostalCodeModel[]> {
      const pays = this.editForm.get('CODPAYS').value;
      return this.clientService.searchPostalCode(value, pays).pipe(
        map((resp) => {
          this.listPostalCodes = resp.data;
          this.listVilles = undefined;

          return resp.data;
        }),
        catchError((err) => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'))
          return of({});
        })
      );
    }

    private _ville_filter(value: string): Observable<PostalCodeModel[]> {
      const pays = this.editForm.get('CODPAYS').value;
      return this.clientService.searchPostalCode(value, pays, 'ville').pipe(
        map((resp) => {
          this.listVilles = resp.data;
          this.listPostalCodes = undefined;
          
          return resp.data;
        }),
        catchError((err) => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'))
          return of({});
        })
      );
    }

    openUpVilleSelector() {
        const dialogRef = this.dialog.open(SearchPostalCodeComponent, {      
            width: '60vw',
            data: {
                list_codes: this.listPostalCodes
            }
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if(result && result.status === 'success') {
                this.editForm.patchValue({
                    CODPOS: result.code_data.CODPOS,
                    CODPAYS: result.code_data.CODPAYS,
                    TVILLE: result.code_data.VILLE,                    
                });
            }
          });
    }

    triggerBack() {
      if(this.displayType === 'tab') {
        this.onChange.emit({ status: 'return' });
      } else {
        this.router.navigate(['/client']);
      }
    }

    openClientHistory() {
      const dialogRef = this.dialog.open(ClientHistoriqueComponent,
        {
          data: {
            IDTIERS: this.client.IDTIERS,
            IDINSTTIE: this.client.IDINSTTIE,
            IDSOC: this.client.IDSOC,
          }
        }
      );

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.status === 'success') {

        }
      });
    }

    confirmAnonymizeDialog(): Observable<any> {
      const message = `<h1 class="mb-3">${this.translateService.instant('kclient_anonymize_confirm')}</h1>`;
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '50vw',
        data: {
          title: '',
          description: message,
          buttons: [this.translateService.instant('ksyes'),this.translateService.instant('ksno')]
        }     
      });
  
      return dialogRef.afterClosed();
    }

    async anonymizeClient(): Promise<void> {
      if (this.userPermission && !this.userPermission['2_1']) {
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kgnoaccess'));
        return;
      }

      const isConfirm = await this.confirmAnonymizeDialog().toPromise();
      if(isConfirm !== 'OK') {
        return;
      }
      
      this.loadingService.show();

      try {
        const response: any = await this.clientService.anonymizeClient(
          this.client.IDTIERS, 
          this.client.IDINSTTIE, 
          this.user.CIINT
        ).toPromise();
    
        if (response.success !== "ok") {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
        }
    
        if (response.data.ErrorCode) {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), response.data.Error);
        } else {
          await this.getClientByID();

          this.dialogService.prompt(
            this.translateService.instant('kmsuccess'), this.translateService.instant('kclient_anonymize_success')
          );

          this.loadingService.hide();
        }
      } catch (err) {
        this.loadingService.hide();
        this.dialogService.prompt(
          this.translateService.instant('kpconerr'), err.message || this.translateService.instant('kpconerrtry')
        );
      }
    }

    async getClientByID() {
      try {
        const response: any = await this.clientService.getClientById(this.client.IDTIERS).toPromise();
    
        this.loadingService.hide();
    
        if (response.success !== "ok") {
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
        }

        if (response.data && response.data.length > 0) {
          this.client = response.data[0];
          this.updateClientDetails();
        }
      } catch (err) {
        this.loadingService.hide();
        this.dialogService.prompt(
          this.translateService.instant('kpconerr'), err.message || this.translateService.instant('kpconerrtry')
        );
      }
    }

    isClientAnonymise() {
      if (this.client.TRAISOC === Constants.DEFAULT_ANONYMIZE && this.client.TPRENOM === Constants.DEFAULT_ANONYMIZE) {
        return true;
      }

      return false;
    }

    updateClientDetails() {
      this.editForm.patchValue({
        IDSOC: Constants.IDSOC,
        IDINST: (this.client?.IDINSTTIE == undefined?this.client.IDINST:this.client.IDINSTTIE),
        CMINT: this.client.CMINT,
        TLANG: this.client.TLANG || '',
        CODPAYS: this.client.CODPAYS,
        IDCIV: this.client.IDCIV,
        TPRENOM: this.client.TPRENOM,
        TRAISOC: this.client.TRAISOC,
        TADR1: this.client.TADR1,
        TADR2: this.client.TADR2,
        TADR3: this.client.TADR3,
        TADR4: this.client.TADR4,
        TDTNAIS: ((this.client.TDTNAIS !== null && this.client.TDTNAIS !== '0000-00-00') ? new Date(this.client.TDTNAIS) : ''),
        TFAX: this.client.TFAX,
        CODPOS: this.client.CODPOS,
        TVILLE: this.client.TVILLE,
        TTEL: this.client.TTEL,
        TPORT: this.client.TPORT,
        TEMAIL: this.client.TEMAIL,
        IDTIERS: this.client.IDTIERS,
        TOKSMS: this.client.TOKSMS == "-1" ? true : false,
        TOKMAIL: this.client.TOKMAIL == "-1" ? true : false,
        TOKAPP1: this.client.TOKAPP1 == "-1" ? true : false,
      });

      const list_respose_data = this.storageService.getItem('clientListData');
      const updated_list = list_respose_data.data.filter(item => item.IDTIERS !== this.client.IDTIERS);
      list_respose_data.data = updated_list;
      this.storageService.setItem('clientListData', list_respose_data);
    }
}
