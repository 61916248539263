<mat-sidenav-container style="height: 100%; overflow-x: hidden;overflow-y: hidden;">
    <mat-sidenav #sidenav mode="over" opened="false" >
        <side-nav-menu [sidenav]="sidenav" [appTitle]="'kapptitre' | translate" [version]="'capVersion' | translate" [logout]="Disconnect" [isMobile]="isMobile"
            [username]="userLoggedIn?(user?.CINOM + ' ' + user?.CIPRENOM):''" >
            <div *ngIf="isMobile">
                <side-nav-group *ngIf="sideMenu.backButton" title="Retour" icon="keyboard_arrow_left" (click)="goBack()" class="backbut"></side-nav-group>            
                <div *ngIf="sideMenu.mainMenu" class="mainmenu">
                    <side-nav-group class="submenu" *ngIf="checkAccessFromArray(['69_0', '74_0', '73_0', '101_0', '52_0', '71_0', '60_0'])" [title]="'kggeneral' | translate" icon="settings" (click)="openSubMenu('general')"><mat-icon class="arrowicon">chevron_right</mat-icon></side-nav-group>
                    <side-nav-group class="submenu" *ngIf="checkAccessFromArray(['4_0', '110_0', '32_0', '75_0', '56_0', '24_0'])" [title]="'kgsales' | translate" icon="add_shopping_cart" (click)="openSubMenu('sales')"><mat-icon class="arrowicon">chevron_right</mat-icon></side-nav-group>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('client')" route="/client" [childRoutes]="['^/client-detail']" [title]="'kgclients' | translate" icon="assignment_ind"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('article')" route="/article" [childRoutes]="['^/article-detail']" [title]="'kgarticles' | translate" icon="shopping_cart"></side-nav-item>
                    <side-nav-group class="submenu" *ngIf="checkAccessFromArray(['54_0', '0_18'])" [title]="'kreporting' | translate" icon="print" (click)="openSubMenu('reporting')"><mat-icon class="arrowicon">chevron_right</mat-icon></side-nav-group>
                </div>

                <side-nav-group *ngIf="checkAccessFromArray(['69_0', '74_0', '73_0', '101_0', '52_0', '71_0', '60_0']) && sideMenu.generalMenu" [title]="'kggeneral' | translate" icon="settings">
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('niveau-de-securite')" route="/niveau-de-securite" [title]="'ksniveau' | translate" icon="vpn_key"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('gestion-des-droits')" route="/gestion-des-droits" [title]="'kgtitle' | translate" icon="lock_open"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('utilisateurs')" route="/utilisateurs" [childRoutes]="['^/ajouter-utilisateur$', '^/modifier-utilisateur/.*$']" [title]="'kuusers' | translate" icon="person"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('motifs')" route="/motifs" [title]="'kmotif' | translate"  icon="fact_check"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('motif-blocage')" route="/motif-blocage" [title]="'kgblockdeblock' | translate" icon="rule"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('postal-codes')" route="/postal-codes" [title]="'kpcodepostal' | translate" icon="place"></side-nav-item>                
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('magasin-complement-info')" route="/magasin-complement-info" [title]="'kcimtitle' | translate" icon="menu_book"></side-nav-item>
                </side-nav-group>
            
                <side-nav-group *ngIf="checkAccessFromArray(['4_0', '110_0', '32_0', '75_0', '56_0', '24_0']) && sideMenu.salesMenu" [title]="'kgsales' | translate" icon="add_shopping_cart">
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('bon-de-vente')" route="/bon-de-vente" [childRoutes]="['^/create-bon-de-vente$', '^/modify-bon-de-vente/.*$', '^/detail-bon-de-vente/.*$']" [title]="'kgbonsdevente' | translate" icon="assignment"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('autre-magasin')" route="/bon-de-vente/autre-magasin" [childRoutes]="['^/create-bon-de-vente/.*$']" [title]="'kbonventeautremag' | translate" icon="assignment"></side-nav-item>
                    <side-nav-group *ngIf="checkAccessFromArray(['32_0', '75_0', '56_0'])" class="salesmemu submenu" (click)="openSubMenu('commandes')" [title]="'kboncomgestcmd' | translate" icon="assignment_turned_in"><mat-icon class="arrowicon">chevron_right</mat-icon></side-nav-group>
                    <side-nav-group *ngIf="checkAccessFromArray(['35_0', '35_1', '35_2'])" class="reservesmemu submenu" (click)="openSubMenu('reserves')" [title]="'kdroitreserves' | translate" icon="warehouse"><mat-icon class="arrowicon">chevron_right</mat-icon></side-nav-group>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('devis')" route="/devis" [childRoutes]="['^/create-devis$', '^/modify-devis/.*$', '^/devis-detail/.*$']" [title]="'kgdeviss' | translate" icon="request_quote"></side-nav-item>
                </side-nav-group>

                <side-nav-group *ngIf="checkAccessFromArray(['32_0', '75_0', '56_0']) && sideMenu.commandesMenu" [title]="'kboncomgestcmd' | translate" icon="assignment_turned_in">
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('bon-de-commande')" route="/bon-de-commande" [childRoutes]="['^/bon-de-commande/[0-9]{1,}$', '^/modify-bon-de-commande/[0-9]{1,}$', '^/bon-de-commande-detail/[0-9]{1,}$']" [title]="'kkkbonsdecommande' | translate" icon="assignment_turned_in"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('historique-des-commandes')" route="/historique-des-commandes" [childRoutes]="['^/bon-de-commande-detail/[0-9]{1,}[?]pageName=historique$']" [title]="'kboncomhiscmd' | translate" icon="receipt_long"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('commandes-supprimees')" route="/commandes-supprimees" [childRoutes]="['^/bon-de-commande-detail/[0-9]{1,}[?]pageName=supprimees$']" [title]="'kboncomsuppri' | translate" icon="restore_from_trash"></side-nav-item>
                </side-nav-group>

                <side-nav-group *ngIf="checkAccessFromArray(['35_0', '35_1', '35_2']) && sideMenu.reservesMenu" [title]="'kdroitreserves' | translate" icon="warehouse">
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('retrait-marchandise')" route="/retrait-marchandise" [childRoutes]="['^/retrait-marchandise$', '^/modify-retrait-marchandise/.*$', '^/retrait-marchandise-detail/.*$']" [title]="'kretraitmarchandise' | translate" icon="shopping_cart_checkout"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('gestion-des-e2')" route="/gestion-des-e2" [childRoutes]="['^/gestion-des-e2$', '^/modify-gestion-des-e2/.*$', '^/gestion-des-e2-detail/.*$']" [title]="'kgestiondese2' | translate" icon="production_quantity_limits"></side-nav-item>
                </side-nav-group>

                <side-nav-group *ngIf="checkAccessFromArray(['54_0', '0_18']) && sideMenu.reportingMenu" [title]="'kreporting' | translate" icon="print">
                    <div *ngFor="let item of reportingMenusList">
                        <side-nav-group class="salesmemu submenu" *ngIf="item?.child" (click)="openSubMenu(item.value)" [title]="item.label | translate" [icon]="item.icon"><mat-icon class="arrowicon">chevron_right</mat-icon></side-nav-group>
                        <side-nav-item [sidenav]="sidenav" [route]="'/reporting/'+item.value" *ngIf="!item?.child" [title]="item.label | translate" [icon]="item.icon"></side-nav-item>
                    </div>
                </side-nav-group>

                <div *ngFor="let item of reportingMenusList">
                    <side-nav-group *ngIf="item?.child && ((item.value === 'editions_de_controle' && sideMenu.reportingEditControl) || (item.value === 'editions_de_stocks' && sideMenu.reportingEditStock))" [title]="item.label | translate" [icon]="item.icon">
                        <side-nav-item [sidenav]="sidenav" [route]="'/reporting/'+childItem.value" *ngFor="let childItem of item?.child" [title]="childItem.label | translate" icon="folder"></side-nav-item>
                    </side-nav-group>
                </div>
            </div>
            <div *ngIf="!isMobile" class="desktop">
                <side-nav-group [title]="'kggeneral' | translate" *ngIf="checkAccessFromArray(['69_0', '74_0', '73_0', '101_0', '52_0', '71_0', '60_0'])" icon="settings">
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('niveau-de-securite')" route="/niveau-de-securite" [title]="'ksniveau' | translate" icon="vpn_key"></side-nav-item>                    
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('gestion-des-droits')" route="/gestion-des-droits" [title]="'kgtitle' | translate" icon="lock_open"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('utilisateurs')" route="/utilisateurs" [childRoutes]="['^/ajouter-utilisateur$', '^/modifier-utilisateur/.*$']" [title]="'kuusers' | translate" icon="person"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('motifs')" route="/motifs" [title]="'kmotif' | translate"  icon="fact_check"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('motif-blocage')" route="/motif-blocage" [title]="'kgblockdeblock' | translate" icon="rule"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('postal-codes')" route="/postal-codes" [title]="'kpcodepostal' | translate" icon="place"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('magasin-complement-info')" route="/magasin-complement-info" [title]="'kcimtitle' | translate" icon="menu_book"></side-nav-item>
                </side-nav-group>
                
                <side-nav-group [title]="'kgsales' | translate" *ngIf="checkAccessFromArray(['4_0', '110_0', '24_0', '32_0', '75_0', '56_0', '35_0', '35_1', '35_2'])" icon="add_shopping_cart">
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('bon-de-vente')" route="/bon-de-vente" [childRoutes]="['^/create-bon-de-vente$', '^/modify-bon-de-vente/.*$', '^/detail-bon-de-vente/.*$']" [title]="'kgbonsdevente' | translate" icon="assignment"></side-nav-item>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('autre-magasin')" route="/bon-de-vente/autre-magasin" [childRoutes]="['^/create-bon-de-vente/.*$']" [title]="'kbonventeautremag' | translate" icon="assignment"></side-nav-item>
                    <side-nav-group [title]="'kboncomgestcmd' | translate" *ngIf="checkAccessFromArray(['32_0', '75_0', '56_0'])" icon="assignment_turned_in">
                        <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('bon-de-commande')" route="/bon-de-commande" [childRoutes]="['^/bon-de-commande/[0-9]{1,}$', '^/modify-bon-de-commande/[0-9]{1,}$', '^/bon-de-commande-detail/[0-9]{1,}$']" [title]="'kkkbonsdecommande' | translate" icon="assignment_turned_in"></side-nav-item>
                        <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('historique-des-commandes')" route="/historique-des-commandes" [childRoutes]="['^/bon-de-commande-detail/[0-9]{1,}[?]pageName=historique$']" [title]="'kboncomhiscmd' | translate" icon="receipt_long"></side-nav-item>
                        <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('commandes-supprimees')" route="/commandes-supprimees" [childRoutes]="['^/bon-de-commande-detail/[0-9]{1,}[?]pageName=supprimees$']" [title]="'kboncomsuppri' | translate" icon="restore_from_trash"></side-nav-item>
                    </side-nav-group>
                    <side-nav-group [title]="'kdroitreserves' | translate" *ngIf="checkAccessFromArray(['35_0', '35_1', '35_2'])" icon="warehouse">
                        <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('retrait-marchandise')" route="/retrait-marchandise" [childRoutes]="['^/retrait-marchandise$', '^/modify-retrait-marchandise/.*$', '^/retrait-marchandise-detail/.*$']" [title]="'kretraitmarchandise' | translate" icon="shopping_cart_checkout"></side-nav-item>
                        <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('gestion-des-e2')" route="/gestion-des-e2" [childRoutes]="['^/gestion-des-e2$', '^/modify-gestion-des-e2/.*$', '^/gestion-des-e2-detail/.*$']" [title]="'kgestiondese2' | translate" icon="production_quantity_limits"></side-nav-item>
                    </side-nav-group>
                    <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('devis')" route="/devis" [childRoutes]="['^/create-devis$', '^/modify-devis/.*$', '^/devis-detail/.*$']" [title]="'kgdeviss' | translate" icon="request_quote"></side-nav-item>
                </side-nav-group>
                
                <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('client')" route="/client" [childRoutes]="['^/client-detail']" [title]="'kgclients' | translate" icon="assignment_ind"></side-nav-item>
                <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility('article')" route="/article" [childRoutes]="['^/article-detail']" [title]="'kgarticles' | translate" icon="shopping_cart"></side-nav-item>

                <side-nav-group [title]="'kreporting' | translate" *ngIf="checkAccessFromArray(['54_0', '0_18'])" icon="print">
                    <div *ngFor="let item of reportingMenusList">
                        <side-nav-group [title]="item.label | translate" *ngIf="item?.child && definingMenuVisibility(item.value === 'editions_de_controle'?'editions_de_controle':'reporting')" [icon]="item.icon">
                            <div *ngFor="let childItem of item?.child">
                                <side-nav-item [sidenav]="sidenav" *ngIf="definingMenuVisibility(childItem.value === 'suivi_des_bons'?'suivi_des_bons':'reporting')" [route]="'/reporting/'+childItem.value" [title]="childItem.label | translate" icon="folder"></side-nav-item>
                            </div>
                        </side-nav-group>

                        <side-nav-item [sidenav]="sidenav" [route]="'/reporting/'+item.value" *ngIf="!item?.child && definingMenuVisibility('reporting')" [title]="item.label | translate" [icon]="item.icon"></side-nav-item>
                    </div>
                </side-nav-group>
            </div>
        </side-nav-menu>
    </mat-sidenav>

    <mat-toolbar color="primary">
        <div class="row align-items-center" [class.no-gutters]="isMobile">
            <div class="align-items-center d-flex justify-content-start col-lg-4 col-md-4" [class]="userLoggedIn?'col-2':'col-6'">
                <button mat-icon-button (click)="sidenav.toggle()" *ngIf="userLoggedIn" class="bt_menu mr-4">
                    <mat-icon>menu</mat-icon>
                </button>
                <h2 class="app_title d-md-block d-lg-block" style="text-transform: initial" [class]="userLoggedIn?'d-none':'d-block'">
                    <span class="pointer">
                        {{ 'kapptitre' | translate }}
                    </span>
                    <span class="version">{{ 'capVersion' | translate }}</span>    
                </h2>
            </div>
            <div class="d-lg-block d-md-block col-md-4 col-lg-4" [class]="userLoggedIn?'d-none col-2':'col-6'">
                <div class="text-center">
                    <img src="assets/images/app-logo/logo.png" [class]="isMobile?'img-fluid':''" style="max-height: 55px;" alt="CapInfo">
                </div>
            </div>
            <div class="col-4 col-md-3 col-lg-3 text-right d-none d-sm-block">
                <div [hidden]="!userLoggedIn" class="currentuser_name">{{user?.CINOM + ' ' + user?.CIPRENOM}}</div>
            </div>
            <div class="col-7 d-block d-md-none d-lg-none" *ngIf="!headerSearchInputStatus">
                <div class="align-items-center d-flex d-md-none d-lg-none justify-content-end" *ngIf="headerSelectOption">                    
                    <mat-form-field class="headerselect">
                        <mat-select [(ngModel)]="dropValue" (selectionChange)="submit_select_box()">                            
                            <mat-option  *ngFor="let item of dropOption" [value]="item.value">{{item.label | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="d-md-none d-lg-none justify-content-end" *ngIf="currentPageName == '/gestion-des-droits'">    
                    <form [formGroup]="selectForm" >
                        <mat-form-field class="headerselect">
                            <mat-select  color="primary" disableOptionCentering (selectionChange)="onNiveauSelectionChange($event.value)" formControlName="search_niveau">
                                <mat-option>
                                    <ngx-mat-select-search
                                    [placeholderLabel]="'kgchooseniv' | translate"
                                    [noEntriesFoundLabel]="'kgnoniv' | translate"
                                    (ngModelChange)="onSearchChange($event)" [(ngModel)]="filterNiveau" [ngModelOptions]="{standalone: true}"></ngx-mat-select-search>
                                </mat-option>

                                <mat-option *ngFor="let item of filteredNiveauTypes" [value]="item.NIVINT">{{ item.NIVNOM }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>        
                </div>
            </div>
            <div class="col-7 d-block d-md-none d-lg-none" *ngIf="headerSearchInputStatus">
                <form (ngSubmit)="submit_search_input()">
                    <input #rapidInput inputmode="none" name="header_search" (click)="inputFocus($event)" [type]="headerSearchInputType" [(ngModel)]="searchValue" (ngModelChange)="change_search_input()" class="form-control header-search-input">
                </form>
            </div>
            <div class="col-3 col-md-1 col-lg-1 text-right bloc_logout" *ngIf="userLoggedIn">
                <div class="align-items-center d-none d-md-flex d-lg-flex justify-content-end">
                    <mat-icon (click)="Disconnect()" class="bt_logout bt_cursor" [title]="'klogout' | translate">power_settings_new</mat-icon>
                </div>

                <div class="align-items-center d-flex d-md-none d-lg-none justify-content-end" *ngIf="!headerSearchInputStatus">
                    <mat-icon (click)="search_callback()" class="bt_logout bt_cursor text-white dummy" *ngIf="headerSearchButtonStatus && currentPageName != '/reporting/reporting_attente'" [title]="'kclsearc' | translate">search1</mat-icon>
                    <mat-icon (click)="add_callback()" class="bt_logout bt_cursor text-white mr-2 header-addition" *ngIf="headerAddButtonStatus" [title]="'kpadd' | translate">add_circle</mat-icon>
                    
                    <mat-icon (click)="refreshReports()" class="bt_logout bt_cursor text-white mr-2 header-addition" *ngIf="currentPageName == '/reporting/reporting_attente'" [title]="'kreport_actualiser' | translate">refresh</mat-icon>
                    <mat-icon (click)="deleteSelectedReports()" class="bt_logout bt_cursor text-white mr-2 header-addition" *ngIf="currentPageName == '/reporting/reporting_attente' && headerDeleteReportStatus" [title]="'kbonsupprimer' | translate">delete</mat-icon>
                </div>

                <div class="align-items-center d-flex d-md-none d-lg-none justify-content-around" *ngIf="headerSearchInputStatus">
                    <mat-icon (click)="submit_search_input()" class="bt_logout bt_cursor text-white" [title]="'kclsearc' | translate">search2</mat-icon>
                    <mat-icon (click)="scan_callback()" class="bt_logout bt_cursor text-white ml-2"  *ngIf="(currentPageName == '/article' || currentPageName == '/bon-de-vente' || currentPageName == '/bon-de-commande' || currentPageName == '/historique-des-commandes' || currentPageName == '/commandes-supprimees' || currentPageName == '/retrait-marchandise' || currentPageName == '/gestion-des-e2' || currentPageName == '/devis')">
                        <i class="fa fa-barcode" aria-hidden="true"></i></mat-icon>
                    <mat-icon (click)="add_callback()" class="bt_logout bt_cursor text-white ml-2" *ngIf="headerAddButtonStatus" [title]="'kpadd' | translate">add_circle</mat-icon>
                    <mat-icon (click)="search_callback()" class="bt_logout bt_cursor text-white" *ngIf="headerSearchInputThreeDotsStatus" [title]="'kclsearc' | translate">more_vert</mat-icon>
                </div>

                <div class="align-items-center d-flex d-md-none d-lg-none justify-content-end" *ngIf="headerThreeDots">
                    <mat-icon (click)="search_callback()" class="bt_logout bt_cursor text-white" [title]="'kclsearc' | translate">more_vert</mat-icon>
                </div>    
               
            </div>
        </div>
    </mat-toolbar>

    <div class="main-container">
        <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
        <app-scroll-to-top></app-scroll-to-top>
    </div>

</mat-sidenav-container>
<ngx-spinner [fullScreen]="true" size="medium">
    <div class="mt-3" *ngIf="spinnerButtonStatus">
        <button [class]="(isMobile && isReportingPage) ? 'bt_valid w-300' : 'bt_valid'" mat-button type="button" (click)="isReportingPage ? navigateToPendingReports() : manuallyCancelSubscription()">
            <i class="material-icons">close</i>
            <span [class]="isReportingPage ? 'd-block' : 'd-none d-sm-none d-md-block'">
                {{ (isReportingPage ? "kreport_loading" : "kretourpage") | translate }}
            </span>                        
        </button>
    </div>
</ngx-spinner>
