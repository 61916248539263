import {Component, OnInit, Input, Output, OnDestroy, EventEmitter, ViewChild, AfterViewInit, Inject, Optional} from '@angular/core';

import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

import {Article} from '../../../models/articles';
import {ArticleService} from '../../../providers/api/articleService';

import {StockService} from '../../../providers/api/stockService';

import {User} from '../../../models/users';
import { UserService } from 'providers/api/userService';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UpdateMotifBlocageComponent } from './update-motif-blocage/update-motif-blocage.component';
import { UpdateBlocageExterneComponent } from './update-blocage-externe/update-blocage-externe.component';
import { UpdateStockEmplacementComponent } from './update-stock-emplacement/update-stock-emplacement.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { Constants } from 'providers/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { BlocageCustomMotifComponent } from './update-motif-blocage/blocage-custom-motif/blocage-custom-motif.component';
import { UtilService } from 'providers/api/utilService';
import BonPanier from '../../../models/bonPanier';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-article-stock',
    templateUrl: './article-stock.component.html',
    providers: [ArticleService, DialogsService, TranslateService, UtilService]
})

export class ArticleStockComponent implements AfterViewInit, OnInit, OnDestroy {

    private _article: Article
    get article() {return this._article}
    @Input() set article(article: Article) {
        this.user = this.userService.getCurrentUser();          
        this.USERCMINT = this.user.CMINT;

        this.articleChange.emit(this._article = article)

        this.CAINT = this.article?this.article.CAINT:undefined;
        this.STOCK_DISPO = this.article?this.article.STOCKDISPO:undefined;

        if(this.displayType === 'dialog') {
            if(!this.tabSubscription) {
                this.tabSubscription = this.tabChanged.subscribe((
                    (index) => {
                        this.showLoader = true;
                        this.showMotifLoader = true;

                        if(index === 1 && this.CAINT) {                            
                            this.getStockBloque();
                            this.getStockDispo();
                            this.getMotifBlocage();  
                            this.getListStockEmplacement();
                            this.getStockEmplacementDetails();
                        }               
                    }
                ));
            }            
        }

        if(this.displayType === 'component' && article !== undefined) {
            if(this.tabSubscription !== undefined && this.tabSubscription !== null) {
                this.tabSubscription.unsubscribe();
            }

            this.tabSubscription = this.tabChanged.subscribe((
                (index) => {
                    this.showLoader = true;
                    this.showMotifLoader = true;

                    if((index === 1 || index === 8) && this.CAINT) {
                        if(index === 1) {                            
                            this.getStockBloque();
                            this.getStockDispo();
                            this.getMotifBlocage();
                        }

                        if(index === 8) {                            
                            this.getListStockEmplacement();
                            this.getStockEmplacementDetails();
                        }                                                        
                    }               
                }
            ));         
        } else {
            this.showLoader = false;
            this.showMotifLoader = false;
        }
    }    
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();

    @Input() tabChanged: Subject<any>;

    @Input() currentStockMag: any | undefined;

    CAINT: any;
    currentUser: User = null;
    STOCK_DISPO: any;
    STOCK_BLOQUE: any;
    article_session: any;

    allMotifBlocage: any;
    listMotifBlocage: any[] = [];

    // DATATABLE
    @ViewChild(DataTableDirective, {static: false})
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtMotifOptions: DataTables.Settings = {};
    dtMotifTrigger: Subject<any> = new Subject();
    dtTrigger: Subject<any> = new Subject();

    user: User;
    USERCMINT: any;

    showMotifLoader = true;
    showLoader = true;
    previousTabIndex: number;

    listStockEmplacement: any;
    filteredStockEmplacement: any[] = [];

    emplacementValue = 0;
    e2Value = 0;
    edValue = 0;
    blValue = 0;
    livEnCoursValue = 0;
    attenteLivValue = 0;    

    tabSubscription: Subscription;
    @Input() displayType: 'dialog' | 'component' | 'bonde' = 'dialog';
    flowType: any;
    defaultTab: string = 'info_logistique';

    @Input() displayOnly: 'block_stock' | 'emplacement_stock';
    @Input() stockBlocageEditable: boolean = true;
    @Input() bonVenteFlow: boolean = false;

    isMobile: boolean;

    userPermission: any;
    authorizedBlocageUser: User;

    enableStockMagSelection: boolean = false;

    NUMBON: string;
    PANIER: BonPanier | undefined;

    constructor(private stockService: StockService,                
                private userService: UserService,
                private dialogService: DialogsService,
                private dialog: MatDialog,
                private translateService: TranslateService,
                private deviceDetector: DeviceDetectorService,
                private loadingService: NgxSpinnerService,
                private articleService: ArticleService,
                private utilService: UtilService,
                private storageService: StorageService,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
                @Optional() private dialogRef?: MatDialogRef<ArticleStockComponent>) {


    }

    ngOnInit() {
        this.isMobile = this.deviceDetector.isMobile();
        
        this.userPermission = this.storageService.getItem('CurrentUserPermission');
        if(this.data !== undefined && this.data !== null && Object.keys(this.data).length > 0) {

            this.user = this.userService.getCurrentUser();          
            this.USERCMINT = this.user.CMINT;
            
            this.displayType = (this.data.displayType !== undefined && this.data.displayType != null)?this.data.displayType:'bonde';
            this.article = this.data.articleData;

            if(this.data.stockBlocageEditable !== undefined && this.data.stockBlocageEditable !== null) {
                this.stockBlocageEditable = this.data.stockBlocageEditable;                
            }

            if(this.data.currentStockMag !== undefined && this.data.currentStockMag !== null) {
                this.currentStockMag = this.data.currentStockMag;
            }

            if (this.data.NUMBON !== undefined && this.data.NUMBON !== null) {
                this.NUMBON = this.data.NUMBON;
            }

            if (this.data.PANIER !== undefined && this.data.PANIER !== null) {
                this.PANIER = this.data.PANIER;
            }

            if(this.data.enableStockMagSelection !== undefined && this.data.enableStockMagSelection !== null) {
                this.enableStockMagSelection = this.data.enableStockMagSelection;
            }

            if(this.data.bonVenteFlow !== undefined && this.data.bonVenteFlow !== null) {
                this.bonVenteFlow = this.data.bonVenteFlow;
            }

            if (this.data.flowType !== undefined && this.data.flowType !== null) {
                this.flowType = this.data.flowType;

                if (this.flowType === 'bonde_vente') {
                    // Open Stock Autre magasins accordion
                    this.defaultTab = 'autre_magasins';
                }
            }

            this.CAINT = this.article?this.article.CAINT:undefined;
            this.STOCK_DISPO = this.article?this.article.STOCKDISPO:undefined;

            if(this.CAINT) {
                this.showLoader = true;
                this.showMotifLoader = true;

                this.getStockBloque();
                this.getStockDispo();
                this.getMotifBlocage();  
                this.getListStockEmplacement();
                this.getStockEmplacementDetails();
            }
        } else {
            if(this.CAINT) {
                this.showLoader = true;
                this.showMotifLoader = true;

                this.getStockBloque();
                this.getStockDispo();
                this.getMotifBlocage();  
                this.getListStockEmplacement();
                this.getStockEmplacementDetails();
            }
        }        
        

        this.dtMotifOptions = {
            language: {
                processing: this.translateService.instant('kprocessing'),
                search: this.translateService.instant('ksearch'),
                loadingRecords: this.translateService.instant('kloading'),
                info: this.translateService.instant('kgenshowentry'),
                infoEmpty: this.translateService.instant('kgenshowingentry'),
                zeroRecords: this.translateService.instant('knodata'),
                emptyTable: this.translateService.instant('knodatadash'),
                paginate: {
                    first: this.translateService.instant('kfirst'),
                    previous: this.translateService.instant('kprevious'),
                    next: this.translateService.instant('knext'),
                    last: this.translateService.instant('klast'),
                }        
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };

        this.dtOptions = {
            language: {
                processing: this.translateService.instant('kprocessing'),
                search: this.translateService.instant('ksearch'),
                loadingRecords: this.translateService.instant('kloading'),
                info: this.translateService.instant('kgenshowentry'),
                infoEmpty: this.translateService.instant('kgenshowingentry'),
                zeroRecords: this.translateService.instant('knodata'),
                emptyTable: this.translateService.instant('knodatadash'),
                paginate: {
                    first: this.translateService.instant('kfirst'),
                    previous: this.translateService.instant('kprevious'),
                    next: this.translateService.instant('knext'),
                    last: this.translateService.instant('klast'),
                }        
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };
    }

    ngOnDestroy(): void {
        if(this.displayType === 'dialog') {
            this.tabSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        // si on l'enlève, on ne peut pas recharger le tableau en faisant une nouvelle recherche, mais du coupe cela casse le datatable oupfffff....
        this.dtMotifTrigger.next();
        this.dtTrigger.next();
    }

    onChosenMagChange(eventData: { changedCMINT: number, authorizedUser: any } | undefined) {
        this.dialogRef.close({ status: 'success', type: 'mag_change', changedCMINT: eventData.changedCMINT, authorizedUser: eventData.authorizedUser });
    }

    getStockBloque() {
        let CMINT = this.USERCMINT;
        if (this.currentStockMag) {
            CMINT = this.currentStockMag;
        }

        this.stockService.getStockBlock(this.CAINT, CMINT).subscribe(
            (data) => {
                if(data) {
                    this.STOCK_BLOQUE = parseFloat(data.STOCK_BLOQUE);
                }                
            },
            (error) => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        );
    }

    getStockEmplacementDetails() {
        let CMINT = this.USERCMINT;
        if (this.currentStockMag) {
            CMINT = this.currentStockMag;
        }

        this.stockService.getStockEmplacementParams(this.CAINT, CMINT).subscribe(
            (res) => {
                if(res.success !== undefined) {
                    const data = res.data;
                    this.e2Value = data.E2_value?data.E2_value:0;
                    this.edValue = data.ED_value?data.ED_value:0;
                    this.blValue = data.BL_value?data.BL_value:0;
                    this.livEnCoursValue = data.LIVCOUR_value?data.LIVCOUR_value:0;
                    this.attenteLivValue = data.LIVATT_value?data.LIVATT_value:0;
                }                
            },
            (error) => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        );
    }
    

    getStockDispo() {
        let CMINT = this.USERCMINT;
        if (this.currentStockMag) {
            CMINT = this.currentStockMag;
        }

        this.stockService.getStockDispo(this.CAINT, CMINT).subscribe(
            (data) => {
                if(data) {
                   this.STOCK_DISPO = parseFloat(data.STOCK_DISPO);
                   this.article.STOCKDISPO = this.STOCK_DISPO;
                }                 
            },
            (error) => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }
        );
    }

    getMotifBlocage() {
        let CMINT = this.USERCMINT;
        if (this.currentStockMag) {
            CMINT = this.currentStockMag;
        }

        this.stockService.getMotifBlocageStock(this.CAINT, CMINT, this.user.CIINT, this.USERCMINT).subscribe(
            response => {            
                this.showMotifLoader = false;
                if(response.success !== undefined) {
                    this.allMotifBlocage = response.data;
                    this.listMotifBlocage = this.allMotifBlocage.filter((res: any) => res.OKVISIBLE != '0');
                }
                this.rebindDataTable();
            },
            err => {
              this.showMotifLoader = false;
              this.allMotifBlocage = undefined;
              this.listMotifBlocage = this.allMotifBlocage;

              this.rebindDataTable();
              this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            }
        );
    }

    async getListStockEmplacement() {
        let CMINT = this.USERCMINT;
        if (this.currentStockMag) {
            CMINT = this.currentStockMag;
        }

        this.stockService.getListStockEmplacement(this.CAINT, CMINT, Constants.APP_DEFAULT_LANG, this.user.CIINT).subscribe(
            response => {            
                this.showLoader = false;
                if(response.success !== undefined) {
                    this.listStockEmplacement = response.data;  
                    
                    this.emplacementValue = 0;            
                    this.listStockEmplacement.forEach(
                        (row: any) => {
                            this.emplacementValue = (+this.emplacementValue) + (+row.MVTQTE);
                        }
                    );

                    this.filteredStockEmplacement = this.listStockEmplacement.filter(
                        (row: any) => {
                            return (row.MVTQTE != null && +row.MVTQTE != 0);
                        }
                    )
                    
                    this.dtTrigger.next();
                }
            },
            err => {
              this.showLoader = false;

              this.emplacementValue = 0;
              this.listStockEmplacement = undefined;
              this.filteredStockEmplacement = this.listStockEmplacement;
              this.dtTrigger.next();
              
              this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            }
        );
    }

    async checkEditable(motif: any) {
        if(!this.stockBlocageEditable) return;

        const editable = (motif && motif.OKMODIF == '-1')?true:false;
    
        if(editable) {
            if(!this.userPermission['0_10']) {
                this.authorizedBlocageUser = undefined;
                await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();

                const prevNumBon = this.storageService.getItem('previousNumBon');
                const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '10', this.user.CIINT, ((prevNumBon != undefined && prevNumBon != 'null' && prevNumBon !== '')?prevNumBon:''), this.article.CDART); 

                if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
                  return;
                }

                this.authorizedBlocageUser = authorizationResult.data;
            }
            
            if(this.bonVenteFlow) {
                if(motif.IDMOT == 99) {
                    this.updateExterneMotifBlocage(motif);
                } else {
                    this.updateMotifBlocage(motif, 'bonde');
                }
                return;
            }

            if(motif.IDMOT == 99) {
                this.updateExterneMotifBlocage(motif);
            } else {
                this.updateMotifBlocage(motif, 'article');
            }
            
        } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartuauth'), this.translateService.instant('kuok'));            
        }
    }

    bondeBlocageConfirmation(motif: any): void {
        const message = `
          <h1 class="text-left mb-3">${this.translateService.instant('ksuppridevis')} 9300038</h1>
          <h1 class="text-left">${this.translateService.instant('kkqty')} 1</h1>
          <h1 class="text-left">${this.translateService.instant('kkreason')} ${motif.LIBEL}</h1>
        `;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '60vw',
          data: {
            title: '',
            description: message,
            buttons: [
                this.translateService.instant('ksyes'),
                this.translateService.instant('kartno')
              ]
          }     
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result === "OK") {
            this.openUpBondeCustomMotif(motif);
          }
        });    
    }

    openUpBondeCustomMotif(motif: any): void {        
        const dialogRef = this.dialog.open(BlocageCustomMotifComponent, {
          maxWidth: '40vw',
          data: {
                        
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {          
          if(result && result.status === "success") {
            const custom_motif_data = result.data;
            const prevNumBon = this.storageService.getItem('previousNumBon');
            //   Motif process
            motif['MOTIF'] = custom_motif_data.MOTLIB;
            motif['BLOC_LIBEL'] = motif.LIBEL;
            motif['USER_ID'] = this.user.CIINT;
            motif['NUMCOM'] = (prevNumBon != undefined && prevNumBon != 'null' && prevNumBon !== '')?prevNumBon:'';
            motif['CDART'] = this.article.CDART;

            this.updateStockBlocage(motif, 'bonde');
          }      
        });    
    }

    updateStockBlocage(motif: any, type: 'article' | 'bonde' = 'article') {
        if(motif === undefined || motif === null || !motif.VBLOC || motif.VBLOC == 0) return;

        const form_data: any = {};
    
        const entered_qty = +motif.VBLOC;
    
        this.loadingService.show();
        form_data['CAINT'] = this.CAINT;
        form_data['IDMAG'] = this.USERCMINT;
        form_data['IDSOC'] = Constants.IDSOC;
        form_data['IDINST'] = Constants.CIINST;
        form_data['IDMOT'] = motif.IDMOT;
        form_data['QTY'] = entered_qty - 1;    

        if(type === 'bonde') {
            form_data['MOTIF'] = motif.MOTIF;
            form_data['BLOC_LIBEL'] = motif.BLOC_LIBEL;
            form_data['USER_ID'] = motif.USER_ID;
            if(motif.NUMCOM !== '') {
                form_data['NUMCOM'] = motif.NUMCOM;
            }
            form_data['CDART'] = motif.CDART;
        }
    
        this.articleService.updateMotifBlocage(form_data).subscribe(
          (res) => {
            this.loadingService.hide();
            if(res.success !== undefined) {
                this.listMotifBlocage.map(
                    (row: any) => {
                        if(row.IDMOT == motif.IDMOT) {
                            row.VBLOC = +row.VBLOC - 1;
                            return row;
                        }
                        return row;
                    }
                );    
    
                this.STOCK_BLOQUE = this.STOCK_BLOQUE - 1;
                this.STOCK_DISPO = this.STOCK_DISPO + 1;
            } else {
              this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));  
            }
          },
          err => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
          }
        )    
    }

    updateMotifBlocage(motif: any, type: 'article' | 'bonde' = 'article'): void {
        const dialogRef = this.dialog.open(UpdateMotifBlocageComponent, {
          maxWidth: '40vw',
          data: {
            motif_data: motif,
            STOCK_DISPO: this.STOCK_DISPO,
            article_data: this.article,
            flowType: type,
            authorizedBlocageUser: this.authorizedBlocageUser,
            NUMBON: this.NUMBON,
            PANIER: this.PANIER,
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {          
          if(result && result.status === "success") {
                this.listMotifBlocage.map(
                    (row: any) => {
                        if(row.IDMOT == motif.IDMOT) {
                            row.VBLOC = result.changed_qty?result.changed_qty:0;
                            return row;
                        }
                        return row;
                    }
                );
                
                this.STOCK_BLOQUE = this.STOCK_BLOQUE + (+result.difference_qty);
                this.STOCK_DISPO = this.STOCK_DISPO - (+result.difference_qty);
          }      
        });    
    }

    updateExterneMotifBlocage(motif: any): void {
        const listMotifs = this.listMotifBlocage.filter((row: any) => row.IDMOT != 99);        

        const dialogRef = this.dialog.open(UpdateBlocageExterneComponent, {
          maxWidth: '80vw',
          autoFocus: false,
          data: {
            motif_data: motif,
            STOCK_DISPO: this.STOCK_DISPO,
            article_data: this.article,
            listMotifs: listMotifs,
            authorizedBlocageUser: this.authorizedBlocageUser,
            NUMBON: this.NUMBON,
            PANIER: this.PANIER,
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {

          if(result && result.status === "success") {

            const update_data = result.data;
            let total_affected_qty = 0;
            
            this.listMotifBlocage.map(
                (row: any) => {
                    const update_found = update_data.find((update_row: any) => update_row.IDMOT == row.IDMOT);                    

                    if(update_found !== undefined && update_found !== null) {
                        row.VBLOC = ((+row.VBLOC) > 0)?((+row.VBLOC) + (+update_found.QTY)):update_found.QTY;
                        total_affected_qty += +update_found.QTY;
                    }

                    return row;
                }
            );
            
            this.listMotifBlocage.map(
                (row: any) => {
                    if(row.IDMOT == 99) {
                        row.VBLOC = (+row.VBLOC) - (+total_affected_qty);
                        return row;
                    }
                return row;
            });

            setTimeout(
                () => {
                this.rebindDataTable();
            }, 500);
            
          }
        });    
    }

    rebindDataTable() {
        if(this.datatableElement !== undefined && this.datatableElement != null && this.datatableElement.dtInstance !== undefined && this.datatableElement.dtInstance != null) {
            this.datatableElement.dtInstance.then(x => {            
                x.destroy();
                
                this.dtMotifTrigger.next();
                this.dtTrigger.next();
            });
        }
    }

    openTransferStockLocation(stockEmplacement: any) {
        const dialogRef = this.dialog.open(UpdateStockEmplacementComponent, {
            maxWidth: '75vw',
            autoFocus: false,
            data: {
              emplacement_data: stockEmplacement,
              STOCK_DISPO: this.STOCK_DISPO,
              article_data: this.article,
              all_stock_locations: this.listStockEmplacement
            }
        });
      
        dialogRef.afterClosed().subscribe(result => {
            this.showLoader = true;
            this.getListStockEmplacement();

            if(result && result.status === "success") {
            }      
        });   
    }

    closeDialog() {
        this.dialogRef.close({ status: 'success', type: 'default', STOCK_DISPO: this.STOCK_DISPO, STOCK_BLOQUE: this.STOCK_BLOQUE });
    }
}
