<div class="page-container" >
    <div class="title row">
        <div class="col-1 col-sm-2 col-md-2 col-lg-3">
            <mat-icon class="pointer" (click)="returnBack()" [title]="'kuback' | translate">
                keyboard_arrow_left
            </mat-icon>
        </div>
        <div class="col-10 col-sm-8 col-md-8 col-lg-6 text-center">
            <h1 class="text-center">
                {{FIRSTNAME}} {{LASTNAME}} 
            </h1>
        </div>
        <div class="col-1 col-sm-2 col-md-2 col-lg-3 no-padding" *ngIf="isMobile">
            <mat-icon>person</mat-icon>
        </div> 
    </div>

    <form [formGroup]="editUserForm" (ngSubmit)="editUserFormSubmitter()">
        <div class="bloc_container padding_15 bg_light" *ngIf="!isMobile">
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="row no-gutters">
                        <div class="col-12 col-sm-12 col-lg-8">
                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3 tiers">
                                    <mat-form-field >
                                        <input matInput [placeholder]="'kuuserno' | translate" [value]="editUserID" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-3">
                                    <div class="d-flex h-100 align-items-center">
                                        <mat-checkbox formControlName="CIACTIF">{{'kuactiveuser' | translate}}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                                    <label for="USERLANG">{{ 'klang' | translate }}</label>
                                    <ng-select formControlName="USERLANG" bindLabel="name" appearance="outline" [searchable]="false" [clearable]="false" labelForId="USERLANG">
                                        <ng-option [value]="item" *ngFor="let item of listLang">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <span class="cap__flag mr-3" [class]="'cap__'+item"></span>
                                                <span>{{ item | uppercase }}</span>
                                            </div>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field>
                                        <input matInput formControlName="CIUSER" [placeholder]="('kuusercode' | translate) + '*'" class="text-uppercase" />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4 nom">
                                    <mat-form-field>
                                        <input matInput formControlName="CINOM" class="text-uppercase" [placeholder]="('kuname' | translate) + '*'" />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4 nom">
                                    <mat-form-field>
                                        <input matInput formControlName="CIPRENOM" class="text-uppercase" [placeholder]="'kuprenom' | translate" />
                                    </mat-form-field>
                                </div>                                
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field>
                                        <input type="password" matInput formControlName="CIPSW" [placeholder]="('kupassword' | translate) + '*'" />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field class="select_civilite">
                                        <mat-select formControlName="CTUINTGC" disableOptionCentering panelClass="dropdown-panel" [placeholder]="'kuniveaulev' | translate">
                                            <mat-option> 
                                                <ngx-mat-select-search
                                                [placeholderLabel]="'kuallsec' | translate"
                                                [noEntriesFoundLabel]="'kksecuritetrouve' | translate"                                
                                                (ngModelChange)="onSecurityChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterNiveau"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let item of filteredNiveauTypes" [value]="item.NIVINT">{{ item.NIVNOM }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field>
                                        <input matInput formControlName="CIEXTERNE" [placeholder]="'kucodext' | translate" />
                                    </mat-form-field>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>        

        <div class="bloc_container padding_15 bg_light" *ngIf="!isMobile">
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="d-flex h-100 align-items-center">
                        <div class="pl-3">
                            <label>{{'kureinit' | translate}} </label>                            
                            <mat-slide-toggle class="ml-3 mt-2" formControlName="OKREINIT" ></mat-slide-toggle>
                        </div>                        
                    </div>                    
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="d-flex h-100 align-items-center">
                        <div class="pl-3">
                            <label>{{'kupassexpiry' | translate}} </label>  
                            <mat-slide-toggle formControlName="OKEXPIRE"  class="ml-3 mt-2"></mat-slide-toggle>
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="d-flex h-100 align-items-center">
                        <div class="mt-3 row">                            
                            <app-merchandise-de-vente  displayType="component" [prevTreeItems]="treeItems" [userID]="editUserID" (updatedRows)="updatedRows" (newTreeValues)="onMerchandiseUpdate($event)"></app-merchandise-de-vente>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container"  *ngIf="isMobile">
            <mat-accordion>                     
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header class="mb-4">
                        <mat-panel-title>
                            {{'kkdetails' | translate}} 
                        </mat-panel-title>                
                    </mat-expansion-panel-header>
                    
                    <ng-template matExpansionPanelContent>
                        <div class="row no-gutters">
                            <div class="col-12 col-sm-12 col-lg-8">
                                <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3 tiers">
                                        <mat-form-field >
                                            <input matInput [placeholder]="'kuuserno' | translate" [value]="editUserID" disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-3">
                                        <div class="d-flex h-100 align-items-center">
                                            <mat-checkbox formControlName="CIACTIF">{{'kuactiveuser' | translate}}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                        <mat-form-field>
                                            <input matInput formControlName="CIUSER" [placeholder]="('kuusercode' | translate) + '*'" class="text-uppercase" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-9 col-lg-4 nom">
                                        <mat-form-field>
                                            <input matInput formControlName="CINOM" class="text-uppercase" [placeholder]="('kuname' | translate) + '*'" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-9 col-lg-4 nom">
                                        <mat-form-field>
                                            <input matInput formControlName="CIPRENOM" class="text-uppercase" [placeholder]="'kuprenom' | translate" />
                                        </mat-form-field>
                                    </div>                                
                                    <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                        <mat-form-field>
                                            <input type="password" matInput formControlName="CIPSW" [placeholder]="('kupassword' | translate) + '*'" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                        <mat-form-field class="select_civilite">
                                            <mat-select formControlName="CTUINTGC" disableOptionCentering panelClass="dropdown-panel" [placeholder]="'kuniveaulev' | translate">
                                                <mat-option> 
                                                    <ngx-mat-select-search
                                                    [placeholderLabel]="'kuallsec' | translate"
                                                    [noEntriesFoundLabel]="'kksecuritetrouve' | translate"                                
                                                    (ngModelChange)="onSecurityChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterNiveau"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let item of filteredNiveauTypes" [value]="item.NIVINT">{{ item.NIVNOM }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                        <mat-form-field>
                                            <input matInput formControlName="CIEXTERNE" [placeholder]="'kucodext' | translate" />
                                        </mat-form-field>
                                    </div>     
                                    <div class="form-group col-12 col-sm-4 col-md-9 col-lg-4">
                                        <label for="USERLANG">{{ 'klang' | translate }}</label>
                                        <ng-select formControlName="USERLANG" bindLabel="name" appearance="outline" [searchable]="false" [clearable]="false" labelForId="USERLANG">
                                            <ng-option [value]="item" *ngFor="let item of listLang">
                                                <div class="d-flex justify-content-start align-items-center">
                                                    <span class="cap__flag mr-3" [class]="'cap__'+item"></span>
                                                    <span>{{ item | uppercase }}</span>
                                                </div>
                                            </ng-option>
                                        </ng-select>
                                    </div>                                                       
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </ng-template>
                </mat-expansion-panel> 
                <mat-expansion-panel >
                    <mat-expansion-panel-header class="mb-4">
                      <mat-panel-title>
                        {{'kpassword' | translate}}
                      </mat-panel-title>                
                    </mat-expansion-panel-header>
                    
                    <ng-template matExpansionPanelContent>
                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div>
                                            <label>{{'kureinit' | translate}} </label>                            
                                            <mat-slide-toggle class="ml-3 mt-2" formControlName="OKREINIT" ></mat-slide-toggle>
                                        </div>                        
                                    </div>   
                                </div>                 
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div>
                                            <label>{{'kupassexpiry' | translate}} </label>  
                                            <mat-slide-toggle formControlName="OKEXPIRE"  class="ml-3 mt-2"></mat-slide-toggle>
                                        </div>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </ng-template>
                  </mat-expansion-panel>
                  <mat-expansion-panel class="mb-3">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{'kumerch1' | translate}}
                      </mat-panel-title>                
                    </mat-expansion-panel-header>
                    
                    <ng-template matExpansionPanelContent>    
                        <app-merchandise-de-vente  displayType="component" [prevTreeItems]="treeItems" [userID]="editUserID" (updatedRows)="updatedRows" (newTreeValues)="onMerchandiseUpdate($event)"></app-merchandise-de-vente>
                    </ng-template>
                  </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">                
                <div class="col-12 text-center">
                    <button type="submit" *ngIf="!readOnly" class="bt_valid mr-2" mat-button  [title]="'kuvalid' | translate">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kuvalid' | translate}}
                        </span>
                    </button>
                    <button class="bt_valid" mat-button routerLink="/utilisateurs" [title]="'kuback' | translate">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kuback' | translate}}        
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>







