<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
              {{'keditreport' | translate}}
            </h1>
        </div>
    </div>

    <form [formGroup]="reportingForm" (ngSubmit)="reportingFormSubmitter()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">                
                <div class="col-12 col-sm-6" *ngIf="fromDateStat">
                    <mat-form-field floatLabel="always" class="date">
                        <input matInput formControlName="START_DATE" [max]="today" (dateChange)="onDateChanged()" [matDatepicker]="cdeDate"
                            [placeholder]="(fromDateStat && toDateStat?'kperiodeditdu':'kperiodeditau') | translate"
                            class="text-uppercase">
                        <mat-datepicker-toggle matSuffix [for]="cdeDate">
                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #cdeDate [disabled]="dateDisabled"></mat-datepicker>
                        <button mat-icon-button type="button" *ngIf="reportStartDate.value" [disabled]="dateDisabled" (click)="_clearDate('START_DATE')" matSuffix ><mat-icon>close</mat-icon></button>

                        <button matSuffix type="button" *ngIf="currentMenu?.dateFreedom" [matMenuTriggerFor]="menu" mat-icon-button>
                            <mat-icon class="d-flex justify-content-center align-items-center">
                                <span style="line-height: initial;" *ngIf="dateAct === 'equal'">=</span>
                                <span style="line-height: initial;" *ngIf="dateAct === 'greater_equal'">>=</span>
                                <span style="line-height: initial;" *ngIf="dateAct === 'less_equal'"><=</span>
                                <span style="line-height: initial;" *ngIf="dateAct === 'between'"><></span>
                            </mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-menu #menu="matMenu" [hasBackdrop]="false">
                        <button mat-menu-item (click)="changeDateAct('equal')">{{'kbondategale' | translate}}</button>
                        <button mat-menu-item (click)="changeDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                        <button mat-menu-item (click)="changeDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                        <button mat-menu-item (click)="changeDateAct('between')">{{'kbondatentre' | translate}}</button>
                    </mat-menu>
                </div>
                <div class="col-12 col-sm-6" *ngIf="toDateStat || (currentMenu?.dateFreedom && dateAct === 'between')">
                    <mat-form-field floatLabel="always">
                        <input matInput formControlName="END_DATE" [min]="minDateAct" [max]="today" [matDatepicker]="cdeEndDate"
                            [placeholder]="'kau' | translate"
                            class="text-uppercase">
                            
                        <mat-datepicker-toggle matSuffix [for]="cdeEndDate">
                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #cdeEndDate [disabled]="dateDisabled"></mat-datepicker>
                        <button mat-icon-button type="button" *ngIf="reportEndDate.value" [disabled]="dateDisabled" (click)="_clearDate('END_DATE')" matSuffix ><mat-icon>close</mat-icon></button>
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-12" *ngIf="magasinStat">
                    <mat-form-field>
                        <mat-select [placeholder]="'kclshop' | translate" formControlName="MAGASIN" >
                            <mat-option>
                                <ngx-mat-select-search
                                [placeholderLabel]="'klselstore' | translate"
                                [noEntriesFoundLabel]="'klnostore' | translate"                                
                                (ngModelChange)="onSearchChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterMagasin"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option value="">{{ 'kcltouslesmag' | translate }}</mat-option>
                            <mat-option *ngFor="let magasin of filteredMagasins" [value]="magasin.CMINT">
                                {{ magasin?.CMRAISOC }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center height_bloc" >                      
                    <div>
                        <button mat-button type="submit" class="bt_send">
                            <i class="material-icons">done</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'kpvalid' | translate}}
                            </span>
                        </button>
                        <button mat-button [mat-dialog-close] class="bt_send ml-2">
                            <i class="material-icons">close</i>
                            <span class="d-none d-sm-none d-md-block">
                                {{'kpback' | translate}}
                            </span>
                        </button>
                    </div>    
                </div>
            </div>
        </div>
    </form>
</div>