import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EditPostalCodeComponent } from '../edit-postal-code/edit-postal-code.component';
import { PostalCodesService } from 'providers/api/postalCodesService';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-add-postal-code',
  templateUrl: './add-postal-code.component.html',
  styleUrls: ['./add-postal-code.component.scss'],
  providers: [PostalCodesService, DialogsService, NgxSpinnerService]
})
export class AddPostalCodeComponent {

  addPostalCodeForm: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<EditPostalCodeComponent>,
              private formBuilder: UntypedFormBuilder,
              private postalCodeService: PostalCodesService,
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private translateService: TranslateService) {                
      this.addPostalCodeForm = this.formBuilder.group({
        CODPOS: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(5)]],
        VILLE: ['', Validators.required]        
      });
  }

  addPostalCodeFormSubmission(): void {
    this.loadingService.show();

    if(this.addPostalCodeForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }

    const form_data = this.addPostalCodeForm.value;
    this.postalCodeService.addPostalCode(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kpsuccess'));
          this.dialogRef.close(form_data);
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kpcreateerr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )
  }

}
