import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Article } from 'models/articles';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArticleService } from 'providers/api/articleService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { User } from 'models/users';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { formatNumber } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-update-stock-emplacement',
  templateUrl: './update-stock-emplacement.component.html',
  styleUrls: ['./update-stock-emplacement.component.scss'],
  providers: [ArticleService, DialogsService, TranslateService, NgxSpinnerService]
})
export class UpdateStockEmplacementComponent implements OnInit {
  
  @ViewChild('firstFocusElement') firstFocusElement: ElementRef;

  stockEmplacementData: any;
  articleData: Article;

  transferStockForm: any;
  
  originQuantity: number;
  maxQuantity: number;
  affectedQuantity = 0;

  user: User;
  USERCMINT: any;

  listMotifBlocage: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};  
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  definerButtonDisabled: boolean = false;
  suprimerButtonDisabled: boolean = true;

  lastCheckedDate: any;
  allStockLocations: any;
  isMobile: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UpdateStockEmplacementComponent>,
    private formBuilder: UntypedFormBuilder,
    private articleService: ArticleService,
    private userService: UserService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private loadingService: NgxSpinnerService,
    private dialog: MatDialog,
    private deviceDetector: DeviceDetectorService) {      
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.allStockLocations = this.data.all_stock_locations;
    this.stockEmplacementData = this.data.emplacement_data;
    this.articleData = this.data.article_data;      
    this.showLoader = false;    

    this.lastCheckedDate = this.stockEmplacementData.ESDTCHECK?moment(this.stockEmplacementData.ESDTCHECK).format('DD.MM.YYYY'):null;

    if(this.stockEmplacementData.ESPRINC == -1) {
      this.definerButtonDisabled = true;
    }

    this.user = this.userService.getCurrentUser();          
    this.USERCMINT = this.user.CMINT;

    this.transferStockForm = this.formBuilder.group({
      ACTUAL_ALLEE: [{ value: this.stockEmplacementData.EMPATTR1, disabled: true }],
      ACTUAL_COLONNE: [{ value: this.stockEmplacementData.EMPATTR2, disabled: true }],
      ACTUAL_NIVEAU: [{ value: this.stockEmplacementData.EMPATTR3, disabled: true }],
      ACTUAL_QTE: [{ value: formatNumber(this.stockEmplacementData.MVTQTE, 'fr-FR', '0.2'), disabled: true }],
      DEST_ALLEE: ['', Validators.required],
      DEST_COLONNE: ['', Validators.required],
      DEST_NIVEAU: ['', Validators.required],
      DEST_QTE: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    $.fn['dataTable'].ext.search.pop()
  }

  ngAfterViewInit(): void {
      // si on l'enlève, on ne peut pas recharger le tableau en faisant une nouvelle recherche, mais du coupe cela casse le datatable oupfffff....      
      this.dtTrigger.next();

      setTimeout(() => {
        this.firstFocusElement.nativeElement.focus();
        this.firstFocusElement.nativeElement.setAttribute('inputmode', 'decimal');
        this.firstFocusElement.nativeElement.setAttribute('type', 'number');
      }, 100)
  }

  newStockLocationConfirmation(stockLocationData: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: this.translateService.instant('kartqtsupconfirm'),
        description: this.translateService.instant('kartqtcreatenewloc')
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.insertNewStockLocation(stockLocationData);
      }      
    });    
  }

  transferStockLocation() {

    if(this.transferStockForm.invalid) {
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));
      return;
    }
      
    const form_data = this.transferStockForm.getRawValue();    

    if(form_data.DEST_QTE > this.stockEmplacementData.MVTQTE) {
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kartqtinsufqnty'));
      return;
    }

    const existingStockLocation = this.allStockLocations.find((row: any) => {
      return row.EMPATTR1 == form_data.DEST_ALLEE && row.EMPATTR2 == form_data.DEST_COLONNE && row.EMPATTR3 == form_data.DEST_NIVEAU;
    });    

    if(existingStockLocation && existingStockLocation !== null) {
      const transfer_data = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        IDINSTINT: Constants.CIINST,
        IDINSTCAI: Constants.CIINST,
        SourceIDEMP: this.stockEmplacementData.IDEMP,
        DestIDEMP: existingStockLocation.IDEMP,
        SourceQty: this.stockEmplacementData.MVTQTE,
        DestIQty: form_data.DEST_QTE,
        CAINT: this.articleData.CAINT
      };

      this.loadingService.show();
      this.articleService.transferStockLocation(transfer_data).subscribe(
        (res) => {
          this.loadingService.hide();
          if(res.success !== undefined) {
            this.dialogRef.close();
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
        }
      );      
    } else {
      const newStockLocation = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        IDINSTINT: Constants.CIINST,
        IDINSTCAI: Constants.CIINST,
        EMPATTR1: form_data.DEST_ALLEE,
        EMPATTR2: form_data.DEST_COLONNE,
        EMPATTR3: form_data.DEST_NIVEAU,
        MVTQTE: form_data.DEST_QTE,
        CAINT: this.articleData.CAINT,
        CIINT: this.user.CIINT,
        IDEMP: this.stockEmplacementData.IDEMP
      };

      this.newStockLocationConfirmation(newStockLocation);      
    }        
  }

  insertNewStockLocation(stockLocationData: any) {
    this.articleService.addNewStockLocation(stockLocationData).subscribe(
      (res) => {
        this.loadingService.hide();
        if(res.success !== undefined) {
          this.dialogRef.close();
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    );
  }

  updateDefinerDetails() {

    if(this.stockEmplacementData === undefined || this.stockEmplacementData === null || this.stockEmplacementData === '' || this.stockEmplacementData.length === 0) {      
      return;
    }

    this.loadingService.show();    

    const form_data = {
      CAINT: this.articleData.CAINT,
      ESPRINC: '-1',
      IDMAG: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      IDINSTINT: Constants.CIINST,
      IDINSTCAI: Constants.CIINST,
      IDEMP: this.stockEmplacementData.IDEMP
    };    

    this.articleService.updateDefaultStockLocation(form_data).subscribe(
      (res: any) => {
        this.loadingService.hide();

        if(res.success !== undefined) {
          this.stockEmplacementData.ESPRINC = '0';
          this.definerButtonDisabled = true;
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));  
        }
      },
      (err) => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    );
  }

  updateQuantityConfirm() {

    if(this.stockEmplacementData === undefined || this.stockEmplacementData === null || this.stockEmplacementData === '' || this.stockEmplacementData.length === 0) {      
      return;
    }

    this.loadingService.show();    

    const form_data = {
      CAINT: this.articleData.CAINT,
      IDMAG: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      IDINSTINT: Constants.CIINST,
      IDINSTCAI: Constants.CIINST,
      IDEMP: this.stockEmplacementData.IDEMP
    };        

    this.articleService.updateQtyConfirmStockLocation(form_data).subscribe(
      (res: any) => {
        this.loadingService.hide();

        if(res.success !== undefined) {
            this.lastCheckedDate = res.data?moment(res.data).format('DD.MM.YYYY'):null;
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));  
        }
      },
      (err) => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    );
  }
  
  deleteStockLocation() {
    if(this.stockEmplacementData === undefined || this.stockEmplacementData === null || this.stockEmplacementData === '' || this.stockEmplacementData.length === 0) {      
      return;
    }

    this.loadingService.show();    

    const form_data = {
      CAINT: this.articleData.CAINT,
      IDMAG: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      IDINSTINT: Constants.CIINST,
      IDINSTCAI: Constants.CIINST,
      IDEMP: this.stockEmplacementData.IDEMP
    };        

    this.articleService.deleteStockLocation(form_data).subscribe(
      (res: any) => {
        this.loadingService.hide();

        if(res.success !== undefined) {
            this.dialogRef.close();
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));  
        }
      },
      (err) => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    );
  }
}
