<div class="page-container">
    <div class="title row" *ngIf="displayType !== 'tab'">
        <div class="col-1 col-sm-2 col-md-2 col-lg-3">
            <mat-icon class="pointer" (click)="returnBack()" [title]="'kuback' | translate">
                keyboard_arrow_left
            </mat-icon>
        </div>
        <div class="col-10 col-sm-8 col-md-8 col-lg-6 text-center">
            <h1 class="text-center">
                {{ client.CIVLIBC }} {{ client.TPRENOM }} {{ client.TRAISOC }}
            </h1>
        </div>
        <div class="col-1 col-sm-2 col-md-2 col-lg-3 no-padding" *ngIf="isMobile">
            <mat-icon>assignment_ind</mat-icon>
        </div>  
    </div>

    <form [formGroup]="createForm" (ngSubmit)="createFormSubmitter()">

        <div class="bloc_container bg_light pt-3"  [class.padding_15]="!isMobile" [class.w-100]="displayType === 'tab'">
            <div class="row">
                <div class="col-12 col-md-12" [class.no-padding]="isMobile">
                    <div class="row">
                        <div class="col-12 col-md-4 col-lg-2 tiers">
                            <mat-form-field >
                                <input matInput name="IDTIERS" formControlName="IDTIERS" [placeholder]="('kcltiers' | translate) + '*'">
                            </mat-form-field>
                        </div>

                        <div class="col-12 col-md-3 col-lg-2">
                            <p class="text-left">
                                <span>{{'kclshop' | translate}}</span><br>
                                <strong>{{ user.CMRAISOC }}</strong>
                            </p>
                        </div>
                        <div class="col-12 col-md-3 col-lg-4">
                            <div class="d-flex flex-column m-0 mb-3 p-0 text-left">
                                <label for="TLANG" class="client-lang-label">{{ 'klang' | translate }}</label>
                                <ng-select formControlName="TLANG" bindLabel="name" appearance="outline" [searchable]="false" [clearable]="false" labelForId="TLANG">
                                    <ng-option value="">{{ 'kclnondefi' | translate }}</ng-option>
                                    <ng-option [value]="item" *ngFor="let item of listLang">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <span class="cap__flag mr-3" [class]="'cap__'+item"></span>
                                            <span>{{ item | uppercase }}</span>
                                        </div>
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-lg-8" [class.no-padding]="isMobile">
                    <div class="row mb-4">
                        <div class="col-12 col-sm-2 col-md-3 col-lg-2">
                            <mat-form-field floatLabel="always" class="select_civilite">
                                <mat-select [placeholder]="'kclcivil' | translate" formControlName="IDCIV">
                                    <mat-option *ngFor="let civ_item of civilite" [value]="civ_item.IDCIV">
                                        {{ civ_item.CIVLIBC }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-4 col-md-9 col-lg-4 nom">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="TRAISOC" [placeholder]="('kclnom' | translate) + '*'">
                                <mat-error *ngIf="createForm.get('TRAISOC').touched && createForm.get('TRAISOC').hasError('pattern')">{{'kclformatinvalid' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6 prenom">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="TPRENOM" [placeholder]="'kclfirstnom' | translate">
                                <mat-error *ngIf="createForm.get('TPRENOM').touched && createForm.get('TPRENOM').hasError('pattern')">{{'kclformatinvalid' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-sm-6 col-lg-6 adresse">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="TADR1" [placeholder]="'kcladddet' | translate">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-6 adresse">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="TADR2" [placeholder]="'kclentre' | translate">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-sm-6 col-lg-6 adresse">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="TADR3" [placeholder]="('kclnlib' | translate) + '*'">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-6 adresse">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="TADR4" [placeholder]="'kcllieu' | translate">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-sm-6 col-lg-6 adresse">
                            <mat-form-field floatLabel="always">
                                <input matInput inputmode="numeric" formControlName="CODPOS" (keyup)="onCodePostalChanged()" (focus)="onCodePostalFocus()" [placeholder]="('kboncodeopostal' | translate) + '*'" maxlength="5" [matAutocomplete]="code_auto" />

                                <button type="button" mat-icon-button matSuffix (click)="resetCodPos()" *ngIf="codposInputField.value" tabindex="-1">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-autocomplete autoActiveFirstOption (optionSelected)="onAutoOptionSelected($event)" #code_auto="matAutocomplete">                                            
                                    <mat-option *ngFor="let option of filteredPostalCodes | async" [value]="option.TROWID">
                                        {{ option.VILLE }}, {{ option.CODPOS }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>                                    
                        </div>
                        <div class="col-12 col-sm-6 col-lg-6 adresse">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="TVILLE" (keyup)="onVilleChanged()" (focus)="onVilleFocus()" [placeholder]="('kclcity' | translate) + '*'" [matAutocomplete]="ville_auto" />
                                <mat-error *ngIf="createForm.get('TVILLE').touched && createForm.get('TVILLE').hasError('pattern')">{{'kclformatinvalid' | translate}}</mat-error>

                                <button type="button" mat-icon-button matSuffix (click)="resetVille()" *ngIf="villeInputField.value" tabindex="-1">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-autocomplete autoActiveFirstOption (optionSelected)="onAutoOptionSelected($event, 'ville')" #ville_auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredVilles | async" [value]="option.TROWID">
                                        {{ option.VILLE }}, {{ option.CODPOS }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-lg-6">
                            <mat-form-field floatLabel="always">
                                <mat-select [placeholder]="'kclpays' | translate" formControlName="CODPAYS">                                    
                                    <mat-option value="">{{'kclnondefi' | translate}}</mat-option>
                                    <mat-option *ngFor="let pay of listPays" [value]="pay.CODPAYS">
                                        {{ pay.PAYSNOM }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-6">
                            <mat-form-field floatLabel="always" class="date">
                                <input matInput [max]="today" [matDatepicker]="birthdayDate" formControlName="TDTNAIS" [placeholder]="'kcldatedenai' | translate" >
                                <mat-datepicker-toggle matSuffix [for]="birthdayDate">
                                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #birthdayDate [disabled]="loyalCustomer"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-lg-4" [class.no-padding]="isMobile">
                    <div class="row mb-4">
                        <div class="col-12 col-sm-12 col-lg-12 mt-4 mt-md-0 ">
                            <mat-form-field floatLabel="always" [class.mat-form-field-invalid]="portInputField.errors">
                                <mat-label>{{ 'kclport' | translate }} *</mat-label>
                                <ngx-intl-tel-input
                                    [cssClass]="'mat-input-element'"
                                    [enableSearch]="true"
                                    [enableAutoCountrySelect]="false"
                                    [enablePlaceholder]="true"
                                    [customPlaceholder]="replaceXplaceholder(TPORT?.selectedCountry?.placeHolder)"
                                    [searchCountryPlaceholder]="'kclsearc' | translate" 
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="selectedPortCountry"
                                    [maxLength]="15"
                                    [phoneValidation]="false" 
                                    [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.National"
                                    name="TPORT" 
                                    formControlName="TPORT"
                                    [inputId]="'TPORT'"
                                    (countryChange)="portIntlCountryChange($event)"
                                    (keyup)="setTelOrPORTValidation()"
                                    #TPORT
                                    >
                                </ngx-intl-tel-input>

                                <input type="text" matInput class="d-none">
                                <mat-hint>e.g. {{ replaceXplaceholder(TPORT?.selectedCountry?.placeHolder) }}</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-sm-12 col-lg-12 text-left">
                            <mat-checkbox formControlName="TOKSMS" class="mat-checkbox mr-4">
                                {{'koptin_sms' | translate}}
                            </mat-checkbox>

                            <mat-checkbox formControlName="TOKAPP1" class="mat-checkbox">
                                {{'koptin_whatsapp' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-sm-6 col-lg-12 opt-in">
                            <mat-form-field floatLabel="always" [class.mat-form-field-invalid]="teleInputField.errors">
                                <mat-label>{{ 'kcltele' | translate }} * {{ 'ktelport' | translate }}</mat-label>
                                <ngx-intl-tel-input
                                    [cssClass]="'mat-input-element'"
                                    [enableSearch]="true"
                                    [enableAutoCountrySelect]="false"
                                    [enablePlaceholder]="true"
                                    [customPlaceholder]="replaceXplaceholder(TTEL?.selectedCountry?.placeHolder)"
                                    [searchCountryPlaceholder]="'kclsearc' | translate"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="selectedTeleCountry"
                                    [maxLength]="15"
                                    [phoneValidation]="false" 
                                    [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.National"
                                    name="TTEL" 
                                    formControlName="TTEL"
                                    [inputId]="'TTEL'"
                                    (countryChange)="teleIntlCountryChange($event)"
                                    (keyup)="setTelOrPORTValidation()"
                                    #TTEL
                                    >
                                </ngx-intl-tel-input>
                            
                                <input type="text" matInput class="d-none">
                                <mat-hint>e.g. {{ replaceXplaceholder(TTEL?.selectedCountry?.placeHolder) }}</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-sm-5 col-lg-5">
                            <mat-form-field floatLabel="always">
                                <input matInput type="tel" formControlName="TFAX" [placeholder]="'kclfax' | translate" >
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-7 col-lg-7 email">
                            <div class="row">
                                <mat-form-field floatLabel="always" [class.mat-form-field-invalid]="emailInputField.errors">
                                    <input matInput type="email" formControlName="TEMAIL" [placeholder]="'kclemail' | translate" (change)="onEmailChange($event)">
                                    <mat-error *ngIf="createForm.get('TEMAIL').touched && createForm.get('TEMAIL').hasError('pattern')">{{'kclformatinvalid' | translate}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-checkbox formControlName="TOKMAIL" class="mat-checkbox">
                                    {{'koptin_email' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>                           
                </div>
            </div>
        </div>

        <div class="bloc_container" [class.w-100]="displayType === 'tab'">
            <div class="row bg_white">
                <div class="col-6 text-right">
                    <button type="submit" class="bt_valid" mat-button [title]="'kclvalidate' | translate">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kclvalidate' | translate}}
                        </span>                        
                    </button>
                </div>
                <div class="col-6 text-left">
                    <button type="button" class="bt_valid" mat-button (click)="triggerBack()" [title]="'kclretour' | translate">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kclretour' | translate}}
                        </span>                        
                    </button>
                </div>                
            </div>
        </div>
    </form>
</div>