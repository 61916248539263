import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from './api/userService';
import { UtilService } from './api/utilService';
import { StorageService } from './api/storageService';

@Pipe({
  name: 'cap_currency',  
})
export class CapCurrencyPipe implements PipeTransform {

  constructor(private userService: UserService,
            private utilService: UtilService,
            private storageService: StorageService) { }

  transform(value: number, type?: false | 'symbol' | 'label', prefix?: boolean) {
      const currencySymbol = this.storageService.getItem('CAP_CURRENCY_SYMBOL'); // VSTR2
      const currencyString = this.storageService.getItem('CAP_CURRENCY_TEXT'); // VSTR3

      let locale = 'fr-FR';
      if(this.utilService.getCurrentDecimalSymbol === ',') {
        locale = 'fr-FR';
      } else {
        locale = 'en-US';
      }

    if(type !== undefined && type !== null) {
        if(type === 'symbol') {
            if(prefix !== undefined && prefix !== true) {
                return formatNumber(value, locale, '0.2')+' '+currencySymbol;
            } else {
                return currencySymbol+' '+formatNumber(value, locale, '0.2');
            }
        } else if(type === false) {
            if(prefix !== undefined && prefix !== true) {
                return formatNumber(value, locale, '0.2');
            } else {
                return formatNumber(value, locale, '0.2');
            }
        } else {
            if(prefix !== undefined && prefix !== true) {
                return formatNumber(value, locale, '0.2')+' '+currencyString;
            } else {
                return currencyString+' '+formatNumber(value, locale, '0.2');
            }
        }
    } else {
        if(prefix !== undefined && prefix !== true) {
            return formatNumber(value, locale, '0.2')+' '+currencySymbol;
        } else {
            return currencySymbol+' '+formatNumber(value, locale, '0.2');
        }            
    }    
  }
}