import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import { ModifyArticleDetailComponent } from './modify-article-detail/modify-article-detail.component';
import * as moment from 'moment';
import { Article } from 'models/articles';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { ModifyLineItemChoixComponent } from 'pages/bon-de-commande/modify-line-item-choix/modify-line-item-choix.component';
import { Router } from '@angular/router';
import { ActionReserveComponent } from 'pages/bon-de-commande/action-reserve/action-reserve.component';
import { DateTimeComponent } from '../date-time/date-time.component';
import { Constants } from 'providers/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'models/users';
import { UserService } from 'providers/api/userService';
import { BonVenteService } from 'providers/api/bonventeService';
import { BonVente } from 'models/bonventes';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { UtilService } from 'providers/api/utilService';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-info-complementaries',
  templateUrl: './info-complementaries.component.html',
  styleUrls: ['./info-complementaries.component.scss']
})
export class InfoComplementariesComponent implements OnInit {

  CAINT: string;

  private _article: Article;   
  get article(): Article { return this._article }
  @Input() set article(article: Article) {        
    this.articleChange.emit(this._article = article)

    this.CAINT = this.article?this.article.CAINT:undefined;
    if(this.CAINT) {
      this.article_data = this.article;
    }
  }
  @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();

  private _activeItemNumber: number;
  get activeItemNumber(): number { return this._activeItemNumber }
  @Input() set activeItemNumber(itemNumber: number) {
    this.activeItemNumberChange.emit(this._activeItemNumber = itemNumber);

    if(this.panier !== undefined && this.panier !== null) {
      this.currentArticle = this.panier.articles[itemNumber];

      this.wefoxDate = this.currentArticle.dtlib3;
      this.wefoxStrLib2 = this.currentArticle.strlib2;
      this.wefoxStrLib3 = this.currentArticle.strlib3;
      this.wefoxClientDOB = this.currentArticle.dtlib4;

      this.createdDate = moment(this.panier.dtsaisie).utcOffset(this.panier.dtsaisie).format('DD.MM.YYYY HH:mm');
      this.modifiedDate = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('DD.MM.YYYY HH:mm'); 

      this.articleModifiedDate = (this.currentArticle !== undefined && this.currentArticle.dtmaj !== undefined && this.currentArticle.dtmaj !== null)?moment(this.currentArticle.dtmaj).utcOffset(this.currentArticle.dtmaj).format('DD.MM.YYYY HH:mm'):'';      
      
      this.itemPromesseDate = (this.currentArticle !== undefined && this.currentArticle.dtpromessecalc !== undefined && this.currentArticle.dtpromessecalc !== null && moment(this.currentArticle.dtpromessecalc).year() != 1899)?moment(this.currentArticle.dtpromessecalc).utcOffset(this.currentArticle.dtpromessecalc).format('DD.MM.YYYY'):'';

      this.articleModifiedUserName = (this.currentArticle !== undefined)?this.currentArticle.libuser:'';
    }
  }
  @Output() private activeItemNumberChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() userName: string;
  @Input() modifiedUserName?: string;
  @Input() bonNumBon: string;
  @Input() flowType: string;
  @Input() readOnly: boolean = false;

  private _panier: BonPanier;
  get panier(): BonPanier { return this._panier }
  @Input() set panier(panier: BonPanier) {
    this.panierChange.emit(this._panier = panier);
    
    if(panier !== undefined && panier !== null) {
      const numBon = this.storageService.getItem('previousNumBon');    
      if(numBon !== undefined && numBon != null) {
        this.bonNumBon = numBon;
      }

      this.createdDate = moment(panier.dtsaisie).utcOffset(panier.dtsaisie).format('DD.MM.YYYY HH:mm');
      this.modifiedDate = moment(panier.dtmaj).utcOffset(panier.dtmaj).format('DD.MM.YYYY HH:mm');

      this.userName = panier.libuser;
      this.modifiedUserName = panier.libusermaj ? panier.libusermaj : panier.libuser;

      this.currentArticle = panier.articles[this.activeItemNumber];

      if (this.currentArticle != undefined && this.currentArticle != null) {
        this.wefoxDate = this.currentArticle.dtlib3;
        this.wefoxStrLib2 = this.currentArticle.strlib2;
        this.wefoxStrLib3 = this.currentArticle.strlib3;
        this.wefoxClientDOB = this.currentArticle.dtlib4;

        this.articleModifiedDate = (this.currentArticle !== undefined && this.currentArticle.dtmaj !== undefined && this.currentArticle.dtmaj !== null)?moment(this.currentArticle.dtmaj).utcOffset(this.currentArticle.dtmaj).format('DD.MM.YYYY HH:mm'):'';

        this.itemPromesseDate = (this.currentArticle !== undefined && this.currentArticle.dtpromessecalc !== undefined && this.currentArticle.dtpromessecalc !== null && moment(this.currentArticle.dtpromessecalc).year() != 1899)?moment(this.currentArticle.dtpromessecalc).utcOffset(this.currentArticle.dtpromessecalc).format('DD.MM.YYYY'):'';

        this.articleModifiedUserName = (this.currentArticle !== undefined)?this.currentArticle.libuser:'';
      }
    } else {
      this.createdDate = undefined;
      this.modifiedDate = undefined; 
    }    
  }
  @Output() private panierChange: EventEmitter<BonPanier> = new EventEmitter<BonPanier>();

  @Input() private flowCMINT: any;
  @Input() public wefoxAvail: boolean = false;

  isMobile: boolean;
  createdDate: string;
  modifiedDate: string;

  currentArticle: PanierArticle;
  article_data: Article;

  articleModifiedUserName: string = '';
  articleModifiedDate: string = '';

  itemPromesseDate: string = '';

  wefoxDate: string;
  wefoxStrLib3: string;
  wefoxStrLib2: string;
  wefoxClientDOB?: string;

  user: User;
  USERCMINT: any;

  constructor(private deviceDetector: DeviceDetectorService,
    private dialogService: DialogsService,
    private translateService: TranslateService,
    private userService: UserService,
    private utilService: UtilService,
    private loadingService: NgxSpinnerService,
    private bonVenteService: BonVenteService,
    private bonCommandeService: BonCommandeService,
    private router: Router,
    private dialog: MatDialog,
    private storageService: StorageService) { }

  ngOnInit(): void {    
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
  }

  openUpArticleDetails() {
    if(this.flowType === 'bonde_commande') {
      if(this.currentArticle.typecde === 'E2/ED' || this.currentArticle.typecde === 'E2/BL') {
        this.editBonCommandeItem(this.currentArticle);
      } else {
        this.editBonCommandeItemRestricted(this.currentArticle);
      }
    } else if(this.flowType === 'retrait_marchandise') {
      this.openUpActionReserve(this.currentArticle);
    } else {
      this.editBonCommandeItem(this.currentArticle);
    }    
  }

  openUpDateLib3Popup() {
    const dialogRef = this.dialog.open(DateTimeComponent,
      {
        data: {
          CMINT: this.flowCMINT,
          panier: this.panier,
          currentArticle: this.currentArticle,
          popupType: 'edit',
          flowType: this.flowType
        }
      }
    );

    dialogRef.afterClosed().subscribe(res => {
      if(res !== undefined && res !== null) {
        const data = res.data;
        this.wefoxDate = this.utilService.formatDateToWS(data.dtlib3);
        this.wefoxStrLib2 = data.strlib2 || '';
        this.wefoxStrLib3 = data.strlib3 || '';
        this.wefoxClientDOB = data.dtlib4 || '';

        if(this.flowType === 'bonde_commande' || this.flowType == 'retrait_marchandise') {
          this.updateBondeCommande('wefox');
        } else if(this.flowType === 'bonde_vente') {
          this.updateBondeVente('wefox');
        }
      }
    });
  }

  dateFormatter(date: string, offset = true, today_time = true) {
    if(date === undefined || date  === null) {
      return '';
    }
    
    const given_date = moment(date).format('DD.MM.YYYY HH:mm');

    if(given_date === undefined || moment(date).year() === 1899) {
      return '';
    }

    if(offset) {
      return moment(date).utcOffset(date).format('DD.MM.YY HH:mm');
    }
    return moment(date).format('DD.MM.YY HH:mm');
  }

  updateBondeVente(type: 'wefox') {
    if(this.panier === undefined || this.panier === null) {
      return;
    }    
    
    this.loadingService.show();    

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const articles = [...this.panier.articles];

    if(type === 'wefox') {
      articles.map(r => {
        if(r.idlig == this.currentArticle.idlig) {
          r.dtlib3 = this.wefoxDate;
          r.strlib2 = this.wefoxStrLib2;
          r.strlib3 = this.wefoxStrLib3;
          r.dtlib4 = this.wefoxClientDOB;
        }
        return r;
      })
    }

    let bondeVenteData: BonPanier = {
      ...this.panier,
      articles,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData);    
  }

  editBonDeVente(bondeVenteData: any) {
    this.bonVenteService.modifyBonvente(bondeVenteData).subscribe(
      (resp) => {
        this.loadingService.hide();        

        if(resp.statusCode == 200) {
          if(resp.data.ErrorCode == 0) {
            this.panier = resp.data.Panier;
            this.bonNumBon = resp.data.NumBon;

            this.storageService.setItem('previousBonPanier', this.panier);
            this.storageService.setItem('previousNumBon', resp.data.NumBon);
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    ); 
  }

  updateBondeCommande(type: 'wefox') {
    if(this.panier === undefined || this.panier === null) {
      return;
    }    
    
    this.loadingService.show();    

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const articles = [...this.panier.articles];

    if(type === 'wefox') {
      articles.map(r => {
        if(r.idlig == this.currentArticle.idlig) {
          r.dtlib3 = this.wefoxDate;
          r.strlib2 = this.wefoxStrLib2;
          r.strlib3 = this.wefoxStrLib3;
          r.dtlib4 = this.wefoxClientDOB;
        }
        return r;
      })
    }

    let panierData: BonPanier = {
      ...this.panier,
      articles,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    let bondeCommandeData = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pPanier: panierData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };

    this.editBonDeCommande(bondeCommandeData);    
  }

  editBonDeCommande(bonCommandeData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyBonCommandeFull(bonCommandeData).subscribe(
        (resp) => {
          this.loadingService.hide();
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.bonNumBon = resp.data.NumBon;
              this.panier = resp.data.Panier;
              
              resolve({ response: resp });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              reject({ response: resp });
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            resolve({ response: resp });
          }
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    });     
  }

  async openUpActionReserve(item: PanierArticle) {
    // if(this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21']) {
    //   await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
    //   const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '21', this.user.CIINT, this.bonNumBon); 

    //   if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
    //     return;
    //   }
    // }

    const dialogRef = this.dialog.open(ActionReserveComponent, {
      width: '40vw',
      maxWidth: '40vw',
      autoFocus: false,
      data: {
        panier: this.panier,
        bonNumBon: this.bonNumBon,
        panier_article: item,
        article_data: this.article_data,
        CMINT: this.flowCMINT
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // For now, No rules to handle here
      }
    });    
  }

  editBonCommandeItem(item: PanierArticle) {    
    
    const dialogRef = this.dialog.open(ModifyArticleDetailComponent, {
      width: '40vw',
      maxWidth: '40vw',  
      height: this.isMobile?'100%':'90vh',
      autoFocus: false,
      data: {
        panier: this.panier,
        bonNumBon: this.bonNumBon,
        panier_article: this.currentArticle,
        article_data: this.article_data,
        flowType: this.flowType,
        CMINT: this.flowCMINT
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(result.status === 'success') {
          this.bonNumBon = result.numBon;
          this.panier = result.panier;          
          this.panierChange.next(this.panier);
        } else if(result.status === 'removed') {
          this.router.navigate(['/bon-de-commande']);
        }
      }
    });    
  }

  editBonCommandeItemRestricted(item: PanierArticle) {
    const dialogRef = this.dialog.open(ModifyLineItemChoixComponent, {
      width: '40vw',
      maxWidth: '40vw',  
      height: this.isMobile?'100%':undefined,
      data: {
        panier: this.panier,
        bonNumBon: this.bonNumBon,
        panier_article: this.currentArticle,
        article_data: this.article_data,
        CMINT: this.flowCMINT
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(result.status === 'success') {
          this.bonNumBon = result.numBon;
          this.panier = result.panier;

          this.panierChange.next(this.panier);
        } else if(result.status === 'removed') {
          this.router.navigate(['/bon-de-commande']);
        }
      }
    });
  }

  formatClientDOB() {
    if (this.wefoxClientDOB) {
      if (moment(this.wefoxClientDOB).year() === 1899) {
        return '';
      }
      return moment(this.wefoxClientDOB).format('DD.MM.YYYY')
    }
    return '';
  }

}
