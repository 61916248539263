<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{ pageTitle | translate }}
            </h1>
        </div>
        <div class="col-12" *ngIf="!showLoader && isMobile">
            <button type="button" mat-raised-button class="bt_code mb-2" (click)="openColorCode()" >
                <div class="text-medium">{{'kdeviscodecol' | translate}}</div>
            </button>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile && filterValues">
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>

    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium">{{'kclclearform' | translate}}</div>
                </button>
            </div>
        </div>
    </div>

    <form #f="ngForm" [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">    
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="NUMBON"
                                    [placeholder]="'kbonnbon' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="NOM"
                                    [placeholder]="'kpname' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="PRENOM"
                                    [placeholder]="'kuprenom' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div class="row no-gutters">
                                <div class="col-md-4">
                                    <mat-form-field>
                                        <input matInput formControlName="CODPOS"
                                            [placeholder]="'kboncodeopostal' | translate"
                                            class="text-uppercase">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-8 pl-3">
                                    <mat-form-field>
                                        <input matInput formControlName="VILLE"
                                            [placeholder]="'kclcity' | translate"
                                            class="text-uppercase">
                                    </mat-form-field>
                                </div>
                            </div>                            
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="TEL_PORT"
                                    [placeholder]="'kcltel' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>                      
                    </div>
                </div>
                <div class="col-4">
                    <div class="row"> 
                        <div class="col-12">
                            <div class="row no-gutters">
                                <div class="col-6">
                                    <mat-form-field>
                                        <input matInput formControlName="TOTAL_ENTRE_FROM"
                                            [placeholder]="'kbontotbetn' | translate"
                                            class="text-uppercase">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 pl-4">
                                    <mat-form-field>
                                        <input matInput formControlName="TOTAL_ENTRE_TO"
                                            [placeholder]="'kbonand' | translate"
                                            class="text-uppercase">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="NUM_EXT"
                                    [placeholder]="'kbonnext' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <div class="row no-gutters">
                                <div [class]="dateAct === 'between'?'col-6':'col-12'">
                                    <mat-form-field floatLabel="always" class="date">
                                        <input matInput formControlName="DATE" (dateChange)="onDateChanged('create')" [matDatepicker]="cdeDate"
                                            [placeholder]="'kbondate' | translate"
                                            class="text-uppercase">
                                        <mat-datepicker-toggle matSuffix [for]="cdeDate">
                                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #cdeDate [disabled]="false"></mat-datepicker>
                                        <button mat-icon-button type="button" *ngIf="searchStartDate.value" (click)="_clearDate('DATE')" matSuffix ><mat-icon>close</mat-icon></button>
                                        <button matSuffix type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                                            <mat-icon class="d-flex justify-content-center align-items-center">
                                                <span style="line-height: initial;" *ngIf="dateAct === 'equal'">=</span>
                                                <span style="line-height: initial;" *ngIf="dateAct === 'greater_equal'">>=</span>
                                                <span style="line-height: initial;" *ngIf="dateAct === 'less_equal'"><=</span>
                                                <span style="line-height: initial;" *ngIf="dateAct === 'between'"><></span>
                                            </mat-icon>
                                        </button>                                
                                    </mat-form-field>
                                    <mat-menu #menu="matMenu" [hasBackdrop]="false">
                                        <button mat-menu-item (click)="changeDateAct('equal')">{{'kbondategale' | translate}}</button>
                                        <button mat-menu-item (click)="changeDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                                        <button mat-menu-item (click)="changeDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                                        <button mat-menu-item (click)="changeDateAct('between')">{{'kbondatentre' | translate}}</button>
                                    </mat-menu>
                                </div>
                                <div class="col-6 pl-3" *ngIf="dateAct === 'between'">
                                    <mat-form-field floatLabel="always">
                                        <input matInput formControlName="DATE_END" [min]="minDateAct" [matDatepicker]="cdeEndDate"
                                            [placeholder]="'kbonand' | translate"
                                            class="text-uppercase">
                                            
                                        <mat-datepicker-toggle matSuffix [for]="cdeEndDate">
                                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #cdeEndDate [disabled]="false"></mat-datepicker>
                                        <button mat-icon-button type="button" *ngIf="searchEndDate.value" (click)="_clearDate('DATE_END')" matSuffix ><mat-icon>close</mat-icon></button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row no-gutters">
                                <div [class]="echDateAct === 'between'?'col-6':'col-12'">
                                    <mat-form-field floatLabel="always" class="date">
                                        <input matInput formControlName="DEADLINE" (dateChange)="onDateChanged('deadline')" [matDatepicker]="echDate"
                                            [placeholder]="'kbondeadline' | translate"
                                            class="text-uppercase">
                                        <mat-datepicker-toggle matSuffix [for]="echDate">
                                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #echDate [disabled]="false"></mat-datepicker>
                                        <button mat-icon-button type="button" *ngIf="searchStartDeadlineDate.value" (click)="_clearDate('DEADLINE')" matSuffix ><mat-icon>close</mat-icon></button>

                                        <button matSuffix type="button" [matMenuTriggerFor]="echMenu" mat-icon-button>
                                            <mat-icon class="d-flex justify-content-center align-items-center">
                                                <span style="line-height: initial;" *ngIf="echDateAct === 'equal'">=</span>
                                                <span style="line-height: initial;" *ngIf="echDateAct === 'greater_equal'">>=</span>
                                                <span style="line-height: initial;" *ngIf="echDateAct === 'less_equal'"><=</span>
                                                <span style="line-height: initial;" *ngIf="echDateAct === 'between'"><></span>
                                            </mat-icon>
                                        </button>                                
                                    </mat-form-field>
                                    <mat-menu #echMenu="matMenu" [hasBackdrop]="false">
                                        <button mat-menu-item (click)="changeEchDateAct('equal')">{{'kbondategale' | translate}}</button>
                                        <button mat-menu-item (click)="changeEchDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                                        <button mat-menu-item (click)="changeEchDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                                        <button mat-menu-item (click)="changeEchDateAct('between')">{{'kbondatentre' | translate}}</button>
                                    </mat-menu>
                                </div>
                                <div class="col-6 pl-3" *ngIf="echDateAct === 'between'">
                                    <mat-form-field floatLabel="always">
                                        <input matInput formControlName="DEADLINE_END" [min]="minEchDateAct" [matDatepicker]="echEndDate"
                                            [placeholder]="'kbonand' | translate"
                                            class="text-uppercase">
                                            
                                        <mat-datepicker-toggle matSuffix [for]="echEndDate">
                                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #echEndDate [disabled]="false"></mat-datepicker>
                                        <button mat-icon-button type="button" *ngIf="searchEndDeadlineDate.value" (click)="_clearDate('DEADLINE_END')" matSuffix ><mat-icon>close</mat-icon></button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="ARTICLE" (keydown.enter)="onCodeEntered($event)"
                                    [placeholder]="'kartarttit' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>                        
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-12" *ngIf="pageType !== 'historique'">
                            <mat-form-field>
                                <mat-select [placeholder]="'kartstate' | translate" formControlName="ETAT" >
                                    <mat-option value="">{{'kutousetats' | translate}}</mat-option>
                                    <mat-option *ngFor="let etat of etatList" [value]="etat.id">
                                        {{ etat.label | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select [placeholder]="'kbonsecteur' | translate" formControlName="SECTEUR" >
                                    <mat-option value="">{{'kutoussecteur' | translate}}</mat-option>
                                    <mat-option *ngFor="let magStruct of magasinStructure" [value]="magStruct.CSTNIV1">
                                        {{ magStruct.CSTNIV1+ ' ' + magStruct.CSTLIB1 }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="pageType !== 'historique'">
                            <mat-form-field> 
                                <mat-select [placeholder]="'kbonautrechoice' | translate" formControlName="AUTRE_CHOIX" >                                    
                                    
                                    <mat-option *ngFor="let autreChoix of autreChoixList" [value]="autreChoix.id">
                                        {{ autreChoix.label | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select [placeholder]="'kboncanal' | translate" formControlName="CANAL" >
                                    <mat-option value="">{{'kutouscanal' | translate}}</mat-option>
                                    <mat-option *ngFor="let canal of canalListData" [value]="canal.IDCANAL">
                                        {{ canal.CANALLIB | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> 
                        <div class="col-12 mt-3 mb-3" *ngIf="pageType === 'historique'">
                            <div class="radio-container">
                                <mat-label>{{ "kbontype" | translate }}</mat-label>
                                <mat-radio-group formControlName="TYPECDE" aria-label="Select an option">
                                    <mat-radio-button *ngFor="let item of typeCdeList" [value]="item.id"><span [translate]="item.label" class="d-block"></span></mat-radio-button>
                                </mat-radio-group>
                            </div>                            
                        </div>
                        <div class="col-12 mt-3">
                            <button type="button" mat-raised-button class="bt_code" (click)="openColorCode()" >
                                <div class="text-medium">{{'kdeviscodecol' | translate}}</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button type="submit" class="bt_valid" mat-button>
                        <mat-icon>search</mat-icon>
                        {{ 'kpsearch' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
  
    <div class="bloc_container">
        <div class="row">            
            <div class="col-12 no-padding table-scroller">
                <div>
                    <mat-paginator *ngIf="listBonDeCommande?.length != 0 && !showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

                <table *ngIf="!isMobile" datatable class="row-border hover bonCommande" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead>
                        <tr>
                            <th class="nom" nowrap *ngIf="pageType === 'retrait-marchandise'">{{ 'Prépa.' | translate }}</th>
                            <th *ngFor="let col of bonCommandeListColumns" [width]="col.width" [attr.nowrap]="col.nowrap ? true : null">
                                <ng-container *ngIf="col.value === 'kbondeadline'">
                                    {{ (positionnement === 'Client prévenu'?'kbonalert':'kbondeadline') | translate }}
                                </ng-container>

                                <ng-container *ngIf="col.value !== 'kbondeadline'">
                                    {{ col.name | translate }}
                                </ng-container>
                            </th>
                            <th class="port"></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listBonDeCommande?.length != 0 && !showLoader">
                        <tr *ngFor="let bonCommande of listBonDeCommande">
                            <td class="prenom" nowrap *ngIf="pageType === 'retrait-marchandise'">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="prepa_radio">
                                        <mat-slide-toggle [(ngModel)]="bonCommande.preparation" (ngModelChange)="onPrepaClick(bonCommande)" [title]="'kprepa' | translate" [disabled]="!prepaCommandeEnabled"></mat-slide-toggle>
                                    </div>
                                </div>
                            </td>
                            <td *ngFor="let col of bonCommandeListColumns" (click)="redirectToModify(bonCommande)"
                                [attr.nowrap]="(col.nowrap && col.value !== 'gsm') ? true : null"
                            >
                                <ng-container *ngIf="col.value === 'numbon'">
                                    <span class="colorcode" [class]="defineRowColor(bonCommande)"></span> {{ bonCommande.numbon | slice:0:2 }} {{ bonCommande.numbon | slice:2:4 }} {{ bonCommande.numbon | slice:4:7 }} {{ bonCommande.numbon | slice:7:12 }} {{ bonCommande.numbon | slice:12:15 }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'civlib'">
                                    {{bonCommande.civlib?' '+bonCommande.civlib:'' }}{{ bonCommande.nom?' '+bonCommande.nom:'' }}<br> {{ bonCommande.prenom | uppercase  }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'cp'">
                                    {{ bonCommande.cp }}<br> {{ bonCommande.ville | uppercase }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'gsm'">
                                    {{ bonCommande.gsm | formatTele }} <br> {{ bonCommande.tel ? (bonCommande.gsm != '' && bonCommande.gsm != null) ? '/ ' + (bonCommande.tel | formatTele) : '' : '' }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'dtmaj'">
                                    {{ listDateFormatter(bonCommande.dtmaj) }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'dtech'">
                                    {{ positionnement === 'Client prévenu'?_alerteDateCalculation(bonCommande.dtech):listDateFormatter(bonCommande.dtech, false, false) }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'dtrel'">
                                    {{ listDateFormatter(bonCommande.dtrel, false, false) }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'montant'">
                                    {{ bonCommande.montant | cap_currency:null:false }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'canal'">
                                    {{ canalIdCheck(bonCommande.canal) }}
                                </ng-container>

                                <ng-container *ngIf="col.value === 'magstocklib' || col.value === 'typecde' || col.value === 'numexterne' || col.value === 'numbonrgp'">
                                    {{ bonCommande[col.value] }}
                                </ng-container>
                            </td>
                            <td nowrap>
                                <button type="button" mat-mini-fab color="warning" class="ml-1" [title]="'kbonrelanceclient' | translate" *ngIf="pageType === 'relance'">
                                    <mat-icon>notifications</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="primary" class="ml-1" [title]="(detailBonCommandeVisibility || pageType === 'historique' || pageType === 'supprimees')?('kkbondecomdtxt' | translate):''" (click)="redirectToModify(bonCommande)">                                    
                                    <mat-icon>{{ (detailBonCommandeVisibility || !userAllowedToModifyOrRemove(bonCommande) || pageType === 'historique' || pageType === 'supprimees')?'visibility':'edit' }}</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="danger" class="ml-1" [title]="'kbonhistorique' | translate" (click)="viewBonCommandeHistory(bonCommande)" *ngIf="pageType === 'historique' || pageType === 'supprimees'">
                                    <mat-icon>history</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="danger" class="ml-1" [title]="'kboncomtickets' | translate" (click)="openUpTickets(bonCommande)" *ngIf="pageType === 'historique' || pageType === 'supprimees'">
                                    <mat-icon>receipt</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="primary" class="ml-1" [title]="'kboncomreactive' | translate" (click)="reactivateConfirmation(bonCommande)" *ngIf="(pageType === 'historique' || pageType === 'supprimees') && (userPermission !== undefined && userPermission !== null && userPermission['32_16']) && userAllowedToModifyOrRemove(bonCommande)">
                                    <mat-icon>model_training</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="warn" class="ml-1" *ngIf="pageType !== 'historique' && pageType !== 'supprimees' && pageType !== 'retrait-marchandise' && !detailBonCommandeVisibility && userAllowedToModifyOrRemove(bonCommande) && bonCommande.preparation !== true" (click)="deleteConfirmation(bonCommande)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="listBonDeCommande?.length == 0 || showLoader">
                        <tr>
                            <td [attr.colspan]="pageType === 'retrait-marchandise'?15:14" *ngIf="listBonDeCommande?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                            <td [attr.colspan]="pageType === 'retrait-marchandise'?15:14" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>                           
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td *ngIf="pageType === 'retrait-marchandise'" style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>
                <mat-list *ngIf="isMobile && (listBonDeCommande?.length == 0 || showLoader)">                  
                    <mat-list-item *ngIf="listBonDeCommande?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">                        
                        <p matLine class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                    </mat-list-item>
                </mat-list>              
                <mat-list *ngIf="isMobile && (listBonDeCommande?.length != 0 && !showLoader)">
                   
                    <mat-list-item  *ngFor="let bonCommande of listBonDeCommande;let i=index;" [id]="'swipeItem-' + i" style="user-select: none;" (swipe)="onSwipe($event, i, bonCommande)">
                        <div class="swipedContent">
                            <button type="button" mat-mini-fab color="warn" *ngIf="pageType !== 'historique' && pageType !== 'supprimees' && pageType !== 'retrait-marchandise' && !detailBonCommandeVisibility && userAllowedToModifyOrRemove(bonCommande) && bonCommande.preparation !== true" (click)="deleteConfirmation(bonCommande)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button type="button" mat-mini-fab color="primary" [title]="'kboncomreactive' | translate" (click)="reactivateConfirmation(bonCommande)" *ngIf="(pageType === 'historique' || pageType === 'supprimees') && (userPermission !== undefined && userPermission !== null && userPermission['32_16']) && userAllowedToModifyOrRemove(bonCommande)">
                                <mat-icon>model_training</mat-icon>
                            </button>
                        </div>

                        <div class="w-100 list-item-main-content" [class.prevent-swipe]="pageType && pageType === 'retrait-marchandise'">
                            <div matLine (click)="redirectToModify(bonCommande)" class="align-items-start d-flex flex-row justify-content-between">

                                <div class="m-0 d-flex flex-row align-content-center justify-content-start">
                                    <p class="m-0">
                                        <span class="colorcode" [class]="defineRowColor(bonCommande)"></span> {{ bonCommande.numbon | slice:0:2 }} {{ bonCommande.numbon | slice:2:4 }} {{ bonCommande.numbon | slice:4:7 }} {{ bonCommande.numbon | slice:7:12 }} {{ bonCommande.numbon | slice:12:15 }}
                                    </p>
                                    <span class="different-store ml-2" *ngIf="isMagStockAvail(bonCommande)">
                                        <mat-icon>store</mat-icon>
                                    </span>
                                </div>
                                <p class="m-0">{{ listDateFormatter(bonCommande.dtmaj) }}</p>
                            </div>
                            <div matLine (click)="redirectToModify(bonCommande)" class="align-items-start d-flex flex-row justify-content-between">
                                <div matLine class="mobile-list-title">{{bonCommande.civlib?' '+bonCommande.civlib:'' }} {{ bonCommande.nom | uppercase }} {{ bonCommande.prenom | uppercase  }}</div>
                                <p matLine class="mobile-list-title text-right no-margin"> 
                                    <span class="badge badge-secondary">{{ bonCommande.montant | cap_currency:null:false }}</span>
                                </p>
                            </div>
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <p matLine (click)="redirectToModify(bonCommande)" class="mobile-list-title mb-0">
                                    {{ bonCommande.cp }} {{ bonCommande.ville?bonCommande.ville+(bonCommande.gsm?' , ':''):'' | uppercase }}<br>
                                    {{ bonCommande.gsm | formatTele}} {{ bonCommande.tel?(bonCommande.gsm !== '' && bonCommande.gsm != null?' / ':'')+(bonCommande.tel | formatTele):'' }}<br>
                                    <span class="d-flex align-items-center mt-1">
                                        <mat-icon class="mr-1 responsive-icon">assignment</mat-icon>
                                        <span>{{ bonCommande.numbonrgp | slice:0:2 }} {{ bonCommande.numbonrgp | slice:2:4 }} {{ bonCommande.numbonrgp | slice:4:7 }} {{ bonCommande.numbonrgp | slice:7:12 }} {{ bonCommande.numbonrgp | slice:12:15 }}</span>
                                    </span>
                                </p>                            
                                <p matLine class="mobile-list-title d-flex flex-column text-right">
                                    <span (click)="redirectToModify(bonCommande)">{{ bonCommande.typecde }}</span>
                                    <span class="prepa_radio d-inline-flex justify-content-end" *ngIf="pageType && pageType === 'retrait-marchandise'">
                                        <mat-slide-toggle [(ngModel)]="bonCommande.preparation" (ngModelChange)="onPrepaClick(bonCommande)" [title]="'kprepa' | translate"></mat-slide-toggle>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>         
            </div>
        </div>
    </div>
</div>
