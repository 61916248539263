<form [formGroup]="livreForm" (ngSubmit)="livreFormSubmitter()">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 text-left" *ngIf="suiviLivraison">
            <button type="button" (click)="openUpDeliveryTracking()" mat-button>
                {{ 'ksuicilivrai' | translate }}
            </button>
        </div>

        <div class="col-12 col-md-12 col-lg-12">
            <mat-form-field floatLabel="always" >
                <mat-label>{{'kbonlivde' | translate}}</mat-label>
                <input matInput [min]="today" formControlName="dtliv" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="dtliv_status"></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-12 col-lg-12" *ngIf="!lienLogAvail">            
            <mat-form-field>
                <mat-select [placeholder]="'kbonmatin' | translate" formControlName="livjour" >      
                    <mat-option value="">&nbsp;</mat-option>
                    <mat-option value="M">{{'kbonmatin' | translate}}</mat-option>
                    <mat-option value="A">{{'kbonapresmidi' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-12 col-lg-12 text-left" *ngIf="!lienLogAvail">
            <mat-checkbox formControlName="livconfirm"> {{'kbonconfirmationdedel' | translate}}</mat-checkbox>
        </div>

        <div class="col-12 col-md-12 col-lg-12" *ngIf="!lienLogAvail">
            <mat-form-field flatLabel="always">
                <mat-label>{{'kboninformationdedel1' | translate}}</mat-label>
                <input matInput
                       formControlName="livinfo1"                       
                       class="text-uppercase">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'kboninformationdedel2' | translate}}</mat-label>
                <input matInput
                       formControlName="livinfo2"                       
                       class="text-uppercase">
            </mat-form-field>
        </div>
        
        <div class="col-12 col-md-6 col-lg-6" *ngIf="!lienLogAvail && magComplementData && magComplementData.OKLIVDIRECT == '-1'">
            <mat-checkbox formControlName="livdirect" (change)="checkboxChanged($event,'direct')"> {{'kbonlivdirect' | translate}}</mat-checkbox>            
        </div>
        <div class="col-12 col-md-6 col-lg-6" *ngIf="!lienLogAvail && magComplementData && magComplementData.OKLIVDEPORT == '-1'">
            <mat-checkbox formControlName="livdeport" (change)="checkboxChanged($event,'deport')"> {{'kbonlivedeport' | translate}}</mat-checkbox>            
        </div>
    </div>
    <div class="row" *ngIf="!lienLogAvail">
        <div class="col-12 col-md-12 col-lg-12">
            <mat-form-field>                
                <input matInput
                        formControlName="transporteurLabel"                       
                       [placeholder]="'kbontrans' | translate"
                       class="text-uppercase" />

                    <button matSuffix type="button" (click)="openUpTransporteurList()" mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                    <button matSuffix class="ml-1" *ngIf="previousTranporteur || panier.transporteur !== ''" color="warn" type="button" (click)="removeSelectedTranporteur()" mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
            </mat-form-field>
        </div>
    </div>    

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-6 text-right">
                <button type="submit" class="bt_valid" mat-button [title]="'kuok' | translate">
                    <i class="material-icons">done</i>
                    <span class="d-none d-sm-none d-md-block">{{ 'kuok' | translate }}</span>
                </button>
            </div>
            <div class="col-6 text-left">
                <button class="bt_valid" type="button" mat-button mat-dialog-close [title]="'kucancel' | translate">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">{{ 'kucancel' | translate }}</span>
                </button>
            </div>                
        </div>
    </div>
</form>