import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import * as moment from 'moment';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { FocusMonitor } from '@angular/cdk/a11y';
import { UtilService } from 'providers/api/utilService';
import { User } from 'models/users';
import {Constants} from '../../../providers/constants';
import {Observable} from 'rxjs';
import {ConfirmDialogComponent} from '../../../components/confirm-dialog/confirm-dialog.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {BottomSheetComponent} from '../../../components/bottom-sheet/bottom-sheet.component';
import SheetItem from '../../../models/bottomSheet';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-action-reserve',
  templateUrl: './action-reserve.component.html',
  styleUrls: ['./action-reserve.component.scss'],
  providers: [BonVenteService]
})
export class ActionReserveComponent implements OnInit {

  @ViewChild('firstField') firstField: ElementRef;

  @ViewChild('prixVente', {static: true}) newPrixVente: ElementRef;
  @ViewChild('articleQty', {static: true}) articleQty: ElementRef;

  maskOptions = {
    mask: Number,
    scale: 2,    
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  qtyMaskOptions = {
    mask: Number,
    scale: 2,
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  isMobile: boolean;
  user: UserModel;
  USERCMINT: any;

  articleData: Article;
  currentItem: PanierArticle;

  articleModifiedUserName: string;
  articleModifiedDate: string;

  bonNumBon: string;
  panier: BonPanier;

  editItemForm: UntypedFormGroup;    
  flowType: string;

  descriptionMode = false;

  // Article d'options
  listArticleGroups: any[];
  articleGroupsRawData: any[];
  currentArticleChosenGroups: any;

  userPermission: any;
  PU_vente_changable: boolean = false;
  authorizedLinePrixUser: User;

  qtyLength: number = 0;
  qtyWidth: number = 0;
  qtyHeight: number = 0;

  commander: boolean = false;
  quantity5Decimal: boolean = false;

  isDisabled: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ActionReserveComponent>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private dialogService: DialogsService,
    private userService: UserService,  
    private bonVenteService: BonVenteService,
    private bonCommandeService: BonCommandeService,
    private loadingService: NgxSpinnerService,
    private utilService: UtilService,
    private currencyPipe: CapCurrencyPipe,
    private deviceDetector: DeviceDetectorService,
    private focusMonitor: FocusMonitor,
    private _bottomSheet: MatBottomSheet,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.userPermission = this.storageService.getItem('CurrentUserPermission');

    this.editItemForm = this.formBuilder.group({
      CDART: [{value: '', disabled: true}, Validators.required],
      CALIB: [{value: '', disabled: true}, Validators.required],
      QTY: ['', [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      GEOGRAPH_RESERVE: [''],
      ALLEE: ['', [Validators.pattern('^[0-9\.\,]+$')]],
      EMPLACEMENT: ['', [Validators.pattern('^[0-9\.\,]+$')]],
      HAUTEUR: ['', [Validators.pattern('^[0-9\.\,]+$')]]
    });

    this.loadDependencies();
  }

  ngAfterViewInit() {
    if(this.firstField) {
      setTimeout(() => {
        this.focusMonitor.focusVia(this.firstField.nativeElement, null)
        this.firstField.nativeElement.setAttribute('inputmode', 'text');
      }, 100);
    }
  }

  async loadDependencies() {
    this.loadingService.show();

    this.bonNumBon = this.data.bonNumBon;
    this.panier = this.data.panier;
    this.currentItem = this.data.panier_article;    
    this.articleData = this.data.article_data;  
    this.USERCMINT = (this.data.CMINT !== undefined && this.data.CMINT !== null)?this.data.CMINT:this.user.CMINT;

    this.articleModifiedDate = (this.currentItem !== undefined && this.currentItem.dtmaj !== undefined && this.currentItem.dtmaj !== null)?moment(this.currentItem.dtmaj).utcOffset(this.currentItem.dtmaj).format('DD.MM.YYYY HH:mm'):'';
    this.articleModifiedUserName = (this.currentItem !== undefined)?this.currentItem.libuser:'';

    this.editItemForm.patchValue({
      CDART: this.currentItem.cdart,
      CALIB: this.currentItem.lib,
    });

    await this.getQuantityDecimalStatus();

    if(this.quantity5Decimal) {
      this.editItemForm.patchValue({
        QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite,'0.5')
      });
    } else {
      this.editItemForm.patchValue({
        QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite,'0.2')
      });
    }

    this.loadingService.hide();
  }

  get quantityMask() {
    return this.qtyMaskOptions;
  }

  async getQuantityDecimalStatus() {
    try {
      const paramData = await this.userService.getRuleParamsData(10,12, 'VBOOL1');
      this.quantity5Decimal = false;
  
      this.qtyMaskOptions = {
        mask: Number,
        scale: 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ["."]
      };
  
      if(paramData !== undefined && paramData != null && paramData == '-1') {
          this.quantity5Decimal = true;
  
          this.qtyMaskOptions = {
            mask: Number,
            scale: 5,
            padFractionalZeros: true,
            radix: this.utilService.getCurrentDecimalSymbol,
            mapToRadix: ["."]
          };
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  get solderBonTitle() {
    return this.currentItem.statut == 7 ? "kretour_article" : "ksolder_article"
  }

  get conformeBonTitle() {
    return this.currentItem.statut == 17 ? "karticle_conforme" : "karticle_non_conforme"
  }

  get solderCommandeEnabled() {
    return this.userPermission && this.userPermission['35_12'];
  }

  get conformeCommandeEnabled() {
    return this.userPermission && this.userPermission['35_13'];
  }

  get modifyEmplacementsEnabled() {
    return this.userPermission && this.userPermission['35_14'];
  }

  get annulationLivraisonEnabled() {
    return this.userPermission && this.userPermission['35_15'];
  }

  get deliveryStatutTitle() {
    return this.currentItem.statut == 5 ? "kliv_en_cours" : this.currentItem.statut == 6 ? "kliv_en_attente" : "";
  }

  openUpBottomSheet() {
    const sheetItems: SheetItem[] = [
      {
        title: 'Effacer emplacement du bon',
        disabled: !this.modifyEmplacementsEnabled
      },
      {
        title: 'Effacer emplacement de l\'article',
        disabled: !this.modifyEmplacementsEnabled
      },
      {
        title: this.translateService.instant(this.solderBonTitle),
        onClick: () => {
          this.onSolderArticleClick();
        },
        disabled: !this.solderCommandeEnabled
      },
      {
        title: this.translateService.instant(this.conformeBonTitle),
        onClick: () => {
          this.onArticleConformeClick();
        },
        disabled: !this.conformeCommandeEnabled
      },
      {
        title: this.translateService.instant(this.deliveryStatutTitle),
        onClick: () => {
          this.onLivraisonStatusChange();
        },
        disabled: !this.annulationLivraisonEnabled,
      },
    ];

    this._bottomSheet.open(BottomSheetComponent, {
      data: {
        sheetItems
      }
    });
  }

  async onLivraisonStatusChange() {
    if (this.panier.preparation) {
      if (this.currentItem.typecde === "BL" && (this.currentItem.statut === 5 || this.currentItem.statut === 6)) {
        const pLignes = this.getpLignes();
        const data = { pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes };
        
        const etatConf = await this.confirmationDialog(this.currentItem.statut).toPromise();
        if(etatConf !== 'OK') {
          return;
        }
  
        this.loadingService.show();
        this.livrerOrAnnulerLivraisonBonDeCommande(data, this.currentItem.statut);
      } else {
        this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('Karticle_delivery_not_possible'));
      }
    } else {
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('kliv_ok_prepa_warn'));
    }
  }

  onSolderArticleClick() {
    if(this.currentItem.typecde.indexOf('E2') > 0) {
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('ke2_en_cours'));
      return;
    }

    if(this.currentItem.statut == 17) {
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('karticle_non_conforme_alert'));
      return;
    }

    this.modifySolderBon();
  }

  onArticleConformeClick() {
    if(this.currentItem.statut == 7) {
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('karticle_solde'));
      return;
    }

    this.modifyConformeArticle();
  }

  __resteApayerConfirmation(): Observable<any> {
    const message = `
      <h1 class="mb-3">${this.translateService.instant('kcommandepayee')}</h1>
      <h1 class="mb-3">${this.translateService.instant('kboncontinue')}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'), this.translateService.instant('ksno')]
      }
    });

    return dialogRef.afterClosed();
  }

  getpLignes() {
    let pLignes = this.currentItem.idlig.toString();
    let multiLignes = [this.currentItem.idlig];

    if(this.currentItem.opt < 0) {
      this.panier.articles.map((items: PanierArticle) => {
        if(items.opt == Math.abs(this.currentItem.opt)) {
          multiLignes.push(items.idlig)
        }
      })

      pLignes = multiLignes.join(';')
    }

    return pLignes;
  }

  async modifySolderBon() {
    this.loadingService.show();
    const pLignes = this.getpLignes();

    if (this.currentItem.statut == 7) {
      // Retour article
      this.deSolderBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes });
    } else {
      if (this.currentItem.typecde.includes('E2')) {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('ke2_en_cours'));
        return;
      }

      const allOtherArticlesHaveStatus7 = this.panier.articles.filter(item => item.idlig !== this.currentItem.idlig).every(rw => rw.statut === 7);
      if (allOtherArticlesHaveStatus7 && (this.panier.montant > 0 && this.panier.montantrestant > 0)) {
        this.loadingService.hide();
        const resteConf = await this.__resteApayerConfirmation().toPromise();
        if (resteConf !== 'OK') {
          return;
        }

        this.loadingService.show();
      }

      // Solder l'article
      this.solderBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes });
    }
  }

  async modifyConformeArticle() {
    this.loadingService.show();
    const pLignes = this.getpLignes();

    if (this.currentItem.statut == 17) {
      // Article conforme
      this.conformeBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes });
    } else {
      // Article non conforme
      this.nonConformeBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes });
    }
  }

  solderBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.solderBonCommande(data).subscribe(
        async (resp) => {
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              // Need to update the Retrait marchandise Panier object and the list cache data
              this.userService.actionReserveRefreshBon.next({ status: 'UPDATE_PANIER' });
              this.dialogRef.close();
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  deSolderBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.deSolderBonCommande(data).subscribe(
        (resp) => {
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              // Need to update the Retrait marchandise Panier object and the list cache data
              this.userService.actionReserveRefreshBon.next({ status: 'UPDATE_PANIER' });
              this.dialogRef.close();
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  nonConformeBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.nonConformeBonCommande(data).subscribe(
        (resp) => {
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              // Need to update the Retrait marchandise Panier object and the list cache data
              this.userService.actionReserveRefreshBon.next({ status: 'UPDATE_PANIER' });
              this.dialogRef.close();
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  conformeBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.conformeBonCommande(data).subscribe(
        (resp) => {
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              // Need to update the Retrait marchandise Panier object and the list cache data
              this.userService.actionReserveRefreshBon.next({ status: 'UPDATE_PANIER' });
              this.dialogRef.close();
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        error => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    );
  }

  editItemFormSubmitter() {
    console.log(this.editItemForm.getRawValue());
  }

  confirmationDialog(statut: number): Observable<any> {
    const message = `<h1 class="mb-3">${this.translateService.instant(statut === 5 ? 'kliv_en_cours_confirm_art' : 'kliv_en_attente_confirm_art')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'),this.translateService.instant('ksno')]
      }     
    });

    return dialogRef.afterClosed();
  }
  
  livrerOrAnnulerLivraisonBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }, statut: number) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    const handleResponse = async (resp) => {
      if (resp.statusCode == 200) {
          if (resp.data.ErrorCode == 0) {
            // Need to update the Retrait marchandise Panier object and the list cache data
            this.userService.actionReserveRefreshBon.next({ status: 'UPDATE_PANIER' });
            this.dialogRef.close();
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
      } else {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    };

    const handleError = () => {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    };

    const serviceCall = statut === 5 
      ? this.bonCommandeService.livrerBonDeCommande(data)
      : this.bonCommandeService.annulerLivraisonBonDeCommande(data);

    serviceCall.subscribe(handleResponse, handleError);
  }

}
