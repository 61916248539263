import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import BonPanier, { PanierArticle } from 'models/bonPanier';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import * as moment from 'moment';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { Constants } from 'providers/constants';
import { BlocageCustomMotifComponent } from 'pages/article-detail/article-stock/update-motif-blocage/blocage-custom-motif/blocage-custom-motif.component';
import { VenteArticleGroupsComponent } from 'pages/create-bon-de-vente/vente-article-groups/vente-article-groups.component';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { FocusMonitor } from '@angular/cdk/a11y';
import { UtilService } from 'providers/api/utilService';
import { User } from 'models/users';
import { CalculateQuantityComponent } from 'pages/create-bon-de-vente/calculate-quantity/calculate-quantity.component';
import { MotifForcePrixComponent } from 'pages/create-bon-de-vente/motif-force-prix/motif-force-prix.component';
import {ParamDetail} from '../../../models/paramDetail';
import {
  AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG,
  AUTHORIZE_MODIFY_ARTICLES_BON_AUTRE_VENDEUR,
  HistoryService
} from '../../../providers/api/historyService';
import { NonServiceArticlesListComponent } from 'pages/create-bon-de-vente/non-service-articles-list/non-service-articles-list.component';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-modify-line-item-choix',
  templateUrl: './modify-line-item-choix.component.html',
  styleUrls: ['./modify-line-item-choix.component.scss'],
  providers: [BonVenteService, UserService]
})
export class ModifyLineItemChoixComponent implements OnInit {

    @ViewChild('firstField') firstField: ElementRef;

  @ViewChild('prixVente', {static: true}) newPrixVente: ElementRef;
  @ViewChild('articleQty', {static: true}) articleQty: ElementRef;

  maskOptions = {
    mask: Number,
    scale: 2,    
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  qtyMaskOptions: any = {
    mask: Number,
    scale: 2,
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  isMobile: boolean;
  user: UserModel;
  USERCMINT: any;

  articleData: Article;
  currentItem: PanierArticle;

  listBonVenteArticles: Article[];
  listNonServiceArticles: Article[] = [];
  listNonServiceItems: PanierArticle[] = [];
  cdartArticlesHashTable: { [key: string]: Article } = {};

  
  articleModifiedUserName: string;
  articleModifiedDate: string;
  
  bonNumBon: string;
  panier: BonPanier;
  
  editItemForm: UntypedFormGroup;    
  flowType: string;
  
  descriptionMode = false;
  
  // Article d'options
  listArticleGroups: any[];
  articleGroupsRawData: any[];
  currentArticleChosenGroups: any;
  linkedStatus: 'service' | 'bundle' | 'no_link' = 'no_link';
  isWefoxArticle: boolean = false;

  userPermission: any;
  PU_vente_changable: boolean = false;

  authorizedLinePrixUser: User;
  authorizedArticleStockMagUser: User;
  authorizedModifyItemUser: User;

  qtyLength: number = 0;
  qtyWidth: number = 0;
  qtyHeight: number = 0;

  commander: boolean = false;
  quantity5Decimal: boolean = false;
  maxQteAllowed = 0;

  readOnly: boolean = true;
  isLinkedToAutreArticleHistory: any;

  // Stock Autre or User Params List
  paramsList: ParamDetail[];
  // Only User Params List (Backup)
  userParamsList: ParamDetail[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModifyLineItemChoixComponent>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private dialogService: DialogsService,
    private userService: UserService,  
    private bonVenteService: BonVenteService,
    private bonCommandeService: BonCommandeService,
    private loadingService: NgxSpinnerService,
    private utilService: UtilService,
    private currencyPipe: CapCurrencyPipe,
    private deviceDetector: DeviceDetectorService,
    private focusMonitor: FocusMonitor,
    private historyService: HistoryService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.userPermission = this.storageService.getItem('CurrentUserPermission');


    this.listBonVenteArticles = this.data?.listBonVenteArticles;
    this.listNonServiceArticles = this.data?.listNonServiceArticles;
    this.listNonServiceItems = this.data?.listNonServiceItems;
    this.cdartArticlesHashTable = this.data?.cdartArticlesHashTable;

    this.editItemForm = this.formBuilder.group({
      EXPO: [false],
      COIN_SOLDE: [false],
      INFO1: [''],
      INFO2: [''],

      CDART: [{value: '', disabled: true}, Validators.required],
      CALIB: [{value: '', disabled: true}, Validators.required],
      QTY: [{value: '', disabled: true}, [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      PRIX: [{value: '', disabled: true}],
      PRIX_VENTE: ['', [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      PRIX_TOTAL: [{value: '', disabled: true}],
      PRIX_FORCE: [false],
      MOTIF_PRIX: [''],
    });

    this.loadDependencies();
  }

  ngAfterViewInit() {
    if(this.firstField) {
      setTimeout(() => {
        this.focusMonitor.focusVia(this.firstField.nativeElement, null)
        this.firstField.nativeElement.setAttribute('inputmode', 'text');
      }, 100);
    }
  }

  async loadDependencies() {
    this.loadingService.show();

    this.bonNumBon = this.data.bonNumBon;
    this.panier = this.data.panier;
    this.currentItem = this.data.panier_article;    
    this.articleData = this.data.article_data;

    if (this.data && this.data.hasOwnProperty('authorizedModifyItemUser')) {
      this.authorizedModifyItemUser = this.data.authorizedModifyItemUser || undefined;
    }

    await this._restrictQtyDecimal(this.articleData);

    this.USERCMINT = (this.data.CMINT !== undefined && this.data.CMINT !== null)?this.data.CMINT:this.user.CMINT;

    this.articleModifiedDate = (this.currentItem !== undefined && this.currentItem.dtmaj !== undefined && this.currentItem.dtmaj !== null)?moment(this.currentItem.dtmaj).utcOffset(this.currentItem.dtmaj).format('DD.MM.YYYY HH:mm'):'';
    this.articleModifiedUserName = (this.currentItem !== undefined)?this.currentItem.libuser:'';

    this.editItemForm.patchValue({
      EXPO: this.currentItem.expo?true:false,
      COIN_SOLDE: this.currentItem.coinsolde?true:false,
      INFO1: this.currentItem.commlig1,
      INFO2: this.currentItem.commlig2,

      CDART: this.currentItem.cdart,
      CALIB: this.currentItem.lib,
      PRIX_FORCE: this.currentItem.prixforce,
      MOTIF_PRIX: this.currentItem.motifprixforce,
    });

    // In order to fix the Input Mask issue for prices,
    // we're putting them into setTimeOut
    setTimeout(() => {
      this.editItemForm.patchValue({
        PRIX: this.utilService.formatMaskCompat(this.currentItem.prix),
        PRIX_VENTE: this.utilService.formatMaskCompat(this.currentItem.prixupv),
        PRIX_TOTAL: this.utilService.formatMaskCompat(this.currentItem.montant),
      })
    }, 500);

    await this.getQuantityMaxLimit();

    if(this.articleData.CAUNVTE === Constants.QTE_ALLOW_DECIMAL_CAUNVTE) {
      await this.getQuantityDecimalStatus();
    }

    if (this.currentItem.opt > 0) {
      const parentItem = [...this.panier.articles].find(rw => rw.opt == -this.currentItem.opt);
      const parentArticle = this.listBonVenteArticles.find(rw => rw.CDART === parentItem.cdart);

      await this.checkArticleParentChildStatus([[parentArticle.CAINT, this.articleData.CAINT]]);
    }

    if(this.currentItem.opt < 0 && +this.articleData.GROUP_COUNT > 0) {
      this.linkedStatus = 'bundle';
      await this.searchArticleGroupsCAINT(this.articleData.CAINT);
    }

    await this.getListParams();
    if (this.panier.magstock) {
      await this.getListParams(this.panier.magstock);
    }

    this.definingTypeDeRules();
    
    this.PU_vente_changable = true;
    if(!this.userPermission['0_17']) {
      this.PU_vente_changable = false;
    }

    if(this.quantity5Decimal) {
      this.editItemForm.patchValue({
        QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite,  this.quantityMask.scale === 0 ? '0.0' : '0.5')
      });
    } else {
      this.editItemForm.patchValue({
        QTY: this.utilService.formatQteMaskCompat(this.currentItem.quantite,  this.quantityMask.scale === 0 ? '0.0' : '0.2')
      });
    }

    if(this.currentItem.statut != 7 && this.currentItem.statut != 17 && this.currentItem.statut != 27 && this.panier.preparation == false) {
      this.readOnly = false;
    }

    if(!this.userPermission['32_12'] || this.currentItem.statut == 7 || this.currentItem.statut == 17 || this.currentItem.statut == 27 || this.panier.preparation) {
      this.editItemForm.get('PRIX_VENTE').disable({ onlySelf: true });
      this.editItemForm.get('PRIX_VENTE').setErrors(null);
      this.editItemForm.get('PRIX_VENTE').clearValidators();
      this.editItemForm.get('PRIX_VENTE').updateValueAndValidity();
    } else {
      this.editItemForm.get('PRIX_VENTE').enable({ onlySelf: true });
      this.editItemForm.get('PRIX_VENTE').setValidators([Validators.required, Validators.pattern('^[0-9\.\,]+$')]);
      this.editItemForm.get('PRIX_VENTE').updateValueAndValidity();
    }

    if(this.readOnly) {
      this.editItemForm.get('QTY').disable({ onlySelf: true });
      this.editItemForm.get('INFO1').disable({ onlySelf: true });
      this.editItemForm.get('INFO2').disable({ onlySelf: true });
      this.editItemForm.get('EXPO').disable({ onlySelf: true });
      this.editItemForm.get('COIN_SOLDE').disable({ onlySelf: true });
    }

    this.loadingService.hide();
  }

  get isServiceArticle() {
    return this.articleData && this.articleData.CAGESTOCK == '0';
}

  get quantityMask() {
    return this.qtyMaskOptions;
  }

  _restrictQtyDecimal(currentArticle: Article): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if(currentArticle === undefined || currentArticle === null) {
        reject('INVALID_CURRENT_ARTICLE');
        return;
      }

      if(currentArticle.CAUNVTE !== Constants.QTE_ALLOW_DECIMAL_CAUNVTE) {
        this.qtyMaskOptions = {
          mask: Number,
          scale: 0,
          padFractionalZeros: false,
          normalizeZeros: false,
          radix: this.utilService.getCurrentDecimalSymbol,
          mapToRadix: ["."]
        };
      } else {
        this.qtyMaskOptions = {
          mask: Number,
          scale: this.quantity5Decimal ? 5 : 2,
          padFractionalZeros: true,
          radix: this.utilService.getCurrentDecimalSymbol,
          mapToRadix: ["."]
        };
      }

      // To solve issue with Qty while changing the MaskOptions.scale to zero
      setTimeout(() => {
        this.editItemForm.get('QTY').setValue('1');
        resolve();
      }, 100);
    });
  }

  getListParams(overrideCMINT?: any): Promise<void> {
    return new Promise((resolve, reject) => {

      let CMINT = this.USERCMINT;
      if (overrideCMINT !== undefined && overrideCMINT !== null) {
        CMINT = overrideCMINT;
      }

      if (CMINT != this.USERCMINT && (this.userParamsList === undefined || this.userParamsList === null)) {
        this.userParamsList = this.paramsList;
      }

      this.userService.getRulesParams(CMINT, this.USERCMINT).subscribe(
          (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
          },
          (error: any) => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject('error_callback');
          });
    });
  }

  async getQuantityMaxLimit() {
    try {
      const paramData = await this.userService.getRuleParamsData(10, 12, 'VFLOAT1');
      this.maxQteAllowed = 0;

      if (paramData !== undefined && paramData != null && paramData !== '') {
        this.maxQteAllowed = +paramData;
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  async getQuantityDecimalStatus() {
    try {
      const paramData = await this.userService.getRuleParamsData(10,12, 'VBOOL1');
      this.quantity5Decimal = false;
  
      this.qtyMaskOptions = {
        mask: Number,
        scale: 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ["."]
      };
  
      if(paramData !== undefined && paramData != null && paramData == '-1') {
          this.quantity5Decimal = true;
  
          this.qtyMaskOptions = {
            mask: Number,
            scale: 5,
            padFractionalZeros: true,
            radix: this.utilService.getCurrentDecimalSymbol,
            mapToRadix: ["."]
          };
      }
    } catch (err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  checkArticleParentChildStatus(payload: string[][]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.checkArticleParentChildStatus(payload).subscribe(
          (res: any) => {
            if(res.success !== undefined) {
              if(res.data.length > 0) {
                this.linkedStatus = 'bundle';
              } else {
                this.linkedStatus = 'service';
              }
              resolve(res);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
              resolve(res);
            }
          },
          (err) => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    })
  }

  definingTypeDeRules() {
    if(this.articleData.etatart === 'G') {
      this.commander = true;
    }

    const checkWefox = this.checkArticleWefoxStatus(this.articleData.CDART.toLowerCase());
    this.isWefoxArticle = checkWefox.wefoxStat;
  }

  onQTYChanged(event: any) {
    this.rowTotalCalculation();
  }

  onQuantityFocused() {
    if(this.articleData === undefined || this.articleData === null) return;

    if(this.articleData.CAUNVTE === 'M3') {
      this.openUpCalculateQty().then((result) => {
        if(result && result.status === "success") {
          const data = result.data;

          const length = data.length?data.length:0;
          const width = data.width?data.width:0;
          const height = data.height?data.height:0;

          this.qtyLength = length;
          this.qtyWidth = width;
          this.qtyHeight = height;

          const oldDescription = this.editItemForm.get('INFO1').value;
          let description = `L ${length} x l ${width} x h ${height}`;

          if(oldDescription !== '' && oldDescription !== null) {
            description = `${oldDescription} | ${description}`;
          }

          const totalQty = length * width * height;

          if(this.quantity5Decimal) {
            this.editItemForm.patchValue({
              QTY: this.utilService.formatQteMaskCompat(totalQty,  this.quantityMask.scale === 0 ? '0.0' : '0.5'),
              INFO1: description
            });
          } else {
            this.editItemForm.patchValue({
              QTY: this.utilService.formatQteMaskCompat(totalQty,  this.quantityMask.scale === 0 ? '0.0' : '0.2'),
              INFO1: description
            });
          }

          this.rowTotalCalculation();
        }
      })
    }
  }

  openUpCalculateQty(): Promise<any> {
    const dialogRef = this.dialog.open(CalculateQuantityComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.panier,
          currentItemFormData: this.editItemForm.getRawValue(),
          currentArticle: this.articleData,
          dimensions: {
            length: this.qtyLength,
            width: this.qtyWidth,
            height: this.qtyHeight,
          }
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  quantityChange(action){    
    this.articleQty.nativeElement.setAttribute('inputmode', 'none');
    let qty = Number(this.editItemForm.get('QTY').value);
    if(action === 'increment'){
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }    
    this.editItemForm.get('QTY').setValue(qty.toString());
    setTimeout(() => {
      this.articleQty.nativeElement.setAttribute('inputmode', 'decimal');
    }, 50);
    this.rowTotalCalculation(); 
  }

  get itemPrix(): AbstractControl {
    return this.editItemForm.get('PRIX');
  }

  async onPriceChanged(event: any) {
    if(!this.PU_vente_changable) {
      this.authorizedLinePrixUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '17', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        event.preventDefault();
        return;
      }
      
      this.authorizedLinePrixUser = authorizationResult.data;
      this.PU_vente_changable = true;
    }

    this.rowTotalCalculation();

    // While forcing prix, make a copy of it into PRIX form control
    let entered_prix = this.editItemForm.get('PRIX_VENTE').value;
    this.itemPrix.setValue(entered_prix);
  }

  rowTotalCalculation() {
    const price = this.editItemForm.get('PRIX_VENTE').value;
    const qty = this.editItemForm.get('QTY').value;

    if(isNaN(price) || isNaN(qty)) {
      this.editItemForm.patchValue({
        PRIX_TOTAL: '0'
      });
      return;
    }

    const total_price = price * qty;

    this.editItemForm.patchValue({
      PRIX_TOTAL: total_price.toString(),
    });
  }

  checkArticleWefoxStatus(CDART: string): { wefoxStat: boolean, wefoxTypeRestriction: boolean, wefoxType: string } {
    const processedRules = this.userService.processRulesParams(this.paramsList);

    const result = {
      wefoxStat: false,
      wefoxTypeRestriction: true,
      wefoxType: ''
    };

    if (processedRules['20_61']['VBOOL1'] == '-1') {
      if (CDART.toLowerCase() === processedRules['20_61']['VSTR1'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR2'] !== '' && processedRules['20_61']['VSTR2'] != null)?processedRules['20_61']['VSTR2']:'';

        result.wefoxTypeRestriction = true;
      }

      if (CDART.toLowerCase() === processedRules['20_61']['VSTR3'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR4'] !== '' && processedRules['20_61']['VSTR4'] != null)?processedRules['20_61']['VSTR4']:'';

        result.wefoxTypeRestriction = true;
      }
    }

    return result;
  }

  editItemFormSubmitter() {
    if(this.currentItem === undefined || this.currentItem === null) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const form_data = this.editItemForm.getRawValue();

    if (this.maxQteAllowed > 0 && +form_data.QTY > this.maxQteAllowed) {
      this.articleQty.nativeElement.setAttribute('inputmode', 'none');
      this.articleQty.nativeElement.select();
      this.articleQty.nativeElement.focus();
      setTimeout(() => {
        this.articleQty.nativeElement.setAttribute('inputmode', 'decimal');
      }, 50);

      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntytrop'), this.translateService.instant('kuok'));
      return;
    }

    const checkWefox = this.checkArticleWefoxStatus(form_data.CDART.toLowerCase());

    if((form_data.PRIX_VENTE === '' || form_data.PRIX_VENTE === null || +form_data.PRIX_VENTE === 0) &&
      !(checkWefox.wefoxStat) &&
        !this.userPermission['0_22']) {
      this.newPrixVente.nativeElement.select();
      this.newPrixVente.nativeElement.focus();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kboninvalid'));
      return;
    }

    if((this.editItemForm.get('PRIX_VENTE').touched && !this.editItemForm.get('PRIX_VENTE').pristine && this.editItemForm.get('PRIX_VENTE').dirty) && (+form_data.PRIX !== +this.articleData.calculated_price)) {
      this.openUpPrixForceMotif();
      return;
    }

    this.loadingService.show();
    this.editLineItem();
  }

  openUpPrixForceMotif() {
    const dialogRef = this.dialog.open(MotifForcePrixComponent,
      {
        data: {
          item: this.currentItem,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(result.data.force_motif !== '') {
            this.editItemForm.get('PRIX_FORCE').setValue(true);
            this.editItemForm.get('MOTIF_PRIX').setValue(result.data.force_motif);

            this.loadingService.show();
            this.editLineItem();
          } else {
            this.editItemForm.get('PRIX_FORCE').setValue(false);
            this.editItemForm.get('MOTIF_PRIX').setValue('');
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonprixoblig'));
          }          
      }
    });
  }

  toggleDescriptionMode() {
    this.descriptionMode = !this.descriptionMode;
  }

  async editLineItem() {
    const form_data = this.editItemForm.getRawValue();

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');    
    let grand_total_amount = 0;
    
    let article_data: PanierArticle[] = [];
    if(this.panier !== undefined && this.panier !== null) {    
      article_data = this.panier.articles;

      article_data.map((row) => {        

        if(row.idlig == this.currentItem.idlig) {
          row.iduser = +this.user.CIINT;
          row.idinstuser = +this.user.IDINSTINT;
          row.libuser = this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':row.libuser;
          
          row.commlig1 = (form_data.INFO1 !== null)?form_data.INFO1:"";
          row.commlig2 = (form_data.INFO2 !== null)?form_data.INFO2:"";

          row.prix = parseFloat(form_data.PRIX.toString()); // Article prix
          row.prixforce = form_data.PRIX_FORCE;
          row.motifprixforce = (form_data.MOTIF_PRIX !== null)?form_data.MOTIF_PRIX:"";
          row.prixupv = parseFloat(form_data.PRIX_VENTE.toString()); // New Price (Typeable) - PU Ven.
          row.montant = parseFloat((form_data.PRIX_VENTE * form_data.QTY).toString()); // Row Subtotal - PV Total
          
          row.coinsolde = form_data.COIN_SOLDE;
          row.expo = form_data.EXPO;

          row.dtmaj = current_date;
          
          grand_total_amount += row.montant;
          return row;
        }

        grand_total_amount += row.montant;
        return row;
      });
    }

    let bondeVenteData = {
      ...this.panier,
      articles: article_data,

      montant : grand_total_amount,
      montantHT: grand_total_amount,

      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    let bondeCommandeData = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pPanier: bondeVenteData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };

    bondeCommandeData.pPanier = bondeVenteData;

    this.editBonDeCommande(bondeCommandeData).then(
      async () => {

        if (this.authorizedModifyItemUser !== undefined && this.authorizedModifyItemUser !== null) {
          await this._addModificationItemHistory(this.currentItem.cdart, +form_data.QTY, +form_data.PRIX);
          this.authorizedModifyItemUser = undefined;
        }

        if((+this.articleData.GROUP_COUNT > 0 && this.articleGroupsRawData.some((_r: any) => _r.TYPECDE == null || this.currentItem.typecde.indexOf(_r.TYPECDE) > -1))) {
          const groupsPopupResult = await this.openUpCommmandeArticleGroups(this.listArticleGroups, this.currentItem, this.articleData, this.panier.typecde);

          if(groupsPopupResult !== undefined && groupsPopupResult !== null && groupsPopupResult.status !== undefined && groupsPopupResult.status === 'success') {
            this.loadingService.show();
            this.currentArticleChosenGroups = groupsPopupResult.data;
            const processedRules = this.userService.processRulesParams(this.paramsList);

            // Check All Bons (Normal bons/Bons with autre mag) whether
            // All the Child options are having stock more than 0 or not
            // if not more than 0, display an alert and stop the execution instead of continuing.
            const notAvailChildCdartList = [];
            const someChildNotHaveStockAvail = this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
              return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
                return _row_option.SELECTED &&
                    _row_option.CAGE_STOCK != '0' &&
                    (!_row_option.STOCK_DISPO ||
                        (_row_option.PANIER_POSITION != null &&
                            (+_row_option.STOCK_DISPO + this.currentItem.quantite) < +form_data.QTY) ||
                        (_row_option.PANIER_POSITION == null && +_row_option.STOCK_DISPO < +form_data.QTY));
              });
            });

            this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
              return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
                if(_row_option.SELECTED &&
                    _row_option.CAGE_STOCK != '0' &&
                    (!_row_option.STOCK_DISPO ||
                      (_row_option.PANIER_POSITION != null &&
                        (+_row_option.STOCK_DISPO + this.currentItem.quantite) < +form_data.QTY) ||
                      (_row_option.PANIER_POSITION == null && +_row_option.STOCK_DISPO < +form_data.QTY))) {
                  notAvailChildCdartList.push(_row_option.CDART);
                }
              });
            });

            if (someChildNotHaveStockAvail && (this.panier.magstock || this.panier.typecde.indexOf('E2') < 0) && (!processedRules['20_6'] || (processedRules['20_6']['VBOOL3'] != '-1' && processedRules['20_6']['VBOOL4'] != '-1'))) {
              const message = `${this.translateService.instant('katystockact')} ${notAvailChildCdartList ? notAvailChildCdartList.join(', ') : ''}`;
              this.dialogService.prompt(this.translateService.instant('kuerror'), message);

              this.loadingService.hide();
              return;
            }

            if (this.panier.magstock) {
              this.authorizedArticleStockMagUser = undefined;

              const someChildHaveStockMoreThanZero = this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
                return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
                  return _row_option.SELECTED && _row_option.CAGE_STOCK != '0' && (_row_option.STOCK_DISPO && +_row_option.STOCK_DISPO > 0);
                });
              });

              if (someChildHaveStockMoreThanZero && !this.userPermission['0_23']) {
                this.loadingService.hide();
                await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kstock_disponible_votre_magasin')).toPromise();
                const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '23', this.user.CIINT, '');

                if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
                  return;
                }

                this.loadingService.show();
                this.authorizedArticleStockMagUser = authorizationResult.data;
              }
            }

            let itemsToBeRemoved = [];

            // To check any old options exists which are unlinked from the currentLineItem group.
            this.panier.articles.map(
              (row) => {
                if(row.opt != 0 && row.opt == Math.abs(this.currentItem.opt) && this.articleGroupsRawData.every((gr) => gr.CDART != row.cdart)) {
                  itemsToBeRemoved.push(row.idlig);
                }
              }
            );

            for (const _row_group of this.currentArticleChosenGroups.GROUPS) {
              for (const _row_option of _row_group.GROUP_CHOSEN_OPTIONS) {
                if(!_row_option.SELECTED) {
                  let oldTypeOptionExists = this.panier.articles.find((_r_pan_art) => (_r_pan_art.opt == Math.abs(this.currentItem.opt) && _r_pan_art.cdart == _row_option.CDART));
                  if(oldTypeOptionExists !== undefined && oldTypeOptionExists !== null) {
                    itemsToBeRemoved.push(oldTypeOptionExists.idlig);
                  }
                }
              }
            }

            if(itemsToBeRemoved !== undefined && itemsToBeRemoved !== null && itemsToBeRemoved.length > 0) {
              let removeLinebondeCommandeData = {
                pMag: this.panier.mag,
                pNumBon: this.bonNumBon,
                pLignes: itemsToBeRemoved.join(';'),
                pMotif: '',
                Id_User: +this.user.CIINT,
                Id_InstUser: +this.user.IDINSTINT
              };

              await this.deleteBonDeCommandeLigne(removeLinebondeCommandeData, false);
            }

            let position = this.currentItem.idlig + 1;
            let prevGroup = undefined;

            for (const _row_group of this.currentArticleChosenGroups.GROUPS) {
              let groupPosition = _row_group.PANIER_POSITION;
              let groupChosenCount = _row_group.CHOSEN_COUNT;
              let groupMax = _row_group.CHOOSE_MAX;

              let nextOpt: number = this.panier.articles.find(__r => __r.idlig == this.currentItem.idlig)?.opt;
              if(this.currentItem.opt == 0) {
                this.panier.articles.map((_r) => {
                  if(_r.opt < 0) {
                    nextOpt = _r.opt;
                  }

                  return _r;
                })
                nextOpt = nextOpt - 1;
              }

              for (const _r of this.panier.articles) {
                if(_r.opt == Math.abs(nextOpt)) {
                  position = _r.idlig + 1;
                }
              }

              if(prevGroup !== undefined && (groupPosition === undefined || groupPosition === null)) {
                groupPosition = prevGroup.PANIER_POSITION + 1;
              }

              if(groupPosition !== undefined && groupPosition !== null) {
                position = groupPosition;
              }

              for (const _row_option of _row_group.GROUP_CHOSEN_OPTIONS) {
                if(_row_option.SELECTED) {
                  let checkIfExists = this.panier.articles.find((_r) => _r.cdart == _row_option.CDART && _r.opt == Math.abs(nextOpt));
                  if(checkIfExists === undefined || checkIfExists === null) {
                    await this._newCommandeLineOptionsInsertion(position, _row_option, this.currentItem, nextOpt);
                    this.currentArticleChosenGroups.GROUPS.map(
                      (_r_gr: any) => {
                        if(_r_gr.PANIER_POSITION !== undefined && _r_gr.PANIER_POSITION !== null) {
                          _r_gr.PANIER_POSITION = +(_r_gr.PANIER_POSITION) + 1;
                        }

                        return _r_gr;
                    });
                    position = position + 1 // next to the last idligne from corresponding group options
                  } else {
                    position = checkIfExists.idlig + 1 // next to the last idligne from corresponding panier article
                  }
                }
              }

              prevGroup = _row_group;
            }
            this.__updateBonDeCommandeList();
            this.loadingService.hide();
            this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});                
          } else {
            this.loadingService.hide();
            this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});  
          }
        } else {
          this.loadingService.hide();
          this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});
        }
      }
    );
  }

  openUpCommmandeArticleGroups(listGroups: any, currentPanierArticle: PanierArticle, currentArticle: Article, TYPECDE: string): Promise<any> {
    const dialogRef = this.dialog.open(VenteArticleGroupsComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.panier,
          listArticleGroups: listGroups,
          currentItemFormData: undefined,
          TYPECDE: TYPECDE,
          flowType: 'edit',
          pageType: 'commande', // commande, others
          currentItem: currentPanierArticle,
          currentArticle: currentArticle
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  async _newCommandeLineOptionsInsertion(position: number,optionArticle: any, parentPanierArticle: PanierArticle, newOpt?: number): Promise<void> {
    return new Promise(
      async (resolve, reject) => {
      const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
      const article_data = {
        idlig: position,
        cdart: optionArticle.CDART,
        quantite: parseFloat(optionArticle.QTY.toString()),
        prix: parseFloat(optionArticle.PRIX.toString()), // Article prix
        prixforce: false,
        motifprixforce: "",
        commlig1: "",
        commlig2: "",
        prixachatHT: 0.0,
        prixachatTTC: 0.0,
        tauxTVA: "", // Optional
        typecde: parentPanierArticle.typecde,
        remlig: 0, // Total remise amount of the row
        remtot: 0,
        datafid: "",
        datafidaco: "",
        dataopeco: "" ,
        datarem: "",
        okdiff: false,
        bundle: 0,
        opt: Math.abs(newOpt?newOpt:parentPanierArticle.opt),
        lib: optionArticle.CALIB,
        idinstuser: +this.user.IDINSTINT,
        iduser: +this.user.CIINT,
        libuser: this.user?(this.user.CIPRENOM+' '+this.user.CINOM[0]+'.'):'',
        dtpromesse: current_date, // 2018-03-27 10:04:41
        coinsolde: false,
        expo: false,
        motifcde: '',
        numcde: '',
        prixupv: parseFloat(optionArticle.PRIX.toString()), // New Price (Typeable) => PU Ven.
        montant: parseFloat((optionArticle.PRIX * optionArticle.QTY).toString()), // Row Subtotal - PV Total
        remise: 0,
        emplacement: "",
        statut: 0,
        dtmaj: current_date
      };
  
      const insertionData = {
        pMag: this.USERCMINT, 
        pNumBon: this.bonNumBon,
        pArticle: article_data,
        pPosition: position 
      };

      insertionData['Id_User'] = this.user.CIINT;
      insertionData['Id_InstUser'] = Constants.CIINST;
  
      this.insertNewOptionCallAPI(insertionData, false).then(
        async () => {
          if (this.panier.magstock && this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
            await this._addMagStockHistory(this.user.CIINT, article_data.cdart, false);

            if (this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
              await this._addMagStockHistory(this.authorizedArticleStockMagUser.CIINT, article_data.cdart);
            }
          }

          resolve()
        }
      );
    })
  }

  _addMagStockHistory(CIINT: string, CDART?: any, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART ? CDART : this.currentItem.cdart,
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: null,
      VALUER2: null,
    }, HISTORY_AUTHORIZATION);
  }

  _addModificationItemHistory(CDART: string, QTY: number, PRIX: number): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_MODIFY_ARTICLES_BON_AUTRE_VENDEUR,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedModifyItemUser !== undefined && this.authorizedModifyItemUser !== null) ? this.authorizedModifyItemUser.CIINT : this.user.CIINT,
      VALUER1: QTY,
      VALUER2: PRIX,
    });
  }

  async _addAutreArticleLinkHistory(autreCDART: string, autreBON: string, currentCDART, unlink: boolean = false, historyData?: any) {
    let ARTEAN = '';
    let MOTIF = '';

    if (unlink) {
      ARTEAN = 'idem';
      MOTIF = 'idem';

      if(historyData?.ARTEAN && historyData?.MOTIF) {
        ARTEAN = historyData?.ARTEAN;
        MOTIF = historyData?.MOTIF;
      }
    } else {
      ARTEAN = currentCDART;
      MOTIF = `“Bon lié N° : ${autreBON} ; Article : ${autreCDART}`;
    }

    return await this.historyService.addHistory({
      NumBon: this.bonNumBon,
      mag: this.USERCMINT,
      iduser: this.user.CIINT,
      ARTEAN,
      MOTIF,
      OPERAT : unlink ? this.translateService.instant('kunlink_autre_article') : this.translateService.instant('klink_autre_article'),
      VALUER1: null,
      VALUER2: null,
    });
  }

  insertNewOptionCallAPI(data: { pMag: any, pNumBon: string, pArticle: PanierArticle, pPosition: number }, closeLoader: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if(data == undefined || data == null) {
        resolve(null);
      }

      this.bonCommandeService.newOptionInsertion(data).subscribe(
        (resp) => {
          if(resp.statusCode == 200) {
            this.bonNumBon = resp.data.NumBon;
            
            this.panier = resp.data.Panier;
            // List update after a success response
            const oldListData = this.storageService.getItem('bonCommandeListData');
            if(oldListData !== undefined && oldListData !== null) {
              oldListData.data.Bons.map(
                (row: any) => {
                  if(row.numbon == this.bonNumBon) {
                    row.montant = this.panier.montant;
                    row.dtrel = this.panier.dtrel;
                    row.typecde = this.panier.typecde;
                    row.dtech = this.panier.dtech;
                    row.dtmaj = this.panier.dtmaj;
                  }
                  return row;
                }
              );

              const updatedCommande = oldListData.data.Bons.find(
                (row: any) => row.numbon == this.bonNumBon
              );

              let updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
              updated_row_removed.unshift(updatedCommande);

              oldListData.data.Bons = updated_row_removed;

              this.storageService.setItem('bonCommandeListData', oldListData);
            }

            if(closeLoader) {
              this.loadingService.hide();
            }
            
            resolve(resp);
          } else {
            
            if(closeLoader) {
              this.loadingService.hide();
            }

            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve(resp);
          }        
        },
        err => {
          
          if(closeLoader) {
            this.loadingService.hide();
          }

          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      )
    });
  }

  editBonDeCommande(bonCommandeData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyBonCommandeFull(bonCommandeData).subscribe(
        (resp) => {
          this.loadingService.hide();
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.__updateBonDeCommandeList();
              resolve({ response: resp });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              reject({ response: resp });
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            resolve({ response: resp });
          }
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    });     
  }

  __updateBonDeCommandeList() {
    // List update after a success response
    const oldListData = this.storageService.getItem('bonCommandeListData');
    if(oldListData !== undefined && oldListData !== null && oldListData.data != null) {
      oldListData.data.Bons = oldListData.data.Bons.filter((_r: any) => _r !== null && _r != 'null');
      oldListData.data.Bons.map(
        (row: any) => {
          if(row.numbon == this.bonNumBon) {
            row.montant = this.panier.montant;
            row.dtrel = this.panier.dtrel;
            row.typecde = this.panier.typecde;
            row.dtech = this.panier.dtech;
            row.statut = this.panier.statut;
            row.dtmaj = this.panier.dtmaj;
          }
          return row;
        }
      );

      const updatedCommande = oldListData.data.Bons.find(
        (row: any) => row.numbon == this.bonNumBon
      );

      let updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
      updated_row_removed.unshift(updatedCommande);

      oldListData.data.Bons = updated_row_removed;

      this.storageService.setItem('bonCommandeListData', oldListData);
    }
  }

  updateExpoSolde() {
    const expo = this.editItemForm.get('EXPO').value;
    const coin_solde = this.editItemForm.get('COIN_SOLDE').value;
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const panierData = {
      ...this.panier,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    panierData.articles.map((item) => {
      if(item.idlig == this.currentItem.idlig && item.cdart == this.currentItem.cdart) {
        item.expo = expo;
        item.coinsolde = coin_solde;

        item.idinstuser = +this.user.IDINSTINT;
        item.iduser = +this.user.CIINT;
        item.libuser = this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj;

        return item;
      }
      return item;
    });    

    let bondeCommandeData = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pPanier: panierData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };

    this.editBonDeCommande(bondeCommandeData).then(
      () => {
        this.panier = panierData;
      }
    );
  }

  searchArticleGroupsCAINT(CAINT: any): Promise<any> {
    return new Promise((resolve, reject) => {

      let CMINT = this.USERCMINT;
      if (this.panier.magstock) {
        CMINT = this.panier.magstock;
      }

      this.bonVenteService.getArticleGroupsByCAINT(CAINT, CMINT, this.user.CIINT, this.USERCMINT).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            if(res.data.length > 0) {
              let groupsData = res.data;
              this.articleGroupsRawData = groupsData;
              this.listArticleGroups = [];
  
              if(groupsData !== undefined && groupsData != null && groupsData.length > 0) {
                groupsData.map((row: any) => {
                  let groupAvail = this.listArticleGroups.find((_row) => _row.group_id == row.id);
    
                  if(groupAvail === undefined || groupAvail === null) {
                    this.listArticleGroups.push({
                      group_id: row.id,
                      group_label: row.label,
                      group_min: row.selectable_qtymin,
                      group_max: row.selectable_qty,
                      group_custom: row.custom,
                      group_type: row.TYPECDE,
                      group_deposit: row.DEPOSIT,
                      group_active: row.ACTIVE,
                      group_position: row.grouppos,
                      group_articles: [row]
                    });
                  } else {
                    this.listArticleGroups.map((_row) => {
                      if(_row.group_id == row.id) {
                        _row.group_articles.push(row);
                      }
                    });                
                  }              
                });
              }                      
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
            }   
            resolve(res);
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            resolve(res);
          }      
        },
        (err) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    })
  }

  async checkArticleIsLinkedToAutreBon() {
    await this.getHistoryLists();

    return (this.isLinkedToAutreArticleHistory && this.isLinkedToAutreArticleHistory?.ARTEAN) && this.isLinkedToAutreArticleHistory.ARTEAN === this.currentItem.cdart;
  }

  async getHistoryLists() {
    return new Promise((resolve, reject) => {

      this.bonVenteService.getBonVenteHistorique(this.bonNumBon, this.panier.mag).subscribe(
        (response) => {
            if(response.success !== undefined && response?.data?.length > 0) {
              const autreLinkedTraces = response.data.filter(item => item.OPERAT.includes(this.translateService.instant('klink_autre_article')));
              this.isLinkedToAutreArticleHistory = autreLinkedTraces.find(item => item.ARTEAN === this.currentItem.cdart)
            }
            resolve(response);
        }, error => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(error);
        }
      );
    });
  }

  supprimerCommandeLineItem(motif: string) {
    if(this.panier === undefined || this.panier === null) {
      return;  
    }

    this.loadingService.show();    
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let article_data: PanierArticle[] = [...this.panier.articles];
    let pLignes: number[] = [this.currentItem.idlig];

    if (this.linkedStatus === 'service' && this.currentItem.opt > 0) {
      const otherServiceChildren = article_data.filter((rw) => rw.idlig !== this.currentItem.idlig && rw.opt === this.currentItem.opt);

      if (otherServiceChildren.length === 0) {
        article_data.map(rw => {
          if (rw.opt === (-this.currentItem.opt)) {
            rw.opt = 0;
          }

          return rw;
        })
      }
    }
    
    article_data = this.panier.articles.filter(
      (item) => {
        if(this.currentItem.opt < 0 && item.opt == Math.abs(this.currentItem.opt)) {
          pLignes.push(item.idlig);
          return false;
        }

        return item.idlig != this.currentItem.idlig;
      }
    );
    this.panier.articles = article_data;

    let bondeCommandeData = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pLignes: pLignes.join(';'),
      pMotif: (motif !== undefined && motif !== null)?motif:'',
      Id_User: +this.user.CIINT,
      Id_InstUser: +this.user.IDINSTINT
    };

    this.deleteBonDeCommandeLigne(bondeCommandeData);
  }

  deleteConfirmation(): void {
    if(this.linkedStatus === 'service' && this.currentItem.opt > 0) {
      this.supprimerCommandeLineItem('');
            
    } else {
      let title = '';
      let message = `
      <h1 class="text-left mb-3">${this.translateService.instant('kboncomsupprimercmd')}</h1>
      <h1 class="text-left pl-2">        
        ${this.translateService.instant('kbonNum')} : ${this.bonNumBon} <br>
        ${this.translateService.instant('kbonpour')}: ${this.panier.client?.prenom+' '+this.panier.client?.nom} <br>
        ${this.translateService.instant('kbonmontant')}: ${this.currencyPipe.transform(this.panier.montant, null, false)}
      </h1>
      `;
  
      if(this.panier.articles.length > 1) {
        title = this.translateService.instant('kboncomsupprimerligne');
        message = '';
      }
  
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '60vw',
        data: {
          title: title,
          description: message
        }     
      });
  
      dialogRef.afterClosed().subscribe(async result => {
        if(result === "OK") {
          this.loadingService.show();

          this.checkArticleIsLinkedToAutreBon().then(res => {
            if(res) {
              // Add history trace for removing non-service article.
              this._addAutreArticleLinkHistory('', '', '', true, this.isLinkedToAutreArticleHistory)

              if(this.panier.articles.filter((_r) => _r.opt <= 0).length > 1) {
                this.supprimerCommandeLineItem('');
              } else {
                this.deleteBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pMotif: '' });
              }
            } else {
              this.openUpBondeCustomMotif();
            }
          })
        }
      });
    }
  }

  openUpBondeCustomMotif(): void {
    const dialogRef = this.dialog.open(BlocageCustomMotifComponent, {
      maxWidth: '40vw',
      data: {
        action: Constants.MOTIF_SUPPRESSION_COMMANDE
      }
    });

    dialogRef.afterClosed().subscribe(result => {          
      if(result && result.status === "success") {
        const custom_motif_data = result.data;
        
        let motifText = custom_motif_data.MOTLIB;
        if(this.panier.articles.filter((_r) => _r.opt <= 0).length > 1) {
          this.supprimerCommandeLineItem(motifText);
        } else {
          this.deleteBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pMotif: motifText });
        }
      }      
    });    
  }

  openUpNonServiceArticleSelection() {
    const dialogRef = this.dialog.open(NonServiceArticlesListComponent, {
      width: '40vw',
      maxWidth: '40vw',
      maxHeight: '80vh',
      data: {
        currentItem: this.currentItem,
        nonServiceArticles: this.listNonServiceArticles,
        nonServiceItems: this.listNonServiceItems,
        nonServiceArticleHashTable: this.cdartArticlesHashTable,
        panier: this.panier,
        isBonDeCommande: true,
        bonNumBon: this.bonNumBon,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        const chosenLineId = result.data.CHOSEN_IDLIG;

        const serviceItem = this.currentItem; // Service
        const nonServiceItem = this.listNonServiceItems.find(rw => rw.idlig == chosenLineId); // Non service

        this._linkNonServiceToService(serviceItem, nonServiceItem);
      }

      if (result && result.status === 'success') {
        
        if (result.data.CHOSEN_IDLIG !== undefined && result.data.CHOSEN_IDLIG !== null) {
          const chosenLineId = result.data.CHOSEN_IDLIG;
          
          const serviceItem = this.currentItem; // Service
          const nonServiceItem = this.listNonServiceItems.find(rw => rw.idlig == chosenLineId); // Non service
          
          this._linkNonServiceToService(serviceItem, nonServiceItem);
        } else {
          // Add History record for adding non service article from another bon-de-commande
          if (result?.data?.NUMBON && result?.data?.AUTRE_CHOSEN_CDART) {
            this._addAutreArticleLinkHistory(result.data.AUTRE_CHOSEN_CDART, result.data.NUMBON, this.currentItem.cdart)

            this.editItemFormSubmitter()
          }
        }
      }
    });
  }

  async _linkNonServiceToService(serviceItem: PanierArticle, nonServiceItem: PanierArticle) {
    if (nonServiceItem === undefined || nonServiceItem === null) {
      return;
    }

    this.loadingService.show();

    let article_data: PanierArticle[] = [...this.panier.articles];

    let currentIdLigne = 1;
    let nextOpt = 0;
    article_data.forEach((_r) => {
      if(_r.opt < 0) {
        nextOpt = _r.opt;
      }
    });
    nextOpt = nextOpt - 1;

    currentIdLigne = nonServiceItem.idlig;
    if (nonServiceItem.opt < 0) {
      // If it's already a parent.
      nextOpt = nonServiceItem.opt;

      const filteredChildren = [...article_data]
          .filter(rw => rw.opt === Math.abs(nonServiceItem.opt))
          .map(rw => rw.idlig);

      if (filteredChildren.length > 0) {
        currentIdLigne = Math.max(...filteredChildren);
      }
    }

    const QTE = nonServiceItem.quantite;
    let PRIX_UPV = serviceItem.prixupv;
    let PRIX_PVT = PRIX_UPV * QTE;

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const whole_remise_percent = (this.panier.remise !== undefined && this.panier.remise !== null) ? +this.panier.remise : 0.0;
    let line_remise_percent = (this.currentItem.remise !== undefined && this.currentItem.remise !== null)?+this.currentItem.remise:0.0;
    let line_remise_prix = 0,
        line_remise_value = 0,
        total_line_remise = 0,
        line_remtot = 0,
        line_subtotal = 0;

    const itemRemPercentage = this.currentItem.remise;

    line_remise_percent = itemRemPercentage;

    if(line_remise_percent > 0) {
      line_remise_value = serviceItem.prix * (line_remise_percent / 100);
      line_remise_prix = serviceItem.prix - line_remise_value;

      total_line_remise = line_remise_value * +QTE;
      line_subtotal = line_remise_prix * +QTE;

      PRIX_UPV = line_remise_prix;
      PRIX_PVT = line_subtotal;
    }

    if(whole_remise_percent > 0) {
      line_remtot = total_line_remise * (whole_remise_percent / 100);
    }

    const serviceChildItem = {
      ...serviceItem,
      opt: Math.abs(nextOpt),
      quantite: QTE,
      typecde: nonServiceItem.typecde,
      prixupv: PRIX_UPV,
      montant: PRIX_PVT,

      remise: line_remise_percent,
      remlig: parseFloat(total_line_remise.toString()),
      remtot: parseFloat(line_remtot.toString()), // Total remise amount of the row

      idusermaj: +this.user.CIINT,
      idinstusermaj: +this.user.IDINSTINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : serviceItem.libuser,
      dtmaj: current_date
    };

    let grand_total_amount = 0;

    // Removing the service item from the article_data array
    article_data.splice(article_data.findIndex(rw => rw.idlig === serviceItem.idlig), 1);

    // Inserting the service item into the article_data array in a specific position
    article_data.splice(article_data.findIndex(rw => rw.idlig === currentIdLigne) + 1, 0, serviceChildItem);

    article_data = article_data.map((row) => {
      if (row.idlig === nonServiceItem.idlig) {
        // Update the `opt` only to the chosen Non Service article
        row.opt = nextOpt;

        row.dtmaj = current_date;
        row.idusermaj = +this.user.CIINT;
        row.idinstusermaj = +this.user.IDINSTINT;
        row.libusermaj = this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : row.libusermaj;
      }

      grand_total_amount += row.montant;

      return row;
    });

    let dataRem = "";
    let remTotal = 0;
    const remPercentage = whole_remise_percent;

    if(whole_remise_percent !== undefined && whole_remise_percent !== null && whole_remise_percent > 0) {
      remTotal = grand_total_amount * (remPercentage / 100);

      dataRem = this.panier.datarem;
      remTotal = this.panier.remtot;
    }

    const bondeVenteData = {
      ...this.panier,
      articles: article_data,

      idinstusermaj : this.user.IDINSTINT,
      idusermaj : +this.user.CIINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.panier.libuser,

      remtot : remTotal,
      datarem : dataRem,
      montant : grand_total_amount,
      montantHT: grand_total_amount,
      dtmaj : current_date,
    };

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    let bondeCommandeData = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pPanier: bondeVenteData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };
    
    await this.editBonDeCommande(bondeCommandeData).then(
      async () => {
        this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});                
      }
    );
    
    this.loadingService.hide();
  }
  
  deleteBonDeCommandeLigne(data: { pMag: number, pNumBon: string, pLignes: string, pMotif: string, Id_User: number, Id_InstUser: number }, closable: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.removeBonCommandeLineItem(data).subscribe(
        (resp) => {
          if(closable) {
            this.loadingService.hide();
          }
          if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                if(data.pLignes.indexOf(';') > -1) {
                  const removedRows: any = data.pLignes.split(';');
                  this.panier.articles = this.panier.articles.filter((_rart) => {
                    return removedRows.indexOf(_rart.idlig) == -1
                  });
                } else {
                  const removedRow = +data.pLignes;
                  this.panier.articles = this.panier.articles.filter((_rart) => _rart.idlig != removedRow);
                }

                if(closable) {
                  this.dialogRef.close({status: 'success', numBon: this.bonNumBon, panier: this.panier});
                }
                resolve(resp);
              } else {
                this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                resolve(resp);
              }          
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
              resolve(resp);
            }         
        },
        error => {        
          if(closable) {
            this.loadingService.hide();
          }
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
          reject(error);
        }
      );
    });
  }

  deleteBonDeCommande(data: { pMag: number, pNumBon: string, pMotif: string }) {
    this.bonCommandeService.removeBonCommande(data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {

              const oldListData = this.storageService.getItem('bonCommandeListData');

              if(oldListData !== undefined && oldListData != 'null' && oldListData !== null) {
                oldListData.data.Bons = oldListData?.data?.Bons.filter((row) => row.numbon != data.pNumBon);
              }

              this.storageService.setItem('bonCommandeListData', oldListData);

              this.dialogRef.close({status: 'removed', numBon: this.bonNumBon, panier: this.panier});
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }          
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
          }         
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
      }
    );
  }
}
