import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import {Constants} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  defaultConfig: any;

  constructor(private http: HttpClient) { }


  getAppConfig() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.http.get(Constants.DEFAULT_APP_CONFIG_PATH, { headers }).subscribe((res: any) => {
      this.defaultConfig = res
    });

    return this.http.get(Constants.APP_CONFIG_PATH, { headers }).pipe(
      map(
        ((appConfig: any) => {
          const updatedAppConfig = cloneDeep(appConfig);

          // Articles - Check if required keys are available, if not, use the default configs 
          const articlesListDefaultSort = get(appConfig, 'articles.list.default_sort', null);
          !articlesListDefaultSort && set(updatedAppConfig, 'articles.list.default_sort', this.defaultConfig.articles.list.default_sort)
          
          const articlesListColumns = get(appConfig, 'articles.list.columns', null);
          !articlesListColumns && set(updatedAppConfig, 'articles.list.columns', this.defaultConfig.articles.list.columns)

          // BonVente - Check if required keys are available, if not, use the default configs 
          const bonVenteListColumns = get(appConfig, 'bonVente.list.columns', null);
          !bonVenteListColumns && set(updatedAppConfig, 'bonVente.list.columns', this.defaultConfig.bonVente.list.columns)

          const bonVenteCreateColumns = get(appConfig, 'bonVente.create.columns', null);
          !bonVenteCreateColumns && set(updatedAppConfig, 'bonVente.create.columns', this.defaultConfig.bonVente.create.columns)

          const bonVenteModifyColumns = get(appConfig, 'bonVente.modify.columns', null);
          !bonVenteModifyColumns && set(updatedAppConfig, 'bonVente.modify.columns', this.defaultConfig.bonVente.modify.columns)

          return updatedAppConfig;
        })
      ),
    );
  }
}
