import { Component, OnInit, Inject, ViewChild, ElementRef  } from '@angular/core';
import { BonVenteService } from '../../../providers/api/bonventeService';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import { MagasinStructModel } from 'models/magasinStructModel';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';
import { StorageService } from 'providers/api/storageService';


@Component({
  selector: 'app-bon-de-commade-filter',
  templateUrl: './bon-de-commade-filter.component.html',
  styleUrls: ['./bon-de-commade-filter.component.scss'],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},BonVenteService]
})
export class BonDeCommadeFilterComponent implements OnInit {

  @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;
  @ViewChild('f', { static: false }) searchFormRef: NgForm;

  // champs de recherche
  searchData: any = null;
  user: any;
  USERCMINT: any;
  showLoader = true;
  searchForm: UntypedFormGroup;
  searched: boolean = false;

  dateAct: string = 'equal';
  minDateAct: any = null;
  echDateAct: string = 'equal';
  minEchDateAct: any = null;

  pageType: 'commande' | 'relance' | 'historique' | 'supprimees' = 'commande';
  pageTitle = "kkkbonsdecommande";

  magasinStructure: MagasinStructModel[];
  canalList: any = [];
  etatList: { id: string, label: string }[] = [];
  typeCdeList: { id: string, label: string }[] = [];
  autreChoixList: { id: string, label: string }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BonDeCommadeFilterComponent>,
    public bonVenteService: BonVenteService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private storageService: StorageService,
    ) { }

  ngOnInit(): void {
    this.user = this.data.user;
    this.USERCMINT = this.data.USERCMINT;

    this.pageType = this.data.pageType;
    this.pageTitle = this.data.pageTitle;

    this.magasinStructure = this.data.magasinStructure;
    this.canalList = this.data.canalList;
    this.etatList = this.data.etatList;
    this.typeCdeList = this.data.typeCdeList;
    this.autreChoixList = this.data.autreChoixList;

    this.dateAct = this.data.dateAct;

    this.searchForm = this.formBuilder.group({
      IDSOC: [Constants.IDSOC],
      CMINT: this.USERCMINT,
      NUMBON: [''],
      NOM: [''],
      PRENOM: [''],
      CODPOS: [''],
      VILLE: [''],
      EMPORTER_TESTER: [false],
      TEL_PORT: [''],
      TOTAL_ENTRE_FROM: [''],
      TOTAL_ENTRE_TO: [''],
      NUM_EXT: [''],
      DATE: [{ value: '', disabled: true }],
      DATE_END: [{ value: '', disabled: true }],
      DEADLINE: [{ value: '', disabled: true }],
      DEADLINE_END: [{ value: '', disabled: true }],
      ETAT: [''],
      RETRAIT_1H: [false],
      ARTICLE: [''],
      SECTEUR: [''],
      AUTRE_CHOIX: ['-1'],
      TYPECDE: [''],
      CANAL: [''],
    });

    const oldFormData: any = this.storageService.getItem('bonCommandeFormData');

    if(oldFormData !== undefined && oldFormData !== null) {
      this.searchForm.patchValue({
        IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
        CMINT: oldFormData.CMINT !== null?oldFormData.CMINT:this.USERCMINT,
        NUMBON: oldFormData.NUMBON !== null?oldFormData.NUMBON:'',
        NOM: oldFormData.NOM !== null?oldFormData.NOM:'',
        PRENOM: oldFormData.PRENOM !== null?oldFormData.PRENOM:'',
        CODPOS: oldFormData.CODPOS !== null?oldFormData.CODPOS:'',
        VILLE: oldFormData.VILLE !== null?oldFormData.VILLE:'',
        CP_VILLE: oldFormData.CP_VILLE !== null?oldFormData.CP_VILLE:'',
        EMPORTER_TESTER: oldFormData.EMPORTER_TESTER !== null?oldFormData.EMPORTER_TESTER:false,
        TEL_PORT: oldFormData.TEL_PORT !== null?oldFormData.TEL_PORT:'',
        TOTAL_ENTRE_FROM: oldFormData.TOTAL_ENTRE_FROM !== null?oldFormData.TOTAL_ENTRE_FROM:'',
        TOTAL_ENTRE_TO: oldFormData.TOTAL_ENTRE_TO !== null?oldFormData.TOTAL_ENTRE_TO:'',
        NUM_EXT: oldFormData.NUM_EXT !== null?oldFormData.NUM_EXT:'',
        DATE: oldFormData.DATE !== null?oldFormData.DATE:'',
        DATE_END: oldFormData.DATE_END !== null?oldFormData.DATE_END:'',
        DEADLINE: oldFormData.DEADLINE !== null?oldFormData.DEADLINE:'',
        DEADLINE_END: oldFormData.DEADLINE_END !== null?oldFormData.DEADLINE_END:'',
        ETAT: oldFormData.ETAT !== null?oldFormData.ETAT:'',
        RETRAIT_1H: oldFormData.RETRAIT_1H !== null?oldFormData.RETRAIT_1H:false,
        ARTICLE: oldFormData.ARTICLE !== null?oldFormData.ARTICLE:'',
        SECTEUR: oldFormData.SECTEUR !== null?oldFormData.SECTEUR:'',
        AUTRE_CHOIX: oldFormData.AUTRE_CHOIX !== null?oldFormData.AUTRE_CHOIX:'-1',
        TYPECDE: oldFormData.TYPECDE !== null?oldFormData.TYPECDE:'',
        CANAL: oldFormData.CANAL !== null?oldFormData.CANAL:'',
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.filterFirst.nativeElement.select();
      this.filterFirst.nativeElement.focus();
      this.filterFirst.nativeElement.setAttribute('inputmode', 'numeric');
    }, 300);
  }

  changeDateAct(type: string = 'equal') {
    this.dateAct = type;
  }

  changeEchDateAct(type: string = 'equal') {
    this.echDateAct = type;
  }

  headerSearchFormSubmitter () {
    this.userService.headerSearchInputValue.next(undefined);
    this.dialogRef.close({
      status: 'success',
      form_data: this.searchForm.getRawValue(),
      dateAct: this.dateAct
    });
  }

  _barCodeLogicCheck = (value: string, emulation = false) => {
    if(value !== undefined && value != null) {
      
      if(value.indexOf('~') > -1) {
        const [CDART, PRIX] = value.split('~');
        this.searchForm.patchValue({ ARTICLE: CDART });

        if(emulation) {
          if(this.searchFormRef) {
            this.searchFormRef.ngSubmit.emit();
          } else {
            this.headerSearchFormSubmitter()
          }
        }

        return;
      }

      if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
        let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
        CDART = CDART.replace(/^0+/, '');

        this.searchForm.patchValue({ ARTICLE: CDART });

        if(emulation) {
          if(this.searchFormRef) {
            this.searchFormRef.ngSubmit.emit();
          } else {
            this.headerSearchFormSubmitter()
          }
        }

        return;
      }

      this.searchForm.patchValue({ ARTICLE: value });
      if(emulation) {
        if(this.searchFormRef) {
          this.searchFormRef.ngSubmit.emit();
        } else {
          this.headerSearchFormSubmitter()
        }
      }
    }
  }

  onCodeEntered(e: any) {
    const cdart = this.searchForm.get('ARTICLE');
    this._barCodeLogicCheck(cdart.value, true);
  }

  openScanArticle() {
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: '95vh',
        autoFocus: false
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        this._barCodeLogicCheck(result.data);
      }
    });
  }

  onDateChanged(type: 'create' | 'deadline') {
    if(type === 'create') {
      this.minDateAct = this.searchStartDate.value?this.searchStartDate.value:null;
      if(this.minDateAct !== null) {
        this.searchEndDate.setValue(this.minDateAct);
      }
    } 

    if(type === 'deadline') {
      this.minEchDateAct = this.searchStartDeadlineDate.value?this.searchStartDeadlineDate.value:null;
      if(this.minEchDateAct !== null) {
        this.searchEndDeadlineDate.setValue(this.minEchDateAct);
      }
    }
    
  }

  get searchStartDate() {
    return this.searchForm.get('DATE');
  }

  get searchEndDate() {
    return this.searchForm.get('DATE_END');
  }

  get searchStartDeadlineDate() {
    return this.searchForm.get('DEADLINE');
  }

  get searchEndDeadlineDate() {
    return this.searchForm.get('DEADLINE_END');
  }

  _clearDate = (form_field: string) => {
    this.searchForm.get(form_field).setValue('');

    if(form_field == 'DATE') {
      this.minDateAct = null;
    }

    if(form_field == 'DEADLINE') {
      this.minEchDateAct = null;
    }
  }

  resetForm() {
    this.userService.headerSearchInputValue.next(undefined);

    this.searchForm.reset();
    this.storageService.removeItem('bonCommandeFormData');

    this.dateAct = 'equal';
    this.echDateAct = 'equal';

    this.searchForm.patchValue({
      IDSOC: Constants.IDSOC,
      CMINT: this.USERCMINT,
      SECTEUR: '',
      AUTRE_CHOIX: '-1',
      TYPECDE: '',
      EMPORTER_TESTER: false,
      RETRAIT_1H: false,
      CANAL: '',
      ETAT: ''
    });
  }

}
