<div class="modal-container" id="couche_prix_article">
    <div class="row no-gutters">
        <div class="col-12">
            <div *ngIf="pageType === 'commande'">
                <h2 class="text-center">{{ currentItem.lib }}</h2>
                <h4 class="mb-4 text-center">{{ currentItem.cdart }}</h4>
            </div>
            <form [formGroup]="articleGroupForm" (ngSubmit)="articleGroupFormSubmitter()">
                <mat-vertical-stepper formArrayName="GROUPS" [linear]="true" #stepper *ngIf="flowType == 'new'">
                    <ng-template matStepperIcon="edit">
                        {{ currentStepIndex + 1 }}
                    </ng-template>

                    <div>
                        <mat-step [formGroupName]="currentStepIndex" [stepControl]="articleFormGroups?.get([currentStepIndex])">
                            <div>
                                <form [formGroup]="articleFormGroups?.get([currentStepIndex])" (ngSubmit)="stepFormSubmitter(currentStepIndex)">
                                    <ng-template matStepLabel>{{ currentFormGroup(currentStepIndex).value.GROUP_LABEL | titlecase }}</ng-template>
                                    
                                    <div *ngIf="currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors" class="mb-3">
                                        <h4 class="text-danger" *ngIf="currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors && currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors.min">{{ 'kkchoisirmoins' | translate: { min_option: currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors.min.min } }}</h4>
                                        <h4 class="text-danger" *ngIf="currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors && currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors.max">{{ 'kkchoisirmax' | translate: { max_option: currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors.max.max } }}</h4>
                                    </div>
    
                                    <mat-list>
                                        <mat-list-item  *ngFor="let group_option of currentFormGroup(currentStepIndex).get('GROUP_CHOSEN_OPTIONS')?.controls;let j=index" class="test">
                                            <div class="row no-gutters mb-2">
                                                <div class="col-2 col-md-2">
                                                    <div class="stepper-image-holder">
                                                        <img [src]="getImageURL(group_option.value.CDART)" [alt]="group_option.value.CDART" default="assets/images/no_img.png" />
                                                    </div>
                                                </div>
                                                <div class="col-10 col-md-10 d-flex">
                                                    <div class="d-flex w-100 justify-content-between align-items-center">
                                                        <mat-checkbox [(ngModel)]="group_option.value.SELECTED" (ngModelChange)="optionChanged(currentStepIndex, group_option.value.CAINT)" [ngModelOptions]="{standalone: true}" matLine>{{ group_option.value.CALIB }}</mat-checkbox>
                                                        <span class="badge" *ngIf="group_option.value.PRIX != null && group_option.value.PRIX !== '' && group_option.value.PRIX > 0">{{ group_option.value.PRIX | cap_currency }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
    
                                    <div class="d-flex" [class]="currentStepIndex !== 0?'justify-content-between':'justify-content-end'">
                                        <button mat-button *ngIf="currentStepIndex !== 0" (click)="previousStep(currentStepIndex)" matStepperPrevious>
                                            <mat-icon>arrow_back_ios</mat-icon> 
                                            <span class="d-none d-sm-none d-md-block">
                                                {{'ksback' | translate}}
                                            </span>
                                        </button>

                                        <div class="d-flex text-right">
                                            <button type="submit" mat-button class="ml-2" [title]="'ksvalid' | translate" *ngIf="!currentFormGroup(currentStepIndex).get('CHOSEN_COUNT').errors" matStepperNext>
                                                <mat-icon>done</mat-icon> 
                                                <span class="d-none d-sm-none d-md-block">
                                                    {{'ksvalid' | translate}}
                                                </span>
                                            </button>
                                            <button mat-button class="ml-2" [title]="'kucancel' | translate" [mat-dialog-close]>
                                                <mat-icon>close</mat-icon> 
                                                <span class="d-none d-sm-none d-md-block">
                                                    {{'kucancel' | translate}}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </mat-step>
                    </div>
                </mat-vertical-stepper>

                <mat-vertical-stepper formArrayName="GROUPS" [linear]="true" #stepper *ngIf="flowType == 'edit'">
                    <mat-step *ngFor="let item of articleFormGroups.controls;let i=index" [formGroupName]="i" [stepControl]="articleFormGroups?.get([i])">
                        <div>
                            <form [formGroup]="articleFormGroups?.get([i])" (ngSubmit)="stepFormSubmitter(i)">
                                <ng-template matStepLabel>{{ item.value.GROUP_LABEL | titlecase }}</ng-template>

                                <div *ngIf="item.get('CHOSEN_COUNT').errors">
                                    <h4 class="text-danger" *ngIf="item.get('CHOSEN_COUNT').errors && item.get('CHOSEN_COUNT').errors.min">{{ 'kkchoisirmoins' | translate: { min_option: item.get('CHOSEN_COUNT').errors.min.min } }}</h4>
                                    <h4 class="text-danger" *ngIf="item.get('CHOSEN_COUNT').errors && item.get('CHOSEN_COUNT').errors.max">{{ 'kkchoisirmax' | translate: { max_option: item.get('CHOSEN_COUNT').errors.max.max } }}</h4>
                                </div>

                                <mat-list>
                                    <mat-list-item  *ngFor="let group_option of item.get('GROUP_CHOSEN_OPTIONS').controls;let j=index" class="test">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-2 col-md-2">
                                                <div class="stepper-image-holder">
                                                    <img [src]="getImageURL(group_option.value.CDART)" [alt]="group_option.value.CDART" default="assets/images/no_img.png" />
                                                </div>
                                            </div>
                                            <div class="col-10 col-md-10 d-flex">
                                                <div class="d-flex w-100 justify-content-between align-items-center">
                                                    <mat-checkbox [(ngModel)]="group_option.value.SELECTED" (ngModelChange)="optionChanged(i, group_option.value.CAINT)" [ngModelOptions]="{standalone: true}" matLine>{{ group_option.value.CALIB }}</mat-checkbox>
                                                    <span class="badge" *ngIf="group_option.value.PRIX != null && group_option.value.PRIX !== '' && group_option.value.PRIX > 0">{{ group_option.value.PRIX | cap_currency }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                            </form>
                        </div>
                    </mat-step>                            
                </mat-vertical-stepper>

                <div class="text-right" *ngIf="flowType == 'edit'">
                    <button type="submit" mat-button [title]="'ksvalid' | translate" *ngIf="articleFormGroups.valid">
                        <mat-icon>done</mat-icon> 
                        <span class="d-none d-sm-none d-md-block">
                            {{'ksvalid' | translate}}
                        </span>
                    </button>
                    <button mat-button class="ml-2" [title]="'kucancel' | translate" *ngIf="articleFormGroups.valid" [mat-dialog-close]>
                        <mat-icon>close</mat-icon> 
                        <span class="d-none d-sm-none d-md-block">
                            {{'kucancel' | translate}}
                        </span>
                    </button>
                </div>
            </form>
        </div>                
    </div>
</div>