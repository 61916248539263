import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Client } from 'models/clients';
import { LivraisonDetailsComponent } from './livraison-details/livraison-details.component';
import * as moment from 'moment';
import BonPanier from 'models/bonPanier';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import MagComplement from 'models/magComplement';
import { EmporteeDiffereComponent } from '../emportee-differe/emportee-differe.component';
import { BonVente } from 'models/bonventes';
import { Magasin } from 'models/magasins';
import { ClientService } from 'providers/api/clientService';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import { DeviceDetectorService } from 'ngx-device-detector';
import {of, Subject, Subscription} from 'rxjs';
import { ClientFilterComponent } from 'pages/client/client-filter/client-filter.component';
import { SearchClientComponent } from '../search-client/search-client.component';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { User } from 'models/users';
import { DateDePromesseComponent } from '../date-de-promesse/date-de-promesse.component';
import { UtilService } from 'providers/api/utilService';
import {InfoCarrierComponent} from './info-carrier/info-carrier.component';
import Transporteur from '../../../models/transporteur';
import LienList from '../../../models/gc_lien';
import {Article} from '../../../models/articles';
import {Observable} from 'rxjs/Observable';
import { StorageService } from 'providers/api/storageService';
import { ClientHistoriqueComponent } from 'components/client-historique/client-historique.component';

@Component({
  selector: 'app-info-livraison-bonde',
  templateUrl: './info-livraison-bonde.component.html',
  styleUrls: ['./info-livraison-bonde.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoLivraisonBondeComponent implements OnInit, OnChanges {

  private _client: Client;
  advancedDialogRef: any;
  get client(): Client { return this._client }
  @Input() set client(client: Client) {this.clientChange.emit(this._client = client)}
  @Output() private clientChange: EventEmitter<Client> = new EventEmitter<Client>();

  private _livreClient: Client;
  get livreClient(): Client { return this._livreClient }
  @Input() set livreClient(livreClient: Client) {this.livreClientChange.emit(this._livreClient = livreClient)}
  @Output() private livreClientChange: EventEmitter<Client> = new EventEmitter<Client>();

  private _sameAsClient: boolean;
  get sameAsClient(): boolean { return this._sameAsClient }
  @Input() set sameAsClient(sameAsClient: boolean) {
    this.sameAsClientChange.emit(this._sameAsClient = sameAsClient);    
  }
  @Output() private sameAsClientChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _livreDetail: any;
  get livreDetail(): any { return this._livreDetail }
  @Input() set livreDetail(livreDetail: any) {this.livreDetailChange.emit(this._livreDetail = livreDetail)}
  @Output() private livreDetailChange: EventEmitter<any> = new EventEmitter<any>();

  private _transporteurLabel: any;
  get transporteurLabel(): any { return this._transporteurLabel }
  @Input() set transporteurLabel(transporteurLabel: any) {
    this.transporteurLabelChange.emit(this._transporteurLabel = transporteurLabel);

    if(this.livreDetail !== undefined) {
      this.livreDetail = { ...this.livreDetail, transporteurLabel: transporteurLabel};
    }
  }
  @Output() private transporteurLabelChange: EventEmitter<any> = new EventEmitter<any>();  

  private _panier: BonPanier;
  get panier(): BonPanier { return this._panier }
  @Input() set panier(panier: BonPanier) {
    this.panierChange.emit(this._panier = panier);
    this.panierLivreUpdated.next(true);
    
    this.lienLogAvail = false;
    
    if(panier !== undefined && panier !== null) {
      const numBon = this.storageService.getItem('previousNumBon');
      if(numBon !== undefined && numBon != null) {
        this.bonNumBon = numBon;
      }

      if(panier.lienlog !== undefined && panier.lienlog !== null && panier.lienlog !== '') {
        this.lienLogAvail = true;
      }

      this.livreDetail = {
        dtliv: new Date(panier.dtliv),
        dtlivsouhait: new Date(panier.dtlivsouhait),
        livjour: panier.livjour,
        livconfirm: panier.livconfirm,
        livinfo1: panier.livinfo1,
        livinfo2: panier.livinfo2,
        livdeport: panier.livdeport,
        livdirect: panier.livdirect,

        transporteurContent: panier.transporteur,
        transporteurLabel: this.transporteurLabel
      };

      if(this.livraisonDialogRef !== undefined && this.livraisonDialogRef !== null) {
        if(this.livraisonDialogRef.componentInstance?.livraisonDetailsChanged) {
          this.livraisonDialogRef.componentInstance?.livraisonDetailsChanged.next(this.livreDetail);
        }
      }
      
      this.emporteeDiffereDate = panier.dtech;
      this.promesseDate = (panier.dtpromesse != null && moment(panier.dtpromesse).startOf('day').year() !== 1899)?panier.dtpromesse:panier.dtpromessecalc;
      
      this.livreArtAvail = false;
      this.emporteeArtAvail = false;
      this.promesseArtAvail = false;

      this.panier.articles.map(
        (row) => {
          if(row.typecde === 'BL' || row.typecde === 'E2/BL') {
            this.livreArtAvail = true;
          }

          if(row.typecde === 'E2/ED' || row.typecde === 'E2/BL') {
            this.promesseArtAvail = true;
          }

          if(row.typecde === 'ED') {
            this.emporteeArtAvail = true;
          }
        }
      );

      this.livreDate = moment(this.livreDetail.dtliv).format('DD.MM.YYYY');
      this.livreDateYear = moment(this.livreDetail.dtliv).year();
    } else {
      this.livreDetail = undefined;
      this.livreDate = undefined;
      this.livreDateYear = undefined;
    }    
  }
  @Output() private panierChange: EventEmitter<BonPanier> = new EventEmitter<BonPanier>();
  
  @Input() private magComplementData: MagComplement;
  @Input() public prixLivraison: number;

  @Input() listMagasins: Magasin[];
  
  @Input() set flowCMINT(flowCMINT: any) {
    if(flowCMINT !== undefined && flowCMINT != null) {
      this.USERCMINT = flowCMINT;
    } else {
      this.user = this.userService.getCurrentUser();          
      this.USERCMINT = this.user.CMINT;
    }
  }

  @Input() flowType: any;
  @Input() readOnly: boolean = false;

  private _chosenArticle: Article;
  get chosenArticle(): Article { return this._chosenArticle }
  @Input() set chosenArticle(chosenArticle: Article) {
    if(chosenArticle !== undefined && chosenArticle != null) {
      this._chosenArticle = chosenArticle;
    }
  };

  private _modifiableStat: boolean;
  get modifiableStat(): boolean { return this._modifiableStat }
  @Input() set modifiableStat(modifiableStat: boolean) {
    if(modifiableStat !== undefined && modifiableStat != null) {
      this._modifiableStat = modifiableStat;

      if (this.flowType === 'bonde_commande' && modifiableStat === true) {
        if(this.sameAsClient)  {
          this.searchForm.get('client_nom').disable({ onlySelf: true });
        } else {
          this.sectionDisabled = false;
          this.searchForm.get('client_nom').enable({ onlySelf: true });
        }
      } else {
        this.searchForm.get('client_nom').disable({ onlySelf: true });
      }
    }
  }

  @Output() private livraisonTrackUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() private livraisonStatusChange: EventEmitter<number> = new EventEmitter<number>();
  
  user: User;
  USERCMINT: any;

  isMobile: boolean;

  bonNumBon: string;

  searchForm: UntypedFormGroup = this.formBuilder.group({
    same_client: [this.sameAsClient],
    client_nom: [''],
  });
  selectedClient: Client;
  
  searchButtonDisabled: boolean = true;

  livreDate: string;
  livreDateYear: number;

  livreArtAvail: boolean = false;

  emporteeArtAvail: boolean = false;
  emporteeDiffereDate: string;

  promesseArtAvail: boolean = false;
  promesseDate: string;

  newClient: boolean = false;
  editClient: boolean = false;

  magasinNom: string;

  popupCloseSusscription: Subscription;

  userPermission: any;
  sectionDisabled: boolean = false;

  livraisonDialogRef: MatDialogRef<LivraisonDetailsComponent>;

  _openUpLivPop: boolean = false;
  panierLivreUpdated: Subject<boolean> = new Subject();

  lienLogAvail: boolean = false;
  prestataire: string = '';

  carrierData: { value: string, data: Transporteur };

  lienListSubscription: Subscription;
  lienListData: Observable<LienList[]>;
  lienListLoading: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private loadingService: NgxSpinnerService,
    private bonVenteService: BonVenteService,
    private bonCommandeService: BonCommandeService,
    private userService: UserService,
    private utilService: UtilService,
    private dialogService: DialogsService,
    private clientService: ClientService,
    private translateService: TranslateService,
    private deviceDetector: DeviceDetectorService,
    private cdRef: ChangeDetectorRef,
    private storageService: StorageService) {

      this.popupCloseSusscription = this.userService.popupClose.subscribe(closeAction => {
        if(closeAction == false){
           this.closeAdvancedSearch();
        }
      });

    }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();

    this.searchForm.get('client_nom').disable({ onlySelf: true });        

    if(this.livreDetail !== undefined) {
      this.livreDetail = { ...this.livreDetail, transporteurLabel: this.transporteurLabel};
    }
    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.defineSectionStatus();

    this.loadDependencies();

    this.panierLivreUpdated.subscribe((res) => {
      if(res === true && this._openUpLivPop) {
        this._openUpLivraionInfoDetails()
      }
    })
  }

  async loadDependencies() {
    await this._getLivraisonPrestataire();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['chosenArticle'] !== undefined) {
      if(changes['chosenArticle'].previousValue != changes['chosenArticle'].currentValue) {
        if(this.livreArtAvail === true) {
          this._getLienList();
        }
      }
    }

    if(changes['client'] !== undefined) {
      if(!this.sameAsClient) {
        this.selectedClient = this.livreClient;

        if((this.flowType === 'bonde_commande' && !this.modifiableStat) || this.flowType == 'retrait_marchandise') {
          this.searchForm.get('client_nom').disable({ onlySelf: true });
        } else {
          this.searchForm.get('client_nom').enable({ onlySelf: true });
        }
        
        this.searchForm.get('same_client').setValue(false);

        this.refreshClientMagasinNom();
      } else {
        this.selectedClient = changes.client.currentValue;
        this.livreClient = this.selectedClient;

        this.searchForm.get('client_nom').disable({ onlySelf: true });
        this.searchForm.patchValue({
          client_nom: '',
          same_client: true
        });

        this.refreshClientMagasinNom();
      }

      this.defineSectionStatus();
    }
    
    if(changes.sameAsClient !== undefined) {
      const status = changes.sameAsClient.currentValue;
      this.searchButtonDisabled = status;

      if(!status) {
        this.selectedClient = this.livreClient;

        if((this.flowType === 'bonde_commande' && !this.modifiableStat) || this.flowType == 'retrait_marchandise') {
          this.searchForm.get('client_nom').disable({ onlySelf: true });
        } else {
          this.searchForm.get('client_nom').enable({ onlySelf: true });
        }
        
        this.searchForm.get('same_client').setValue(false);

        this.refreshClientMagasinNom();
      } else {      
        this.selectedClient = this.client;
        this.livreClient = this.selectedClient;        

        this.searchForm.get('client_nom').disable({ onlySelf: true });
        this.searchForm.patchValue({
          client_nom: '',
          same_client: true
        });

        this.refreshClientMagasinNom();
      }

      this.defineSectionStatus();
    }
  }
  ngOnDestroy(): void {
    this.popupCloseSusscription.unsubscribe();
  }

  __lienLink(link: string): string {
    return `//${link}`;
  }

  _getLienList(): Promise<void> {
    if(!this.panier || !this.chosenArticle) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.lienListLoading = true;

      const data = {
        CMINT: this.panier.mag,
        NUMBON: this.bonNumBon,
        CAINT: this.chosenArticle.CAINT
      };

      if(this.lienListSubscription) {
        this.lienListSubscription.unsubscribe();
      }

      this.lienListSubscription = this.bonCommandeService.getLivraisonLienList(data).subscribe(
          (res) => {
            if(res.success === 'ok') {
              this.lienListData = of(res.data);
              this.cdRef.markForCheck();
            }

            this.lienListLoading = false;
            resolve();
          },
          (err) => {
            this.lienListLoading = false;
            this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
            reject();
          }
      )
    })
  }

  async _getLivraisonPrestataire() {
    try {
      this.prestataire = await this.userService.getRuleParamsData(20, 21, 'VSTR1');
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  defineSectionStatus() {
    if((this.flowType === 'bonde_commande' && !this.modifiableStat) || this.flowType == 'retrait_marchandise') {
      this.sectionDisabled = true;
      this.searchForm.get('client_nom').disable({ onlySelf: true });
    } else {
      if(this.readOnly) {
        this.sectionDisabled = true;
        this.searchForm.get('client_nom').disable({ onlySelf: true });
      } else {
        if(this.sameAsClient) {
          this.sectionDisabled = true;
          this.searchForm.get('client_nom').disable({ onlySelf: true });
        } else {
          this.sectionDisabled = false;
          this.searchForm.get('client_nom').enable({ onlySelf: true });
        }
      }
    }
  }

  changeSameClient(status?: boolean) {
    this.sameAsClient = this.searchForm.value['same_client'];    
    this.changeSameStatus(this.sameAsClient);
  }

  changeSameStatus(status: boolean) {
    this.searchButtonDisabled = status;

    if(!status) {
      this.selectedClient = undefined;
      this.livreClient = this.selectedClient;      

      if((this.flowType === 'bonde_commande' && !this.modifiableStat) || this.flowType == 'retrait_marchandise') {
        this.searchForm.get('client_nom').disable({ onlySelf: true });
      } else {
        this.searchForm.get('client_nom').enable({ onlySelf: true });
      }      
      
      this.refreshClientMagasinNom();
    } else {      
      this.selectedClient = this.client;
      this.livreClient = this.selectedClient;
      this.updateBondeVente('client');

      this.refreshClientMagasinNom();

      this.searchForm.get('client_nom').disable({ onlySelf: true });
      this.searchForm.patchValue({
        client_nom: ''
      });
    }
  }

  searchClientFormSubmitter(data?: any) {
    let passData = {};
    if(data !== undefined && data != null) {
      passData = {
        ...data,
        CMINT: this.USERCMINT
      };
    } else {
      passData = {
        client_nom: this.searchForm.get('client_nom').value,
        CMINT: this.USERCMINT
      };
    }

    const dialogRef = this.dialog.open(SearchClientComponent, {
      width: '100vw',
      maxHeight: this.isMobile?'95vh':'90vh', 
      data: passData
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.selectedClient = result.client_data;
        this.livreClient = this.selectedClient;

        this.refreshClientMagasinNom();

        this.updateBondeVente('client');
      }
    });
  }

  async openUpLivraionInfoDetails() {
    this.panierLivreUpdated.next(null);
    this.loadingService.show();
    this._openUpLivPop = true;
    this.livraisonTrackUpdated.next(true);
  }

  _openUpLivraionInfoDetails() {
    this._openUpLivPop = false;
    this.livraisonDialogRef = this.dialog.open(LivraisonDetailsComponent, {
      width: '40vw',
      maxWidth: '40vw',    
      data: {
        livreDetail: this.livreDetail,
        magComplementData: this.magComplementData,
        panier: this.panier
      }
    });

    this.livraisonDialogRef.componentInstance.suiviLivraisonClosed.subscribe(
      (onSuiviLivraisonChanged: any) => {
        if(onSuiviLivraisonChanged !== undefined && onSuiviLivraisonChanged !== null && onSuiviLivraisonChanged.status === true) {
          this.loadingService.show();
          this.livraisonTrackUpdated.next(onSuiviLivraisonChanged.status);
        }
      }
    )

    this.livraisonDialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.livreDetail = result.data;
        this.transporteurLabel = this.livreDetail.transporteurLabel;

        this.livreDate = moment(this.livreDetail.dtliv).format('DD.MM.YYYY');
        this.livreDateYear = moment(this.livreDetail.dtliv).year();

        if(this.flowType === 'bonde_commande' || this.flowType == 'retrait_marchandise') {
          this.updateBonCommande('BL');
        } else {
          this.updateBondeVente('livraison');
        }
      }
    });
  }

  openUpCarrier() {
    const carrierDialogRef = this.dialog.open(InfoCarrierComponent, {
      width: '40vw',
      maxWidth: '40vw',
      data: {
        panier: this.panier
      }
    });

    carrierDialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        console.log(result);
        const transporteurVal = result.data;
        const transporteurData = result.transporteurData;

        this.carrierData = { value: transporteurVal, data: transporteurData };

        this.transporteurLabel = transporteurData ? transporteurData.TRALIBL : '';

        this.updateBondeVente('carrier');
      }
    });
  }

  openUpEmporteDiffere() {
    const dialogRef = this.dialog.open(EmporteeDiffereComponent, {
      width: '50vw',
      maxWidth: '50vw',
      data: {
        magComplementData: this.magComplementData,
        panier: this.panier,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.emporteeDiffereDate = result.data.dtemporte;

        if(this.flowType === 'bonde_commande' || this.flowType == 'retrait_marchandise') {
          this.updateBonCommande('ED');
        } else {
          this.updateBondeVente('emporte');
        }        
      }
    });
  }

  openUpDatePromesse() {
    const dialogRef = this.dialog.open(DateDePromesseComponent, {
      width: '50vw',
      maxWidth: '50vw',
      data: {
        magComplementData: this.magComplementData,
        panier: this.panier,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.promesseDate = result.data.dtpromesse;

        if(this.flowType === 'bonde_commande' || this.flowType == 'retrait_marchandise') {
          this.updateBonCommande('promesse');
        } else {
          this.updateBondeVente('promesse');
        }        
      }
    });
  }

  updateBonCommande(type: 'ED' | 'BL' | 'promesse') {
    this.loadingService.show();   
    let bonCommandeData: any = {
      dtliv: this.panier.dtliv,
      dtlivsouhait: this.panier.dtlivsouhait,
      livjour: this.panier.livjour,
      livconfirm: this.panier.livconfirm,
      livinfo1: this.panier.livinfo1,      
      livinfo2: this.panier.livinfo2,
      transporteur: this.panier.transporteur,
      livdeport: this.panier.livdeport,
      livdirecte: this.panier.livdirect,
      dtech: this.panier.dtech,
      txtcomm: this.panier.comm,
      IDMAG: this.panier.mag,
      IDSOC: Constants.IDSOC,
      pNumBon: this.bonNumBon,

      idusermaj: +this.user.CIINT,
      idinstusermaj: +this.user.IDINSTINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
    };

    if(type === 'BL') {
      if(this.livreDetail !== undefined && this.livreDetail !== null) {
        bonCommandeData.dtliv = moment(this.livreDetail.dtliv).format('YYYY-MM-DD HH:mm:ss');
        bonCommandeData.dtlivsouhait = moment(this.livreDetail.dtlivsouhait).format('YYYY-MM-DD HH:mm:ss');
        bonCommandeData.livjour = this.livreDetail.livjour;
        bonCommandeData.livconfirm = this.livreDetail.livconfirm?true:false;
        bonCommandeData.livinfo1 = (this.livreDetail.livinfo1 !== null)?this.livreDetail.livinfo1:'';
        bonCommandeData.livinfo2 = (this.livreDetail.livinfo2 !== null)?this.livreDetail.livinfo2:'';
        bonCommandeData.livdeport = this.livreDetail.livdeport?true:false;
        bonCommandeData.livdirect = this.livreDetail.livdirect?true:false;
  
        bonCommandeData.transporteur = this.livreDetail.transporteurContent;
      } else {
        bonCommandeData.dtliv = moment(this.livreDetail.dtliv).format('YYYY-MM-DD HH:mm:ss');
        bonCommandeData.dtlivsouhait = moment(this.livreDetail.dtlivsouhait).format('YYYY-MM-DD HH:mm:ss');
        bonCommandeData.livjour = '';
        bonCommandeData.livconfirm = false;
        bonCommandeData.livinfo1 = '';
        bonCommandeData.livinfo2 = '';
        bonCommandeData.livdeport = false;
        bonCommandeData.livdirect = false;
        
        bonCommandeData.transporteur = '';
      }
    }

    if(type === 'ED') {
      bonCommandeData.dtech = moment(this.emporteeDiffereDate).format('YYYY-MM-DD HH:mm:ss');
    }

    if(type === 'promesse') {
      bonCommandeData.dtpromesse = moment(this.promesseDate).format('YYYY-MM-DD HH:mm:ss');
    }

    this.bonCommandeService.modifyLivraisonBonCommande(bonCommandeData).subscribe(
      (data) => {
        this.loadingService.hide();
        if(data.statusCode == 200) {
          this.panier.idusermaj = this.livreDetail.idusermaj;
          this.panier.idinstusermaj = this.livreDetail.idinstusermaj;
          this.panier.libusermaj = this.livreDetail.libusermaj;

          if(type === 'BL') {
            if(this.livreDetail !== undefined && this.livreDetail !== null) {
              this.panier.dtliv = this.utilService.formatDateToWS(this.livreDetail.dtliv);
              this.panier.dtlivsouhait = this.utilService.formatDateToWS(this.livreDetail.dtlivsouhait);
              this.panier.livjour = this.livreDetail.livjour;
              this.panier.livconfirm = this.livreDetail.livconfirm?true:false;
              this.panier.livinfo1 = (this.livreDetail.livinfo1 !== null)?this.livreDetail.livinfo1:'';
              this.panier.livinfo2 = (this.livreDetail.livinfo2 !== null)?this.livreDetail.livinfo2:'';
              this.panier.livdeport = this.livreDetail.livdeport?true:false;
              this.panier.livdirect = this.livreDetail.livdirect?true:false;
        
              this.panier.transporteur = this.livreDetail.transporteurContent;
            } else {
              this.panier.dtliv = this.utilService.formatDateToWS(this.livreDetail.dtliv);
              this.panier.dtlivsouhait = this.utilService.formatDateToWS(this.livreDetail.dtlivsouhait);
              this.panier.livjour = '';
              this.panier.livconfirm = false;
              this.panier.livinfo1 = '';
              this.panier.livinfo2 = '';
              this.panier.livdeport = false;
              this.panier.livdirect = false;
              
              this.panier.transporteur = '';
            }
          }
      
          if(type === 'ED') {
            this.panier.dtech = this.utilService.formatDateToWS(this.emporteeDiffereDate);
          }

          if(type === 'promesse') {
            this.panier.dtpromesse = this.utilService.formatDateToWS(this.promesseDate);
          }

          this.storageService.setItem('previousBonPanier', this.panier);
          this.panierChange.emit(this.panier);
        }
      },
      error => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        this.loadingService.hide();
      }
    );
  }

  updateBondeVente(type: 'client' | 'livraison' | 'emporte' | 'promesse' | 'carrier') {
    if(this.panier === undefined || this.panier === null) {
      return;
    }    
    
    this.loadingService.show();    

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let bondeVenteData: BonPanier = {
      ...this.panier,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    if(type === 'client') {
      if(this.livreClient !== undefined && this.livreClient !== null && !this.sameAsClient) {
        bondeVenteData.clientlivre = {
          mag: +this.livreClient.CMINT,
          civil: +this.livreClient.IDCIV,
          nom: this.livreClient.TRAISOC,
          prenom: this.livreClient.TPRENOM,
          adr1: this.livreClient.TADR1,
          adr2: this.livreClient.TADR2,
          adr3: this.livreClient.TADR3,
          adr4: this.livreClient.TADR4,
          codpos: this.livreClient.CODPOS,
          ville: this.livreClient.TVILLE,
          pays: this.livreClient.CODPAYS,
          tel: this.livreClient.TTEL,
          fax: this.livreClient.TFAX,
          gsm: this.livreClient.TPORT,
          email: this.livreClient.TEMAIL,
          idinstcli: +this.livreClient.IDINSTTIE,
          idcli: +this.livreClient.IDTIERS
        };
      } else {
        bondeVenteData.clientlivre = "";
      }
    }    

    if(type === 'livraison') {
      if(this.livreDetail !== undefined && this.livreDetail !== null) {
        bondeVenteData.dtliv = moment(this.livreDetail.dtliv).format('YYYY-MM-DD HH:mm:ss');
        bondeVenteData.dtlivsouhait = moment(this.livreDetail.dtliv).format('YYYY-MM-DD HH:mm:ss');
        bondeVenteData.livjour = this.livreDetail.livjour;
        bondeVenteData.livconfirm = this.livreDetail.livconfirm?true:false;
        bondeVenteData.livinfo1 = (this.livreDetail.livinfo1 !== null)?this.livreDetail.livinfo1:'';
        bondeVenteData.livinfo2 = (this.livreDetail.livinfo2 !== null)?this.livreDetail.livinfo2:'';
        bondeVenteData.livdeport = this.livreDetail.livdeport?true:false;
        bondeVenteData.livdirect = this.livreDetail.livdirect?true:false;

        bondeVenteData.transporteur = this.livreDetail.transporteurContent;
      } else {
        bondeVenteData.dtliv = moment(this.livreDetail.dtliv).format('YYYY-MM-DD HH:mm:ss');
        bondeVenteData.dtlivsouhait = moment(this.livreDetail.dtliv).format('YYYY-MM-DD HH:mm:ss');
        bondeVenteData.livjour = '';
        bondeVenteData.livconfirm = false;
        bondeVenteData.livinfo1 = '';
        bondeVenteData.livinfo2 = '';
        bondeVenteData.livdeport = false;
        bondeVenteData.livdirect = false;
        
        bondeVenteData.transporteur = '';
      }
    }

    if(type === 'emporte') {
      bondeVenteData.dtech = moment(this.emporteeDiffereDate).format('YYYY-MM-DD HH:mm:ss');
    }

    if(type === 'promesse') {
      bondeVenteData.dtpromesse = moment(this.promesseDate).format('YYYY-MM-DD HH:mm:ss');
    }

    if(type === 'carrier') {
      bondeVenteData.transporteur = this.carrierData.value;
    }

    if (this.flowType === 'bonde_commande') {
      const bondeCommandeData = {
        pMag: this.panier.mag,
        pNumBon: this.bonNumBon,
        pPanier: bondeVenteData, // Sending as Panier
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST,
      };

      this.updateBonDeCommandeFull(bondeCommandeData);
      return;
    }

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData);    
  }

  editBonDeVente(bondeVenteData: any) {
    this.bonVenteService.modifyBonvente(bondeVenteData).subscribe(
      (resp) => {
        this.loadingService.hide();        

        if(resp.statusCode == 200) {
          if(resp.data.ErrorCode == 0) {
            // List update after a success response
            const oldListData = this.storageService.getItem('bonVenteListData');
            const oldNumBon = this.bonNumBon;
            // ------------------------------

            this.panier = resp.data.Panier;
            this.bonNumBon = resp.data.NumBon;

            this.storageService.setItem('previousBonPanier', this.panier);
            this.storageService.setItem('previousNumBon', resp.data.NumBon);

            // List update after a success response
            if(oldListData !== undefined && oldListData !== null) {
              oldListData.data.map(
                (row: BonVente) => {
                  if(row.NUMCOM == oldNumBon) {
                    row.TRAISOC = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                    row.TPRENOM = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                    row.TADR3 = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TADR3:null;
                    row.CODPOS = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                    row.TVILLE = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                    row.TTEL = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.ENDMOD = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('YYYY-MM-DD HH:mm:ss');
                    
                    row.MTHT = this.panier.montantHT;                    
                    row.MTTTC = this.panier.montant;
                    row.MTTVA = (this.panier.montant - this.panier.montantHT);
                    
                    row.CLINUM = (this.panier.client !== undefined && this.panier.client !== null)?this.panier.client.idcli.toString():"0";
                    row.CLILIVNUM = (this.panier.clientlivre !== undefined && this.panier.clientlivre !== null)?this.panier.clientlivre.idcli.toString():"0";
                    row.CLIFACNUM = (this.panier.clientfacture !== undefined && this.panier.clientfacture !== null)?this.panier.clientfacture.idcli.toString():"0";                    

                    row.NUMCOM = this.bonNumBon;

                    return row;
                  }

                  return row;
                }
              );

              const updated_bonvente = oldListData.data.find(
                (row: BonVente) => row.NUMCOM == this.bonNumBon
              );

              let updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
              updated_row_removed.unshift(updated_bonvente);

              oldListData.data = updated_row_removed;

              this.storageService.setItem('bonVenteListData', oldListData);
            }
            // ------------------------------
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    ); 
  }

  __updateBonDeCommandeList() {
    // List update after a success response
    const oldListData = this.storageService.getItem('bonCommandeListData');
    if(oldListData !== undefined && oldListData !== null && oldListData.data != null) {
      oldListData.data.Bons = oldListData.data.Bons.filter((_r: any) => _r !== null && _r != 'null');
      oldListData.data.Bons.map(
          (row: any) => {
            if(row.numbon == this.bonNumBon) {
              row.montant = this.panier.montant;
              row.dtrel = this.panier.dtrel;
              row.typecde = this.panier.typecde;
              row.dtech = this.panier.dtech;
              row.statut = this.panier.statut;
              row.dtmaj = this.panier.dtmaj;
            }
            return row;
          }
      );

      const updatedCommande = oldListData.data.Bons.find(
          (row: any) => row.numbon == this.bonNumBon
      );

      let updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
      updated_row_removed.unshift(updatedCommande);

      oldListData.data.Bons = updated_row_removed;

      this.storageService.setItem('bonCommandeListData', oldListData);
    }
  }

  async updateBonDeCommandeFull(bonCommandeData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyBonCommandeFull(bonCommandeData).subscribe(
          async (resp) => {
            if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                await this.getBonDeCommandeByNumBon(this.bonNumBon);
                this.__updateBonDeCommandeList();
                resolve({ response: resp });
              } else {
                this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                reject({ response: resp });
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
              resolve({ response: resp });
            }
          },
          err => {
            reject(err);
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
      );
    });
  }

  async getBonDeCommandeByNumBon(numBon: string, closeLoader: boolean = true): Promise<any> {
    if(numBon == 'undefined' || numBon == 'null') {
      return;
    }

    return new Promise((resolve, reject) => {
      this.bonCommandeService.getBonCommandeDetail(numBon, this.panier.mag, 'commande').subscribe(
          async (resp) => {
            this.loadingService.hide();
            if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                this.panier = resp.data.Panier;
                this.bonNumBon = resp.data.NumBon;

                this.storageService.setItem('previousBonPanier', this.panier);
                this.storageService.setItem('previousNumBon', resp.data.NumBon);
              } else {
                this.loadingService.hide();
                this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
              }
              resolve(resp);
            } else {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              resolve(resp);
            }
          },
          err => {
            reject(err);
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
      )
    });
  }

  openCreateClientForm() {
    this.newClient = true;
    this.editClient = false;
  }

  onCreateClientChanged(event: any) {
    if(event.status === 'return') {
      this.newClient = false;
    }

    if(event.status === 'success_bon_create') {
      this.newClient = false;
      const response = event.response;
      this.selectedClient = response.data.livreClient;
      this.livreClient = this.selectedClient;

      this.refreshClientMagasinNom();
    }

    if(event.status === 'success_create') {
      this.newClient = false;
      const response = event.response;
      this.selectedClient = response.data;
      this.livreClient = this.selectedClient;

      this.refreshClientMagasinNom();
    }
  }

  openEditClientForm() {
    this.newClient = false;
    this.editClient = true;
    this.selectedClient.MAG_NOM = this.magasinNom;
  }

  onEditClientChanged(event: any) {
    if(event.status === 'return') {
      this.editClient = false;
    }

    if(event.status === 'success_edit_bon_client') {
      this.editClient = false;
      const response = event.response;

      if(response.data.Panier.clientlivre != null) {
        this.selectedClient = response.data.livreClient;
      } else {
        this.selectedClient = response.data.customClient;
      }
      this.livreClient = this.selectedClient;
      if(this.sameAsClient) {
        this.client = this.selectedClient;
      }
      this.refreshClientMagasinNom();
    }

    if(event.status === 'success_edit_client') {
      this.editClient = false;
      const response = event.data;
      this.selectedClient = response;
      this.livreClient = this.selectedClient;
      if(this.sameAsClient){
        this.client = this.selectedClient;
      }
      this.refreshClientMagasinNom();
    }
  }

  refreshClientMagasinNom() {
    if(this.selectedClient !== undefined && this.selectedClient !== null) {
      if(this.selectedClient.CMINT !== undefined && this.selectedClient.CMINT !== null && +this.selectedClient.CMINT > 0) {
        const magData = this.listMagasins.find((row) =>  row.CMINT == this.selectedClient.CMINT);
        if(magData !== undefined && magData != null) {
          this.magasinNom = magData.CMRAISOC;
        }
      } else {
        this.magasinNom = undefined;
      }
    }
  }

  searchClients() {
    let searchKey = this.searchForm.get('client_nom').value;

    if(searchKey === undefined || searchKey == null || searchKey === '') {
      return;
    }

    this.loadingService.show();
    let form_data: any = {};

    form_data.NOM = searchKey;
    form_data.IDSOC = Constants.IDSOC;
    form_data.CIINT = this.user.CIINT;
    form_data.paracmint = this.USERCMINT;

    this.clientService.searchClients(form_data).subscribe(
      (response: any) => {
          if(response.success !== undefined) {
            const data = response.data;
            const totalResults: number = response.count;

            if(totalResults === 1) {
              this.selectedClient = data[0];
              this.livreClient = this.selectedClient;
              this.refreshClientMagasinNom();

              if(this.panier !== undefined && this.panier !== null) {
                this.updateBondeVente('client');
              } else {
                this.loadingService.hide();
              }
  
              this.searchForm.get('client_nom').setValue('');
            } else {
              this.loadingService.hide();
              this.searchClientFormSubmitter();
            }
          }
      }, 
      err => {
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )
  }
  
  openAdvancedSearch(){
    this.userService.setAdvanceSearchFormData(null);  
    const dialogRef = this.dialog.open(ClientFilterComponent, {
      width: '100vw',
      maxHeight: '80vh',
      data: {
        fetchType: 'new_load',
        dateAct: 'equal'
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {  
        this.userService.setAdvanceSearchFormData(result.form_data);     
        const data = {
          formType: 'advancedSearch',
          filterData: result
        };
        this.searchClientFormSubmitter(data);
      }      
    });
  }

  closeAdvancedSearch(){
  }

  dateFormatter(date: string, offset = true, today_time = true) {
    if(date === undefined || date  === null) {
      return '';
    }
    
    const given_date = moment(date).format('DD.MM.YYYY');

    if(given_date === undefined || moment(date).year() === 1899) {
      return '';
    }

    if(offset) {
      return moment(date).utcOffset(date).format('DD.MM.YY');
    }
    return moment(date).format('DD.MM.YY');
  }

  handleDeliveryStatusChange() {
    if (this.panier && (this.panier.statut == 5 || this.panier.statut == 6)) {
        this.livraisonStatusChange.next(this.panier.statut);
    }
  }

  openClientHistory() {
    const dialogRef = this.dialog.open(ClientHistoriqueComponent,
      {
        data: {
          IDTIERS: this.selectedClient.IDTIERS,
          IDINSTTIE: this.selectedClient.IDINSTTIE,
          IDSOC: this.selectedClient.IDSOC,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }
}
