import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import { SideNavItemComponent } from './item';

@Component({
    selector: 'side-nav-menu',
    template: `
      <nav style="height:100%">
        <mat-toolbar color="primary">
          <button mat-icon-button class="back-button" (click)="sidenav.close()"><mat-icon>close</mat-icon></button>&nbsp;
          <div class="side-nav-menu-title" *ngIf="isMobile">
            <span class="side-nav-main-title">{{ appTitle }} {{ capVersion }}</span>
            <span class="side-nav-sub-title" *ngIf="username && username !== ''">{{ username }}</span>            
          </div>
          <div class="mobile-logout" *ngIf="isMobile">
            <div class="align-items-center d-flex justify-content-end">
                <mat-icon (click)="logout()" class="bt_logout bt_cursor" title="Déconnexion">power_settings_new</mat-icon>
            </div>
          </div>
        </mat-toolbar>

        <ul class="side-nav-menu">
          <ng-content></ng-content>
        </ul>
      </nav>`,
    styles: [
      `.side-nav-menu{
          list-style-type: none;
          padding: 0
      }
      .back-button {
          margin: 0.55em 0;
      }
      .side-nav-menu-title {
        font-size: 14px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;        
        justify-content: center;
        align-items: flex-start;
        position: relative;
      }
      .side-nav-main-title {        
        display: block;
        font-weight: 800;
        line-height: 1.5em;
      }
      .side-nav-sub-title {
        font-size: 11px;
        color: #ccc;
        display: block;
        line-height: 1em;
      }
      .mobile-logout {
        justify-items: flex-end;
        align-self: center;
        flex: 1;
        margin-right: 6px;
      }
      `]
  })
  export class SideNavMenuComponent {

    @Input() sidenav: MatSidenav;
    @Input() appTitle: string;
    @Input() username: string;
    @Input() logout: Function;
    @Input() isMobile: boolean;    
    @ContentChildren(SideNavItemComponent, {descendants: true}) items: QueryList<SideNavItemComponent>;
    active: SideNavItemComponent;
    @Input('version') capVersion: string;

    constructor(private router: Router) {
      this.router.events.subscribe( e => {
        if (e instanceof NavigationEnd) {
          this.items.forEach( i => {
            i.activatePath(this.getPath());
          })
          this.sidenav.close();
        }
      });
    }

    private getPath() {
      const path = this.router.routerState.snapshot.url;
      return path;
    }

  }
