import {Component, OnInit, OnDestroy, ViewChild, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

import {DevisService} from '../../providers/api/devisService';

import {Constants} from '../../providers/constants';
import {UserService} from '../../providers/api/userService';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogsService } from 'providers/api/dialogService';

import * as moment from 'moment';
import { ColorsListComponent } from 'pages/bon-de-commande/colors-list/colors-list.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DevisFilterComponent } from './devis-filter/devis-filter.component';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { User } from 'models/users';
import { DOCUMENT } from '@angular/common';
import { UtilService } from 'providers/api/utilService';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-devis',
    styleUrls: ['./devis.component.scss'],
    templateUrl: './devis.component.html',
    providers: [DevisService]
})

export class DevisComponent implements OnInit, OnDestroy {

    // Service
    listDevis: any[] = [];

    user: User;
    USERCMINT: any;

    filterValues: any;

    // DATATABLE
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    searchForm: UntypedFormGroup;
    showLoader = false;

    currentPage: number = 0;
    totalPages: number;
    rowsPerPage: number = 10;

    dateAct: string = 'equal';
    minDateAct: any = null;

    pageType: 'devis' | 'cuisine' | 'dressing' = 'devis';

    isMobile: boolean = false;
    searchSubscription: Subscription;
    searchCodeSubscription: Subscription;
    addListenerSubscription: Subscription;

    userPermission: any;
    detailDevisVisibility: boolean = false;

    statusList: { id: string, label: string }[] = [
      {
        label: "kuall",
        id: "0"
      },
      {
        label: "kkencours",
        id: "1"
      },
      {
        label: "kkvalidedev",
        id: "2"
      }
    ];

    constructor(private translateService: TranslateService,
                private formBuilder: UntypedFormBuilder,
                public devisService: DevisService,
                private dialogService: DialogsService,
                private userService: UserService,
                private utilService: UtilService, 
                private deviceService: DeviceDetectorService,
                private loadingService: NgxSpinnerService,
                private router: Router,
                private currencyPipe: CapCurrencyPipe,
                public dialog: MatDialog,
                private storageService: StorageService,
                @Inject(DOCUMENT) document) {
    }

    ngOnInit(): void {

        this.isMobile = this.deviceService.isMobile();
        this.rowsPerPage = 10;

        if(this.isMobile) {
          this.rowsPerPage = 5;
        }

        this.user = this.userService.getCurrentUser();
        this.USERCMINT = this.user.CMINT;

        this.userService.headerAdd.next(false);
        this.userService.headerAddListener.next(false);
        this.userService.headerSearchInputThreeDots.next(true);
        this.userService.headerSearchInput.next(true);
        this.userService.headerSearchInputType.next('text');
        this.userService.headerSearch.next(false);

        this.searchSubscription = this.userService.headerSearch.subscribe(
          (status: boolean) => {
            if(status === true) {
              this.openUpBondeFilterScreen();
            }
          }
        ) 

        this.searchCodeSubscription = this.userService.headerSearchInputValue.subscribe(
          (search_code) => {           
            if(search_code !== undefined) {
              let rapid_search_code:any = Number(search_code);
              if(isNaN(rapid_search_code)){
                this.searchForm.patchValue({ NOM: search_code === null?'':search_code, NUMBON : '' });
              } else {
                this.searchForm.patchValue({ NUMBON: search_code === null?'':search_code, NOM: '' });          
              }
              this.searchFormSubmitter();
            }
          }
        )
        this.addListenerSubscription = this.userService.headerAddListener.subscribe(
          (status: boolean) => {
            if(status === true) {
              this.router.navigate(['/create-devis']);
            }
          }
        )

        this.dtOptions = {
            language: {
              processing: this.translateService.instant('kprocessing'),
              search: this.translateService.instant('ksearch'),
              loadingRecords: this.translateService.instant('kloading'),
              info: this.translateService.instant('kgenshowentry'),
              infoEmpty: this.translateService.instant('kgenshowingentry'),
              zeroRecords: this.translateService.instant('knodata'),
              emptyTable: this.translateService.instant('knodatadash'),
              paginate: {
                  first: this.translateService.instant('kfirst'),
                  previous: this.translateService.instant('kprevious'),
                  next: this.translateService.instant('knext'),
                  last: this.translateService.instant('klast'),
              }          
            },
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };

        this.searchForm = this.formBuilder.group({
            IDSOC: [Constants.IDSOC],
            CMINT: this.USERCMINT,
            NUMBON: [''],
            NOM: [''],
            PRENOM: [''],
            CODPOS: [''],
            VILLE: [''],
            TEL_PORT: [''],
            TOTAL_ENTRE_FROM: [''],
            TOTAL_ENTRE_TO: [''],
            DATE: [{ value: '', disabled: true }],
            DATE_END: [{ value: '', disabled: true }],
            USERCON: [false],
            ETAT: ['0']
        });

        this.userPermission = this.storageService.getItem('CurrentUserPermission');

        if(this.userPermission['24_0'] && !this.userPermission['24_1']) {
          this.detailDevisVisibility = true;
        }

        const oldFormData: any = this.storageService.getItem('devisFormData');
        const oldListResponse: any = this.storageService.getItem('devisListData');
        
        if(oldFormData !== undefined && oldFormData !== null) {
          this.searchForm.setValue({
            IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
            CMINT: oldFormData.CMINT !== null?oldFormData.CMINT:this.USERCMINT,
            NUMBON: oldFormData.NUMBON !== null?oldFormData.NUMBON:'',
            NOM: oldFormData.NOM !== null?oldFormData.NOM:'',
            PRENOM: oldFormData.PRENOM !== null?oldFormData.PRENOM:'',
            CODPOS: oldFormData.CODPOS !== null?oldFormData.CODPOS:'',
            VILLE: oldFormData.VILLE !== null?oldFormData.VILLE:'',
            TEL_PORT: oldFormData.TEL_PORT !== null?oldFormData.TEL_PORT:'',
            TOTAL_ENTRE_FROM: oldFormData.TOTAL_ENTRE_FROM !== null?oldFormData.TOTAL_ENTRE_FROM:'',
            TOTAL_ENTRE_TO: oldFormData.TOTAL_ENTRE_TO !== null?oldFormData.TOTAL_ENTRE_TO:'',
            DATE: oldFormData.DATE !== null?oldFormData.DATE:'',
            DATE_END: oldFormData.DATE_END !== null?oldFormData.DATE_END:'',
            USERCON: oldFormData.USERCON !== null?oldFormData.USERCON:false,
            ETAT: oldFormData.ETAT !== null?oldFormData.ETAT:'',
          });

          this.filterValues = oldFormData;
        }
        
        if(oldListResponse !== undefined && oldListResponse != null) {
          const data = oldListResponse.data.Bons;
          this.currentPage = Math.floor(oldListResponse.data.FinBon / this.rowsPerPage) - 1;
          
          this.totalPages = oldListResponse.data.NbBons;
          
          this.listDevis = [...data];
          this.showLoader = false;

          setTimeout(() => {
            this.dtTrigger.next();
          }, 400);
        } else {
        }
    }

    ngOnDestroy(): void {      
        if(this.router.routerState.snapshot.url.indexOf('modify-devis') > -1 || this.router.routerState.snapshot.url.indexOf('devis-detail') > -1) {

        } else {
          this.storageService.removeItem('devisFormData');
          this.storageService.removeItem('devisListData');
        }

        this.userService.headerSearch.next(false);
        this.userService.headerSearchInput.next(false);
        this.searchSubscription.unsubscribe();        
        this.userService.headerAdd.next(false);
        this.addListenerSubscription.unsubscribe();
        this.searchCodeSubscription.unsubscribe();

        this.dtTrigger.unsubscribe();
    }


    resetForm() {
        this.searchForm.reset();
        this.storageService.removeItem('devisFormData');
  
        this.dateAct = 'equal';
  
        this.searchForm.patchValue({
          IDSOC: Constants.IDSOC,
          CMINT: this.USERCMINT,          
          USERCON: false,
          ETAT: ''
        });

        this.userService.headerSearchInputValue.next('');
    }

    onDateChanged() {
      this.minDateAct = this.searchStartDate.value?this.searchStartDate.value:null;
      if(this.minDateAct !== null) {
        this.searchEndDate.setValue(this.minDateAct);
      }
    }

    changeDateAct(type: string = 'equal') {
        this.dateAct = type;
    }
    
    _clearDate = (form_field: string) => {
        this.searchForm.get(form_field).setValue('');
        if(form_field == 'DATE') {
          this.minDateAct = null;
        }
    }

    get searchStartDate() {
        return this.searchForm.get('DATE');
    }

    get searchEndDate() {
        return this.searchForm.get('DATE_END');
    }

    editDevis(devis: any) {
      if(devis.statut == 2 || this.detailDevisVisibility) {
        this.router.navigate(['/devis-detail/'+devis.numbon]);  
        return;
      } 

      this.router.navigate(['/modify-devis/'+devis.numbon]);
    }

    defineRowColor(item: any) {
        let colorClass: string = 'devis_en_cours';
  
        if(item.statut == 2) {
          colorClass = 'devis_valide';
        } 
        
        if (item.statut == 1) {
          colorClass = 'devis_en_cours';
        }
  
        return colorClass;
    }

    listValideDateFormatter(date: string, offset = true, today_time = true) {
        if(date === undefined || date  === null) {
          return '';
        }
        
        const given_date = moment(date).format('DD.MM.YYYY');
        const today_date = moment().format('DD.MM.YYYY');
  
        if(given_date === undefined || moment(date).year() === 1899) {
          return '';
        }
  
        if(given_date === today_date && today_time) {
          return moment(date).utcOffset(date).format('HH:mm');
        }
  
        if(offset) {
          return moment(date).utcOffset(date).format('DD.MM.YY');
        }
        return moment(date).format('DD.MM.YY');
    }

    listDateFormatter(date: string, offset = true, today_time = true) {
        if(date === undefined || date  === null) {
          return '';
        }
        
        const given_date = moment(date).format('DD.MM.YYYY');
        const today_date = moment().format('DD.MM.YYYY');
  
        if(given_date === undefined || moment(date).year() === 1899) {
          return '';
        }
  
        if(given_date === today_date && today_time) {
          return moment(date).utcOffset(date).format('HH:mm');
        }
  
        if(offset) {
          return moment(date).utcOffset(date).format('DD.MM.YY HH:mm');
        }
        return moment(date).format('DD.MM.YY HH:mm');
    }

    searchFormSubmitter(): void {
        this.showLoader = true;

        if(this.searchForm.invalid) {    
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));

          return;
        }
    
        const form_data = this.searchForm.getRawValue(); 
       
        this.storageService.setItem('devisFormData', form_data);
        this.currentPage = 0;

        let creeMinDate = '1899-12-30T00:00:00', creeMaxDate = '1899-12-30T00:00:00',
            echMinDate = '1899-12-30T00:00:00', echMaxDate = '1899-12-30T00:00:00';

        if(this.dateAct === 'between') {
          creeMinDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
          creeMaxDate = form_data.DATE_END?this.utilService.formatDateToWS(form_data.DATE_END):"1899-12-30T00:00:00";
        } else if (this.dateAct === 'greater_equal') {
          creeMinDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
          creeMaxDate = "1899-12-30T00:00:00";
        } else if (this.dateAct === 'less_equal') {
          creeMinDate = "1899-12-30T00:00:00";
          creeMaxDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
        } else {
          creeMinDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
          creeMaxDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
        }

        this.filterValues = form_data;
        let search_data: any = {};
        let otherFilters: any = {};

        switch(this.pageType) {
          case 'devis':
          case 'cuisine':
          case 'dressing':
            otherFilters = {
                NumBon: form_data.NUMBON != null?form_data.NUMBON:'',
                Autre: '',
                Tel: form_data.TEL_PORT != null?form_data.TEL_PORT:'',
                Ville: form_data.VILLE !== null?form_data.VILLE:'',
                UserCint: form_data.USERCON?this.user.CIINT:'',
                UserInst: form_data.USERCON?this.user.IDINSTINT:'',
                Statut: form_data.ETAT?form_data.ETAT:'0'
            };
  
            search_data = {
                pMag: form_data.CMINT,
                pNumfid: "",
                pNom: form_data.NOM != null?form_data.NOM:'',
                pPrenom: form_data.PRENOM != null?form_data.PRENOM:'',
                pCodpos: form_data.CODPOS != null?form_data.CODPOS:'',
                pMin: form_data.TOTAL_ENTRE_FROM != null?form_data.TOTAL_ENTRE_FROM:'',
                pMax: form_data.TOTAL_ENTRE_TO != null?form_data.TOTAL_ENTRE_TO:'',
                pDtCreMin: creeMinDate,
                pDtCreMax: creeMaxDate,
                pDtEchMin: echMinDate,
                pDtEchMax: echMaxDate,
                pArt: '',
                pTypeDevis: "",
                pNumExterne: '',              
                pNiv1: 0,
                pNbElements: this.rowsPerPage,
                pNumPage: this.currentPage + 1,
                pFilter: otherFilters
            };

            this.searchDevis(search_data);
          break;
        }
    }

    searchDevis(search_data: any) {
        this.devisService.searchDevis(search_data).subscribe(
          (response: any) => {
            if(response.success !== undefined) {
              if(response.data.ErrorCode == 0) {
  
                const data = response.data.Bons;
                this.totalPages = response.data.NbBons;                
        
                this.listDevis = [...data];
        
                this.showLoader = false;
                this.dtTrigger.next();
  
                this.storageService.setItem('devisListData', response);
              } else {
                this.showLoader = false;
                this.dialogService.prompt(this.translateService.instant('kuconerr'), response.data.Error);
              }              
            } else {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }        
          }, 
          err => {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        );
    }

    searchPaginate(page?: number) {

        if(this.searchForm.invalid) {
          this.showLoader = false;
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
    
          return;
        }
  
        const form_data = this.searchForm.getRawValue();
         
        this.storageService.setItem('devisFormData', form_data);
  
        let creeMinDate = '1899-12-30T00:00:00', creeMaxDate = '1899-12-30T00:00:00',
            echMinDate = '1899-12-30T00:00:00', echMaxDate = '1899-12-30T00:00:00';
  
        if(this.dateAct === 'between') {
          creeMinDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
          creeMaxDate = form_data.DATE_END?this.utilService.formatDateToWS(form_data.DATE_END):"1899-12-30T00:00:00";
        } else if (this.dateAct === 'greater_equal') {
          creeMinDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
          creeMaxDate = "1899-12-30T00:00:00";
        } else if (this.dateAct === 'less_equal') {
          creeMinDate = "1899-12-30T00:00:00";
          creeMaxDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
        } else {
          creeMinDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
          creeMaxDate = form_data.DATE?this.utilService.formatDateToWS(form_data.DATE):"1899-12-30T00:00:00";
        }
  
        this.filterValues = form_data;
        let search_data: any = {};
        let otherFilters: any = {};
    
        switch(this.pageType) {
          case 'devis':
          case 'cuisine':
          case 'dressing':
            otherFilters = {
                NumBon: form_data.NUMBON != null?form_data.NUMBON:'',
                Autre: '',
                Tel: form_data.TEL_PORT != null?form_data.TEL_PORT:'',
                Ville: form_data.VILLE !== null?form_data.VILLE:'',
                UserCint: form_data.USERCON?this.user.CIINT:'',
                UserInst: form_data.USERCON?this.user.IDINSTINT:'',
                Statut: form_data.ETAT?form_data.ETAT:'0'
            };
  
            search_data = {
                pMag: form_data.CMINT,
                pNumfid: "",
                pNom: form_data.NOM != null?form_data.NOM:'',
                pPrenom: form_data.PRENOM != null?form_data.PRENOM:'',
                pCodpos: form_data.CODPOS != null?form_data.CODPOS:'',
                pMin: form_data.TOTAL_ENTRE_FROM != null?form_data.TOTAL_ENTRE_FROM:'',
                pMax: form_data.TOTAL_ENTRE_TO != null?form_data.TOTAL_ENTRE_TO:'',
                pDtCreMin: creeMinDate,
                pDtCreMax: creeMaxDate,
                pDtEchMin: echMinDate,
                pDtEchMax: echMaxDate,
                pArt: '',
                pTypeDevis: "",
                pNumExterne: '',              
                pNiv1: 0,
                pNbElements: this.rowsPerPage,
                pNumPage: page,
                pFilter: otherFilters,
            };

            this.searchDevis(search_data);
          break;
        }
    }

    paginationChange(event: any) {
        this.showLoader = true;
        const changedPageIndex = event.pageIndex;    
    
        this.currentPage = changedPageIndex;

        this.searchPaginate(changedPageIndex + 1);
    }

    openColorCode() {
        const dialogRef = this.dialog.open(ColorsListComponent, {      
          width: '30vw',
          data: {
            type: this.pageType
          }
        });
    }

    deleteConfirmation(item: any): void {
      const message = `
      <h1 class="text-left mb-3">${this.translateService.instant('ksuppridevis')}</h1>
      <h1 class="text-left pl-2">        
        ${this.translateService.instant('kbonNum')} : ${item.numbon} <br>
        ${this.translateService.instant('kbonpour')} : ${item.nom != null?item.nom:''}${item.prenom != null?' '+item.prenom:''}<br>
        ${this.translateService.instant('kbonmontant')} : ${this.currencyPipe.transform(item.montant, null, false)}
      </h1>
      `;
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '60vw',
        data: {
          title: '',
          description: message
        }     
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result === "OK") {
          this.loadingService.show();
          this.deleteDevis({ pMag: this.USERCMINT, pNumBon: item.numbon, Id_User: this.user.CIINT, Id_InstUser: Constants.CIINST });
        }
      });    
    }

    deleteDevis(data: { pMag: number, pNumBon: string, Id_User: string, Id_InstUser: string }) {
      this.devisService.removeDevis(data).subscribe(
        (resp) => {
          this.loadingService.hide();          
          if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                this.listDevis = this.listDevis.filter((el) => el.numbon != data.pNumBon);                

                if(this.listDevis.length == 0) {
                  this.showLoader = true;

                  if(this.currentPage != 0) {
                    this.currentPage--;
                  }

                  this.searchPaginate(this.currentPage + 1);
                } else {
                  const storable_json = {
                    rowsPerPage: this.rowsPerPage,
                    count: this.totalPages,
                    data: this.listDevis
                  };

                  this.storageService.setItem('devisListData', storable_json);
                }
              } else {
                this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              }          
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            }         
        },
        error => {        
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      );
    }

    openUpBondeFilterScreen() {
      this.userService.headerSearch.next(false);  
      const dialogRef = this.dialog.open(DevisFilterComponent, {      
        width: '60vw',
        maxHeight: this.isMobile?'85vh':undefined,
        data: {
          form_data: this.searchForm.value,
          user: this.user,
          USERCMINT: this.USERCMINT,
          resetForm: this.resetForm,
          dateAct: this.dateAct,
          statusList: this.statusList,
          type: this.pageType
        }     
      });  
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.status === "success") {
          const form_data = result.form_data;
          this.dateAct = result.dateAct;
          this.searchForm.patchValue({ 
            IDSOC: form_data.IDSOC !== null?form_data.IDSOC:Constants.IDSOC,
            CMINT: form_data.CMINT !== null?form_data.CMINT:this.USERCMINT,
            NUMBON: form_data.NUMBON !== null?form_data.NUMBON:'',
            NOM: form_data.NOM !== null?form_data.NOM:'',
            PRENOM: form_data.PRENOM !== null?form_data.PRENOM:'',
            CODPOS: form_data.CODPOS !== null?form_data.CODPOS:'',
            VILLE: form_data.VILLE !== null?form_data.VILLE:'',
            TEL_PORT: form_data.TEL_PORT !== null?form_data.TEL_PORT:'',
            TOTAL_ENTRE_FROM: form_data.TOTAL_ENTRE_FROM !== null?form_data.TOTAL_ENTRE_FROM:'',
            TOTAL_ENTRE_TO: form_data.TOTAL_ENTRE_TO !== null?form_data.TOTAL_ENTRE_TO:'',
            DATE: form_data.DATE !== null?form_data.DATE:'',
            DATE_END: form_data.DATE_END !== null?form_data.DATE_END:'',
            USERCON: form_data.USERCON !== null?form_data.USERCON:false,
            ETAT: form_data.ETAT !== null?form_data.ETAT:'',
          });
          this.filterValues = form_data;
          this.searchFormSubmitter();
        }
      });

    }

    onSwipe(e: any, index: number) {
      if(this.detailDevisVisibility) {
        return;
      }

      if(e.deltaX > 0) {
        // Right Swipe
        const element = document.getElementById('swipeItem-' + index);
        element.style.transform = 'translateX(0)'
        element.classList.remove('swiped');
      } else {
        // Left Swipe
        this.__resetPreviousSwipes('.mat-list-item');

        const element = document.getElementById('swipeItem-' + index);
        element.classList.add('swiped');
        element.style.transform = 'translateX(-100px)'
      }
    }

    __resetPreviousSwipes(listEl: string) {
      const allSwipeAbles = document.querySelectorAll(listEl);
      allSwipeAbles.forEach((el: any, i) => {
        el.classList.remove('swiped');
        el.style.transform = 'translateX(0)';
      })
    }

    get filterValuesFormat(): string {      
      let filterArr = [];
      if(this.filterValues.NUMBON !== undefined && this.filterValues.NUMBON !== null && this.filterValues.NUMBON !== '') {
        filterArr.push(this.filterValues.NUMBON);
      }
      if(this.filterValues.NOM !== undefined && this.filterValues.NOM !== null && this.filterValues.NOM !== '') {
        filterArr.push(this.filterValues.NOM);
      }
      if(this.filterValues.PRENOM !== undefined && this.filterValues.PRENOM !== null && this.filterValues.PRENOM !== '') {
        filterArr.push(this.filterValues.PRENOM);
      }
      if(this.filterValues.TEL_PORT !== undefined && this.filterValues.TEL_PORT !== null && this.filterValues.TEL_PORT !== '') {
        filterArr.push(this.filterValues.TEL_PORT);
      }
      if(this.filterValues.CODPOS !== undefined && this.filterValues.CODPOS !== null && this.filterValues.CODPOS !== '') {
        filterArr.push(this.filterValues.CODPOS);
      }
      if(this.filterValues.VILLE !== undefined && this.filterValues.VILLE !== null && this.filterValues.VILLE !== '') {
        filterArr.push(this.filterValues.VILLE);
      }

      if(this.filterValues.DATE !== undefined && this.filterValues.DATE !== null && this.filterValues.DATE !== '') {
        filterArr.push(moment(this.filterValues.DATE).format('DD.MM.YYYY'));
      }
      if(this.filterValues.DEADLINE !== undefined && this.filterValues.DEADLINE !== null && this.filterValues.DEADLINE !== '') {
        filterArr.push(moment(this.filterValues.DEADLINE).format('DD.MM.YYYY'));
      }
      
      if(this.filterValues.TOTAL_ENTRE_FROM !== undefined && this.filterValues.TOTAL_ENTRE_FROM !== null && this.filterValues.TOTAL_ENTRE_FROM !== '') {
        filterArr.push(this.filterValues.TOTAL_ENTRE_FROM);
      }
      if(this.filterValues.TOTAL_ENTRE_TO !== undefined && this.filterValues.TOTAL_ENTRE_TO !== null && this.filterValues.TOTAL_ENTRE_TO !== '') {
        filterArr.push(this.filterValues.TOTAL_ENTRE_TO);
      }

      if(this.filterValues.USERCON !== undefined && this.filterValues.USERCON !== null && this.filterValues.USERCON !== '') {
        if(this.filterValues.USERCON) {
          filterArr.push(this.translateService.instant('kbonuser'));
        }
      }

      if(this.filterValues.ETAT !== undefined && this.filterValues.ETAT !== null && this.filterValues.ETAT !== '') {
        if(this.filterValues.ETAT && this.filterValues.ETAT !== '') {
          const status = this.statusList.find((_r) => _r.id == this.filterValues.ETAT);
          if(status) {
            filterArr.push(this.translateService.instant(status.label));
          }
        }
      }
     
      return filterArr.length > 0 ? filterArr.join(', ') : '';
    }

}
