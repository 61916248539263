import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from '../../models/users';

import {Client} from '../../models/clients';

import {BonVente} from '../../models/bonventes';

import {Article} from '../../models/articles';

import {Remise} from '../../models/remises';
import {ParamDetail} from '../../models/paramDetail';
import {Printer} from '../../models/printer';

import {PrinterService} from './printerService';

@Injectable()
export class Global {
     client: Client = null;
     article: Article = null;
     listRemise: Remise[] = [];
     listRemisePied: Remise[] = [];
     listParamDetail: ParamDetail[] = [];
     listPrinter: Printer[] = [];

    currentBonVente: BonVente = null;
    bonvente: BonVente = null;
    currentUser: User = null;

    TRAISOC: any;

    constructor(public http: HttpClient,
                private printerService: PrinterService) {

    }

    reloadData() {
         const that = this;

         if (that.currentUser !== null && that.currentUser.CMINT.length > 0) {
            this.printerService.getListPrinter(that.currentUser.CMINT).subscribe(data => {
                if (data.length > 0) {
                    that.listPrinter = [];
                }
                for (var i = 0; i < data.length; i++) {
                    that.listPrinter.push(new Printer(data[i]));
                }
            }, (error) => {

            }, () => {
            });
        }
    }

    getParamWithInfo(trowID: string, trowDetId: string, cmInt: string): ParamDetail {
        for (const param of this.listParamDetail) {
            if (param.TROWID === trowID && param.TROWDETID && param.CMINT === cmInt) {
                return param;
            }
        }
        return this.getParamByDefault(trowID, trowDetId);
    }

    getParamByDefault(trowID: string, trowDetId: string): ParamDetail {
        for (const param of this.listParamDetail) {
            if (param.TROWID === trowID && param.TROWDETID) {
                return param;
            }
        }
        return null;
    }
}
