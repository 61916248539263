<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium">{{'kclclearform' | translate}}</div>
            </button>
        </div>
    </div>
</div>

<form [formGroup]="searchForm" (ngSubmit)="dialogSearchFormSubmitter()">
    <div class="bloc_container bg_light w-100 pt-3">
        <div class="row">            
            <div class="col-12">
                <div class="row no-gutters">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <mat-select [placeholder]="'kclshop' | translate" formControlName="CMINT" >
                                <mat-option>
                                    <ngx-mat-select-search
                                    [placeholderLabel]="'klselstore' | translate"
                                    [noEntriesFoundLabel]="'klnostore' | translate"                                
                                    (ngModelChange)="onSearchChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterMagasin"></ngx-mat-select-search>
                                </mat-option>

                                <mat-option value="">{{ 'kcltouslesmag' | translate }}</mat-option>
                                <mat-option value="non_defini">{{'kclnondefi' | translate}}</mat-option>
                                <mat-option *ngFor="let magasin of filteredMagasins" [value]="magasin.CMINT">
                                    {{ magasin.CMRAISOC }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="NOM"
                                [placeholder]="'kclnom' | translate"
                                class="text-uppercase" #filterFirst>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="TPRENOM"
                                [placeholder]="'kclfirstnom' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="CP"                                
                                [placeholder]="'kclcp' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>                                           
                </div>

                <div class="row no-gutters">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="VILLE"
                            [placeholder]="'kclcity' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div> 
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <input type="tel" matInput formControlName="TEL"
                                [placeholder]="'kcltel' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="TEMAIL"
                                [placeholder]="'kclemail' | translate"
                                class="text-uppercase">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div [class]="dateAct === 'between'?'col-6':'col-12'">
                        <mat-form-field floatLabel="always" class="date">
                            <input matInput formControlName="BIRTHDATEMIN" [max]="today" (dateChange)="onDateChanged()" [matDatepicker]="cdeDate"
                                [placeholder]="'kbondate' | translate"
                                class="text-uppercase">
                            <mat-datepicker-toggle matSuffix [for]="cdeDate">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #cdeDate [disabled]="false"></mat-datepicker>
                            <button mat-icon-button type="button" *ngIf="searchStartDate.value" (click)="_clearDate('BIRTHDATEMIN')" matSuffix ><mat-icon>close</mat-icon></button>
                            <button matSuffix type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                                <mat-icon class="d-flex justify-content-center align-items-center">
                                    <span style="line-height: initial;" *ngIf="dateAct === 'equal'">=</span>
                                    <span style="line-height: initial;" *ngIf="dateAct === 'greater_equal'">>=</span>
                                    <span style="line-height: initial;" *ngIf="dateAct === 'less_equal'"><=</span>
                                    <span style="line-height: initial;" *ngIf="dateAct === 'between'"><></span>
                                </mat-icon>
                            </button>                                
                        </mat-form-field>
                        <mat-menu #menu="matMenu" [hasBackdrop]="false">
                            <button mat-menu-item (click)="changeDateAct('equal')">{{'kbondategale' | translate}}</button>
                            <button mat-menu-item (click)="changeDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                            <button mat-menu-item (click)="changeDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                            <button mat-menu-item (click)="changeDateAct('between')">{{'kbondatentre' | translate}}</button>
                        </mat-menu>
                    </div>
                    <div class="col-6" *ngIf="dateAct === 'between'">
                        <mat-form-field floatLabel="always">
                            <input matInput formControlName="BIRTHDATEMAX" [min]="minDateAct" [max]="today" [matDatepicker]="cdeEndDate"
                                [placeholder]="'kbonand' | translate"
                                class="text-uppercase">
                                
                            <mat-datepicker-toggle matSuffix [for]="cdeEndDate">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #cdeEndDate [disabled]="false"></mat-datepicker>
                            <button mat-icon-button type="button" *ngIf="searchEndDate.value" (click)="_clearDate('BIRTHDATEMAX')" matSuffix ><mat-icon>close</mat-icon></button>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <mat-form-field>
                            <mat-select [placeholder]="'kclpays' | translate" formControlName="PAYS" >
                                <mat-option>
                                    <ngx-mat-select-search
                                    [placeholderLabel]="'kcltouslespays' | translate"
                                    [noEntriesFoundLabel]="'kkaucunepaystrouve' | translate"                                
                                    (ngModelChange)="onCountryChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterPays"></ngx-mat-select-search>
                                </mat-option>  
                                <mat-option value="tous">{{'kcltouslespays' | translate}}</mat-option>
                                <mat-option value="non_defini">{{'kclnondefi' | translate}}</mat-option>
                                <mat-option *ngFor="let pay of filteredPays" [value]="pay.CODPAYS">
                                    {{ pay.PAYSNOM }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> 
            </div>
        </div>
    </div>

    <div class="bloc_container w-100">
        <div class="row bg_white">
            <div class="col-12 mt-2 mt-md-0 text-center height_bloc" >                      
                <div>
                    <button type="submit" class="bt_valid" mat-button>
                        <i class="material-icons">search</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kclsearc' | translate}}
                        </span>                        
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kucancel" | translate }}
                        </span>                        
                    </button>
                </div>    
            </div>
        </div>
    </div>
</form>