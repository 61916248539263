import {Component, Input, OnInit, ViewChild} from '@angular/core';
import BonPanier from '../../../models/bonPanier';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {MagasinStructModel} from '../../../models/magasinStructModel';
import * as moment from 'moment';
import {UserService} from '../../../providers/api/userService';
import {NgxSpinnerService} from 'ngx-spinner';
import {BonCommandeService} from '../../../providers/api/bonCommandeService';
import {DialogsService} from '../../../providers/api/dialogService';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UtilService} from '../../../providers/api/utilService';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Router} from '@angular/router';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-commande-liees',
  templateUrl: './commande-liees.component.html',
  styleUrls: ['./commande-liees.component.scss'],
  providers: [BonCommandeService, UtilService, {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},]
})
export class CommandeLieesComponent implements OnInit {

  @Input() userName: string;
  @Input() bonNumBon: string;
  @Input() flowType: string;
  @Input() readOnly = false;

  private _panier: BonPanier;
  get panier(): BonPanier { return this._panier }
  @Input() set panier(panier: BonPanier) {
    this._panier = panier;

    if(panier !== undefined && panier !== null) {
      this.getLinkedBondeCommandeList();

      const numBon = this.storageService.getItem('previousNumBon');
      if(numBon !== undefined && numBon != null) {
        this.bonNumBon = numBon;
      }
    }
  }

  listBonDeCommande: any[] | undefined;

  user: any;
  USERCMINT: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = false;
  magasinStructure: MagasinStructModel[];

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number = 10;

  isMobile: boolean = false;
  detailBonCommandeVisibility: boolean = false;
  userPermission: any;
  defaultDiffereDays: number;

  pageType = 'commande';

  itemClickedCount = 0;

  netTotal = 0;
  netRestantTotal = 0;

  constructor(private userService: UserService,
              private loadingService: NgxSpinnerService,
              private bonCommandeService: BonCommandeService,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private router: Router,
              private deviceService: DeviceDetectorService,
              private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
          first: this.translateService.instant('kfirst'),
          previous: this.translateService.instant('kprevious'),
          next: this.translateService.instant('knext'),
          last: this.translateService.instant('klast'),
        }
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.userPermission = this.storageService.getItem('CurrentUserPermission');

    if(this.userPermission['32_0'] && !this.userPermission['32_10']) {
      this.detailBonCommandeVisibility = true;
    }

    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();

    this.defaultDiffereDays = await this.userService.getRuleParamsData(20, 50, 'VINT1');
  }

  defineRowColor(item: any) {
    let colorClass: string = 'cde_en_cours';
    let today = moment();
    let dtech = moment(item.dtech).utcOffset(item.dtech);

    let dtechDiff = today.diff(dtech, 'days');

    let dtrel = moment(item.dtrel).utcOffset(item.dtrel);
    const ED_subtracted_date = moment().subtract(this.defaultDiffereDays, 'days');
    let dtrelDiff = ED_subtracted_date.diff(dtrel);

    if((item.statut < 7) && ((item.dtech != 0) && dtechDiff > 0) && ((item.dtrel == 0) || dtrelDiff > 0)) {
      colorClass = 'cde_a_relancer';
    }

    if(item.livdirect == true){
      colorClass = 'liv_directe';
    }

    if(item.livdeport == true){
      colorClass = 'liv_deportee';
    }

    if(item.statut == 2 || item.statut == 9) {
      colorClass = 'e2_en_cours';
    }

    if (item.statut == 7 || item.statut == 8) {
      colorClass = 'cde_soldee';
    }

    if (item.statut == 17) {
      colorClass = 'cde_cloture_contient';
    }

    if (item.statut == 27) {
      colorClass = 'solde_partiel';
    }

    if (item.statut == 11) {
      colorClass = 'e2_partielle';
    }

    if (item.statut == 50) {
      colorClass = '';
    }

    if (item.statut >= 89 && item.statut <= 98) {
      colorClass = 'som_tick_art';
    }

    return colorClass;
  }

  redirectToModify(item: any) {
    if (item.numbon === this.bonNumBon) {
      return;
    }

    if(this.detailBonCommandeVisibility) {
      this.router.navigate(['/bon-de-commande-detail/', item.numbon, item.mag], { replaceUrl: true, queryParams: { pageName: this.pageType } });
      return;
    }

    this.router.navigate(['/modify-bon-de-commande', item.numbon, item.mag], { replaceUrl: true });
  }

  onCommandeClicked(item: any) {
    this.itemClickedCount++;
    setTimeout(() => {
      if(this.itemClickedCount === 1) {
        if(this.isMobile) {
          this.redirectToModify(item);
        }
      } else if(this.itemClickedCount === 2) {
        if(!this.isMobile) {
          this.redirectToModify(item);
        }
      }
      this.itemClickedCount = 0;
    }, 250);
  }

  async getLinkedBondeCommandeList() {
    const creeMinDate = '1899-12-30T00:00:00', creeMaxDate = '1899-12-30T00:00:00',
        echMinDate = '1899-12-30T00:00:00', echMaxDate = '1899-12-30T00:00:00';

    const NumBonGrp = this.panier.numbonrgp;

    if (NumBonGrp === undefined || NumBonGrp === null || NumBonGrp === '') {
      this.showLoader = false;
      this.dtTrigger.next();
      return;
    }

    const otherFilters = {
      NumBon: NumBonGrp
    };

    const search_data = {
      pMag: this.panier.mag,
      pNumfid: "",
      pNom: '',
      pPrenom: '',
      pCodpos: '',
      pMin: '',
      pMax: '',
      pDtCreMin: creeMinDate,
      pDtCreMax: creeMaxDate,
      pDtEchMin: echMinDate,
      pDtEchMax: echMaxDate,
      pArt: '',
      pCanal: '',
      pNumExterne: '',
      pStatut: 0,
      pNiv1: 0,
      pRetrait1H: false,
      pTypecde: "",
      pNbElements: this.rowsPerPage,
      pNumPage: this.currentPage + 1,
      pFilter: otherFilters,
    };

    if (!this.listBonDeCommande) {
      await this.searchGestionDesCommande(search_data);
      this.loadingService.hide();
    }
  }

  _calculateTotal() {
    if (this.listBonDeCommande) {
      this.netTotal = this.listBonDeCommande.reduce((a, b) => a.montant + b.montant);
      this.netRestantTotal = this.listBonDeCommande.reduce((a, b) => a.montantrestant + b.montantrestant);
    }
  }

  searchGestionDesCommande(search_data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.bonCommandeService.searchBonDeCommande(search_data).subscribe(
          (response: any) => {
            if(response.success !== undefined) {
              if(response.data.ErrorCode == 0) {

                const data = response.data.Bons;
                this.totalPages = response.data.NbBons;

                this.listBonDeCommande = [...data];

                this.showLoader = false;
                this.dtTrigger.next();

                this._calculateTotal();
              } else {
                this.showLoader = false;
                this.dialogService.prompt(this.translateService.instant('kuconerr'), response.data.Error);
              }
            } else {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }

            resolve(response);
          },
          err => {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    })
  }

  listDateFormatter(date: string, offset = true, today_time = true) {
    if(date === undefined || date  === null) {
      return '';
    }

    const given_date = moment(date).format('DD.MM.YYYY');
    const today_date = moment().format('DD.MM.YYYY');

    if(given_date === undefined || moment(date).year() === 1899) {
      return '';
    }

    if(given_date === today_date && today_time) {
      return moment(date).utcOffset(date).format('HH:mm');
    }

    if(offset) {
      return moment(date).utcOffset(date).format('DD.MM.YY');
    }
    return moment(date).format('DD.MM.YY');
  }

  paginationChange(e: any) {
    
  }
}
