import {Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

import {Article} from '../../../../models/articles';
import {ArticleService} from '../../../../providers/api/articleService';

import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogsService } from 'providers/api/dialogService';
import { User } from 'models/users';
import { UserService } from 'providers/api/userService';
import * as moment from 'moment';
import {ParamDetail} from '../../../../models/paramDetail';

@Component({
    selector: 'app-article-logistique-couche-prix',
    templateUrl: './article-logistique-couche-prix.component.html',
    providers: [ArticleService, TranslateService]
})

export class ArticleLogistiqueCouchePrixComponent implements OnInit {

    private _article: Article
    get article() {return this._article}
    @Input() set article(article: Article) {
        this.articleChange.emit(this._article = article);

        if(article !== undefined && article !== null) {
            this.user = this.userService.getCurrentUser();
            this.USERCMINT = this.user.CMINT;
            
            this.CAINT = article.CAINT;       
            this.showLoader = true;     
            this.getCouchePrixData();
        } else {
            this.showLoader = false;         
        }
    };
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();
    
    CAINT: string;

    ListCouche: any[] = [];
    showLoader = true;

    // DATATABLE
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    @Input() displayType: 'dialog' | 'component' = 'dialog';

    isMobile: boolean;

    user: User;
    USERCMINT: any;

    futureDatesRestricted: boolean;
    futureDateLimit = 0;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
                private articleService: ArticleService,
                private userService: UserService,
                private translateService: TranslateService,
                private deviceDetector: DeviceDetectorService,
                private dialogService: DialogsService) {

    }

    ngOnInit() {
        this.user = this.userService.getCurrentUser();
        this.USERCMINT = this.user.CMINT;

        this.isMobile = this.deviceDetector.isMobile();
        
        if(this.displayType !== 'component') {
            this.CAINT = this.data.CAINT;            

            if(this.CAINT !== undefined && this.CAINT !== null) {
                this.showLoader = true;
                this.getCouchePrixData();
            }
        }        

        this.dtOptions = {
            language: {
                processing: this.translateService.instant('kprocessing'),
                search: this.translateService.instant('ksearch'),
                loadingRecords: this.translateService.instant('kloading'),
                info: this.translateService.instant('kgenshowentry'),
                infoEmpty: this.translateService.instant('kgenshowingentry'),
                zeroRecords: this.translateService.instant('knodata'),
                emptyTable: this.translateService.instant('knodatadash'),
                paginate: {
                    first: this.translateService.instant('kfirst'),
                    previous: this.translateService.instant('kprevious'),
                    next: this.translateService.instant('knext'),
                    last: this.translateService.instant('klast'),
                }        
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };        
    }

    checkFutureRestriction(): Promise<void> {
        return new Promise(async (resolve) => {
            const paramData: ParamDetail = await this.userService.getRuleParamsData(30, 10);

            this.futureDateLimit = (paramData.VINT1 !== undefined && paramData.VINT1 !== null && paramData.VINT1 !== '') ? +paramData.VINT1 : 0;

            if (paramData.VBOOL1 === '-1') {
                this.futureDatesRestricted = false;
            } else {
                this.futureDatesRestricted = true;
            }

            resolve();
        })
    }

    async getCouchePrixData() {
        await this.checkFutureRestriction();
        this.articleService.getCouchePrix({ CAINT: this.CAINT, CMINT: this.USERCMINT }).subscribe(
            (res) => {
                this.showLoader = false;
                if(res.success !== undefined) {
                    this.ListCouche = res.data;

                    if (this.futureDatesRestricted) {
                        // 30/10 VBOOL <> -1
                        this.ListCouche = this.ListCouche.filter((item: any) => {
                            return !moment(moment(item.PRIXDDEB).format('YYYY-MM-DD')).isAfter(moment().format('YYYY-MM-DD'));
                        });
                    } else {
                        // 30/10 VBOOL = -1
                        if (this.futureDateLimit !== 0) {
                            // 30/10 VINT1 <> 0
                            const maxDateLimit = moment().add(this.futureDateLimit, 'day').format('YYYY-MM-DD');

                            this.ListCouche = this.ListCouche.filter((item: any) => {
                                return moment(moment(item.PRIXDDEB).format('YYYY-MM-DD')).isBefore(maxDateLimit);
                            });
                        }
                    }

                    this.dtTrigger.next();
                }
            },
            error => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                this.showLoader = false;
            }
        );
    }

}
