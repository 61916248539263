import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'providers/constants';

@Injectable()
export class PostalCodesService {
    constructor(private http: HttpClient) {
    }
    
    getListofPostalCodes(page?: number): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};       
        
        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_POSTAL_CODES_URL + 'getCodeAtLoad', options);        
    }

    searchPostalCodes(orderBy: string, code?: number, city?: string, sort: 'asc' | 'desc' = 'asc', page?: number): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};       
        
        params['orderby'] = orderBy;
        params['sort'] = sort;

        if(code && !isNaN(code) && code > 0) {
            params['code'] = code;
        }

        if(city && city !== "") {
            params['city'] = city;
        }        

        if(page && !isNaN(page) && page > 0) {
            params['page'] = page;
        }

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_POSTAL_CODES_URL + 'searchCode', options);        
    }

    addPostalCode(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_POSTAL_CODES_URL + 'createCode', data, options);
    }

    updatePostalCode(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const options = {
            headers: headers        
        };        
        
        return this.http.post(Constants.API_POSTAL_CODES_URL + 'updateCode', data, options);
    }

    deletePostalCode(row_id: number): Observable<any> {

        const headers = new HttpHeaders();            
        headers.append('Content-Type', 'application/json');
        
        const params = {};
        params['TROWID'] = row_id;            

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.get(Constants.API_POSTAL_CODES_URL + 'deleteCode', options);
    }

}