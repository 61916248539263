import {Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener} from '@angular/core';
import {Router} from '@angular/router';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

import {DialogsService} from '../../providers/api/dialogService';
import {UserService} from '../../providers/api/userService';
import {Global} from '../../providers/api/global';
import {User} from '../../models/users';
import {Magasin} from '../../models/magasins';
import {LoginService} from '../../providers/api/loginService';

import {Constants} from '../../providers/constants';

import { DroitChild, GestionDesDroitsModel } from 'models/gestionDesDroitsModel';
import { ResetPasswordComponent } from 'pages/reset-password/reset-password.component';
import { UserComplementModel } from 'models/user-model';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ParamDetail } from 'models/paramDetail';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { StorageService } from 'providers/api/storageService';
import { KeycloakOperationService } from 'providers/api/keyclockService';
import { environment } from 'environments/environment';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    providers: [UserService, LoginService, DialogsService]
})

export class LoginComponent implements OnInit, AfterViewInit {

    @ViewChild('loginFormRef') loginFormRef: NgForm;
    @ViewChild('userNameInput') userNameInput: ElementRef;
    @ViewChild('password') passwordInput: ElementRef;

    // bouton ENTER valide formulaire
    private pressedButton: any;

    public login: { username: string, password: string} = { username: '', password: '' }

      // Service
    listMagasins: Magasin[] = [];
    USER_CMINT: any;
    control_magasin : string;

    title:  any = null;
    message: any = null;
    filterMagasin: string = "";
    filteredMagasins: Magasin[] = [];

    currentUserDroit: GestionDesDroitsModel;
    ruleParams: ParamDetail[];
    isMobile: boolean;
    @ViewChild('mySelect') mySelect;
    scanValue: any;

    loginForm: UntypedFormGroup;

    passwordExpiryDays: number;

    passwordHide: boolean = true;

    keycloakLogin: boolean = false;
    keycloakMagasin: string = '';

    constructor(
        public router: Router,
        private global: Global,
        public dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        public dialogsService: DialogsService,
        public userService: UserService,
        public loginService: LoginService,
        private loadingService: NgxSpinnerService,        
        private translateService: TranslateService,
        private dialogService: DialogsService,
        private deviceDetector: DeviceDetectorService,
        private storageService: StorageService,
        private keycloakService: KeycloakOperationService) {
    }

    ngOnInit() {
        if (environment.ENABLE_KEYCLOAK) {
            this.keycloakService.isLoggedIn().then(async (isLoggedIn) => {
                this.keycloakLogin = isLoggedIn;
            })
    
            this.keycloakService.getUserProfile().then(async (userProfile) => {
                const magasin = userProfile?.attributes?.MAGASIN;
                if (magasin) {
                    this.keycloakMagasin = magasin[0];
    
                    this.handleLoginAPI(userProfile?.username?.toUpperCase(), '', this.keycloakMagasin, this, this.keycloakLogin);
                }
            })
        }

        this.isMobile = this.deviceDetector.isMobile();
        this.global.currentUser = null;

        this.loginForm = this.formBuilder.group({
            USERNAME: ['', Validators.required],
            PASSWORD: ['', Validators.required],
            MAGASIN: ['', Validators.required]
        });

        this.USER_CMINT = '';
        this.control_magasin = null;
        
        if (!this.keycloakLogin) {
            this.getMagasins();
            this.loadDependencies();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if(this.userNameInput) {
                this.userNameInput.nativeElement.focus();
                this.userNameInput.nativeElement.setAttribute('inputmode', 'text');
            }
        }, 50);
        
        this.checkRememberUser();
    }

    async loadDependencies() {
        await this.getPasswordExpiryDays();
    }

    onSearchChange(search: string) {
        this.filteredMagasins = this.listMagasins.filter(row => row.CMRAISOC.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }

    public generate_token(length){
        //edit the token allowed characters
        var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        var b = [];  
        for (var i=0; i<length; i++) {
            var j = (Math.random() * (a.length-1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    async resetPasswordAlert(user_data: User, userComplementData: UserComplementModel): Promise<void> {
        let title = this.translateService.get('klpassreset').toPromise();
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {      
          width: '60vw',
          data: {
            title: await title,
            description: "",
            okButtonOnly: true
          }     
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result === "OK") {
            this.resetPasswordDialog(user_data, userComplementData, 'reinit');
          }      
        });    
      }

    async resetExpiredPasswordAlert(user_data: User, userComplementData: UserComplementModel): Promise<void> {
        let title = this.translateService.get('klpasschange').toPromise();
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {      
            width: '60vw',
            data: {
                title: await title,
                description: "",
                okButtonOnly: true
            }     
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result === "OK") {
                this.resetPasswordDialog(user_data, userComplementData, 'expiry');
            }      
        });    
    }  

    resetPasswordDialog(user_data: User, userComplementData: UserComplementModel, resetType: 'reinit' | 'expiry'): void {
        const dialogRef = this.dialog.open(ResetPasswordComponent, {
          width: '100vw',
          data: {
              userData: user_data,
              userComplementData: userComplementData,
              reset_type: resetType              
          },
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result === 'success') {
                this.loginForm.get('PASSWORD').setValue('');
            }
        });    
    }     

    loginFormSubmitter() {
        const form_data = this.loginForm.getRawValue();
        this.Connect();
    }

    async getPasswordExpiryDays() {
        try {
            const paramData = await this.userService.getRuleParamsData(10,6, 'VINT1');

            if(paramData !== undefined && paramData != null) {
                this.passwordExpiryDays = +paramData;
            }   
        } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    }

    async getDecimalSymbol() {
        try {
            const paramData = await this.userService.getRuleParamsData(10,1, 'VSTR4');
            this.storageService.setItem('DECIMAL_SYMBOL', ',');

            if(paramData !== undefined && paramData != null) {
                this.storageService.setItem('DECIMAL_SYMBOL', paramData);
            }
        } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    }

    async Connect() {
        this.userNameInput.nativeElement.setAttribute('inputmode', 'none');
        this.passwordInput.nativeElement.setAttribute('inputmode', 'none');
        
        setTimeout(() => {
            this.userNameInput.nativeElement.setAttribute('inputmode', 'text');
            this.passwordInput.nativeElement.setAttribute('inputmode', 'text');
        }, 100);

        const that = this;

        if(this.userService.isUserLoggedIn()) {
            await this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('kdejaconnecte')).toPromise();
            this.userService.__successRedirect().then((result) => {
                if(result && result === 'ACCESS_DENIED') {
                    this.USER_CMINT = undefined;
                }
            });
            return;
        }

        if(this.passwordExpiryDays == undefined) {
            await this.getPasswordExpiryDays();
        }

        const formData = this.loginForm.getRawValue();

        if(formData.USERNAME === "" || formData.USERNAME === undefined) {
            this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klusername'));
            return;
        }

        if(formData.PASSWORD === "" || formData.PASSWORD === undefined) {
            this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klpassword'));
            return;
        }

        if(formData.MAGASIN === "" || formData.MAGASIN === undefined) {
            this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klchosestore')).toPromise().then(() => {
                this.mySelect.open();
            });
            return;
        }
        
        this.handleLoginAPI(formData.USERNAME, formData.PASSWORD.toUpperCase(), formData.MAGASIN, that);
    }

    handleLoginAPI(username: string, password: string, magasin: string, that: any, keycloakLogin: boolean = false) {
        this.loadingService.show();

        this.loginService.getLogin(username, password, magasin, keycloakLogin).subscribe(
        async (logindata) => {
                if (logindata.success !== undefined) {
                    const userData: User = logindata.data[0];
                    const paramsData: any = logindata.paraminfo[0];     
                    
                    // Language conf
                    let changable_lang = paramsData.VCHXMUL1;
                    if(userData.CILANG !== undefined && userData.CILANG != null && userData.CILANG !== '') {
                        let trimmedLangShort = userData.CILANG.substr(0, 2);
                        changable_lang = trimmedLangShort;
                    } else {
                        if(paramsData.VCHXMUL1 == '' || paramsData.VCHXMUL1 == null) {
                            const societyParamData = await this.userService.getRuleParamsData(10, 1, undefined, true);
                            changable_lang = societyParamData.VCHXMUL1;
                        }
                    }

                    this.storageService.setItem('CURRENT_LANG_USER', userData);
                    await this.setAppLang(changable_lang);

                    if(userData.CIACTIF == '0') {
                        this.loadingService.hide();
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('kluservalid'), this.translateService.instant('kuok'), '100px');
                        return;
                    }

                    if(userData.CTUINTGC === '' || userData.CTUINTGC === null || userData.CTUINTGC == '0') {
                        this.loadingService.hide();
                        this.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('kdroitsindefini'), this.translateService.instant('kuok'), '100px');
                        return;
                    }                    

                    const userComplementData: UserComplementModel = (logindata.dataPassword && logindata.dataPassword.length > 0)?logindata.dataPassword[0]:null;
                    if(userComplementData !== undefined && userComplementData != null) {
                        this.storageService.setItem('USER_STRUCT', userComplementData.STRUCTU);
                    }

                    if(userComplementData === null || (userComplementData !== null && userComplementData.OKREINIT == '-1')) {
                        this.loadingService.hide();
                        this.resetPasswordAlert(userData, userComplementData);
                        return;
                    }

                    const today = moment();
                    const lastPasswordChanged = userComplementData !== null?moment(userComplementData.DATPASS):null;
                    const difference = lastPasswordChanged !== null?today.diff(lastPasswordChanged, 'days'):null;

                    if(userComplementData !== null && userComplementData.OKEXPIRE == '0' && difference !== null && this.passwordExpiryDays != 0 && difference >= this.passwordExpiryDays) {
                        this.loadingService.hide();
                        this.resetExpiredPasswordAlert(userData, userComplementData);
                        return;
                    }

                    let currencyText = paramsData.VSTR3;
                    let currencySymbol = paramsData.VSTR2;

                    this.storageService.setItem('CAP_CURRENCY_TEXT', currencyText);
                    this.storageService.setItem('CAP_CURRENCY_SYMBOL', currencySymbol);

                    this.USER_CMINT = userData.CMINT;
               
                    if(this.USER_CMINT === '0' && !magasin){
                        this.control_magasin = "klselectstore";
                    }else{
                        await this.getListDroitsData(userData.CMINT, userData.CIINT, userData.CTUINTGC);
                        await this.getTimeZoneFromWS();

                        // le user qui se connecte a un CMINT (magasin) détermniné 
                        let cmint = this.USER_CMINT;
                        if (cmint === '0') {
                            cmint = magasin;
                            userData.CMINT = cmint;
                            userData.CMRAISOC = this.filteredMagasins.find((row) => row.CMINT == magasin).CMRAISOC;
                        } else {
                            userData.CMRAISOC = this.filteredMagasins.find((row) => row.CMINT == this.USER_CMINT).CMRAISOC;
                        }
                        
                        that.userService.setCurrentUser(userData);
                        this.reloadAppLang(changable_lang);

                        await this.getDecimalSymbol();

                        this.userService.__successRedirect().then((result) => {
                            if(result && result === 'ACCESS_DENIED') {
                                this.USER_CMINT = undefined;
                            }
                        });
                    } 
                
                } else {
                    that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klbadpassword'));
                    if (environment.ENABLE_KEYCLOAK) {
                        that.keycloakService.logout();
                    }
                }
                this.loadingService.hide();
            },
            (error) => {
                const loginData = error.error;

                const status = loginData.status;

                switch(status) {
                    case 'WRONG_CREDENTIALS':
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klbadpassword'));
                        break;
                    case 'MAGASIN_EMPTY':
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klstorecon'));
                        break;
                    case 'WRONG_MAGASIN':
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klstorenotuser'));
                        break;
                    case 'MAGASIN_EMPTY':
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klstorecon'));
                        this.mySelect.open();
                        break;
                    case 'USERNAME_EMPTY':
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('kluserempty'));
                        break;
                    case 'PASSWORD_EMPTY':
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klpassempty'));
                        break;    
                    default:
                        that.dialogsService.prompt(this.translateService.instant('kserror'), this.translateService.instant('klbadpassword'));
                        break;                    
                }             

                if (environment.ENABLE_KEYCLOAK) {
                    that.keycloakService.logout();
                }
                
                this.loadingService.hide();   
            },
        () => {
        });
    }

    async setAppLang(prevLang?: string) {
        let lang_data = Constants.APP_DEFAULT_LANG;
        if(prevLang !== undefined && prevLang !== null  && prevLang !== '') {
            lang_data = prevLang;
        } else {
            try {
                lang_data = await this.userService.getRuleParamsData(10, 1, 'VCHXMUL1');
            } catch(err) {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
        }
        
        if(lang_data !== undefined && lang_data !== null && lang_data !== '') {
            this.translateService.setDefaultLang(lang_data);
        } else {
            this.translateService.setDefaultLang(Constants.APP_DEFAULT_LANG);
        }        
    }

    async reloadAppLang(prevLang?: string) {
        let lang_data = Constants.APP_DEFAULT_LANG;
        if(prevLang !== undefined && prevLang !== null  && prevLang !== '') {
            lang_data = prevLang;
        } else {
            try {
                lang_data = await this.userService.getRuleParamsData(10, 1, 'VCHXMUL1');
            } catch(err) {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
        }
        
        if(lang_data !== undefined && lang_data !== null && lang_data !== '') {
            this.translateService.reloadLang(lang_data);
        } else {
            this.translateService.reloadLang(Constants.APP_DEFAULT_LANG);
        }        
    }

    getTimeZoneFromWS(): Promise<void> {
        return new Promise((resolve, reject) => {
            const timezoneOffset = this.storageService.getItem('TIMEZONE_OFFSET');
            if (timezoneOffset !== undefined && timezoneOffset !== null) {
                resolve();
                return;
            }

            this.userService.getTimeZone().subscribe(
                (res) => {
                    if(res.success !== undefined && res.success != null && res.success === 'ok') {
                        this.storageService.setItem('TIMEZONE_OFFSET', res.data);
                    }
                    resolve();
                },
                err => {
                    this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                    reject();
                }
            );
        });
    }

    async getListDroitsData(CMINT: string, CIINT: string, niveauID: string | null): Promise<GestionDesDroitsModel> | null {
        if(niveauID === null) return null;

        return new Promise((resolve, reject) => {
            this.userService.getListofDroits(CMINT, CIINT, niveauID).subscribe(
                (res: any) => {                    
                  if(res.success !== undefined) {
                      this.currentUserDroit = res.data;
                      this.processUserDroitData();
                      
                      resolve(res.data);
                  } else {
                      reject();
                  }
                },
                error => {
                    this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                    reject(error)
                }
            );
        });
    }

    _processDroitsData(data: DroitChild) {
        let processedData: {} = {};
        let childData: {} = {};

        processedData[data.id+'_'+data.value] = data.checked;

        if(data.child !== undefined && data.child != 'null') {
            let childRows: any = data.child;

            childRows.map((child_row: DroitChild) => {
                childData = {...childData, ...this._processDroitsData(child_row)};
            });
        }

        processedData = {...processedData, ...childData};

        return processedData;
    }

    processUserDroitData() {
        if(this.currentUserDroit === undefined || this.currentUserDroit === null) return;

        let processed: {} = {};

        this.currentUserDroit.general?.map(
            row => {                
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.ventes?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.reporting?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.clients?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );

        this.currentUserDroit.articles?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );
        
        this.currentUserDroit.confirmation_action?.map(
            row => {
                processed = { ...processed, ...this._processDroitsData(row) };
            }
        );
        
        this.storageService.setItem('CurrentUserPermission', processed)
    }

    
    // affiche la liste des magasins rattachés à la société
    public getMagasins(){

        const that = this;
        that.listMagasins = [];

        this.loginService.getMagasins().subscribe(
        (data) => {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    that.listMagasins.push(new Magasin(data[i]));                   
                }    
                this.filteredMagasins = this.listMagasins;                              
            }
        }, 
        (error) => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }, 
        () => {
        });
    }

    public onChangeMagasin(CMINT) {
        this.mySelect.close();
    }

    selectOptions() {
        this.mySelect.open();
    }

    checkRememberUser() {
        if(!this.loginForm.touched) return;

        const rememberedUser = this.userService.getRememberedUser();
        if(rememberedUser !== undefined && rememberedUser != null) {
            const formData = this.loginForm.getRawValue();
            if(formData.USERNAME === undefined || formData.USERNAME == null || formData.USERNAME === '') {
                return;
            }

            if(formData.PASSWORD === undefined || formData.PASSWORD == null || formData.PASSWORD === '') {
                return;
            }            

            if(rememberedUser.CIUSER.toLowerCase() === formData.USERNAME.toLowerCase() && rememberedUser.CIPSW.toLowerCase() === formData.PASSWORD.toLowerCase()) {
                this.loginForm.get('MAGASIN').setValue(rememberedUser.CMINT);
                this.loginFormRef.ngSubmit.next();
            } else {
                this.loginForm.get('MAGASIN').setValue('');
            }
        }
    }

    openScanArticle(e: any) {
        const dialogRef = this.dialog.open(BarcodeScannerComponent,
          {
            width: '60vw',
            maxHeight: this.isMobile?'95vh':undefined,
            autoFocus: false
          }
        );
    
        dialogRef.afterClosed().subscribe(result => {
          if(result && result.status === "success") {          
            const scanValue = result.data;
            if(scanValue){
                let scanData = scanValue.split('~~');

                if(scanData !== undefined && scanData != null) {
                    this.loginForm.get('USERNAME').setValue(scanData[0]);
                    this.loginForm.get('PASSWORD').setValue(scanData[1]);
                }
            }  
          }      
        });
    }

    onUsernameEnterPressed(e: any) {
        let key = e.keyCode || e.which;
        if(key == 13) {
            this.emulationScan();
        }
    }

    emulationScan(){
        const formData = this.loginForm.getRawValue();
        let enterValue = formData.USERNAME;        
        if(enterValue){
            if(enterValue.indexOf('~~') > -1) {
                let emulationScanData = enterValue.split('~~');

                if(emulationScanData !== undefined && emulationScanData != null) {
                    this.loginForm.get('USERNAME').setValue(emulationScanData[0]);
                    this.loginForm.get('PASSWORD').setValue(emulationScanData[1]);

                    this.checkRememberUser();

                    if(formData.MAGASIN === '' || formData.MAGASIN == null) {
                        this.mySelect.open();
                    }
                }
            }
        }

        this.passwordInput.nativeElement.focus();
    }

    onPasswordEnterPressed(e: any) {
        let key = e.keyCode || e.which;
        if(key == 13) {
            this.mySelect.open();
        }
    }

    @HostListener("window:focus", ["$event.target.value"])
    onFocused(e) {
        if(this.userService.isUserLoggedIn()) {
            this.userService.__successRedirect().then((result) => {
                if(result && result === 'ACCESS_DENIED') {
                    this.USER_CMINT = undefined;
                }
            });
        }
    }

    togglePasswordDisplay() {
        this.passwordHide = !this.passwordHide
    }
   
}