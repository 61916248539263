<div style="min-height: 400px;" class="position-relative">
    <div class="row">
        <div class="col-12 table-scroller" [class.no-padding]="isMobile">
            <div>
                <mat-paginator *ngIf="listBonDeCommande && listBonDeCommande?.length != 0 && !showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                               [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                >
                </mat-paginator>
            </div>

            <table *ngIf="!isMobile" datatable class="row-border hover bonCommande" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                <thead>
                    <tr>
                        <th nowrap width="14%" class="nom">{{ 'kbonbonl' | translate }}</th>
                        <th class="date">{{ 'kbondate' | translate }}</th>
                        <th class="port">{{ 'kbontype' | translate }}</th>
                        <th class="port text-left" width="5%">{{ 'kbontotal' | translate }}</th>
                        <th class="port text-left">{{ 'kboncomrestapayer' | translate }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="listBonDeCommande && listBonDeCommande?.length != 0 && !showLoader">
                <tr *ngFor="let bonCommande of listBonDeCommande" [class]="bonCommande.numbon === this.bonNumBon?'bg-light':''" >
                    <td class="numero" (click)="onCommandeClicked(bonCommande)" nowrap>
                        <span class="colorcode" [class]="defineRowColor(bonCommande)"></span> {{ bonCommande.numbon | slice:0:2 }} {{ bonCommande.numbon | slice:2:4 }} {{ bonCommande.numbon | slice:4:7 }} {{ bonCommande.numbon | slice:7:12 }} {{ bonCommande.numbon | slice:12:15 }}
                    </td>
                    <td class="date" (click)="onCommandeClicked(bonCommande)">{{ listDateFormatter(bonCommande.dtmaj) }}</td>
                    <td class="port" (click)="onCommandeClicked(bonCommande)">{{ bonCommande.typecde }}</td>
                    <td class="port text-left" (click)="onCommandeClicked(bonCommande)" nowrap>
                        <div style="width: 80px;white-space: break-spaces;">{{ bonCommande.montant | cap_currency:null:false }}</div>
                    </td>
                    <td class="port text-left" (click)="onCommandeClicked(bonCommande)" nowrap>
                        <div style="width: 80px;white-space: break-spaces;">{{ bonCommande.montantrestant | cap_currency:null:false }}</div>
                    </td>
                </tr>
                </tbody>
                <tbody *ngIf="!listBonDeCommande || listBonDeCommande?.length == 0 || showLoader">
                <tr>
                    <td [attr.colspan]="5" *ngIf="(!listBonDeCommande || listBonDeCommande?.length == 0) && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                    <td [attr.colspan]="5" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                    <td style="display: none;"></td>
                    <td style="display: none;"></td>
                    <td style="display: none;"></td>
                    <td style="display: none;"></td>
                </tr>
                </tbody>
            </table>
            <mat-list *ngIf="isMobile && (!listBonDeCommande || listBonDeCommande?.length == 0 || showLoader)">
                <mat-list-item *ngIf="(!listBonDeCommande || listBonDeCommande?.length == 0) && !showLoader">
                    <p matLine class="text-center">
                        {{ "kpnoresult" | translate  }}
                    </p>
                </mat-list-item>

                <mat-list-item *ngIf="showLoader">
                    <p matLine class="text-center">
                        <mat-icon class="synching">sync</mat-icon>
                    </p>
                </mat-list-item>
            </mat-list>
            <mat-list *ngIf="isMobile && (listBonDeCommande && listBonDeCommande?.length != 0 && !showLoader)">

                <mat-list-item  *ngFor="let bonCommande of listBonDeCommande;let i=index;" [class]="bonCommande.numbon === this.bonNumBon?'bg-light':''" style="user-select: none;">
                    <div class="w-100" (click)="onCommandeClicked(bonCommande)">
                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                            <p class="m-0">
                                <span class="colorcode" [class]="defineRowColor(bonCommande)"></span> {{ bonCommande.numbon | slice:0:2 }} {{ bonCommande.numbon | slice:2:4 }} {{ bonCommande.numbon | slice:4:7 }} {{ bonCommande.numbon | slice:7:12 }} {{ bonCommande.numbon | slice:12:15 }}
                            </p>
                            <p class="m-0">{{ listDateFormatter(bonCommande.dtmaj) }}</p>
                        </div>
                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                            <p matLine class="mobile-list-title no-margin">
                                <span class="badge badge-secondary">{{ bonCommande.montant | cap_currency:null:false }}</span>
                                <span class="badge badge-info ml-1">{{ bonCommande.montantrestant | cap_currency:null:false }}</span>
                            </p>
                            <p matLine class="mobile-list-title text-right mb-0">
                                <span class="badge badge-dark ml-1">{{ bonCommande.typecde }}</span>
                            </p>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>

    <div class="position-absolute w-100" style="bottom: 0">
        <div class="row">
            <div class="col-6 text-center text-md-right p-1">
                Totaux:
            </div>
            <div class="col-3">
                {{ netTotal | cap_currency:null:false }}
            </div>
            <div class="col-3 col-md-2">
                {{ netRestantTotal | cap_currency:null:false }}
            </div>
        </div>
    </div>
</div>
