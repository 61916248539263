import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { MotifsModel } from 'models/motifs-model';
import { User } from 'models/users';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { MotifService } from 'providers/api/motifService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';

@Component({
  selector: 'app-forcage-bonde',
  templateUrl: './forcage-bonde.component.html',
  styleUrls: ['./forcage-bonde.component.scss']
})
export class ForcageBondeComponent implements OnInit {

  user: User;
  USERCMINT: any;

  title = "";
  forcage_rules: { label: string, value: string, type: 'vente' | 'commande' }[];

  forcageForm: UntypedFormGroup;
  otherMotif: boolean = false;

  listMotifs: MotifsModel[] = [];
  motifAction: number;  

  showLoader: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ForcageBondeComponent>,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder,
              private motifService: MotifService,
              private userService: UserService,
              private loadingService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.showLoader = true;

    this.user = this.userService.getCurrentUser();

    this.title = this.data.type === 'vente'?'kbonmotifforcevent':'kbonmotifforcecom';
    this.forcage_rules = this.data.forcage_rules;
    this.USERCMINT = this.data.CMINT;

    this.forcageForm = this.formBuilder.group({
      motif: ['autre', Validators.required],
      custom_motif: [{value: '', disabled: true }]
    });

    this.motifAction = this.data.type === 'vente'?5:4;
        
    this.getListMotifs(this.motifAction);
  }

  onForcageChanged(event: any) {    
    if(event.value === 'autre') {
      this.otherMotif = true;

      this.forcageForm.get('custom_motif').setValidators([Validators.required, Validators.minLength(2)]);
      this.forcageForm.get('custom_motif').enable({ onlySelf: true });
      this.forcageForm.get('custom_motif').updateValueAndValidity();
    } else {
      const currentMotif = this.listMotifs.find((row) => row.MOTLIB == event.value);

      if(currentMotif.MOTFREE == '-1') {
        this.otherMotif = true;

        this.forcageForm.get('custom_motif').setValidators([Validators.required, Validators.minLength(2)]);
        this.forcageForm.get('custom_motif').enable({ onlySelf: true });
        this.forcageForm.get('custom_motif').updateValueAndValidity();
      } else {
        this.otherMotif = false;
        this.forcageForm.get('custom_motif').setValue('');
        this.forcageForm.get('custom_motif').disable({ onlySelf: true });
        this.forcageForm.get('custom_motif').setErrors(null);
        this.forcageForm.get('custom_motif').clearValidators();
        this.forcageForm.get('custom_motif').updateValueAndValidity();
      }      
    }
  }

  forcageFormSubmitter() {
    if(this.forcageForm.invalid) {
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('kmmand'));
      return;
    }

    const form_data = this.forcageForm.getRawValue();

    let motifText = '';

    if(this.otherMotif) {
      if(form_data.motif === 'autre') {
        motifText = form_data.custom_motif;
      } else {
        motifText = form_data.motif+": "+form_data.custom_motif;
      }
    } else {
      motifText = form_data.motif;
    }
    
    this.dialogRef.close({ status: 'success', motif: motifText });
  }

  setMotifInitVal() {
    if(this.listMotifs !== undefined && this.listMotifs !== null && this.listMotifs.length > 0) {
      
      const first_element = this.listMotifs[0];
      this.forcageForm.get('motif').setValue(first_element.MOTLIB);

      if(first_element.MOTFREE == '-1') {
        this.otherMotif = true;

        this.forcageForm.get('custom_motif').setValidators([Validators.required, Validators.minLength(2)]);
        this.forcageForm.get('custom_motif').enable({ onlySelf: true });
        this.forcageForm.get('custom_motif').updateValueAndValidity();
      } else {
        this.otherMotif = false;

        this.forcageForm.get('custom_motif').setValue('');
        this.forcageForm.get('custom_motif').disable({ onlySelf: true });
        this.forcageForm.get('custom_motif').setErrors(null);
        this.forcageForm.get('custom_motif').clearValidators();
        this.forcageForm.get('custom_motif').updateValueAndValidity();
      }
    } else {
      this.otherMotif = true;

      this.forcageForm.get('custom_motif').setValidators([Validators.required, Validators.minLength(2)]);
      this.forcageForm.get('custom_motif').enable({ onlySelf: true });
      this.forcageForm.get('custom_motif').updateValueAndValidity();
    }
  }

  getListMotifs(action: number) {
    const httpData = {
      action: action,
      IDMAG: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      CIINT: this.user.CIINT
    };

    this.motifService.getListofMotifsWithoutPagination(httpData).subscribe(
      (response) => {
        this.showLoader = false;
        this.loadingService.hide();
        const data = response.data;

        this.listMotifs = [...data];        
        this.setMotifInitVal();
      },
      error => {
        this.showLoader = false;
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
      }
    )  
  }

}
