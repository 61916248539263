<div class="page-container">
    <div class="title row" *ngIf="displayType === 'component'">
        <div class="col-12 text-center">
            <h1 class="text-center" translate>kartsearch</h1>
        </div>

        <div class="col-12 text-center" *ngIf="isMobile && filterValues">
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>    

    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium" translate>kartclearform</div>
                </button>
            </div>
        </div>
    </div>

    <form #f="ngForm" [formGroup]="searchForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="row no-gutters">
                        <div class="col-12 col-lg-12">
                            <mat-form-field>
                                <input matInput formControlName="CDART" 
                                    [placeholder]="'kartcode' | translate" (keydown.enter)="onCodeEntered($event)" class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-12">
                            <mat-form-field>
                                <input matInput formControlName="NOM"
                                    [placeholder]="'kartnom' | translate" (keydown.enter)="f.ngSubmit.emit()" class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-12">
                            <mat-form-field>
                                <input matInput formControlName="CODEEXT" 
                                    [placeholder]="'kartcodeext' | translate" (keydown.enter)="f.ngSubmit.emit()" class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div class="row no-gutters">
                                <div class="col-6">
                                    <mat-form-field>
                                        <input matInput formControlName="MTTTCMIN"
                                            [placeholder]="'kprixentre' | translate"
                                            (keydown.enter)="f.ngSubmit.emit()"
                                            class="text-uppercase">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 pl-4">
                                    <mat-form-field>
                                        <input matInput formControlName="MTTTCMAX"
                                            [placeholder]="'kbonand' | translate"
                                            (keydown.enter)="f.ngSubmit.emit()"
                                            class="text-uppercase">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="row no-gutters">                                        
                        <div class="col-12 col-md-12 col-lg-12">
                            <mat-checkbox formControlName="LIV" 
                                        class="mat-checkbox" >
                                {{'kartdelitem' | translate}}
                            </mat-checkbox>
                        </div> 
                        <div class="col-12 col-md-12 col-lg-12">
                            <mat-checkbox formControlName="ACTIF" 
                                    class="mat-checkbox">
                                {{'kartactitm' | translate}}
                            </mat-checkbox>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12">
                            <mat-checkbox formControlName="STOCK_SUPERIOR"
                                    class="mat-checkbox">
                                {{'kdisponibleimmedi' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 col-md-6 col-lg-5">
                    <div class="radio-container">
                        <mat-label translate>kartordstruct</mat-label>
                        <mat-radio-group name="order_structure" formControlName="STRUCT_ORDER" (change)="changeMagasinStructTreeOrder($event)">
                            <mat-radio-button value="CODE"><span [translate]="'kartcode'"></span></mat-radio-button>
                            <mat-radio-button value="LABEL"><span [translate]="'kartlib'"></span></mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="border_fieldset margin_top_20" [style]="displayType === 'dialog'?'max-height: 200px; height: auto;':''">
                        <mat-form-field floatLabel="always">
                            <input matInput [(ngModel)]="searchStructureMarchandiseLabel" (keydown.enter)="searchStructureMarchandise(searchStructureMarchandiseLabel)" [ngModelOptions]="{standalone: true}"
                                [placeholder]="'kartmar' | translate" class="text-uppercase">

                            <button mat-icon-button type="button" *ngIf="searchStructureMarchandiseLabel && searchStructureMarchandiseLabel !== ''" (click)="searchStructureMarchandise(searchStructureMarchandiseLabel)" matSuffix ><mat-icon>search</mat-icon></button>
                            <button mat-icon-button type="button" *ngIf="searchStructureMarchandiseLabel && searchStructureMarchandiseLabel !== ''" (click)="_clearStructuLabel()" matSuffix ><mat-icon>add</mat-icon></button>
                            <button mat-icon-button type="button" *ngIf="searchStructureMarchandiseLabel && searchStructureMarchandiseLabel !== ''" (click)="_resetStructuTree()" matSuffix ><mat-icon>close</mat-icon></button>
                        </mat-form-field>
                        <div class="form-group mb-0">                                    
                            <ngx-treeview #magasinTree [config]="config" [items]="filteredTreeItems" [itemTemplate]="itemTemplate" (selectedChange)="onTreeValueChanged($event)">
                            </ngx-treeview>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="radio-container">
                        <mat-label translate>kartordsous</mat-label>
                        <mat-radio-group name="order_sous_famile" formControlName="SOUS_ORDER" (change)="changeSousFamileTreeOrder($event)" >
                            <mat-radio-button value="SOUS_CODE"><span [translate]="'kartcode'"></span></mat-radio-button>
                            <mat-radio-button value="SOUS_LABEL"><span [translate]="'kartlib'"></span></mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="border_fieldset margin_top_20" [style]="displayType === 'dialog'?'max-height: 200px; height: auto;':''">                        
                        <div class="form-group mb-0">
                            <ngx-treeview #sousTree [config]="sousTreeConfig" [items]="sousfamileTreeItems" [itemTemplate]="sousItemTemplate" (selectedChange)="onSousTreeValueChanged($event)">
                            </ngx-treeview>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">                
                <div class="align-items-center col-12 d-flex flex-column flex-md-row flex-lg-row justify-content-center text-center">
                    <button type="submit" class="bt_valid" mat-button>
                        <mat-icon>search</mat-icon>
                        {{'kpsearch' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">

        <div class="row">
            <div class="col-12 no-padding table-scroller">
                <div class="">
                    <mat-paginator *ngIf="listArticle?.length != 0 && !showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

               <table #tablearticle *ngIf="!isMobile && articleListColumns" id="article_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover dataTable no-footer table-bg-color">
                    <thead>
                        <tr>
                            <th translate *ngIf="multiSelectionEnabled"></th>
                            <th translate></th>
                            <th translate *ngFor="let col of articleListColumns" [width]="col.width">
                                <ng-container *ngIf="col.value === 'CDART' || col.value === 'CALIB3'">
                                    <div class="d-flex justify-content-start align-items-center sorter" (click)="sortingOrder(col.value)">
                                        <span>{{ col.name | translate }}</span>
                                        <span class="ml-3" *ngIf="orderedByColumn === col.value">
                                            <mat-icon *ngIf="sortByColumn === 'asc'">keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="sortByColumn === 'desc'">keyboard_arrow_up</mat-icon>
                                        </span>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="col.value !== 'CDART' && col.value !== 'CALIB3'">
                                    {{ col.name }}
                                </ng-container>
                            </th>
                            <th><span *ngIf="displayType === 'component'">&nbsp;</span></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listArticle?.length != 0 && !showLoader">
                        <tr *ngFor="let article of listArticle" [class.bg-warning]="searchedArticle && searchedArticle.CAINT == article.CAINT">
                            <td *ngIf="multiSelectionEnabled">
                                <mat-checkbox [(ngModel)]="checkedLineItems[article.CDART]" (ngModelChange)="itemChosenChange($event, article)" class="mt-2"></mat-checkbox>
                            </td>
                            <td (click)="editArticle(article)">
                                <div class="my-2 image-holder">                                    
                                    <img [src]="getImageURL(article.CDART)" [alt]="article.CDART" default="assets/images/no_img.png" />
                                </div>
                            </td>

                            <td *ngFor="let col of articleListColumns" (click)="col.value !== 'STOCKDISPO' ? editArticle(article) : null">
                                <ng-container *ngIf="col.value === 'CDART'">
                                    {{ article.CDART | uppercase }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'CSTNIV1'">
                                    {{ article.CSTNIV1 && article.CSTNIV1 != '0'?article.CSTNIV1:'' }}
                                    {{ article.CSTNIV2 && article.CSTNIV2 != '0'?' / '+article.CSTNIV2:'' }}
                                    {{ article.CSTNIV3 && article.CSTNIV3 != '0'?' / '+article.CSTNIV3:'' }}
                                    {{ article.CSTNIV4 && article.CSTNIV4 != '0'?' / '+article.CSTNIV4:'' }}
                                    {{ article.CSTNIV5 && article.CSTNIV5 != '0'?' / '+article.CSTNIV5:'' }}  
                                </ng-container>
                                <ng-container *ngIf="col.value === 'CSFSFAM'">
                                    {{ article.CSFSFAM && article.CSFSFAM !== null && article.CSFSFAM != '0'?article.CSFLIB1:'' }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'CAECODE'">
                                    {{ (article.CAECODE && article.CAECODE === 'A'?'kartactive':'kartinactive') | translate }}
                                </ng-container>
                                <ng-container *ngIf="col.value === 'STOCKDISPO'">
                                    <div class="align-items-center d-flex" [class]="(article.CAGESTOCK == '-1' && article.CAOKLIV != '-1') && displayType !== 'component'?'justify-content-around':'justify-content-start'" >
                                        <span class="mr-1">{{ (article.CAGESTOCK == '-1' && article.CAOKLIV != '-1')?((article.STOCKDISPO != null?article.STOCKDISPO:0) | cap_decimal):('kartmang' | translate) }}</span>
                                        <button type="button" mat-mini-fab color="primary" (click)="openUpDetailStock(article)" class="tiny" *ngIf="(article.CAGESTOCK == '-1' && article.CAOKLIV != '-1') && displayType !== 'component'">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="col.value === 'calculated_price'">
                                    {{ (article.calculated_price ? +article.calculated_price : 0) | cap_currency:null:false }}
                                </ng-container>

                                <ng-container *ngIf="col.value !== 'CDART' && col.value !== 'CSTNIV1' && col.value !== 'CSFSFAM' && col.value !== 'CAECODE' && col.value !== 'STOCKDISPO' && col.value !== 'calculated_price'">
                                    {{  article[col.value] }}
                                </ng-container>
                            </td>

                            <td (click)="editArticle(article)">
                                <button type="button" mat-mini-fab color="primary" *ngIf="displayType === 'component'">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </tr>                        
                    </tbody>

                    <tbody *ngIf="listArticle?.length == 0 || showLoader">
                        <tr>
                            <td [attr.colspan]="multiSelectionEnabled?11:10" *ngIf="listArticle?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate  }}</td>
                            <td [attr.colspan]="multiSelectionEnabled?11:10" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                            <td *ngIf="multiSelectionEnabled" style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>

                <mat-list *ngIf="isMobile && (listArticle?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="listArticle?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">                        
                        <p matLine class="text-center" *ngIf="displayType === 'component'">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                        <p matLine class="text-center" *ngIf="displayType === 'dialog'">
                            {{ 'kchargement' | translate }}
                        </p>
                    </mat-list-item>
                </mat-list>

                <mat-list *ngIf="isMobile && (listArticle?.length != 0 && !showLoader)">
                    <mat-list-item  *ngFor="let article of listArticle" [class.bg-warning]="searchedArticle && searchedArticle.CAINT == article.CAINT" class="test">
                        <div class="w-100" [class.d-flex]="multiSelectionEnabled">
                            <div style="width: 7%;text-align: center;" *ngIf="multiSelectionEnabled">
                                <mat-checkbox [(ngModel)]="checkedLineItems[article.CDART]" (ngModelChange)="itemChosenChange($event, article)" class="mt-2"></mat-checkbox>
                            </div>
                            <div [style]="multiSelectionEnabled?'width: 93%':''" (click)="editArticle(article)">
                                <img matListAvatar [src]="getImageURL(article.CDART)" default="assets/images/no_img.png" [alt]="article.CDART">
                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                    <h2 class="m-0">
                                        {{ article.CDART }}
                                        <strong>
                                            {{ article.CSTNIV1 && article.CSTNIV1 != '0'?' - ' + article.CSTNIV1:'' }}
                                            {{ article.CSTNIV2 && article.CSTNIV2 != '0'?' / '+article.CSTNIV2:'' }}
                                            {{ article.CSTNIV3 && article.CSTNIV3 != '0'?' / '+article.CSTNIV3:'' }}
                                            {{ article.CSTNIV4 && article.CSTNIV4 != '0'?' / '+article.CSTNIV4:'' }}
                                            {{ article.CSTNIV5 && article.CSTNIV5 != '0'?' / '+article.CSTNIV5:'' }}
                                        </strong>
                                    </h2>
                                    <p class="m-0">
                                        <span class="badge" [class]="article.CAECODE && article.CAECODE === 'A'?'badge-success':'badge-danger'" translate>{{ article.CAECODE && article.CAECODE === 'A'?'kartactive':'kartinactive' }}</span>
                                    </p>
                                </div>

                                <p matLine class="mobile-list-title">
                                    {{ article.CALIB1 }} {{ article.CALIB3 }}
                                </p>

                                <p matLine class="mt-1 align-items-end d-flex flex-row justify-content-end">
                                    <span class="badge badge-info">{{ (article.CAGESTOCK == '-1' && article.CAOKLIV != '-1')?((article.STOCKDISPO != null?article.STOCKDISPO:0) | cap_decimal):('kartmang' | translate) }}</span>
                                    <span class="badge badge-secondary ml-2">
                                 {{ (article.calculated_price ? +article.calculated_price : 0) | cap_currency:null:false }}
                            </span>
                                </p>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>              

            </div>
        </div>
    </div>
</div>

<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange">
    <div class="form-inline row-item">
        <i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
            <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
            <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
        </i>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckedChange()"
                [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
            <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
                {{item.text}}
            </label>                                
        </div>
    </div>
</ng-template>

<ng-template #sousItemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange">
    <div class="form-inline row-item">
        <i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
            <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
            <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
        </i>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckedChange()"
                [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
            <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
                {{item.text}}
            </label>                                
        </div>
    </div>
</ng-template>
