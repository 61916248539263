import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'providers/constants';

@Injectable()
export class GestionDesDroitService {
    constructor(private http: HttpClient) {
    }
    
    getListofDroits(IDMAG: string, CIINT: string, niveauId?: number): Observable<any> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {
            IDMAG: IDMAG,
            IDSOC: Constants.IDSOC,
            CIINT: CIINT
        };
        
        if(niveauId !== undefined && niveauId !== null) {
            params['NIVINT'] = niveauId;
        }

        const options = {
            headers: headers,
            params: params
        }; 
        
        return this.http.get(Constants.API_GESTION_DES_DROITS_URL + 'getDroitsList', options);        
    }

    
    getListofNiveau(IDMAG: string, CIINT: string): Observable<any> {        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');               

        const options = {
            headers: headers,
            params: {
                IDMAG: IDMAG,
                IDSOC: Constants.IDSOC,
                CIINT: CIINT
            }
        };        
        
        return this.http.get(Constants.API_USER_URL + 'getUserNiveauList', options);        
    }


    updateGestionDroit(data: any): Observable<any> {

        const headers = new HttpHeaders();
        
        const params = {};        

        const options = {
            headers: headers,
            params: params
        };        
        
        return this.http.post(Constants.API_GESTION_DES_DROITS_URL + 'updateDroits', data, options);
    }

}