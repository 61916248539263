import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Constants} from '../constants';

import {Observable} from 'rxjs';

@Injectable()
export class CiviliteService {

    constructor(private http: HttpClient) {
        
    }

    getCivilite(IDMAG: string, CIINT: string): Observable<any> {        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers,
            params: {
                IDMAG: IDMAG,
                IDSOC: Constants.IDSOC,
                CIINT: CIINT
            }
        };
        
        return this.http.get(Constants.API_CIVILITE_URL + 'getCivilite', options);
    }

}