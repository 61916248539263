<h1 mat-dialog-title class="text-center">{{ confirmation_title }}</h1>
<div mat-dialog-content class="text-center" *ngIf="confirmation_message" [innerHtml]="confirmation_message"></div>
<div mat-dialog-actions class="justify-content-center">  
  <button mat-button (click)="closeWithConfirm()" [disabled]="okButtonDisabled" >
    <mat-icon>done</mat-icon>
    <span class="d-none d-sm-none d-md-block">
      {{ okButtonText }}
    </span>    
  </button>
  <button mat-button mat-dialog-close="cancel" class="" cdkFocusInitial *ngIf="!OkButtonOnly" #annulerButton="matButton">
    <mat-icon>close</mat-icon>
    <span class="d-none d-sm-none d-md-block">
      {{ cancelButtonText }}
    </span>    
  </button>
</div>
