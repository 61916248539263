import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-gestion-filter',
  templateUrl: './gestion-filter.component.html',
  styleUrls: ['./gestion-filter.component.scss']
})
export class GestionFilterComponent implements OnInit {
  
  niveauTypes: any;
  filteredNiveauTypes: any;  
  oldFormData: any;
  searchForm: any;
  filterNiveau: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<GestionFilterComponent>,
  private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {    
    this.niveauTypes = this.data.niveauTypes;
    this.filteredNiveauTypes = this.data.filteredNiveauTypes;
    
    this.searchForm = this.formBuilder.group({
      search_niveau: [1, Validators.required],      
    });
    
    this.oldFormData = this.data.form_data;

    this.searchForm.patchValue({
      search_niveau: this.oldFormData.search_niveau
    });
  }

  headerSearchSubmit() {    
    this.dialogRef.close({status: 'success', form_data: this.searchForm.value });
  }

  onSearchChange(search: string) {
    this.filteredNiveauTypes = this.niveauTypes.filter(row => row.NIVNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

}
