import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import BonPanier from 'models/bonPanier';

@Component({
  selector: 'app-track-delivery',
  templateUrl: './track-delivery.component.html',
  styleUrls: ['./track-delivery.component.scss']
})
export class TrackDeliveryComponent implements OnInit {

  panier: BonPanier;
  iframeUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<TrackDeliveryComponent>,) { }

  ngOnInit(): void {
    this.panier = this.data.panier;
    this.iframeUrl = this.panier.lienlog;
  }

}
