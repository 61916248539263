import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from './api/utilService';

@Pipe({
  name: 'cap_decimal',  
})
export class CapDecimalPipe implements PipeTransform {

  constructor(private utilService: UtilService) { }

  __reduceDecimalPlaces(value: number) {
    let locale = 'fr-FR';
    if(this.utilService.getCurrentDecimalSymbol === ',') {
      locale = 'fr-FR';
    } else {
      locale = 'en-US';
    }

    if(value % 1 != 0) formatNumber(value, locale, '0.2')

    return formatNumber(value, locale, '0.0');
  }

  transform(value: number) {
      return this.__reduceDecimalPlaces(value)
  }
}