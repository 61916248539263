import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gestion-pre-affectation',
  templateUrl: './gestion-pre-affectation.component.html',
  styleUrls: ['./gestion-pre-affectation.component.scss']
})
export class GestionPreAffectationComponent implements OnInit {

  isMobile: boolean;
  showLoader = false;

  listArticles: any[] = [];

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private deviceDetector: DeviceDetectorService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
  }

}
