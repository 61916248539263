import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'models/articles';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from 'providers/api/storageService';
import { UserService } from 'providers/api/userService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-article',
  templateUrl: './search-article.component.html',
  styleUrls: ['./search-article.component.scss']
})
export class SearchArticleComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('filterFirstEl', { static: false }) filterFirst: ElementRef;
  
  searchedArticle: Article;
  searchNomPhrase: string;
  // Mobile - Filter needs
  searchValue: any;
  
  searchInputSubscription: Subscription;
  searchInputValueSubscription: Subscription;
  popupCloseSubscription : Subscription

  isMobile: boolean = false;
  formSearchType = 'basic';

  preFilterConf: any;
  filterValues: any;
  flowCMINT: any;
  flowType: 'bonde_vente'|'bonde_commande'|'devis'|'retrait_marchandise';

  multiArticles: any[] = [];

  currentArticleStockMag: number | undefined;
  currentArticleStockMagNom: string = "";
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SearchArticleComponent>,
    private userService: UserService,
    private deviceDetector: DeviceDetectorService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();

    if(this.data) {
      this.flowType = this.data.flowType;
      this.flowCMINT = this.data.CMINT; 
      this.searchNomPhrase = this.data.CDART;

      if (this.data.stockAutreMag !== undefined && this.data.stockAutreMag !== null) {
        this.currentArticleStockMag = this.data.stockAutreMag;
        this.currentArticleStockMagNom = this.data.stockAutreMagNom;
        this.flowCMINT = this.currentArticleStockMag;
      }

      if(this.data.formType === 'advancedSearch') {
        this.formSearchType = 'advancedSearch';

        this.preFilterConf = this.data.filterData;
        this.filterValues = this.data.filterData.form_data;
      }
    }

    this.popupCloseSubscription = this.userService.popupClose.subscribe(result =>{
      if(result.action === 'clientAdsearch'){
        this.inputFocus();
        const formData = this.userService.getAdvanceSearchFormData();
        if(this.preFilterConf){
          this.preFilterConf['form_data'] = formData;
        }
        this.filterValues = formData;
      }
    })
    
    this.searchInputSubscription = this.userService.headerSearchInput.subscribe((status) => {
      if(status === false) {
          this.searchValue = '';
      }
    });

    this.searchInputValueSubscription = this.userService.headerSearchInputValue.subscribe(
      (search_code) => {
        if(search_code !== undefined) {
          this.searchValue = search_code;
        }
      }
    );

    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.searchInputSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.filterFirst) {
        this.filterFirst.nativeElement.select();
        this.filterFirst.nativeElement.focus();
        this.filterFirst.nativeElement.setAttribute('inputmode', 'text');
        this.filterFirst.nativeElement.setAttribute('type', 'text');
      }  
    }, 500);
  }

  get multiSelectionEnabled() {
    return !this.currentArticleStockMag;
  }

  search_callback() {    
    this.userService.headerSearch.next(true);
  }

  submit_search_input() {     
    this.userService.headerSearchInputValue.next(this.searchValue);  
    this.filterValues.CDART = '';
    this.filterValues.NOM = '';
    this.filterValues.CODEEXT = '';
    this.filterValues.LIV = '';
    this.filterValues.MTTTCMIN = '';
    this.filterValues.MTTTCMAX = '';
    this.filterValues.STRUCTU_LABEL = '';
    this.filterValues.STOCK_SUPERIOR = '';
    this.filterValues['rapidSearch'] = this.searchValue;
  }

  submitSelectedArticle = (chosenArticle: Article) => {
    this.searchedArticle = chosenArticle;

    this.searchValue = '';
    this.dialogRef.close({ status: 'success', data: this.searchedArticle });
  }

  inputFocus(event?: any){
    if(this.filterFirst) {
      this.filterFirst.nativeElement.select();
      this.filterFirst.nativeElement.focus();
      this.filterFirst.nativeElement.setAttribute('inputmode', 'text');
      this.filterFirst.nativeElement.setAttribute('type', 'text');
    }
  }

  onChosenArticleChange(chosenItems: any[]) {
    this.multiArticles = chosenItems;
  }

  onMultiArticleValidation() {
    if(this.multiArticles === undefined || this.multiArticles == null || this.multiArticles.length === 0) {
      return;
    }

    this.searchValue = '';
    this.dialogRef.close({ status: 'success', multi: true, data: this.multiArticles });
  }

  get filterValuesFormat(): string {
    let filterArr = [];
    if( this.filterValues['rapidSearch']){
      filterArr.push( this.filterValues['rapidSearch']);
    }
    if(this.filterValues.CDART !== undefined && this.filterValues.CDART !== null && this.filterValues.CDART !== '') {
      filterArr.push(this.filterValues.CDART);
    }
    if(this.filterValues.NOM !== undefined && this.filterValues.NOM !== null && this.filterValues.NOM !== '') {
      filterArr.push(this.filterValues.NOM);
    }
    if(this.filterValues.CODEEXT !== undefined && this.filterValues.CODEEXT !== null && this.filterValues.CODEEXT !== '') {
      filterArr.push(this.filterValues.CODEEXT);
    }       
    if(this.filterValues.MTTTCMIN !== undefined && this.filterValues.MTTTCMIN !== null && this.filterValues.MTTTCMIN !== '') {
      filterArr.push(this.filterValues.MTTTCMIN);
    }
    if(this.filterValues.MTTTCMAX !== undefined && this.filterValues.MTTTCMAX !== null && this.filterValues.MTTTCMAX !== '') {
      filterArr.push(this.filterValues.MTTTCMAX);
    }   
    if(this.filterValues.LIV !== undefined && this.filterValues.LIV !== null && this.filterValues.LIV !== '') {
      if(this.filterValues.LIV) {
        filterArr.push(this.translateService.instant('kartdelitem'));
      }
    }
    if(this.filterValues.ACTIF !== undefined && this.filterValues.ACTIF !== null && this.filterValues.ACTIF !== '') {
      if(this.filterValues.ACTIF) {
        filterArr.push(this.translateService.instant('kartactitm'));
      }
    }
    if(this.filterValues.STOCK_SUPERIOR !== undefined && this.filterValues.STOCK_SUPERIOR !== null && this.filterValues.STOCK_SUPERIOR !== '') {
      if(this.filterValues.STOCK_SUPERIOR) {
        filterArr.push(this.translateService.instant('kdisponibleimmedi'));
      }
    }

    const structuKeyWords = this.storageService.getItem('STRUCTU_KEYWORDS');
    if(structuKeyWords !== undefined && structuKeyWords != 'null' && structuKeyWords != null && structuKeyWords !== '') {
      filterArr.push(`"${structuKeyWords}"`);
    }
    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
