<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium" translate>kartclearform</div>
            </button>
        </div>
    </div>
</div>
<form [formGroup]="searchForm" (ngSubmit)="headerSearchFormSubmitter()">
    <div class="bloc_container padding_15 bg_light">
        <div class="row no-gutters">
            <div class="col-12 ">

                <div class="row">
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput inputmode="numeric" formControlName="postal_code" maxlength="5" [placeholder]="'kpcode' | translate" #filterFirst />
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                        <mat-form-field>
                            <input matInput formControlName="city" [placeholder]="'kpcity' | translate" />
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                        <mat-form-field>
                            <mat-label>{{ "kbontrierpar" | translate }}</mat-label>
                            <mat-select disableOptionCentering panelClass="dropdown-panel" color="primary" formControlName="order">
                                <mat-option *ngFor="let item of orderByList" [value]="item.value">{{ item.label | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-12 mt-2 mt-md-0 text-center">
                <button class="bt_valid" mat-button type="submit">
                    <mat-icon>search</mat-icon>
                    <span class="d-none d-sm-none d-md-block">{{ "kpsearch" | translate }}</span>                    
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kucancel" | translate }}
                    </span>                        
                </button>
            </div>
        </div>
    </div>
</form>