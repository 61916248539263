import {Component, AfterViewInit, OnInit, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

import {UserService} from 'providers/api/userService';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from 'providers/constants';
import { PostalCodeModel } from 'models/postal-code';
import { PostalCodesService } from 'providers/api/postalCodesService';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { EditPostalCodeComponent } from '../edit-postal-code/edit-postal-code.component';
import { DialogsService } from 'providers/api/dialogService';
import { AddPostalCodeComponent } from '../add-postal-code/add-postal-code.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PostalCodeFilterComponent } from './postal-code-filter/postal-code-filter.component';

@Component({
  selector: 'app-postal-codes',
  templateUrl: './postal-codes.component.html',
  styleUrls: ['./postal-codes.component.scss'],
  providers: [PostalCodesService]
})
export class PostalCodesComponent implements OnInit, OnDestroy, AfterViewInit {

  searchForm: UntypedFormGroup;
  orderByList = [
    {
      label: "kpcode",
      value: Constants.POSTAL_CODE_ORDER_CODE
    },
    {
      label: "kpname",
      value: Constants.POSTAL_CODE_ORDER_NOM
    },
  ];

  // bouton ENTER valide formulaire
  private pressedButton: any;

  // champs de recherche
  searchData: any = null;

  // Service
  listPostalCodes: PostalCodeModel[] = [];

  user: any;
  USERCMINT: any;

  // DATATABLE  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  searched = false;

  isMobile: boolean = false;
  searchSubscription: Subscription;
  searchCodeSubscription: Subscription;
  addListenerSubscription: Subscription;

  filterValues: any;

  constructor(private userService: UserService, 
    public postalCodeService: PostalCodesService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private loadingService: NgxSpinnerService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService) { 
     
  }

  ngOnInit(): void {
    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.isMobile = this.deviceService.isMobile();

    this.userService.headerAdd.next(false);
    this.userService.headerAddListener.next(false);
    this.userService.headerSearchInputThreeDots.next(true);
    this.userService.headerSearchInput.next(true);
    this.userService.headerSearchInputType.next('text');
    this.userService.headerSearch.next(false);

    this.searchForm = this.formBuilder.group({
      postal_code: ['', [Validators.pattern('[0-9]*'), Validators.maxLength(5)]],
      city: [''],
      order: [Constants.POSTAL_CODE_ORDER_CODE],
      sort: ['asc']
    });
    
    this.searchSubscription = this.userService.headerSearch.subscribe(
      (status: boolean) => {
        if(status === true) {
          this.openUpFilterScreen();
        }
      }
    ) 

    this.searchCodeSubscription = this.userService.headerSearchInputValue.subscribe(
      (search_code) => {
        if(search_code !== undefined) {
          let rapid_search_code:any = Number(search_code);
          if(isNaN(rapid_search_code)){
            this.searchForm.patchValue({ city: search_code === null?'':search_code, postal_code : '' });
          } else {
            this.searchForm.patchValue({ postal_code: search_code === null?'':search_code, city: '' });          
          }
          this.searchFormSubmitter();
        }
      }
    )
  
    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }          
      },      
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.getListPostalCodes();
  }

  ngOnDestroy(): void {
    this.userService.headerAdd.next(false);
    this.userService.headerSearch.next(false);
    this.userService.headerSearchInput.next(false);
    this.userService.headerAddListener.next(false);
    this.searchSubscription.unsubscribe();
    this.searchCodeSubscription.unsubscribe();
    this.dtTrigger.unsubscribe();    
  }

  ngAfterViewInit(): void {      
      this.dtTrigger.next();
  }

  get orderedByColumn() {
    return this.searchForm.get('order').value;
  }

  get sortByColumn() {
    return this.searchForm.get('sort').value;
  }

  sortingOrder(orderBy, sort?: 'asc' | 'desc') {
    let prevOrder = this.orderedByColumn;
    this.searchForm.get('order').setValue(orderBy);

    if(sort === undefined || sort == null) {
      let prevSort = this.sortByColumn;
      if(prevOrder === orderBy) {
        this.searchForm.get('sort').setValue(prevSort === 'asc'?'desc':'asc');
      } else {
        this.searchForm.get('sort').setValue('asc');
      }
      
    }

    this.searchFormSubmitter();
  }

  getListPostalCodes(page?: number) {
    this.postalCodeService.getListofPostalCodes(page).subscribe(
      (response) => {        
        const data = response.data;

        this.rowsPerPage = response.rowsPerPage;
        this.totalPages = response.count;        

        this.listPostalCodes = [...data];
        
        this.showLoader = false;
        this.dtTrigger.next();
      },
      error => {
        this.showLoader = false;        
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )  
  }
  resetForm(){    
    this.searchForm.patchValue({
      postal_code: '',
      city: '',
      order: Constants.POSTAL_CODE_ORDER_CODE
    });
    
    this.filterValues = undefined;    
    this.searchFormSubmitter();
  }

  searchFormSubmitter(): void {        
    this.showLoader = true;

    if(this.searchForm.invalid) {
      this.showLoader = false;
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }

    const form_data = this.searchForm.value;    
    this.filterValues = form_data;

    this.postalCodeService.searchPostalCodes(form_data.order, form_data.postal_code, form_data.city, form_data.sort).subscribe(
      (response: any) => {
        this.currentPage = 0;

        const data = response.data;

        this.rowsPerPage = response.rowsPerPage;
        this.totalPages = response.count;

        this.listPostalCodes = [...data];

        this.searched = true;        

        this.showLoader = false;
        this.dtTrigger.next();
      }, 
      err => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )    
  }

  searchPaginate(page?: number) {
    const form_data = this.searchForm.value;
    
    if(this.searchForm.invalid) {
      this.showLoader = false;
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }
    
    this.filterValues = form_data;
    this.postalCodeService.searchPostalCodes(form_data.order, form_data.postal_code, form_data.city, form_data.sort, page).subscribe(
      (response: any) => {
        const data = response.data;

        this.rowsPerPage = response.rowsPerPage;
        this.totalPages = response.count;

        this.listPostalCodes = [...data];

        this.showLoader = false;
        this.dtTrigger.next();
      }, 
      err => {
        this.showLoader = false;
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )
  }

  paginationChange(event: any) {
    this.showLoader = true;
    const changedPageIndex = event.pageIndex;    

    this.currentPage = changedPageIndex;

    if(this.searched) {
      this.searchPaginate(changedPageIndex + 1);
    } else {
      this.getListPostalCodes(changedPageIndex + 1);
    }
  }

  addPostalCode(): void {
    const dialogRef = this.dialog.open(AddPostalCodeComponent, {      
      width: '100vw',
      autoFocus: !this.isMobile,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.currentPage = 0;
      if(this.searched) {
        this.searchPaginate(this.currentPage + 1);
      } else {
        this.getListPostalCodes(this.currentPage + 1);
      }
    });    
  }

  editPostalCode(code_deta: PostalCodeModel): void {
    const dialogRef = this.dialog.open(EditPostalCodeComponent, {      
      width: '100vw',
      autoFocus: !this.isMobile,
      data: code_deta
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result !== null) {
        this.listPostalCodes.map(el => {
          if(el.TROWID == result.TROWID) {
            const updated_el: PostalCodeModel = el;
  
            updated_el.CODPOS = result.CODPOS;
            updated_el.VILLE = result.VILLE;
            return updated_el;
          }
          return el;        
        })
      }
    });    
  }

  deleteConfirmation(row_id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {      
      width: '60vw',
      data: {
        title: this.translateService.instant('kpcdelete'),
        description: ""
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.deletePostalCode(row_id);
      }      
    });    
  }

  deletePostalCode(row_id: number): void {    
      this.loadingService.show();

      this.postalCodeService.deletePostalCode(row_id).subscribe(
        (resp) => {
          this.loadingService.hide();

          if(resp.statusCode == 200) {

            this.listPostalCodes = this.listPostalCodes.filter((el) => el.TROWID != row_id);

            if(this.listPostalCodes.length == 0) {            
              this.showLoader = true;

              if(this.currentPage != 0) {
                this.currentPage--;
              }
              
              if(this.searched) {
                this.searchPaginate(this.currentPage + 1);
              } else {
                this.getListPostalCodes(this.currentPage + 1);
              }
            }          

            this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kpdelete'));          
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kpremoveeerr'));          
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
        }
      );    
  }

  
  openUpFilterScreen() {
    this.userService.headerSearch.next(false);    
    const dialogRef = this.dialog.open(PostalCodeFilterComponent, {      
      width: '60vw',
      data: {
        form_data: this.searchForm.value,
        orderByList: this.orderByList,        
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        const form_data = result.form_data;

        this.searchForm.patchValue({
          postal_code: form_data.postal_code,
          city: form_data.city,
          order: form_data.order,
          sort: form_data.sort
        });

        this.filterValues = form_data;

        this.searchFormSubmitter();
      }      
    }); 
  }

  get filterValuesFormat(): string {
    let filterArr = [];

    if(this.filterValues.postal_code !== undefined && this.filterValues.postal_code !== null && this.filterValues.postal_code !== '') {
      filterArr.push(this.filterValues.postal_code);
    }

    if(this.filterValues.city !== undefined && this.filterValues.city !== null && this.filterValues.city !== '') {
      filterArr.push(this.filterValues.city);
    }

    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
