<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{ pageTitle | translate }}
            </h1>
        </div>
        <div class="col-12" *ngIf="!showLoader && isMobile">
            <button type="button" mat-raised-button class="bt_code mb-2" (click)="openColorCode()" >
                <div class="text-medium">{{'kdeviscodecol' | translate}}</div>
            </button>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile && filterValues">
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>

    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium">{{'kclclearform' | translate}}</div>
                </button>
            </div>
        </div>
    </div>

    <form #f="ngForm" [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="NUMBON"
                                       [placeholder]="'kbonnbon' | translate"
                                       class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="NOM"
                                       [placeholder]="'kpname' | translate"
                                       class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="PRENOM"
                                       [placeholder]="'kuprenom' | translate"
                                       class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <div class="row no-gutters">
                                <div class="col-md-4">
                                    <mat-form-field>
                                        <input matInput formControlName="CODPOS"
                                               [placeholder]="'kboncodeopostal' | translate"
                                               class="text-uppercase">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-8 pl-3">
                                    <mat-form-field>
                                        <input matInput formControlName="VILLE"
                                               [placeholder]="'kclcity' | translate"
                                               class="text-uppercase">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="TEL_PORT"
                                       [placeholder]="'kcltel' | translate"
                                       class="text-uppercase">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row no-gutters">
                                <div class="col-6">
                                    <mat-form-field>
                                        <input matInput formControlName="TOTAL_ENTRE_FROM"
                                               [placeholder]="'kbontotbetn' | translate"
                                               class="text-uppercase">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 pl-4">
                                    <mat-form-field>
                                        <input matInput formControlName="TOTAL_ENTRE_TO"
                                               [placeholder]="'kbonand' | translate"
                                               class="text-uppercase">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="NUM_EXT"
                                       [placeholder]="'kbonnext' | translate"
                                       class="text-uppercase">
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <mat-form-field>
                                <mat-select [placeholder]="'kbonsecteur' | translate" formControlName="SECTEUR" >
                                    <mat-option value="">{{'kutoussecteur' | translate}}</mat-option>
                                    <mat-option *ngFor="let magStruct of magasinStructure" [value]="magStruct.CSTNIV1">
                                        {{ magStruct.CSTNIV1+ ' ' + magStruct.CSTLIB1 }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput formControlName="ARTICLE" (keydown.enter)="onCodeEntered($event)"
                                       [placeholder]="'kartarttit' | translate"
                                       class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 mt-3">
                            <button type="button" mat-raised-button class="bt_code" (click)="openColorCode()" >
                                <div class="text-medium">{{'kdeviscodecol' | translate}}</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select [placeholder]="'Bons E2' | translate" formControlName="BONS_E2" >
                                    <mat-option *ngFor="let bonE2Item of bonsE2List" [value]="bonE2Item.id">{{ bonE2Item.label | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <mat-form-field>
                                <input matInput
                                       name="CODE"
                                       formControlName="CODE"
                                       [placeholder]="'kfournisseurcode' | translate"
                                       class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select [placeholder]="'kfournisseurlibel' | translate" formControlName="LIBEL" >
                                    <mat-option *ngFor="let fournisseurNomItem of fournisseurNomList" [value]="fournisseurNomItem.id">{{ fournisseurNomItem.label | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-12">
                            <div class="radio-container mt-3">
                                <mat-label>{{'kordre' | translate}}</mat-label>
                                <mat-radio-group name="ORDER_BY" formControlName="ORDER_BY" class="mt-3" >
                                    <mat-radio-button value="date"><span [translate]="'kdate'"></span></mat-radio-button>
                                    <mat-radio-button class="ml-3" value="nom_client"><span [translate]="'knomclient'"></span></mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button type="submit" class="bt_valid" mat-button>
                        <mat-icon>search</mat-icon>
                        {{ 'kpsearch' | translate }}
                    </button>
                    <button type="button" class="bt_valid ml-3" mat-button>
                        <mat-icon>print</mat-icon>
                        {{ 'klistedese2amettredecotre' | translate }}
                    </button>
                    <button type="button" class="bt_valid ml-3" (click)="affectationGlobaleConfirmation()" mat-button>
                        <mat-icon>checklist_rtl</mat-icon>
                        {{ 'kaffectationglobale' | translate }}
                    </button>
                </div>
                <div class="col-12 text-center">
                    <button type="button" class="bt_valid ml-3 mt-0" (click)="openUpDesaffecterPopup()" mat-button>
                        <mat-icon>refresh</mat-icon>
                        {{ 'kdesaffecter' | translate }}
                    </button>
                    <button type="button" class="bt_valid ml-3 mt-0" (click)="openUpPreAffectationPopup()" mat-button>
                        <mat-icon>task</mat-icon>
                        {{ 'kpreaffectation' | translate }}
                    </button>
                    <button type="button" class="bt_valid ml-3 mt-0" mat-button>
                        <mat-icon>done</mat-icon>
                        {{ 'kuvalid' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">
        <div class="row">
            <div class="col-12 no-padding table-scroller">
                <div>
                    <mat-paginator *ngIf="listBonDeCommande?.length != 0 && !showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                   [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                    >
                    </mat-paginator>
                </div>

                <table *ngIf="!isMobile" datatable class="row-border hover bonCommande" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead>
                    <tr>
                        <th class="nom" width="4%" nowrap>
                            <div class="d-flex justify-content-center align-items-center">
                                <mat-checkbox [(ngModel)]="checkedLineItems['all']" (ngModelChange)="itemChosenChange($event, 'all')" class="mt-2"></mat-checkbox>
                            </div>
                        </th>
                        <th nowrap width="10%" class="nom">{{ 'kbonbonl' | translate }}</th>
                        <th class="port">{{ 'kbon_stock_magasin' | translate }}</th>
                        <th class="nom" nowrap>{{ 'kbonnompre' | translate }}</th>
                        <th class="cp" nowrap>{{ 'kboncpville' | translate }}</th>
                        <th class="tel" width="90" style="width: 90px !important" nowrap>{{ 'kcltel' | translate }}</th>

                        <th class="date">{{ 'kbondate' | translate }}</th>

                        <th class="port">{{ 'kbontype' | translate }}</th>
                        <th class="port" width="5%">{{ 'kbontotal' | translate }}</th>

                        <th class="date" nowrap>{{ 'kbonnext' | translate }}</th>

                        <th class="date" style="width: 70px !important" width="50" nowrap>{{ 'Qté Cdé' | translate }}</th>
                        <th class="date" style="width: 70px !important" width="50" nowrap>{{ 'Qté réservée' | translate }}</th>

                        <th class="date" style="width: 110px !important" width="80" nowrap>{{ 'kkbondevente' | translate }}</th>

                        <th class="port"></th>
                    </tr>
                    </thead>
                    <tbody *ngIf="listBonDeCommande?.length != 0 && !showLoader">
                    <tr *ngFor="let bonCommande of listBonDeCommande" >
                        <td class="prenom" nowrap>
                            <div class="d-flex justify-content-center align-items-center">
                                <mat-checkbox [(ngModel)]="checkedLineItems[bonCommande.numbon]" (ngModelChange)="itemChosenChange($event, bonCommande)" class="mt-2"></mat-checkbox>
                            </div>
                        </td>
                        <td class="numero" nowrap (click)="redirectToModify(bonCommande)">
                            <span class="colorcode" [class]="defineRowColor(bonCommande)"></span> {{ bonCommande.numbon | slice:0:2 }} {{ bonCommande.numbon | slice:2:4 }} {{ bonCommande.numbon | slice:4:7 }} {{ bonCommande.numbon | slice:7:12 }} {{ bonCommande.numbon | slice:12:15 }}
                        </td>
                        <td class="date" (click)="redirectToModify(bonCommande)">{{ bonCommande.magstocklib }}</td>
                        <td class="prenom" (click)="redirectToModify(bonCommande)" nowrap>{{bonCommande.civlib?' '+bonCommande.civlib:'' }}{{ bonCommande.nom?' '+bonCommande.nom:'' }}<br> {{ bonCommande.prenom | uppercase  }}</td>
                        <td class="cp" (click)="redirectToModify(bonCommande)">{{ bonCommande.cp }}<br> {{ bonCommande.ville | uppercase }}</td>
                        <td class="tel" (click)="redirectToModify(bonCommande)" [innerHtml]="(bonCommande.gsm | formatTele)+(bonCommande.tel?(bonCommande.gsm !== '' && bonCommande.gsm != null?'<br />/ ':'')+(bonCommande.tel | formatTele):'')"></td>

                        <td class="date" (click)="redirectToModify(bonCommande)">{{ listDateFormatter(bonCommande.dtmaj) }}</td>

                        <td class="port" (click)="redirectToModify(bonCommande)">{{ bonCommande.typecde }}</td>
                        <td class="port" (click)="redirectToModify(bonCommande)" nowrap>
                            <div style="width: 60px;white-space: break-spaces;">{{ bonCommande.montant | cap_currency:null:false }}</div>
                        </td>

                        <td class="date" (click)="redirectToModify(bonCommande)">{{ bonCommande.numexterne }}</td>

                        <td class="date" (click)="redirectToModify(bonCommande)">{{ bonCommande.montant }}</td>
                        <td class="date" (click)="redirectToModify(bonCommande)">{{ bonCommande.montant }}</td>

                        <td class="date" (click)="redirectToModify(bonCommande)">{{ bonCommande.numbonrgp }}</td>

                        <td nowrap>
                            <button type="button" mat-mini-fab color="primary" class="ml-1" [title]="detailBonCommandeVisibility?('kkbondecomdtxt' | translate):''" (click)="redirectToModify(bonCommande)">
                                <mat-icon>{{ detailBonCommandeVisibility?'visibility':'edit' }}</mat-icon>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tbody *ngIf="listBonDeCommande?.length == 0 || showLoader">
                    <tr>
                        <td [attr.colspan]="14" *ngIf="listBonDeCommande?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                        <td [attr.colspan]="14" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                        <td style="display: none;"></td>
                    </tr>
                    </tbody>
                </table>
                <mat-list *ngIf="isMobile && (listBonDeCommande?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="listBonDeCommande?.length == 0 && !showLoader">
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">
                        <p matLine class="text-center">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                    </mat-list-item>
                </mat-list>
                <mat-list *ngIf="isMobile && (listBonDeCommande?.length != 0 && !showLoader)">

                    <mat-list-item  *ngFor="let bonCommande of listBonDeCommande;let i=index;" [id]="'swipeItem-' + i" style="user-select: none;">
                        <div class="w-100 list-item-main-content" (click)="redirectToModify(bonCommande)">
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <p class="m-0">
                                    <span class="colorcode" [class]="defineRowColor(bonCommande)"></span> {{ bonCommande.numbon | slice:0:2 }} {{ bonCommande.numbon | slice:2:4 }} {{ bonCommande.numbon | slice:4:7 }} {{ bonCommande.numbon | slice:7:12 }} {{ bonCommande.numbon | slice:12:15 }}
                                </p>
                                <p class="m-0">{{ listDateFormatter(bonCommande.dtmaj) }}</p>
                            </div>
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <div matLine class="mobile-list-title">{{bonCommande.civlib?' '+bonCommande.civlib:'' }} {{ bonCommande.nom | uppercase }} {{ bonCommande.prenom | uppercase  }}</div>
                                <p matLine class="mobile-list-title text-right no-margin">
                                    <span class="badge badge-secondary">{{ bonCommande.montant | cap_currency:null:false }}</span>
                                </p>
                            </div>
                            <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                <p matLine class="mobile-list-title mb-0">
                                    {{ bonCommande.cp }} {{ bonCommande.ville?bonCommande.ville+(bonCommande.gsm?' , ':''):'' | uppercase }}<br>
                                    {{ bonCommande.gsm | formatTele}} {{ bonCommande.tel?(bonCommande.gsm !== '' && bonCommande.gsm != null?' / ':'')+(bonCommande.tel | formatTele):'' }}<br>
                                    <span class="d-flex align-items-center mt-1">
                                        <mat-icon class="mr-1 responsive-icon">assignment</mat-icon>
                                        <span>{{ bonCommande.numbonrgp | slice:0:2 }} {{ bonCommande.numbonrgp | slice:2:4 }} {{ bonCommande.numbonrgp | slice:4:7 }} {{ bonCommande.numbonrgp | slice:7:12 }} {{ bonCommande.numbonrgp | slice:12:15 }}</span>
                                    </span>
                                </p>
                                <p matLine class="mobile-list-title text-right">
                                    <span>{{ bonCommande.typecde }}</span>
                                </p>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>
