import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import BonPanier from 'models/bonPanier';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { DialogsService } from 'providers/api/dialogService';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { TicketsAcompte } from 'models/bonCommande';
import { Client } from 'models/clients';
import { DeviceDetectorService } from 'ngx-device-detector';
import {UserService} from '../../../providers/api/userService';
import {User} from '../../../models/users';
import {Constants} from '../../../providers/constants';
import RequestPaymentLink from '../../../models/saferpay';
import { Magasin } from 'models/magasins';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  @Input() ticketData;

  showAcompteLoader: boolean = true;
  showArticleLoader: boolean = true;
  showReglementLoader: boolean = true;
  
  listTicketAcompte: TicketsAcompte[] = [];
  listTicketsArticle: TicketsAcompte[] = [];
  listReglements: any[] = [];

  totalTicketAcompte: number = 0;
  totalTicketArticle: number = 0;
  totalTicketReglement: number = 0;
  
  vTotAco = 0;
  vTotTreso = 0;
  vTotArt = 0;
  vTotCom = 0;
  vTotPrPe = 0;

  totalRestePayer: number = 0;
  totalTickets: number = 0;

  commandeRow: any;
  panier: BonPanier;
  client: Client;
  numBon: string;

  CMINT: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  bonDuDate: string;
  bonClientNom: string;
  isMobile: boolean;

  pageType: 'commande' | 'relance' | 'historique' | 'supprimees' | 'retrait-marchandise' = 'commande';

  paymentUrl: string | undefined;
  paymentApiKey: string | undefined;
  user: User;
  USERCMINT: any;

  listMagasins: Magasin[];

  showLoader = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  
              private bonCommandeService: BonCommandeService,
              private loadingService: NgxSpinnerService,
              private translateService: TranslateService,
              private dialogService: DialogsService,
              private deviceDetector: DeviceDetectorService,
              private userService: UserService,
              private storageService: StorageService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    if(this.isMobile){
      this.data = this.ticketData;
    }

    this.user = this.userService.getLoggedUser();
    if (this.user !== undefined && this.user != null) {
      this.USERCMINT = this.user.CMINT;
    }

    this.numBon = this.data.numBon;
    this.panier = this.data.panier;
    this.client = this.data.client;
    this.CMINT = this.data.CMINT;
    this.commandeRow = this.data.commandeData;
    this.pageType = this.data.pageType;
    this.listMagasins = this.data.listMagasins;

    this.loadDependencies();
  }

  async loadDependencies() {
    this.loadingService.show();
    if(this.panier !== undefined && this.panier !== null) {
      this.bonClientNom = (this.client.CIVLIBC !== undefined && this.client.CIVLIBC != null?this.client.CIVLIBC+' ':'')+this.panier.client.nom+' '+this.panier.client.prenom;
      this.bonDuDate = moment(this.panier.dtsaisie).utcOffset(this.panier.dtsaisie).format('DD.MM.YYYY hh:mm');
    } else {
      this.bonClientNom = this.commandeRow.nom+' '+this.commandeRow.prenom;
      this.bonDuDate = moment(this.commandeRow.dtsaisie).utcOffset(this.commandeRow.dtsaisie).format('DD.MM.YYYY hh:mm:ss');
    }

    await this.getCommandePaymentUrl();
    await this.getCommandeTickets();
    this.loadingService.hide();
  }

  get paymentButtonStatus(): boolean {
    return this.paymentUrl &&
        (this.totalRestePayer !== undefined && this.totalRestePayer !== null && this.totalRestePayer > 0) &&
        (this.panier && this.panier.client.email != null && this.panier.client.email !== '');
  }

  // CAP-1248: Removing the Pay button on bon de commande history and delete pages.
  get checkPageType(): boolean {
    return this.pageType === "supprimees" || this.pageType === "historique";
  }

  async getCommandePaymentUrl() {
    try {
      const paramData = await this.userService.getRuleParamsData(30, 9);
      this.paymentUrl = undefined;

      if (paramData.VSTR2 !== undefined && paramData.VSTR2 != null && paramData.VSTR2 !== '') {
        this.paymentUrl = paramData.VSTR2;
      }
      if (paramData.VSTR2 !== undefined && paramData.VSTR2 != null && paramData.VSTR2 !== '') {
        this.paymentApiKey = paramData.VSTR3;
      }
    } catch (err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  _totalCalculation() {
    this.listTicketAcompte.map(
      (row) => {
        this.totalTicketAcompte = this.totalTicketAcompte + (+row.MNTTTC);
      }
    );
    
    this.listReglements.map(
      (row) => {
        this.totalTicketReglement = this.totalTicketReglement + (+row.MNTTTC);
      }
    );

    this.listTicketsArticle.map(
      (row) => {
        this.totalTicketArticle = this.totalTicketArticle + (+row.MNTTTC);
      }
    );
  }

  getCommandeTickets(): Promise<any> {
    if(this.numBon === undefined || this.numBon == 'null' || this.CMINT === undefined || this.CMINT == 'null') return;

    return new Promise((resolve, reject) => {
      this.bonCommandeService.getBonComandeTickets(this.numBon, this.CMINT, this.pageType).subscribe(
        (data: any) => {
            this.showLoader = false;
            if(data.success !== undefined && data.success === 'ok') {
              this.listTicketAcompte = data.ticketsAcompte;
              this.listReglements = data.modeDeReglement;
              this.listTicketsArticle = data.ticketsArticle;

              this.vTotAco = data.vTotAco;
              this.vTotTreso = data.vTotTreso;
              this.vTotArt = data.vTotArt;
              this.vTotCom = data.vTotCom;
              this.vTotPrPe = data.vTotPrPe;

              this.totalTickets = this.vTotAco + this.vTotArt;
              this.totalRestePayer = this.panier.montantrestant;

              this._totalCalculation();

              this.showAcompteLoader = false;
              this.showReglementLoader = false;
              this.showArticleLoader = false;

              resolve(data);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.showLoader = false;
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  listDateFormatter(date: string, offset = true, today_time = true) {
    if(date === undefined || date  === null) {
      return '';
    }
    
    const given_date = moment(date).format('DD.MM.YYYY');
    const today_date = moment().format('DD.MM.YYYY');

    if(given_date === undefined || given_date === '30.12.1899' || given_date === '29.12.1899') {
      return '';
    }

    if(given_date === today_date && today_time) {
      return moment(date).utcOffset(date).format('HH:mm');
    }

    if(offset) {
      return moment(date).utcOffset(date).format('DD.MM.YY');
    }
    return moment(date).format('DD.MM.YY');
  }

  onPayButtonClicked() {
    const langCode = this.translateService.getDefaultLang();
    const currencyString = this.storageService.getItem('CAP_CURRENCY_TEXT'); // VSTR3

    const CMINT = this.panier.mag;
    const clientDetail = this.panier.client;

    if ((clientDetail.prenom === undefined || clientDetail.prenom == null || clientDetail.prenom === '') ||
        (clientDetail.nom === undefined || clientDetail.nom == null || clientDetail.nom === '')) {
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kpay_firstname_lastname_mandatory'));
      return;
    }

    const paymentData: { language: string, saferPayData: RequestPaymentLink} = {
      language: langCode,
      saferPayData: {
        Payment: {
          Description: 'Payment Link gen. from CapPayment (via CapOrder)',
          Amount: {
            Value: Math.round(this.panier.montantrestant * 100), // Multiples of hundreds
            CurrencyCode: currencyString
          },
          OrderId: `${this.numBon}-${CMINT}`
        },
        Payer: {
          LanguageCode: langCode,
          BillingAddress: {
            FirstName: clientDetail.prenom,
            LastName: clientDetail.nom,
            Company: '-',
            Gender: clientDetail.civil === 1 ? 'MALE' : 'FEMALE',
            Street: (clientDetail.adr1 || clientDetail.adr2 || clientDetail.adr3 || clientDetail.adr4),
            Zip: clientDetail.codpos,
            City: clientDetail.ville,
            CountryCode: clientDetail.pays,
            Email: clientDetail.email
          }
        },
        BillingAddressForm: {
          Display: false
        }
      }
    };

    const payload = {
        IDSOC: Constants.IDSOC,
        NUMBON: this.numBon,
        MAGASIN: CMINT,
        ID_USER: this.user.CIINT,
        LANG: langCode,
        PAYMENT_URL: this.paymentUrl,
        PAYMENT_API : this.paymentApiKey,
        PAYMENT_DATA: paymentData
    };

    this._sendPaymentLink(payload);
  }

  _sendPaymentLink(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.sendCdePaymentLink(payload).subscribe(
          (resp) => {
            this.loadingService.hide();

            if (resp.statusCode === 200) {
              this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kpayment_link_sent', { EMAIL_ADDRESS: payload.PAYMENT_DATA.saferPayData.Payer.BillingAddress.Email }));
              resolve({ response: resp });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp?.data?.ErrorMessage);
              resolve({ response: resp });
            }
          },
          err => {
            reject(err);
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
      );
    });
  }

  getReturnMagasin(ticketArt: TicketsAcompte) {
    if (ticketArt.CMINTRET) {
      const magasin = this.listMagasins.find(mag => mag.CMINT == ticketArt.CMINTRET);
      return magasin ? magasin.CMRAISOC : '';
    }
    return '';
  }
}
