import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-motifs-filter',
  templateUrl: './motifs-filter.component.html',
  styleUrls: ['./motifs-filter.component.scss']
})
export class MotifsFilterComponent implements OnInit {

  motifActions: any;
  oldFormData: any;
  searchForm: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<MotifsFilterComponent>,
  private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {    
    this.motifActions = this.data.motifActions;
    
    this.searchForm = this.formBuilder.group({
      motif_action: ['', [Validators.required]],      
    });
    
    this.oldFormData = this.data.form_data;

    this.searchForm.patchValue({
      motif_action: this.oldFormData.motif_action
    });
  }

  headerSearchSubmit() {  
    this.dialogRef.close({status: 'success', form_data: this.searchForm.value });
  }

}
