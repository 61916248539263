import { KeycloakService } from 'keycloak-angular';

import { environment } from '../environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () => {
    if (environment.ENABLE_KEYCLOAK) {
      return keycloak.init({
        config: {
          url: 'https://3.108.181.3:8443/',
          realm: 'capinfo',
          clientId: 'caporder'
        },
        enableBearerInterceptor: true,
        bearerPrefix: 'Bearer',
        bearerExcludedUrls: ['/assets'],
        initOptions: {
          onLoad: 'login-required',
          flow: 'standard',
          // onLoad: 'check-sso',  // Avoid forcing login and instead check SSO silently
          // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
          // flow: 'standard',
          checkLoginIframe: false,
        },
      });
    }

    return Promise.resolve();
  }
}
