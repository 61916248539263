import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Constants } from '../constants';

import {Observable} from 'rxjs';

@Injectable()
export class RemiseService  {

    constructor(private http : HttpClient) {
    }

    getRemise(): Observable<any> {        

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.get(Constants.API_MOTIF_URL + 'getRemise', options);

    }

    getRemiseLigne(CMINT :any): Observable<any> {
        
        const headers = new HttpHeaders();

        headers.append('Content-Type', 'application/json');
        const params = {};

        params['CMINT'] = CMINT;

        const options = {
            headers: headers,
            params : params
        };

        return this.http.get(Constants.API_REMISE_URL + 'getRemisesLigne', options);
    }

    getRemisePied(CMINT :any) : Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        const params = {};

        params['CMINT'] = CMINT;

        const options = {
            headers: headers,
            params : params
        };

        return this.http.get(Constants.API_REMISE_URL + 'getRemisesPied', options);
    }

    getRemiseById(REMMCODE: any, CMINT: any): Observable<any> {
        
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const params = {};
        
        params['REMMCODE'] = REMMCODE;
        params['CMINT'] = CMINT;

        const options = {
            headers: headers,
            params: params
        };
        
        return this.http.get(Constants.API_REMISE_URL + 'getRemiseById', options);
    }
}
