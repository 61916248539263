import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

import {UserService} from 'providers/api/userService';
import { UntypedFormBuilder } from '@angular/forms';
import { Constants } from 'providers/constants';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserModel } from 'models/user-model';
import { NiveauModel } from 'models/niveau-model';
import { OrderDownlineTreeviewEventParser, TreeviewComponent, TreeviewConfig, TreeviewEventParser, TreeviewI18n } from 'ngx-treeview2';
import { GestionDesDroitService } from 'providers/api/gestionDesDroitService';
import { GestionDesDroitsModel, DroitChild, UpdateDroit } from 'models/gestionDesDroitsModel';
import { TranslateService } from '@ngx-translate/core';
import { TreeviewTranslation } from 'providers/treeview-translation';
import { GestionFilterComponent } from './gestion-filter/gestion-filter.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import CustomTreeItem from 'models/customTreeItem';
import { StorageService } from 'providers/api/storageService';


@Component({
  selector: 'app-gestion-des-droits',
  templateUrl: './gestion-des-droits.component.html',
  styleUrls: ['./gestion-des-droits.component.scss'],
  providers: [GestionDesDroitService, { provide: TreeviewI18n, useClass: TreeviewTranslation },
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }]
})
export class GestionDesDroitsComponent implements OnInit {

  searchForm: any;

  // bouton ENTER valide formulaire
  private pressedButton: any;

  // champs de recherche
  searchData: any = null;

  // Service
  listDroits: GestionDesDroitsModel;

  user: UserModel;
  USERCMINT: any;

  // DATATABLE  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  showLoader = true;

  currentPage: number = 0;
  totalPages: number;
  rowsPerPage: number;

  searched = false;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: true,
    maxHeight: 450,    
  });
  treeValues: string[];
  treeItems: CustomTreeItem[];

  filterNiveau: any;

  changedDroitsdata: UpdateDroit[] = [];

  @ViewChild('gestionTree') gestionTreeComp: TreeviewComponent;

  isMobile: boolean = false;
  searchSubscription: Subscription;
  
  niveauTypes: NiveauModel[];
  filteredNiveauTypes: NiveauModel[];
  selectBoxSubscription: Subscription;

  filterValues: any;

  userPermission: any;

  updateSubscription: Subscription;

  superAdminLogged: boolean = false;

  constructor(private userService: UserService, 
    public gestionDesDroitService: GestionDesDroitService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private loadingService: NgxSpinnerService,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    private storageService: StorageService) {
     
  }

  ngOnInit(): void {

    // on cherche le user connecté et son magasin de rattachement
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;

    this.isMobile = this.deviceService.isMobile();

    this.userService.headerSearch.next(false);
    this.userService.headerSearchButton.next(false);

    this.loadingService.show();
    this.loadDroitsOnLoad();

    this.searchSubscription = this.userService.headerSearch.subscribe(
      (status: boolean) => {
        if(status === true) {
          this.openUpFilterScreen();
        }
      }
    )

    this.searchForm = this.formBuilder.group({      
      search_niveau: ['1'],      
    });
    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');

    this.superAdminLogged = this.user.CTUINTGC == '1'?true:false;

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }          
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };   
    
    this.selectBoxSubscription = this.userService.headerSelectBox.subscribe((dropdownObject) => {
      if(dropdownObject.pageName === 'gestiondes'){        
        this.searchForm.patchValue({
          search_niveau: dropdownObject.value,
        });
        this.onNiveauSecuriteChange();        
      }            
    });    
  }

  ngOnDestroy(): void {
    this.userService.headerSearch.next(false);
    this.searchSubscription.unsubscribe();
    this.selectBoxSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  updateGestionDesDroit() {
    if(this.updateSubscription !== undefined && this.updateSubscription != null) {
      this.updateSubscription.unsubscribe();
    }

    this.updateSubscription = this.gestionDesDroitService.updateGestionDroit(this.changedDroitsdata).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.changedDroitsdata = [];
        } else {
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kgupdaterror'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kgconerr'), this.translateService.instant('kgneterr'));
      }
    );    
  }

  async loadDroitsOnLoad() {
    await this.getListNiveau();
    this.getListDroits();
  }

  getListNiveau(): Promise<any> {
    return new Promise((resolve, reject) => {
      if(this.user === undefined || this.user == null) {
        reject('EMPTY_USER');
        return;
      };
      
      this.gestionDesDroitService.getListofNiveau(this.USERCMINT, this.user.CIINT).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            this.niveauTypes = res.data;

            if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['74_1']) {
              this.niveauTypes = this.niveauTypes.filter((_d) => _d.NIVINT == +this.user.CTUINTGC); // Restrict to display Logged user level only
              if(this.niveauTypes.length > 0) {
                this.searchForm.get('search_niveau').setValue(this.niveauTypes[0].NIVINT);
              }
            }

            this.filteredNiveauTypes = this.niveauTypes;
            this.userService.userNiveauTypesList.next(this.filteredNiveauTypes);
          }
          resolve(res);
        },
        error => {
          this.dialogService.prompt(this.translateService.instant('kgconerr'), this.translateService.instant('kgneterr'));
          reject(error);
        }
      );
    })    
  }

  get isSuperAdminLoggedIn(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && +currentUser.CTUINTGC === 1;
  }

  getListDroits() {
    if(this.user === undefined || this.user == null) return;
    
    const niveauID = this.searchForm.get('search_niveau').value;
    this.filterValues = {'search_niveau': niveauID};

    this.gestionDesDroitService.getListofDroits(this.USERCMINT, this.user.CIINT, niveauID).subscribe(
      (response) => {    
        
        if(response.statusCode == 200) {
          const data = response.data;
          this.listDroits = data;

          const generalChildrens: CustomTreeItem[] = [];
          const ventesChildrens: CustomTreeItem[] = [];
          const reportingChildrens: CustomTreeItem[] = [];
          const clientsChildrens: CustomTreeItem[] = [];
          const articlesChildrens: CustomTreeItem[] = [];
          const confirmationChildrens: CustomTreeItem[] = [];

          this.listDroits.general.forEach(
            (row: DroitChild) => {
              const sub_child: any = row.child;
              const sub_childrens: CustomTreeItem[] = [];

              let otherChecked = false;
              let allChecked = true;
              
              if(sub_child && sub_child !== "null") {
                sub_child.forEach(
                  (sub_child_row: DroitChild) => {
                    if(this.superAdminLogged || this.userPermission[sub_child_row.id+'_'+sub_child_row.value]) {
                      if(sub_child_row.checked) {
                        otherChecked = true;
                      }

                      if(!sub_child_row.checked) {
                        allChecked = false;
                      }

                      sub_childrens.push({
                        text: sub_child_row.label,
                        value: sub_child_row.id+'_'+sub_child_row.value,
                        checked: sub_child_row.checked,
                        disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                      });
                    }
                  }
                )
              }
              
              if(this.superAdminLogged || this.userPermission[row.id+'_'+row.value]) {
                generalChildrens.push({
                  text: row.label,
                  value: row.id+'_'+row.value,
                  children: sub_childrens,
                  checked: row.checked,
                  collapsed: true,
                  indeterminate: (sub_childrens && sub_childrens.length > 0) && ((row.checked && !allChecked) || (otherChecked && !allChecked)),
                  disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                });
              }
            }
          );                   

          this.listDroits.ventes.forEach(
            (row: DroitChild) => {
              const sub_child: any = row.child;
              const sub_childrens: CustomTreeItem[] = [];
              
              let otherChecked = false;
              let allChecked = true;
              if(sub_child && sub_child !== "null") {
                sub_child.forEach(
                  (sub_child_row: DroitChild) => {    
                    if(this.superAdminLogged || this.userPermission[sub_child_row.id+'_'+sub_child_row.value]) {
                      if(sub_child_row.checked) {
                        otherChecked = true;
                      }

                      if(!sub_child_row.checked) {
                        allChecked = false;
                      }

                      const sub_sub_child: any = sub_child_row.child;
                      const sub_sub_childrens: CustomTreeItem[] = [];

                      let subOtherChecked = false;
                      let subAllChecked = true;

                      if(sub_sub_child && sub_sub_child !== "null") {
                        sub_sub_child.forEach(
                          (sub_sub_child_row: DroitChild) => {    
                            if(this.superAdminLogged || this.userPermission[sub_sub_child_row.id+'_'+sub_sub_child_row.value]) {
                              if(sub_sub_child_row.checked) {
                                subOtherChecked = true;
                              }
        
                              if(!sub_sub_child_row.checked) {
                                subAllChecked = false;
                                allChecked = false;
                              }

                              sub_sub_childrens.push({
                                text: sub_sub_child_row.label,
                                value: sub_sub_child_row.id+'_'+sub_sub_child_row.value,
                                checked: sub_sub_child_row.checked,
                                disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                              });
                            }
                          }
                        );
                      }

                      subOtherChecked = (sub_sub_childrens && sub_sub_childrens.length > 0) && sub_sub_childrens.some(rw => rw.checked || rw.indeterminate);
                      subAllChecked = (sub_sub_childrens && sub_sub_childrens.length > 0) && sub_sub_childrens.every(rw => rw.checked);

                      sub_childrens.push({
                        text: sub_child_row.label,
                        value: sub_child_row.id+'_'+sub_child_row.value,
                        children: sub_sub_childrens.length > 0 ?sub_sub_childrens:undefined,
                        checked: sub_child_row.checked || subAllChecked,
                        collapsed: true,
                        indeterminate: (sub_sub_childrens && sub_sub_childrens.length > 0) && ((subOtherChecked && !subAllChecked) || (sub_child_row.checked && !subAllChecked)),
                        disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                      });
                    }
                  }
                )
              }

              if(this.superAdminLogged || this.userPermission[row.id+'_'+row.value]) {
                otherChecked = (sub_childrens && sub_childrens.length > 0) && sub_childrens.some(rw => rw.checked || rw.indeterminate);
                allChecked = (sub_childrens && sub_childrens.length > 0) && sub_childrens.every(rw => rw.checked && !rw.indeterminate);

                ventesChildrens.push({
                  text: row.label,
                  value: row.id+'_'+row.value,              
                  children: sub_childrens,
                  checked: row.checked || allChecked,
                  collapsed: true,
                  indeterminate: (sub_childrens && sub_childrens.length > 0) && ((row.checked && !allChecked) || (otherChecked && !allChecked)),
                  disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                });
              }
            }
          );
          
          this.listDroits.reporting?.forEach(
            (row: DroitChild) => {
              const sub_child: any = row.child;
              const sub_childrens: CustomTreeItem[] = [];
              
              let otherChecked = false;
              let allChecked = true;

              if(sub_child && sub_child !== "null") {
                sub_child.forEach(
                  (sub_child_row: DroitChild) => {  
                    if(this.superAdminLogged || this.userPermission[sub_child_row.id+'_'+sub_child_row.value]) {
                      if(sub_child_row.checked) {
                        otherChecked = true;
                      }

                      if(!sub_child_row.checked) {
                        allChecked = false;
                      }

                      sub_childrens.push({
                        text: sub_child_row.label,
                        value: sub_child_row.id+'_'+sub_child_row.value,
                        checked: sub_child_row.checked,
                        disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                      });
                    }
                  }
                )
              }

              if(this.superAdminLogged || this.userPermission[row.id+'_'+row.value]) {
                reportingChildrens.push({
                  text: row.label,
                  value: row.id+'_'+row.value,              
                  children: sub_childrens,
                  checked: row.checked,
                  collapsed: true,
                  indeterminate: (sub_childrens && sub_childrens.length > 0) && ((row.checked && !allChecked) || (otherChecked && !allChecked)),
                  disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                });
              }
            }
          );

          this.listDroits.clients.forEach(
            (row: DroitChild) => {
              const sub_child: any = row.child;
              const sub_childrens: CustomTreeItem[] = [];

              let otherChecked = false;
              let allChecked = true;
              
              if(sub_child && sub_child !== "null") {
                sub_child.forEach(
                  (sub_child_row: DroitChild) => {     
                    if(this.superAdminLogged || this.userPermission[sub_child_row.id+'_'+sub_child_row.value]) { 
                      if(sub_child_row.checked) {
                        otherChecked = true;
                      }

                      if(!sub_child_row.checked) {
                        allChecked = false;
                      }

                      sub_childrens.push({
                        text: sub_child_row.label,
                        value: sub_child_row.id+'_'+sub_child_row.value,
                        checked: sub_child_row.checked,
                        disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                      });
                    }
                  }
                )
              }

              if(this.superAdminLogged || this.userPermission[row.id+'_'+row.value]) {
                clientsChildrens.push({
                  text: row.label,
                  value: row.id+'_'+row.value,              
                  children: sub_childrens,
                  checked: row.checked,
                  collapsed: true,
                  indeterminate: (sub_childrens && sub_childrens.length > 0) && ((row.checked && !allChecked) || (otherChecked && !allChecked)),
                  disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                });
              }
            }
          );

          this.listDroits.articles.forEach(
            (row: DroitChild) => {
              const sub_child: any = row.child;
              const sub_childrens: CustomTreeItem[] = [];

              let otherChecked = false;
              let allChecked = true;
              
              if(sub_child && sub_child !== "null") {
                sub_child.forEach(
                  (sub_child_row: DroitChild) => {   
                    if(this.superAdminLogged || this.userPermission[sub_child_row.id+'_'+sub_child_row.value]) { 
                      if(sub_child_row.checked) {
                        otherChecked = true;
                      }

                      if(!sub_child_row.checked) {
                        allChecked = false;
                      }

                      sub_childrens.push({
                        text: sub_child_row.label,
                        value: sub_child_row.id+'_'+sub_child_row.value,
                        checked: sub_child_row.checked,
                        disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                      });
                    }
                  }
                )
              }

              if(this.superAdminLogged || this.userPermission[row.id+'_'+row.value]) {
                articlesChildrens.push({
                  text: row.label,
                  value: row.id+'_'+row.value,
                  children: sub_childrens,
                  checked: row.checked,
                  collapsed: true,
                  indeterminate: (sub_childrens && sub_childrens.length > 0) && ((row.checked && !allChecked) || (otherChecked && !allChecked)),
                  disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                });
              }
            }
          );

          this.listDroits.confirmation_action.forEach(
            (row: DroitChild) => {
              const sub_child: any = row.child;
              const sub_childrens: CustomTreeItem[] = [];
              
              let otherChecked = false;
              let allChecked = true;

              if(sub_child && sub_child !== "null") {
                sub_child.forEach(
                  (sub_child_row: DroitChild) => {     
                    if(this.superAdminLogged || this.userPermission[sub_child_row.id+'_'+sub_child_row.value]) { 
                      if(sub_child_row.checked) {
                        otherChecked = true;
                      }

                      if(!sub_child_row.checked) {
                        allChecked = false;
                      }

                      sub_childrens.push({
                        text: sub_child_row.label,
                        value: sub_child_row.id+'_'+sub_child_row.value,
                        checked: sub_child_row.checked,
                        disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                      });
                    }
                  }
                )
              }

              if(this.superAdminLogged || this.userPermission[row.id+'_'+row.value]) {
                confirmationChildrens.push({
                  text: row.label,
                  value: row.id+'_'+row.value,              
                  children: sub_childrens,
                  checked: row.checked,
                  collapsed: true,
                  indeterminate: (sub_childrens && sub_childrens.length > 0) && ((row.checked && !allChecked) || (otherChecked && !allChecked)),
                  disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
                });
              }
            }
          );
          
          this.treeItems = [];

          const generalTreeData = {
            text: 'kggeneral',
            value: 'general',
            collapsed: true,
            checked: this._checkChildrenAllChecked(generalChildrens),
            indeterminate: (this._checkChildrenAnythingChecked(generalChildrens) && !this._checkChildrenAllChecked(generalChildrens)),
            children: generalChildrens,
            disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
          };

          if(generalChildrens !== undefined && generalChildrens !== null && generalChildrens.length > 0) {
            this.treeItems.push(generalTreeData);
          }

          const ventesTreeData = {
            text: 'kgsales',
            value: 'ventes',
            collapsed: true,
            checked: this._checkChildrenAllChecked(ventesChildrens),
            indeterminate: (this._checkChildrenAnythingChecked(ventesChildrens) && !this._checkChildrenAllChecked(ventesChildrens)),
            children: ventesChildrens,
            disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
          };

          if(ventesChildrens !== undefined && ventesChildrens !== null && ventesChildrens.length > 0) {
            this.treeItems.push(ventesTreeData);
          }

          const clientsTreeData = {
            text: 'kgclients',
            value: 'clients',
            collapsed: true,
            checked: this._checkChildrenAllChecked(clientsChildrens),
            indeterminate: (this._checkChildrenAnythingChecked(clientsChildrens) && !this._checkChildrenAllChecked(clientsChildrens)),
            children: clientsChildrens,
            disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
          };

          if(clientsChildrens !== undefined && clientsChildrens !== null && clientsChildrens.length > 0) {
            this.treeItems.push(clientsTreeData);
          }

          const articlesTreeData = {
            text: 'kgarticles',
            value: 'articles',
            collapsed: true,
            checked: this._checkChildrenAllChecked(articlesChildrens),
            indeterminate: (this._checkChildrenAnythingChecked(articlesChildrens) && !this._checkChildrenAllChecked(articlesChildrens)),
            children: articlesChildrens,
            disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
          };

          if(articlesChildrens !== undefined && articlesChildrens !== null && articlesChildrens.length > 0) {
            this.treeItems.push(articlesTreeData);
          }

          const confirmationTreeData = {
            text: 'kgcaction',
            value: 'confirmation_action',
            collapsed: true,
            checked: this._checkChildrenAllChecked(confirmationChildrens),
            indeterminate: (this._checkChildrenAnythingChecked(confirmationChildrens) && !this._checkChildrenAllChecked(confirmationChildrens)),
            children: confirmationChildrens,
            disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
          };

          if(confirmationChildrens !== undefined && confirmationChildrens !== null && confirmationChildrens.length > 0) {
            this.treeItems.push(confirmationTreeData);
          }

          const reportingTreeData = {
            text: 'kgreport',
            value: 'reporting',
            collapsed: true,
            checked: this._checkChildrenAllChecked(reportingChildrens),
            indeterminate: (this._checkChildrenAnythingChecked(reportingChildrens) && !this._checkChildrenAllChecked(reportingChildrens)),
            children: reportingChildrens,
            disabled: (!this.isSuperAdminLoggedIn && +niveauID === 1)
          };

          if(reportingChildrens !== undefined && reportingChildrens !== null && reportingChildrens.length > 0) {
            this.treeItems.push(reportingTreeData);
          }
          
          this.loadingService.hide();
        } else {          
          this.dialogService.prompt(this.translateService.instant('kgconerr'), this.translateService.instant('kgneterr'));
          this.loadingService.hide();
        }      
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kgconerr'), this.translateService.instant('kgneterr'));
      }
    )  
  }

  _checkIfNothingChecked(): boolean {
    return this.treeItems.some(
      (row) => {
        if(row.checked || row.indeterminate) {
          return true;
        } else {
          return this.__loopChildrens(row.children);
        }
      }
    )
  }

  _checkChildrenAllChecked(items: CustomTreeItem[]): boolean {
    if(items == undefined || items == null) return;

    return items.every(
      (row) => {
        if(row.checked && !row.indeterminate) {
          return true;
        } else {
          if(row.children !== undefined && row.children !== null && row.children.length > 0) {
            return this._checkChildrenAllChecked(row.children);
          }
        }
      }
    )
  }

  _checkChildrenAnythingChecked(items: CustomTreeItem[]): boolean {
    if(items == undefined || items == null) return;

    return items.some(
      (row) => {
        if(row.checked || row.indeterminate) {
          return true;
        } else {
          if(row.children !== undefined && row.children !== null && row.children.length > 0) {
            return this._checkChildrenAnythingChecked(row.children);
          }
        }
      }
    )
  }

  __loopChildrens(childrensArray: CustomTreeItem[]): boolean {
    return childrensArray.some(
      (row) => {
        if(row.checked || row.indeterminate) {
          return true
        }

        if(row.children && row.children.length > 0) {
          this.__loopChildrens(row.children);
        }
      }
    )
  }

  _treeCollapseToggle(item: any) {
    item.collapsed = !item.collapsed;
  }

  processCurrentItemChildren(currentItem: CustomTreeItem, niveauID: any) {
    currentItem.children.map(
        (row) => {
          if (row.indeterminate) {
            row.indeterminate = false;
          }

          row.checked = currentItem.checked;

          if(row.value !== currentItem.value) {
            const [CHILD_NUMECR, CHILD_NUMCAS] = row.value.split('_');
            if((CHILD_NUMECR !== undefined && CHILD_NUMECR != 'null') && (CHILD_NUMCAS !== undefined && CHILD_NUMCAS != 'null')) {
              this.__updateChangedDroitsData({
                NIVINT: niveauID,
                NUMECR: CHILD_NUMECR,
                NUMCAS: CHILD_NUMCAS,
                IDSOC: Constants.IDSOC,
                OKVALID: (row.checked || row.indeterminate)?'-1':'0'
              });
            }
          }

          if(row.children !== undefined && row.children !== null) {
            row.children.map(
                (sub_row) => {
                  if(sub_row.indeterminate) {
                    sub_row.indeterminate = false;
                  }

                  sub_row.checked = row.checked;

                  if(row.value !== sub_row.value) {
                    const [SUB_CHILD_NUMECR, SUB_CHILD_NUMCAS] = sub_row.value.split('_');
                    if((SUB_CHILD_NUMECR !== undefined && SUB_CHILD_NUMECR != 'null') && (SUB_CHILD_NUMCAS !== undefined && SUB_CHILD_NUMCAS != 'null')) {
                      this.__updateChangedDroitsData({
                        NIVINT: niveauID,
                        NUMECR: SUB_CHILD_NUMECR,
                        NUMCAS: SUB_CHILD_NUMCAS,
                        IDSOC: Constants.IDSOC,
                        OKVALID: (sub_row.checked || sub_row.indeterminate)?'-1':'0'
                      });
                    }
                  }

                  if(sub_row.children !== undefined && sub_row.children !== null) {
                    sub_row.children.map(
                        (__sub_row) => {
                          if (__sub_row.indeterminate) {
                            __sub_row.indeterminate = false;
                          }

                          __sub_row.checked = currentItem.checked;

                          if(__sub_row.value !== currentItem.value) {
                            const [CHILD_NUMECR, CHILD_NUMCAS] = __sub_row.value.split('_');
                            if((CHILD_NUMECR !== undefined && CHILD_NUMECR != 'null') && (CHILD_NUMCAS !== undefined && CHILD_NUMCAS != 'null')) {
                              this.__updateChangedDroitsData({
                                NIVINT: niveauID,
                                NUMECR: CHILD_NUMECR,
                                NUMCAS: CHILD_NUMCAS,
                                IDSOC: Constants.IDSOC,
                                OKVALID: (__sub_row.checked || __sub_row.indeterminate)?'-1':'0'
                              });
                            }
                          }
                        })
                  }

                  return sub_row;
                }
            )
          }

          return row;
        }
    )
  }

  _treeCheckboxChange(currentItem: CustomTreeItem, parentItem?: CustomTreeItem, topParent?: CustomTreeItem, highTopParent?: CustomTreeItem) {

    if(currentItem.indeterminate) {
      currentItem.indeterminate = false;  
    }

    const niveauID = this.searchForm.get('search_niveau').value;
    this.filterValues = {'search_niveau': niveauID};
    const [NUMECR, NUMCAS] = currentItem.value.split('_');

    if((NUMECR !== undefined && NUMECR != 'null') && (NUMCAS !== undefined && NUMCAS != 'null')) {
      this.__updateChangedDroitsData({
        NIVINT: niveauID,
        NUMECR: NUMECR,
        NUMCAS: NUMCAS,
        IDSOC: Constants.IDSOC,
        OKVALID: (currentItem.checked || currentItem.indeterminate)?'-1':'0'
      });
    }

    if (currentItem.children !== undefined && currentItem.children !== null) {
      this.processCurrentItemChildren(currentItem, niveauID);
    }

    if(parentItem !== undefined && parentItem !== null) {
      this.__parentItemsLogic(niveauID, currentItem, parentItem, (topParent !== undefined && topParent !== null));
    }

    if(topParent !== undefined && topParent !== null) {
      this.__parentItemsLogic(niveauID, currentItem, topParent, (highTopParent !== undefined && highTopParent !== null));
    }

    if(highTopParent !== undefined && highTopParent !== null) {
      this.__topParentItemsLogic(highTopParent);
    }

    this.updateGestionDesDroit();
  }

  __parentItemsLogic(niveauID: number, currentItem: CustomTreeItem, parentItem: CustomTreeItem, topParentAvail: boolean = false) {
    if(parentItem !== undefined && parentItem !== null) {
      let allChecked = true;
      let noOthersChecked = true;
      const [PARENT_NUMECR, PARENT_NUMCAS] = parentItem.value.split('_');

      if(parentItem.children !== undefined && parentItem.children !== null && parentItem.children.length > 0) {
        allChecked = this._checkChildrenAllChecked(parentItem.children);
        noOthersChecked = !this._checkChildrenAnythingChecked(parentItem.children);
      }

      if(topParentAvail) {
        if(allChecked) {
          parentItem.indeterminate = false;
          parentItem.checked = true;
        } else {
          parentItem.indeterminate = true;
          parentItem.checked = false;
        }
        return;
        
      } else {
        if(allChecked) {
          parentItem.indeterminate = false;
          parentItem.checked = true;
        } else {
          if(noOthersChecked) {
            parentItem.indeterminate = false;
            parentItem.checked = false;
          } else {
            parentItem.indeterminate = true;
            parentItem.checked = false;
          }
        }
      }

      if((PARENT_NUMECR !== undefined && PARENT_NUMECR != 'null') && (PARENT_NUMCAS !== undefined && PARENT_NUMCAS != 'null')) {
        if(!allChecked) {
          if(noOthersChecked && currentItem.checked) {
            this.__updateChangedDroitsData({
              NIVINT: niveauID,
              NUMECR: PARENT_NUMECR,
              NUMCAS: PARENT_NUMCAS,
              IDSOC: Constants.IDSOC,
              OKVALID: (parentItem.checked || parentItem.indeterminate)?'-1':'0'
            });
          }
        }
      }
    }
  }

  __topParentItemsLogic(parentItem: CustomTreeItem) {
    if(parentItem !== undefined && parentItem !== null) {
      let allChecked = true;
      let noOthersChecked = true;

      if(parentItem.children !== undefined && parentItem.children !== null && parentItem.children.length > 0) {
        allChecked = this._checkChildrenAllChecked(parentItem.children);
        noOthersChecked = !this._checkChildrenAnythingChecked(parentItem.children);
      }

      if(allChecked) {
        parentItem.indeterminate = false;
        parentItem.checked = true;
      } else {
        if(noOthersChecked) {
          parentItem.indeterminate = false;
          parentItem.checked = false;
        } else {
          parentItem.indeterminate = true;
          parentItem.checked = false;
        }
      }
    }
  }

  __updateChangedDroitsData(data: { NIVINT: any, NUMECR: any, NUMCAS: any, IDSOC: string, OKVALID: string }) {
    if(this.changedDroitsdata !== undefined && this.changedDroitsdata !== null && this.changedDroitsdata.length > 0) {
      let exists = this.changedDroitsdata.find((row) => row.NIVINT == data.NIVINT && row.NUMCAS == data.NUMCAS && row.NUMECR == data.NUMECR && row.IDSOC == data.IDSOC);
      if(exists) {
        this.changedDroitsdata.map((row) => {
          if(row.NIVINT == data.NIVINT && row.NUMCAS == data.NUMCAS && row.NUMECR == data.NUMECR && row.IDSOC == data.IDSOC) {
            row.OKVALID = data.OKVALID;
          }
          return row;
        })
      } else {
        this.changedDroitsdata.push({
          NIVINT: data.NIVINT,
          NUMECR: data.NUMECR,
          NUMCAS: data.NUMCAS,
          IDSOC: data.IDSOC,
          OKVALID: data.OKVALID
        });
      }
    } else {
      this.changedDroitsdata = [];
      this.changedDroitsdata.push({
        NIVINT: data.NIVINT,
        NUMECR: data.NUMECR,
        NUMCAS: data.NUMCAS,
        IDSOC: data.IDSOC,
        OKVALID: data.OKVALID
      });
    }
  }

  onSearchChange(search: string) {
    this.filteredNiveauTypes = this.niveauTypes.filter(row => row.NIVNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  onNiveauSecuriteChange() {
    this.loadingService.show();
    this.getListDroits();
  }

  

  openUpFilterScreen() {
    this.userService.headerSearch.next(false);

    const dialogRef = this.dialog.open(GestionFilterComponent, {
      width: '60vw',
      data: {
        form_data: this.searchForm.value,
        niveauTypes: this.niveauTypes,
        filteredNiveauTypes: this.filteredNiveauTypes,
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        const form_data = result.form_data;

        this.searchForm.patchValue({
          search_niveau: form_data.search_niveau,          
        });

        this.filterValues = form_data;

        this.loadingService.show();
        this.getListDroits();   
      }      
    }); 
  }

  get filterValuesFormat(): string {
    if(this.filterValues === undefined || this.filterValues === null) return '';

    let filterArr = [];
  
    if(this.filterValues.search_niveau !== undefined && this.filterValues.search_niveau !== null && this.filterValues.search_niveau !== '') {
      if(this.filteredNiveauTypes) {
        const levelLabel = this.filteredNiveauTypes.find((row) => row.NIVINT == this.filterValues.search_niveau);
        filterArr.push(levelLabel.NIVNOM);
      }
    }

    return filterArr.length > 0 ? filterArr.join(', ') : '';
  }

}
