import {Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

import {Article, Entreprots} from '../../../../models/articles';

import {StockService} from '../../../../providers/api/stockService';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from 'providers/api/userService';
import { UserModel } from 'models/user-model';
import { DialogsService } from 'providers/api/dialogService';
import {UtilService} from '../../../../providers/api/utilService';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-article-logistique-stock-autre',
    templateUrl: './article-logistique-stock-autre.component.html',
    providers: [TranslateService]
})

export class ArticleLogistiqueStockAutreComponent implements OnInit {

    private _article: Article
    get article() {return this._article}
    @Input() set article(article: Article) {
        this.articleChange.emit(this._article = article);
        
        if(article !== undefined && article !== null) {
            this.showLoader = true;
            this.CAINT = article.CAINT;
            this.getStockFromAutreMag();
        } else {
            this.showLoader = false;
        }
    };
    @Output() private articleChange: EventEmitter<Article> = new EventEmitter<Article>();
    @Output() private magasinChange: EventEmitter<{ changedCMINT: number, authorizedUser: any } | undefined> = new EventEmitter<{ changedCMINT: number, authorizedUser: any } | undefined>();
    
    CAINT: string;
    @Input() listEntreprots: Entreprots[];

    @Input() displayType: 'dialog' | 'component' = 'dialog';

    @Input() enableMagSelection: boolean = false;

    lisStock: any[] = [];
    SumStockDispo: any;
    SumStockBloque: any;
    SumStockE2: any;
    showLoader = true;

    // DATATABLE
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    isMobile: boolean;

    userPermission: any;

    user: UserModel;
    USERCMINT: any;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
                private stockService: StockService,
                private translateService: TranslateService,
                private userService: UserService,
                private deviceDetector: DeviceDetectorService,
                private dialogService: DialogsService,
                private utilService: UtilService,
                private storageService: StorageService) {

    }


    ngOnInit() {
        this.isMobile = this.deviceDetector.isMobile();
        this.user = this.userService.getCurrentUser();
        this.USERCMINT = this.user.CMINT;

        this.userPermission = this.storageService.getItem('CurrentUserPermission');

        if(this.displayType !== 'component') {
            this.CAINT = this.data.CAINT;
            this.listEntreprots = (this.data?this.data.listEntreprots:undefined);

            if(this.CAINT !== undefined && this.CAINT !== null) {
                this.showLoader = true;
                this.getStockFromAutreMag();
            }
        }        
        
        this.dtOptions = {
            language: {
                processing: this.translateService.instant('kprocessing'),
                search: this.translateService.instant('ksearch'),
                loadingRecords: this.translateService.instant('kloading'),
                info: this.translateService.instant('kgenshowentry'),
                infoEmpty: this.translateService.instant('kgenshowingentry'),
                zeroRecords: this.translateService.instant('knodata'),
                emptyTable: this.translateService.instant('knodatadash'),
                paginate: {
                    first: this.translateService.instant('kfirst'),
                    previous: this.translateService.instant('kprevious'),
                    next: this.translateService.instant('knext'),
                    last: this.translateService.instant('klast'),
                }        
            },      
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };
    }

    get magasinChangeAllowed() {
        return this.enableMagSelection && this.userPermission != undefined && this.userPermission != null && this.userPermission['0_24'];
    }

    async onChooseMagasin(row: any) {
        let authorizedStockAutreMagUser;
        if (this.article.STOCKDISPO > 0 && !this.userPermission['0_23']) {
            await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kstock_disponible_votre_magasin')).toPromise();
            const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '23', this.user.CIINT, '');

            if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
                return;
            }

            authorizedStockAutreMagUser = authorizationResult.data;
        }

        this.magasinChange.emit({ changedCMINT: row.CMINT, authorizedUser: authorizedStockAutreMagUser });
    }

    async getStockFromAutreMag() {
        const paramData = await this.userService.getRuleParamsData(10, 8, 'VBOOL1');

        this.stockService.getStockFromOtherMag(this.CAINT, this.USERCMINT).subscribe(
            (res) => {
                this.showLoader = false;
                if(res.success !== undefined) {
                    const data = res.data;
                    this.lisStock = [];
                    this.dtTrigger.next();
                    if (data.length > 0) {
                        this.SumStockDispo = 0;
                        this.SumStockBloque = 0;
                        this.SumStockE2 = 0;
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].STOCK_DISPO === null) {
                                data[i].STOCK_DISPO = '0';
                            } else {
                                if(this.userPermission != undefined && this.userPermission != null && this.userPermission['0_11'] && paramData == '-1') {
                                    this.SumStockDispo += +data[i].STOCK_DISPO;
                                } else {
                                    if(data[i].default_mag == '-1' || data[i].default_mag == 'autre') {
                                        this.SumStockDispo += +data[i].STOCK_DISPO;
                                    }
                                }
                            }

                            if (data[i].STOCK_BLOQUE === null) {
                                data[i].STOCK_BLOQUE = '0';
                            } else {
                                if(this.userPermission != undefined && this.userPermission != null && this.userPermission['0_11'] && paramData == '-1') {
                                    this.SumStockBloque += +data[i].STOCK_BLOQUE;
                                } else {
                                    if(data[i].default_mag == '-1' || data[i].default_mag == 'autre') {
                                        this.SumStockBloque += +data[i].STOCK_BLOQUE;
                                    }
                                }
                            }

                            if (data[i].E2_val === null) {
                                data[i].E2_val = '0';
                            } else {
                                if(this.userPermission != undefined && this.userPermission != null && this.userPermission['0_11'] && paramData == '-1') {
                                    this.SumStockE2 += +data[i].E2_val;
                                } else {
                                    if(data[i].default_mag == '-1' || data[i].default_mag == 'autre') {
                                        this.SumStockE2 += +data[i].E2_val;
                                    }
                                }
                            }
                        
                            if(this.userPermission != undefined && this.userPermission != null && this.userPermission['0_11'] && paramData == '-1') {
                                this.lisStock.push(new Article(data[i]));
                            } else {
                                if(data[i].default_mag == '-1' || data[i].default_mag == 'autre') {
                                    this.lisStock.push(new Article(data[i]));
                                }
                            }
                        }
                    }
                }                    
            },
            error => {
                this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                this.showLoader = false;
            }
        );
    }
}
