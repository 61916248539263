import {Component, AfterViewInit, OnInit, OnDestroy, ViewChild, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

import {BonVente} from '../../models/bonventes';
import {BonVenteService} from '../../providers/api/bonventeService';

import {UserService} from '../../providers/api/userService';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Constants } from 'providers/constants';
import { DialogsService } from 'providers/api/dialogService';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonDeVenteFilterComponent } from './bon-de-vente-filter/bon-de-vente-filter.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { User } from 'models/users';
import { DOCUMENT } from '@angular/common';
import { JsonService } from '../../providers/api/json.service';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-bon-de-vente',
    templateUrl: './bon-de-vente.component.html',
    providers: [BonVenteService]
})

export class BonDeVenteComponent implements AfterViewInit, OnInit, OnDestroy {

    // bouton ENTER valide formulaire
    private pressedButton: any;

    // champs de recherche
    searchData: any = null;

    // Service
    listBonvente: BonVente[] = [];

    user: User;
    USERCMINT: any;

    // DATATABLE
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    showLoader = false;

    currentPage: number = 0;
    totalPages: number;
    rowsPerPage: number;

    searchForm: UntypedFormGroup;
    searched: boolean = false;

    isMobile: boolean = false;
    searchSubscription: Subscription;
    searchCodeSubscription: Subscription;
    addListenerSubscription: Subscription;

    filterValues: any;

    userPermission: any;
    detailBonVenteVisibility: boolean = false;
    bonVenteListColumns: any;

    constructor(private router: Router,
                private userService: UserService,
                public bonVenteService: BonVenteService,
                private translateService: TranslateService,
                private loadingService: NgxSpinnerService,
                private formBuilder: UntypedFormBuilder,
                private dialogService: DialogsService,
                private currencyPipe: CapCurrencyPipe,
                private deviceService: DeviceDetectorService,
                private dialog: MatDialog,
                public jsonService: JsonService,
                private storageService: StorageService,
                @Inject(DOCUMENT) document
                ) {
    }

    protected editBonVente(info: BonVente): void {
        if(this.detailBonVenteVisibility || !this.userAllowedToModifyOrRemove(info)) {
          this.router.navigate(['/detail-bon-de-vente', info.NUMCOM, info.CMINT]);
          return;
        } 

        this.router.navigate(['/modify-bon-de-vente', info.NUMCOM, info.CMINT]);
    }

    ngOnInit(): void {
        this.isMobile = this.deviceService.isMobile();
        // on cherche le user connecté et son magasin de rattachement
        this.user = this.userService.getCurrentUser();
        this.USERCMINT = this.user.CMINT;

        this.userPermission = this.storageService.getItem('CurrentUserPermission');

        this.userService.headerAdd.next(false);
        this.userService.headerAddListener.next(false);
        this.userService.headerSearchInputThreeDots.next(true);
        this.userService.headerSearchInput.next(true);
        this.userService.headerSearchInputType.next('text');
        this.userService.headerSearch.next(false);

        this.jsonService.getAppConfig().subscribe((res: any) => {
          this.bonVenteListColumns = res.bonVente.list.columns.sort((a, b) => a.order - b.order);
          this.bonVenteListColumns = this.bonVenteListColumns.filter(col => col.display);
          }, 
          (error) => {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        );

        this.searchSubscription = this.userService.headerSearch.subscribe(
          (status: boolean) => {
            if(status === true) {
              this.openUpBondeFilterScreen();
            }
          }
        ) 

        this.searchCodeSubscription = this.userService.headerSearchInputValue.subscribe(
          (search_code) => {           
            if(search_code !== undefined) {
              let rapid_search_code:any = Number(search_code);
              if(isNaN(rapid_search_code)){
                this.searchForm.patchValue({ TRAISOC: search_code === null?'':search_code, NUMCOM : '' });
              } else {
                this.searchForm.patchValue({ NUMCOM: search_code === null?'':search_code, TRAISOC: '' });          
              }
              this.searchFormSubmitter();
            }
          }
        )
        this.addListenerSubscription = this.userService.headerAddListener.subscribe(
          (status: boolean) => {
            if(status === true) {
              this.router.navigate(['/create-bon-de-vente']);
            }
          }
        )

        this.dtOptions = {
            language: {
              processing: this.translateService.instant('kprocessing'),
              search: this.translateService.instant('ksearch'),
              loadingRecords: this.translateService.instant('kloading'),
              info: this.translateService.instant('kgenshowentry'),
              infoEmpty: this.translateService.instant('kgenshowingentry'),
              zeroRecords: this.translateService.instant('knodata'),
              emptyTable: this.translateService.instant('knodatadash'),
              paginate: {
                  first: this.translateService.instant('kfirst'),
                  previous: this.translateService.instant('kprevious'),
                  next: this.translateService.instant('knext'),
                  last: this.translateService.instant('klast'),
              }          
            },
            ordering: false,
            paging: false,
            searching: false,
            retrieve: true,
            info: false
        };
        
        this.searchForm = this.formBuilder.group({
            IDSOC: [Constants.IDSOC],
            NUMCOM: [''],
            TRAISOC: [''],
            TPRENOM: [''],
            CODPOS: [''],
            TVILLE: [''],
            TPORT: [''],
            USERCON: [false],
            MTTTCMIN: [''],
            MTTTCMAX: [''],
        });
        
        const oldFormData: any = this.storageService.getItem('bonVenteFormData');
        const oldListResponse: any = this.storageService.getItem('bonVenteListData');
        
        if(oldFormData !== undefined && oldFormData !== null) {
          this.searchForm.setValue({
            IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
            NUMCOM: oldFormData.NUMCOM !== null?oldFormData.NUMCOM:'',
            TRAISOC: oldFormData.TRAISOC !== null?oldFormData.TRAISOC:'',
            TPRENOM: oldFormData.TPRENOM !== null?oldFormData.TPRENOM:'',
            CODPOS: oldFormData.CODPOS !== null?oldFormData.CODPOS:'',
            TVILLE: oldFormData.TVILLE !== null?oldFormData.TVILLE:'',
            TPORT: oldFormData.TPORT !== null?oldFormData.TPORT:'',
            USERCON: oldFormData.USERCON !== null?oldFormData.USERCON:false,
            MTTTCMIN: oldFormData.MTTTCMIN !== null?oldFormData.MTTTCMIN:'',
            MTTTCMAX: oldFormData.MTTTCMAX !== null?oldFormData.MTTTCMAX:'',
          });
          this.filterValues = oldFormData;
        }
        
        if(oldListResponse !== undefined && oldListResponse !== null) {
          const data = oldListResponse.data;
    
          this.rowsPerPage = oldListResponse.rowsPerPage;
          this.totalPages = oldListResponse.count;        
    
          this.listBonvente = [...data];
    
          this.showLoader = false;
          this.dtTrigger.next();
        }
        if(this.userPermission['4_0'] && !this.userPermission['4_1']) {
          this.detailBonVenteVisibility = true;
        }
    }

    getListLastBonDeVente() {
        this.bonVenteService.getLastBonVente('1').subscribe(
            data => {
                this.showLoader = false;
                if (data.length > 0) {
                    this.listBonvente = [...data];                    
                }
            }, 
            (error) => {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              this.showLoader = false;
            }
        );
    }

    searchFormSubmitter(): void {
        this.showLoader = true;
    
        if(this.searchForm.invalid) {    
          this.showLoader = false;  
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
    
          return;
        }
    
        const form_data = this.searchForm.value; 
       
        this.storageService.setItem('bonVenteFormData', form_data);

        const search_data = {
          CMINT: this.USERCMINT,
          IDSOC: form_data.IDSOC,
          NUMCOM: form_data.NUMCOM !== null?form_data.NUMCOM:'',
          TRAISOC: form_data.TRAISOC !== null?form_data.TRAISOC:'',
          TPRENOM: form_data.TPRENOM !== null?form_data.TPRENOM:'',
          CODPOS: form_data.CODPOS !== null?form_data.CODPOS:'',
          TVILLE: form_data.TVILLE !== null?form_data.TVILLE:'',
          TPORT: form_data.TPORT !== null?form_data.TPORT:'',
          USERCON: form_data.USERCON?'-1':'0',
          MTTTCMIN: form_data.MTTTCMIN !== null?form_data.MTTTCMIN:'',
          MTTTCMAX: form_data.MTTTCMAX !== null?form_data.MTTTCMAX:'',          
        };        
        this.filterValues = form_data;
        this.bonVenteService.searchBonvente(search_data).subscribe(
          (response: any) => {
    
            if(response.success !== undefined) {
              this.currentPage = 0;
    
              const data = response.data;
      
              this.rowsPerPage = response.rowsPerPage;
              this.totalPages = response.count;
      
              this.listBonvente = [...data];
      
              this.searched = true;        
      
              this.showLoader = false;
              this.dtTrigger.next();
    
              this.storageService.setItem('bonVenteListData', response);
              this.storageService.setItem('bonVenteSearched', this.searched);
            } else {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }        
          }, 
          err => {
            this.showLoader = false;
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        )
    }
    
    searchPaginate(page?: number) {
        
        if(this.searchForm.invalid) {
          this.showLoader = false;      
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
    
          return;
        }
    
        const form_data = this.searchForm.value;
        this.storageService.setItem('bonVenteFormData', form_data);
    
        const search_data = {
          CMINT: this.USERCMINT,
          IDSOC: form_data.IDSOC,
          NUMCOM: form_data.NUMCOM !== null?form_data.NUMCOM:'',
          TRAISOC: form_data.TRAISOC !== null?form_data.TRAISOC:'',
          TPRENOM: form_data.TPRENOM !== null?form_data.TPRENOM:'',
          CODPOS: form_data.CODPOS !== null?form_data.CODPOS:'',
          TVILLE: form_data.TVILLE !== null?form_data.TVILLE:'',
          TPORT: form_data.TPORT !== null?form_data.TPORT:'',
          USERCON: form_data.USERCON?'-1':'0',
          MTTTCMIN: form_data.MTTTCMIN !== null?form_data.MTTTCMIN:'',
          MTTTCMAX: form_data.MTTTCMAX !== null?form_data.MTTTCMAX:'',          
        };        
        this.filterValues = form_data;
        this.bonVenteService.searchBonvente(search_data, page).subscribe(
            (response: any) => {
      
              if(response.success !== undefined) {
                const data = response.data;
        
                this.rowsPerPage = response.rowsPerPage;
                this.totalPages = response.count;
        
                this.listBonvente = [...data];                            
        
                this.showLoader = false;
                this.dtTrigger.next();
      
                this.storageService.setItem('bonVenteListData', response);
                this.storageService.setItem('bonVenteSearched', this.searched);
              } else {
                this.showLoader = false;
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              }        
            }, 
            err => {
              this.showLoader = false;
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
        )
    }

    paginationChange(event: any) {
        this.showLoader = true;
        const changedPageIndex = event.pageIndex;    
    
        this.currentPage = changedPageIndex;
    
        this.searchPaginate(changedPageIndex + 1);
    }

    ngOnDestroy(): void {      
        if(this.router.routerState.snapshot.url.indexOf('modify-bon-de-vente') > -1 || this.router.routerState.snapshot.url.indexOf('detail-bon-de-vente') > -1) {

        } else {
          this.storageService.removeItem('bonVenteFormData');
          this.storageService.removeItem('bonVenteListData');
        }
        this.userService.headerSearch.next(false);
        this.userService.headerSearchInput.next(false);
        this.searchSubscription.unsubscribe();
        this.userService.headerAdd.next(false);
        this.addListenerSubscription.unsubscribe();
        this.searchCodeSubscription.unsubscribe();
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    deleteConfirmation(item: BonVente): void {
      const message = `
      <h1 class="text-left mb-3">${this.translateService.instant('kbonsursupprimer')}</h1>
      <h1 class="text-left pl-2">        
        ${this.translateService.instant('kbonNum')} : ${item.NUMCOM} <br>
        ${this.translateService.instant('kbonpour')}: ${item.TRAISOC != null?item.TRAISOC:''}${item.TPRENOM != null?' '+item.TPRENOM:''}<br>
        ${this.translateService.instant('kbonmontant')}: ${this.currencyPipe.transform(item.MTTTC, null, false)}
      </h1>
      `;
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '60vw',
        data: {
          title: '',
          description: message
        }     
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result === "OK") {
          this.loadingService.show();
          this.deleteBonDeVente({ pMag: this.USERCMINT, pNumBon: item.NUMCOM, Id_User: this.user.CIINT, Id_InstUser: this.user.IDINSTINT });
        }
      });    
    }

    deleteBonDeVente(data: { pMag: number, pNumBon: string, Id_User: any, Id_InstUser: any }) {
      this.bonVenteService.removeBonvente(data).subscribe(
        (resp) => {
          this.loadingService.hide();          
          if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                this.listBonvente = this.listBonvente.filter((el) => el.NUMCOM != data.pNumBon);                

                if(this.listBonvente.length == 0) {
                  this.showLoader = true;

                  if(this.currentPage != 0) {
                    this.currentPage--;
                  }

                  this.searchPaginate(this.currentPage + 1);
                } else {
                  const storable_json = {
                    rowsPerPage: this.rowsPerPage,
                    count: this.totalPages,
                    data: this.listBonvente
                  };

                  this.storageService.setItem('bonVenteListData', storable_json);
                }
              } else {
                this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              }          
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            }         
        },
        error => {        
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      );
    }

    viewBonVenteDetail(item: BonVente) {
      this.router.navigate(['/detail-bon-de-vente', item.NUMCOM, item.CMINT]);
    }

    resetForm() {
      this.searchForm.reset();
      this.storageService.removeItem('bonVenteFormData');

      this.searchForm.patchValue({
        IDSOC: Constants.IDSOC,
        USERCON: false,
        NUMCOM: '',
        TRAISOC: '',
        TPRENOM: '',
        CODPOS: '',
        TVILLE: '',
        TPORT: '',
        MTTTCMIN: '',
        MTTTCMAX: '',
      });

      // To empty the search input value and trigger the searchFormSubmitter 
      this.userService.headerSearchInputValue.next('');
    }

    listDateFormatter(date: string) {
      if(date === undefined || date  === null) {
        return '';
      }
      
      const given_date = moment(date).format('DD.MM.YYYY');
      const today_date = moment().format('DD.MM.YYYY');

      if(given_date === today_date) {
        return moment(date).format('HH:mm');
      }

      return moment(date).format('DD.MM.YYYY HH:mm');
    }

    
    openUpBondeFilterScreen() {
      this.userService.headerSearch.next(false);  
      const dialogRef = this.dialog.open(BonDeVenteFilterComponent, {      
        width: '60vw',
        maxHeight: this.isMobile?'85vh':undefined,
        data: {
          form_data: this.searchForm.value,
          user: this.user,
          USERCMINT: this.USERCMINT,
          resetForm: this.resetForm
        }     
      });  
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.status === "success") {
          const form_data = result.form_data;

          this.searchForm.patchValue({ 
            IDSOC: form_data.IDSOC,
            NUMCOM: form_data.NUMCOM !== null?form_data.NUMCOM:'',
            TRAISOC: form_data.TRAISOC !== null?form_data.TRAISOC:'',
            TPRENOM: form_data.TPRENOM !== null?form_data.TPRENOM:'',
            CODPOS: form_data.CODPOS !== null?form_data.CODPOS:'',
            TVILLE: form_data.TVILLE !== null?form_data.TVILLE:'',
            TPORT: form_data.TPORT !== null?form_data.TPORT:'',
            USERCON: form_data.USERCON !== null?form_data.USERCON:false,
            MTTTCMIN: form_data.MTTTCMIN !== null?form_data.MTTTCMIN:'',
            MTTTCMAX: form_data.MTTTCMAX !== null?form_data.MTTTCMAX:'',
          });
          this.filterValues = form_data;
          this.searchFormSubmitter();
        }
      });

    }

    onSwipe(e: any, index: number) {
      const currentRowItem = this.listBonvente[index];
      if(this.detailBonVenteVisibility || !this.userAllowedToModifyOrRemove(currentRowItem)) {
        return;
      }

      if(e.deltaX > 0) {
        // Right Swipe
        const element = document.getElementById('swipeItem-' + index);
        element.style.transform = 'translateX(0)'
        element.classList.remove('swiped');
      } else {
        // Left Swipe
        this.__resetPreviousSwipes('.mat-list-item');

        const element = document.getElementById('swipeItem-' + index);
        element.classList.add('swiped');
        element.style.transform = 'translateX(-100px)'
      }
    }

    __resetPreviousSwipes(listEl: string) {
      const allSwipeAbles = document.querySelectorAll(listEl);
      allSwipeAbles.forEach((el: any, i) => {
        el.classList.remove('swiped');
        el.style.transform = 'translateX(0)';
      })
    }

    get filterValuesFormat(): string {
      let filterArr = [];

      if(this.filterValues.NUMCOM !== undefined && this.filterValues.NUMCOM !== null && this.filterValues.NUMCOM !== '') {
        filterArr.push(this.filterValues.NUMCOM);
      }

      if(this.filterValues.TRAISOC !== undefined && this.filterValues.TRAISOC !== null && this.filterValues.TRAISOC !== '') {
        filterArr.push(this.filterValues.TRAISOC);
      }

      if(this.filterValues.TPRENOM !== undefined && this.filterValues.TPRENOM !== null && this.filterValues.TPRENOM !== '') {
        filterArr.push(this.filterValues.TPRENOM);
      }

      if(this.filterValues.TPORT !== undefined && this.filterValues.TPORT !== null && this.filterValues.TPORT !== '') {
        filterArr.push(this.filterValues.TPORT);
      }

      if(this.filterValues.CODPOS !== undefined && this.filterValues.CODPOS !== null && this.filterValues.CODPOS !== '') {
        filterArr.push(this.filterValues.CODPOS);
      }
      if(this.filterValues.TVILLE !== undefined && this.filterValues.TVILLE !== null && this.filterValues.TVILLE !== '') {
        filterArr.push(this.filterValues.TVILLE);
      }

      if(this.filterValues.USERCON !== undefined && this.filterValues.USERCON !== null && this.filterValues.USERCON !== '') {
        if(this.filterValues.USERCON) {
          filterArr.push(this.translateService.instant('kbonuser'));
        }
      }

      if(this.filterValues.MTTTCMIN !== undefined && this.filterValues.MTTTCMIN !== null && this.filterValues.MTTTCMIN !== '') {
        filterArr.push(this.filterValues.MTTTCMIN);
      }
      if(this.filterValues.MTTTCMAX !== undefined && this.filterValues.MTTTCMAX !== null && this.filterValues.MTTTCMAX !== '') {
        filterArr.push(this.filterValues.MTTTCMAX);
      }    

      return filterArr.length > 0 ? filterArr.join(', ') : '';
    }

    userAllowedToModifyOrRemove(item: BonVente): boolean {
        if (this.userPermission && !this.userPermission['0_24'] && item.CMINTSTOCK) {
            return false;
        }

        return item.CMINT == this.USERCMINT;
    }

    isMagStockAvail(item: BonVente): boolean {
        return item.CMINTSTOCK && item.CMINTSTOCK != '0';
    }

}
