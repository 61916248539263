import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { BonVenteService } from '../../../providers/api/bonventeService';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-bon-de-vente-filter',
  templateUrl: './bon-de-vente-filter.component.html',
  styleUrls: ['./bon-de-vente-filter.component.scss'],
  providers: [BonVenteService]
})
export class BonDeVenteFilterComponent implements OnInit, AfterViewInit {

    @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;

    // champs de recherche
    searchData: any = null;
    user: any;
    USERCMINT: any;
    showLoader = true;
    searchForm: UntypedFormGroup;
    searched: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<BonDeVenteFilterComponent>,
      public bonVenteService: BonVenteService,
      private userService: UserService,
      private formBuilder: UntypedFormBuilder,
      private storageService: StorageService,
      ) { }

    ngOnInit(): void {
        this.searchForm = this.formBuilder.group({
          IDSOC: [Constants.IDSOC],
          NUMCOM: [''],
          TRAISOC: [''],
          TPRENOM: [''],
          CODPOS: [''],
          TVILLE: [''],
          TPORT: [''],
          USERCON: [false],
          MTTTCMIN: [''],
          MTTTCMAX: [''],
      });

      const oldFormData: any = this.storageService.getItem('bonVenteFormData');

      if(oldFormData !== undefined && oldFormData !== null) {
        this.searchForm.patchValue({
          IDSOC: oldFormData.IDSOC !== null?oldFormData.IDSOC:Constants.IDSOC,
          NUMCOM: oldFormData.NUMCOM !== null?oldFormData.NUMCOM:'',
          TRAISOC: oldFormData.TRAISOC !== null?oldFormData.TRAISOC:'',
          TPRENOM: oldFormData.TPRENOM !== null?oldFormData.TPRENOM:'',
          CODPOS: oldFormData.CODPOS !== null?oldFormData.CODPOS:'',
          TVILLE: oldFormData.TVILLE !== null?oldFormData.TVILLE:'',
          TPORT: oldFormData.TPORT !== null?oldFormData.TPORT:'',
          USERCON: oldFormData.USERCON !== null?oldFormData.USERCON:false,
          MTTTCMIN: oldFormData.MTTTCMIN !== null?oldFormData.MTTTCMIN:'',
          MTTTCMAX: oldFormData.MTTTCMAX !== null?oldFormData.MTTTCMAX:'',
        });
      }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.filterFirst.nativeElement.select();
      this.filterFirst.nativeElement.focus();
      this.filterFirst.nativeElement.setAttribute('inputmode', 'numeric');
    }, 300);
  }

  headerSearchFormSubmitter () {
    this.userService.headerSearchInputValue.next(undefined);
    this.dialogRef.close({
      status: 'success',
      form_data: this.searchForm.value,
    });
  }

  resetForm() {
    this.userService.headerSearchInputValue.next(undefined);
    this.searchForm.reset();
    this.storageService.removeItem('bonVenteFormData');

    this.searchForm.patchValue({
      IDSOC: Constants.IDSOC,
      USERCON: false,
    });
  }

}
