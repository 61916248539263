<div class="modal-container" id="prix_force_article">
    <form [formGroup]="calculateQtyForm" (ngSubmit)="calculateQtyFormSubmitter()">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">{{'kkenterqty' | translate}}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{'kklengthen' | translate}}</mat-label>
                        <input [imask]="maskOptions" [unmask]="true" matInput (focus)="lengthInput.select()" cdkFocusInitial formControlName="length" #lengthInput/>
                        <h2 class="text-lowercase" matSuffix>m</h2>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{'kkwidth' | translate}}</mat-label>
                        <input [imask]="maskOptions" [unmask]="true" matInput (focus)="widthInput.select()" formControlName="width" #widthInput />
                        <h2 class="text-lowercase" matSuffix>m</h2>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{'kkheight' | translate}}</mat-label>
                        <input [imask]="maskOptions" [unmask]="true" matInput (focus)="heightInput.select()" formControlName="height" #heightInput />
                        <h2 class="text-lowercase" matSuffix>m</h2>
                    </mat-form-field>
                </div>
            </div>            
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button type="submit" class="bt_send">
                <i class="material-icons">done</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kuok' | translate}}
                </span>
            </button>
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
    </form>
</div>