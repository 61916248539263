<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
                {{ "kpcodepostal" | translate }}
            </h1>
        </div>        
        <div class="col-12 text-center" *ngIf="isMobile">
            <div class="row">
                <button type="button" class="bt_valid w-100 pad10 mb-2" (click)="addPostalCode()" mat-button >
                    <mat-icon class="addicon">add_circle</mat-icon>
                    {{ 'kpadd' | translate }}
                </button>
            </div>
        </div>
        <div class="col-12 text-center" *ngIf="isMobile && filterValues">
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>
    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium">{{'kclclearform' | translate}}</div>
                </button>
            </div>
        </div>
    </div>

    <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-12 ">

                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="postal_code" maxlength="5" [placeholder]="'kpcode' | translate" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-4 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="city" [placeholder]="'kpcity' | translate" />
                            </mat-form-field>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button type="submit">
                        <mat-icon>search</mat-icon>
                        {{ "kpsearch" | translate }}
                    </button>

                    <button type="button" mat-button class="bt_valid ml-3" (click)="addPostalCode()">
                        <mat-icon>add_circle</mat-icon>
                        {{ "kpadd" | translate }}
                    </button>
                </div>                
            </div>
        </div>
    </form>    
    <div class="bloc_container">

        <div class="row">
            <div class="col-12 no-padding">
                <div class="">
                    <mat-paginator *ngIf="!showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator> 
                </div>                
                <table datatable class="row-border hover" id="postalCodesList" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                    <thead [class.d-none]="isMobile">
                        <tr>
                            <th class="code">
                                <div class="d-flex justify-content-start align-items-center sorter" (click)="sortingOrder('CODPOS')">
                                    <span>{{ "kpcode" | translate }}</span>                                    
                                    <span class="ml-3" *ngIf="orderedByColumn === 'CODPOS'">
                                        <mat-icon *ngIf="sortByColumn === 'asc'">keyboard_arrow_down</mat-icon>
                                        <mat-icon *ngIf="sortByColumn === 'desc'">keyboard_arrow_up</mat-icon>
                                    </span>
                                </div>
                            </th>
                            <th class="ville">
                                <div class="d-flex justify-content-start align-items-center sorter" (click)="sortingOrder('VILLE')">
                                    <span>{{ "kpcity" | translate }}</span>                                    
                                    <span class="ml-3" *ngIf="orderedByColumn === 'VILLE'">
                                        <mat-icon *ngIf="sortByColumn === 'asc'">keyboard_arrow_down</mat-icon>
                                        <mat-icon *ngIf="sortByColumn === 'desc'">keyboard_arrow_up</mat-icon>
                                    </span>
                                </div>
                            </th>                            
                            <th width="15%" class="actions">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listPostalCodes?.length != 0 && !showLoader">
                        <tr *ngFor="let code_row of listPostalCodes">
                            <td class="numero" (click)="editPostalCode(code_row)">{{ code_row.CODPOS }}</td>
                            <td class="nom" (click)="editPostalCode(code_row)">{{ code_row.VILLE | uppercase  }}</td>
                            <td class="actions">
                                <button type="button" mat-mini-fab color="primary" (click)="editPostalCode(code_row)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button type="button" mat-mini-fab color="warn" class="ml-1 my-1" (click)="deleteConfirmation(code_row.TROWID)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>                        
                    </tbody>
                    <tbody *ngIf="listPostalCodes?.length == 0 || showLoader">
                        <tr>
                            <td colspan="3" *ngIf="listPostalCodes?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate  }}</td>
                            <td colspan="3" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>
