import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import SheetItem from '../../models/bottomSheet';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit {

  sheetItemsList: SheetItem[] = []

  constructor(private router: Router,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>) { }

  ngOnInit(): void {

    if (this.data !== undefined && this.data !== null) {
      if (this.data.sheetItems !== undefined && this.data.sheetItems !== null) {
        this.sheetItemsList = this.data.sheetItems;
      }
    }
  }

  onSheetItemClicked(item: SheetItem) {
    if (item.redirectUrl !== undefined && item.redirectUrl !== null && item.redirectUrl !== '') {
      window.location.href = item.redirectUrl;
      this.bottomSheetRef.dismiss();
    } else if (item.redirectTo !== undefined && item.redirectTo !== null && item.redirectTo !== '') {
      this.router.navigate(item.redirectTo);
      this.bottomSheetRef.dismiss();
    } else if (item.onClick !== undefined && item.onClick !== null) {
      item.onClick();
      this.bottomSheetRef.dismiss();
      return;
    } else {
      return;
    }
  }

}
