import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import BonPanier from 'models/bonPanier';
import MagComplement from 'models/magComplement';
import Transporteur from 'models/transporteur';
import * as moment from "moment";
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';

@Component({
  selector: 'app-date-de-promesse',
  templateUrl: './date-de-promesse.component.html',
  styleUrls: ['./date-de-promesse.component.scss'],
  providers: [ {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
    BonVenteService]
})
export class DateDePromesseComponent implements OnInit {

  today = new Date();
  minDate = new Date();

  promesseForm: UntypedFormGroup; 
  oldLivreData: any;  

  dtliv_status: boolean = false;

  magComplementData: MagComplement;
  
  transporteurLabel: string = '';
  panier: BonPanier;

  previousTranporteur: Transporteur;

  defaultDiffereDays: number;
  paramData: any;

  constructor(private formBuilder: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private userService: UserService,
            public dialogRef: MatDialogRef<DateDePromesseComponent>,
            private dialog: MatDialog,
            private dialogService: DialogsService,
            private loadingService: NgxSpinnerService,
            private translateService: TranslateService) { }

  ngOnInit(): void {
    this.oldLivreData = this.data.livreDetail;
    this.magComplementData = this.data.magComplementData;
    this.panier = this.data.panier;    
    
    this.promesseForm = this.formBuilder.group({
      dtpromesse: [{value: '', disabled: true}],
    });

    this.setDefaultDtEmporte();

    if(this.panier !== undefined && this.panier !== null) {
      let promesseDateYear = moment(this.panier.dtpromesse).year();
      let promesseCalcDateYear = moment(this.panier.dtpromessecalc).year();
      this.promesseForm.patchValue({
        dtpromesse: (promesseDateYear !== undefined && promesseDateYear !== 1899)?new Date(this.panier.dtpromesse):(promesseCalcDateYear !== undefined && promesseCalcDateYear !== 1899?new Date(this.panier.dtpromessecalc):'')
      })
    }
  }

  async setDefaultDtEmporte() {
    this.loadingService.show();
    this.minDate = (this.panier.dtpromessecalc != null && moment(this.panier.dtpromessecalc).startOf('day').year() != 1899)?new Date(this.panier.dtpromessecalc):this.today;
    this.loadingService.hide();
  }

  promesseFormSubmitter() {
    const form_data = this.promesseForm.getRawValue();

    const today = moment().startOf('day');
    const ED_default_date = moment().add(this.defaultDiffereDays, 'days').startOf('day');
    const selected_date = moment(form_data.dtpromesse).startOf('day');

    const diff = ED_default_date.diff(selected_date, 'days');
    
    this.promesseUpdate();
  }

  promesseUpdate() {
    const form_data = this.promesseForm.getRawValue();

    form_data.dtpromesse = (form_data.dtpromesse !== '')?moment(form_data.dtpromesse).format('YYYY-MM-DD HH:mm:ss'):moment('1899-12-28').format('YYYY-MM-DD HH:mm:ss');
    this.dialogRef.close({ status: 'success', data: form_data });
  }

  promesseConfirmation(): void {
    const message = `
      <h1 class="mb-3">${this.translateService.instant('kbondatenotstd')}</h1>
      <h1 class="">${this.translateService.instant('kboncontinue')}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [
          this.translateService.instant('ksyes'),
          this.translateService.instant('kartno')
        ]
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.promesseUpdate();
      }
    });    
  }

}
