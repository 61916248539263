<div class="modal-container" id="couche_prix_article">
    <form [formGroup]="forcageForm" (ngSubmit)="forcageFormSubmitter()">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="text-uppercase text-center ">
                <div class="row">
                    <div class="col-11 text-left">
                        <h4 class="modal_title">{{ title | translate }}</h4>
                    </div>
                    <div class="col-1 text-right no-padding">
                        <button mat-button [mat-dialog-close] class="bt_close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-md-12">
                    <div *ngIf="listMotifs?.length == 0 || showLoader" class="w-100 text-center">
                        <p *ngIf="listMotifs?.length == 0"></p>  
                        <mat-icon *ngIf="showLoader" class="synching">sync</mat-icon>
                    </div>

                    <mat-radio-group
                        formControlName="motif"
                        aria-labelledby="forcage-label"
                        *ngIf="listMotifs?.length != 0 && !showLoader"
                        class="forcage-radio-group" (change)="onForcageChanged($event)">
                        <mat-radio-button class="forcage-radio-button" *ngFor="let rule of listMotifs;" [value]="rule.MOTLIB">
                            {{rule.MOTLIB}}
                        </mat-radio-button>

                        <mat-radio-button *ngIf="listMotifs !== undefined && (listMotifs === null || listMotifs.length === 0)" class="forcage-radio-button" value="autre">
                            < {{'kmaddlibre' | translate}} >
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="otherMotif">
                    <mat-form-field class="example-full-width" floatLabel="always">
                        <mat-label>{{ 'kblomotif' | translate }} </mat-label>
                        <input matInput formControlName="custom_motif">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>

        <mat-card-actions class="text-center">
            <button mat-button type="submit" class="bt_send">
                <i class="material-icons">done</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kuok' | translate}}
                </span>
            </button>
            <button mat-button [mat-dialog-close] class="bt_send">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{'kartclose' | translate}}
                </span>
            </button>
        </mat-card-actions>
    </mat-card>
    </form>
</div>