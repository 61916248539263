import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import BonPanier, { PanierInfosfac } from 'models/bonPanier';
import { Client } from 'models/clients';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { BonVente } from 'models/bonventes';
import { Magasin } from 'models/magasins';
import { ClientService } from 'providers/api/clientService';
import { Constants } from 'providers/constants';
import { UserService } from 'providers/api/userService';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { SearchClientComponent } from '../search-client/search-client.component';
import { ClientFilterComponent } from 'pages/client/client-filter/client-filter.component';
import { User } from 'models/users';
import {BonCommandeService} from '../../../providers/api/bonCommandeService';
import { StorageService } from 'providers/api/storageService';
import { ClientHistoriqueComponent } from 'components/client-historique/client-historique.component';

@Component({
  selector: 'app-info-facturation-bonde',
  templateUrl: './info-facturation-bonde.component.html',
  styleUrls: ['./info-facturation-bonde.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoFacturationBondeComponent implements OnInit {

  advancedDialogRef: any;

  private _client: Client;
  get client(): Client { return this._client }
  @Input() set client(client: Client) {this.clientChange.emit(this._client = client)}
  @Output() private clientChange: EventEmitter<Client> = new EventEmitter<Client>();

  private _factuClient: Client;
  get factuClient(): Client { return this._factuClient }
  @Input() set factuClient(factuClient: Client) {this.factuClientChange.emit(this._factuClient = factuClient)}
  @Output() private factuClientChange: EventEmitter<Client> = new EventEmitter<Client>();

  private _sameAsClient: boolean;
  get sameAsClient(): boolean { return this._sameAsClient }
  @Input() set sameAsClient(sameAsClient: boolean) {this.sameAsClientChange.emit(this._sameAsClient = sameAsClient)}
  @Output() private sameAsClientChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _facturationDetail: PanierInfosfac;
  get facturationDetail(): PanierInfosfac { return this._facturationDetail }
  @Input() set facturationDetail(facturationDetail: PanierInfosfac) {this.facturationDetailChange.emit(this._facturationDetail = facturationDetail)}
  @Output() private facturationDetailChange: EventEmitter<PanierInfosfac> = new EventEmitter<PanierInfosfac>();  

  private _panier: BonPanier;
  get panier(): BonPanier { return this._panier }
  @Input() set panier(panier: BonPanier) {
    this.panierChange.emit(this._panier = panier);

    if(panier !== undefined && panier !== null) {
      const numBon = this.storageService.getItem('previousNumBon');
      if(numBon !== undefined && numBon != null) {
        this.bonNumBon = numBon;
      }
    } else {
      
    }    
  }
  @Output() private panierChange: EventEmitter<BonPanier> = new EventEmitter<BonPanier>();

  @Input() listMagasins: Magasin[];

  @Input() set flowCMINT(flowCMINT: any) {
    if(flowCMINT !== undefined && flowCMINT != null) {
      this.USERCMINT = flowCMINT;
    } else {
      this.user = this.userService.getCurrentUser();          
      this.USERCMINT = this.user.CMINT;
    }
  }

  @Input() flowType: any;
  @Input() readOnly: boolean = false;

  isMobile: boolean;
  user: User;
  USERCMINT: any;

  bonNumBon: string;

  searchForm: UntypedFormGroup = this.formBuilder.group({
    same_client: [this.sameAsClient],
    client_nom: [''],
  });

  selectedClient: Client;
  
  searchButtonDisabled: boolean = true;

  newClient: boolean = false;
  editClient: boolean = false;

  magasinNom: string;
  popupCloseSusscription: Subscription;

  userPermission: any;
  sectionDisabled: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private loadingService: NgxSpinnerService,
    private bonVenteService: BonVenteService,
    private bonCommandeService: BonCommandeService,
    private userService: UserService,
    private dialogService: DialogsService,
    private clientService: ClientService,
    private translateService: TranslateService,
    private deviceDetector: DeviceDetectorService,
    private storageService: StorageService
    ) {

      this.popupCloseSusscription = this.userService.popupClose.subscribe(closeAction => {
        if(closeAction == false){
           this.closeAdvancedSearch();
        }
      });
     }

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();

    this.searchForm.get('client_nom').disable({ onlySelf: true });
    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.defineSectionStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.client !== undefined) {
      if(!this.sameAsClient) {
        this.selectedClient = this.factuClient;
        
        if(this.flowType == 'retrait_marchandise') {
          this.searchForm.get('client_nom').disable({ onlySelf: true });
        } else {
          this.searchForm.get('client_nom').enable({ onlySelf: true });
        }

        this.searchForm.get('same_client').setValue(false);

        this.refreshClientMagasinNom();
      } else {
        this.selectedClient = changes.client.currentValue;
        this.factuClient = this.selectedClient;

        this.searchForm.get('client_nom').disable({ onlySelf: true });
        this.searchForm.patchValue({
          client_nom: '',
          same_client: true
        });

        this.refreshClientMagasinNom();
      }
      this.defineSectionStatus();
    }
    
    if(changes.sameAsClient !== undefined) {
      const status = changes.sameAsClient.currentValue;
      this.searchButtonDisabled = status;

      if(!status) {
        this.selectedClient = this.factuClient;

        if(this.flowType == 'retrait_marchandise') {
          this.searchForm.get('client_nom').disable({ onlySelf: true });
        } else {
          this.searchForm.get('client_nom').enable({ onlySelf: true });
        }

        this.searchForm.get('same_client').setValue(false);

        this.refreshClientMagasinNom();
      } else {      
        this.selectedClient = this.client;
        this.factuClient = this.selectedClient;
        
        this.searchForm.get('client_nom').disable({ onlySelf: true });
        this.searchForm.patchValue({
          client_nom: '',
          same_client: true
        });

        this.refreshClientMagasinNom();
      }    
      this.defineSectionStatus();
    }
  }
  ngOnDestroy(): void {
    this.popupCloseSusscription.unsubscribe();
  }

  defineSectionStatus() {
    if(this.flowType == 'retrait_marchandise') {
      this.sectionDisabled = true;
      this.searchForm.get('client_nom').disable({ onlySelf: true });
    } else {
      if(this.readOnly) {
        this.sectionDisabled = true;
        this.searchForm.get('client_nom').disable({ onlySelf: true });
      } else {
        if(this.sameAsClient) {
          this.sectionDisabled = true;
          this.searchForm.get('client_nom').disable({ onlySelf: true });
        } else {
          this.sectionDisabled = false;
          this.searchForm.get('client_nom').enable({ onlySelf: true });
        }
      }
    }
  }

  changeSameClient() {
    this.sameAsClient = this.searchForm.value['same_client'];
    this.changeSameStatus(this.sameAsClient);
  }

  async changeSameStatus(status: boolean) {
    this.searchButtonDisabled = status;

    if(!status) {
      this.selectedClient = undefined;
      this.factuClient = this.selectedClient;

      if(this.flowType == 'retrait_marchandise') {
        this.searchForm.get('client_nom').disable({ onlySelf: true });
      } else {
        this.searchForm.get('client_nom').enable({ onlySelf: true });
      }

      this.refreshClientMagasinNom();
    } else {
      this.selectedClient = this.client;
      this.factuClient = this.selectedClient;

      if (this.flowType === 'bonde_commande') {
        this.updateBonCommande();
      }

      this.refreshClientMagasinNom();
      this.searchForm.get('client_nom').disable({ onlySelf: true });
      this.searchForm.patchValue({
        client_nom: ''
      });
    }
  }

  searchClientFormSubmitter(data?: any) {
    let passData = {};
    if(data !== undefined && data != null) {
      passData = {
        ...data,
        CMINT: this.USERCMINT
      };
    } else {
      passData = {
        CMINT: this.USERCMINT,
        client_nom: this.searchForm.get('client_nom').value
      };
    }

    const dialogRef = this.dialog.open(SearchClientComponent, {
      width: '100vw',
      maxHeight: this.isMobile?'95vh':'90vh', 
      data: passData
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        this.selectedClient = result.client_data;
        this.factuClient = this.selectedClient;
        this.refreshClientMagasinNom();

        if(this.flowType === 'bonde_vente') {
          this.updateBondeVente();
        } else if(this.flowType === 'bonde_commande') {
          this.updateBonCommande();
        }
      }
    });
  }

  updateBondeVente() {
    if(this.panier === undefined || this.panier === null) {
      return;
    }    
    
    this.loadingService.show();    

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let bondeVenteData: BonPanier = {
      ...this.panier,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    if(this.factuClient !== undefined && this.factuClient !== null && !this.sameAsClient) {
      bondeVenteData.clientfacture = {
        mag: +this.factuClient.CMINT,
        civil: +this.factuClient.IDCIV,
        nom: this.factuClient.TRAISOC,
        prenom: this.factuClient.TPRENOM,
        adr1: this.factuClient.TADR1,
        adr2: this.factuClient.TADR2,
        adr3: this.factuClient.TADR3,
        adr4: this.factuClient.TADR4,
        codpos: this.factuClient.CODPOS,
        ville: this.factuClient.TVILLE,
        pays: this.factuClient.CODPAYS,
        tel: this.factuClient.TTEL,
        fax: this.factuClient.TFAX,
        gsm: this.factuClient.TPORT,
        email: this.factuClient.TEMAIL,
        idinstcli: +this.factuClient.IDINSTTIE,
        idcli: +this.factuClient.IDTIERS
      };
    } else {
      bondeVenteData.clientfacture = "";
    }

    bondeVenteData['editinfo'] = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData);    
  }

  editBonDeVente(bondeVenteData: any) {
    this.bonVenteService.modifyBonvente(bondeVenteData).subscribe(
      (resp) => {
        this.loadingService.hide();        

        if(resp.statusCode == 200) {
          if(resp.data.ErrorCode == 0) {
            // List update after a success response
            const oldListData = this.storageService.getItem('bonVenteListData');
            const oldNumBon = this.bonNumBon;
            // ------------------------------

            this.panier = resp.data.Panier;
            this.bonNumBon = resp.data.NumBon;

            this.storageService.setItem('previousBonPanier', this.panier);
            this.storageService.setItem('previousNumBon', resp.data.NumBon);

            // List update after a success response
            if(oldListData !== undefined && oldListData !== null) {
              oldListData.data.map(
                (row: BonVente) => {
                  if(row.NUMCOM == oldNumBon) {
                    row.TRAISOC = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TRAISOC:null;
                    row.TPRENOM = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPRENOM:null;
                    row.TADR3 = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TADR3:null;
                    row.CODPOS = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.CODPOS:null;
                    row.TVILLE = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TVILLE:null;
                    row.TTEL = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TTEL:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.TPORT = (this.panier.client !== undefined && this.panier.client !== null)?resp.data.customClient.TPORT:null;
                    row.ENDMOD = moment(this.panier.dtmaj).utcOffset(this.panier.dtmaj).format('YYYY-MM-DD HH:mm:ss');
                    
                    row.MTHT = this.panier.montantHT;                    
                    row.MTTTC = this.panier.montant;
                    row.MTTVA = (this.panier.montant - this.panier.montantHT);
                    
                    row.CLINUM = (this.panier.client !== undefined && this.panier.client !== null)?this.panier.client.idcli.toString():"0";
                    row.CLILIVNUM = (this.panier.clientlivre !== undefined && this.panier.clientlivre !== null)?this.panier.clientlivre.idcli.toString():"0";
                    row.CLIFACNUM = (this.panier.clientfacture !== undefined && this.panier.clientfacture !== null)?this.panier.clientfacture.idcli.toString():"0";                    

                    row.NUMCOM = this.bonNumBon;

                    return row;
                  }

                  return row;
                }
              );

              const updated_bonvente = oldListData.data.find(
                (row: BonVente) => row.NUMCOM == this.bonNumBon
              );

              let updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
              updated_row_removed.unshift(updated_bonvente);

              oldListData.data = updated_row_removed;

              this.storageService.setItem('bonVenteListData', oldListData);
            }
            // ------------------------------
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {          
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    ); 
  }

  async updateBonCommande() {
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
    const panier = {
      ...this.panier,
      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.panier.libusermaj,
      dtmaj: current_date
    };

    if(this.factuClient !== undefined && this.factuClient !== null && !this.sameAsClient) {
      panier.clientfacture = {
        mag: +this.factuClient.CMINT,
        civil: +this.factuClient.IDCIV,
        nom: this.factuClient.TRAISOC,
        prenom: this.factuClient.TPRENOM,
        adr1: this.factuClient.TADR1,
        adr2: this.factuClient.TADR2,
        adr3: this.factuClient.TADR3,
        adr4: this.factuClient.TADR4,
        codpos: this.factuClient.CODPOS,
        ville: this.factuClient.TVILLE,
        pays: this.factuClient.CODPAYS,
        tel: this.factuClient.TTEL,
        fax: this.factuClient.TFAX,
        gsm: this.factuClient.TPORT,
        email: this.factuClient.TEMAIL,
        idinstcli: +this.factuClient.IDINSTTIE,
        idcli: +this.factuClient.IDTIERS
      };
    } else {
      panier.clientfacture = "";
    }

    const bondeCommandeData = {
      pMag: this.panier.mag,
      pNumBon: this.bonNumBon,
      pPanier: panier, // Sending as Panier
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };

    this.loadingService.show();
    await this.editBonDeCommandeFull(bondeCommandeData);
    this.loadingService.hide();
  }

  __updateBonDeCommandeList() {
    // List update after a success response
    const oldListData = this.storageService.getItem('bonCommandeListData');
    if(oldListData !== undefined && oldListData !== null && oldListData.data != null) {
      oldListData.data.Bons = oldListData.data.Bons.filter((_r: any) => _r !== null && _r != 'null');
      oldListData.data.Bons.map(
          (row: any) => {
            if(row.numbon == this.bonNumBon) {
              row.montant = this.panier.montant;
              row.dtrel = this.panier.dtrel;
              row.typecde = this.panier.typecde;
              row.dtech = this.panier.dtech;
              row.statut = this.panier.statut;
              row.dtmaj = this.panier.dtmaj;
            }
            return row;
          }
      );

      const updatedCommande = oldListData.data.Bons.find(
          (row: any) => row.numbon == this.bonNumBon
      );

      let updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
      updated_row_removed.unshift(updatedCommande);

      oldListData.data.Bons = updated_row_removed;

      this.storageService.setItem('bonCommandeListData', oldListData);
    }
  }

  async editBonDeCommandeFull(bonCommandeData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyBonCommandeFull(bonCommandeData).subscribe(
          async (resp) => {
            if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                await this.getBonDeCommandeByNumBon(this.bonNumBon);
                this.__updateBonDeCommandeList();
                resolve({ response: resp });
              } else {
                this.loadingService.hide();
                this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                reject({ response: resp });
              }
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
              resolve({ response: resp });
            }
          },
          err => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    });
  }

  async getBonDeCommandeByNumBon(numBon: string, closeLoader: boolean = true): Promise<any> {
    if(numBon == 'undefined' || numBon == 'null') {
      return;
    }

    return new Promise((resolve, reject) => {
      this.bonCommandeService.getBonCommandeDetail(numBon, this.panier.mag, 'commande').subscribe(
          async (resp) => {
            this.loadingService.hide();
            if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                this.panier = resp.data.Panier;
                this.bonNumBon = resp.data.NumBon;

                this.storageService.setItem('previousBonPanier', this.panier);
                this.storageService.setItem('previousNumBon', resp.data.NumBon);
              } else {
                this.loadingService.hide();
                this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
              }
              resolve(resp);
            } else {
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              resolve(resp);
            }
          },
          err => {
            reject(err);
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
      )
    });
  }

  openCreateClientForm() {
    this.newClient = true;
    this.editClient = false;
  }

  onCreateClientChanged(event: any) {
    if(event.status === 'return') {
      this.newClient = false;
    }

    if(event.status === 'success_bon_create') {
      this.newClient = false;
      const response = event.response;
      this.selectedClient = response.data.factureClient;
      this.factuClient = this.selectedClient;

      this.refreshClientMagasinNom();
    }

    if(event.status === 'success_create') {
      this.newClient = false;
      const response = event.response;
      this.selectedClient = response.data;
      this.factuClient = this.selectedClient;

      this.refreshClientMagasinNom();
    }
  }

  openEditClientForm() {
    this.newClient = false;
    this.editClient = true;
    this.selectedClient.MAG_NOM = this.magasinNom;
  }

  onEditClientChanged(event: any) {
    if(event.status === 'return') {
      this.editClient = false;
    }

    if(event.status === 'success_edit_bon_client') {
      this.editClient = false;
      const response = event.response;

      if (response.data.Panier.clientfacture != null) {
        this.selectedClient = response.data.factureClient;
      } else {
        this.selectedClient = response.data.customClient;
      }
      this.factuClient = this.selectedClient;
      if(this.sameAsClient){
        this.client = this.selectedClient;
      }
      this.refreshClientMagasinNom();
    }

    if(event.status === 'success_edit_client') {
      this.editClient = false;
      const response = event.data;
      this.selectedClient = response;
      this.factuClient = this.selectedClient;
      if(this.sameAsClient){
        this.client = this.selectedClient;
      }
      this.refreshClientMagasinNom();
    }
  }

  refreshClientMagasinNom() {
    if(this.selectedClient !== undefined && this.selectedClient !== null) {
      if(this.selectedClient.CMINT !== undefined && this.selectedClient.CMINT !== null && +this.selectedClient.CMINT > 0) {
        const magData = this.listMagasins.find((row) =>  row.CMINT == this.selectedClient.CMINT);
        if(magData !== undefined && magData != null) {
          this.magasinNom = magData.CMRAISOC;
        }
      } else {
        this.magasinNom = undefined;
      }
    }
  }

  searchClients() {
    let searchKey = this.searchForm.get('client_nom').value;

    if(searchKey === undefined || searchKey == null || searchKey === '') {
      return;
    }

    this.loadingService.show();
    let form_data: any = {};

    form_data.NOM = searchKey;
    form_data.IDSOC = Constants.IDSOC;
    form_data.CIINT = this.user.CIINT;
    form_data.paracmint = this.USERCMINT;

    this.clientService.searchClients(form_data).subscribe(
      (response: any) => {
          if(response.success !== undefined) {
            const data = response.data;
            const totalResults: number = response.count;

            if(totalResults === 1) {
              this.selectedClient = data[0];
              this.factuClient = this.selectedClient;
              this.refreshClientMagasinNom();

              if(this.panier !== undefined && this.panier !== null) {
                if(this.flowType === 'bonde_vente') {
                  this.updateBondeVente();
                } else if(this.flowType === 'bonde_commande') {
                  this.updateBonCommande();
                }
              } else {
                this.loadingService.hide();
              }
  
              this.searchForm.get('client_nom').setValue('');
            } else {
              this.loadingService.hide();
              this.searchClientFormSubmitter();
            }
          }
      }, 
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));
      }
    )
  }

  openAdvancedSearch(){
    this.userService.setAdvanceSearchFormData(null);  
    const dialogRef = this.dialog.open(ClientFilterComponent, {
      width: '100vw',
      maxHeight: '80vh',
      data: {
        fetchType: 'new_load',
        dateAct: 'equal'
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {  
        this.userService.setAdvanceSearchFormData(result.form_data);   
        const data = {
          formType: 'advancedSearch',
          filterData: result
        };
        this.searchClientFormSubmitter(data);
      }      
    });
  }
  
  closeAdvancedSearch(){
  }

  openClientHistory() {
    const dialogRef = this.dialog.open(ClientHistoriqueComponent,
      {
        data: {
          IDTIERS: this.selectedClient.IDTIERS,
          IDINSTTIE: this.selectedClient.IDINSTTIE,
          IDSOC: this.selectedClient.IDSOC,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

}
