import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Constants } from 'providers/constants';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject, Subscription } from 'rxjs';
import { BonVenteService } from 'providers/api/bonventeService';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Article } from 'models/articles';
import { Client } from 'models/clients';
import * as moment from 'moment';
import { ArticleStockComponent } from 'pages/article-detail/article-stock/article-stock.component';
import { ArticleDetailComponent } from 'pages/article-detail/article-detail.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import BonPanier, { PanierArticle, PanierClient, PanierInfosfac } from 'models/bonPanier';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { ForcageBondeComponent } from 'pages/create-bon-de-vente/forcage-bonde/forcage-bonde.component';
import { ModifyArticleDetailComponent } from 'pages/create-bon-de-vente/info-complementaries/modify-article-detail/modify-article-detail.component';
import { RemiseDetailComponent } from 'pages/create-bon-de-vente/remise-detail/remise-detail.component';
import { RemiseListComponent } from 'pages/create-bon-de-vente/remise-list/remise-list.component';
import { NumeroCourtComponent } from 'pages/create-bon-de-vente/numero-court/numero-court.component';
import { MagComplementService } from 'providers/api/magComplementService';
import MagComplement from 'models/magComplement';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchArticleComponent } from 'pages/create-bon-de-vente/search-article/search-article.component';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { EmporteeDiffereComponent } from 'pages/create-bon-de-vente/emportee-differe/emportee-differe.component';
import { LivraisonDetailsComponent } from 'pages/create-bon-de-vente/info-livraison-bonde/livraison-details/livraison-details.component';
import { HistoriqueBondeComponent } from 'pages/detail-bon-de-vente/historique-bonde/historique-bonde.component';
import { BonVente } from 'models/bonventes';
import { MotifForcePrixComponent } from 'pages/create-bon-de-vente/motif-force-prix/motif-force-prix.component';
import { LoginService } from 'providers/api/loginService';
import { Magasin } from 'models/magasins';
import { ArticleFilterComponent } from 'pages/article/article-filter/article-filter.component';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import { FacturationAcompteComponent } from 'pages/create-bon-de-vente/facturation-acompte/facturation-acompte.component';
import { VenteArticleGroupsComponent } from 'pages/create-bon-de-vente/vente-article-groups/vente-article-groups.component';
import { ParamDetail } from 'models/paramDetail';
import { UtilService } from 'providers/api/utilService';
import { User } from 'models/users';
import { SearchClientComponent } from 'pages/create-bon-de-vente/search-client/search-client.component';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { CalculateQuantityComponent } from '../create-bon-de-vente/calculate-quantity/calculate-quantity.component';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { CapDecimalPipe } from 'providers/customNumber-pipe';
import { FocusMonitor } from '@angular/cdk/a11y';
import { PdfViewerComponent } from 'pages/pdf-viewer/pdf-viewer.component';
import { DateDePromesseComponent } from 'pages/create-bon-de-vente/date-de-promesse/date-de-promesse.component';
import { ImprimerConfirmationComponent } from 'pages/imprimer-confirmation/imprimer-confirmation.component';
import { DateTimeComponent } from 'pages/create-bon-de-vente/date-time/date-time.component';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import {
  AUTHORIZE_CHANGEMENT_DE_PRIX,
  AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG,
  AUTHORIZE_FORCAGE_VENTE_OU_COMMANDE, AUTHORIZE_REMISE_EN_LIGNE, AUTHORIZE_REMISE_EN_PIED, AUTHORIZE_VALIDATION_BON_DE_COMMANDE,
  HistoryService
} from '../../providers/api/historyService';
import { NonServiceArticlesListComponent } from 'pages/create-bon-de-vente/non-service-articles-list/non-service-articles-list.component';
import { JsonService } from 'providers/api/json.service';
import { StorageService } from 'providers/api/storageService';


@Component({
  selector: 'app-modify-bon-de-vente',
  templateUrl: './modify-bon-de-vente.component.html',
  styleUrls: ['./modify-bon-de-vente.component.scss'],
  providers: [BonVenteService, UtilService, {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}]
})
export class ModifyBonDeVenteComponent implements OnInit, OnDestroy {

  @ViewChild('newArticleCdart', {static: false}) cdartInput: ElementRef;
  @ViewChild('prixVente', {static: false}) newPrixVente: ElementRef;
  @ViewChild('newArticleQty', {static: false}) newArticleQty: ElementRef;
  @ViewChild('typeCde', {static: false}) newTypeCde: MatSelect;
  @ViewChild('newItemForm', {static: false}) newArticleFormEl: NgForm;


  maskOptions = {
    mask: Number,
    scale: 2,
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ['.']
  };

  qtyMaskOptions: any = {
    mask: Number,
    scale: 2,
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ['.']
  };

  user: UserModel;
  USERCMINT: any;

  addBonDeVenteForm: UntypedFormGroup;
  newArticleForm: UntypedFormGroup;
  currentArticle: Article;
  currentArticleStockMag: number | undefined;
  currentArticleStockMagNom: string = "";
  articleGroupForm: any[];

  listBonVenteItems: PanierArticle[] = [];
  listBonVenteArticles: Article[] = [];

  showLoader = false;
  bonvente: any;
  historiqueData: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  article: Article;
  originMagArticle: Article;

  chosenClient: Client;

  chosenFidelite: Client;

  sameAsClient = true;
  chosenLivraisonClient: Client;
  focusClientSearch = false;

  sameAsFacturationClient = true;
  chosenFacturationClient: Client;
  facturationData: PanierInfosfac;

  livraisonData: any;

  bonVenteLineItems: Article[];
  activeLineItem = 0;
  activeItemWefoxStat = false;
  itemDoubleClicked: any;
  itemClickedCount = 0;

  bonNumBon = '*************';
  bonNumBonChanged: boolean = false;
  bonDuDate: string;
  previousPanier: BonPanier;
  previousPanierClient: Client;
  previousFIDClient: Client;
  previousFactureClient: Client;
  previousLivreClient: Client;

  currentUserName = '';

  totalHT: number;
  totalTVA: number;
  totalTTC: number;
  totalDiscount: number;
  totalDiscountPercentage: string;
  totalPrixCaisse: number;

  chosenRemise: any[];
  chosenLineRemise: any[];

  formType: 'saise' | 'modification' = 'saise';

  forcage_text = '';
  forcageType: 'vente' | 'commande';
  commander = false;

  forcage_rules: { label: string, value: string, type: 'vente' | 'commande' }[] = [
    {
      label: this.translateService.instant('kbonerrstock'),
      value: this.translateService.instant('kbonerrstock'),
      type: 'vente'
    },
    {
      label: this.translateService.instant('kbonrecencour'),
      value: this.translateService.instant('kbonrecencour'),
      type: 'vente'
    },

    {
      label: this.translateService.instant('kbonnotfound'),
      value: this.translateService.instant('kbonnotfound'),
      type: 'commande'
    },
    {
      label: this.translateService.instant('kbonnotpastser'),
      value: this.translateService.instant('kbonnotpastser'),
      type: 'commande'
    },
    {
      label: this.translateService.instant('kbonaucoin'),
      value: this.translateService.instant('kbonaucoin'),
      type: 'commande'
    },
    {
      label: this.translateService.instant('kbonomni'),
      value: this.translateService.instant('kbonomni'),
      type: 'commande'
    }
  ];

  chosenForcage: string;

  typeVenteRules: {title: string, data: { label: string, value: string, paramKey: string, disabled?: boolean }[]};

  bonVenteComments = '';
  magComplementData: MagComplement;

  previousTranporteurLabel: string;
  transporteurLabel: string;

  isMobile: boolean;

  emporteDiffere = false;
  livraisonBondeVente = false;
  promesseAvail = false;

  prixLivraison: number;

  listMagasins: Magasin[];

  bonVenteSelectedTab: number;
  advancedDialogRef: any;

  popupCloseSusscription: Subscription;

  flowType = 'bonde_vente';
  // mat-expansion-panel
  step = 1;

  // Article d'options
  listArticleGroups: any[];
  articleGroupsRawData: any[];
  currentArticleChosenGroups: any;

  // Stock Autre or User Params List
  paramsList: ParamDetail[];
  // Only User Params List (Backup)
  userParamsList: ParamDetail[];

  userPermission: any;
  readOnly = false;

  validationButtonAccess = false;
  forcageButtonAccess = false;

  PU_vente_changable = false;
  authorizedLinePrixUser: User;
  authorizedForcageUser: User;
  authorizedValidationCommandeUser: User;
  authorizedTotalRemiseUser: User;
  authorizedLineRemiseUser: User;
  authorizedArticleStockMagUser: User;

  // Note Counter
  totalNotes = 0;

  // Previous Devis NUMBON
  sourceDevisNumbon: string;

  qtyLength = 0;
  qtyWidth = 0;
  qtyHeight = 0;

  livraisonDialogRef: MatDialogRef<LivraisonDetailsComponent>;

  codeClient: any;

  bonApiSubscription: Subscription;

  livraisonAlertStatus = false;
  quantity5Decimal = false;
  quantityDisabled = false;

  livraisonTotale: boolean;

  emailParamData: string;
  emailMandatory = false;

  bonMag: number;

  magasinNom: string = "";

  linkedStatus: 'service' | 'bundle' | 'no_link' = 'no_link';
  isWefoxArticle: boolean = false;
  bonVenteModifyColumns: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public snackBar: MatSnackBar,
              private formBuilder: UntypedFormBuilder,
              private userService: UserService,
              private bonVenteService: BonVenteService,
              private loginService: LoginService,
              private magComplementService: MagComplementService,
              private loadingService: NgxSpinnerService,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private utilService: UtilService,
              private dialog: MatDialog,
              private currencyPipe: CapCurrencyPipe,
              private decimalPipe: CapDecimalPipe,
              private deviceDetector: DeviceDetectorService,
              private focusMonitor: FocusMonitor,
              private historyService: HistoryService,
              public jsonService: JsonService,
              private storageService: StorageService) {
                this.popupCloseSusscription = this.userService.popupClose.subscribe(closeAction => {
                  if (closeAction == false) {
                     this.closeAdvancedSearch();
                  }
                });
    }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
    this.userService.headerSearchButton.next(false);

    this.route.data.subscribe(
      (routeData: any) => {
        if (routeData !== undefined && routeData !== null && routeData.readOnly !== undefined && routeData.readOnly !== null) {
          this.readOnly = routeData.readOnly;
        } else {
          this.readOnly = false;
        }
      }
    );

    this.jsonService.getAppConfig().subscribe((res: any) => {
      this.bonVenteModifyColumns = res.bonVente.modify.columns.sort((a, b) => a.order - b.order);
      this.bonVenteModifyColumns = this.bonVenteModifyColumns.filter(col => col.display);
      }, 
      (error) => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );

    this._getCodeClient();

    this.addBonDeVenteForm = this.formBuilder.group({
      CMINT: ['', Validators.required],
      IDSOC: [Constants.IDSOC, Validators.required],
    });

    this.newArticleForm = this.formBuilder.group({
      CDART: ['', [Validators.required]],
      CALIB1: [{ value: '', disabled: true }, Validators.required],
      QTE: [{ value: '1', disabled: true }, [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      CAPRIX: [{ value: '', disabled: true }, Validators.required],
      NEW_PRIX: [{ value: '0', disabled: true }, [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      ROW_TOTAL: [{ value: '', disabled: true }, Validators.required],
      TYPECDE: [{ value: '', disabled: true }, Validators.required],
      MOTANT: [{ value: '0', disabled: true }, Validators.required],
      STOCKDISPO: [{ value: '', disabled: true }],

      PRIX_FORCE: [false],
      MOTIF_PRIX: [''],
      MOTIF_PRIX_LBL: [''],

      GROUP_AVAIL: [false]
    });

    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.addBonDeVenteForm.patchValue({ CMINT: this.USERCMINT });

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }
      },
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };

    this.loadDependencies();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.isMobile && this.cdartInput) {
        this.cdartInput.nativeElement.focus();
        this.cdartInput.nativeElement.select();
        this.cdartInput.nativeElement.setAttribute('inputmode', 'text');
      }
    }, 100)
  }

  async _getCodeClient() {
    try {
      this.codeClient = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
    } catch (err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  ngOnDestroy() {
    this.__clearPageVars();
    this.popupCloseSusscription.unsubscribe();
    if (this.bonApiSubscription) {
      this.bonApiSubscription.unsubscribe();
    }
  }

  async loadDependencies() {
    this.loadingService.show();

    this.formType = 'modification';

    const routeParams = this.route.snapshot.params;
    const numBon = routeParams['numbon'];

    const magasin = routeParams['magasin'];
    this.bonMag = magasin;

    if (numBon !== undefined && numBon != null && numBon !== '') {
      this.bonNumBon = numBon;
      await this.getMagasins();

      await this.getListParams();
      await this.refreshParamBasedDetail();
      await this.getLivraisonAlertStatus();

      await this.getBonDeVenteByNumBon(this.bonNumBon);

      const countData = {
        NumBon: this.bonNumBon,
        IDSOC: Constants.IDSOC,
        mag: this.previousPanier.mag
      };

      this.getNoteCount(countData);
      this.getMagComplementData();
    } else {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kboninvalidreq'));
      this.router.navigate(['/bon-de-vente']);
    }

    this.PU_vente_changable = true;
    if (!this.userPermission['0_17']) {
      this.PU_vente_changable = false;
    }

    this.forcageButtonAccess = true;
    if (!this.userPermission['4_3']) {
      this.forcageButtonAccess = false;
    }

    this.validationButtonAccess = true;
    if (!this.userPermission['4_4']) {
      this.validationButtonAccess = false;
    }

    this.newArticleForm.get('CDART').valueChanges.subscribe(
      (value) => {
        if (value === '' || value === null) {
          this.currentArticle = undefined;
          this.originMagArticle = undefined;

          this._restrictQtyDecimal(this.currentArticle);

          this.newArticleForm.patchValue({
            CALIB1: '',
            CAPRIX: null,
            QTE: '1',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: null,
            TYPECDE: '',
            STOCKDISPO: '',
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;

          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
          this.forcage_text = '';
          this.commander = false;
        }
      }
    );
  }

  async refreshParamBasedDetail() {
    await this.getQuantityDecimalStatus();
    this.emailParamData = await this.userService.getRuleParamsData(20, 54, 'VSTR1');
  }

  get quantityMask() {
    return this.qtyMaskOptions;
  }

  get viewDevisDetail() {
    return this.sourceDevisNumbon && this.sourceDevisNumbon !== '' && this.userPermission['24_0'];
  }

  _checkEmailMandatory() {
    this.emailMandatory = false;
    if (this.emailParamData) {
      const splitUp = this.emailParamData.split(';');
      this.emailMandatory = this.previousPanier.articles.some(r => splitUp.indexOf(r.typecde) > -1);
    }
  }

  async onLivraisonTotaleStatusChange(status: boolean) {
    if (status === true) {
      const confirmation = await this.livTotaleConfirmation();
      if (confirmation && confirmation === 'OK') {
        this.updateLivraisonTotaleWithPanier(status);
      } else {
        this.livraisonTotale = !status; // Reset the Livraison Totale slide-toggle field if not confirmed/cancelled.
      }
    } else {
      this.updateLivraisonTotaleWithPanier(status);
    }
  }

  updateLivraisonTotaleWithPanier(status: boolean) {
    this.loadingService.show();

    // Replace all BL to E2/BL if any E2/BL already found. (Only if Livtottale checked true)
    const article_data = this._updatePanierArticlesLivTotale(this.previousPanier.articles);

    const panierData = {
      ...this.previousPanier,
      articles: article_data,
      livtotale: status // Updating the Livraison Totale property
    };

    const venteData = {
      ...panierData,
      editinfo: {
        pMag: this.USERCMINT,
        pNumBon: this.bonNumBon
      },
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST
    };

    this.editBonDeVente(venteData);
  }

  livTotaleConfirmation(): Promise<any> {
    const message = `<h1 class="text-left mb-3">${this.translateService.instant('kconfirmlivraisontotale')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message
      }
    });

    return dialogRef.afterClosed().toPromise();
  }

  _updatePanierArticlesLivTotale(article_data: PanierArticle[]) {
    if (this.livraisonTotale) {
      let hasE2BL = false;
      let hasE2ED = false;
  
      article_data.forEach((row) => {
        if (row.typecde === 'E2/BL') {
          hasE2BL = true;
        } else if (row.typecde === 'E2/ED') {
          hasE2ED = true;
        }
      });
  
      if (hasE2BL || hasE2ED) {
        article_data = article_data.map((row) => {
          if (hasE2BL && row.typecde === 'BL') {
            row.typecde = 'E2/BL';
          } else if (hasE2ED && row.typecde === 'ED') {
            row.typecde = 'E2/ED';
          }
          return row;
        });
      }
    }

    return article_data;
  }

  async getQuantityDecimalStatus() {
    try {
      const paramData = await this.userService.getRuleParamsData(10, 12, 'VBOOL1');
      this.quantity5Decimal = false;

      this.qtyMaskOptions = {
        mask: Number,
        scale: 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ['.']
      };

      if (paramData !== undefined && paramData != null && paramData == '-1') {
          this.quantity5Decimal = true;

          this.qtyMaskOptions = {
            mask: Number,
            scale: 5,
            padFractionalZeros: true,
            radix: this.utilService.getCurrentDecimalSymbol,
            mapToRadix: ['.']
          };
      }
    } catch (err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  async getLivraisonAlertStatus() {
    try {
      this.livraisonAlertStatus = false;
      const paramData = await this.userService.getRuleParamsData(20, 21, 'VBOOL2');

      if (paramData !== undefined && paramData != null) {
          this.livraisonAlertStatus = paramData == '-1' ? true : false;
      }
    } catch (err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  onLivraisonTrackingChanged(event: boolean) {
    if (event === true) {
      console.log('Tracking Changed !');
      this.getBonDeVenteByNumBon(this.bonNumBon, true).then((res) => {
        this.livraisonData = {
          dtliv: new Date(this.previousPanier.dtliv),
          dtlivsouhait: new Date(this.previousPanier.dtlivsouhait),
          livjour: this.previousPanier.livjour,
          livconfirm: this.previousPanier.livconfirm,
          livinfo1: this.previousPanier.livinfo1,
          livinfo2: this.previousPanier.livinfo2,
          livdeport: this.previousPanier.livdeport,
          livdirect: this.previousPanier.livdirect,

          transporteurContent: this.previousPanier.transporteur,
          transporteurLabel: this.transporteurLabel
        };

        if (this.livraisonDialogRef.componentInstance?.livraisonDetailsChanged) {
          this.livraisonDialogRef.componentInstance?.livraisonDetailsChanged.next(this.livraisonData);
        }
      }).catch(() => {
        this.loadingService.hide();
      });
    }
  }

  panierUpdate = (panier: BonPanier) => {
    if (panier !== undefined && panier !== null) {
      this.previousPanier = panier;
      this.listBonVenteItems = this.previousPanier.articles;
    }
  };

  setActiveItem(i: number) {
    this.activeLineItem = i;
  }

  refreshByPanier(panier: BonPanier) {
    if (panier && panier !== null) {
      this.chosenLineRemise = undefined;
      this.chosenRemise = undefined;

      this.sourceDevisNumbon = panier.numdevis;

      this.currentUserName = panier.libuser;

      this.listBonVenteItems = panier.articles;

      this.livraisonTotale = panier.livtotale;

      this._checkEmailMandatory();

      // Reset Stock Magasin
      this.currentArticleStockMag = undefined;
      this.currentArticleStockMagNom = undefined;

      // Check Stock Magasin
      if (panier.magstock) {
        this.currentArticleStockMag = panier.magstock;

        // Getting the Magsin Nom
        const currentBonStockMag = this.listMagasins.find((row) => +row.CMINT == this.currentArticleStockMag)
        if(currentBonStockMag) {
          this.currentArticleStockMagNom = currentBonStockMag.CMRAISOC;
        }

        // Getting the Magasin Nom
        const currentBonMag = this.listMagasins.find((row) => +row.CMINT == panier.mag);
        if(currentBonMag) {
          this.magasinNom = currentBonMag.CMRAISOC;
        }
      }

      if (panier.client !== undefined && panier.client !== null) {
        this.chosenClient = this.previousPanierClient;
      }

      if (panier.numfid !== undefined && panier.numfid !== null && panier.numfid !== '') {
        this.chosenFidelite = this.previousFIDClient;
      }

      if (panier.clientfacture !== undefined && panier.clientfacture !== null && panier.clientfacture !== '') {
        this.sameAsFacturationClient = false;
        this.chosenFacturationClient = this.previousFactureClient;
      }

      if (panier.clientlivre !== undefined && panier.clientlivre !== null && panier.clientlivre !== '') {
        this.sameAsClient = false;
        this.chosenLivraisonClient = this.previousLivreClient;
      }

      if (panier.transporteur !== '' && panier.transporteur !== null) {
        this.transporteurLabel = this.previousTranporteurLabel;
      }

      this.prixLivraison = 0;
      this.livraisonBondeVente = false;
      this.emporteDiffere = false;
      this.promesseAvail = false;
      panier?.articles?.map(
        (row) => {

          const articleData = this.listBonVenteArticles?.find((item) => item.CDART == row.cdart);
          if (articleData && articleData.CAOKLIV == '-1') {
            this.prixLivraison += row.quantite * row.prix - row.remlig - row.remtot;
          }

          if (row.typecde === 'BL') {
            this.livraisonBondeVente = true;
          }

          if (row.typecde === 'ED') {
            this.emporteDiffere = true;
          }

          if (row.typecde === 'E2/ED' || row.typecde === 'E2/BL') {
            this.promesseAvail = true;
          }
        }
      );

      this.facturationData = panier.infosfac;

      this.totalHT = panier.montantHT;
      this.totalTTC = panier.montant;
      this.totalTVA = panier.montant - panier.montantHT;
      this.totalDiscount = panier.remtot;

      this.totalPrixCaisse = panier.montant + panier.remtot;

      if (panier.datarem !== '' && panier.datarem !== null) {
        this.totalDiscountPercentage = panier.remise.toString();
      } else {
        if (panier.remise && panier.remise > 0) {
          this.totalDiscountPercentage = panier.remise.toString();
        } else {
          this.totalDiscountPercentage = '0';
        }
      }

      this.bonVenteComments = panier.comm;

      this.activeLineItem = 0;
      if(panier.articles && panier.articles.length > 0) {
        const currentItem = panier?.articles[this.activeLineItem];
        this.article = this.listBonVenteArticles?.find(row => row.CDART == currentItem?.cdart);

        const checkWefox = this.checkArticleWefoxStatus(this.article.CDART);
        this.activeItemWefoxStat = checkWefox.wefoxStat;
      }

      this.bonDuDate = moment(panier.dtsaisie).utcOffset(panier.dtsaisie).format('DD.MM.YYYY HH:mm');
    }
  }

  checkArticleWefoxStatus(CDART: string, processedRules?: any): { wefoxStat: boolean, wefoxTypeRestriction: boolean, wefoxType: string } {
    if (processedRules === undefined || processedRules === null) {
      processedRules = this.userService.processRulesParams(this.paramsList);
    }

    const result = {
      wefoxStat: false,
      wefoxTypeRestriction: true,
      wefoxType: ''
    };

    if (processedRules['20_61']['VBOOL1'] == '-1') {
      if (CDART.toLowerCase() === processedRules['20_61']['VSTR1'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR2'] !== '' && processedRules['20_61']['VSTR2'] != null) ? processedRules['20_61']['VSTR2']:'';

        result.wefoxTypeRestriction = true;
      }

      if (CDART.toLowerCase() === processedRules['20_61']['VSTR3'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR4'] !== '' && processedRules['20_61']['VSTR4'] != null) ? processedRules['20_61']['VSTR4']:'';

        result.wefoxTypeRestriction = true;
      }
    }

    return result;
  }

  viewSourceDevis(): void {
    if (!this.sourceDevisNumbon || this.sourceDevisNumbon === '') { return; }

    this.router.navigate(['/devis-detail/', this.sourceDevisNumbon]);
    return;
  }

  moveToInfoTab(currentIndex: number) {
    this.setActiveItem(currentIndex);

    // To Expand Info. tab
    this.step = 6;

    // Update variables based on the selected article line item
    const currentItem = this.previousPanier?.articles[this.activeLineItem];
    this.article = this.listBonVenteArticles?.find(row => row.CDART == currentItem?.cdart);

    const checkWefox = this.checkArticleWefoxStatus(this.article.CDART);
    this.activeItemWefoxStat = checkWefox.wefoxStat;

    // Reset the swipe back to normal state
    this.__resetPreviousSwipes('.mat-list-item');
  }

  loadArticleItem(item: PanierArticle) {
    this.itemClickedCount++;

    setTimeout(() => {
      if (this.itemClickedCount === 1) {
        this.article = this.listBonVenteArticles?.find(row => row.CDART == item.cdart);

        const checkWefox = this.checkArticleWefoxStatus(this.article.CDART);
        this.activeItemWefoxStat = checkWefox.wefoxStat;

        this.dialog.closeAll();
        if (this.isMobile) {
          this.openUpArticleDetail(this.article);
        }
      } else if (this.itemClickedCount === 2) {
        this.dialog.closeAll();
        if (!this.readOnly) {
          this.editBonVenteItem(item);
        }
      }
      this.itemClickedCount = 0;
    }, 250);
  }

  onClickRow(i: number, item: PanierArticle) {
    this.setActiveItem(i);
    this.loadArticleItem(item)
  }

  loadArticleDetail(item: PanierArticle, event) {
    event.stopPropagation();
    this.article = this.listBonVenteArticles.find(row => row.CDART == item.cdart);
    this.dialog.closeAll();
    this.openUpArticleDetail(this.article);
  }

  loadEditArticleItem(item: PanierArticle, event) {
      event.stopPropagation();
      this.dialog.closeAll();

      if (this.readOnly) { return; }

      this.editBonVenteItem(item);
  }

  async editBonVenteItem(item: PanierArticle) {
    if (this.previousPanier == undefined || this.previousPanier == null || this.listBonVenteArticles == undefined || this.listBonVenteArticles == null || item == undefined || item == null) {
      return;
    }

    let authorizedModifyItemUser: User | undefined;

    if (this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21']) {
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '21', this.user.CIINT, this.bonNumBon);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      authorizedModifyItemUser = authorizationResult.data;
    }

    const article_data = this.listBonVenteArticles.find(
      (row) => row.CDART == item.cdart
    );

    const listNonServiceArticles = this.listBonVenteArticles.filter((row) => row.CAGESTOCK == '-1');

    const cdartArticlesHashTable = [...listNonServiceArticles].reduce((acc, it) => {
      if (!acc.hasOwnProperty(it.CDART)) {
        acc[it.CDART] = {};
      }

      acc[it.CDART] = it;
      return acc;
    }, {});

    const listNonServiceItems = this.listBonVenteItems.filter(rw => {
      return rw.opt <= 0 && (cdartArticlesHashTable[rw.cdart] && cdartArticlesHashTable[rw.cdart].CAGESTOCK == '-1');
    });

    const dialogRef = this.dialog.open(ModifyArticleDetailComponent, {
      width: '50vw',
      maxWidth: '55vw',
      height: this.isMobile ? '80vh' : '85vh',
      autoFocus: false,
      data: {
        panier: this.previousPanier,
        bonNumBon: this.bonNumBon,
        panier_article: item,
        article_data: article_data,
        listBonVenteArticles: this.listBonVenteArticles,
        CMINT: this.USERCMINT,
        authorizedModifyItemUser,
        listNonServiceArticles,
        listNonServiceItems,
        cdartArticlesHashTable,
        isBonDeVente: true,
      }
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result && result.status === 'success') {
        const NumBon = result.numBon;

        if (NumBon !== this.bonNumBon) {
          // Maintaining a variable to track NumBon changes
          // by which, we decide whether the route can be changed or not
          this.bonNumBonChanged = true;

          await this.router.navigate(['/modify-bon-de-vente', NumBon, this.previousPanier.mag], { replaceUrl: true })

          this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
          this.storageService.setItem('previousBonPanier', this.previousPanier);
          this.storageService.setItem('previousNumBon', this.bonNumBon);
        }

        this.bonNumBon = NumBon;
        this.previousPanier = result.panier;

        if (result.customArticles !== undefined && result.customArticles != null) {
          this.listBonVenteArticles = result.customArticles;
        }

        this.refreshByPanier(this.previousPanier);
      }
    });
  }

  getCdartWithPlacehold(CDART: string): string {
    return (Constants.CONFORAMA_CDART_PLACEHOLDER).replace('{{CDART_PLACEHOLDER}}', CDART);
  }

  getImageURL(CDART: string): string {
    if (this.codeClient && this.codeClient.toLowerCase() === 'conforama') {
      // To generate Conforama Image URL Algorithm
      let concatenated = '';
      const length = Constants.CONFORMA_IMAGE_SEGMENT_LENGTH;
      for (let i = 0; i < length; i++) {
        if (i < (length - 1)) {
          if (concatenated !== '') {
            concatenated += '/'
          }
          if(CDART[i] !== undefined) {
            concatenated += (CDART[i].padEnd(length - i, '0'));
          } else {
            concatenated += ('0'.padEnd(length - i, '0')); // Prefixing Zero if the CDART length is small as mentioned in CAP-1056
          }
        }
      }

      const URL = Constants.CONFORAMA_IMAGE_URL + concatenated + '/' + this.getCdartWithPlacehold(CDART) + Constants.DEFAULT_IMAGE_EXTENSION;
      return URL;
    }

    return Constants.BASE_IMAGE_URL + CDART + Constants.DEFAULT_IMAGE_EXTENSION;
  }

  async imprimerConfirmCheckboxPopup() {
    if (!this.previousPanier.client || (this.previousPanier.client && (this.previousPanier.client.email == null || this.previousPanier.client.email === ''))) {
      this.imprimerBondeVente();
      return;
    }

    const dialogRef = this.dialog.open(ImprimerConfirmationComponent, {
      width: '60vw',
      data: {
        panier: this.previousPanier,
        CMINT: this.USERCMINT,
        flowType: this.flowType
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== undefined && result !== null && result.status === 'success') {
        console.log(result);
        const editBon = result.data.EDIT_BON;
        const sendBon = result.data.SEND_BON;

        if (sendBon) {
          await this.insertBatchReport();
          await this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmsgenvoye')).toPromise();
        }

        if (editBon) {
          this.imprimerBondeVente();
        }
      }
    });
  }

  insertBatchReport(): Promise<any> {
    this.loadingService.show();
    return new Promise((resolve, reject) => {
      const data = {
        BRACTION: Constants.BRACTION_VENTE,
        DTREPORT: moment().format('YYYY-MM-DD HH:mm:ss'),
        CIINT: this.user.CIINT,
        BRINT1: Constants.IDSOC, // IDSOC
        BRINT2: this.USERCMINT, // CMINT
        BRSTR1: this.bonNumBon,
        TRAITE: 0
      };

      this.bonVenteService.createBatchReport(data).subscribe(
        (resp) => {
          this.loadingService.hide();
          resolve(resp);
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    })
  }

  imprimerBondeVente() {
    this.loadingService.show();
    this.__printBonVente({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pImprimante: 'download' });
  }

  __printBonVente(bondeVenteData: { pMag: any, pNumBon: string, pImprimante: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.imprimerBonVente(bondeVenteData).subscribe(
        (resp) => {
          this.loadingService.hide();

          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {
              const fileUrl = resp.basePath + resp.data.Fichier; // 'Bon_2721162009080_122446557.pdf'

              this.bonVenteService.downloadFile(fileUrl).subscribe(
                (_down_res) => {
                  this.openUpPdfViewer({ url: fileUrl });
                },
                error => {
                  console.error('Error while downloading the file', error);
                  this.dialogService.prompt(this.translateService.instant('kfailure'), error.message);
                }
              );
              resolve();
            } else {
              reject();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {
            reject();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject();
        }
      );
    })
  }

  openUpPdfViewer(data: any) {
    const dialogRef = this.dialog.open(PdfViewerComponent,
      {
        height: this.isMobile ? '100%' : undefined,
        data: {
          ...data
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  newArticleFormSubmitter() {
    if (this.bonNumBon === undefined || this.bonNumBon === null) {
      this.router.navigate(['/bon-de-vente']);
      return;
    }

    if (this.previousPanier === undefined || this.previousPanier === null) {
      this.router.navigate(['/bon-de-vente']);
      return;
    }

    if (this.currentArticle === undefined || this.currentArticle === null) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const form_data = this.newArticleForm.getRawValue();

    if (form_data.CDART === '' || form_data.CDART === null || form_data.TYPECDE === '' || form_data.TYPECDE === null || form_data.QTE === '' || form_data.QTE === null || +form_data.QTE === 0) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const processedRules = this.userService.processRulesParams(this.paramsList);
    const checkWefox = this.checkArticleWefoxStatus(form_data.CDART.toLowerCase(), processedRules);
    this.isWefoxArticle = checkWefox.wefoxStat;

    if ((form_data.NEW_PRIX === '' || form_data.NEW_PRIX === null || +form_data.NEW_PRIX === 0) &&
      !(checkWefox.wefoxStat) &&
       !this.userPermission['0_22']) {
      this.newPrixVente.nativeElement.select();
      this.newPrixVente.nativeElement.focus();

      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kboninvalid'));
      return;
    }

    const maxAllowedQte = (processedRules['10_12']['VFLOAT1'] !== undefined && processedRules['10_12']['VFLOAT1'] !== null && processedRules['10_12']['VFLOAT1'] !== '') ? +processedRules['10_12']['VFLOAT1'] : 0;
    if (maxAllowedQte > 0 && +form_data.QTE > maxAllowedQte) {
      this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
      this.newArticleQty.nativeElement.select();
      this.newArticleQty.nativeElement.focus();
      setTimeout(() => {
        this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
      }, 50);

      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntytrop'), this.translateService.instant('kuok'));
      return;
    }

    if ((form_data.TYPECDE === 'EI' || form_data.TYPECDE === 'ED' || form_data.TYPECDE === 'BL') &&
      (this.chosenForcage === undefined || this.chosenForcage === null) &&
      !isNaN(form_data.STOCKDISPO) &&
        +form_data.STOCKDISPO < +form_data.QTE) {
          this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
          this.newArticleQty.nativeElement.focus();
          this.newArticleQty.nativeElement.select();
          setTimeout(() => {
            this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
          }, 50);
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('katystockact'));
        return;
    }

    if (this.newArticleForm.get('NEW_PRIX').touched && !this.newArticleForm.get('NEW_PRIX').pristine && this.newArticleForm.get('NEW_PRIX').dirty && (+form_data.CAPRIX !== +this.currentArticle.calculated_price)) {
      this.openUpPrixForceMotif();
      return;
    }

    this.addNewLineItem();
  }

  async addNewLineItem() {
    const form_data = this.newArticleForm.getRawValue();

    if (this.currentArticleStockMag && +this.originMagArticle.STOCKDISPO > 0 && !this.userPermission['0_23']) {
      this.loadingService.hide();
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kstock_disponible_votre_magasin')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '23', this.user.CIINT, '');

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.loadingService.show();
      this.authorizedArticleStockMagUser = authorizationResult.data;
    }

    if (form_data.GROUP_AVAIL || +this.currentArticle.GROUP_COUNT > 0) {
      this.searchArticleGroupsCAINT(this.currentArticle.CAINT, form_data.TYPECDE);
      return;
    }

    this.loadingService.show();
    this.__newLineItemAddLogic();
  }

  async __newLineItemAddLogic(type: 'normal' | 'options' = 'normal') {
    const form_data = this.newArticleForm.getRawValue();
    const processedRules = this.userService.processRulesParams(this.paramsList);

    if (type === 'options') {
      // Check All Bons (Normal bons/Bons with autre mag) whether
      // All the Child options are having stock more than 0 or not
      // if not more than 0, display an alert and stop the execution instead of continuing.
      const notAvailChildCdartList = [];
      const someChildNotHaveStockAvail = this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
        return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
          return _row_option.SELECTED && _row_option.CAGE_STOCK != '0' && (!_row_option.STOCK_DISPO || +_row_option.STOCK_DISPO <= 0);
        });
      });

      this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
        return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
          if(_row_option.SELECTED && _row_option.CAGE_STOCK != '0' && (!_row_option.STOCK_DISPO || +_row_option.STOCK_DISPO <= 0)) {
            notAvailChildCdartList.push(_row_option.CDART);
          }
        });
      });

      if (someChildNotHaveStockAvail && (this.currentArticleStockMag || form_data.TYPECDE.indexOf('E2') < 0) && (this.currentArticleStockMag || form_data.TYPECDE.indexOf('E2') < 0) && (!processedRules['20_6'] || (processedRules['20_6']['VBOOL3'] != '-1' && processedRules['20_6']['VBOOL4'] != '-1'))) {
        this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
        this.newArticleQty.nativeElement.focus();
        this.newArticleQty.nativeElement.select();
        setTimeout(() => {
          this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
        }, 50);

        const message = `${this.translateService.instant('katystockact')} ${notAvailChildCdartList ? notAvailChildCdartList.join(', ') : ''}`;
        this.dialogService.prompt(this.translateService.instant('kuerror'), message);

        this.loadingService.hide();
        return;
      }

      if (this.currentArticleStockMag) {
        const someChildHaveStockMoreThanZero = this.currentArticleChosenGroups.GROUPS.some((_row_group: any) => {
          return _row_group.GROUP_CHOSEN_OPTIONS.some((_row_option: any) => {
            return _row_option.SELECTED && _row_option.CAGE_STOCK != '0' && (_row_option.STOCK_DISPO && +_row_option.STOCK_DISPO > 0);
          });
        });

        if (!this.authorizedArticleStockMagUser && someChildHaveStockMoreThanZero && !this.userPermission['0_23']) {
          this.loadingService.hide();
          await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kstock_disponible_votre_magasin')).toPromise();
          const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '23', this.user.CIINT, '');

          if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
            return;
          }

          this.loadingService.show();
          this.authorizedArticleStockMagUser = authorizationResult.data;
        }
      }
    }

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
    const row_tax = (+form_data.ROW_TOTAL) * 0.021;

    let grand_total_amount = 0;

    let client_data: PanierClient;

    if (this.chosenClient && this.chosenClient !== null) {
      client_data = {
        mag: +this.chosenClient.CMINT,
        civil: +this.chosenClient.IDCIV,
        nom: this.chosenClient.TRAISOC,
        prenom: this.chosenClient.TPRENOM,
        adr1: this.chosenClient.TADR1,
        adr2: this.chosenClient.TADR2,
        adr3: this.chosenClient.TADR3,
        adr4: this.chosenClient.TADR4,
        codpos: this.chosenClient.CODPOS,
        ville: this.chosenClient.TVILLE,
        pays: this.chosenClient.CODPAYS,
        tel: this.chosenClient.TTEL,
        fax: this.chosenClient.TFAX,
        gsm: this.chosenClient.TPORT,
        email: this.chosenClient.TEMAIL,
        idinstcli: +Constants.CIINST,
        idcli: +this.chosenClient.IDTIERS
      }
    }

    if (this.previousPanier !== undefined && this.previousPanier !== null && this.previousPanier.client !== undefined && this.previousPanier.client !== null) {
      client_data = this.previousPanier.client;
    }

    let PRIX_UPV = form_data.NEW_PRIX;
    let PRIX_PVT = form_data.NEW_PRIX * form_data.QTE;

    let lineDataRem = '';
    let lineRemTotal = 0.0;
    let lineRemPercentage = 0.0;
    let totalLineRemTotal = 0;
    let lineRemTot = 0;

    let dtlib3 = '1899-12-30T00:00:00.000+02:00';
    let strlib2 = '',
        strlib3 = '',
        dtlib4 = '1899-12-30T00:00:00.000+02:00';
    const checkWefox = this.checkArticleWefoxStatus(this.currentArticle.CDART);

    if (checkWefox.wefoxStat) {
      this.loadingService.hide();
      const dateLib3Res = await this.openUpDateLib3Popup().toPromise();
      if (dateLib3Res !== undefined && dateLib3Res !== null && dateLib3Res.status == 'success') {
        dtlib3 = this.utilService.formatDateToWS(dateLib3Res.data.dtlib3);
        strlib2 = dateLib3Res.data.strlib2;
        strlib3 = dateLib3Res.data.strlib3;

        if (dateLib3Res.data.dtlib4 != null && dateLib3Res.data.dtlib4 != '') {
          dtlib4 = dateLib3Res.data.dtlib4;  
        }
      } else {
        return;
      }
      this.loadingService.show();
    }

    if (this.chosenLineRemise !== undefined && this.chosenLineRemise !== null) {
      if (this.chosenLineRemise.length === 0) {
        // Remise 0
        lineRemPercentage = 0.0;
        lineRemTotal = 0.0;
        lineDataRem = '';
        totalLineRemTotal = 0;
        lineRemTot = 0;
      } else {
        const remFormat: string[] = [];
        for (let j = 0; j < this.chosenLineRemise.length; j++) {
          const currentLineRemPercentage = +this.chosenLineRemise[j].REMMTAUX;
          lineRemPercentage += currentLineRemPercentage;
          lineRemTotal += form_data.CAPRIX * (currentLineRemPercentage / 100);
          remFormat.push(this.chosenLineRemise[j].REMMCODE + '#' + (+currentLineRemPercentage) + '#' + this.utilService.formatMaskCompat(lineRemTotal));
        }
        lineDataRem = remFormat.join(';');

        PRIX_UPV = form_data.CAPRIX - lineRemTotal;
        PRIX_PVT = PRIX_UPV * +form_data.QTE;

        totalLineRemTotal = lineRemTotal * +form_data.QTE;
      }
    }

    // for Remise History
    const currentCdart = this.currentArticle.CDART;
    const oldPrix = parseFloat(form_data.CAPRIX.toString());
    const newPrix = form_data.NEW_PRIX.toString();

    let article_data: PanierArticle[] = [];
    let currentOpt = 0;
    let currentIdLigne = 1;
    article_data = this.previousPanier.articles;

    if (type === 'options' || form_data.GROUP_AVAIL || +this.currentArticle.GROUP_COUNT > 0) {
      this.previousPanier.articles.map((_item_art: PanierArticle) => {
        if (_item_art.opt < 0) {
          currentOpt = _item_art.opt;
        }
      });

      currentOpt = currentOpt - 1;
    }

    let description = '';
    if (this.currentArticle.CAUNVTE === 'M3') {
      description = `L ${this.qtyLength} x l ${this.qtyWidth} x h ${this.qtyHeight}`;
    }

    currentIdLigne = article_data.length + 1;

    article_data.push({
      idlig: currentIdLigne,
      cdart: this.currentArticle.CDART,
      quantite: parseFloat(form_data.QTE.toString()),
      prix: parseFloat(form_data.CAPRIX.toString()), // Article prix
      prixforce: form_data.PRIX_FORCE,
      motifprixforce: (form_data.MOTIF_PRIX !== null) ? form_data.MOTIF_PRIX : '',
      commlig1: description,
      commlig2: '',
      prixachatHT: 0.0,
      prixachatTTC: 0.0,
      tauxTVA: '', // Optional
      typecde: form_data.TYPECDE,
      remlig: parseFloat(totalLineRemTotal.toString()), // Total remise amount of the row
      remtot: parseFloat(lineRemTot.toString()),
      datafid: '',
      datafidaco: '',
      dataopeco: '' ,
      datarem: lineDataRem,
      okdiff: false,
      bundle: 0,
      opt: currentOpt,
      lib: this.currentArticle.CALIB3,
      idinstuser: +this.user.IDINSTINT,
      iduser: +this.user.CIINT,
      libuser: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.currentUserName,
      dtpromesse: current_date, // 2018-03-27 10:04:41
      dtlib3: dtlib3,
      dtlib4: dtlib4,
      strlib2: strlib2,
      strlib3: strlib3,
      coinsolde: false,
      expo: false,
      motifcde: (this.chosenForcage !== undefined && this.chosenForcage !== null) ? this.chosenForcage : '',
      numcde: '',
      prixupv: parseFloat(form_data.NEW_PRIX.toString()), // New Price (Typeable) => PU Ven.
      montant: parseFloat((form_data.NEW_PRIX * form_data.QTE).toString()), // Row Subtotal - PV Total
      remise: lineRemPercentage,
      emplacement: '',
      statut: this.currentArticle.CAECODE === 'A' ? -1 : 0,
      dtmaj: current_date
    });

    if (type === 'options') {
      currentOpt = Math.abs(currentOpt);

      this.currentArticleChosenGroups.GROUPS.map((_row_group: any) => {
        _row_group.GROUP_CHOSEN_OPTIONS.map((_row_option: any) => {
          if (_row_option.SELECTED) {
            currentIdLigne = currentIdLigne + 1;

            article_data.push({
              idlig: currentIdLigne,
              cdart: _row_option.CDART,
              quantite: parseFloat(form_data.QTE.toString()),
              prix: parseFloat(_row_option.PRIX.toString()), // Article prix
              prixforce: false,
              motifprixforce: '',
              commlig1: '',
              commlig2: '',
              prixachatHT: 0.0,
              prixachatTTC: 0.0,
              tauxTVA: '', // Optional
              typecde: form_data.TYPECDE,
              remlig: 0, // Total remise amount of the row
              remtot: 0,
              datafid: '',
              datafidaco: '',
              dataopeco: '' ,
              datarem: '',
              okdiff: false,
              bundle: 0,
              opt: currentOpt,
              lib: _row_option.CALIB,
              idinstuser: +this.user.IDINSTINT,
              iduser: +this.user.CIINT,
              libuser: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.currentUserName,
              dtpromesse: current_date, // 2018-03-27 10:04:41
              coinsolde: false,
              expo: false,
              motifcde: '',
              numcde: '',
              prixupv: parseFloat(_row_option.PRIX.toString()), // New Price (Typeable) => PU Ven.
              montant: parseFloat((_row_option.PRIX * _row_option.QTY).toString()), // Row Subtotal - PV Total
              remise: 0,
              emplacement: '',
              statut: 0,
              dtmaj: current_date
            });
          }
        })
      })
    }

    article_data.map((row) => {
      grand_total_amount += row.montant;
    });

    const dataRem = (this.previousPanier !== undefined && this.previousPanier !== null) ? this.previousPanier.datarem : '';
    const remTotal = (this.previousPanier !== undefined && this.previousPanier !== null) ? this.previousPanier.remtot : 0;
    const remPercentage = (this.previousPanier !== undefined && this.previousPanier !== null) ? this.previousPanier.remise : 0;

    // Default Date limite for Emportéé différe from Params
    const ED_buffer_days = await this.userService.getRuleParamsData(20, 50, 'VINT1');
    const ED_default_date = moment().add(ED_buffer_days, 'days').format('YYYY-MM-DD HH:mm:ss');

    // Replace all BL to E2/BL if any E2/BL already found. (Only if Livtottale checked true)
    article_data = this._updatePanierArticlesLivTotale(article_data);

    let bondeVenteData: any = {
      articles: article_data,
      mag: this.USERCMINT,
      clientlivre : '',
      clientfacture : '',
      numfid: '',
      comm : this.bonVenteComments !== null ? this.bonVenteComments : '',
      idinstuser : +this.user.IDINSTINT,
      iduser: +this.user.CIINT,
      libuser: this.currentUserName,

      idinstusermaj : +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.currentUserName,

      userexterne : '',
      numdevis: '',

      dtliv : '1899-12-30T00:00:00.000+01:00', // Livraison Date
      dtlivsouhait : '1899-12-30T00:00:00.000+01:00', //
      livjour : '', // A (Afternoon) / M (Morning)
      livconfirm : false, // Confirmation de livraison
      livinfo1 : '', // Livraison Info 1
      livinfo2 : '', // Livraison Info 2
      livdeport : false, // Livraison Transport
      livdirect : false, // Livraison Directe

      transporteur : '',
      numexterne : '',
      remtot : remTotal,
      datafid : '',
      datafidaco : '',
      dataopeco : '',
      datarem : dataRem,
      vteemp : 0,
      pseudo : '',
      place : '',
      infocde : '',
      numcourt : '',
      statut : 1,
      dtsaisie : current_date,
      dtmaj : current_date,
      dtech : '1899-12-30T00:00:00.000+01:00',
      dtrel : '1899-12-30T00:00:00.000+01:00',
      dtpromesse : '1899-12-30T00:00:00.000+01:00',
      ecartpromesse : '',
      remise: remPercentage,
      canal : 0,
      montant : grand_total_amount,
      montantrestant : 0,
      preparation: false,
      retrait1h: false,
      montantHT: grand_total_amount,
      typecde: '',
      paiements: [],
      infosfac: {
        totEI: 0,
        totED: 0,
        totBL: 0,
        totE2: 0,
        mntED: 0,
        mntBL: 0,
        mntE2: 0,
        pourED: 100,
        pourBL: 100,
        pourE2: 20
      }
    };

    if (this.previousPanier !== undefined && this.previousPanier !== null) {
      bondeVenteData = {
        ...this.previousPanier,
        articles: article_data,
        clientlivre : this.previousPanier.clientlivre !== null ? this.previousPanier.clientlivre : '',
        clientfacture : this.previousPanier.clientfacture !== null ? this.previousPanier.clientfacture : '',
        comm : this.bonVenteComments !== null ? this.bonVenteComments : '',
        idusermaj: +this.user.CIINT,
        idinstusermaj: +this.user.IDINSTINT,
        libusermaj: this.currentUserName,

        remtot : remTotal,
        datarem : dataRem,
        dtmaj : current_date,
        remise: remPercentage,
        montant : grand_total_amount,
        montantHT: grand_total_amount,
        infosfac: this.previousPanier.infosfac
      };
    }

    if (this.chosenFidelite) {
      bondeVenteData['numfid'] = this.chosenFidelite.NUMFID;
    }

    if (this.livraisonData) {
      bondeVenteData['dtliv'] = this.livraisonData.dtliv ? this.livraisonData.dtliv : current_date;
      bondeVenteData['livjour'] = this.livraisonData.livjour ? this.livraisonData.livjour : current_date;
      bondeVenteData['livconfirm'] = this.livraisonData.livconfirm;
      bondeVenteData['livinfo1'] = this.livraisonData.livinfo1 ? this.livraisonData.livinfo1 : '';
      bondeVenteData['livinfo2'] = this.livraisonData.livinfo2 ? this.livraisonData.livinfo2 : '';
      bondeVenteData['livdirect'] = this.livraisonData.livdirect;
      bondeVenteData['livdeport'] = this.livraisonData.livdeport;
    }

    if (client_data !== undefined && client_data !== null) {
      bondeVenteData['client'] = client_data;
    } else {
      bondeVenteData['client'] = '';
    }

    bondeVenteData['editinfo'] = {
      pMag: this.USERCMINT,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;

    bondeVenteData.magstock = this.currentArticleStockMag || 0;

    bondeVenteData['Id_InstUser'] = Constants.CIINST;
    const chosenLineRemiseBcup = this.chosenLineRemise;
    const chosenArticleBcup = this.currentArticle;
    const forcePrixMotif = (form_data.MOTIF_PRIX !== null) ? form_data.MOTIF_PRIX_LBL : '';

    this.editBonDeVente(bondeVenteData).then(
      async (resp: any) => {
        if (this.currentArticleStockMag && this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
          await this._addMagStockHistory(this.user.CIINT, currentCdart, false);

          if (this.authorizedArticleStockMagUser !== undefined && this.authorizedArticleStockMagUser !== null) {
            await this._addMagStockHistory(this.authorizedArticleStockMagUser.CIINT, currentCdart);
            this.authorizedArticleStockMagUser = undefined;
          }
        }

        if (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) {
          await this._addChangementPrixHistory(currentCdart, forcePrixMotif, +chosenArticleBcup.calculated_price, form_data.CAPRIX);
          this.authorizedLinePrixUser = undefined;
        }

        if (this.authorizedForcageUser !== undefined && this.authorizedForcageUser !== null) {
          await this._addForcageVenteCdeHistory(currentCdart, this.chosenForcage, this.forcageType);
          this.authorizedForcageUser = undefined;
        }

        if (chosenLineRemiseBcup !== undefined && chosenLineRemiseBcup !== null && chosenLineRemiseBcup.length > 0) {
          try {
            await this._addLigneRemiseHistory(currentCdart, this.user.CIINT, oldPrix, newPrix, false);

            if (this.authorizedLineRemiseUser !== undefined && this.authorizedLineRemiseUser !== null) {
              await this._addLigneRemiseHistory(currentCdart, this.authorizedLineRemiseUser.CIINT, oldPrix, newPrix);
              this.authorizedLineRemiseUser = undefined;
            }
          } catch (err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        }

        this.chosenForcage = undefined;
        this.commander = false;

        let livreBL_Avail = false;
        let livre_E2_BL_Avail = false;

        let BL_Avail = false;
        let E2_BL_Avail = false;

        let currentArticleData;
        this.previousPanier.articles.map(
          (row) => {
            currentArticleData = this.listBonVenteArticles.find((item) => item.CDART == row.cdart);

            if (currentArticleData.CAOKLIV == '-1') {
              if (row.typecde === 'BL') {
                livreBL_Avail = true;
              }

              if (row.typecde === 'E2/BL') {
                livre_E2_BL_Avail = true;
              }
            } else {
              if (row.typecde === 'BL') {
                BL_Avail = true;
              }

              if (row.typecde === 'E2/BL') {
                E2_BL_Avail = true;
              }
            }
          }
        );

        let livreAlertMessage = '';
        if (BL_Avail && !livreBL_Avail) {
          livreAlertMessage += `<h1 class="mb-1">${this.translateService.instant('kbonajouterbl')} </h1>`;
        }

        if (E2_BL_Avail && !livre_E2_BL_Avail) {
          livreAlertMessage += `<h1 class="">${this.translateService.instant('kbonajoutere2')} </h1>`;
        }

        if (this.livraisonAlertStatus) {
          this.livraisonAlert(livreAlertMessage);
        }

        this.openCommandeDetail();

        // Check if the added item is a service article and open the popup where it can be linked to any other non service article
        if (!this.isWefoxArticle) {
          let currentItem: PanierArticle;
          if (article_data?.length > 0) {
            currentItem = article_data[article_data.length - 1]
          }

          if (currentItem.opt >= 0) {
            const parentItem = [...article_data].find(rw => rw.opt == -currentItem.opt);
            const parentArticle = this.listBonVenteArticles.find(rw => rw.CDART === parentItem.cdart);

            await this.checkArticleParentChildStatus([[parentArticle.CAINT, currentArticleData.CAINT]]);
          }
      
          if(currentItem.opt < 0 && +currentArticleData.GROUP_COUNT > 0) {
            this.linkedStatus = 'bundle';
          }
      
          this.checkIfAddedArticleIsService(currentItem, currentArticleData)
        }
      }
    );
  }

  checkIfAddedArticleIsService(currentItem: PanierArticle, currentArticleData) {
    if (currentArticleData.CAGESTOCK == '0' && currentItem?.opt >= 0 && this.linkedStatus !== 'bundle') {
      const listNonServiceArticles = this.listBonVenteArticles.filter((row) => row.CAGESTOCK == '-1');

      const cdartArticlesHashTable = [...listNonServiceArticles].reduce((acc, it) => {
        if (!acc.hasOwnProperty(it.CDART)) {
          acc[it.CDART] = {};
        }

        acc[it.CDART] = it;
        return acc;
      }, {});

      const listNonServiceItems = this.listBonVenteItems.filter(rw => {
        return rw.opt <= 0 && (cdartArticlesHashTable[rw.cdart] && cdartArticlesHashTable[rw.cdart].CAGESTOCK == '-1');
      });
      
      const dialogRef = this.dialog.open(NonServiceArticlesListComponent, {
        width: '40vw',
        maxWidth: '40vw',
        maxHeight: '80vh',
        data: {
          currentItem: currentItem,
          nonServiceArticles: listNonServiceArticles,
          nonServiceItems: listNonServiceItems,
          nonServiceArticleHashTable: cdartArticlesHashTable,
          panier: this.previousPanier,
          isBonDeVente: true,
          bonNumBon: this.bonNumBon,
        }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.status === 'success') {
          
          if (result.data.CHOSEN_IDLIG !== undefined && result.data.CHOSEN_IDLIG !== null) {
            const chosenLineId = result.data.CHOSEN_IDLIG;
            
            const serviceItem = currentItem; // Service
            const nonServiceItem = listNonServiceItems.find(rw => rw.idlig == chosenLineId); // Non service
            
            this._linkNonServiceToService(serviceItem, nonServiceItem, currentItem);
          } else {
            // Add History record for adding non service article from another bon-de-commande
            if (result?.data?.NUMBON && result?.data?.AUTRE_CHOSEN_CDART) {
              this._addAutreArticleLinkHistory(result.data.AUTRE_CHOSEN_CDART, result.data.NUMBON, currentItem.cdart)  
            }
          }
        }
      });
    }
  }

  checkArticleParentChildStatus(payload: string[][]): Promise<any> {
    this.loadingService.show(); 
    return new Promise((resolve, reject) => {
      this.bonVenteService.checkArticleParentChildStatus(payload).subscribe(
          (res: any) => {
            if(res.success !== undefined) {
              if(res.data.length > 0) {
                this.linkedStatus = 'bundle';
              } else {
                this.linkedStatus = 'service';
              }
              this.loadingService.hide();
              resolve(res);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
              resolve(res);
            }
          },
          (err) => {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    })
  }

  async _linkNonServiceToService(serviceItem: PanierArticle, nonServiceItem: PanierArticle, currentItem) {
    if (nonServiceItem === undefined || nonServiceItem === null) {
      return;
    }

    this.loadingService.show();

    let article_data: PanierArticle[] = [...this.previousPanier.articles];

    let currentIdLigne = 1;
    let nextOpt = 0;
    article_data.forEach((_r) => {
      if(_r.opt < 0) {
        nextOpt = _r.opt;
      }
    });
    nextOpt = nextOpt - 1;

    currentIdLigne = nonServiceItem.idlig;
    if (nonServiceItem.opt < 0) {
      // If it's already a parent.
      nextOpt = nonServiceItem.opt;

      const filteredChildren = [...article_data]
          .filter(rw => rw.opt === Math.abs(nonServiceItem.opt))
          .map(rw => rw.idlig);

      if (filteredChildren.length > 0) {
        currentIdLigne = Math.max(...filteredChildren);
      }
    }

    const QTE = nonServiceItem.quantite;
    let PRIX_UPV = serviceItem.prixupv;
    let PRIX_PVT = PRIX_UPV * QTE;

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const whole_remise_percent = (this.previousPanier.remise !== undefined && this.previousPanier.remise !== null) ? +this.previousPanier.remise : 0.0;
    let line_remise_percent = (currentItem.remise !== undefined && currentItem.remise !== null) ? +currentItem.remise : 0.0;
    let line_remise_prix = 0,
        line_remise_value = 0,
        total_line_remise = 0,
        line_remtot = 0,
        line_subtotal = 0;

    const itemRemPercentage = currentItem.remise;

    line_remise_percent = itemRemPercentage;

    if(line_remise_percent > 0) {
      line_remise_value = serviceItem.prix * (line_remise_percent / 100);
      line_remise_prix = serviceItem.prix - line_remise_value;

      total_line_remise = line_remise_value * +QTE;
      line_subtotal = line_remise_prix * +QTE;

      PRIX_UPV = line_remise_prix;
      PRIX_PVT = line_subtotal;
    }

    if(whole_remise_percent > 0) {
      line_remtot = total_line_remise * (whole_remise_percent / 100);
    }

    const serviceChildItem = {
      ...serviceItem,
      opt: Math.abs(nextOpt),
      quantite: QTE,
      typecde: nonServiceItem.typecde,
      prixupv: PRIX_UPV,
      montant: PRIX_PVT,

      remise: line_remise_percent,
      remlig: parseFloat(total_line_remise.toString()),
      remtot: parseFloat(line_remtot.toString()), // Total remise amount of the row

      idusermaj: +this.user.CIINT,
      idinstusermaj: +this.user.IDINSTINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : serviceItem.libuser,
      dtmaj: current_date
    };

    let grand_total_amount = 0;

    // Removing the service item from the article_data array
    article_data.splice(article_data.findIndex(rw => rw.idlig === serviceItem.idlig), 1);

    // Inserting the service item into the article_data array in a specific position
    article_data.splice(article_data.findIndex(rw => rw.idlig === currentIdLigne) + 1, 0, serviceChildItem);

    article_data = article_data.map((row) => {
      if (row.idlig === nonServiceItem.idlig) {
        // Update the `opt` only to the chosen Non Service article
        row.opt = nextOpt;

        row.dtmaj = current_date;
        row.idusermaj = +this.user.CIINT;
        row.idinstusermaj = +this.user.IDINSTINT;
        row.libusermaj = this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : row.libusermaj;
      }

      grand_total_amount += row.montant;

      return row;
    });

    let dataRem = "";
    let remTotal = 0;
    const remPercentage = whole_remise_percent;

    if(whole_remise_percent !== undefined && whole_remise_percent !== null && whole_remise_percent > 0) {
      remTotal = grand_total_amount * (remPercentage / 100);

      dataRem = this.previousPanier.datarem;
      remTotal = this.previousPanier.remtot;
    }

    const bondeVenteData = {
      ...this.previousPanier,
      articles: article_data,

      idinstusermaj : this.user.IDINSTINT,
      idusermaj : +this.user.CIINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.previousPanier.libuser,

      remtot : remTotal,
      datarem : dataRem,
      montant : grand_total_amount,
      montantHT: grand_total_amount,
      dtmaj : current_date,
    };

    bondeVenteData['editinfo'] = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon
    };

    if (this.flowType === 'bonde_vente') {
        bondeVenteData['Id_User'] = this.user.CIINT;
        bondeVenteData['Id_InstUser'] = Constants.CIINST;

        await this.editBonDeVente(bondeVenteData);
    }

    this.loadingService.hide();
  }

  _addMagStockHistory(CIINT: string, CDART?: any, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_FORCAGE_STOCK_AUTRE_MAG,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART ? CDART : this.currentArticle.CDART,
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: null,
      VALUER2: null,
    }, HISTORY_AUTHORIZATION);
  }

  _addChangementPrixHistory(CDART: string, MOTIF: string, oldPrix: number, newPrix: number): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_CHANGEMENT_DE_PRIX,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART ? CDART : this.currentArticle.CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) ? this.authorizedLinePrixUser.CIINT : this.user.CIINT,
      MOTIF: MOTIF,
      VALUER1: oldPrix,
      VALUER2: newPrix,
    });
  }

  _addForcageVenteCdeHistory(CDART: string, MOTIF: string, TYPE_OPERAT: 'commande' | 'vente'): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_FORCAGE_VENTE_OU_COMMANDE,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART ? CDART : this.currentArticle.CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedForcageUser !== undefined && this.authorizedForcageUser !== null) ? this.authorizedForcageUser.CIINT : this.user.CIINT,
      MOTIF: MOTIF,
      VALUER1: null,
      VALUER2: null,
      TYPE_OPERAT: TYPE_OPERAT as string,
    });
  }

  _addValidationCommandeHistory(NUMBON: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_VALIDATION_BON_DE_COMMANDE,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedValidationCommandeUser !== undefined && this.authorizedValidationCommandeUser !== null) ? this.authorizedValidationCommandeUser.CIINT : this.user.CIINT,
      MOTIF: 'Bon de vente',
      VALUER1: null,
      VALUER2: null,
      TYPE_OPERAT: 'vente',
    });
  }

  async _addAutreArticleLinkHistory(autreCDART: string, autreBON: string, currentCDART, unlink: boolean = false, historyData?: any) {
    let ARTEAN = '';
    let MOTIF = '';

    if (unlink) {
      ARTEAN = 'idem';
      MOTIF = 'idem';

      if(historyData?.ARTEAN && historyData?.MOTIF) {
        ARTEAN = historyData?.ARTEAN;
        MOTIF = historyData?.MOTIF;
      }
    } else {
      ARTEAN = currentCDART;
      MOTIF = `“Bon lié N° : ${autreBON} ; Article : ${autreCDART}`;
    }

    return await this.historyService.addHistory({
      NumBon: this.bonNumBon,
      mag: this.USERCMINT,
      iduser: this.user.CIINT,
      ARTEAN,
      MOTIF,
      OPERAT : unlink ? this.translateService.instant('kunlink_autre_article') : this.translateService.instant('klink_autre_article'),
      VALUER1: null,
      VALUER2: null,
    });
  }

  editBonDeVente(bondeVenteData: any, closeLoader: boolean = true) {
    return new Promise((resolve, reject) => {
      this.bonApiSubscription = this.bonVenteService.modifyBonvente(bondeVenteData).subscribe(
        async (resp) => {
          if (closeLoader) { this.loadingService.hide(); }

          const currentArticleBcup = this.currentArticle;

          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {
              this.chosenLineRemise = undefined;
              this.chosenRemise = undefined;

              // List update after a success response
              const oldListData = this.storageService.getItem('bonVenteListData');
              const oldNumBon = this.bonNumBon;
              // ------------------------------

              const NumBon = resp.data.NumBon;

              if (NumBon !== this.bonNumBon) {
                // Maintaining a variable to track NumBon changes
                // by which, we decide whether the route can be changed or not
                this.bonNumBonChanged = true;

                await this.router.navigate(['/modify-bon-de-vente', NumBon, this.previousPanier.mag], { replaceUrl: true })

                this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
                this.storageService.setItem('previousBonPanier', this.previousPanier);
                this.storageService.setItem('previousNumBon', this.bonNumBon);
              }

              this.bonNumBon = NumBon;
              this.previousPanier = resp.data.Panier;
              this.listBonVenteItems = this.previousPanier.articles;

              this.listBonVenteArticles = resp.data.customArticles;
              this.previousPanierClient = resp.data.customClient;
              this.chosenClient = this.previousPanierClient;

              this.storageService.setItem('previousBonPanier', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);

              this.currentArticle = undefined;
              this.article = this.currentArticle;
              this.originMagArticle = undefined;

              this.newArticleForm.patchValue({
                CDART: '',
                CALIB1: '',
                QTE: '1',
                CAPRIX: '',
                NEW_PRIX: this.utilService.formatMaskCompat(0),
                ROW_TOTAL: '',
                TYPECDE: '',
                MOTANT: this.utilService.formatMaskCompat(0),
                STOCKDISPO: '',
                PRIX_FORCE: false,
                MOTIF_FORCE: '',
              });

              this.qtyLength = 0;
              this.qtyWidth = 0;
              this.qtyHeight = 0;

              this.newArticleForm.get('QTE').disable({ onlySelf: true });
              this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
              this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

              // List update after a success response
              if (oldListData !== undefined && oldListData !== null) {
                oldListData.data.map(
                  (row: BonVente) => {
                    if (row.NUMCOM == oldNumBon) {
                      row.TRAISOC = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TRAISOC : null;
                      row.TPRENOM = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TPRENOM : null;
                      row.TADR3 = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TADR3 : null;
                      row.CODPOS = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.CODPOS : null;
                      row.TVILLE = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TVILLE : null;
                      row.TTEL = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TTEL : null;
                      row.TPORT = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TPORT : null;
                      row.TPORT = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TPORT : null;
                      row.ENDMOD = moment(this.previousPanier.dtmaj).utcOffset(this.previousPanier.dtmaj).format('YYYY-MM-DD HH:mm:ss');

                      row.MTHT = this.previousPanier.montantHT;
                      row.MTTTC = this.previousPanier.montant;
                      row.MTTVA = (this.previousPanier.montant - this.previousPanier.montantHT);

                      row.CLINUM = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.previousPanier.client.idcli.toString() : '0';
                      row.CLILIVNUM = (this.previousPanier.clientlivre !== undefined && this.previousPanier.clientlivre !== null) ? this.previousPanier.clientlivre.idcli.toString() : '0';
                      row.CLIFACNUM = (this.previousPanier.clientfacture !== undefined && this.previousPanier.clientfacture !== null) ? this.previousPanier.clientfacture.idcli.toString() : '0';

                      let cumulativeTypeCDE = '';
                      let _typeCdeArr = this.previousPanier.articles.map((row_art) => row_art.typecde);

                      _typeCdeArr = _typeCdeArr.filter(function(elem, index, self) {
                        return index === self.indexOf(elem);
                      })

                      cumulativeTypeCDE = _typeCdeArr.join(',');

                      row.TYPECDE = cumulativeTypeCDE;

                      row.NUMCOM = this.bonNumBon;

                      return row;
                    }

                    return row;
                  }
                );

                const updated_bonvente = oldListData.data.find(
                  (row: BonVente) => row.NUMCOM == this.bonNumBon
                );

                const updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
                updated_row_removed.unshift(updated_bonvente);

                oldListData.data = updated_row_removed;

                this.storageService.setItem('bonVenteListData', oldListData);
              }
              // ------------------------------

              this.refreshByPanier(this.previousPanier);
              resolve({ response: resp, current_article: currentArticleBcup });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              reject({ response: resp, current_article: currentArticleBcup });
            }
          } else {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve({ response: resp, current_article: currentArticleBcup });
          }
        },
        err => {
          if (closeLoader) { this.loadingService.hide(); }
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });
  }

  modifyAndCheckBonDeVente(bondeVenteData: any, closeLoader: boolean = true) {
    return new Promise((resolve, reject) => {
      this.bonVenteService.modifyEtCheckBonvente(bondeVenteData).subscribe(
        (resp) => {
          if (closeLoader) { this.loadingService.hide(); }

          const currentArticleBcup = this.currentArticle;

          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {
              this.chosenLineRemise = undefined;
              this.chosenRemise = undefined;

              // List update after a success response
              const oldListData = this.storageService.getItem('bonVenteListData');
              const oldNumBon = this.bonNumBon;
              // ------------------------------

              this.bonNumBon = resp.data.NumBon;
              this.previousPanier = resp.data.Panier;
              this.listBonVenteItems = this.previousPanier.articles;

              if (resp.data.customArticles) {
                this.listBonVenteArticles = resp.data.customArticles;
              }
              if (resp.data.customClient) {
                this.previousPanierClient = resp.data.customClient;
                this.chosenClient = this.previousPanierClient;
              }

              this.storageService.setItem('previousBonPanier', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);

              this.currentArticle = undefined;
              this.article = this.currentArticle;
              this.originMagArticle = undefined;

              this.newArticleForm.patchValue({
                CDART: '',
                CALIB1: '',
                QTE: '1',
                CAPRIX: '',
                NEW_PRIX: this.utilService.formatMaskCompat(0),
                ROW_TOTAL: '',
                TYPECDE: '',
                MOTANT: this.utilService.formatMaskCompat(0),
                STOCKDISPO: '',
                PRIX_FORCE: false,
                MOTIF_FORCE: '',
              });

              this.qtyLength = 0;
              this.qtyWidth = 0;
              this.qtyHeight = 0;

              this.newArticleForm.get('QTE').disable({ onlySelf: true });
              this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
              this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

              // List update after a success response
              if (oldListData !== undefined && oldListData !== null) {
                oldListData.data.map(
                  (row: BonVente) => {
                    if (row.NUMCOM == oldNumBon) {
                      row.TRAISOC = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TRAISOC : null;
                      row.TPRENOM = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TPRENOM : null;
                      row.TADR3 = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TADR3 : null;
                      row.CODPOS = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.CODPOS : null;
                      row.TVILLE = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TVILLE : null;
                      row.TTEL = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TTEL : null;
                      row.TPORT = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TPORT : null;
                      row.TPORT = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.chosenClient.TPORT : null;
                      row.ENDMOD = moment(this.previousPanier.dtmaj).utcOffset(this.previousPanier.dtmaj).format('YYYY-MM-DD HH:mm:ss');

                      row.MTHT = this.previousPanier.montantHT;
                      row.MTTTC = this.previousPanier.montant;
                      row.MTTVA = (this.previousPanier.montant - this.previousPanier.montantHT);

                      row.CLINUM = (this.previousPanier.client !== undefined && this.previousPanier.client !== null) ? this.previousPanier.client.idcli.toString() : '0';
                      row.CLILIVNUM = (this.previousPanier.clientlivre !== undefined && this.previousPanier.clientlivre !== null) ? this.previousPanier.clientlivre.idcli.toString() : '0';
                      row.CLIFACNUM = (this.previousPanier.clientfacture !== undefined && this.previousPanier.clientfacture !== null) ? this.previousPanier.clientfacture.idcli.toString() : '0';

                      let cumulativeTypeCDE = '';
                      let _typeCdeArr = this.previousPanier.articles.map((row_art) => row_art.typecde);

                      _typeCdeArr = _typeCdeArr.filter(function(elem, index, self) {
                        return index === self.indexOf(elem);
                      })

                      cumulativeTypeCDE = _typeCdeArr.join(',');

                      row.TYPECDE = cumulativeTypeCDE;

                      row.NUMCOM = this.bonNumBon;

                      return row;
                    }

                    return row;
                  }
                );

                const updated_bonvente = oldListData.data.find(
                  (row: BonVente) => row.NUMCOM == this.bonNumBon
                );

                const updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
                updated_row_removed.unshift(updated_bonvente);

                oldListData.data = updated_row_removed;

                this.storageService.setItem('bonVenteListData', oldListData);
              }
              // ------------------------------

              this.refreshByPanier(this.previousPanier);
              resolve({ response: resp, current_article: currentArticleBcup });
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              reject({ response: resp, current_article: currentArticleBcup });
            }
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve({ response: resp, current_article: currentArticleBcup });
          }
        },
        err => {
          if (closeLoader) { this.loadingService.hide(); }
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });
  }

  async searchArticleGroupsCAINT(CAINT: any, TYPECDE: string) {
    this.loadingService.show();

    let CMINT = this.USERCMINT;
    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    this.bonVenteService.getArticleGroupsByCAINT(CAINT, CMINT, this.user.CIINT, this.USERCMINT).subscribe(
      async (res: any) => {
        if (res.success !== undefined) {
          if (res.data.length > 0) {
            const groupsData = res.data;
            this.listArticleGroups = [];

            if (groupsData !== undefined && groupsData != null && groupsData.length > 0) {
              groupsData.map((row: any) => {
                const groupAvail = this.listArticleGroups.find((_row) => _row.group_id == row.id);

                if (groupAvail === undefined || groupAvail === null) {
                  this.listArticleGroups.push({
                    group_id: row.id,
                    group_label: row.label,
                    group_min: row.selectable_qtymin,
                    group_max: row.selectable_qty,
                    group_custom: row.custom,
                    group_type: row.TYPECDE,
                    group_deposit: row.DEPOSIT,
                    group_active: row.ACTIVE,
                    group_position: row.grouppos,
                    group_articles: [row]
                  });
                } else {
                  this.listArticleGroups.map((_row) => {
                    if (_row.group_id == row.id) {
                      _row.group_articles.push(row);
                    }
                  });
                }
              });

              if (groupsData.some((_r: any) => _r.TYPECDE == null || TYPECDE.indexOf(_r.TYPECDE) > -1)) {
                const groupsPopupResult = await this.openUpArticleGroups(this.listArticleGroups, undefined, TYPECDE);
                if (groupsPopupResult !== undefined && groupsPopupResult !== null && groupsPopupResult.status !== undefined && groupsPopupResult.status === 'success') {
                  this.currentArticleChosenGroups = groupsPopupResult.data;
                  this.__newLineItemAddLogic('options');
                }
              } else {
                this.__newLineItemAddLogic();
              }
            } else {
              this.__newLineItemAddLogic();
            }
          } else {
            this.currentArticle = undefined;
            this.originMagArticle = undefined;

            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
          }
        } else {
          this.currentArticle = undefined;
          this.originMagArticle = undefined;

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }
      },
      (err) => {
        this.currentArticle = undefined;
        this.originMagArticle = undefined;

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  _searchMultiArticleGroupsCAINT(CAINT: any, closeLoader: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {

      let CMINT = this.USERCMINT;
      if (this.previousPanier && this.previousPanier.magstock) {
        CMINT = this.previousPanier.magstock;
      }

      this.bonVenteService.getArticleGroupsByCAINT(CAINT, CMINT, this.user.CIINT, this.USERCMINT).subscribe(
        (res: any) => {
          if (res.success !== undefined) {
            if (res.data.length > 0) {
              if (closeLoader) {
                this.loadingService.hide();
              }

              const groupsData = res.data;
              this.articleGroupsRawData = groupsData;
              this.listArticleGroups = [];

              if (groupsData !== undefined && groupsData != null && groupsData.length > 0) {
                groupsData.map((row: any) => {
                  const groupAvail = this.listArticleGroups.find((_row) => _row.group_id == row.id);

                  if (groupAvail === undefined || groupAvail === null) {
                    this.listArticleGroups.push({
                      group_id: row.id,
                      group_label: row.label,
                      group_min: row.selectable_qtymin,
                      group_max: row.selectable_qty,
                      group_custom: row.custom,
                      group_type: row.TYPECDE,
                      group_deposit: row.DEPOSIT,
                      group_active: row.ACTIVE,
                      group_position: row.grouppos,
                      group_articles: [row]
                    });
                  } else {
                    this.listArticleGroups.map((_row) => {
                      if (_row.group_id == row.id) {
                        _row.group_articles.push(row);
                      }
                    });
                  }
                });
              }

              resolve(res);
            } else {
              this.currentArticle = undefined;
              this.originMagArticle = undefined;
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
              if (closeLoader) {
                this.loadingService.hide();
              }
              resolve(res);
            }
          } else {
            this.currentArticle = undefined;
            this.originMagArticle = undefined;

            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            if (closeLoader) {
              this.loadingService.hide();
            }
            resolve(res);
          }
        },
        (err) => {
          this.currentArticle = undefined;
          this.originMagArticle = undefined;

          if (closeLoader) {
            this.loadingService.hide();
          }
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });
  }

  _focusPUvente() {
    if (this.newPrixVente) {
      this.newPrixVente.nativeElement.select();
      this.newPrixVente.nativeElement.focus();
      this.newPrixVente.nativeElement.click();
    }
  }

  _focusTypeCde() {
    if (this.newTypeCde) {
      this.newTypeCde.focus();
      this.newTypeCde.open();
    }
  }

  _triggerSubmitNewItem() {
    this.newArticleFormEl?.ngSubmit.emit();
  }

  searchArticleRapid(event: any = '') {
    if (event) {
      event.preventDefault();
    }

    if (this.newArticleForm.get('CDART').errors) {
      return;
    }
    this.chosenLineRemise = undefined;

    const search_code = this.newArticleForm.get('CDART').value;

    if (this.cdartInput && this.isMobile) {
      this.cdartInput.nativeElement.readOnly = true;
      this.cdartInput.nativeElement.setAttribute('inputmode', 'none');

      setTimeout(() => {
        this.cdartInput.nativeElement.setAttribute('inputmode', 'text');
        this.cdartInput.nativeElement.readOnly = false;
      }, 50)
    }

    this._barCodeLogicCheck(search_code, false).catch((val) => {
      this.searchArticleCDART();
    })
  }

  _restrictQtyDecimal(currentArticle: Article) {
    if (currentArticle === undefined || currentArticle === null) {
      this.qtyMaskOptions = {
        mask: Number,
        signed: false,
        scale: this.quantity5Decimal ? 5 : 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ['.']
      };

      // To solve issue with Qty while changing the MaskOptions.scale to zero
      setTimeout(() => {
        this.newArticleForm.get('QTE').setValue('1');
      }, 100);
      return;
    }

    const processedRules = this.userService.processRulesParams(this.paramsList);

    let maxLimit;
    if (this.currentArticleStockMag && (currentArticle.STOCKDISPO !== undefined && currentArticle.STOCKDISPO !== null)) {
      if (!processedRules['20_6'] || (processedRules['20_6']['VBOOL3'] != '-1' && processedRules['20_6']['VBOOL4'] != '-1')) {
        maxLimit = currentArticle.STOCKDISPO;
      }
    }

    if (currentArticle.CAUNVTE !== Constants.QTE_ALLOW_DECIMAL_CAUNVTE) {
      this.qtyMaskOptions = {
        mask: Number,
        padFractionalZeros: false,
        normalizeZeros: false,
        scale: 0,
        signed: false,
        radix: this.utilService.getCurrentDecimalSymbol, // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix
        max: maxLimit
      };
    } else {
      this.qtyMaskOptions = {
        mask: Number,
        signed: false,
        scale: this.quantity5Decimal ? 5 : 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ['.'],
        max: maxLimit
      };
    }

    // To solve issue with Qty while changing the MaskOptions.scale to zero
    setTimeout(() => {
      this.newArticleForm.get('QTE').setValue('1');
    }, 100);
  }

  __getNewItemPrix(userMagArt: Article, stockMagArt: Article): string[] {
    let CAPRIX = '0', NEW_PRIX = '0', ROW_TOTAL = '0';

    CAPRIX = this.utilService.formatMaskCompat(((stockMagArt && stockMagArt.calculated_price !== null) ? +stockMagArt.calculated_price : 0));
    NEW_PRIX = this.utilService.formatMaskCompat(((stockMagArt && stockMagArt.calculated_price !== null) ? +stockMagArt.calculated_price : 0));
    ROW_TOTAL = this.utilService.formatMaskCompat(((stockMagArt && stockMagArt.calculated_price !== null) ? +stockMagArt.calculated_price : 0));

    if (this.currentArticleStockMag) {
      CAPRIX = this.utilService.formatMaskCompat(((userMagArt && userMagArt.calculated_price !== null) ? +userMagArt.calculated_price : 0));
      NEW_PRIX = this.utilService.formatMaskCompat(((userMagArt && userMagArt.calculated_price !== null) ? +userMagArt.calculated_price : 0));
      ROW_TOTAL = this.utilService.formatMaskCompat(((userMagArt && userMagArt.calculated_price !== null) ? +userMagArt.calculated_price : 0));
    }

    return [CAPRIX, NEW_PRIX, ROW_TOTAL];
  }

  searchArticleCDART(overrideCMINT?: any) {

    if (this.newArticleForm.get('CDART').errors) {
      return;
    }

    this.loadingService.show();
    const CDART = this.newArticleForm.get('CDART').value;

    let CMINT = this.USERCMINT;

    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    if (overrideCMINT != undefined && overrideCMINT != null && overrideCMINT != 0) {
      CMINT = overrideCMINT;
    }

    this.bonVenteService.getArticleByCDART(CDART, CMINT, this.USERCMINT).subscribe(
      async (res: any) => {
        if (res.success !== undefined) {
          this.PU_vente_changable = true;
          if (!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if (res.data.length > 0) {
            const groupAvail = res.groupExist ? res.groupExist : false;
            this.currentArticle = res.data[0];

            if (this.currentArticleStockMag) {
              await this.getArticleByCdartUserCmint(this.currentArticle.CDART, this.USERCMINT);
            }

            this._restrictQtyDecimal(this.currentArticle);

            const [CAPRIX, NEW_PRIX, ROW_TOTAL] = this.__getNewItemPrix(this.originMagArticle, this.currentArticle);

            this.newArticleForm.patchValue({
              CALIB1: this.currentArticle.CALIB3,
              CAPRIX: CAPRIX,
              NEW_PRIX: NEW_PRIX,
              ROW_TOTAL: ROW_TOTAL,
              TYPECDE: '',
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: groupAvail,
              QTE: '1',
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if (this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }

            if (this.newArticleQty && !this.isMobile) {
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }

            this.definingTypeDeRules();

            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.originMagArticle = undefined;
            this.newArticleForm.patchValue({
              CALIB1: '',
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: false,
              QTE: '1',
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;

            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            if (!isNaN(Number(CDART))) {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
            } else {
              this.searchArticleNOM();
            }
          }
        } else {
          this.currentArticle = undefined;
          this.originMagArticle = undefined;
          this.newArticleForm.patchValue({
            CALIB1: '',
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: '',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            GROUP_AVAIL: false,
            QTE: '1',
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;

          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }
      },
      (err) => {
        this.currentArticle = undefined;
        this.originMagArticle = undefined;
        this.newArticleForm.patchValue({
          CALIB1: '',
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: '',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          GROUP_AVAIL: false,
          QTE: '1',
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;

        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  searchArticleNOM() {
    const NOM = this.newArticleForm.get('CDART').value;

    let CMINT = this.USERCMINT;
    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    this.bonVenteService.getArticleByNOM(NOM, CMINT, this.USERCMINT).subscribe(
      async (res: any) => {
        if (res.success !== undefined) {
          const data = res.data;
          const totalResults: number = res.data.length;

          if (totalResults === 1) {
            const groupAvail = res.groupExist ? res.groupExist : false;
            this.currentArticle = res.data[0];

            if (this.currentArticleStockMag) {
              await this.getArticleByCdartUserCmint(this.currentArticle.CDART, this.USERCMINT);
            }

            this._restrictQtyDecimal(this.currentArticle);

            const [CAPRIX, NEW_PRIX, ROW_TOTAL] = this.__getNewItemPrix(this.originMagArticle, this.currentArticle);

            this.newArticleForm.patchValue({
              CDART: this.currentArticle.CDART,
              CALIB1: this.currentArticle.CALIB1,
              CAPRIX: CAPRIX,
              NEW_PRIX: NEW_PRIX,
              ROW_TOTAL: ROW_TOTAL,
              TYPECDE: '',
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: groupAvail,
              QTE: '1',
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if (this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }

            if (this.newArticleQty && !this.isMobile) {
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }
            this.definingTypeDeRules();
            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.originMagArticle = undefined;
            this.newArticleForm.patchValue({
              CDART: '',
              CALIB1: '',
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: false,
              QTE: '1',
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;

            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            if (data.length > 0) {
              this.loadingService.hide();
              const data = {
                formType: 'advancedSearch',
                filterData: { NOM }
              };
              this.openUpSearchArticle(undefined, data);
            } else {
              this.loadingService.hide();
              const data = {
                formType: 'advancedSearch',
                filterData: { NOM }
              };
              this.openUpSearchArticle(undefined, data);
            }
          }
        } else {
          this.currentArticle = undefined;
          this.originMagArticle = undefined;
          this.newArticleForm.patchValue({
            CDART: '',
            CALIB1: '',
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: '',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            GROUP_AVAIL: false,
            QTE: '1',
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;

          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }
      },
      (err) => {
        this.currentArticle = undefined;
        this.originMagArticle = undefined;
        this.newArticleForm.patchValue({
          CDART: '',
          CALIB1: '',
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: '',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          GROUP_AVAIL: false,
          QTE: '1',
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;

        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  searchArticleByCMEAN(event: any) {
    if (event === undefined || event == 'null') {
      return;
    }

    this.loadingService.show();
    const CMEAN = event;

    let CMINT = this.USERCMINT;
    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    this.bonVenteService.getArticleByCMEAN(CMEAN, CMINT, this.USERCMINT).subscribe(
      async (res: any) => {
        if (res.success !== undefined) {
          this.PU_vente_changable = true;
          if (!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if (res.data.length > 0) {
            this.currentArticle = res.data[0];

            if (this.currentArticleStockMag) {
              await this.getArticleByCdartUserCmint(this.currentArticle.CDART, this.USERCMINT);
            }

            this._restrictQtyDecimal(this.currentArticle);

            const [CAPRIX, NEW_PRIX, ROW_TOTAL] = this.__getNewItemPrix(this.originMagArticle, this.currentArticle);

            this.newArticleForm.patchValue({
              CDART: this.currentArticle.CDART,
              CALIB1: this.currentArticle.CALIB3,
              CAPRIX: CAPRIX,
              NEW_PRIX: NEW_PRIX,
              ROW_TOTAL: ROW_TOTAL,
              TYPECDE: '',
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              QTE: '1',
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if (this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }

            if (this.newArticleQty && !this.isMobile) {
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }

            this.definingTypeDeRules();

            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.originMagArticle = undefined;
            this.newArticleForm.patchValue({
              CDART: '',
              CALIB1: '',
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              QTE: '1',
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;

            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
          }
        } else {
          this.currentArticle = undefined;
          this.originMagArticle = undefined;
          this.newArticleForm.patchValue({
            CDART: '',
            CALIB1: '',
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: '',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            QTE: '1',
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;

          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }
      },
      (err) => {
        this.currentArticle = undefined;
        this.originMagArticle = undefined;
        this.newArticleForm.patchValue({
          CDART: '',
          CALIB1: '',
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: '',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          QTE: '1',
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;

        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  getArticleByCdartUserCmint(CDART: string, CMINT: any) {
    return new Promise((resolve, reject) => {
      if (CDART === undefined || CDART === null) {
        reject('INVALID_CDART');
      }

      if (CMINT === undefined || CMINT === null) {
        reject('INVALID_CMINT');
      }

      this.bonVenteService.getArticleByCDART(CDART, CMINT, this.USERCMINT).subscribe(
          (res: any) => {
            if (res.success !== undefined) {
              if (res.data.length > 0) {
                this.originMagArticle = res.data[0];
              } else {
                this.originMagArticle = undefined;
                this.loadingService.hide();
              }
            } else {
              this.originMagArticle = undefined;
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            }

            resolve(res);
          },
          (err) => {
            this.originMagArticle = undefined;
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            reject(err);
          }
      );
    });
  }

  getArticleByCdart(CDART: string) {
    if (CDART === undefined || CDART === null) {
      return;
    }

    let CMINT = this.USERCMINT;
    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    this.loadingService.show();
    this.bonVenteService.getArticleByCDART(CDART, CMINT, this.USERCMINT).subscribe(
      (res: any) => {
        if (res.success !== undefined) {
          this.PU_vente_changable = true;
          if (!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if (res.data.length > 0) {
            this.article = res.data[0];

            this.loadingService.hide();
          } else {
            this.article = undefined;

            this.loadingService.hide();
          }
        } else {
          this.article = undefined;

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }
      },
      (err) => {
        this.article = undefined;

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }
  searchArticleSubmitter(data?: any) {

    let CMINT = this.USERCMINT;
    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    const passData = {
      ...data,
      CMINT,
    };

    const dialogRef = this.dialog.open(SearchClientComponent, {
      width: '100vw',
      maxHeight: this.isMobile ? '95vh' : '90vh',
      data: passData
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.status === 'success') {
        this.newArticleForm.get('client_nom').setValue('');
      }
    });
  }

  onQTYChanged(event: any) {
    this.rowTotalCalculation();
  }

  incDecByArrows(event: any) {
    const qty = Number(this.newArticleForm.get('QTE').value);
    let total = 0;
    if (event.which == 38) {
      total = qty + 1;
      this.newArticleForm.get('QTE').setValue(total.toString());
    } else if (event.which == 40) {
      total = qty - 1;
      this.newArticleForm.get('QTE').setValue(total.toString());
    }
  }

  onQuantityFocused() {
    if (this.currentArticle === undefined || this.currentArticle === null) { return; }

    if (this.currentArticle.CAUNVTE === 'M3') {
      this.openUpCalculateQty().then((result) => {
        if (result && result.status === 'success') {
          const data = result.data;

          const length = data.length ? data.length : 0;
          const width = data.width ? data.width : 0;
          const height = data.height ? data.height : 0;

          this.qtyLength = length;
          this.qtyWidth = width;
          this.qtyHeight = height;

          const totalQty = length * width * height;

          this.newArticleForm.patchValue({
            QTE: this.utilService.formatQteMaskCompat(totalQty)
          });

          this.rowTotalCalculation();
        }
      })
    }
  }

  openUpCalculateQty(): Promise<any> {
    const dialogRef = this.dialog.open(CalculateQuantityComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier,
          currentItemFormData: this.newArticleForm.getRawValue(),
          currentArticle: this.currentArticle,
          dimensions: {
            length: this.qtyLength,
            width: this.qtyWidth,
            height: this.qtyHeight,
          }
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  quantityChange(action) {
    this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
    let qty = Number(this.newArticleForm.get('QTE').value);
    if (action === 'increment') {
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }
    this.newArticleForm.get('QTE').setValue(qty.toString());
    setTimeout(() => {
      this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
    }, 50);
    this.rowTotalCalculation();
  }

  get currentArticleCAPrix(): AbstractControl {
    return this.newArticleForm.get('CAPRIX');
  }

  async onPriceChanged(event?: any) {
    if (!this.PU_vente_changable && (+this.currentArticle.calculated_price > 0)) {
      this.authorizedLinePrixUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '17', this.user.CIINT, this.bonNumBon);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        event.preventDefault();
        return;
      }

      this.authorizedLinePrixUser = authorizationResult.data;
      this.PU_vente_changable = true;
    }

    this.rowTotalCalculation();

    // While forcing prix, make a copy of it into CAPRIX form control
    const entered_prix = this.newArticleForm.get('NEW_PRIX').value;
    this.currentArticleCAPrix.setValue(entered_prix);
  }

  rowTotalCalculation() {
    const price = this.newArticleForm.get('NEW_PRIX').value;
    const qty = this.newArticleForm.get('QTE').value;

    if (isNaN(price) || isNaN(qty)) {
      this.newArticleForm.patchValue({
        ROW_TOTAL: this.utilService.formatMaskCompat(0)
      });
      return;
    }

    const total_price = price * qty;

    this.newArticleForm.patchValue({
      ROW_TOTAL: this.utilService.formatMaskCompat(total_price)
    });
  }

  async submitBondeVente(type: 'vente_submit' | 'commande_validate' = 'vente_submit') {
    if (this.previousPanier === undefined || this.previousPanier === null || (this.previousPanier && this.previousPanier.articles.length === 0)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if (this.previousPanier.client === undefined || this.previousPanier.client === null) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonclientobligat')).subscribe(
        (res) => {
          this.step = 2;
          this.bonVenteSelectedTab = 0;
          setTimeout(() => {
            this.focusClientSearch = true;
          }, 400);
        }
      );
      return;
    }

    if (
        (this.previousPanier.client.gsm === undefined || this.previousPanier.client.gsm === null || this.previousPanier.client.gsm === '')
        && (this.previousPanier.client.tel === undefined || this.previousPanier.client.tel === null || this.previousPanier.client.tel === '')
    ) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclienttelnatelmand'));
      return;
    }

    if (this.emailMandatory
        && (this.previousPanier.client.email === undefined || this.previousPanier.client.email === null || this.previousPanier.client.email === '')
    ) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclientemailmand'));
      return;
    }

    const EI_Only = this.listBonVenteItems.every((_r) => _r.typecde == 'EI');

    if ((EI_Only === undefined || EI_Only === null || EI_Only === false) &&
    ((this.previousPanier.client.nom === '' || this.previousPanier.client.nom === null) ||
    (this.previousPanier.client.adr3 === '' || this.previousPanier.client.adr3 === null) ||
    (this.previousPanier.client.codpos === '' || this.previousPanier.client.codpos === null) ||
    (this.previousPanier.client.ville === '' || this.previousPanier.client.ville === null) ||
    (
      (this.previousPanier.client.gsm === '' || this.previousPanier.client.gsm === null) &&
      (this.previousPanier.client.tel === '' || this.previousPanier.client.tel === null)
    )
    )
    ) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kcooclientoblig'));
      return;
    }

    if (type === 'commande_validate') {
      let EI_only = true;
      this.previousPanier.articles.map(
        (art_row) => {
          if (art_row.typecde !== 'EI') {
            EI_only = false;
          }
        }
      );

      if (EI_only) {
        const EI_only_message = `
          ${this.translateService.instant('kbonautomatic')} <br/>
          ${this.translateService.instant('kbonproceedcheckout')}
        `;
        this.dialogService.prompt(this.translateService.instant('kuerror'), EI_only_message);
        return;
      }
    }

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    const bonventeData: BonPanier = {
      ...this.previousPanier,
      comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
      dtmaj: current_date
    };

    bonventeData['editinfo'] = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon
    };

    const livreYear = moment(this.previousPanier.dtliv).startOf('day').year();
    const ED_year = moment(this.previousPanier.dtech).startOf('day').year();
    const Promesse_year = moment(this.previousPanier.dtpromesse).startOf('day').year();

    this.loadingService.show();

    const requestPayload = {
      ...bonventeData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
      editinfo: {
        pMag: this.previousPanier.mag,
        pNumBon: this.bonNumBon
      }
    };
    await this.editBonDeVente(requestPayload);

    if (type === 'commande_validate') {
      this.openUpFacturationAcompte(this.previousPanier, (result: any) => {
        if (this.livraisonBondeVente && (this.previousPanier.livjour === '' || livreYear == 1899)) {
          this.openUpLivraionInfoDetails(this.previousPanier, (result: any) => {
            const bonventeData: BonPanier = {
              ...this.previousPanier,
              comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
              dtmaj: current_date
            };

            this.livraisonData = result.data;

            if (this.livraisonData) {
              bonventeData['dtliv'] = this.livraisonData.dtliv ? this.livraisonData.dtliv : current_date;
              bonventeData['livjour'] = this.livraisonData.livjour ? this.livraisonData.livjour : current_date;
              bonventeData['livconfirm'] = this.livraisonData.livconfirm;
              bonventeData['livinfo1'] = this.livraisonData.livinfo1 ? this.livraisonData.livinfo1 : '';
              bonventeData['livinfo2'] = this.livraisonData.livinfo2 ? this.livraisonData.livinfo2 : '';
              bonventeData['livdirect'] = this.livraisonData.livdirect;
              bonventeData['livdeport'] = this.livraisonData.livdeport;
            }

            this.loadingService.show();

            const requestData = {
              pMag: this.previousPanier.mag,
              pPanier: bonventeData,
              pNumBon: this.bonNumBon,
              Id_User: this.user.CIINT,
              Id_InstUser: Constants.CIINST
            };

            this.modifyAndCheckBonDeVente(requestData, false).then(
              (data: any) => {

                if (this.emporteDiffere && ED_year == 1899) {
                  this.openUpEmporteDiffere(this.previousPanier, (result: any) => {
                    const bonventeData: BonPanier = {
                      ...this.previousPanier,
                      comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
                      dtmaj: current_date
                    };

                    bonventeData['dtech'] = result.data.dtemporte;

                    this.loadingService.show();

                    const requestData = {
                      pMag: this.previousPanier.mag,
                      pPanier: bonventeData,
                      pNumBon: this.bonNumBon,
                      Id_User: this.user.CIINT,
                      Id_InstUser: Constants.CIINST
                    };

                    this.modifyAndCheckBonDeVente(requestData, false).then(
                      (data: any) => {
                        if (this.promesseAvail && Promesse_year == 1899) {
                          this.openUpDatePromesse(this.previousPanier, (result: any) => {
                            const bonventeData: BonPanier = {
                              ...this.previousPanier,
                              comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
                              dtmaj: current_date
                            };

                            bonventeData['dtpromesse'] = result.data.dtpromesse;

                            this.loadingService.show();

                            const requestData = {
                              pMag: this.previousPanier.mag,
                              pPanier: bonventeData,
                              pNumBon: this.bonNumBon,
                              Id_User: this.user.CIINT,
                              Id_InstUser: Constants.CIINST
                            };

                            this.modifyAndCheckBonDeVente(requestData).then(
                              (data: any) => {
                                this.validationBonDeCommande();
                              }
                            );
                          })

                          return;
                        }

                        this.validationBonDeCommande();
                      });
                  });
                  return;
                }

                if (this.promesseAvail && Promesse_year == 1899) {
                  this.openUpDatePromesse(this.previousPanier, (result: any) => {
                    const bonventeData: BonPanier = {
                      ...this.previousPanier,
                      comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
                      dtmaj: current_date
                    };

                    bonventeData['dtpromesse'] = result.data.dtpromesse;

                    this.loadingService.show();

                    const requestData = {
                      pMag: this.previousPanier.mag,
                      pPanier: bonventeData,
                      pNumBon: this.bonNumBon,
                      Id_User: this.user.CIINT,
                      Id_InstUser: Constants.CIINST
                    };

                    this.modifyAndCheckBonDeVente(requestData).then(
                      (data: any) => {
                        this.validationBonDeCommande();
                      }
                    );
                  })

                  return;
                }

                this.validationBonDeCommande();
              }
            );
          });
          return;
        }

        if (this.emporteDiffere && ED_year == 1899) {
          this.openUpEmporteDiffere(this.previousPanier, (result: any) => {
            const bonventeData: BonPanier = {
              ...this.previousPanier,
              comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
              dtmaj: current_date
            };

            bonventeData['dtech'] = moment(result.data.dtemporte).format('YYYY-MM-DD HH:mm:ss');

            this.loadingService.show();

            const requestData = {
              pMag: this.previousPanier.mag,
              pPanier: bonventeData,
              pNumBon: this.bonNumBon,
              Id_User: this.user.CIINT,
              Id_InstUser: Constants.CIINST
            };

            this.modifyAndCheckBonDeVente(requestData, false).then(
              (data: any) => {
                if (this.promesseAvail && Promesse_year == 1899) {
                  this.openUpDatePromesse(this.previousPanier, (result: any) => {
                    const bonventeData: BonPanier = {
                      ...this.previousPanier,
                      comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
                      dtmaj: current_date
                    };

                    bonventeData['dtpromesse'] = result.data.dtpromesse;

                    this.loadingService.show();

                    const requestData = {
                      pMag: this.previousPanier.mag,
                      pPanier: bonventeData,
                      pNumBon: this.bonNumBon,
                      Id_User: this.user.CIINT,
                      Id_InstUser: Constants.CIINST
                    };

                    this.modifyAndCheckBonDeVente(requestData).then(
                      (data: any) => {
                        this.validationBonDeCommande();
                      }
                    );
                  })

                  return;
                }

                this.validationBonDeCommande();
              });
          });
          return;
        }

        if (this.promesseAvail && Promesse_year == 1899) {
          this.openUpDatePromesse(this.previousPanier, (result: any) => {
            const bonventeData: BonPanier = {
              ...this.previousPanier,
              comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
              dtmaj: current_date
            };

            bonventeData['dtpromesse'] = result.data.dtpromesse;

            this.loadingService.show();

            const requestData = {
              pMag: this.previousPanier.mag,
              pPanier: bonventeData,
              pNumBon: this.bonNumBon,
              Id_User: this.user.CIINT,
              Id_InstUser: Constants.CIINST
            };

            this.modifyAndCheckBonDeVente(requestData).then(
              (data: any) => {
                this.validationBonDeCommande();
              }
            );
          })

          return;
        }

        this.loadingService.show();

        const requestData = {
          pMag: this.previousPanier.mag,
          pPanier: bonventeData,
          pNumBon: this.bonNumBon,
          Id_User: this.user.CIINT,
          Id_InstUser: Constants.CIINST
        };

        this.modifyAndCheckBonDeVente(requestData, false).then(
          (data: any) => {
            this.validationBonDeCommande();
          }
        );
      });
    } else {
      if (this.livraisonBondeVente && (this.previousPanier.livjour === '' || livreYear == 1899)) {
        this.openUpLivraionInfoDetails(this.previousPanier, (result: any) => {
          const bonventeData: BonPanier = {
            ...this.previousPanier,
            comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
            dtmaj: current_date
          };

          this.livraisonData = result.data;

          if (this.livraisonData) {
            bonventeData['dtliv'] = this.livraisonData.dtliv ? this.livraisonData.dtliv : current_date;
            bonventeData['livjour'] = this.livraisonData.livjour ? this.livraisonData.livjour : current_date;
            bonventeData['livconfirm'] = this.livraisonData.livconfirm;
            bonventeData['livinfo1'] = this.livraisonData.livinfo1 ? this.livraisonData.livinfo1 : '';
            bonventeData['livinfo2'] = this.livraisonData.livinfo2 ? this.livraisonData.livinfo2 : '';
            bonventeData['livdirect'] = this.livraisonData.livdirect;
            bonventeData['livdeport'] = this.livraisonData.livdeport;
          }

          let requestData = {
            pMag: this.previousPanier.mag,
            pPanier: bonventeData,
            pNumBon: this.bonNumBon,
            Id_User: this.user.CIINT,
            Id_InstUser: Constants.CIINST
          };

          if (this.emporteDiffere && ED_year == 1899) {
            this.openUpEmporteDiffere(this.previousPanier, (result: any) => {

              bonventeData['dtech'] = result.data.dtemporte;

              requestData = {
                ...requestData,
                pPanier: bonventeData,
              };

              if (this.promesseAvail && Promesse_year == 1899) {
                this.openUpDatePromesse(this.previousPanier, (result: any) => {

                  bonventeData['dtpromesse'] = result.data.dtpromesse;

                  requestData = {
                    ...requestData,
                    pPanier: bonventeData,
                  };

                  this.openUpNumeroCourt(requestData);
                })
                return;
              }
              this.openUpNumeroCourt(requestData);
            });
            return;
          }
          this.openUpNumeroCourt(requestData);
        });
        return;
      }

      if (this.emporteDiffere && ED_year == 1899) {
        this.openUpEmporteDiffere(this.previousPanier, (result: any) => {
          const bonventeData: BonPanier = {
            ...this.previousPanier,
            comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
            dtmaj: current_date
          };

          bonventeData['dtech'] = moment(result.data.dtemporte).format('YYYY-MM-DD HH:mm:ss');

          let requestData = {
            pMag: this.previousPanier.mag,
            pPanier: bonventeData,
            pNumBon: this.bonNumBon,
            Id_User: this.user.CIINT,
            Id_InstUser: Constants.CIINST
          };

          if (this.promesseAvail && Promesse_year == 1899) {
            this.openUpDatePromesse(this.previousPanier, (result: any) => {

              bonventeData['dtpromesse'] = result.data.dtpromesse;

              requestData = {
                ...requestData,
                pPanier: bonventeData,
              };

              this.openUpNumeroCourt(requestData);
            })
            return;
          }
          
          this.openUpNumeroCourt(requestData);
        });
        return;
      }

      if (this.promesseAvail && Promesse_year == 1899) {
        this.openUpDatePromesse(this.previousPanier, (result: any) => {
          const bonventeData: BonPanier = {
            ...this.previousPanier,
            comm: this.bonVenteComments !== null ? this.bonVenteComments : '',
            dtmaj: current_date
          };

          bonventeData['dtpromesse'] = result.data.dtpromesse;

          const requestData = {
            pMag: this.previousPanier.mag,
            pPanier: bonventeData,
            pNumBon: this.bonNumBon,
            Id_User: this.user.CIINT,
            Id_InstUser: Constants.CIINST
          };

          this.openUpNumeroCourt(requestData);
        })

        return;
      }

      const requestData = {
        pMag: this.previousPanier.mag,
        pPanier: bonventeData,
        pNumBon: this.bonNumBon,
        Id_User: this.user.CIINT,
        Id_InstUser: Constants.CIINST
      };

      this.openUpNumeroCourt(requestData, true);
    }
  }

  async validateEnBonDeCommande() {
    if (this.previousPanier === undefined || this.previousPanier === null || (this.previousPanier && this.previousPanier.articles.length === 0)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if (!this.userPermission['0_3']) {
      this.authorizedValidationCommandeUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '3', this.user.CIINT, this.bonNumBon);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedValidationCommandeUser = authorizationResult.data;
    }

    this.submitBondeVente('commande_validate');
  }

  validationBonDeCommande() {
    const Id_InstUser = Constants.CIINST;
    const Id_User = this.user.CIINT;
    this.bonVenteService.validateEnBonDeCommande(this.bonNumBon, this.USERCMINT, Id_User, Id_InstUser).subscribe(
      async (resp: any) => {
        if (resp.statusCode == 200) {
          if (resp.data.ErrorCode == 0) {

            if (this.authorizedValidationCommandeUser !== undefined && this.authorizedValidationCommandeUser !== null) {
              await this._addValidationCommandeHistory(this.bonNumBon);
            }

            this.storageService.removeItem('bonVenteListData');
            this.storageService.removeItem('bonVenteSearched');

            const generatedNumBon: string = resp.data.NumBon;

            if (generatedNumBon.search(';') > -1) {
              if (this.authorizedValidationCommandeUser !== undefined && this.authorizedValidationCommandeUser !== null) {
                const convertedArr = generatedNumBon.split(';');

                for await(const genNumBon of convertedArr) {
                  await this._addValidationCommandeHistory(genNumBon);
                }
              }
            } else {
              if (this.authorizedValidationCommandeUser !== undefined && this.authorizedValidationCommandeUser !== null) {
                await this._addValidationCommandeHistory(generatedNumBon);
              }
            }

            if (generatedNumBon.search(';') > -1) {
              this.router.navigate(['/bon-de-commande/', this.bonNumBon]).then(
                () => {
                  this.loadingService.hide();
                }
              );
            } else {
              let redirectTo = '/modify-bon-de-commande';
              if (this.userPermission['32_0'] && !this.userPermission['32_10']) {
                redirectTo = '/bon-de-commande-detail';
              }

              this.router.navigate([redirectTo, resp.data.NumBon, this.previousPanier.mag]).then(
                () => {
                  this.loadingService.hide();
                }
              );
            }

          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )
  }

  openUpArticleGroups(listGroups: any, currentArticle?: Article, TYPECDE?: string): Promise<any> {
    const dialogRef = this.dialog.open(VenteArticleGroupsComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier,
          listArticleGroups: listGroups,
          currentItemFormData: undefined,
          TYPECDE: TYPECDE,
          currentArticle: currentArticle || undefined
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  openUpNumeroCourt(requestData?: any, onlyNumeroCourt: boolean = false) {
    const processedRules = this.userService.processRulesParams(this.paramsList);
    if (processedRules['20_60']['VBOOL1'] != '-1') {
      this.router.navigate(['/bon-de-vente']);
      return;
    }

    const dialogRef = this.dialog.open(NumeroCourtComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        this.loadingService.show();

        if (result.data && result.data.NUMERO_COURT) {
          const current_date = moment().format('YYYY-MM-DD HH:mm:ss');    

          requestData.pPanier = {
            ...requestData.pPanier,
            numcourt: result.data.NUMERO_COURT !== null?result.data.NUMERO_COURT:((this.previousPanier !== undefined && this.previousPanier !== null && this.previousPanier.numcourt !== '')?this.previousPanier.numcourt:''),
            dtmaj: current_date
          }

          this.modifyAndCheckBonDeVente(requestData).then(
            (data: any) => {
              this.router.navigate(['/bon-de-vente']);
            }
          );
          return;
        }

        // If there is no change on bondevente data, no need to call modifyAndCheckBonDeVente Fucntion
        if (onlyNumeroCourt) {
          this.loadingService.hide();
          this.router.navigate(['/bon-de-vente']);
          return;
        }

        this.modifyAndCheckBonDeVente(requestData).then(
          (data: any) => {
            this.router.navigate(['/bon-de-vente']);
          }
        );
      }
    });
  }

  openUpArticleDetail(articleData: Article) {
    const dialogRef = this.dialog.open(ArticleDetailComponent,
      {
        height: this.isMobile ? '100%' : undefined,
        data: {
          displayType: 'dialog',
          CDART: articleData.CDART,
          flowCMINT: this.currentArticleStockMag,
          articleData: articleData
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  openUpLivraionInfoDetails(panier?: BonPanier, callback?: Function) {
    this.livraisonDialogRef = this.dialog.open(LivraisonDetailsComponent, {
      width: '40vw',
      maxWidth: '40vw',
      data: {
        livreDetail: this.livraisonData,
        magComplementData: this.magComplementData,
        panier: panier
      }
    });

    this.livraisonDialogRef.componentInstance.suiviLivraisonClosed.subscribe(
      (onSuiviLivraisonChanged: any) => {
        if (onSuiviLivraisonChanged !== undefined && onSuiviLivraisonChanged !== null && onSuiviLivraisonChanged.status === true) {
          this.loadingService.show();
          this.onLivraisonTrackingChanged(true)
        }
      }
    )

    this.livraisonDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.status === 'success') {
        callback(result);
      }
    });
  }

  openUpEmporteDiffere(panier?: BonPanier, callback?: Function) {
    const dialogRef = this.dialog.open(EmporteeDiffereComponent, {
      width: '40vw',
      maxWidth: '40vw',
      data: {
        magComplementData: this.magComplementData,
        panier: panier,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.status === 'success') {
        callback(result);
      }
    });
  }

  openUpDatePromesse(panier?: BonPanier, callback?: Function) {
    const dialogRef = this.dialog.open(DateDePromesseComponent, {
      width: '40vw',
      maxWidth: '40vw',
      data: {
        magComplementData: this.magComplementData,
        panier: panier,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.status === 'success') {
        callback(result);
      }
    });
  }

  openUpFacturationAcompte(panier?: BonPanier, callback?: Function) {
    const dialogRef = this.dialog.open(FacturationAcompteComponent, {
      width: '40vw',
      maxWidth: '40vw',
      data: {
        panier: panier,
        facturationDetail: this.previousPanier.infosfac
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.status === 'success') {
        callback(result);
      }
    });
  }

  getNoteCount(data: { NumBon: string, IDSOC: any, mag: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.getNoteCount(data).subscribe(
      (data: any) => {
        if (data.success !== undefined) {
          this.totalNotes = data.countNotes;
        }
        resolve(data);
      },
      (error: any) => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        reject(error);
      });
    });
  }

  getMagasins(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.listMagasins = [];
      this.loginService.getMagasins().subscribe(
      (data: any) => {
          if (data.length > 0) {
            this.listMagasins = [...data];
          }
          resolve(data);
      },
      (error: any) => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
      });
    });
  }

  getBonDeVenteByNumBon(numBon: string, withoutAlerts: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (numBon == 'undefined' || numBon == 'null') {
        reject('INVALID_NUMBON');
        return;
      }

      const Id_InstUser = Constants.CIINST;
      const Id_User = this.user.CIINT;

      this.bonVenteService.getBonDeVenteByNumBon(numBon, this.bonMag, Id_User, Id_InstUser).subscribe(
        async (resp) => {
          if (resp.statusCode == 200) {
            if (resp.data.ErrorCode == 0) {
              this.bonNumBon = resp.data.NumBon;
              this.previousPanier = resp.data.Panier;
              this.listBonVenteItems = this.previousPanier.articles;

              this.listBonVenteArticles = resp.data.customArticles;
              this.previousPanierClient = resp.data.customClient;
              this.chosenClient = this.previousPanierClient;

              if (this.previousPanier.numfid !== '' && this.previousPanier.numfid !== null) {
                this.previousFIDClient = resp.data.fidClient;
              }

              if (this.previousPanier.clientfacture !== '' && this.previousPanier.clientfacture !== null) {
                this.previousFactureClient = resp.data.factureClient;
              }

              if (this.previousPanier.clientlivre !== '' && this.previousPanier.clientlivre !== null) {
                this.previousLivreClient = resp.data.livreClient;
              }

              if (this.previousPanier.transporteur !== '' && this.previousPanier.transporteur !== null) {
                this.previousTranporteurLabel = resp.data.transLabel;
              }

              this.storageService.setItem('previousBonPanier', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);

              if(this.previousPanier && this.previousPanier.magstock) {
                await this.getListParams(this.previousPanier.magstock);
                this.refreshParamBasedDetail();
              }

              this.refreshByPanier(this.previousPanier);
              this.loadingService.hide();


              let livreBL_Avail = false;
              let livre_E2_BL_Avail = false;

              let BL_Avail = false;
              let E2_BL_Avail = false;

              this.previousPanier.articles.map(
                (row) => {
                  const currentArticleData = this.listBonVenteArticles.find((item) => item.CDART == row.cdart);

                  if (currentArticleData.CAOKLIV == '-1') {
                    if (row.typecde === 'BL') {
                      livreBL_Avail = true;
                    }

                    if (row.typecde === 'E2/BL') {
                      livre_E2_BL_Avail = true;
                    }
                  } else {
                    if (row.typecde === 'BL') {
                      BL_Avail = true;
                    }

                    if (row.typecde === 'E2/BL') {
                      E2_BL_Avail = true;
                    }
                  }
                }
              );

              let livreAlertMessage = '';
              if (BL_Avail && !livreBL_Avail) {
                livreAlertMessage += `<h1 class="mb-1">${this.translateService.instant('kbonajouterbl')} </h1>`;
              }

              if (E2_BL_Avail && !livre_E2_BL_Avail) {
                livreAlertMessage += `<h1 class="">${this.translateService.instant('kbonajoutere2')} </h1>`;
              }

              if (!withoutAlerts && this.livraisonAlertStatus) {
                this.livraisonAlert(livreAlertMessage);
              }

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);

              this.__clearPageVars();
              this.router.navigate(['bon-de-vente']);
              resolve(resp);
            }
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve(resp);
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      )
    })
  }

  livraisonAlert(livreAlertMessage: string, callback?: Function) {
    if (livreAlertMessage.length === 0) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: livreAlertMessage,
        okButtonOnly: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
        if (callback) {
          callback(result)
        }
      }
    });
  }

  canExit() {
    if ((this.bonNumBon !== undefined && this.previousPanier !== undefined) && (this.listBonVenteItems.length == 0 || this.bonVenteComments != this.previousPanier.comm || this.previousPanier.client == null) && !this.bonNumBonChanged) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbongoodsalesnotval'));
      return false;
    }

    this.bonNumBonChanged = false;
    return true;
  }

  returnBack() {
    this.router.navigate(['/bon-de-vente']);
  }

  async resetStockAutreMag() {
    this.loadingService.show();
    this.currentArticleStockMag = undefined;
    this.currentArticleStockMagNom = undefined;
    await this.getListParams();
    this.searchArticleCDART();
  }

  openUpDetailStock() {
    const dialogRef = this.dialog.open(ArticleStockComponent,
      {
        minWidth: '50vw',
        autoFocus: false,
        data: {
          NUMBON: this.bonNumBon,
          PANIER: this.previousPanier,
          articleData: this.currentArticle,
          CAINT: this.currentArticle.CAINT,
          stockBlocageEditable: true,
          bonVenteFlow: true,
          currentStockMag: this.currentArticleStockMag,
          enableStockMagSelection: true,
          flowType: this.flowType
        }
      }
    );

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.status === 'success') {
        if (result.type !== 'default') {
          if(this.previousPanier != undefined && this.previousPanier != null && this.previousPanier.articles.length > 0) {
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kun_seul_magasin_expedition_possible'));
            return;
          }

          this.loadingService.show();
          this.currentArticleStockMag = result.changedCMINT ? +result.changedCMINT : undefined;
          this.authorizedArticleStockMagUser = result.authorizedUser;

          // Getting the Magsin Nom
          const currentBonStockMag = this.listMagasins.find((row) => +row.CMINT == this.currentArticleStockMag)
          if(currentBonStockMag) {
            this.currentArticleStockMagNom = currentBonStockMag.CMRAISOC;
          }

          await this.getListParams(this.currentArticleStockMag);
          this.searchArticleCDART(this.currentArticleStockMag);
          return;
        }

        if(this.previousPanier && this.previousPanier.magstock) {
          return;
        }

        if (result.type === 'default' && result.STOCK_DISPO !== undefined && result.STOCK_DISPO !== null) {
          if (this.currentArticleStockMag) {
            this.loadingService.show();
            this.currentArticleStockMag = undefined;
            await this.getListParams(this.currentArticleStockMag);
            this.searchArticleCDART(this.currentArticleStockMag);
            return;
          }

          this.currentArticle.STOCKDISPO = result.STOCK_DISPO;
          this.newArticleForm.get('STOCKDISPO').setValue(this.decimalPipe.transform(result.STOCK_DISPO));
        }
      }
    });
  }

  async openUpRemiseList(type?: number, totalAmount?: number, newLine?: boolean) {
    if (!this.previousPanier || (type === 1 && !this.currentArticle)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if (newLine === true && !this.userPermission['0_1']) {
      this.authorizedLineRemiseUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '1', this.user.CIINT, this.bonNumBon);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedLineRemiseUser = authorizationResult.data;
    }

    if (newLine !== true && !this.userPermission['0_5']) {
      this.authorizedTotalRemiseUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '5', this.user.CIINT, this.bonNumBon);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedTotalRemiseUser = authorizationResult.data;
    }

    const dialogRef = this.dialog.open(RemiseListComponent,
      {
        data: {
          remiseType: type !== undefined ? type : 2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined ? totalAmount : 0, // Total amount to calculate discount
          previousChosenRemise: type !== undefined ? (type === 1 ? this.chosenLineRemise : this.chosenRemise) : this.chosenRemise,
          panier: this.previousPanier,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (type === 1 && (totalAmount === undefined || totalAmount == 0)) {
        this.newPrixVente.nativeElement.select();
        this.newPrixVente.nativeElement.focus();
      }

      if (result && result.status === 'success') {
        if (type === 1) {
          this.chosenLineRemise = [...result.data];
        } else {
          this.chosenRemise = [...result.data];
        }

        if (newLine === true) {
          if (result.data !== undefined && result.data !== null) {
            const qty = this.newArticleForm.get('QTE').value;
            if (result.data.length !== 0) {
              let row_total = 0,
                  rowTotalDiscount = 0,
                  rowTotalPercentage = 0,
                  discountedPrix = 0;

              for (let j = 0; j < this.chosenLineRemise.length; j++) {
                const remPercentage = +this.chosenLineRemise[j].REMMTAUX;

                let remTotal = this.currentArticleCAPrix.value * (remPercentage / 100);
                if (discountedPrix > 0) {
                  remTotal = discountedPrix * (remPercentage / 100);
                  discountedPrix = discountedPrix - remTotal;
                } else {
                  remTotal = this.currentArticleCAPrix.value * (remPercentage / 100);
                  discountedPrix = this.currentArticleCAPrix.value - remTotal;
                }

                rowTotalDiscount += remTotal;
                rowTotalPercentage += remPercentage;
              }

              const totalAfterDiscount = this.currentArticleCAPrix.value - rowTotalDiscount;
              row_total = totalAfterDiscount * qty;

              // Discount percentage calculation
              const newRemisePercent = ((this.currentArticleCAPrix.value - totalAfterDiscount) / this.currentArticleCAPrix.value) * 100;

              this.newArticleForm.get('ROW_TOTAL').setValue(this.utilService.formatMaskCompat(row_total));
              this.newArticleForm.get('NEW_PRIX').setValue(this.utilService.formatMaskCompat(+totalAfterDiscount.toFixed(2)));
              this.newArticleForm.get('MOTANT').setValue(this.utilService.formatMaskCompat(newRemisePercent));
            } else {
              const row_total = this.currentArticleCAPrix.value * qty;
              this.newArticleForm.get('ROW_TOTAL').setValue(this.utilService.formatMaskCompat(row_total));
              this.newArticleForm.get('NEW_PRIX').setValue(this.utilService.formatMaskCompat(this.currentArticleCAPrix.value));
              this.newArticleForm.get('MOTANT').setValue(this.utilService.formatMaskCompat(0));
            }
          }
        }

        if (newLine !== true && this.previousPanier !== undefined && this.previousPanier !== null) {
          let grand_total_amount = 0;
          const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

          this.previousPanier.articles.map((row) => {
            grand_total_amount += row.montant;
          });

          this.loadingService.show();

          if (type === 2 || type === undefined) {
            // Total remise update
            let dataRem = '';
            let remTotal = 0.0;
            let remPercentage = 0.0;

            if (this.chosenRemise !== undefined && this.chosenRemise !== null) {
              if (this.chosenRemise.length === 0) {
                // Remise 0
                remPercentage = 0.0;
                remTotal = 0.0;
                dataRem = '';
              } else {
                const remFormat: string[] = [];
                for (let j = 0; j < this.chosenRemise.length; j++) {
                  const currentRemPercentage = +this.chosenRemise[j].REMMTAUX;
                  remPercentage += currentRemPercentage;
                  remTotal += grand_total_amount * (currentRemPercentage / 100);
                  remFormat.push(this.chosenRemise[j].REMMCODE + '#' + (+currentRemPercentage) + '#' + this.utilService.formatMaskCompat(remTotal));
                }
                dataRem = remFormat.join(';');
              }
            } else {
              // Previous Panier Remise data
              remPercentage = this.previousPanier.remise;
              remTotal = this.previousPanier.remtot;
              dataRem = this.previousPanier.datarem;
            }

            const bondeVenteData: BonPanier = {
              ...this.previousPanier,
              comm: this.bonVenteComments !== null ? this.bonVenteComments : this.previousPanier.comm,
              dtmaj : current_date,

              remtot : remTotal,
              datarem : dataRem,
              remise: remPercentage,
            };

            bondeVenteData['editinfo'] = {
              pMag: this.USERCMINT,
              pNumBon: this.bonNumBon
            };

            bondeVenteData['Id_User'] = this.user.CIINT;
            bondeVenteData['Id_InstUser'] = Constants.CIINST;

            const chosenRemiseBcup = this.chosenRemise;

            this.editBonDeVente(bondeVenteData).then(
              () => {
                if (chosenRemiseBcup !== undefined && chosenRemiseBcup !== null && chosenRemiseBcup.length > 0) {
                  try {
                    this._addRemiseHistory(remTotal, this.user.CIINT, remPercentage, false);

                    if (this.authorizedTotalRemiseUser !== undefined && this.authorizedTotalRemiseUser !== null) {
                      this._addRemiseHistory(remTotal, this.authorizedTotalRemiseUser.CIINT, remPercentage);
                    }
                  } catch (err) {
                    this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                  }
                }
              }
            );
          } else {
            // Line Remise update
          }
        }
      }
    });
  }

  _addRemiseHistory(discountAmount: number, CIINT: string, discountPercentage: number, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REMISE_EN_PIED,
      NUMBON: this.bonNumBon,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: discountAmount,
      VALUER2: discountPercentage
    }, HISTORY_AUTHORIZATION);
  }

  _addLigneRemiseHistory(CDART: string, CIINT: string, oldPrix: number, newPrix: number, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REMISE_EN_LIGNE,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART,
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: oldPrix,
      VALUER2: newPrix,
    }, HISTORY_AUTHORIZATION);
  }

  openUpAddedRemiseList(type?: number, totalAmount?: number, item?: PanierArticle) {
    if (!this.previousPanier) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const currentArticleData = item ? this.listBonVenteArticles.find((el) => el.CDART == item.cdart) : undefined;

    const dialogRef = this.dialog.open(RemiseDetailComponent,
      {
        data: {
          remiseType: type !== undefined ? type : 2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined ? totalAmount : 0, // Total amount to calculate discount
          chosenRemise: type !== undefined ? (type === 1 ? this.chosenLineRemise : this.chosenRemise) : this.chosenRemise,
          panier: this.previousPanier,
          panier_article: item,
          custom_article: currentArticleData,
          USERCMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {

      }
    });
  }

  openUpPrixForceMotif() {
    const dialogRef = this.dialog.open(MotifForcePrixComponent,
      {
        data: {
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
          if (result.data.force_motif !== '') {
            this.newArticleForm.get('PRIX_FORCE').setValue(true);
            this.newArticleForm.get('MOTIF_PRIX').setValue(result.data.force_motif);
            this.newArticleForm.get('MOTIF_PRIX_LBL').setValue(result.data.MOTIF_LABEL);

            this.loadingService.show();
            this.addNewLineItem();
          } else {
            this.newArticleForm.get('PRIX_FORCE').setValue(false);
            this.newArticleForm.get('MOTIF_PRIX').setValue('');
            this.newArticleForm.get('MOTIF_PRIX_LBL').setValue('');
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonprixoblig'));
          }
      }
    });
  }

  openUpDateLib3Popup(): Observable<any> {
    const dialogRef = this.dialog.open(DateTimeComponent,
      {
        data: {
          CMINT: this.USERCMINT,
          panier: this.previousPanier,
          currentArticle: this.currentArticle,
          popupType: 'new',
          flowType: this.flowType,
          chosenClient: this.chosenClient,
        }
      }
    );

    return dialogRef.afterClosed();
  }

  async openUpForcageRules() {
    if (!this.userPermission['0_2']) {
      this.authorizedForcageUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '2', this.user.CIINT, this.bonNumBon, this.newArticleForm.get('CDART').value);

      if (authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedForcageUser = authorizationResult.data;
    }

    const filteredRules = this.forcage_rules.filter(
      (rule) => {
        return rule.type === this.forcageType;
      }
    );

    const dialogRef = this.dialog.open(ForcageBondeComponent,
      {
        data: {
          forcage_rules: filteredRules,
          type: this.forcageType,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        if (result.motif !== undefined && result.motif !== null && result.motif !== '') {
          this.chosenForcage = result.motif;

          this.definingForcageTypeDeRules('force');
        }
      }
    });
  }

  unlockForcage() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: this.translateService.instant('kbonannforce'),
        description: '',
        buttons: [
          this.translateService.instant('ksyes'),
          this.translateService.instant('kartno')
        ]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
        this.chosenForcage = undefined;
        this.definingForcageTypeDeRules('revert');
      }
    });
  }

  getMagComplementData() {
    return new Promise((resolve, reject) => {
      this.magComplementService.getMagComplementData(this.USERCMINT).subscribe(
        (resp) => {
          if (resp.statusCode == 200) {
            this.magComplementData = resp.data[0];
          }
          resolve(resp);
        },
        error => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
        }
      );
    });
  }

  onCloseBondeVente() {
    if (this.readOnly) {
      this.router.navigate(['/bon-de-vente']);
      return;
    }

    if (this.previousPanier !== undefined && this.previousPanier !== null) {
      this.deleteConfirmation(this.bonNumBon, this.previousPanier);
      return;
    }

    this.router.navigate(['/bon-de-vente']);
  }

  deleteConfirmation(numBon: string, item: BonPanier): void {
    const message = `
    <h1 class="text-left mb-3">${this.translateService.instant('kbonsursupprimer')}</h1>
    <h1 class="text-left pl-2">
      ${this.translateService.instant('kbonNum')} : ${numBon} <br>
      ${this.translateService.instant('kbonpour')} : ${item.client?.nom != null ? item.client?.nom : ''}${item.client?.prenom != null ? ' ' + item.client?.prenom : ''}<br>
      ${this.translateService.instant('kbonmontant')} : ${this.currencyPipe.transform(item.montant, null, false)}
    </h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
        this.loadingService.show();
        this.deleteBonDeVente({ pMag: this.USERCMINT, pNumBon: numBon, Id_User: this.user.CIINT, Id_InstUser: this.user.IDINSTINT });
      }
    });
  }

  __clearPageVars() {
    this.storageService.removeItem('previousNumBon');
    this.storageService.removeItem('previousBonPanier');

    this.bonNumBon = undefined;
    this.previousPanier = undefined;
    this.listBonVenteItems = undefined;
    this.listBonVenteArticles = undefined;
    this.bonVenteComments = '';

    this.currentArticle = undefined;
    this.originMagArticle = undefined;
    this.newArticleForm.patchValue({
      CALIB1: '',
      CAPRIX: '',
      NEW_PRIX: this.utilService.formatMaskCompat(0),
      ROW_TOTAL: '',
      TYPECDE: '',
      STOCKDISPO: ''
    });

    this.qtyLength = 0;
    this.qtyWidth = 0;
    this.qtyHeight = 0;

    this.newArticleForm.get('QTE').disable({ onlySelf: true });
    this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
    this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
  }

  deleteBonDeVente(data: { pMag: number, pNumBon: string, Id_User: any, Id_InstUser: any }) {
    this.bonVenteService.removeBonvente(data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if (resp.statusCode == 200) {
          if (resp.data.ErrorCode == 0) {

            this.__clearPageVars();

            this.storageService.removeItem('bonVenteListData');
            this.router.navigate(['/bon-de-vente']);
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
        } else {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      },
      error => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  onSwipe(e: any, index: number, item: PanierArticle) {
    if ((this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21'])) {
      return;
    }

    if (e.deltaX > 0) {
      // Right Swipe
      const element = document.getElementById('swipeItem-' + index);
      element.style.transform = 'translateX(0)'
      element.classList.remove('swiped');
    } else {
      // Left Swipe
      this.__resetPreviousSwipes('.mat-list-item');

      const element = document.getElementById('swipeItem-' + index);
      element.classList.add('swiped');
      element.style.transform = 'translateX(-100px)'
    }
  }

  __resetPreviousSwipes(listEl: string) {
    const allSwipeAbles = document.querySelectorAll(listEl);
    allSwipeAbles.forEach((el: any, i) => {
      el.classList.remove('swiped');
      el.style.transform = 'translateX(0)';
    })
  }

  supprimerLineItem(currentItem: PanierArticle) {
    if (this.previousPanier === undefined || this.previousPanier === null) {
      return;
    }

    this.loadingService.show();
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let article_data: PanierArticle[] = [];
    article_data = this.previousPanier.articles.filter(
      (item) => {
        if (currentItem.opt < 0 && item.opt == Math.abs(currentItem.opt)) {
          return false;
        }

        return item.idlig != currentItem.idlig;
      }
    );

    const bondeVenteData = {
      ...this.previousPanier,
      articles: article_data,
      dtmaj : current_date
    };

    if (article_data.length === 0) {
      // To reset the magstock (Autre Stock magasin)
      // when all the articles from the bon has been removed.
      bondeVenteData.magstock = 0;
    }

    bondeVenteData['editinfo'] = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData);
  }

  openUpSearchArticle(event: any = '', filterData?: any) {
    if (event) {
      event.preventDefault();
    }

    let CMINT = this.USERCMINT;
    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    let passData = {
      CMINT,
      flowType: 'bonde_vente',
      CDART: this.newArticleForm.get('CDART').value,
      stockAutreMag: this.currentArticleStockMag,
      stockAutreMagNom: this.currentArticleStockMagNom
    };

    if (filterData !== undefined && filterData != null) {
      passData = {
        CMINT,
        flowType: 'bonde_vente',
        stockAutreMag: this.currentArticleStockMag,
        stockAutreMagNom: this.currentArticleStockMagNom,
        ...filterData
      };
    }

    const dialogRef = this.dialog.open(SearchArticleComponent,
      {
        maxHeight: this.isMobile ? '95vh' : '90vh',
        data: passData
      }
    );

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.status === 'success') {
          if (result.data !== undefined) {
            this.loadingService.show();

            if (result.hasOwnProperty('multi') && result.multi === true) {
              this.__processMultiChosenArticles(result.data); // Processing chosen multi articles
            } else {
              const chosenArticle = result.data;
              this.currentArticle = chosenArticle;

              if (this.currentArticleStockMag) {
                await this.getArticleByCdartUserCmint(this.currentArticle.CDART, this.USERCMINT);
              }

              const [CAPRIX, NEW_PRIX, ROW_TOTAL] = this.__getNewItemPrix(this.originMagArticle, this.currentArticle);

              this.newArticleForm.patchValue({
                CDART: this.currentArticle.CDART,
                CALIB1: this.currentArticle.CALIB3,
                CAPRIX: CAPRIX,
                NEW_PRIX: NEW_PRIX,
                ROW_TOTAL: ROW_TOTAL,
                TYPECDE: '',
                STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
                GROUP_AVAIL: +this.currentArticle.GROUP_COUNT > 0
              });

              this.newArticleForm.get('QTE').enable({ onlySelf: true });
              this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
              this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

              if (this.cdartInput && this.isMobile) {
                this.cdartInput.nativeElement.select();
              }

              if (this.newArticleQty && !this.isMobile) {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
                this.newArticleQty.nativeElement.focus();
                this.newArticleQty.nativeElement.select();
                setTimeout(() => {
                  this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
                }, 50)
              }

              this.definingTypeDeRules();
              this.loadingService.hide();
            }
          } else {
            this.currentArticle = undefined;
            this.originMagArticle = undefined;
            this.newArticleForm.patchValue({
              CALIB1: '',
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              GROUP_AVAIL: false
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;

            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
          }
      }
    });
  }

  async __processMultiChosenArticles(data: any[]) {
    console.log('Processing Multi Articles: ', data);

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
    const processedRules = this.userService.processRulesParams(this.paramsList);
    let grand_total_amount = 0;
    let bondeVenteData = {
      articles: [],
      mag: this.USERCMINT,
      clientlivre : '',
      clientfacture : '',
      numfid: '',
      comm : this.bonVenteComments,

      idinstuser : +this.user.IDINSTINT,
      iduser : +this.user.CIINT,
      libuser: this.currentUserName,

      idinstusermaj: +this.user.IDINSTINT,
      idusermaj: +this.user.CIINT,
      libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.currentUserName,

      userexterne : '',
      numdevis: '',

      dtliv : '1899-12-28T00:00:00.000+01:00', // Livraison Date
      dtlivsouhait : '1899-12-28T00:00:00.000+01:00', //
      livjour : '', // A (Afternoon) / M (Morning)
      livconfirm : false, // Confirmation de livraison
      livinfo1 : '', // Livraison Info 1
      livinfo2 : '', // Livraison Info 2
      livdeport : false, // Livraison Transport
      livdirect : false, // Livraison Directe

      transporteur : '',
      numexterne : '',
      remtot : 0,
      datafid : '',
      datafidaco : '',
      dataopeco : '',
      datarem : '',
      vteemp : 0,
      pseudo : '',
      place : '',
      infocde : '',
      numcourt : '',
      statut : 1,
      dtsaisie : current_date,
      dtmaj : current_date,
      dtech : '1899-12-28T00:00:00.000+01:00',
      dtrel : '1899-12-28T00:00:00.000+01:00',
      dtpromesse : '1899-12-28T00:00:00.000+01:00',
      ecartpromesse : '',
      remise: 0,
      canal : 0,
      montant : grand_total_amount,
      montantrestant : 0,
      preparation: false,
      retrait1h: false,
      montantHT: grand_total_amount,
      typecde: '',
      paiements: [],
      infosfac: {
        totEI: 0,
        totED: 0,
        totBL: 0,
        totE2: 0,
        mntED: 0,
        mntBL: 0,
        mntE2: 0,
        pourED: 100,
        pourBL: 100,
        pourE2: 20
      }
    };

    let client_data: PanierClient;

    if (this.chosenClient && this.chosenClient !== null) {
      client_data = {
        mag: +this.chosenClient.CMINT,
        civil: +this.chosenClient.IDCIV,
        nom: this.chosenClient.TRAISOC,
        prenom: this.chosenClient.TPRENOM,
        adr1: this.chosenClient.TADR1,
        adr2: this.chosenClient.TADR2,
        adr3: this.chosenClient.TADR3,
        adr4: this.chosenClient.TADR4,
        codpos: this.chosenClient.CODPOS,
        ville: this.chosenClient.TVILLE,
        pays: this.chosenClient.CODPAYS,
        tel: this.chosenClient.TTEL,
        fax: this.chosenClient.TFAX,
        gsm: this.chosenClient.TPORT,
        email: this.chosenClient.TEMAIL,
        idinstcli: +Constants.CIINST,
        idcli: +this.chosenClient.IDTIERS
      }
    }

    if (this.previousPanier !== undefined && this.previousPanier !== null && this.previousPanier.client !== undefined && this.previousPanier.client !== null) {
      client_data = this.previousPanier.client;
    }

    const dataRem = (this.previousPanier !== undefined && this.previousPanier !== null) ? this.previousPanier.datarem : '';
    const remTotal = (this.previousPanier !== undefined && this.previousPanier !== null) ? this.previousPanier.remtot : 0;
    const remPercentage = (this.previousPanier !== undefined && this.previousPanier !== null) ? this.previousPanier.remise : 0;

    let article_data: PanierArticle[] = [];

    if (this.previousPanier !== undefined && this.previousPanier !== null && this.previousPanier.articles !== undefined && this.previousPanier.articles !== null && this.previousPanier.articles.length > 0) {
      article_data = [...this.previousPanier.articles];
    }

    for (const rowMultiArticle of data) {
      const row: { cdart: string, status: boolean, data: Article } = rowMultiArticle;
      console.log('ROW: ', row);

      const ROW_PRIX = row.data.calculated_price ? +row.data.calculated_price : 0;
      const ROW_QTY = 1;
      const ROW_TOTAL = +ROW_PRIX * ROW_QTY;

      const currentCdart = row.cdart;
      let currentOpt = 0;
      let currentIdLigne = 1;

      let dtlib3 = '1899-12-30T00:00:00.000+02:00';
      let strlib2 = '',
          strlib3 = '',
          dtlib4 = '1899-12-30T00:00:00.000+02:00';

      const checkWefox = this.checkArticleWefoxStatus(currentCdart, processedRules);
      if (checkWefox.wefoxStat) {
        this.loadingService.hide();
        const dateLib3Res = await this.openUpDateLib3Popup().toPromise();
        if (dateLib3Res !== undefined && dateLib3Res !== null && dateLib3Res.status == 'success') {
          dtlib3 = this.utilService.formatDateToWS(dateLib3Res.data.dtlib3);
          strlib2 = dateLib3Res.data.strlib2;
          strlib3 = dateLib3Res.data.strlib3;

          if (dateLib3Res.data.dtlib4 != null && dateLib3Res.data.dtlib4 != '') {
            dtlib4 = dateLib3Res.data.dtlib4;  
          }
        } else {
          return; 
        }
        this.loadingService.show();
      }

      let description = '';
      if (row.data.CAUNVTE === 'M3') {
        description = `L ${this.qtyLength} x l ${this.qtyWidth} x h ${this.qtyHeight}`;
      }

      let ROW_TYPECDE = 'E2/ED';
      if (!isNaN(row.data.STOCKDISPO) && +row.data.STOCKDISPO > 0) {
        ROW_TYPECDE = 'EI';
      }

      if(row.data.CAGESTOCK == "0" && row.data.CAOKLIV == "-1") {
        ROW_TYPECDE = "BL";
      }

      if (this.previousPanier !== undefined && this.previousPanier !== null) {
        if (+row.data.GROUP_COUNT > 0) {
          article_data.map((_item_art: PanierArticle) => {
            if (_item_art.opt < 0) {
              currentOpt = _item_art.opt;
            }
          });

          currentOpt = currentOpt - 1;
        }

        currentIdLigne = article_data.length + 1;
      } else {
        if (+row.data.GROUP_COUNT > 0) {
          currentOpt = -1;
        }
      }

      article_data.push({
        idlig: currentIdLigne,
        cdart: currentCdart,
        quantite: parseFloat(ROW_QTY.toString()),
        prix: parseFloat(ROW_PRIX.toString()), // Article prix
        prixforce: false,
        motifprixforce: '',
        commlig1: '',
        commlig2: '',
        prixachatHT: 0.0,
        prixachatTTC: 0.0,
        tauxTVA: '', // Optional
        typecde: ROW_TYPECDE,
        remlig: 0, // Total remise amount of the row
        remtot: 0,
        datafid: '',
        datafidaco: '',
        dataopeco: '' ,
        datarem: '',
        okdiff: false,
        bundle: 0,
        opt: currentOpt,
        lib: row.data.CALIB3,
        idinstuser: +this.user.IDINSTINT,
        iduser: +this.user.CIINT,
        libuser: this.currentUserName,
        dtpromesse: current_date, // 2018-03-27 10:04:41
        dtlib3: dtlib3,
        dtlib4: dtlib4,
        strlib2: strlib2,
        strlib3: strlib3,
        coinsolde: false,
        expo: false,
        motifcde: '',
        numcde: '',
        prixupv: parseFloat(ROW_PRIX.toString()), // New Price (Typeable) - PU Ven.
        montant: parseFloat(ROW_TOTAL.toString()), // Row Subtotal - PV Total
        remise: 0,
        emplacement: '',
        statut: row.data.CAECODE === 'A' ? -1 : 0,
        dtmaj: current_date
      });

      if (+row.data.GROUP_COUNT > 0) {
        currentOpt = Math.abs(currentOpt);

        await this._searchMultiArticleGroupsCAINT(row.data.CAINT, false);

        if (this.articleGroupsRawData.some((_r: any) => _r.TYPECDE == null || ROW_TYPECDE.indexOf(_r.TYPECDE) > -1)) {
          const groupsPopupResult = await this.openUpArticleGroups(this.listArticleGroups, row.data, ROW_TYPECDE);

          if (groupsPopupResult !== undefined && groupsPopupResult !== null && groupsPopupResult.status !== undefined && groupsPopupResult.status === 'success') {
            this.currentArticleChosenGroups = groupsPopupResult.data;

            this.currentArticleChosenGroups.GROUPS.map((_row_group: any) => {
              _row_group.GROUP_CHOSEN_OPTIONS.map((_row_option: any) => {
                if (_row_option.SELECTED) {
                  currentIdLigne = currentIdLigne + 1;

                  article_data.push({
                    idlig: currentIdLigne,
                    cdart: _row_option.CDART,
                    quantite: parseFloat(ROW_QTY.toString()),
                    prix: parseFloat(_row_option.PRIX.toString()), // Article prix
                    prixforce: false,
                    motifprixforce: '',
                    commlig1: '',
                    commlig2: '',
                    prixachatHT: 0.0,
                    prixachatTTC: 0.0,
                    tauxTVA: '', // Optional
                    typecde: ROW_TYPECDE,
                    remlig: 0, // Total remise amount of the row
                    remtot: 0,
                    datafid: '',
                    datafidaco: '',
                    dataopeco: '' ,
                    datarem: '',
                    okdiff: false,
                    bundle: 0,
                    opt: currentOpt,
                    lib: _row_option.CALIB,
                    idinstuser: +this.user.IDINSTINT,
                    iduser: +this.user.CIINT,
                    libuser: this.currentUserName,
                    dtpromesse: current_date, // 2018-03-27 10:04:41
                    coinsolde: false,
                    expo: false,
                    motifcde: '',
                    numcde: '',
                    prixupv: parseFloat(_row_option.PRIX.toString()), // New Price (Typeable) => PU Ven.
                    montant: parseFloat((_row_option.PRIX * _row_option.QTY).toString()), // Row Subtotal - PV Total
                    remise: 0,
                    emplacement: '',
                    statut: 0,
                    dtmaj: current_date
                  });
                }
              })
            })
          }
        }
      }
    }

    article_data.map((row) => {
      grand_total_amount += row.montant;
    });

    if (this.previousPanier !== undefined && this.previousPanier !== null) {
      bondeVenteData = {
        ...this.previousPanier,
        articles: article_data,
        clientlivre : this.previousPanier.clientlivre !== null ? this.previousPanier.clientlivre : '',
        clientfacture : this.previousPanier.clientfacture !== null ? this.previousPanier.clientfacture : '',
        comm: this.bonVenteComments !== null ? this.bonVenteComments : this.previousPanier.comm,

        idinstusermaj: +this.user.IDINSTINT,
        idusermaj: +this.user.CIINT,
        libusermaj: this.user ? this.user.CIPRENOM + ' ' + this.user.CINOM[0] + '.' : this.currentUserName,

        remtot : remTotal,
        datarem : dataRem,
        dtmaj : current_date,
        remise: remPercentage,
        montant : grand_total_amount,
        montantHT: grand_total_amount,
        infosfac: this.previousPanier.infosfac
      };
    } else {
      bondeVenteData.articles = article_data;
      bondeVenteData.remtot = remTotal;
      bondeVenteData.datarem = dataRem;
      bondeVenteData.remise = remPercentage;
      bondeVenteData.montant = grand_total_amount;
      bondeVenteData.montantHT = grand_total_amount;
    }

    if (this.chosenFidelite) {
      bondeVenteData['numfid'] = this.chosenFidelite.NUMFID;
    }

    if (this.livraisonData) {
      bondeVenteData['dtliv'] = this.livraisonData.dtliv ? this.livraisonData.dtliv : current_date;
      bondeVenteData['livjour'] = this.livraisonData.livjour ? this.livraisonData.livjour : current_date;
      bondeVenteData['livconfirm'] = this.livraisonData.livconfirm;
      bondeVenteData['livinfo1'] = this.livraisonData.livinfo1 ? this.livraisonData.livinfo1 : '';
      bondeVenteData['livinfo2'] = this.livraisonData.livinfo2 ? this.livraisonData.livinfo2 : '';
      bondeVenteData['livdirect'] = this.livraisonData.livdirect;
      bondeVenteData['livdeport'] = this.livraisonData.livdeport;
    }

    if (client_data !== undefined && client_data !== null) {
      bondeVenteData['client'] = client_data;
    } else {
      bondeVenteData['client'] = '';
    }

    bondeVenteData['editinfo'] = {
      pMag: this.USERCMINT,
      pNumBon: this.bonNumBon
    };

    bondeVenteData['Id_User'] = this.user.CIINT;
    bondeVenteData['Id_InstUser'] = Constants.CIINST;

    this.editBonDeVente(bondeVenteData).then(
      async (resp: any) => {
        this.chosenForcage = undefined;
        this.commander = false;

        this.openCommandeDetail();
      }
    );
  }

  viewBonVenteHistory() {
    if (!this.previousPanier) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    let noteReadOnly = this.readOnly;
    if (!this.userPermission['4_1']) {
      noteReadOnly = true;
    }

    // Overriding the Notes access to let the user to Add Notes
    // Only if the Bon is created with the stock magasin which the current logged in user belongs to.
    if (this.readOnly && this.currentArticleStockMag == this.USERCMINT) {
      noteReadOnly = false;
    }

    this.historiqueData = {
      panier: this.previousPanier,
      numBon: this.bonNumBon,
      CMINT: this.previousPanier.mag,
      readOnly: noteReadOnly
    }
    if (!this.isMobile) {
      const dialogRef = this.dialog.open(HistoriqueBondeComponent,
        {
          data: this.historiqueData
        }
      );

      dialogRef.componentInstance.onNoteAdded.subscribe(
        (onNoteAdded: any) => {
          if (onNoteAdded !== undefined && onNoteAdded !== null && onNoteAdded.status === true) {
            const countData = {
              NumBon: this.bonNumBon,
              IDSOC: Constants.IDSOC,
              mag: this.previousPanier.mag
            };

            this.getNoteCount(countData);
          }
        }
      )

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.status === 'success') {

        }
      });
    }
  }
  viewArticleDetail() {
    if (this.isMobile) {
      const dialogRef = this.dialog.open(ArticleDetailComponent, {
          width: '60vw',
          height: '100%',
          data: {
             articleData: this.article,
             flowCMINT: this.currentArticleStockMag,
             displayType: 'dialog'
          }
        }
      );
    }
  }

  openAdvancedSearch() {
    const dialogRef = this.dialog.open(ArticleFilterComponent, {
        width: '60vw',
        maxHeight: this.isMobile ? '95vh' : undefined,
        autoFocus: false,
        data: {
          fetchType: 'new_load'
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        const data = {
          formType: 'advancedSearch',
          filterData: result
        };
        this.openUpSearchArticle(undefined, data);
      }
    });
  }

  closeAdvancedSearch() {
    if (this.advancedDialogRef) {
      this.advancedDialogRef.close();
    }
  }

  allowNumeric(event) {
    const pattern = /^[0-9\.]+$/;
    const inputChar = String.fromCharCode(event.keyCode);
    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }
  }

  _barCodeLogicCheck = (value: string, scanner = true) => {
    return new Promise((resolve, reject) => {
      if (value !== undefined && value != null) {

        if (value.indexOf('~') > -1) {
          const [CDART, PRIX] = value.split('~');
          this.newArticleForm.patchValue({ CDART });

          this.searchArticleCDART();
          resolve(CDART);
          return;
        }

        if (value.length === Constants.BARCODE_HIGHEST_LENGTH) {
          let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
          CDART = CDART.replace(/^0+/, '');

          this.newArticleForm.patchValue({ CDART });

          this.searchArticleCDART();
          resolve(CDART);
          return;
        }

        if (scanner) {
          this.searchArticleByCMEAN(value);
          resolve(value);
        } else {
          reject(value);
        }
      }
    });
  }

  openScanArticle(event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: this.isMobile ? '95vh' : undefined,
        autoFocus: false
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result && result.status === 'success') {
        this._barCodeLogicCheck(result.data);
      }
    });
  }

  setStep(index: number) {
    this.step = index;

    if (index === 0) {
      setTimeout(() => {
        if (this.cdartInput) {
          this.focusMonitor.focusVia(this.cdartInput.nativeElement, 'touch')
        }
      }, 50)
    }
  }

  __scrollToTab(el: string) {
    setTimeout(() => {
      if (this.cdartInput) {
        this.cdartInput.nativeElement.setAttribute('inputmode', 'none');
        this.focusMonitor.focusVia(this.cdartInput.nativeElement, 'keyboard');
        this.cdartInput.nativeElement.setAttribute('inputmode', 'text');
      }
    }, 200);

    setTimeout(() => {
      const element = document.getElementById(el);
      element.scrollIntoView({ behavior: 'smooth' })
    }, 80)
  }

  openCommandeDetail() {
    this.step = 1;
  }

  get checkE2TypesToEnableForcerCommande() {
    const processedRules = this.userService.processRulesParams(this.paramsList);
    return processedRules['20_3']['VBOOL4'] == '-1' || processedRules['20_4']['VBOOL2'] == '-1' || processedRules['20_4']['VBOOL3'] == '-1' || processedRules['20_4']['VBOOL4'] == '-1'
  }
  
  async filterAndUpdateVenteType() {
    const filteredRules = this.typeVenteRules.data.filter((row) => !row.disabled);

    if (filteredRules.length === 0) {
      this.typeVenteRules.data = [];
      this.newArticleForm.get('TYPECDE').setValue('');
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kprix_commande_impossible_magasin')).toPromise();
      return;
    }

    this.newArticleForm.get('TYPECDE').setValue(filteredRules[0].value);
  }

  async definingTypeDeRules() {
    this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });
    const processedRules = this.userService.processRulesParams(this.paramsList);
    let processedUserRules = this.userService.processRulesParams(this.userParamsList);

    if (this.userParamsList === undefined || this.userParamsList === null) {
        processedUserRules = processedRules;
    }

    if (this.currentArticle.CAGESTOCK != '0' && this.currentArticle.CAOKLIV != '-1') {
      if (this.currentArticle.STOCKDISPO > 0) {
        this.forcageType = 'commande';
        this.forcage_text = 'kbonforcecommand';
        this.commander = false;
        this.typeVenteRules = {
          title: 'kbontypedevente',
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: 'EI',
              disabled: (processedRules['20_3']['VBOOL1'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: 'ED',
              disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: 'BL',
              disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
            },
          ]
        };

        this.filterAndUpdateVenteType();
      } else {
        this.forcageType = 'vente';
        this.commander = true;
        this.forcage_text = 'kbonforecsale';

        this.typeVenteRules = {
          title: 'kbondevenircmd',
          data: [
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: 'E2/ED',
              disabled: (processedRules['20_4']['VBOOL3'] == '-1') ? false : true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: 'E2/BL',
              disabled: (processedRules['20_4']['VBOOL4'] == '-1') ? false : true
            },
          ]
        };

        this.filterAndUpdateVenteType();
      }
    } else {
      this.commander = false;
      this.forcageType = undefined;
      this.forcage_text = '';
      if (this.currentArticle.CAOKLIV == '-1') {
        this.typeVenteRules = {
          title: 'kbontypedeli',
          data: [
            {
              paramKey: '20_4;VSTR1;VBOOL1',
              label: processedUserRules['20_4']['VSTR1'],
              value: 'BL',
              disabled: (processedRules['20_4']['VBOOL1'] == '-1') ? false : true
            },
            {
              paramKey: '20_4;VSTR2;VBOOL2',
              label: processedUserRules['20_4']['VSTR2'],
              value: 'E2/BL',
              disabled: (processedRules['20_4']['VBOOL2'] == '-1') ? false : true
            },
          ]
        };

        this.newArticleForm.get('STOCKDISPO').setValue(this.translateService.instant('kbonartliv'));
        this.filterAndUpdateVenteType();
      } else {

        this.typeVenteRules = {
          title: 'kbontypedecomvente',
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: 'EI',
              disabled: (processedRules['20_3']['VBOOL1'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: 'ED',
              disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: 'BL',
              disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
            },
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: 'E2/ED',
              disabled: (processedRules['20_4']['VBOOL3'] == '-1') ? false : true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: 'E2/BL',
              disabled: (processedRules['20_4']['VBOOL4'] == '-1') ? false : true
            },
          ]
        };

        this.newArticleForm.get('STOCKDISPO').setValue(this.translateService.instant('kartmang'));
        this.filterAndUpdateVenteType();
      }
    }

    if (this.currentArticle.etatart === 'G') {
      this.commander = true;
    }

    // Wefox article check
    const checkWefox = this.checkArticleWefoxStatus(this.currentArticle.CDART, processedRules);
    if (checkWefox.wefoxStat) {
      const typeCdeArt = checkWefox.wefoxType.split(Constants.WEFOX_TYPE_SEPARATOR);
      this.newArticleForm.get('TYPECDE').setValue(typeCdeArt ? typeCdeArt[0] : '');

      this.quantityDisabled = true;
      this.newArticleForm.get('QTE').disable({ onlySelf: true });

      if(checkWefox.wefoxTypeRestriction) {
        this.typeVenteRules = {
          title: 'kbontypedecomvente',
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: "EI",
              disabled: (processedRules['20_3']['VBOOL1'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: "ED",
              disabled: (processedRules['20_3']['VBOOL2'] == '-1')?false:true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: "BL",
              disabled: (processedRules['20_3']['VBOOL3'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: "E2/ED",
              disabled: (processedRules['20_4']['VBOOL3'] == '-1')?false:true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: "E2/BL",
              disabled: (processedRules['20_4']['VBOOL4'] == '-1')?false:true
            },
          ]
        };

        this.typeVenteRules.data = this.typeVenteRules.data.filter((row) => {
          return typeCdeArt.some((_r: string) => row.value.includes(_r));
        });
      }
    } else {
      this.quantityDisabled = false;
      this.newArticleForm.get('QTE').enable({ onlySelf: true });
    }

    this.handleAutreStockArticleTypeRules(processedRules);
  }

  definingForcageTypeDeRules(type: 'force' | 'revert') {
    const processedRules = this.userService.processRulesParams(this.paramsList);
    let processedUserRules = this.userService.processRulesParams(this.userParamsList);

    if (this.userParamsList === undefined || this.userParamsList === null) {
        processedUserRules = processedRules;
    }

    if (this.forcageType === 'commande') {
      this.commander = type === 'force' ? true : false;

      if (type === 'force') {
        this.typeVenteRules = {
          title: 'kbondevenircmd',
          data: [
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: 'E2/ED',
              disabled: (processedRules['20_4']['VBOOL3'] == '-1') ? false : true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: 'E2/BL',
              disabled: (processedRules['20_4']['VBOOL4'] == '-1') ? false : true
            },
          ]
        };

        const prevType = this.newArticleForm.get('TYPECDE').value;

        if (processedRules['20_4']['VBOOL3'] == '-1' && prevType == 'ED') {
          this.newArticleForm.get('TYPECDE').setValue('E2/ED');
        } else if (processedRules['20_4']['VBOOL4'] == '-1' && prevType == 'BL') {
          this.newArticleForm.get('TYPECDE').setValue('E2/BL');
        } else {
          this.newArticleForm.get('TYPECDE').setValue('E2/ED');
        }

        // Handling for autre stock article 
        this.handleAutreStockArticleTypeRules(processedRules);
      } else {
        this.typeVenteRules = {
          title: 'kbontypedevente',
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: 'EI',
              disabled: (processedRules['20_3']['VBOOL1'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: 'ED',
              disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: 'BL',
              disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
            },
          ]
        };

        const prevType = this.newArticleForm.get('TYPECDE').value;
        if (processedRules['20_3']['VBOOL2'] == '-1' && prevType == 'E2/ED') {
          this.newArticleForm.get('TYPECDE').setValue('ED');
        } else if (processedRules['20_3']['VBOOL3'] == '-1' && prevType == 'E2/BL') {
          this.newArticleForm.get('TYPECDE').setValue('BL');
        } else {
          this.newArticleForm.get('TYPECDE').setValue('EI');
        }

        // Handling Forcer la commande revert
        this.handleAutreStockArticleTypeRules(processedRules);
      }
    } else {
      this.commander = type === 'force' ? false : true;

      if (type === 'force') {
        this.typeVenteRules = {
          title: 'kbontypedevente',
          data: [
            {
              paramKey: '20_3;VSTR1;VBOOL1',
              label: processedUserRules['20_3']['VSTR1'],
              value: 'EI',
              disabled: (processedRules['20_3']['VBOOL1'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR2;VBOOL2',
              label: processedUserRules['20_3']['VSTR2'],
              value: 'ED',
              disabled: (processedRules['20_3']['VBOOL2'] == '-1') ? false : true
            },
            {
              paramKey: '20_3;VSTR3;VBOOL3',
              label: processedUserRules['20_3']['VSTR3'],
              value: 'BL',
              disabled: (processedRules['20_3']['VBOOL3'] == '-1') ? false : true
            },
          ]
        };

        const prevType = this.newArticleForm.get('TYPECDE').value;
        if (processedRules['20_3']['VBOOL2'] == '-1' && prevType == 'E2/ED') {
          this.newArticleForm.get('TYPECDE').setValue('ED');
        } else if (processedRules['20_3']['VBOOL3'] == '-1' && prevType == 'E2/BL') {
          this.newArticleForm.get('TYPECDE').setValue('BL');
        } else {
          this.newArticleForm.get('TYPECDE').setValue('EI');
        }

      } else {
        this.typeVenteRules = {
          title: 'kbondevenircmd',
          data: [
            {
              paramKey: '20_4;VSTR3;VBOOL3',
              label: processedUserRules['20_4']['VSTR3'],
              value: 'E2/ED',
              disabled: (processedRules['20_4']['VBOOL3'] == '-1') ? false : true
            },
            {
              paramKey: '20_4;VSTR4;VBOOL4',
              label: processedUserRules['20_4']['VSTR4'],
              value: 'E2/BL',
              disabled: (processedRules['20_4']['VBOOL4'] == '-1') ? false : true
            },
          ]
        };

        const prevType = this.newArticleForm.get('TYPECDE').value;
        if (processedRules['20_4']['VBOOL3'] == '-1' && prevType == 'ED') {
          this.newArticleForm.get('TYPECDE').setValue('E2/ED');
        } else if (processedRules['20_4']['VBOOL4'] == '-1' && prevType == 'BL') {
          this.newArticleForm.get('TYPECDE').setValue('E2/BL');
        } else {
          this.newArticleForm.get('TYPECDE').setValue('E2/ED');
        }
      }
    }
  }

  async getListParams(overrideCMINT?: any): Promise<void> {
    return new Promise((resolve, reject) => {

      let CMINT = overrideCMINT;
      if (CMINT === undefined || CMINT === null) {
        CMINT = this.USERCMINT;
      }

      if (CMINT != this.USERCMINT && (this.userParamsList === undefined || this.userParamsList === null)) {
        this.userParamsList = this.paramsList;
      }

      this.userService.getRulesParams(CMINT, this.USERCMINT).subscribe(
        (resp: any) => {
            if (resp.success !== undefined && resp.success === 'ok') {
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        },
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  get userAllowedToModifyOrRemove(): boolean {
    return this.previousPanier && this.previousPanier.mag == this.USERCMINT;
  }

  get displayLivraisonTotale() {
    const processedRules = this.userService.processRulesParams(this.paramsList);
    return !this.currentArticleStockMag || (processedRules['20_6'] && (processedRules['20_6']['VBOOL3'] == '-1' || processedRules['20_6']['VBOOL4'] == '-1'));
  }

  get allowForcageAutreMagStock(): boolean {
    const processedRules = this.userService.processRulesParams(this.paramsList);

    return !this.currentArticleStockMag ||
        (processedRules && (
            (processedRules['20_5'] && processedRules['20_5']['VBOOL4'] == '-1') ||
            (processedRules['20_6'] && (processedRules['20_6']['VBOOL2'] == '-1' ||
                processedRules['20_6']['VBOOL3'] == '-1' ||
                processedRules['20_6']['VBOOL4'] == '-1'))
        ));
  }

  async handleAutreStockArticleTypeRules(processedRules: any) {
    if (this.currentArticleStockMag) {
      if (processedRules['20_5'] && processedRules['20_6']) {
        const replacementParamKey = { '20_3': '20_5', '20_4': '20_6' };

        this.typeVenteRules.data = this.typeVenteRules.data.map(rw => {
          const [PARAM_ROW_ID, PARAM_STR, PARAM_BOOL] = rw.paramKey.split(';');

          rw.label = processedRules[replacementParamKey[PARAM_ROW_ID]][PARAM_STR];
          rw.disabled = (processedRules[replacementParamKey[PARAM_ROW_ID]][PARAM_BOOL] == '-1') ? false : true;

          return rw;
        });

        const filteredRules = this.typeVenteRules.data.filter((row) => !row.disabled);

        if (filteredRules.length === 0) {
          this.typeVenteRules.data = [];
          this.newArticleForm.get('TYPECDE').setValue('');
          await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kprix_commande_impossible_magasin')).toPromise();
          return;
        }

        this.newArticleForm.get('TYPECDE').setValue(filteredRules[0].value);

        return;
      }

      this.typeVenteRules.data = this.typeVenteRules.data.filter((row) => row.value != 'EI' && row.value.indexOf('E2') == -1 && !row.disabled);

      if (this.typeVenteRules.data.length === 0) {
        this.typeVenteRules.data = [];
        this.newArticleForm.get('TYPECDE').setValue('');
        await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kprix_commande_impossible_magasin')).toPromise();
        return;
      }

      const row_ED = this.typeVenteRules.data.find(r => r.value === 'ED');
      const row_BL = this.typeVenteRules.data.find(r => r.value === 'BL');

      if (row_ED && row_BL) {
        this.newArticleForm.get('TYPECDE').setValue('ED');
      } else {
        this.newArticleForm.get('TYPECDE').setValue(this.typeVenteRules.data[0].value);
      }
    }
  }

 }


