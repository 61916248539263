<div class="page-container">
    <div class="title row">
        <div class="col-12 text-center">
            <h1 class="text-center">
            {{pageTitle | translate}}
            </h1>
        </div>
    </div>

    <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()">
        <div class="bloc_container padding_15 bg_light" *ngIf="enableStatusFilter">
            <div class="row no-gutters">
                <div class="col-12 ">
                    <div class="row">
                        <div class="col-12 col-md-3 col-lg-3">
                            <mat-form-field>
                                <mat-select disableOptionCentering panelClass="dropdown-panel" color="primary" 
                                    formControlName="reportStatus" [placeholder]="'kreport_status' | translate"
                                >
                                    <mat-option *ngFor="let item of reportStatusTypes" [value]="item.value">{{ item.label | translate }}</mat-option>
                                </mat-select>                                
                            </mat-form-field>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container" *ngIf="!isMobile">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button type="submit" class="bt_valid mr-2" mat-button>
                        <mat-icon>refresh</mat-icon>
                        {{ 'kreport_actualiser' | translate }}
                    </button>

                    <button type="button" class="bt_valid" mat-button (click)="removeReport()" *ngIf="getSelectedIds().length > 0">
                        <mat-icon>delete</mat-icon>
                        {{ 'kbonsupprimer' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">
      <div class="row">
          <div class="col-12 no-padding table-scroller">
              <div class="">
                  <mat-paginator (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                              [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                              >
                  </mat-paginator>
              </div>

              <table *ngIf="!isMobile && columns" datatable class="row-border hover dataTable no-footer table-bg-color" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="width: 100%;">
                  <thead>
                      <tr>
                          <th *ngFor="let col of columns">
                              {{ col | translate }}
                          </th>
                          <th width="7%">&nbsp;</th>
                          <th width="7%">
                            <mat-checkbox [checked]="selectAll" [indeterminate]="someSelected()" (change)="toggleSelectAll($event.checked)"></mat-checkbox>
                          </th>
                      </tr>
                  </thead>
                  <tbody *ngIf="listPendingReports?.length != 0 && !showLoader">
                      <tr *ngFor="let report of listPendingReports" >
                        <td nowrap>{{ report.BRSTR1 }}</td>
                          <td>
                            <span [class]="reportStatusBadge(report.TRAITE)">{{ reportStatus(report.TRAITE) | translate }}</span>
                        </td>
                        <td>{{ formatDate(report.DTREPORT) }}</td>
                        <td nowrap>
                            <button type="button" mat-mini-fab color="primary" class="mr-2" [disabled]="!(isReportAvailable(report.TRAITE))" 
                                (click)="downloadPDF(report.BRSTR2, report.TROWID)"
                            >
                                <mat-icon>download_2</mat-icon>
                            </button>
                            <button type="button" mat-mini-fab color="warn" (click)="removeReport(report.TROWID)"> 
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                        <td>
                            <mat-checkbox [(ngModel)]="report.selected" (ngModelChange)="toggleItemSelection(report.selected)"></mat-checkbox>
                        </td>
                      </tr>
                  </tbody>
                  <tbody *ngIf="listPendingReports?.length == 0 || showLoader">
                      <tr>
                          <td colspan="10" *ngIf="listPendingReports?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate }}</td>
                          <td colspan="10" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>
                          <td style="display: none;"></td>
                          <td style="display: none;"></td>
                          <td style="display: none;"></td>
                          <td style="display: none;"></td>
                      </tr>
                  </tbody>
              </table>

              <mat-list *ngIf="isMobile && (listPendingReports?.length == 0 || showLoader)">
                <mat-list-item *ngIf="listPendingReports?.length == 0 && !showLoader">                        
                    <p matLine class="text-center">
                        {{ "kpnoresult" | translate  }}
                    </p>
                </mat-list-item>

                <mat-list-item *ngIf="showLoader">                        
                    <p matLine class="text-center">
                        <mat-icon class="synching">sync</mat-icon>
                    </p>
                </mat-list-item>
            </mat-list>
            
            <mat-list *ngIf="isMobile && (listPendingReports?.length != 0 && !showLoader)">
                <mat-list-item  *ngFor="let report of listPendingReports;let i=index;" [id]="'swipeItem-' + i" style="user-select: none;" (swipe)="onSwipe($event, i)">
                    <div class="swipedContent">
                        <button type="button" mat-mini-fab color="warn" class="" (click)="removeReport(report.TROWID)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div class="w-100 list-item-main-content">
                        <div class="d-flex flex-row">
                            <div class="col-9">
                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                    <div class="m-0 d-flex flex-row align-content-center justify-content-start">
                                        <p class="m-0">{{ report.BRSTR1 }}</p>
                                    </div>
                                </div>
                                <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                    <div matLine class="mobile-list-title">{{ formatDate(report.DTREPORT) }}</div>
                                    <p class="m-0"><span [class]="reportStatusBadge(report.TRAITE)">{{ reportStatus(report.TRAITE) | translate }}</span></p>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="d-flex">
                                    <button type="button" mat-mini-fab color="primary" [disabled]="!(isReportAvailable(report.TRAITE))" 
                                    (click)="downloadPDF(report.BRSTR2, report.TROWID)">
                                        <mat-icon>download_2</mat-icon>
                                    </button>
                                    <div class="ml-4 mt-3">
                                        <mat-checkbox [(ngModel)]="report.selected" (ngModelChange)="toggleItemSelection(report.selected)"></mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>    
          </div>
      </div>
    </div>
</div>
