<div class="modal-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title class="text-uppercase text-center ">
                    <div class="row">
                        <div class="col-11 text-center">
                            <h4 class="modal_title">
                                {{data.title}}
                            </h4>
                        </div>
                        <div class="col-1 text-right no-padding">
                            <button mat-button [mat-dialog-close] class="bt_close">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row ">
                    <div class="col-12 col-sm-12 text-center">
                        {{data.message}}<br>
                    </div>
                </div>
                <div class="row " *ngIf="data.info != ''">
                    <div class="col-12 offset-sm-3 col-sm-9 text-left info">
                       <br>
                      {{'kbonNum' | translate}} {{data.numcom}}<br>
                      {{'kbonmonatant' | translate}} : {{data.montant}} €<br>
                      {{'kbonclient' | translate}} : {{data.client_info}}
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>
    
            <mat-card-actions class="text-center">
                <div class="row">
                    <div class="col-6 text-right">
                        <button mat-button [mat-dialog-close] class="bt_cancel">{{'kcimannuler' | translate}}</button>
                    </div>
                    <div class="col-6 text-left">
                        <button mat-button (click)="dialogRef.close('ok')" class="bt_send">{{'kpvalid' | translate}}</button>
                    </div>
                </div>
            </mat-card-actions>
    
        </mat-card>
    </div>