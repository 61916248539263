import { environment } from 'environments/environment';

export class Constants {

    // HEADER ----------
    static HEADER_AUTHORIZATION = 'X-API-AUTHORIZATION';
    static HEADER_API_KEY = 'X-API-KEY';
    static HEADER_APP_VERSION = 'X-API-VERSION';

    // API USER ---------
    static STORAGE_KEY_USER = 'user';
    static STORAGE_KEY_USER_TOKEN = 'user_token';


    // STORAGE ----------
    static TYPE_STORAGE_SESSION = 'session';
    static TYPE_STORAGE_LOCAL = 'local';
    static STORAGE_TYPES = [Constants.TYPE_STORAGE_SESSION, Constants.TYPE_STORAGE_LOCAL];

    static FIVE_YEARS_EXPIRE = 157680000; // 5 ans
    static DEFAULT_STORAGE_EXPIRE = 3600; // 1 heure
    static IDPANIER_STORAGE_EXPIRE = 21600; // 6 heures
    static DEFAULT_STORAGE_KEY = "key";

    public static BASE_URL = environment.BASE_URL;
    static SOAP_URL = 'http://51.15.154.114:8080';
    static API_URL = Constants.BASE_URL + 'api/';
    static BASE_IMAGE_URL = Constants.BASE_URL + 'api/artimages/';
    static CONFORMA_IMAGE_SEGMENT_LENGTH = 6;
    static CONFORAMA_IMAGE_URL = "https://conforamach.azureedge.net/medias/";
    static CONFORAMA_CDART_PLACEHOLDER = "G_{{CDART_PLACEHOLDER}}_A";
    static DEFAULT_IMAGE_EXTENSION = '.jpg';

    static APP_DEFAULT_LANG = 'fr';

    static API_ARTICLE_URL = Constants.API_URL + 'article.php/';
    static API_BONVENTE_URL = Constants.API_URL + 'bonvente.php/';
    static API_CLIENT_URL = Constants.API_URL + 'client.php/';
    static API_USER_URL = Constants.API_URL + 'user.php/';
    static API_LOGIN_URL = Constants.API_URL + 'login.php/';

    static API_MOTIF_URL = Constants.API_URL + 'motif.php/';
    static API_COMMANDE_URL = Constants.API_URL + 'commande.php/';
    static API_DATA_URL = Constants.API_URL + 'data.php/';
    static API_DEVIS_URL = Constants.API_URL + 'devis.php/';
    static API_REMISE_URL = Constants.API_URL + 'remise.php/';
    static API_STOCK_URL = Constants.API_URL + 'stock.php/';
    static API_STOCK_BLOCK_URL = Constants.API_URL + 'stockblock.php/';
    static API_PARAM_URL = Constants.API_URL + 'params.php/';
    static API_LIGNE_URL = Constants.API_URL + 'ligne.php/';
    static API_PRINTER_URL = Constants.API_URL + 'printer.php/';
    static API_SOAP_CONVERTBONDEVENTE = Constants.SOAP_URL + '/CashOrderWS.exe/wsdl/ICashOrderWS/';
    static API_CIVILITE_URL = Constants.API_URL + 'civilite.php/';

    static API_POSTAL_CODES_URL = Constants.API_URL + 'codespostaux.php/';
    static API_NIVEAU_SECURITE_URL = Constants.API_URL + 'securite.php/';    
    static API_GESTION_DES_DROITS_URL = Constants.API_URL + 'gestdroits.php/';    
    static API_MULTI_LANG_URL = Constants.API_URL + 'langue.php/';    
    static API_MAG_COMPLEMENT_URL = Constants.API_URL + 'compmagasin.php/';

    static TIMEOUT = environment.production ? 1 : 1200;
    static LOGIN_TIMEOUT = environment.LOGIN_TIMEOUT;

    static TEL_PLACEHOLDER_TEXT = 'x'; // Tel./Port Placeholder text

    static WEFOX_TYPE_SEPARATOR = ';';

    static IDSOC = '1';
    static IDMAG = '1';
    static CMINT = '1';
    static CIINST = '999';
    static NUMERO_SOCIETE = '1';

    static TYPE_BONVENTE = 1;
    static TYPE_COMMANDE = 2;
    static TYPE_DEVIS = 3;

    static POSTAL_CODE_ORDER_NOM = "VILLE";
    static POSTAL_CODE_ORDER_CODE = "CODPOS";

    static TYPE_NIVEAU_ADMIN = "admin";
    static TYPE_NIVEAU_SECRATARY = "secretary";
    static TYPE_NIVEAU_VENDOR = "vendor";
    static TYPE_NIVEAU_POINT_INFO = "point_info";
    static TYPE_NIVEAU_RESERVE = "reserve";    

    static MOTIF_ANNULATION_DEVIS = 1;
    static MOTIF_RELANCE_DEVIS = 2;
    static MOTIF_SUPPRESSION_COMMANDE = 3;
    static MOTIF_FORCAGE_COMMANDE = 4;
    static MOTIF_FORCAGE_VENTE = 5;
    static MOTIF_DEBLOCAGE_STOCK = 6;

    static USERS_DEFAULT_STATUS = -1;

    static USER_ORDER_NOM = "CINOM";
    static USER_ORDER_CODE = "CIUSER";

    static USER_PASSWORD_EXPIRY_DAYS = 30; // default should be 30, for testing we're using 1 day.
    
    static ARTICLE_SEARCH_ORDER_CODE = "CDART";
    static ARTICLE_SEARCH_ORDER_LABEL = "CALIB3";

    static BARCODE_HIGHEST_LENGTH = 26;
    static BARCODE_CDART_OFFSET_START = 0;
    static BARCODE_CDART_OFFSET_END = 8;

    // Generalé
    static PAGE_RESTRICT_GENERAL_CODES_POSTAUX = "71_0";
    static PAGE_RESTRICT_GENERAL_NIVEAU_DE_SECURITE = "69_0";
    static PAGE_RESTRICT_GENERAL_UTILISATEURS = "73_0";
    static PAGE_RESTRICT_GENERAL_UTILISATERUS_EFFECT = "73_1";
    static PAGE_RESTRICT_GENERAL_GESTION_DES_DROITS = "74_0";
    static PAGE_RESTRICT_GENERAL_GESTION_DES_DROITS_EFFECT = "74_1";
    static PAGE_RESTRICT_GENERAL_EXPLOITATION = "77_0";
    static PAGE_RESTRICT_GENERAL_COMPLEMENT_INFO_MAGASIN = "60_0";
    static PAGE_RESTRICT_GENERAL_PARAMETERIZATION_KIT = "30_0";
    static PAGE_RESTRICT_GENERAL_BLOCK_STOCK_SETTINGS = "52_0";

    // Ventes
    static PAGE_RESTRICT_VENTES_BON_DE_VENTE = "4_0";
    static PAGE_RESTRICT_VENTES_BON_DE_VENTE_MODIFICATION = "4_1";
    static PAGE_RESTRICT_VENTES_BON_DE_VENTE_MODIFICATION_VOUCHER = "4_2";
    static PAGE_RESTRICT_VENTES_BON_DE_VENTE_OTHER_STORE = "110_0";    
    static PAGE_RESTRICT_VENTES_GESTION_DES_COMMANDES = "32_0";
    static PAGE_RESTRICT_VENTES_GESTION_DES_COMMANDES_MODIFICATION = "32_10";
    static PAGE_RESTRICT_VENTES_GESTION_DES_COMMANDES_RELANCE = "32_3";
    static PAGE_RESTRICT_VENTES_GESTION_DES_COMMANDES_RELANCE_CLIENT = "75_0";
    static PAGE_RESTRICT_VENTES_GESTION_DES_COMMANDES_SUPRIEMER = "56_0";  
    static PAGE_RESTRICT_VENTES_RESERVES = "32_50";
    static PAGE_RESTRICT_VENTES_RESERVES_MODIFICATION = "32_60";
    static PAGE_RESTRICT_VENTES_RESERVES_RETRAIT_MERCHANDISE = "40_0";
    static PAGE_RESTRICT_VENTES_RESERVES_PREPARE_QUOTE = "32_51";
    static PAGE_RESTRICT_VENTES_RESERVES_LIVRAISON = "32_52";
    static PAGE_RESTRICT_VENTES_RESERVES_PREPARE_COMMANDES = "32_55";
    static PAGE_RESTRICT_VENTES_RESERVES_RELANCE_CLIENTS = "32_53";
    static PAGE_RESTRICT_VENTES_RESERVES_GESTION_E2 = "35_2";
    static PAGE_RESTRICT_VENTES_RESERVES_GESTION_E2_SMS = "48_1";
    static PAGE_RESTRICT_VENTES_RESERVES_HISTORY_COMMANDES = "75_50";
    static PAGE_RESTRICT_VENTES_RESERVES_SUPPRIMER_COMMANDES = "56_50";
    static PAGE_RESTRICT_VENTES_RESERVES_GESTION_ZONES = "91_0";
    static PAGE_RESTRICT_VENTES_RESERVES_GESTION_EMPLACEMENTS = "92_0";
    static PAGE_RESTRICT_VENTES_RESERVES_ENTER_MOVEMENTS = "93_0";
    static PAGE_RESTRICT_VENTES_RESERVES_GESTION_DES_MOVEMENTS = "94_0";
    static PAGE_RESTRICT_VENTES_RESERVES_VISUALIZATION_UTILISATEURS = "94_1";
    static PAGE_RESTRICT_VENTES_RESERVES_STOCK_EMPLACEMENTS = "96_0";
    static PAGE_RESTRICT_VENTES_RESERVES_DRIVE = "97_0";
    static PAGE_RESTRICT_VENTES_DEVIS = "24_0";
    static PAGE_RESTRICT_VENTES_DEVIS_MODIFICATION = "24_0";
    static PAGE_RESTRICT_VENTES_DEVIS_KIT = "26_1";
    static PAGE_RESTRICT_VENTES_DEVIS_KIT_MODIFICATION = "26_1";
    static PAGE_RESTRICT_VENTES_SAV = "33_0";
    static PAGE_RESTRICT_VENTES_SAV_MODIFICATION = "33_1";
    static PAGE_RESTRICT_VENTES_SAV_VISUALIZATION = "33_2";


    static BRACTION_VENTE = "DEMAT BV";
    static BRACTION_COMMANDE = "DEMAT CDE";
    static BRACTION_DEVIS = "DEMAT DEV";
    static BRACTION_REPORT = "REPORTING CAPORDER";

    static REPORT_TODO = "0";
    static REPORT_INPROGRESS = "1";
    static REPORT_AVAILABLE = "2";
    static REPORT_ERROR = "10";
    static REPORT_CONSULTED = "3";

    static QTE_ALLOW_DECIMAL_CAUNVTE = "KG";

    // App Config
    public static DEFAULT_APP_CONFIG_PATH = 'assets/config/app.config.json';
    public static APP_CONFIG_PATH = environment.APP_CONFIG_PATH;

    public static DEFAULT_ANONYMIZE = "XXXXXXXXXX";
}
