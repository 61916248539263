import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Constants } from 'providers/constants';
import { UserModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject,Subscription } from 'rxjs';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Article } from 'models/articles';
import { Client } from 'models/clients';
import * as moment from 'moment';
import { ArticleStockComponent } from 'pages/article-detail/article-stock/article-stock.component';
import { ArticleDetailComponent } from 'pages/article-detail/article-detail.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import BonPanier, { PanierArticle, PanierInfosfac } from 'models/bonPanier';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import { ModifyArticleDetailComponent } from 'pages/create-bon-de-vente/info-complementaries/modify-article-detail/modify-article-detail.component';
import { RemiseDetailComponent } from 'pages/create-bon-de-vente/remise-detail/remise-detail.component';
import { RemiseListComponent } from 'pages/create-bon-de-vente/remise-list/remise-list.component';
import { NumeroCourtComponent } from 'pages/create-bon-de-vente/numero-court/numero-court.component';
import { MagComplementService } from 'providers/api/magComplementService';
import MagComplement from 'models/magComplement';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchArticleComponent } from 'pages/create-bon-de-vente/search-article/search-article.component';
import { EmporteeDiffereComponent } from 'pages/create-bon-de-vente/emportee-differe/emportee-differe.component';
import { LivraisonDetailsComponent } from 'pages/create-bon-de-vente/info-livraison-bonde/livraison-details/livraison-details.component';
import { HistoriqueBondeComponent } from 'pages/detail-bon-de-vente/historique-bonde/historique-bonde.component';
import { LoginService } from 'providers/api/loginService';
import { Magasin } from 'models/magasins';
import { ArticleFilterComponent } from 'pages/article/article-filter/article-filter.component';
import { BonCommandeService } from 'providers/api/bonCommandeService';
import { DateDeRelanceComponent } from 'pages/bon-de-commande/date-de-relance/date-de-relance.component';
import { TicketsComponent } from 'pages/bon-de-commande/tickets/tickets.component';
import { AlerteClientComponent } from 'pages/bon-de-commande/alerte-client/alerte-client.component';
import { BlocageCustomMotifComponent } from 'pages/article-detail/article-stock/update-motif-blocage/blocage-custom-motif/blocage-custom-motif.component';
import { ModifyLineItemChoixComponent } from 'pages/bon-de-commande/modify-line-item-choix/modify-line-item-choix.component';
import { UtilService } from 'providers/api/utilService';
import { VenteArticleGroupsComponent } from 'pages/create-bon-de-vente/vente-article-groups/vente-article-groups.component';
import { BonVenteService } from 'providers/api/bonventeService';
import { User } from 'models/users';
import { MotifForcePrixComponent } from 'pages/create-bon-de-vente/motif-force-prix/motif-force-prix.component';
import { BarcodeScannerComponent } from 'pages/create-bon-de-vente/barcode-scanner/barcode-scanner.component';
import { CapCurrencyPipe } from 'providers/customCurrency-pipe';
import { CalculateQuantityComponent } from 'pages/create-bon-de-vente/calculate-quantity/calculate-quantity.component';
import { CapDecimalPipe } from 'providers/customNumber-pipe';
import { FocusMonitor } from '@angular/cdk/a11y';
import { PdfViewerComponent } from 'pages/pdf-viewer/pdf-viewer.component';
import { DateDePromesseComponent } from 'pages/create-bon-de-vente/date-de-promesse/date-de-promesse.component';
import { ParamDetail } from 'models/paramDetail';
import { ActionReserveComponent } from 'pages/bon-de-commande/action-reserve/action-reserve.component';
import { ImprimerConfirmationComponent } from 'pages/imprimer-confirmation/imprimer-confirmation.component';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import {MatLegacySlideToggle as MatSlideToggle} from '@angular/material/legacy-slide-toggle';
import {
  AUTHORIZE_CHANGEMENT_DE_PRIX,
  AUTHORIZE_FAIRE_UNE_RELANCE,
  AUTHORIZE_REACTIVATION_COMMANDE,
  AUTHORIZE_REMISE_EN_E2, AUTHORIZE_REMISE_EN_LIGNE,
  AUTHORIZE_SUPPRESSION_COMMANDE,
  HistoryService
} from '../../providers/api/historyService';
import { StorageService } from 'providers/api/storageService';

@Component({
  selector: 'app-modify-retrait-marchandise',
  templateUrl: './modify-retrait-marchandise.component.html',
  styleUrls: ['./modify-retrait-marchandise.component.scss'],
  providers: [BonCommandeService, UtilService, {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}]
})
export class ModifyRetraitMarchandiseComponent implements OnInit {

  @ViewChild('newArticleCdart', {static: false}) cdartInput: ElementRef;
  @ViewChild('prixVente', {static: false}) newPrixVente: ElementRef;
  @ViewChild('newArticleQty', {static: false}) newArticleQty: ElementRef;
  @ViewChild('newItemForm', {static: false}) newArticleFormEl: NgForm;

  @ViewChild('prepaToggler', {static: false}) prepaToggler: MatSlideToggle;

  maskOptions = {
    mask: Number,
    scale: 2,    
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  qtyMaskOptions = {
    mask: Number,
    scale: 2,
    padFractionalZeros: true,
    radix: this.utilService.getCurrentDecimalSymbol,
    mapToRadix: ["."]
  };

  user: UserModel;
  USERCMINT: any;
  
  newArticleForm: UntypedFormGroup;
  currentArticle: Article;

  currentArticleStockMag: number | undefined;
  currentArticleStockMagNom: string = "";

  listBonCommandeItems: PanierArticle[] = [];
  listBonVenteArticles: Article[] = [];

  showLoader = false;
  bonvente: any;
  historiqueData: any;

  // DATATABLE
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  currentItem: PanierArticle;
  article: Article;

  chosenClient: Client;

  chosenFidelite: Client;

  sameAsClient = true;
  chosenLivraisonClient: Client;
  focusClientSearch: boolean = false;

  sameAsFacturationClient = true;
  chosenFacturationClient: Client;
  facturationData: PanierInfosfac;

  livraisonData: any;

  bonVenteLineItems: Article[];
  activeLineItem: number = 0;
  activeItemWefoxStat = false;
  itemDoubleClicked: any;
  itemClickedCount = 0;

  bonNumBon: string = '*************';
  bonDuDate: string;
  bonRelanceDate: string; 
  previousPanier: BonPanier;
  previousPanierClient: Client;
  previousFIDClient: Client;
  previousFactureClient: Client;
  previousLivreClient: Client;

  currentUserName: string = "";

  totalHT: number;
  totalTVA: number;
  totalTTC: number;
  totalTTCNC: number;
  totalDiscount: number;
  totalDiscountPercentage: string;
  totalPrixCaisse: number;

  chosenRemise: any[];
  chosenLineRemise: any[];

  formType: 'saise' | 'modification' = 'saise';

  forcage_text = "";
  forcageType: 'vente' | 'commande';
  commander: boolean = false;

  forcage_rules: { label: string, value: string, type: 'vente' | 'commande' }[] = [
    {
      label: this.translateService.instant('kbonerrstock'),
      value: this.translateService.instant('kbonerrstock'),
      type: 'vente'
    },
    {
      label: this.translateService.instant('kbonrecencour'),
      value: this.translateService.instant('kbonrecencour'),
      type: 'vente'
    },

    {
      label: this.translateService.instant('kbonnotfound'),
      value: this.translateService.instant('kbonnotfound'),
      type: 'commande'
    },
    {
      label: this.translateService.instant('kbonnotpastser'),
      value: this.translateService.instant('kbonnotpastser'),
      type: 'commande'
    },
    {
      label: this.translateService.instant('kbonaucoin'),
      value: this.translateService.instant('kbonaucoin'),
      type: 'commande'
    },
    {
      label: this.translateService.instant('kbonomni'),
      value: this.translateService.instant('kbonomni'),
      type: 'commande'
    }
  ];

  chosenForcage: string;

  typeVenteRules: {title: string, data: { label: string, value: string }[]};

  bonVenteComments: string = "";
  magComplementData: MagComplement;

  previousTranporteurLabel: string;
  transporteurLabel: string;

  isMobile: boolean;

  emporteDiffere: boolean = false;
  livraisonBondeVente: boolean = false;
  promesseAvail: boolean = false;
  emporteeDiffereDate: string;
  promesseDate: string;

  prixLivraison: number;

  listMagasins: Magasin[];

  bonVenteSelectedTab: number;
  advancedDialogRef: any;
  popupCloseSusscription: Subscription;

  flowType: string = 'retrait_marchandise';
  step = 1;

  remiseEnE2: boolean = true;
  remiseEnE2Disabled: boolean = false;

  chosenLineItems: any[] = [];
  checkedLineItems: any[] = [];

  numBon: string;
  CMINT: any;
  titre: string;
  magasinNom: string = "";

  alertClientData: any;
  ticketData: any;
  relanceData: any;

  userPermission: any;
  readOnly: boolean = false;

  pageType: 'commande' | 'historique' | 'supprimees' = 'commande';

  // Article d'options
  listArticleGroups: any[];
  articleGroupsRawData: any[];
  currentArticleChosenGroups: any;

  PU_vente_changable: boolean = false;
  authorizedLinePrixUser: User;

  authorizedRemiseE2User: User;
  authorizedModifierTypeUser: User;
  authorizedSuppressionCommandeUser: User;
  authorizedReactivationCommandeUser: User;
  authorizedLineRemiseUser: User;
  authorizedRelanceUser: User;

  // Note Counter
  totalNotes: number = 0;

  // Previous Devis NUMBON
  sourceDevisNumbon: string;

  qtyLength: number = 0;
  qtyWidth: number = 0;
  qtyHeight: number = 0;

  livraisonDialogRef: MatDialogRef<LivraisonDetailsComponent>;

  codeClient: any;

  paramsList: ParamDetail[];
  buttonsListAvail: boolean = false;
  insertionAllowed: boolean = false;

  bonApiSubscription: Subscription;
  quantity5Decimal: boolean = false;
  
  solderBonStat: boolean = false;

  emailParamData: string;
  emailMandatory = false;

  livraisonDetailSubscription: Subscription | undefined;
  modificationCommandeDisabled: boolean = false;
  updateCommentInProgress: boolean = false;

  bonMag: number;
  
  constructor(private router: Router,    
              private route: ActivatedRoute,          
              public snackBar: MatSnackBar,
              private formBuilder: UntypedFormBuilder,                            
              private userService: UserService,
              private bonVenteService: BonVenteService,
              private bonCommandeService: BonCommandeService,
              private loginService: LoginService,
              private magComplementService: MagComplementService,
              private loadingService: NgxSpinnerService,
              private dialogService: DialogsService,
              private utilService: UtilService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private currencyPipe: CapCurrencyPipe,
              private decimalPipe: CapDecimalPipe,
              private deviceDetector: DeviceDetectorService,
              private focusMonitor: FocusMonitor,
              private historyService: HistoryService,
              private storageService: StorageService) {
                this.popupCloseSusscription = this.userService.popupClose.subscribe(closeAction => {
                  if(closeAction == false){
                     this.closeAdvancedSearch();
                  }
                });      
              }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
    this.userService.headerSearchButton.next(false);
    this.pageType = this.route.snapshot.queryParams.pageName;
    
    if(this.pageType === undefined || this.pageType === null) {
      this.pageType = 'commande';
    }

    this._getCodeClient();

    this.route.data.subscribe(
      (routeData: any) => {
        if(routeData !== undefined && routeData !== null && routeData.readOnly !== undefined && routeData.readOnly !== null) {
          this.readOnly = routeData.readOnly;
        } else {
          this.readOnly = false;
        }
      }
    );

    this.newArticleForm = this.formBuilder.group({
      CDART: ['', [Validators.required]],
      CALIB1: [{ value: '', disabled: true }, Validators.required],
      QTE: [{ value: 1, disabled: true }, [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      CAPRIX: [{ value: null, disabled: true }, Validators.required],
      NEW_PRIX: [{ value: 0, disabled: true }, [Validators.required, Validators.pattern('^[0-9\.\,]+$')]],
      ROW_TOTAL: [{ value: null, disabled: true }, Validators.required],
      TYPECDE: [{ value: '', disabled: true }, Validators.required],
      MOTANT: [{ value: 0, disabled: true }, Validators.required],
      STOCKDISPO: [{ value: '', disabled: true }],

      PRIX_FORCE: [false],
      MOTIF_PRIX: [''],
      MOTIF_PRIX_LBL: [''],

      GROUP_AVAIL: [false]
    });
    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');

    this.dtOptions = {
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }       
      },      
      ordering: false,
      paging: false,
      searching: false,
      retrieve: true,
      info: false
    };      

    this.loadDependencies();
  }

  async loadDependencies() {
    this.formType = 'modification';
    this.getMagasins();
    
    const routeParams = this.route.snapshot.params;    
    const numBon = routeParams['numbon'];

    const magasin = routeParams['magasin'];
    this.bonMag = magasin;

    if(numBon !== undefined && numBon != null && numBon !== '') {
      this.bonNumBon = numBon;
      this.loadingService.show();
      await this.getListParams();
      await this.getQuantityDecimalStatus();

      this.emailParamData = await this.userService.getRuleParamsData(20, 54, 'VSTR1');

      this._processingButtonsList();
      await this.getBonDeCommandeByNumBon(this.bonNumBon);  
      
      const EI_available = this.listBonCommandeItems.some((_r) => _r.typecde == 'EI');

      if(EI_available) {
        if(this.userPermission['32_11']) {
          this.insertionAllowed = true;
        } else {
          this.insertionAllowed = false;
        }
      } else {
        this.insertionAllowed = true;
      }

      const countData = {
        NumBon: this.bonNumBon,
        IDSOC: Constants.IDSOC,
        mag: this.previousPanier.mag
      };

      this.getNoteCount(countData);
    } else {
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kboninvalidreq'));
      this.router.navigate(['/retrait-marchandise']);
    }

    this.getMagComplementData();

    this.PU_vente_changable = true;
    if(!this.userPermission['0_17']) {
      this.PU_vente_changable = false;
    }
    
    this.newArticleForm.get('CDART').valueChanges.subscribe(
      (value) => {
        if(value === '' || value === null) {
          this.currentArticle = undefined;
          this.chosenLineRemise = [];
          this.newArticleForm.patchValue({
            CALIB1: '',            
            CAPRIX: null,
            QTE: this.utilService.formatQteMaskCompat(1),
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: null,
            TYPECDE: '',
            STOCKDISPO: '',
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });
          this.forcage_text = "";
          this.commander = false;
        }
      }
    );

    this.userService.actionReserveRefreshBon.subscribe((resp) => {
      if (resp !== undefined && resp !== null) {
        if (resp.status === 'UPDATE_PANIER' && this.bonNumBon) {
          this.loadingService.show();
          this.getBonDeCommandeByNumBon(this.bonNumBon).then(() => {
            this.__updateBonDeCommandeList();
          });
        }
      }
    })
  }

  get quantityMask() {
    return this.qtyMaskOptions;
  }

  get viewBonCommandeDetail() {
    return this.userPermission['32_0'];
  }

  _checkEmailMandatory() {
    this.emailMandatory = false;
    if (this.emailParamData) {
      const splitUp = this.emailParamData.split(';');
      this.emailMandatory = this.previousPanier.articles.some(r => splitUp.indexOf(r.typecde) > -1);
    }
  }
  
  async getQuantityDecimalStatus() {
    try {
      const paramData = await this.userService.getRuleParamsData(10,12, 'VBOOL1');
      this.quantity5Decimal = false;
      this.qtyMaskOptions = {
        mask: Number,
        scale: 2,
        padFractionalZeros: true,
        radix: this.utilService.getCurrentDecimalSymbol,
        mapToRadix: ["."]
      };
      if(paramData !== undefined && paramData != null && paramData == '-1') {
          this.quantity5Decimal = true;
          this.qtyMaskOptions = {
            mask: Number,
            scale: 5,
            padFractionalZeros: true,
            radix: this.utilService.getCurrentDecimalSymbol,
            mapToRadix: ["."]
          };
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  async _getCodeClient() {
    try {
      this.codeClient = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(!this.isMobile && this.cdartInput) {
        this.cdartInput.nativeElement.focus();
        this.cdartInput.nativeElement.select();
        this.cdartInput.nativeElement.setAttribute('inputmode', 'text'); 
      }
    }, 100)
  }

  ngOnDestroy() {
    this.__clearPageVars();
    this.popupCloseSusscription.unsubscribe();
    
    if(this.bonApiSubscription) {
      this.bonApiSubscription.unsubscribe();
    }
  }

  onLivraisonTrackingChanged(event: boolean) {
    if(event === true) {
      console.log('Tracking Changed !');
      this.getBonDeCommandeByNumBon(this.bonNumBon).then((res) => {
        this.livraisonData = {
          dtliv: new Date(this.previousPanier.dtliv),
          dtlivsouhait: new Date(this.previousPanier.dtlivsouhait),
          livjour: this.previousPanier.livjour,
          livconfirm: this.previousPanier.livconfirm,
          livinfo1: this.previousPanier.livinfo1,
          livinfo2: this.previousPanier.livinfo2,
          livdeport: this.previousPanier.livdeport,
          livdirect: this.previousPanier.livdirect,
  
          transporteurContent: this.previousPanier.transporteur,
          transporteurLabel: this.transporteurLabel
        };

        if(this.livraisonDialogRef.componentInstance?.livraisonDetailsChanged) {
          this.livraisonDialogRef.componentInstance?.livraisonDetailsChanged.next(this.livraisonData);
        }
      });
    }
  }
  
  panierUpdate = (panier: BonPanier) => {
    if(panier !== undefined && panier !== null) {     
      this.previousPanier = panier;
      this.listBonCommandeItems = this.previousPanier.articles;
      this.refreshByPanier(this.previousPanier);
    }    
  };

  setActiveItem(i: number) {
    this.activeLineItem = i;
  }

  refreshByPanier(panier: BonPanier) {
    if(panier && panier !== null) {
      this.chosenLineRemise = undefined;
      this.chosenRemise = undefined;
      
      this.sourceDevisNumbon = panier.numdevis;

      this.promesseDate = (panier.dtpromesse != null && moment(panier.dtpromesse).startOf('day').year() !== 1899)?panier.dtpromesse:panier.dtpromessecalc;

      if(this.pageType === 'historique' || this.pageType === 'supprimees') {
        this.sourceDevisNumbon = undefined;
      }

      this.solderBonStat = true;
      if(panier.statut != 7) {
        this.solderBonStat = true;
      }

      this.listBonCommandeItems = panier.articles;

      this._checkEmailMandatory();

      // Check Stock Magasin
      if (panier.magstock) {
        this.currentArticleStockMag = panier.magstock;

        // Getting the panier.magstock Magasin Nom
        const currentBonStockMag = this.listMagasins.find((row) => +row.CMINT == this.currentArticleStockMag)
        if(currentBonStockMag) {
          this.currentArticleStockMagNom = currentBonStockMag.CMRAISOC;
        }

        // Getting the panier.mag Magasin Nom
        const currentBonMag = this.listMagasins.find((row) => +row.CMINT == panier.mag)
        if(currentBonMag && this.USERCMINT != panier.mag) {
          this.magasinNom = currentBonMag.CMRAISOC;
        }
      }

      this.currentUserName = panier.libuser;

      if(panier.statut != 2 && (panier.typecde == 'E2/ED' || panier.typecde == 'E2/BL')) {
        this.remiseEnE2 = true;
      } else {
        this.remiseEnE2 = false;
      }

      if(panier.client !== undefined && panier.client !== null) {
        this.chosenClient = this.previousPanierClient;
      }

      if(panier.numfid !== undefined && panier.numfid !== null && panier.numfid !== '') {
        this.chosenFidelite = this.previousFIDClient;        
      }

      if(panier.clientfacture !== undefined && panier.clientfacture !== null && panier.clientfacture !== '') {
        this.sameAsFacturationClient = false;
        this.chosenFacturationClient = this.previousFactureClient;
      }

      if(panier.clientlivre !== undefined && panier.clientlivre !== null && panier.clientlivre !== '') {
        this.sameAsClient = false;
        this.chosenLivraisonClient = this.previousLivreClient;
      }

      if(panier.transporteur !== '' && panier.transporteur !== null) {
        this.transporteurLabel = this.previousTranporteurLabel;
      }

      this.prixLivraison = 0;
      this.livraisonBondeVente = false;
      this.emporteDiffere = false;
      this.promesseAvail = false;
      panier?.articles?.map(
        (row) => {

          const articleData = this.listBonVenteArticles?.find((item) => item.CDART == row.cdart);
          if(articleData && articleData.CAOKLIV == '-1') {
            this.prixLivraison += row.quantite * row.prix - row.remlig - row.remtot;
          }

          if(row.typecde === 'BL') {
            this.livraisonBondeVente = true;
          }

          if(row.typecde === 'ED') {
            this.emporteDiffere = true;
          }

          if(row.typecde === 'E2/ED' || row.typecde === 'E2/BL') {
            this.promesseAvail = true;
          }
        }
      );

      this.facturationData = panier.infosfac;    
    
      this.totalHT = panier.montantHT;
      this.totalTTC = panier.montant;
      this.totalTTCNC = panier.montanthorsNC;
      this.totalTVA = panier.montant - panier.montantHT;
      this.totalDiscount = panier.remtot;

      this.totalPrixCaisse = panier.montant + panier.remtot;

      if(panier.datarem !== '' && panier.datarem !== null) {        
        this.totalDiscountPercentage = panier.remise.toString();
      } else {
        if(panier.remise && panier.remise > 0) {
          this.totalDiscountPercentage = panier.remise.toString();
        } else {
          this.totalDiscountPercentage = '0';
        }
      }

      this.bonVenteComments = panier.comm;
      
      this.activeLineItem = 0;
      const currentItem = panier?.articles[this.activeLineItem];
      this.currentItem = currentItem;
      this.article = this.listBonVenteArticles?.find(row => row.CDART == currentItem.cdart);

      const checkWefox = this.checkArticleWefoxStatus(this.article.CDART);
      this.activeItemWefoxStat = checkWefox.wefoxStat;

      let dtRelance = moment(panier.dtrel).format('DD.MM.YYYY');
      this.bonRelanceDate = dtRelance && (dtRelance !== '30.12.1899' && dtRelance !== '29.12.1899') ? dtRelance : undefined;
      this.bonDuDate = moment(panier.dtsaisie).utcOffset(panier.dtsaisie).format('DD.MM.YYYY HH:mm');

      // CAP-1267: Reset the checkbox selected.
      this.checkedLineItems = [];
      this.chosenLineItems = []; 
    }
  }

  viewSourceDevis(): void {
    if(!this.sourceDevisNumbon || this.sourceDevisNumbon === '') return;

    this.router.navigate(['/devis-detail/', this.sourceDevisNumbon]);
    return;
  }

  moveToInfoTab(currentIndex: number) {
    this.setActiveItem(currentIndex);

    // To Expand Info. tab
    this.step = 6;

    // Update variables based on the selected article line item
    const currentItem = this.previousPanier?.articles[this.activeLineItem];
    this.article = this.listBonVenteArticles?.find(row => row.CDART == currentItem?.cdart);

    const checkWefox = this.checkArticleWefoxStatus(this.article.CDART);
    this.activeItemWefoxStat = checkWefox.wefoxStat;

    // Reset the swipe back to normal state
    this.__resetPreviousSwipes('.mat-list-item');
  }

  onRowClick(i: number, item: PanierArticle) {
    if(this.checkOkStockValue(item)) {
      this.setActiveItem(i);
      this.loadArticleItem(item);
    }
  }

  loadArticleItem(item: PanierArticle) {
    this.itemClickedCount++;

    setTimeout(() => {
      if(this.itemClickedCount === 1) {
        this.currentItem = item;
        this.article = this.listBonVenteArticles.find(row => row.CDART == item.cdart);

        const checkWefox = this.checkArticleWefoxStatus(this.article.CDART);
        this.activeItemWefoxStat = checkWefox.wefoxStat;

        this.dialog.closeAll();
        if(this.isMobile) {
          this.openUpArticleDetail(this.article);
        }
      } else if(this.itemClickedCount === 2) {
        this.dialog.closeAll();
        if(!this.readOnly) {
          this.openUpActionReserve(item);
        }
      }
      this.itemClickedCount = 0;
    }, 250);
  }

  loadArticleDetail(item: PanierArticle, event) {
    event.stopPropagation();
    this.currentItem = item;    
    this.article = this.listBonVenteArticles.find(row => row.CDART == item.cdart);  
    this.dialog.closeAll();    
    this.openUpArticleDetail(this.article);
  } 

  loadEditArticleItem(item: PanierArticle, event) {
    if(this.readOnly || !this.checkOkStockValue(item)) return;
    
    if(event.target.classList.contains('mat-checkbox-inner-container')){      
      this.itemChosenChange(event, item);
    } else {
      event.stopPropagation();
      this.dialog.closeAll();

      this.openUpActionReserve(item);
    }
  }

  async editBonCommandeItem(item: PanierArticle) {
    let authorizedModifyItemUser: User | undefined;

    if(this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21']) {
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '21', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      authorizedModifyItemUser = authorizationResult.data;
    }

    const article_data = this.listBonVenteArticles.find(
      (row) => row.CDART == item.cdart
    );
    
    const dialogRef = this.dialog.open(ModifyArticleDetailComponent, {
      width: '40vw',
      maxWidth: '40vw',  
      height: this.isMobile?'74vh':undefined,
      autoFocus: false,
      data: {
        panier: this.previousPanier,
        bonNumBon: this.bonNumBon,
        panier_article: item,
        article_data: article_data,
        flowType: 'bonde_commande',
        CMINT: this.USERCMINT,
        authorizedModifyItemUser,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(result.status === 'success') {
          this.loadingService.show();
          this.previousPanier = result.panier;
          this.refreshByPanier(this.previousPanier);

          this.getBonDeCommandeByNumBon(this.bonNumBon).then(() => {
              this.__updateBonDeCommandeList();
          });
        } else if(result.status === 'removed') {
          this.router.navigate(['/retrait-marchandise']);
        }
      }
    });    
  }

  async openUpActionReserve(item: PanierArticle) {
    // if(this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21']) {
    //   await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
    //   const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '21', this.user.CIINT, this.bonNumBon); 

    //   if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
    //     return;
    //   }
    // }

    const article_data = this.listBonVenteArticles.find(
      (row) => row.CDART == item.cdart
    );
    
    const dialogRef = this.dialog.open(ActionReserveComponent, {
      width: '40vw',
      maxWidth: '40vw',
      autoFocus: false,
      data: {
        panier: this.previousPanier,
        bonNumBon: this.bonNumBon,
        panier_article: item,
        article_data: article_data,
        CMINT: this.USERCMINT
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // For now, No rules to handle here
      }
    });    
  }

  async editBonCommandeItemRestricted(item: PanierArticle) {
    let authorizedModifyItemUser: User | undefined;

    if(this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21']) {
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '21', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      authorizedModifyItemUser = authorizationResult.data;
    }

    const article_data = this.listBonVenteArticles.find(
      (row) => row.CDART == item.cdart
    );
    
    const dialogRef = this.dialog.open(ModifyLineItemChoixComponent, {
      width: '40vw',
      maxWidth: '40vw',
      autoFocus: false,
      data: {
        panier: this.previousPanier,
        bonNumBon: this.bonNumBon,
        panier_article: item,
        article_data: article_data,
        CMINT: this.USERCMINT,
        authorizedModifyItemUser,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(result.status === 'success') {
          this.loadingService.show();
          
          this.bonNumBon = result.numBon;
          this.previousPanier = result.panier;        
          this.refreshByPanier(this.previousPanier);

          this.getBonDeCommandeByNumBon(this.bonNumBon).then(() => {
            this.__updateBonDeCommandeList();
          });
        } else if(result.status === 'removed') {
          this.router.navigate(['/retrait-marchandise']);
        }
      }
    });    
  }

  getCdartWithPlacehold(CDART: string): string {
    return (Constants.CONFORAMA_CDART_PLACEHOLDER).replace("{{CDART_PLACEHOLDER}}", CDART);
  }

  getImageURL(CDART: string): string {
    if(this.codeClient && this.codeClient.toLowerCase() === 'conforama') {
      // To generate Conforama Image URL Algorithm
      let concatenated = "";
      const length = Constants.CONFORMA_IMAGE_SEGMENT_LENGTH;
      for(let i=0;i < length;i++) {
        if(i < (length - 1)) {
          if(concatenated !== '') {
            concatenated += "/"  
          }
          if(CDART[i] !== undefined) {
            concatenated += (CDART[i].padEnd(length - i, '0'));
          } else {
            concatenated += ('0'.padEnd(length - i, '0')); // Prefixing Zero if the CDART length is small as mentioned in CAP-1056
          }
        }
      }

      const URL = Constants.CONFORAMA_IMAGE_URL + concatenated + '/' + this.getCdartWithPlacehold(CDART) + Constants.DEFAULT_IMAGE_EXTENSION;
      return URL;
    }
    
    return Constants.BASE_IMAGE_URL+CDART+Constants.DEFAULT_IMAGE_EXTENSION;
  }

  itemChosenChange(event: any, item: any) {    
    let occupied = this.chosenLineItems.find((row) => row.id == item.idlig);

    if(occupied) {
      this.chosenLineItems.map((row) => {
        if(row.id == item.idlig) {
          row.status = event;
        }
        return row;
      });
      this.chosenLineItems = this.chosenLineItems.filter((row) => row.status);
    } else {
      this.chosenLineItems.push({
        id: item.idlig,
        status: event,
        data: item
      });
    }
  }

  checkBonStatus27() {
    if(this.previousPanier.statut == 27) {
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kalreadysold'));
      return true;
    }
    
    return false;
  }

  async onClickSolderLeBon() {
    if(this.previousPanier === undefined || (this.previousPanier !== undefined && this.previousPanier.statut == 2)) {
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('ke2_en_cours'));
      return;
    }

    let lineConf = undefined;
    let type: 'total' | 'line' = 'total';

    if (this.previousPanier.articles.length == 1 ||
        this.previousPanier.articles.filter((_r) => _r.opt <= 0).length == 1 ||
        this.chosenLineItems.length === this.previousPanier.articles.filter((_ar) => _ar.opt <= 0).length) {
      type = 'total';
    } else {
      lineConf = await this._totalOrLineConfirmation('solder_le_bon').toPromise();

      if(lineConf === undefined || lineConf === null) return;
    }

    if (lineConf === "OK") {
      type = 'line';

      if(this.chosenLineItems === undefined || this.chosenLineItems === null || this.chosenLineItems.length === 0) {
        this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kkaucunesel'));
        return;
      }

      const checkEtatAlreadyExists = this.chosenLineItems.some((row) => row.data.statut == 7 || row.data.statut == 17 || row.data.statut == 27);

      if(checkEtatAlreadyExists) {
        const etatConf = await this.__etatExistsConfirmation().toPromise();
        if(etatConf !== 'OK') {
          return;
        }
      }
    }

    // CAP-1268 Checks the bon status = 27 & displays the message `the bon already sold`
    if (this.checkBonStatus27()) {
      return;
    } 

    // CAP-1267: Update logic as per comments
    if(this.previousPanier.montant > 0 && this.previousPanier.montantrestant > 0) {
      const resteConf = await this.__resteApayerConfirmation().toPromise();
      if(resteConf !== 'OK') {
        return;
      }
    }

    // to Change the status to 7 of selected lines or the whole bon
    this.loadingService.show();
    this.modifySolderBon(type);
  }

  modifySolderBon(type: 'line' | 'total') {
    let pLignes: string = "";
    // Push chosen line item's id one by one and Join them with ";" (Semicolon) if any
    if(type === 'line') {
      let multiLignes = [];
      if((this.chosenLineItems !== undefined && this.chosenLineItems !== null)) {
        this.chosenLineItems.map((r) => {
          multiLignes.push(r.id);

          if(r.data.opt < 0) {
            this.listBonCommandeItems.map(cr => { // If Parent item chosen, type of the child also should be changed.
              if(cr.opt == Math.abs(r.data.opt)) {
                multiLignes.push(cr.idlig)
              }
            })
          }

          return r;
        });
      }

      pLignes = (this.chosenLineItems !== undefined && this.chosenLineItems !== null)?multiLignes.join(';'):this.currentItem.idlig.toString();
    } else {
      pLignes = "";
    }

    this.solderBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes });
  }

  async onClickNonConforme() {
    let lineConf = undefined;
    let type: 'total' | 'line' = 'total';

    if (this.previousPanier.articles.length == 1 ||
        this.previousPanier.articles.filter((_r) => _r.opt <= 0).length == 1 ||
        this.chosenLineItems.length === this.previousPanier.articles.filter((_ar) => _ar.opt <= 0).length) {
      type = 'total';
    } else {
      lineConf = await this._totalOrLineConfirmation('non_conforme').toPromise();

      if(lineConf === undefined || lineConf === null) return;
    }

    if (lineConf === "OK") {
      type = 'line';

      if(this.chosenLineItems === undefined || this.chosenLineItems === null || this.chosenLineItems.length === 0) {
        this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kkaucunesel'));
        return;
      }

      const checkEtatAlreadyExists = this.chosenLineItems.some((row) => row.data.statut == 7 || row.data.statut == 17 || row.data.statut == 27);

      if(checkEtatAlreadyExists) {
        const etatConf = await this.__etatExistsConfirmation().toPromise();
        if(etatConf !== 'OK') {
          return;
        }
      }
    }

    // CAP-1268 Checks the bon status = 27 & displays the message `the bon already sold`
    if (this.checkBonStatus27()) {
      return;
    } 

    // CAP-1267: removing payer confirmation popup for Non-Conforme as per comments
    // if(this.previousPanier.montant > 0 && this.previousPanier.montantrestant == 0) {
    //   const resteConf = await this.__resteApayerConfirmation().toPromise();
    //   if(resteConf !== 'OK') {
    //     return;
    //   }
    // }

    // to Change the status to 17 of selected lines or the whole bon
    this.loadingService.show();
    this.modifyNonConforme(type);
  }

  modifyNonConforme(type: 'line' | 'total') {
    let pLignes: string = "";
    // Push chosen line item's id one by one and Join them with ";" (Semicolon) if any
    if(type === 'line') {
      let multiLignes = [];
      if((this.chosenLineItems !== undefined && this.chosenLineItems !== null)) {
        this.chosenLineItems.map((r) => {
          multiLignes.push(r.id);

          if(r.data.opt < 0) {
            this.listBonCommandeItems.map(cr => { // If Parent item chosen, type of the child also should be changed.
              if(cr.opt == Math.abs(r.data.opt)) {
                multiLignes.push(cr.idlig)
              }
            })
          }

          return r;
        });
      }

      pLignes = (this.chosenLineItems !== undefined && this.chosenLineItems !== null)?multiLignes.join(';'):this.currentItem.idlig.toString();
    } else {
      pLignes = "";
    }

    this.nonConformeBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes });
  }

  _totalOrLineConfirmation(method: 'solder_le_bon' | 'non_conforme'): Observable<any> {
    const message = `<h1 class="mb-3">${this.translateService.instant('kboncommodiligne')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'),this.translateService.instant('ksno')]
      }     
    });

    return dialogRef.afterClosed();
  }

  solderBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.solderBonCommande(data).subscribe(
      (resp) => {
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
                this.getBonDeCommandeByNumBon(this.bonNumBon, true).then(
                  () => {
                    this.__updateBonDeCommandeList();
                  }
                );
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }          
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }         
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  nonConformeBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.nonConformeBonCommande(data).subscribe(
      (resp) => {
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
                this.getBonDeCommandeByNumBon(this.bonNumBon, true).then(
                  () => {
                    this.__updateBonDeCommandeList();
                  }
                );
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }          
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }         
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  __etatExistsConfirmation(): Observable<any> {
    const message = `<h1 class="mb-3">${this.translateService.instant('kvoulezretmarchandise')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'),this.translateService.instant('ksno')]
      }     
    });

    return dialogRef.afterClosed();
  }

  __resteApayerConfirmation(): Observable<any> {
    const message = `
      <h1 class="mb-3">${this.translateService.instant('kcommandepayee')}</h1>
      <h1 class="mb-3">${this.translateService.instant('kboncontinue')}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'),this.translateService.instant('ksno')]
      }     
    });

    return dialogRef.afterClosed();
  }

  get _prepaStatus() {
    return this.previousPanier && this.previousPanier.preparation;
  }

  get prepaCommandeEnabled() {
    return this.userPermission && this.userPermission['35_11'];
  }

  get solderCommandeEnabled() {
    return this.userPermission && this.userPermission['35_12'];
  }

  get conformeCommandeEnabled() {
    return this.userPermission && this.userPermission['35_13'];
  }

  async onPrepaClick(event: any) {
    const checkStatus = event.checked;

    if(checkStatus && this.previousPanier.statut == 2) {
      this.prepaToggler.checked = false;
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kpas_possible_e2_en_cours'));
      return;
    }

    this.loadingService.show();

    const panierData: any = {
      ...this.previousPanier,        
      idinstuser: Constants.CIINST,
      iduser: this.user.CIINT,
      preparation: checkStatus
  };

    // Prepa process
    const bonDeCommandeData = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon,
      pPanier: panierData,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST,
    };

    this.editBonDeCommande(bonDeCommandeData).then(() => {
      this.__updateBonDeCommandeList();
    });
  }

  editBonDeCommande(bonCommandeData: any) {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyBonCommandeFull(bonCommandeData).subscribe(
        (resp) => {
          this.loadingService.hide();        
          const currentArticleBcup = this.currentArticle;
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.chosenLineRemise = undefined;
              this.chosenRemise = undefined;

              // List update after a success response
              const oldListData = this.storageService.getItem('bonVenteListData');
              const oldNumBon = this.bonNumBon;
              // ------------------------------

              this.bonNumBon = resp.data.NumBon;
              this.previousPanier = resp.data.Panier;

              // List update after a success response
              if(oldListData !== undefined && oldListData !== null) {
                
              }
              // ------------------------------

              this.refreshByPanier(this.previousPanier);
              resolve({ response: resp, current_article: currentArticleBcup });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              reject({ response: resp, current_article: currentArticleBcup });
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve({ response: resp, current_article: currentArticleBcup });
          }
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    });     
  }

  updateTypeBonDeCommande(bonCommandeData: { pMag: number, pNumBon: string, pType: string, pLignes: string, Id_User?: any, Id_InstUser?: any }) {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.modifyTypeBondeCommande(bonCommandeData).subscribe(
        (resp) => {
          this.loadingService.hide();        
          const currentArticleBcup = this.currentArticle;
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              
              this.previousPanier = resp.data.Panier;
              this.listBonCommandeItems = this.previousPanier.articles;              
    
              this.storageService.setItem('previousBonPanier', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);

              this.refreshByPanier(this.previousPanier);

              this.checkedLineItems = [];
              this.chosenLineItems = [];

              this.__updateBonDeCommandeList();

              resolve({ response: resp, current_article: currentArticleBcup });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
              reject({ response: resp, current_article: currentArticleBcup });
            }
          } else {          
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve({ response: resp, current_article: currentArticleBcup });
          }
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    });     
  }

  onQTYChanged(event: any) {
    this.rowTotalCalculation();
  }

  incDecByArrows(event: any) {
    const $input = this.newArticleForm.get('QTE');

    if (event.which == 38) {
      let total = parseInt($input.value) + 1;
      $input.setValue(this.utilService.formatQteMaskCompat(total));
    } else if (event.which == 40) {
      let total = parseInt($input.value) - 1;
      $input.setValue(this.utilService.formatQteMaskCompat(total));
    }
  }


  onQuantityFocused() {
    if(this.currentArticle === undefined || this.currentArticle === null) return;

    if(this.currentArticle.CAUNVTE === 'M3') {
      this.openUpCalculateQty().then((result) => {
        if(result && result.status === "success") {
          const data = result.data;

          const length = data.length?data.length:0;
          const width = data.width?data.width:0;
          const height = data.height?data.height:0;

          this.qtyLength = length;
          this.qtyWidth = width;
          this.qtyHeight = height;

          const totalQty = length * width * height;

          this.newArticleForm.patchValue({
            QTE: this.utilService.formatQteMaskCompat(totalQty)
          });

          this.rowTotalCalculation();
        }
      })
    }
  }

  openUpCalculateQty(): Promise<any> {
    const dialogRef = this.dialog.open(CalculateQuantityComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier,
          currentItemFormData: this.newArticleForm.getRawValue(),
          currentArticle: this.currentArticle,
          dimensions: {
            length: this.qtyLength,
            width: this.qtyWidth,
            height: this.qtyHeight,
          }
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  quantityChange(action){
    this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
    let qty = Number(this.newArticleForm.get('QTE').value);
    if(action === 'increment'){
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }
    this.newArticleForm.get('QTE').setValue(qty.toString());
    setTimeout(() => {
      this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
    }, 50);
    this.rowTotalCalculation();
  }

  get currentArticleCAPrix(): AbstractControl {
    return this.newArticleForm.get('CAPRIX');
  }

  get getTotalMainArticleItems() {
    if (!this.listBonCommandeItems || !this.listBonVenteArticles) {
      return 0;
    }

    const articles = this.listBonCommandeItems.map(itemA => {
      const matchingItemB = this.listBonVenteArticles.find(itemB => itemB.CDART === itemA.cdart);
      return {
        ...itemA,
        ...matchingItemB
      };
    });

    return articles.filter((art) => art.opt <= 0 && art.OKSTOCK == "-1").length;
  }

  checkOkStockValue(item: PanierArticle) {
    const article = this.listBonVenteArticles.find(art => art.CDART == item.cdart);
    let okStock = "0";
    if (article) {
      okStock = article.OKSTOCK;
    }

    return okStock == "-1";
  }

  async onPriceChanged(event?: any) {    
    if(!this.PU_vente_changable && (+this.currentArticle.calculated_price > 0)) {
      this.authorizedLinePrixUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '17', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        event.preventDefault();
        return;
      }
      
      this.authorizedLinePrixUser = authorizationResult.data;
      this.PU_vente_changable = true;
    }

    this.rowTotalCalculation();

    // While forcing prix, make a copy of it into CAPRIX form control
    let entered_prix = this.newArticleForm.get('NEW_PRIX').value;
    this.currentArticleCAPrix.setValue(entered_prix);
  }

  rowTotalCalculation() {
    const price = this.newArticleForm.get('NEW_PRIX').value;
    const qty = this.newArticleForm.get('QTE').value;

    if(isNaN(price) || isNaN(qty)) {
      this.newArticleForm.patchValue({
        ROW_TOTAL: this.utilService.formatMaskCompat(0)
      });
      return;
    }

    const total_price = price * qty;

    this.newArticleForm.patchValue({
      ROW_TOTAL: this.utilService.formatMaskCompat(total_price)
    });
  }

  async imprimerConfirmCheckboxPopup() {
    if(!this.previousPanier.client || (this.previousPanier.client && (this.previousPanier.client.email == null || this.previousPanier.client.email === ''))) {
      this.imprimerBondeCommande();
      return;
    }

    const dialogRef = this.dialog.open(ImprimerConfirmationComponent, {
      width: '60vw',
      data: {
        panier: this.previousPanier,
        CMINT: this.USERCMINT,
        flowType: this.flowType
      }     
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result !== undefined && result !== null && result.status === "success") {
        console.log(result);
        const editBon = result.data.EDIT_BON;
        const sendBon = result.data.SEND_BON;

        if(sendBon) {
          await this.insertBatchReport();
          await this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmsgenvoye')).toPromise();
        }

        if(editBon) {
          this.imprimerBondeCommande();
        }
      }
    });    
  }

  imprimerConfirmCheckboxPopupV2() {
    this.imprimerBondeCommande();
  }

  insertBatchReport(): Promise<any> {
    this.loadingService.show();
    return new Promise((resolve, reject) => {
      const data = {
        BRACTION: Constants.BRACTION_COMMANDE,
        DTREPORT: moment().format('YYYY-MM-DD HH:mm:ss'),
        CIINT: this.user.CIINT,
        BRINT1: Constants.IDSOC, // IDSOC
        BRINT2: this.USERCMINT, // CMINT
        BRSTR1: this.bonNumBon,
        TRAITE: 0
      };

      this.bonVenteService.createBatchReport(data).subscribe(
        (resp) => {
          this.loadingService.hide();
          resolve(resp);
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    })
  }

  imprimerBondeCommande() {
    this.loadingService.show();
    this.__printBonCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pImprimante: 'download' });
  }

  __printBonCommande(bondeCommandeData: { pMag: any, pNumBon: string, pImprimante: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.imprimerBonCommande(bondeCommandeData).subscribe(
        (resp) => {
          this.loadingService.hide();        
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              let fileUrl = resp.basePath+resp.data.Fichier; // 'Bon_2721162009080_122446557.pdf'

              this.bonCommandeService.downloadFile(fileUrl).subscribe(
                (_down_res) => {
                  this.openUpPdfViewer({ url: fileUrl });
                },
                error => {
                  console.error("Error while downloading the file", error);
                  this.dialogService.prompt(this.translateService.instant('kfailure'), error.message);
                }
              );
              resolve();
            } else {
              reject();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {         
            reject(); 
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject();
        }
      );
    })
  }

  __printRelance(relanceData: { pMag: any, pNumBon: string, pImprimante: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.bonCommandeService.imprimerRelance(relanceData).subscribe(
        (resp) => {
          this.loadingService.hide();        
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              let fileUrl = resp.basePath+resp.data.Fichier; // 'Bon_2721162009080_122446557.pdf'

              this.bonCommandeService.downloadFile(fileUrl).subscribe(
                (_down_res) => {
                  this.openUpPdfViewer({ url: fileUrl });
                },
                error => {
                  console.error("Error while downloading the file", error);
                  this.dialogService.prompt(this.translateService.instant('kfailure'), error.message);
                }
              );
              resolve();
            } else {
              reject();
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }
          } else {         
            reject(); 
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }
        },
        err => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject();
        }
      );
    })
  }

  openUpPdfViewer(data: any) {
    const dialogRef = this.dialog.open(PdfViewerComponent,
      {
        height: this.isMobile?'100%':undefined,
        data: {
          ...data
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        
      }      
    });
  }

  checkArticleWefoxStatus(CDART: string, processedRules?: any): { wefoxStat: boolean, wefoxTypeRestriction: boolean, wefoxType: string } {
    if (processedRules === undefined || processedRules === null) {
      processedRules = this.userService.processRulesParams(this.paramsList);
    }

    const result = {
      wefoxStat: false,
      wefoxTypeRestriction: true,
      wefoxType: ''
    };

    if (processedRules['20_61']['VBOOL1'] == '-1') {
      if (CDART.toLowerCase() === processedRules['20_61']['VSTR1'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR2'] !== '' && processedRules['20_61']['VSTR2'] != null)?processedRules['20_61']['VSTR2']:'';

        result.wefoxTypeRestriction = true;
      }

      if (CDART.toLowerCase() === processedRules['20_61']['VSTR3'].toLowerCase()) {
        result.wefoxStat = true;
        result.wefoxType = (processedRules['20_61']['VSTR4'] !== '' && processedRules['20_61']['VSTR4'] != null)?processedRules['20_61']['VSTR4']:'';

        result.wefoxTypeRestriction = true;
      }
    }

    return result;
  }

  newArticleFormSubmitter() {
    if(this.bonNumBon === undefined || this.bonNumBon === null) {
      this.router.navigate(['/bon-de-vente']);
      return;
    }

    if(this.previousPanier === undefined || this.previousPanier === null) {
      this.router.navigate(['/bon-de-vente']);
      return;
    }

    if(this.currentArticle === undefined || this.currentArticle === null) {      
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }
        
    const form_data = this.newArticleForm.getRawValue();

    if(form_data.CDART === '' || form_data.CDART === null || form_data.TYPECDE === '' || form_data.TYPECDE === null || form_data.QTE === '' || form_data.QTE === null || +form_data.QTE === 0) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const processedRules = this.userService.processRulesParams(this.paramsList);
    const checkWefox = this.checkArticleWefoxStatus(form_data.CDART.toLowerCase(), processedRules);
    if((form_data.NEW_PRIX === '' || form_data.NEW_PRIX === null || +form_data.NEW_PRIX === 0) &&
        !(checkWefox.wefoxStat) &&
        !this.userPermission['0_22']) {
      this.newPrixVente.nativeElement.select();
      this.newPrixVente.nativeElement.focus();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kboninvalid'));
      return;
    }

    const maxAllowedQte = (processedRules['10_12']['VFLOAT1'] !== undefined && processedRules['10_12']['VFLOAT1'] !== null && processedRules['10_12']['VFLOAT1'] !== '') ? +processedRules['10_12']['VFLOAT1'] : 0;
    if (maxAllowedQte > 0 && +form_data.QTE > maxAllowedQte) {
      this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
      this.newArticleQty.nativeElement.select();
      this.newArticleQty.nativeElement.focus();
      setTimeout(() => {
        this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
      }, 50);

      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kartqntytrop'), this.translateService.instant('kuok'));
      return;
    }

    if((form_data.TYPECDE === 'EI' || form_data.TYPECDE === 'ED' || form_data.TYPECDE === 'BL') && 
      !isNaN(form_data.STOCKDISPO) &&
        +form_data.STOCKDISPO < +form_data.QTE) {
          this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
          this.newArticleQty.nativeElement.focus();
          this.newArticleQty.nativeElement.select();
          setTimeout(() => {
            this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
          }, 50);
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('katystockact'));
        return;
    }

    if(this.newArticleForm.get('NEW_PRIX').touched && !this.newArticleForm.get('NEW_PRIX').pristine && this.newArticleForm.get('NEW_PRIX').dirty && (+form_data.CAPRIX !== +this.currentArticle.calculated_price)) {
      this.openUpPrixForceMotif();
      return;
    }

    this.addNewLineItem();
  }

  async addNewLineItem() {
    const form_data = this.newArticleForm.getRawValue();

    if(form_data.GROUP_AVAIL || +this.currentArticle.GROUP_COUNT > 0) {
      this.loadingService.show();
      this.searchArticleGroupsCAINT(this.currentArticle.CAINT, true, true, form_data.TYPECDE);
      return;
    }

    this.loadingService.show();
    this.__newLineItemAddLogic();
  }

  async __newLineItemAddLogic(type: 'normal' | 'options' = 'normal') {
    const form_data = this.newArticleForm.getRawValue();      

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
    const row_tax = (+form_data.ROW_TOTAL) * 0.021;
    
    let grand_total_amount = 0;
  
    let PRIX_UPV = form_data.NEW_PRIX;
    let PRIX_PVT = form_data.NEW_PRIX * form_data.QTE;
    
    let lineDataRem = "";
    let lineRemTotal = 0.0;
    let lineRemPercentage = 0.0;
    let totalLineRemTotal = 0;
    let lineRemTot = 0;

    if(this.chosenLineRemise !== undefined && this.chosenLineRemise !== null) {
      if(this.chosenLineRemise.length === 0) {
        // Remise 0
        lineRemPercentage = 0.0;
        lineRemTotal = 0.0;
        lineDataRem = '';
        totalLineRemTotal = 0;
        lineRemTot = 0;
      } else {
        let remFormat: string[] = [];
        for(let j=0;j < this.chosenLineRemise.length;j++) {
          let currentLineRemPercentage = +this.chosenLineRemise[j].REMMTAUX;
          lineRemPercentage += currentLineRemPercentage;
          lineRemTotal += form_data.CAPRIX * (currentLineRemPercentage / 100);
          remFormat.push(this.chosenLineRemise[j].REMMCODE+'#'+(+currentLineRemPercentage)+'#'+this.utilService.formatMaskCompat(lineRemTotal));
        }
        lineDataRem = remFormat.join(';');

        PRIX_UPV = form_data.CAPRIX - lineRemTotal;
        PRIX_PVT = PRIX_UPV * +form_data.QTE;

        totalLineRemTotal = lineRemTotal * +form_data.QTE;
      }
    }

    // for Remise History
    let currentCdart = this.currentArticle.CDART;
    let oldPrix = parseFloat(form_data.CAPRIX.toString());
    let newPrix = form_data.NEW_PRIX.toString();

    let article_data: PanierArticle[] = [];
    let currentOpt: number = 0;
    let currentIdLigne: number = 1;

    if(type === 'options' || form_data.GROUP_AVAIL || +this.currentArticle.GROUP_COUNT > 0) {
      this.previousPanier.articles.map((_item_art: PanierArticle) => {
        if(_item_art.opt < 0) {
          currentOpt = _item_art.opt;
        }
      });

      currentOpt = currentOpt - 1;
    }

    this.previousPanier.articles.map((_item_art: PanierArticle) => {
      currentIdLigne = _item_art.idlig;  
    });
    
    currentIdLigne = currentIdLigne + 1;

    let description = '';
    if(this.currentArticle.CAUNVTE === 'M3') {
      description = `L ${this.qtyLength} x l ${this.qtyWidth} x h ${this.qtyHeight}`;
    }

    article_data.push({
      idlig: currentIdLigne,
      cdart: this.currentArticle.CDART,
      quantite: parseFloat(form_data.QTE.toString()),
      prix: parseFloat(form_data.CAPRIX.toString()), // Article prix
      prixforce: form_data.PRIX_FORCE,
      motifprixforce: (form_data.MOTIF_PRIX !== null)?form_data.MOTIF_PRIX:"",
      commlig1: description,
      commlig2: "",
      prixachatHT: 0.0,
      prixachatTTC: 0.0,
      tauxTVA: "", // Optional
      typecde: form_data.TYPECDE,
      remlig: parseFloat(totalLineRemTotal.toString()), // Total remise amount of the row
      remtot: parseFloat(lineRemTot.toString()),
      datafid: "",
      datafidaco: "",
      dataopeco: "" ,
      datarem: lineDataRem,
      okdiff: false,
      bundle: 0,
      opt: currentOpt,
      lib: this.currentArticle.CALIB3,
      idinstuser: +this.user.IDINSTINT,
      iduser: +this.user.CIINT,
      libuser: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.currentUserName,
      dtpromesse: current_date, // 2018-03-27 10:04:41
      coinsolde: false,
      expo: false,
      motifcde: '',
      numcde: '',
      prixupv: parseFloat(form_data.NEW_PRIX.toString()), // New Price (Typeable) => PU Ven.
      montant: parseFloat((form_data.NEW_PRIX * form_data.QTE).toString()), // Row Subtotal - PV Total
      remise: lineRemPercentage,
      emplacement: "",
      statut: this.currentArticle.CAECODE === 'A'?-1:0,
      dtmaj: current_date
    });

    if(type === 'options') {
      currentOpt = Math.abs(currentOpt);
      
      this.currentArticleChosenGroups.GROUPS.map((_row_group: any) => {
        _row_group.GROUP_CHOSEN_OPTIONS.map((_row_option: any) => {
          if(_row_option.SELECTED) {
            currentIdLigne = currentIdLigne + 1;

            article_data.push({
              idlig: currentIdLigne,
              cdart: _row_option.CDART,
              quantite: parseFloat(form_data.QTE.toString()),
              prix: parseFloat(_row_option.PRIX.toString()), // Article prix
              prixforce: false,
              motifprixforce: "",
              commlig1: "",
              commlig2: "",
              prixachatHT: 0.0,
              prixachatTTC: 0.0,
              tauxTVA: "", // Optional
              typecde: form_data.TYPECDE,
              remlig: 0, // Total remise amount of the row
              remtot: 0,
              datafid: "",
              datafidaco: "",
              dataopeco: "" ,
              datarem: "",
              okdiff: false,
              bundle: 0,
              opt: currentOpt,
              lib: _row_option.CALIB,
              idinstuser: +this.user.IDINSTINT,
              iduser: +this.user.CIINT,
              libuser: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.currentUserName,
              dtpromesse: current_date, // 2018-03-27 10:04:41
              coinsolde: false,
              expo: false,
              motifcde: '',
              numcde: '',
              prixupv: parseFloat(_row_option.PRIX.toString()), // New Price (Typeable) => PU Ven.
              montant: parseFloat((_row_option.PRIX * _row_option.QTY).toString()), // Row Subtotal - PV Total
              remise: 0,
              emplacement: "",
              statut: 0,
              dtmaj: current_date
            });
          }
        })
      })
    }
    const chosenLineRemiseBcup = this.chosenLineRemise;
    const chosenArticleBcup = this.currentArticle;
    const forcePrixMotif = (form_data.MOTIF_PRIX !== null) ? form_data.MOTIF_PRIX_LBL : '';

    let insertion: any;
    for(const article_datum of article_data) {
      insertion = await this.insertNewOptionCallAPI({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pArticle: article_datum, pPosition: article_datum.idlig }, false);
    }

    if(chosenLineRemiseBcup !== undefined && chosenLineRemiseBcup !== null && chosenLineRemiseBcup.length > 0) {
      try {
        await this._addLigneRemiseHistory(currentCdart, this.user.CIINT, oldPrix, newPrix, false);

        if (this.authorizedLineRemiseUser !== undefined && this.authorizedLineRemiseUser !== null) {
          await this._addLigneRemiseHistory(currentCdart, this.authorizedLineRemiseUser.CIINT, oldPrix, newPrix);
          this.authorizedLineRemiseUser = undefined;
        }
      } catch(err) {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    }

    if (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) {
      await this._addChangementPrixHistory(currentCdart, forcePrixMotif, +chosenArticleBcup.calculated_price, form_data.CAPRIX);
      this.authorizedLinePrixUser = undefined;
    }

    if(insertion) {
      this.bonNumBon = insertion.data.NumBon;
      this.previousPanier = insertion.data.Panier;     
      this.listBonCommandeItems = this.previousPanier.articles;
  
      this.listBonVenteArticles = insertion.data.customArticles;
      this.previousPanierClient = insertion.data.customClient;
      this.chosenClient = this.previousPanierClient;
  
      this.storageService.setItem('previousBonPanier', this.previousPanier);
      this.storageService.setItem('previousNumBon', this.bonNumBon);
  
      this.chosenLineRemise = undefined;
      this.chosenRemise = undefined;
  
      this.currentArticle = undefined;
      this.article = this.currentArticle;          
      this.commander = false;
      this.chosenForcage = undefined;
  
      this.newArticleForm.patchValue({
        CDART: '',
        CALIB1: '',
        QTE: this.utilService.formatQteMaskCompat(1),
        CAPRIX: '',
        NEW_PRIX: this.utilService.formatMaskCompat(0),
        ROW_TOTAL: '',
        TYPECDE: '',
        MOTANT: this.utilService.formatMaskCompat(0),
        STOCKDISPO: '',
        PRIX_FORCE: false,
        MOTIF_FORCE: '',
        GROUP_AVAIL: false
      });

      this.qtyLength = 0;
      this.qtyWidth = 0;
      this.qtyHeight = 0;
  
      this.newArticleForm.get('QTE').disable({ onlySelf: true });
      this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
      this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });          
      this.refreshByPanier(this.previousPanier);
      this.bondeCommandeTicketStatus();
      this.openCommandeDetail();
      this.__updateBonDeCommandeList();
    }
    
    this.loadingService.hide();
  }

  _addLigneRemiseHistory(CDART: string, CIINT: string, oldPrix: number, newPrix: number, HISTORY_AUTHORIZATION: boolean = true): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REMISE_EN_LIGNE,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART,
      CMINT: this.USERCMINT,
      CIINT: CIINT,
      VALUER1: oldPrix,
      VALUER2: newPrix,
    }, HISTORY_AUTHORIZATION);
  }

  setStep(index: number) {
    this.step = index;

    if(index === 0) {
      setTimeout(() => {
        if(this.cdartInput) {
          this.cdartInput.nativeElement.setAttribute('inputmode', 'none'); 
          this.cdartInput.nativeElement.focus();
          this.cdartInput.nativeElement.select();
          this.cdartInput.nativeElement.setAttribute('inputmode', 'text'); 
        }
      }, 100)
    }
  }

  __scrollToTab(el: string) {
    setTimeout(() => {
      if(this.cdartInput) {
        this.cdartInput.nativeElement.setAttribute('inputmode', 'none');
        this.focusMonitor.focusVia(this.cdartInput.nativeElement, 'keyboard');
        this.cdartInput.nativeElement.setAttribute('inputmode', 'text');
      }
    }, 200);

    setTimeout(() => {
      const element = document.getElementById(el);
      element.scrollIntoView({ behavior: 'smooth' })
    }, 80)
  }

  openCommandeDetail(){
    this.step = 1;
  }

  openUpPrixForceMotif() {
    const dialogRef = this.dialog.open(MotifForcePrixComponent,
      {
        data: {
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(result.data.force_motif !== '') {
            this.newArticleForm.get('PRIX_FORCE').setValue(true);
            this.newArticleForm.get('MOTIF_PRIX').setValue(result.data.force_motif);
            this.newArticleForm.get('MOTIF_PRIX_LBL').setValue(result.data.MOTIF_LABEL);
            
            this.loadingService.show();
            this.addNewLineItem();
          } else {
            this.newArticleForm.get('PRIX_FORCE').setValue(false);
            this.newArticleForm.get('MOTIF_PRIX').setValue('');
            this.newArticleForm.get('MOTIF_PRIX_LBL').setValue('');

            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonprixoblig'));
          }          
      }
    });
  }

  async submitBonDeCommande() {
    if(this.previousPanier === undefined || this.previousPanier === null || (this.previousPanier && this.previousPanier.articles.length === 0)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if(this.previousPanier.client === undefined || this.previousPanier.client === null) {      
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonclientobligat')).subscribe(
        (res) => {
          this.bonVenteSelectedTab = 0;
          setTimeout(() => {
            this.focusClientSearch = true;
          }, 400);
        }
      );
      return;
    }

    if (
        (this.previousPanier.client.gsm === undefined || this.previousPanier.client.gsm === null || this.previousPanier.client.gsm === '')
        && (this.previousPanier.client.tel === undefined || this.previousPanier.client.tel === null || this.previousPanier.client.tel === '')
    ) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclienttelnatelmand'));
      return;
    }

    if (this.emailMandatory
        && (this.previousPanier.client.email === undefined || this.previousPanier.client.email === null || this.previousPanier.client.email === '')
    ) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kclientemailmand'));
      return;
    }

    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let bonCommandeData: any = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon,
      pPanier: {
        ...this.previousPanier,
        comm: this.bonVenteComments !== null?this.bonVenteComments:'',      
        dtmaj: current_date
      }
    };

    const livreYear = moment(this.previousPanier.dtliv).year();
    const emporteYear = moment(this.previousPanier.dtech).year();
    let Promesse_year = moment(this.previousPanier.dtpromesse).startOf('day').year();

    this.loadingService.show();
    await this.getBonDeCommandeByNumBon(this.bonNumBon);

    if(this.livraisonBondeVente && livreYear == 1899 && this.previousPanier.livjour === '' && this.previousPanier.transporteur === '') {
      this.openUpLivraionInfoDetails(this.previousPanier, (result: any) => {        
        this.livraisonData = result.data;
              
        this.updateBonCommandeEdBl('BL').then(
          (data: any) => {
            if(this.emporteDiffere && emporteYear == 1899) {
              this.openUpEmporteDiffere(this.previousPanier, (result: any) => {
                this.emporteeDiffereDate = result.data.dtemporte;
                this.updateBonCommandeEdBl('ED').then(
                  () => {
                    if(this.promesseAvail && Promesse_year == 1899) {
                      this.openUpDatePromesse(this.previousPanier, (result: any) => {
                        this.promesseDate = result.data.dtpromesse;
            
                        this.updateBonCommandeEdBl('promesse').then(
                          (data: any) => {
                            this.router.navigate(['/retrait-marchandise']);
                          }
                        );
                      })
            
                      return;
                    }

                    this.router.navigate(['/retrait-marchandise']);
                  }
                );
              });
              return;
            }

            if(this.promesseAvail && Promesse_year == 1899) {
              this.openUpDatePromesse(this.previousPanier, (result: any) => {
                this.promesseDate = result.data.dtpromesse;
    
                this.updateBonCommandeEdBl('promesse').then(
                  (data: any) => {
                    this.router.navigate(['/retrait-marchandise']);
                  }
                );
              })
    
              return;
            }

            this.router.navigate(['/retrait-marchandise']);
          }
        );
      });
      return;
    }

    if(this.emporteDiffere && emporteYear == 1899) {
      this.openUpEmporteDiffere(this.previousPanier, (result: any) => {
        this.emporteeDiffereDate = result.data.dtemporte;
        this.updateBonCommandeEdBl('ED').then(
          () => {
            if(this.promesseAvail && Promesse_year == 1899) {
              this.openUpDatePromesse(this.previousPanier, (result: any) => {
                this.promesseDate = result.data.dtpromesse;
    
                this.updateBonCommandeEdBl('promesse').then(
                  (data: any) => {
                    this.router.navigate(['/retrait-marchandise']);
                  }
                );
              })
    
              return;
            }

            this.router.navigate(['/retrait-marchandise']);
          }
        );
      });
      return;
    }

    if(this.promesseAvail && Promesse_year == 1899) {
      this.openUpDatePromesse(this.previousPanier, (result: any) => {
        this.promesseDate = result.data.dtpromesse;

        this.updateBonCommandeEdBl('promesse').then(
          (data: any) => {
            this.router.navigate(['/retrait-marchandise']);
          }
        );
      })

      return;
    }

    bonCommandeData['Id_User'] = this.user.CIINT;
    bonCommandeData['Id_InstUser'] = Constants.CIINST;

    this.loadingService.show();
    this.editBonDeCommande(bonCommandeData).then(
      (data: any) => {
        this.router.navigate(['/retrait-marchandise']);
      }
    );          
  }

  onFocusCommentaire() {
    this.updateCommentInProgress = true;
    this.updateBonCommandeEdBl('comment');
  }

  onBlurCommentaire() {
    let oldComments = this.previousPanier.comm;
    
    if(oldComments != this.bonVenteComments) {
      this.updateBonCommandeEdBl('comment');
    }
  }

  updateBonCommandeEdBl(type: 'ED' | 'BL' | 'comment' | 'promesse'): Promise<void> {
    return new Promise((resolve, reject) => {
      if(type !== 'comment') {
        this.loadingService.show();
      }

      this.modificationCommandeDisabled = true;

      if(this.livraisonDetailSubscription) {
        this.livraisonDetailSubscription.unsubscribe();
      }

      let bonCommandeData: any = {
        dtliv: this.previousPanier.dtliv,
        dtlivsouhait: this.previousPanier.dtlivsouhait,
        livjour: this.previousPanier.livjour,
        livconfirm: this.previousPanier.livconfirm,
        livinfo1: this.previousPanier.livinfo1,      
        livinfo2: this.previousPanier.livinfo2,
        transporteur: this.previousPanier.transporteur,
        livdeport: this.previousPanier.livdeport,
        livdirecte: this.previousPanier.livdirect,
        dtech: this.previousPanier.dtech,
        txtcomm: this.previousPanier.comm,
        IDMAG: this.previousPanier.mag,
        IDSOC: Constants.IDSOC,
        pNumBon: this.bonNumBon,

        idusermaj: +this.user.CIINT,
        idinstusermaj: +this.user.IDINSTINT,
        libusermaj: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.previousPanier.libusermaj,
      };
  
      if(type === 'BL') {
        if(this.livraisonData !== undefined && this.livraisonData !== null) {
          bonCommandeData.dtliv = moment(this.livraisonData.dtliv).format('YYYY-MM-DD HH:mm:ss');
          bonCommandeData.dtlivsouhait = moment(this.livraisonData.dtlivsouhait).format('YYYY-MM-DD HH:mm:ss');
          bonCommandeData.livjour = this.livraisonData.livjour;
          bonCommandeData.livconfirm = this.livraisonData.livconfirm?true:false;
          bonCommandeData.livinfo1 = (this.livraisonData.livinfo1 !== null)?this.livraisonData.livinfo1:'';
          bonCommandeData.livinfo2 = (this.livraisonData.livinfo2 !== null)?this.livraisonData.livinfo2:'';
          bonCommandeData.livdeport = this.livraisonData.livdeport?true:false;
          bonCommandeData.livdirect = this.livraisonData.livdirect?true:false;
    
          bonCommandeData.transporteur = this.livraisonData.transporteurContent;
        } else {
          bonCommandeData.dtliv = moment(this.livraisonData.dtliv).format('YYYY-MM-DD HH:mm:ss');
          bonCommandeData.dtlivsouhait = moment(this.livraisonData.dtlivsouhait).format('YYYY-MM-DD HH:mm:ss');
          bonCommandeData.livjour = '';
          bonCommandeData.livconfirm = false;
          bonCommandeData.livinfo1 = '';
          bonCommandeData.livinfo2 = '';
          bonCommandeData.livdeport = false;
          bonCommandeData.livdirect = false;
          
          bonCommandeData.transporteur = '';
        }
      }
  
      if(type === 'ED') {
        bonCommandeData.dtech = moment(this.emporteeDiffereDate).format('YYYY-MM-DD HH:mm:ss');
      }

      if(type === 'promesse') {
        bonCommandeData.dtpromesse = moment(this.emporteeDiffereDate).format('YYYY-MM-DD HH:mm:ss');
      }

      if(type === 'comment') {
        bonCommandeData.txtcomm = this.bonVenteComments;
      }
  

      this.livraisonDetailSubscription = this.bonCommandeService.modifyLivraisonBonCommande(bonCommandeData).subscribe(
        (data) => {
          this.updateCommentInProgress = false;
          this.loadingService.hide();

          if(data.statusCode == 200) {
            this.previousPanier.idusermaj = bonCommandeData.idusermaj;
            this.previousPanier.idinstusermaj = bonCommandeData.idinstusermaj;
            this.previousPanier.libusermaj = bonCommandeData.libusermaj;

            if(type === 'BL') {
              if(this.livraisonData !== undefined && this.livraisonData !== null) {
                this.previousPanier.dtliv = this.utilService.formatDateToWS(this.livraisonData.dtliv);
                this.previousPanier.dtlivsouhait = this.utilService.formatDateToWS(this.livraisonData.dtlivsouhait);
                this.previousPanier.livjour = this.livraisonData.livjour;
                this.previousPanier.livconfirm = this.livraisonData.livconfirm?true:false;
                this.previousPanier.livinfo1 = (this.livraisonData.livinfo1 !== null)?this.livraisonData.livinfo1:'';
                this.previousPanier.livinfo2 = (this.livraisonData.livinfo2 !== null)?this.livraisonData.livinfo2:'';
                this.previousPanier.livdeport = this.livraisonData.livdeport?true:false;
                this.previousPanier.livdirect = this.livraisonData.livdirect?true:false;
          
                this.previousPanier.transporteur = this.livraisonData.transporteurContent;
              } else {
                this.previousPanier.dtliv = this.utilService.formatDateToWS(this.livraisonData.dtliv);
                this.previousPanier.dtlivsouhait = this.utilService.formatDateToWS(this.livraisonData.dtlivsouhait);
                this.previousPanier.livjour = '';
                this.previousPanier.livconfirm = false;
                this.previousPanier.livinfo1 = '';
                this.previousPanier.livinfo2 = '';
                this.previousPanier.livdeport = false;
                this.previousPanier.livdirect = false;
                
                this.previousPanier.transporteur = '';
              }
            }
        
            if(type === 'ED') {
              this.previousPanier.dtech = this.utilService.formatDateToWS(this.emporteeDiffereDate);
            }

            if(type === 'promesse') {
              this.previousPanier.dtpromesse = this.utilService.formatDateToWS(this.promesseDate);
            }

            if(type === 'comment') {
              this.previousPanier.comm = this.bonVenteComments;
            }
  
            this.storageService.setItem('previousBonPanier', this.previousPanier);
          }

          this.modificationCommandeDisabled = false;
          resolve();
        },
        error => {
          this.updateCommentInProgress = false;
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          this.modificationCommandeDisabled = false;
          reject();
        }
      );
    });
  }

  openUpNumeroCourt() {
    const dialogRef = this.dialog.open(NumeroCourtComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        this.router.navigate(['/retrait-marchandise']);
      }      
    });
  }

  openUpArticleDetail(articleData: Article) {
    const dialogRef = this.dialog.open(ArticleDetailComponent,
      {
        height: this.isMobile?'100%':undefined,
        data: {
          displayType: 'dialog',
          CDART: articleData.CDART,
          articleData: articleData
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        
      }      
    });
  }

  openUpLivraionInfoDetails(panier?: BonPanier, callback?: Function) {
    this.livraisonDialogRef = this.dialog.open(LivraisonDetailsComponent, {
      width: '40vw',
      maxWidth: '40vw',    
      data: {
        livreDetail: this.livraisonData,
        magComplementData: this.magComplementData,
        panier: panier
      }
    });

    this.livraisonDialogRef.componentInstance.suiviLivraisonClosed.subscribe(
      (onSuiviLivraisonChanged: any) => {
        if(onSuiviLivraisonChanged !== undefined && onSuiviLivraisonChanged !== null && onSuiviLivraisonChanged.status === true) {
          this.loadingService.show();
          this.onLivraisonTrackingChanged(true)
        }
      }
    )

    this.livraisonDialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {
        callback(result);        
      }
    });
  }

  openUpEmporteDiffere(panier?: BonPanier, callback?: Function) {
    const dialogRef = this.dialog.open(EmporteeDiffereComponent, {
      width: '40vw',
      maxWidth: '40vw',
      data: {
        magComplementData: this.magComplementData,
        panier: panier,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {        
        callback(result);
      }
    });
  }

  openUpDatePromesse(panier?: BonPanier, callback?: Function) {
    const dialogRef = this.dialog.open(DateDePromesseComponent, {
      width: '40vw',
      maxWidth: '40vw',
      data: {
        magComplementData: this.magComplementData,
        panier: panier,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.status === 'success') {        
        callback(result);
      }
    });
  }

  redirectModificationBondeCommande() {
    this.router.navigate(['/modify-bon-de-commande', this.bonNumBon, this.previousPanier.mag], { queryParams: { from: "retrait-marchandise" } });
  }

  __updateBonDeCommandeList() {
    // List update after a success response
    const oldListData = this.storageService.getItem('bonCommandeListData');
    if(oldListData !== undefined && oldListData !== null && oldListData.data != null) {
      oldListData.data.Bons = oldListData.data.Bons.filter((_r: any) => _r !== null && _r != 'null');
      oldListData.data.Bons.map(
        (row: any) => {
          if(row.numbon == this.bonNumBon) {
            row.montant = this.previousPanier.montant;
            row.dtrel = this.previousPanier.dtrel;
            row.typecde = this.previousPanier.typecde;
            row.dtech = this.previousPanier.dtech;
            row.statut = this.previousPanier.statut;
            row.dtmaj = this.previousPanier.dtmaj;
            row.preparation = this.previousPanier.preparation;
          }
          return row;
        }
      );

      const updatedCommande = oldListData.data.Bons.find(
        (row: any) => row.numbon == this.bonNumBon
      );

      let updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
      updated_row_removed.unshift(updatedCommande);

      oldListData.data.Bons = updated_row_removed;

      this.storageService.setItem('bonCommandeListData', oldListData);
    }
  }

  async _newLineOptionsInsertion(position: number,optionArticle: any, parentPanierArticle: PanierArticle): Promise<void> {
    return new Promise(
      async (resolve, reject) => {
      const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
      const article_data = {
        idlig: position,
        cdart: optionArticle.CDART,
        quantite: parseFloat(optionArticle.QTY.toString()),
        prix: parseFloat(optionArticle.PRIX.toString()), // Article prix
        prixforce: false,
        motifprixforce: "",
        commlig1: "",
        commlig2: "",
        prixachatHT: 0.0,
        prixachatTTC: 0.0,
        tauxTVA: "", // Optional
        typecde: parentPanierArticle.typecde,
        remlig: 0, // Total remise amount of the row
        remtot: 0,
        datafid: "",
        datafidaco: "",
        dataopeco: "" ,
        datarem: "",
        okdiff: false,
        bundle: 0,
        opt: Math.abs(parentPanierArticle.opt),
        lib: optionArticle.CALIB,
        idinstuser: +this.user.IDINSTINT,
        iduser: +this.user.CIINT,
        libuser: this.user?this.user.CIPRENOM+' '+this.user.CINOM[0]+'.':this.currentUserName,
        dtpromesse: current_date, // 2018-03-27 10:04:41
        coinsolde: false,
        expo: false,
        motifcde: '',
        numcde: '',
        prixupv: parseFloat(optionArticle.PRIX.toString()), // New Price (Typeable) => PU Ven.
        montant: parseFloat((optionArticle.PRIX * optionArticle.QTY).toString()), // Row Subtotal - PV Total
        remise: 0,
        emplacement: "",
        statut: 0,
        dtmaj: current_date
      };
  
      const insertionData = {
        pMag: this.USERCMINT, 
        pNumBon: this.bonNumBon,
        pArticle: article_data,
        pPosition: position 
      };
  
      insertionData['Id_User'] = this.user.CIINT;
      insertionData['Id_InstUser'] = Constants.CIINST;

      await this.insertNewOptionCallAPI(insertionData, false);
      resolve();
    })
  }

  insertNewOptionCallAPI(data: { pMag: any, pNumBon: string, pArticle: PanierArticle, pPosition: number }, closeLoader: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if(data == undefined || data == null) {
        resolve(null);
      }

      data['Id_User'] = this.user.CIINT;
      data['Id_InstUser'] = Constants.CIINST;

      this.bonApiSubscription = this.bonCommandeService.newOptionInsertion(data).subscribe(
        (resp) => {
          if(resp.statusCode == 200) {
            this.bonNumBon = resp.data.NumBon;
            this.previousPanier = resp.data.Panier;
            this.listBonCommandeItems = this.previousPanier.articles;
  
            this.listBonVenteArticles = resp.data.customArticles;
            this.previousPanierClient = resp.data.customClient;
            this.chosenClient = this.previousPanierClient;
            
            if(this.previousPanier.numfid !== '' && this.previousPanier.numfid !== null) {
              this.previousFIDClient = resp.data.fidClient;
            }
  
            if(this.previousPanier.clientfacture !== '' && this.previousPanier.clientfacture !== null) {
              this.previousFactureClient = resp.data.factureClient;
            }
            
            if(this.previousPanier.clientlivre !== '' && this.previousPanier.clientlivre !== null) {
              this.previousLivreClient = resp.data.livreClient;
            }
  
            if(this.previousPanier.transporteur !== '' && this.previousPanier.transporteur !== null) {
              this.previousTranporteurLabel = resp.data.transLabel;
            }
  
            this.storageService.setItem('previousBonPanier', this.previousPanier);
            this.storageService.setItem('previousNumBon', this.bonNumBon);  
  
            this.refreshByPanier(this.previousPanier);
            
            if(closeLoader) {
              this.loadingService.hide();
              this.bondeCommandeTicketStatus();
            }
            
            resolve(resp);
          } else {
            
            if(closeLoader) {
              this.loadingService.hide();
            }

            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve(resp);
          }        
        },
        err => {
          
          if(closeLoader) {
            this.loadingService.hide();
          }

          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      )
    });
  }

  _addChangementPrixHistory(CDART: string, MOTIF: string, oldPrix: number, newPrix: number): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_CHANGEMENT_DE_PRIX,
      NUMBON: this.bonNumBon,
      ARTEAN: CDART ? CDART : this.currentArticle.CDART,
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedLinePrixUser !== undefined && this.authorizedLinePrixUser !== null) ? this.authorizedLinePrixUser.CIINT : this.user.CIINT,
      MOTIF: MOTIF,
      VALUER1: oldPrix,
      VALUER2: newPrix,
    });
  }

  _addRemiseEnE2History(NUMBON: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REMISE_EN_E2,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedRemiseE2User !== undefined && this.authorizedRemiseE2User !== null) ? this.authorizedRemiseE2User.CIINT : this.user.CIINT,
      VALUER1: null,
      VALUER2: null,
    });
  }

  _addSuppressionCommandeHistory(NUMBON: string, MOTIF: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_SUPPRESSION_COMMANDE,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedSuppressionCommandeUser !== undefined && this.authorizedSuppressionCommandeUser !== null) ? this.authorizedSuppressionCommandeUser.CIINT : this.user.CIINT,
      MOTIF: MOTIF,
      VALUER1: null,
      VALUER2: null,
    });
  }

  _addReactivationCommandeHistory(NUMBON: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_REACTIVATION_COMMANDE,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedReactivationCommandeUser !== undefined && this.authorizedReactivationCommandeUser !== null) ? this.authorizedReactivationCommandeUser.CIINT : this.user.CIINT,
      VALUER1: null,
      VALUER2: null,
    });
  }

  _addFaireRelanceHistory(NUMBON: string, OPERAT_TYPE: string): Promise<any> {
    return this.historyService.addAuthorizationHistory({
      AUTHORIZED_RIGHT_ID: AUTHORIZE_FAIRE_UNE_RELANCE,
      NUMBON: NUMBON,
      ARTEAN: '',
      CMINT: this.USERCMINT,
      CIINT: (this.authorizedRelanceUser !== undefined && this.authorizedRelanceUser !== null) ? this.authorizedRelanceUser.CIINT : this.user.CIINT,
      MOTIF: 'Manuel',
      VALUER1: null,
      VALUER2: null,
      TYPE_OPERAT: OPERAT_TYPE
    });
  }

  openUpArticleGroups(listGroups: any, currentPanierArticle: PanierArticle, currentArticle: Article, TYPECDE: string): Promise<any> {
    const dialogRef = this.dialog.open(VenteArticleGroupsComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier,
          listArticleGroups: listGroups,
          currentItemFormData: undefined,
          TYPECDE: TYPECDE,
          flowType: 'edit',
          pageType: 'commande', // commande, others
          currentItem: currentPanierArticle,
          currentArticle: currentArticle
        }
      }
    );

    return dialogRef.afterClosed().toPromise();
  }

  openUpNewArticleGroups(listGroups: any, callback: Function) {
    const dialogRef = this.dialog.open(VenteArticleGroupsComponent,
      {
        data: {
          bonNumBon: this.bonNumBon,
          panier: this.previousPanier,
          listArticleGroups: listGroups,
          currentItemFormData: this.newArticleForm.getRawValue(),
          currentArticle: this.currentArticle
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        callback(result);
      }      
    });
  }

  _focusPUvente() {
    if(this.newPrixVente){
      this.newPrixVente.nativeElement.select();            
      this.newPrixVente.nativeElement.focus();
      this.newPrixVente.nativeElement.click();
    }
  }

  _triggerSubmitNewItem() {
    this.newArticleFormEl?.ngSubmit.emit();
  }

  searchArticleRapid(event: any =''){
    if(event){
      event.preventDefault();
    }

    if(this.newArticleForm.get('CDART').errors) {
      return;
    }
    this.chosenLineRemise = undefined;
    const search_code = this.newArticleForm.get('CDART').value;

    if(this.cdartInput && this.isMobile) {
      this.cdartInput.nativeElement.readOnly = true;
      this.cdartInput.nativeElement.setAttribute('inputmode', 'none');

      setTimeout(() => {
        this.cdartInput.nativeElement.setAttribute('inputmode', 'text');
        this.cdartInput.nativeElement.readOnly = false;
      }, 50)
    }

    this._barCodeLogicCheck(search_code, false).catch((val) => {
      this.searchArticleCDART();
    })
  }

  searchArticleCDART(event: any ='') {
    if(event){
    event.preventDefault();
    }

    if(this.newArticleForm.get('CDART').errors) {
      return;
    }

    this.loadingService.show();
    const CDART = this.newArticleForm.get('CDART').value;

    this.bonVenteService.getArticleByCDART(CDART, this.USERCMINT).subscribe(
      (res: any) => {
        this.chosenLineRemise = [];
        if(res.success !== undefined) {
          this.PU_vente_changable = true;
          if(!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if(res.data.length > 0) {
            let groupAvail = res.groupExist?res.groupExist:false;
            this.currentArticle = res.data[0];            

            this.newArticleForm.patchValue({
              CALIB1: this.currentArticle.CALIB3,
              CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
              TYPECDE: this.previousPanier.typecde,
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: groupAvail,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });
            
            if(this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }

            if(this.newArticleQty && !this.isMobile){
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }

            this.definingTypeDeRules();
            
            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CALIB1: '',            
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: false,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;
            
            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            if(!isNaN(Number(CDART))) {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
            } else {
              this.searchArticleNOM();
            }
          }          
        } else {
          this.currentArticle = undefined;
          this.newArticleForm.patchValue({
            CALIB1: '',            
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: '',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            GROUP_AVAIL: false,
            QTE: this.utilService.formatQteMaskCompat(1),
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.currentArticle = undefined;
        this.chosenLineRemise = [];
        this.newArticleForm.patchValue({
          CALIB1: '',            
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: '',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          GROUP_AVAIL: false,
          QTE: this.utilService.formatQteMaskCompat(1),
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;
        
        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  searchArticleByCMEAN(event: any) {
    if(event === undefined || event == 'null') {
      return;
    }

    this.loadingService.show();
    const CMEAN = event;

    this.bonVenteService.getArticleByCMEAN(CMEAN, this.USERCMINT).subscribe(
      (res: any) => {
        this.chosenLineRemise = [];
        if(res.success !== undefined) {
          this.PU_vente_changable = true;
          if(!this.userPermission['0_17']) {
            this.PU_vente_changable = false;
          }

          if(res.data.length > 0) {
            let groupAvail = res.groupExist?res.groupExist:false;
            this.currentArticle = res.data[0];            

            this.newArticleForm.patchValue({
              CDART: this.currentArticle.CDART,
              CALIB1: this.currentArticle.CALIB3,
              CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
              TYPECDE: this.previousPanier.typecde,
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: groupAvail,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if(this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }
            
            if(this.newArticleQty && !this.isMobile){
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }

            this.definingTypeDeRules();
            
            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CDART: '',
              CALIB1: '',            
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: false,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;
            
            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
          }          
        } else {
          this.currentArticle = undefined;
          this.newArticleForm.patchValue({
            CDART: '',
            CALIB1: '',            
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: '',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            GROUP_AVAIL: false,
            QTE: this.utilService.formatQteMaskCompat(1),
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.currentArticle = undefined;
        this.chosenLineRemise = [];
        this.newArticleForm.patchValue({
          CDART: '',
          CALIB1: '',            
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: '',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          GROUP_AVAIL: false,
          QTE: this.utilService.formatQteMaskCompat(1),
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;
        
        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  searchArticleGroupsCAINT(CAINT: any, opensPopup: boolean = true, closeLoader: boolean = true, TYPECDE: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.getArticleGroupsByCAINT(CAINT, this.USERCMINT, this.user.CIINT).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            if(res.data.length > 0) {
              if(closeLoader) {
                this.loadingService.hide();
              }

              let groupsData = res.data;
              this.articleGroupsRawData = groupsData;
              this.listArticleGroups = [];
  
              if(groupsData !== undefined && groupsData != null && groupsData.length > 0) {
                groupsData.map((row: any) => {
                  let groupAvail = this.listArticleGroups.find((_row) => _row.group_id == row.id);
    
                  if(groupAvail === undefined || groupAvail === null) {
                    this.listArticleGroups.push({
                      group_id: row.id,
                      group_label: row.label,
                      group_min: row.selectable_qtymin,
                      group_max: row.selectable_qty,
                      group_custom: row.custom,
                      group_type: row.TYPECDE,
                      group_deposit: row.DEPOSIT,
                      group_active: row.ACTIVE,
                      group_position: row.grouppos,
                      group_articles: [row]
                    });
                  } else {
                    this.listArticleGroups.map((_row) => {
                      if(_row.group_id == row.id) {
                        _row.group_articles.push(row);
                      }
                    });                
                  }              
                });

                if(groupsData.some((_r: any) => _r.TYPECDE == null || TYPECDE.indexOf(_r.TYPECDE) > -1)) {
                  if(opensPopup) {
                    this.openUpNewArticleGroups(this.listArticleGroups, (res: any) => {
                      if(res.status === 'success') {
                        this.currentArticleChosenGroups = res.data;
                        this.loadingService.show();
                        this.__newLineItemAddLogic('options');
                      }
                    });
                  }
                } else {
                  if(opensPopup) {
                    this.loadingService.show();
                    this.__newLineItemAddLogic();
                  }
                }
              }
              resolve(res);
            } else {
              this.currentArticle = undefined;
              this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonitemnotfound'));
              if(closeLoader) {
                this.loadingService.hide();
              }
              resolve(res);
            }
          } else {
            this.currentArticle = undefined;          

            this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
            if(closeLoader) {
              this.loadingService.hide();
            }
            resolve(res);
          }      
        },
        (err) => {
          this.currentArticle = undefined;
          
          if(closeLoader) {
            this.loadingService.hide();
          }
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(err);
        }
      );
    });    
  }

  searchArticleNOM() {
    const NOM = this.newArticleForm.get('CDART').value;
    
    this.bonVenteService.getArticleByNOM(NOM, this.USERCMINT).subscribe(
      (res: any) => {
        if(res.success !== undefined) {
          const data = res.data;
          const totalResults: number = res.data.length;
          
          if(totalResults === 1) {
            let groupAvail = res.groupExist?res.groupExist:false;
            this.currentArticle = res.data[0];
            this.newArticleForm.patchValue({
              CDART: this.currentArticle.CDART,
              CALIB1: this.currentArticle.CALIB1,
              CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
              TYPECDE: this.previousPanier.typecde,
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: groupAvail,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });   
            
            if(this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }
            
            if(this.newArticleQty && !this.isMobile){
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }
            this.definingTypeDeRules();
            this.loadingService.hide();
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CDART: '',
              CALIB1: '',
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: '',
              PRIX_FORCE: false,
              MOTIF_FORCE: '',
              GROUP_AVAIL: false,
              QTE: this.utilService.formatQteMaskCompat(1),
              MOTANT: this.utilService.formatMaskCompat(0)
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;

            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

            if(data.length > 0) {
              this.loadingService.hide();
              const data = {
                formType: 'advancedSearch',
                filterData: { NOM }
              };
              this.openUpSearchArticle(undefined, data);
            } else {
              this.loadingService.hide();
              const data = {
                formType: 'advancedSearch',
                filterData: { NOM }
              };
              this.openUpSearchArticle(undefined, data);
            }
          }
        } else {
          this.currentArticle = undefined;
          this.newArticleForm.patchValue({
            CDART: '',
            CALIB1: '',            
            CAPRIX: '',
            NEW_PRIX: this.utilService.formatMaskCompat(0),
            ROW_TOTAL: '',
            TYPECDE: '',
            STOCKDISPO: '',
            PRIX_FORCE: false,
            MOTIF_FORCE: '',
            GROUP_AVAIL: false,
            QTE: this.utilService.formatQteMaskCompat(1),
            MOTANT: this.utilService.formatMaskCompat(0)
          });

          this.qtyLength = 0;
          this.qtyWidth = 0;
          this.qtyHeight = 0;
          
          this.newArticleForm.get('QTE').disable({ onlySelf: true });
          this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
          this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
        }      
      },
      (err) => {
        this.currentArticle = undefined;
        this.newArticleForm.patchValue({
          CDART: '',
          CALIB1: '',            
          CAPRIX: '',
          NEW_PRIX: this.utilService.formatMaskCompat(0),
          ROW_TOTAL: '',
          TYPECDE: '',
          STOCKDISPO: '',
          PRIX_FORCE: false,
          MOTIF_FORCE: '',
          GROUP_AVAIL: false,
          QTE: this.utilService.formatQteMaskCompat(1),
          MOTANT: this.utilService.formatMaskCompat(0)
        });

        this.qtyLength = 0;
        this.qtyWidth = 0;
        this.qtyHeight = 0;
        
        this.newArticleForm.get('QTE').disable({ onlySelf: true });
        this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
        this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });

        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  definingTypeDeRules() {
    if(this.currentArticle.CAGESTOCK != '0' && this.currentArticle.CAOKLIV != '-1') {
      if(this.currentArticle.STOCKDISPO > 0) {
        this.forcageType = 'commande';
        this.forcage_text = "kbonforcecommand";
        this.commander = false;
      } else {
        this.forcageType = 'vente';
        this.commander = true;
        this.forcage_text = "kbonforecsale";
      }
    } else {
      this.commander = false;
      this.forcageType = undefined;
      this.forcage_text = "";
      if(this.currentArticle.CAOKLIV == '-1') {
        this.newArticleForm.patchValue({
          STOCKDISPO: this.translateService.instant('kbonartliv'),
        });
      } else {
        this.newArticleForm.patchValue({
          STOCKDISPO: this.translateService.instant('kartmang'),
        });
      }
    }

    if(this.currentArticle.etatart === 'G') {
      this.commander = true;
    }
  }

  get isE2Type() {
    return this.previousPanier.typecde.indexOf('E2') > -1;
  }

  deleteCommandeConfirmation(item: PanierArticle): void {    
    let title = '';
    let message = `
    <h1 class="text-left mb-3">${this.translateService.instant('kboncomsupprimercmd')}</h1>
    <h1 class="text-left pl-2">        
      ${this.translateService.instant('kbonNum')} : ${this.bonNumBon} <br>
      ${this.translateService.instant('kbonpour')}: ${this.previousPanier.client?.prenom+' '+this.previousPanier.client?.nom} <br>
      ${this.translateService.instant('kbonmontant')}: ${this.currencyPipe.transform(this.previousPanier.montant, null, false)}
    </h1>
    `;

    if(this.previousPanier.articles.length > 1) {
      title = this.translateService.instant('kboncomsupprimerligne');
      message = '';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: title,
        description: message
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.loadingService.show();
        this.openUpLigneBondeCustomMotif(item);
      }
    });    
  }

  openUpLigneBondeCustomMotif(item: PanierArticle): void {
    const dialogRef = this.dialog.open(BlocageCustomMotifComponent, {
      maxWidth: '40vw',
      data: {
        action: Constants.MOTIF_SUPPRESSION_COMMANDE
      }
    });

    dialogRef.afterClosed().subscribe(result => {          
      if(result && result.status === "success") {
        const custom_motif_data = result.data;
        
        let motifText = custom_motif_data.MOTLIB;
        if(this.previousPanier.articles.filter((_r) => _r.opt <= 0).length > 1) {
          this.supprimerCommandeLineItem(motifText, item);
        } else {
          this.deleteBonDeCommande({ pMag: this.USERCMINT, pNumBon: this.bonNumBon, pMotif: motifText });
        }
      }      
    });    
  }

  onSwipe(e: any, index: number, item: PanierArticle) {
    if((this.previousPanier.iduser != +this.user.CIINT && !this.userPermission['0_21'])) {
      return;
    }

    if(e.deltaX > 0) {
      // Right Swipe
      const element = document.getElementById('swipeItem-' + index);
      element.style.transform = 'translateX(0)'
      element.classList.remove('swiped');
    } else {
      // Left Swipe
      this.__resetPreviousSwipes('.mat-list-item');

      const element = document.getElementById('swipeItem-' + index);
      element.classList.add('swiped');
      element.style.transform = 'translateX(-100px)'
    }
  }

  __resetPreviousSwipes(listEl: string) {
    const allSwipeAbles = document.querySelectorAll(listEl);
    allSwipeAbles.forEach((el: any, i) => {
      el.classList.remove('swiped');
      el.style.transform = 'translateX(0)';
    })
  }

  deleteBonDeCommandeLigne(data: { pMag: number, pNumBon: string, pLignes: string, pMotif: string, Id_User: any, Id_InstUser: any }, closeLoader: boolean = true): Promise<any> {
    return new Promise(
      async (resolve, reject) => {
      this.bonCommandeService.removeBonCommandeLineItem(data).subscribe(
        async (resp) => {
          if(resp.statusCode == 200) {
              if(resp.data.ErrorCode == 0) {
                await this.getBonDeCommandeByNumBon(this.bonNumBon, false);
                this.__updateBonDeCommandeList();
                if(closeLoader) {
                  this.loadingService.hide();
                }
                resolve(resp);
              } else {
                if(closeLoader) {
                  this.loadingService.hide();
                }
                this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
                resolve(resp);
              }          
            } else {
              if(closeLoader) {
                this.loadingService.hide();
              }
              this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
              resolve(resp);
            }         
        },
        error => {        
          if(closeLoader) {
            this.loadingService.hide();
          }
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
        }
      );
    });
  }

  supprimerCommandeLineItem(motif: string, currentItem: PanierArticle) {
    if(this.previousPanier === undefined || this.previousPanier === null) {
      return;  
    }

    this.loadingService.show();    
    const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

    let article_data: PanierArticle[] = [];
    let pLignes: number[] = [currentItem.idlig];
    article_data = this.previousPanier.articles.filter(
      (item) => {
        if(currentItem.opt < 0 && item.opt == Math.abs(currentItem.opt)) {
          pLignes.push(item.idlig);
          return false;
        }

        return item.idlig != currentItem.idlig;
      }
    );
    this.previousPanier.articles = article_data;

    let bondeCommandeData = {
      pMag: this.previousPanier.mag,
      pNumBon: this.bonNumBon,
      pLignes: pLignes.join(';'),
      pMotif: (motif !== undefined && motif !== null)?motif:'',
      Id_User: +this.user.CIINT,
      Id_InstUser: +this.user.IDINSTINT
    };

    this.deleteBonDeCommandeLigne(bondeCommandeData);
  }

  openUpAlerteClient() {    
    
    this.alertClientData = {
      numBon: this.bonNumBon,
      panier: this.previousPanier, 
      CMINT: this.USERCMINT,
      readOnly: this.readOnly || ([3, 4, 5, 6].indexOf(this.previousPanier.statut) == -1)
    }

    if(!this.isMobile){
      const dialogRef = this.dialog.open(AlerteClientComponent, {
        minWidth: '60vw',
        data: this.alertClientData        
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if(result && result.status === 'success') {
        }
      });
    }
  }

  getListParams(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getRulesParams(this.USERCMINT).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {              
              this.paramsList = resp.data;

              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  _processingButtonsList() {
    this.buttonsListAvail = false;

    let processedRules = this.userService.processRulesParams(this.paramsList);
    
    if(processedRules['51_1']['VBOOL1'] == '-1' || processedRules['51_1']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_5']['VBOOL1'] == '-1' || processedRules['51_5']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_6']['VBOOL1'] == '-1' || processedRules['51_6']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_7']['VBOOL1'] == '-1' || processedRules['51_7']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_8']['VBOOL1'] == '-1' || processedRules['51_8']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_9']['VBOOL1'] == '-1' || processedRules['51_9']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_10']['VBOOL1'] == '-1' || processedRules['51_10']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_11']['VBOOL1'] == '-1' || processedRules['51_11']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_12']['VBOOL1'] == '-1' || processedRules['51_12']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_13']['VBOOL1'] == '-1' || processedRules['51_13']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

    if(processedRules['51_14']['VBOOL1'] == '-1' || processedRules['51_14']['VBOOL4'] == '-1') {
      this.buttonsListAvail = true;
    }

  }

  openUpTickets() {
    this.ticketData = {
      numBon: this.bonNumBon,
      panier: this.previousPanier,
      client: this.previousPanierClient,
      CMINT: this.previousPanier.mag,
      pageType: ''
    }
    if(!this.isMobile){
      const dialogRef = this.dialog.open(TicketsComponent, {
        minWidth: '82vw',
        height: '40vw',
        data: this.ticketData
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if(result && result.status === 'success') {
        }
      });
    }
  }

  async openUpRelance() {
    if(!this.userPermission['0_16']) {
      this.authorizedRelanceUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '16', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedRelanceUser = authorizationResult.data;
    }

    this.relanceData = {
      panier: this.previousPanier,
      numBon: this.bonNumBon,
      CMINT: this.USERCMINT,
      readOnly: this.readOnly || ([3, 4, 5, 6].indexOf(this.previousPanier.statut) == -1),
      flowType: this.flowType
    }
    
      const dialogRef = this.dialog.open(DateDeRelanceComponent, {
        width: '50vw',
        maxWidth: '50vw',
        data: this.relanceData
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if(result && result.status === 'success') {
          const { data: popup_data, confirmation_data } = result;
          const current_date = moment().format('YYYY-MM-DD HH:mm:ss');
          let updateCommandeData = {
            pMag: this.previousPanier.mag,
            pNumBon: this.bonNumBon,
            pDate: this.utilService.formatDateToWS(popup_data.dtrel),
            Id_User: this.user.CIINT,
            Id_InstUser: this.user.IDINSTINT
          };
          
          this.loadingService.show();
          this.bonCommandeService.modifyDateRelance(updateCommandeData).subscribe(
            async (resp: any) => {
              if(confirmation_data && confirmation_data.SMS === true) {
                await this.sendSMS('relance');
              }

              if(confirmation_data && confirmation_data.EMAIL === true) {
                await this.__printRelance({ pNumBon: this.bonNumBon, pMag: this.USERCMINT, pImprimante: 'download' });
              }

              if (this.authorizedRelanceUser !== undefined && this.authorizedRelanceUser != null) {
                await this._addFaireRelanceHistory(this.bonNumBon, 'relance');
                this.authorizedRelanceUser = undefined;
              }

              this.loadingService.hide();
              if(resp.statusCode == 200) {
                if(resp.data.ErrorCode == 0) {
                  this.previousPanier.dtmaj = this.utilService.formatDateToWS(current_date);
                  this.previousPanier.dtrel = (popup_data.dtrel !== undefined && popup_data.dtrel != 'null')?this.utilService.formatDateToWS(popup_data.dtrel):this.previousPanier.dtrel;
      
                  this.storageService.setItem('previousBonPanier', this.previousPanier);
    
                  // List update after a success response
                  const oldListData = this.storageService.getItem('bonCommandeListData');
                  if(oldListData !== undefined && oldListData !== null) {
                    oldListData.data.Bons.map(
                      (row: any) => {
                        if(row.numbon == this.bonNumBon) {
                          row.dtrel = (popup_data.dtrel !== undefined && popup_data.dtrel != 'null')?this.utilService.formatDateToWS(popup_data.dtrel):this.previousPanier.dtrel;
                        }
                        return row;
                      }
                    );
    
                    const updatedCommande = oldListData.data.Bons.find(
                      (row: any) => row.numbon == this.bonNumBon
                    );
    
                    let updated_row_removed: any[] = oldListData.data.Bons.filter((row: any) => row.numbon != this.bonNumBon);
                    updated_row_removed.unshift(updatedCommande);
    
                    oldListData.data.Bons = updated_row_removed;
    
                    this.storageService.setItem('bonCommandeListData', oldListData);
                  }
    
                  this.refreshByPanier(this.previousPanier);
                } else {
                  this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
                }              
              }
            },
            error => {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            }
          );        
        }
      });
    
  }

  sendSMS(type: string): Promise<void> {
    if(this.bonNumBon === undefined || this.bonNumBon == 'null' || this.USERCMINT === undefined || this.USERCMINT == 'null') return;

    const Id_User = this.user.CIINT;
    const Id_InstUser = this.user.IDINSTINT;

    return new Promise((resolve, reject) => {
      this.bonCommandeService.sendClientSMS(this.bonNumBon, this.USERCMINT, type, Id_User, Id_InstUser).subscribe(
        (resp: any) => {
            if(resp.success !== undefined && resp.success === 'ok') {
              if(resp.data.ErrorCode == 0) {                
                this.dialogService.prompt(this.translateService.instant('kmsuccess'), this.translateService.instant('kmsgenvoye'));
              } else {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);
              }              
              resolve(resp);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  _etatDefine = (): string => {
    if(this.previousPanier === undefined || this.previousPanier === null) return '';
    switch(this.previousPanier.statut) {
      case 0:
        return 'kbonsaise';
        break;
      case 2:
        return 'kbonattenteaffect';
        break;
        case 3:
          return 'kboncomattenderetrait';
          break;
        case 4:
          return 'kboncomattenderetraitED';
          break;
        case 5:
          return 'kboncomattendeliv';
          break;
        case 6:
          return 'kboncomattendelivencour';
          break;
        case 7:
          return 'kboncomcloture';
          break;
        case 8:
          return 'kboncomsoldecompt';
          break;
        case 9:
          return 'kboncomattendevalidate';
          break;
        case 10:
          return 'kboncome2affecttot';
          break;
        case 11:
          return 'kboncome2affect';
          break;
        case 17:
          return 'kboncomcloturecontient';
          break;
        case 27:
          return 'ksolde_partiel_etat';
          break;
        case 50:
          return 'kboncomerrorcompte';
          break;
        case 89:
        case 90:
        case 91:
        case 92:
        case 93:
        case 94:
        case 95:
        case 96:
        case 97:
        case 98:
          return 'kboncomsomticketart';
          break;
        default:
          return '';
          break;
    }    
  }

  getNoteCount(data: { NumBon: string, IDSOC: any, mag: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.bonVenteService.getNoteCount(data).subscribe(
      (data: any) => {
        if(data.success !== undefined) {
          this.totalNotes = data.countNotes;
        }
        resolve(data);
      }, 
      (error: any) => {
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        reject(error);
      });
    });
  }

  public getMagasins(){
    this.listMagasins = [];
    this.loginService.getMagasins().subscribe(
    (data: any) => {
        if (data.length > 0) {
          this.listMagasins = [...data];
        }
    }, 
    (error: any) => {        
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }, 
    () => {              
       
    });
  }

  async getBonDeCommandeByNumBon(numBon: string, closeLoader: boolean = true): Promise<any> {
    if(numBon === undefined || numBon === null) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.bonCommandeService.getBonCommandeDetail(numBon, this.bonMag, this.pageType).subscribe(
        async (resp) => {
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              this.bonNumBon = resp.data.NumBon;
              this.previousPanier = resp.data.Panier;
              this.listBonCommandeItems = this.previousPanier.articles;
    
              this.listBonVenteArticles = resp.data.customArticles;
              this.previousPanierClient = resp.data.customClient;
              this.chosenClient = this.previousPanierClient;
              
              if(this.previousPanier.numfid !== '' && this.previousPanier.numfid !== null) {
                this.previousFIDClient = resp.data.fidClient;
              }
    
              if(this.previousPanier.clientfacture !== '' && this.previousPanier.clientfacture !== null) {
                this.previousFactureClient = resp.data.factureClient;
              }
              
              if(this.previousPanier.clientlivre !== '' && this.previousPanier.clientlivre !== null) {
                this.previousLivreClient = resp.data.livreClient;
              }
    
              if(this.previousPanier.transporteur !== '' && this.previousPanier.transporteur !== null) {
                this.previousTranporteurLabel = resp.data.transLabel;
              }
    
              this.storageService.setItem('previousBonPanier', this.previousPanier);
              this.storageService.setItem('previousNumBon', this.bonNumBon);

              this.refreshByPanier(this.previousPanier);

              if(closeLoader) {
                await this.bondeCommandeTicketStatus();
                this.loadingService.hide();
              }
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);

              this.__clearPageVars();

              this.returnBack();
            }
            resolve(resp);
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
            resolve(resp);
          }        
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      )
    });
  }

  async validateRemiseEnE2() {
    if(this.bonNumBon == 'undefined' || this.bonNumBon == 'null') {
      return;
    }

    if(!this.userPermission['0_12']) {
      this.authorizedRemiseE2User = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '12', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedRemiseE2User = authorizationResult.data;
    }

    const RemiseE2Data = { 
      pMag: this.USERCMINT, 
      pNumBon: this.bonNumBon,
      Id_User: this.user.CIINT,
      Id_InstUser: Constants.CIINST
    };

    this.loadingService.show();
    this.bonCommandeService.validateRemiseEnE2(RemiseE2Data).subscribe(
      async (resp) => {
        if(resp.statusCode == 200) {
          if(resp.data.ErrorCode == 0) {

            if (this.authorizedRemiseE2User !== undefined && this.authorizedRemiseE2User != null) {
              await this._addRemiseEnE2History(this.bonNumBon);
              this.authorizedRemiseE2User = undefined;
            }

            this.remiseEnE2Disabled = true;
            this.getBonDeCommandeByNumBon(this.bonNumBon).then(
              (resp) => {
                this.__updateBonDeCommandeList();
              }
            );
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kuconerr'), resp.data.Error);            
          }
        } else {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }        
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    )
  }

  canExit() {
    if((this.bonNumBon !== undefined && this.previousPanier !== undefined) && (this.listBonCommandeItems.length == 0 || this.bonVenteComments != this.previousPanier.comm)){
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbondecommande_incomplete'));
      return false;
    }

    return true;
  }

  returnBack() {
    if(this.pageType === 'commande') {
      this.router.navigate(['/retrait-marchandise']);
    } else if (this.pageType === 'historique') {
      this.router.navigate(['/historique-des-commandes']);
    } else {
      this.router.navigate(['/commandes-supprimees']);
    }
  }

  openUpDetailStock() {
    const dialogRef = this.dialog.open(ArticleStockComponent,
      {
        minWidth: "50vw",
        autoFocus: false,
        data: {
          NUMBON: this.bonNumBon,
          PANIER: this.previousPanier,
          articleData: this.currentArticle,
          CAINT: this.currentArticle.CAINT,
          stockBlocageEditable: true,
          bonVenteFlow: true,
          currentStockMag: this.currentArticleStockMag,
          flowType: this.flowType
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(!this.currentArticleStockMag && result.STOCK_DISPO !== undefined && result.STOCK_DISPO !== null) {
            this.currentArticle.STOCKDISPO = result.STOCK_DISPO;
            this.newArticleForm.get('STOCKDISPO').setValue(this.decimalPipe.transform(result.STOCK_DISPO));
          }
      }
    });
  }

  async openUpRemiseList(type?: number, totalAmount?: number, newLine?: boolean) {
    if(!this.previousPanier || (type === 1 && !this.currentArticle)) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    if(newLine === true && !this.userPermission['0_1']) {
      this.authorizedLineRemiseUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '1', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedLineRemiseUser = authorizationResult.data;
    }

    if(newLine !== true && !this.userPermission['0_5']) {
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '5', this.user.CIINT, (this.bonNumBon && this.bonNumBon !== '*************'?this.bonNumBon:'')); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }
    }

    const dialogRef = this.dialog.open(RemiseListComponent,
      {
        data: {
          remiseType: type !== undefined?type:2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined?totalAmount:0, // Total amount to calculate discount
          previousChosenRemise: type !== undefined?(type === 1?this.chosenLineRemise:this.chosenRemise):this.chosenRemise,
          panier: this.previousPanier,
          CMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(type === 1 && (totalAmount === undefined || totalAmount == 0)) {
        this.newPrixVente.nativeElement.select();
        this.newPrixVente.nativeElement.focus();
      }
      
      if(result && result.status === "success") {        
        if(type === 1) {
          this.chosenLineRemise = [...result.data];
        } else {
          this.chosenRemise = [...result.data];
        }

        if(newLine === true) {
          if(result.data !== undefined && result.data !== null) {
            const qty = this.newArticleForm.get('QTE').value;
            if(result.data.length !== 0) {              
              let row_total = 0, 
                  rowTotalDiscount = 0, 
                  rowTotalPercentage = 0,
                  discountedPrix = 0;
  
              for(let j=0;j < this.chosenLineRemise.length;j++) {
                const remPercentage = +this.chosenLineRemise[j].REMMTAUX;
                
                let remTotal = this.currentArticleCAPrix.value * (remPercentage / 100);
                if(discountedPrix > 0) {
                  remTotal = discountedPrix * (remPercentage / 100);
                  discountedPrix = discountedPrix - remTotal;
                } else {
                  remTotal = this.currentArticleCAPrix.value * (remPercentage / 100);
                  discountedPrix = this.currentArticleCAPrix.value - remTotal;
                }
  
                rowTotalDiscount += remTotal;
                rowTotalPercentage += remPercentage;
              }

              const totalAfterDiscount = this.currentArticleCAPrix.value - rowTotalDiscount;
              row_total = totalAfterDiscount * qty;

              //Discount percentage calculation
              let newRemisePercent = ((this.currentArticleCAPrix.value - totalAfterDiscount) / this.currentArticleCAPrix.value) * 100;
              
              this.newArticleForm.get('ROW_TOTAL').setValue(this.utilService.formatMaskCompat(row_total));
              this.newArticleForm.get('NEW_PRIX').setValue(this.utilService.formatMaskCompat(+totalAfterDiscount.toFixed(2)));              
              this.newArticleForm.get('MOTANT').setValue(this.utilService.formatMaskCompat(newRemisePercent));
            } else {
              const row_total = this.currentArticleCAPrix.value * qty;
              this.newArticleForm.get('ROW_TOTAL').setValue(this.utilService.formatMaskCompat(row_total));
              this.newArticleForm.get('NEW_PRIX').setValue(this.utilService.formatMaskCompat(this.currentArticleCAPrix.value));
              this.newArticleForm.get('MOTANT').setValue(this.utilService.formatMaskCompat(0));
            }
          }          
        }

        if(newLine !== true && this.previousPanier !== undefined && this.previousPanier !== null) {
          let grand_total_amount = 0;
          const current_date = moment().format('YYYY-MM-DD HH:mm:ss');

          this.previousPanier.articles.map((row) => {
            grand_total_amount += row.montant;
          });

          this.loadingService.show();
          
          if(type === 2 || type === undefined) {
            // Total remise update
            let dataRem = "";
            let remTotal = 0.0;
            let remPercentage = 0.0;            

            if(this.chosenRemise !== undefined && this.chosenRemise !== null) {
              if(this.chosenRemise.length === 0) {
                // Remise 0
                remPercentage = 0.0;
                remTotal = 0.0;
                dataRem = '';
              } else {
                let remFormat: string[] = [];
                for(let j=0;j < this.chosenRemise.length;j++) {
                  let currentRemPercentage = +this.chosenRemise[j].REMMTAUX;
                  remPercentage += currentRemPercentage;
                  remTotal += grand_total_amount * (currentRemPercentage / 100);
                  remFormat.push(this.chosenRemise[j].REMMCODE+'#'+(+currentRemPercentage)+'#'+this.utilService.formatMaskCompat(remTotal));
                }
                dataRem = remFormat.join(';');
              }
            } else {
              // Previous Panier Remise data
              remPercentage = this.previousPanier.remise;
              remTotal = this.previousPanier.remtot;
              dataRem = this.previousPanier.datarem;
            }

            let bondeCommandeData = {
              pMag: this.USERCMINT,
              pNumBon: this.bonNumBon,
              pPanier: this.previousPanier
            };

            bondeCommandeData.pPanier = {
              ...this.previousPanier,            
              comm: this.bonVenteComments !== null?this.bonVenteComments:this.previousPanier.comm,
              dtmaj : current_date,
  
              remtot : remTotal,            
              datarem : dataRem,                                    
              remise: remPercentage,
            };

            bondeCommandeData['Id_User'] = this.user.CIINT;
            bondeCommandeData['Id_InstUser'] = Constants.CIINST;
      
            this.editBonDeCommande(bondeCommandeData);
          } else {
            // Line Remise update
          }
        }
      }      
    });
  }

  openUpAddedRemiseList(type?: number, totalAmount?: number, item?: PanierArticle) {
    if(!this.previousPanier) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    const currentArticleData = item?this.listBonVenteArticles.find((el) => el.CDART == item.cdart):undefined;

    const dialogRef = this.dialog.open(RemiseDetailComponent,
      {
        data: {
          remiseType: type !== undefined?type:2, // 1 = Line remise, 2 = Whole remise
          totalPrice: totalAmount !== undefined?totalAmount:0, // Total amount to calculate discount
          chosenRemise: type !== undefined?(type === 1?this.chosenLineRemise:this.chosenRemise):this.chosenRemise,
          panier: this.previousPanier,
          panier_article: item,
          custom_article: currentArticleData,
          USERCMINT: this.USERCMINT
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
              
      }      
    });
  }

  getMagComplementData() {
    return new Promise((resolve, reject) => {
      this.magComplementService.getMagComplementData(this.USERCMINT).subscribe(
        (resp) => {          
          if(resp.statusCode == 200) {
            this.magComplementData = resp.data[0];            
          } 
          resolve(resp);
        },
        error => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject(error);
        }
      );
    });
  }

  onCloseBonDeCommande() {
    this.router.navigate(['/retrait-marchandise']);
  } 

  async deleteConfirmation(numBon: string, item: any) {

    if(!this.userPermission['0_14']) {
      this.authorizedSuppressionCommandeUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '14', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedSuppressionCommandeUser = authorizationResult.data;
    }

    const message = `
    <h1 class="text-left mb-3">${this.translateService.instant('kbonsursupprimer')}</h1>
    <h1 class="text-left pl-2">        
      ${this.translateService.instant('kbonNum')} : ${numBon} <br>
      ${this.translateService.instant('kbonpour')}: ${item.client?.nom != null?item.client?.nom:''}${item.client?.prenom != null?' '+item.client?.prenom:''} <br>
      ${this.translateService.instant('kbonmontant')}: ${this.currencyPipe.transform(item.montant, null, false)}
    </h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.loadingService.show();
        this.openUpBondeCustomMotif(numBon, item);
      }
    });    
  }

  openUpBondeCustomMotif(numBon: string, item: any): void {
    const dialogRef = this.dialog.open(BlocageCustomMotifComponent, {
      maxWidth: '40vw',
      data: {
        action: Constants.MOTIF_SUPPRESSION_COMMANDE
      }
    });

    dialogRef.afterClosed().subscribe(result => {          
      if(result && result.status === "success") {
        const custom_motif_data = result.data;

        let motifText = custom_motif_data.MOTLIB;
        this.deleteBonDeCommande({ pMag: this.USERCMINT, pNumBon: numBon, pMotif: motifText });
      }      
    });    
  }

  __clearPageVars() {
    this.storageService.removeItem('previousNumBon');
    this.storageService.removeItem('previousBonPanier');

    this.bonNumBon = undefined;
    this.previousPanier = undefined;
    this.listBonCommandeItems = undefined;
    this.listBonVenteArticles = undefined;
    this.bonVenteComments = '';

    this.currentArticle = undefined;

    if(((this.router.routerState.snapshot.url.indexOf('bon-de-commande') > -1 || this.router.routerState.snapshot.url.indexOf('retrait-marchandise') > -1) && this.pageType === 'commande') || 
    (this.router.routerState.snapshot.url.indexOf('historique-des-commandes') > -1 && this.pageType === 'historique') || 
    (this.router.routerState.snapshot.url.indexOf('commandes-supprimees') > -1 && this.pageType === 'supprimees')) {

    } else {
      this.storageService.removeItem('bonCommandeFormData');
      this.storageService.removeItem('bonCommandeListData');
    }
  }

  deleteBonDeCommande(data: { pMag: number, pNumBon: string, pMotif: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.removeBonCommande(data).subscribe(
      async (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {

              if (this.authorizedSuppressionCommandeUser !== undefined && this.authorizedSuppressionCommandeUser != null) {
                await this._addSuppressionCommandeHistory(data.pNumBon, data.pMotif);
                this.authorizedSuppressionCommandeUser = undefined;
              }

              this.__clearPageVars();
              this.storageService.removeItem('bonCommandeListData');
              this.router.navigate(['/retrait-marchandise']);
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }          
          } else {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }         
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  openUpSearchArticle(event: any = '', filterData?: any) {
    if(event){
      event.preventDefault();
    }

    let CMINT = this.USERCMINT;
    if (this.previousPanier && this.previousPanier.magstock) {
      CMINT = this.previousPanier.magstock;
    }

    let passData = {
      CMINT,
      flowType: 'retrait_marchandise',
      CDART: this.newArticleForm.get('CDART').value,
      stockAutreMag: this.currentArticleStockMag,
      stockAutreMagNom: this.currentArticleStockMagNom
    };

    if(filterData !== undefined && filterData != null) {
      passData = {
        CMINT: this.USERCMINT,
        flowType: 'retrait_marchandise',
        stockAutreMag: this.currentArticleStockMag,
        stockAutreMagNom: this.currentArticleStockMagNom,
        ...filterData
      };
    }

    const dialogRef = this.dialog.open(SearchArticleComponent,
      {
        maxHeight: this.isMobile?'95vh':'90vh', 
        data: passData
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
          if(result.data !== undefined) {
            const chosenArticle = result.data;
            this.currentArticle = chosenArticle;            

            this.newArticleForm.patchValue({
              CDART: this.currentArticle.CDART,
              CALIB1: this.currentArticle.CALIB3,
              CAPRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              NEW_PRIX: this.utilService.formatMaskCompat(((this.currentArticle.calculated_price !== null)?+this.currentArticle.calculated_price:0)),
              ROW_TOTAL: this.utilService.formatMaskCompat((+this.currentArticle.calculated_price)),
              TYPECDE: this.previousPanier.typecde,
              STOCKDISPO: this.decimalPipe.transform(this.currentArticle.STOCKDISPO),
              GROUP_AVAIL: false
            });
            
            this.newArticleForm.get('QTE').enable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').enable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').enable({ onlySelf: true });

            if(this.cdartInput && this.isMobile) {
              this.cdartInput.nativeElement.select();
            }

            if(this.newArticleQty && !this.isMobile){
              this.newArticleQty.nativeElement.setAttribute('inputmode', 'none');
              this.newArticleQty.nativeElement.focus();
              this.newArticleQty.nativeElement.select();
              setTimeout(() => {
                this.newArticleQty.nativeElement.setAttribute('inputmode', 'decimal');
              }, 50)
            }

            if(this.currentArticle.CAGESTOCK != '0' && this.currentArticle.CAOKLIV != '-1') {
              if(this.currentArticle.STOCKDISPO > 0) {
                this.forcageType = 'commande';
                this.forcage_text = "kbonforcecommand";
                this.commander = false;
              } else {
                this.forcageType = 'vente';
                this.commander = true;
                this.forcage_text = "kbonforecsale";
              }
            } else {
              this.commander = false;
              this.forcageType = undefined;
              this.forcage_text = "";
              if(this.currentArticle.CAGESTOCK == '0') {
                this.newArticleForm.patchValue({
                  STOCKDISPO: this.translateService.instant('kartmang'),
                });
              } else if(this.currentArticle.CAOKLIV == '-1') {
                this.newArticleForm.patchValue({
                  STOCKDISPO: this.translateService.instant('kbonartliv'),
                });
              }
            }
          } else {
            this.currentArticle = undefined;
            this.newArticleForm.patchValue({
              CALIB1: '',            
              CAPRIX: '',
              NEW_PRIX: this.utilService.formatMaskCompat(0),
              ROW_TOTAL: '',
              TYPECDE: '',
              STOCKDISPO: ''
            });

            this.qtyLength = 0;
            this.qtyWidth = 0;
            this.qtyHeight = 0;
            
            this.newArticleForm.get('QTE').disable({ onlySelf: true });
            this.newArticleForm.get('NEW_PRIX').disable({ onlySelf: true });
            this.newArticleForm.get('TYPECDE').disable({ onlySelf: true });             
          }
      }      
    });
  }

  viewBonCommandeHistory() {
    if(!this.previousPanier) {
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kbonnocrntorder'));
      return;
    }

    let noteReadOnly = this.readOnly;
    if(!this.userPermission['35_1']) {
      noteReadOnly = true;
    }

    // Overriding the Notes access to let the user to Add Notes
    // Only if the Bon is created with the stock magasin which the current logged in user belongs to.
    if (this.readOnly && this.currentArticleStockMag == this.USERCMINT) {
      noteReadOnly = false;
    }

    this.historiqueData = {
      panier: this.previousPanier,
      numBon: this.bonNumBon,
      CMINT: this.previousPanier.mag,
      readOnly: noteReadOnly
    }
    if(!this.isMobile){
      const dialogRef = this.dialog.open(HistoriqueBondeComponent,
        {
          data: this.historiqueData          
        }
      );

      dialogRef.componentInstance.onNoteAdded.subscribe(
        (onNoteAdded: any) => {
          if(onNoteAdded !== undefined && onNoteAdded !== null && onNoteAdded.status === true) {
            const countData = {
              NumBon: this.bonNumBon,
              IDSOC: Constants.IDSOC,
              mag: this.previousPanier.mag,
            };
      
            this.getNoteCount(countData);
          }
        }
      )

      dialogRef.afterClosed().subscribe(result => {
        if(result && result.status === "success") {        
        
        }      
      });
    } 
  }

  viewArticleDetail(){
    if(this.isMobile){
      const dialogRef = this.dialog.open(ArticleDetailComponent,{
          width: '60vw',
          height: '100%',
          data: {
             articleData: this.article,
             displayType: 'dialog'
          }
        }
      );      
    } 
  }

  openAdvancedSearch(){ 
    const dialogRef = this.dialog.open(ArticleFilterComponent,{
        width: '60vw',
        maxHeight: this.isMobile?'95vh':undefined,
        autoFocus: false,
        data: {
          fetchType: 'new_load'
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {    
        const data = {
          formType: 'advancedSearch',
          filterData: result
        };
        this.openUpSearchArticle(undefined, data);
      }      
    });
  }

  closeAdvancedSearch(){        
    if(this.advancedDialogRef){
      this.advancedDialogRef.close();
    }
  } 

  allowNumeric(event) {
    const pattern = /^[0-9\.]+$/; 
    const inputChar = String.fromCharCode(event.keyCode);
    if (!pattern.test(inputChar)) {    
        // invalid character, prevent input
        event.preventDefault();
    }
  }

  _barCodeLogicCheck = (value: string, scanner = true) => {
    return new Promise((resolve, reject) => {
      if(value !== undefined && value != null) {
        
        if(value.indexOf('~') > -1) {
          const [CDART, PRIX] = value.split('~');
          this.newArticleForm.patchValue({ CDART });

          this.searchArticleCDART();
          resolve(CDART);
          return;  
        }

        if(value.length === Constants.BARCODE_HIGHEST_LENGTH) {
          let CDART = value.substr(Constants.BARCODE_CDART_OFFSET_START, Constants.BARCODE_CDART_OFFSET_END);
          CDART = CDART.replace(/^0+/, '');
          
          this.newArticleForm.patchValue({ CDART });

          this.searchArticleCDART();
          resolve(CDART);
          return;
        }

        if(scanner) {
          this.searchArticleByCMEAN(value);
          resolve(value);
        } else {
          reject(value);
        }
      }
    })
  }

  openScanArticle() {
    const dialogRef = this.dialog.open(BarcodeScannerComponent,
      {
        width: '60vw',
        maxHeight: this.isMobile?'95vh':undefined,
        autoFocus: false
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status === "success") {
        this._barCodeLogicCheck(result.data);
      }      
    });
  }

  bondeCommandeTicketStatus(): Promise<any> {
    if(this.bonNumBon === undefined || this.bonNumBon == 'null' || this.USERCMINT === undefined || this.USERCMINT == 'null') return;
    
    return new Promise((resolve, reject) => {
      this.bonCommandeService.getBonComandeTicketsStatus(this.bonNumBon, this.bonMag || this.USERCMINT, '', this.user.CIINT).subscribe(
        (data: any) => {
            if(data.success !== undefined && data.success === 'ok') {
              this.titre = data.titre;

              resolve(data);
            } else {
              this.loadingService.hide();
              this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
              reject('api_error');
            }
        }, 
        (error: any) => {
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject('error_callback');
        });
    });
  }

  
  async reactivateConfirmation() {
    if(!this.userPermission['0_15']) {
      this.authorizedReactivationCommandeUser = undefined;
      await this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('knavezdroits')).toPromise();
      const authorizationResult = await this.utilService.authorizationCheck(this.USERCMINT, '0', '15', this.user.CIINT, this.bonNumBon); 

      if(authorizationResult === undefined || authorizationResult === null || (authorizationResult !== undefined && authorizationResult.status !== 'success')) {
        return;
      }

      this.authorizedReactivationCommandeUser = authorizationResult.data;
    }
  
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: this.translateService.instant('kboncomreactiverbon'),
        description: ''
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.loadingService.show();

        if(this.pageType === 'historique') {
          this.reactivateBonHistory({ pMag: this.USERCMINT, pNumBon: this.bonNumBon });  
        }

        if(this.pageType === 'supprimees') {
          this.reactivateBonSupprimees({ pMag: this.USERCMINT, pNumBon: this.bonNumBon });
        }
      }
    });
  }

  reactivateBonSupprimees(data: { pMag: number, pNumBon: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.reactiverBonCommande(data).subscribe(
      async (resp) => {
        this.loadingService.hide();
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {

              if (this.authorizedReactivationCommandeUser !== undefined && this.authorizedReactivationCommandeUser != null) {
                await this._addReactivationCommandeHistory(data.pNumBon);
                this.authorizedReactivationCommandeUser = undefined;
              }

                const oldListData = this.storageService.getItem('bonCommandeListData');
                if(oldListData !== undefined && oldListData != 'null' && oldListData.data != undefined && oldListData.data != 'null') {
                  oldListData.data.Bons = oldListData.data.Bons.filter((row) => {
                    return row.numbon != this.bonNumBon;
                  })
                }

                this.storageService.setItem('bonCommandeListData', oldListData);
                this.router.navigate(['/commandes-supprimees']);
             
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }          
          } else {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }         
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  reactivateBonHistory(data: { pMag: number, pNumBon: string }) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    this.bonCommandeService.reactiverBonCommandeHistory(data).subscribe(
      async (resp) => {
        this.loadingService.hide();
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {

              if (this.authorizedReactivationCommandeUser !== undefined && this.authorizedReactivationCommandeUser != null) {
                await this._addReactivationCommandeHistory(data.pNumBon);
                this.authorizedReactivationCommandeUser = undefined;
              }

              const oldListData = this.storageService.getItem('bonCommandeListData');

              if(oldListData !== undefined && oldListData != 'null' && oldListData.data != undefined && oldListData.data != 'null') {
                oldListData.data.Bons = oldListData.data.Bons.filter((row) => {
                  return row.numbon != this.bonNumBon;
                })
              }

              this.storageService.setItem('bonCommandeListData', oldListData);
              this.router.navigate(['/historique-des-commandes']);
             
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }          
          } else {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          }         
      },
      error => {        
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  get userAllowedToModifyOrRemove(): boolean {
    return this.previousPanier && this.previousPanier.mag == this.USERCMINT;
  }

  livrerOrAnnulerLivraisonBonDeCommande(data: { pMag: number, pNumBon: string, pLignes: string }, statut: number) {
    data['Id_User'] = this.user.CIINT;
    data['Id_InstUser'] = Constants.CIINST;

    const handleResponse = async (resp) => {
      if (resp.statusCode == 200) {
          if (resp.data.ErrorCode == 0) {
            await this.getBonDeCommandeByNumBon(this.bonNumBon, true);
            this.__updateBonDeCommandeList();
          } else {
            this.loadingService.hide();
            this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
          }
      } else {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    };

    const handleError = () => {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    };

    const serviceCall = statut === 5 
      ? this.bonCommandeService.livrerBonDeCommande(data)
      : this.bonCommandeService.annulerLivraisonBonDeCommande(data);

    serviceCall.subscribe(handleResponse, handleError);
  }

  confirmationDialog(statut: number): Observable<any> {
    const message = `<h1 class="mb-3">${this.translateService.instant(statut === 5 ? 'kliv_en_cours_confirm' : 'kliv_en_attente_confirm')}</h1>`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'),this.translateService.instant('ksno')]
      }     
    });

    return dialogRef.afterClosed();
  }

  async onLivraisonStatusChanged(statut: number) {
    const data = { pMag: this.USERCMINT, pNumBon: this.bonNumBon, pLignes: "" };
    
    if (statut === 5 || statut === 6) {
      const etatConf = await this.confirmationDialog(statut).toPromise();
      if(etatConf !== 'OK') {
        return;
      }

      this.loadingService.show();
      this.livrerOrAnnulerLivraisonBonDeCommande(data, statut);
    }
  }
}
