import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';

//Pages
import { LoginComponent } from '../pages/login/login.component';

import { BonDeVenteComponent } from '../pages/bon-de-vente/bon-de-vente.component';

import { DevisComponent } from '../pages/devis/devis.component';

import { ClientComponent } from '../pages/client/client.component';
import { ClientDetailComponent } from '../pages/client-detail/client-detail.component';

import { ArticleComponent } from '../pages/article/article.component';
import { ArticleDetailComponent } from '../pages/article-detail/article-detail.component';

import { AuthGuard } from '../providers/router/auth.guard';

import { PostalCodesComponent } from 'pages/table-base/postal-codes/postal-codes.component';
import { NiveauDeSecuriteComponent } from 'pages/table-base/niveau-de-securite/niveau-de-securite.component';
import { MotifsComponent } from 'pages/table-base/motifs/motifs.component';
import { UsersComponent } from 'pages/users/users.component';
import { AddUserComponent } from 'pages/users/add-user/add-user.component';
import { EditUserComponent } from 'pages/users/edit-user/edit-user.component';
import { GestionDesDroitsComponent } from 'pages/gestion-des-droits/gestion-des-droits.component';
import { BlocageDeblocageStockComponent } from 'pages/blocage-deblocage-stock/blocage-deblocage-stock.component';
import { CreateBonDeVenteComponent } from 'pages/create-bon-de-vente/create-bon-de-vente.component';
import { ModifyBonDeVenteComponent } from 'pages/modify-bon-de-vente/modify-bon-de-vente.component';
import { MagasinComplementInfoComponent } from 'pages/magasin-complement-info/magasin-complement-info.component'
import { AutreMagasinComponent } from 'pages/create-bon-de-vente/autre-magasin/autre-magasin.component';
import { BonDeCommandeComponent } from 'pages/bon-de-commande/bon-de-commande.component';
import { ModifyBonDeCommandeComponent } from 'pages/modify-bon-de-commande/modify-bon-de-commande.component';
import { CreateDevisComponent } from 'pages/create-devis/create-devis.component';
import { ModifyDevisComponent } from 'pages/modify-devis/modify-devis.component';
import { DeactivatorGuard } from 'providers/router/deactivator.guard';
import { LoginGuard } from 'providers/router/login.guard';
import { ReportingComponent } from 'pages/reporting/reporting.component';
import { ModifyRetraitMarchandiseComponent } from 'pages/modify-retrait-marchandise/modify-retrait-marchandise.component';
import {SearchGestionDesE2Component} from '../pages/search-gestion-des-e2/search-gestion-des-e2.component';
import {ReportingAttendeComponent} from '../pages/reporting-attende/reporting-attende.component';
import { KeycloakGuard } from 'providers/router/keycloak.guard';
import { environment } from 'environments/environment';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        canActivate: [environment.ENABLE_KEYCLOAK ? KeycloakGuard : LoginGuard],
        component: LoginComponent
    },    
    { 
        path: 'bon-de-vente',
        canActivate: [AuthGuard],
        component: BonDeVenteComponent,
        data: {
            id: '4_0'
        }
    },
    {
        path: 'create-bon-de-vente',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: CreateBonDeVenteComponent,
        data: {
            id: '4_1'
        }
    },
    { 
        path: 'create-bon-de-vente/:CMINT',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: CreateBonDeVenteComponent,
        data: {
            id: '110_0'
        }
    },
    { 
        path: 'modify-bon-de-vente/:numbon/:magasin',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: ModifyBonDeVenteComponent,
        data: {
            id: '4_1'
        }
    },
    { 
        path: 'detail-bon-de-vente/:numbon/:magasin',
        canActivate: [AuthGuard],
        component: ModifyBonDeVenteComponent,
        data: {
            id: null,
            readOnly: true
        }
    },
    {
        path: 'devis',
        canActivate: [AuthGuard],
        component: DevisComponent,
        data: {
            id: '24_0'
        }
    },
    {
        path: 'create-devis',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: CreateDevisComponent,
        data: {
            id: '24_1'
        }
    },
    {
        path: 'modify-devis/:numbon',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: ModifyDevisComponent,
        data: {
            id: '24_1'
        }
    },
    {
        path: 'devis-detail/:numbon',
        canActivate: [AuthGuard],
        component: ModifyDevisComponent,
        data: {
            id: null,
            readOnly: true
        }
    },
    {
        path: 'client',
        canActivate: [AuthGuard],
        component: ClientComponent,
        data: {
            id: '2_0'
        }
    },
    {
        path: 'client-detail',
        canActivate: [AuthGuard],
        component: ClientDetailComponent,
        data: {
            id: '2_0'
        }
    },
    {
        path: 'client-detail/:id',
        canActivate: [AuthGuard],
        component: ClientDetailComponent,
        data: {
            id: '2_0'
        }
    },
    {
        path: 'article',
        canActivate: [AuthGuard],
        component: ArticleComponent,
        data: {
            id: '23_0'
        }
    },
    {
        path: 'article-detail/:cdart',
        canActivate: [AuthGuard],
        component: ArticleDetailComponent,
        data: {
            id: '23_0'
        }
    },
    {
        path: 'postal-codes',
        canActivate: [AuthGuard],
        component: PostalCodesComponent,
        data: {
            id: '71_0'
        }
    },
    {
        path: 'niveau-de-securite',
        canActivate: [AuthGuard],
        component: NiveauDeSecuriteComponent,
        data: {
            id: '69_0'
        }
    },
    {
        path: 'motifs',
        canActivate: [AuthGuard],
        component: MotifsComponent,
        data: {
            id: '101_0'
        }
    },
    {
        path: 'utilisateurs',
        canActivate: [AuthGuard],
        component: UsersComponent,
        data: {
            id: '73_0'
        }
    },
    {
        path: 'ajouter-utilisateur',
        canActivate: [AuthGuard],
        component: AddUserComponent,
        data: {
            id: '73_0'
        }
    },
    {
        path: 'modifier-utilisateur/:user_id',
        canActivate: [AuthGuard],
        component: EditUserComponent,
        data: {
            id: '73_0'
        }
    },
    {
        path: 'gestion-des-droits',
        canActivate: [AuthGuard],
        component: GestionDesDroitsComponent,
        data: {
            id: '74_0'
        }
    },
    {
        path: 'motif-blocage',
        canActivate: [AuthGuard],
        component: BlocageDeblocageStockComponent,
        data: {
            id: '52_0'
        }
    },
    {
        path: 'bon-de-vente/autre-magasin',
        canActivate: [AuthGuard],
        component: AutreMagasinComponent,
        data: {
            id: '110_0'
        }
    },
    {
        path: 'bon-de-commande', 
        canActivate: [AuthGuard],
        component: BonDeCommandeComponent,
        data: {
            id: '32_0'
        }
    },
    {
        path: 'bon-de-commande/:source_devis_numbon', 
        canActivate: [AuthGuard],
        component: BonDeCommandeComponent,
        data: {
            id: '32_0'
        }
    },
    {
        path: 'retrait-marchandise', 
        canActivate: [AuthGuard],
        component: BonDeCommandeComponent,
        data: {
            id: '35_1',
            type: 'retrait-marchandise'
        }
    },
    {
        path: 'gestion-des-e2',
        canActivate: [AuthGuard],
        component: SearchGestionDesE2Component,
        data: {
            id: '35_2',
            type: 'gestion-des-e2'
        }
    },
    {
        path: 'relance-client', 
        canActivate: [AuthGuard],
        component: BonDeCommandeComponent,
        data: {
            id: '32_3',
            autre_choix: '6',
            type: 'relance'
        }
    },
    {
        path: 'historique-des-commandes', 
        canActivate: [AuthGuard],
        component: BonDeCommandeComponent,
        data: {
            id: '75_0',
            type: 'historique'
        }
    },
    {
        path: 'commandes-supprimees',
        canActivate: [AuthGuard],
        component: BonDeCommandeComponent,
        data: {
            id: '56_0',
            type: 'supprimees'
        }
    },
    {
        path: 'modify-bon-de-commande/:numbon/:magasin',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: ModifyBonDeCommandeComponent,
        data: {
            id: '32_10'
        }
    },
    {
        path: 'modify-gestion-des-e2/:numbon',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: ModifyBonDeCommandeComponent,
        data: {
            id: '32_10',
            redirectTo: 'gestion-des-e2' // As we're using same component (ModifyBonDeCommandeComponent) for Gestion des E2, specifying the redirectTo parameter
        }
    },
    {
        path: 'modify-retrait-marchandise/:numbon/:magasin',
        canActivate: [AuthGuard],
        canDeactivate: [DeactivatorGuard],
        component: ModifyRetraitMarchandiseComponent,
        data: {
            id: '35_1'
        }
    },
    {
        
        path: 'magasin-complement-info',
        canActivate: [AuthGuard],
        component: MagasinComplementInfoComponent,
        data: {
            id: '60_0'
        }
    },
    {
        path: 'bon-de-commande-detail/:numbon/:magasin',
        canActivate: [AuthGuard],
        component: ModifyBonDeCommandeComponent,
        data: {
            id: null,
            readOnly: true
        }
    },
    {
        path: 'gestion-des-e2-detail/:numbon',
        canActivate: [AuthGuard],
        component: ModifyBonDeCommandeComponent,
        data: {
            id: null,
            readOnly: true,
            redirectTo: 'gestion-des-e2' // As we're using same component (ModifyBonDeCommandeComponent) for Gestion des E2, specifying the redirectTo parameter
        }
    },
    {
        path: 'retrait-marchandise-detail/:numbon/:magasin',
        canActivate: [AuthGuard],
        component: ModifyRetraitMarchandiseComponent,
        data: {
            id: null,
            readOnly: true
        }
    },
    {
        path: 'reporting/reporting_attente',
        canActivate: [AuthGuard],
        component: ReportingAttendeComponent,
        data: {
            id: '54_0',
        }
    },
    {
        path: 'reporting/:page_slug',
        canActivate: [AuthGuard],
        component: ReportingComponent,
        data: {
            id: '54_0',
            childIds: ['54_0', '0_18']
        }
    },
];


@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes, {}),
    ],
    providers: [AuthGuard],
    declarations: [],
    exports: [
        RouterModule
    ],
})

export class AppRoutingModule {
}
