<div class="bloc_container no-border margin_bottom_10 container_add">
    <div class="row">
        <div class="col-12 no-padding text-left">
            <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                <mat-icon>settings_backup_restore</mat-icon>
                <div class="text-medium" translate>kartclearform</div>
            </button>
        </div>
    </div>
</div>
<form [formGroup]="searchForm" (ngSubmit)="dialogSearchFormSubmitter()">
    <div class="bloc_container padding_15 bg_light">
        <div class="row no-gutters">
            <div class="col-12 ">

                <div class="row">
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 order-1">
                        <mat-form-field>
                            <input matInput formControlName="search_code" [placeholder]="'kucode' | translate" #filterFirst />
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 order-2">
                        <mat-form-field>
                            <input matInput formControlName="search_nom" [placeholder]="'kuname' | translate" />
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 order-5">
                        <mat-form-field class="mt-2">
                            <mat-label>{{ 'kbontrierpar' | translate }}</mat-label>
                            <mat-select color="primary" disableOptionCentering panelClass="dropdown-panel" formControlName="search_order">
                                <mat-option *ngFor="let item of orderByList" [value]="item.value">{{ item.label | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 d-none d-sm-none d-md-block">

                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 order-3">
                        <mat-form-field class="mt-2">
                            <mat-select [placeholder]="'kuniveaulev' | translate" color="primary" disableOptionCentering panelClass="dropdown-panel" formControlName="search_niveau">                                    
                                <mat-option>
                                    <ngx-mat-select-search
                                    [placeholderLabel]="'kuallsec' | translate"
                                    [noEntriesFoundLabel]="'kksecuritetrouve' | translate"                                
                                    (ngModelChange)="onSecurityChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterNiveau"></ngx-mat-select-search>
                                </mat-option>  
                                <mat-option value="tous">{{'kuallsec' | translate}}</mat-option>
                                <mat-option value="nondefini">{{'kuundefine' | translate}}</mat-option>                                    
                                <mat-option *ngFor="let item of filteredNiveauTypes" [value]="item.NIVINT">{{ item.NIVNOM }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 order-4">
                        <div class="d-flex h-100 align-items-center">
                            <mat-checkbox formControlName="search_actif">{{'kuuserdeact' | translate}}</mat-checkbox>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    </div>

    <div class="bloc_container">
        <div class="row bg_white">
            <div class="col-12 text-center mt-2 mt-md-0">
                <button class="bt_valid" mat-button type="submit">
                    <mat-icon>search</mat-icon>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kpsearch" | translate }}
                    </span>                    
                </button>
                <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                    <i class="material-icons">close</i>
                    <span class="d-none d-sm-none d-md-block">
                        {{ "kucancel" | translate }}
                    </span>                        
                </button>
            </div>
        </div>
    </div>
</form>