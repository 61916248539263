
export class User {
    CMINT: string = '';
    CMRAISOC?: string = '';
    CIINT: string = '';
    CTUINT: string = '';
    CINOM: string = '';
    CIPRENOM: string = '';
    CIPSW: string = '';
    CIACTIF: string = '';
    CITEL: string = '';
    CIMAIL: string = '';
    CIUSER: string = '';
    IDSOC: string = '';
    IDINSTINT: string = '';
    CTUINTFID: string = '';
    CTUINTGC: string = '';
    CGINT: string = '';
    CIVISU: string = '';
    CILANG: string = '';
    CITHEMCOLLAB: string = '';
    CITHEMADMIN: string = '';
    CIDTPSW: string = '';
    CIDTLASTACT: string = '';
    CIEXTERNE: string = '';

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
