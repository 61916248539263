<div class="page-container">   
    <div class="title row">
        <div class="col-1 col-sm-2 col-md-2 col-lg-3">
            <mat-icon class="pointer" (click)="returnBack()" [title]="'kuback' | translate">
                keyboard_arrow_left
            </mat-icon>
        </div>
        <div class="col-10 col-sm-8 col-md-8 col-lg-6 text-center">
            <h1 class="text-center">
                {{'kuadduser' | translate}}
            </h1>
        </div>
        <div class="col-1 col-sm-2 col-md-2 col-lg-3 no-padding d-block d-sm-block d-md-none">
            <mat-icon>person</mat-icon>
        </div> 
    </div>

    <form [formGroup]="addUserForm" (ngSubmit)="addUserFormSubmitter()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="row no-gutters">
                        <div class="col-12 col-sm-12 col-lg-8">
                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3 tiers">
                                    <mat-form-field >
                                        <input matInput [placeholder]="'kuuserno' | translate" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-3">
                                    <div class="d-flex h-100 align-items-center">
                                        <mat-checkbox formControlName="CIACTIF">{{'kuactiveuser' | translate}}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                                    <label for="USERLANG">{{ 'klang' | translate }}</label>
                                    <ng-select formControlName="USERLANG" bindLabel="name" appearance="outline" [searchable]="false" [clearable]="false" labelForId="USERLANG">
                                        <ng-option [value]="item" *ngFor="let item of listLang">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <span class="cap__flag mr-3" [class]="'cap__'+item"></span>
                                                <span>{{ item | uppercase }}</span>
                                            </div>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field>
                                        <input matInput formControlName="CIUSER" [placeholder]="('kuusercode' | translate) + '*'" class="text-uppercase" />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4 nom">
                                    <mat-form-field>
                                        <input matInput formControlName="CINOM" class="text-uppercase" [placeholder]="('kuname' | translate) + '*'" />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4 nom">
                                    <mat-form-field>
                                        <input matInput formControlName="CIPRENOM" class="text-uppercase" [placeholder]="'kuprenom' | translate" />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field>
                                        <input type="password" matInput formControlName="CIPSW" [placeholder]="('kupassword' | translate) + '*'" autocomplete="new-password" />
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field class="select_civilite">
                                        <mat-select formControlName="CTUINTGC" disableOptionCentering panelClass="dropdown-panel" [placeholder]="'kuniveaulev' | translate">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                [placeholderLabel]="'kuallsec' | translate"
                                                [noEntriesFoundLabel]="'kksecuritetrouve' | translate"                                
                                                (ngModelChange)="onSecurityChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterNiveau"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let item of filteredNiveauTypes" [value]="item.NIVINT">{{ item.NIVNOM }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-9 col-lg-4">
                                    <mat-form-field>
                                        <input matInput formControlName="CIEXTERNE" [placeholder]="'kucodext' | translate" />
                                    </mat-form-field>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">                
                <div class="col-6 text-right">
                    <button type="submit" class="bt_valid" mat-button  [title]="'kuvalid' | translate">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kuvalid' | translate}}
                        </span>                        
                    </button>
                </div>
                <div class="col-6 text-left">
                    <button class="bt_valid" mat-button routerLink="/utilisateurs" [title]="'kuback' | translate">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{'kuback' | translate}}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>