import {Component, OnInit, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-article-description-photo',
  templateUrl: './article-description-photo.component.html'
})
export class ArticleDescriptionPhotoComponent implements OnInit {

    imageUrl: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
      this.imageUrl = this.data.image;      
    }

    ngOnInit() {
    }

}
