<h1 mat-dialog-title class="text-center">{{ "ksmodify" | translate }}</h1>
<div mat-dialog-content>
    <form [formGroup]="editSecuriteLevelForm" (ngSubmit)="editFormSubmission()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field>
                        <input matInput formControlName="TYPE" [placeholder]="'ksleveltype' | translate" [disabled]="isDisabled" />
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field>
                        <input matInput formControlName="NIVNOM" maxlength="120" [placeholder]="'kslevel' | translate" />
                    </mat-form-field>                    
                </div>
            </div>
        </div>
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button [disabled]="editSecuriteLevelForm.invalid" type="submit">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "ksvalid" | translate }}
                        </span>                        
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "ksback" | translate }}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>