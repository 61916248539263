<h1 mat-dialog-title class="text-center">{{ 'kkautoreq' | translate }}</h1>
<div mat-dialog-content>
    <form [formGroup]="authCheckForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="authorizationFormSubmitter()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field>
                        <span matPrefix>
                              <mat-icon>
                                <i class="fa fa-user" aria-hidden="true"></i>
                            </mat-icon>
                        </span>
                        <input matInput [placeholder]="'kusername' | translate" (keydown.enter)="onUsernameEnterPressed($event)" formControlName="USERNAME" >

                        <button matSuffix type="button" *ngIf="isMobile" (click)="openScanArticle($event)" tabindex="-1" mat-icon-button>
                            <i class="fa fa-barcode" aria-hidden="true"></i>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field>
                        <span matPrefix>
                             <mat-icon>
                                 <i class="fa fa-key" aria-hidden="true"></i>
                           </mat-icon>
                       </span>
                       <input matInput [placeholder]="'kpassword' | translate" formControlName="PASSWORD" type="password" minlength="3" >
                   </mat-form-field>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button [disabled]="authCheckForm.invalid" type="submit">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kuok" | translate }}
                        </span>                        
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kucancel" | translate }}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>        
    </form>
</div>