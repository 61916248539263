import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import BonPanier from 'models/bonPanier';
import { BonVente } from 'models/bonventes';
import { User } from 'models/users';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { StorageService } from 'providers/api/storageService';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';

@Component({
  selector: 'app-numero-court',
  templateUrl: './numero-court.component.html',
  styleUrls: ['./numero-court.component.scss'],
  providers: [BonVenteService, DialogsService]
})
export class NumeroCourtComponent implements OnInit {

  user: User;
  USERCMINT: any;

  numeroForm: UntypedFormGroup;

  bonNumBon: string;
  previousPanier: BonPanier;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<NumeroCourtComponent>,
              private formBuilder: UntypedFormBuilder,
              private bonVenteService: BonVenteService,
              private loadingService: NgxSpinnerService,
              private userService: UserService,
              private dialogService: DialogsService,
              private translateService: TranslateService,
              private storageService: StorageService) { }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
    this.bonNumBon = this.data.bonNumBon;
    this.previousPanier = this.data.panier;

    this.numeroForm = this.formBuilder.group({
      NUMERO_COURT: ['', [Validators.required, Validators.pattern('^[0-9]+'), Validators.minLength(4), Validators.maxLength(4)]]
    })

    if(this.previousPanier !== undefined && this.previousPanier !== null) {
      let numCourt = this.previousPanier.numcourt;
      if(numCourt === '' || numCourt === null) {
        numCourt = this.bonNumBon.slice(this.bonNumBon.length - 4);
        this.numeroForm.get('NUMERO_COURT').markAsDirty();
      }

      this.numeroForm.get('NUMERO_COURT').setValue(numCourt);
    }
  }

  numeroFormSubmitter() {
    if(this.numeroForm.invalid) {
      return;
    }
    
    const form_data = this.numeroForm.value;
    if(!this.numeroForm.dirty || form_data.NUMERO_COURT == this.previousPanier.numcourt) {
      this.dialogRef.close({ status: 'success' });
      return;
    }

    this.dialogRef.close({ status: 'success', data: form_data });
  }

  modifyEtCheckBonDeVente(bondeVenteData: any) {
    return new Promise((resolve, reject) => {
      this.bonVenteService.modifyEtCheckBonvente(bondeVenteData).subscribe(
        (resp) => {
          this.loadingService.hide();
  
          if(resp.statusCode == 200) {
            if(resp.data.ErrorCode == 0) {
              // List update after a success response
              const oldListData = this.storageService.getItem('bonVenteListData');
              const oldNumBon = this.bonNumBon;
              // ------------------------------

              this.previousPanier = resp.data.Panier;
              this.bonNumBon = resp.data.NumBon;

              // List update after a success response
              if(oldListData !== undefined && oldListData !== null) {
                oldListData.data.map(
                  (row: BonVente) => {
                    if(row.NUMCOM == oldNumBon) {
                      row.TRAISOC = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.TRAISOC:null;
                      row.TPRENOM = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.TPRENOM:null;
                      row.TADR3 = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.TADR3:null;
                      row.CODPOS = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.CODPOS:null;
                      row.TVILLE = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.TVILLE:null;
                      row.TTEL = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.TTEL:null;
                      row.TPORT = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.TPORT:null;
                      row.TPORT = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?resp.data.customClient.TPORT:null;
                      row.ENDMOD = moment(this.previousPanier.dtmaj).utcOffset(this.previousPanier.dtmaj).format('YYYY-MM-DD HH:mm:ss');
                      
                      row.MTHT = this.previousPanier.montantHT;                    
                      row.MTTTC = this.previousPanier.montant;
                      row.MTTVA = (this.previousPanier.montant - this.previousPanier.montantHT);
                      
                      row.CLINUM = (this.previousPanier.client !== undefined && this.previousPanier.client !== null)?this.previousPanier.client.idcli.toString():"0";
                      row.CLILIVNUM = (this.previousPanier.clientlivre !== undefined && this.previousPanier.clientlivre !== null)?this.previousPanier.clientlivre.idcli.toString():"0";
                      row.CLIFACNUM = (this.previousPanier.clientfacture !== undefined && this.previousPanier.clientfacture !== null)?this.previousPanier.clientfacture.idcli.toString():"0";                    

                      row.NUMCOM = this.bonNumBon;

                      return row;
                    }

                    return row;
                  }
                );

                const updated_bonvente = oldListData.data.find(
                  (row: BonVente) => row.NUMCOM == this.bonNumBon
                );
  
                let updated_row_removed: BonVente[] = oldListData.data.filter((row: BonVente) => row.NUMCOM != this.bonNumBon);
                updated_row_removed.unshift(updated_bonvente);
  
                oldListData.data = updated_row_removed;

                this.storageService.setItem('bonVenteListData', oldListData);
              }
              // ------------------------------

              this.dialogRef.close({ status: 'success' });
            } else {
              this.dialogService.prompt(this.translateService.instant('kfailure'), resp.data.Error);
            }            
            resolve(resp);
          } else {          
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuadderr'));
            reject(resp);
          }
        },
        err => {
          reject(err);
          this.loadingService.hide();
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
      );
    });     
  }

}
