import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location, formatDate} from '@angular/common';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from 'providers/constants';
import { UtilisatuersService } from 'providers/api/utilisatuersService';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NiveauModel } from 'models/niveau-model';
import { UserModel, UserComplementModel } from 'models/user-model';
import { UserService } from 'providers/api/userService';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { MagasinStructModel } from 'models/magasinStructModel';

import {
  TreeviewItem,
  TreeviewEventParser, OrderDownlineTreeviewEventParser
} from 'ngx-treeview2';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MerchandiseDeVenteComponent } from '../merchandise-de-vente/merchandise-de-vente.component';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'providers/api/storageService';

interface CollapsedItems {
  collapsed: boolean;
  children?: CollapsedItems[] | undefined | null;
  value?: string;
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [
    UtilisatuersService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }    
  ]
})
export class EditUserComponent implements OnInit {

  treeValues: string[];
  treeItems: TreeviewItem[];  
  isMobile: boolean = false;
  user: UserModel;
  USERCMINT: any;
  FIRSTNAME: any;
  LASTNAME: any;
  editUserForm: UntypedFormGroup;
  niveauTypes: NiveauModel[];
  filteredNiveauTypes: NiveauModel[];
  filterNiveau: string = "";

  updatedRows: number;

  routeParamSubscription: any;
  editUserID: number;
  editUserData: UserModel;
  editUserComplementdata: UserComplementModel;

  magasinStructure: MagasinStructModel[];
  previousTreeValue: string = "";

  previousTreeCollapsedValues: CollapsedItems;  

  userPermission: any;
  readOnly: boolean = false;

  listLang: any[];

  constructor(private router: Router,              
              public snackBar: MatSnackBar,
              private location: Location,
              private formBuilder: UntypedFormBuilder,
              private deviceService: DeviceDetectorService,
              private userService: UserService,
              private utilisatuersService: UtilisatuersService,
              private loadingService: NgxSpinnerService,
              private dialogService: DialogsService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private translateService: TranslateService,
              private storageService: StorageService) {
      this.editUserForm = this.formBuilder.group({
        CIINT: ['', Validators.required],
        CIINST: ['', Validators.required],
        CMINT: ['', Validators.required],
        CIACTIF: [false], // -1 (true) => Active and 0 (false) => In-Active
        IDSOC: [Constants.IDSOC, Validators.required],
        CIUSER: ['', Validators.required],
        CINOM: ['', Validators.required],
        CIPRENOM: [''],
        CIPSW: ['', [Validators.required, Validators.minLength(3)]],
        CTUINTGC: [''],
        CIEXTERNE: [''],

        OKREINIT: [''],
        OKEXPIRE: [''],
        STRUCTU: [''],
        USERLANG: ['']
      });      
 }

  ngOnInit() {    
    this.userService.headerSearchButton.next(false);
    this.isMobile = this.deviceService.isMobile();
    this.routeParamSubscription = this.route.params.subscribe(
      params => {
        this.editUserID = +params['user_id'];  
        this.previousTreeCollapsedValues = sessionStorage.getItem('EDIT_MARCHANDISE_'+this.editUserID)?JSON.parse(sessionStorage.getItem('EDIT_MARCHANDISE_'+this.editUserID)):undefined;        
      }
    )       

    this.user = this.userService.getCurrentUser();
    this.USERCMINT = this.user.CMINT;
    this.editUserForm.patchValue({ CMINT: this.USERCMINT });    
    this.userPermission = this.storageService.getItem('CurrentUserPermission');
    this.getUserData();    
    this._getListLanguages();
  }

  ngOnDestroy() {
    this.routeParamSubscription.unsubscribe();
  }
  onSecurityChange(search: string) { 
    this.filteredNiveauTypes = this.niveauTypes.filter(row => row.NIVNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  async _getListLanguages() {
    try {
      const paramData = await this.userService.getRuleParamsData(10, 1, 'VCHXMUL1LIS');
      if(paramData != null) {
        this.listLang = [];
  
        if(paramData == null || paramData == '') {
          let langShortCode = await this.userService.getRuleParamsData(10, 1, 'VCHXMUL1');
          if(this.editUserData.CILANG !== undefined && this.editUserData.CILANG != null && this.editUserData.CILANG !== '') {
              let trimmedLangShort = this.editUserData.CILANG.substr(0, 2);
              langShortCode = trimmedLangShort;
          }
  
          this.listLang = [langShortCode];
        } else {
          const separated = paramData.split(' ');
          this.listLang = separated;
        }
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    }
  }

  async getListNiveau(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.utilisatuersService.getListofNiveau(this.USERCMINT, this.user.CIINT).subscribe(
        (res: any) => {
          if(res.success !== undefined) {                    
            this.niveauTypes = res.data;
            if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['73_1']) {
              this.niveauTypes = this.niveauTypes.filter((_d) => !(_d.NIVINT >= 1 && _d.NIVINT <= 9)); // Restrict Super Administrator level
            } 
            this.filteredNiveauTypes = this.niveauTypes;        
            resolve();
          }
        },
        error => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });    
  }

  async getListMagasinStructure(): Promise<any> {
    return new Promise((resolve, reject) => {
      let httpData = {
        IDMAG: this.USERCMINT,
        IDSOC: Constants.IDSOC,
        CIINT: this.user.CIINT
      };

      this.utilisatuersService.getMagasinStructure(httpData).subscribe(
        (res: any) => {
          if(res.success !== undefined) {
            this.magasinStructure = res.data;
            const previousTreeValue = this.splitUpStructString(this.previousTreeValue);

            this.treeItems = [
              new TreeviewItem({
                text: this.translateService.instant('kartmar'),
                value: '',
                checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                children: this.__structurizeMarchandiseData(this.magasinStructure, previousTreeValue),
                collapsed: this.previousTreeCollapsedValues?this.previousTreeCollapsedValues.collapsed:false,
                disabled: this.readOnly
              })
            ];

            this.changeMagasinStructTreeOrder({ value: 'CODE' });            
            
            resolve(res);            
          }
        },
        err => {
          this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
          reject()
        }
      );
    });    
  }

  __isolateStructureData = (magasinStructure: MagasinStructModel[], level: 1 | 2 | 3 | 4 | 5 = 1) => {
    const filteredStruct = magasinStructure.filter(
      (res: MagasinStructModel) => {
        if(level === 1) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 == '0');
        }

        if(level === 2) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 == '0');
        }

        if(level === 3) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 == '0');
        }

        if(level === 4) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 != '0' && res.CSTNIV5 == '0');
        }

        if(level === 5) {
          return (res.CSTNIV1 !== null && res.CSTNIV1 !== '' && res.CSTNIV1 != '0' && res.CSTNIV2 != '0' && res.CSTNIV3 != '0' && res.CSTNIV4 != '0' && res.CSTNIV5 != '0');
        }
      }
    );

    return filteredStruct;
  }

  __structurizeMarchandiseData = (magasinStructure: MagasinStructModel[], previousTreeValue: Array<{ level_1: string, level_2?: string, level_3?: string, level_4?: string, level_5?: string }> | "") => {

    const level_1 = this.__isolateStructureData(magasinStructure, 1);
    const level_2 = this.__isolateStructureData(magasinStructure, 2);
    const level_3 = this.__isolateStructureData(magasinStructure, 3);
    const level_4 = this.__isolateStructureData(magasinStructure, 4);
    const level_5 = this.__isolateStructureData(magasinStructure, 5);

    const parentTreeItems: TreeviewItem[] = [];

    level_1.forEach(
      (row_1: MagasinStructModel) => {

        const level_1_prev_filter = (previousTreeValue !== "" && previousTreeValue.length > 0)?previousTreeValue.filter(tree_row => tree_row.level_1 === row_1.CSTNIV1):null;
        const level_1_collapsed = this.previousTreeCollapsedValues?this.previousTreeCollapsedValues.children.find(el => el.value === row_1.CSTNIV1):null;

        const level_2_filtered = level_2.filter((_r) => _r.CSTNIV1 == row_1.CSTNIV1);
        const level_1_childrens: any[] = [];

        if(level_2_filtered) {
          level_2_filtered.forEach(
            (row_2: MagasinStructModel) => {

              const level_2_prev_filter = level_1_prev_filter !== null?level_1_prev_filter.filter(tree_row => tree_row.level_2 === row_2.CSTNIV2):null;
              const level_2_collapsed = this.previousTreeCollapsedValues?level_1_collapsed.children.find(el => el.value === row_2.CSTNIV2):undefined;                                            

              const level_3_filtered = level_3.filter((_r) => _r.CSTNIV2 == row_2.CSTNIV2);
              const level_2_childrens: any[] = [];

              if(level_3_filtered) {
                level_3_filtered.forEach(
                  (row_3: MagasinStructModel) => {
                    
                    let level_3_prev_filter: any = (level_2_prev_filter && level_2_prev_filter !== null)?level_2_prev_filter.filter(tree_row => tree_row.level_3 === row_3.CSTNIV3):null;
                    if(level_4 === undefined || level_4.length == 0) {
                      level_3_prev_filter = (level_2_prev_filter && level_2_prev_filter !== null)?level_2_prev_filter.find(tree_row => tree_row.level_3 === row_3.CSTNIV3):null;
                    }
                    
                    const level_3_collapsed = this.previousTreeCollapsedValues?level_2_collapsed.children.find(el => el.value === row_3.CSTNIV3):undefined;

                    const level_4_filtered = level_4.filter((_r) => _r.CSTNIV3 == row_3.CSTNIV3);
                    const level_3_childrens: any[] = [];

                    if(level_4_filtered) {
                      level_4_filtered.forEach(
                        (row_4: MagasinStructModel) => {
                          let level_4_prev_filter: any = (level_3_prev_filter && level_3_prev_filter !== null)?level_3_prev_filter.filter(tree_row => tree_row.level_4 === row_4.CSTNIV4):null;
                          if(level_5 === undefined || level_5.length == 0) {
                            level_4_prev_filter = (level_3_prev_filter && level_3_prev_filter !== null)?level_3_prev_filter.find(tree_row => tree_row.level_4 === row_4.CSTNIV4):null;
                          }

                          const level_4_collapsed = this.previousTreeCollapsedValues?level_3_collapsed.children.find(el => el.value === row_4.CSTNIV3):undefined;

                          const level_5_filtered = level_5.filter((_r) => _r.CSTNIV4 == row_4.CSTNIV4);
                          const level_4_childrens: any[] = [];

                          if(level_5_filtered) {
                            level_5_filtered.forEach(
                              (row_5: MagasinStructModel) => {

                                const level_5_prev_filter = (level_4_prev_filter && level_4_prev_filter !== null)?level_4_prev_filter.find(tree_row => tree_row.level_5 === row_5.CSTNIV5):null;                            

                                level_4_childrens.push({
                                  text: row_5.CSTNIV5+' - '+row_5.CSTLIB1,
                                  value: row_5.CSTNIV5,
                                  checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:((level_5_prev_filter && level_5_prev_filter !== null)?(level_5_prev_filter.level_5?true:false):false),
                                  disabled: this.readOnly
                                });
                              }
                            )
                          }

                          level_3_childrens.push({
                            text: row_4.CSTNIV4+' - '+row_4.CSTLIB1,
                            value: row_4.CSTNIV4,
                            checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:(((level_5 === undefined || level_5.length == 0) && level_4_prev_filter && level_4_prev_filter !== null)?(level_4_prev_filter.level_4?true:false):false),
                            // checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:((level_3_prev_filter && level_3_prev_filter !== null)?(level_3_prev_filter.sub_child_id?true:false):false),
                            collapsed: level_4_collapsed !== null && level_4_collapsed !== undefined?level_4_collapsed.collapsed:true,
                            children: level_4_childrens,
                            disabled: this.readOnly                              
                          });
                        }
                      )
                    }

                    level_2_childrens.push({
                      text: row_3.CSTNIV3+' - '+row_3.CSTLIB1,
                      value: row_3.CSTNIV3,
                      checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:(((level_4 === undefined || level_4.length == 0) && level_3_prev_filter && level_3_prev_filter !== null)?(level_3_prev_filter.level_3?true:false):false),
                      // checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:((level_3_prev_filter && level_3_prev_filter !== null)?(level_3_prev_filter.sub_child_id?true:false):false),
                      collapsed: level_3_collapsed !== null && level_3_collapsed !== undefined?level_3_collapsed.collapsed:true,
                      children: level_3_childrens,
                      disabled: this.readOnly                              
                    });
                  }
                )
              }                      

              level_1_childrens.push({
                text: row_2.CSTNIV2+' - '+row_2.CSTLIB1,
                value: row_2.CSTNIV2,
                checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
                collapsed: level_2_collapsed !== null && level_2_collapsed !== undefined?level_2_collapsed.collapsed:true,
                children: level_2_childrens,
                disabled: this.readOnly
              });
            }
          )
        }                

        parentTreeItems.push(new TreeviewItem({
          text: row_1.CSTNIV1+' - '+row_1.CSTLIB1,
          value: row_1.CSTNIV1,
          checked: (previousTreeValue === "" || previousTreeValue.length === 0)?true:undefined,
          collapsed: level_1_collapsed !== null?level_1_collapsed.collapsed:true,
          children: level_1_childrens,
          disabled: this.readOnly
        }));
        
      }
    )

    return parentTreeItems;
  }

  sortByString = (a: string, b: string) => { 
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
  }

  sortByNumber = (a: number, b: number) => {
    return a - b; 
  }

  structureSorting(event: any, a: TreeviewItem, b: TreeviewItem) {
    if(a.children) {
      a.children.sort(
        (a: TreeviewItem, b: TreeviewItem) => {            
          return this.structureSorting(event, a, b);
        }
      );
    }

    if(b.children) {
      b.children.sort(
        (a: TreeviewItem, b: TreeviewItem) => {
          return this.structureSorting(event, a, b);               
        }
      );
    }      

    const a_text = a.text.split(' - ')[1];
    const a_value = a.value;

    const b_text = b.text.split(' - ')[1];
    const b_value = b.value;

    if(event.value === 'CODE') { // Sorting by CODE
      return this.sortByNumber(a_value, b_value);
    } else if (event.value === 'LABEL') { // Sorting by Label
      return this.sortByString(a_text, b_text);
    } 
  }

  changeMagasinStructTreeOrder(event: any) {
    if(this.treeItems[0] === undefined || this.treeItems[0] == null || this.treeItems[0].children == null || this.treeItems[0].children == undefined || this.treeItems[0].children.length == 0) return;

    this.treeItems[0].children.sort(
      (a: TreeviewItem, b: TreeviewItem) => {        
        return this.structureSorting(event, a, b);
      }
    );
  }

  splitUpStructString(previous_string: string):Array<{ level_1: string, level_2?: string, level_3?: string, level_4?: string, level_5?: string }> | "" {    
    const splittedData: any[] = [];

    if(previous_string) {    
      const tree_values_split_up = previous_string.split('#');      
      tree_values_split_up.forEach(
        (row) => {
          const [level_1, level_2, level_3, level_4, level_5] = row.split(';');

          let attachment: any = { level_1 };

          if(level_2 !== undefined) {
            attachment = { ...attachment, level_2 };
          }
          
          if(level_3 !== undefined) {
            attachment = { ...attachment, level_3 };
          }

          if(level_4 !== undefined) {
            attachment = { ...attachment, level_4 };
          }

          if(level_5 !== undefined) {
            attachment = { ...attachment, level_5 };
          }
          
          splittedData.push(attachment);                
        }
      );
    }
    
    return splittedData || "";
  }

  async getUserData() {
    this.loadingService.show();

    await this.getListNiveau();    

    this.utilisatuersService.getUserByID(this.editUserID).subscribe(
      async (res: any) => {        
        if(res.success !== undefined) {
          this.editUserData = res.data[0];
          this.editUserComplementdata = res.datacomplement[0];
          this.previousTreeValue = this.editUserComplementdata?this.editUserComplementdata.STRUCTU:'';
          
          if((this.userPermission != undefined && this.userPermission != null) && !this.userPermission['73_2']) {
            if(this.editUserData.CTUINTGC != null && (+this.editUserData.CTUINTGC >= 1) && (+this.editUserData.CTUINTGC <= 9)) {
              this.readOnly = true;
      
              this.editUserForm.disable({ onlySelf: true }); 
            }
          }

          await this.getListMagasinStructure();

          let langShortCode = await this.userService.getRuleParamsData(10, 1, 'VCHXMUL1');
          if(this.editUserData.CILANG !== undefined && this.editUserData.CILANG != null && this.editUserData.CILANG !== '') {
              let trimmedLangShort = this.editUserData.CILANG.substr(0, 2);
              langShortCode = trimmedLangShort;
          }

          this.editUserForm.patchValue({
            CIINT: this.editUserID,
            CIINST: this.editUserData.IDINSTINT,
            CMINT: this.USERCMINT,
            CIACTIF: this.editUserData.CIACTIF === '-1'?true:false,
            IDSOC: this.editUserData.IDSOC,
            CIUSER: this.editUserData.CIUSER,
            CINOM: this.editUserData.CINOM,
            CIPRENOM: this.editUserData.CIPRENOM,
            CIPSW: this.editUserData.CIPSW,
            CTUINTGC: this.editUserData.CTUINTGC,
            CIEXTERNE: this.editUserData.CIEXTERNE,

            OKREINIT: this.editUserComplementdata?(this.editUserComplementdata.OKREINIT=='-1'?true:false):true,
            OKEXPIRE: this.editUserComplementdata?(this.editUserComplementdata.OKEXPIRE=='-1'?true:false):false,
            STRUCTU: this.editUserComplementdata?this.editUserComplementdata.STRUCTU:'',
            USERLANG: langShortCode
          });

          if(this.editUserComplementdata !== undefined && this.editUserComplementdata != null) {
            if(this.editUserComplementdata.STRUCTU == undefined || this.editUserComplementdata.STRUCTU == '' || this.editUserComplementdata.STRUCTU == null) {
              this.treeValues = [''];
            } else {
              this.treeValues = this.editUserComplementdata.STRUCTU.split('#');
            }
          }

          this.FIRSTNAME = this.editUserData.CINOM;          
          this.LASTNAME = this.editUserData.CIPRENOM;
          this.loadingService.hide();          
        } else {
          this.loadingService.hide();
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );
  }

  editUserFormSubmitter() {
    this.loadingService.show();    

    if(this.editUserForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kumand'));
      return;
    }

    const form_data = this.editUserForm.getRawValue();
    form_data.CIPSW = form_data.CIPSW.toUpperCase();

    if((form_data.STRUCTU === undefined || form_data.STRUCTU == null || form_data.STRUCTU === "") && (this.treeValues === undefined || this.treeValues == null || this.treeValues.length === 0)) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kunocommstructsel'));
      return;
    }

    let selectedNiveau = null;
    if(form_data.CTUINTGC !== undefined && form_data.CTUINTGC !== '' && form_data.CTUINTGC !== null) {
      selectedNiveau = this.niveauTypes.find(el => el.NIVINT == form_data.CTUINTGC);
    }

    const storageFormData = { ...form_data, NIVNOM: (selectedNiveau && selectedNiveau !== null)?selectedNiveau.NIVNOM:null }
    this.storageService.setItem('utilisateurEditFormData', storageFormData);

    form_data.CIACTIF = form_data.CIACTIF?'-1':'0';

    form_data.OKREINIT=form_data.OKREINIT=== true?'-1':'0';
    form_data.OKEXPIRE=form_data.OKEXPIRE=== true?'-1':'0';
    form_data.CILANG=`${form_data.USERLANG}_${form_data.USERLANG.toUpperCase()}.php`;
    delete form_data.USERLANG;
    
    this.utilisatuersService.updateUser(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kuupdate'));
          
          this.editUserForm.reset();
          this.niveauTypes = [];          

          this.router.navigate(['utilisateurs']);    
        } else {          
          if(resp.status && resp.status === 'USEREXISTS') {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuseralreadyexistmag'));
          } else {
            this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kuupdateerr'));
          }
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
      }
    );      
  } 
  
  returnBack() {
    this.location.back();
  }

  openUpTreeView(): void {
    const dialogRef = this.dialog.open(MerchandiseDeVenteComponent, {
      maxWidth: "85vw",
      data: {
        previousValue: this.previousTreeValue,
        treeItems: this.treeItems,
        userID: this.editUserID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined && result !== null && result.status === 'updated') {
        this.treeValues = result.value;
        this.editUserForm.patchValue({
          STRUCTU: this.treeValues?this.treeValues.join('#'):''
        });

        this.editUserForm.get('STRUCTU').markAsTouched();
        this.editUserForm.get('STRUCTU').markAsDirty();  
        
        this.previousTreeCollapsedValues = sessionStorage.getItem('EDIT_MARCHANDISE_'+this.editUserID)?JSON.parse(sessionStorage.getItem('EDIT_MARCHANDISE_'+this.editUserID)):undefined;        
      }
    });   
  }

  onMerchandiseUpdate(event: { treeValues: string[], treeItems: TreeviewItem[] }) {
    this.treeValues = event.treeValues;
    this.editUserForm.patchValue({
      STRUCTU: (this.treeValues && this.treeValues.length > 0 && (event.treeItems[0].checked === false || event.treeItems[0].checked === undefined))?this.treeValues.join('#'):''
    });

    this.editUserForm.get('STRUCTU').markAsTouched();
    this.editUserForm.get('STRUCTU').markAsDirty();  
    
    this.previousTreeCollapsedValues = sessionStorage.getItem('EDIT_MARCHANDISE_'+this.editUserID)?JSON.parse(sessionStorage.getItem('EDIT_MARCHANDISE_'+this.editUserID)):undefined;
  }
}
