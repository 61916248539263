import {Component, OnInit, Input, ViewChild, Inject, Optional, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import {Article, Entreprots} from '../../models/articles';
import {ArticleService} from '../../providers/api/articleService';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from 'providers/api/userService';
import { BonVenteService } from 'providers/api/bonventeService';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogsService } from 'providers/api/dialogService';
import { TranslateService } from '@ngx-translate/core';
import { MatAccordion } from '@angular/material/expansion';
import { Constants } from 'providers/constants';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {CUSTOM_DATE_FORMATS} from '../../providers/custom-date-formats';
import { StorageService } from 'providers/api/storageService';

@Component({
    selector: 'app-article-detail',
    templateUrl: './article-detail.component.html',
    providers: [ArticleService,
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
            {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
            {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
            BonVenteService
        ]
})
export class ArticleDetailComponent implements OnInit {
    private _vente_item_article: Article;

    @Input() set vente_item_article(vente_item_article: Article) {
        if(vente_item_article !== undefined && vente_item_article !== null) {                   
            this.CDART = vente_item_article.CDART;
            this.article = vente_item_article;
            this.article_session = this.article;

            this._vente_item_article = vente_item_article;
            this.vente_item_articleChange.emit(vente_item_article);
        }        
    }
    get vente_item_article(): Article {
        return this._vente_item_article;
    }
    @Output() vente_item_articleChange = new EventEmitter<Article>();
    
    @Input() displayType: 'page' | 'tab' | 'dialog' = 'page';
    @Input() flowCMINT: any | undefined;

    article: Article;
    originMagArticle: Article;
    article_session: Article;
    CDART_SLICE: any;
    CDART: any;
    chosenTabGroup = 0;
    tabTrigger: Subject<any> = new Subject();

    CAINT: any;
    user: any;
    USERCMINT: any;
    currentStockMag: any;
    listEntreprots: Promise<Entreprots[]>;

    isMobile: boolean = false;

    @ViewChild(MatAccordion) accordion: MatAccordion;
    expandedAccordion: number = 7;    

    stockBlocageEditable: boolean = true;
    bonVenteFlow: boolean = false;

    userPermission: any;
    codeClient: any;

    deliveryOptions: any[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                public snackBar: MatSnackBar,
                private location: Location,
                private articleService: ArticleService,
                private bonService: BonVenteService,
                private deviceService: DeviceDetectorService,
                private userService: UserService,
                private loadingService: NgxSpinnerService,
                private dialogService: DialogsService,
                private translateService: TranslateService,
                private storageService: StorageService,
                @Optional() @Inject(MAT_DIALOG_DATA) public mobileVenteData: any,
                @Optional() private dialogRef?: MatDialogRef<ArticleDetailComponent>,) {
    }    

    ngOnInit() {
        this.userPermission = this.storageService.getItem('CurrentUserPermission');

        this.userService.headerSearchButton.next(false);
        this.userService.headerSearch.next(false);        

        this.isMobile = this.deviceService.isMobile();
        this.user = this.userService.getCurrentUser();          

        this.changedAccordionTo(7);

        this.loadDependencies();
    }

    async loadDependencies() {
        this.currentStockMag = undefined;
        this.USERCMINT = this.user.CMINT;
        if (this.flowCMINT) {
            this.currentStockMag = this.flowCMINT;
        }

        setTimeout(() => {
            if (this.mobileVenteData && this.mobileVenteData.displayType !== undefined) {
                this.displayType = this.mobileVenteData.displayType;
            }

            if(this.displayType === 'page') {
                this.route.params.subscribe(params => {
                    if (params["cdart"]) {
                        this.getArticleByCDART(params["cdart"]);
                    }
                });
            } else if (this.displayType === 'tab') {
                this.stockBlocageEditable = true;
                this.bonVenteFlow = true;
                this.getArticleByCDART(this.CDART);
            } else {
                this.CDART = this.mobileVenteData.articleData.CDART;
                this.article = this.mobileVenteData.articleData;

                if (this.mobileVenteData.flowCMINT) {
                    this.currentStockMag = this.mobileVenteData.flowCMINT;
                    this.flowCMINT = this.currentStockMag;
                }

                this.article_session = this.article;
                this.getArticleByCDART(this.CDART);
            }
        }, 300);

        this._getCodeClient();
    }

    async _getCodeClient() {
        try {
            this.codeClient = await this.userService.getRuleParamsData(10, 7, 'VSTR1');
        } catch(err) {
            this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
        }
    }

    ngOnDestroy() {
        this.userService.headerSearch.next(false);  
        this.userService.headerSearchButton.next(false);
        this.storageService.setItem('articleDetailUpdate', this.article);
    }
    
    returnBack() {
        if(this.displayType === 'dialog'){
            this.dialogRef.close();
            return;
        }
        this.location.back();
    }

    get articlePrix(): any {
        if (this.currentStockMag) {
            return (this.originMagArticle && (this.originMagArticle.calculated_price || 0)) || 0;
        }

        return (this.article && (this.article.calculated_price || 0)) || 0;
    }

    getCdartWithPlacehold(CDART: string): string {
        return (Constants.CONFORAMA_CDART_PLACEHOLDER).replace("{{CDART_PLACEHOLDER}}", CDART);
    }

    getImageURL(CDART: string): string {
        if(CDART === undefined || CDART === null || CDART === '') return undefined;

        if(this.codeClient && this.codeClient.toLowerCase() === 'conforama') {
            // To generate Conforama Image URL Algorithm
            let concatenated = "";
            const length = Constants.CONFORMA_IMAGE_SEGMENT_LENGTH;
            for(let i=0;i < length;i++) {
              if(i < (length - 1)) {
                if(concatenated !== '') {
                  concatenated += "/"  
                }
                if(CDART[i] !== undefined) {
                    concatenated += (CDART[i].padEnd(length - i, '0'));
                  } else {
                    concatenated += ('0'.padEnd(length - i, '0')); // Prefixing Zero if the CDART length is small as mentioned in CAP-1056
                  }
              }
            }
    
            const URL = Constants.CONFORAMA_IMAGE_URL + concatenated + '/' + this.getCdartWithPlacehold(CDART) + Constants.DEFAULT_IMAGE_EXTENSION;
            return URL;
        }
  
        return Constants.BASE_IMAGE_URL+CDART+Constants.DEFAULT_IMAGE_EXTENSION;
    }

    getArticleGroupsByCAINT(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.bonService.getArticleGroupsByCAINT(this.CAINT, this.flowCMINT || this.USERCMINT, this.user.CIINT, this.USERCMINT).subscribe(
              (res: any) => {
                if(res.success == 'ok') {
                    if (res?.data.length > 0) {
                        this.deliveryOptions = res.data.filter(item => item.TYPECDE == "BL");
                    }
                }
                resolve(res);
              },
              (err) => {
                this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
                reject(err);
              }
            );
        });
    }

    getArticleByCDART(CDART: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (CDART === undefined || CDART == null) {
                reject('INVALID_CDART');
                return;
            }

            this.loadingService.show();

            this.bonService.getArticleByCDART(CDART, this.flowCMINT || this.USERCMINT, this.USERCMINT).subscribe(
                async (resp) => {
                    if(resp.success !== undefined) {
                        this.article = resp.data[0];
                        this.CAINT = this.article.CAINT;

                        if (resp?.groupExist) {
                            await this.getArticleGroupsByCAINT();
                        }

                        if (this.currentStockMag) {
                            await this.getOriginStockArticleByCDART(this.CDART);
                        }

                        this.listEntreprots = this.getListEntreprot();
                    } else {
                        this.router.navigate(['/article']);

                        this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                    }
                    this.loadingService.hide();
                    resolve(resp);
                },
                err => {
                    this.loadingService.hide();
                    this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                    this.router.navigate(['/article']);
                    reject(err);
                }
            );
        });
    }

    getOriginStockArticleByCDART(CDART: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (CDART === undefined || CDART == null) {
                reject('INVALID_CDART');
                return;
            }

            this.loadingService.show();

            this.bonService.getArticleByCDART(CDART, this.USERCMINT, this.USERCMINT).subscribe(
                (resp) => {
                    this.loadingService.hide();

                    if (resp.success !== undefined) {
                        this.originMagArticle = resp.data[0];
                    }

                    resolve(resp);
                },
                err => {
                    this.loadingService.hide();
                    this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                    this.router.navigate(['/article']);
                    reject(err);
                }
            );
        });
    }

    onTabChanged(event: number) { 
        this.chosenTabGroup = event;
        this.tabTrigger.next(event);
    }

    scrollTo(id: string, accordionNo: number) {
        let el = document.getElementById(id);
        el.scrollIntoView({ behavior: 'smooth' });
        this.expandedAccordion = accordionNo;
        this.changedAccordionTo(accordionNo);
    }

    changedAccordionTo(value: number) {
        this.tabTrigger.next(value);
    }

    getListEntreprot(): Promise<Entreprots[]> {
        return new Promise<Entreprots[]>((resolve, reject) => {
            this.articleService.getListEntreprot(this.CAINT, this.flowCMINT || this.USERCMINT).subscribe(
                (res) => {
                    if(res.success !== undefined) {
                        resolve(res.data);        
                    }
                }, 
                err => {
                    this.dialogService.prompt(this.translateService.instant('kuerror'), this.translateService.instant('kuneterr'));
                    reject(err);
                }
            );
        });    
    }

}
