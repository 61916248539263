import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Entreprots } from 'models/articles';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-stock-entreprots',
  templateUrl: './stock-entreprots.component.html',
  styleUrls: ['./stock-entreprots.component.scss'],
  providers: [TranslateService]
})
export class StockEntreprotsComponent implements OnInit {

  dtOptions: any = {};
  user: any;
  USERCMINT: any;
  showLoader = true;
  dtTrigger: Subject<any> = new Subject();

  listEntreprots: Entreprots[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private translateService: TranslateService
    ) { }

  ngOnInit(): void {
    this.listEntreprots = this.data.listEntreprots;
    this.dtTrigger.next();
    this.showLoader = false;

    this.dtOptions = {
      pageLength: 5,            
      ordering: false,
      paging: false,
      searching: false,            
      info: false,           
      retrieve: true, 
      
      lengthMenu: [[5, 10, 20, 30, -1], [5, 10, 20, 30, this.translateService.instant('karttous')]],
      language: {
        processing: this.translateService.instant('kprocessing'),
        search: this.translateService.instant('ksearch'),
        loadingRecords: this.translateService.instant('kloading'),
        info: this.translateService.instant('kgenshowentry'),
        infoEmpty: this.translateService.instant('kgenshowingentry'),
        zeroRecords: this.translateService.instant('knodata'),
        emptyTable: this.translateService.instant('knodatadash'),
        paginate: {
            first: this.translateService.instant('kfirst'),
            previous: this.translateService.instant('kprevious'),
            next: this.translateService.instant('knext'),
            last: this.translateService.instant('klast'),
        }          
      },
    };
  }

  ngAfterViewInit(): void {      
    this.dtTrigger.next();
  }

  ngOnDestroy() {
      this.dtTrigger.unsubscribe();
  }

}
