import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit {

  title: string = "kbonscanarticle";
  value: string;
  isError = false;

  formats: BarcodeFormat[] = [BarcodeFormat.CODE_128, BarcodeFormat.EAN_13, BarcodeFormat.UPC_A, BarcodeFormat.UPC_E, BarcodeFormat.EAN_8];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BarcodeScannerComponent>
  ) {}

  ngOnInit(): void {
    if (this.data?.title) {
      this.title = this.data.title;
    }
  }

  onError(error: any) {    
    this.isError = true;
    console.error("Scan error: ", error);   
  }

  onScan(result: string) {
    this.value = result;
    this.dialogRef.close({ status: 'success', data: result });
  }
}
