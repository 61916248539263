<div class="page-container">
    <div class="title row" *ngIf="pageType !== 'popup'">
        <div class="col-12 text-center">
            <h1 class="text-center">
              {{'kclfindcust' | translate}}
            </h1>
        </div>       
        <div class="col-12 text-center" *ngIf="isMobile && filterValues">            
            <h2 class="text-left font-italic font-weight-light text-capitalize">{{ filterValuesFormat }}</h2>
        </div>
    </div>

    <div class="bloc_container no-border margin_bottom_10 container_add" *ngIf="!isMobile">
        <div class="row">
            <div class="col-12 no-padding text-left">
                <button mat-raised-button class="bt_add opacity_moins" (click)="resetForm()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    <div class="text-medium">{{'kclclearform' | translate}}</div>
                </button>
            </div>
        </div>
    </div>

    <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmitter()" *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">                
                <div class="col-12 col-md-12">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <mat-form-field>
                                <mat-select [placeholder]="'kclshop' | translate" formControlName="CMINT" >
                                    <mat-option>
                                        <ngx-mat-select-search
                                        [placeholderLabel]="'klselstore' | translate"
                                        [noEntriesFoundLabel]="'klnostore' | translate"                                
                                        (ngModelChange)="onSearchChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterMagasin"></ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option value="">{{ 'kcltouslesmag' | translate }}</mat-option>
                                    <mat-option value="non_defini">{{'kclnondefi' | translate}}</mat-option>
                                    <mat-option *ngFor="let magasin of filteredMagasins" [value]="magasin.CMINT">
                                        {{ magasin?.CMRAISOC }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="NOM"
                                    [placeholder]="'kclnom' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="TPRENOM"
                                    [placeholder]="'kclfirstnom' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <mat-form-field>
                                <input type="tel" matInput formControlName="TEL"
                                    [placeholder]="'kcltel' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>                                               
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="TEMAIL"
                                    [placeholder]="'kclemail' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>                                             
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <mat-form-field>
                                <mat-select [placeholder]="'kclpays' | translate" formControlName="PAYS" >  
                                    <mat-option>
                                        <ngx-mat-select-search
                                        [placeholderLabel]="'kcltouslespays' | translate"
                                        [noEntriesFoundLabel]="'kkaucunepaystrouve' | translate"                                
                                        (ngModelChange)="onCountryChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterPays"></ngx-mat-select-search>
                                    </mat-option>    
                                    <mat-option value="tous">{{'kcltouslespays' | translate}}</mat-option>
                                    <mat-option value="non_defini">{{'kclnondefi' | translate}}</mat-option>
                                    <mat-option *ngFor="let pay of filteredPays" [value]="pay.CODPAYS">
                                        {{ pay.PAYSNOM }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="CP"
                                    [placeholder]="'kclcp' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div> 
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <mat-form-field>
                                <input matInput formControlName="VILLE"
                                    [placeholder]="'kclcity' | translate"
                                    class="text-uppercase">
                            </mat-form-field>
                        </div>
                    </div> 
                    <div class="row">
                        <div [class]="dateAct === 'between'?'col-12 col-sm-6 col-md-3 col-lg-2':'col-12 col-sm-6 col-md-4 col-lg-4'">
                            <mat-form-field floatLabel="always" class="date">
                                <input matInput formControlName="BIRTHDATEMIN" [max]="today" (dateChange)="onDateChanged()" [matDatepicker]="cdeDate"
                                    [placeholder]="'kbondate' | translate"
                                    class="text-uppercase">
                                <mat-datepicker-toggle matSuffix [for]="cdeDate">
                                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #cdeDate [disabled]="false"></mat-datepicker>
                                <button mat-icon-button type="button" *ngIf="searchStartDate.value" (click)="_clearDate('BIRTHDATEMIN')" matSuffix ><mat-icon>close</mat-icon></button>
                                <button matSuffix type="button" [matMenuTriggerFor]="menu" mat-icon-button>
                                    <mat-icon class="d-flex justify-content-center align-items-center">
                                        <span style="line-height: initial;" *ngIf="dateAct === 'equal'">=</span>
                                        <span style="line-height: initial;" *ngIf="dateAct === 'greater_equal'">>=</span>
                                        <span style="line-height: initial;" *ngIf="dateAct === 'less_equal'"><=</span>
                                        <span style="line-height: initial;" *ngIf="dateAct === 'between'"><></span>
                                    </mat-icon>
                                </button>                                
                            </mat-form-field>
                            <mat-menu #menu="matMenu" [hasBackdrop]="false">
                                <button mat-menu-item (click)="changeDateAct('equal')">{{'kbondategale' | translate}}</button>
                                <button mat-menu-item (click)="changeDateAct('greater_equal')">{{'kbondatsuperior' | translate}}</button>
                                <button mat-menu-item (click)="changeDateAct('less_equal')">{{'kbondatinferior' | translate}}</button>
                                <button mat-menu-item (click)="changeDateAct('between')">{{'kbondatentre' | translate}}</button>
                            </mat-menu>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-2 pl-3" *ngIf="dateAct === 'between'">
                            <mat-form-field floatLabel="always">
                                <input matInput formControlName="BIRTHDATEMAX" [min]="minDateAct" [max]="today" [matDatepicker]="cdeEndDate"
                                    [placeholder]="'kbonand' | translate"
                                    class="text-uppercase">
                                    
                                <mat-datepicker-toggle matSuffix [for]="cdeEndDate">
                                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #cdeEndDate [disabled]="false"></mat-datepicker>
                                <button mat-icon-button type="button" *ngIf="searchEndDate.value" (click)="_clearDate('BIRTHDATEMAX')" matSuffix ><mat-icon>close</mat-icon></button>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center height_bloc" >                      
                    <div>
                        <button type="submit" class="bt_valid" mat-button>
                            <i class="material-icons">search</i>
                            <span class="d-none d-sm-none d-md-block">{{'kclsearc' | translate}}</span>
                        </button>
                    </div>    
                </div>
            </div>
        </div>
    </form>

    <div class="bloc_container">

        <div class="row">
            <div class="col-12 no-padding  table-scroller" [class.mb-4]="pageType === 'popup'">
                <div class="">
                    <mat-paginator *ngIf="listClient?.length != 0 && !showLoader" (page)="paginationChange($event)" [pageIndex]="currentPage" [length]="totalPages"
                                [pageSize]="rowsPerPage" [showFirstLastButtons]="true"
                                >
                    </mat-paginator>
                </div>

                <table *ngIf="!isMobile" #tableclient id="client_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover dataTable no-footer">
                   <thead>
                        <tr>
                            <th width="18%" class="nom">{{'kclsocial' | translate}}</th>                            
                            <th width="14%" class="cp">{{'kclcpville' | translate}}</th>
                            <th width="14%" class="tel">{{'kclport' | translate}}</th>
                            <th width="14%" class="tel">{{'kcltele' | translate}}</th>
                            <th class="adresse">{{'kcladdr' | translate}}</th>
                            <th class="port">{{'kclfide' | translate}}</th>
                            <th><span *ngIf="pageType !== 'popup'">&nbsp;</span></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listClient?.length != 0 && !showLoader">
                        <tr *ngFor="let client of listClient" (click)="editClient(client)">
                            <td class="nom">{{ ((client.CIVLIB != null && client.CIVLIB !== '')?client.CIVLIB+' ':'') + client.TRAISOC + ' ' + client.TPRENOM | uppercase }}</td>
                            <td class="prenom">{{ client.CODPOS + ' ' + client.TVILLE | uppercase }}</td>
                            <td class="port">{{ client.TPORT | formatTele }}</td>
                            <td class="adresse">{{ client.TTEL | formatTele }}</td>
                            <td class="adresse">{{ client.TADR3 }}</td>
                            <td class="cp" width="12%">{{ client.NUMFID }}</td>
                            <td class="actions">
                                <button type="button" mat-mini-fab color="primary"  [class]="'edit'"  *ngIf="pageType !== 'popup'">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>                            
                        </tr>                        
                    </tbody>
                    <tbody *ngIf="listClient?.length == 0 || showLoader">
                        <tr>
                            <td colspan="7" *ngIf="listClient?.length == 0 && !showLoader" class="no-data-available text-center">{{ "kpnoresult" | translate  }}</td>
                            <td colspan="7" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>                            
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                        </tr>
                    </tbody>
                </table>

                <mat-list *ngIf="isMobile && (listClient?.length == 0 || showLoader)">
                    <mat-list-item *ngIf="listClient?.length == 0 && !showLoader">                        
                        <p matLine class="text-center">
                            {{ "kpnoresult" | translate  }}
                        </p>
                    </mat-list-item>

                    <mat-list-item *ngIf="showLoader">
                        <p matLine class="text-center" *ngIf="pageType === 'page'">
                            <mat-icon class="synching">sync</mat-icon>
                        </p>
                        <p matLine class="text-center" *ngIf="pageType === 'popup'">
                            {{ 'kchargement' | translate }}
                        </p>
                    </mat-list-item>
                </mat-list>

                <mat-list *ngIf="isMobile && (listClient?.length != 0 && !showLoader)">
                    <mat-list-item  *ngFor="let client of listClient" (click)="editClient(client)">     
                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                            <h2 class="mobile-list-title">
                                {{ ((client.CIVLIB != null && client.CIVLIB !== '')?client.CIVLIB+' ':'') + client.TRAISOC + ' ' + client.TPRENOM | uppercase }}
                            </h2>
                            <p class="m-0">
                                <small>{{ client.NUMFID }}</small>
                            </p>
                        </div>

                        <p matLine class="mobile-list-title">
                            {{ client.TADR3?client.TADR3:'' }}
                        </p>

                        <p matLine class="mobile-list-title">
                            {{ client.CODPOS + ' ' + client.TVILLE | uppercase }}
                        </p>
                                                
                        <p matLine class="mobile-list-title">
                            {{ client.TPORT | formatTele }} {{ client.TTEL?(client.TPORT && client.TPORT.trim() !== ''?' | ':'')+(client.TTEL | formatTele):'' }} 
                        </p>                        
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>
