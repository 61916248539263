import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PostalCodeModel } from 'models/postal-code';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { PostalCodesService } from 'providers/api/postalCodesService';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-postal-code',
  templateUrl: './edit-postal-code.component.html',
  styleUrls: ['./edit-postal-code.component.scss'],
  providers: [PostalCodesService, DialogsService, NgxSpinnerService]
})
export class EditPostalCodeComponent {

  editPostalCodeForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PostalCodeModel,
              private dialogRef: MatDialogRef<EditPostalCodeComponent>,
              private formBuilder: UntypedFormBuilder,
              private postalCodeService: PostalCodesService,
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private translateService: TranslateService) {
      this.editPostalCodeForm = this.formBuilder.group({
        CODPOS: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(5)]],
        VILLE: ['', Validators.required],
        TROWID: ['', Validators.required]
      });

      this.editPostalCodeForm.patchValue({
        'CODPOS': this.data.CODPOS,
        'VILLE': this.data.VILLE,
        'TROWID': this.data.TROWID
      });
  }

  editFormSubmission(): void {
    this.loadingService.show();

    if(this.editPostalCodeForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kperr'), this.translateService.instant('kpmand'));

      return;
    }

    const form_data = this.editPostalCodeForm.value;
    this.postalCodeService.updatePostalCode(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kpupdate'));          
          this.dialogRef.close(form_data);
        } else {
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kpupdateerr'));          
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kpconerr'), this.translateService.instant('kpconerrtry'));        
      }
    )
  }

}
