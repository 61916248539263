<div class="page-container">
    <div class="title row">
        <div class="col-1 col-sm-2 col-md-2 col-lg-3">
            <mat-icon class="pointer" (click)="returnBack()" [title]="'kuback' | translate">
                keyboard_arrow_left
            </mat-icon>
        </div>
        <div class="col-10 col-sm-8 col-md-8 col-lg-6 text-center">
            <h1 class="text-center d-flex justify-content-center align-items-center" *ngIf="!isMobile">
                <span>{{ 'kgdevis' | translate }} - {{ (formType === 'saise'?'kbonsaise':'kbonmodi') | translate }}</span>
                <span class="badge ml-2" *ngIf="previousPanier" [class]="defineRowColor()">{{ (previousPanier.statut == 2?'kkvalidedev':'kkencours') | translate }}</span>
            </h1>
            <h1 class="text-center d-flex justify-content-center align-items-center" *ngIf="isMobile">
                <span>{{ 'kgdevis' | translate }} - {{ (formType === 'saise'?'kbonsaise':'kbonmodi') | translate }}</span>
                <span class="badge ml-2" *ngIf="previousPanier" [class]="defineRowColor()">{{ (previousPanier.statut == 2?'kkvalidedev':'kkencours') | translate }}</span>
            </h1>
        </div>
        <div class="col-1 col-sm-2 col-md-2 col-lg-3 no-padding" *ngIf="isMobile">
            <mat-icon>request_quote</mat-icon>
        </div>
    </div>

    <div *ngIf="!isMobile">
        <div class="bloc_container padding_15 bg_light">
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="boxed-section">
                        <div class="row">
                            <div class="col-12 col-md-4 col-lg-3 tiers">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kdevbonl' | translate }} </mat-label>
                                    <input matInput [value]="(bonNumBon && bonNumBon != 'null')?bonNumBon:'*************'" placeholder="*************" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 tiers">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'kdevdatdebon' | translate }} </mat-label>
                                    <input matInput [value]="(bonDuDate)?bonDuDate:''" placeholder="" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 tiers">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{'kbonuser1' | translate}} </mat-label>
                                    <input matInput [value]="currentUserName" disabled>
                                </mat-form-field>
                            </div>                            
                        </div>
                    </div>
                    
                    <form [formGroup]="newArticleForm" #newItemForm=ngForm [cdkTrapFocusAutoCapture]="true" [cdkTrapFocus]="true"  (keydown.enter)="$event.preventDefault()" (ngSubmit)="newArticleFormSubmitter()">
                        <div class="boxed-section">                        
                            <div class="row">                        
                                <div class="col-12 col-sm-4 col-md-3 col-lg-2 pr-2">
                                    <mat-form-field floatLabel="always" appearance="outline">
                                        <mat-label>{{'kartref' | translate}}</mat-label>
                                        <input matInput type="text" formControlName="CDART" (keyup.enter)="searchArticleRapid($event)" class="text-uppercase" cdkFocusInitial #newArticleCdart />
                                        <button matSuffix type="button" (click)="openUpSearchArticle($event)" tabindex="-1" mat-icon-button>
                                            <mat-icon>search</mat-icon>
                                        </button>
                                        <button matSuffix class="ml-2" type="button" *ngIf="isMobile" (click)="openScanArticle($event)" tabindex="-1" mat-icon-button>
                                            <i class="fa fa-barcode" aria-hidden="true"></i>
                                        </button>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-3 col-lg-2 pl-1 pr-2">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>{{'kbondesgn' | translate}}</mat-label>
                                        <input matInput formControlName="CALIB1" class="text-uppercase" />
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-9 col-lg-5 pl-1 pr-2">
                                    <div class="row">
                                        <div class="col-12 col-lg-3 quantity_holder pl-1 pr-2">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kartqqty' | translate}}</mat-label>
                                                <input [imask]="quantityMask" [unmask]="true" matInput #newArticleQty formControlName="QTE" (click)="onQuantityFocused()" (change)="onQTYChanged($event)" (keyup)="onQTYChanged($event)" (keydown)="incDecByArrows($event)" (keydown.tab)="_focusPUvente();false;" (keydown.enter)="_focusPUvente()"  class="text-uppercase" />
        
                                                <button type="button" mat-icon-button matSuffix (click)="quantityChange('increment')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>add</mat-icon>
                                                </button> 
                                                <button type="button" mat-icon-button class="ml-2" matSuffix (click)="quantityChange('decrement')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-lg-3 pl-1 pr-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpucais' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="CAPRIX" class="text-uppercase"/>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-lg-3 pl-1 pr-2">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kbonpuven' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput #prixVente min="1" formControlName="NEW_PRIX" (change)="onPriceChanged($event)" (keydown)="onPriceChanged($event)" (keyup)="onPriceChanged($event)" (keydown.tab)="_focusTypecde();false;" (keydown.enter)="_focusTypecde();false;"  class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-lg-3 pl-1 pr-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpvtot' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="ROW_TOTAL" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-4 col-md-3 col-lg-2 pl-1 pr-2">
                                    <mat-form-field floatLabel="always" appearance="outline">
                                        <mat-label>{{'kbontypedevente' | translate}}</mat-label>
                                        <mat-select formControlName="TYPECDE" #typeCde (keydown.enter)="_triggerSubmitNewItem()" (keydown.tab)="_triggerSubmitNewItem();false;" disableOptionCentering panelClass="dropdown-panel">                                            
                                            <mat-optgroup *ngIf="typeVenteRules" [label]="typeVenteRules.title | translate">
                                                <mat-option *ngFor="let item of typeVenteRules.data;let i=index;" [value]="item.value" [selected]="(i == 0)?true:false" [disabled]="item.disabled != undefined && item.disabled">{{item.label | translate}}</mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-4 col-md-3 col-lg-1 pl-1">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>{{'kbonremise' | translate}}</mat-label>
                                        <input [imask]="maskOptions" [unmask]="true" matInput formControlName="MOTANT" />

                                        <button type="button" mat-icon-button matSuffix [disabled]="!currentArticle" (click)="openUpRemiseList(1, currentArticleCAPrix.value, true)">
                                            %
                                        </button>
                                    </mat-form-field>
                                </div>
                                <div class="clearfix"></div>
                                
                                <div class="col-12 col-md-3 col-lg-2">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>{{'kboninstock' | translate}} </mat-label>
                                        <input matInput formControlName="STOCKDISPO" placeholder="">
    
                                        <button type="button" mat-icon-button matSuffix (click)="openUpDetailStock()" [class.d-none]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')" [disabled]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')">
                                            <mat-icon>edit</mat-icon>
                                        </button>                                
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-4 col-lg-5 d-flex align-items-center">
                                    <h4 class="text-primary animated flash" *ngIf="commander">{{ ((currentArticle && currentArticle.etatart == 'G')?((currentArticle && +currentArticle.STOCKDISPO <= 0)?('ksanssuite' | translate):('ksanssuite' | translate)): ('kboncommande' | translate))}}</h4>
                                </div>
                                <div class="col-md-5 text-right">
                                    <button type="submit" class="lock-open" mat-button><mat-icon>check</mat-icon> {{ 'ksaddbtn' | translate }}</button>
                                </div>
                            </div>                        
                        </div>  
                    </form>
                </div>
                <div class="col-12">
                    <div style="border: 1px solid #d8d8d8;overflow-y: scroll;" class="bg-white">
                        <table #tableItems id="items_datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border no-border table-bordered hover dataTable no-footer">
                            <thead>
                                 <tr>
                                     <th class="nom">{{'kartref' | translate}}</th>
                                     <th class="cp">{{'kbondesgn' | translate}}</th>
                                     <th class="tel text-right">{{'kartqqty' | translate}}</th>
                                     <th class="tel text-right">{{'kbonpucais' | translate}}</th>
                                     <th class="adresse text-right">{{'kbonpuven' | translate}}</th>                            
                                     <th class="port text-right">{{'kbonpvtot' | translate}}</th>
                                     <th class="port">{{'kbontype' | translate}}</th>
                                     <th class="port">{{'kbonrem' | translate}}</th>                                 
                                 </tr>
                             </thead>
                             <tbody *ngIf="listBonVenteItems?.length != 0 && !showLoader">
                                 <tr *ngFor="let item of listBonVenteItems;let i=index;" [class.parent-row]="item.opt < 0" [class.child-row]="item.opt > 0" [class.active]="activeLineItem === i">
                                     <td class="nom" (click)="setActiveItem(i);loadArticleItem(item)">
                                        <div class="d-flex justify-content-start align-items-center">                                            
                                            <span>{{ item.cdart }}</span>
                                            <span class="red-dot" *ngIf="(item.commlig1 !== null && item.commlig1 !== '') || (item.commlig2 !== null && item.commlig2 !== '')"></span>
                                         </div>
                                     </td>
                                     <td class="prenom" (click)="setActiveItem(i);loadArticleItem(item)">                                         
                                         <div class="d-flex justify-content-between">
                                            <span>{{ item.lib }}</span>
                                            <div class="align-self-start d-flex flex-column align-items-end">
                                                <span class="badge badge-danger mb-1" *ngIf="item.coinsolde">{{'kboncoin' | translate}}</span>
                                                <span class="badge badge-danger mt-1" *ngIf="item.expo">{{'kbonexpo' | translate}}</span>
                                            </div>
                                         </div>
                                     </td>
                                     <td class="adresse text-right" (click)="setActiveItem(i);loadArticleItem(item)">{{ item.quantite | number: '0.2' }}</td>
                                     <td class="port text-right" (click)="setActiveItem(i);loadArticleItem(item)">                                         
                                         <div class="d-flex justify-content-end align-items-center">
                                            <span *ngIf="item.prixforce" class="badge badge-danger mr-3">P</span>
                                            <span>{{ item.prix | number: '0.2' }}</span>
                                         </div>
                                     </td>
                                     <td class="adresse text-right" (click)="setActiveItem(i);loadArticleItem(item)">                                         
                                         <div class="d-flex justify-content-end align-items-center">
                                            <span *ngIf="(item.datarem !== '' && item.datarem !== null)" class="badge badge-danger mr-3">%</span>
                                            <span>{{ item.prixupv | number: '0.2' }}</span>
                                         </div>
                                     </td>
                                     <td class="cp text-right" (click)="setActiveItem(i);loadArticleItem(item)">{{ item.montant | number: '0.2' }}</td>
                                     <td class="cp" (click)="setActiveItem(i);loadArticleItem(item)">{{ item.typecde }}</td>
                                     <td class="cp text-right">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>{{ item.remise | number: '0.2' }} %</span>
                                        
                                            <button type="button" mat-icon-button class="button-small theme-color" (click)="openUpAddedRemiseList(1, 0, item)">
                                                <mat-icon>info</mat-icon>
                                            </button>
                                        </div>
                                    </td>                                 
                                 </tr>                        
                             </tbody>
                             <tbody *ngIf="listBonVenteItems?.length == 0 || showLoader">
                                 <tr>
                                     <td colspan="8" *ngIf="listBonVenteItems?.length == 0 && !showLoader" class="no-data-available text-center empty_items_table"></td>
                                     <td colspan="8" *ngIf="showLoader" class="no-data-available text-center"><mat-icon class="synching">sync</mat-icon></td>                            
                                     <td style="display: none;"></td>
                                     <td style="display: none;"></td>
                                     <td style="display: none;"></td>
                                     <td style="display: none;"></td>
                                     <td style="display: none;"></td>
                                     <td style="display: none;"></td>
                                     <td style="display: none;"></td>
                                 </tr>
                             </tbody>
                         </table>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row no-gutters">
                        <div class="col-md-7">
                            <div class="mt-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-form-field>
                                            <mat-label>{{'kboncomment' | translate}}</mat-label>
                                            <input matInput [(ngModel)]="devisComments" class="text-uppercase"/>
                                        </mat-form-field>
                                    </div>
                                </div>                                
                            </div>
                            <div class="mt-3">
                                <div class="row">
                                    <div class="col-md-5">                                        
                                        <div class="validatebonde">
                                            <button type="button" class="btn-block" (click)="validationEnBonDeVente()" *ngIf="validateBtnStat" mat-button>
                                                <i class="material-icons">assignment</i>
                                                {{(previousPanier && previousPanier.statut == 2?'kkvoirvente':'kkvalidatebondevente') | translate}}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                    <div class="col-md-4">
                                        <mat-form-field floatLabel="always">
                                            <mat-label>{{'kbonremtot' | translate}} </mat-label>
                                            <input matInput [value]="((totalDiscountPercentage?totalDiscountPercentage:0) | number: '0.2')+'%'" disabled>

                                            <button type="button" mat-icon-button matSuffix (click)="openUpRemiseList(2, totalPrixCaisse)">
                                                %
                                            </button>
                                            <button type="button" mat-icon-button matSuffix (click)="openUpAddedRemiseList()">
                                                i
                                            </button>                                            
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                <h4>{{'kbontotHT' | translate}}</h4>
                                <h4>{{ (totalHT?totalHT:0) | cap_currency:null:false }}</h4>
                            </div>

                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                <h4>{{'kbontotTVA' | translate}}</h4>
                                <h4>{{ (totalTVA?totalTVA:0) | cap_currency:null:false }}</h4>
                            </div>

                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                <h4>{{'kbontotTTC' | translate}}</h4>
                                <h4>{{ (totalTTC?totalTTC:0) | cap_currency:null:false }}</h4>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" class="btn-block text-left" (click)="submitBondeVente()" mat-button>
                                <i class="material-icons">check</i>
                                {{ 'kuok' | translate }}
                            </button>

                            <button type="button" class="btn-block text-left" (click)="imprimerConfirmCheckboxPopup()" [disabled]="bonNumBon == undefined || bonNumBon == 'null' || bonNumBon == ''" mat-button>
                                <i class="material-icons">print</i>
                                {{ 'kartprint' | translate }}
                            </button>

                            <button type="button" class="btn-block text-left" (click)="onCloseBondeVente()" mat-button>
                                <i class="material-icons">{{ (previousPanier !== undefined && previousPanier !== null)?'delete':'close'}}</i>
                                {{ ((previousPanier !== undefined && previousPanier !== null)?'kbonsupprimer':'ktcol') | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bloc_container">
            <mat-card class="no-padding">
                <mat-card-content class="mat-card-content text-center">
                    <div class="row">
                        <div class="col-12 no-padding">    
                            <mat-tab-group [(selectedIndex)]="devisSelectedTab" style='min-height:400px'>
                                <mat-tab [label]="'kbonclient' | translate">
                                    <app-client-bonde [flowType]="flowType" [emailMandatory]="emailMandatory" [panier]="previousPanier" [flowCMINT]="flowCMINT" [(focusInput)]="focusClientSearch" [listMagasins]="listMagasins" (panierChange)="panierUpdate($event)" [(client)]="chosenClient" [(chosenFidelite)]="chosenFidelite"></app-client-bonde>
                                </mat-tab>

                                <mat-tab [label]="'kbonloyalty' | translate">
                                    <app-fidelite-bonde [flowType]="flowType" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(bonNumBon)]="bonNumBon" [(client)]="chosenClient" [(fidelite)]="chosenFidelite"></app-fidelite-bonde>
                                </mat-tab>

                                <mat-tab [label]="'kboninfocom' | translate">
                                    <app-info-complementaries [flowType]="flowType" [flowCMINT]="flowCMINT" [(article)]="article" [bonNumBon]="bonNumBon" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(activeItemNumber)]="activeLineItem" [userName]="currentUserName"></app-info-complementaries>
                                </mat-tab>

                                <mat-tab [label]="'kartarttit' | translate">
                                    <ng-template matTabContent>
                                        <app-article-detail [flowType]="flowType" displayType="tab" [flowCMINT]="flowCMINT" [(vente_item_article)]="article"></app-article-detail>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="bloc_container mobile" *ngIf="isMobile">
        <div class="row no-gutters">
            <div class="col-12">
                <mat-accordion>
                    <mat-expansion-panel id="scr_ajouter" [expanded]="step === 0" (afterExpand)="__scrollToTab('scr_ajouter')" (opened)="setStep(0)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kbonajouterart' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <form [formGroup]="newArticleForm" #newItemForm=ngForm (keydown.enter)="$event.preventDefault()" (ngSubmit)="newArticleFormSubmitter()">
                                <div class="boxed-section">                        
                                    <div class="row">                        
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kartref' | translate}}</mat-label>
                                                <input matInput inputmode="none" formControlName="CDART" (click)="newArticleCdart.select()" (keyup.enter)="searchArticleRapid($event)" class="text-uppercase col-8" #newArticleCdart />
                                                <button matSuffix type="button" (click)="searchArticleRapid($event)" mat-icon-button class="inlineblock" tabindex="-1" *ngIf="isMobile">
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <button matSuffix class="ml-2" type="button" *ngIf="isMobile" (click)="openScanArticle($event)" tabindex="-1" mat-icon-button>
                                                    <i class="fa fa-barcode" aria-hidden="true"></i>
                                                </button>
                                                <button matSuffix type="button" (click)="openUpSearchArticle($event)" mat-icon-button class="inlineblock" tabindex="-1" *ngIf="!isMobile">
                                                    <mat-icon>search</mat-icon>
                                                </button>                                              
                                                <button matSuffix type="button" class="ml-2 inlineblock" mat-icon-button (click)="openAdvancedSearch()" tabindex="-1" *ngIf="isMobile">
                                                    <mat-icon class="bt_logout bt_cursor text-white" [title]="'kclsearc' | translate">more_vert</mat-icon>
                                                </button>
                                            </mat-form-field>   
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbondesgn' | translate}}</mat-label>
                                                <input matInput formControlName="CALIB1" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kboninstock' | translate}} </mat-label>
                                                <input matInput formControlName="STOCKDISPO" placeholder="">
            
                                                <h4 matSuffix class="text-primary text-right animated flash mr-3" *ngIf="commander">{{ ((currentArticle && currentArticle.etatart == 'G')?((currentArticle && +currentArticle.STOCKDISPO <= 0)?('ksanssuite' | translate):('ksanssuite' | translate)): ('kboncommande' | translate))}}</h4>
                                                <button type="button" mat-icon-button matSuffix (click)="openUpDetailStock()" tabindex="-1" [class.d-none]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')" [disabled]="!currentArticle || currentArticle === null || (currentArticle && currentArticle.CAGESTOCK == '0') || (currentArticle && currentArticle.CAOKLIV == '-1')">
                                                    <mat-icon>edit</mat-icon>
                                                </button>                                
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pr-1">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kartqqty' | translate}}</mat-label>
                                                <input [imask]="quantityMask" [unmask]="true" inputmode="none" matInput #newArticleQty formControlName="QTE" (click)="onQuantityFocused()" (change)="onQTYChanged($event)" (keyup)="onQTYChanged($event)" (keydown)="incDecByArrows($event)" (keydown.tab)="_focusPUvente();false;" (keydown.enter)="_focusPUvente()" (click)="newArticleQty.select()" class="text-uppercase" />

                                                <button type="button" mat-icon-button matSuffix class="ml-2" (click)="quantityChange('decrement')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>remove</mat-icon>
                                                </button>
                                                <button type="button" mat-icon-button matSuffix (click)="quantityChange('increment')" tabindex="-1" *ngIf="currentArticle && !quantityDisabled">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pl-1">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kbonpuven' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" type="text" inputmode="decimal" matInput #prixVente formControlName="NEW_PRIX" (change)="onPriceChanged($event)" (keydown)="onPriceChanged($event)" (keyup)="onPriceChanged($event)" (keydown.tab)="_focusTypecde();false;" (keydown.enter)="_focusTypecde();false;" (focus)="prixVente.select()" (click)="prixVente.select()" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pr-1">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpucais' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="CAPRIX" class="text-uppercase"/>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-2 col-lg-1 pl-1">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonpvtot' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="ROW_TOTAL" class="text-uppercase" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <mat-form-field floatLabel="always" appearance="outline">
                                                <mat-label>{{'kbontypedevente' | translate}}</mat-label>
                                                <mat-select formControlName="TYPECDE" #typeCde disableOptionCentering panelClass="dropdown-panel">
                                                    <mat-optgroup *ngIf="typeVenteRules" [label]="typeVenteRules.title | translate">
                                                        <mat-option *ngFor="let item of typeVenteRules.data;let i=index;" [value]="item.value" [selected]="(i == 0)?true:false" [disabled]="item.disabled != undefined && item.disabled">{{item.label | translate}}</mat-option>                                                
                                                    </mat-optgroup>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-9 col-lg-2">
                                            <mat-form-field floatLabel="always">
                                                <mat-label>{{'kbonremise' | translate}}</mat-label>
                                                <input [imask]="maskOptions" [unmask]="true" matInput formControlName="MOTANT" />
        
                                                <button type="button" mat-icon-button matSuffix [disabled]="!currentArticle" (click)="openUpRemiseList(1, currentArticleCAPrix.value, true)">
                                                    %
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div class="clearfix"></div>
        
                                        <div class="col-md-7"></div>
                                        <div class="col-md-5 text-right">
                                            <button class="ml-2 addbut" type="submit" mat-button><mat-icon>check</mat-icon></button>
                                        </div>
                                    </div>                        
                                </div>  
                            </form>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel id="scr_detail" [expanded]="step === 1" (afterExpand)="__scrollToTab('scr_detail')" (opened)="setStep(1)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kboncommanddet' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>
                            <div class="row no-gutters">
                                <div class="col-12">
                                    <div style="border: 1px solid #d8d8d8;overflow-y: scroll;" class="bg-white">
                                        <mat-list *ngIf="(listBonVenteItems?.length == 0 || showLoader)">
                                            <mat-list-item *ngIf="listBonVenteItems?.length == 0 && !showLoader">                        
                                                <p matLine class="text-center">
                                                    {{ "kpnoresult" | translate  }}
                                                </p>
                                            </mat-list-item>
                        
                                            <mat-list-item *ngIf="showLoader">                        
                                                <p matLine class="text-center">
                                                    <mat-icon class="synching">sync</mat-icon>
                                                </p>
                                            </mat-list-item>
                                        </mat-list>
                        
                                        <mat-list *ngIf="(listBonVenteItems?.length != 0 && !showLoader)">
                                            <mat-list-item  *ngFor="let item of listBonVenteItems;let i=index;" [id]="'swipeItem-' + i" style="user-select: none;" (swipe)="onSwipe($event, i, item)" [class.active]="activeLineItem === i">
                                                <div class="swipedContent">
                                                    <button type="button" mat-mini-fab color="warn" class="" (click)="supprimerLineItem(item)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>

                                                <div class="w-100 d-flex list-item-main-content" (click)="setActiveItem(i);loadEditArticleItem(item,$event)">
                                                    <img matListAvatar [src]="getImageURL(item.cdart)" default="assets/images/no_img.png" [alt]="item.cdart">

                                                    <div class="mat-list-text">
                                                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                            <h2 class="m-0" (click)="loadArticleDetail(item,$event)">
                                                                <span class="red-dot" *ngIf="(item.commlig1 !== null && item.commlig1 !== '') || (item.commlig2 !== null && item.commlig2 !== '')"></span>
                                                                <span class="linktxt ml-1">{{ item.cdart }} </span>
                                                            </h2>
                                                            <div class="d-flex flex-row align-items-center justify-content-around">
                                                                <span class="badge badge-danger mr-1" *ngIf="item.coinsolde">{{'kboncoin' | translate}}</span>
                                                                <span class="badge badge-danger" *ngIf="item.expo">{{'kbonexpo' | translate}}</span>
                                                            </div>
                                                        </div>
            
                                                        <div matLine class="align-items-start d-flex flex-row justify-content-between">
                                                            <p class="mobile-list-title mb-0">
                                                                <span class="linktxt"  (click)="loadArticleDetail(item,$event)"> {{ item.lib }}</span>
    
                                                                <br *ngIf="item.opt < 0 || item.opt > 0">
                                                                <span class="badge badge-info" *ngIf="item.opt < 0">{{ 'kkopts' | translate }}</span>
                                                                <span class="badge badge-success" *ngIf="item.opt > 0">{{ 'kkopt' | translate }}</span>
                                                            </p>
                                                            <span>{{ item.typecde }}</span>
                                                        </div>
            
                                                        <p matLine class="mt-1 align-items-center d-flex flex-row justify-content-end">
                                                            <span *ngIf="item.prixforce" class="badge badge-danger mr-3">P</span>
            
                                                            <span class="badge badge-info ml-1">
                                                                {{ item.quantite | number: '0.2' }}
                                                            </span>
                                                            <span class="badge badge-secondary ml-1">
                                                                {{ item.montant | cap_currency:null:false }}
                                                            </span>
                                                            <span class="badge badge-danger ml-1" *ngIf="item.remise != 0">
                                                                {{ item.remise | number: '0.2' }} %
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                        </mat-list>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters">
                                        <div class="col-12">
                                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                                <h4>{{'kbontotHT' | translate}}</h4>
                                                <h4>{{ (totalHT?totalHT:0) | cap_currency:null:false }}</h4>
                                            </div>
                    
                                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                                <h4>{{'kbontotTVA' | translate}}</h4>
                                                <h4>{{ (totalTVA?totalTVA:0) | cap_currency:null:false }}</h4>
                                            </div>
                    
                                            <div class="mt-3 d-flex align-items-center justify-content-around">
                                                <h4>{{'kbontotTTC' | translate}}</h4>
                                                <h4>{{ (totalTTC?totalTTC:0) | cap_currency:null:false }}</h4>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3 mt-3">
                                                <mat-form-field floatLabel="always">
                                                    <mat-label>{{'kbonremtot' | translate}} </mat-label>
                                                    <input matInput [value]="((totalDiscountPercentage?totalDiscountPercentage:0) | number: '0.2')+'%'" disabled>
            
                                                    <button type="button" mat-icon-button matSuffix (click)="openUpRemiseList(2, totalPrixCaisse)">
                                                        %
                                                    </button>
                                                    <button type="button" mat-icon-button matSuffix (click)="openUpAddedRemiseList()">
                                                        i
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </div>     
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-form-field>
                                                    <mat-label>{{'kboncomment' | translate}}</mat-label>
                                                    <input matInput [(ngModel)]="devisComments" class="text-uppercase"/>
                                                </mat-form-field>
                                            </div>
                                        </div>                             
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel id="scr_client" [expanded]="step === 2" (afterExpand)="__scrollToTab('scr_client')" (opened)="setStep(2)" class="mobile">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kbonclient' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-client-bonde [emailMandatory]="emailMandatory" [flowType]="flowType" [(focusInput)]="focusClientSearch" [panier]="previousPanier" [flowCMINT]="flowCMINT" [(focusInput)]="focusClientSearch" [listMagasins]="listMagasins" (panierChange)="panierUpdate($event)" [(client)]="chosenClient" [(chosenFidelite)]="chosenFidelite"></app-client-bonde>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel id="scr_fidelite" class="mobile" (afterExpand)="__scrollToTab('scr_fidelite')" (opened)="setStep(3)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kbonloyalty' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>                        
                        <ng-template matExpansionPanelContent>                            
                            <app-fidelite-bonde [flowType]="flowType" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(bonNumBon)]="bonNumBon" [(client)]="chosenClient" [(fidelite)]="chosenFidelite"></app-fidelite-bonde>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel id="scr_validatelimit" [expanded]="step === 4" (afterExpand)="__scrollToTab('scr_validatelimit')" (opened)="setStep(4)" class="mobile">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kdevisvalidate' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-client-bonde [emailMandatory]="emailMandatory" [flowType]="flowType" [showOnly]="showOnly" [(focusInput)]="focusClientSearch" [panier]="previousPanier" [flowCMINT]="flowCMINT" [(focusInput)]="focusClientSearch" [listMagasins]="listMagasins" (panierChange)="panierUpdate($event)" [(client)]="chosenClient" [(chosenFidelite)]="chosenFidelite"></app-client-bonde>
                        </ng-template>
                    </mat-expansion-panel>                   
                    <mat-expansion-panel id="scr_complement" class="mobile" (afterExpand)="__scrollToTab('scr_complement')" (opened)="setStep(6)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'kboninfocom' | translate}}
                            </mat-panel-title>                
                        </mat-expansion-panel-header>
                        
                        <ng-template matExpansionPanelContent>                            
                            <app-info-complementaries [flowType]="flowType" [flowCMINT]="flowCMINT" [(article)]="article" [bonNumBon]="bonNumBon" [panier]="previousPanier" (panierChange)="panierUpdate($event)" [(activeItemNumber)]="activeLineItem" [userName]="currentUserName"></app-info-complementaries>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <div class="row bg_white brtop">  
            <div class="row mt-3">
                <div class="col-4">
                    <button type="button" class="bt_valid" (click)="validationEnBonDeVente()" *ngIf="validateBtnStat" mat-button>
                        <i class="material-icons">assignment</i>
                        <span class="d-none d-sm-none d-md-block">{{(previousPanier && previousPanier.statut == 2?'kkvoirvente':'kkvalidatebondevente') | translate}}</span>
                    </button>
                </div>
                <div class="col-8 text-right">
                    <button type="button" class="btn-valid text-left" (click)="imprimerConfirmCheckboxPopup()" [disabled]="bonNumBon == undefined || bonNumBon == 'null' || bonNumBon == ''" mat-button>
                        <i class="material-icons">print</i>                        
                    </button>
                    <button type="submit" class="bt_valid ml-2" (click)="submitBondeVente()" mat-button>                                            
                        <i class="material-icons">done</i>
                    </button>
                    <button type="button" class="bt_valid ml-2" (click)="onCloseBondeVente()" mat-button>
                        <mat-icon>{{ (previousPanier !== undefined && previousPanier !== null)?'delete':'close'}}</mat-icon>
                    </button>
                </div>                
            </div>
        </div>
    </div>
</div>
