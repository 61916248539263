import { ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// As mentioned in the ticket CAP-1028
// https://manureva.atlassian.net/browse/CAP-1208
const PORT_PREFIX_PATTERN = {
  41: new RegExp(/^(75|76|77|78|79)/),
  33: new RegExp(/^(6|7)/),
  39: new RegExp(/^(3)/),
};
const TEL_PREFIX_PATTERN = {
  41: new RegExp(/^(21|22|24|26|27|31|32|33|34|41|43|44|52|55|56|61|62|71|81|91)/),
  33: new RegExp(/^(1|2|3|4|5)/),
  39: new RegExp(/^(?!3)/),
};

export function PhoneNumberValidator(regionCode: string = undefined, type: 'port' | 'tele'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validNumber = false;
    try {
      const inputVal = control?.value?.number?control?.value?.number:control.value;
      if(inputVal !== undefined && inputVal != null) {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
          inputVal?.toString(), regionCode
        );

        validNumber = phoneNumberUtil.isValidNumber(phoneNumber);

        if (validNumber) {
          const mobileNumberExtracted = phoneNumber.getNationalNumber().toString();
          const countryCodeExtracted = phoneNumber.getCountryCode();

          if (type === 'port' && PORT_PREFIX_PATTERN.hasOwnProperty(countryCodeExtracted)) {
            validNumber = PORT_PREFIX_PATTERN[countryCodeExtracted].test(mobileNumberExtracted);
          }

          if (type === 'tele' && TEL_PREFIX_PATTERN.hasOwnProperty(countryCodeExtracted)) {
            validNumber = TEL_PREFIX_PATTERN[countryCodeExtracted].test(mobileNumberExtracted);
          }
        }
      } else {
        validNumber = true; // If input value is empty
      }
    } catch (e) { }

    return validNumber ? null : { 'validatePhoneNumber': true };
  }
}
