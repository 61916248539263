import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
    selector: 'side-nav-item',
    template: `<li>
                  <a class="side-nav-item" md-ripple
                    [ngClass]="{'side-nav-item-active': active,'side-nav-item-disabled': disabled}"
                    (click)="navigate()">
                        <mat-icon *ngIf="icon" style="margin-right:10px;vertical-align:middle">{{icon}}</mat-icon>
                        <span style="vertical-align:middle">{{title}}</span>
                  </a>
               </li>`,
    styles: [
      `.side-nav-item {
          list-style-type: none;
          box-sizing: border-box;
          display: block;
          font-size: 16px;
          font-weight: 400;
          line-height: 48px;
          text-decoration: none;
          transition: all .3s;
          padding: 0 16px;
          position: relative;
          cursor: pointer;
        }
        .side-nav-item-active {
          background: #ddd
        }
        .side-nav-item-disabled {
          color: #aaa
        }
        .side-nav-item:hover {
          background: #f0f0f0;
        }`]
  })
  export class SideNavItemComponent {

    @Input() sidenav: MatSidenav;
    
    private _title: string;
    get title(): string { return this._title }
    @Input() set title(title: string) {
      this._title = title;
    }

    @Input('icon')  icon: string;

    private _route: string;
    get route(): string { return this._route }
    @Input() set route(route: string) {
      this._route = route;
      this.activatePath();
    }

    @Input('disabled') disabled: boolean;

    private _childRoutes: string[];
    get childRoutes(): string[] { return this._childRoutes }
    @Input() set childRoutes(childRoutes: string[]) {
      this._childRoutes = childRoutes;
      this.activatePath();
    }

    active: boolean;

    constructor(private router: Router) {
    }

    navigate() {
      if(this.route === this.router.url) {
        this.sidenav.close();
      }

      if (!this.disabled) {        
        this.router.navigate([this.route]);
      }
    }

    activatePath(path?: string) {
      const currentURL = this.router.routerState.snapshot.url;
      const checkChildRoutes = (this.childRoutes && this.childRoutes.length > 0) && this.childRoutes.find((_row: string) => (new RegExp(_row)).test(currentURL));

      if (currentURL === this.route || checkChildRoutes) {
        this.active = true;
      } else {
        this.active = false;
      }
    }
  }
