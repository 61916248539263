import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Constants } from '../constants';

import {Observable} from 'rxjs';

@Injectable()
export class ParamService  {

  constructor(private http : HttpClient) {
  }

  getListParam() : Observable<any> {
    
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    var options = {
        headers: headers
    };

    return this.http.get(Constants.API_PARAM_URL + 'getParamsDetail', options);
  }

    getNextId(idMag) : Observable<any> {    

      let params = {};
      params['IDSOC'] = Constants.IDSOC;
      params['IDMAG'] = idMag;

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      var options = {
          headers: headers,
          params: params
      };

      return this.http.get(Constants.API_PARAM_URL + 'getNextId', options);
    }
}
