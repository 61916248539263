import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { NiveauSecuriteService } from 'providers/api/niveauSecuriteService';
import { Constants } from 'providers/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-niveau-de-securite',
  templateUrl: './add-niveau-de-securite.component.html',
  styleUrls: ['./add-niveau-de-securite.component.scss']
})
export class AddNiveauDeSecuriteComponent {

  niveauTypes: any;
  chosenTypeValue: string;
  chosenTypeLabel: string;
  addSecuriteLevelForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AddNiveauDeSecuriteComponent>,
              private formBuilder: UntypedFormBuilder,
              private niveauSecuriteService: NiveauSecuriteService,
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private translateService: TranslateService) {
      
      this.niveauTypes = this.data.list_niveau_type;
      this.chosenTypeValue = this.data.niveau_type;
      this.chosenTypeLabel = (this.niveauTypes.find(el => el.value == this.chosenTypeValue)).label;
      
      this.addSecuriteLevelForm = this.formBuilder.group({        
        TYPE: [this.data.niveau_type, Validators.required],
        IDSOC : [Constants.IDSOC],
        NIVNOM: ['', [Validators.required]]                
      });
  }

  addSecuriteLevelFormSubmission(): void {
    this.loadingService.show();

    if(this.addSecuriteLevelForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kserror'), this.translateService.instant('ksmand'));

      return;
    }

    const form_data = this.addSecuriteLevelForm.value;

    this.niveauSecuriteService.addSecuriteLevel(form_data).subscribe(
      (resp) => {
        this.loadingService.hide();
        if(resp.statusCode == 200) {
          this.dialogService.prompt(this.translateService.instant('kpsuccessmot'), this.translateService.instant('kssuccess'));
          this.dialogRef.close(form_data);
        } else {
          this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('ksadderror'));          
        }
      },
      err => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('ksconerr'), this.translateService.instant('ksconerrtry'));
      }
    )
  }

}
