import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-colors-list',
  templateUrl: './colors-list.component.html',
  styleUrls: ['./colors-list.component.scss']
})
export class ColorsListComponent implements OnInit {

  @Input() type: 'commande' | 'relance' | 'historique' | 'supprimees' | 'retrait-marchandise' | 'gestion-des-e2' | 'search-gestion-des-e2' | 'devis' | 'cuisine' | 'dressing' = 'commande';
  @Input() displayType: 'popup' | 'comp' = 'popup';

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              @Optional() public dialogRef: MatDialogRef<ColorsListComponent>) { }

  ngOnInit(): void {
    if(this.data !== undefined && this.data !== null) {
      this.type = this.data.type;
    }
  }

}
