import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NiveauModel } from 'models/niveau-model';
import { UserService } from 'providers/api/userService';
import { Constants } from 'providers/constants';


@Component({
  selector: 'app-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss']
})
export class UsersFilterComponent implements OnInit, AfterViewInit {

  @ViewChild('filterFirst', { static: true }) filterFirst: ElementRef;
  searchForm: any;
  orderByList: any;
  niveauTypes: NiveauModel[];
  filteredNiveauTypes: NiveauModel[];
  filterNiveau: string = "";
  oldFormData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<UsersFilterComponent>,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService) { }

  ngOnInit(): void {
    this.orderByList = this.data.orderByList;
    this.niveauTypes = this.data.niveauTypes;
    this.filteredNiveauTypes = this.niveauTypes;
    this.oldFormData = this.data.form_data;

    this.searchForm = this.formBuilder.group({
      search_code: [''],
      search_nom: [''],
      search_niveau: ['tous'],
      search_order: [Constants.USER_ORDER_NOM],
      sort: ['asc'],
      search_actif: [false]
    });

    this.searchForm.patchValue({
      search_code: this.oldFormData.search_code,
      search_nom: this.oldFormData.search_nom,
      search_niveau: this.oldFormData.search_niveau,
      search_order: this.oldFormData.search_order,
      sort: this.oldFormData.sort,
      search_actif: this.oldFormData.search_actif
    });
  }
  onSecurityChange(search: string) {
    this.filteredNiveauTypes = this.niveauTypes.filter(row => row.NIVNOM.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.filterFirst.nativeElement.select();
      this.filterFirst.nativeElement.focus();
    }, 300);
  }

  dialogSearchFormSubmitter() {    
      this.userService.headerSearchInputValue.next(undefined);
      this.dialogRef.close({status: 'success', form_data: this.searchForm.value });
  }
  resetForm(){    
    this.searchForm.patchValue({
      search_code: '',
      search_nom: '',
      search_niveau: 'tous',
      search_actif: '',
      search_order: Constants.USER_ORDER_NOM
    }); 
  }

}
