<div [style]="isMobile?'width: 100%;':'width: 120vh;'">
    <div class="row">
        <div class="col-md-12">
            <ngx-extended-pdf-viewer *ngIf="type === 'PDF'" [src]="url" [useBrowserLocale]="true" [width]="isMobile?'100%':'120vh'" [height]="'90vh'"></ngx-extended-pdf-viewer>

            <!-- <ngx-doc-viewer
            *ngIf="type === 'XL'"
            [url]="url"
            viewer="office"
            [style]="isMobile ? 'width:100%;height:90vh;' : 'width:120vh;height:90vh;'"
            ></ngx-doc-viewer> -->
        </div>
    </div>
</div>

<div class="bloc_container w-100">
    <div class="row bg_white">                
        <div class="align-items-center mt-2 mt-md-0 col-12 d-flex flex-md-row flex-lg-row justify-content-center text-center">
            <a [href]="url" 
                target="_blank"
                rel="noopener noreferrer"
                style="text-decoration: none;"
                mat-button
                class="bt_valid"
            >
                <i class="material-icons">download</i>
                <span class="d-none d-sm-none d-md-block">
                    {{ "kktelecharger" | translate }}
                </span>
            </a>

            <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                <i class="material-icons">close</i>
                <span class="d-none d-sm-none d-md-block">
                    {{ "kartclose" | translate }}
                </span>                        
            </button>
        </div>
    </div>
</div>