
export class Printer {
  'TROWID': string = '';
  'IDSOC': string = '';
  'CMINT': string = '';
  'LIBIMPR': string = 'F';
  'NOMIMPR': string = ''

    constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}
