import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'components/confirm-dialog/confirm-dialog.component';
import BonPanier from 'models/bonPanier';
import MagComplement from 'models/magComplement';
import Transporteur from 'models/transporteur';
import * as moment from "moment";
import { NgxSpinnerService } from 'ngx-spinner';
import { BonVenteService } from 'providers/api/bonventeService';
import { DialogsService } from 'providers/api/dialogService';
import { UserService } from 'providers/api/userService';
// horaires date picker en français
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CUSTOM_DATE_FORMATS} from '../../../providers/custom-date-formats';

@Component({
  selector: 'app-validite-limit',
  templateUrl: './validite-limit.component.html',
  styleUrls: ['./validite-limit.component.scss'],
  providers: [ {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},BonVenteService]
})
export class ValiditeLimitComponent implements OnInit {

  today = new Date();
  devisLimitForm: UntypedFormGroup; 
  oldLivreData: any;  

  dtliv_status: boolean = false;

  magComplementData: MagComplement;
  
  transporteurLabel: string = '';
  panier: BonPanier;

  previousTranporteur: Transporteur;

  paramData: any;
  defaultDiffereDays: number;

  constructor(private formBuilder: UntypedFormBuilder,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private userService: UserService,
            public dialogRef: MatDialogRef<ValiditeLimitComponent>,
            private dialog: MatDialog,
            private dialogService: DialogsService,
            private loadingService: NgxSpinnerService,
            private translateService: TranslateService) { }

  ngOnInit(): void {
    this.oldLivreData = this.data.livreDetail;
    this.magComplementData = this.data.magComplementData;
    this.panier = this.data.panier;    
    
    this.devisLimitForm = this.formBuilder.group({
      dtlimit: [{value: '', disabled: true}],
    });

    this.setDefaultLimit();

    if(this.panier !== undefined && this.panier !== null) {
      let limitDateYear = moment(this.panier.dtech).year();
      this.devisLimitForm.patchValue({
        dtlimit: (limitDateYear !== undefined && limitDateYear !== 1899)?new Date(this.panier.dtech):''
      })
    }
  }

  async setDefaultLimit() {    
    let oldTechYear = moment(this.panier.dtech).year();
    try {
      this.paramData = await this.userService.getRuleParamsData(20, 50);
      this.defaultDiffereDays = this.paramData['VINT2'];

      if(oldTechYear === 1899) {
        this.loadingService.show();
        // Default Date limite for Validite from Params
  
        if(this.paramData['VBOOL2'] == '-1') {
          const devisLimiteDate = moment().add(this.defaultDiffereDays, 'days').format('YYYY-MM-DD HH:mm:ss');
          this.devisLimitForm.get('dtlimit').setValue(new Date(devisLimiteDate));
        }
  
        this.loadingService.hide();
      }
    } catch(err) {
      this.dialogService.prompt(this.translateService.instant('kuconerr'), this.translateService.instant('kuneterr'));
    } 
  }

  async devisLimitFormSubmitter() {
    const form_data = this.devisLimitForm.getRawValue();

    const today = moment().startOf('day');
    const devisMaxDays = moment().add(this.defaultDiffereDays, 'days').startOf('day');
    const selected_date = moment(form_data.dtlimit).startOf('day');

    const diff = devisMaxDays.diff(selected_date, 'days');

    if(today.diff(selected_date, 'days') > 0) { // Error alert, If selected date < today date
      this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kbondateinf'));
      return;
    }

    if(diff < 0) { // Error alert, If selected date > (today date + 30)
      if(this.paramData['VBOOL2'] == '-1') {
        this.dialogService.prompt(this.translateService.instant('kfailure'), this.translateService.instant('kdatestd'));
        return;
      } else {
        const confirmation: any = await this.updateConfirmation();
        if(!confirmation || (confirmation && confirmation.status && confirmation.status !== 'OK')) {
          return;
        }
      }
    }
    
    this.devisLimiteUpdate();
  }

  updateConfirmation(): Promise<any> {
    const message = `
    <h1 class="text-left mb-3">${this.translateService.instant('kdatestd')}</h1>
    <h1 class="text-left pl-2">${this.translateService.instant('kboncontinue')}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [this.translateService.instant('ksyes'), this.translateService.instant('ksno')]
      }     
    });

    return dialogRef.afterClosed().toPromise();
  }

  devisLimiteUpdate() {
    const form_data = this.devisLimitForm.getRawValue();

    form_data.dtlimit = (form_data.dtlimit !== '')?moment(form_data.dtlimit).format('YYYY-MM-DD HH:mm:ss'):moment('1899-12-28').format('YYYY-MM-DD HH:mm:ss');
    this.dialogRef.close({ status: 'success', data: form_data });
  }

  limiteConfirmation(): void {
    const message = `
      <h1 class="mb-3">${this.translateService.instant('kbondatenotstd')}</h1>
      <h1 class="">${this.translateService.instant('kboncontinue')}</h1>
    `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '60vw',
      data: {
        title: '',
        description: message,
        buttons: [
          this.translateService.instant('ksyes'),
          this.translateService.instant('kartno')
        ]
      }     
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "OK") {
        this.devisLimiteUpdate();
      }
    });    
  }

}
