<div mat-dialog-content>
    <form [formGroup]="codeSelectionForm" (ngSubmit)="onCodeSelected()">
        <div class="bloc_container padding_15 bg_light">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="select_civilite">
                        <mat-select [placeholder]="'kclcity' | translate" formControlName="code_pos">
                            <mat-option *ngFor="let code of listCodes" [value]="code.TROWID">
                                {{ code.VILLE }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>                
            </div>
        </div>
        <div class="bloc_container">
            <div class="row bg_white">
                <div class="col-12 text-center">
                    <button class="bt_valid" mat-button type="submit">
                        <i class="material-icons">done</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kpvalid" | translate }}
                        </span>                        
                    </button>
                    <button class="bt_valid ml-3" mat-button mat-dialog-close type="button">
                        <i class="material-icons">close</i>
                        <span class="d-none d-sm-none d-md-block">
                            {{ "kpback" | translate }}
                        </span>                        
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>