import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogsService } from 'providers/api/dialogService';
import { NgxSpinnerService } from 'ngx-spinner';
import { MotifService } from 'providers/api/motifService';
import { Constants } from 'providers/constants';
import { TranslateService } from '@ngx-translate/core';
import { MotifsModel } from 'models/motifs-model';
import { User } from 'models/users';
import { UserService } from 'providers/api/userService';

@Component({
  selector: 'app-blocage-custom-motif',
  templateUrl: './blocage-custom-motif.component.html',
  styleUrls: ['./blocage-custom-motif.component.scss']
})
export class BlocageCustomMotifComponent {

  user: User;
  USERCMINT: any;

  editMotifForm: UntypedFormGroup;

  listMotifs: MotifsModel[];
  customMotif: boolean = true;

  action: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<BlocageCustomMotifComponent>,
              private formBuilder: UntypedFormBuilder,              
              private dialogService: DialogsService,
              private loadingService: NgxSpinnerService,
              private motifService: MotifService,
              private userService: UserService,
              private translateService: TranslateService) {      

      this.user = this.userService.getCurrentUser();          
      this.USERCMINT = this.user.CMINT;

      this.action = this.data.action;   
      if(this.action === undefined || this.action === null) {
        this.action = Constants.MOTIF_DEBLOCAGE_STOCK;
      }   

      this.editMotifForm = this.formBuilder.group({
        PRE_MOTLIB: ['free_text', Validators.required],
        IDSOC : [Constants.IDSOC],
        MOTLIB: [''],
      });

      this.loadingService.show();

      this.getListMotifs(this.action);
  }

  editFormSubmission(): void {
    this.loadingService.show();

    if(this.editMotifForm.invalid) {
      this.loadingService.hide();
      this.dialogService.prompt(this.translateService.instant('kmerror'), this.translateService.instant('kmmand'));

      return;
    }

    this.loadingService.hide();
    const form_data = this.editMotifForm.getRawValue();

    let motifText = '';

    if(this.customMotif) {
      if(form_data.PRE_MOTLIB === 'free_text') {
        motifText = form_data.MOTLIB;
      } else {
        motifText = form_data.PRE_MOTLIB+": "+form_data.MOTLIB;
      }
    } else {
      motifText = form_data.PRE_MOTLIB;
    }

    const motif_data = {
      MOTLIB: motifText,

      _customMotif: this.customMotif,
      _MOTLIB: form_data.MOTLIB,
      _PRE_MOTLIB: form_data.PRE_MOTLIB
    };

    this.dialogRef.close({ status: 'success', data: motif_data });
  }

  setMotifInitVal() {
    if(this.listMotifs !== undefined && this.listMotifs !== null && this.listMotifs.length > 0) {
      
      const first_element = this.listMotifs[0];
      this.editMotifForm.get('PRE_MOTLIB').setValue(first_element.MOTLIB);

      if(first_element.MOTFREE == '-1') {
        this.customMotif = true;

        this.editMotifForm.get('MOTLIB').setValidators([Validators.required, Validators.minLength(2)]);
        this.editMotifForm.get('MOTLIB').updateValueAndValidity();
      } else {
        this.customMotif = false;

        this.editMotifForm.get('MOTLIB').setValue('');
        this.editMotifForm.get('MOTLIB').setErrors(null);
        this.editMotifForm.get('MOTLIB').clearValidators();
        this.editMotifForm.get('MOTLIB').updateValueAndValidity();
      }
    } else {
      this.customMotif = true;

      this.editMotifForm.get('MOTLIB').setValidators([Validators.required, Validators.minLength(2)]);
      this.editMotifForm.get('MOTLIB').updateValueAndValidity();
    }
  }

  onMotifChanged(event: any) {
    if(event.value === 'free_text') {
      this.customMotif = true;

      this.editMotifForm.get('MOTLIB').setValidators([Validators.required, Validators.minLength(2)]);
      this.editMotifForm.get('MOTLIB').updateValueAndValidity();
    } else {
      const currentMotif = this.listMotifs.find((row) => row.MOTLIB == event.value);

      if(currentMotif.MOTFREE == '-1') {
        this.customMotif = true;

        this.editMotifForm.get('MOTLIB').setValidators([Validators.required, Validators.minLength(2)]);
        this.editMotifForm.get('MOTLIB').updateValueAndValidity();
      } else {
        this.customMotif = false;

        this.editMotifForm.get('MOTLIB').setValue('');
        this.editMotifForm.get('MOTLIB').setErrors(null);
        this.editMotifForm.get('MOTLIB').clearValidators();
        this.editMotifForm.get('MOTLIB').updateValueAndValidity();
      }
    }
  }

  getListMotifs(action: number) {
    const httpData = {
      action: action,
      IDMAG: this.USERCMINT,
      IDSOC: Constants.IDSOC,
      CIINT: this.user.CIINT
    };

    this.motifService.getListofMotifsWithoutPagination(httpData).subscribe(
      (response) => {
        this.loadingService.hide();
        const data = response.data;

        this.listMotifs = [...data];
        this.setMotifInitVal();
      },
      error => {
        this.loadingService.hide();
        this.dialogService.prompt(this.translateService.instant('kmconerr'), this.translateService.instant('kmneterr'));
      }
    )  
  }

}
